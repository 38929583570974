import { useAuth0 } from "@auth0/auth0-react";
import { type DqDatasetExportFormProviderSchemaType } from "@decentriq/components";
import { useCreateDatasetExportMutation } from "@decentriq/graphql/dist/hooks";
import { DataTargetType } from "@decentriq/graphql/dist/types";
import { Button } from "@mui/joy";
import { useCallback, useMemo, useState } from "react";
import { createContext, type SetStateAction, useContext } from "react";
import { AmazonClient } from "services";
import { useConfiguration } from "contexts";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { encodeDatasetExportDerivedState } from "../../utils";

interface DatasetExportContextValue {
  selectedDataTarget: DataTargetType;
  setSelectedDataTarget: (
    selectedDataTarget: SetStateAction<DataTargetType>
  ) => void;
  // After submitting a form, datasetExportId is returned, so dataset export data (incl. status) can be fetched via datasetExport query
  datasetExportId: string | null;
  setDatasetExportId: (datasetExportId: SetStateAction<string | null>) => void;
  setIsExportCreated: (isExportCreated: SetStateAction<boolean>) => void;
  isExportCreated: boolean;
  manifestHash: string;
  setDatasetExportError: (error: string) => void;
  datasetExportError: string | null;
  handleCanChangeSyncTypeTo: (type: DataTargetType | null) => boolean;
  handleSubmit: (
    values: DqDatasetExportFormProviderSchemaType
  ) => Promise<void>;
}

export const DatasetExportContext = createContext<DatasetExportContextValue>({
  datasetExportError: "",
  datasetExportId: null,
  handleCanChangeSyncTypeTo: () => true,
  handleSubmit: async () => {},
  // Returns true if the first step of export is completed (creating export itself)
  isExportCreated: false,
  manifestHash: "",
  selectedDataTarget: DataTargetType.S3,
  setDatasetExportError: () => {},
  setDatasetExportId: () => {},
  setIsExportCreated: () => {},
  setSelectedDataTarget: () => {},
});

export const DatasetExportConsumer = DatasetExportContext.Consumer;

export const useDatasetExport = () => useContext(DatasetExportContext);

export interface DatasetExportProviderProps {
  children: React.ReactNode;
  manifestHash: string;
}

export const DatasetExportProvider: React.FC<DatasetExportProviderProps> = ({
  children,
  manifestHash,
}) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { user } = useAuth0();
  const userId = user?.email ?? "";
  const { amazonAmcClientId } = useConfiguration();
  const amazonClient = useMemo(
    () => new AmazonClient(amazonAmcClientId),
    [amazonAmcClientId]
  );
  // After submitting a form, datasetExportId is returned, so dataset export data (incl. status)
  // can be fetched via datasetExport query on the EXPORT_STATUS step
  const [datasetExportId, setDatasetExportId] = useState<string | null>(null);
  const [selectedDataTarget, setSelectedDataTarget] = useState<DataTargetType>(
    DataTargetType.S3
  );
  // Returns true if the first step of external export is completed (creating export itself)
  const [isExportCreated, setIsExportCreated] = useState<boolean>(false);
  // Errors that come from the local resolver
  const [datasetExportError, setDatasetExportError] = useState<string | null>(
    null
  );
  const [createDatasetExportMutation] = useCreateDatasetExportMutation();
  const handleSubmit = useCallback(
    async (
      createDatasetExportMutationVariables: DqDatasetExportFormProviderSchemaType
    ) => {
      await createDatasetExportMutation({
        onCompleted: ({ createDatasetExport }) => {
          setDatasetExportId(createDatasetExport?.dataConnectorJob?.id);
          setIsExportCreated(true);
        },
        onError: (error) => {
          setDatasetExportError(error?.message);
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "The exporting process could not get started. Please try again by refreshing the page."
            )
          );
        },
        variables: {
          input: {
            ...createDatasetExportMutationVariables,
            manifestHash,
          },
        },
      });
    },
    [
      createDatasetExportMutation,
      manifestHash,
      enqueueSnackbar,
      setDatasetExportError,
      setDatasetExportId,
      setIsExportCreated,
    ]
  );
  const handleCanChangeSyncTypeTo = useCallback(
    (type: DataTargetType | null) => {
      if (type !== DataTargetType.AmazonAmc) {
        return true;
      }
      enqueueSnackbar(
        "In order to export to Amazon AMC please proceed with the authorization process.",
        {
          action: (
            <Button
              onClick={() =>
                amazonClient.authorize({
                  redirectUrl: `${amazonClient.redirectUrl}&state=${encodeDatasetExportDerivedState(manifestHash, DataTargetType.AmazonAmc, userId)}`,
                })
              }
              size="sm"
              sx={{
                "--Button-minHeight": "24px",
                borderRadius: "2px",
                lineHeight: 1,
                paddingInline: "0.5rem",
              }}
            >
              Authorize
            </Button>
          ),
          persist: true,
          preventDuplicate: true,
          variant: "info",
        }
      );
      return false;
    },
    [enqueueSnackbar, manifestHash, userId, amazonClient]
  );
  const contextValue = useMemo<DatasetExportContextValue>(
    () => ({
      datasetExportError,
      datasetExportId,
      handleCanChangeSyncTypeTo,
      handleSubmit,
      isExportCreated,
      manifestHash,
      selectedDataTarget,
      setDatasetExportError,
      setDatasetExportId,
      setIsExportCreated,
      setSelectedDataTarget,
    }),
    [
      datasetExportError,
      datasetExportId,
      handleCanChangeSyncTypeTo,
      handleSubmit,
      isExportCreated,
      manifestHash,
      selectedDataTarget,
      setDatasetExportError,
      setDatasetExportId,
      setIsExportCreated,
      setSelectedDataTarget,
    ]
  );
  return (
    <DatasetExportContext.Provider value={contextValue}>
      {children}
    </DatasetExportContext.Provider>
  );
};
