import { DataTargetType } from "@decentriq/graphql/dist/types";
import { z } from "zod";

export const DatasetExportDerivedStateSchema = z.object({
  manifestHash: z.string(),
  syncType: z.nativeEnum(DataTargetType),
  userId: z.string(),
});

export type DatasetExportDerivedState = z.infer<
  typeof DatasetExportDerivedStateSchema
>;
