/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const attestation = $root.attestation = (() => {

    /**
     * Namespace attestation.
     * @exports attestation
     * @namespace
     */
    const attestation = {};

    attestation.Fatquote = (function() {

        /**
         * Properties of a Fatquote.
         * @memberof attestation
         * @interface IFatquote
         * @property {attestation.IFatquoteEpid|null} [epid] Fatquote epid
         * @property {attestation.IFatquoteDcap|null} [dcap] Fatquote dcap
         * @property {attestation.IFatquoteNitro|null} [nitro] Fatquote nitro
         * @property {attestation.IFatquoteSnp|null} [snp] Fatquote snp
         * @property {attestation.IFatquoteDcapMrsigner|null} [dcapMrsigner] Fatquote dcapMrsigner
         */

        /**
         * Constructs a new Fatquote.
         * @memberof attestation
         * @classdesc Represents a Fatquote.
         * @implements IFatquote
         * @constructor
         * @param {attestation.IFatquote=} [properties] Properties to set
         */
        function Fatquote(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Fatquote epid.
         * @member {attestation.IFatquoteEpid|null|undefined} epid
         * @memberof attestation.Fatquote
         * @instance
         */
        Fatquote.prototype.epid = null;

        /**
         * Fatquote dcap.
         * @member {attestation.IFatquoteDcap|null|undefined} dcap
         * @memberof attestation.Fatquote
         * @instance
         */
        Fatquote.prototype.dcap = null;

        /**
         * Fatquote nitro.
         * @member {attestation.IFatquoteNitro|null|undefined} nitro
         * @memberof attestation.Fatquote
         * @instance
         */
        Fatquote.prototype.nitro = null;

        /**
         * Fatquote snp.
         * @member {attestation.IFatquoteSnp|null|undefined} snp
         * @memberof attestation.Fatquote
         * @instance
         */
        Fatquote.prototype.snp = null;

        /**
         * Fatquote dcapMrsigner.
         * @member {attestation.IFatquoteDcapMrsigner|null|undefined} dcapMrsigner
         * @memberof attestation.Fatquote
         * @instance
         */
        Fatquote.prototype.dcapMrsigner = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Fatquote fatquote.
         * @member {"epid"|"dcap"|"nitro"|"snp"|"dcapMrsigner"|undefined} fatquote
         * @memberof attestation.Fatquote
         * @instance
         */
        Object.defineProperty(Fatquote.prototype, "fatquote", {
            get: $util.oneOfGetter($oneOfFields = ["epid", "dcap", "nitro", "snp", "dcapMrsigner"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Fatquote instance using the specified properties.
         * @function create
         * @memberof attestation.Fatquote
         * @static
         * @param {attestation.IFatquote=} [properties] Properties to set
         * @returns {attestation.Fatquote} Fatquote instance
         */
        Fatquote.create = function create(properties) {
            return new Fatquote(properties);
        };

        /**
         * Encodes the specified Fatquote message. Does not implicitly {@link attestation.Fatquote.verify|verify} messages.
         * @function encode
         * @memberof attestation.Fatquote
         * @static
         * @param {attestation.IFatquote} message Fatquote message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Fatquote.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.epid != null && Object.hasOwnProperty.call(message, "epid"))
                $root.attestation.FatquoteEpid.encode(message.epid, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.dcap != null && Object.hasOwnProperty.call(message, "dcap"))
                $root.attestation.FatquoteDcap.encode(message.dcap, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.nitro != null && Object.hasOwnProperty.call(message, "nitro"))
                $root.attestation.FatquoteNitro.encode(message.nitro, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.snp != null && Object.hasOwnProperty.call(message, "snp"))
                $root.attestation.FatquoteSnp.encode(message.snp, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.dcapMrsigner != null && Object.hasOwnProperty.call(message, "dcapMrsigner"))
                $root.attestation.FatquoteDcapMrsigner.encode(message.dcapMrsigner, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Fatquote message, length delimited. Does not implicitly {@link attestation.Fatquote.verify|verify} messages.
         * @function encodeDelimited
         * @memberof attestation.Fatquote
         * @static
         * @param {attestation.IFatquote} message Fatquote message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Fatquote.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Fatquote message from the specified reader or buffer.
         * @function decode
         * @memberof attestation.Fatquote
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {attestation.Fatquote} Fatquote
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Fatquote.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.attestation.Fatquote();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.epid = $root.attestation.FatquoteEpid.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.dcap = $root.attestation.FatquoteDcap.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.nitro = $root.attestation.FatquoteNitro.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.snp = $root.attestation.FatquoteSnp.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.dcapMrsigner = $root.attestation.FatquoteDcapMrsigner.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Fatquote message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof attestation.Fatquote
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {attestation.Fatquote} Fatquote
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Fatquote.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Fatquote message.
         * @function verify
         * @memberof attestation.Fatquote
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Fatquote.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.epid != null && message.hasOwnProperty("epid")) {
                properties.fatquote = 1;
                {
                    let error = $root.attestation.FatquoteEpid.verify(message.epid);
                    if (error)
                        return "epid." + error;
                }
            }
            if (message.dcap != null && message.hasOwnProperty("dcap")) {
                if (properties.fatquote === 1)
                    return "fatquote: multiple values";
                properties.fatquote = 1;
                {
                    let error = $root.attestation.FatquoteDcap.verify(message.dcap);
                    if (error)
                        return "dcap." + error;
                }
            }
            if (message.nitro != null && message.hasOwnProperty("nitro")) {
                if (properties.fatquote === 1)
                    return "fatquote: multiple values";
                properties.fatquote = 1;
                {
                    let error = $root.attestation.FatquoteNitro.verify(message.nitro);
                    if (error)
                        return "nitro." + error;
                }
            }
            if (message.snp != null && message.hasOwnProperty("snp")) {
                if (properties.fatquote === 1)
                    return "fatquote: multiple values";
                properties.fatquote = 1;
                {
                    let error = $root.attestation.FatquoteSnp.verify(message.snp);
                    if (error)
                        return "snp." + error;
                }
            }
            if (message.dcapMrsigner != null && message.hasOwnProperty("dcapMrsigner")) {
                if (properties.fatquote === 1)
                    return "fatquote: multiple values";
                properties.fatquote = 1;
                {
                    let error = $root.attestation.FatquoteDcapMrsigner.verify(message.dcapMrsigner);
                    if (error)
                        return "dcapMrsigner." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Fatquote message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof attestation.Fatquote
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {attestation.Fatquote} Fatquote
         */
        Fatquote.fromObject = function fromObject(object) {
            if (object instanceof $root.attestation.Fatquote)
                return object;
            let message = new $root.attestation.Fatquote();
            if (object.epid != null) {
                if (typeof object.epid !== "object")
                    throw TypeError(".attestation.Fatquote.epid: object expected");
                message.epid = $root.attestation.FatquoteEpid.fromObject(object.epid);
            }
            if (object.dcap != null) {
                if (typeof object.dcap !== "object")
                    throw TypeError(".attestation.Fatquote.dcap: object expected");
                message.dcap = $root.attestation.FatquoteDcap.fromObject(object.dcap);
            }
            if (object.nitro != null) {
                if (typeof object.nitro !== "object")
                    throw TypeError(".attestation.Fatquote.nitro: object expected");
                message.nitro = $root.attestation.FatquoteNitro.fromObject(object.nitro);
            }
            if (object.snp != null) {
                if (typeof object.snp !== "object")
                    throw TypeError(".attestation.Fatquote.snp: object expected");
                message.snp = $root.attestation.FatquoteSnp.fromObject(object.snp);
            }
            if (object.dcapMrsigner != null) {
                if (typeof object.dcapMrsigner !== "object")
                    throw TypeError(".attestation.Fatquote.dcapMrsigner: object expected");
                message.dcapMrsigner = $root.attestation.FatquoteDcapMrsigner.fromObject(object.dcapMrsigner);
            }
            return message;
        };

        /**
         * Creates a plain object from a Fatquote message. Also converts values to other types if specified.
         * @function toObject
         * @memberof attestation.Fatquote
         * @static
         * @param {attestation.Fatquote} message Fatquote
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Fatquote.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.epid != null && message.hasOwnProperty("epid")) {
                object.epid = $root.attestation.FatquoteEpid.toObject(message.epid, options);
                if (options.oneofs)
                    object.fatquote = "epid";
            }
            if (message.dcap != null && message.hasOwnProperty("dcap")) {
                object.dcap = $root.attestation.FatquoteDcap.toObject(message.dcap, options);
                if (options.oneofs)
                    object.fatquote = "dcap";
            }
            if (message.nitro != null && message.hasOwnProperty("nitro")) {
                object.nitro = $root.attestation.FatquoteNitro.toObject(message.nitro, options);
                if (options.oneofs)
                    object.fatquote = "nitro";
            }
            if (message.snp != null && message.hasOwnProperty("snp")) {
                object.snp = $root.attestation.FatquoteSnp.toObject(message.snp, options);
                if (options.oneofs)
                    object.fatquote = "snp";
            }
            if (message.dcapMrsigner != null && message.hasOwnProperty("dcapMrsigner")) {
                object.dcapMrsigner = $root.attestation.FatquoteDcapMrsigner.toObject(message.dcapMrsigner, options);
                if (options.oneofs)
                    object.fatquote = "dcapMrsigner";
            }
            return object;
        };

        /**
         * Converts this Fatquote to JSON.
         * @function toJSON
         * @memberof attestation.Fatquote
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Fatquote.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Fatquote
         * @function getTypeUrl
         * @memberof attestation.Fatquote
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Fatquote.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/attestation.Fatquote";
        };

        return Fatquote;
    })();

    attestation.FatquoteEpid = (function() {

        /**
         * Properties of a FatquoteEpid.
         * @memberof attestation
         * @interface IFatquoteEpid
         * @property {Uint8Array|null} [iasResponseBody] FatquoteEpid iasResponseBody
         * @property {Uint8Array|null} [iasCertificate] FatquoteEpid iasCertificate
         * @property {Uint8Array|null} [iasSignature] FatquoteEpid iasSignature
         * @property {Uint8Array|null} [iasRootCaDer] FatquoteEpid iasRootCaDer
         */

        /**
         * Constructs a new FatquoteEpid.
         * @memberof attestation
         * @classdesc Represents a FatquoteEpid.
         * @implements IFatquoteEpid
         * @constructor
         * @param {attestation.IFatquoteEpid=} [properties] Properties to set
         */
        function FatquoteEpid(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FatquoteEpid iasResponseBody.
         * @member {Uint8Array} iasResponseBody
         * @memberof attestation.FatquoteEpid
         * @instance
         */
        FatquoteEpid.prototype.iasResponseBody = $util.newBuffer([]);

        /**
         * FatquoteEpid iasCertificate.
         * @member {Uint8Array} iasCertificate
         * @memberof attestation.FatquoteEpid
         * @instance
         */
        FatquoteEpid.prototype.iasCertificate = $util.newBuffer([]);

        /**
         * FatquoteEpid iasSignature.
         * @member {Uint8Array} iasSignature
         * @memberof attestation.FatquoteEpid
         * @instance
         */
        FatquoteEpid.prototype.iasSignature = $util.newBuffer([]);

        /**
         * FatquoteEpid iasRootCaDer.
         * @member {Uint8Array} iasRootCaDer
         * @memberof attestation.FatquoteEpid
         * @instance
         */
        FatquoteEpid.prototype.iasRootCaDer = $util.newBuffer([]);

        /**
         * Creates a new FatquoteEpid instance using the specified properties.
         * @function create
         * @memberof attestation.FatquoteEpid
         * @static
         * @param {attestation.IFatquoteEpid=} [properties] Properties to set
         * @returns {attestation.FatquoteEpid} FatquoteEpid instance
         */
        FatquoteEpid.create = function create(properties) {
            return new FatquoteEpid(properties);
        };

        /**
         * Encodes the specified FatquoteEpid message. Does not implicitly {@link attestation.FatquoteEpid.verify|verify} messages.
         * @function encode
         * @memberof attestation.FatquoteEpid
         * @static
         * @param {attestation.IFatquoteEpid} message FatquoteEpid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FatquoteEpid.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.iasResponseBody != null && Object.hasOwnProperty.call(message, "iasResponseBody"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.iasResponseBody);
            if (message.iasCertificate != null && Object.hasOwnProperty.call(message, "iasCertificate"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.iasCertificate);
            if (message.iasSignature != null && Object.hasOwnProperty.call(message, "iasSignature"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.iasSignature);
            if (message.iasRootCaDer != null && Object.hasOwnProperty.call(message, "iasRootCaDer"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.iasRootCaDer);
            return writer;
        };

        /**
         * Encodes the specified FatquoteEpid message, length delimited. Does not implicitly {@link attestation.FatquoteEpid.verify|verify} messages.
         * @function encodeDelimited
         * @memberof attestation.FatquoteEpid
         * @static
         * @param {attestation.IFatquoteEpid} message FatquoteEpid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FatquoteEpid.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FatquoteEpid message from the specified reader or buffer.
         * @function decode
         * @memberof attestation.FatquoteEpid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {attestation.FatquoteEpid} FatquoteEpid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FatquoteEpid.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.attestation.FatquoteEpid();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.iasResponseBody = reader.bytes();
                        break;
                    }
                case 2: {
                        message.iasCertificate = reader.bytes();
                        break;
                    }
                case 3: {
                        message.iasSignature = reader.bytes();
                        break;
                    }
                case 4: {
                        message.iasRootCaDer = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FatquoteEpid message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof attestation.FatquoteEpid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {attestation.FatquoteEpid} FatquoteEpid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FatquoteEpid.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FatquoteEpid message.
         * @function verify
         * @memberof attestation.FatquoteEpid
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FatquoteEpid.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.iasResponseBody != null && message.hasOwnProperty("iasResponseBody"))
                if (!(message.iasResponseBody && typeof message.iasResponseBody.length === "number" || $util.isString(message.iasResponseBody)))
                    return "iasResponseBody: buffer expected";
            if (message.iasCertificate != null && message.hasOwnProperty("iasCertificate"))
                if (!(message.iasCertificate && typeof message.iasCertificate.length === "number" || $util.isString(message.iasCertificate)))
                    return "iasCertificate: buffer expected";
            if (message.iasSignature != null && message.hasOwnProperty("iasSignature"))
                if (!(message.iasSignature && typeof message.iasSignature.length === "number" || $util.isString(message.iasSignature)))
                    return "iasSignature: buffer expected";
            if (message.iasRootCaDer != null && message.hasOwnProperty("iasRootCaDer"))
                if (!(message.iasRootCaDer && typeof message.iasRootCaDer.length === "number" || $util.isString(message.iasRootCaDer)))
                    return "iasRootCaDer: buffer expected";
            return null;
        };

        /**
         * Creates a FatquoteEpid message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof attestation.FatquoteEpid
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {attestation.FatquoteEpid} FatquoteEpid
         */
        FatquoteEpid.fromObject = function fromObject(object) {
            if (object instanceof $root.attestation.FatquoteEpid)
                return object;
            let message = new $root.attestation.FatquoteEpid();
            if (object.iasResponseBody != null)
                if (typeof object.iasResponseBody === "string")
                    $util.base64.decode(object.iasResponseBody, message.iasResponseBody = $util.newBuffer($util.base64.length(object.iasResponseBody)), 0);
                else if (object.iasResponseBody.length >= 0)
                    message.iasResponseBody = object.iasResponseBody;
            if (object.iasCertificate != null)
                if (typeof object.iasCertificate === "string")
                    $util.base64.decode(object.iasCertificate, message.iasCertificate = $util.newBuffer($util.base64.length(object.iasCertificate)), 0);
                else if (object.iasCertificate.length >= 0)
                    message.iasCertificate = object.iasCertificate;
            if (object.iasSignature != null)
                if (typeof object.iasSignature === "string")
                    $util.base64.decode(object.iasSignature, message.iasSignature = $util.newBuffer($util.base64.length(object.iasSignature)), 0);
                else if (object.iasSignature.length >= 0)
                    message.iasSignature = object.iasSignature;
            if (object.iasRootCaDer != null)
                if (typeof object.iasRootCaDer === "string")
                    $util.base64.decode(object.iasRootCaDer, message.iasRootCaDer = $util.newBuffer($util.base64.length(object.iasRootCaDer)), 0);
                else if (object.iasRootCaDer.length >= 0)
                    message.iasRootCaDer = object.iasRootCaDer;
            return message;
        };

        /**
         * Creates a plain object from a FatquoteEpid message. Also converts values to other types if specified.
         * @function toObject
         * @memberof attestation.FatquoteEpid
         * @static
         * @param {attestation.FatquoteEpid} message FatquoteEpid
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FatquoteEpid.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.iasResponseBody = "";
                else {
                    object.iasResponseBody = [];
                    if (options.bytes !== Array)
                        object.iasResponseBody = $util.newBuffer(object.iasResponseBody);
                }
                if (options.bytes === String)
                    object.iasCertificate = "";
                else {
                    object.iasCertificate = [];
                    if (options.bytes !== Array)
                        object.iasCertificate = $util.newBuffer(object.iasCertificate);
                }
                if (options.bytes === String)
                    object.iasSignature = "";
                else {
                    object.iasSignature = [];
                    if (options.bytes !== Array)
                        object.iasSignature = $util.newBuffer(object.iasSignature);
                }
                if (options.bytes === String)
                    object.iasRootCaDer = "";
                else {
                    object.iasRootCaDer = [];
                    if (options.bytes !== Array)
                        object.iasRootCaDer = $util.newBuffer(object.iasRootCaDer);
                }
            }
            if (message.iasResponseBody != null && message.hasOwnProperty("iasResponseBody"))
                object.iasResponseBody = options.bytes === String ? $util.base64.encode(message.iasResponseBody, 0, message.iasResponseBody.length) : options.bytes === Array ? Array.prototype.slice.call(message.iasResponseBody) : message.iasResponseBody;
            if (message.iasCertificate != null && message.hasOwnProperty("iasCertificate"))
                object.iasCertificate = options.bytes === String ? $util.base64.encode(message.iasCertificate, 0, message.iasCertificate.length) : options.bytes === Array ? Array.prototype.slice.call(message.iasCertificate) : message.iasCertificate;
            if (message.iasSignature != null && message.hasOwnProperty("iasSignature"))
                object.iasSignature = options.bytes === String ? $util.base64.encode(message.iasSignature, 0, message.iasSignature.length) : options.bytes === Array ? Array.prototype.slice.call(message.iasSignature) : message.iasSignature;
            if (message.iasRootCaDer != null && message.hasOwnProperty("iasRootCaDer"))
                object.iasRootCaDer = options.bytes === String ? $util.base64.encode(message.iasRootCaDer, 0, message.iasRootCaDer.length) : options.bytes === Array ? Array.prototype.slice.call(message.iasRootCaDer) : message.iasRootCaDer;
            return object;
        };

        /**
         * Converts this FatquoteEpid to JSON.
         * @function toJSON
         * @memberof attestation.FatquoteEpid
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FatquoteEpid.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FatquoteEpid
         * @function getTypeUrl
         * @memberof attestation.FatquoteEpid
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FatquoteEpid.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/attestation.FatquoteEpid";
        };

        return FatquoteEpid;
    })();

    attestation.FatquoteDcap = (function() {

        /**
         * Properties of a FatquoteDcap.
         * @memberof attestation
         * @interface IFatquoteDcap
         * @property {Uint8Array|null} [dcapQuote] FatquoteDcap dcapQuote
         * @property {Uint8Array|null} [tcbInfo] FatquoteDcap tcbInfo
         * @property {Uint8Array|null} [qeIdentity] FatquoteDcap qeIdentity
         * @property {Uint8Array|null} [tcbSignCert] FatquoteDcap tcbSignCert
         * @property {Uint8Array|null} [qeSignCert] FatquoteDcap qeSignCert
         * @property {Uint8Array|null} [dcapRootCaDer] FatquoteDcap dcapRootCaDer
         */

        /**
         * Constructs a new FatquoteDcap.
         * @memberof attestation
         * @classdesc Represents a FatquoteDcap.
         * @implements IFatquoteDcap
         * @constructor
         * @param {attestation.IFatquoteDcap=} [properties] Properties to set
         */
        function FatquoteDcap(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FatquoteDcap dcapQuote.
         * @member {Uint8Array} dcapQuote
         * @memberof attestation.FatquoteDcap
         * @instance
         */
        FatquoteDcap.prototype.dcapQuote = $util.newBuffer([]);

        /**
         * FatquoteDcap tcbInfo.
         * @member {Uint8Array} tcbInfo
         * @memberof attestation.FatquoteDcap
         * @instance
         */
        FatquoteDcap.prototype.tcbInfo = $util.newBuffer([]);

        /**
         * FatquoteDcap qeIdentity.
         * @member {Uint8Array} qeIdentity
         * @memberof attestation.FatquoteDcap
         * @instance
         */
        FatquoteDcap.prototype.qeIdentity = $util.newBuffer([]);

        /**
         * FatquoteDcap tcbSignCert.
         * @member {Uint8Array} tcbSignCert
         * @memberof attestation.FatquoteDcap
         * @instance
         */
        FatquoteDcap.prototype.tcbSignCert = $util.newBuffer([]);

        /**
         * FatquoteDcap qeSignCert.
         * @member {Uint8Array} qeSignCert
         * @memberof attestation.FatquoteDcap
         * @instance
         */
        FatquoteDcap.prototype.qeSignCert = $util.newBuffer([]);

        /**
         * FatquoteDcap dcapRootCaDer.
         * @member {Uint8Array} dcapRootCaDer
         * @memberof attestation.FatquoteDcap
         * @instance
         */
        FatquoteDcap.prototype.dcapRootCaDer = $util.newBuffer([]);

        /**
         * Creates a new FatquoteDcap instance using the specified properties.
         * @function create
         * @memberof attestation.FatquoteDcap
         * @static
         * @param {attestation.IFatquoteDcap=} [properties] Properties to set
         * @returns {attestation.FatquoteDcap} FatquoteDcap instance
         */
        FatquoteDcap.create = function create(properties) {
            return new FatquoteDcap(properties);
        };

        /**
         * Encodes the specified FatquoteDcap message. Does not implicitly {@link attestation.FatquoteDcap.verify|verify} messages.
         * @function encode
         * @memberof attestation.FatquoteDcap
         * @static
         * @param {attestation.IFatquoteDcap} message FatquoteDcap message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FatquoteDcap.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dcapQuote != null && Object.hasOwnProperty.call(message, "dcapQuote"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dcapQuote);
            if (message.tcbInfo != null && Object.hasOwnProperty.call(message, "tcbInfo"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.tcbInfo);
            if (message.qeIdentity != null && Object.hasOwnProperty.call(message, "qeIdentity"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.qeIdentity);
            if (message.tcbSignCert != null && Object.hasOwnProperty.call(message, "tcbSignCert"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.tcbSignCert);
            if (message.qeSignCert != null && Object.hasOwnProperty.call(message, "qeSignCert"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.qeSignCert);
            if (message.dcapRootCaDer != null && Object.hasOwnProperty.call(message, "dcapRootCaDer"))
                writer.uint32(/* id 6, wireType 2 =*/50).bytes(message.dcapRootCaDer);
            return writer;
        };

        /**
         * Encodes the specified FatquoteDcap message, length delimited. Does not implicitly {@link attestation.FatquoteDcap.verify|verify} messages.
         * @function encodeDelimited
         * @memberof attestation.FatquoteDcap
         * @static
         * @param {attestation.IFatquoteDcap} message FatquoteDcap message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FatquoteDcap.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FatquoteDcap message from the specified reader or buffer.
         * @function decode
         * @memberof attestation.FatquoteDcap
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {attestation.FatquoteDcap} FatquoteDcap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FatquoteDcap.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.attestation.FatquoteDcap();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dcapQuote = reader.bytes();
                        break;
                    }
                case 2: {
                        message.tcbInfo = reader.bytes();
                        break;
                    }
                case 3: {
                        message.qeIdentity = reader.bytes();
                        break;
                    }
                case 4: {
                        message.tcbSignCert = reader.bytes();
                        break;
                    }
                case 5: {
                        message.qeSignCert = reader.bytes();
                        break;
                    }
                case 6: {
                        message.dcapRootCaDer = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FatquoteDcap message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof attestation.FatquoteDcap
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {attestation.FatquoteDcap} FatquoteDcap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FatquoteDcap.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FatquoteDcap message.
         * @function verify
         * @memberof attestation.FatquoteDcap
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FatquoteDcap.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dcapQuote != null && message.hasOwnProperty("dcapQuote"))
                if (!(message.dcapQuote && typeof message.dcapQuote.length === "number" || $util.isString(message.dcapQuote)))
                    return "dcapQuote: buffer expected";
            if (message.tcbInfo != null && message.hasOwnProperty("tcbInfo"))
                if (!(message.tcbInfo && typeof message.tcbInfo.length === "number" || $util.isString(message.tcbInfo)))
                    return "tcbInfo: buffer expected";
            if (message.qeIdentity != null && message.hasOwnProperty("qeIdentity"))
                if (!(message.qeIdentity && typeof message.qeIdentity.length === "number" || $util.isString(message.qeIdentity)))
                    return "qeIdentity: buffer expected";
            if (message.tcbSignCert != null && message.hasOwnProperty("tcbSignCert"))
                if (!(message.tcbSignCert && typeof message.tcbSignCert.length === "number" || $util.isString(message.tcbSignCert)))
                    return "tcbSignCert: buffer expected";
            if (message.qeSignCert != null && message.hasOwnProperty("qeSignCert"))
                if (!(message.qeSignCert && typeof message.qeSignCert.length === "number" || $util.isString(message.qeSignCert)))
                    return "qeSignCert: buffer expected";
            if (message.dcapRootCaDer != null && message.hasOwnProperty("dcapRootCaDer"))
                if (!(message.dcapRootCaDer && typeof message.dcapRootCaDer.length === "number" || $util.isString(message.dcapRootCaDer)))
                    return "dcapRootCaDer: buffer expected";
            return null;
        };

        /**
         * Creates a FatquoteDcap message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof attestation.FatquoteDcap
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {attestation.FatquoteDcap} FatquoteDcap
         */
        FatquoteDcap.fromObject = function fromObject(object) {
            if (object instanceof $root.attestation.FatquoteDcap)
                return object;
            let message = new $root.attestation.FatquoteDcap();
            if (object.dcapQuote != null)
                if (typeof object.dcapQuote === "string")
                    $util.base64.decode(object.dcapQuote, message.dcapQuote = $util.newBuffer($util.base64.length(object.dcapQuote)), 0);
                else if (object.dcapQuote.length >= 0)
                    message.dcapQuote = object.dcapQuote;
            if (object.tcbInfo != null)
                if (typeof object.tcbInfo === "string")
                    $util.base64.decode(object.tcbInfo, message.tcbInfo = $util.newBuffer($util.base64.length(object.tcbInfo)), 0);
                else if (object.tcbInfo.length >= 0)
                    message.tcbInfo = object.tcbInfo;
            if (object.qeIdentity != null)
                if (typeof object.qeIdentity === "string")
                    $util.base64.decode(object.qeIdentity, message.qeIdentity = $util.newBuffer($util.base64.length(object.qeIdentity)), 0);
                else if (object.qeIdentity.length >= 0)
                    message.qeIdentity = object.qeIdentity;
            if (object.tcbSignCert != null)
                if (typeof object.tcbSignCert === "string")
                    $util.base64.decode(object.tcbSignCert, message.tcbSignCert = $util.newBuffer($util.base64.length(object.tcbSignCert)), 0);
                else if (object.tcbSignCert.length >= 0)
                    message.tcbSignCert = object.tcbSignCert;
            if (object.qeSignCert != null)
                if (typeof object.qeSignCert === "string")
                    $util.base64.decode(object.qeSignCert, message.qeSignCert = $util.newBuffer($util.base64.length(object.qeSignCert)), 0);
                else if (object.qeSignCert.length >= 0)
                    message.qeSignCert = object.qeSignCert;
            if (object.dcapRootCaDer != null)
                if (typeof object.dcapRootCaDer === "string")
                    $util.base64.decode(object.dcapRootCaDer, message.dcapRootCaDer = $util.newBuffer($util.base64.length(object.dcapRootCaDer)), 0);
                else if (object.dcapRootCaDer.length >= 0)
                    message.dcapRootCaDer = object.dcapRootCaDer;
            return message;
        };

        /**
         * Creates a plain object from a FatquoteDcap message. Also converts values to other types if specified.
         * @function toObject
         * @memberof attestation.FatquoteDcap
         * @static
         * @param {attestation.FatquoteDcap} message FatquoteDcap
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FatquoteDcap.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.dcapQuote = "";
                else {
                    object.dcapQuote = [];
                    if (options.bytes !== Array)
                        object.dcapQuote = $util.newBuffer(object.dcapQuote);
                }
                if (options.bytes === String)
                    object.tcbInfo = "";
                else {
                    object.tcbInfo = [];
                    if (options.bytes !== Array)
                        object.tcbInfo = $util.newBuffer(object.tcbInfo);
                }
                if (options.bytes === String)
                    object.qeIdentity = "";
                else {
                    object.qeIdentity = [];
                    if (options.bytes !== Array)
                        object.qeIdentity = $util.newBuffer(object.qeIdentity);
                }
                if (options.bytes === String)
                    object.tcbSignCert = "";
                else {
                    object.tcbSignCert = [];
                    if (options.bytes !== Array)
                        object.tcbSignCert = $util.newBuffer(object.tcbSignCert);
                }
                if (options.bytes === String)
                    object.qeSignCert = "";
                else {
                    object.qeSignCert = [];
                    if (options.bytes !== Array)
                        object.qeSignCert = $util.newBuffer(object.qeSignCert);
                }
                if (options.bytes === String)
                    object.dcapRootCaDer = "";
                else {
                    object.dcapRootCaDer = [];
                    if (options.bytes !== Array)
                        object.dcapRootCaDer = $util.newBuffer(object.dcapRootCaDer);
                }
            }
            if (message.dcapQuote != null && message.hasOwnProperty("dcapQuote"))
                object.dcapQuote = options.bytes === String ? $util.base64.encode(message.dcapQuote, 0, message.dcapQuote.length) : options.bytes === Array ? Array.prototype.slice.call(message.dcapQuote) : message.dcapQuote;
            if (message.tcbInfo != null && message.hasOwnProperty("tcbInfo"))
                object.tcbInfo = options.bytes === String ? $util.base64.encode(message.tcbInfo, 0, message.tcbInfo.length) : options.bytes === Array ? Array.prototype.slice.call(message.tcbInfo) : message.tcbInfo;
            if (message.qeIdentity != null && message.hasOwnProperty("qeIdentity"))
                object.qeIdentity = options.bytes === String ? $util.base64.encode(message.qeIdentity, 0, message.qeIdentity.length) : options.bytes === Array ? Array.prototype.slice.call(message.qeIdentity) : message.qeIdentity;
            if (message.tcbSignCert != null && message.hasOwnProperty("tcbSignCert"))
                object.tcbSignCert = options.bytes === String ? $util.base64.encode(message.tcbSignCert, 0, message.tcbSignCert.length) : options.bytes === Array ? Array.prototype.slice.call(message.tcbSignCert) : message.tcbSignCert;
            if (message.qeSignCert != null && message.hasOwnProperty("qeSignCert"))
                object.qeSignCert = options.bytes === String ? $util.base64.encode(message.qeSignCert, 0, message.qeSignCert.length) : options.bytes === Array ? Array.prototype.slice.call(message.qeSignCert) : message.qeSignCert;
            if (message.dcapRootCaDer != null && message.hasOwnProperty("dcapRootCaDer"))
                object.dcapRootCaDer = options.bytes === String ? $util.base64.encode(message.dcapRootCaDer, 0, message.dcapRootCaDer.length) : options.bytes === Array ? Array.prototype.slice.call(message.dcapRootCaDer) : message.dcapRootCaDer;
            return object;
        };

        /**
         * Converts this FatquoteDcap to JSON.
         * @function toJSON
         * @memberof attestation.FatquoteDcap
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FatquoteDcap.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FatquoteDcap
         * @function getTypeUrl
         * @memberof attestation.FatquoteDcap
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FatquoteDcap.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/attestation.FatquoteDcap";
        };

        return FatquoteDcap;
    })();

    attestation.FatquoteDcapMrsigner = (function() {

        /**
         * Properties of a FatquoteDcapMrsigner.
         * @memberof attestation
         * @interface IFatquoteDcapMrsigner
         * @property {Uint8Array|null} [dcapQuote] FatquoteDcapMrsigner dcapQuote
         * @property {Uint8Array|null} [tcbInfo] FatquoteDcapMrsigner tcbInfo
         * @property {Uint8Array|null} [qeIdentity] FatquoteDcapMrsigner qeIdentity
         * @property {Uint8Array|null} [tcbSignCert] FatquoteDcapMrsigner tcbSignCert
         * @property {Uint8Array|null} [qeSignCert] FatquoteDcapMrsigner qeSignCert
         * @property {Uint8Array|null} [dcapRootCaDer] FatquoteDcapMrsigner dcapRootCaDer
         * @property {attestation.ISigstoreEvidence|null} [sigstoreEvidence] FatquoteDcapMrsigner sigstoreEvidence
         */

        /**
         * Constructs a new FatquoteDcapMrsigner.
         * @memberof attestation
         * @classdesc Represents a FatquoteDcapMrsigner.
         * @implements IFatquoteDcapMrsigner
         * @constructor
         * @param {attestation.IFatquoteDcapMrsigner=} [properties] Properties to set
         */
        function FatquoteDcapMrsigner(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FatquoteDcapMrsigner dcapQuote.
         * @member {Uint8Array} dcapQuote
         * @memberof attestation.FatquoteDcapMrsigner
         * @instance
         */
        FatquoteDcapMrsigner.prototype.dcapQuote = $util.newBuffer([]);

        /**
         * FatquoteDcapMrsigner tcbInfo.
         * @member {Uint8Array} tcbInfo
         * @memberof attestation.FatquoteDcapMrsigner
         * @instance
         */
        FatquoteDcapMrsigner.prototype.tcbInfo = $util.newBuffer([]);

        /**
         * FatquoteDcapMrsigner qeIdentity.
         * @member {Uint8Array} qeIdentity
         * @memberof attestation.FatquoteDcapMrsigner
         * @instance
         */
        FatquoteDcapMrsigner.prototype.qeIdentity = $util.newBuffer([]);

        /**
         * FatquoteDcapMrsigner tcbSignCert.
         * @member {Uint8Array} tcbSignCert
         * @memberof attestation.FatquoteDcapMrsigner
         * @instance
         */
        FatquoteDcapMrsigner.prototype.tcbSignCert = $util.newBuffer([]);

        /**
         * FatquoteDcapMrsigner qeSignCert.
         * @member {Uint8Array} qeSignCert
         * @memberof attestation.FatquoteDcapMrsigner
         * @instance
         */
        FatquoteDcapMrsigner.prototype.qeSignCert = $util.newBuffer([]);

        /**
         * FatquoteDcapMrsigner dcapRootCaDer.
         * @member {Uint8Array} dcapRootCaDer
         * @memberof attestation.FatquoteDcapMrsigner
         * @instance
         */
        FatquoteDcapMrsigner.prototype.dcapRootCaDer = $util.newBuffer([]);

        /**
         * FatquoteDcapMrsigner sigstoreEvidence.
         * @member {attestation.ISigstoreEvidence|null|undefined} sigstoreEvidence
         * @memberof attestation.FatquoteDcapMrsigner
         * @instance
         */
        FatquoteDcapMrsigner.prototype.sigstoreEvidence = null;

        /**
         * Creates a new FatquoteDcapMrsigner instance using the specified properties.
         * @function create
         * @memberof attestation.FatquoteDcapMrsigner
         * @static
         * @param {attestation.IFatquoteDcapMrsigner=} [properties] Properties to set
         * @returns {attestation.FatquoteDcapMrsigner} FatquoteDcapMrsigner instance
         */
        FatquoteDcapMrsigner.create = function create(properties) {
            return new FatquoteDcapMrsigner(properties);
        };

        /**
         * Encodes the specified FatquoteDcapMrsigner message. Does not implicitly {@link attestation.FatquoteDcapMrsigner.verify|verify} messages.
         * @function encode
         * @memberof attestation.FatquoteDcapMrsigner
         * @static
         * @param {attestation.IFatquoteDcapMrsigner} message FatquoteDcapMrsigner message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FatquoteDcapMrsigner.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dcapQuote != null && Object.hasOwnProperty.call(message, "dcapQuote"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dcapQuote);
            if (message.tcbInfo != null && Object.hasOwnProperty.call(message, "tcbInfo"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.tcbInfo);
            if (message.qeIdentity != null && Object.hasOwnProperty.call(message, "qeIdentity"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.qeIdentity);
            if (message.tcbSignCert != null && Object.hasOwnProperty.call(message, "tcbSignCert"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.tcbSignCert);
            if (message.qeSignCert != null && Object.hasOwnProperty.call(message, "qeSignCert"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.qeSignCert);
            if (message.dcapRootCaDer != null && Object.hasOwnProperty.call(message, "dcapRootCaDer"))
                writer.uint32(/* id 6, wireType 2 =*/50).bytes(message.dcapRootCaDer);
            if (message.sigstoreEvidence != null && Object.hasOwnProperty.call(message, "sigstoreEvidence"))
                $root.attestation.SigstoreEvidence.encode(message.sigstoreEvidence, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified FatquoteDcapMrsigner message, length delimited. Does not implicitly {@link attestation.FatquoteDcapMrsigner.verify|verify} messages.
         * @function encodeDelimited
         * @memberof attestation.FatquoteDcapMrsigner
         * @static
         * @param {attestation.IFatquoteDcapMrsigner} message FatquoteDcapMrsigner message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FatquoteDcapMrsigner.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FatquoteDcapMrsigner message from the specified reader or buffer.
         * @function decode
         * @memberof attestation.FatquoteDcapMrsigner
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {attestation.FatquoteDcapMrsigner} FatquoteDcapMrsigner
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FatquoteDcapMrsigner.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.attestation.FatquoteDcapMrsigner();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dcapQuote = reader.bytes();
                        break;
                    }
                case 2: {
                        message.tcbInfo = reader.bytes();
                        break;
                    }
                case 3: {
                        message.qeIdentity = reader.bytes();
                        break;
                    }
                case 4: {
                        message.tcbSignCert = reader.bytes();
                        break;
                    }
                case 5: {
                        message.qeSignCert = reader.bytes();
                        break;
                    }
                case 6: {
                        message.dcapRootCaDer = reader.bytes();
                        break;
                    }
                case 7: {
                        message.sigstoreEvidence = $root.attestation.SigstoreEvidence.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FatquoteDcapMrsigner message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof attestation.FatquoteDcapMrsigner
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {attestation.FatquoteDcapMrsigner} FatquoteDcapMrsigner
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FatquoteDcapMrsigner.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FatquoteDcapMrsigner message.
         * @function verify
         * @memberof attestation.FatquoteDcapMrsigner
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FatquoteDcapMrsigner.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dcapQuote != null && message.hasOwnProperty("dcapQuote"))
                if (!(message.dcapQuote && typeof message.dcapQuote.length === "number" || $util.isString(message.dcapQuote)))
                    return "dcapQuote: buffer expected";
            if (message.tcbInfo != null && message.hasOwnProperty("tcbInfo"))
                if (!(message.tcbInfo && typeof message.tcbInfo.length === "number" || $util.isString(message.tcbInfo)))
                    return "tcbInfo: buffer expected";
            if (message.qeIdentity != null && message.hasOwnProperty("qeIdentity"))
                if (!(message.qeIdentity && typeof message.qeIdentity.length === "number" || $util.isString(message.qeIdentity)))
                    return "qeIdentity: buffer expected";
            if (message.tcbSignCert != null && message.hasOwnProperty("tcbSignCert"))
                if (!(message.tcbSignCert && typeof message.tcbSignCert.length === "number" || $util.isString(message.tcbSignCert)))
                    return "tcbSignCert: buffer expected";
            if (message.qeSignCert != null && message.hasOwnProperty("qeSignCert"))
                if (!(message.qeSignCert && typeof message.qeSignCert.length === "number" || $util.isString(message.qeSignCert)))
                    return "qeSignCert: buffer expected";
            if (message.dcapRootCaDer != null && message.hasOwnProperty("dcapRootCaDer"))
                if (!(message.dcapRootCaDer && typeof message.dcapRootCaDer.length === "number" || $util.isString(message.dcapRootCaDer)))
                    return "dcapRootCaDer: buffer expected";
            if (message.sigstoreEvidence != null && message.hasOwnProperty("sigstoreEvidence")) {
                let error = $root.attestation.SigstoreEvidence.verify(message.sigstoreEvidence);
                if (error)
                    return "sigstoreEvidence." + error;
            }
            return null;
        };

        /**
         * Creates a FatquoteDcapMrsigner message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof attestation.FatquoteDcapMrsigner
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {attestation.FatquoteDcapMrsigner} FatquoteDcapMrsigner
         */
        FatquoteDcapMrsigner.fromObject = function fromObject(object) {
            if (object instanceof $root.attestation.FatquoteDcapMrsigner)
                return object;
            let message = new $root.attestation.FatquoteDcapMrsigner();
            if (object.dcapQuote != null)
                if (typeof object.dcapQuote === "string")
                    $util.base64.decode(object.dcapQuote, message.dcapQuote = $util.newBuffer($util.base64.length(object.dcapQuote)), 0);
                else if (object.dcapQuote.length >= 0)
                    message.dcapQuote = object.dcapQuote;
            if (object.tcbInfo != null)
                if (typeof object.tcbInfo === "string")
                    $util.base64.decode(object.tcbInfo, message.tcbInfo = $util.newBuffer($util.base64.length(object.tcbInfo)), 0);
                else if (object.tcbInfo.length >= 0)
                    message.tcbInfo = object.tcbInfo;
            if (object.qeIdentity != null)
                if (typeof object.qeIdentity === "string")
                    $util.base64.decode(object.qeIdentity, message.qeIdentity = $util.newBuffer($util.base64.length(object.qeIdentity)), 0);
                else if (object.qeIdentity.length >= 0)
                    message.qeIdentity = object.qeIdentity;
            if (object.tcbSignCert != null)
                if (typeof object.tcbSignCert === "string")
                    $util.base64.decode(object.tcbSignCert, message.tcbSignCert = $util.newBuffer($util.base64.length(object.tcbSignCert)), 0);
                else if (object.tcbSignCert.length >= 0)
                    message.tcbSignCert = object.tcbSignCert;
            if (object.qeSignCert != null)
                if (typeof object.qeSignCert === "string")
                    $util.base64.decode(object.qeSignCert, message.qeSignCert = $util.newBuffer($util.base64.length(object.qeSignCert)), 0);
                else if (object.qeSignCert.length >= 0)
                    message.qeSignCert = object.qeSignCert;
            if (object.dcapRootCaDer != null)
                if (typeof object.dcapRootCaDer === "string")
                    $util.base64.decode(object.dcapRootCaDer, message.dcapRootCaDer = $util.newBuffer($util.base64.length(object.dcapRootCaDer)), 0);
                else if (object.dcapRootCaDer.length >= 0)
                    message.dcapRootCaDer = object.dcapRootCaDer;
            if (object.sigstoreEvidence != null) {
                if (typeof object.sigstoreEvidence !== "object")
                    throw TypeError(".attestation.FatquoteDcapMrsigner.sigstoreEvidence: object expected");
                message.sigstoreEvidence = $root.attestation.SigstoreEvidence.fromObject(object.sigstoreEvidence);
            }
            return message;
        };

        /**
         * Creates a plain object from a FatquoteDcapMrsigner message. Also converts values to other types if specified.
         * @function toObject
         * @memberof attestation.FatquoteDcapMrsigner
         * @static
         * @param {attestation.FatquoteDcapMrsigner} message FatquoteDcapMrsigner
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FatquoteDcapMrsigner.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.dcapQuote = "";
                else {
                    object.dcapQuote = [];
                    if (options.bytes !== Array)
                        object.dcapQuote = $util.newBuffer(object.dcapQuote);
                }
                if (options.bytes === String)
                    object.tcbInfo = "";
                else {
                    object.tcbInfo = [];
                    if (options.bytes !== Array)
                        object.tcbInfo = $util.newBuffer(object.tcbInfo);
                }
                if (options.bytes === String)
                    object.qeIdentity = "";
                else {
                    object.qeIdentity = [];
                    if (options.bytes !== Array)
                        object.qeIdentity = $util.newBuffer(object.qeIdentity);
                }
                if (options.bytes === String)
                    object.tcbSignCert = "";
                else {
                    object.tcbSignCert = [];
                    if (options.bytes !== Array)
                        object.tcbSignCert = $util.newBuffer(object.tcbSignCert);
                }
                if (options.bytes === String)
                    object.qeSignCert = "";
                else {
                    object.qeSignCert = [];
                    if (options.bytes !== Array)
                        object.qeSignCert = $util.newBuffer(object.qeSignCert);
                }
                if (options.bytes === String)
                    object.dcapRootCaDer = "";
                else {
                    object.dcapRootCaDer = [];
                    if (options.bytes !== Array)
                        object.dcapRootCaDer = $util.newBuffer(object.dcapRootCaDer);
                }
                object.sigstoreEvidence = null;
            }
            if (message.dcapQuote != null && message.hasOwnProperty("dcapQuote"))
                object.dcapQuote = options.bytes === String ? $util.base64.encode(message.dcapQuote, 0, message.dcapQuote.length) : options.bytes === Array ? Array.prototype.slice.call(message.dcapQuote) : message.dcapQuote;
            if (message.tcbInfo != null && message.hasOwnProperty("tcbInfo"))
                object.tcbInfo = options.bytes === String ? $util.base64.encode(message.tcbInfo, 0, message.tcbInfo.length) : options.bytes === Array ? Array.prototype.slice.call(message.tcbInfo) : message.tcbInfo;
            if (message.qeIdentity != null && message.hasOwnProperty("qeIdentity"))
                object.qeIdentity = options.bytes === String ? $util.base64.encode(message.qeIdentity, 0, message.qeIdentity.length) : options.bytes === Array ? Array.prototype.slice.call(message.qeIdentity) : message.qeIdentity;
            if (message.tcbSignCert != null && message.hasOwnProperty("tcbSignCert"))
                object.tcbSignCert = options.bytes === String ? $util.base64.encode(message.tcbSignCert, 0, message.tcbSignCert.length) : options.bytes === Array ? Array.prototype.slice.call(message.tcbSignCert) : message.tcbSignCert;
            if (message.qeSignCert != null && message.hasOwnProperty("qeSignCert"))
                object.qeSignCert = options.bytes === String ? $util.base64.encode(message.qeSignCert, 0, message.qeSignCert.length) : options.bytes === Array ? Array.prototype.slice.call(message.qeSignCert) : message.qeSignCert;
            if (message.dcapRootCaDer != null && message.hasOwnProperty("dcapRootCaDer"))
                object.dcapRootCaDer = options.bytes === String ? $util.base64.encode(message.dcapRootCaDer, 0, message.dcapRootCaDer.length) : options.bytes === Array ? Array.prototype.slice.call(message.dcapRootCaDer) : message.dcapRootCaDer;
            if (message.sigstoreEvidence != null && message.hasOwnProperty("sigstoreEvidence"))
                object.sigstoreEvidence = $root.attestation.SigstoreEvidence.toObject(message.sigstoreEvidence, options);
            return object;
        };

        /**
         * Converts this FatquoteDcapMrsigner to JSON.
         * @function toJSON
         * @memberof attestation.FatquoteDcapMrsigner
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FatquoteDcapMrsigner.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FatquoteDcapMrsigner
         * @function getTypeUrl
         * @memberof attestation.FatquoteDcapMrsigner
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FatquoteDcapMrsigner.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/attestation.FatquoteDcapMrsigner";
        };

        return FatquoteDcapMrsigner;
    })();

    attestation.SigstoreFile = (function() {

        /**
         * Properties of a SigstoreFile.
         * @memberof attestation
         * @interface ISigstoreFile
         * @property {string|null} [name] SigstoreFile name
         * @property {Uint8Array|null} [contents] SigstoreFile contents
         */

        /**
         * Constructs a new SigstoreFile.
         * @memberof attestation
         * @classdesc Represents a SigstoreFile.
         * @implements ISigstoreFile
         * @constructor
         * @param {attestation.ISigstoreFile=} [properties] Properties to set
         */
        function SigstoreFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SigstoreFile name.
         * @member {string} name
         * @memberof attestation.SigstoreFile
         * @instance
         */
        SigstoreFile.prototype.name = "";

        /**
         * SigstoreFile contents.
         * @member {Uint8Array} contents
         * @memberof attestation.SigstoreFile
         * @instance
         */
        SigstoreFile.prototype.contents = $util.newBuffer([]);

        /**
         * Creates a new SigstoreFile instance using the specified properties.
         * @function create
         * @memberof attestation.SigstoreFile
         * @static
         * @param {attestation.ISigstoreFile=} [properties] Properties to set
         * @returns {attestation.SigstoreFile} SigstoreFile instance
         */
        SigstoreFile.create = function create(properties) {
            return new SigstoreFile(properties);
        };

        /**
         * Encodes the specified SigstoreFile message. Does not implicitly {@link attestation.SigstoreFile.verify|verify} messages.
         * @function encode
         * @memberof attestation.SigstoreFile
         * @static
         * @param {attestation.ISigstoreFile} message SigstoreFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SigstoreFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.contents != null && Object.hasOwnProperty.call(message, "contents"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.contents);
            return writer;
        };

        /**
         * Encodes the specified SigstoreFile message, length delimited. Does not implicitly {@link attestation.SigstoreFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof attestation.SigstoreFile
         * @static
         * @param {attestation.ISigstoreFile} message SigstoreFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SigstoreFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SigstoreFile message from the specified reader or buffer.
         * @function decode
         * @memberof attestation.SigstoreFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {attestation.SigstoreFile} SigstoreFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SigstoreFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.attestation.SigstoreFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.contents = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SigstoreFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof attestation.SigstoreFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {attestation.SigstoreFile} SigstoreFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SigstoreFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SigstoreFile message.
         * @function verify
         * @memberof attestation.SigstoreFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SigstoreFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.contents != null && message.hasOwnProperty("contents"))
                if (!(message.contents && typeof message.contents.length === "number" || $util.isString(message.contents)))
                    return "contents: buffer expected";
            return null;
        };

        /**
         * Creates a SigstoreFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof attestation.SigstoreFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {attestation.SigstoreFile} SigstoreFile
         */
        SigstoreFile.fromObject = function fromObject(object) {
            if (object instanceof $root.attestation.SigstoreFile)
                return object;
            let message = new $root.attestation.SigstoreFile();
            if (object.name != null)
                message.name = String(object.name);
            if (object.contents != null)
                if (typeof object.contents === "string")
                    $util.base64.decode(object.contents, message.contents = $util.newBuffer($util.base64.length(object.contents)), 0);
                else if (object.contents.length >= 0)
                    message.contents = object.contents;
            return message;
        };

        /**
         * Creates a plain object from a SigstoreFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof attestation.SigstoreFile
         * @static
         * @param {attestation.SigstoreFile} message SigstoreFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SigstoreFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                if (options.bytes === String)
                    object.contents = "";
                else {
                    object.contents = [];
                    if (options.bytes !== Array)
                        object.contents = $util.newBuffer(object.contents);
                }
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.contents != null && message.hasOwnProperty("contents"))
                object.contents = options.bytes === String ? $util.base64.encode(message.contents, 0, message.contents.length) : options.bytes === Array ? Array.prototype.slice.call(message.contents) : message.contents;
            return object;
        };

        /**
         * Converts this SigstoreFile to JSON.
         * @function toJSON
         * @memberof attestation.SigstoreFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SigstoreFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SigstoreFile
         * @function getTypeUrl
         * @memberof attestation.SigstoreFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SigstoreFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/attestation.SigstoreFile";
        };

        return SigstoreFile;
    })();

    attestation.SigstoreEvidence = (function() {

        /**
         * Properties of a SigstoreEvidence.
         * @memberof attestation
         * @interface ISigstoreEvidence
         * @property {attestation.ISigstoreEvidenceV1|null} [v1] SigstoreEvidence v1
         */

        /**
         * Constructs a new SigstoreEvidence.
         * @memberof attestation
         * @classdesc Represents a SigstoreEvidence.
         * @implements ISigstoreEvidence
         * @constructor
         * @param {attestation.ISigstoreEvidence=} [properties] Properties to set
         */
        function SigstoreEvidence(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SigstoreEvidence v1.
         * @member {attestation.ISigstoreEvidenceV1|null|undefined} v1
         * @memberof attestation.SigstoreEvidence
         * @instance
         */
        SigstoreEvidence.prototype.v1 = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SigstoreEvidence sigstoreEvidence.
         * @member {"v1"|undefined} sigstoreEvidence
         * @memberof attestation.SigstoreEvidence
         * @instance
         */
        Object.defineProperty(SigstoreEvidence.prototype, "sigstoreEvidence", {
            get: $util.oneOfGetter($oneOfFields = ["v1"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SigstoreEvidence instance using the specified properties.
         * @function create
         * @memberof attestation.SigstoreEvidence
         * @static
         * @param {attestation.ISigstoreEvidence=} [properties] Properties to set
         * @returns {attestation.SigstoreEvidence} SigstoreEvidence instance
         */
        SigstoreEvidence.create = function create(properties) {
            return new SigstoreEvidence(properties);
        };

        /**
         * Encodes the specified SigstoreEvidence message. Does not implicitly {@link attestation.SigstoreEvidence.verify|verify} messages.
         * @function encode
         * @memberof attestation.SigstoreEvidence
         * @static
         * @param {attestation.ISigstoreEvidence} message SigstoreEvidence message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SigstoreEvidence.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.v1 != null && Object.hasOwnProperty.call(message, "v1"))
                $root.attestation.SigstoreEvidenceV1.encode(message.v1, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SigstoreEvidence message, length delimited. Does not implicitly {@link attestation.SigstoreEvidence.verify|verify} messages.
         * @function encodeDelimited
         * @memberof attestation.SigstoreEvidence
         * @static
         * @param {attestation.ISigstoreEvidence} message SigstoreEvidence message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SigstoreEvidence.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SigstoreEvidence message from the specified reader or buffer.
         * @function decode
         * @memberof attestation.SigstoreEvidence
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {attestation.SigstoreEvidence} SigstoreEvidence
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SigstoreEvidence.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.attestation.SigstoreEvidence();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.v1 = $root.attestation.SigstoreEvidenceV1.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SigstoreEvidence message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof attestation.SigstoreEvidence
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {attestation.SigstoreEvidence} SigstoreEvidence
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SigstoreEvidence.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SigstoreEvidence message.
         * @function verify
         * @memberof attestation.SigstoreEvidence
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SigstoreEvidence.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.v1 != null && message.hasOwnProperty("v1")) {
                properties.sigstoreEvidence = 1;
                {
                    let error = $root.attestation.SigstoreEvidenceV1.verify(message.v1);
                    if (error)
                        return "v1." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SigstoreEvidence message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof attestation.SigstoreEvidence
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {attestation.SigstoreEvidence} SigstoreEvidence
         */
        SigstoreEvidence.fromObject = function fromObject(object) {
            if (object instanceof $root.attestation.SigstoreEvidence)
                return object;
            let message = new $root.attestation.SigstoreEvidence();
            if (object.v1 != null) {
                if (typeof object.v1 !== "object")
                    throw TypeError(".attestation.SigstoreEvidence.v1: object expected");
                message.v1 = $root.attestation.SigstoreEvidenceV1.fromObject(object.v1);
            }
            return message;
        };

        /**
         * Creates a plain object from a SigstoreEvidence message. Also converts values to other types if specified.
         * @function toObject
         * @memberof attestation.SigstoreEvidence
         * @static
         * @param {attestation.SigstoreEvidence} message SigstoreEvidence
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SigstoreEvidence.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.v1 != null && message.hasOwnProperty("v1")) {
                object.v1 = $root.attestation.SigstoreEvidenceV1.toObject(message.v1, options);
                if (options.oneofs)
                    object.sigstoreEvidence = "v1";
            }
            return object;
        };

        /**
         * Converts this SigstoreEvidence to JSON.
         * @function toJSON
         * @memberof attestation.SigstoreEvidence
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SigstoreEvidence.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SigstoreEvidence
         * @function getTypeUrl
         * @memberof attestation.SigstoreEvidence
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SigstoreEvidence.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/attestation.SigstoreEvidence";
        };

        return SigstoreEvidence;
    })();

    attestation.SigstoreEvidenceV1 = (function() {

        /**
         * Properties of a SigstoreEvidenceV1.
         * @memberof attestation
         * @interface ISigstoreEvidenceV1
         * @property {Array.<attestation.ISigstoreFile>|null} [files] SigstoreEvidenceV1 files
         * @property {Uint8Array|null} [rekorLogEntryJson] SigstoreEvidenceV1 rekorLogEntryJson
         */

        /**
         * Constructs a new SigstoreEvidenceV1.
         * @memberof attestation
         * @classdesc Represents a SigstoreEvidenceV1.
         * @implements ISigstoreEvidenceV1
         * @constructor
         * @param {attestation.ISigstoreEvidenceV1=} [properties] Properties to set
         */
        function SigstoreEvidenceV1(properties) {
            this.files = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SigstoreEvidenceV1 files.
         * @member {Array.<attestation.ISigstoreFile>} files
         * @memberof attestation.SigstoreEvidenceV1
         * @instance
         */
        SigstoreEvidenceV1.prototype.files = $util.emptyArray;

        /**
         * SigstoreEvidenceV1 rekorLogEntryJson.
         * @member {Uint8Array} rekorLogEntryJson
         * @memberof attestation.SigstoreEvidenceV1
         * @instance
         */
        SigstoreEvidenceV1.prototype.rekorLogEntryJson = $util.newBuffer([]);

        /**
         * Creates a new SigstoreEvidenceV1 instance using the specified properties.
         * @function create
         * @memberof attestation.SigstoreEvidenceV1
         * @static
         * @param {attestation.ISigstoreEvidenceV1=} [properties] Properties to set
         * @returns {attestation.SigstoreEvidenceV1} SigstoreEvidenceV1 instance
         */
        SigstoreEvidenceV1.create = function create(properties) {
            return new SigstoreEvidenceV1(properties);
        };

        /**
         * Encodes the specified SigstoreEvidenceV1 message. Does not implicitly {@link attestation.SigstoreEvidenceV1.verify|verify} messages.
         * @function encode
         * @memberof attestation.SigstoreEvidenceV1
         * @static
         * @param {attestation.ISigstoreEvidenceV1} message SigstoreEvidenceV1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SigstoreEvidenceV1.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.files != null && message.files.length)
                for (let i = 0; i < message.files.length; ++i)
                    $root.attestation.SigstoreFile.encode(message.files[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.rekorLogEntryJson != null && Object.hasOwnProperty.call(message, "rekorLogEntryJson"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.rekorLogEntryJson);
            return writer;
        };

        /**
         * Encodes the specified SigstoreEvidenceV1 message, length delimited. Does not implicitly {@link attestation.SigstoreEvidenceV1.verify|verify} messages.
         * @function encodeDelimited
         * @memberof attestation.SigstoreEvidenceV1
         * @static
         * @param {attestation.ISigstoreEvidenceV1} message SigstoreEvidenceV1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SigstoreEvidenceV1.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SigstoreEvidenceV1 message from the specified reader or buffer.
         * @function decode
         * @memberof attestation.SigstoreEvidenceV1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {attestation.SigstoreEvidenceV1} SigstoreEvidenceV1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SigstoreEvidenceV1.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.attestation.SigstoreEvidenceV1();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.files && message.files.length))
                            message.files = [];
                        message.files.push($root.attestation.SigstoreFile.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.rekorLogEntryJson = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SigstoreEvidenceV1 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof attestation.SigstoreEvidenceV1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {attestation.SigstoreEvidenceV1} SigstoreEvidenceV1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SigstoreEvidenceV1.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SigstoreEvidenceV1 message.
         * @function verify
         * @memberof attestation.SigstoreEvidenceV1
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SigstoreEvidenceV1.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.files != null && message.hasOwnProperty("files")) {
                if (!Array.isArray(message.files))
                    return "files: array expected";
                for (let i = 0; i < message.files.length; ++i) {
                    let error = $root.attestation.SigstoreFile.verify(message.files[i]);
                    if (error)
                        return "files." + error;
                }
            }
            if (message.rekorLogEntryJson != null && message.hasOwnProperty("rekorLogEntryJson"))
                if (!(message.rekorLogEntryJson && typeof message.rekorLogEntryJson.length === "number" || $util.isString(message.rekorLogEntryJson)))
                    return "rekorLogEntryJson: buffer expected";
            return null;
        };

        /**
         * Creates a SigstoreEvidenceV1 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof attestation.SigstoreEvidenceV1
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {attestation.SigstoreEvidenceV1} SigstoreEvidenceV1
         */
        SigstoreEvidenceV1.fromObject = function fromObject(object) {
            if (object instanceof $root.attestation.SigstoreEvidenceV1)
                return object;
            let message = new $root.attestation.SigstoreEvidenceV1();
            if (object.files) {
                if (!Array.isArray(object.files))
                    throw TypeError(".attestation.SigstoreEvidenceV1.files: array expected");
                message.files = [];
                for (let i = 0; i < object.files.length; ++i) {
                    if (typeof object.files[i] !== "object")
                        throw TypeError(".attestation.SigstoreEvidenceV1.files: object expected");
                    message.files[i] = $root.attestation.SigstoreFile.fromObject(object.files[i]);
                }
            }
            if (object.rekorLogEntryJson != null)
                if (typeof object.rekorLogEntryJson === "string")
                    $util.base64.decode(object.rekorLogEntryJson, message.rekorLogEntryJson = $util.newBuffer($util.base64.length(object.rekorLogEntryJson)), 0);
                else if (object.rekorLogEntryJson.length >= 0)
                    message.rekorLogEntryJson = object.rekorLogEntryJson;
            return message;
        };

        /**
         * Creates a plain object from a SigstoreEvidenceV1 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof attestation.SigstoreEvidenceV1
         * @static
         * @param {attestation.SigstoreEvidenceV1} message SigstoreEvidenceV1
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SigstoreEvidenceV1.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.files = [];
            if (options.defaults)
                if (options.bytes === String)
                    object.rekorLogEntryJson = "";
                else {
                    object.rekorLogEntryJson = [];
                    if (options.bytes !== Array)
                        object.rekorLogEntryJson = $util.newBuffer(object.rekorLogEntryJson);
                }
            if (message.files && message.files.length) {
                object.files = [];
                for (let j = 0; j < message.files.length; ++j)
                    object.files[j] = $root.attestation.SigstoreFile.toObject(message.files[j], options);
            }
            if (message.rekorLogEntryJson != null && message.hasOwnProperty("rekorLogEntryJson"))
                object.rekorLogEntryJson = options.bytes === String ? $util.base64.encode(message.rekorLogEntryJson, 0, message.rekorLogEntryJson.length) : options.bytes === Array ? Array.prototype.slice.call(message.rekorLogEntryJson) : message.rekorLogEntryJson;
            return object;
        };

        /**
         * Converts this SigstoreEvidenceV1 to JSON.
         * @function toJSON
         * @memberof attestation.SigstoreEvidenceV1
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SigstoreEvidenceV1.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SigstoreEvidenceV1
         * @function getTypeUrl
         * @memberof attestation.SigstoreEvidenceV1
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SigstoreEvidenceV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/attestation.SigstoreEvidenceV1";
        };

        return SigstoreEvidenceV1;
    })();

    attestation.FatquoteNitro = (function() {

        /**
         * Properties of a FatquoteNitro.
         * @memberof attestation
         * @interface IFatquoteNitro
         * @property {Uint8Array|null} [cose] FatquoteNitro cose
         * @property {Uint8Array|null} [nitroRootCaDer] FatquoteNitro nitroRootCaDer
         */

        /**
         * Constructs a new FatquoteNitro.
         * @memberof attestation
         * @classdesc Represents a FatquoteNitro.
         * @implements IFatquoteNitro
         * @constructor
         * @param {attestation.IFatquoteNitro=} [properties] Properties to set
         */
        function FatquoteNitro(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FatquoteNitro cose.
         * @member {Uint8Array} cose
         * @memberof attestation.FatquoteNitro
         * @instance
         */
        FatquoteNitro.prototype.cose = $util.newBuffer([]);

        /**
         * FatquoteNitro nitroRootCaDer.
         * @member {Uint8Array} nitroRootCaDer
         * @memberof attestation.FatquoteNitro
         * @instance
         */
        FatquoteNitro.prototype.nitroRootCaDer = $util.newBuffer([]);

        /**
         * Creates a new FatquoteNitro instance using the specified properties.
         * @function create
         * @memberof attestation.FatquoteNitro
         * @static
         * @param {attestation.IFatquoteNitro=} [properties] Properties to set
         * @returns {attestation.FatquoteNitro} FatquoteNitro instance
         */
        FatquoteNitro.create = function create(properties) {
            return new FatquoteNitro(properties);
        };

        /**
         * Encodes the specified FatquoteNitro message. Does not implicitly {@link attestation.FatquoteNitro.verify|verify} messages.
         * @function encode
         * @memberof attestation.FatquoteNitro
         * @static
         * @param {attestation.IFatquoteNitro} message FatquoteNitro message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FatquoteNitro.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cose != null && Object.hasOwnProperty.call(message, "cose"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.cose);
            if (message.nitroRootCaDer != null && Object.hasOwnProperty.call(message, "nitroRootCaDer"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.nitroRootCaDer);
            return writer;
        };

        /**
         * Encodes the specified FatquoteNitro message, length delimited. Does not implicitly {@link attestation.FatquoteNitro.verify|verify} messages.
         * @function encodeDelimited
         * @memberof attestation.FatquoteNitro
         * @static
         * @param {attestation.IFatquoteNitro} message FatquoteNitro message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FatquoteNitro.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FatquoteNitro message from the specified reader or buffer.
         * @function decode
         * @memberof attestation.FatquoteNitro
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {attestation.FatquoteNitro} FatquoteNitro
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FatquoteNitro.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.attestation.FatquoteNitro();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.cose = reader.bytes();
                        break;
                    }
                case 2: {
                        message.nitroRootCaDer = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FatquoteNitro message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof attestation.FatquoteNitro
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {attestation.FatquoteNitro} FatquoteNitro
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FatquoteNitro.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FatquoteNitro message.
         * @function verify
         * @memberof attestation.FatquoteNitro
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FatquoteNitro.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.cose != null && message.hasOwnProperty("cose"))
                if (!(message.cose && typeof message.cose.length === "number" || $util.isString(message.cose)))
                    return "cose: buffer expected";
            if (message.nitroRootCaDer != null && message.hasOwnProperty("nitroRootCaDer"))
                if (!(message.nitroRootCaDer && typeof message.nitroRootCaDer.length === "number" || $util.isString(message.nitroRootCaDer)))
                    return "nitroRootCaDer: buffer expected";
            return null;
        };

        /**
         * Creates a FatquoteNitro message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof attestation.FatquoteNitro
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {attestation.FatquoteNitro} FatquoteNitro
         */
        FatquoteNitro.fromObject = function fromObject(object) {
            if (object instanceof $root.attestation.FatquoteNitro)
                return object;
            let message = new $root.attestation.FatquoteNitro();
            if (object.cose != null)
                if (typeof object.cose === "string")
                    $util.base64.decode(object.cose, message.cose = $util.newBuffer($util.base64.length(object.cose)), 0);
                else if (object.cose.length >= 0)
                    message.cose = object.cose;
            if (object.nitroRootCaDer != null)
                if (typeof object.nitroRootCaDer === "string")
                    $util.base64.decode(object.nitroRootCaDer, message.nitroRootCaDer = $util.newBuffer($util.base64.length(object.nitroRootCaDer)), 0);
                else if (object.nitroRootCaDer.length >= 0)
                    message.nitroRootCaDer = object.nitroRootCaDer;
            return message;
        };

        /**
         * Creates a plain object from a FatquoteNitro message. Also converts values to other types if specified.
         * @function toObject
         * @memberof attestation.FatquoteNitro
         * @static
         * @param {attestation.FatquoteNitro} message FatquoteNitro
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FatquoteNitro.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.cose = "";
                else {
                    object.cose = [];
                    if (options.bytes !== Array)
                        object.cose = $util.newBuffer(object.cose);
                }
                if (options.bytes === String)
                    object.nitroRootCaDer = "";
                else {
                    object.nitroRootCaDer = [];
                    if (options.bytes !== Array)
                        object.nitroRootCaDer = $util.newBuffer(object.nitroRootCaDer);
                }
            }
            if (message.cose != null && message.hasOwnProperty("cose"))
                object.cose = options.bytes === String ? $util.base64.encode(message.cose, 0, message.cose.length) : options.bytes === Array ? Array.prototype.slice.call(message.cose) : message.cose;
            if (message.nitroRootCaDer != null && message.hasOwnProperty("nitroRootCaDer"))
                object.nitroRootCaDer = options.bytes === String ? $util.base64.encode(message.nitroRootCaDer, 0, message.nitroRootCaDer.length) : options.bytes === Array ? Array.prototype.slice.call(message.nitroRootCaDer) : message.nitroRootCaDer;
            return object;
        };

        /**
         * Converts this FatquoteNitro to JSON.
         * @function toJSON
         * @memberof attestation.FatquoteNitro
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FatquoteNitro.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FatquoteNitro
         * @function getTypeUrl
         * @memberof attestation.FatquoteNitro
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FatquoteNitro.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/attestation.FatquoteNitro";
        };

        return FatquoteNitro;
    })();

    attestation.FatquoteSnp = (function() {

        /**
         * Properties of a FatquoteSnp.
         * @memberof attestation
         * @interface IFatquoteSnp
         * @property {Uint8Array|null} [reportBin] FatquoteSnp reportBin
         * @property {Uint8Array|null} [amdArkDer] FatquoteSnp amdArkDer
         * @property {Uint8Array|null} [amdSevDer] FatquoteSnp amdSevDer
         * @property {Uint8Array|null} [vcekCrtDer] FatquoteSnp vcekCrtDer
         * @property {Uint8Array|null} [reportData] FatquoteSnp reportData
         * @property {Uint8Array|null} [roughtimePubKey] FatquoteSnp roughtimePubKey
         * @property {Uint8Array|null} [roughtimeNonce] FatquoteSnp roughtimeNonce
         * @property {Uint8Array|null} [signedTimestamp] FatquoteSnp signedTimestamp
         * @property {Uint8Array|null} [decentriqDer] FatquoteSnp decentriqDer
         * @property {Uint8Array|null} [chipDer] FatquoteSnp chipDer
         */

        /**
         * Constructs a new FatquoteSnp.
         * @memberof attestation
         * @classdesc Represents a FatquoteSnp.
         * @implements IFatquoteSnp
         * @constructor
         * @param {attestation.IFatquoteSnp=} [properties] Properties to set
         */
        function FatquoteSnp(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FatquoteSnp reportBin.
         * @member {Uint8Array} reportBin
         * @memberof attestation.FatquoteSnp
         * @instance
         */
        FatquoteSnp.prototype.reportBin = $util.newBuffer([]);

        /**
         * FatquoteSnp amdArkDer.
         * @member {Uint8Array} amdArkDer
         * @memberof attestation.FatquoteSnp
         * @instance
         */
        FatquoteSnp.prototype.amdArkDer = $util.newBuffer([]);

        /**
         * FatquoteSnp amdSevDer.
         * @member {Uint8Array} amdSevDer
         * @memberof attestation.FatquoteSnp
         * @instance
         */
        FatquoteSnp.prototype.amdSevDer = $util.newBuffer([]);

        /**
         * FatquoteSnp vcekCrtDer.
         * @member {Uint8Array} vcekCrtDer
         * @memberof attestation.FatquoteSnp
         * @instance
         */
        FatquoteSnp.prototype.vcekCrtDer = $util.newBuffer([]);

        /**
         * FatquoteSnp reportData.
         * @member {Uint8Array} reportData
         * @memberof attestation.FatquoteSnp
         * @instance
         */
        FatquoteSnp.prototype.reportData = $util.newBuffer([]);

        /**
         * FatquoteSnp roughtimePubKey.
         * @member {Uint8Array} roughtimePubKey
         * @memberof attestation.FatquoteSnp
         * @instance
         */
        FatquoteSnp.prototype.roughtimePubKey = $util.newBuffer([]);

        /**
         * FatquoteSnp roughtimeNonce.
         * @member {Uint8Array} roughtimeNonce
         * @memberof attestation.FatquoteSnp
         * @instance
         */
        FatquoteSnp.prototype.roughtimeNonce = $util.newBuffer([]);

        /**
         * FatquoteSnp signedTimestamp.
         * @member {Uint8Array} signedTimestamp
         * @memberof attestation.FatquoteSnp
         * @instance
         */
        FatquoteSnp.prototype.signedTimestamp = $util.newBuffer([]);

        /**
         * FatquoteSnp decentriqDer.
         * @member {Uint8Array} decentriqDer
         * @memberof attestation.FatquoteSnp
         * @instance
         */
        FatquoteSnp.prototype.decentriqDer = $util.newBuffer([]);

        /**
         * FatquoteSnp chipDer.
         * @member {Uint8Array} chipDer
         * @memberof attestation.FatquoteSnp
         * @instance
         */
        FatquoteSnp.prototype.chipDer = $util.newBuffer([]);

        /**
         * Creates a new FatquoteSnp instance using the specified properties.
         * @function create
         * @memberof attestation.FatquoteSnp
         * @static
         * @param {attestation.IFatquoteSnp=} [properties] Properties to set
         * @returns {attestation.FatquoteSnp} FatquoteSnp instance
         */
        FatquoteSnp.create = function create(properties) {
            return new FatquoteSnp(properties);
        };

        /**
         * Encodes the specified FatquoteSnp message. Does not implicitly {@link attestation.FatquoteSnp.verify|verify} messages.
         * @function encode
         * @memberof attestation.FatquoteSnp
         * @static
         * @param {attestation.IFatquoteSnp} message FatquoteSnp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FatquoteSnp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.reportBin != null && Object.hasOwnProperty.call(message, "reportBin"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.reportBin);
            if (message.amdArkDer != null && Object.hasOwnProperty.call(message, "amdArkDer"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.amdArkDer);
            if (message.amdSevDer != null && Object.hasOwnProperty.call(message, "amdSevDer"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.amdSevDer);
            if (message.vcekCrtDer != null && Object.hasOwnProperty.call(message, "vcekCrtDer"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.vcekCrtDer);
            if (message.reportData != null && Object.hasOwnProperty.call(message, "reportData"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.reportData);
            if (message.roughtimePubKey != null && Object.hasOwnProperty.call(message, "roughtimePubKey"))
                writer.uint32(/* id 6, wireType 2 =*/50).bytes(message.roughtimePubKey);
            if (message.roughtimeNonce != null && Object.hasOwnProperty.call(message, "roughtimeNonce"))
                writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.roughtimeNonce);
            if (message.signedTimestamp != null && Object.hasOwnProperty.call(message, "signedTimestamp"))
                writer.uint32(/* id 8, wireType 2 =*/66).bytes(message.signedTimestamp);
            if (message.decentriqDer != null && Object.hasOwnProperty.call(message, "decentriqDer"))
                writer.uint32(/* id 9, wireType 2 =*/74).bytes(message.decentriqDer);
            if (message.chipDer != null && Object.hasOwnProperty.call(message, "chipDer"))
                writer.uint32(/* id 10, wireType 2 =*/82).bytes(message.chipDer);
            return writer;
        };

        /**
         * Encodes the specified FatquoteSnp message, length delimited. Does not implicitly {@link attestation.FatquoteSnp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof attestation.FatquoteSnp
         * @static
         * @param {attestation.IFatquoteSnp} message FatquoteSnp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FatquoteSnp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FatquoteSnp message from the specified reader or buffer.
         * @function decode
         * @memberof attestation.FatquoteSnp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {attestation.FatquoteSnp} FatquoteSnp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FatquoteSnp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.attestation.FatquoteSnp();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.reportBin = reader.bytes();
                        break;
                    }
                case 2: {
                        message.amdArkDer = reader.bytes();
                        break;
                    }
                case 3: {
                        message.amdSevDer = reader.bytes();
                        break;
                    }
                case 4: {
                        message.vcekCrtDer = reader.bytes();
                        break;
                    }
                case 5: {
                        message.reportData = reader.bytes();
                        break;
                    }
                case 6: {
                        message.roughtimePubKey = reader.bytes();
                        break;
                    }
                case 7: {
                        message.roughtimeNonce = reader.bytes();
                        break;
                    }
                case 8: {
                        message.signedTimestamp = reader.bytes();
                        break;
                    }
                case 9: {
                        message.decentriqDer = reader.bytes();
                        break;
                    }
                case 10: {
                        message.chipDer = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FatquoteSnp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof attestation.FatquoteSnp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {attestation.FatquoteSnp} FatquoteSnp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FatquoteSnp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FatquoteSnp message.
         * @function verify
         * @memberof attestation.FatquoteSnp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FatquoteSnp.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.reportBin != null && message.hasOwnProperty("reportBin"))
                if (!(message.reportBin && typeof message.reportBin.length === "number" || $util.isString(message.reportBin)))
                    return "reportBin: buffer expected";
            if (message.amdArkDer != null && message.hasOwnProperty("amdArkDer"))
                if (!(message.amdArkDer && typeof message.amdArkDer.length === "number" || $util.isString(message.amdArkDer)))
                    return "amdArkDer: buffer expected";
            if (message.amdSevDer != null && message.hasOwnProperty("amdSevDer"))
                if (!(message.amdSevDer && typeof message.amdSevDer.length === "number" || $util.isString(message.amdSevDer)))
                    return "amdSevDer: buffer expected";
            if (message.vcekCrtDer != null && message.hasOwnProperty("vcekCrtDer"))
                if (!(message.vcekCrtDer && typeof message.vcekCrtDer.length === "number" || $util.isString(message.vcekCrtDer)))
                    return "vcekCrtDer: buffer expected";
            if (message.reportData != null && message.hasOwnProperty("reportData"))
                if (!(message.reportData && typeof message.reportData.length === "number" || $util.isString(message.reportData)))
                    return "reportData: buffer expected";
            if (message.roughtimePubKey != null && message.hasOwnProperty("roughtimePubKey"))
                if (!(message.roughtimePubKey && typeof message.roughtimePubKey.length === "number" || $util.isString(message.roughtimePubKey)))
                    return "roughtimePubKey: buffer expected";
            if (message.roughtimeNonce != null && message.hasOwnProperty("roughtimeNonce"))
                if (!(message.roughtimeNonce && typeof message.roughtimeNonce.length === "number" || $util.isString(message.roughtimeNonce)))
                    return "roughtimeNonce: buffer expected";
            if (message.signedTimestamp != null && message.hasOwnProperty("signedTimestamp"))
                if (!(message.signedTimestamp && typeof message.signedTimestamp.length === "number" || $util.isString(message.signedTimestamp)))
                    return "signedTimestamp: buffer expected";
            if (message.decentriqDer != null && message.hasOwnProperty("decentriqDer"))
                if (!(message.decentriqDer && typeof message.decentriqDer.length === "number" || $util.isString(message.decentriqDer)))
                    return "decentriqDer: buffer expected";
            if (message.chipDer != null && message.hasOwnProperty("chipDer"))
                if (!(message.chipDer && typeof message.chipDer.length === "number" || $util.isString(message.chipDer)))
                    return "chipDer: buffer expected";
            return null;
        };

        /**
         * Creates a FatquoteSnp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof attestation.FatquoteSnp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {attestation.FatquoteSnp} FatquoteSnp
         */
        FatquoteSnp.fromObject = function fromObject(object) {
            if (object instanceof $root.attestation.FatquoteSnp)
                return object;
            let message = new $root.attestation.FatquoteSnp();
            if (object.reportBin != null)
                if (typeof object.reportBin === "string")
                    $util.base64.decode(object.reportBin, message.reportBin = $util.newBuffer($util.base64.length(object.reportBin)), 0);
                else if (object.reportBin.length >= 0)
                    message.reportBin = object.reportBin;
            if (object.amdArkDer != null)
                if (typeof object.amdArkDer === "string")
                    $util.base64.decode(object.amdArkDer, message.amdArkDer = $util.newBuffer($util.base64.length(object.amdArkDer)), 0);
                else if (object.amdArkDer.length >= 0)
                    message.amdArkDer = object.amdArkDer;
            if (object.amdSevDer != null)
                if (typeof object.amdSevDer === "string")
                    $util.base64.decode(object.amdSevDer, message.amdSevDer = $util.newBuffer($util.base64.length(object.amdSevDer)), 0);
                else if (object.amdSevDer.length >= 0)
                    message.amdSevDer = object.amdSevDer;
            if (object.vcekCrtDer != null)
                if (typeof object.vcekCrtDer === "string")
                    $util.base64.decode(object.vcekCrtDer, message.vcekCrtDer = $util.newBuffer($util.base64.length(object.vcekCrtDer)), 0);
                else if (object.vcekCrtDer.length >= 0)
                    message.vcekCrtDer = object.vcekCrtDer;
            if (object.reportData != null)
                if (typeof object.reportData === "string")
                    $util.base64.decode(object.reportData, message.reportData = $util.newBuffer($util.base64.length(object.reportData)), 0);
                else if (object.reportData.length >= 0)
                    message.reportData = object.reportData;
            if (object.roughtimePubKey != null)
                if (typeof object.roughtimePubKey === "string")
                    $util.base64.decode(object.roughtimePubKey, message.roughtimePubKey = $util.newBuffer($util.base64.length(object.roughtimePubKey)), 0);
                else if (object.roughtimePubKey.length >= 0)
                    message.roughtimePubKey = object.roughtimePubKey;
            if (object.roughtimeNonce != null)
                if (typeof object.roughtimeNonce === "string")
                    $util.base64.decode(object.roughtimeNonce, message.roughtimeNonce = $util.newBuffer($util.base64.length(object.roughtimeNonce)), 0);
                else if (object.roughtimeNonce.length >= 0)
                    message.roughtimeNonce = object.roughtimeNonce;
            if (object.signedTimestamp != null)
                if (typeof object.signedTimestamp === "string")
                    $util.base64.decode(object.signedTimestamp, message.signedTimestamp = $util.newBuffer($util.base64.length(object.signedTimestamp)), 0);
                else if (object.signedTimestamp.length >= 0)
                    message.signedTimestamp = object.signedTimestamp;
            if (object.decentriqDer != null)
                if (typeof object.decentriqDer === "string")
                    $util.base64.decode(object.decentriqDer, message.decentriqDer = $util.newBuffer($util.base64.length(object.decentriqDer)), 0);
                else if (object.decentriqDer.length >= 0)
                    message.decentriqDer = object.decentriqDer;
            if (object.chipDer != null)
                if (typeof object.chipDer === "string")
                    $util.base64.decode(object.chipDer, message.chipDer = $util.newBuffer($util.base64.length(object.chipDer)), 0);
                else if (object.chipDer.length >= 0)
                    message.chipDer = object.chipDer;
            return message;
        };

        /**
         * Creates a plain object from a FatquoteSnp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof attestation.FatquoteSnp
         * @static
         * @param {attestation.FatquoteSnp} message FatquoteSnp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FatquoteSnp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.reportBin = "";
                else {
                    object.reportBin = [];
                    if (options.bytes !== Array)
                        object.reportBin = $util.newBuffer(object.reportBin);
                }
                if (options.bytes === String)
                    object.amdArkDer = "";
                else {
                    object.amdArkDer = [];
                    if (options.bytes !== Array)
                        object.amdArkDer = $util.newBuffer(object.amdArkDer);
                }
                if (options.bytes === String)
                    object.amdSevDer = "";
                else {
                    object.amdSevDer = [];
                    if (options.bytes !== Array)
                        object.amdSevDer = $util.newBuffer(object.amdSevDer);
                }
                if (options.bytes === String)
                    object.vcekCrtDer = "";
                else {
                    object.vcekCrtDer = [];
                    if (options.bytes !== Array)
                        object.vcekCrtDer = $util.newBuffer(object.vcekCrtDer);
                }
                if (options.bytes === String)
                    object.reportData = "";
                else {
                    object.reportData = [];
                    if (options.bytes !== Array)
                        object.reportData = $util.newBuffer(object.reportData);
                }
                if (options.bytes === String)
                    object.roughtimePubKey = "";
                else {
                    object.roughtimePubKey = [];
                    if (options.bytes !== Array)
                        object.roughtimePubKey = $util.newBuffer(object.roughtimePubKey);
                }
                if (options.bytes === String)
                    object.roughtimeNonce = "";
                else {
                    object.roughtimeNonce = [];
                    if (options.bytes !== Array)
                        object.roughtimeNonce = $util.newBuffer(object.roughtimeNonce);
                }
                if (options.bytes === String)
                    object.signedTimestamp = "";
                else {
                    object.signedTimestamp = [];
                    if (options.bytes !== Array)
                        object.signedTimestamp = $util.newBuffer(object.signedTimestamp);
                }
                if (options.bytes === String)
                    object.decentriqDer = "";
                else {
                    object.decentriqDer = [];
                    if (options.bytes !== Array)
                        object.decentriqDer = $util.newBuffer(object.decentriqDer);
                }
                if (options.bytes === String)
                    object.chipDer = "";
                else {
                    object.chipDer = [];
                    if (options.bytes !== Array)
                        object.chipDer = $util.newBuffer(object.chipDer);
                }
            }
            if (message.reportBin != null && message.hasOwnProperty("reportBin"))
                object.reportBin = options.bytes === String ? $util.base64.encode(message.reportBin, 0, message.reportBin.length) : options.bytes === Array ? Array.prototype.slice.call(message.reportBin) : message.reportBin;
            if (message.amdArkDer != null && message.hasOwnProperty("amdArkDer"))
                object.amdArkDer = options.bytes === String ? $util.base64.encode(message.amdArkDer, 0, message.amdArkDer.length) : options.bytes === Array ? Array.prototype.slice.call(message.amdArkDer) : message.amdArkDer;
            if (message.amdSevDer != null && message.hasOwnProperty("amdSevDer"))
                object.amdSevDer = options.bytes === String ? $util.base64.encode(message.amdSevDer, 0, message.amdSevDer.length) : options.bytes === Array ? Array.prototype.slice.call(message.amdSevDer) : message.amdSevDer;
            if (message.vcekCrtDer != null && message.hasOwnProperty("vcekCrtDer"))
                object.vcekCrtDer = options.bytes === String ? $util.base64.encode(message.vcekCrtDer, 0, message.vcekCrtDer.length) : options.bytes === Array ? Array.prototype.slice.call(message.vcekCrtDer) : message.vcekCrtDer;
            if (message.reportData != null && message.hasOwnProperty("reportData"))
                object.reportData = options.bytes === String ? $util.base64.encode(message.reportData, 0, message.reportData.length) : options.bytes === Array ? Array.prototype.slice.call(message.reportData) : message.reportData;
            if (message.roughtimePubKey != null && message.hasOwnProperty("roughtimePubKey"))
                object.roughtimePubKey = options.bytes === String ? $util.base64.encode(message.roughtimePubKey, 0, message.roughtimePubKey.length) : options.bytes === Array ? Array.prototype.slice.call(message.roughtimePubKey) : message.roughtimePubKey;
            if (message.roughtimeNonce != null && message.hasOwnProperty("roughtimeNonce"))
                object.roughtimeNonce = options.bytes === String ? $util.base64.encode(message.roughtimeNonce, 0, message.roughtimeNonce.length) : options.bytes === Array ? Array.prototype.slice.call(message.roughtimeNonce) : message.roughtimeNonce;
            if (message.signedTimestamp != null && message.hasOwnProperty("signedTimestamp"))
                object.signedTimestamp = options.bytes === String ? $util.base64.encode(message.signedTimestamp, 0, message.signedTimestamp.length) : options.bytes === Array ? Array.prototype.slice.call(message.signedTimestamp) : message.signedTimestamp;
            if (message.decentriqDer != null && message.hasOwnProperty("decentriqDer"))
                object.decentriqDer = options.bytes === String ? $util.base64.encode(message.decentriqDer, 0, message.decentriqDer.length) : options.bytes === Array ? Array.prototype.slice.call(message.decentriqDer) : message.decentriqDer;
            if (message.chipDer != null && message.hasOwnProperty("chipDer"))
                object.chipDer = options.bytes === String ? $util.base64.encode(message.chipDer, 0, message.chipDer.length) : options.bytes === Array ? Array.prototype.slice.call(message.chipDer) : message.chipDer;
            return object;
        };

        /**
         * Converts this FatquoteSnp to JSON.
         * @function toJSON
         * @memberof attestation.FatquoteSnp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FatquoteSnp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FatquoteSnp
         * @function getTypeUrl
         * @memberof attestation.FatquoteSnp
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FatquoteSnp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/attestation.FatquoteSnp";
        };

        return FatquoteSnp;
    })();

    attestation.AttestationSpecification = (function() {

        /**
         * Properties of an AttestationSpecification.
         * @memberof attestation
         * @interface IAttestationSpecification
         * @property {attestation.IAttestationSpecificationIntelEpid|null} [intelEpid] AttestationSpecification intelEpid
         * @property {attestation.IAttestationSpecificationIntelDcap|null} [intelDcap] AttestationSpecification intelDcap
         * @property {attestation.IAttestationSpecificationAwsNitro|null} [awsNitro] AttestationSpecification awsNitro
         * @property {attestation.IAttestationSpecificationAmdSnp|null} [amdSnp] AttestationSpecification amdSnp
         * @property {attestation.IAttestationSpecificationIntelDcapMrsigner|null} [intelDcapMrsigner] AttestationSpecification intelDcapMrsigner
         */

        /**
         * Constructs a new AttestationSpecification.
         * @memberof attestation
         * @classdesc Represents an AttestationSpecification.
         * @implements IAttestationSpecification
         * @constructor
         * @param {attestation.IAttestationSpecification=} [properties] Properties to set
         */
        function AttestationSpecification(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AttestationSpecification intelEpid.
         * @member {attestation.IAttestationSpecificationIntelEpid|null|undefined} intelEpid
         * @memberof attestation.AttestationSpecification
         * @instance
         */
        AttestationSpecification.prototype.intelEpid = null;

        /**
         * AttestationSpecification intelDcap.
         * @member {attestation.IAttestationSpecificationIntelDcap|null|undefined} intelDcap
         * @memberof attestation.AttestationSpecification
         * @instance
         */
        AttestationSpecification.prototype.intelDcap = null;

        /**
         * AttestationSpecification awsNitro.
         * @member {attestation.IAttestationSpecificationAwsNitro|null|undefined} awsNitro
         * @memberof attestation.AttestationSpecification
         * @instance
         */
        AttestationSpecification.prototype.awsNitro = null;

        /**
         * AttestationSpecification amdSnp.
         * @member {attestation.IAttestationSpecificationAmdSnp|null|undefined} amdSnp
         * @memberof attestation.AttestationSpecification
         * @instance
         */
        AttestationSpecification.prototype.amdSnp = null;

        /**
         * AttestationSpecification intelDcapMrsigner.
         * @member {attestation.IAttestationSpecificationIntelDcapMrsigner|null|undefined} intelDcapMrsigner
         * @memberof attestation.AttestationSpecification
         * @instance
         */
        AttestationSpecification.prototype.intelDcapMrsigner = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * AttestationSpecification attestationSpecification.
         * @member {"intelEpid"|"intelDcap"|"awsNitro"|"amdSnp"|"intelDcapMrsigner"|undefined} attestationSpecification
         * @memberof attestation.AttestationSpecification
         * @instance
         */
        Object.defineProperty(AttestationSpecification.prototype, "attestationSpecification", {
            get: $util.oneOfGetter($oneOfFields = ["intelEpid", "intelDcap", "awsNitro", "amdSnp", "intelDcapMrsigner"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new AttestationSpecification instance using the specified properties.
         * @function create
         * @memberof attestation.AttestationSpecification
         * @static
         * @param {attestation.IAttestationSpecification=} [properties] Properties to set
         * @returns {attestation.AttestationSpecification} AttestationSpecification instance
         */
        AttestationSpecification.create = function create(properties) {
            return new AttestationSpecification(properties);
        };

        /**
         * Encodes the specified AttestationSpecification message. Does not implicitly {@link attestation.AttestationSpecification.verify|verify} messages.
         * @function encode
         * @memberof attestation.AttestationSpecification
         * @static
         * @param {attestation.IAttestationSpecification} message AttestationSpecification message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AttestationSpecification.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.intelEpid != null && Object.hasOwnProperty.call(message, "intelEpid"))
                $root.attestation.AttestationSpecificationIntelEpid.encode(message.intelEpid, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.intelDcap != null && Object.hasOwnProperty.call(message, "intelDcap"))
                $root.attestation.AttestationSpecificationIntelDcap.encode(message.intelDcap, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.awsNitro != null && Object.hasOwnProperty.call(message, "awsNitro"))
                $root.attestation.AttestationSpecificationAwsNitro.encode(message.awsNitro, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.amdSnp != null && Object.hasOwnProperty.call(message, "amdSnp"))
                $root.attestation.AttestationSpecificationAmdSnp.encode(message.amdSnp, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.intelDcapMrsigner != null && Object.hasOwnProperty.call(message, "intelDcapMrsigner"))
                $root.attestation.AttestationSpecificationIntelDcapMrsigner.encode(message.intelDcapMrsigner, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AttestationSpecification message, length delimited. Does not implicitly {@link attestation.AttestationSpecification.verify|verify} messages.
         * @function encodeDelimited
         * @memberof attestation.AttestationSpecification
         * @static
         * @param {attestation.IAttestationSpecification} message AttestationSpecification message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AttestationSpecification.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AttestationSpecification message from the specified reader or buffer.
         * @function decode
         * @memberof attestation.AttestationSpecification
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {attestation.AttestationSpecification} AttestationSpecification
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AttestationSpecification.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.attestation.AttestationSpecification();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.intelEpid = $root.attestation.AttestationSpecificationIntelEpid.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.intelDcap = $root.attestation.AttestationSpecificationIntelDcap.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.awsNitro = $root.attestation.AttestationSpecificationAwsNitro.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.amdSnp = $root.attestation.AttestationSpecificationAmdSnp.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.intelDcapMrsigner = $root.attestation.AttestationSpecificationIntelDcapMrsigner.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AttestationSpecification message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof attestation.AttestationSpecification
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {attestation.AttestationSpecification} AttestationSpecification
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AttestationSpecification.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AttestationSpecification message.
         * @function verify
         * @memberof attestation.AttestationSpecification
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AttestationSpecification.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.intelEpid != null && message.hasOwnProperty("intelEpid")) {
                properties.attestationSpecification = 1;
                {
                    let error = $root.attestation.AttestationSpecificationIntelEpid.verify(message.intelEpid);
                    if (error)
                        return "intelEpid." + error;
                }
            }
            if (message.intelDcap != null && message.hasOwnProperty("intelDcap")) {
                if (properties.attestationSpecification === 1)
                    return "attestationSpecification: multiple values";
                properties.attestationSpecification = 1;
                {
                    let error = $root.attestation.AttestationSpecificationIntelDcap.verify(message.intelDcap);
                    if (error)
                        return "intelDcap." + error;
                }
            }
            if (message.awsNitro != null && message.hasOwnProperty("awsNitro")) {
                if (properties.attestationSpecification === 1)
                    return "attestationSpecification: multiple values";
                properties.attestationSpecification = 1;
                {
                    let error = $root.attestation.AttestationSpecificationAwsNitro.verify(message.awsNitro);
                    if (error)
                        return "awsNitro." + error;
                }
            }
            if (message.amdSnp != null && message.hasOwnProperty("amdSnp")) {
                if (properties.attestationSpecification === 1)
                    return "attestationSpecification: multiple values";
                properties.attestationSpecification = 1;
                {
                    let error = $root.attestation.AttestationSpecificationAmdSnp.verify(message.amdSnp);
                    if (error)
                        return "amdSnp." + error;
                }
            }
            if (message.intelDcapMrsigner != null && message.hasOwnProperty("intelDcapMrsigner")) {
                if (properties.attestationSpecification === 1)
                    return "attestationSpecification: multiple values";
                properties.attestationSpecification = 1;
                {
                    let error = $root.attestation.AttestationSpecificationIntelDcapMrsigner.verify(message.intelDcapMrsigner);
                    if (error)
                        return "intelDcapMrsigner." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AttestationSpecification message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof attestation.AttestationSpecification
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {attestation.AttestationSpecification} AttestationSpecification
         */
        AttestationSpecification.fromObject = function fromObject(object) {
            if (object instanceof $root.attestation.AttestationSpecification)
                return object;
            let message = new $root.attestation.AttestationSpecification();
            if (object.intelEpid != null) {
                if (typeof object.intelEpid !== "object")
                    throw TypeError(".attestation.AttestationSpecification.intelEpid: object expected");
                message.intelEpid = $root.attestation.AttestationSpecificationIntelEpid.fromObject(object.intelEpid);
            }
            if (object.intelDcap != null) {
                if (typeof object.intelDcap !== "object")
                    throw TypeError(".attestation.AttestationSpecification.intelDcap: object expected");
                message.intelDcap = $root.attestation.AttestationSpecificationIntelDcap.fromObject(object.intelDcap);
            }
            if (object.awsNitro != null) {
                if (typeof object.awsNitro !== "object")
                    throw TypeError(".attestation.AttestationSpecification.awsNitro: object expected");
                message.awsNitro = $root.attestation.AttestationSpecificationAwsNitro.fromObject(object.awsNitro);
            }
            if (object.amdSnp != null) {
                if (typeof object.amdSnp !== "object")
                    throw TypeError(".attestation.AttestationSpecification.amdSnp: object expected");
                message.amdSnp = $root.attestation.AttestationSpecificationAmdSnp.fromObject(object.amdSnp);
            }
            if (object.intelDcapMrsigner != null) {
                if (typeof object.intelDcapMrsigner !== "object")
                    throw TypeError(".attestation.AttestationSpecification.intelDcapMrsigner: object expected");
                message.intelDcapMrsigner = $root.attestation.AttestationSpecificationIntelDcapMrsigner.fromObject(object.intelDcapMrsigner);
            }
            return message;
        };

        /**
         * Creates a plain object from an AttestationSpecification message. Also converts values to other types if specified.
         * @function toObject
         * @memberof attestation.AttestationSpecification
         * @static
         * @param {attestation.AttestationSpecification} message AttestationSpecification
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AttestationSpecification.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.intelEpid != null && message.hasOwnProperty("intelEpid")) {
                object.intelEpid = $root.attestation.AttestationSpecificationIntelEpid.toObject(message.intelEpid, options);
                if (options.oneofs)
                    object.attestationSpecification = "intelEpid";
            }
            if (message.intelDcap != null && message.hasOwnProperty("intelDcap")) {
                object.intelDcap = $root.attestation.AttestationSpecificationIntelDcap.toObject(message.intelDcap, options);
                if (options.oneofs)
                    object.attestationSpecification = "intelDcap";
            }
            if (message.awsNitro != null && message.hasOwnProperty("awsNitro")) {
                object.awsNitro = $root.attestation.AttestationSpecificationAwsNitro.toObject(message.awsNitro, options);
                if (options.oneofs)
                    object.attestationSpecification = "awsNitro";
            }
            if (message.amdSnp != null && message.hasOwnProperty("amdSnp")) {
                object.amdSnp = $root.attestation.AttestationSpecificationAmdSnp.toObject(message.amdSnp, options);
                if (options.oneofs)
                    object.attestationSpecification = "amdSnp";
            }
            if (message.intelDcapMrsigner != null && message.hasOwnProperty("intelDcapMrsigner")) {
                object.intelDcapMrsigner = $root.attestation.AttestationSpecificationIntelDcapMrsigner.toObject(message.intelDcapMrsigner, options);
                if (options.oneofs)
                    object.attestationSpecification = "intelDcapMrsigner";
            }
            return object;
        };

        /**
         * Converts this AttestationSpecification to JSON.
         * @function toJSON
         * @memberof attestation.AttestationSpecification
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AttestationSpecification.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AttestationSpecification
         * @function getTypeUrl
         * @memberof attestation.AttestationSpecification
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AttestationSpecification.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/attestation.AttestationSpecification";
        };

        return AttestationSpecification;
    })();

    attestation.AttestationSpecificationIntelEpid = (function() {

        /**
         * Properties of an AttestationSpecificationIntelEpid.
         * @memberof attestation
         * @interface IAttestationSpecificationIntelEpid
         * @property {Uint8Array|null} [mrenclave] AttestationSpecificationIntelEpid mrenclave
         * @property {Uint8Array|null} [iasRootCaDer] AttestationSpecificationIntelEpid iasRootCaDer
         * @property {boolean|null} [acceptDebug] AttestationSpecificationIntelEpid acceptDebug
         * @property {boolean|null} [acceptGroupOutOfDate] AttestationSpecificationIntelEpid acceptGroupOutOfDate
         * @property {boolean|null} [acceptConfigurationNeeded] AttestationSpecificationIntelEpid acceptConfigurationNeeded
         */

        /**
         * Constructs a new AttestationSpecificationIntelEpid.
         * @memberof attestation
         * @classdesc Represents an AttestationSpecificationIntelEpid.
         * @implements IAttestationSpecificationIntelEpid
         * @constructor
         * @param {attestation.IAttestationSpecificationIntelEpid=} [properties] Properties to set
         */
        function AttestationSpecificationIntelEpid(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AttestationSpecificationIntelEpid mrenclave.
         * @member {Uint8Array} mrenclave
         * @memberof attestation.AttestationSpecificationIntelEpid
         * @instance
         */
        AttestationSpecificationIntelEpid.prototype.mrenclave = $util.newBuffer([]);

        /**
         * AttestationSpecificationIntelEpid iasRootCaDer.
         * @member {Uint8Array} iasRootCaDer
         * @memberof attestation.AttestationSpecificationIntelEpid
         * @instance
         */
        AttestationSpecificationIntelEpid.prototype.iasRootCaDer = $util.newBuffer([]);

        /**
         * AttestationSpecificationIntelEpid acceptDebug.
         * @member {boolean} acceptDebug
         * @memberof attestation.AttestationSpecificationIntelEpid
         * @instance
         */
        AttestationSpecificationIntelEpid.prototype.acceptDebug = false;

        /**
         * AttestationSpecificationIntelEpid acceptGroupOutOfDate.
         * @member {boolean} acceptGroupOutOfDate
         * @memberof attestation.AttestationSpecificationIntelEpid
         * @instance
         */
        AttestationSpecificationIntelEpid.prototype.acceptGroupOutOfDate = false;

        /**
         * AttestationSpecificationIntelEpid acceptConfigurationNeeded.
         * @member {boolean} acceptConfigurationNeeded
         * @memberof attestation.AttestationSpecificationIntelEpid
         * @instance
         */
        AttestationSpecificationIntelEpid.prototype.acceptConfigurationNeeded = false;

        /**
         * Creates a new AttestationSpecificationIntelEpid instance using the specified properties.
         * @function create
         * @memberof attestation.AttestationSpecificationIntelEpid
         * @static
         * @param {attestation.IAttestationSpecificationIntelEpid=} [properties] Properties to set
         * @returns {attestation.AttestationSpecificationIntelEpid} AttestationSpecificationIntelEpid instance
         */
        AttestationSpecificationIntelEpid.create = function create(properties) {
            return new AttestationSpecificationIntelEpid(properties);
        };

        /**
         * Encodes the specified AttestationSpecificationIntelEpid message. Does not implicitly {@link attestation.AttestationSpecificationIntelEpid.verify|verify} messages.
         * @function encode
         * @memberof attestation.AttestationSpecificationIntelEpid
         * @static
         * @param {attestation.IAttestationSpecificationIntelEpid} message AttestationSpecificationIntelEpid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AttestationSpecificationIntelEpid.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mrenclave != null && Object.hasOwnProperty.call(message, "mrenclave"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.mrenclave);
            if (message.iasRootCaDer != null && Object.hasOwnProperty.call(message, "iasRootCaDer"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.iasRootCaDer);
            if (message.acceptDebug != null && Object.hasOwnProperty.call(message, "acceptDebug"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.acceptDebug);
            if (message.acceptGroupOutOfDate != null && Object.hasOwnProperty.call(message, "acceptGroupOutOfDate"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.acceptGroupOutOfDate);
            if (message.acceptConfigurationNeeded != null && Object.hasOwnProperty.call(message, "acceptConfigurationNeeded"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.acceptConfigurationNeeded);
            return writer;
        };

        /**
         * Encodes the specified AttestationSpecificationIntelEpid message, length delimited. Does not implicitly {@link attestation.AttestationSpecificationIntelEpid.verify|verify} messages.
         * @function encodeDelimited
         * @memberof attestation.AttestationSpecificationIntelEpid
         * @static
         * @param {attestation.IAttestationSpecificationIntelEpid} message AttestationSpecificationIntelEpid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AttestationSpecificationIntelEpid.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AttestationSpecificationIntelEpid message from the specified reader or buffer.
         * @function decode
         * @memberof attestation.AttestationSpecificationIntelEpid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {attestation.AttestationSpecificationIntelEpid} AttestationSpecificationIntelEpid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AttestationSpecificationIntelEpid.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.attestation.AttestationSpecificationIntelEpid();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.mrenclave = reader.bytes();
                        break;
                    }
                case 2: {
                        message.iasRootCaDer = reader.bytes();
                        break;
                    }
                case 3: {
                        message.acceptDebug = reader.bool();
                        break;
                    }
                case 4: {
                        message.acceptGroupOutOfDate = reader.bool();
                        break;
                    }
                case 5: {
                        message.acceptConfigurationNeeded = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AttestationSpecificationIntelEpid message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof attestation.AttestationSpecificationIntelEpid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {attestation.AttestationSpecificationIntelEpid} AttestationSpecificationIntelEpid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AttestationSpecificationIntelEpid.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AttestationSpecificationIntelEpid message.
         * @function verify
         * @memberof attestation.AttestationSpecificationIntelEpid
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AttestationSpecificationIntelEpid.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mrenclave != null && message.hasOwnProperty("mrenclave"))
                if (!(message.mrenclave && typeof message.mrenclave.length === "number" || $util.isString(message.mrenclave)))
                    return "mrenclave: buffer expected";
            if (message.iasRootCaDer != null && message.hasOwnProperty("iasRootCaDer"))
                if (!(message.iasRootCaDer && typeof message.iasRootCaDer.length === "number" || $util.isString(message.iasRootCaDer)))
                    return "iasRootCaDer: buffer expected";
            if (message.acceptDebug != null && message.hasOwnProperty("acceptDebug"))
                if (typeof message.acceptDebug !== "boolean")
                    return "acceptDebug: boolean expected";
            if (message.acceptGroupOutOfDate != null && message.hasOwnProperty("acceptGroupOutOfDate"))
                if (typeof message.acceptGroupOutOfDate !== "boolean")
                    return "acceptGroupOutOfDate: boolean expected";
            if (message.acceptConfigurationNeeded != null && message.hasOwnProperty("acceptConfigurationNeeded"))
                if (typeof message.acceptConfigurationNeeded !== "boolean")
                    return "acceptConfigurationNeeded: boolean expected";
            return null;
        };

        /**
         * Creates an AttestationSpecificationIntelEpid message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof attestation.AttestationSpecificationIntelEpid
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {attestation.AttestationSpecificationIntelEpid} AttestationSpecificationIntelEpid
         */
        AttestationSpecificationIntelEpid.fromObject = function fromObject(object) {
            if (object instanceof $root.attestation.AttestationSpecificationIntelEpid)
                return object;
            let message = new $root.attestation.AttestationSpecificationIntelEpid();
            if (object.mrenclave != null)
                if (typeof object.mrenclave === "string")
                    $util.base64.decode(object.mrenclave, message.mrenclave = $util.newBuffer($util.base64.length(object.mrenclave)), 0);
                else if (object.mrenclave.length >= 0)
                    message.mrenclave = object.mrenclave;
            if (object.iasRootCaDer != null)
                if (typeof object.iasRootCaDer === "string")
                    $util.base64.decode(object.iasRootCaDer, message.iasRootCaDer = $util.newBuffer($util.base64.length(object.iasRootCaDer)), 0);
                else if (object.iasRootCaDer.length >= 0)
                    message.iasRootCaDer = object.iasRootCaDer;
            if (object.acceptDebug != null)
                message.acceptDebug = Boolean(object.acceptDebug);
            if (object.acceptGroupOutOfDate != null)
                message.acceptGroupOutOfDate = Boolean(object.acceptGroupOutOfDate);
            if (object.acceptConfigurationNeeded != null)
                message.acceptConfigurationNeeded = Boolean(object.acceptConfigurationNeeded);
            return message;
        };

        /**
         * Creates a plain object from an AttestationSpecificationIntelEpid message. Also converts values to other types if specified.
         * @function toObject
         * @memberof attestation.AttestationSpecificationIntelEpid
         * @static
         * @param {attestation.AttestationSpecificationIntelEpid} message AttestationSpecificationIntelEpid
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AttestationSpecificationIntelEpid.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.mrenclave = "";
                else {
                    object.mrenclave = [];
                    if (options.bytes !== Array)
                        object.mrenclave = $util.newBuffer(object.mrenclave);
                }
                if (options.bytes === String)
                    object.iasRootCaDer = "";
                else {
                    object.iasRootCaDer = [];
                    if (options.bytes !== Array)
                        object.iasRootCaDer = $util.newBuffer(object.iasRootCaDer);
                }
                object.acceptDebug = false;
                object.acceptGroupOutOfDate = false;
                object.acceptConfigurationNeeded = false;
            }
            if (message.mrenclave != null && message.hasOwnProperty("mrenclave"))
                object.mrenclave = options.bytes === String ? $util.base64.encode(message.mrenclave, 0, message.mrenclave.length) : options.bytes === Array ? Array.prototype.slice.call(message.mrenclave) : message.mrenclave;
            if (message.iasRootCaDer != null && message.hasOwnProperty("iasRootCaDer"))
                object.iasRootCaDer = options.bytes === String ? $util.base64.encode(message.iasRootCaDer, 0, message.iasRootCaDer.length) : options.bytes === Array ? Array.prototype.slice.call(message.iasRootCaDer) : message.iasRootCaDer;
            if (message.acceptDebug != null && message.hasOwnProperty("acceptDebug"))
                object.acceptDebug = message.acceptDebug;
            if (message.acceptGroupOutOfDate != null && message.hasOwnProperty("acceptGroupOutOfDate"))
                object.acceptGroupOutOfDate = message.acceptGroupOutOfDate;
            if (message.acceptConfigurationNeeded != null && message.hasOwnProperty("acceptConfigurationNeeded"))
                object.acceptConfigurationNeeded = message.acceptConfigurationNeeded;
            return object;
        };

        /**
         * Converts this AttestationSpecificationIntelEpid to JSON.
         * @function toJSON
         * @memberof attestation.AttestationSpecificationIntelEpid
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AttestationSpecificationIntelEpid.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AttestationSpecificationIntelEpid
         * @function getTypeUrl
         * @memberof attestation.AttestationSpecificationIntelEpid
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AttestationSpecificationIntelEpid.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/attestation.AttestationSpecificationIntelEpid";
        };

        return AttestationSpecificationIntelEpid;
    })();

    attestation.AttestationSpecificationIntelDcap = (function() {

        /**
         * Properties of an AttestationSpecificationIntelDcap.
         * @memberof attestation
         * @interface IAttestationSpecificationIntelDcap
         * @property {Uint8Array|null} [mrenclave] AttestationSpecificationIntelDcap mrenclave
         * @property {Uint8Array|null} [dcapRootCaDer] AttestationSpecificationIntelDcap dcapRootCaDer
         * @property {boolean|null} [acceptDebug] AttestationSpecificationIntelDcap acceptDebug
         * @property {boolean|null} [acceptOutOfDate] AttestationSpecificationIntelDcap acceptOutOfDate
         * @property {boolean|null} [acceptConfigurationNeeded] AttestationSpecificationIntelDcap acceptConfigurationNeeded
         * @property {boolean|null} [acceptRevoked] AttestationSpecificationIntelDcap acceptRevoked
         */

        /**
         * Constructs a new AttestationSpecificationIntelDcap.
         * @memberof attestation
         * @classdesc Represents an AttestationSpecificationIntelDcap.
         * @implements IAttestationSpecificationIntelDcap
         * @constructor
         * @param {attestation.IAttestationSpecificationIntelDcap=} [properties] Properties to set
         */
        function AttestationSpecificationIntelDcap(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AttestationSpecificationIntelDcap mrenclave.
         * @member {Uint8Array} mrenclave
         * @memberof attestation.AttestationSpecificationIntelDcap
         * @instance
         */
        AttestationSpecificationIntelDcap.prototype.mrenclave = $util.newBuffer([]);

        /**
         * AttestationSpecificationIntelDcap dcapRootCaDer.
         * @member {Uint8Array} dcapRootCaDer
         * @memberof attestation.AttestationSpecificationIntelDcap
         * @instance
         */
        AttestationSpecificationIntelDcap.prototype.dcapRootCaDer = $util.newBuffer([]);

        /**
         * AttestationSpecificationIntelDcap acceptDebug.
         * @member {boolean} acceptDebug
         * @memberof attestation.AttestationSpecificationIntelDcap
         * @instance
         */
        AttestationSpecificationIntelDcap.prototype.acceptDebug = false;

        /**
         * AttestationSpecificationIntelDcap acceptOutOfDate.
         * @member {boolean} acceptOutOfDate
         * @memberof attestation.AttestationSpecificationIntelDcap
         * @instance
         */
        AttestationSpecificationIntelDcap.prototype.acceptOutOfDate = false;

        /**
         * AttestationSpecificationIntelDcap acceptConfigurationNeeded.
         * @member {boolean} acceptConfigurationNeeded
         * @memberof attestation.AttestationSpecificationIntelDcap
         * @instance
         */
        AttestationSpecificationIntelDcap.prototype.acceptConfigurationNeeded = false;

        /**
         * AttestationSpecificationIntelDcap acceptRevoked.
         * @member {boolean} acceptRevoked
         * @memberof attestation.AttestationSpecificationIntelDcap
         * @instance
         */
        AttestationSpecificationIntelDcap.prototype.acceptRevoked = false;

        /**
         * Creates a new AttestationSpecificationIntelDcap instance using the specified properties.
         * @function create
         * @memberof attestation.AttestationSpecificationIntelDcap
         * @static
         * @param {attestation.IAttestationSpecificationIntelDcap=} [properties] Properties to set
         * @returns {attestation.AttestationSpecificationIntelDcap} AttestationSpecificationIntelDcap instance
         */
        AttestationSpecificationIntelDcap.create = function create(properties) {
            return new AttestationSpecificationIntelDcap(properties);
        };

        /**
         * Encodes the specified AttestationSpecificationIntelDcap message. Does not implicitly {@link attestation.AttestationSpecificationIntelDcap.verify|verify} messages.
         * @function encode
         * @memberof attestation.AttestationSpecificationIntelDcap
         * @static
         * @param {attestation.IAttestationSpecificationIntelDcap} message AttestationSpecificationIntelDcap message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AttestationSpecificationIntelDcap.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mrenclave != null && Object.hasOwnProperty.call(message, "mrenclave"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.mrenclave);
            if (message.dcapRootCaDer != null && Object.hasOwnProperty.call(message, "dcapRootCaDer"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.dcapRootCaDer);
            if (message.acceptDebug != null && Object.hasOwnProperty.call(message, "acceptDebug"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.acceptDebug);
            if (message.acceptOutOfDate != null && Object.hasOwnProperty.call(message, "acceptOutOfDate"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.acceptOutOfDate);
            if (message.acceptConfigurationNeeded != null && Object.hasOwnProperty.call(message, "acceptConfigurationNeeded"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.acceptConfigurationNeeded);
            if (message.acceptRevoked != null && Object.hasOwnProperty.call(message, "acceptRevoked"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.acceptRevoked);
            return writer;
        };

        /**
         * Encodes the specified AttestationSpecificationIntelDcap message, length delimited. Does not implicitly {@link attestation.AttestationSpecificationIntelDcap.verify|verify} messages.
         * @function encodeDelimited
         * @memberof attestation.AttestationSpecificationIntelDcap
         * @static
         * @param {attestation.IAttestationSpecificationIntelDcap} message AttestationSpecificationIntelDcap message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AttestationSpecificationIntelDcap.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AttestationSpecificationIntelDcap message from the specified reader or buffer.
         * @function decode
         * @memberof attestation.AttestationSpecificationIntelDcap
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {attestation.AttestationSpecificationIntelDcap} AttestationSpecificationIntelDcap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AttestationSpecificationIntelDcap.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.attestation.AttestationSpecificationIntelDcap();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.mrenclave = reader.bytes();
                        break;
                    }
                case 2: {
                        message.dcapRootCaDer = reader.bytes();
                        break;
                    }
                case 3: {
                        message.acceptDebug = reader.bool();
                        break;
                    }
                case 4: {
                        message.acceptOutOfDate = reader.bool();
                        break;
                    }
                case 5: {
                        message.acceptConfigurationNeeded = reader.bool();
                        break;
                    }
                case 6: {
                        message.acceptRevoked = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AttestationSpecificationIntelDcap message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof attestation.AttestationSpecificationIntelDcap
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {attestation.AttestationSpecificationIntelDcap} AttestationSpecificationIntelDcap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AttestationSpecificationIntelDcap.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AttestationSpecificationIntelDcap message.
         * @function verify
         * @memberof attestation.AttestationSpecificationIntelDcap
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AttestationSpecificationIntelDcap.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mrenclave != null && message.hasOwnProperty("mrenclave"))
                if (!(message.mrenclave && typeof message.mrenclave.length === "number" || $util.isString(message.mrenclave)))
                    return "mrenclave: buffer expected";
            if (message.dcapRootCaDer != null && message.hasOwnProperty("dcapRootCaDer"))
                if (!(message.dcapRootCaDer && typeof message.dcapRootCaDer.length === "number" || $util.isString(message.dcapRootCaDer)))
                    return "dcapRootCaDer: buffer expected";
            if (message.acceptDebug != null && message.hasOwnProperty("acceptDebug"))
                if (typeof message.acceptDebug !== "boolean")
                    return "acceptDebug: boolean expected";
            if (message.acceptOutOfDate != null && message.hasOwnProperty("acceptOutOfDate"))
                if (typeof message.acceptOutOfDate !== "boolean")
                    return "acceptOutOfDate: boolean expected";
            if (message.acceptConfigurationNeeded != null && message.hasOwnProperty("acceptConfigurationNeeded"))
                if (typeof message.acceptConfigurationNeeded !== "boolean")
                    return "acceptConfigurationNeeded: boolean expected";
            if (message.acceptRevoked != null && message.hasOwnProperty("acceptRevoked"))
                if (typeof message.acceptRevoked !== "boolean")
                    return "acceptRevoked: boolean expected";
            return null;
        };

        /**
         * Creates an AttestationSpecificationIntelDcap message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof attestation.AttestationSpecificationIntelDcap
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {attestation.AttestationSpecificationIntelDcap} AttestationSpecificationIntelDcap
         */
        AttestationSpecificationIntelDcap.fromObject = function fromObject(object) {
            if (object instanceof $root.attestation.AttestationSpecificationIntelDcap)
                return object;
            let message = new $root.attestation.AttestationSpecificationIntelDcap();
            if (object.mrenclave != null)
                if (typeof object.mrenclave === "string")
                    $util.base64.decode(object.mrenclave, message.mrenclave = $util.newBuffer($util.base64.length(object.mrenclave)), 0);
                else if (object.mrenclave.length >= 0)
                    message.mrenclave = object.mrenclave;
            if (object.dcapRootCaDer != null)
                if (typeof object.dcapRootCaDer === "string")
                    $util.base64.decode(object.dcapRootCaDer, message.dcapRootCaDer = $util.newBuffer($util.base64.length(object.dcapRootCaDer)), 0);
                else if (object.dcapRootCaDer.length >= 0)
                    message.dcapRootCaDer = object.dcapRootCaDer;
            if (object.acceptDebug != null)
                message.acceptDebug = Boolean(object.acceptDebug);
            if (object.acceptOutOfDate != null)
                message.acceptOutOfDate = Boolean(object.acceptOutOfDate);
            if (object.acceptConfigurationNeeded != null)
                message.acceptConfigurationNeeded = Boolean(object.acceptConfigurationNeeded);
            if (object.acceptRevoked != null)
                message.acceptRevoked = Boolean(object.acceptRevoked);
            return message;
        };

        /**
         * Creates a plain object from an AttestationSpecificationIntelDcap message. Also converts values to other types if specified.
         * @function toObject
         * @memberof attestation.AttestationSpecificationIntelDcap
         * @static
         * @param {attestation.AttestationSpecificationIntelDcap} message AttestationSpecificationIntelDcap
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AttestationSpecificationIntelDcap.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.mrenclave = "";
                else {
                    object.mrenclave = [];
                    if (options.bytes !== Array)
                        object.mrenclave = $util.newBuffer(object.mrenclave);
                }
                if (options.bytes === String)
                    object.dcapRootCaDer = "";
                else {
                    object.dcapRootCaDer = [];
                    if (options.bytes !== Array)
                        object.dcapRootCaDer = $util.newBuffer(object.dcapRootCaDer);
                }
                object.acceptDebug = false;
                object.acceptOutOfDate = false;
                object.acceptConfigurationNeeded = false;
                object.acceptRevoked = false;
            }
            if (message.mrenclave != null && message.hasOwnProperty("mrenclave"))
                object.mrenclave = options.bytes === String ? $util.base64.encode(message.mrenclave, 0, message.mrenclave.length) : options.bytes === Array ? Array.prototype.slice.call(message.mrenclave) : message.mrenclave;
            if (message.dcapRootCaDer != null && message.hasOwnProperty("dcapRootCaDer"))
                object.dcapRootCaDer = options.bytes === String ? $util.base64.encode(message.dcapRootCaDer, 0, message.dcapRootCaDer.length) : options.bytes === Array ? Array.prototype.slice.call(message.dcapRootCaDer) : message.dcapRootCaDer;
            if (message.acceptDebug != null && message.hasOwnProperty("acceptDebug"))
                object.acceptDebug = message.acceptDebug;
            if (message.acceptOutOfDate != null && message.hasOwnProperty("acceptOutOfDate"))
                object.acceptOutOfDate = message.acceptOutOfDate;
            if (message.acceptConfigurationNeeded != null && message.hasOwnProperty("acceptConfigurationNeeded"))
                object.acceptConfigurationNeeded = message.acceptConfigurationNeeded;
            if (message.acceptRevoked != null && message.hasOwnProperty("acceptRevoked"))
                object.acceptRevoked = message.acceptRevoked;
            return object;
        };

        /**
         * Converts this AttestationSpecificationIntelDcap to JSON.
         * @function toJSON
         * @memberof attestation.AttestationSpecificationIntelDcap
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AttestationSpecificationIntelDcap.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AttestationSpecificationIntelDcap
         * @function getTypeUrl
         * @memberof attestation.AttestationSpecificationIntelDcap
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AttestationSpecificationIntelDcap.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/attestation.AttestationSpecificationIntelDcap";
        };

        return AttestationSpecificationIntelDcap;
    })();

    attestation.AttestationSpecificationAwsNitro = (function() {

        /**
         * Properties of an AttestationSpecificationAwsNitro.
         * @memberof attestation
         * @interface IAttestationSpecificationAwsNitro
         * @property {Uint8Array|null} [nitroRootCaDer] AttestationSpecificationAwsNitro nitroRootCaDer
         * @property {Uint8Array|null} [pcr0] AttestationSpecificationAwsNitro pcr0
         * @property {Uint8Array|null} [pcr1] AttestationSpecificationAwsNitro pcr1
         * @property {Uint8Array|null} [pcr2] AttestationSpecificationAwsNitro pcr2
         * @property {Uint8Array|null} [pcr8] AttestationSpecificationAwsNitro pcr8
         */

        /**
         * Constructs a new AttestationSpecificationAwsNitro.
         * @memberof attestation
         * @classdesc Represents an AttestationSpecificationAwsNitro.
         * @implements IAttestationSpecificationAwsNitro
         * @constructor
         * @param {attestation.IAttestationSpecificationAwsNitro=} [properties] Properties to set
         */
        function AttestationSpecificationAwsNitro(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AttestationSpecificationAwsNitro nitroRootCaDer.
         * @member {Uint8Array} nitroRootCaDer
         * @memberof attestation.AttestationSpecificationAwsNitro
         * @instance
         */
        AttestationSpecificationAwsNitro.prototype.nitroRootCaDer = $util.newBuffer([]);

        /**
         * AttestationSpecificationAwsNitro pcr0.
         * @member {Uint8Array} pcr0
         * @memberof attestation.AttestationSpecificationAwsNitro
         * @instance
         */
        AttestationSpecificationAwsNitro.prototype.pcr0 = $util.newBuffer([]);

        /**
         * AttestationSpecificationAwsNitro pcr1.
         * @member {Uint8Array} pcr1
         * @memberof attestation.AttestationSpecificationAwsNitro
         * @instance
         */
        AttestationSpecificationAwsNitro.prototype.pcr1 = $util.newBuffer([]);

        /**
         * AttestationSpecificationAwsNitro pcr2.
         * @member {Uint8Array} pcr2
         * @memberof attestation.AttestationSpecificationAwsNitro
         * @instance
         */
        AttestationSpecificationAwsNitro.prototype.pcr2 = $util.newBuffer([]);

        /**
         * AttestationSpecificationAwsNitro pcr8.
         * @member {Uint8Array} pcr8
         * @memberof attestation.AttestationSpecificationAwsNitro
         * @instance
         */
        AttestationSpecificationAwsNitro.prototype.pcr8 = $util.newBuffer([]);

        /**
         * Creates a new AttestationSpecificationAwsNitro instance using the specified properties.
         * @function create
         * @memberof attestation.AttestationSpecificationAwsNitro
         * @static
         * @param {attestation.IAttestationSpecificationAwsNitro=} [properties] Properties to set
         * @returns {attestation.AttestationSpecificationAwsNitro} AttestationSpecificationAwsNitro instance
         */
        AttestationSpecificationAwsNitro.create = function create(properties) {
            return new AttestationSpecificationAwsNitro(properties);
        };

        /**
         * Encodes the specified AttestationSpecificationAwsNitro message. Does not implicitly {@link attestation.AttestationSpecificationAwsNitro.verify|verify} messages.
         * @function encode
         * @memberof attestation.AttestationSpecificationAwsNitro
         * @static
         * @param {attestation.IAttestationSpecificationAwsNitro} message AttestationSpecificationAwsNitro message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AttestationSpecificationAwsNitro.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nitroRootCaDer != null && Object.hasOwnProperty.call(message, "nitroRootCaDer"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.nitroRootCaDer);
            if (message.pcr0 != null && Object.hasOwnProperty.call(message, "pcr0"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.pcr0);
            if (message.pcr1 != null && Object.hasOwnProperty.call(message, "pcr1"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.pcr1);
            if (message.pcr2 != null && Object.hasOwnProperty.call(message, "pcr2"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.pcr2);
            if (message.pcr8 != null && Object.hasOwnProperty.call(message, "pcr8"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.pcr8);
            return writer;
        };

        /**
         * Encodes the specified AttestationSpecificationAwsNitro message, length delimited. Does not implicitly {@link attestation.AttestationSpecificationAwsNitro.verify|verify} messages.
         * @function encodeDelimited
         * @memberof attestation.AttestationSpecificationAwsNitro
         * @static
         * @param {attestation.IAttestationSpecificationAwsNitro} message AttestationSpecificationAwsNitro message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AttestationSpecificationAwsNitro.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AttestationSpecificationAwsNitro message from the specified reader or buffer.
         * @function decode
         * @memberof attestation.AttestationSpecificationAwsNitro
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {attestation.AttestationSpecificationAwsNitro} AttestationSpecificationAwsNitro
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AttestationSpecificationAwsNitro.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.attestation.AttestationSpecificationAwsNitro();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.nitroRootCaDer = reader.bytes();
                        break;
                    }
                case 2: {
                        message.pcr0 = reader.bytes();
                        break;
                    }
                case 3: {
                        message.pcr1 = reader.bytes();
                        break;
                    }
                case 4: {
                        message.pcr2 = reader.bytes();
                        break;
                    }
                case 5: {
                        message.pcr8 = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AttestationSpecificationAwsNitro message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof attestation.AttestationSpecificationAwsNitro
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {attestation.AttestationSpecificationAwsNitro} AttestationSpecificationAwsNitro
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AttestationSpecificationAwsNitro.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AttestationSpecificationAwsNitro message.
         * @function verify
         * @memberof attestation.AttestationSpecificationAwsNitro
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AttestationSpecificationAwsNitro.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nitroRootCaDer != null && message.hasOwnProperty("nitroRootCaDer"))
                if (!(message.nitroRootCaDer && typeof message.nitroRootCaDer.length === "number" || $util.isString(message.nitroRootCaDer)))
                    return "nitroRootCaDer: buffer expected";
            if (message.pcr0 != null && message.hasOwnProperty("pcr0"))
                if (!(message.pcr0 && typeof message.pcr0.length === "number" || $util.isString(message.pcr0)))
                    return "pcr0: buffer expected";
            if (message.pcr1 != null && message.hasOwnProperty("pcr1"))
                if (!(message.pcr1 && typeof message.pcr1.length === "number" || $util.isString(message.pcr1)))
                    return "pcr1: buffer expected";
            if (message.pcr2 != null && message.hasOwnProperty("pcr2"))
                if (!(message.pcr2 && typeof message.pcr2.length === "number" || $util.isString(message.pcr2)))
                    return "pcr2: buffer expected";
            if (message.pcr8 != null && message.hasOwnProperty("pcr8"))
                if (!(message.pcr8 && typeof message.pcr8.length === "number" || $util.isString(message.pcr8)))
                    return "pcr8: buffer expected";
            return null;
        };

        /**
         * Creates an AttestationSpecificationAwsNitro message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof attestation.AttestationSpecificationAwsNitro
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {attestation.AttestationSpecificationAwsNitro} AttestationSpecificationAwsNitro
         */
        AttestationSpecificationAwsNitro.fromObject = function fromObject(object) {
            if (object instanceof $root.attestation.AttestationSpecificationAwsNitro)
                return object;
            let message = new $root.attestation.AttestationSpecificationAwsNitro();
            if (object.nitroRootCaDer != null)
                if (typeof object.nitroRootCaDer === "string")
                    $util.base64.decode(object.nitroRootCaDer, message.nitroRootCaDer = $util.newBuffer($util.base64.length(object.nitroRootCaDer)), 0);
                else if (object.nitroRootCaDer.length >= 0)
                    message.nitroRootCaDer = object.nitroRootCaDer;
            if (object.pcr0 != null)
                if (typeof object.pcr0 === "string")
                    $util.base64.decode(object.pcr0, message.pcr0 = $util.newBuffer($util.base64.length(object.pcr0)), 0);
                else if (object.pcr0.length >= 0)
                    message.pcr0 = object.pcr0;
            if (object.pcr1 != null)
                if (typeof object.pcr1 === "string")
                    $util.base64.decode(object.pcr1, message.pcr1 = $util.newBuffer($util.base64.length(object.pcr1)), 0);
                else if (object.pcr1.length >= 0)
                    message.pcr1 = object.pcr1;
            if (object.pcr2 != null)
                if (typeof object.pcr2 === "string")
                    $util.base64.decode(object.pcr2, message.pcr2 = $util.newBuffer($util.base64.length(object.pcr2)), 0);
                else if (object.pcr2.length >= 0)
                    message.pcr2 = object.pcr2;
            if (object.pcr8 != null)
                if (typeof object.pcr8 === "string")
                    $util.base64.decode(object.pcr8, message.pcr8 = $util.newBuffer($util.base64.length(object.pcr8)), 0);
                else if (object.pcr8.length >= 0)
                    message.pcr8 = object.pcr8;
            return message;
        };

        /**
         * Creates a plain object from an AttestationSpecificationAwsNitro message. Also converts values to other types if specified.
         * @function toObject
         * @memberof attestation.AttestationSpecificationAwsNitro
         * @static
         * @param {attestation.AttestationSpecificationAwsNitro} message AttestationSpecificationAwsNitro
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AttestationSpecificationAwsNitro.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.nitroRootCaDer = "";
                else {
                    object.nitroRootCaDer = [];
                    if (options.bytes !== Array)
                        object.nitroRootCaDer = $util.newBuffer(object.nitroRootCaDer);
                }
                if (options.bytes === String)
                    object.pcr0 = "";
                else {
                    object.pcr0 = [];
                    if (options.bytes !== Array)
                        object.pcr0 = $util.newBuffer(object.pcr0);
                }
                if (options.bytes === String)
                    object.pcr1 = "";
                else {
                    object.pcr1 = [];
                    if (options.bytes !== Array)
                        object.pcr1 = $util.newBuffer(object.pcr1);
                }
                if (options.bytes === String)
                    object.pcr2 = "";
                else {
                    object.pcr2 = [];
                    if (options.bytes !== Array)
                        object.pcr2 = $util.newBuffer(object.pcr2);
                }
                if (options.bytes === String)
                    object.pcr8 = "";
                else {
                    object.pcr8 = [];
                    if (options.bytes !== Array)
                        object.pcr8 = $util.newBuffer(object.pcr8);
                }
            }
            if (message.nitroRootCaDer != null && message.hasOwnProperty("nitroRootCaDer"))
                object.nitroRootCaDer = options.bytes === String ? $util.base64.encode(message.nitroRootCaDer, 0, message.nitroRootCaDer.length) : options.bytes === Array ? Array.prototype.slice.call(message.nitroRootCaDer) : message.nitroRootCaDer;
            if (message.pcr0 != null && message.hasOwnProperty("pcr0"))
                object.pcr0 = options.bytes === String ? $util.base64.encode(message.pcr0, 0, message.pcr0.length) : options.bytes === Array ? Array.prototype.slice.call(message.pcr0) : message.pcr0;
            if (message.pcr1 != null && message.hasOwnProperty("pcr1"))
                object.pcr1 = options.bytes === String ? $util.base64.encode(message.pcr1, 0, message.pcr1.length) : options.bytes === Array ? Array.prototype.slice.call(message.pcr1) : message.pcr1;
            if (message.pcr2 != null && message.hasOwnProperty("pcr2"))
                object.pcr2 = options.bytes === String ? $util.base64.encode(message.pcr2, 0, message.pcr2.length) : options.bytes === Array ? Array.prototype.slice.call(message.pcr2) : message.pcr2;
            if (message.pcr8 != null && message.hasOwnProperty("pcr8"))
                object.pcr8 = options.bytes === String ? $util.base64.encode(message.pcr8, 0, message.pcr8.length) : options.bytes === Array ? Array.prototype.slice.call(message.pcr8) : message.pcr8;
            return object;
        };

        /**
         * Converts this AttestationSpecificationAwsNitro to JSON.
         * @function toJSON
         * @memberof attestation.AttestationSpecificationAwsNitro
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AttestationSpecificationAwsNitro.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AttestationSpecificationAwsNitro
         * @function getTypeUrl
         * @memberof attestation.AttestationSpecificationAwsNitro
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AttestationSpecificationAwsNitro.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/attestation.AttestationSpecificationAwsNitro";
        };

        return AttestationSpecificationAwsNitro;
    })();

    attestation.AttestationSpecificationAmdSnp = (function() {

        /**
         * Properties of an AttestationSpecificationAmdSnp.
         * @memberof attestation
         * @interface IAttestationSpecificationAmdSnp
         * @property {Uint8Array|null} [amdArkDer] AttestationSpecificationAmdSnp amdArkDer
         * @property {Uint8Array|null} [measurement] AttestationSpecificationAmdSnp measurement
         * @property {Uint8Array|null} [roughtimePubKey] AttestationSpecificationAmdSnp roughtimePubKey
         * @property {Array.<Uint8Array>|null} [authorizedChipIds] AttestationSpecificationAmdSnp authorizedChipIds
         * @property {Uint8Array|null} [decentriqDer] AttestationSpecificationAmdSnp decentriqDer
         */

        /**
         * Constructs a new AttestationSpecificationAmdSnp.
         * @memberof attestation
         * @classdesc Represents an AttestationSpecificationAmdSnp.
         * @implements IAttestationSpecificationAmdSnp
         * @constructor
         * @param {attestation.IAttestationSpecificationAmdSnp=} [properties] Properties to set
         */
        function AttestationSpecificationAmdSnp(properties) {
            this.authorizedChipIds = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AttestationSpecificationAmdSnp amdArkDer.
         * @member {Uint8Array} amdArkDer
         * @memberof attestation.AttestationSpecificationAmdSnp
         * @instance
         */
        AttestationSpecificationAmdSnp.prototype.amdArkDer = $util.newBuffer([]);

        /**
         * AttestationSpecificationAmdSnp measurement.
         * @member {Uint8Array} measurement
         * @memberof attestation.AttestationSpecificationAmdSnp
         * @instance
         */
        AttestationSpecificationAmdSnp.prototype.measurement = $util.newBuffer([]);

        /**
         * AttestationSpecificationAmdSnp roughtimePubKey.
         * @member {Uint8Array} roughtimePubKey
         * @memberof attestation.AttestationSpecificationAmdSnp
         * @instance
         */
        AttestationSpecificationAmdSnp.prototype.roughtimePubKey = $util.newBuffer([]);

        /**
         * AttestationSpecificationAmdSnp authorizedChipIds.
         * @member {Array.<Uint8Array>} authorizedChipIds
         * @memberof attestation.AttestationSpecificationAmdSnp
         * @instance
         */
        AttestationSpecificationAmdSnp.prototype.authorizedChipIds = $util.emptyArray;

        /**
         * AttestationSpecificationAmdSnp decentriqDer.
         * @member {Uint8Array} decentriqDer
         * @memberof attestation.AttestationSpecificationAmdSnp
         * @instance
         */
        AttestationSpecificationAmdSnp.prototype.decentriqDer = $util.newBuffer([]);

        /**
         * Creates a new AttestationSpecificationAmdSnp instance using the specified properties.
         * @function create
         * @memberof attestation.AttestationSpecificationAmdSnp
         * @static
         * @param {attestation.IAttestationSpecificationAmdSnp=} [properties] Properties to set
         * @returns {attestation.AttestationSpecificationAmdSnp} AttestationSpecificationAmdSnp instance
         */
        AttestationSpecificationAmdSnp.create = function create(properties) {
            return new AttestationSpecificationAmdSnp(properties);
        };

        /**
         * Encodes the specified AttestationSpecificationAmdSnp message. Does not implicitly {@link attestation.AttestationSpecificationAmdSnp.verify|verify} messages.
         * @function encode
         * @memberof attestation.AttestationSpecificationAmdSnp
         * @static
         * @param {attestation.IAttestationSpecificationAmdSnp} message AttestationSpecificationAmdSnp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AttestationSpecificationAmdSnp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.amdArkDer != null && Object.hasOwnProperty.call(message, "amdArkDer"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.amdArkDer);
            if (message.measurement != null && Object.hasOwnProperty.call(message, "measurement"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.measurement);
            if (message.roughtimePubKey != null && Object.hasOwnProperty.call(message, "roughtimePubKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.roughtimePubKey);
            if (message.authorizedChipIds != null && message.authorizedChipIds.length)
                for (let i = 0; i < message.authorizedChipIds.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.authorizedChipIds[i]);
            if (message.decentriqDer != null && Object.hasOwnProperty.call(message, "decentriqDer"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.decentriqDer);
            return writer;
        };

        /**
         * Encodes the specified AttestationSpecificationAmdSnp message, length delimited. Does not implicitly {@link attestation.AttestationSpecificationAmdSnp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof attestation.AttestationSpecificationAmdSnp
         * @static
         * @param {attestation.IAttestationSpecificationAmdSnp} message AttestationSpecificationAmdSnp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AttestationSpecificationAmdSnp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AttestationSpecificationAmdSnp message from the specified reader or buffer.
         * @function decode
         * @memberof attestation.AttestationSpecificationAmdSnp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {attestation.AttestationSpecificationAmdSnp} AttestationSpecificationAmdSnp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AttestationSpecificationAmdSnp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.attestation.AttestationSpecificationAmdSnp();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.amdArkDer = reader.bytes();
                        break;
                    }
                case 2: {
                        message.measurement = reader.bytes();
                        break;
                    }
                case 3: {
                        message.roughtimePubKey = reader.bytes();
                        break;
                    }
                case 4: {
                        if (!(message.authorizedChipIds && message.authorizedChipIds.length))
                            message.authorizedChipIds = [];
                        message.authorizedChipIds.push(reader.bytes());
                        break;
                    }
                case 5: {
                        message.decentriqDer = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AttestationSpecificationAmdSnp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof attestation.AttestationSpecificationAmdSnp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {attestation.AttestationSpecificationAmdSnp} AttestationSpecificationAmdSnp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AttestationSpecificationAmdSnp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AttestationSpecificationAmdSnp message.
         * @function verify
         * @memberof attestation.AttestationSpecificationAmdSnp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AttestationSpecificationAmdSnp.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.amdArkDer != null && message.hasOwnProperty("amdArkDer"))
                if (!(message.amdArkDer && typeof message.amdArkDer.length === "number" || $util.isString(message.amdArkDer)))
                    return "amdArkDer: buffer expected";
            if (message.measurement != null && message.hasOwnProperty("measurement"))
                if (!(message.measurement && typeof message.measurement.length === "number" || $util.isString(message.measurement)))
                    return "measurement: buffer expected";
            if (message.roughtimePubKey != null && message.hasOwnProperty("roughtimePubKey"))
                if (!(message.roughtimePubKey && typeof message.roughtimePubKey.length === "number" || $util.isString(message.roughtimePubKey)))
                    return "roughtimePubKey: buffer expected";
            if (message.authorizedChipIds != null && message.hasOwnProperty("authorizedChipIds")) {
                if (!Array.isArray(message.authorizedChipIds))
                    return "authorizedChipIds: array expected";
                for (let i = 0; i < message.authorizedChipIds.length; ++i)
                    if (!(message.authorizedChipIds[i] && typeof message.authorizedChipIds[i].length === "number" || $util.isString(message.authorizedChipIds[i])))
                        return "authorizedChipIds: buffer[] expected";
            }
            if (message.decentriqDer != null && message.hasOwnProperty("decentriqDer"))
                if (!(message.decentriqDer && typeof message.decentriqDer.length === "number" || $util.isString(message.decentriqDer)))
                    return "decentriqDer: buffer expected";
            return null;
        };

        /**
         * Creates an AttestationSpecificationAmdSnp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof attestation.AttestationSpecificationAmdSnp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {attestation.AttestationSpecificationAmdSnp} AttestationSpecificationAmdSnp
         */
        AttestationSpecificationAmdSnp.fromObject = function fromObject(object) {
            if (object instanceof $root.attestation.AttestationSpecificationAmdSnp)
                return object;
            let message = new $root.attestation.AttestationSpecificationAmdSnp();
            if (object.amdArkDer != null)
                if (typeof object.amdArkDer === "string")
                    $util.base64.decode(object.amdArkDer, message.amdArkDer = $util.newBuffer($util.base64.length(object.amdArkDer)), 0);
                else if (object.amdArkDer.length >= 0)
                    message.amdArkDer = object.amdArkDer;
            if (object.measurement != null)
                if (typeof object.measurement === "string")
                    $util.base64.decode(object.measurement, message.measurement = $util.newBuffer($util.base64.length(object.measurement)), 0);
                else if (object.measurement.length >= 0)
                    message.measurement = object.measurement;
            if (object.roughtimePubKey != null)
                if (typeof object.roughtimePubKey === "string")
                    $util.base64.decode(object.roughtimePubKey, message.roughtimePubKey = $util.newBuffer($util.base64.length(object.roughtimePubKey)), 0);
                else if (object.roughtimePubKey.length >= 0)
                    message.roughtimePubKey = object.roughtimePubKey;
            if (object.authorizedChipIds) {
                if (!Array.isArray(object.authorizedChipIds))
                    throw TypeError(".attestation.AttestationSpecificationAmdSnp.authorizedChipIds: array expected");
                message.authorizedChipIds = [];
                for (let i = 0; i < object.authorizedChipIds.length; ++i)
                    if (typeof object.authorizedChipIds[i] === "string")
                        $util.base64.decode(object.authorizedChipIds[i], message.authorizedChipIds[i] = $util.newBuffer($util.base64.length(object.authorizedChipIds[i])), 0);
                    else if (object.authorizedChipIds[i].length >= 0)
                        message.authorizedChipIds[i] = object.authorizedChipIds[i];
            }
            if (object.decentriqDer != null)
                if (typeof object.decentriqDer === "string")
                    $util.base64.decode(object.decentriqDer, message.decentriqDer = $util.newBuffer($util.base64.length(object.decentriqDer)), 0);
                else if (object.decentriqDer.length >= 0)
                    message.decentriqDer = object.decentriqDer;
            return message;
        };

        /**
         * Creates a plain object from an AttestationSpecificationAmdSnp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof attestation.AttestationSpecificationAmdSnp
         * @static
         * @param {attestation.AttestationSpecificationAmdSnp} message AttestationSpecificationAmdSnp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AttestationSpecificationAmdSnp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.authorizedChipIds = [];
            if (options.defaults) {
                if (options.bytes === String)
                    object.amdArkDer = "";
                else {
                    object.amdArkDer = [];
                    if (options.bytes !== Array)
                        object.amdArkDer = $util.newBuffer(object.amdArkDer);
                }
                if (options.bytes === String)
                    object.measurement = "";
                else {
                    object.measurement = [];
                    if (options.bytes !== Array)
                        object.measurement = $util.newBuffer(object.measurement);
                }
                if (options.bytes === String)
                    object.roughtimePubKey = "";
                else {
                    object.roughtimePubKey = [];
                    if (options.bytes !== Array)
                        object.roughtimePubKey = $util.newBuffer(object.roughtimePubKey);
                }
                if (options.bytes === String)
                    object.decentriqDer = "";
                else {
                    object.decentriqDer = [];
                    if (options.bytes !== Array)
                        object.decentriqDer = $util.newBuffer(object.decentriqDer);
                }
            }
            if (message.amdArkDer != null && message.hasOwnProperty("amdArkDer"))
                object.amdArkDer = options.bytes === String ? $util.base64.encode(message.amdArkDer, 0, message.amdArkDer.length) : options.bytes === Array ? Array.prototype.slice.call(message.amdArkDer) : message.amdArkDer;
            if (message.measurement != null && message.hasOwnProperty("measurement"))
                object.measurement = options.bytes === String ? $util.base64.encode(message.measurement, 0, message.measurement.length) : options.bytes === Array ? Array.prototype.slice.call(message.measurement) : message.measurement;
            if (message.roughtimePubKey != null && message.hasOwnProperty("roughtimePubKey"))
                object.roughtimePubKey = options.bytes === String ? $util.base64.encode(message.roughtimePubKey, 0, message.roughtimePubKey.length) : options.bytes === Array ? Array.prototype.slice.call(message.roughtimePubKey) : message.roughtimePubKey;
            if (message.authorizedChipIds && message.authorizedChipIds.length) {
                object.authorizedChipIds = [];
                for (let j = 0; j < message.authorizedChipIds.length; ++j)
                    object.authorizedChipIds[j] = options.bytes === String ? $util.base64.encode(message.authorizedChipIds[j], 0, message.authorizedChipIds[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.authorizedChipIds[j]) : message.authorizedChipIds[j];
            }
            if (message.decentriqDer != null && message.hasOwnProperty("decentriqDer"))
                object.decentriqDer = options.bytes === String ? $util.base64.encode(message.decentriqDer, 0, message.decentriqDer.length) : options.bytes === Array ? Array.prototype.slice.call(message.decentriqDer) : message.decentriqDer;
            return object;
        };

        /**
         * Converts this AttestationSpecificationAmdSnp to JSON.
         * @function toJSON
         * @memberof attestation.AttestationSpecificationAmdSnp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AttestationSpecificationAmdSnp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AttestationSpecificationAmdSnp
         * @function getTypeUrl
         * @memberof attestation.AttestationSpecificationAmdSnp
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AttestationSpecificationAmdSnp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/attestation.AttestationSpecificationAmdSnp";
        };

        return AttestationSpecificationAmdSnp;
    })();

    attestation.AttestationSpecificationIntelDcapMrsigner = (function() {

        /**
         * Properties of an AttestationSpecificationIntelDcapMrsigner.
         * @memberof attestation
         * @interface IAttestationSpecificationIntelDcapMrsigner
         * @property {Uint8Array|null} [mrsigner] AttestationSpecificationIntelDcapMrsigner mrsigner
         * @property {Uint8Array|null} [dcapRootCaDer] AttestationSpecificationIntelDcapMrsigner dcapRootCaDer
         * @property {boolean|null} [acceptDebug] AttestationSpecificationIntelDcapMrsigner acceptDebug
         * @property {boolean|null} [acceptOutOfDate] AttestationSpecificationIntelDcapMrsigner acceptOutOfDate
         * @property {boolean|null} [acceptConfigurationNeeded] AttestationSpecificationIntelDcapMrsigner acceptConfigurationNeeded
         * @property {boolean|null} [acceptRevoked] AttestationSpecificationIntelDcapMrsigner acceptRevoked
         * @property {number|null} [isvprodid] AttestationSpecificationIntelDcapMrsigner isvprodid
         * @property {Uint8Array|null} [sigstoreRootOlpcJson] AttestationSpecificationIntelDcapMrsigner sigstoreRootOlpcJson
         */

        /**
         * Constructs a new AttestationSpecificationIntelDcapMrsigner.
         * @memberof attestation
         * @classdesc Represents an AttestationSpecificationIntelDcapMrsigner.
         * @implements IAttestationSpecificationIntelDcapMrsigner
         * @constructor
         * @param {attestation.IAttestationSpecificationIntelDcapMrsigner=} [properties] Properties to set
         */
        function AttestationSpecificationIntelDcapMrsigner(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AttestationSpecificationIntelDcapMrsigner mrsigner.
         * @member {Uint8Array} mrsigner
         * @memberof attestation.AttestationSpecificationIntelDcapMrsigner
         * @instance
         */
        AttestationSpecificationIntelDcapMrsigner.prototype.mrsigner = $util.newBuffer([]);

        /**
         * AttestationSpecificationIntelDcapMrsigner dcapRootCaDer.
         * @member {Uint8Array} dcapRootCaDer
         * @memberof attestation.AttestationSpecificationIntelDcapMrsigner
         * @instance
         */
        AttestationSpecificationIntelDcapMrsigner.prototype.dcapRootCaDer = $util.newBuffer([]);

        /**
         * AttestationSpecificationIntelDcapMrsigner acceptDebug.
         * @member {boolean} acceptDebug
         * @memberof attestation.AttestationSpecificationIntelDcapMrsigner
         * @instance
         */
        AttestationSpecificationIntelDcapMrsigner.prototype.acceptDebug = false;

        /**
         * AttestationSpecificationIntelDcapMrsigner acceptOutOfDate.
         * @member {boolean} acceptOutOfDate
         * @memberof attestation.AttestationSpecificationIntelDcapMrsigner
         * @instance
         */
        AttestationSpecificationIntelDcapMrsigner.prototype.acceptOutOfDate = false;

        /**
         * AttestationSpecificationIntelDcapMrsigner acceptConfigurationNeeded.
         * @member {boolean} acceptConfigurationNeeded
         * @memberof attestation.AttestationSpecificationIntelDcapMrsigner
         * @instance
         */
        AttestationSpecificationIntelDcapMrsigner.prototype.acceptConfigurationNeeded = false;

        /**
         * AttestationSpecificationIntelDcapMrsigner acceptRevoked.
         * @member {boolean} acceptRevoked
         * @memberof attestation.AttestationSpecificationIntelDcapMrsigner
         * @instance
         */
        AttestationSpecificationIntelDcapMrsigner.prototype.acceptRevoked = false;

        /**
         * AttestationSpecificationIntelDcapMrsigner isvprodid.
         * @member {number} isvprodid
         * @memberof attestation.AttestationSpecificationIntelDcapMrsigner
         * @instance
         */
        AttestationSpecificationIntelDcapMrsigner.prototype.isvprodid = 0;

        /**
         * AttestationSpecificationIntelDcapMrsigner sigstoreRootOlpcJson.
         * @member {Uint8Array} sigstoreRootOlpcJson
         * @memberof attestation.AttestationSpecificationIntelDcapMrsigner
         * @instance
         */
        AttestationSpecificationIntelDcapMrsigner.prototype.sigstoreRootOlpcJson = $util.newBuffer([]);

        /**
         * Creates a new AttestationSpecificationIntelDcapMrsigner instance using the specified properties.
         * @function create
         * @memberof attestation.AttestationSpecificationIntelDcapMrsigner
         * @static
         * @param {attestation.IAttestationSpecificationIntelDcapMrsigner=} [properties] Properties to set
         * @returns {attestation.AttestationSpecificationIntelDcapMrsigner} AttestationSpecificationIntelDcapMrsigner instance
         */
        AttestationSpecificationIntelDcapMrsigner.create = function create(properties) {
            return new AttestationSpecificationIntelDcapMrsigner(properties);
        };

        /**
         * Encodes the specified AttestationSpecificationIntelDcapMrsigner message. Does not implicitly {@link attestation.AttestationSpecificationIntelDcapMrsigner.verify|verify} messages.
         * @function encode
         * @memberof attestation.AttestationSpecificationIntelDcapMrsigner
         * @static
         * @param {attestation.IAttestationSpecificationIntelDcapMrsigner} message AttestationSpecificationIntelDcapMrsigner message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AttestationSpecificationIntelDcapMrsigner.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mrsigner != null && Object.hasOwnProperty.call(message, "mrsigner"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.mrsigner);
            if (message.dcapRootCaDer != null && Object.hasOwnProperty.call(message, "dcapRootCaDer"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.dcapRootCaDer);
            if (message.acceptDebug != null && Object.hasOwnProperty.call(message, "acceptDebug"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.acceptDebug);
            if (message.acceptOutOfDate != null && Object.hasOwnProperty.call(message, "acceptOutOfDate"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.acceptOutOfDate);
            if (message.acceptConfigurationNeeded != null && Object.hasOwnProperty.call(message, "acceptConfigurationNeeded"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.acceptConfigurationNeeded);
            if (message.acceptRevoked != null && Object.hasOwnProperty.call(message, "acceptRevoked"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.acceptRevoked);
            if (message.isvprodid != null && Object.hasOwnProperty.call(message, "isvprodid"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.isvprodid);
            if (message.sigstoreRootOlpcJson != null && Object.hasOwnProperty.call(message, "sigstoreRootOlpcJson"))
                writer.uint32(/* id 8, wireType 2 =*/66).bytes(message.sigstoreRootOlpcJson);
            return writer;
        };

        /**
         * Encodes the specified AttestationSpecificationIntelDcapMrsigner message, length delimited. Does not implicitly {@link attestation.AttestationSpecificationIntelDcapMrsigner.verify|verify} messages.
         * @function encodeDelimited
         * @memberof attestation.AttestationSpecificationIntelDcapMrsigner
         * @static
         * @param {attestation.IAttestationSpecificationIntelDcapMrsigner} message AttestationSpecificationIntelDcapMrsigner message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AttestationSpecificationIntelDcapMrsigner.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AttestationSpecificationIntelDcapMrsigner message from the specified reader or buffer.
         * @function decode
         * @memberof attestation.AttestationSpecificationIntelDcapMrsigner
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {attestation.AttestationSpecificationIntelDcapMrsigner} AttestationSpecificationIntelDcapMrsigner
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AttestationSpecificationIntelDcapMrsigner.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.attestation.AttestationSpecificationIntelDcapMrsigner();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.mrsigner = reader.bytes();
                        break;
                    }
                case 2: {
                        message.dcapRootCaDer = reader.bytes();
                        break;
                    }
                case 3: {
                        message.acceptDebug = reader.bool();
                        break;
                    }
                case 4: {
                        message.acceptOutOfDate = reader.bool();
                        break;
                    }
                case 5: {
                        message.acceptConfigurationNeeded = reader.bool();
                        break;
                    }
                case 6: {
                        message.acceptRevoked = reader.bool();
                        break;
                    }
                case 7: {
                        message.isvprodid = reader.uint32();
                        break;
                    }
                case 8: {
                        message.sigstoreRootOlpcJson = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AttestationSpecificationIntelDcapMrsigner message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof attestation.AttestationSpecificationIntelDcapMrsigner
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {attestation.AttestationSpecificationIntelDcapMrsigner} AttestationSpecificationIntelDcapMrsigner
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AttestationSpecificationIntelDcapMrsigner.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AttestationSpecificationIntelDcapMrsigner message.
         * @function verify
         * @memberof attestation.AttestationSpecificationIntelDcapMrsigner
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AttestationSpecificationIntelDcapMrsigner.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mrsigner != null && message.hasOwnProperty("mrsigner"))
                if (!(message.mrsigner && typeof message.mrsigner.length === "number" || $util.isString(message.mrsigner)))
                    return "mrsigner: buffer expected";
            if (message.dcapRootCaDer != null && message.hasOwnProperty("dcapRootCaDer"))
                if (!(message.dcapRootCaDer && typeof message.dcapRootCaDer.length === "number" || $util.isString(message.dcapRootCaDer)))
                    return "dcapRootCaDer: buffer expected";
            if (message.acceptDebug != null && message.hasOwnProperty("acceptDebug"))
                if (typeof message.acceptDebug !== "boolean")
                    return "acceptDebug: boolean expected";
            if (message.acceptOutOfDate != null && message.hasOwnProperty("acceptOutOfDate"))
                if (typeof message.acceptOutOfDate !== "boolean")
                    return "acceptOutOfDate: boolean expected";
            if (message.acceptConfigurationNeeded != null && message.hasOwnProperty("acceptConfigurationNeeded"))
                if (typeof message.acceptConfigurationNeeded !== "boolean")
                    return "acceptConfigurationNeeded: boolean expected";
            if (message.acceptRevoked != null && message.hasOwnProperty("acceptRevoked"))
                if (typeof message.acceptRevoked !== "boolean")
                    return "acceptRevoked: boolean expected";
            if (message.isvprodid != null && message.hasOwnProperty("isvprodid"))
                if (!$util.isInteger(message.isvprodid))
                    return "isvprodid: integer expected";
            if (message.sigstoreRootOlpcJson != null && message.hasOwnProperty("sigstoreRootOlpcJson"))
                if (!(message.sigstoreRootOlpcJson && typeof message.sigstoreRootOlpcJson.length === "number" || $util.isString(message.sigstoreRootOlpcJson)))
                    return "sigstoreRootOlpcJson: buffer expected";
            return null;
        };

        /**
         * Creates an AttestationSpecificationIntelDcapMrsigner message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof attestation.AttestationSpecificationIntelDcapMrsigner
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {attestation.AttestationSpecificationIntelDcapMrsigner} AttestationSpecificationIntelDcapMrsigner
         */
        AttestationSpecificationIntelDcapMrsigner.fromObject = function fromObject(object) {
            if (object instanceof $root.attestation.AttestationSpecificationIntelDcapMrsigner)
                return object;
            let message = new $root.attestation.AttestationSpecificationIntelDcapMrsigner();
            if (object.mrsigner != null)
                if (typeof object.mrsigner === "string")
                    $util.base64.decode(object.mrsigner, message.mrsigner = $util.newBuffer($util.base64.length(object.mrsigner)), 0);
                else if (object.mrsigner.length >= 0)
                    message.mrsigner = object.mrsigner;
            if (object.dcapRootCaDer != null)
                if (typeof object.dcapRootCaDer === "string")
                    $util.base64.decode(object.dcapRootCaDer, message.dcapRootCaDer = $util.newBuffer($util.base64.length(object.dcapRootCaDer)), 0);
                else if (object.dcapRootCaDer.length >= 0)
                    message.dcapRootCaDer = object.dcapRootCaDer;
            if (object.acceptDebug != null)
                message.acceptDebug = Boolean(object.acceptDebug);
            if (object.acceptOutOfDate != null)
                message.acceptOutOfDate = Boolean(object.acceptOutOfDate);
            if (object.acceptConfigurationNeeded != null)
                message.acceptConfigurationNeeded = Boolean(object.acceptConfigurationNeeded);
            if (object.acceptRevoked != null)
                message.acceptRevoked = Boolean(object.acceptRevoked);
            if (object.isvprodid != null)
                message.isvprodid = object.isvprodid >>> 0;
            if (object.sigstoreRootOlpcJson != null)
                if (typeof object.sigstoreRootOlpcJson === "string")
                    $util.base64.decode(object.sigstoreRootOlpcJson, message.sigstoreRootOlpcJson = $util.newBuffer($util.base64.length(object.sigstoreRootOlpcJson)), 0);
                else if (object.sigstoreRootOlpcJson.length >= 0)
                    message.sigstoreRootOlpcJson = object.sigstoreRootOlpcJson;
            return message;
        };

        /**
         * Creates a plain object from an AttestationSpecificationIntelDcapMrsigner message. Also converts values to other types if specified.
         * @function toObject
         * @memberof attestation.AttestationSpecificationIntelDcapMrsigner
         * @static
         * @param {attestation.AttestationSpecificationIntelDcapMrsigner} message AttestationSpecificationIntelDcapMrsigner
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AttestationSpecificationIntelDcapMrsigner.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.mrsigner = "";
                else {
                    object.mrsigner = [];
                    if (options.bytes !== Array)
                        object.mrsigner = $util.newBuffer(object.mrsigner);
                }
                if (options.bytes === String)
                    object.dcapRootCaDer = "";
                else {
                    object.dcapRootCaDer = [];
                    if (options.bytes !== Array)
                        object.dcapRootCaDer = $util.newBuffer(object.dcapRootCaDer);
                }
                object.acceptDebug = false;
                object.acceptOutOfDate = false;
                object.acceptConfigurationNeeded = false;
                object.acceptRevoked = false;
                object.isvprodid = 0;
                if (options.bytes === String)
                    object.sigstoreRootOlpcJson = "";
                else {
                    object.sigstoreRootOlpcJson = [];
                    if (options.bytes !== Array)
                        object.sigstoreRootOlpcJson = $util.newBuffer(object.sigstoreRootOlpcJson);
                }
            }
            if (message.mrsigner != null && message.hasOwnProperty("mrsigner"))
                object.mrsigner = options.bytes === String ? $util.base64.encode(message.mrsigner, 0, message.mrsigner.length) : options.bytes === Array ? Array.prototype.slice.call(message.mrsigner) : message.mrsigner;
            if (message.dcapRootCaDer != null && message.hasOwnProperty("dcapRootCaDer"))
                object.dcapRootCaDer = options.bytes === String ? $util.base64.encode(message.dcapRootCaDer, 0, message.dcapRootCaDer.length) : options.bytes === Array ? Array.prototype.slice.call(message.dcapRootCaDer) : message.dcapRootCaDer;
            if (message.acceptDebug != null && message.hasOwnProperty("acceptDebug"))
                object.acceptDebug = message.acceptDebug;
            if (message.acceptOutOfDate != null && message.hasOwnProperty("acceptOutOfDate"))
                object.acceptOutOfDate = message.acceptOutOfDate;
            if (message.acceptConfigurationNeeded != null && message.hasOwnProperty("acceptConfigurationNeeded"))
                object.acceptConfigurationNeeded = message.acceptConfigurationNeeded;
            if (message.acceptRevoked != null && message.hasOwnProperty("acceptRevoked"))
                object.acceptRevoked = message.acceptRevoked;
            if (message.isvprodid != null && message.hasOwnProperty("isvprodid"))
                object.isvprodid = message.isvprodid;
            if (message.sigstoreRootOlpcJson != null && message.hasOwnProperty("sigstoreRootOlpcJson"))
                object.sigstoreRootOlpcJson = options.bytes === String ? $util.base64.encode(message.sigstoreRootOlpcJson, 0, message.sigstoreRootOlpcJson.length) : options.bytes === Array ? Array.prototype.slice.call(message.sigstoreRootOlpcJson) : message.sigstoreRootOlpcJson;
            return object;
        };

        /**
         * Converts this AttestationSpecificationIntelDcapMrsigner to JSON.
         * @function toJSON
         * @memberof attestation.AttestationSpecificationIntelDcapMrsigner
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AttestationSpecificationIntelDcapMrsigner.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AttestationSpecificationIntelDcapMrsigner
         * @function getTypeUrl
         * @memberof attestation.AttestationSpecificationIntelDcapMrsigner
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AttestationSpecificationIntelDcapMrsigner.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/attestation.AttestationSpecificationIntelDcapMrsigner";
        };

        return AttestationSpecificationIntelDcapMrsigner;
    })();

    return attestation;
})();

export const azure_blob_storage = $root.azure_blob_storage = (() => {

    /**
     * Namespace azure_blob_storage.
     * @exports azure_blob_storage
     * @namespace
     */
    const azure_blob_storage = {};

    azure_blob_storage.ImportRole = (function() {

        /**
         * Properties of an ImportRole.
         * @memberof azure_blob_storage
         * @interface IImportRole
         */

        /**
         * Constructs a new ImportRole.
         * @memberof azure_blob_storage
         * @classdesc Represents an ImportRole.
         * @implements IImportRole
         * @constructor
         * @param {azure_blob_storage.IImportRole=} [properties] Properties to set
         */
        function ImportRole(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ImportRole instance using the specified properties.
         * @function create
         * @memberof azure_blob_storage.ImportRole
         * @static
         * @param {azure_blob_storage.IImportRole=} [properties] Properties to set
         * @returns {azure_blob_storage.ImportRole} ImportRole instance
         */
        ImportRole.create = function create(properties) {
            return new ImportRole(properties);
        };

        /**
         * Encodes the specified ImportRole message. Does not implicitly {@link azure_blob_storage.ImportRole.verify|verify} messages.
         * @function encode
         * @memberof azure_blob_storage.ImportRole
         * @static
         * @param {azure_blob_storage.IImportRole} message ImportRole message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImportRole.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ImportRole message, length delimited. Does not implicitly {@link azure_blob_storage.ImportRole.verify|verify} messages.
         * @function encodeDelimited
         * @memberof azure_blob_storage.ImportRole
         * @static
         * @param {azure_blob_storage.IImportRole} message ImportRole message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImportRole.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ImportRole message from the specified reader or buffer.
         * @function decode
         * @memberof azure_blob_storage.ImportRole
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {azure_blob_storage.ImportRole} ImportRole
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImportRole.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.azure_blob_storage.ImportRole();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ImportRole message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof azure_blob_storage.ImportRole
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {azure_blob_storage.ImportRole} ImportRole
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImportRole.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ImportRole message.
         * @function verify
         * @memberof azure_blob_storage.ImportRole
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ImportRole.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an ImportRole message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof azure_blob_storage.ImportRole
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {azure_blob_storage.ImportRole} ImportRole
         */
        ImportRole.fromObject = function fromObject(object) {
            if (object instanceof $root.azure_blob_storage.ImportRole)
                return object;
            return new $root.azure_blob_storage.ImportRole();
        };

        /**
         * Creates a plain object from an ImportRole message. Also converts values to other types if specified.
         * @function toObject
         * @memberof azure_blob_storage.ImportRole
         * @static
         * @param {azure_blob_storage.ImportRole} message ImportRole
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImportRole.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ImportRole to JSON.
         * @function toJSON
         * @memberof azure_blob_storage.ImportRole
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImportRole.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ImportRole
         * @function getTypeUrl
         * @memberof azure_blob_storage.ImportRole
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ImportRole.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/azure_blob_storage.ImportRole";
        };

        return ImportRole;
    })();

    azure_blob_storage.ExportRole = (function() {

        /**
         * Properties of an ExportRole.
         * @memberof azure_blob_storage
         * @interface IExportRole
         * @property {azure_blob_storage.ISinkInput|null} [input] ExportRole input
         */

        /**
         * Constructs a new ExportRole.
         * @memberof azure_blob_storage
         * @classdesc Represents an ExportRole.
         * @implements IExportRole
         * @constructor
         * @param {azure_blob_storage.IExportRole=} [properties] Properties to set
         */
        function ExportRole(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExportRole input.
         * @member {azure_blob_storage.ISinkInput|null|undefined} input
         * @memberof azure_blob_storage.ExportRole
         * @instance
         */
        ExportRole.prototype.input = null;

        /**
         * Creates a new ExportRole instance using the specified properties.
         * @function create
         * @memberof azure_blob_storage.ExportRole
         * @static
         * @param {azure_blob_storage.IExportRole=} [properties] Properties to set
         * @returns {azure_blob_storage.ExportRole} ExportRole instance
         */
        ExportRole.create = function create(properties) {
            return new ExportRole(properties);
        };

        /**
         * Encodes the specified ExportRole message. Does not implicitly {@link azure_blob_storage.ExportRole.verify|verify} messages.
         * @function encode
         * @memberof azure_blob_storage.ExportRole
         * @static
         * @param {azure_blob_storage.IExportRole} message ExportRole message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExportRole.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.input != null && Object.hasOwnProperty.call(message, "input"))
                $root.azure_blob_storage.SinkInput.encode(message.input, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ExportRole message, length delimited. Does not implicitly {@link azure_blob_storage.ExportRole.verify|verify} messages.
         * @function encodeDelimited
         * @memberof azure_blob_storage.ExportRole
         * @static
         * @param {azure_blob_storage.IExportRole} message ExportRole message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExportRole.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExportRole message from the specified reader or buffer.
         * @function decode
         * @memberof azure_blob_storage.ExportRole
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {azure_blob_storage.ExportRole} ExportRole
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExportRole.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.azure_blob_storage.ExportRole();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.input = $root.azure_blob_storage.SinkInput.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExportRole message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof azure_blob_storage.ExportRole
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {azure_blob_storage.ExportRole} ExportRole
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExportRole.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExportRole message.
         * @function verify
         * @memberof azure_blob_storage.ExportRole
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExportRole.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.input != null && message.hasOwnProperty("input")) {
                let error = $root.azure_blob_storage.SinkInput.verify(message.input);
                if (error)
                    return "input." + error;
            }
            return null;
        };

        /**
         * Creates an ExportRole message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof azure_blob_storage.ExportRole
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {azure_blob_storage.ExportRole} ExportRole
         */
        ExportRole.fromObject = function fromObject(object) {
            if (object instanceof $root.azure_blob_storage.ExportRole)
                return object;
            let message = new $root.azure_blob_storage.ExportRole();
            if (object.input != null) {
                if (typeof object.input !== "object")
                    throw TypeError(".azure_blob_storage.ExportRole.input: object expected");
                message.input = $root.azure_blob_storage.SinkInput.fromObject(object.input);
            }
            return message;
        };

        /**
         * Creates a plain object from an ExportRole message. Also converts values to other types if specified.
         * @function toObject
         * @memberof azure_blob_storage.ExportRole
         * @static
         * @param {azure_blob_storage.ExportRole} message ExportRole
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExportRole.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.input = null;
            if (message.input != null && message.hasOwnProperty("input"))
                object.input = $root.azure_blob_storage.SinkInput.toObject(message.input, options);
            return object;
        };

        /**
         * Converts this ExportRole to JSON.
         * @function toJSON
         * @memberof azure_blob_storage.ExportRole
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExportRole.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ExportRole
         * @function getTypeUrl
         * @memberof azure_blob_storage.ExportRole
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExportRole.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/azure_blob_storage.ExportRole";
        };

        return ExportRole;
    })();

    azure_blob_storage.SingleFile = (function() {

        /**
         * Properties of a SingleFile.
         * @memberof azure_blob_storage
         * @interface ISingleFile
         * @property {string|null} [name] SingleFile name
         */

        /**
         * Constructs a new SingleFile.
         * @memberof azure_blob_storage
         * @classdesc Represents a SingleFile.
         * @implements ISingleFile
         * @constructor
         * @param {azure_blob_storage.ISingleFile=} [properties] Properties to set
         */
        function SingleFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SingleFile name.
         * @member {string} name
         * @memberof azure_blob_storage.SingleFile
         * @instance
         */
        SingleFile.prototype.name = "";

        /**
         * Creates a new SingleFile instance using the specified properties.
         * @function create
         * @memberof azure_blob_storage.SingleFile
         * @static
         * @param {azure_blob_storage.ISingleFile=} [properties] Properties to set
         * @returns {azure_blob_storage.SingleFile} SingleFile instance
         */
        SingleFile.create = function create(properties) {
            return new SingleFile(properties);
        };

        /**
         * Encodes the specified SingleFile message. Does not implicitly {@link azure_blob_storage.SingleFile.verify|verify} messages.
         * @function encode
         * @memberof azure_blob_storage.SingleFile
         * @static
         * @param {azure_blob_storage.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified SingleFile message, length delimited. Does not implicitly {@link azure_blob_storage.SingleFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof azure_blob_storage.SingleFile
         * @static
         * @param {azure_blob_storage.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer.
         * @function decode
         * @memberof azure_blob_storage.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {azure_blob_storage.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.azure_blob_storage.SingleFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof azure_blob_storage.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {azure_blob_storage.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SingleFile message.
         * @function verify
         * @memberof azure_blob_storage.SingleFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SingleFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a SingleFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof azure_blob_storage.SingleFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {azure_blob_storage.SingleFile} SingleFile
         */
        SingleFile.fromObject = function fromObject(object) {
            if (object instanceof $root.azure_blob_storage.SingleFile)
                return object;
            let message = new $root.azure_blob_storage.SingleFile();
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a SingleFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof azure_blob_storage.SingleFile
         * @static
         * @param {azure_blob_storage.SingleFile} message SingleFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SingleFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this SingleFile to JSON.
         * @function toJSON
         * @memberof azure_blob_storage.SingleFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SingleFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SingleFile
         * @function getTypeUrl
         * @memberof azure_blob_storage.SingleFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SingleFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/azure_blob_storage.SingleFile";
        };

        return SingleFile;
    })();

    azure_blob_storage.RawFile = (function() {

        /**
         * Properties of a RawFile.
         * @memberof azure_blob_storage
         * @interface IRawFile
         */

        /**
         * Constructs a new RawFile.
         * @memberof azure_blob_storage
         * @classdesc Represents a RawFile.
         * @implements IRawFile
         * @constructor
         * @param {azure_blob_storage.IRawFile=} [properties] Properties to set
         */
        function RawFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RawFile instance using the specified properties.
         * @function create
         * @memberof azure_blob_storage.RawFile
         * @static
         * @param {azure_blob_storage.IRawFile=} [properties] Properties to set
         * @returns {azure_blob_storage.RawFile} RawFile instance
         */
        RawFile.create = function create(properties) {
            return new RawFile(properties);
        };

        /**
         * Encodes the specified RawFile message. Does not implicitly {@link azure_blob_storage.RawFile.verify|verify} messages.
         * @function encode
         * @memberof azure_blob_storage.RawFile
         * @static
         * @param {azure_blob_storage.IRawFile} message RawFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RawFile message, length delimited. Does not implicitly {@link azure_blob_storage.RawFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof azure_blob_storage.RawFile
         * @static
         * @param {azure_blob_storage.IRawFile} message RawFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RawFile message from the specified reader or buffer.
         * @function decode
         * @memberof azure_blob_storage.RawFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {azure_blob_storage.RawFile} RawFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.azure_blob_storage.RawFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RawFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof azure_blob_storage.RawFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {azure_blob_storage.RawFile} RawFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RawFile message.
         * @function verify
         * @memberof azure_blob_storage.RawFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RawFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RawFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof azure_blob_storage.RawFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {azure_blob_storage.RawFile} RawFile
         */
        RawFile.fromObject = function fromObject(object) {
            if (object instanceof $root.azure_blob_storage.RawFile)
                return object;
            return new $root.azure_blob_storage.RawFile();
        };

        /**
         * Creates a plain object from a RawFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof azure_blob_storage.RawFile
         * @static
         * @param {azure_blob_storage.RawFile} message RawFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RawFile.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RawFile to JSON.
         * @function toJSON
         * @memberof azure_blob_storage.RawFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RawFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RawFile
         * @function getTypeUrl
         * @memberof azure_blob_storage.RawFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RawFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/azure_blob_storage.RawFile";
        };

        return RawFile;
    })();

    azure_blob_storage.ZipFile = (function() {

        /**
         * Properties of a ZipFile.
         * @memberof azure_blob_storage
         * @interface IZipFile
         * @property {azure_blob_storage.ISingleFile|null} [singleFile] ZipFile singleFile
         */

        /**
         * Constructs a new ZipFile.
         * @memberof azure_blob_storage
         * @classdesc Represents a ZipFile.
         * @implements IZipFile
         * @constructor
         * @param {azure_blob_storage.IZipFile=} [properties] Properties to set
         */
        function ZipFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ZipFile singleFile.
         * @member {azure_blob_storage.ISingleFile|null|undefined} singleFile
         * @memberof azure_blob_storage.ZipFile
         * @instance
         */
        ZipFile.prototype.singleFile = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ZipFile selection.
         * @member {"singleFile"|undefined} selection
         * @memberof azure_blob_storage.ZipFile
         * @instance
         */
        Object.defineProperty(ZipFile.prototype, "selection", {
            get: $util.oneOfGetter($oneOfFields = ["singleFile"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ZipFile instance using the specified properties.
         * @function create
         * @memberof azure_blob_storage.ZipFile
         * @static
         * @param {azure_blob_storage.IZipFile=} [properties] Properties to set
         * @returns {azure_blob_storage.ZipFile} ZipFile instance
         */
        ZipFile.create = function create(properties) {
            return new ZipFile(properties);
        };

        /**
         * Encodes the specified ZipFile message. Does not implicitly {@link azure_blob_storage.ZipFile.verify|verify} messages.
         * @function encode
         * @memberof azure_blob_storage.ZipFile
         * @static
         * @param {azure_blob_storage.IZipFile} message ZipFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.singleFile != null && Object.hasOwnProperty.call(message, "singleFile"))
                $root.azure_blob_storage.SingleFile.encode(message.singleFile, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ZipFile message, length delimited. Does not implicitly {@link azure_blob_storage.ZipFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof azure_blob_storage.ZipFile
         * @static
         * @param {azure_blob_storage.IZipFile} message ZipFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ZipFile message from the specified reader or buffer.
         * @function decode
         * @memberof azure_blob_storage.ZipFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {azure_blob_storage.ZipFile} ZipFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.azure_blob_storage.ZipFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.singleFile = $root.azure_blob_storage.SingleFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ZipFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof azure_blob_storage.ZipFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {azure_blob_storage.ZipFile} ZipFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ZipFile message.
         * @function verify
         * @memberof azure_blob_storage.ZipFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ZipFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.singleFile != null && message.hasOwnProperty("singleFile")) {
                properties.selection = 1;
                {
                    let error = $root.azure_blob_storage.SingleFile.verify(message.singleFile);
                    if (error)
                        return "singleFile." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ZipFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof azure_blob_storage.ZipFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {azure_blob_storage.ZipFile} ZipFile
         */
        ZipFile.fromObject = function fromObject(object) {
            if (object instanceof $root.azure_blob_storage.ZipFile)
                return object;
            let message = new $root.azure_blob_storage.ZipFile();
            if (object.singleFile != null) {
                if (typeof object.singleFile !== "object")
                    throw TypeError(".azure_blob_storage.ZipFile.singleFile: object expected");
                message.singleFile = $root.azure_blob_storage.SingleFile.fromObject(object.singleFile);
            }
            return message;
        };

        /**
         * Creates a plain object from a ZipFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof azure_blob_storage.ZipFile
         * @static
         * @param {azure_blob_storage.ZipFile} message ZipFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ZipFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.singleFile != null && message.hasOwnProperty("singleFile")) {
                object.singleFile = $root.azure_blob_storage.SingleFile.toObject(message.singleFile, options);
                if (options.oneofs)
                    object.selection = "singleFile";
            }
            return object;
        };

        /**
         * Converts this ZipFile to JSON.
         * @function toJSON
         * @memberof azure_blob_storage.ZipFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ZipFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ZipFile
         * @function getTypeUrl
         * @memberof azure_blob_storage.ZipFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ZipFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/azure_blob_storage.ZipFile";
        };

        return ZipFile;
    })();

    azure_blob_storage.SinkInput = (function() {

        /**
         * Properties of a SinkInput.
         * @memberof azure_blob_storage
         * @interface ISinkInput
         * @property {string|null} [dependency] SinkInput dependency
         * @property {string|null} [name] SinkInput name
         * @property {azure_blob_storage.IRawFile|null} [raw] SinkInput raw
         * @property {azure_blob_storage.IZipFile|null} [zip] SinkInput zip
         */

        /**
         * Constructs a new SinkInput.
         * @memberof azure_blob_storage
         * @classdesc Represents a SinkInput.
         * @implements ISinkInput
         * @constructor
         * @param {azure_blob_storage.ISinkInput=} [properties] Properties to set
         */
        function SinkInput(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SinkInput dependency.
         * @member {string} dependency
         * @memberof azure_blob_storage.SinkInput
         * @instance
         */
        SinkInput.prototype.dependency = "";

        /**
         * SinkInput name.
         * @member {string} name
         * @memberof azure_blob_storage.SinkInput
         * @instance
         */
        SinkInput.prototype.name = "";

        /**
         * SinkInput raw.
         * @member {azure_blob_storage.IRawFile|null|undefined} raw
         * @memberof azure_blob_storage.SinkInput
         * @instance
         */
        SinkInput.prototype.raw = null;

        /**
         * SinkInput zip.
         * @member {azure_blob_storage.IZipFile|null|undefined} zip
         * @memberof azure_blob_storage.SinkInput
         * @instance
         */
        SinkInput.prototype.zip = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SinkInput file.
         * @member {"raw"|"zip"|undefined} file
         * @memberof azure_blob_storage.SinkInput
         * @instance
         */
        Object.defineProperty(SinkInput.prototype, "file", {
            get: $util.oneOfGetter($oneOfFields = ["raw", "zip"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SinkInput instance using the specified properties.
         * @function create
         * @memberof azure_blob_storage.SinkInput
         * @static
         * @param {azure_blob_storage.ISinkInput=} [properties] Properties to set
         * @returns {azure_blob_storage.SinkInput} SinkInput instance
         */
        SinkInput.create = function create(properties) {
            return new SinkInput(properties);
        };

        /**
         * Encodes the specified SinkInput message. Does not implicitly {@link azure_blob_storage.SinkInput.verify|verify} messages.
         * @function encode
         * @memberof azure_blob_storage.SinkInput
         * @static
         * @param {azure_blob_storage.ISinkInput} message SinkInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SinkInput.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dependency != null && Object.hasOwnProperty.call(message, "dependency"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dependency);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.raw != null && Object.hasOwnProperty.call(message, "raw"))
                $root.azure_blob_storage.RawFile.encode(message.raw, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.zip != null && Object.hasOwnProperty.call(message, "zip"))
                $root.azure_blob_storage.ZipFile.encode(message.zip, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SinkInput message, length delimited. Does not implicitly {@link azure_blob_storage.SinkInput.verify|verify} messages.
         * @function encodeDelimited
         * @memberof azure_blob_storage.SinkInput
         * @static
         * @param {azure_blob_storage.ISinkInput} message SinkInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SinkInput.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SinkInput message from the specified reader or buffer.
         * @function decode
         * @memberof azure_blob_storage.SinkInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {azure_blob_storage.SinkInput} SinkInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SinkInput.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.azure_blob_storage.SinkInput();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dependency = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.raw = $root.azure_blob_storage.RawFile.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.zip = $root.azure_blob_storage.ZipFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SinkInput message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof azure_blob_storage.SinkInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {azure_blob_storage.SinkInput} SinkInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SinkInput.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SinkInput message.
         * @function verify
         * @memberof azure_blob_storage.SinkInput
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SinkInput.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                if (!$util.isString(message.dependency))
                    return "dependency: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.raw != null && message.hasOwnProperty("raw")) {
                properties.file = 1;
                {
                    let error = $root.azure_blob_storage.RawFile.verify(message.raw);
                    if (error)
                        return "raw." + error;
                }
            }
            if (message.zip != null && message.hasOwnProperty("zip")) {
                if (properties.file === 1)
                    return "file: multiple values";
                properties.file = 1;
                {
                    let error = $root.azure_blob_storage.ZipFile.verify(message.zip);
                    if (error)
                        return "zip." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SinkInput message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof azure_blob_storage.SinkInput
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {azure_blob_storage.SinkInput} SinkInput
         */
        SinkInput.fromObject = function fromObject(object) {
            if (object instanceof $root.azure_blob_storage.SinkInput)
                return object;
            let message = new $root.azure_blob_storage.SinkInput();
            if (object.dependency != null)
                message.dependency = String(object.dependency);
            if (object.name != null)
                message.name = String(object.name);
            if (object.raw != null) {
                if (typeof object.raw !== "object")
                    throw TypeError(".azure_blob_storage.SinkInput.raw: object expected");
                message.raw = $root.azure_blob_storage.RawFile.fromObject(object.raw);
            }
            if (object.zip != null) {
                if (typeof object.zip !== "object")
                    throw TypeError(".azure_blob_storage.SinkInput.zip: object expected");
                message.zip = $root.azure_blob_storage.ZipFile.fromObject(object.zip);
            }
            return message;
        };

        /**
         * Creates a plain object from a SinkInput message. Also converts values to other types if specified.
         * @function toObject
         * @memberof azure_blob_storage.SinkInput
         * @static
         * @param {azure_blob_storage.SinkInput} message SinkInput
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SinkInput.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.dependency = "";
                object.name = "";
            }
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                object.dependency = message.dependency;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.raw != null && message.hasOwnProperty("raw")) {
                object.raw = $root.azure_blob_storage.RawFile.toObject(message.raw, options);
                if (options.oneofs)
                    object.file = "raw";
            }
            if (message.zip != null && message.hasOwnProperty("zip")) {
                object.zip = $root.azure_blob_storage.ZipFile.toObject(message.zip, options);
                if (options.oneofs)
                    object.file = "zip";
            }
            return object;
        };

        /**
         * Converts this SinkInput to JSON.
         * @function toJSON
         * @memberof azure_blob_storage.SinkInput
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SinkInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SinkInput
         * @function getTypeUrl
         * @memberof azure_blob_storage.SinkInput
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SinkInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/azure_blob_storage.SinkInput";
        };

        return SinkInput;
    })();

    azure_blob_storage.AzureBlobStorageWorkerConfiguration = (function() {

        /**
         * Properties of an AzureBlobStorageWorkerConfiguration.
         * @memberof azure_blob_storage
         * @interface IAzureBlobStorageWorkerConfiguration
         * @property {string|null} [credentialsDependency] AzureBlobStorageWorkerConfiguration credentialsDependency
         * @property {azure_blob_storage.IImportRole|null} [importRole] AzureBlobStorageWorkerConfiguration importRole
         * @property {azure_blob_storage.IExportRole|null} [exportRole] AzureBlobStorageWorkerConfiguration exportRole
         */

        /**
         * Constructs a new AzureBlobStorageWorkerConfiguration.
         * @memberof azure_blob_storage
         * @classdesc Represents an AzureBlobStorageWorkerConfiguration.
         * @implements IAzureBlobStorageWorkerConfiguration
         * @constructor
         * @param {azure_blob_storage.IAzureBlobStorageWorkerConfiguration=} [properties] Properties to set
         */
        function AzureBlobStorageWorkerConfiguration(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AzureBlobStorageWorkerConfiguration credentialsDependency.
         * @member {string} credentialsDependency
         * @memberof azure_blob_storage.AzureBlobStorageWorkerConfiguration
         * @instance
         */
        AzureBlobStorageWorkerConfiguration.prototype.credentialsDependency = "";

        /**
         * AzureBlobStorageWorkerConfiguration importRole.
         * @member {azure_blob_storage.IImportRole|null|undefined} importRole
         * @memberof azure_blob_storage.AzureBlobStorageWorkerConfiguration
         * @instance
         */
        AzureBlobStorageWorkerConfiguration.prototype.importRole = null;

        /**
         * AzureBlobStorageWorkerConfiguration exportRole.
         * @member {azure_blob_storage.IExportRole|null|undefined} exportRole
         * @memberof azure_blob_storage.AzureBlobStorageWorkerConfiguration
         * @instance
         */
        AzureBlobStorageWorkerConfiguration.prototype.exportRole = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * AzureBlobStorageWorkerConfiguration role.
         * @member {"importRole"|"exportRole"|undefined} role
         * @memberof azure_blob_storage.AzureBlobStorageWorkerConfiguration
         * @instance
         */
        Object.defineProperty(AzureBlobStorageWorkerConfiguration.prototype, "role", {
            get: $util.oneOfGetter($oneOfFields = ["importRole", "exportRole"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new AzureBlobStorageWorkerConfiguration instance using the specified properties.
         * @function create
         * @memberof azure_blob_storage.AzureBlobStorageWorkerConfiguration
         * @static
         * @param {azure_blob_storage.IAzureBlobStorageWorkerConfiguration=} [properties] Properties to set
         * @returns {azure_blob_storage.AzureBlobStorageWorkerConfiguration} AzureBlobStorageWorkerConfiguration instance
         */
        AzureBlobStorageWorkerConfiguration.create = function create(properties) {
            return new AzureBlobStorageWorkerConfiguration(properties);
        };

        /**
         * Encodes the specified AzureBlobStorageWorkerConfiguration message. Does not implicitly {@link azure_blob_storage.AzureBlobStorageWorkerConfiguration.verify|verify} messages.
         * @function encode
         * @memberof azure_blob_storage.AzureBlobStorageWorkerConfiguration
         * @static
         * @param {azure_blob_storage.IAzureBlobStorageWorkerConfiguration} message AzureBlobStorageWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AzureBlobStorageWorkerConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.credentialsDependency != null && Object.hasOwnProperty.call(message, "credentialsDependency"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.credentialsDependency);
            if (message.importRole != null && Object.hasOwnProperty.call(message, "importRole"))
                $root.azure_blob_storage.ImportRole.encode(message.importRole, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.exportRole != null && Object.hasOwnProperty.call(message, "exportRole"))
                $root.azure_blob_storage.ExportRole.encode(message.exportRole, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AzureBlobStorageWorkerConfiguration message, length delimited. Does not implicitly {@link azure_blob_storage.AzureBlobStorageWorkerConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof azure_blob_storage.AzureBlobStorageWorkerConfiguration
         * @static
         * @param {azure_blob_storage.IAzureBlobStorageWorkerConfiguration} message AzureBlobStorageWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AzureBlobStorageWorkerConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AzureBlobStorageWorkerConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof azure_blob_storage.AzureBlobStorageWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {azure_blob_storage.AzureBlobStorageWorkerConfiguration} AzureBlobStorageWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AzureBlobStorageWorkerConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.azure_blob_storage.AzureBlobStorageWorkerConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.credentialsDependency = reader.string();
                        break;
                    }
                case 2: {
                        message.importRole = $root.azure_blob_storage.ImportRole.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.exportRole = $root.azure_blob_storage.ExportRole.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AzureBlobStorageWorkerConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof azure_blob_storage.AzureBlobStorageWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {azure_blob_storage.AzureBlobStorageWorkerConfiguration} AzureBlobStorageWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AzureBlobStorageWorkerConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AzureBlobStorageWorkerConfiguration message.
         * @function verify
         * @memberof azure_blob_storage.AzureBlobStorageWorkerConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AzureBlobStorageWorkerConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.credentialsDependency != null && message.hasOwnProperty("credentialsDependency"))
                if (!$util.isString(message.credentialsDependency))
                    return "credentialsDependency: string expected";
            if (message.importRole != null && message.hasOwnProperty("importRole")) {
                properties.role = 1;
                {
                    let error = $root.azure_blob_storage.ImportRole.verify(message.importRole);
                    if (error)
                        return "importRole." + error;
                }
            }
            if (message.exportRole != null && message.hasOwnProperty("exportRole")) {
                if (properties.role === 1)
                    return "role: multiple values";
                properties.role = 1;
                {
                    let error = $root.azure_blob_storage.ExportRole.verify(message.exportRole);
                    if (error)
                        return "exportRole." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AzureBlobStorageWorkerConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof azure_blob_storage.AzureBlobStorageWorkerConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {azure_blob_storage.AzureBlobStorageWorkerConfiguration} AzureBlobStorageWorkerConfiguration
         */
        AzureBlobStorageWorkerConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.azure_blob_storage.AzureBlobStorageWorkerConfiguration)
                return object;
            let message = new $root.azure_blob_storage.AzureBlobStorageWorkerConfiguration();
            if (object.credentialsDependency != null)
                message.credentialsDependency = String(object.credentialsDependency);
            if (object.importRole != null) {
                if (typeof object.importRole !== "object")
                    throw TypeError(".azure_blob_storage.AzureBlobStorageWorkerConfiguration.importRole: object expected");
                message.importRole = $root.azure_blob_storage.ImportRole.fromObject(object.importRole);
            }
            if (object.exportRole != null) {
                if (typeof object.exportRole !== "object")
                    throw TypeError(".azure_blob_storage.AzureBlobStorageWorkerConfiguration.exportRole: object expected");
                message.exportRole = $root.azure_blob_storage.ExportRole.fromObject(object.exportRole);
            }
            return message;
        };

        /**
         * Creates a plain object from an AzureBlobStorageWorkerConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof azure_blob_storage.AzureBlobStorageWorkerConfiguration
         * @static
         * @param {azure_blob_storage.AzureBlobStorageWorkerConfiguration} message AzureBlobStorageWorkerConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AzureBlobStorageWorkerConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.credentialsDependency = "";
            if (message.credentialsDependency != null && message.hasOwnProperty("credentialsDependency"))
                object.credentialsDependency = message.credentialsDependency;
            if (message.importRole != null && message.hasOwnProperty("importRole")) {
                object.importRole = $root.azure_blob_storage.ImportRole.toObject(message.importRole, options);
                if (options.oneofs)
                    object.role = "importRole";
            }
            if (message.exportRole != null && message.hasOwnProperty("exportRole")) {
                object.exportRole = $root.azure_blob_storage.ExportRole.toObject(message.exportRole, options);
                if (options.oneofs)
                    object.role = "exportRole";
            }
            return object;
        };

        /**
         * Converts this AzureBlobStorageWorkerConfiguration to JSON.
         * @function toJSON
         * @memberof azure_blob_storage.AzureBlobStorageWorkerConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AzureBlobStorageWorkerConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AzureBlobStorageWorkerConfiguration
         * @function getTypeUrl
         * @memberof azure_blob_storage.AzureBlobStorageWorkerConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AzureBlobStorageWorkerConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/azure_blob_storage.AzureBlobStorageWorkerConfiguration";
        };

        return AzureBlobStorageWorkerConfiguration;
    })();

    return azure_blob_storage;
})();

export const compute_container = $root.compute_container = (() => {

    /**
     * Namespace compute_container.
     * @exports compute_container
     * @namespace
     */
    const compute_container = {};

    compute_container.ContainerWorkerConfiguration = (function() {

        /**
         * Properties of a ContainerWorkerConfiguration.
         * @memberof compute_container
         * @interface IContainerWorkerConfiguration
         * @property {compute_container.IStaticImage|null} ["static"] ContainerWorkerConfiguration static
         */

        /**
         * Constructs a new ContainerWorkerConfiguration.
         * @memberof compute_container
         * @classdesc Represents a ContainerWorkerConfiguration.
         * @implements IContainerWorkerConfiguration
         * @constructor
         * @param {compute_container.IContainerWorkerConfiguration=} [properties] Properties to set
         */
        function ContainerWorkerConfiguration(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContainerWorkerConfiguration static.
         * @member {compute_container.IStaticImage|null|undefined} static
         * @memberof compute_container.ContainerWorkerConfiguration
         * @instance
         */
        ContainerWorkerConfiguration.prototype["static"] = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ContainerWorkerConfiguration configuration.
         * @member {"static"|undefined} configuration
         * @memberof compute_container.ContainerWorkerConfiguration
         * @instance
         */
        Object.defineProperty(ContainerWorkerConfiguration.prototype, "configuration", {
            get: $util.oneOfGetter($oneOfFields = ["static"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ContainerWorkerConfiguration instance using the specified properties.
         * @function create
         * @memberof compute_container.ContainerWorkerConfiguration
         * @static
         * @param {compute_container.IContainerWorkerConfiguration=} [properties] Properties to set
         * @returns {compute_container.ContainerWorkerConfiguration} ContainerWorkerConfiguration instance
         */
        ContainerWorkerConfiguration.create = function create(properties) {
            return new ContainerWorkerConfiguration(properties);
        };

        /**
         * Encodes the specified ContainerWorkerConfiguration message. Does not implicitly {@link compute_container.ContainerWorkerConfiguration.verify|verify} messages.
         * @function encode
         * @memberof compute_container.ContainerWorkerConfiguration
         * @static
         * @param {compute_container.IContainerWorkerConfiguration} message ContainerWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContainerWorkerConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message["static"] != null && Object.hasOwnProperty.call(message, "static"))
                $root.compute_container.StaticImage.encode(message["static"], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ContainerWorkerConfiguration message, length delimited. Does not implicitly {@link compute_container.ContainerWorkerConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_container.ContainerWorkerConfiguration
         * @static
         * @param {compute_container.IContainerWorkerConfiguration} message ContainerWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContainerWorkerConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ContainerWorkerConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof compute_container.ContainerWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_container.ContainerWorkerConfiguration} ContainerWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContainerWorkerConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_container.ContainerWorkerConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message["static"] = $root.compute_container.StaticImage.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ContainerWorkerConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_container.ContainerWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_container.ContainerWorkerConfiguration} ContainerWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContainerWorkerConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ContainerWorkerConfiguration message.
         * @function verify
         * @memberof compute_container.ContainerWorkerConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ContainerWorkerConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message["static"] != null && message.hasOwnProperty("static")) {
                properties.configuration = 1;
                {
                    let error = $root.compute_container.StaticImage.verify(message["static"]);
                    if (error)
                        return "static." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ContainerWorkerConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_container.ContainerWorkerConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_container.ContainerWorkerConfiguration} ContainerWorkerConfiguration
         */
        ContainerWorkerConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_container.ContainerWorkerConfiguration)
                return object;
            let message = new $root.compute_container.ContainerWorkerConfiguration();
            if (object["static"] != null) {
                if (typeof object["static"] !== "object")
                    throw TypeError(".compute_container.ContainerWorkerConfiguration.static: object expected");
                message["static"] = $root.compute_container.StaticImage.fromObject(object["static"]);
            }
            return message;
        };

        /**
         * Creates a plain object from a ContainerWorkerConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_container.ContainerWorkerConfiguration
         * @static
         * @param {compute_container.ContainerWorkerConfiguration} message ContainerWorkerConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContainerWorkerConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message["static"] != null && message.hasOwnProperty("static")) {
                object["static"] = $root.compute_container.StaticImage.toObject(message["static"], options);
                if (options.oneofs)
                    object.configuration = "static";
            }
            return object;
        };

        /**
         * Converts this ContainerWorkerConfiguration to JSON.
         * @function toJSON
         * @memberof compute_container.ContainerWorkerConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContainerWorkerConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ContainerWorkerConfiguration
         * @function getTypeUrl
         * @memberof compute_container.ContainerWorkerConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ContainerWorkerConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_container.ContainerWorkerConfiguration";
        };

        return ContainerWorkerConfiguration;
    })();

    compute_container.StaticImage = (function() {

        /**
         * Properties of a StaticImage.
         * @memberof compute_container
         * @interface IStaticImage
         * @property {Array.<string>|null} [command] StaticImage command
         * @property {Array.<compute_container.IMountPoint>|null} [mountPoints] StaticImage mountPoints
         * @property {string|null} [outputPath] StaticImage outputPath
         * @property {boolean|null} [includeContainerLogsOnError] StaticImage includeContainerLogsOnError
         * @property {boolean|null} [includeContainerLogsOnSuccess] StaticImage includeContainerLogsOnSuccess
         * @property {number|Long|null} [minimumContainerMemorySize] StaticImage minimumContainerMemorySize
         * @property {number|null} [extraChunkCacheSizeToAvailableMemoryRatio] StaticImage extraChunkCacheSizeToAvailableMemoryRatio
         * @property {compute_container.IProxyConfiguration|null} [proxyConfiguration] StaticImage proxyConfiguration
         */

        /**
         * Constructs a new StaticImage.
         * @memberof compute_container
         * @classdesc Represents a StaticImage.
         * @implements IStaticImage
         * @constructor
         * @param {compute_container.IStaticImage=} [properties] Properties to set
         */
        function StaticImage(properties) {
            this.command = [];
            this.mountPoints = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StaticImage command.
         * @member {Array.<string>} command
         * @memberof compute_container.StaticImage
         * @instance
         */
        StaticImage.prototype.command = $util.emptyArray;

        /**
         * StaticImage mountPoints.
         * @member {Array.<compute_container.IMountPoint>} mountPoints
         * @memberof compute_container.StaticImage
         * @instance
         */
        StaticImage.prototype.mountPoints = $util.emptyArray;

        /**
         * StaticImage outputPath.
         * @member {string} outputPath
         * @memberof compute_container.StaticImage
         * @instance
         */
        StaticImage.prototype.outputPath = "";

        /**
         * StaticImage includeContainerLogsOnError.
         * @member {boolean} includeContainerLogsOnError
         * @memberof compute_container.StaticImage
         * @instance
         */
        StaticImage.prototype.includeContainerLogsOnError = false;

        /**
         * StaticImage includeContainerLogsOnSuccess.
         * @member {boolean} includeContainerLogsOnSuccess
         * @memberof compute_container.StaticImage
         * @instance
         */
        StaticImage.prototype.includeContainerLogsOnSuccess = false;

        /**
         * StaticImage minimumContainerMemorySize.
         * @member {number|Long|null|undefined} minimumContainerMemorySize
         * @memberof compute_container.StaticImage
         * @instance
         */
        StaticImage.prototype.minimumContainerMemorySize = null;

        /**
         * StaticImage extraChunkCacheSizeToAvailableMemoryRatio.
         * @member {number|null|undefined} extraChunkCacheSizeToAvailableMemoryRatio
         * @memberof compute_container.StaticImage
         * @instance
         */
        StaticImage.prototype.extraChunkCacheSizeToAvailableMemoryRatio = null;

        /**
         * StaticImage proxyConfiguration.
         * @member {compute_container.IProxyConfiguration|null|undefined} proxyConfiguration
         * @memberof compute_container.StaticImage
         * @instance
         */
        StaticImage.prototype.proxyConfiguration = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * StaticImage _minimumContainerMemorySize.
         * @member {"minimumContainerMemorySize"|undefined} _minimumContainerMemorySize
         * @memberof compute_container.StaticImage
         * @instance
         */
        Object.defineProperty(StaticImage.prototype, "_minimumContainerMemorySize", {
            get: $util.oneOfGetter($oneOfFields = ["minimumContainerMemorySize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * StaticImage _extraChunkCacheSizeToAvailableMemoryRatio.
         * @member {"extraChunkCacheSizeToAvailableMemoryRatio"|undefined} _extraChunkCacheSizeToAvailableMemoryRatio
         * @memberof compute_container.StaticImage
         * @instance
         */
        Object.defineProperty(StaticImage.prototype, "_extraChunkCacheSizeToAvailableMemoryRatio", {
            get: $util.oneOfGetter($oneOfFields = ["extraChunkCacheSizeToAvailableMemoryRatio"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * StaticImage _proxyConfiguration.
         * @member {"proxyConfiguration"|undefined} _proxyConfiguration
         * @memberof compute_container.StaticImage
         * @instance
         */
        Object.defineProperty(StaticImage.prototype, "_proxyConfiguration", {
            get: $util.oneOfGetter($oneOfFields = ["proxyConfiguration"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new StaticImage instance using the specified properties.
         * @function create
         * @memberof compute_container.StaticImage
         * @static
         * @param {compute_container.IStaticImage=} [properties] Properties to set
         * @returns {compute_container.StaticImage} StaticImage instance
         */
        StaticImage.create = function create(properties) {
            return new StaticImage(properties);
        };

        /**
         * Encodes the specified StaticImage message. Does not implicitly {@link compute_container.StaticImage.verify|verify} messages.
         * @function encode
         * @memberof compute_container.StaticImage
         * @static
         * @param {compute_container.IStaticImage} message StaticImage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StaticImage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.command != null && message.command.length)
                for (let i = 0; i < message.command.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.command[i]);
            if (message.mountPoints != null && message.mountPoints.length)
                for (let i = 0; i < message.mountPoints.length; ++i)
                    $root.compute_container.MountPoint.encode(message.mountPoints[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.outputPath != null && Object.hasOwnProperty.call(message, "outputPath"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.outputPath);
            if (message.includeContainerLogsOnError != null && Object.hasOwnProperty.call(message, "includeContainerLogsOnError"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.includeContainerLogsOnError);
            if (message.includeContainerLogsOnSuccess != null && Object.hasOwnProperty.call(message, "includeContainerLogsOnSuccess"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.includeContainerLogsOnSuccess);
            if (message.minimumContainerMemorySize != null && Object.hasOwnProperty.call(message, "minimumContainerMemorySize"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.minimumContainerMemorySize);
            if (message.extraChunkCacheSizeToAvailableMemoryRatio != null && Object.hasOwnProperty.call(message, "extraChunkCacheSizeToAvailableMemoryRatio"))
                writer.uint32(/* id 7, wireType 5 =*/61).float(message.extraChunkCacheSizeToAvailableMemoryRatio);
            if (message.proxyConfiguration != null && Object.hasOwnProperty.call(message, "proxyConfiguration"))
                $root.compute_container.ProxyConfiguration.encode(message.proxyConfiguration, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified StaticImage message, length delimited. Does not implicitly {@link compute_container.StaticImage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_container.StaticImage
         * @static
         * @param {compute_container.IStaticImage} message StaticImage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StaticImage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StaticImage message from the specified reader or buffer.
         * @function decode
         * @memberof compute_container.StaticImage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_container.StaticImage} StaticImage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StaticImage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_container.StaticImage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.command && message.command.length))
                            message.command = [];
                        message.command.push(reader.string());
                        break;
                    }
                case 2: {
                        if (!(message.mountPoints && message.mountPoints.length))
                            message.mountPoints = [];
                        message.mountPoints.push($root.compute_container.MountPoint.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.outputPath = reader.string();
                        break;
                    }
                case 4: {
                        message.includeContainerLogsOnError = reader.bool();
                        break;
                    }
                case 5: {
                        message.includeContainerLogsOnSuccess = reader.bool();
                        break;
                    }
                case 6: {
                        message.minimumContainerMemorySize = reader.uint64();
                        break;
                    }
                case 7: {
                        message.extraChunkCacheSizeToAvailableMemoryRatio = reader.float();
                        break;
                    }
                case 8: {
                        message.proxyConfiguration = $root.compute_container.ProxyConfiguration.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StaticImage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_container.StaticImage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_container.StaticImage} StaticImage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StaticImage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StaticImage message.
         * @function verify
         * @memberof compute_container.StaticImage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StaticImage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.command != null && message.hasOwnProperty("command")) {
                if (!Array.isArray(message.command))
                    return "command: array expected";
                for (let i = 0; i < message.command.length; ++i)
                    if (!$util.isString(message.command[i]))
                        return "command: string[] expected";
            }
            if (message.mountPoints != null && message.hasOwnProperty("mountPoints")) {
                if (!Array.isArray(message.mountPoints))
                    return "mountPoints: array expected";
                for (let i = 0; i < message.mountPoints.length; ++i) {
                    let error = $root.compute_container.MountPoint.verify(message.mountPoints[i]);
                    if (error)
                        return "mountPoints." + error;
                }
            }
            if (message.outputPath != null && message.hasOwnProperty("outputPath"))
                if (!$util.isString(message.outputPath))
                    return "outputPath: string expected";
            if (message.includeContainerLogsOnError != null && message.hasOwnProperty("includeContainerLogsOnError"))
                if (typeof message.includeContainerLogsOnError !== "boolean")
                    return "includeContainerLogsOnError: boolean expected";
            if (message.includeContainerLogsOnSuccess != null && message.hasOwnProperty("includeContainerLogsOnSuccess"))
                if (typeof message.includeContainerLogsOnSuccess !== "boolean")
                    return "includeContainerLogsOnSuccess: boolean expected";
            if (message.minimumContainerMemorySize != null && message.hasOwnProperty("minimumContainerMemorySize")) {
                properties._minimumContainerMemorySize = 1;
                if (!$util.isInteger(message.minimumContainerMemorySize) && !(message.minimumContainerMemorySize && $util.isInteger(message.minimumContainerMemorySize.low) && $util.isInteger(message.minimumContainerMemorySize.high)))
                    return "minimumContainerMemorySize: integer|Long expected";
            }
            if (message.extraChunkCacheSizeToAvailableMemoryRatio != null && message.hasOwnProperty("extraChunkCacheSizeToAvailableMemoryRatio")) {
                properties._extraChunkCacheSizeToAvailableMemoryRatio = 1;
                if (typeof message.extraChunkCacheSizeToAvailableMemoryRatio !== "number")
                    return "extraChunkCacheSizeToAvailableMemoryRatio: number expected";
            }
            if (message.proxyConfiguration != null && message.hasOwnProperty("proxyConfiguration")) {
                properties._proxyConfiguration = 1;
                {
                    let error = $root.compute_container.ProxyConfiguration.verify(message.proxyConfiguration);
                    if (error)
                        return "proxyConfiguration." + error;
                }
            }
            return null;
        };

        /**
         * Creates a StaticImage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_container.StaticImage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_container.StaticImage} StaticImage
         */
        StaticImage.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_container.StaticImage)
                return object;
            let message = new $root.compute_container.StaticImage();
            if (object.command) {
                if (!Array.isArray(object.command))
                    throw TypeError(".compute_container.StaticImage.command: array expected");
                message.command = [];
                for (let i = 0; i < object.command.length; ++i)
                    message.command[i] = String(object.command[i]);
            }
            if (object.mountPoints) {
                if (!Array.isArray(object.mountPoints))
                    throw TypeError(".compute_container.StaticImage.mountPoints: array expected");
                message.mountPoints = [];
                for (let i = 0; i < object.mountPoints.length; ++i) {
                    if (typeof object.mountPoints[i] !== "object")
                        throw TypeError(".compute_container.StaticImage.mountPoints: object expected");
                    message.mountPoints[i] = $root.compute_container.MountPoint.fromObject(object.mountPoints[i]);
                }
            }
            if (object.outputPath != null)
                message.outputPath = String(object.outputPath);
            if (object.includeContainerLogsOnError != null)
                message.includeContainerLogsOnError = Boolean(object.includeContainerLogsOnError);
            if (object.includeContainerLogsOnSuccess != null)
                message.includeContainerLogsOnSuccess = Boolean(object.includeContainerLogsOnSuccess);
            if (object.minimumContainerMemorySize != null)
                if ($util.Long)
                    (message.minimumContainerMemorySize = $util.Long.fromValue(object.minimumContainerMemorySize)).unsigned = true;
                else if (typeof object.minimumContainerMemorySize === "string")
                    message.minimumContainerMemorySize = parseInt(object.minimumContainerMemorySize, 10);
                else if (typeof object.minimumContainerMemorySize === "number")
                    message.minimumContainerMemorySize = object.minimumContainerMemorySize;
                else if (typeof object.minimumContainerMemorySize === "object")
                    message.minimumContainerMemorySize = new $util.LongBits(object.minimumContainerMemorySize.low >>> 0, object.minimumContainerMemorySize.high >>> 0).toNumber(true);
            if (object.extraChunkCacheSizeToAvailableMemoryRatio != null)
                message.extraChunkCacheSizeToAvailableMemoryRatio = Number(object.extraChunkCacheSizeToAvailableMemoryRatio);
            if (object.proxyConfiguration != null) {
                if (typeof object.proxyConfiguration !== "object")
                    throw TypeError(".compute_container.StaticImage.proxyConfiguration: object expected");
                message.proxyConfiguration = $root.compute_container.ProxyConfiguration.fromObject(object.proxyConfiguration);
            }
            return message;
        };

        /**
         * Creates a plain object from a StaticImage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_container.StaticImage
         * @static
         * @param {compute_container.StaticImage} message StaticImage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StaticImage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.command = [];
                object.mountPoints = [];
            }
            if (options.defaults) {
                object.outputPath = "";
                object.includeContainerLogsOnError = false;
                object.includeContainerLogsOnSuccess = false;
            }
            if (message.command && message.command.length) {
                object.command = [];
                for (let j = 0; j < message.command.length; ++j)
                    object.command[j] = message.command[j];
            }
            if (message.mountPoints && message.mountPoints.length) {
                object.mountPoints = [];
                for (let j = 0; j < message.mountPoints.length; ++j)
                    object.mountPoints[j] = $root.compute_container.MountPoint.toObject(message.mountPoints[j], options);
            }
            if (message.outputPath != null && message.hasOwnProperty("outputPath"))
                object.outputPath = message.outputPath;
            if (message.includeContainerLogsOnError != null && message.hasOwnProperty("includeContainerLogsOnError"))
                object.includeContainerLogsOnError = message.includeContainerLogsOnError;
            if (message.includeContainerLogsOnSuccess != null && message.hasOwnProperty("includeContainerLogsOnSuccess"))
                object.includeContainerLogsOnSuccess = message.includeContainerLogsOnSuccess;
            if (message.minimumContainerMemorySize != null && message.hasOwnProperty("minimumContainerMemorySize")) {
                if (typeof message.minimumContainerMemorySize === "number")
                    object.minimumContainerMemorySize = options.longs === String ? String(message.minimumContainerMemorySize) : message.minimumContainerMemorySize;
                else
                    object.minimumContainerMemorySize = options.longs === String ? $util.Long.prototype.toString.call(message.minimumContainerMemorySize) : options.longs === Number ? new $util.LongBits(message.minimumContainerMemorySize.low >>> 0, message.minimumContainerMemorySize.high >>> 0).toNumber(true) : message.minimumContainerMemorySize;
                if (options.oneofs)
                    object._minimumContainerMemorySize = "minimumContainerMemorySize";
            }
            if (message.extraChunkCacheSizeToAvailableMemoryRatio != null && message.hasOwnProperty("extraChunkCacheSizeToAvailableMemoryRatio")) {
                object.extraChunkCacheSizeToAvailableMemoryRatio = options.json && !isFinite(message.extraChunkCacheSizeToAvailableMemoryRatio) ? String(message.extraChunkCacheSizeToAvailableMemoryRatio) : message.extraChunkCacheSizeToAvailableMemoryRatio;
                if (options.oneofs)
                    object._extraChunkCacheSizeToAvailableMemoryRatio = "extraChunkCacheSizeToAvailableMemoryRatio";
            }
            if (message.proxyConfiguration != null && message.hasOwnProperty("proxyConfiguration")) {
                object.proxyConfiguration = $root.compute_container.ProxyConfiguration.toObject(message.proxyConfiguration, options);
                if (options.oneofs)
                    object._proxyConfiguration = "proxyConfiguration";
            }
            return object;
        };

        /**
         * Converts this StaticImage to JSON.
         * @function toJSON
         * @memberof compute_container.StaticImage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StaticImage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StaticImage
         * @function getTypeUrl
         * @memberof compute_container.StaticImage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StaticImage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_container.StaticImage";
        };

        return StaticImage;
    })();

    compute_container.ProxyConfiguration = (function() {

        /**
         * Properties of a ProxyConfiguration.
         * @memberof compute_container
         * @interface IProxyConfiguration
         * @property {compute_container.IProxyConfigurationLiberal|null} [liberal] ProxyConfiguration liberal
         * @property {compute_container.IProxyConfigurationDomainAllowlist|null} [domainAllowlist] ProxyConfiguration domainAllowlist
         */

        /**
         * Constructs a new ProxyConfiguration.
         * @memberof compute_container
         * @classdesc Represents a ProxyConfiguration.
         * @implements IProxyConfiguration
         * @constructor
         * @param {compute_container.IProxyConfiguration=} [properties] Properties to set
         */
        function ProxyConfiguration(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProxyConfiguration liberal.
         * @member {compute_container.IProxyConfigurationLiberal|null|undefined} liberal
         * @memberof compute_container.ProxyConfiguration
         * @instance
         */
        ProxyConfiguration.prototype.liberal = null;

        /**
         * ProxyConfiguration domainAllowlist.
         * @member {compute_container.IProxyConfigurationDomainAllowlist|null|undefined} domainAllowlist
         * @memberof compute_container.ProxyConfiguration
         * @instance
         */
        ProxyConfiguration.prototype.domainAllowlist = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ProxyConfiguration configuration.
         * @member {"liberal"|"domainAllowlist"|undefined} configuration
         * @memberof compute_container.ProxyConfiguration
         * @instance
         */
        Object.defineProperty(ProxyConfiguration.prototype, "configuration", {
            get: $util.oneOfGetter($oneOfFields = ["liberal", "domainAllowlist"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ProxyConfiguration instance using the specified properties.
         * @function create
         * @memberof compute_container.ProxyConfiguration
         * @static
         * @param {compute_container.IProxyConfiguration=} [properties] Properties to set
         * @returns {compute_container.ProxyConfiguration} ProxyConfiguration instance
         */
        ProxyConfiguration.create = function create(properties) {
            return new ProxyConfiguration(properties);
        };

        /**
         * Encodes the specified ProxyConfiguration message. Does not implicitly {@link compute_container.ProxyConfiguration.verify|verify} messages.
         * @function encode
         * @memberof compute_container.ProxyConfiguration
         * @static
         * @param {compute_container.IProxyConfiguration} message ProxyConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProxyConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.liberal != null && Object.hasOwnProperty.call(message, "liberal"))
                $root.compute_container.ProxyConfigurationLiberal.encode(message.liberal, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.domainAllowlist != null && Object.hasOwnProperty.call(message, "domainAllowlist"))
                $root.compute_container.ProxyConfigurationDomainAllowlist.encode(message.domainAllowlist, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ProxyConfiguration message, length delimited. Does not implicitly {@link compute_container.ProxyConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_container.ProxyConfiguration
         * @static
         * @param {compute_container.IProxyConfiguration} message ProxyConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProxyConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProxyConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof compute_container.ProxyConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_container.ProxyConfiguration} ProxyConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProxyConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_container.ProxyConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.liberal = $root.compute_container.ProxyConfigurationLiberal.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.domainAllowlist = $root.compute_container.ProxyConfigurationDomainAllowlist.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProxyConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_container.ProxyConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_container.ProxyConfiguration} ProxyConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProxyConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProxyConfiguration message.
         * @function verify
         * @memberof compute_container.ProxyConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProxyConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.liberal != null && message.hasOwnProperty("liberal")) {
                properties.configuration = 1;
                {
                    let error = $root.compute_container.ProxyConfigurationLiberal.verify(message.liberal);
                    if (error)
                        return "liberal." + error;
                }
            }
            if (message.domainAllowlist != null && message.hasOwnProperty("domainAllowlist")) {
                if (properties.configuration === 1)
                    return "configuration: multiple values";
                properties.configuration = 1;
                {
                    let error = $root.compute_container.ProxyConfigurationDomainAllowlist.verify(message.domainAllowlist);
                    if (error)
                        return "domainAllowlist." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ProxyConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_container.ProxyConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_container.ProxyConfiguration} ProxyConfiguration
         */
        ProxyConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_container.ProxyConfiguration)
                return object;
            let message = new $root.compute_container.ProxyConfiguration();
            if (object.liberal != null) {
                if (typeof object.liberal !== "object")
                    throw TypeError(".compute_container.ProxyConfiguration.liberal: object expected");
                message.liberal = $root.compute_container.ProxyConfigurationLiberal.fromObject(object.liberal);
            }
            if (object.domainAllowlist != null) {
                if (typeof object.domainAllowlist !== "object")
                    throw TypeError(".compute_container.ProxyConfiguration.domainAllowlist: object expected");
                message.domainAllowlist = $root.compute_container.ProxyConfigurationDomainAllowlist.fromObject(object.domainAllowlist);
            }
            return message;
        };

        /**
         * Creates a plain object from a ProxyConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_container.ProxyConfiguration
         * @static
         * @param {compute_container.ProxyConfiguration} message ProxyConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProxyConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.liberal != null && message.hasOwnProperty("liberal")) {
                object.liberal = $root.compute_container.ProxyConfigurationLiberal.toObject(message.liberal, options);
                if (options.oneofs)
                    object.configuration = "liberal";
            }
            if (message.domainAllowlist != null && message.hasOwnProperty("domainAllowlist")) {
                object.domainAllowlist = $root.compute_container.ProxyConfigurationDomainAllowlist.toObject(message.domainAllowlist, options);
                if (options.oneofs)
                    object.configuration = "domainAllowlist";
            }
            return object;
        };

        /**
         * Converts this ProxyConfiguration to JSON.
         * @function toJSON
         * @memberof compute_container.ProxyConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProxyConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProxyConfiguration
         * @function getTypeUrl
         * @memberof compute_container.ProxyConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProxyConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_container.ProxyConfiguration";
        };

        return ProxyConfiguration;
    })();

    compute_container.ProxyConfigurationLiberal = (function() {

        /**
         * Properties of a ProxyConfigurationLiberal.
         * @memberof compute_container
         * @interface IProxyConfigurationLiberal
         */

        /**
         * Constructs a new ProxyConfigurationLiberal.
         * @memberof compute_container
         * @classdesc Represents a ProxyConfigurationLiberal.
         * @implements IProxyConfigurationLiberal
         * @constructor
         * @param {compute_container.IProxyConfigurationLiberal=} [properties] Properties to set
         */
        function ProxyConfigurationLiberal(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ProxyConfigurationLiberal instance using the specified properties.
         * @function create
         * @memberof compute_container.ProxyConfigurationLiberal
         * @static
         * @param {compute_container.IProxyConfigurationLiberal=} [properties] Properties to set
         * @returns {compute_container.ProxyConfigurationLiberal} ProxyConfigurationLiberal instance
         */
        ProxyConfigurationLiberal.create = function create(properties) {
            return new ProxyConfigurationLiberal(properties);
        };

        /**
         * Encodes the specified ProxyConfigurationLiberal message. Does not implicitly {@link compute_container.ProxyConfigurationLiberal.verify|verify} messages.
         * @function encode
         * @memberof compute_container.ProxyConfigurationLiberal
         * @static
         * @param {compute_container.IProxyConfigurationLiberal} message ProxyConfigurationLiberal message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProxyConfigurationLiberal.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ProxyConfigurationLiberal message, length delimited. Does not implicitly {@link compute_container.ProxyConfigurationLiberal.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_container.ProxyConfigurationLiberal
         * @static
         * @param {compute_container.IProxyConfigurationLiberal} message ProxyConfigurationLiberal message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProxyConfigurationLiberal.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProxyConfigurationLiberal message from the specified reader or buffer.
         * @function decode
         * @memberof compute_container.ProxyConfigurationLiberal
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_container.ProxyConfigurationLiberal} ProxyConfigurationLiberal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProxyConfigurationLiberal.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_container.ProxyConfigurationLiberal();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProxyConfigurationLiberal message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_container.ProxyConfigurationLiberal
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_container.ProxyConfigurationLiberal} ProxyConfigurationLiberal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProxyConfigurationLiberal.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProxyConfigurationLiberal message.
         * @function verify
         * @memberof compute_container.ProxyConfigurationLiberal
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProxyConfigurationLiberal.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ProxyConfigurationLiberal message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_container.ProxyConfigurationLiberal
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_container.ProxyConfigurationLiberal} ProxyConfigurationLiberal
         */
        ProxyConfigurationLiberal.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_container.ProxyConfigurationLiberal)
                return object;
            return new $root.compute_container.ProxyConfigurationLiberal();
        };

        /**
         * Creates a plain object from a ProxyConfigurationLiberal message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_container.ProxyConfigurationLiberal
         * @static
         * @param {compute_container.ProxyConfigurationLiberal} message ProxyConfigurationLiberal
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProxyConfigurationLiberal.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ProxyConfigurationLiberal to JSON.
         * @function toJSON
         * @memberof compute_container.ProxyConfigurationLiberal
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProxyConfigurationLiberal.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProxyConfigurationLiberal
         * @function getTypeUrl
         * @memberof compute_container.ProxyConfigurationLiberal
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProxyConfigurationLiberal.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_container.ProxyConfigurationLiberal";
        };

        return ProxyConfigurationLiberal;
    })();

    compute_container.ProxyConfigurationDomainAllowlist = (function() {

        /**
         * Properties of a ProxyConfigurationDomainAllowlist.
         * @memberof compute_container
         * @interface IProxyConfigurationDomainAllowlist
         * @property {Array.<string>|null} [domain] ProxyConfigurationDomainAllowlist domain
         */

        /**
         * Constructs a new ProxyConfigurationDomainAllowlist.
         * @memberof compute_container
         * @classdesc Represents a ProxyConfigurationDomainAllowlist.
         * @implements IProxyConfigurationDomainAllowlist
         * @constructor
         * @param {compute_container.IProxyConfigurationDomainAllowlist=} [properties] Properties to set
         */
        function ProxyConfigurationDomainAllowlist(properties) {
            this.domain = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProxyConfigurationDomainAllowlist domain.
         * @member {Array.<string>} domain
         * @memberof compute_container.ProxyConfigurationDomainAllowlist
         * @instance
         */
        ProxyConfigurationDomainAllowlist.prototype.domain = $util.emptyArray;

        /**
         * Creates a new ProxyConfigurationDomainAllowlist instance using the specified properties.
         * @function create
         * @memberof compute_container.ProxyConfigurationDomainAllowlist
         * @static
         * @param {compute_container.IProxyConfigurationDomainAllowlist=} [properties] Properties to set
         * @returns {compute_container.ProxyConfigurationDomainAllowlist} ProxyConfigurationDomainAllowlist instance
         */
        ProxyConfigurationDomainAllowlist.create = function create(properties) {
            return new ProxyConfigurationDomainAllowlist(properties);
        };

        /**
         * Encodes the specified ProxyConfigurationDomainAllowlist message. Does not implicitly {@link compute_container.ProxyConfigurationDomainAllowlist.verify|verify} messages.
         * @function encode
         * @memberof compute_container.ProxyConfigurationDomainAllowlist
         * @static
         * @param {compute_container.IProxyConfigurationDomainAllowlist} message ProxyConfigurationDomainAllowlist message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProxyConfigurationDomainAllowlist.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.domain != null && message.domain.length)
                for (let i = 0; i < message.domain.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.domain[i]);
            return writer;
        };

        /**
         * Encodes the specified ProxyConfigurationDomainAllowlist message, length delimited. Does not implicitly {@link compute_container.ProxyConfigurationDomainAllowlist.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_container.ProxyConfigurationDomainAllowlist
         * @static
         * @param {compute_container.IProxyConfigurationDomainAllowlist} message ProxyConfigurationDomainAllowlist message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProxyConfigurationDomainAllowlist.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProxyConfigurationDomainAllowlist message from the specified reader or buffer.
         * @function decode
         * @memberof compute_container.ProxyConfigurationDomainAllowlist
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_container.ProxyConfigurationDomainAllowlist} ProxyConfigurationDomainAllowlist
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProxyConfigurationDomainAllowlist.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_container.ProxyConfigurationDomainAllowlist();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.domain && message.domain.length))
                            message.domain = [];
                        message.domain.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProxyConfigurationDomainAllowlist message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_container.ProxyConfigurationDomainAllowlist
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_container.ProxyConfigurationDomainAllowlist} ProxyConfigurationDomainAllowlist
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProxyConfigurationDomainAllowlist.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProxyConfigurationDomainAllowlist message.
         * @function verify
         * @memberof compute_container.ProxyConfigurationDomainAllowlist
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProxyConfigurationDomainAllowlist.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.domain != null && message.hasOwnProperty("domain")) {
                if (!Array.isArray(message.domain))
                    return "domain: array expected";
                for (let i = 0; i < message.domain.length; ++i)
                    if (!$util.isString(message.domain[i]))
                        return "domain: string[] expected";
            }
            return null;
        };

        /**
         * Creates a ProxyConfigurationDomainAllowlist message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_container.ProxyConfigurationDomainAllowlist
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_container.ProxyConfigurationDomainAllowlist} ProxyConfigurationDomainAllowlist
         */
        ProxyConfigurationDomainAllowlist.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_container.ProxyConfigurationDomainAllowlist)
                return object;
            let message = new $root.compute_container.ProxyConfigurationDomainAllowlist();
            if (object.domain) {
                if (!Array.isArray(object.domain))
                    throw TypeError(".compute_container.ProxyConfigurationDomainAllowlist.domain: array expected");
                message.domain = [];
                for (let i = 0; i < object.domain.length; ++i)
                    message.domain[i] = String(object.domain[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a ProxyConfigurationDomainAllowlist message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_container.ProxyConfigurationDomainAllowlist
         * @static
         * @param {compute_container.ProxyConfigurationDomainAllowlist} message ProxyConfigurationDomainAllowlist
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProxyConfigurationDomainAllowlist.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.domain = [];
            if (message.domain && message.domain.length) {
                object.domain = [];
                for (let j = 0; j < message.domain.length; ++j)
                    object.domain[j] = message.domain[j];
            }
            return object;
        };

        /**
         * Converts this ProxyConfigurationDomainAllowlist to JSON.
         * @function toJSON
         * @memberof compute_container.ProxyConfigurationDomainAllowlist
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProxyConfigurationDomainAllowlist.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProxyConfigurationDomainAllowlist
         * @function getTypeUrl
         * @memberof compute_container.ProxyConfigurationDomainAllowlist
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProxyConfigurationDomainAllowlist.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_container.ProxyConfigurationDomainAllowlist";
        };

        return ProxyConfigurationDomainAllowlist;
    })();

    compute_container.MountPoint = (function() {

        /**
         * Properties of a MountPoint.
         * @memberof compute_container
         * @interface IMountPoint
         * @property {string|null} [path] MountPoint path
         * @property {string|null} [dependency] MountPoint dependency
         */

        /**
         * Constructs a new MountPoint.
         * @memberof compute_container
         * @classdesc Represents a MountPoint.
         * @implements IMountPoint
         * @constructor
         * @param {compute_container.IMountPoint=} [properties] Properties to set
         */
        function MountPoint(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MountPoint path.
         * @member {string} path
         * @memberof compute_container.MountPoint
         * @instance
         */
        MountPoint.prototype.path = "";

        /**
         * MountPoint dependency.
         * @member {string} dependency
         * @memberof compute_container.MountPoint
         * @instance
         */
        MountPoint.prototype.dependency = "";

        /**
         * Creates a new MountPoint instance using the specified properties.
         * @function create
         * @memberof compute_container.MountPoint
         * @static
         * @param {compute_container.IMountPoint=} [properties] Properties to set
         * @returns {compute_container.MountPoint} MountPoint instance
         */
        MountPoint.create = function create(properties) {
            return new MountPoint(properties);
        };

        /**
         * Encodes the specified MountPoint message. Does not implicitly {@link compute_container.MountPoint.verify|verify} messages.
         * @function encode
         * @memberof compute_container.MountPoint
         * @static
         * @param {compute_container.IMountPoint} message MountPoint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MountPoint.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.path);
            if (message.dependency != null && Object.hasOwnProperty.call(message, "dependency"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.dependency);
            return writer;
        };

        /**
         * Encodes the specified MountPoint message, length delimited. Does not implicitly {@link compute_container.MountPoint.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_container.MountPoint
         * @static
         * @param {compute_container.IMountPoint} message MountPoint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MountPoint.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MountPoint message from the specified reader or buffer.
         * @function decode
         * @memberof compute_container.MountPoint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_container.MountPoint} MountPoint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MountPoint.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_container.MountPoint();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.path = reader.string();
                        break;
                    }
                case 2: {
                        message.dependency = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MountPoint message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_container.MountPoint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_container.MountPoint} MountPoint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MountPoint.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MountPoint message.
         * @function verify
         * @memberof compute_container.MountPoint
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MountPoint.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.path != null && message.hasOwnProperty("path"))
                if (!$util.isString(message.path))
                    return "path: string expected";
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                if (!$util.isString(message.dependency))
                    return "dependency: string expected";
            return null;
        };

        /**
         * Creates a MountPoint message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_container.MountPoint
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_container.MountPoint} MountPoint
         */
        MountPoint.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_container.MountPoint)
                return object;
            let message = new $root.compute_container.MountPoint();
            if (object.path != null)
                message.path = String(object.path);
            if (object.dependency != null)
                message.dependency = String(object.dependency);
            return message;
        };

        /**
         * Creates a plain object from a MountPoint message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_container.MountPoint
         * @static
         * @param {compute_container.MountPoint} message MountPoint
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MountPoint.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.path = "";
                object.dependency = "";
            }
            if (message.path != null && message.hasOwnProperty("path"))
                object.path = message.path;
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                object.dependency = message.dependency;
            return object;
        };

        /**
         * Converts this MountPoint to JSON.
         * @function toJSON
         * @memberof compute_container.MountPoint
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MountPoint.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MountPoint
         * @function getTypeUrl
         * @memberof compute_container.MountPoint
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MountPoint.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_container.MountPoint";
        };

        return MountPoint;
    })();

    return compute_container;
})();

export const compute_post = $root.compute_post = (() => {

    /**
     * Namespace compute_post.
     * @exports compute_post
     * @namespace
     */
    const compute_post = {};

    compute_post.PostWorkerConfiguration = (function() {

        /**
         * Properties of a PostWorkerConfiguration.
         * @memberof compute_post
         * @interface IPostWorkerConfiguration
         * @property {boolean|null} [useMockBackend] PostWorkerConfiguration useMockBackend
         */

        /**
         * Constructs a new PostWorkerConfiguration.
         * @memberof compute_post
         * @classdesc Represents a PostWorkerConfiguration.
         * @implements IPostWorkerConfiguration
         * @constructor
         * @param {compute_post.IPostWorkerConfiguration=} [properties] Properties to set
         */
        function PostWorkerConfiguration(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PostWorkerConfiguration useMockBackend.
         * @member {boolean} useMockBackend
         * @memberof compute_post.PostWorkerConfiguration
         * @instance
         */
        PostWorkerConfiguration.prototype.useMockBackend = false;

        /**
         * Creates a new PostWorkerConfiguration instance using the specified properties.
         * @function create
         * @memberof compute_post.PostWorkerConfiguration
         * @static
         * @param {compute_post.IPostWorkerConfiguration=} [properties] Properties to set
         * @returns {compute_post.PostWorkerConfiguration} PostWorkerConfiguration instance
         */
        PostWorkerConfiguration.create = function create(properties) {
            return new PostWorkerConfiguration(properties);
        };

        /**
         * Encodes the specified PostWorkerConfiguration message. Does not implicitly {@link compute_post.PostWorkerConfiguration.verify|verify} messages.
         * @function encode
         * @memberof compute_post.PostWorkerConfiguration
         * @static
         * @param {compute_post.IPostWorkerConfiguration} message PostWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PostWorkerConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.useMockBackend != null && Object.hasOwnProperty.call(message, "useMockBackend"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.useMockBackend);
            return writer;
        };

        /**
         * Encodes the specified PostWorkerConfiguration message, length delimited. Does not implicitly {@link compute_post.PostWorkerConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_post.PostWorkerConfiguration
         * @static
         * @param {compute_post.IPostWorkerConfiguration} message PostWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PostWorkerConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PostWorkerConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof compute_post.PostWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_post.PostWorkerConfiguration} PostWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PostWorkerConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_post.PostWorkerConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.useMockBackend = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PostWorkerConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_post.PostWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_post.PostWorkerConfiguration} PostWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PostWorkerConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PostWorkerConfiguration message.
         * @function verify
         * @memberof compute_post.PostWorkerConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PostWorkerConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.useMockBackend != null && message.hasOwnProperty("useMockBackend"))
                if (typeof message.useMockBackend !== "boolean")
                    return "useMockBackend: boolean expected";
            return null;
        };

        /**
         * Creates a PostWorkerConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_post.PostWorkerConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_post.PostWorkerConfiguration} PostWorkerConfiguration
         */
        PostWorkerConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_post.PostWorkerConfiguration)
                return object;
            let message = new $root.compute_post.PostWorkerConfiguration();
            if (object.useMockBackend != null)
                message.useMockBackend = Boolean(object.useMockBackend);
            return message;
        };

        /**
         * Creates a plain object from a PostWorkerConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_post.PostWorkerConfiguration
         * @static
         * @param {compute_post.PostWorkerConfiguration} message PostWorkerConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PostWorkerConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.useMockBackend = false;
            if (message.useMockBackend != null && message.hasOwnProperty("useMockBackend"))
                object.useMockBackend = message.useMockBackend;
            return object;
        };

        /**
         * Converts this PostWorkerConfiguration to JSON.
         * @function toJSON
         * @memberof compute_post.PostWorkerConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PostWorkerConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PostWorkerConfiguration
         * @function getTypeUrl
         * @memberof compute_post.PostWorkerConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PostWorkerConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_post.PostWorkerConfiguration";
        };

        return PostWorkerConfiguration;
    })();

    return compute_post;
})();

export const compute_s3_sink = $root.compute_s3_sink = (() => {

    /**
     * Namespace compute_s3_sink.
     * @exports compute_s3_sink
     * @namespace
     */
    const compute_s3_sink = {};

    /**
     * S3Provider enum.
     * @name compute_s3_sink.S3Provider
     * @enum {number}
     * @property {number} AWS=0 AWS value
     * @property {number} GCS=1 GCS value
     */
    compute_s3_sink.S3Provider = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "AWS"] = 0;
        values[valuesById[1] = "GCS"] = 1;
        return values;
    })();

    compute_s3_sink.S3SinkWorkerConfiguration = (function() {

        /**
         * Properties of a S3SinkWorkerConfiguration.
         * @memberof compute_s3_sink
         * @interface IS3SinkWorkerConfiguration
         * @property {string|null} [endpoint] S3SinkWorkerConfiguration endpoint
         * @property {string|null} [region] S3SinkWorkerConfiguration region
         * @property {Array.<compute_s3_sink.IS3Object>|null} [objects] S3SinkWorkerConfiguration objects
         * @property {compute_s3_sink.S3Provider|null} [s3Provider] S3SinkWorkerConfiguration s3Provider
         * @property {compute_s3_sink.IUserDefinedCredentials|null} [userDefinedCredentials] S3SinkWorkerConfiguration userDefinedCredentials
         * @property {compute_s3_sink.IDqDspCredentials|null} [dqDspCredentials] S3SinkWorkerConfiguration dqDspCredentials
         */

        /**
         * Constructs a new S3SinkWorkerConfiguration.
         * @memberof compute_s3_sink
         * @classdesc Represents a S3SinkWorkerConfiguration.
         * @implements IS3SinkWorkerConfiguration
         * @constructor
         * @param {compute_s3_sink.IS3SinkWorkerConfiguration=} [properties] Properties to set
         */
        function S3SinkWorkerConfiguration(properties) {
            this.objects = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * S3SinkWorkerConfiguration endpoint.
         * @member {string} endpoint
         * @memberof compute_s3_sink.S3SinkWorkerConfiguration
         * @instance
         */
        S3SinkWorkerConfiguration.prototype.endpoint = "";

        /**
         * S3SinkWorkerConfiguration region.
         * @member {string} region
         * @memberof compute_s3_sink.S3SinkWorkerConfiguration
         * @instance
         */
        S3SinkWorkerConfiguration.prototype.region = "";

        /**
         * S3SinkWorkerConfiguration objects.
         * @member {Array.<compute_s3_sink.IS3Object>} objects
         * @memberof compute_s3_sink.S3SinkWorkerConfiguration
         * @instance
         */
        S3SinkWorkerConfiguration.prototype.objects = $util.emptyArray;

        /**
         * S3SinkWorkerConfiguration s3Provider.
         * @member {compute_s3_sink.S3Provider} s3Provider
         * @memberof compute_s3_sink.S3SinkWorkerConfiguration
         * @instance
         */
        S3SinkWorkerConfiguration.prototype.s3Provider = 0;

        /**
         * S3SinkWorkerConfiguration userDefinedCredentials.
         * @member {compute_s3_sink.IUserDefinedCredentials|null|undefined} userDefinedCredentials
         * @memberof compute_s3_sink.S3SinkWorkerConfiguration
         * @instance
         */
        S3SinkWorkerConfiguration.prototype.userDefinedCredentials = null;

        /**
         * S3SinkWorkerConfiguration dqDspCredentials.
         * @member {compute_s3_sink.IDqDspCredentials|null|undefined} dqDspCredentials
         * @memberof compute_s3_sink.S3SinkWorkerConfiguration
         * @instance
         */
        S3SinkWorkerConfiguration.prototype.dqDspCredentials = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * S3SinkWorkerConfiguration credentials.
         * @member {"userDefinedCredentials"|"dqDspCredentials"|undefined} credentials
         * @memberof compute_s3_sink.S3SinkWorkerConfiguration
         * @instance
         */
        Object.defineProperty(S3SinkWorkerConfiguration.prototype, "credentials", {
            get: $util.oneOfGetter($oneOfFields = ["userDefinedCredentials", "dqDspCredentials"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new S3SinkWorkerConfiguration instance using the specified properties.
         * @function create
         * @memberof compute_s3_sink.S3SinkWorkerConfiguration
         * @static
         * @param {compute_s3_sink.IS3SinkWorkerConfiguration=} [properties] Properties to set
         * @returns {compute_s3_sink.S3SinkWorkerConfiguration} S3SinkWorkerConfiguration instance
         */
        S3SinkWorkerConfiguration.create = function create(properties) {
            return new S3SinkWorkerConfiguration(properties);
        };

        /**
         * Encodes the specified S3SinkWorkerConfiguration message. Does not implicitly {@link compute_s3_sink.S3SinkWorkerConfiguration.verify|verify} messages.
         * @function encode
         * @memberof compute_s3_sink.S3SinkWorkerConfiguration
         * @static
         * @param {compute_s3_sink.IS3SinkWorkerConfiguration} message S3SinkWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        S3SinkWorkerConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.endpoint != null && Object.hasOwnProperty.call(message, "endpoint"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.endpoint);
            if (message.region != null && Object.hasOwnProperty.call(message, "region"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.region);
            if (message.objects != null && message.objects.length)
                for (let i = 0; i < message.objects.length; ++i)
                    $root.compute_s3_sink.S3Object.encode(message.objects[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.s3Provider != null && Object.hasOwnProperty.call(message, "s3Provider"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.s3Provider);
            if (message.userDefinedCredentials != null && Object.hasOwnProperty.call(message, "userDefinedCredentials"))
                $root.compute_s3_sink.UserDefinedCredentials.encode(message.userDefinedCredentials, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.dqDspCredentials != null && Object.hasOwnProperty.call(message, "dqDspCredentials"))
                $root.compute_s3_sink.DqDspCredentials.encode(message.dqDspCredentials, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified S3SinkWorkerConfiguration message, length delimited. Does not implicitly {@link compute_s3_sink.S3SinkWorkerConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_s3_sink.S3SinkWorkerConfiguration
         * @static
         * @param {compute_s3_sink.IS3SinkWorkerConfiguration} message S3SinkWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        S3SinkWorkerConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a S3SinkWorkerConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof compute_s3_sink.S3SinkWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_s3_sink.S3SinkWorkerConfiguration} S3SinkWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        S3SinkWorkerConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_s3_sink.S3SinkWorkerConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.endpoint = reader.string();
                        break;
                    }
                case 2: {
                        message.region = reader.string();
                        break;
                    }
                case 4: {
                        if (!(message.objects && message.objects.length))
                            message.objects = [];
                        message.objects.push($root.compute_s3_sink.S3Object.decode(reader, reader.uint32()));
                        break;
                    }
                case 5: {
                        message.s3Provider = reader.int32();
                        break;
                    }
                case 6: {
                        message.userDefinedCredentials = $root.compute_s3_sink.UserDefinedCredentials.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.dqDspCredentials = $root.compute_s3_sink.DqDspCredentials.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a S3SinkWorkerConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_s3_sink.S3SinkWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_s3_sink.S3SinkWorkerConfiguration} S3SinkWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        S3SinkWorkerConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a S3SinkWorkerConfiguration message.
         * @function verify
         * @memberof compute_s3_sink.S3SinkWorkerConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        S3SinkWorkerConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.endpoint != null && message.hasOwnProperty("endpoint"))
                if (!$util.isString(message.endpoint))
                    return "endpoint: string expected";
            if (message.region != null && message.hasOwnProperty("region"))
                if (!$util.isString(message.region))
                    return "region: string expected";
            if (message.objects != null && message.hasOwnProperty("objects")) {
                if (!Array.isArray(message.objects))
                    return "objects: array expected";
                for (let i = 0; i < message.objects.length; ++i) {
                    let error = $root.compute_s3_sink.S3Object.verify(message.objects[i]);
                    if (error)
                        return "objects." + error;
                }
            }
            if (message.s3Provider != null && message.hasOwnProperty("s3Provider"))
                switch (message.s3Provider) {
                default:
                    return "s3Provider: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.userDefinedCredentials != null && message.hasOwnProperty("userDefinedCredentials")) {
                properties.credentials = 1;
                {
                    let error = $root.compute_s3_sink.UserDefinedCredentials.verify(message.userDefinedCredentials);
                    if (error)
                        return "userDefinedCredentials." + error;
                }
            }
            if (message.dqDspCredentials != null && message.hasOwnProperty("dqDspCredentials")) {
                if (properties.credentials === 1)
                    return "credentials: multiple values";
                properties.credentials = 1;
                {
                    let error = $root.compute_s3_sink.DqDspCredentials.verify(message.dqDspCredentials);
                    if (error)
                        return "dqDspCredentials." + error;
                }
            }
            return null;
        };

        /**
         * Creates a S3SinkWorkerConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_s3_sink.S3SinkWorkerConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_s3_sink.S3SinkWorkerConfiguration} S3SinkWorkerConfiguration
         */
        S3SinkWorkerConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_s3_sink.S3SinkWorkerConfiguration)
                return object;
            let message = new $root.compute_s3_sink.S3SinkWorkerConfiguration();
            if (object.endpoint != null)
                message.endpoint = String(object.endpoint);
            if (object.region != null)
                message.region = String(object.region);
            if (object.objects) {
                if (!Array.isArray(object.objects))
                    throw TypeError(".compute_s3_sink.S3SinkWorkerConfiguration.objects: array expected");
                message.objects = [];
                for (let i = 0; i < object.objects.length; ++i) {
                    if (typeof object.objects[i] !== "object")
                        throw TypeError(".compute_s3_sink.S3SinkWorkerConfiguration.objects: object expected");
                    message.objects[i] = $root.compute_s3_sink.S3Object.fromObject(object.objects[i]);
                }
            }
            switch (object.s3Provider) {
            default:
                if (typeof object.s3Provider === "number") {
                    message.s3Provider = object.s3Provider;
                    break;
                }
                break;
            case "AWS":
            case 0:
                message.s3Provider = 0;
                break;
            case "GCS":
            case 1:
                message.s3Provider = 1;
                break;
            }
            if (object.userDefinedCredentials != null) {
                if (typeof object.userDefinedCredentials !== "object")
                    throw TypeError(".compute_s3_sink.S3SinkWorkerConfiguration.userDefinedCredentials: object expected");
                message.userDefinedCredentials = $root.compute_s3_sink.UserDefinedCredentials.fromObject(object.userDefinedCredentials);
            }
            if (object.dqDspCredentials != null) {
                if (typeof object.dqDspCredentials !== "object")
                    throw TypeError(".compute_s3_sink.S3SinkWorkerConfiguration.dqDspCredentials: object expected");
                message.dqDspCredentials = $root.compute_s3_sink.DqDspCredentials.fromObject(object.dqDspCredentials);
            }
            return message;
        };

        /**
         * Creates a plain object from a S3SinkWorkerConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_s3_sink.S3SinkWorkerConfiguration
         * @static
         * @param {compute_s3_sink.S3SinkWorkerConfiguration} message S3SinkWorkerConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        S3SinkWorkerConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.objects = [];
            if (options.defaults) {
                object.endpoint = "";
                object.region = "";
                object.s3Provider = options.enums === String ? "AWS" : 0;
            }
            if (message.endpoint != null && message.hasOwnProperty("endpoint"))
                object.endpoint = message.endpoint;
            if (message.region != null && message.hasOwnProperty("region"))
                object.region = message.region;
            if (message.objects && message.objects.length) {
                object.objects = [];
                for (let j = 0; j < message.objects.length; ++j)
                    object.objects[j] = $root.compute_s3_sink.S3Object.toObject(message.objects[j], options);
            }
            if (message.s3Provider != null && message.hasOwnProperty("s3Provider"))
                object.s3Provider = options.enums === String ? $root.compute_s3_sink.S3Provider[message.s3Provider] === undefined ? message.s3Provider : $root.compute_s3_sink.S3Provider[message.s3Provider] : message.s3Provider;
            if (message.userDefinedCredentials != null && message.hasOwnProperty("userDefinedCredentials")) {
                object.userDefinedCredentials = $root.compute_s3_sink.UserDefinedCredentials.toObject(message.userDefinedCredentials, options);
                if (options.oneofs)
                    object.credentials = "userDefinedCredentials";
            }
            if (message.dqDspCredentials != null && message.hasOwnProperty("dqDspCredentials")) {
                object.dqDspCredentials = $root.compute_s3_sink.DqDspCredentials.toObject(message.dqDspCredentials, options);
                if (options.oneofs)
                    object.credentials = "dqDspCredentials";
            }
            return object;
        };

        /**
         * Converts this S3SinkWorkerConfiguration to JSON.
         * @function toJSON
         * @memberof compute_s3_sink.S3SinkWorkerConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        S3SinkWorkerConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for S3SinkWorkerConfiguration
         * @function getTypeUrl
         * @memberof compute_s3_sink.S3SinkWorkerConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        S3SinkWorkerConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_s3_sink.S3SinkWorkerConfiguration";
        };

        return S3SinkWorkerConfiguration;
    })();

    compute_s3_sink.UserDefinedCredentials = (function() {

        /**
         * Properties of a UserDefinedCredentials.
         * @memberof compute_s3_sink
         * @interface IUserDefinedCredentials
         * @property {string|null} [userDefinedCredentials] UserDefinedCredentials userDefinedCredentials
         */

        /**
         * Constructs a new UserDefinedCredentials.
         * @memberof compute_s3_sink
         * @classdesc Represents a UserDefinedCredentials.
         * @implements IUserDefinedCredentials
         * @constructor
         * @param {compute_s3_sink.IUserDefinedCredentials=} [properties] Properties to set
         */
        function UserDefinedCredentials(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserDefinedCredentials userDefinedCredentials.
         * @member {string} userDefinedCredentials
         * @memberof compute_s3_sink.UserDefinedCredentials
         * @instance
         */
        UserDefinedCredentials.prototype.userDefinedCredentials = "";

        /**
         * Creates a new UserDefinedCredentials instance using the specified properties.
         * @function create
         * @memberof compute_s3_sink.UserDefinedCredentials
         * @static
         * @param {compute_s3_sink.IUserDefinedCredentials=} [properties] Properties to set
         * @returns {compute_s3_sink.UserDefinedCredentials} UserDefinedCredentials instance
         */
        UserDefinedCredentials.create = function create(properties) {
            return new UserDefinedCredentials(properties);
        };

        /**
         * Encodes the specified UserDefinedCredentials message. Does not implicitly {@link compute_s3_sink.UserDefinedCredentials.verify|verify} messages.
         * @function encode
         * @memberof compute_s3_sink.UserDefinedCredentials
         * @static
         * @param {compute_s3_sink.IUserDefinedCredentials} message UserDefinedCredentials message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserDefinedCredentials.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userDefinedCredentials != null && Object.hasOwnProperty.call(message, "userDefinedCredentials"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userDefinedCredentials);
            return writer;
        };

        /**
         * Encodes the specified UserDefinedCredentials message, length delimited. Does not implicitly {@link compute_s3_sink.UserDefinedCredentials.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_s3_sink.UserDefinedCredentials
         * @static
         * @param {compute_s3_sink.IUserDefinedCredentials} message UserDefinedCredentials message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserDefinedCredentials.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserDefinedCredentials message from the specified reader or buffer.
         * @function decode
         * @memberof compute_s3_sink.UserDefinedCredentials
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_s3_sink.UserDefinedCredentials} UserDefinedCredentials
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserDefinedCredentials.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_s3_sink.UserDefinedCredentials();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.userDefinedCredentials = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserDefinedCredentials message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_s3_sink.UserDefinedCredentials
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_s3_sink.UserDefinedCredentials} UserDefinedCredentials
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserDefinedCredentials.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserDefinedCredentials message.
         * @function verify
         * @memberof compute_s3_sink.UserDefinedCredentials
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserDefinedCredentials.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userDefinedCredentials != null && message.hasOwnProperty("userDefinedCredentials"))
                if (!$util.isString(message.userDefinedCredentials))
                    return "userDefinedCredentials: string expected";
            return null;
        };

        /**
         * Creates a UserDefinedCredentials message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_s3_sink.UserDefinedCredentials
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_s3_sink.UserDefinedCredentials} UserDefinedCredentials
         */
        UserDefinedCredentials.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_s3_sink.UserDefinedCredentials)
                return object;
            let message = new $root.compute_s3_sink.UserDefinedCredentials();
            if (object.userDefinedCredentials != null)
                message.userDefinedCredentials = String(object.userDefinedCredentials);
            return message;
        };

        /**
         * Creates a plain object from a UserDefinedCredentials message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_s3_sink.UserDefinedCredentials
         * @static
         * @param {compute_s3_sink.UserDefinedCredentials} message UserDefinedCredentials
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserDefinedCredentials.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.userDefinedCredentials = "";
            if (message.userDefinedCredentials != null && message.hasOwnProperty("userDefinedCredentials"))
                object.userDefinedCredentials = message.userDefinedCredentials;
            return object;
        };

        /**
         * Converts this UserDefinedCredentials to JSON.
         * @function toJSON
         * @memberof compute_s3_sink.UserDefinedCredentials
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserDefinedCredentials.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserDefinedCredentials
         * @function getTypeUrl
         * @memberof compute_s3_sink.UserDefinedCredentials
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserDefinedCredentials.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_s3_sink.UserDefinedCredentials";
        };

        return UserDefinedCredentials;
    })();

    compute_s3_sink.DqDspCredentials = (function() {

        /**
         * Properties of a DqDspCredentials.
         * @memberof compute_s3_sink
         * @interface IDqDspCredentials
         * @property {compute_s3_sink.DspCredentialsType|null} [dspType] DqDspCredentials dspType
         */

        /**
         * Constructs a new DqDspCredentials.
         * @memberof compute_s3_sink
         * @classdesc Represents a DqDspCredentials.
         * @implements IDqDspCredentials
         * @constructor
         * @param {compute_s3_sink.IDqDspCredentials=} [properties] Properties to set
         */
        function DqDspCredentials(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DqDspCredentials dspType.
         * @member {compute_s3_sink.DspCredentialsType} dspType
         * @memberof compute_s3_sink.DqDspCredentials
         * @instance
         */
        DqDspCredentials.prototype.dspType = 0;

        /**
         * Creates a new DqDspCredentials instance using the specified properties.
         * @function create
         * @memberof compute_s3_sink.DqDspCredentials
         * @static
         * @param {compute_s3_sink.IDqDspCredentials=} [properties] Properties to set
         * @returns {compute_s3_sink.DqDspCredentials} DqDspCredentials instance
         */
        DqDspCredentials.create = function create(properties) {
            return new DqDspCredentials(properties);
        };

        /**
         * Encodes the specified DqDspCredentials message. Does not implicitly {@link compute_s3_sink.DqDspCredentials.verify|verify} messages.
         * @function encode
         * @memberof compute_s3_sink.DqDspCredentials
         * @static
         * @param {compute_s3_sink.IDqDspCredentials} message DqDspCredentials message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DqDspCredentials.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dspType != null && Object.hasOwnProperty.call(message, "dspType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.dspType);
            return writer;
        };

        /**
         * Encodes the specified DqDspCredentials message, length delimited. Does not implicitly {@link compute_s3_sink.DqDspCredentials.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_s3_sink.DqDspCredentials
         * @static
         * @param {compute_s3_sink.IDqDspCredentials} message DqDspCredentials message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DqDspCredentials.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DqDspCredentials message from the specified reader or buffer.
         * @function decode
         * @memberof compute_s3_sink.DqDspCredentials
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_s3_sink.DqDspCredentials} DqDspCredentials
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DqDspCredentials.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_s3_sink.DqDspCredentials();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dspType = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DqDspCredentials message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_s3_sink.DqDspCredentials
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_s3_sink.DqDspCredentials} DqDspCredentials
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DqDspCredentials.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DqDspCredentials message.
         * @function verify
         * @memberof compute_s3_sink.DqDspCredentials
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DqDspCredentials.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dspType != null && message.hasOwnProperty("dspType"))
                switch (message.dspType) {
                default:
                    return "dspType: enum value expected";
                case 0:
                    break;
                }
            return null;
        };

        /**
         * Creates a DqDspCredentials message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_s3_sink.DqDspCredentials
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_s3_sink.DqDspCredentials} DqDspCredentials
         */
        DqDspCredentials.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_s3_sink.DqDspCredentials)
                return object;
            let message = new $root.compute_s3_sink.DqDspCredentials();
            switch (object.dspType) {
            default:
                if (typeof object.dspType === "number") {
                    message.dspType = object.dspType;
                    break;
                }
                break;
            case "SPLICKY":
            case 0:
                message.dspType = 0;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a DqDspCredentials message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_s3_sink.DqDspCredentials
         * @static
         * @param {compute_s3_sink.DqDspCredentials} message DqDspCredentials
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DqDspCredentials.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.dspType = options.enums === String ? "SPLICKY" : 0;
            if (message.dspType != null && message.hasOwnProperty("dspType"))
                object.dspType = options.enums === String ? $root.compute_s3_sink.DspCredentialsType[message.dspType] === undefined ? message.dspType : $root.compute_s3_sink.DspCredentialsType[message.dspType] : message.dspType;
            return object;
        };

        /**
         * Converts this DqDspCredentials to JSON.
         * @function toJSON
         * @memberof compute_s3_sink.DqDspCredentials
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DqDspCredentials.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DqDspCredentials
         * @function getTypeUrl
         * @memberof compute_s3_sink.DqDspCredentials
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DqDspCredentials.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_s3_sink.DqDspCredentials";
        };

        return DqDspCredentials;
    })();

    /**
     * DspCredentialsType enum.
     * @name compute_s3_sink.DspCredentialsType
     * @enum {number}
     * @property {number} SPLICKY=0 SPLICKY value
     */
    compute_s3_sink.DspCredentialsType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SPLICKY"] = 0;
        return values;
    })();

    compute_s3_sink.S3Object = (function() {

        /**
         * Properties of a S3Object.
         * @memberof compute_s3_sink
         * @interface IS3Object
         * @property {string|null} [dependency] S3Object dependency
         * @property {compute_s3_sink.IZipObject|null} [zip] S3Object zip
         * @property {compute_s3_sink.IRawObject|null} [raw] S3Object raw
         */

        /**
         * Constructs a new S3Object.
         * @memberof compute_s3_sink
         * @classdesc Represents a S3Object.
         * @implements IS3Object
         * @constructor
         * @param {compute_s3_sink.IS3Object=} [properties] Properties to set
         */
        function S3Object(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * S3Object dependency.
         * @member {string} dependency
         * @memberof compute_s3_sink.S3Object
         * @instance
         */
        S3Object.prototype.dependency = "";

        /**
         * S3Object zip.
         * @member {compute_s3_sink.IZipObject|null|undefined} zip
         * @memberof compute_s3_sink.S3Object
         * @instance
         */
        S3Object.prototype.zip = null;

        /**
         * S3Object raw.
         * @member {compute_s3_sink.IRawObject|null|undefined} raw
         * @memberof compute_s3_sink.S3Object
         * @instance
         */
        S3Object.prototype.raw = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * S3Object format.
         * @member {"zip"|"raw"|undefined} format
         * @memberof compute_s3_sink.S3Object
         * @instance
         */
        Object.defineProperty(S3Object.prototype, "format", {
            get: $util.oneOfGetter($oneOfFields = ["zip", "raw"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new S3Object instance using the specified properties.
         * @function create
         * @memberof compute_s3_sink.S3Object
         * @static
         * @param {compute_s3_sink.IS3Object=} [properties] Properties to set
         * @returns {compute_s3_sink.S3Object} S3Object instance
         */
        S3Object.create = function create(properties) {
            return new S3Object(properties);
        };

        /**
         * Encodes the specified S3Object message. Does not implicitly {@link compute_s3_sink.S3Object.verify|verify} messages.
         * @function encode
         * @memberof compute_s3_sink.S3Object
         * @static
         * @param {compute_s3_sink.IS3Object} message S3Object message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        S3Object.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dependency != null && Object.hasOwnProperty.call(message, "dependency"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dependency);
            if (message.zip != null && Object.hasOwnProperty.call(message, "zip"))
                $root.compute_s3_sink.ZipObject.encode(message.zip, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.raw != null && Object.hasOwnProperty.call(message, "raw"))
                $root.compute_s3_sink.RawObject.encode(message.raw, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified S3Object message, length delimited. Does not implicitly {@link compute_s3_sink.S3Object.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_s3_sink.S3Object
         * @static
         * @param {compute_s3_sink.IS3Object} message S3Object message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        S3Object.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a S3Object message from the specified reader or buffer.
         * @function decode
         * @memberof compute_s3_sink.S3Object
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_s3_sink.S3Object} S3Object
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        S3Object.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_s3_sink.S3Object();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dependency = reader.string();
                        break;
                    }
                case 2: {
                        message.zip = $root.compute_s3_sink.ZipObject.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.raw = $root.compute_s3_sink.RawObject.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a S3Object message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_s3_sink.S3Object
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_s3_sink.S3Object} S3Object
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        S3Object.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a S3Object message.
         * @function verify
         * @memberof compute_s3_sink.S3Object
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        S3Object.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                if (!$util.isString(message.dependency))
                    return "dependency: string expected";
            if (message.zip != null && message.hasOwnProperty("zip")) {
                properties.format = 1;
                {
                    let error = $root.compute_s3_sink.ZipObject.verify(message.zip);
                    if (error)
                        return "zip." + error;
                }
            }
            if (message.raw != null && message.hasOwnProperty("raw")) {
                if (properties.format === 1)
                    return "format: multiple values";
                properties.format = 1;
                {
                    let error = $root.compute_s3_sink.RawObject.verify(message.raw);
                    if (error)
                        return "raw." + error;
                }
            }
            return null;
        };

        /**
         * Creates a S3Object message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_s3_sink.S3Object
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_s3_sink.S3Object} S3Object
         */
        S3Object.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_s3_sink.S3Object)
                return object;
            let message = new $root.compute_s3_sink.S3Object();
            if (object.dependency != null)
                message.dependency = String(object.dependency);
            if (object.zip != null) {
                if (typeof object.zip !== "object")
                    throw TypeError(".compute_s3_sink.S3Object.zip: object expected");
                message.zip = $root.compute_s3_sink.ZipObject.fromObject(object.zip);
            }
            if (object.raw != null) {
                if (typeof object.raw !== "object")
                    throw TypeError(".compute_s3_sink.S3Object.raw: object expected");
                message.raw = $root.compute_s3_sink.RawObject.fromObject(object.raw);
            }
            return message;
        };

        /**
         * Creates a plain object from a S3Object message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_s3_sink.S3Object
         * @static
         * @param {compute_s3_sink.S3Object} message S3Object
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        S3Object.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.dependency = "";
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                object.dependency = message.dependency;
            if (message.zip != null && message.hasOwnProperty("zip")) {
                object.zip = $root.compute_s3_sink.ZipObject.toObject(message.zip, options);
                if (options.oneofs)
                    object.format = "zip";
            }
            if (message.raw != null && message.hasOwnProperty("raw")) {
                object.raw = $root.compute_s3_sink.RawObject.toObject(message.raw, options);
                if (options.oneofs)
                    object.format = "raw";
            }
            return object;
        };

        /**
         * Converts this S3Object to JSON.
         * @function toJSON
         * @memberof compute_s3_sink.S3Object
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        S3Object.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for S3Object
         * @function getTypeUrl
         * @memberof compute_s3_sink.S3Object
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        S3Object.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_s3_sink.S3Object";
        };

        return S3Object;
    })();

    compute_s3_sink.RawObject = (function() {

        /**
         * Properties of a RawObject.
         * @memberof compute_s3_sink
         * @interface IRawObject
         * @property {string|null} [key] RawObject key
         */

        /**
         * Constructs a new RawObject.
         * @memberof compute_s3_sink
         * @classdesc Represents a RawObject.
         * @implements IRawObject
         * @constructor
         * @param {compute_s3_sink.IRawObject=} [properties] Properties to set
         */
        function RawObject(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RawObject key.
         * @member {string} key
         * @memberof compute_s3_sink.RawObject
         * @instance
         */
        RawObject.prototype.key = "";

        /**
         * Creates a new RawObject instance using the specified properties.
         * @function create
         * @memberof compute_s3_sink.RawObject
         * @static
         * @param {compute_s3_sink.IRawObject=} [properties] Properties to set
         * @returns {compute_s3_sink.RawObject} RawObject instance
         */
        RawObject.create = function create(properties) {
            return new RawObject(properties);
        };

        /**
         * Encodes the specified RawObject message. Does not implicitly {@link compute_s3_sink.RawObject.verify|verify} messages.
         * @function encode
         * @memberof compute_s3_sink.RawObject
         * @static
         * @param {compute_s3_sink.IRawObject} message RawObject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawObject.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            return writer;
        };

        /**
         * Encodes the specified RawObject message, length delimited. Does not implicitly {@link compute_s3_sink.RawObject.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_s3_sink.RawObject
         * @static
         * @param {compute_s3_sink.IRawObject} message RawObject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawObject.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RawObject message from the specified reader or buffer.
         * @function decode
         * @memberof compute_s3_sink.RawObject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_s3_sink.RawObject} RawObject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawObject.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_s3_sink.RawObject();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RawObject message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_s3_sink.RawObject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_s3_sink.RawObject} RawObject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawObject.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RawObject message.
         * @function verify
         * @memberof compute_s3_sink.RawObject
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RawObject.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            return null;
        };

        /**
         * Creates a RawObject message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_s3_sink.RawObject
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_s3_sink.RawObject} RawObject
         */
        RawObject.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_s3_sink.RawObject)
                return object;
            let message = new $root.compute_s3_sink.RawObject();
            if (object.key != null)
                message.key = String(object.key);
            return message;
        };

        /**
         * Creates a plain object from a RawObject message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_s3_sink.RawObject
         * @static
         * @param {compute_s3_sink.RawObject} message RawObject
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RawObject.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.key = "";
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            return object;
        };

        /**
         * Converts this RawObject to JSON.
         * @function toJSON
         * @memberof compute_s3_sink.RawObject
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RawObject.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RawObject
         * @function getTypeUrl
         * @memberof compute_s3_sink.RawObject
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RawObject.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_s3_sink.RawObject";
        };

        return RawObject;
    })();

    compute_s3_sink.ZipObject = (function() {

        /**
         * Properties of a ZipObject.
         * @memberof compute_s3_sink
         * @interface IZipObject
         * @property {compute_s3_sink.ISingleFile|null} [singleFile] ZipObject singleFile
         * @property {compute_s3_sink.IFullContent|null} [fullContent] ZipObject fullContent
         */

        /**
         * Constructs a new ZipObject.
         * @memberof compute_s3_sink
         * @classdesc Represents a ZipObject.
         * @implements IZipObject
         * @constructor
         * @param {compute_s3_sink.IZipObject=} [properties] Properties to set
         */
        function ZipObject(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ZipObject singleFile.
         * @member {compute_s3_sink.ISingleFile|null|undefined} singleFile
         * @memberof compute_s3_sink.ZipObject
         * @instance
         */
        ZipObject.prototype.singleFile = null;

        /**
         * ZipObject fullContent.
         * @member {compute_s3_sink.IFullContent|null|undefined} fullContent
         * @memberof compute_s3_sink.ZipObject
         * @instance
         */
        ZipObject.prototype.fullContent = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ZipObject kind.
         * @member {"singleFile"|"fullContent"|undefined} kind
         * @memberof compute_s3_sink.ZipObject
         * @instance
         */
        Object.defineProperty(ZipObject.prototype, "kind", {
            get: $util.oneOfGetter($oneOfFields = ["singleFile", "fullContent"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ZipObject instance using the specified properties.
         * @function create
         * @memberof compute_s3_sink.ZipObject
         * @static
         * @param {compute_s3_sink.IZipObject=} [properties] Properties to set
         * @returns {compute_s3_sink.ZipObject} ZipObject instance
         */
        ZipObject.create = function create(properties) {
            return new ZipObject(properties);
        };

        /**
         * Encodes the specified ZipObject message. Does not implicitly {@link compute_s3_sink.ZipObject.verify|verify} messages.
         * @function encode
         * @memberof compute_s3_sink.ZipObject
         * @static
         * @param {compute_s3_sink.IZipObject} message ZipObject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipObject.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.singleFile != null && Object.hasOwnProperty.call(message, "singleFile"))
                $root.compute_s3_sink.SingleFile.encode(message.singleFile, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.fullContent != null && Object.hasOwnProperty.call(message, "fullContent"))
                $root.compute_s3_sink.FullContent.encode(message.fullContent, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ZipObject message, length delimited. Does not implicitly {@link compute_s3_sink.ZipObject.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_s3_sink.ZipObject
         * @static
         * @param {compute_s3_sink.IZipObject} message ZipObject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipObject.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ZipObject message from the specified reader or buffer.
         * @function decode
         * @memberof compute_s3_sink.ZipObject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_s3_sink.ZipObject} ZipObject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipObject.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_s3_sink.ZipObject();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.singleFile = $root.compute_s3_sink.SingleFile.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.fullContent = $root.compute_s3_sink.FullContent.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ZipObject message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_s3_sink.ZipObject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_s3_sink.ZipObject} ZipObject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipObject.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ZipObject message.
         * @function verify
         * @memberof compute_s3_sink.ZipObject
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ZipObject.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.singleFile != null && message.hasOwnProperty("singleFile")) {
                properties.kind = 1;
                {
                    let error = $root.compute_s3_sink.SingleFile.verify(message.singleFile);
                    if (error)
                        return "singleFile." + error;
                }
            }
            if (message.fullContent != null && message.hasOwnProperty("fullContent")) {
                if (properties.kind === 1)
                    return "kind: multiple values";
                properties.kind = 1;
                {
                    let error = $root.compute_s3_sink.FullContent.verify(message.fullContent);
                    if (error)
                        return "fullContent." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ZipObject message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_s3_sink.ZipObject
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_s3_sink.ZipObject} ZipObject
         */
        ZipObject.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_s3_sink.ZipObject)
                return object;
            let message = new $root.compute_s3_sink.ZipObject();
            if (object.singleFile != null) {
                if (typeof object.singleFile !== "object")
                    throw TypeError(".compute_s3_sink.ZipObject.singleFile: object expected");
                message.singleFile = $root.compute_s3_sink.SingleFile.fromObject(object.singleFile);
            }
            if (object.fullContent != null) {
                if (typeof object.fullContent !== "object")
                    throw TypeError(".compute_s3_sink.ZipObject.fullContent: object expected");
                message.fullContent = $root.compute_s3_sink.FullContent.fromObject(object.fullContent);
            }
            return message;
        };

        /**
         * Creates a plain object from a ZipObject message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_s3_sink.ZipObject
         * @static
         * @param {compute_s3_sink.ZipObject} message ZipObject
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ZipObject.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.singleFile != null && message.hasOwnProperty("singleFile")) {
                object.singleFile = $root.compute_s3_sink.SingleFile.toObject(message.singleFile, options);
                if (options.oneofs)
                    object.kind = "singleFile";
            }
            if (message.fullContent != null && message.hasOwnProperty("fullContent")) {
                object.fullContent = $root.compute_s3_sink.FullContent.toObject(message.fullContent, options);
                if (options.oneofs)
                    object.kind = "fullContent";
            }
            return object;
        };

        /**
         * Converts this ZipObject to JSON.
         * @function toJSON
         * @memberof compute_s3_sink.ZipObject
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ZipObject.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ZipObject
         * @function getTypeUrl
         * @memberof compute_s3_sink.ZipObject
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ZipObject.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_s3_sink.ZipObject";
        };

        return ZipObject;
    })();

    compute_s3_sink.SingleFile = (function() {

        /**
         * Properties of a SingleFile.
         * @memberof compute_s3_sink
         * @interface ISingleFile
         * @property {string|null} [key] SingleFile key
         * @property {string|null} [path] SingleFile path
         */

        /**
         * Constructs a new SingleFile.
         * @memberof compute_s3_sink
         * @classdesc Represents a SingleFile.
         * @implements ISingleFile
         * @constructor
         * @param {compute_s3_sink.ISingleFile=} [properties] Properties to set
         */
        function SingleFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SingleFile key.
         * @member {string} key
         * @memberof compute_s3_sink.SingleFile
         * @instance
         */
        SingleFile.prototype.key = "";

        /**
         * SingleFile path.
         * @member {string} path
         * @memberof compute_s3_sink.SingleFile
         * @instance
         */
        SingleFile.prototype.path = "";

        /**
         * Creates a new SingleFile instance using the specified properties.
         * @function create
         * @memberof compute_s3_sink.SingleFile
         * @static
         * @param {compute_s3_sink.ISingleFile=} [properties] Properties to set
         * @returns {compute_s3_sink.SingleFile} SingleFile instance
         */
        SingleFile.create = function create(properties) {
            return new SingleFile(properties);
        };

        /**
         * Encodes the specified SingleFile message. Does not implicitly {@link compute_s3_sink.SingleFile.verify|verify} messages.
         * @function encode
         * @memberof compute_s3_sink.SingleFile
         * @static
         * @param {compute_s3_sink.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.path);
            return writer;
        };

        /**
         * Encodes the specified SingleFile message, length delimited. Does not implicitly {@link compute_s3_sink.SingleFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_s3_sink.SingleFile
         * @static
         * @param {compute_s3_sink.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer.
         * @function decode
         * @memberof compute_s3_sink.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_s3_sink.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_s3_sink.SingleFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                case 2: {
                        message.path = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_s3_sink.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_s3_sink.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SingleFile message.
         * @function verify
         * @memberof compute_s3_sink.SingleFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SingleFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.path != null && message.hasOwnProperty("path"))
                if (!$util.isString(message.path))
                    return "path: string expected";
            return null;
        };

        /**
         * Creates a SingleFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_s3_sink.SingleFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_s3_sink.SingleFile} SingleFile
         */
        SingleFile.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_s3_sink.SingleFile)
                return object;
            let message = new $root.compute_s3_sink.SingleFile();
            if (object.key != null)
                message.key = String(object.key);
            if (object.path != null)
                message.path = String(object.path);
            return message;
        };

        /**
         * Creates a plain object from a SingleFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_s3_sink.SingleFile
         * @static
         * @param {compute_s3_sink.SingleFile} message SingleFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SingleFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.path = "";
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.path != null && message.hasOwnProperty("path"))
                object.path = message.path;
            return object;
        };

        /**
         * Converts this SingleFile to JSON.
         * @function toJSON
         * @memberof compute_s3_sink.SingleFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SingleFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SingleFile
         * @function getTypeUrl
         * @memberof compute_s3_sink.SingleFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SingleFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_s3_sink.SingleFile";
        };

        return SingleFile;
    })();

    compute_s3_sink.FullContent = (function() {

        /**
         * Properties of a FullContent.
         * @memberof compute_s3_sink
         * @interface IFullContent
         */

        /**
         * Constructs a new FullContent.
         * @memberof compute_s3_sink
         * @classdesc Represents a FullContent.
         * @implements IFullContent
         * @constructor
         * @param {compute_s3_sink.IFullContent=} [properties] Properties to set
         */
        function FullContent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new FullContent instance using the specified properties.
         * @function create
         * @memberof compute_s3_sink.FullContent
         * @static
         * @param {compute_s3_sink.IFullContent=} [properties] Properties to set
         * @returns {compute_s3_sink.FullContent} FullContent instance
         */
        FullContent.create = function create(properties) {
            return new FullContent(properties);
        };

        /**
         * Encodes the specified FullContent message. Does not implicitly {@link compute_s3_sink.FullContent.verify|verify} messages.
         * @function encode
         * @memberof compute_s3_sink.FullContent
         * @static
         * @param {compute_s3_sink.IFullContent} message FullContent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FullContent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified FullContent message, length delimited. Does not implicitly {@link compute_s3_sink.FullContent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_s3_sink.FullContent
         * @static
         * @param {compute_s3_sink.IFullContent} message FullContent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FullContent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FullContent message from the specified reader or buffer.
         * @function decode
         * @memberof compute_s3_sink.FullContent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_s3_sink.FullContent} FullContent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FullContent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_s3_sink.FullContent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FullContent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_s3_sink.FullContent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_s3_sink.FullContent} FullContent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FullContent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FullContent message.
         * @function verify
         * @memberof compute_s3_sink.FullContent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FullContent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a FullContent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_s3_sink.FullContent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_s3_sink.FullContent} FullContent
         */
        FullContent.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_s3_sink.FullContent)
                return object;
            return new $root.compute_s3_sink.FullContent();
        };

        /**
         * Creates a plain object from a FullContent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_s3_sink.FullContent
         * @static
         * @param {compute_s3_sink.FullContent} message FullContent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FullContent.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this FullContent to JSON.
         * @function toJSON
         * @memberof compute_s3_sink.FullContent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FullContent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FullContent
         * @function getTypeUrl
         * @memberof compute_s3_sink.FullContent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FullContent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_s3_sink.FullContent";
        };

        return FullContent;
    })();

    compute_s3_sink.S3Credentials = (function() {

        /**
         * Properties of a S3Credentials.
         * @memberof compute_s3_sink
         * @interface IS3Credentials
         * @property {string|null} [accessKey] S3Credentials accessKey
         * @property {string|null} [secretKey] S3Credentials secretKey
         */

        /**
         * Constructs a new S3Credentials.
         * @memberof compute_s3_sink
         * @classdesc Represents a S3Credentials.
         * @implements IS3Credentials
         * @constructor
         * @param {compute_s3_sink.IS3Credentials=} [properties] Properties to set
         */
        function S3Credentials(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * S3Credentials accessKey.
         * @member {string} accessKey
         * @memberof compute_s3_sink.S3Credentials
         * @instance
         */
        S3Credentials.prototype.accessKey = "";

        /**
         * S3Credentials secretKey.
         * @member {string} secretKey
         * @memberof compute_s3_sink.S3Credentials
         * @instance
         */
        S3Credentials.prototype.secretKey = "";

        /**
         * Creates a new S3Credentials instance using the specified properties.
         * @function create
         * @memberof compute_s3_sink.S3Credentials
         * @static
         * @param {compute_s3_sink.IS3Credentials=} [properties] Properties to set
         * @returns {compute_s3_sink.S3Credentials} S3Credentials instance
         */
        S3Credentials.create = function create(properties) {
            return new S3Credentials(properties);
        };

        /**
         * Encodes the specified S3Credentials message. Does not implicitly {@link compute_s3_sink.S3Credentials.verify|verify} messages.
         * @function encode
         * @memberof compute_s3_sink.S3Credentials
         * @static
         * @param {compute_s3_sink.IS3Credentials} message S3Credentials message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        S3Credentials.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.accessKey != null && Object.hasOwnProperty.call(message, "accessKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.accessKey);
            if (message.secretKey != null && Object.hasOwnProperty.call(message, "secretKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.secretKey);
            return writer;
        };

        /**
         * Encodes the specified S3Credentials message, length delimited. Does not implicitly {@link compute_s3_sink.S3Credentials.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_s3_sink.S3Credentials
         * @static
         * @param {compute_s3_sink.IS3Credentials} message S3Credentials message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        S3Credentials.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a S3Credentials message from the specified reader or buffer.
         * @function decode
         * @memberof compute_s3_sink.S3Credentials
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_s3_sink.S3Credentials} S3Credentials
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        S3Credentials.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_s3_sink.S3Credentials();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.accessKey = reader.string();
                        break;
                    }
                case 2: {
                        message.secretKey = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a S3Credentials message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_s3_sink.S3Credentials
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_s3_sink.S3Credentials} S3Credentials
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        S3Credentials.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a S3Credentials message.
         * @function verify
         * @memberof compute_s3_sink.S3Credentials
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        S3Credentials.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.accessKey != null && message.hasOwnProperty("accessKey"))
                if (!$util.isString(message.accessKey))
                    return "accessKey: string expected";
            if (message.secretKey != null && message.hasOwnProperty("secretKey"))
                if (!$util.isString(message.secretKey))
                    return "secretKey: string expected";
            return null;
        };

        /**
         * Creates a S3Credentials message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_s3_sink.S3Credentials
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_s3_sink.S3Credentials} S3Credentials
         */
        S3Credentials.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_s3_sink.S3Credentials)
                return object;
            let message = new $root.compute_s3_sink.S3Credentials();
            if (object.accessKey != null)
                message.accessKey = String(object.accessKey);
            if (object.secretKey != null)
                message.secretKey = String(object.secretKey);
            return message;
        };

        /**
         * Creates a plain object from a S3Credentials message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_s3_sink.S3Credentials
         * @static
         * @param {compute_s3_sink.S3Credentials} message S3Credentials
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        S3Credentials.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.accessKey = "";
                object.secretKey = "";
            }
            if (message.accessKey != null && message.hasOwnProperty("accessKey"))
                object.accessKey = message.accessKey;
            if (message.secretKey != null && message.hasOwnProperty("secretKey"))
                object.secretKey = message.secretKey;
            return object;
        };

        /**
         * Converts this S3Credentials to JSON.
         * @function toJSON
         * @memberof compute_s3_sink.S3Credentials
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        S3Credentials.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for S3Credentials
         * @function getTypeUrl
         * @memberof compute_s3_sink.S3Credentials
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        S3Credentials.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_s3_sink.S3Credentials";
        };

        return S3Credentials;
    })();

    return compute_s3_sink;
})();

export const compute_sql = $root.compute_sql = (() => {

    /**
     * Namespace compute_sql.
     * @exports compute_sql
     * @namespace
     */
    const compute_sql = {};

    compute_sql.SqlWorkerConfiguration = (function() {

        /**
         * Properties of a SqlWorkerConfiguration.
         * @memberof compute_sql
         * @interface ISqlWorkerConfiguration
         * @property {compute_sql.IValidationConfiguration|null} [validation] SqlWorkerConfiguration validation
         * @property {compute_sql.IComputationConfiguration|null} [computation] SqlWorkerConfiguration computation
         */

        /**
         * Constructs a new SqlWorkerConfiguration.
         * @memberof compute_sql
         * @classdesc Represents a SqlWorkerConfiguration.
         * @implements ISqlWorkerConfiguration
         * @constructor
         * @param {compute_sql.ISqlWorkerConfiguration=} [properties] Properties to set
         */
        function SqlWorkerConfiguration(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SqlWorkerConfiguration validation.
         * @member {compute_sql.IValidationConfiguration|null|undefined} validation
         * @memberof compute_sql.SqlWorkerConfiguration
         * @instance
         */
        SqlWorkerConfiguration.prototype.validation = null;

        /**
         * SqlWorkerConfiguration computation.
         * @member {compute_sql.IComputationConfiguration|null|undefined} computation
         * @memberof compute_sql.SqlWorkerConfiguration
         * @instance
         */
        SqlWorkerConfiguration.prototype.computation = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SqlWorkerConfiguration configuration.
         * @member {"validation"|"computation"|undefined} configuration
         * @memberof compute_sql.SqlWorkerConfiguration
         * @instance
         */
        Object.defineProperty(SqlWorkerConfiguration.prototype, "configuration", {
            get: $util.oneOfGetter($oneOfFields = ["validation", "computation"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SqlWorkerConfiguration instance using the specified properties.
         * @function create
         * @memberof compute_sql.SqlWorkerConfiguration
         * @static
         * @param {compute_sql.ISqlWorkerConfiguration=} [properties] Properties to set
         * @returns {compute_sql.SqlWorkerConfiguration} SqlWorkerConfiguration instance
         */
        SqlWorkerConfiguration.create = function create(properties) {
            return new SqlWorkerConfiguration(properties);
        };

        /**
         * Encodes the specified SqlWorkerConfiguration message. Does not implicitly {@link compute_sql.SqlWorkerConfiguration.verify|verify} messages.
         * @function encode
         * @memberof compute_sql.SqlWorkerConfiguration
         * @static
         * @param {compute_sql.ISqlWorkerConfiguration} message SqlWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SqlWorkerConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.validation != null && Object.hasOwnProperty.call(message, "validation"))
                $root.compute_sql.ValidationConfiguration.encode(message.validation, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.computation != null && Object.hasOwnProperty.call(message, "computation"))
                $root.compute_sql.ComputationConfiguration.encode(message.computation, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SqlWorkerConfiguration message, length delimited. Does not implicitly {@link compute_sql.SqlWorkerConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_sql.SqlWorkerConfiguration
         * @static
         * @param {compute_sql.ISqlWorkerConfiguration} message SqlWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SqlWorkerConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SqlWorkerConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof compute_sql.SqlWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_sql.SqlWorkerConfiguration} SqlWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SqlWorkerConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_sql.SqlWorkerConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.validation = $root.compute_sql.ValidationConfiguration.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.computation = $root.compute_sql.ComputationConfiguration.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SqlWorkerConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_sql.SqlWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_sql.SqlWorkerConfiguration} SqlWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SqlWorkerConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SqlWorkerConfiguration message.
         * @function verify
         * @memberof compute_sql.SqlWorkerConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SqlWorkerConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.validation != null && message.hasOwnProperty("validation")) {
                properties.configuration = 1;
                {
                    let error = $root.compute_sql.ValidationConfiguration.verify(message.validation);
                    if (error)
                        return "validation." + error;
                }
            }
            if (message.computation != null && message.hasOwnProperty("computation")) {
                if (properties.configuration === 1)
                    return "configuration: multiple values";
                properties.configuration = 1;
                {
                    let error = $root.compute_sql.ComputationConfiguration.verify(message.computation);
                    if (error)
                        return "computation." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SqlWorkerConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_sql.SqlWorkerConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_sql.SqlWorkerConfiguration} SqlWorkerConfiguration
         */
        SqlWorkerConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_sql.SqlWorkerConfiguration)
                return object;
            let message = new $root.compute_sql.SqlWorkerConfiguration();
            if (object.validation != null) {
                if (typeof object.validation !== "object")
                    throw TypeError(".compute_sql.SqlWorkerConfiguration.validation: object expected");
                message.validation = $root.compute_sql.ValidationConfiguration.fromObject(object.validation);
            }
            if (object.computation != null) {
                if (typeof object.computation !== "object")
                    throw TypeError(".compute_sql.SqlWorkerConfiguration.computation: object expected");
                message.computation = $root.compute_sql.ComputationConfiguration.fromObject(object.computation);
            }
            return message;
        };

        /**
         * Creates a plain object from a SqlWorkerConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_sql.SqlWorkerConfiguration
         * @static
         * @param {compute_sql.SqlWorkerConfiguration} message SqlWorkerConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SqlWorkerConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.validation != null && message.hasOwnProperty("validation")) {
                object.validation = $root.compute_sql.ValidationConfiguration.toObject(message.validation, options);
                if (options.oneofs)
                    object.configuration = "validation";
            }
            if (message.computation != null && message.hasOwnProperty("computation")) {
                object.computation = $root.compute_sql.ComputationConfiguration.toObject(message.computation, options);
                if (options.oneofs)
                    object.configuration = "computation";
            }
            return object;
        };

        /**
         * Converts this SqlWorkerConfiguration to JSON.
         * @function toJSON
         * @memberof compute_sql.SqlWorkerConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SqlWorkerConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SqlWorkerConfiguration
         * @function getTypeUrl
         * @memberof compute_sql.SqlWorkerConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SqlWorkerConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_sql.SqlWorkerConfiguration";
        };

        return SqlWorkerConfiguration;
    })();

    compute_sql.ValidationConfiguration = (function() {

        /**
         * Properties of a ValidationConfiguration.
         * @memberof compute_sql
         * @interface IValidationConfiguration
         * @property {compute_sql.ITableSchema|null} [tableSchema] ValidationConfiguration tableSchema
         */

        /**
         * Constructs a new ValidationConfiguration.
         * @memberof compute_sql
         * @classdesc Represents a ValidationConfiguration.
         * @implements IValidationConfiguration
         * @constructor
         * @param {compute_sql.IValidationConfiguration=} [properties] Properties to set
         */
        function ValidationConfiguration(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ValidationConfiguration tableSchema.
         * @member {compute_sql.ITableSchema|null|undefined} tableSchema
         * @memberof compute_sql.ValidationConfiguration
         * @instance
         */
        ValidationConfiguration.prototype.tableSchema = null;

        /**
         * Creates a new ValidationConfiguration instance using the specified properties.
         * @function create
         * @memberof compute_sql.ValidationConfiguration
         * @static
         * @param {compute_sql.IValidationConfiguration=} [properties] Properties to set
         * @returns {compute_sql.ValidationConfiguration} ValidationConfiguration instance
         */
        ValidationConfiguration.create = function create(properties) {
            return new ValidationConfiguration(properties);
        };

        /**
         * Encodes the specified ValidationConfiguration message. Does not implicitly {@link compute_sql.ValidationConfiguration.verify|verify} messages.
         * @function encode
         * @memberof compute_sql.ValidationConfiguration
         * @static
         * @param {compute_sql.IValidationConfiguration} message ValidationConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ValidationConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tableSchema != null && Object.hasOwnProperty.call(message, "tableSchema"))
                $root.compute_sql.TableSchema.encode(message.tableSchema, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ValidationConfiguration message, length delimited. Does not implicitly {@link compute_sql.ValidationConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_sql.ValidationConfiguration
         * @static
         * @param {compute_sql.IValidationConfiguration} message ValidationConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ValidationConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ValidationConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof compute_sql.ValidationConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_sql.ValidationConfiguration} ValidationConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ValidationConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_sql.ValidationConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.tableSchema = $root.compute_sql.TableSchema.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ValidationConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_sql.ValidationConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_sql.ValidationConfiguration} ValidationConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ValidationConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ValidationConfiguration message.
         * @function verify
         * @memberof compute_sql.ValidationConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ValidationConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tableSchema != null && message.hasOwnProperty("tableSchema")) {
                let error = $root.compute_sql.TableSchema.verify(message.tableSchema);
                if (error)
                    return "tableSchema." + error;
            }
            return null;
        };

        /**
         * Creates a ValidationConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_sql.ValidationConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_sql.ValidationConfiguration} ValidationConfiguration
         */
        ValidationConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_sql.ValidationConfiguration)
                return object;
            let message = new $root.compute_sql.ValidationConfiguration();
            if (object.tableSchema != null) {
                if (typeof object.tableSchema !== "object")
                    throw TypeError(".compute_sql.ValidationConfiguration.tableSchema: object expected");
                message.tableSchema = $root.compute_sql.TableSchema.fromObject(object.tableSchema);
            }
            return message;
        };

        /**
         * Creates a plain object from a ValidationConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_sql.ValidationConfiguration
         * @static
         * @param {compute_sql.ValidationConfiguration} message ValidationConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ValidationConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.tableSchema = null;
            if (message.tableSchema != null && message.hasOwnProperty("tableSchema"))
                object.tableSchema = $root.compute_sql.TableSchema.toObject(message.tableSchema, options);
            return object;
        };

        /**
         * Converts this ValidationConfiguration to JSON.
         * @function toJSON
         * @memberof compute_sql.ValidationConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ValidationConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ValidationConfiguration
         * @function getTypeUrl
         * @memberof compute_sql.ValidationConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ValidationConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_sql.ValidationConfiguration";
        };

        return ValidationConfiguration;
    })();

    compute_sql.TableSchema = (function() {

        /**
         * Properties of a TableSchema.
         * @memberof compute_sql
         * @interface ITableSchema
         * @property {Array.<compute_sql.INamedColumn>|null} [namedColumns] TableSchema namedColumns
         */

        /**
         * Constructs a new TableSchema.
         * @memberof compute_sql
         * @classdesc Represents a TableSchema.
         * @implements ITableSchema
         * @constructor
         * @param {compute_sql.ITableSchema=} [properties] Properties to set
         */
        function TableSchema(properties) {
            this.namedColumns = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TableSchema namedColumns.
         * @member {Array.<compute_sql.INamedColumn>} namedColumns
         * @memberof compute_sql.TableSchema
         * @instance
         */
        TableSchema.prototype.namedColumns = $util.emptyArray;

        /**
         * Creates a new TableSchema instance using the specified properties.
         * @function create
         * @memberof compute_sql.TableSchema
         * @static
         * @param {compute_sql.ITableSchema=} [properties] Properties to set
         * @returns {compute_sql.TableSchema} TableSchema instance
         */
        TableSchema.create = function create(properties) {
            return new TableSchema(properties);
        };

        /**
         * Encodes the specified TableSchema message. Does not implicitly {@link compute_sql.TableSchema.verify|verify} messages.
         * @function encode
         * @memberof compute_sql.TableSchema
         * @static
         * @param {compute_sql.ITableSchema} message TableSchema message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableSchema.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.namedColumns != null && message.namedColumns.length)
                for (let i = 0; i < message.namedColumns.length; ++i)
                    $root.compute_sql.NamedColumn.encode(message.namedColumns[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified TableSchema message, length delimited. Does not implicitly {@link compute_sql.TableSchema.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_sql.TableSchema
         * @static
         * @param {compute_sql.ITableSchema} message TableSchema message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableSchema.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TableSchema message from the specified reader or buffer.
         * @function decode
         * @memberof compute_sql.TableSchema
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_sql.TableSchema} TableSchema
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableSchema.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_sql.TableSchema();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.namedColumns && message.namedColumns.length))
                            message.namedColumns = [];
                        message.namedColumns.push($root.compute_sql.NamedColumn.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TableSchema message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_sql.TableSchema
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_sql.TableSchema} TableSchema
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableSchema.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TableSchema message.
         * @function verify
         * @memberof compute_sql.TableSchema
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TableSchema.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.namedColumns != null && message.hasOwnProperty("namedColumns")) {
                if (!Array.isArray(message.namedColumns))
                    return "namedColumns: array expected";
                for (let i = 0; i < message.namedColumns.length; ++i) {
                    let error = $root.compute_sql.NamedColumn.verify(message.namedColumns[i]);
                    if (error)
                        return "namedColumns." + error;
                }
            }
            return null;
        };

        /**
         * Creates a TableSchema message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_sql.TableSchema
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_sql.TableSchema} TableSchema
         */
        TableSchema.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_sql.TableSchema)
                return object;
            let message = new $root.compute_sql.TableSchema();
            if (object.namedColumns) {
                if (!Array.isArray(object.namedColumns))
                    throw TypeError(".compute_sql.TableSchema.namedColumns: array expected");
                message.namedColumns = [];
                for (let i = 0; i < object.namedColumns.length; ++i) {
                    if (typeof object.namedColumns[i] !== "object")
                        throw TypeError(".compute_sql.TableSchema.namedColumns: object expected");
                    message.namedColumns[i] = $root.compute_sql.NamedColumn.fromObject(object.namedColumns[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a TableSchema message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_sql.TableSchema
         * @static
         * @param {compute_sql.TableSchema} message TableSchema
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TableSchema.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.namedColumns = [];
            if (message.namedColumns && message.namedColumns.length) {
                object.namedColumns = [];
                for (let j = 0; j < message.namedColumns.length; ++j)
                    object.namedColumns[j] = $root.compute_sql.NamedColumn.toObject(message.namedColumns[j], options);
            }
            return object;
        };

        /**
         * Converts this TableSchema to JSON.
         * @function toJSON
         * @memberof compute_sql.TableSchema
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TableSchema.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TableSchema
         * @function getTypeUrl
         * @memberof compute_sql.TableSchema
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TableSchema.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_sql.TableSchema";
        };

        return TableSchema;
    })();

    compute_sql.NamedColumn = (function() {

        /**
         * Properties of a NamedColumn.
         * @memberof compute_sql
         * @interface INamedColumn
         * @property {string|null} [name] NamedColumn name
         * @property {compute_sql.IColumnType|null} [columnType] NamedColumn columnType
         */

        /**
         * Constructs a new NamedColumn.
         * @memberof compute_sql
         * @classdesc Represents a NamedColumn.
         * @implements INamedColumn
         * @constructor
         * @param {compute_sql.INamedColumn=} [properties] Properties to set
         */
        function NamedColumn(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NamedColumn name.
         * @member {string|null|undefined} name
         * @memberof compute_sql.NamedColumn
         * @instance
         */
        NamedColumn.prototype.name = null;

        /**
         * NamedColumn columnType.
         * @member {compute_sql.IColumnType|null|undefined} columnType
         * @memberof compute_sql.NamedColumn
         * @instance
         */
        NamedColumn.prototype.columnType = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * NamedColumn _name.
         * @member {"name"|undefined} _name
         * @memberof compute_sql.NamedColumn
         * @instance
         */
        Object.defineProperty(NamedColumn.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new NamedColumn instance using the specified properties.
         * @function create
         * @memberof compute_sql.NamedColumn
         * @static
         * @param {compute_sql.INamedColumn=} [properties] Properties to set
         * @returns {compute_sql.NamedColumn} NamedColumn instance
         */
        NamedColumn.create = function create(properties) {
            return new NamedColumn(properties);
        };

        /**
         * Encodes the specified NamedColumn message. Does not implicitly {@link compute_sql.NamedColumn.verify|verify} messages.
         * @function encode
         * @memberof compute_sql.NamedColumn
         * @static
         * @param {compute_sql.INamedColumn} message NamedColumn message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NamedColumn.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.columnType != null && Object.hasOwnProperty.call(message, "columnType"))
                $root.compute_sql.ColumnType.encode(message.columnType, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NamedColumn message, length delimited. Does not implicitly {@link compute_sql.NamedColumn.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_sql.NamedColumn
         * @static
         * @param {compute_sql.INamedColumn} message NamedColumn message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NamedColumn.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NamedColumn message from the specified reader or buffer.
         * @function decode
         * @memberof compute_sql.NamedColumn
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_sql.NamedColumn} NamedColumn
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NamedColumn.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_sql.NamedColumn();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.columnType = $root.compute_sql.ColumnType.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NamedColumn message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_sql.NamedColumn
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_sql.NamedColumn} NamedColumn
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NamedColumn.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NamedColumn message.
         * @function verify
         * @memberof compute_sql.NamedColumn
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NamedColumn.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.name != null && message.hasOwnProperty("name")) {
                properties._name = 1;
                if (!$util.isString(message.name))
                    return "name: string expected";
            }
            if (message.columnType != null && message.hasOwnProperty("columnType")) {
                let error = $root.compute_sql.ColumnType.verify(message.columnType);
                if (error)
                    return "columnType." + error;
            }
            return null;
        };

        /**
         * Creates a NamedColumn message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_sql.NamedColumn
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_sql.NamedColumn} NamedColumn
         */
        NamedColumn.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_sql.NamedColumn)
                return object;
            let message = new $root.compute_sql.NamedColumn();
            if (object.name != null)
                message.name = String(object.name);
            if (object.columnType != null) {
                if (typeof object.columnType !== "object")
                    throw TypeError(".compute_sql.NamedColumn.columnType: object expected");
                message.columnType = $root.compute_sql.ColumnType.fromObject(object.columnType);
            }
            return message;
        };

        /**
         * Creates a plain object from a NamedColumn message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_sql.NamedColumn
         * @static
         * @param {compute_sql.NamedColumn} message NamedColumn
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NamedColumn.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.columnType = null;
            if (message.name != null && message.hasOwnProperty("name")) {
                object.name = message.name;
                if (options.oneofs)
                    object._name = "name";
            }
            if (message.columnType != null && message.hasOwnProperty("columnType"))
                object.columnType = $root.compute_sql.ColumnType.toObject(message.columnType, options);
            return object;
        };

        /**
         * Converts this NamedColumn to JSON.
         * @function toJSON
         * @memberof compute_sql.NamedColumn
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NamedColumn.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NamedColumn
         * @function getTypeUrl
         * @memberof compute_sql.NamedColumn
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NamedColumn.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_sql.NamedColumn";
        };

        return NamedColumn;
    })();

    compute_sql.ColumnType = (function() {

        /**
         * Properties of a ColumnType.
         * @memberof compute_sql
         * @interface IColumnType
         * @property {compute_sql.PrimitiveType|null} [primitiveType] ColumnType primitiveType
         * @property {boolean|null} [nullable] ColumnType nullable
         */

        /**
         * Constructs a new ColumnType.
         * @memberof compute_sql
         * @classdesc Represents a ColumnType.
         * @implements IColumnType
         * @constructor
         * @param {compute_sql.IColumnType=} [properties] Properties to set
         */
        function ColumnType(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ColumnType primitiveType.
         * @member {compute_sql.PrimitiveType} primitiveType
         * @memberof compute_sql.ColumnType
         * @instance
         */
        ColumnType.prototype.primitiveType = 0;

        /**
         * ColumnType nullable.
         * @member {boolean} nullable
         * @memberof compute_sql.ColumnType
         * @instance
         */
        ColumnType.prototype.nullable = false;

        /**
         * Creates a new ColumnType instance using the specified properties.
         * @function create
         * @memberof compute_sql.ColumnType
         * @static
         * @param {compute_sql.IColumnType=} [properties] Properties to set
         * @returns {compute_sql.ColumnType} ColumnType instance
         */
        ColumnType.create = function create(properties) {
            return new ColumnType(properties);
        };

        /**
         * Encodes the specified ColumnType message. Does not implicitly {@link compute_sql.ColumnType.verify|verify} messages.
         * @function encode
         * @memberof compute_sql.ColumnType
         * @static
         * @param {compute_sql.IColumnType} message ColumnType message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ColumnType.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.primitiveType != null && Object.hasOwnProperty.call(message, "primitiveType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.primitiveType);
            if (message.nullable != null && Object.hasOwnProperty.call(message, "nullable"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.nullable);
            return writer;
        };

        /**
         * Encodes the specified ColumnType message, length delimited. Does not implicitly {@link compute_sql.ColumnType.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_sql.ColumnType
         * @static
         * @param {compute_sql.IColumnType} message ColumnType message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ColumnType.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ColumnType message from the specified reader or buffer.
         * @function decode
         * @memberof compute_sql.ColumnType
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_sql.ColumnType} ColumnType
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ColumnType.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_sql.ColumnType();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.primitiveType = reader.int32();
                        break;
                    }
                case 2: {
                        message.nullable = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ColumnType message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_sql.ColumnType
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_sql.ColumnType} ColumnType
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ColumnType.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ColumnType message.
         * @function verify
         * @memberof compute_sql.ColumnType
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ColumnType.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.primitiveType != null && message.hasOwnProperty("primitiveType"))
                switch (message.primitiveType) {
                default:
                    return "primitiveType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.nullable != null && message.hasOwnProperty("nullable"))
                if (typeof message.nullable !== "boolean")
                    return "nullable: boolean expected";
            return null;
        };

        /**
         * Creates a ColumnType message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_sql.ColumnType
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_sql.ColumnType} ColumnType
         */
        ColumnType.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_sql.ColumnType)
                return object;
            let message = new $root.compute_sql.ColumnType();
            switch (object.primitiveType) {
            default:
                if (typeof object.primitiveType === "number") {
                    message.primitiveType = object.primitiveType;
                    break;
                }
                break;
            case "INT64":
            case 0:
                message.primitiveType = 0;
                break;
            case "STRING":
            case 1:
                message.primitiveType = 1;
                break;
            case "FLOAT64":
            case 2:
                message.primitiveType = 2;
                break;
            }
            if (object.nullable != null)
                message.nullable = Boolean(object.nullable);
            return message;
        };

        /**
         * Creates a plain object from a ColumnType message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_sql.ColumnType
         * @static
         * @param {compute_sql.ColumnType} message ColumnType
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ColumnType.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.primitiveType = options.enums === String ? "INT64" : 0;
                object.nullable = false;
            }
            if (message.primitiveType != null && message.hasOwnProperty("primitiveType"))
                object.primitiveType = options.enums === String ? $root.compute_sql.PrimitiveType[message.primitiveType] === undefined ? message.primitiveType : $root.compute_sql.PrimitiveType[message.primitiveType] : message.primitiveType;
            if (message.nullable != null && message.hasOwnProperty("nullable"))
                object.nullable = message.nullable;
            return object;
        };

        /**
         * Converts this ColumnType to JSON.
         * @function toJSON
         * @memberof compute_sql.ColumnType
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ColumnType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ColumnType
         * @function getTypeUrl
         * @memberof compute_sql.ColumnType
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ColumnType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_sql.ColumnType";
        };

        return ColumnType;
    })();

    /**
     * PrimitiveType enum.
     * @name compute_sql.PrimitiveType
     * @enum {number}
     * @property {number} INT64=0 INT64 value
     * @property {number} STRING=1 STRING value
     * @property {number} FLOAT64=2 FLOAT64 value
     */
    compute_sql.PrimitiveType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "INT64"] = 0;
        values[valuesById[1] = "STRING"] = 1;
        values[valuesById[2] = "FLOAT64"] = 2;
        return values;
    })();

    compute_sql.TableDependencyMapping = (function() {

        /**
         * Properties of a TableDependencyMapping.
         * @memberof compute_sql
         * @interface ITableDependencyMapping
         * @property {string|null} [table] TableDependencyMapping table
         * @property {string|null} [dependency] TableDependencyMapping dependency
         */

        /**
         * Constructs a new TableDependencyMapping.
         * @memberof compute_sql
         * @classdesc Represents a TableDependencyMapping.
         * @implements ITableDependencyMapping
         * @constructor
         * @param {compute_sql.ITableDependencyMapping=} [properties] Properties to set
         */
        function TableDependencyMapping(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TableDependencyMapping table.
         * @member {string} table
         * @memberof compute_sql.TableDependencyMapping
         * @instance
         */
        TableDependencyMapping.prototype.table = "";

        /**
         * TableDependencyMapping dependency.
         * @member {string} dependency
         * @memberof compute_sql.TableDependencyMapping
         * @instance
         */
        TableDependencyMapping.prototype.dependency = "";

        /**
         * Creates a new TableDependencyMapping instance using the specified properties.
         * @function create
         * @memberof compute_sql.TableDependencyMapping
         * @static
         * @param {compute_sql.ITableDependencyMapping=} [properties] Properties to set
         * @returns {compute_sql.TableDependencyMapping} TableDependencyMapping instance
         */
        TableDependencyMapping.create = function create(properties) {
            return new TableDependencyMapping(properties);
        };

        /**
         * Encodes the specified TableDependencyMapping message. Does not implicitly {@link compute_sql.TableDependencyMapping.verify|verify} messages.
         * @function encode
         * @memberof compute_sql.TableDependencyMapping
         * @static
         * @param {compute_sql.ITableDependencyMapping} message TableDependencyMapping message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableDependencyMapping.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.table != null && Object.hasOwnProperty.call(message, "table"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.table);
            if (message.dependency != null && Object.hasOwnProperty.call(message, "dependency"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.dependency);
            return writer;
        };

        /**
         * Encodes the specified TableDependencyMapping message, length delimited. Does not implicitly {@link compute_sql.TableDependencyMapping.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_sql.TableDependencyMapping
         * @static
         * @param {compute_sql.ITableDependencyMapping} message TableDependencyMapping message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableDependencyMapping.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TableDependencyMapping message from the specified reader or buffer.
         * @function decode
         * @memberof compute_sql.TableDependencyMapping
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_sql.TableDependencyMapping} TableDependencyMapping
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableDependencyMapping.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_sql.TableDependencyMapping();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.table = reader.string();
                        break;
                    }
                case 2: {
                        message.dependency = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TableDependencyMapping message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_sql.TableDependencyMapping
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_sql.TableDependencyMapping} TableDependencyMapping
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableDependencyMapping.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TableDependencyMapping message.
         * @function verify
         * @memberof compute_sql.TableDependencyMapping
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TableDependencyMapping.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.table != null && message.hasOwnProperty("table"))
                if (!$util.isString(message.table))
                    return "table: string expected";
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                if (!$util.isString(message.dependency))
                    return "dependency: string expected";
            return null;
        };

        /**
         * Creates a TableDependencyMapping message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_sql.TableDependencyMapping
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_sql.TableDependencyMapping} TableDependencyMapping
         */
        TableDependencyMapping.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_sql.TableDependencyMapping)
                return object;
            let message = new $root.compute_sql.TableDependencyMapping();
            if (object.table != null)
                message.table = String(object.table);
            if (object.dependency != null)
                message.dependency = String(object.dependency);
            return message;
        };

        /**
         * Creates a plain object from a TableDependencyMapping message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_sql.TableDependencyMapping
         * @static
         * @param {compute_sql.TableDependencyMapping} message TableDependencyMapping
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TableDependencyMapping.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.table = "";
                object.dependency = "";
            }
            if (message.table != null && message.hasOwnProperty("table"))
                object.table = message.table;
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                object.dependency = message.dependency;
            return object;
        };

        /**
         * Converts this TableDependencyMapping to JSON.
         * @function toJSON
         * @memberof compute_sql.TableDependencyMapping
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TableDependencyMapping.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TableDependencyMapping
         * @function getTypeUrl
         * @memberof compute_sql.TableDependencyMapping
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TableDependencyMapping.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_sql.TableDependencyMapping";
        };

        return TableDependencyMapping;
    })();

    compute_sql.ComputationConfiguration = (function() {

        /**
         * Properties of a ComputationConfiguration.
         * @memberof compute_sql
         * @interface IComputationConfiguration
         * @property {string|null} [sqlStatement] ComputationConfiguration sqlStatement
         * @property {compute_sql.IPrivacySettings|null} [privacySettings] ComputationConfiguration privacySettings
         * @property {Array.<compute_sql.IConstraint>|null} [constraints] ComputationConfiguration constraints
         * @property {Array.<compute_sql.ITableDependencyMapping>|null} [tableDependencyMappings] ComputationConfiguration tableDependencyMappings
         */

        /**
         * Constructs a new ComputationConfiguration.
         * @memberof compute_sql
         * @classdesc Represents a ComputationConfiguration.
         * @implements IComputationConfiguration
         * @constructor
         * @param {compute_sql.IComputationConfiguration=} [properties] Properties to set
         */
        function ComputationConfiguration(properties) {
            this.constraints = [];
            this.tableDependencyMappings = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ComputationConfiguration sqlStatement.
         * @member {string} sqlStatement
         * @memberof compute_sql.ComputationConfiguration
         * @instance
         */
        ComputationConfiguration.prototype.sqlStatement = "";

        /**
         * ComputationConfiguration privacySettings.
         * @member {compute_sql.IPrivacySettings|null|undefined} privacySettings
         * @memberof compute_sql.ComputationConfiguration
         * @instance
         */
        ComputationConfiguration.prototype.privacySettings = null;

        /**
         * ComputationConfiguration constraints.
         * @member {Array.<compute_sql.IConstraint>} constraints
         * @memberof compute_sql.ComputationConfiguration
         * @instance
         */
        ComputationConfiguration.prototype.constraints = $util.emptyArray;

        /**
         * ComputationConfiguration tableDependencyMappings.
         * @member {Array.<compute_sql.ITableDependencyMapping>} tableDependencyMappings
         * @memberof compute_sql.ComputationConfiguration
         * @instance
         */
        ComputationConfiguration.prototype.tableDependencyMappings = $util.emptyArray;

        /**
         * Creates a new ComputationConfiguration instance using the specified properties.
         * @function create
         * @memberof compute_sql.ComputationConfiguration
         * @static
         * @param {compute_sql.IComputationConfiguration=} [properties] Properties to set
         * @returns {compute_sql.ComputationConfiguration} ComputationConfiguration instance
         */
        ComputationConfiguration.create = function create(properties) {
            return new ComputationConfiguration(properties);
        };

        /**
         * Encodes the specified ComputationConfiguration message. Does not implicitly {@link compute_sql.ComputationConfiguration.verify|verify} messages.
         * @function encode
         * @memberof compute_sql.ComputationConfiguration
         * @static
         * @param {compute_sql.IComputationConfiguration} message ComputationConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComputationConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sqlStatement != null && Object.hasOwnProperty.call(message, "sqlStatement"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sqlStatement);
            if (message.privacySettings != null && Object.hasOwnProperty.call(message, "privacySettings"))
                $root.compute_sql.PrivacySettings.encode(message.privacySettings, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.constraints != null && message.constraints.length)
                for (let i = 0; i < message.constraints.length; ++i)
                    $root.compute_sql.Constraint.encode(message.constraints[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.tableDependencyMappings != null && message.tableDependencyMappings.length)
                for (let i = 0; i < message.tableDependencyMappings.length; ++i)
                    $root.compute_sql.TableDependencyMapping.encode(message.tableDependencyMappings[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ComputationConfiguration message, length delimited. Does not implicitly {@link compute_sql.ComputationConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_sql.ComputationConfiguration
         * @static
         * @param {compute_sql.IComputationConfiguration} message ComputationConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComputationConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ComputationConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof compute_sql.ComputationConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_sql.ComputationConfiguration} ComputationConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComputationConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_sql.ComputationConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sqlStatement = reader.string();
                        break;
                    }
                case 2: {
                        message.privacySettings = $root.compute_sql.PrivacySettings.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        if (!(message.constraints && message.constraints.length))
                            message.constraints = [];
                        message.constraints.push($root.compute_sql.Constraint.decode(reader, reader.uint32()));
                        break;
                    }
                case 4: {
                        if (!(message.tableDependencyMappings && message.tableDependencyMappings.length))
                            message.tableDependencyMappings = [];
                        message.tableDependencyMappings.push($root.compute_sql.TableDependencyMapping.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ComputationConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_sql.ComputationConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_sql.ComputationConfiguration} ComputationConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComputationConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ComputationConfiguration message.
         * @function verify
         * @memberof compute_sql.ComputationConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ComputationConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sqlStatement != null && message.hasOwnProperty("sqlStatement"))
                if (!$util.isString(message.sqlStatement))
                    return "sqlStatement: string expected";
            if (message.privacySettings != null && message.hasOwnProperty("privacySettings")) {
                let error = $root.compute_sql.PrivacySettings.verify(message.privacySettings);
                if (error)
                    return "privacySettings." + error;
            }
            if (message.constraints != null && message.hasOwnProperty("constraints")) {
                if (!Array.isArray(message.constraints))
                    return "constraints: array expected";
                for (let i = 0; i < message.constraints.length; ++i) {
                    let error = $root.compute_sql.Constraint.verify(message.constraints[i]);
                    if (error)
                        return "constraints." + error;
                }
            }
            if (message.tableDependencyMappings != null && message.hasOwnProperty("tableDependencyMappings")) {
                if (!Array.isArray(message.tableDependencyMappings))
                    return "tableDependencyMappings: array expected";
                for (let i = 0; i < message.tableDependencyMappings.length; ++i) {
                    let error = $root.compute_sql.TableDependencyMapping.verify(message.tableDependencyMappings[i]);
                    if (error)
                        return "tableDependencyMappings." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ComputationConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_sql.ComputationConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_sql.ComputationConfiguration} ComputationConfiguration
         */
        ComputationConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_sql.ComputationConfiguration)
                return object;
            let message = new $root.compute_sql.ComputationConfiguration();
            if (object.sqlStatement != null)
                message.sqlStatement = String(object.sqlStatement);
            if (object.privacySettings != null) {
                if (typeof object.privacySettings !== "object")
                    throw TypeError(".compute_sql.ComputationConfiguration.privacySettings: object expected");
                message.privacySettings = $root.compute_sql.PrivacySettings.fromObject(object.privacySettings);
            }
            if (object.constraints) {
                if (!Array.isArray(object.constraints))
                    throw TypeError(".compute_sql.ComputationConfiguration.constraints: array expected");
                message.constraints = [];
                for (let i = 0; i < object.constraints.length; ++i) {
                    if (typeof object.constraints[i] !== "object")
                        throw TypeError(".compute_sql.ComputationConfiguration.constraints: object expected");
                    message.constraints[i] = $root.compute_sql.Constraint.fromObject(object.constraints[i]);
                }
            }
            if (object.tableDependencyMappings) {
                if (!Array.isArray(object.tableDependencyMappings))
                    throw TypeError(".compute_sql.ComputationConfiguration.tableDependencyMappings: array expected");
                message.tableDependencyMappings = [];
                for (let i = 0; i < object.tableDependencyMappings.length; ++i) {
                    if (typeof object.tableDependencyMappings[i] !== "object")
                        throw TypeError(".compute_sql.ComputationConfiguration.tableDependencyMappings: object expected");
                    message.tableDependencyMappings[i] = $root.compute_sql.TableDependencyMapping.fromObject(object.tableDependencyMappings[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ComputationConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_sql.ComputationConfiguration
         * @static
         * @param {compute_sql.ComputationConfiguration} message ComputationConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ComputationConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.constraints = [];
                object.tableDependencyMappings = [];
            }
            if (options.defaults) {
                object.sqlStatement = "";
                object.privacySettings = null;
            }
            if (message.sqlStatement != null && message.hasOwnProperty("sqlStatement"))
                object.sqlStatement = message.sqlStatement;
            if (message.privacySettings != null && message.hasOwnProperty("privacySettings"))
                object.privacySettings = $root.compute_sql.PrivacySettings.toObject(message.privacySettings, options);
            if (message.constraints && message.constraints.length) {
                object.constraints = [];
                for (let j = 0; j < message.constraints.length; ++j)
                    object.constraints[j] = $root.compute_sql.Constraint.toObject(message.constraints[j], options);
            }
            if (message.tableDependencyMappings && message.tableDependencyMappings.length) {
                object.tableDependencyMappings = [];
                for (let j = 0; j < message.tableDependencyMappings.length; ++j)
                    object.tableDependencyMappings[j] = $root.compute_sql.TableDependencyMapping.toObject(message.tableDependencyMappings[j], options);
            }
            return object;
        };

        /**
         * Converts this ComputationConfiguration to JSON.
         * @function toJSON
         * @memberof compute_sql.ComputationConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ComputationConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ComputationConfiguration
         * @function getTypeUrl
         * @memberof compute_sql.ComputationConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ComputationConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_sql.ComputationConfiguration";
        };

        return ComputationConfiguration;
    })();

    compute_sql.PrivacySettings = (function() {

        /**
         * Properties of a PrivacySettings.
         * @memberof compute_sql
         * @interface IPrivacySettings
         * @property {number|Long|null} [minAggregationGroupSize] PrivacySettings minAggregationGroupSize
         */

        /**
         * Constructs a new PrivacySettings.
         * @memberof compute_sql
         * @classdesc Represents a PrivacySettings.
         * @implements IPrivacySettings
         * @constructor
         * @param {compute_sql.IPrivacySettings=} [properties] Properties to set
         */
        function PrivacySettings(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PrivacySettings minAggregationGroupSize.
         * @member {number|Long} minAggregationGroupSize
         * @memberof compute_sql.PrivacySettings
         * @instance
         */
        PrivacySettings.prototype.minAggregationGroupSize = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new PrivacySettings instance using the specified properties.
         * @function create
         * @memberof compute_sql.PrivacySettings
         * @static
         * @param {compute_sql.IPrivacySettings=} [properties] Properties to set
         * @returns {compute_sql.PrivacySettings} PrivacySettings instance
         */
        PrivacySettings.create = function create(properties) {
            return new PrivacySettings(properties);
        };

        /**
         * Encodes the specified PrivacySettings message. Does not implicitly {@link compute_sql.PrivacySettings.verify|verify} messages.
         * @function encode
         * @memberof compute_sql.PrivacySettings
         * @static
         * @param {compute_sql.IPrivacySettings} message PrivacySettings message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PrivacySettings.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.minAggregationGroupSize != null && Object.hasOwnProperty.call(message, "minAggregationGroupSize"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.minAggregationGroupSize);
            return writer;
        };

        /**
         * Encodes the specified PrivacySettings message, length delimited. Does not implicitly {@link compute_sql.PrivacySettings.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_sql.PrivacySettings
         * @static
         * @param {compute_sql.IPrivacySettings} message PrivacySettings message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PrivacySettings.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PrivacySettings message from the specified reader or buffer.
         * @function decode
         * @memberof compute_sql.PrivacySettings
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_sql.PrivacySettings} PrivacySettings
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PrivacySettings.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_sql.PrivacySettings();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.minAggregationGroupSize = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PrivacySettings message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_sql.PrivacySettings
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_sql.PrivacySettings} PrivacySettings
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PrivacySettings.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PrivacySettings message.
         * @function verify
         * @memberof compute_sql.PrivacySettings
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PrivacySettings.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.minAggregationGroupSize != null && message.hasOwnProperty("minAggregationGroupSize"))
                if (!$util.isInteger(message.minAggregationGroupSize) && !(message.minAggregationGroupSize && $util.isInteger(message.minAggregationGroupSize.low) && $util.isInteger(message.minAggregationGroupSize.high)))
                    return "minAggregationGroupSize: integer|Long expected";
            return null;
        };

        /**
         * Creates a PrivacySettings message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_sql.PrivacySettings
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_sql.PrivacySettings} PrivacySettings
         */
        PrivacySettings.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_sql.PrivacySettings)
                return object;
            let message = new $root.compute_sql.PrivacySettings();
            if (object.minAggregationGroupSize != null)
                if ($util.Long)
                    (message.minAggregationGroupSize = $util.Long.fromValue(object.minAggregationGroupSize)).unsigned = false;
                else if (typeof object.minAggregationGroupSize === "string")
                    message.minAggregationGroupSize = parseInt(object.minAggregationGroupSize, 10);
                else if (typeof object.minAggregationGroupSize === "number")
                    message.minAggregationGroupSize = object.minAggregationGroupSize;
                else if (typeof object.minAggregationGroupSize === "object")
                    message.minAggregationGroupSize = new $util.LongBits(object.minAggregationGroupSize.low >>> 0, object.minAggregationGroupSize.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a PrivacySettings message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_sql.PrivacySettings
         * @static
         * @param {compute_sql.PrivacySettings} message PrivacySettings
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PrivacySettings.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.minAggregationGroupSize = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.minAggregationGroupSize = options.longs === String ? "0" : 0;
            if (message.minAggregationGroupSize != null && message.hasOwnProperty("minAggregationGroupSize"))
                if (typeof message.minAggregationGroupSize === "number")
                    object.minAggregationGroupSize = options.longs === String ? String(message.minAggregationGroupSize) : message.minAggregationGroupSize;
                else
                    object.minAggregationGroupSize = options.longs === String ? $util.Long.prototype.toString.call(message.minAggregationGroupSize) : options.longs === Number ? new $util.LongBits(message.minAggregationGroupSize.low >>> 0, message.minAggregationGroupSize.high >>> 0).toNumber() : message.minAggregationGroupSize;
            return object;
        };

        /**
         * Converts this PrivacySettings to JSON.
         * @function toJSON
         * @memberof compute_sql.PrivacySettings
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PrivacySettings.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PrivacySettings
         * @function getTypeUrl
         * @memberof compute_sql.PrivacySettings
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PrivacySettings.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_sql.PrivacySettings";
        };

        return PrivacySettings;
    })();

    compute_sql.Constraint = (function() {

        /**
         * Properties of a Constraint.
         * @memberof compute_sql
         * @interface IConstraint
         * @property {string|null} [description] Constraint description
         */

        /**
         * Constructs a new Constraint.
         * @memberof compute_sql
         * @classdesc Represents a Constraint.
         * @implements IConstraint
         * @constructor
         * @param {compute_sql.IConstraint=} [properties] Properties to set
         */
        function Constraint(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Constraint description.
         * @member {string} description
         * @memberof compute_sql.Constraint
         * @instance
         */
        Constraint.prototype.description = "";

        /**
         * Creates a new Constraint instance using the specified properties.
         * @function create
         * @memberof compute_sql.Constraint
         * @static
         * @param {compute_sql.IConstraint=} [properties] Properties to set
         * @returns {compute_sql.Constraint} Constraint instance
         */
        Constraint.create = function create(properties) {
            return new Constraint(properties);
        };

        /**
         * Encodes the specified Constraint message. Does not implicitly {@link compute_sql.Constraint.verify|verify} messages.
         * @function encode
         * @memberof compute_sql.Constraint
         * @static
         * @param {compute_sql.IConstraint} message Constraint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Constraint.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.description);
            return writer;
        };

        /**
         * Encodes the specified Constraint message, length delimited. Does not implicitly {@link compute_sql.Constraint.verify|verify} messages.
         * @function encodeDelimited
         * @memberof compute_sql.Constraint
         * @static
         * @param {compute_sql.IConstraint} message Constraint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Constraint.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Constraint message from the specified reader or buffer.
         * @function decode
         * @memberof compute_sql.Constraint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {compute_sql.Constraint} Constraint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Constraint.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.compute_sql.Constraint();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.description = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Constraint message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof compute_sql.Constraint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {compute_sql.Constraint} Constraint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Constraint.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Constraint message.
         * @function verify
         * @memberof compute_sql.Constraint
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Constraint.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            return null;
        };

        /**
         * Creates a Constraint message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof compute_sql.Constraint
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {compute_sql.Constraint} Constraint
         */
        Constraint.fromObject = function fromObject(object) {
            if (object instanceof $root.compute_sql.Constraint)
                return object;
            let message = new $root.compute_sql.Constraint();
            if (object.description != null)
                message.description = String(object.description);
            return message;
        };

        /**
         * Creates a plain object from a Constraint message. Also converts values to other types if specified.
         * @function toObject
         * @memberof compute_sql.Constraint
         * @static
         * @param {compute_sql.Constraint} message Constraint
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Constraint.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.description = "";
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            return object;
        };

        /**
         * Converts this Constraint to JSON.
         * @function toJSON
         * @memberof compute_sql.Constraint
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Constraint.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Constraint
         * @function getTypeUrl
         * @memberof compute_sql.Constraint
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Constraint.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/compute_sql.Constraint";
        };

        return Constraint;
    })();

    return compute_sql;
})();

export const data_room = $root.data_room = (() => {

    /**
     * Namespace data_room.
     * @exports data_room
     * @namespace
     */
    const data_room = {};

    data_room.DataRoom = (function() {

        /**
         * Properties of a DataRoom.
         * @memberof data_room
         * @interface IDataRoom
         * @property {string|null} [id] DataRoom id
         * @property {string|null} [name] DataRoom name
         * @property {string|null} [description] DataRoom description
         * @property {data_room.IGovernanceProtocol|null} [governanceProtocol] DataRoom governanceProtocol
         * @property {data_room.IDataRoomConfiguration|null} [initialConfiguration] DataRoom initialConfiguration
         */

        /**
         * Constructs a new DataRoom.
         * @memberof data_room
         * @classdesc Represents a DataRoom.
         * @implements IDataRoom
         * @constructor
         * @param {data_room.IDataRoom=} [properties] Properties to set
         */
        function DataRoom(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DataRoom id.
         * @member {string} id
         * @memberof data_room.DataRoom
         * @instance
         */
        DataRoom.prototype.id = "";

        /**
         * DataRoom name.
         * @member {string} name
         * @memberof data_room.DataRoom
         * @instance
         */
        DataRoom.prototype.name = "";

        /**
         * DataRoom description.
         * @member {string} description
         * @memberof data_room.DataRoom
         * @instance
         */
        DataRoom.prototype.description = "";

        /**
         * DataRoom governanceProtocol.
         * @member {data_room.IGovernanceProtocol|null|undefined} governanceProtocol
         * @memberof data_room.DataRoom
         * @instance
         */
        DataRoom.prototype.governanceProtocol = null;

        /**
         * DataRoom initialConfiguration.
         * @member {data_room.IDataRoomConfiguration|null|undefined} initialConfiguration
         * @memberof data_room.DataRoom
         * @instance
         */
        DataRoom.prototype.initialConfiguration = null;

        /**
         * Creates a new DataRoom instance using the specified properties.
         * @function create
         * @memberof data_room.DataRoom
         * @static
         * @param {data_room.IDataRoom=} [properties] Properties to set
         * @returns {data_room.DataRoom} DataRoom instance
         */
        DataRoom.create = function create(properties) {
            return new DataRoom(properties);
        };

        /**
         * Encodes the specified DataRoom message. Does not implicitly {@link data_room.DataRoom.verify|verify} messages.
         * @function encode
         * @memberof data_room.DataRoom
         * @static
         * @param {data_room.IDataRoom} message DataRoom message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataRoom.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
            if (message.governanceProtocol != null && Object.hasOwnProperty.call(message, "governanceProtocol"))
                $root.data_room.GovernanceProtocol.encode(message.governanceProtocol, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.initialConfiguration != null && Object.hasOwnProperty.call(message, "initialConfiguration"))
                $root.data_room.DataRoomConfiguration.encode(message.initialConfiguration, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DataRoom message, length delimited. Does not implicitly {@link data_room.DataRoom.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.DataRoom
         * @static
         * @param {data_room.IDataRoom} message DataRoom message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataRoom.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DataRoom message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.DataRoom
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.DataRoom} DataRoom
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataRoom.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.DataRoom();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.description = reader.string();
                        break;
                    }
                case 4: {
                        message.governanceProtocol = $root.data_room.GovernanceProtocol.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.initialConfiguration = $root.data_room.DataRoomConfiguration.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DataRoom message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.DataRoom
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.DataRoom} DataRoom
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataRoom.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DataRoom message.
         * @function verify
         * @memberof data_room.DataRoom
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DataRoom.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            if (message.governanceProtocol != null && message.hasOwnProperty("governanceProtocol")) {
                let error = $root.data_room.GovernanceProtocol.verify(message.governanceProtocol);
                if (error)
                    return "governanceProtocol." + error;
            }
            if (message.initialConfiguration != null && message.hasOwnProperty("initialConfiguration")) {
                let error = $root.data_room.DataRoomConfiguration.verify(message.initialConfiguration);
                if (error)
                    return "initialConfiguration." + error;
            }
            return null;
        };

        /**
         * Creates a DataRoom message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.DataRoom
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.DataRoom} DataRoom
         */
        DataRoom.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.DataRoom)
                return object;
            let message = new $root.data_room.DataRoom();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            if (object.description != null)
                message.description = String(object.description);
            if (object.governanceProtocol != null) {
                if (typeof object.governanceProtocol !== "object")
                    throw TypeError(".data_room.DataRoom.governanceProtocol: object expected");
                message.governanceProtocol = $root.data_room.GovernanceProtocol.fromObject(object.governanceProtocol);
            }
            if (object.initialConfiguration != null) {
                if (typeof object.initialConfiguration !== "object")
                    throw TypeError(".data_room.DataRoom.initialConfiguration: object expected");
                message.initialConfiguration = $root.data_room.DataRoomConfiguration.fromObject(object.initialConfiguration);
            }
            return message;
        };

        /**
         * Creates a plain object from a DataRoom message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.DataRoom
         * @static
         * @param {data_room.DataRoom} message DataRoom
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DataRoom.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.name = "";
                object.description = "";
                object.governanceProtocol = null;
                object.initialConfiguration = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.governanceProtocol != null && message.hasOwnProperty("governanceProtocol"))
                object.governanceProtocol = $root.data_room.GovernanceProtocol.toObject(message.governanceProtocol, options);
            if (message.initialConfiguration != null && message.hasOwnProperty("initialConfiguration"))
                object.initialConfiguration = $root.data_room.DataRoomConfiguration.toObject(message.initialConfiguration, options);
            return object;
        };

        /**
         * Converts this DataRoom to JSON.
         * @function toJSON
         * @memberof data_room.DataRoom
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DataRoom.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DataRoom
         * @function getTypeUrl
         * @memberof data_room.DataRoom
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DataRoom.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.DataRoom";
        };

        return DataRoom;
    })();

    data_room.GovernanceProtocol = (function() {

        /**
         * Properties of a GovernanceProtocol.
         * @memberof data_room
         * @interface IGovernanceProtocol
         * @property {data_room.IStaticDataRoomPolicy|null} [staticDataRoomPolicy] GovernanceProtocol staticDataRoomPolicy
         * @property {data_room.IAffectedDataOwnersApprovePolicy|null} [affectedDataOwnersApprovePolicy] GovernanceProtocol affectedDataOwnersApprovePolicy
         */

        /**
         * Constructs a new GovernanceProtocol.
         * @memberof data_room
         * @classdesc Represents a GovernanceProtocol.
         * @implements IGovernanceProtocol
         * @constructor
         * @param {data_room.IGovernanceProtocol=} [properties] Properties to set
         */
        function GovernanceProtocol(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GovernanceProtocol staticDataRoomPolicy.
         * @member {data_room.IStaticDataRoomPolicy|null|undefined} staticDataRoomPolicy
         * @memberof data_room.GovernanceProtocol
         * @instance
         */
        GovernanceProtocol.prototype.staticDataRoomPolicy = null;

        /**
         * GovernanceProtocol affectedDataOwnersApprovePolicy.
         * @member {data_room.IAffectedDataOwnersApprovePolicy|null|undefined} affectedDataOwnersApprovePolicy
         * @memberof data_room.GovernanceProtocol
         * @instance
         */
        GovernanceProtocol.prototype.affectedDataOwnersApprovePolicy = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * GovernanceProtocol policy.
         * @member {"staticDataRoomPolicy"|"affectedDataOwnersApprovePolicy"|undefined} policy
         * @memberof data_room.GovernanceProtocol
         * @instance
         */
        Object.defineProperty(GovernanceProtocol.prototype, "policy", {
            get: $util.oneOfGetter($oneOfFields = ["staticDataRoomPolicy", "affectedDataOwnersApprovePolicy"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GovernanceProtocol instance using the specified properties.
         * @function create
         * @memberof data_room.GovernanceProtocol
         * @static
         * @param {data_room.IGovernanceProtocol=} [properties] Properties to set
         * @returns {data_room.GovernanceProtocol} GovernanceProtocol instance
         */
        GovernanceProtocol.create = function create(properties) {
            return new GovernanceProtocol(properties);
        };

        /**
         * Encodes the specified GovernanceProtocol message. Does not implicitly {@link data_room.GovernanceProtocol.verify|verify} messages.
         * @function encode
         * @memberof data_room.GovernanceProtocol
         * @static
         * @param {data_room.IGovernanceProtocol} message GovernanceProtocol message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GovernanceProtocol.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.staticDataRoomPolicy != null && Object.hasOwnProperty.call(message, "staticDataRoomPolicy"))
                $root.data_room.StaticDataRoomPolicy.encode(message.staticDataRoomPolicy, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.affectedDataOwnersApprovePolicy != null && Object.hasOwnProperty.call(message, "affectedDataOwnersApprovePolicy"))
                $root.data_room.AffectedDataOwnersApprovePolicy.encode(message.affectedDataOwnersApprovePolicy, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GovernanceProtocol message, length delimited. Does not implicitly {@link data_room.GovernanceProtocol.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.GovernanceProtocol
         * @static
         * @param {data_room.IGovernanceProtocol} message GovernanceProtocol message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GovernanceProtocol.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GovernanceProtocol message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.GovernanceProtocol
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.GovernanceProtocol} GovernanceProtocol
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GovernanceProtocol.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.GovernanceProtocol();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.staticDataRoomPolicy = $root.data_room.StaticDataRoomPolicy.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.affectedDataOwnersApprovePolicy = $root.data_room.AffectedDataOwnersApprovePolicy.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GovernanceProtocol message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.GovernanceProtocol
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.GovernanceProtocol} GovernanceProtocol
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GovernanceProtocol.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GovernanceProtocol message.
         * @function verify
         * @memberof data_room.GovernanceProtocol
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GovernanceProtocol.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.staticDataRoomPolicy != null && message.hasOwnProperty("staticDataRoomPolicy")) {
                properties.policy = 1;
                {
                    let error = $root.data_room.StaticDataRoomPolicy.verify(message.staticDataRoomPolicy);
                    if (error)
                        return "staticDataRoomPolicy." + error;
                }
            }
            if (message.affectedDataOwnersApprovePolicy != null && message.hasOwnProperty("affectedDataOwnersApprovePolicy")) {
                if (properties.policy === 1)
                    return "policy: multiple values";
                properties.policy = 1;
                {
                    let error = $root.data_room.AffectedDataOwnersApprovePolicy.verify(message.affectedDataOwnersApprovePolicy);
                    if (error)
                        return "affectedDataOwnersApprovePolicy." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GovernanceProtocol message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.GovernanceProtocol
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.GovernanceProtocol} GovernanceProtocol
         */
        GovernanceProtocol.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.GovernanceProtocol)
                return object;
            let message = new $root.data_room.GovernanceProtocol();
            if (object.staticDataRoomPolicy != null) {
                if (typeof object.staticDataRoomPolicy !== "object")
                    throw TypeError(".data_room.GovernanceProtocol.staticDataRoomPolicy: object expected");
                message.staticDataRoomPolicy = $root.data_room.StaticDataRoomPolicy.fromObject(object.staticDataRoomPolicy);
            }
            if (object.affectedDataOwnersApprovePolicy != null) {
                if (typeof object.affectedDataOwnersApprovePolicy !== "object")
                    throw TypeError(".data_room.GovernanceProtocol.affectedDataOwnersApprovePolicy: object expected");
                message.affectedDataOwnersApprovePolicy = $root.data_room.AffectedDataOwnersApprovePolicy.fromObject(object.affectedDataOwnersApprovePolicy);
            }
            return message;
        };

        /**
         * Creates a plain object from a GovernanceProtocol message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.GovernanceProtocol
         * @static
         * @param {data_room.GovernanceProtocol} message GovernanceProtocol
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GovernanceProtocol.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.staticDataRoomPolicy != null && message.hasOwnProperty("staticDataRoomPolicy")) {
                object.staticDataRoomPolicy = $root.data_room.StaticDataRoomPolicy.toObject(message.staticDataRoomPolicy, options);
                if (options.oneofs)
                    object.policy = "staticDataRoomPolicy";
            }
            if (message.affectedDataOwnersApprovePolicy != null && message.hasOwnProperty("affectedDataOwnersApprovePolicy")) {
                object.affectedDataOwnersApprovePolicy = $root.data_room.AffectedDataOwnersApprovePolicy.toObject(message.affectedDataOwnersApprovePolicy, options);
                if (options.oneofs)
                    object.policy = "affectedDataOwnersApprovePolicy";
            }
            return object;
        };

        /**
         * Converts this GovernanceProtocol to JSON.
         * @function toJSON
         * @memberof data_room.GovernanceProtocol
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GovernanceProtocol.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GovernanceProtocol
         * @function getTypeUrl
         * @memberof data_room.GovernanceProtocol
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GovernanceProtocol.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.GovernanceProtocol";
        };

        return GovernanceProtocol;
    })();

    data_room.StaticDataRoomPolicy = (function() {

        /**
         * Properties of a StaticDataRoomPolicy.
         * @memberof data_room
         * @interface IStaticDataRoomPolicy
         */

        /**
         * Constructs a new StaticDataRoomPolicy.
         * @memberof data_room
         * @classdesc Represents a StaticDataRoomPolicy.
         * @implements IStaticDataRoomPolicy
         * @constructor
         * @param {data_room.IStaticDataRoomPolicy=} [properties] Properties to set
         */
        function StaticDataRoomPolicy(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new StaticDataRoomPolicy instance using the specified properties.
         * @function create
         * @memberof data_room.StaticDataRoomPolicy
         * @static
         * @param {data_room.IStaticDataRoomPolicy=} [properties] Properties to set
         * @returns {data_room.StaticDataRoomPolicy} StaticDataRoomPolicy instance
         */
        StaticDataRoomPolicy.create = function create(properties) {
            return new StaticDataRoomPolicy(properties);
        };

        /**
         * Encodes the specified StaticDataRoomPolicy message. Does not implicitly {@link data_room.StaticDataRoomPolicy.verify|verify} messages.
         * @function encode
         * @memberof data_room.StaticDataRoomPolicy
         * @static
         * @param {data_room.IStaticDataRoomPolicy} message StaticDataRoomPolicy message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StaticDataRoomPolicy.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified StaticDataRoomPolicy message, length delimited. Does not implicitly {@link data_room.StaticDataRoomPolicy.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.StaticDataRoomPolicy
         * @static
         * @param {data_room.IStaticDataRoomPolicy} message StaticDataRoomPolicy message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StaticDataRoomPolicy.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StaticDataRoomPolicy message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.StaticDataRoomPolicy
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.StaticDataRoomPolicy} StaticDataRoomPolicy
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StaticDataRoomPolicy.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.StaticDataRoomPolicy();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StaticDataRoomPolicy message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.StaticDataRoomPolicy
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.StaticDataRoomPolicy} StaticDataRoomPolicy
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StaticDataRoomPolicy.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StaticDataRoomPolicy message.
         * @function verify
         * @memberof data_room.StaticDataRoomPolicy
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StaticDataRoomPolicy.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a StaticDataRoomPolicy message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.StaticDataRoomPolicy
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.StaticDataRoomPolicy} StaticDataRoomPolicy
         */
        StaticDataRoomPolicy.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.StaticDataRoomPolicy)
                return object;
            return new $root.data_room.StaticDataRoomPolicy();
        };

        /**
         * Creates a plain object from a StaticDataRoomPolicy message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.StaticDataRoomPolicy
         * @static
         * @param {data_room.StaticDataRoomPolicy} message StaticDataRoomPolicy
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StaticDataRoomPolicy.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this StaticDataRoomPolicy to JSON.
         * @function toJSON
         * @memberof data_room.StaticDataRoomPolicy
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StaticDataRoomPolicy.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StaticDataRoomPolicy
         * @function getTypeUrl
         * @memberof data_room.StaticDataRoomPolicy
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StaticDataRoomPolicy.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.StaticDataRoomPolicy";
        };

        return StaticDataRoomPolicy;
    })();

    data_room.AffectedDataOwnersApprovePolicy = (function() {

        /**
         * Properties of an AffectedDataOwnersApprovePolicy.
         * @memberof data_room
         * @interface IAffectedDataOwnersApprovePolicy
         */

        /**
         * Constructs a new AffectedDataOwnersApprovePolicy.
         * @memberof data_room
         * @classdesc Represents an AffectedDataOwnersApprovePolicy.
         * @implements IAffectedDataOwnersApprovePolicy
         * @constructor
         * @param {data_room.IAffectedDataOwnersApprovePolicy=} [properties] Properties to set
         */
        function AffectedDataOwnersApprovePolicy(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new AffectedDataOwnersApprovePolicy instance using the specified properties.
         * @function create
         * @memberof data_room.AffectedDataOwnersApprovePolicy
         * @static
         * @param {data_room.IAffectedDataOwnersApprovePolicy=} [properties] Properties to set
         * @returns {data_room.AffectedDataOwnersApprovePolicy} AffectedDataOwnersApprovePolicy instance
         */
        AffectedDataOwnersApprovePolicy.create = function create(properties) {
            return new AffectedDataOwnersApprovePolicy(properties);
        };

        /**
         * Encodes the specified AffectedDataOwnersApprovePolicy message. Does not implicitly {@link data_room.AffectedDataOwnersApprovePolicy.verify|verify} messages.
         * @function encode
         * @memberof data_room.AffectedDataOwnersApprovePolicy
         * @static
         * @param {data_room.IAffectedDataOwnersApprovePolicy} message AffectedDataOwnersApprovePolicy message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AffectedDataOwnersApprovePolicy.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified AffectedDataOwnersApprovePolicy message, length delimited. Does not implicitly {@link data_room.AffectedDataOwnersApprovePolicy.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.AffectedDataOwnersApprovePolicy
         * @static
         * @param {data_room.IAffectedDataOwnersApprovePolicy} message AffectedDataOwnersApprovePolicy message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AffectedDataOwnersApprovePolicy.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AffectedDataOwnersApprovePolicy message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.AffectedDataOwnersApprovePolicy
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.AffectedDataOwnersApprovePolicy} AffectedDataOwnersApprovePolicy
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AffectedDataOwnersApprovePolicy.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.AffectedDataOwnersApprovePolicy();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AffectedDataOwnersApprovePolicy message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.AffectedDataOwnersApprovePolicy
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.AffectedDataOwnersApprovePolicy} AffectedDataOwnersApprovePolicy
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AffectedDataOwnersApprovePolicy.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AffectedDataOwnersApprovePolicy message.
         * @function verify
         * @memberof data_room.AffectedDataOwnersApprovePolicy
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AffectedDataOwnersApprovePolicy.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an AffectedDataOwnersApprovePolicy message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.AffectedDataOwnersApprovePolicy
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.AffectedDataOwnersApprovePolicy} AffectedDataOwnersApprovePolicy
         */
        AffectedDataOwnersApprovePolicy.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.AffectedDataOwnersApprovePolicy)
                return object;
            return new $root.data_room.AffectedDataOwnersApprovePolicy();
        };

        /**
         * Creates a plain object from an AffectedDataOwnersApprovePolicy message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.AffectedDataOwnersApprovePolicy
         * @static
         * @param {data_room.AffectedDataOwnersApprovePolicy} message AffectedDataOwnersApprovePolicy
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AffectedDataOwnersApprovePolicy.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this AffectedDataOwnersApprovePolicy to JSON.
         * @function toJSON
         * @memberof data_room.AffectedDataOwnersApprovePolicy
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AffectedDataOwnersApprovePolicy.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AffectedDataOwnersApprovePolicy
         * @function getTypeUrl
         * @memberof data_room.AffectedDataOwnersApprovePolicy
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AffectedDataOwnersApprovePolicy.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.AffectedDataOwnersApprovePolicy";
        };

        return AffectedDataOwnersApprovePolicy;
    })();

    data_room.DataRoomConfiguration = (function() {

        /**
         * Properties of a DataRoomConfiguration.
         * @memberof data_room
         * @interface IDataRoomConfiguration
         * @property {Array.<data_room.IConfigurationElement>|null} [elements] DataRoomConfiguration elements
         */

        /**
         * Constructs a new DataRoomConfiguration.
         * @memberof data_room
         * @classdesc Represents a DataRoomConfiguration.
         * @implements IDataRoomConfiguration
         * @constructor
         * @param {data_room.IDataRoomConfiguration=} [properties] Properties to set
         */
        function DataRoomConfiguration(properties) {
            this.elements = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DataRoomConfiguration elements.
         * @member {Array.<data_room.IConfigurationElement>} elements
         * @memberof data_room.DataRoomConfiguration
         * @instance
         */
        DataRoomConfiguration.prototype.elements = $util.emptyArray;

        /**
         * Creates a new DataRoomConfiguration instance using the specified properties.
         * @function create
         * @memberof data_room.DataRoomConfiguration
         * @static
         * @param {data_room.IDataRoomConfiguration=} [properties] Properties to set
         * @returns {data_room.DataRoomConfiguration} DataRoomConfiguration instance
         */
        DataRoomConfiguration.create = function create(properties) {
            return new DataRoomConfiguration(properties);
        };

        /**
         * Encodes the specified DataRoomConfiguration message. Does not implicitly {@link data_room.DataRoomConfiguration.verify|verify} messages.
         * @function encode
         * @memberof data_room.DataRoomConfiguration
         * @static
         * @param {data_room.IDataRoomConfiguration} message DataRoomConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataRoomConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.elements != null && message.elements.length)
                for (let i = 0; i < message.elements.length; ++i)
                    $root.data_room.ConfigurationElement.encode(message.elements[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DataRoomConfiguration message, length delimited. Does not implicitly {@link data_room.DataRoomConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.DataRoomConfiguration
         * @static
         * @param {data_room.IDataRoomConfiguration} message DataRoomConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataRoomConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DataRoomConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.DataRoomConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.DataRoomConfiguration} DataRoomConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataRoomConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.DataRoomConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.elements && message.elements.length))
                            message.elements = [];
                        message.elements.push($root.data_room.ConfigurationElement.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DataRoomConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.DataRoomConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.DataRoomConfiguration} DataRoomConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataRoomConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DataRoomConfiguration message.
         * @function verify
         * @memberof data_room.DataRoomConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DataRoomConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.elements != null && message.hasOwnProperty("elements")) {
                if (!Array.isArray(message.elements))
                    return "elements: array expected";
                for (let i = 0; i < message.elements.length; ++i) {
                    let error = $root.data_room.ConfigurationElement.verify(message.elements[i]);
                    if (error)
                        return "elements." + error;
                }
            }
            return null;
        };

        /**
         * Creates a DataRoomConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.DataRoomConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.DataRoomConfiguration} DataRoomConfiguration
         */
        DataRoomConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.DataRoomConfiguration)
                return object;
            let message = new $root.data_room.DataRoomConfiguration();
            if (object.elements) {
                if (!Array.isArray(object.elements))
                    throw TypeError(".data_room.DataRoomConfiguration.elements: array expected");
                message.elements = [];
                for (let i = 0; i < object.elements.length; ++i) {
                    if (typeof object.elements[i] !== "object")
                        throw TypeError(".data_room.DataRoomConfiguration.elements: object expected");
                    message.elements[i] = $root.data_room.ConfigurationElement.fromObject(object.elements[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a DataRoomConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.DataRoomConfiguration
         * @static
         * @param {data_room.DataRoomConfiguration} message DataRoomConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DataRoomConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.elements = [];
            if (message.elements && message.elements.length) {
                object.elements = [];
                for (let j = 0; j < message.elements.length; ++j)
                    object.elements[j] = $root.data_room.ConfigurationElement.toObject(message.elements[j], options);
            }
            return object;
        };

        /**
         * Converts this DataRoomConfiguration to JSON.
         * @function toJSON
         * @memberof data_room.DataRoomConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DataRoomConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DataRoomConfiguration
         * @function getTypeUrl
         * @memberof data_room.DataRoomConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DataRoomConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.DataRoomConfiguration";
        };

        return DataRoomConfiguration;
    })();

    data_room.ConfigurationElement = (function() {

        /**
         * Properties of a ConfigurationElement.
         * @memberof data_room
         * @interface IConfigurationElement
         * @property {string|null} [id] ConfigurationElement id
         * @property {data_room.IComputeNode|null} [computeNode] ConfigurationElement computeNode
         * @property {attestation.IAttestationSpecification|null} [attestationSpecification] ConfigurationElement attestationSpecification
         * @property {data_room.IUserPermission|null} [userPermission] ConfigurationElement userPermission
         * @property {data_room.IAuthenticationMethod|null} [authenticationMethod] ConfigurationElement authenticationMethod
         */

        /**
         * Constructs a new ConfigurationElement.
         * @memberof data_room
         * @classdesc Represents a ConfigurationElement.
         * @implements IConfigurationElement
         * @constructor
         * @param {data_room.IConfigurationElement=} [properties] Properties to set
         */
        function ConfigurationElement(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConfigurationElement id.
         * @member {string} id
         * @memberof data_room.ConfigurationElement
         * @instance
         */
        ConfigurationElement.prototype.id = "";

        /**
         * ConfigurationElement computeNode.
         * @member {data_room.IComputeNode|null|undefined} computeNode
         * @memberof data_room.ConfigurationElement
         * @instance
         */
        ConfigurationElement.prototype.computeNode = null;

        /**
         * ConfigurationElement attestationSpecification.
         * @member {attestation.IAttestationSpecification|null|undefined} attestationSpecification
         * @memberof data_room.ConfigurationElement
         * @instance
         */
        ConfigurationElement.prototype.attestationSpecification = null;

        /**
         * ConfigurationElement userPermission.
         * @member {data_room.IUserPermission|null|undefined} userPermission
         * @memberof data_room.ConfigurationElement
         * @instance
         */
        ConfigurationElement.prototype.userPermission = null;

        /**
         * ConfigurationElement authenticationMethod.
         * @member {data_room.IAuthenticationMethod|null|undefined} authenticationMethod
         * @memberof data_room.ConfigurationElement
         * @instance
         */
        ConfigurationElement.prototype.authenticationMethod = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ConfigurationElement element.
         * @member {"computeNode"|"attestationSpecification"|"userPermission"|"authenticationMethod"|undefined} element
         * @memberof data_room.ConfigurationElement
         * @instance
         */
        Object.defineProperty(ConfigurationElement.prototype, "element", {
            get: $util.oneOfGetter($oneOfFields = ["computeNode", "attestationSpecification", "userPermission", "authenticationMethod"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ConfigurationElement instance using the specified properties.
         * @function create
         * @memberof data_room.ConfigurationElement
         * @static
         * @param {data_room.IConfigurationElement=} [properties] Properties to set
         * @returns {data_room.ConfigurationElement} ConfigurationElement instance
         */
        ConfigurationElement.create = function create(properties) {
            return new ConfigurationElement(properties);
        };

        /**
         * Encodes the specified ConfigurationElement message. Does not implicitly {@link data_room.ConfigurationElement.verify|verify} messages.
         * @function encode
         * @memberof data_room.ConfigurationElement
         * @static
         * @param {data_room.IConfigurationElement} message ConfigurationElement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfigurationElement.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.computeNode != null && Object.hasOwnProperty.call(message, "computeNode"))
                $root.data_room.ComputeNode.encode(message.computeNode, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.attestationSpecification != null && Object.hasOwnProperty.call(message, "attestationSpecification"))
                $root.attestation.AttestationSpecification.encode(message.attestationSpecification, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.userPermission != null && Object.hasOwnProperty.call(message, "userPermission"))
                $root.data_room.UserPermission.encode(message.userPermission, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.authenticationMethod != null && Object.hasOwnProperty.call(message, "authenticationMethod"))
                $root.data_room.AuthenticationMethod.encode(message.authenticationMethod, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ConfigurationElement message, length delimited. Does not implicitly {@link data_room.ConfigurationElement.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.ConfigurationElement
         * @static
         * @param {data_room.IConfigurationElement} message ConfigurationElement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfigurationElement.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConfigurationElement message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.ConfigurationElement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.ConfigurationElement} ConfigurationElement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfigurationElement.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.ConfigurationElement();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.computeNode = $root.data_room.ComputeNode.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.attestationSpecification = $root.attestation.AttestationSpecification.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.userPermission = $root.data_room.UserPermission.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.authenticationMethod = $root.data_room.AuthenticationMethod.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConfigurationElement message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.ConfigurationElement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.ConfigurationElement} ConfigurationElement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfigurationElement.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConfigurationElement message.
         * @function verify
         * @memberof data_room.ConfigurationElement
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConfigurationElement.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.computeNode != null && message.hasOwnProperty("computeNode")) {
                properties.element = 1;
                {
                    let error = $root.data_room.ComputeNode.verify(message.computeNode);
                    if (error)
                        return "computeNode." + error;
                }
            }
            if (message.attestationSpecification != null && message.hasOwnProperty("attestationSpecification")) {
                if (properties.element === 1)
                    return "element: multiple values";
                properties.element = 1;
                {
                    let error = $root.attestation.AttestationSpecification.verify(message.attestationSpecification);
                    if (error)
                        return "attestationSpecification." + error;
                }
            }
            if (message.userPermission != null && message.hasOwnProperty("userPermission")) {
                if (properties.element === 1)
                    return "element: multiple values";
                properties.element = 1;
                {
                    let error = $root.data_room.UserPermission.verify(message.userPermission);
                    if (error)
                        return "userPermission." + error;
                }
            }
            if (message.authenticationMethod != null && message.hasOwnProperty("authenticationMethod")) {
                if (properties.element === 1)
                    return "element: multiple values";
                properties.element = 1;
                {
                    let error = $root.data_room.AuthenticationMethod.verify(message.authenticationMethod);
                    if (error)
                        return "authenticationMethod." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ConfigurationElement message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.ConfigurationElement
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.ConfigurationElement} ConfigurationElement
         */
        ConfigurationElement.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.ConfigurationElement)
                return object;
            let message = new $root.data_room.ConfigurationElement();
            if (object.id != null)
                message.id = String(object.id);
            if (object.computeNode != null) {
                if (typeof object.computeNode !== "object")
                    throw TypeError(".data_room.ConfigurationElement.computeNode: object expected");
                message.computeNode = $root.data_room.ComputeNode.fromObject(object.computeNode);
            }
            if (object.attestationSpecification != null) {
                if (typeof object.attestationSpecification !== "object")
                    throw TypeError(".data_room.ConfigurationElement.attestationSpecification: object expected");
                message.attestationSpecification = $root.attestation.AttestationSpecification.fromObject(object.attestationSpecification);
            }
            if (object.userPermission != null) {
                if (typeof object.userPermission !== "object")
                    throw TypeError(".data_room.ConfigurationElement.userPermission: object expected");
                message.userPermission = $root.data_room.UserPermission.fromObject(object.userPermission);
            }
            if (object.authenticationMethod != null) {
                if (typeof object.authenticationMethod !== "object")
                    throw TypeError(".data_room.ConfigurationElement.authenticationMethod: object expected");
                message.authenticationMethod = $root.data_room.AuthenticationMethod.fromObject(object.authenticationMethod);
            }
            return message;
        };

        /**
         * Creates a plain object from a ConfigurationElement message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.ConfigurationElement
         * @static
         * @param {data_room.ConfigurationElement} message ConfigurationElement
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConfigurationElement.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.computeNode != null && message.hasOwnProperty("computeNode")) {
                object.computeNode = $root.data_room.ComputeNode.toObject(message.computeNode, options);
                if (options.oneofs)
                    object.element = "computeNode";
            }
            if (message.attestationSpecification != null && message.hasOwnProperty("attestationSpecification")) {
                object.attestationSpecification = $root.attestation.AttestationSpecification.toObject(message.attestationSpecification, options);
                if (options.oneofs)
                    object.element = "attestationSpecification";
            }
            if (message.userPermission != null && message.hasOwnProperty("userPermission")) {
                object.userPermission = $root.data_room.UserPermission.toObject(message.userPermission, options);
                if (options.oneofs)
                    object.element = "userPermission";
            }
            if (message.authenticationMethod != null && message.hasOwnProperty("authenticationMethod")) {
                object.authenticationMethod = $root.data_room.AuthenticationMethod.toObject(message.authenticationMethod, options);
                if (options.oneofs)
                    object.element = "authenticationMethod";
            }
            return object;
        };

        /**
         * Converts this ConfigurationElement to JSON.
         * @function toJSON
         * @memberof data_room.ConfigurationElement
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConfigurationElement.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ConfigurationElement
         * @function getTypeUrl
         * @memberof data_room.ConfigurationElement
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConfigurationElement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.ConfigurationElement";
        };

        return ConfigurationElement;
    })();

    data_room.ConfigurationModification = (function() {

        /**
         * Properties of a ConfigurationModification.
         * @memberof data_room
         * @interface IConfigurationModification
         * @property {data_room.IAddModification|null} [add] ConfigurationModification add
         * @property {data_room.IChangeModification|null} [change] ConfigurationModification change
         * @property {data_room.IDeleteModification|null} ["delete"] ConfigurationModification delete
         */

        /**
         * Constructs a new ConfigurationModification.
         * @memberof data_room
         * @classdesc Represents a ConfigurationModification.
         * @implements IConfigurationModification
         * @constructor
         * @param {data_room.IConfigurationModification=} [properties] Properties to set
         */
        function ConfigurationModification(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConfigurationModification add.
         * @member {data_room.IAddModification|null|undefined} add
         * @memberof data_room.ConfigurationModification
         * @instance
         */
        ConfigurationModification.prototype.add = null;

        /**
         * ConfigurationModification change.
         * @member {data_room.IChangeModification|null|undefined} change
         * @memberof data_room.ConfigurationModification
         * @instance
         */
        ConfigurationModification.prototype.change = null;

        /**
         * ConfigurationModification delete.
         * @member {data_room.IDeleteModification|null|undefined} delete
         * @memberof data_room.ConfigurationModification
         * @instance
         */
        ConfigurationModification.prototype["delete"] = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ConfigurationModification modification.
         * @member {"add"|"change"|"delete"|undefined} modification
         * @memberof data_room.ConfigurationModification
         * @instance
         */
        Object.defineProperty(ConfigurationModification.prototype, "modification", {
            get: $util.oneOfGetter($oneOfFields = ["add", "change", "delete"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ConfigurationModification instance using the specified properties.
         * @function create
         * @memberof data_room.ConfigurationModification
         * @static
         * @param {data_room.IConfigurationModification=} [properties] Properties to set
         * @returns {data_room.ConfigurationModification} ConfigurationModification instance
         */
        ConfigurationModification.create = function create(properties) {
            return new ConfigurationModification(properties);
        };

        /**
         * Encodes the specified ConfigurationModification message. Does not implicitly {@link data_room.ConfigurationModification.verify|verify} messages.
         * @function encode
         * @memberof data_room.ConfigurationModification
         * @static
         * @param {data_room.IConfigurationModification} message ConfigurationModification message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfigurationModification.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.add != null && Object.hasOwnProperty.call(message, "add"))
                $root.data_room.AddModification.encode(message.add, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.change != null && Object.hasOwnProperty.call(message, "change"))
                $root.data_room.ChangeModification.encode(message.change, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message["delete"] != null && Object.hasOwnProperty.call(message, "delete"))
                $root.data_room.DeleteModification.encode(message["delete"], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ConfigurationModification message, length delimited. Does not implicitly {@link data_room.ConfigurationModification.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.ConfigurationModification
         * @static
         * @param {data_room.IConfigurationModification} message ConfigurationModification message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfigurationModification.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConfigurationModification message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.ConfigurationModification
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.ConfigurationModification} ConfigurationModification
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfigurationModification.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.ConfigurationModification();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.add = $root.data_room.AddModification.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.change = $root.data_room.ChangeModification.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message["delete"] = $root.data_room.DeleteModification.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConfigurationModification message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.ConfigurationModification
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.ConfigurationModification} ConfigurationModification
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfigurationModification.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConfigurationModification message.
         * @function verify
         * @memberof data_room.ConfigurationModification
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConfigurationModification.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.add != null && message.hasOwnProperty("add")) {
                properties.modification = 1;
                {
                    let error = $root.data_room.AddModification.verify(message.add);
                    if (error)
                        return "add." + error;
                }
            }
            if (message.change != null && message.hasOwnProperty("change")) {
                if (properties.modification === 1)
                    return "modification: multiple values";
                properties.modification = 1;
                {
                    let error = $root.data_room.ChangeModification.verify(message.change);
                    if (error)
                        return "change." + error;
                }
            }
            if (message["delete"] != null && message.hasOwnProperty("delete")) {
                if (properties.modification === 1)
                    return "modification: multiple values";
                properties.modification = 1;
                {
                    let error = $root.data_room.DeleteModification.verify(message["delete"]);
                    if (error)
                        return "delete." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ConfigurationModification message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.ConfigurationModification
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.ConfigurationModification} ConfigurationModification
         */
        ConfigurationModification.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.ConfigurationModification)
                return object;
            let message = new $root.data_room.ConfigurationModification();
            if (object.add != null) {
                if (typeof object.add !== "object")
                    throw TypeError(".data_room.ConfigurationModification.add: object expected");
                message.add = $root.data_room.AddModification.fromObject(object.add);
            }
            if (object.change != null) {
                if (typeof object.change !== "object")
                    throw TypeError(".data_room.ConfigurationModification.change: object expected");
                message.change = $root.data_room.ChangeModification.fromObject(object.change);
            }
            if (object["delete"] != null) {
                if (typeof object["delete"] !== "object")
                    throw TypeError(".data_room.ConfigurationModification.delete: object expected");
                message["delete"] = $root.data_room.DeleteModification.fromObject(object["delete"]);
            }
            return message;
        };

        /**
         * Creates a plain object from a ConfigurationModification message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.ConfigurationModification
         * @static
         * @param {data_room.ConfigurationModification} message ConfigurationModification
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConfigurationModification.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.add != null && message.hasOwnProperty("add")) {
                object.add = $root.data_room.AddModification.toObject(message.add, options);
                if (options.oneofs)
                    object.modification = "add";
            }
            if (message.change != null && message.hasOwnProperty("change")) {
                object.change = $root.data_room.ChangeModification.toObject(message.change, options);
                if (options.oneofs)
                    object.modification = "change";
            }
            if (message["delete"] != null && message.hasOwnProperty("delete")) {
                object["delete"] = $root.data_room.DeleteModification.toObject(message["delete"], options);
                if (options.oneofs)
                    object.modification = "delete";
            }
            return object;
        };

        /**
         * Converts this ConfigurationModification to JSON.
         * @function toJSON
         * @memberof data_room.ConfigurationModification
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConfigurationModification.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ConfigurationModification
         * @function getTypeUrl
         * @memberof data_room.ConfigurationModification
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConfigurationModification.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.ConfigurationModification";
        };

        return ConfigurationModification;
    })();

    data_room.AddModification = (function() {

        /**
         * Properties of an AddModification.
         * @memberof data_room
         * @interface IAddModification
         * @property {data_room.IConfigurationElement|null} [element] AddModification element
         */

        /**
         * Constructs a new AddModification.
         * @memberof data_room
         * @classdesc Represents an AddModification.
         * @implements IAddModification
         * @constructor
         * @param {data_room.IAddModification=} [properties] Properties to set
         */
        function AddModification(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddModification element.
         * @member {data_room.IConfigurationElement|null|undefined} element
         * @memberof data_room.AddModification
         * @instance
         */
        AddModification.prototype.element = null;

        /**
         * Creates a new AddModification instance using the specified properties.
         * @function create
         * @memberof data_room.AddModification
         * @static
         * @param {data_room.IAddModification=} [properties] Properties to set
         * @returns {data_room.AddModification} AddModification instance
         */
        AddModification.create = function create(properties) {
            return new AddModification(properties);
        };

        /**
         * Encodes the specified AddModification message. Does not implicitly {@link data_room.AddModification.verify|verify} messages.
         * @function encode
         * @memberof data_room.AddModification
         * @static
         * @param {data_room.IAddModification} message AddModification message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddModification.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.element != null && Object.hasOwnProperty.call(message, "element"))
                $root.data_room.ConfigurationElement.encode(message.element, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AddModification message, length delimited. Does not implicitly {@link data_room.AddModification.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.AddModification
         * @static
         * @param {data_room.IAddModification} message AddModification message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddModification.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddModification message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.AddModification
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.AddModification} AddModification
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddModification.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.AddModification();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.element = $root.data_room.ConfigurationElement.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddModification message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.AddModification
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.AddModification} AddModification
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddModification.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddModification message.
         * @function verify
         * @memberof data_room.AddModification
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddModification.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.element != null && message.hasOwnProperty("element")) {
                let error = $root.data_room.ConfigurationElement.verify(message.element);
                if (error)
                    return "element." + error;
            }
            return null;
        };

        /**
         * Creates an AddModification message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.AddModification
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.AddModification} AddModification
         */
        AddModification.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.AddModification)
                return object;
            let message = new $root.data_room.AddModification();
            if (object.element != null) {
                if (typeof object.element !== "object")
                    throw TypeError(".data_room.AddModification.element: object expected");
                message.element = $root.data_room.ConfigurationElement.fromObject(object.element);
            }
            return message;
        };

        /**
         * Creates a plain object from an AddModification message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.AddModification
         * @static
         * @param {data_room.AddModification} message AddModification
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddModification.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.element = null;
            if (message.element != null && message.hasOwnProperty("element"))
                object.element = $root.data_room.ConfigurationElement.toObject(message.element, options);
            return object;
        };

        /**
         * Converts this AddModification to JSON.
         * @function toJSON
         * @memberof data_room.AddModification
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddModification.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AddModification
         * @function getTypeUrl
         * @memberof data_room.AddModification
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AddModification.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.AddModification";
        };

        return AddModification;
    })();

    data_room.ChangeModification = (function() {

        /**
         * Properties of a ChangeModification.
         * @memberof data_room
         * @interface IChangeModification
         * @property {data_room.IConfigurationElement|null} [element] ChangeModification element
         */

        /**
         * Constructs a new ChangeModification.
         * @memberof data_room
         * @classdesc Represents a ChangeModification.
         * @implements IChangeModification
         * @constructor
         * @param {data_room.IChangeModification=} [properties] Properties to set
         */
        function ChangeModification(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChangeModification element.
         * @member {data_room.IConfigurationElement|null|undefined} element
         * @memberof data_room.ChangeModification
         * @instance
         */
        ChangeModification.prototype.element = null;

        /**
         * Creates a new ChangeModification instance using the specified properties.
         * @function create
         * @memberof data_room.ChangeModification
         * @static
         * @param {data_room.IChangeModification=} [properties] Properties to set
         * @returns {data_room.ChangeModification} ChangeModification instance
         */
        ChangeModification.create = function create(properties) {
            return new ChangeModification(properties);
        };

        /**
         * Encodes the specified ChangeModification message. Does not implicitly {@link data_room.ChangeModification.verify|verify} messages.
         * @function encode
         * @memberof data_room.ChangeModification
         * @static
         * @param {data_room.IChangeModification} message ChangeModification message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangeModification.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.element != null && Object.hasOwnProperty.call(message, "element"))
                $root.data_room.ConfigurationElement.encode(message.element, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ChangeModification message, length delimited. Does not implicitly {@link data_room.ChangeModification.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.ChangeModification
         * @static
         * @param {data_room.IChangeModification} message ChangeModification message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangeModification.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChangeModification message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.ChangeModification
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.ChangeModification} ChangeModification
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangeModification.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.ChangeModification();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.element = $root.data_room.ConfigurationElement.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChangeModification message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.ChangeModification
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.ChangeModification} ChangeModification
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangeModification.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChangeModification message.
         * @function verify
         * @memberof data_room.ChangeModification
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChangeModification.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.element != null && message.hasOwnProperty("element")) {
                let error = $root.data_room.ConfigurationElement.verify(message.element);
                if (error)
                    return "element." + error;
            }
            return null;
        };

        /**
         * Creates a ChangeModification message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.ChangeModification
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.ChangeModification} ChangeModification
         */
        ChangeModification.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.ChangeModification)
                return object;
            let message = new $root.data_room.ChangeModification();
            if (object.element != null) {
                if (typeof object.element !== "object")
                    throw TypeError(".data_room.ChangeModification.element: object expected");
                message.element = $root.data_room.ConfigurationElement.fromObject(object.element);
            }
            return message;
        };

        /**
         * Creates a plain object from a ChangeModification message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.ChangeModification
         * @static
         * @param {data_room.ChangeModification} message ChangeModification
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChangeModification.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.element = null;
            if (message.element != null && message.hasOwnProperty("element"))
                object.element = $root.data_room.ConfigurationElement.toObject(message.element, options);
            return object;
        };

        /**
         * Converts this ChangeModification to JSON.
         * @function toJSON
         * @memberof data_room.ChangeModification
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChangeModification.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChangeModification
         * @function getTypeUrl
         * @memberof data_room.ChangeModification
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChangeModification.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.ChangeModification";
        };

        return ChangeModification;
    })();

    data_room.DeleteModification = (function() {

        /**
         * Properties of a DeleteModification.
         * @memberof data_room
         * @interface IDeleteModification
         * @property {string|null} [id] DeleteModification id
         */

        /**
         * Constructs a new DeleteModification.
         * @memberof data_room
         * @classdesc Represents a DeleteModification.
         * @implements IDeleteModification
         * @constructor
         * @param {data_room.IDeleteModification=} [properties] Properties to set
         */
        function DeleteModification(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteModification id.
         * @member {string} id
         * @memberof data_room.DeleteModification
         * @instance
         */
        DeleteModification.prototype.id = "";

        /**
         * Creates a new DeleteModification instance using the specified properties.
         * @function create
         * @memberof data_room.DeleteModification
         * @static
         * @param {data_room.IDeleteModification=} [properties] Properties to set
         * @returns {data_room.DeleteModification} DeleteModification instance
         */
        DeleteModification.create = function create(properties) {
            return new DeleteModification(properties);
        };

        /**
         * Encodes the specified DeleteModification message. Does not implicitly {@link data_room.DeleteModification.verify|verify} messages.
         * @function encode
         * @memberof data_room.DeleteModification
         * @static
         * @param {data_room.IDeleteModification} message DeleteModification message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteModification.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified DeleteModification message, length delimited. Does not implicitly {@link data_room.DeleteModification.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.DeleteModification
         * @static
         * @param {data_room.IDeleteModification} message DeleteModification message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteModification.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteModification message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.DeleteModification
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.DeleteModification} DeleteModification
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteModification.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.DeleteModification();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteModification message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.DeleteModification
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.DeleteModification} DeleteModification
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteModification.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteModification message.
         * @function verify
         * @memberof data_room.DeleteModification
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteModification.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates a DeleteModification message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.DeleteModification
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.DeleteModification} DeleteModification
         */
        DeleteModification.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.DeleteModification)
                return object;
            let message = new $root.data_room.DeleteModification();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a DeleteModification message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.DeleteModification
         * @static
         * @param {data_room.DeleteModification} message DeleteModification
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteModification.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this DeleteModification to JSON.
         * @function toJSON
         * @memberof data_room.DeleteModification
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteModification.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteModification
         * @function getTypeUrl
         * @memberof data_room.DeleteModification
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteModification.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.DeleteModification";
        };

        return DeleteModification;
    })();

    data_room.ConfigurationCommit = (function() {

        /**
         * Properties of a ConfigurationCommit.
         * @memberof data_room
         * @interface IConfigurationCommit
         * @property {string|null} [id] ConfigurationCommit id
         * @property {string|null} [name] ConfigurationCommit name
         * @property {Uint8Array|null} [dataRoomId] ConfigurationCommit dataRoomId
         * @property {Uint8Array|null} [dataRoomHistoryPin] ConfigurationCommit dataRoomHistoryPin
         * @property {Array.<data_room.IConfigurationModification>|null} [modifications] ConfigurationCommit modifications
         */

        /**
         * Constructs a new ConfigurationCommit.
         * @memberof data_room
         * @classdesc Represents a ConfigurationCommit.
         * @implements IConfigurationCommit
         * @constructor
         * @param {data_room.IConfigurationCommit=} [properties] Properties to set
         */
        function ConfigurationCommit(properties) {
            this.modifications = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConfigurationCommit id.
         * @member {string} id
         * @memberof data_room.ConfigurationCommit
         * @instance
         */
        ConfigurationCommit.prototype.id = "";

        /**
         * ConfigurationCommit name.
         * @member {string} name
         * @memberof data_room.ConfigurationCommit
         * @instance
         */
        ConfigurationCommit.prototype.name = "";

        /**
         * ConfigurationCommit dataRoomId.
         * @member {Uint8Array} dataRoomId
         * @memberof data_room.ConfigurationCommit
         * @instance
         */
        ConfigurationCommit.prototype.dataRoomId = $util.newBuffer([]);

        /**
         * ConfigurationCommit dataRoomHistoryPin.
         * @member {Uint8Array} dataRoomHistoryPin
         * @memberof data_room.ConfigurationCommit
         * @instance
         */
        ConfigurationCommit.prototype.dataRoomHistoryPin = $util.newBuffer([]);

        /**
         * ConfigurationCommit modifications.
         * @member {Array.<data_room.IConfigurationModification>} modifications
         * @memberof data_room.ConfigurationCommit
         * @instance
         */
        ConfigurationCommit.prototype.modifications = $util.emptyArray;

        /**
         * Creates a new ConfigurationCommit instance using the specified properties.
         * @function create
         * @memberof data_room.ConfigurationCommit
         * @static
         * @param {data_room.IConfigurationCommit=} [properties] Properties to set
         * @returns {data_room.ConfigurationCommit} ConfigurationCommit instance
         */
        ConfigurationCommit.create = function create(properties) {
            return new ConfigurationCommit(properties);
        };

        /**
         * Encodes the specified ConfigurationCommit message. Does not implicitly {@link data_room.ConfigurationCommit.verify|verify} messages.
         * @function encode
         * @memberof data_room.ConfigurationCommit
         * @static
         * @param {data_room.IConfigurationCommit} message ConfigurationCommit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfigurationCommit.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.dataRoomId != null && Object.hasOwnProperty.call(message, "dataRoomId"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.dataRoomId);
            if (message.dataRoomHistoryPin != null && Object.hasOwnProperty.call(message, "dataRoomHistoryPin"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.dataRoomHistoryPin);
            if (message.modifications != null && message.modifications.length)
                for (let i = 0; i < message.modifications.length; ++i)
                    $root.data_room.ConfigurationModification.encode(message.modifications[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ConfigurationCommit message, length delimited. Does not implicitly {@link data_room.ConfigurationCommit.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.ConfigurationCommit
         * @static
         * @param {data_room.IConfigurationCommit} message ConfigurationCommit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfigurationCommit.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConfigurationCommit message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.ConfigurationCommit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.ConfigurationCommit} ConfigurationCommit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfigurationCommit.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.ConfigurationCommit();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.dataRoomId = reader.bytes();
                        break;
                    }
                case 4: {
                        message.dataRoomHistoryPin = reader.bytes();
                        break;
                    }
                case 5: {
                        if (!(message.modifications && message.modifications.length))
                            message.modifications = [];
                        message.modifications.push($root.data_room.ConfigurationModification.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConfigurationCommit message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.ConfigurationCommit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.ConfigurationCommit} ConfigurationCommit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfigurationCommit.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConfigurationCommit message.
         * @function verify
         * @memberof data_room.ConfigurationCommit
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConfigurationCommit.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                if (!(message.dataRoomId && typeof message.dataRoomId.length === "number" || $util.isString(message.dataRoomId)))
                    return "dataRoomId: buffer expected";
            if (message.dataRoomHistoryPin != null && message.hasOwnProperty("dataRoomHistoryPin"))
                if (!(message.dataRoomHistoryPin && typeof message.dataRoomHistoryPin.length === "number" || $util.isString(message.dataRoomHistoryPin)))
                    return "dataRoomHistoryPin: buffer expected";
            if (message.modifications != null && message.hasOwnProperty("modifications")) {
                if (!Array.isArray(message.modifications))
                    return "modifications: array expected";
                for (let i = 0; i < message.modifications.length; ++i) {
                    let error = $root.data_room.ConfigurationModification.verify(message.modifications[i]);
                    if (error)
                        return "modifications." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ConfigurationCommit message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.ConfigurationCommit
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.ConfigurationCommit} ConfigurationCommit
         */
        ConfigurationCommit.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.ConfigurationCommit)
                return object;
            let message = new $root.data_room.ConfigurationCommit();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            if (object.dataRoomId != null)
                if (typeof object.dataRoomId === "string")
                    $util.base64.decode(object.dataRoomId, message.dataRoomId = $util.newBuffer($util.base64.length(object.dataRoomId)), 0);
                else if (object.dataRoomId.length >= 0)
                    message.dataRoomId = object.dataRoomId;
            if (object.dataRoomHistoryPin != null)
                if (typeof object.dataRoomHistoryPin === "string")
                    $util.base64.decode(object.dataRoomHistoryPin, message.dataRoomHistoryPin = $util.newBuffer($util.base64.length(object.dataRoomHistoryPin)), 0);
                else if (object.dataRoomHistoryPin.length >= 0)
                    message.dataRoomHistoryPin = object.dataRoomHistoryPin;
            if (object.modifications) {
                if (!Array.isArray(object.modifications))
                    throw TypeError(".data_room.ConfigurationCommit.modifications: array expected");
                message.modifications = [];
                for (let i = 0; i < object.modifications.length; ++i) {
                    if (typeof object.modifications[i] !== "object")
                        throw TypeError(".data_room.ConfigurationCommit.modifications: object expected");
                    message.modifications[i] = $root.data_room.ConfigurationModification.fromObject(object.modifications[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ConfigurationCommit message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.ConfigurationCommit
         * @static
         * @param {data_room.ConfigurationCommit} message ConfigurationCommit
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConfigurationCommit.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.modifications = [];
            if (options.defaults) {
                object.id = "";
                object.name = "";
                if (options.bytes === String)
                    object.dataRoomId = "";
                else {
                    object.dataRoomId = [];
                    if (options.bytes !== Array)
                        object.dataRoomId = $util.newBuffer(object.dataRoomId);
                }
                if (options.bytes === String)
                    object.dataRoomHistoryPin = "";
                else {
                    object.dataRoomHistoryPin = [];
                    if (options.bytes !== Array)
                        object.dataRoomHistoryPin = $util.newBuffer(object.dataRoomHistoryPin);
                }
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                object.dataRoomId = options.bytes === String ? $util.base64.encode(message.dataRoomId, 0, message.dataRoomId.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomId) : message.dataRoomId;
            if (message.dataRoomHistoryPin != null && message.hasOwnProperty("dataRoomHistoryPin"))
                object.dataRoomHistoryPin = options.bytes === String ? $util.base64.encode(message.dataRoomHistoryPin, 0, message.dataRoomHistoryPin.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomHistoryPin) : message.dataRoomHistoryPin;
            if (message.modifications && message.modifications.length) {
                object.modifications = [];
                for (let j = 0; j < message.modifications.length; ++j)
                    object.modifications[j] = $root.data_room.ConfigurationModification.toObject(message.modifications[j], options);
            }
            return object;
        };

        /**
         * Converts this ConfigurationCommit to JSON.
         * @function toJSON
         * @memberof data_room.ConfigurationCommit
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConfigurationCommit.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ConfigurationCommit
         * @function getTypeUrl
         * @memberof data_room.ConfigurationCommit
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConfigurationCommit.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.ConfigurationCommit";
        };

        return ConfigurationCommit;
    })();

    data_room.WindowRateLimitingConfig = (function() {

        /**
         * Properties of a WindowRateLimitingConfig.
         * @memberof data_room
         * @interface IWindowRateLimitingConfig
         * @property {number|null} [timeWindowSeconds] WindowRateLimitingConfig timeWindowSeconds
         * @property {number|null} [numMaxExecutions] WindowRateLimitingConfig numMaxExecutions
         */

        /**
         * Constructs a new WindowRateLimitingConfig.
         * @memberof data_room
         * @classdesc Represents a WindowRateLimitingConfig.
         * @implements IWindowRateLimitingConfig
         * @constructor
         * @param {data_room.IWindowRateLimitingConfig=} [properties] Properties to set
         */
        function WindowRateLimitingConfig(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WindowRateLimitingConfig timeWindowSeconds.
         * @member {number} timeWindowSeconds
         * @memberof data_room.WindowRateLimitingConfig
         * @instance
         */
        WindowRateLimitingConfig.prototype.timeWindowSeconds = 0;

        /**
         * WindowRateLimitingConfig numMaxExecutions.
         * @member {number} numMaxExecutions
         * @memberof data_room.WindowRateLimitingConfig
         * @instance
         */
        WindowRateLimitingConfig.prototype.numMaxExecutions = 0;

        /**
         * Creates a new WindowRateLimitingConfig instance using the specified properties.
         * @function create
         * @memberof data_room.WindowRateLimitingConfig
         * @static
         * @param {data_room.IWindowRateLimitingConfig=} [properties] Properties to set
         * @returns {data_room.WindowRateLimitingConfig} WindowRateLimitingConfig instance
         */
        WindowRateLimitingConfig.create = function create(properties) {
            return new WindowRateLimitingConfig(properties);
        };

        /**
         * Encodes the specified WindowRateLimitingConfig message. Does not implicitly {@link data_room.WindowRateLimitingConfig.verify|verify} messages.
         * @function encode
         * @memberof data_room.WindowRateLimitingConfig
         * @static
         * @param {data_room.IWindowRateLimitingConfig} message WindowRateLimitingConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WindowRateLimitingConfig.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.timeWindowSeconds != null && Object.hasOwnProperty.call(message, "timeWindowSeconds"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.timeWindowSeconds);
            if (message.numMaxExecutions != null && Object.hasOwnProperty.call(message, "numMaxExecutions"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.numMaxExecutions);
            return writer;
        };

        /**
         * Encodes the specified WindowRateLimitingConfig message, length delimited. Does not implicitly {@link data_room.WindowRateLimitingConfig.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.WindowRateLimitingConfig
         * @static
         * @param {data_room.IWindowRateLimitingConfig} message WindowRateLimitingConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WindowRateLimitingConfig.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WindowRateLimitingConfig message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.WindowRateLimitingConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.WindowRateLimitingConfig} WindowRateLimitingConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WindowRateLimitingConfig.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.WindowRateLimitingConfig();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.timeWindowSeconds = reader.uint32();
                        break;
                    }
                case 2: {
                        message.numMaxExecutions = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WindowRateLimitingConfig message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.WindowRateLimitingConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.WindowRateLimitingConfig} WindowRateLimitingConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WindowRateLimitingConfig.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WindowRateLimitingConfig message.
         * @function verify
         * @memberof data_room.WindowRateLimitingConfig
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WindowRateLimitingConfig.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.timeWindowSeconds != null && message.hasOwnProperty("timeWindowSeconds"))
                if (!$util.isInteger(message.timeWindowSeconds))
                    return "timeWindowSeconds: integer expected";
            if (message.numMaxExecutions != null && message.hasOwnProperty("numMaxExecutions"))
                if (!$util.isInteger(message.numMaxExecutions))
                    return "numMaxExecutions: integer expected";
            return null;
        };

        /**
         * Creates a WindowRateLimitingConfig message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.WindowRateLimitingConfig
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.WindowRateLimitingConfig} WindowRateLimitingConfig
         */
        WindowRateLimitingConfig.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.WindowRateLimitingConfig)
                return object;
            let message = new $root.data_room.WindowRateLimitingConfig();
            if (object.timeWindowSeconds != null)
                message.timeWindowSeconds = object.timeWindowSeconds >>> 0;
            if (object.numMaxExecutions != null)
                message.numMaxExecutions = object.numMaxExecutions >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a WindowRateLimitingConfig message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.WindowRateLimitingConfig
         * @static
         * @param {data_room.WindowRateLimitingConfig} message WindowRateLimitingConfig
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WindowRateLimitingConfig.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.timeWindowSeconds = 0;
                object.numMaxExecutions = 0;
            }
            if (message.timeWindowSeconds != null && message.hasOwnProperty("timeWindowSeconds"))
                object.timeWindowSeconds = message.timeWindowSeconds;
            if (message.numMaxExecutions != null && message.hasOwnProperty("numMaxExecutions"))
                object.numMaxExecutions = message.numMaxExecutions;
            return object;
        };

        /**
         * Converts this WindowRateLimitingConfig to JSON.
         * @function toJSON
         * @memberof data_room.WindowRateLimitingConfig
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WindowRateLimitingConfig.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WindowRateLimitingConfig
         * @function getTypeUrl
         * @memberof data_room.WindowRateLimitingConfig
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WindowRateLimitingConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.WindowRateLimitingConfig";
        };

        return WindowRateLimitingConfig;
    })();

    data_room.RateLimitingConfig = (function() {

        /**
         * Properties of a RateLimitingConfig.
         * @memberof data_room
         * @interface IRateLimitingConfig
         * @property {data_room.IWindowRateLimitingConfig|null} [window] RateLimitingConfig window
         */

        /**
         * Constructs a new RateLimitingConfig.
         * @memberof data_room
         * @classdesc Represents a RateLimitingConfig.
         * @implements IRateLimitingConfig
         * @constructor
         * @param {data_room.IRateLimitingConfig=} [properties] Properties to set
         */
        function RateLimitingConfig(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RateLimitingConfig window.
         * @member {data_room.IWindowRateLimitingConfig|null|undefined} window
         * @memberof data_room.RateLimitingConfig
         * @instance
         */
        RateLimitingConfig.prototype.window = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * RateLimitingConfig method.
         * @member {"window"|undefined} method
         * @memberof data_room.RateLimitingConfig
         * @instance
         */
        Object.defineProperty(RateLimitingConfig.prototype, "method", {
            get: $util.oneOfGetter($oneOfFields = ["window"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new RateLimitingConfig instance using the specified properties.
         * @function create
         * @memberof data_room.RateLimitingConfig
         * @static
         * @param {data_room.IRateLimitingConfig=} [properties] Properties to set
         * @returns {data_room.RateLimitingConfig} RateLimitingConfig instance
         */
        RateLimitingConfig.create = function create(properties) {
            return new RateLimitingConfig(properties);
        };

        /**
         * Encodes the specified RateLimitingConfig message. Does not implicitly {@link data_room.RateLimitingConfig.verify|verify} messages.
         * @function encode
         * @memberof data_room.RateLimitingConfig
         * @static
         * @param {data_room.IRateLimitingConfig} message RateLimitingConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RateLimitingConfig.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.window != null && Object.hasOwnProperty.call(message, "window"))
                $root.data_room.WindowRateLimitingConfig.encode(message.window, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RateLimitingConfig message, length delimited. Does not implicitly {@link data_room.RateLimitingConfig.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.RateLimitingConfig
         * @static
         * @param {data_room.IRateLimitingConfig} message RateLimitingConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RateLimitingConfig.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RateLimitingConfig message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.RateLimitingConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.RateLimitingConfig} RateLimitingConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RateLimitingConfig.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.RateLimitingConfig();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.window = $root.data_room.WindowRateLimitingConfig.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RateLimitingConfig message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.RateLimitingConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.RateLimitingConfig} RateLimitingConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RateLimitingConfig.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RateLimitingConfig message.
         * @function verify
         * @memberof data_room.RateLimitingConfig
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RateLimitingConfig.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.window != null && message.hasOwnProperty("window")) {
                properties.method = 1;
                {
                    let error = $root.data_room.WindowRateLimitingConfig.verify(message.window);
                    if (error)
                        return "window." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RateLimitingConfig message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.RateLimitingConfig
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.RateLimitingConfig} RateLimitingConfig
         */
        RateLimitingConfig.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.RateLimitingConfig)
                return object;
            let message = new $root.data_room.RateLimitingConfig();
            if (object.window != null) {
                if (typeof object.window !== "object")
                    throw TypeError(".data_room.RateLimitingConfig.window: object expected");
                message.window = $root.data_room.WindowRateLimitingConfig.fromObject(object.window);
            }
            return message;
        };

        /**
         * Creates a plain object from a RateLimitingConfig message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.RateLimitingConfig
         * @static
         * @param {data_room.RateLimitingConfig} message RateLimitingConfig
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RateLimitingConfig.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.window != null && message.hasOwnProperty("window")) {
                object.window = $root.data_room.WindowRateLimitingConfig.toObject(message.window, options);
                if (options.oneofs)
                    object.method = "window";
            }
            return object;
        };

        /**
         * Converts this RateLimitingConfig to JSON.
         * @function toJSON
         * @memberof data_room.RateLimitingConfig
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RateLimitingConfig.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RateLimitingConfig
         * @function getTypeUrl
         * @memberof data_room.RateLimitingConfig
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RateLimitingConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.RateLimitingConfig";
        };

        return RateLimitingConfig;
    })();

    data_room.ComputeNode = (function() {

        /**
         * Properties of a ComputeNode.
         * @memberof data_room
         * @interface IComputeNode
         * @property {string|null} [nodeName] ComputeNode nodeName
         * @property {data_room.IComputeNodeLeaf|null} [leaf] ComputeNode leaf
         * @property {data_room.IComputeNodeParameter|null} [parameter] ComputeNode parameter
         * @property {data_room.IComputeNodeBranch|null} [branch] ComputeNode branch
         * @property {data_room.IComputeNodeAirlock|null} [airlock] ComputeNode airlock
         * @property {data_room.IRateLimitingConfig|null} [rateLimiting] the rate limit applied.
         */

        /**
         * Constructs a new ComputeNode.
         * @memberof data_room
         * @classdesc Represents a ComputeNode.
         * @implements IComputeNode
         * @constructor
         * @param {data_room.IComputeNode=} [properties] Properties to set
         */
        function ComputeNode(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ComputeNode nodeName.
         * @member {string} nodeName
         * @memberof data_room.ComputeNode
         * @instance
         */
        ComputeNode.prototype.nodeName = "";

        /**
         * ComputeNode leaf.
         * @member {data_room.IComputeNodeLeaf|null|undefined} leaf
         * @memberof data_room.ComputeNode
         * @instance
         */
        ComputeNode.prototype.leaf = null;

        /**
         * ComputeNode parameter.
         * @member {data_room.IComputeNodeParameter|null|undefined} parameter
         * @memberof data_room.ComputeNode
         * @instance
         */
        ComputeNode.prototype.parameter = null;

        /**
         * ComputeNode branch.
         * @member {data_room.IComputeNodeBranch|null|undefined} branch
         * @memberof data_room.ComputeNode
         * @instance
         */
        ComputeNode.prototype.branch = null;

        /**
         * ComputeNode airlock.
         * @member {data_room.IComputeNodeAirlock|null|undefined} airlock
         * @memberof data_room.ComputeNode
         * @instance
         */
        ComputeNode.prototype.airlock = null;

        /**
         * the rate limit applied.
         * @member {data_room.IRateLimitingConfig|null|undefined} rateLimiting
         * @memberof data_room.ComputeNode
         * @instance
         */
        ComputeNode.prototype.rateLimiting = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ComputeNode node.
         * @member {"leaf"|"parameter"|"branch"|"airlock"|undefined} node
         * @memberof data_room.ComputeNode
         * @instance
         */
        Object.defineProperty(ComputeNode.prototype, "node", {
            get: $util.oneOfGetter($oneOfFields = ["leaf", "parameter", "branch", "airlock"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ComputeNode _rateLimiting.
         * @member {"rateLimiting"|undefined} _rateLimiting
         * @memberof data_room.ComputeNode
         * @instance
         */
        Object.defineProperty(ComputeNode.prototype, "_rateLimiting", {
            get: $util.oneOfGetter($oneOfFields = ["rateLimiting"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ComputeNode instance using the specified properties.
         * @function create
         * @memberof data_room.ComputeNode
         * @static
         * @param {data_room.IComputeNode=} [properties] Properties to set
         * @returns {data_room.ComputeNode} ComputeNode instance
         */
        ComputeNode.create = function create(properties) {
            return new ComputeNode(properties);
        };

        /**
         * Encodes the specified ComputeNode message. Does not implicitly {@link data_room.ComputeNode.verify|verify} messages.
         * @function encode
         * @memberof data_room.ComputeNode
         * @static
         * @param {data_room.IComputeNode} message ComputeNode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComputeNode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nodeName != null && Object.hasOwnProperty.call(message, "nodeName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.nodeName);
            if (message.leaf != null && Object.hasOwnProperty.call(message, "leaf"))
                $root.data_room.ComputeNodeLeaf.encode(message.leaf, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.branch != null && Object.hasOwnProperty.call(message, "branch"))
                $root.data_room.ComputeNodeBranch.encode(message.branch, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.parameter != null && Object.hasOwnProperty.call(message, "parameter"))
                $root.data_room.ComputeNodeParameter.encode(message.parameter, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.rateLimiting != null && Object.hasOwnProperty.call(message, "rateLimiting"))
                $root.data_room.RateLimitingConfig.encode(message.rateLimiting, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.airlock != null && Object.hasOwnProperty.call(message, "airlock"))
                $root.data_room.ComputeNodeAirlock.encode(message.airlock, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ComputeNode message, length delimited. Does not implicitly {@link data_room.ComputeNode.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.ComputeNode
         * @static
         * @param {data_room.IComputeNode} message ComputeNode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComputeNode.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ComputeNode message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.ComputeNode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.ComputeNode} ComputeNode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComputeNode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.ComputeNode();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.nodeName = reader.string();
                        break;
                    }
                case 2: {
                        message.leaf = $root.data_room.ComputeNodeLeaf.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.parameter = $root.data_room.ComputeNodeParameter.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.branch = $root.data_room.ComputeNodeBranch.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.airlock = $root.data_room.ComputeNodeAirlock.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.rateLimiting = $root.data_room.RateLimitingConfig.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ComputeNode message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.ComputeNode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.ComputeNode} ComputeNode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComputeNode.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ComputeNode message.
         * @function verify
         * @memberof data_room.ComputeNode
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ComputeNode.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.nodeName != null && message.hasOwnProperty("nodeName"))
                if (!$util.isString(message.nodeName))
                    return "nodeName: string expected";
            if (message.leaf != null && message.hasOwnProperty("leaf")) {
                properties.node = 1;
                {
                    let error = $root.data_room.ComputeNodeLeaf.verify(message.leaf);
                    if (error)
                        return "leaf." + error;
                }
            }
            if (message.parameter != null && message.hasOwnProperty("parameter")) {
                if (properties.node === 1)
                    return "node: multiple values";
                properties.node = 1;
                {
                    let error = $root.data_room.ComputeNodeParameter.verify(message.parameter);
                    if (error)
                        return "parameter." + error;
                }
            }
            if (message.branch != null && message.hasOwnProperty("branch")) {
                if (properties.node === 1)
                    return "node: multiple values";
                properties.node = 1;
                {
                    let error = $root.data_room.ComputeNodeBranch.verify(message.branch);
                    if (error)
                        return "branch." + error;
                }
            }
            if (message.airlock != null && message.hasOwnProperty("airlock")) {
                if (properties.node === 1)
                    return "node: multiple values";
                properties.node = 1;
                {
                    let error = $root.data_room.ComputeNodeAirlock.verify(message.airlock);
                    if (error)
                        return "airlock." + error;
                }
            }
            if (message.rateLimiting != null && message.hasOwnProperty("rateLimiting")) {
                properties._rateLimiting = 1;
                {
                    let error = $root.data_room.RateLimitingConfig.verify(message.rateLimiting);
                    if (error)
                        return "rateLimiting." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ComputeNode message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.ComputeNode
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.ComputeNode} ComputeNode
         */
        ComputeNode.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.ComputeNode)
                return object;
            let message = new $root.data_room.ComputeNode();
            if (object.nodeName != null)
                message.nodeName = String(object.nodeName);
            if (object.leaf != null) {
                if (typeof object.leaf !== "object")
                    throw TypeError(".data_room.ComputeNode.leaf: object expected");
                message.leaf = $root.data_room.ComputeNodeLeaf.fromObject(object.leaf);
            }
            if (object.parameter != null) {
                if (typeof object.parameter !== "object")
                    throw TypeError(".data_room.ComputeNode.parameter: object expected");
                message.parameter = $root.data_room.ComputeNodeParameter.fromObject(object.parameter);
            }
            if (object.branch != null) {
                if (typeof object.branch !== "object")
                    throw TypeError(".data_room.ComputeNode.branch: object expected");
                message.branch = $root.data_room.ComputeNodeBranch.fromObject(object.branch);
            }
            if (object.airlock != null) {
                if (typeof object.airlock !== "object")
                    throw TypeError(".data_room.ComputeNode.airlock: object expected");
                message.airlock = $root.data_room.ComputeNodeAirlock.fromObject(object.airlock);
            }
            if (object.rateLimiting != null) {
                if (typeof object.rateLimiting !== "object")
                    throw TypeError(".data_room.ComputeNode.rateLimiting: object expected");
                message.rateLimiting = $root.data_room.RateLimitingConfig.fromObject(object.rateLimiting);
            }
            return message;
        };

        /**
         * Creates a plain object from a ComputeNode message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.ComputeNode
         * @static
         * @param {data_room.ComputeNode} message ComputeNode
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ComputeNode.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.nodeName = "";
            if (message.nodeName != null && message.hasOwnProperty("nodeName"))
                object.nodeName = message.nodeName;
            if (message.leaf != null && message.hasOwnProperty("leaf")) {
                object.leaf = $root.data_room.ComputeNodeLeaf.toObject(message.leaf, options);
                if (options.oneofs)
                    object.node = "leaf";
            }
            if (message.branch != null && message.hasOwnProperty("branch")) {
                object.branch = $root.data_room.ComputeNodeBranch.toObject(message.branch, options);
                if (options.oneofs)
                    object.node = "branch";
            }
            if (message.parameter != null && message.hasOwnProperty("parameter")) {
                object.parameter = $root.data_room.ComputeNodeParameter.toObject(message.parameter, options);
                if (options.oneofs)
                    object.node = "parameter";
            }
            if (message.rateLimiting != null && message.hasOwnProperty("rateLimiting")) {
                object.rateLimiting = $root.data_room.RateLimitingConfig.toObject(message.rateLimiting, options);
                if (options.oneofs)
                    object._rateLimiting = "rateLimiting";
            }
            if (message.airlock != null && message.hasOwnProperty("airlock")) {
                object.airlock = $root.data_room.ComputeNodeAirlock.toObject(message.airlock, options);
                if (options.oneofs)
                    object.node = "airlock";
            }
            return object;
        };

        /**
         * Converts this ComputeNode to JSON.
         * @function toJSON
         * @memberof data_room.ComputeNode
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ComputeNode.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ComputeNode
         * @function getTypeUrl
         * @memberof data_room.ComputeNode
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ComputeNode.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.ComputeNode";
        };

        return ComputeNode;
    })();

    data_room.ComputeNodeLeaf = (function() {

        /**
         * Properties of a ComputeNodeLeaf.
         * @memberof data_room
         * @interface IComputeNodeLeaf
         * @property {boolean|null} [isRequired] ComputeNodeLeaf isRequired
         */

        /**
         * Constructs a new ComputeNodeLeaf.
         * @memberof data_room
         * @classdesc Represents a ComputeNodeLeaf.
         * @implements IComputeNodeLeaf
         * @constructor
         * @param {data_room.IComputeNodeLeaf=} [properties] Properties to set
         */
        function ComputeNodeLeaf(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ComputeNodeLeaf isRequired.
         * @member {boolean} isRequired
         * @memberof data_room.ComputeNodeLeaf
         * @instance
         */
        ComputeNodeLeaf.prototype.isRequired = false;

        /**
         * Creates a new ComputeNodeLeaf instance using the specified properties.
         * @function create
         * @memberof data_room.ComputeNodeLeaf
         * @static
         * @param {data_room.IComputeNodeLeaf=} [properties] Properties to set
         * @returns {data_room.ComputeNodeLeaf} ComputeNodeLeaf instance
         */
        ComputeNodeLeaf.create = function create(properties) {
            return new ComputeNodeLeaf(properties);
        };

        /**
         * Encodes the specified ComputeNodeLeaf message. Does not implicitly {@link data_room.ComputeNodeLeaf.verify|verify} messages.
         * @function encode
         * @memberof data_room.ComputeNodeLeaf
         * @static
         * @param {data_room.IComputeNodeLeaf} message ComputeNodeLeaf message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComputeNodeLeaf.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.isRequired != null && Object.hasOwnProperty.call(message, "isRequired"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isRequired);
            return writer;
        };

        /**
         * Encodes the specified ComputeNodeLeaf message, length delimited. Does not implicitly {@link data_room.ComputeNodeLeaf.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.ComputeNodeLeaf
         * @static
         * @param {data_room.IComputeNodeLeaf} message ComputeNodeLeaf message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComputeNodeLeaf.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ComputeNodeLeaf message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.ComputeNodeLeaf
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.ComputeNodeLeaf} ComputeNodeLeaf
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComputeNodeLeaf.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.ComputeNodeLeaf();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.isRequired = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ComputeNodeLeaf message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.ComputeNodeLeaf
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.ComputeNodeLeaf} ComputeNodeLeaf
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComputeNodeLeaf.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ComputeNodeLeaf message.
         * @function verify
         * @memberof data_room.ComputeNodeLeaf
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ComputeNodeLeaf.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.isRequired != null && message.hasOwnProperty("isRequired"))
                if (typeof message.isRequired !== "boolean")
                    return "isRequired: boolean expected";
            return null;
        };

        /**
         * Creates a ComputeNodeLeaf message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.ComputeNodeLeaf
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.ComputeNodeLeaf} ComputeNodeLeaf
         */
        ComputeNodeLeaf.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.ComputeNodeLeaf)
                return object;
            let message = new $root.data_room.ComputeNodeLeaf();
            if (object.isRequired != null)
                message.isRequired = Boolean(object.isRequired);
            return message;
        };

        /**
         * Creates a plain object from a ComputeNodeLeaf message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.ComputeNodeLeaf
         * @static
         * @param {data_room.ComputeNodeLeaf} message ComputeNodeLeaf
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ComputeNodeLeaf.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.isRequired = false;
            if (message.isRequired != null && message.hasOwnProperty("isRequired"))
                object.isRequired = message.isRequired;
            return object;
        };

        /**
         * Converts this ComputeNodeLeaf to JSON.
         * @function toJSON
         * @memberof data_room.ComputeNodeLeaf
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ComputeNodeLeaf.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ComputeNodeLeaf
         * @function getTypeUrl
         * @memberof data_room.ComputeNodeLeaf
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ComputeNodeLeaf.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.ComputeNodeLeaf";
        };

        return ComputeNodeLeaf;
    })();

    data_room.ComputeNodeParameter = (function() {

        /**
         * Properties of a ComputeNodeParameter.
         * @memberof data_room
         * @interface IComputeNodeParameter
         * @property {boolean|null} [isRequired] ComputeNodeParameter isRequired
         */

        /**
         * Constructs a new ComputeNodeParameter.
         * @memberof data_room
         * @classdesc Represents a ComputeNodeParameter.
         * @implements IComputeNodeParameter
         * @constructor
         * @param {data_room.IComputeNodeParameter=} [properties] Properties to set
         */
        function ComputeNodeParameter(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ComputeNodeParameter isRequired.
         * @member {boolean} isRequired
         * @memberof data_room.ComputeNodeParameter
         * @instance
         */
        ComputeNodeParameter.prototype.isRequired = false;

        /**
         * Creates a new ComputeNodeParameter instance using the specified properties.
         * @function create
         * @memberof data_room.ComputeNodeParameter
         * @static
         * @param {data_room.IComputeNodeParameter=} [properties] Properties to set
         * @returns {data_room.ComputeNodeParameter} ComputeNodeParameter instance
         */
        ComputeNodeParameter.create = function create(properties) {
            return new ComputeNodeParameter(properties);
        };

        /**
         * Encodes the specified ComputeNodeParameter message. Does not implicitly {@link data_room.ComputeNodeParameter.verify|verify} messages.
         * @function encode
         * @memberof data_room.ComputeNodeParameter
         * @static
         * @param {data_room.IComputeNodeParameter} message ComputeNodeParameter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComputeNodeParameter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.isRequired != null && Object.hasOwnProperty.call(message, "isRequired"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isRequired);
            return writer;
        };

        /**
         * Encodes the specified ComputeNodeParameter message, length delimited. Does not implicitly {@link data_room.ComputeNodeParameter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.ComputeNodeParameter
         * @static
         * @param {data_room.IComputeNodeParameter} message ComputeNodeParameter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComputeNodeParameter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ComputeNodeParameter message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.ComputeNodeParameter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.ComputeNodeParameter} ComputeNodeParameter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComputeNodeParameter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.ComputeNodeParameter();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.isRequired = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ComputeNodeParameter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.ComputeNodeParameter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.ComputeNodeParameter} ComputeNodeParameter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComputeNodeParameter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ComputeNodeParameter message.
         * @function verify
         * @memberof data_room.ComputeNodeParameter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ComputeNodeParameter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.isRequired != null && message.hasOwnProperty("isRequired"))
                if (typeof message.isRequired !== "boolean")
                    return "isRequired: boolean expected";
            return null;
        };

        /**
         * Creates a ComputeNodeParameter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.ComputeNodeParameter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.ComputeNodeParameter} ComputeNodeParameter
         */
        ComputeNodeParameter.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.ComputeNodeParameter)
                return object;
            let message = new $root.data_room.ComputeNodeParameter();
            if (object.isRequired != null)
                message.isRequired = Boolean(object.isRequired);
            return message;
        };

        /**
         * Creates a plain object from a ComputeNodeParameter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.ComputeNodeParameter
         * @static
         * @param {data_room.ComputeNodeParameter} message ComputeNodeParameter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ComputeNodeParameter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.isRequired = false;
            if (message.isRequired != null && message.hasOwnProperty("isRequired"))
                object.isRequired = message.isRequired;
            return object;
        };

        /**
         * Converts this ComputeNodeParameter to JSON.
         * @function toJSON
         * @memberof data_room.ComputeNodeParameter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ComputeNodeParameter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ComputeNodeParameter
         * @function getTypeUrl
         * @memberof data_room.ComputeNodeParameter
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ComputeNodeParameter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.ComputeNodeParameter";
        };

        return ComputeNodeParameter;
    })();

    data_room.ComputeNodeAirlock = (function() {

        /**
         * Properties of a ComputeNodeAirlock.
         * @memberof data_room
         * @interface IComputeNodeAirlock
         * @property {number|Long|null} [quotaBytes] ComputeNodeAirlock quotaBytes
         * @property {string|null} [airlockedDependency] ComputeNodeAirlock airlockedDependency
         */

        /**
         * Constructs a new ComputeNodeAirlock.
         * @memberof data_room
         * @classdesc Represents a ComputeNodeAirlock.
         * @implements IComputeNodeAirlock
         * @constructor
         * @param {data_room.IComputeNodeAirlock=} [properties] Properties to set
         */
        function ComputeNodeAirlock(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ComputeNodeAirlock quotaBytes.
         * @member {number|Long} quotaBytes
         * @memberof data_room.ComputeNodeAirlock
         * @instance
         */
        ComputeNodeAirlock.prototype.quotaBytes = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ComputeNodeAirlock airlockedDependency.
         * @member {string} airlockedDependency
         * @memberof data_room.ComputeNodeAirlock
         * @instance
         */
        ComputeNodeAirlock.prototype.airlockedDependency = "";

        /**
         * Creates a new ComputeNodeAirlock instance using the specified properties.
         * @function create
         * @memberof data_room.ComputeNodeAirlock
         * @static
         * @param {data_room.IComputeNodeAirlock=} [properties] Properties to set
         * @returns {data_room.ComputeNodeAirlock} ComputeNodeAirlock instance
         */
        ComputeNodeAirlock.create = function create(properties) {
            return new ComputeNodeAirlock(properties);
        };

        /**
         * Encodes the specified ComputeNodeAirlock message. Does not implicitly {@link data_room.ComputeNodeAirlock.verify|verify} messages.
         * @function encode
         * @memberof data_room.ComputeNodeAirlock
         * @static
         * @param {data_room.IComputeNodeAirlock} message ComputeNodeAirlock message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComputeNodeAirlock.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.quotaBytes != null && Object.hasOwnProperty.call(message, "quotaBytes"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.quotaBytes);
            if (message.airlockedDependency != null && Object.hasOwnProperty.call(message, "airlockedDependency"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.airlockedDependency);
            return writer;
        };

        /**
         * Encodes the specified ComputeNodeAirlock message, length delimited. Does not implicitly {@link data_room.ComputeNodeAirlock.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.ComputeNodeAirlock
         * @static
         * @param {data_room.IComputeNodeAirlock} message ComputeNodeAirlock message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComputeNodeAirlock.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ComputeNodeAirlock message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.ComputeNodeAirlock
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.ComputeNodeAirlock} ComputeNodeAirlock
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComputeNodeAirlock.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.ComputeNodeAirlock();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.quotaBytes = reader.uint64();
                        break;
                    }
                case 2: {
                        message.airlockedDependency = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ComputeNodeAirlock message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.ComputeNodeAirlock
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.ComputeNodeAirlock} ComputeNodeAirlock
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComputeNodeAirlock.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ComputeNodeAirlock message.
         * @function verify
         * @memberof data_room.ComputeNodeAirlock
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ComputeNodeAirlock.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.quotaBytes != null && message.hasOwnProperty("quotaBytes"))
                if (!$util.isInteger(message.quotaBytes) && !(message.quotaBytes && $util.isInteger(message.quotaBytes.low) && $util.isInteger(message.quotaBytes.high)))
                    return "quotaBytes: integer|Long expected";
            if (message.airlockedDependency != null && message.hasOwnProperty("airlockedDependency"))
                if (!$util.isString(message.airlockedDependency))
                    return "airlockedDependency: string expected";
            return null;
        };

        /**
         * Creates a ComputeNodeAirlock message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.ComputeNodeAirlock
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.ComputeNodeAirlock} ComputeNodeAirlock
         */
        ComputeNodeAirlock.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.ComputeNodeAirlock)
                return object;
            let message = new $root.data_room.ComputeNodeAirlock();
            if (object.quotaBytes != null)
                if ($util.Long)
                    (message.quotaBytes = $util.Long.fromValue(object.quotaBytes)).unsigned = true;
                else if (typeof object.quotaBytes === "string")
                    message.quotaBytes = parseInt(object.quotaBytes, 10);
                else if (typeof object.quotaBytes === "number")
                    message.quotaBytes = object.quotaBytes;
                else if (typeof object.quotaBytes === "object")
                    message.quotaBytes = new $util.LongBits(object.quotaBytes.low >>> 0, object.quotaBytes.high >>> 0).toNumber(true);
            if (object.airlockedDependency != null)
                message.airlockedDependency = String(object.airlockedDependency);
            return message;
        };

        /**
         * Creates a plain object from a ComputeNodeAirlock message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.ComputeNodeAirlock
         * @static
         * @param {data_room.ComputeNodeAirlock} message ComputeNodeAirlock
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ComputeNodeAirlock.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.quotaBytes = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.quotaBytes = options.longs === String ? "0" : 0;
                object.airlockedDependency = "";
            }
            if (message.quotaBytes != null && message.hasOwnProperty("quotaBytes"))
                if (typeof message.quotaBytes === "number")
                    object.quotaBytes = options.longs === String ? String(message.quotaBytes) : message.quotaBytes;
                else
                    object.quotaBytes = options.longs === String ? $util.Long.prototype.toString.call(message.quotaBytes) : options.longs === Number ? new $util.LongBits(message.quotaBytes.low >>> 0, message.quotaBytes.high >>> 0).toNumber(true) : message.quotaBytes;
            if (message.airlockedDependency != null && message.hasOwnProperty("airlockedDependency"))
                object.airlockedDependency = message.airlockedDependency;
            return object;
        };

        /**
         * Converts this ComputeNodeAirlock to JSON.
         * @function toJSON
         * @memberof data_room.ComputeNodeAirlock
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ComputeNodeAirlock.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ComputeNodeAirlock
         * @function getTypeUrl
         * @memberof data_room.ComputeNodeAirlock
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ComputeNodeAirlock.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.ComputeNodeAirlock";
        };

        return ComputeNodeAirlock;
    })();

    /**
     * ComputeNodeFormat enum.
     * @name data_room.ComputeNodeFormat
     * @enum {number}
     * @property {number} RAW=0 RAW value
     * @property {number} ZIP=1 ZIP value
     */
    data_room.ComputeNodeFormat = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RAW"] = 0;
        values[valuesById[1] = "ZIP"] = 1;
        return values;
    })();

    data_room.ComputeNodeProtocol = (function() {

        /**
         * Properties of a ComputeNodeProtocol.
         * @memberof data_room
         * @interface IComputeNodeProtocol
         * @property {number|null} [version] ComputeNodeProtocol version
         */

        /**
         * Constructs a new ComputeNodeProtocol.
         * @memberof data_room
         * @classdesc Represents a ComputeNodeProtocol.
         * @implements IComputeNodeProtocol
         * @constructor
         * @param {data_room.IComputeNodeProtocol=} [properties] Properties to set
         */
        function ComputeNodeProtocol(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ComputeNodeProtocol version.
         * @member {number} version
         * @memberof data_room.ComputeNodeProtocol
         * @instance
         */
        ComputeNodeProtocol.prototype.version = 0;

        /**
         * Creates a new ComputeNodeProtocol instance using the specified properties.
         * @function create
         * @memberof data_room.ComputeNodeProtocol
         * @static
         * @param {data_room.IComputeNodeProtocol=} [properties] Properties to set
         * @returns {data_room.ComputeNodeProtocol} ComputeNodeProtocol instance
         */
        ComputeNodeProtocol.create = function create(properties) {
            return new ComputeNodeProtocol(properties);
        };

        /**
         * Encodes the specified ComputeNodeProtocol message. Does not implicitly {@link data_room.ComputeNodeProtocol.verify|verify} messages.
         * @function encode
         * @memberof data_room.ComputeNodeProtocol
         * @static
         * @param {data_room.IComputeNodeProtocol} message ComputeNodeProtocol message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComputeNodeProtocol.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.version);
            return writer;
        };

        /**
         * Encodes the specified ComputeNodeProtocol message, length delimited. Does not implicitly {@link data_room.ComputeNodeProtocol.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.ComputeNodeProtocol
         * @static
         * @param {data_room.IComputeNodeProtocol} message ComputeNodeProtocol message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComputeNodeProtocol.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ComputeNodeProtocol message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.ComputeNodeProtocol
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.ComputeNodeProtocol} ComputeNodeProtocol
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComputeNodeProtocol.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.ComputeNodeProtocol();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.version = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ComputeNodeProtocol message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.ComputeNodeProtocol
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.ComputeNodeProtocol} ComputeNodeProtocol
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComputeNodeProtocol.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ComputeNodeProtocol message.
         * @function verify
         * @memberof data_room.ComputeNodeProtocol
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ComputeNodeProtocol.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isInteger(message.version))
                    return "version: integer expected";
            return null;
        };

        /**
         * Creates a ComputeNodeProtocol message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.ComputeNodeProtocol
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.ComputeNodeProtocol} ComputeNodeProtocol
         */
        ComputeNodeProtocol.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.ComputeNodeProtocol)
                return object;
            let message = new $root.data_room.ComputeNodeProtocol();
            if (object.version != null)
                message.version = object.version >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a ComputeNodeProtocol message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.ComputeNodeProtocol
         * @static
         * @param {data_room.ComputeNodeProtocol} message ComputeNodeProtocol
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ComputeNodeProtocol.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.version = 0;
            if (message.version != null && message.hasOwnProperty("version"))
                object.version = message.version;
            return object;
        };

        /**
         * Converts this ComputeNodeProtocol to JSON.
         * @function toJSON
         * @memberof data_room.ComputeNodeProtocol
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ComputeNodeProtocol.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ComputeNodeProtocol
         * @function getTypeUrl
         * @memberof data_room.ComputeNodeProtocol
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ComputeNodeProtocol.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.ComputeNodeProtocol";
        };

        return ComputeNodeProtocol;
    })();

    data_room.ComputeNodeBranch = (function() {

        /**
         * Properties of a ComputeNodeBranch.
         * @memberof data_room
         * @interface IComputeNodeBranch
         * @property {Uint8Array|null} [config] ComputeNodeBranch config
         * @property {Array.<string>|null} [dependencies] ComputeNodeBranch dependencies
         * @property {data_room.ComputeNodeFormat|null} [outputFormat] ComputeNodeBranch outputFormat
         * @property {data_room.IComputeNodeProtocol|null} [protocol] ComputeNodeBranch protocol
         * @property {string|null} [attestationSpecificationId] ComputeNodeBranch attestationSpecificationId
         */

        /**
         * Constructs a new ComputeNodeBranch.
         * @memberof data_room
         * @classdesc Represents a ComputeNodeBranch.
         * @implements IComputeNodeBranch
         * @constructor
         * @param {data_room.IComputeNodeBranch=} [properties] Properties to set
         */
        function ComputeNodeBranch(properties) {
            this.dependencies = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ComputeNodeBranch config.
         * @member {Uint8Array} config
         * @memberof data_room.ComputeNodeBranch
         * @instance
         */
        ComputeNodeBranch.prototype.config = $util.newBuffer([]);

        /**
         * ComputeNodeBranch dependencies.
         * @member {Array.<string>} dependencies
         * @memberof data_room.ComputeNodeBranch
         * @instance
         */
        ComputeNodeBranch.prototype.dependencies = $util.emptyArray;

        /**
         * ComputeNodeBranch outputFormat.
         * @member {data_room.ComputeNodeFormat} outputFormat
         * @memberof data_room.ComputeNodeBranch
         * @instance
         */
        ComputeNodeBranch.prototype.outputFormat = 0;

        /**
         * ComputeNodeBranch protocol.
         * @member {data_room.IComputeNodeProtocol|null|undefined} protocol
         * @memberof data_room.ComputeNodeBranch
         * @instance
         */
        ComputeNodeBranch.prototype.protocol = null;

        /**
         * ComputeNodeBranch attestationSpecificationId.
         * @member {string} attestationSpecificationId
         * @memberof data_room.ComputeNodeBranch
         * @instance
         */
        ComputeNodeBranch.prototype.attestationSpecificationId = "";

        /**
         * Creates a new ComputeNodeBranch instance using the specified properties.
         * @function create
         * @memberof data_room.ComputeNodeBranch
         * @static
         * @param {data_room.IComputeNodeBranch=} [properties] Properties to set
         * @returns {data_room.ComputeNodeBranch} ComputeNodeBranch instance
         */
        ComputeNodeBranch.create = function create(properties) {
            return new ComputeNodeBranch(properties);
        };

        /**
         * Encodes the specified ComputeNodeBranch message. Does not implicitly {@link data_room.ComputeNodeBranch.verify|verify} messages.
         * @function encode
         * @memberof data_room.ComputeNodeBranch
         * @static
         * @param {data_room.IComputeNodeBranch} message ComputeNodeBranch message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComputeNodeBranch.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.config);
            if (message.dependencies != null && message.dependencies.length)
                for (let i = 0; i < message.dependencies.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.dependencies[i]);
            if (message.outputFormat != null && Object.hasOwnProperty.call(message, "outputFormat"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.outputFormat);
            if (message.protocol != null && Object.hasOwnProperty.call(message, "protocol"))
                $root.data_room.ComputeNodeProtocol.encode(message.protocol, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.attestationSpecificationId != null && Object.hasOwnProperty.call(message, "attestationSpecificationId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.attestationSpecificationId);
            return writer;
        };

        /**
         * Encodes the specified ComputeNodeBranch message, length delimited. Does not implicitly {@link data_room.ComputeNodeBranch.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.ComputeNodeBranch
         * @static
         * @param {data_room.IComputeNodeBranch} message ComputeNodeBranch message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComputeNodeBranch.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ComputeNodeBranch message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.ComputeNodeBranch
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.ComputeNodeBranch} ComputeNodeBranch
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComputeNodeBranch.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.ComputeNodeBranch();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.config = reader.bytes();
                        break;
                    }
                case 2: {
                        if (!(message.dependencies && message.dependencies.length))
                            message.dependencies = [];
                        message.dependencies.push(reader.string());
                        break;
                    }
                case 3: {
                        message.outputFormat = reader.int32();
                        break;
                    }
                case 4: {
                        message.protocol = $root.data_room.ComputeNodeProtocol.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.attestationSpecificationId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ComputeNodeBranch message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.ComputeNodeBranch
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.ComputeNodeBranch} ComputeNodeBranch
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComputeNodeBranch.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ComputeNodeBranch message.
         * @function verify
         * @memberof data_room.ComputeNodeBranch
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ComputeNodeBranch.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.config != null && message.hasOwnProperty("config"))
                if (!(message.config && typeof message.config.length === "number" || $util.isString(message.config)))
                    return "config: buffer expected";
            if (message.dependencies != null && message.hasOwnProperty("dependencies")) {
                if (!Array.isArray(message.dependencies))
                    return "dependencies: array expected";
                for (let i = 0; i < message.dependencies.length; ++i)
                    if (!$util.isString(message.dependencies[i]))
                        return "dependencies: string[] expected";
            }
            if (message.outputFormat != null && message.hasOwnProperty("outputFormat"))
                switch (message.outputFormat) {
                default:
                    return "outputFormat: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.protocol != null && message.hasOwnProperty("protocol")) {
                let error = $root.data_room.ComputeNodeProtocol.verify(message.protocol);
                if (error)
                    return "protocol." + error;
            }
            if (message.attestationSpecificationId != null && message.hasOwnProperty("attestationSpecificationId"))
                if (!$util.isString(message.attestationSpecificationId))
                    return "attestationSpecificationId: string expected";
            return null;
        };

        /**
         * Creates a ComputeNodeBranch message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.ComputeNodeBranch
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.ComputeNodeBranch} ComputeNodeBranch
         */
        ComputeNodeBranch.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.ComputeNodeBranch)
                return object;
            let message = new $root.data_room.ComputeNodeBranch();
            if (object.config != null)
                if (typeof object.config === "string")
                    $util.base64.decode(object.config, message.config = $util.newBuffer($util.base64.length(object.config)), 0);
                else if (object.config.length >= 0)
                    message.config = object.config;
            if (object.dependencies) {
                if (!Array.isArray(object.dependencies))
                    throw TypeError(".data_room.ComputeNodeBranch.dependencies: array expected");
                message.dependencies = [];
                for (let i = 0; i < object.dependencies.length; ++i)
                    message.dependencies[i] = String(object.dependencies[i]);
            }
            switch (object.outputFormat) {
            default:
                if (typeof object.outputFormat === "number") {
                    message.outputFormat = object.outputFormat;
                    break;
                }
                break;
            case "RAW":
            case 0:
                message.outputFormat = 0;
                break;
            case "ZIP":
            case 1:
                message.outputFormat = 1;
                break;
            }
            if (object.protocol != null) {
                if (typeof object.protocol !== "object")
                    throw TypeError(".data_room.ComputeNodeBranch.protocol: object expected");
                message.protocol = $root.data_room.ComputeNodeProtocol.fromObject(object.protocol);
            }
            if (object.attestationSpecificationId != null)
                message.attestationSpecificationId = String(object.attestationSpecificationId);
            return message;
        };

        /**
         * Creates a plain object from a ComputeNodeBranch message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.ComputeNodeBranch
         * @static
         * @param {data_room.ComputeNodeBranch} message ComputeNodeBranch
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ComputeNodeBranch.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.dependencies = [];
            if (options.defaults) {
                if (options.bytes === String)
                    object.config = "";
                else {
                    object.config = [];
                    if (options.bytes !== Array)
                        object.config = $util.newBuffer(object.config);
                }
                object.outputFormat = options.enums === String ? "RAW" : 0;
                object.protocol = null;
                object.attestationSpecificationId = "";
            }
            if (message.config != null && message.hasOwnProperty("config"))
                object.config = options.bytes === String ? $util.base64.encode(message.config, 0, message.config.length) : options.bytes === Array ? Array.prototype.slice.call(message.config) : message.config;
            if (message.dependencies && message.dependencies.length) {
                object.dependencies = [];
                for (let j = 0; j < message.dependencies.length; ++j)
                    object.dependencies[j] = message.dependencies[j];
            }
            if (message.outputFormat != null && message.hasOwnProperty("outputFormat"))
                object.outputFormat = options.enums === String ? $root.data_room.ComputeNodeFormat[message.outputFormat] === undefined ? message.outputFormat : $root.data_room.ComputeNodeFormat[message.outputFormat] : message.outputFormat;
            if (message.protocol != null && message.hasOwnProperty("protocol"))
                object.protocol = $root.data_room.ComputeNodeProtocol.toObject(message.protocol, options);
            if (message.attestationSpecificationId != null && message.hasOwnProperty("attestationSpecificationId"))
                object.attestationSpecificationId = message.attestationSpecificationId;
            return object;
        };

        /**
         * Converts this ComputeNodeBranch to JSON.
         * @function toJSON
         * @memberof data_room.ComputeNodeBranch
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ComputeNodeBranch.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ComputeNodeBranch
         * @function getTypeUrl
         * @memberof data_room.ComputeNodeBranch
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ComputeNodeBranch.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.ComputeNodeBranch";
        };

        return ComputeNodeBranch;
    })();

    data_room.UserPermission = (function() {

        /**
         * Properties of a UserPermission.
         * @memberof data_room
         * @interface IUserPermission
         * @property {string|null} [email] UserPermission email
         * @property {Array.<data_room.IPermission>|null} [permissions] UserPermission permissions
         * @property {string|null} [authenticationMethodId] UserPermission authenticationMethodId
         */

        /**
         * Constructs a new UserPermission.
         * @memberof data_room
         * @classdesc Represents a UserPermission.
         * @implements IUserPermission
         * @constructor
         * @param {data_room.IUserPermission=} [properties] Properties to set
         */
        function UserPermission(properties) {
            this.permissions = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserPermission email.
         * @member {string} email
         * @memberof data_room.UserPermission
         * @instance
         */
        UserPermission.prototype.email = "";

        /**
         * UserPermission permissions.
         * @member {Array.<data_room.IPermission>} permissions
         * @memberof data_room.UserPermission
         * @instance
         */
        UserPermission.prototype.permissions = $util.emptyArray;

        /**
         * UserPermission authenticationMethodId.
         * @member {string} authenticationMethodId
         * @memberof data_room.UserPermission
         * @instance
         */
        UserPermission.prototype.authenticationMethodId = "";

        /**
         * Creates a new UserPermission instance using the specified properties.
         * @function create
         * @memberof data_room.UserPermission
         * @static
         * @param {data_room.IUserPermission=} [properties] Properties to set
         * @returns {data_room.UserPermission} UserPermission instance
         */
        UserPermission.create = function create(properties) {
            return new UserPermission(properties);
        };

        /**
         * Encodes the specified UserPermission message. Does not implicitly {@link data_room.UserPermission.verify|verify} messages.
         * @function encode
         * @memberof data_room.UserPermission
         * @static
         * @param {data_room.IUserPermission} message UserPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserPermission.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
            if (message.permissions != null && message.permissions.length)
                for (let i = 0; i < message.permissions.length; ++i)
                    $root.data_room.Permission.encode(message.permissions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.authenticationMethodId != null && Object.hasOwnProperty.call(message, "authenticationMethodId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.authenticationMethodId);
            return writer;
        };

        /**
         * Encodes the specified UserPermission message, length delimited. Does not implicitly {@link data_room.UserPermission.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.UserPermission
         * @static
         * @param {data_room.IUserPermission} message UserPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserPermission.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserPermission message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.UserPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.UserPermission} UserPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserPermission.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.UserPermission();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.email = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.permissions && message.permissions.length))
                            message.permissions = [];
                        message.permissions.push($root.data_room.Permission.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.authenticationMethodId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserPermission message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.UserPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.UserPermission} UserPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserPermission.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserPermission message.
         * @function verify
         * @memberof data_room.UserPermission
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserPermission.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            if (message.permissions != null && message.hasOwnProperty("permissions")) {
                if (!Array.isArray(message.permissions))
                    return "permissions: array expected";
                for (let i = 0; i < message.permissions.length; ++i) {
                    let error = $root.data_room.Permission.verify(message.permissions[i]);
                    if (error)
                        return "permissions." + error;
                }
            }
            if (message.authenticationMethodId != null && message.hasOwnProperty("authenticationMethodId"))
                if (!$util.isString(message.authenticationMethodId))
                    return "authenticationMethodId: string expected";
            return null;
        };

        /**
         * Creates a UserPermission message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.UserPermission
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.UserPermission} UserPermission
         */
        UserPermission.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.UserPermission)
                return object;
            let message = new $root.data_room.UserPermission();
            if (object.email != null)
                message.email = String(object.email);
            if (object.permissions) {
                if (!Array.isArray(object.permissions))
                    throw TypeError(".data_room.UserPermission.permissions: array expected");
                message.permissions = [];
                for (let i = 0; i < object.permissions.length; ++i) {
                    if (typeof object.permissions[i] !== "object")
                        throw TypeError(".data_room.UserPermission.permissions: object expected");
                    message.permissions[i] = $root.data_room.Permission.fromObject(object.permissions[i]);
                }
            }
            if (object.authenticationMethodId != null)
                message.authenticationMethodId = String(object.authenticationMethodId);
            return message;
        };

        /**
         * Creates a plain object from a UserPermission message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.UserPermission
         * @static
         * @param {data_room.UserPermission} message UserPermission
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserPermission.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.permissions = [];
            if (options.defaults) {
                object.email = "";
                object.authenticationMethodId = "";
            }
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.permissions && message.permissions.length) {
                object.permissions = [];
                for (let j = 0; j < message.permissions.length; ++j)
                    object.permissions[j] = $root.data_room.Permission.toObject(message.permissions[j], options);
            }
            if (message.authenticationMethodId != null && message.hasOwnProperty("authenticationMethodId"))
                object.authenticationMethodId = message.authenticationMethodId;
            return object;
        };

        /**
         * Converts this UserPermission to JSON.
         * @function toJSON
         * @memberof data_room.UserPermission
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserPermission.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserPermission
         * @function getTypeUrl
         * @memberof data_room.UserPermission
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserPermission.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.UserPermission";
        };

        return UserPermission;
    })();

    data_room.AuthenticationMethod = (function() {

        /**
         * Properties of an AuthenticationMethod.
         * @memberof data_room
         * @interface IAuthenticationMethod
         * @property {data_room.IPkiPolicy|null} [personalPki] AuthenticationMethod personalPki
         * @property {data_room.IPkiPolicy|null} [dqPki] AuthenticationMethod dqPki
         * @property {data_room.IDcrSecretPolicy|null} [dcrSecret] AuthenticationMethod dcrSecret
         */

        /**
         * Constructs a new AuthenticationMethod.
         * @memberof data_room
         * @classdesc Represents an AuthenticationMethod.
         * @implements IAuthenticationMethod
         * @constructor
         * @param {data_room.IAuthenticationMethod=} [properties] Properties to set
         */
        function AuthenticationMethod(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AuthenticationMethod personalPki.
         * @member {data_room.IPkiPolicy|null|undefined} personalPki
         * @memberof data_room.AuthenticationMethod
         * @instance
         */
        AuthenticationMethod.prototype.personalPki = null;

        /**
         * AuthenticationMethod dqPki.
         * @member {data_room.IPkiPolicy|null|undefined} dqPki
         * @memberof data_room.AuthenticationMethod
         * @instance
         */
        AuthenticationMethod.prototype.dqPki = null;

        /**
         * AuthenticationMethod dcrSecret.
         * @member {data_room.IDcrSecretPolicy|null|undefined} dcrSecret
         * @memberof data_room.AuthenticationMethod
         * @instance
         */
        AuthenticationMethod.prototype.dcrSecret = null;

        /**
         * Creates a new AuthenticationMethod instance using the specified properties.
         * @function create
         * @memberof data_room.AuthenticationMethod
         * @static
         * @param {data_room.IAuthenticationMethod=} [properties] Properties to set
         * @returns {data_room.AuthenticationMethod} AuthenticationMethod instance
         */
        AuthenticationMethod.create = function create(properties) {
            return new AuthenticationMethod(properties);
        };

        /**
         * Encodes the specified AuthenticationMethod message. Does not implicitly {@link data_room.AuthenticationMethod.verify|verify} messages.
         * @function encode
         * @memberof data_room.AuthenticationMethod
         * @static
         * @param {data_room.IAuthenticationMethod} message AuthenticationMethod message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticationMethod.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.personalPki != null && Object.hasOwnProperty.call(message, "personalPki"))
                $root.data_room.PkiPolicy.encode(message.personalPki, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.dqPki != null && Object.hasOwnProperty.call(message, "dqPki"))
                $root.data_room.PkiPolicy.encode(message.dqPki, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.dcrSecret != null && Object.hasOwnProperty.call(message, "dcrSecret"))
                $root.data_room.DcrSecretPolicy.encode(message.dcrSecret, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AuthenticationMethod message, length delimited. Does not implicitly {@link data_room.AuthenticationMethod.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.AuthenticationMethod
         * @static
         * @param {data_room.IAuthenticationMethod} message AuthenticationMethod message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticationMethod.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AuthenticationMethod message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.AuthenticationMethod
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.AuthenticationMethod} AuthenticationMethod
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticationMethod.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.AuthenticationMethod();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.personalPki = $root.data_room.PkiPolicy.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.dqPki = $root.data_room.PkiPolicy.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.dcrSecret = $root.data_room.DcrSecretPolicy.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AuthenticationMethod message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.AuthenticationMethod
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.AuthenticationMethod} AuthenticationMethod
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticationMethod.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AuthenticationMethod message.
         * @function verify
         * @memberof data_room.AuthenticationMethod
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AuthenticationMethod.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.personalPki != null && message.hasOwnProperty("personalPki")) {
                let error = $root.data_room.PkiPolicy.verify(message.personalPki);
                if (error)
                    return "personalPki." + error;
            }
            if (message.dqPki != null && message.hasOwnProperty("dqPki")) {
                let error = $root.data_room.PkiPolicy.verify(message.dqPki);
                if (error)
                    return "dqPki." + error;
            }
            if (message.dcrSecret != null && message.hasOwnProperty("dcrSecret")) {
                let error = $root.data_room.DcrSecretPolicy.verify(message.dcrSecret);
                if (error)
                    return "dcrSecret." + error;
            }
            return null;
        };

        /**
         * Creates an AuthenticationMethod message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.AuthenticationMethod
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.AuthenticationMethod} AuthenticationMethod
         */
        AuthenticationMethod.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.AuthenticationMethod)
                return object;
            let message = new $root.data_room.AuthenticationMethod();
            if (object.personalPki != null) {
                if (typeof object.personalPki !== "object")
                    throw TypeError(".data_room.AuthenticationMethod.personalPki: object expected");
                message.personalPki = $root.data_room.PkiPolicy.fromObject(object.personalPki);
            }
            if (object.dqPki != null) {
                if (typeof object.dqPki !== "object")
                    throw TypeError(".data_room.AuthenticationMethod.dqPki: object expected");
                message.dqPki = $root.data_room.PkiPolicy.fromObject(object.dqPki);
            }
            if (object.dcrSecret != null) {
                if (typeof object.dcrSecret !== "object")
                    throw TypeError(".data_room.AuthenticationMethod.dcrSecret: object expected");
                message.dcrSecret = $root.data_room.DcrSecretPolicy.fromObject(object.dcrSecret);
            }
            return message;
        };

        /**
         * Creates a plain object from an AuthenticationMethod message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.AuthenticationMethod
         * @static
         * @param {data_room.AuthenticationMethod} message AuthenticationMethod
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuthenticationMethod.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.personalPki = null;
                object.dqPki = null;
                object.dcrSecret = null;
            }
            if (message.personalPki != null && message.hasOwnProperty("personalPki"))
                object.personalPki = $root.data_room.PkiPolicy.toObject(message.personalPki, options);
            if (message.dqPki != null && message.hasOwnProperty("dqPki"))
                object.dqPki = $root.data_room.PkiPolicy.toObject(message.dqPki, options);
            if (message.dcrSecret != null && message.hasOwnProperty("dcrSecret"))
                object.dcrSecret = $root.data_room.DcrSecretPolicy.toObject(message.dcrSecret, options);
            return object;
        };

        /**
         * Converts this AuthenticationMethod to JSON.
         * @function toJSON
         * @memberof data_room.AuthenticationMethod
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuthenticationMethod.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AuthenticationMethod
         * @function getTypeUrl
         * @memberof data_room.AuthenticationMethod
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AuthenticationMethod.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.AuthenticationMethod";
        };

        return AuthenticationMethod;
    })();

    data_room.PkiPolicy = (function() {

        /**
         * Properties of a PkiPolicy.
         * @memberof data_room
         * @interface IPkiPolicy
         * @property {Uint8Array|null} [rootCertificatePem] PkiPolicy rootCertificatePem
         */

        /**
         * Constructs a new PkiPolicy.
         * @memberof data_room
         * @classdesc Represents a PkiPolicy.
         * @implements IPkiPolicy
         * @constructor
         * @param {data_room.IPkiPolicy=} [properties] Properties to set
         */
        function PkiPolicy(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PkiPolicy rootCertificatePem.
         * @member {Uint8Array} rootCertificatePem
         * @memberof data_room.PkiPolicy
         * @instance
         */
        PkiPolicy.prototype.rootCertificatePem = $util.newBuffer([]);

        /**
         * Creates a new PkiPolicy instance using the specified properties.
         * @function create
         * @memberof data_room.PkiPolicy
         * @static
         * @param {data_room.IPkiPolicy=} [properties] Properties to set
         * @returns {data_room.PkiPolicy} PkiPolicy instance
         */
        PkiPolicy.create = function create(properties) {
            return new PkiPolicy(properties);
        };

        /**
         * Encodes the specified PkiPolicy message. Does not implicitly {@link data_room.PkiPolicy.verify|verify} messages.
         * @function encode
         * @memberof data_room.PkiPolicy
         * @static
         * @param {data_room.IPkiPolicy} message PkiPolicy message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PkiPolicy.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rootCertificatePem != null && Object.hasOwnProperty.call(message, "rootCertificatePem"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.rootCertificatePem);
            return writer;
        };

        /**
         * Encodes the specified PkiPolicy message, length delimited. Does not implicitly {@link data_room.PkiPolicy.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.PkiPolicy
         * @static
         * @param {data_room.IPkiPolicy} message PkiPolicy message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PkiPolicy.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PkiPolicy message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.PkiPolicy
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.PkiPolicy} PkiPolicy
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PkiPolicy.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.PkiPolicy();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rootCertificatePem = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PkiPolicy message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.PkiPolicy
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.PkiPolicy} PkiPolicy
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PkiPolicy.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PkiPolicy message.
         * @function verify
         * @memberof data_room.PkiPolicy
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PkiPolicy.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rootCertificatePem != null && message.hasOwnProperty("rootCertificatePem"))
                if (!(message.rootCertificatePem && typeof message.rootCertificatePem.length === "number" || $util.isString(message.rootCertificatePem)))
                    return "rootCertificatePem: buffer expected";
            return null;
        };

        /**
         * Creates a PkiPolicy message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.PkiPolicy
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.PkiPolicy} PkiPolicy
         */
        PkiPolicy.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.PkiPolicy)
                return object;
            let message = new $root.data_room.PkiPolicy();
            if (object.rootCertificatePem != null)
                if (typeof object.rootCertificatePem === "string")
                    $util.base64.decode(object.rootCertificatePem, message.rootCertificatePem = $util.newBuffer($util.base64.length(object.rootCertificatePem)), 0);
                else if (object.rootCertificatePem.length >= 0)
                    message.rootCertificatePem = object.rootCertificatePem;
            return message;
        };

        /**
         * Creates a plain object from a PkiPolicy message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.PkiPolicy
         * @static
         * @param {data_room.PkiPolicy} message PkiPolicy
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PkiPolicy.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.rootCertificatePem = "";
                else {
                    object.rootCertificatePem = [];
                    if (options.bytes !== Array)
                        object.rootCertificatePem = $util.newBuffer(object.rootCertificatePem);
                }
            if (message.rootCertificatePem != null && message.hasOwnProperty("rootCertificatePem"))
                object.rootCertificatePem = options.bytes === String ? $util.base64.encode(message.rootCertificatePem, 0, message.rootCertificatePem.length) : options.bytes === Array ? Array.prototype.slice.call(message.rootCertificatePem) : message.rootCertificatePem;
            return object;
        };

        /**
         * Converts this PkiPolicy to JSON.
         * @function toJSON
         * @memberof data_room.PkiPolicy
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PkiPolicy.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PkiPolicy
         * @function getTypeUrl
         * @memberof data_room.PkiPolicy
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PkiPolicy.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.PkiPolicy";
        };

        return PkiPolicy;
    })();

    data_room.DcrSecretPolicy = (function() {

        /**
         * Properties of a DcrSecretPolicy.
         * @memberof data_room
         * @interface IDcrSecretPolicy
         * @property {Uint8Array|null} [dcrSecretId] DcrSecretPolicy dcrSecretId
         */

        /**
         * Constructs a new DcrSecretPolicy.
         * @memberof data_room
         * @classdesc Represents a DcrSecretPolicy.
         * @implements IDcrSecretPolicy
         * @constructor
         * @param {data_room.IDcrSecretPolicy=} [properties] Properties to set
         */
        function DcrSecretPolicy(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DcrSecretPolicy dcrSecretId.
         * @member {Uint8Array} dcrSecretId
         * @memberof data_room.DcrSecretPolicy
         * @instance
         */
        DcrSecretPolicy.prototype.dcrSecretId = $util.newBuffer([]);

        /**
         * Creates a new DcrSecretPolicy instance using the specified properties.
         * @function create
         * @memberof data_room.DcrSecretPolicy
         * @static
         * @param {data_room.IDcrSecretPolicy=} [properties] Properties to set
         * @returns {data_room.DcrSecretPolicy} DcrSecretPolicy instance
         */
        DcrSecretPolicy.create = function create(properties) {
            return new DcrSecretPolicy(properties);
        };

        /**
         * Encodes the specified DcrSecretPolicy message. Does not implicitly {@link data_room.DcrSecretPolicy.verify|verify} messages.
         * @function encode
         * @memberof data_room.DcrSecretPolicy
         * @static
         * @param {data_room.IDcrSecretPolicy} message DcrSecretPolicy message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrSecretPolicy.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dcrSecretId != null && Object.hasOwnProperty.call(message, "dcrSecretId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dcrSecretId);
            return writer;
        };

        /**
         * Encodes the specified DcrSecretPolicy message, length delimited. Does not implicitly {@link data_room.DcrSecretPolicy.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.DcrSecretPolicy
         * @static
         * @param {data_room.IDcrSecretPolicy} message DcrSecretPolicy message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrSecretPolicy.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DcrSecretPolicy message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.DcrSecretPolicy
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.DcrSecretPolicy} DcrSecretPolicy
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrSecretPolicy.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.DcrSecretPolicy();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dcrSecretId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DcrSecretPolicy message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.DcrSecretPolicy
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.DcrSecretPolicy} DcrSecretPolicy
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrSecretPolicy.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DcrSecretPolicy message.
         * @function verify
         * @memberof data_room.DcrSecretPolicy
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DcrSecretPolicy.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dcrSecretId != null && message.hasOwnProperty("dcrSecretId"))
                if (!(message.dcrSecretId && typeof message.dcrSecretId.length === "number" || $util.isString(message.dcrSecretId)))
                    return "dcrSecretId: buffer expected";
            return null;
        };

        /**
         * Creates a DcrSecretPolicy message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.DcrSecretPolicy
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.DcrSecretPolicy} DcrSecretPolicy
         */
        DcrSecretPolicy.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.DcrSecretPolicy)
                return object;
            let message = new $root.data_room.DcrSecretPolicy();
            if (object.dcrSecretId != null)
                if (typeof object.dcrSecretId === "string")
                    $util.base64.decode(object.dcrSecretId, message.dcrSecretId = $util.newBuffer($util.base64.length(object.dcrSecretId)), 0);
                else if (object.dcrSecretId.length >= 0)
                    message.dcrSecretId = object.dcrSecretId;
            return message;
        };

        /**
         * Creates a plain object from a DcrSecretPolicy message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.DcrSecretPolicy
         * @static
         * @param {data_room.DcrSecretPolicy} message DcrSecretPolicy
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DcrSecretPolicy.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.dcrSecretId = "";
                else {
                    object.dcrSecretId = [];
                    if (options.bytes !== Array)
                        object.dcrSecretId = $util.newBuffer(object.dcrSecretId);
                }
            if (message.dcrSecretId != null && message.hasOwnProperty("dcrSecretId"))
                object.dcrSecretId = options.bytes === String ? $util.base64.encode(message.dcrSecretId, 0, message.dcrSecretId.length) : options.bytes === Array ? Array.prototype.slice.call(message.dcrSecretId) : message.dcrSecretId;
            return object;
        };

        /**
         * Converts this DcrSecretPolicy to JSON.
         * @function toJSON
         * @memberof data_room.DcrSecretPolicy
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DcrSecretPolicy.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DcrSecretPolicy
         * @function getTypeUrl
         * @memberof data_room.DcrSecretPolicy
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DcrSecretPolicy.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.DcrSecretPolicy";
        };

        return DcrSecretPolicy;
    })();

    data_room.Permission = (function() {

        /**
         * Properties of a Permission.
         * @memberof data_room
         * @interface IPermission
         * @property {data_room.IExecuteComputePermission|null} [executeComputePermission] Permission executeComputePermission
         * @property {data_room.ILeafCrudPermission|null} [leafCrudPermission] Permission leafCrudPermission
         * @property {data_room.IRetrieveDataRoomPermission|null} [retrieveDataRoomPermission] Permission retrieveDataRoomPermission
         * @property {data_room.IRetrieveAuditLogPermission|null} [retrieveAuditLogPermission] Permission retrieveAuditLogPermission
         * @property {data_room.IRetrieveDataRoomStatusPermission|null} [retrieveDataRoomStatusPermission] Permission retrieveDataRoomStatusPermission
         * @property {data_room.IUpdateDataRoomStatusPermission|null} [updateDataRoomStatusPermission] Permission updateDataRoomStatusPermission
         * @property {data_room.IRetrievePublishedDatasetsPermission|null} [retrievePublishedDatasetsPermission] Permission retrievePublishedDatasetsPermission
         * @property {data_room.IDryRunPermission|null} [dryRunPermission] Permission dryRunPermission
         * @property {data_room.IGenerateMergeSignaturePermission|null} [generateMergeSignaturePermission] Permission generateMergeSignaturePermission
         * @property {data_room.IExecuteDevelopmentComputePermission|null} [executeDevelopmentComputePermission] Permission executeDevelopmentComputePermission
         * @property {data_room.IMergeConfigurationCommitPermission|null} [mergeConfigurationCommitPermission] Permission mergeConfigurationCommitPermission
         * @property {data_room.IRetrieveComputeResultPermission|null} [retrieveComputeResultPermission] Permission retrieveComputeResultPermission
         * @property {data_room.ICasAuxiliaryStatePermission|null} [casAuxiliaryStatePermission] Permission casAuxiliaryStatePermission
         * @property {data_room.IReadAuxiliaryStatePermission|null} [readAuxiliaryStatePermission] Permission readAuxiliaryStatePermission
         */

        /**
         * Constructs a new Permission.
         * @memberof data_room
         * @classdesc Represents a Permission.
         * @implements IPermission
         * @constructor
         * @param {data_room.IPermission=} [properties] Properties to set
         */
        function Permission(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Permission executeComputePermission.
         * @member {data_room.IExecuteComputePermission|null|undefined} executeComputePermission
         * @memberof data_room.Permission
         * @instance
         */
        Permission.prototype.executeComputePermission = null;

        /**
         * Permission leafCrudPermission.
         * @member {data_room.ILeafCrudPermission|null|undefined} leafCrudPermission
         * @memberof data_room.Permission
         * @instance
         */
        Permission.prototype.leafCrudPermission = null;

        /**
         * Permission retrieveDataRoomPermission.
         * @member {data_room.IRetrieveDataRoomPermission|null|undefined} retrieveDataRoomPermission
         * @memberof data_room.Permission
         * @instance
         */
        Permission.prototype.retrieveDataRoomPermission = null;

        /**
         * Permission retrieveAuditLogPermission.
         * @member {data_room.IRetrieveAuditLogPermission|null|undefined} retrieveAuditLogPermission
         * @memberof data_room.Permission
         * @instance
         */
        Permission.prototype.retrieveAuditLogPermission = null;

        /**
         * Permission retrieveDataRoomStatusPermission.
         * @member {data_room.IRetrieveDataRoomStatusPermission|null|undefined} retrieveDataRoomStatusPermission
         * @memberof data_room.Permission
         * @instance
         */
        Permission.prototype.retrieveDataRoomStatusPermission = null;

        /**
         * Permission updateDataRoomStatusPermission.
         * @member {data_room.IUpdateDataRoomStatusPermission|null|undefined} updateDataRoomStatusPermission
         * @memberof data_room.Permission
         * @instance
         */
        Permission.prototype.updateDataRoomStatusPermission = null;

        /**
         * Permission retrievePublishedDatasetsPermission.
         * @member {data_room.IRetrievePublishedDatasetsPermission|null|undefined} retrievePublishedDatasetsPermission
         * @memberof data_room.Permission
         * @instance
         */
        Permission.prototype.retrievePublishedDatasetsPermission = null;

        /**
         * Permission dryRunPermission.
         * @member {data_room.IDryRunPermission|null|undefined} dryRunPermission
         * @memberof data_room.Permission
         * @instance
         */
        Permission.prototype.dryRunPermission = null;

        /**
         * Permission generateMergeSignaturePermission.
         * @member {data_room.IGenerateMergeSignaturePermission|null|undefined} generateMergeSignaturePermission
         * @memberof data_room.Permission
         * @instance
         */
        Permission.prototype.generateMergeSignaturePermission = null;

        /**
         * Permission executeDevelopmentComputePermission.
         * @member {data_room.IExecuteDevelopmentComputePermission|null|undefined} executeDevelopmentComputePermission
         * @memberof data_room.Permission
         * @instance
         */
        Permission.prototype.executeDevelopmentComputePermission = null;

        /**
         * Permission mergeConfigurationCommitPermission.
         * @member {data_room.IMergeConfigurationCommitPermission|null|undefined} mergeConfigurationCommitPermission
         * @memberof data_room.Permission
         * @instance
         */
        Permission.prototype.mergeConfigurationCommitPermission = null;

        /**
         * Permission retrieveComputeResultPermission.
         * @member {data_room.IRetrieveComputeResultPermission|null|undefined} retrieveComputeResultPermission
         * @memberof data_room.Permission
         * @instance
         */
        Permission.prototype.retrieveComputeResultPermission = null;

        /**
         * Permission casAuxiliaryStatePermission.
         * @member {data_room.ICasAuxiliaryStatePermission|null|undefined} casAuxiliaryStatePermission
         * @memberof data_room.Permission
         * @instance
         */
        Permission.prototype.casAuxiliaryStatePermission = null;

        /**
         * Permission readAuxiliaryStatePermission.
         * @member {data_room.IReadAuxiliaryStatePermission|null|undefined} readAuxiliaryStatePermission
         * @memberof data_room.Permission
         * @instance
         */
        Permission.prototype.readAuxiliaryStatePermission = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Permission permission.
         * @member {"executeComputePermission"|"leafCrudPermission"|"retrieveDataRoomPermission"|"retrieveAuditLogPermission"|"retrieveDataRoomStatusPermission"|"updateDataRoomStatusPermission"|"retrievePublishedDatasetsPermission"|"dryRunPermission"|"generateMergeSignaturePermission"|"executeDevelopmentComputePermission"|"mergeConfigurationCommitPermission"|"retrieveComputeResultPermission"|"casAuxiliaryStatePermission"|"readAuxiliaryStatePermission"|undefined} permission
         * @memberof data_room.Permission
         * @instance
         */
        Object.defineProperty(Permission.prototype, "permission", {
            get: $util.oneOfGetter($oneOfFields = ["executeComputePermission", "leafCrudPermission", "retrieveDataRoomPermission", "retrieveAuditLogPermission", "retrieveDataRoomStatusPermission", "updateDataRoomStatusPermission", "retrievePublishedDatasetsPermission", "dryRunPermission", "generateMergeSignaturePermission", "executeDevelopmentComputePermission", "mergeConfigurationCommitPermission", "retrieveComputeResultPermission", "casAuxiliaryStatePermission", "readAuxiliaryStatePermission"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Permission instance using the specified properties.
         * @function create
         * @memberof data_room.Permission
         * @static
         * @param {data_room.IPermission=} [properties] Properties to set
         * @returns {data_room.Permission} Permission instance
         */
        Permission.create = function create(properties) {
            return new Permission(properties);
        };

        /**
         * Encodes the specified Permission message. Does not implicitly {@link data_room.Permission.verify|verify} messages.
         * @function encode
         * @memberof data_room.Permission
         * @static
         * @param {data_room.IPermission} message Permission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Permission.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.executeComputePermission != null && Object.hasOwnProperty.call(message, "executeComputePermission"))
                $root.data_room.ExecuteComputePermission.encode(message.executeComputePermission, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.leafCrudPermission != null && Object.hasOwnProperty.call(message, "leafCrudPermission"))
                $root.data_room.LeafCrudPermission.encode(message.leafCrudPermission, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.retrieveDataRoomPermission != null && Object.hasOwnProperty.call(message, "retrieveDataRoomPermission"))
                $root.data_room.RetrieveDataRoomPermission.encode(message.retrieveDataRoomPermission, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.retrieveAuditLogPermission != null && Object.hasOwnProperty.call(message, "retrieveAuditLogPermission"))
                $root.data_room.RetrieveAuditLogPermission.encode(message.retrieveAuditLogPermission, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.retrieveDataRoomStatusPermission != null && Object.hasOwnProperty.call(message, "retrieveDataRoomStatusPermission"))
                $root.data_room.RetrieveDataRoomStatusPermission.encode(message.retrieveDataRoomStatusPermission, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.updateDataRoomStatusPermission != null && Object.hasOwnProperty.call(message, "updateDataRoomStatusPermission"))
                $root.data_room.UpdateDataRoomStatusPermission.encode(message.updateDataRoomStatusPermission, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.retrievePublishedDatasetsPermission != null && Object.hasOwnProperty.call(message, "retrievePublishedDatasetsPermission"))
                $root.data_room.RetrievePublishedDatasetsPermission.encode(message.retrievePublishedDatasetsPermission, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.dryRunPermission != null && Object.hasOwnProperty.call(message, "dryRunPermission"))
                $root.data_room.DryRunPermission.encode(message.dryRunPermission, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.generateMergeSignaturePermission != null && Object.hasOwnProperty.call(message, "generateMergeSignaturePermission"))
                $root.data_room.GenerateMergeSignaturePermission.encode(message.generateMergeSignaturePermission, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.executeDevelopmentComputePermission != null && Object.hasOwnProperty.call(message, "executeDevelopmentComputePermission"))
                $root.data_room.ExecuteDevelopmentComputePermission.encode(message.executeDevelopmentComputePermission, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.mergeConfigurationCommitPermission != null && Object.hasOwnProperty.call(message, "mergeConfigurationCommitPermission"))
                $root.data_room.MergeConfigurationCommitPermission.encode(message.mergeConfigurationCommitPermission, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.retrieveComputeResultPermission != null && Object.hasOwnProperty.call(message, "retrieveComputeResultPermission"))
                $root.data_room.RetrieveComputeResultPermission.encode(message.retrieveComputeResultPermission, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.casAuxiliaryStatePermission != null && Object.hasOwnProperty.call(message, "casAuxiliaryStatePermission"))
                $root.data_room.CasAuxiliaryStatePermission.encode(message.casAuxiliaryStatePermission, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.readAuxiliaryStatePermission != null && Object.hasOwnProperty.call(message, "readAuxiliaryStatePermission"))
                $root.data_room.ReadAuxiliaryStatePermission.encode(message.readAuxiliaryStatePermission, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Permission message, length delimited. Does not implicitly {@link data_room.Permission.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.Permission
         * @static
         * @param {data_room.IPermission} message Permission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Permission.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Permission message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.Permission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.Permission} Permission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Permission.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.Permission();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.executeComputePermission = $root.data_room.ExecuteComputePermission.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.leafCrudPermission = $root.data_room.LeafCrudPermission.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.retrieveDataRoomPermission = $root.data_room.RetrieveDataRoomPermission.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.retrieveAuditLogPermission = $root.data_room.RetrieveAuditLogPermission.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.retrieveDataRoomStatusPermission = $root.data_room.RetrieveDataRoomStatusPermission.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.updateDataRoomStatusPermission = $root.data_room.UpdateDataRoomStatusPermission.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.retrievePublishedDatasetsPermission = $root.data_room.RetrievePublishedDatasetsPermission.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.dryRunPermission = $root.data_room.DryRunPermission.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.generateMergeSignaturePermission = $root.data_room.GenerateMergeSignaturePermission.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.executeDevelopmentComputePermission = $root.data_room.ExecuteDevelopmentComputePermission.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.mergeConfigurationCommitPermission = $root.data_room.MergeConfigurationCommitPermission.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.retrieveComputeResultPermission = $root.data_room.RetrieveComputeResultPermission.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.casAuxiliaryStatePermission = $root.data_room.CasAuxiliaryStatePermission.decode(reader, reader.uint32());
                        break;
                    }
                case 14: {
                        message.readAuxiliaryStatePermission = $root.data_room.ReadAuxiliaryStatePermission.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Permission message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.Permission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.Permission} Permission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Permission.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Permission message.
         * @function verify
         * @memberof data_room.Permission
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Permission.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.executeComputePermission != null && message.hasOwnProperty("executeComputePermission")) {
                properties.permission = 1;
                {
                    let error = $root.data_room.ExecuteComputePermission.verify(message.executeComputePermission);
                    if (error)
                        return "executeComputePermission." + error;
                }
            }
            if (message.leafCrudPermission != null && message.hasOwnProperty("leafCrudPermission")) {
                if (properties.permission === 1)
                    return "permission: multiple values";
                properties.permission = 1;
                {
                    let error = $root.data_room.LeafCrudPermission.verify(message.leafCrudPermission);
                    if (error)
                        return "leafCrudPermission." + error;
                }
            }
            if (message.retrieveDataRoomPermission != null && message.hasOwnProperty("retrieveDataRoomPermission")) {
                if (properties.permission === 1)
                    return "permission: multiple values";
                properties.permission = 1;
                {
                    let error = $root.data_room.RetrieveDataRoomPermission.verify(message.retrieveDataRoomPermission);
                    if (error)
                        return "retrieveDataRoomPermission." + error;
                }
            }
            if (message.retrieveAuditLogPermission != null && message.hasOwnProperty("retrieveAuditLogPermission")) {
                if (properties.permission === 1)
                    return "permission: multiple values";
                properties.permission = 1;
                {
                    let error = $root.data_room.RetrieveAuditLogPermission.verify(message.retrieveAuditLogPermission);
                    if (error)
                        return "retrieveAuditLogPermission." + error;
                }
            }
            if (message.retrieveDataRoomStatusPermission != null && message.hasOwnProperty("retrieveDataRoomStatusPermission")) {
                if (properties.permission === 1)
                    return "permission: multiple values";
                properties.permission = 1;
                {
                    let error = $root.data_room.RetrieveDataRoomStatusPermission.verify(message.retrieveDataRoomStatusPermission);
                    if (error)
                        return "retrieveDataRoomStatusPermission." + error;
                }
            }
            if (message.updateDataRoomStatusPermission != null && message.hasOwnProperty("updateDataRoomStatusPermission")) {
                if (properties.permission === 1)
                    return "permission: multiple values";
                properties.permission = 1;
                {
                    let error = $root.data_room.UpdateDataRoomStatusPermission.verify(message.updateDataRoomStatusPermission);
                    if (error)
                        return "updateDataRoomStatusPermission." + error;
                }
            }
            if (message.retrievePublishedDatasetsPermission != null && message.hasOwnProperty("retrievePublishedDatasetsPermission")) {
                if (properties.permission === 1)
                    return "permission: multiple values";
                properties.permission = 1;
                {
                    let error = $root.data_room.RetrievePublishedDatasetsPermission.verify(message.retrievePublishedDatasetsPermission);
                    if (error)
                        return "retrievePublishedDatasetsPermission." + error;
                }
            }
            if (message.dryRunPermission != null && message.hasOwnProperty("dryRunPermission")) {
                if (properties.permission === 1)
                    return "permission: multiple values";
                properties.permission = 1;
                {
                    let error = $root.data_room.DryRunPermission.verify(message.dryRunPermission);
                    if (error)
                        return "dryRunPermission." + error;
                }
            }
            if (message.generateMergeSignaturePermission != null && message.hasOwnProperty("generateMergeSignaturePermission")) {
                if (properties.permission === 1)
                    return "permission: multiple values";
                properties.permission = 1;
                {
                    let error = $root.data_room.GenerateMergeSignaturePermission.verify(message.generateMergeSignaturePermission);
                    if (error)
                        return "generateMergeSignaturePermission." + error;
                }
            }
            if (message.executeDevelopmentComputePermission != null && message.hasOwnProperty("executeDevelopmentComputePermission")) {
                if (properties.permission === 1)
                    return "permission: multiple values";
                properties.permission = 1;
                {
                    let error = $root.data_room.ExecuteDevelopmentComputePermission.verify(message.executeDevelopmentComputePermission);
                    if (error)
                        return "executeDevelopmentComputePermission." + error;
                }
            }
            if (message.mergeConfigurationCommitPermission != null && message.hasOwnProperty("mergeConfigurationCommitPermission")) {
                if (properties.permission === 1)
                    return "permission: multiple values";
                properties.permission = 1;
                {
                    let error = $root.data_room.MergeConfigurationCommitPermission.verify(message.mergeConfigurationCommitPermission);
                    if (error)
                        return "mergeConfigurationCommitPermission." + error;
                }
            }
            if (message.retrieveComputeResultPermission != null && message.hasOwnProperty("retrieveComputeResultPermission")) {
                if (properties.permission === 1)
                    return "permission: multiple values";
                properties.permission = 1;
                {
                    let error = $root.data_room.RetrieveComputeResultPermission.verify(message.retrieveComputeResultPermission);
                    if (error)
                        return "retrieveComputeResultPermission." + error;
                }
            }
            if (message.casAuxiliaryStatePermission != null && message.hasOwnProperty("casAuxiliaryStatePermission")) {
                if (properties.permission === 1)
                    return "permission: multiple values";
                properties.permission = 1;
                {
                    let error = $root.data_room.CasAuxiliaryStatePermission.verify(message.casAuxiliaryStatePermission);
                    if (error)
                        return "casAuxiliaryStatePermission." + error;
                }
            }
            if (message.readAuxiliaryStatePermission != null && message.hasOwnProperty("readAuxiliaryStatePermission")) {
                if (properties.permission === 1)
                    return "permission: multiple values";
                properties.permission = 1;
                {
                    let error = $root.data_room.ReadAuxiliaryStatePermission.verify(message.readAuxiliaryStatePermission);
                    if (error)
                        return "readAuxiliaryStatePermission." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Permission message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.Permission
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.Permission} Permission
         */
        Permission.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.Permission)
                return object;
            let message = new $root.data_room.Permission();
            if (object.executeComputePermission != null) {
                if (typeof object.executeComputePermission !== "object")
                    throw TypeError(".data_room.Permission.executeComputePermission: object expected");
                message.executeComputePermission = $root.data_room.ExecuteComputePermission.fromObject(object.executeComputePermission);
            }
            if (object.leafCrudPermission != null) {
                if (typeof object.leafCrudPermission !== "object")
                    throw TypeError(".data_room.Permission.leafCrudPermission: object expected");
                message.leafCrudPermission = $root.data_room.LeafCrudPermission.fromObject(object.leafCrudPermission);
            }
            if (object.retrieveDataRoomPermission != null) {
                if (typeof object.retrieveDataRoomPermission !== "object")
                    throw TypeError(".data_room.Permission.retrieveDataRoomPermission: object expected");
                message.retrieveDataRoomPermission = $root.data_room.RetrieveDataRoomPermission.fromObject(object.retrieveDataRoomPermission);
            }
            if (object.retrieveAuditLogPermission != null) {
                if (typeof object.retrieveAuditLogPermission !== "object")
                    throw TypeError(".data_room.Permission.retrieveAuditLogPermission: object expected");
                message.retrieveAuditLogPermission = $root.data_room.RetrieveAuditLogPermission.fromObject(object.retrieveAuditLogPermission);
            }
            if (object.retrieveDataRoomStatusPermission != null) {
                if (typeof object.retrieveDataRoomStatusPermission !== "object")
                    throw TypeError(".data_room.Permission.retrieveDataRoomStatusPermission: object expected");
                message.retrieveDataRoomStatusPermission = $root.data_room.RetrieveDataRoomStatusPermission.fromObject(object.retrieveDataRoomStatusPermission);
            }
            if (object.updateDataRoomStatusPermission != null) {
                if (typeof object.updateDataRoomStatusPermission !== "object")
                    throw TypeError(".data_room.Permission.updateDataRoomStatusPermission: object expected");
                message.updateDataRoomStatusPermission = $root.data_room.UpdateDataRoomStatusPermission.fromObject(object.updateDataRoomStatusPermission);
            }
            if (object.retrievePublishedDatasetsPermission != null) {
                if (typeof object.retrievePublishedDatasetsPermission !== "object")
                    throw TypeError(".data_room.Permission.retrievePublishedDatasetsPermission: object expected");
                message.retrievePublishedDatasetsPermission = $root.data_room.RetrievePublishedDatasetsPermission.fromObject(object.retrievePublishedDatasetsPermission);
            }
            if (object.dryRunPermission != null) {
                if (typeof object.dryRunPermission !== "object")
                    throw TypeError(".data_room.Permission.dryRunPermission: object expected");
                message.dryRunPermission = $root.data_room.DryRunPermission.fromObject(object.dryRunPermission);
            }
            if (object.generateMergeSignaturePermission != null) {
                if (typeof object.generateMergeSignaturePermission !== "object")
                    throw TypeError(".data_room.Permission.generateMergeSignaturePermission: object expected");
                message.generateMergeSignaturePermission = $root.data_room.GenerateMergeSignaturePermission.fromObject(object.generateMergeSignaturePermission);
            }
            if (object.executeDevelopmentComputePermission != null) {
                if (typeof object.executeDevelopmentComputePermission !== "object")
                    throw TypeError(".data_room.Permission.executeDevelopmentComputePermission: object expected");
                message.executeDevelopmentComputePermission = $root.data_room.ExecuteDevelopmentComputePermission.fromObject(object.executeDevelopmentComputePermission);
            }
            if (object.mergeConfigurationCommitPermission != null) {
                if (typeof object.mergeConfigurationCommitPermission !== "object")
                    throw TypeError(".data_room.Permission.mergeConfigurationCommitPermission: object expected");
                message.mergeConfigurationCommitPermission = $root.data_room.MergeConfigurationCommitPermission.fromObject(object.mergeConfigurationCommitPermission);
            }
            if (object.retrieveComputeResultPermission != null) {
                if (typeof object.retrieveComputeResultPermission !== "object")
                    throw TypeError(".data_room.Permission.retrieveComputeResultPermission: object expected");
                message.retrieveComputeResultPermission = $root.data_room.RetrieveComputeResultPermission.fromObject(object.retrieveComputeResultPermission);
            }
            if (object.casAuxiliaryStatePermission != null) {
                if (typeof object.casAuxiliaryStatePermission !== "object")
                    throw TypeError(".data_room.Permission.casAuxiliaryStatePermission: object expected");
                message.casAuxiliaryStatePermission = $root.data_room.CasAuxiliaryStatePermission.fromObject(object.casAuxiliaryStatePermission);
            }
            if (object.readAuxiliaryStatePermission != null) {
                if (typeof object.readAuxiliaryStatePermission !== "object")
                    throw TypeError(".data_room.Permission.readAuxiliaryStatePermission: object expected");
                message.readAuxiliaryStatePermission = $root.data_room.ReadAuxiliaryStatePermission.fromObject(object.readAuxiliaryStatePermission);
            }
            return message;
        };

        /**
         * Creates a plain object from a Permission message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.Permission
         * @static
         * @param {data_room.Permission} message Permission
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Permission.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.executeComputePermission != null && message.hasOwnProperty("executeComputePermission")) {
                object.executeComputePermission = $root.data_room.ExecuteComputePermission.toObject(message.executeComputePermission, options);
                if (options.oneofs)
                    object.permission = "executeComputePermission";
            }
            if (message.leafCrudPermission != null && message.hasOwnProperty("leafCrudPermission")) {
                object.leafCrudPermission = $root.data_room.LeafCrudPermission.toObject(message.leafCrudPermission, options);
                if (options.oneofs)
                    object.permission = "leafCrudPermission";
            }
            if (message.retrieveDataRoomPermission != null && message.hasOwnProperty("retrieveDataRoomPermission")) {
                object.retrieveDataRoomPermission = $root.data_room.RetrieveDataRoomPermission.toObject(message.retrieveDataRoomPermission, options);
                if (options.oneofs)
                    object.permission = "retrieveDataRoomPermission";
            }
            if (message.retrieveAuditLogPermission != null && message.hasOwnProperty("retrieveAuditLogPermission")) {
                object.retrieveAuditLogPermission = $root.data_room.RetrieveAuditLogPermission.toObject(message.retrieveAuditLogPermission, options);
                if (options.oneofs)
                    object.permission = "retrieveAuditLogPermission";
            }
            if (message.retrieveDataRoomStatusPermission != null && message.hasOwnProperty("retrieveDataRoomStatusPermission")) {
                object.retrieveDataRoomStatusPermission = $root.data_room.RetrieveDataRoomStatusPermission.toObject(message.retrieveDataRoomStatusPermission, options);
                if (options.oneofs)
                    object.permission = "retrieveDataRoomStatusPermission";
            }
            if (message.updateDataRoomStatusPermission != null && message.hasOwnProperty("updateDataRoomStatusPermission")) {
                object.updateDataRoomStatusPermission = $root.data_room.UpdateDataRoomStatusPermission.toObject(message.updateDataRoomStatusPermission, options);
                if (options.oneofs)
                    object.permission = "updateDataRoomStatusPermission";
            }
            if (message.retrievePublishedDatasetsPermission != null && message.hasOwnProperty("retrievePublishedDatasetsPermission")) {
                object.retrievePublishedDatasetsPermission = $root.data_room.RetrievePublishedDatasetsPermission.toObject(message.retrievePublishedDatasetsPermission, options);
                if (options.oneofs)
                    object.permission = "retrievePublishedDatasetsPermission";
            }
            if (message.dryRunPermission != null && message.hasOwnProperty("dryRunPermission")) {
                object.dryRunPermission = $root.data_room.DryRunPermission.toObject(message.dryRunPermission, options);
                if (options.oneofs)
                    object.permission = "dryRunPermission";
            }
            if (message.generateMergeSignaturePermission != null && message.hasOwnProperty("generateMergeSignaturePermission")) {
                object.generateMergeSignaturePermission = $root.data_room.GenerateMergeSignaturePermission.toObject(message.generateMergeSignaturePermission, options);
                if (options.oneofs)
                    object.permission = "generateMergeSignaturePermission";
            }
            if (message.executeDevelopmentComputePermission != null && message.hasOwnProperty("executeDevelopmentComputePermission")) {
                object.executeDevelopmentComputePermission = $root.data_room.ExecuteDevelopmentComputePermission.toObject(message.executeDevelopmentComputePermission, options);
                if (options.oneofs)
                    object.permission = "executeDevelopmentComputePermission";
            }
            if (message.mergeConfigurationCommitPermission != null && message.hasOwnProperty("mergeConfigurationCommitPermission")) {
                object.mergeConfigurationCommitPermission = $root.data_room.MergeConfigurationCommitPermission.toObject(message.mergeConfigurationCommitPermission, options);
                if (options.oneofs)
                    object.permission = "mergeConfigurationCommitPermission";
            }
            if (message.retrieveComputeResultPermission != null && message.hasOwnProperty("retrieveComputeResultPermission")) {
                object.retrieveComputeResultPermission = $root.data_room.RetrieveComputeResultPermission.toObject(message.retrieveComputeResultPermission, options);
                if (options.oneofs)
                    object.permission = "retrieveComputeResultPermission";
            }
            if (message.casAuxiliaryStatePermission != null && message.hasOwnProperty("casAuxiliaryStatePermission")) {
                object.casAuxiliaryStatePermission = $root.data_room.CasAuxiliaryStatePermission.toObject(message.casAuxiliaryStatePermission, options);
                if (options.oneofs)
                    object.permission = "casAuxiliaryStatePermission";
            }
            if (message.readAuxiliaryStatePermission != null && message.hasOwnProperty("readAuxiliaryStatePermission")) {
                object.readAuxiliaryStatePermission = $root.data_room.ReadAuxiliaryStatePermission.toObject(message.readAuxiliaryStatePermission, options);
                if (options.oneofs)
                    object.permission = "readAuxiliaryStatePermission";
            }
            return object;
        };

        /**
         * Converts this Permission to JSON.
         * @function toJSON
         * @memberof data_room.Permission
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Permission.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Permission
         * @function getTypeUrl
         * @memberof data_room.Permission
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Permission.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.Permission";
        };

        return Permission;
    })();

    data_room.ExecuteComputePermission = (function() {

        /**
         * Properties of an ExecuteComputePermission.
         * @memberof data_room
         * @interface IExecuteComputePermission
         * @property {string|null} [computeNodeId] ExecuteComputePermission computeNodeId
         */

        /**
         * Constructs a new ExecuteComputePermission.
         * @memberof data_room
         * @classdesc Represents an ExecuteComputePermission.
         * @implements IExecuteComputePermission
         * @constructor
         * @param {data_room.IExecuteComputePermission=} [properties] Properties to set
         */
        function ExecuteComputePermission(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExecuteComputePermission computeNodeId.
         * @member {string} computeNodeId
         * @memberof data_room.ExecuteComputePermission
         * @instance
         */
        ExecuteComputePermission.prototype.computeNodeId = "";

        /**
         * Creates a new ExecuteComputePermission instance using the specified properties.
         * @function create
         * @memberof data_room.ExecuteComputePermission
         * @static
         * @param {data_room.IExecuteComputePermission=} [properties] Properties to set
         * @returns {data_room.ExecuteComputePermission} ExecuteComputePermission instance
         */
        ExecuteComputePermission.create = function create(properties) {
            return new ExecuteComputePermission(properties);
        };

        /**
         * Encodes the specified ExecuteComputePermission message. Does not implicitly {@link data_room.ExecuteComputePermission.verify|verify} messages.
         * @function encode
         * @memberof data_room.ExecuteComputePermission
         * @static
         * @param {data_room.IExecuteComputePermission} message ExecuteComputePermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecuteComputePermission.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.computeNodeId != null && Object.hasOwnProperty.call(message, "computeNodeId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.computeNodeId);
            return writer;
        };

        /**
         * Encodes the specified ExecuteComputePermission message, length delimited. Does not implicitly {@link data_room.ExecuteComputePermission.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.ExecuteComputePermission
         * @static
         * @param {data_room.IExecuteComputePermission} message ExecuteComputePermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecuteComputePermission.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExecuteComputePermission message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.ExecuteComputePermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.ExecuteComputePermission} ExecuteComputePermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecuteComputePermission.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.ExecuteComputePermission();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.computeNodeId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExecuteComputePermission message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.ExecuteComputePermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.ExecuteComputePermission} ExecuteComputePermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecuteComputePermission.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExecuteComputePermission message.
         * @function verify
         * @memberof data_room.ExecuteComputePermission
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExecuteComputePermission.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.computeNodeId != null && message.hasOwnProperty("computeNodeId"))
                if (!$util.isString(message.computeNodeId))
                    return "computeNodeId: string expected";
            return null;
        };

        /**
         * Creates an ExecuteComputePermission message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.ExecuteComputePermission
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.ExecuteComputePermission} ExecuteComputePermission
         */
        ExecuteComputePermission.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.ExecuteComputePermission)
                return object;
            let message = new $root.data_room.ExecuteComputePermission();
            if (object.computeNodeId != null)
                message.computeNodeId = String(object.computeNodeId);
            return message;
        };

        /**
         * Creates a plain object from an ExecuteComputePermission message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.ExecuteComputePermission
         * @static
         * @param {data_room.ExecuteComputePermission} message ExecuteComputePermission
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExecuteComputePermission.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.computeNodeId = "";
            if (message.computeNodeId != null && message.hasOwnProperty("computeNodeId"))
                object.computeNodeId = message.computeNodeId;
            return object;
        };

        /**
         * Converts this ExecuteComputePermission to JSON.
         * @function toJSON
         * @memberof data_room.ExecuteComputePermission
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExecuteComputePermission.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ExecuteComputePermission
         * @function getTypeUrl
         * @memberof data_room.ExecuteComputePermission
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExecuteComputePermission.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.ExecuteComputePermission";
        };

        return ExecuteComputePermission;
    })();

    data_room.LeafCrudPermission = (function() {

        /**
         * Properties of a LeafCrudPermission.
         * @memberof data_room
         * @interface ILeafCrudPermission
         * @property {string|null} [leafNodeId] LeafCrudPermission leafNodeId
         */

        /**
         * Constructs a new LeafCrudPermission.
         * @memberof data_room
         * @classdesc Represents a LeafCrudPermission.
         * @implements ILeafCrudPermission
         * @constructor
         * @param {data_room.ILeafCrudPermission=} [properties] Properties to set
         */
        function LeafCrudPermission(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LeafCrudPermission leafNodeId.
         * @member {string} leafNodeId
         * @memberof data_room.LeafCrudPermission
         * @instance
         */
        LeafCrudPermission.prototype.leafNodeId = "";

        /**
         * Creates a new LeafCrudPermission instance using the specified properties.
         * @function create
         * @memberof data_room.LeafCrudPermission
         * @static
         * @param {data_room.ILeafCrudPermission=} [properties] Properties to set
         * @returns {data_room.LeafCrudPermission} LeafCrudPermission instance
         */
        LeafCrudPermission.create = function create(properties) {
            return new LeafCrudPermission(properties);
        };

        /**
         * Encodes the specified LeafCrudPermission message. Does not implicitly {@link data_room.LeafCrudPermission.verify|verify} messages.
         * @function encode
         * @memberof data_room.LeafCrudPermission
         * @static
         * @param {data_room.ILeafCrudPermission} message LeafCrudPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LeafCrudPermission.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.leafNodeId != null && Object.hasOwnProperty.call(message, "leafNodeId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.leafNodeId);
            return writer;
        };

        /**
         * Encodes the specified LeafCrudPermission message, length delimited. Does not implicitly {@link data_room.LeafCrudPermission.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.LeafCrudPermission
         * @static
         * @param {data_room.ILeafCrudPermission} message LeafCrudPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LeafCrudPermission.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LeafCrudPermission message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.LeafCrudPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.LeafCrudPermission} LeafCrudPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LeafCrudPermission.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.LeafCrudPermission();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.leafNodeId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LeafCrudPermission message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.LeafCrudPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.LeafCrudPermission} LeafCrudPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LeafCrudPermission.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LeafCrudPermission message.
         * @function verify
         * @memberof data_room.LeafCrudPermission
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LeafCrudPermission.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.leafNodeId != null && message.hasOwnProperty("leafNodeId"))
                if (!$util.isString(message.leafNodeId))
                    return "leafNodeId: string expected";
            return null;
        };

        /**
         * Creates a LeafCrudPermission message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.LeafCrudPermission
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.LeafCrudPermission} LeafCrudPermission
         */
        LeafCrudPermission.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.LeafCrudPermission)
                return object;
            let message = new $root.data_room.LeafCrudPermission();
            if (object.leafNodeId != null)
                message.leafNodeId = String(object.leafNodeId);
            return message;
        };

        /**
         * Creates a plain object from a LeafCrudPermission message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.LeafCrudPermission
         * @static
         * @param {data_room.LeafCrudPermission} message LeafCrudPermission
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LeafCrudPermission.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.leafNodeId = "";
            if (message.leafNodeId != null && message.hasOwnProperty("leafNodeId"))
                object.leafNodeId = message.leafNodeId;
            return object;
        };

        /**
         * Converts this LeafCrudPermission to JSON.
         * @function toJSON
         * @memberof data_room.LeafCrudPermission
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LeafCrudPermission.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LeafCrudPermission
         * @function getTypeUrl
         * @memberof data_room.LeafCrudPermission
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LeafCrudPermission.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.LeafCrudPermission";
        };

        return LeafCrudPermission;
    })();

    data_room.RetrieveDataRoomPermission = (function() {

        /**
         * Properties of a RetrieveDataRoomPermission.
         * @memberof data_room
         * @interface IRetrieveDataRoomPermission
         */

        /**
         * Constructs a new RetrieveDataRoomPermission.
         * @memberof data_room
         * @classdesc Represents a RetrieveDataRoomPermission.
         * @implements IRetrieveDataRoomPermission
         * @constructor
         * @param {data_room.IRetrieveDataRoomPermission=} [properties] Properties to set
         */
        function RetrieveDataRoomPermission(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RetrieveDataRoomPermission instance using the specified properties.
         * @function create
         * @memberof data_room.RetrieveDataRoomPermission
         * @static
         * @param {data_room.IRetrieveDataRoomPermission=} [properties] Properties to set
         * @returns {data_room.RetrieveDataRoomPermission} RetrieveDataRoomPermission instance
         */
        RetrieveDataRoomPermission.create = function create(properties) {
            return new RetrieveDataRoomPermission(properties);
        };

        /**
         * Encodes the specified RetrieveDataRoomPermission message. Does not implicitly {@link data_room.RetrieveDataRoomPermission.verify|verify} messages.
         * @function encode
         * @memberof data_room.RetrieveDataRoomPermission
         * @static
         * @param {data_room.IRetrieveDataRoomPermission} message RetrieveDataRoomPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveDataRoomPermission.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RetrieveDataRoomPermission message, length delimited. Does not implicitly {@link data_room.RetrieveDataRoomPermission.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.RetrieveDataRoomPermission
         * @static
         * @param {data_room.IRetrieveDataRoomPermission} message RetrieveDataRoomPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveDataRoomPermission.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrieveDataRoomPermission message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.RetrieveDataRoomPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.RetrieveDataRoomPermission} RetrieveDataRoomPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveDataRoomPermission.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.RetrieveDataRoomPermission();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrieveDataRoomPermission message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.RetrieveDataRoomPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.RetrieveDataRoomPermission} RetrieveDataRoomPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveDataRoomPermission.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrieveDataRoomPermission message.
         * @function verify
         * @memberof data_room.RetrieveDataRoomPermission
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrieveDataRoomPermission.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RetrieveDataRoomPermission message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.RetrieveDataRoomPermission
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.RetrieveDataRoomPermission} RetrieveDataRoomPermission
         */
        RetrieveDataRoomPermission.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.RetrieveDataRoomPermission)
                return object;
            return new $root.data_room.RetrieveDataRoomPermission();
        };

        /**
         * Creates a plain object from a RetrieveDataRoomPermission message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.RetrieveDataRoomPermission
         * @static
         * @param {data_room.RetrieveDataRoomPermission} message RetrieveDataRoomPermission
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrieveDataRoomPermission.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RetrieveDataRoomPermission to JSON.
         * @function toJSON
         * @memberof data_room.RetrieveDataRoomPermission
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrieveDataRoomPermission.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrieveDataRoomPermission
         * @function getTypeUrl
         * @memberof data_room.RetrieveDataRoomPermission
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrieveDataRoomPermission.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.RetrieveDataRoomPermission";
        };

        return RetrieveDataRoomPermission;
    })();

    data_room.RetrieveAuditLogPermission = (function() {

        /**
         * Properties of a RetrieveAuditLogPermission.
         * @memberof data_room
         * @interface IRetrieveAuditLogPermission
         */

        /**
         * Constructs a new RetrieveAuditLogPermission.
         * @memberof data_room
         * @classdesc Represents a RetrieveAuditLogPermission.
         * @implements IRetrieveAuditLogPermission
         * @constructor
         * @param {data_room.IRetrieveAuditLogPermission=} [properties] Properties to set
         */
        function RetrieveAuditLogPermission(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RetrieveAuditLogPermission instance using the specified properties.
         * @function create
         * @memberof data_room.RetrieveAuditLogPermission
         * @static
         * @param {data_room.IRetrieveAuditLogPermission=} [properties] Properties to set
         * @returns {data_room.RetrieveAuditLogPermission} RetrieveAuditLogPermission instance
         */
        RetrieveAuditLogPermission.create = function create(properties) {
            return new RetrieveAuditLogPermission(properties);
        };

        /**
         * Encodes the specified RetrieveAuditLogPermission message. Does not implicitly {@link data_room.RetrieveAuditLogPermission.verify|verify} messages.
         * @function encode
         * @memberof data_room.RetrieveAuditLogPermission
         * @static
         * @param {data_room.IRetrieveAuditLogPermission} message RetrieveAuditLogPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveAuditLogPermission.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RetrieveAuditLogPermission message, length delimited. Does not implicitly {@link data_room.RetrieveAuditLogPermission.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.RetrieveAuditLogPermission
         * @static
         * @param {data_room.IRetrieveAuditLogPermission} message RetrieveAuditLogPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveAuditLogPermission.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrieveAuditLogPermission message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.RetrieveAuditLogPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.RetrieveAuditLogPermission} RetrieveAuditLogPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveAuditLogPermission.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.RetrieveAuditLogPermission();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrieveAuditLogPermission message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.RetrieveAuditLogPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.RetrieveAuditLogPermission} RetrieveAuditLogPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveAuditLogPermission.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrieveAuditLogPermission message.
         * @function verify
         * @memberof data_room.RetrieveAuditLogPermission
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrieveAuditLogPermission.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RetrieveAuditLogPermission message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.RetrieveAuditLogPermission
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.RetrieveAuditLogPermission} RetrieveAuditLogPermission
         */
        RetrieveAuditLogPermission.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.RetrieveAuditLogPermission)
                return object;
            return new $root.data_room.RetrieveAuditLogPermission();
        };

        /**
         * Creates a plain object from a RetrieveAuditLogPermission message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.RetrieveAuditLogPermission
         * @static
         * @param {data_room.RetrieveAuditLogPermission} message RetrieveAuditLogPermission
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrieveAuditLogPermission.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RetrieveAuditLogPermission to JSON.
         * @function toJSON
         * @memberof data_room.RetrieveAuditLogPermission
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrieveAuditLogPermission.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrieveAuditLogPermission
         * @function getTypeUrl
         * @memberof data_room.RetrieveAuditLogPermission
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrieveAuditLogPermission.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.RetrieveAuditLogPermission";
        };

        return RetrieveAuditLogPermission;
    })();

    data_room.RetrieveDataRoomStatusPermission = (function() {

        /**
         * Properties of a RetrieveDataRoomStatusPermission.
         * @memberof data_room
         * @interface IRetrieveDataRoomStatusPermission
         */

        /**
         * Constructs a new RetrieveDataRoomStatusPermission.
         * @memberof data_room
         * @classdesc Represents a RetrieveDataRoomStatusPermission.
         * @implements IRetrieveDataRoomStatusPermission
         * @constructor
         * @param {data_room.IRetrieveDataRoomStatusPermission=} [properties] Properties to set
         */
        function RetrieveDataRoomStatusPermission(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RetrieveDataRoomStatusPermission instance using the specified properties.
         * @function create
         * @memberof data_room.RetrieveDataRoomStatusPermission
         * @static
         * @param {data_room.IRetrieveDataRoomStatusPermission=} [properties] Properties to set
         * @returns {data_room.RetrieveDataRoomStatusPermission} RetrieveDataRoomStatusPermission instance
         */
        RetrieveDataRoomStatusPermission.create = function create(properties) {
            return new RetrieveDataRoomStatusPermission(properties);
        };

        /**
         * Encodes the specified RetrieveDataRoomStatusPermission message. Does not implicitly {@link data_room.RetrieveDataRoomStatusPermission.verify|verify} messages.
         * @function encode
         * @memberof data_room.RetrieveDataRoomStatusPermission
         * @static
         * @param {data_room.IRetrieveDataRoomStatusPermission} message RetrieveDataRoomStatusPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveDataRoomStatusPermission.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RetrieveDataRoomStatusPermission message, length delimited. Does not implicitly {@link data_room.RetrieveDataRoomStatusPermission.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.RetrieveDataRoomStatusPermission
         * @static
         * @param {data_room.IRetrieveDataRoomStatusPermission} message RetrieveDataRoomStatusPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveDataRoomStatusPermission.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrieveDataRoomStatusPermission message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.RetrieveDataRoomStatusPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.RetrieveDataRoomStatusPermission} RetrieveDataRoomStatusPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveDataRoomStatusPermission.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.RetrieveDataRoomStatusPermission();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrieveDataRoomStatusPermission message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.RetrieveDataRoomStatusPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.RetrieveDataRoomStatusPermission} RetrieveDataRoomStatusPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveDataRoomStatusPermission.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrieveDataRoomStatusPermission message.
         * @function verify
         * @memberof data_room.RetrieveDataRoomStatusPermission
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrieveDataRoomStatusPermission.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RetrieveDataRoomStatusPermission message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.RetrieveDataRoomStatusPermission
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.RetrieveDataRoomStatusPermission} RetrieveDataRoomStatusPermission
         */
        RetrieveDataRoomStatusPermission.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.RetrieveDataRoomStatusPermission)
                return object;
            return new $root.data_room.RetrieveDataRoomStatusPermission();
        };

        /**
         * Creates a plain object from a RetrieveDataRoomStatusPermission message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.RetrieveDataRoomStatusPermission
         * @static
         * @param {data_room.RetrieveDataRoomStatusPermission} message RetrieveDataRoomStatusPermission
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrieveDataRoomStatusPermission.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RetrieveDataRoomStatusPermission to JSON.
         * @function toJSON
         * @memberof data_room.RetrieveDataRoomStatusPermission
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrieveDataRoomStatusPermission.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrieveDataRoomStatusPermission
         * @function getTypeUrl
         * @memberof data_room.RetrieveDataRoomStatusPermission
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrieveDataRoomStatusPermission.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.RetrieveDataRoomStatusPermission";
        };

        return RetrieveDataRoomStatusPermission;
    })();

    data_room.UpdateDataRoomStatusPermission = (function() {

        /**
         * Properties of an UpdateDataRoomStatusPermission.
         * @memberof data_room
         * @interface IUpdateDataRoomStatusPermission
         */

        /**
         * Constructs a new UpdateDataRoomStatusPermission.
         * @memberof data_room
         * @classdesc Represents an UpdateDataRoomStatusPermission.
         * @implements IUpdateDataRoomStatusPermission
         * @constructor
         * @param {data_room.IUpdateDataRoomStatusPermission=} [properties] Properties to set
         */
        function UpdateDataRoomStatusPermission(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateDataRoomStatusPermission instance using the specified properties.
         * @function create
         * @memberof data_room.UpdateDataRoomStatusPermission
         * @static
         * @param {data_room.IUpdateDataRoomStatusPermission=} [properties] Properties to set
         * @returns {data_room.UpdateDataRoomStatusPermission} UpdateDataRoomStatusPermission instance
         */
        UpdateDataRoomStatusPermission.create = function create(properties) {
            return new UpdateDataRoomStatusPermission(properties);
        };

        /**
         * Encodes the specified UpdateDataRoomStatusPermission message. Does not implicitly {@link data_room.UpdateDataRoomStatusPermission.verify|verify} messages.
         * @function encode
         * @memberof data_room.UpdateDataRoomStatusPermission
         * @static
         * @param {data_room.IUpdateDataRoomStatusPermission} message UpdateDataRoomStatusPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateDataRoomStatusPermission.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateDataRoomStatusPermission message, length delimited. Does not implicitly {@link data_room.UpdateDataRoomStatusPermission.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.UpdateDataRoomStatusPermission
         * @static
         * @param {data_room.IUpdateDataRoomStatusPermission} message UpdateDataRoomStatusPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateDataRoomStatusPermission.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateDataRoomStatusPermission message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.UpdateDataRoomStatusPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.UpdateDataRoomStatusPermission} UpdateDataRoomStatusPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateDataRoomStatusPermission.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.UpdateDataRoomStatusPermission();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateDataRoomStatusPermission message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.UpdateDataRoomStatusPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.UpdateDataRoomStatusPermission} UpdateDataRoomStatusPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateDataRoomStatusPermission.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateDataRoomStatusPermission message.
         * @function verify
         * @memberof data_room.UpdateDataRoomStatusPermission
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateDataRoomStatusPermission.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateDataRoomStatusPermission message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.UpdateDataRoomStatusPermission
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.UpdateDataRoomStatusPermission} UpdateDataRoomStatusPermission
         */
        UpdateDataRoomStatusPermission.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.UpdateDataRoomStatusPermission)
                return object;
            return new $root.data_room.UpdateDataRoomStatusPermission();
        };

        /**
         * Creates a plain object from an UpdateDataRoomStatusPermission message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.UpdateDataRoomStatusPermission
         * @static
         * @param {data_room.UpdateDataRoomStatusPermission} message UpdateDataRoomStatusPermission
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateDataRoomStatusPermission.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateDataRoomStatusPermission to JSON.
         * @function toJSON
         * @memberof data_room.UpdateDataRoomStatusPermission
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateDataRoomStatusPermission.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateDataRoomStatusPermission
         * @function getTypeUrl
         * @memberof data_room.UpdateDataRoomStatusPermission
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateDataRoomStatusPermission.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.UpdateDataRoomStatusPermission";
        };

        return UpdateDataRoomStatusPermission;
    })();

    data_room.RetrievePublishedDatasetsPermission = (function() {

        /**
         * Properties of a RetrievePublishedDatasetsPermission.
         * @memberof data_room
         * @interface IRetrievePublishedDatasetsPermission
         */

        /**
         * Constructs a new RetrievePublishedDatasetsPermission.
         * @memberof data_room
         * @classdesc Represents a RetrievePublishedDatasetsPermission.
         * @implements IRetrievePublishedDatasetsPermission
         * @constructor
         * @param {data_room.IRetrievePublishedDatasetsPermission=} [properties] Properties to set
         */
        function RetrievePublishedDatasetsPermission(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RetrievePublishedDatasetsPermission instance using the specified properties.
         * @function create
         * @memberof data_room.RetrievePublishedDatasetsPermission
         * @static
         * @param {data_room.IRetrievePublishedDatasetsPermission=} [properties] Properties to set
         * @returns {data_room.RetrievePublishedDatasetsPermission} RetrievePublishedDatasetsPermission instance
         */
        RetrievePublishedDatasetsPermission.create = function create(properties) {
            return new RetrievePublishedDatasetsPermission(properties);
        };

        /**
         * Encodes the specified RetrievePublishedDatasetsPermission message. Does not implicitly {@link data_room.RetrievePublishedDatasetsPermission.verify|verify} messages.
         * @function encode
         * @memberof data_room.RetrievePublishedDatasetsPermission
         * @static
         * @param {data_room.IRetrievePublishedDatasetsPermission} message RetrievePublishedDatasetsPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrievePublishedDatasetsPermission.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RetrievePublishedDatasetsPermission message, length delimited. Does not implicitly {@link data_room.RetrievePublishedDatasetsPermission.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.RetrievePublishedDatasetsPermission
         * @static
         * @param {data_room.IRetrievePublishedDatasetsPermission} message RetrievePublishedDatasetsPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrievePublishedDatasetsPermission.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrievePublishedDatasetsPermission message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.RetrievePublishedDatasetsPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.RetrievePublishedDatasetsPermission} RetrievePublishedDatasetsPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrievePublishedDatasetsPermission.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.RetrievePublishedDatasetsPermission();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrievePublishedDatasetsPermission message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.RetrievePublishedDatasetsPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.RetrievePublishedDatasetsPermission} RetrievePublishedDatasetsPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrievePublishedDatasetsPermission.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrievePublishedDatasetsPermission message.
         * @function verify
         * @memberof data_room.RetrievePublishedDatasetsPermission
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrievePublishedDatasetsPermission.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RetrievePublishedDatasetsPermission message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.RetrievePublishedDatasetsPermission
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.RetrievePublishedDatasetsPermission} RetrievePublishedDatasetsPermission
         */
        RetrievePublishedDatasetsPermission.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.RetrievePublishedDatasetsPermission)
                return object;
            return new $root.data_room.RetrievePublishedDatasetsPermission();
        };

        /**
         * Creates a plain object from a RetrievePublishedDatasetsPermission message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.RetrievePublishedDatasetsPermission
         * @static
         * @param {data_room.RetrievePublishedDatasetsPermission} message RetrievePublishedDatasetsPermission
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrievePublishedDatasetsPermission.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RetrievePublishedDatasetsPermission to JSON.
         * @function toJSON
         * @memberof data_room.RetrievePublishedDatasetsPermission
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrievePublishedDatasetsPermission.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrievePublishedDatasetsPermission
         * @function getTypeUrl
         * @memberof data_room.RetrievePublishedDatasetsPermission
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrievePublishedDatasetsPermission.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.RetrievePublishedDatasetsPermission";
        };

        return RetrievePublishedDatasetsPermission;
    })();

    data_room.DryRunPermission = (function() {

        /**
         * Properties of a DryRunPermission.
         * @memberof data_room
         * @interface IDryRunPermission
         */

        /**
         * Constructs a new DryRunPermission.
         * @memberof data_room
         * @classdesc Represents a DryRunPermission.
         * @implements IDryRunPermission
         * @constructor
         * @param {data_room.IDryRunPermission=} [properties] Properties to set
         */
        function DryRunPermission(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DryRunPermission instance using the specified properties.
         * @function create
         * @memberof data_room.DryRunPermission
         * @static
         * @param {data_room.IDryRunPermission=} [properties] Properties to set
         * @returns {data_room.DryRunPermission} DryRunPermission instance
         */
        DryRunPermission.create = function create(properties) {
            return new DryRunPermission(properties);
        };

        /**
         * Encodes the specified DryRunPermission message. Does not implicitly {@link data_room.DryRunPermission.verify|verify} messages.
         * @function encode
         * @memberof data_room.DryRunPermission
         * @static
         * @param {data_room.IDryRunPermission} message DryRunPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DryRunPermission.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DryRunPermission message, length delimited. Does not implicitly {@link data_room.DryRunPermission.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.DryRunPermission
         * @static
         * @param {data_room.IDryRunPermission} message DryRunPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DryRunPermission.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DryRunPermission message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.DryRunPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.DryRunPermission} DryRunPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DryRunPermission.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.DryRunPermission();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DryRunPermission message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.DryRunPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.DryRunPermission} DryRunPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DryRunPermission.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DryRunPermission message.
         * @function verify
         * @memberof data_room.DryRunPermission
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DryRunPermission.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DryRunPermission message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.DryRunPermission
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.DryRunPermission} DryRunPermission
         */
        DryRunPermission.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.DryRunPermission)
                return object;
            return new $root.data_room.DryRunPermission();
        };

        /**
         * Creates a plain object from a DryRunPermission message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.DryRunPermission
         * @static
         * @param {data_room.DryRunPermission} message DryRunPermission
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DryRunPermission.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DryRunPermission to JSON.
         * @function toJSON
         * @memberof data_room.DryRunPermission
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DryRunPermission.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DryRunPermission
         * @function getTypeUrl
         * @memberof data_room.DryRunPermission
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DryRunPermission.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.DryRunPermission";
        };

        return DryRunPermission;
    })();

    data_room.GenerateMergeSignaturePermission = (function() {

        /**
         * Properties of a GenerateMergeSignaturePermission.
         * @memberof data_room
         * @interface IGenerateMergeSignaturePermission
         */

        /**
         * Constructs a new GenerateMergeSignaturePermission.
         * @memberof data_room
         * @classdesc Represents a GenerateMergeSignaturePermission.
         * @implements IGenerateMergeSignaturePermission
         * @constructor
         * @param {data_room.IGenerateMergeSignaturePermission=} [properties] Properties to set
         */
        function GenerateMergeSignaturePermission(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GenerateMergeSignaturePermission instance using the specified properties.
         * @function create
         * @memberof data_room.GenerateMergeSignaturePermission
         * @static
         * @param {data_room.IGenerateMergeSignaturePermission=} [properties] Properties to set
         * @returns {data_room.GenerateMergeSignaturePermission} GenerateMergeSignaturePermission instance
         */
        GenerateMergeSignaturePermission.create = function create(properties) {
            return new GenerateMergeSignaturePermission(properties);
        };

        /**
         * Encodes the specified GenerateMergeSignaturePermission message. Does not implicitly {@link data_room.GenerateMergeSignaturePermission.verify|verify} messages.
         * @function encode
         * @memberof data_room.GenerateMergeSignaturePermission
         * @static
         * @param {data_room.IGenerateMergeSignaturePermission} message GenerateMergeSignaturePermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateMergeSignaturePermission.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GenerateMergeSignaturePermission message, length delimited. Does not implicitly {@link data_room.GenerateMergeSignaturePermission.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.GenerateMergeSignaturePermission
         * @static
         * @param {data_room.IGenerateMergeSignaturePermission} message GenerateMergeSignaturePermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateMergeSignaturePermission.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GenerateMergeSignaturePermission message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.GenerateMergeSignaturePermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.GenerateMergeSignaturePermission} GenerateMergeSignaturePermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateMergeSignaturePermission.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.GenerateMergeSignaturePermission();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GenerateMergeSignaturePermission message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.GenerateMergeSignaturePermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.GenerateMergeSignaturePermission} GenerateMergeSignaturePermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateMergeSignaturePermission.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GenerateMergeSignaturePermission message.
         * @function verify
         * @memberof data_room.GenerateMergeSignaturePermission
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GenerateMergeSignaturePermission.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GenerateMergeSignaturePermission message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.GenerateMergeSignaturePermission
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.GenerateMergeSignaturePermission} GenerateMergeSignaturePermission
         */
        GenerateMergeSignaturePermission.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.GenerateMergeSignaturePermission)
                return object;
            return new $root.data_room.GenerateMergeSignaturePermission();
        };

        /**
         * Creates a plain object from a GenerateMergeSignaturePermission message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.GenerateMergeSignaturePermission
         * @static
         * @param {data_room.GenerateMergeSignaturePermission} message GenerateMergeSignaturePermission
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GenerateMergeSignaturePermission.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GenerateMergeSignaturePermission to JSON.
         * @function toJSON
         * @memberof data_room.GenerateMergeSignaturePermission
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GenerateMergeSignaturePermission.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GenerateMergeSignaturePermission
         * @function getTypeUrl
         * @memberof data_room.GenerateMergeSignaturePermission
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GenerateMergeSignaturePermission.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.GenerateMergeSignaturePermission";
        };

        return GenerateMergeSignaturePermission;
    })();

    data_room.ExecuteDevelopmentComputePermission = (function() {

        /**
         * Properties of an ExecuteDevelopmentComputePermission.
         * @memberof data_room
         * @interface IExecuteDevelopmentComputePermission
         */

        /**
         * Constructs a new ExecuteDevelopmentComputePermission.
         * @memberof data_room
         * @classdesc Represents an ExecuteDevelopmentComputePermission.
         * @implements IExecuteDevelopmentComputePermission
         * @constructor
         * @param {data_room.IExecuteDevelopmentComputePermission=} [properties] Properties to set
         */
        function ExecuteDevelopmentComputePermission(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ExecuteDevelopmentComputePermission instance using the specified properties.
         * @function create
         * @memberof data_room.ExecuteDevelopmentComputePermission
         * @static
         * @param {data_room.IExecuteDevelopmentComputePermission=} [properties] Properties to set
         * @returns {data_room.ExecuteDevelopmentComputePermission} ExecuteDevelopmentComputePermission instance
         */
        ExecuteDevelopmentComputePermission.create = function create(properties) {
            return new ExecuteDevelopmentComputePermission(properties);
        };

        /**
         * Encodes the specified ExecuteDevelopmentComputePermission message. Does not implicitly {@link data_room.ExecuteDevelopmentComputePermission.verify|verify} messages.
         * @function encode
         * @memberof data_room.ExecuteDevelopmentComputePermission
         * @static
         * @param {data_room.IExecuteDevelopmentComputePermission} message ExecuteDevelopmentComputePermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecuteDevelopmentComputePermission.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ExecuteDevelopmentComputePermission message, length delimited. Does not implicitly {@link data_room.ExecuteDevelopmentComputePermission.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.ExecuteDevelopmentComputePermission
         * @static
         * @param {data_room.IExecuteDevelopmentComputePermission} message ExecuteDevelopmentComputePermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecuteDevelopmentComputePermission.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExecuteDevelopmentComputePermission message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.ExecuteDevelopmentComputePermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.ExecuteDevelopmentComputePermission} ExecuteDevelopmentComputePermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecuteDevelopmentComputePermission.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.ExecuteDevelopmentComputePermission();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExecuteDevelopmentComputePermission message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.ExecuteDevelopmentComputePermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.ExecuteDevelopmentComputePermission} ExecuteDevelopmentComputePermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecuteDevelopmentComputePermission.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExecuteDevelopmentComputePermission message.
         * @function verify
         * @memberof data_room.ExecuteDevelopmentComputePermission
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExecuteDevelopmentComputePermission.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an ExecuteDevelopmentComputePermission message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.ExecuteDevelopmentComputePermission
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.ExecuteDevelopmentComputePermission} ExecuteDevelopmentComputePermission
         */
        ExecuteDevelopmentComputePermission.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.ExecuteDevelopmentComputePermission)
                return object;
            return new $root.data_room.ExecuteDevelopmentComputePermission();
        };

        /**
         * Creates a plain object from an ExecuteDevelopmentComputePermission message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.ExecuteDevelopmentComputePermission
         * @static
         * @param {data_room.ExecuteDevelopmentComputePermission} message ExecuteDevelopmentComputePermission
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExecuteDevelopmentComputePermission.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ExecuteDevelopmentComputePermission to JSON.
         * @function toJSON
         * @memberof data_room.ExecuteDevelopmentComputePermission
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExecuteDevelopmentComputePermission.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ExecuteDevelopmentComputePermission
         * @function getTypeUrl
         * @memberof data_room.ExecuteDevelopmentComputePermission
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExecuteDevelopmentComputePermission.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.ExecuteDevelopmentComputePermission";
        };

        return ExecuteDevelopmentComputePermission;
    })();

    data_room.MergeConfigurationCommitPermission = (function() {

        /**
         * Properties of a MergeConfigurationCommitPermission.
         * @memberof data_room
         * @interface IMergeConfigurationCommitPermission
         */

        /**
         * Constructs a new MergeConfigurationCommitPermission.
         * @memberof data_room
         * @classdesc Represents a MergeConfigurationCommitPermission.
         * @implements IMergeConfigurationCommitPermission
         * @constructor
         * @param {data_room.IMergeConfigurationCommitPermission=} [properties] Properties to set
         */
        function MergeConfigurationCommitPermission(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new MergeConfigurationCommitPermission instance using the specified properties.
         * @function create
         * @memberof data_room.MergeConfigurationCommitPermission
         * @static
         * @param {data_room.IMergeConfigurationCommitPermission=} [properties] Properties to set
         * @returns {data_room.MergeConfigurationCommitPermission} MergeConfigurationCommitPermission instance
         */
        MergeConfigurationCommitPermission.create = function create(properties) {
            return new MergeConfigurationCommitPermission(properties);
        };

        /**
         * Encodes the specified MergeConfigurationCommitPermission message. Does not implicitly {@link data_room.MergeConfigurationCommitPermission.verify|verify} messages.
         * @function encode
         * @memberof data_room.MergeConfigurationCommitPermission
         * @static
         * @param {data_room.IMergeConfigurationCommitPermission} message MergeConfigurationCommitPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MergeConfigurationCommitPermission.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified MergeConfigurationCommitPermission message, length delimited. Does not implicitly {@link data_room.MergeConfigurationCommitPermission.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.MergeConfigurationCommitPermission
         * @static
         * @param {data_room.IMergeConfigurationCommitPermission} message MergeConfigurationCommitPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MergeConfigurationCommitPermission.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MergeConfigurationCommitPermission message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.MergeConfigurationCommitPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.MergeConfigurationCommitPermission} MergeConfigurationCommitPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MergeConfigurationCommitPermission.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.MergeConfigurationCommitPermission();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MergeConfigurationCommitPermission message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.MergeConfigurationCommitPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.MergeConfigurationCommitPermission} MergeConfigurationCommitPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MergeConfigurationCommitPermission.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MergeConfigurationCommitPermission message.
         * @function verify
         * @memberof data_room.MergeConfigurationCommitPermission
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MergeConfigurationCommitPermission.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a MergeConfigurationCommitPermission message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.MergeConfigurationCommitPermission
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.MergeConfigurationCommitPermission} MergeConfigurationCommitPermission
         */
        MergeConfigurationCommitPermission.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.MergeConfigurationCommitPermission)
                return object;
            return new $root.data_room.MergeConfigurationCommitPermission();
        };

        /**
         * Creates a plain object from a MergeConfigurationCommitPermission message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.MergeConfigurationCommitPermission
         * @static
         * @param {data_room.MergeConfigurationCommitPermission} message MergeConfigurationCommitPermission
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MergeConfigurationCommitPermission.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this MergeConfigurationCommitPermission to JSON.
         * @function toJSON
         * @memberof data_room.MergeConfigurationCommitPermission
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MergeConfigurationCommitPermission.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MergeConfigurationCommitPermission
         * @function getTypeUrl
         * @memberof data_room.MergeConfigurationCommitPermission
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MergeConfigurationCommitPermission.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.MergeConfigurationCommitPermission";
        };

        return MergeConfigurationCommitPermission;
    })();

    data_room.RetrieveComputeResultPermission = (function() {

        /**
         * Properties of a RetrieveComputeResultPermission.
         * @memberof data_room
         * @interface IRetrieveComputeResultPermission
         * @property {string|null} [computeNodeId] RetrieveComputeResultPermission computeNodeId
         */

        /**
         * Constructs a new RetrieveComputeResultPermission.
         * @memberof data_room
         * @classdesc Represents a RetrieveComputeResultPermission.
         * @implements IRetrieveComputeResultPermission
         * @constructor
         * @param {data_room.IRetrieveComputeResultPermission=} [properties] Properties to set
         */
        function RetrieveComputeResultPermission(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RetrieveComputeResultPermission computeNodeId.
         * @member {string} computeNodeId
         * @memberof data_room.RetrieveComputeResultPermission
         * @instance
         */
        RetrieveComputeResultPermission.prototype.computeNodeId = "";

        /**
         * Creates a new RetrieveComputeResultPermission instance using the specified properties.
         * @function create
         * @memberof data_room.RetrieveComputeResultPermission
         * @static
         * @param {data_room.IRetrieveComputeResultPermission=} [properties] Properties to set
         * @returns {data_room.RetrieveComputeResultPermission} RetrieveComputeResultPermission instance
         */
        RetrieveComputeResultPermission.create = function create(properties) {
            return new RetrieveComputeResultPermission(properties);
        };

        /**
         * Encodes the specified RetrieveComputeResultPermission message. Does not implicitly {@link data_room.RetrieveComputeResultPermission.verify|verify} messages.
         * @function encode
         * @memberof data_room.RetrieveComputeResultPermission
         * @static
         * @param {data_room.IRetrieveComputeResultPermission} message RetrieveComputeResultPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveComputeResultPermission.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.computeNodeId != null && Object.hasOwnProperty.call(message, "computeNodeId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.computeNodeId);
            return writer;
        };

        /**
         * Encodes the specified RetrieveComputeResultPermission message, length delimited. Does not implicitly {@link data_room.RetrieveComputeResultPermission.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.RetrieveComputeResultPermission
         * @static
         * @param {data_room.IRetrieveComputeResultPermission} message RetrieveComputeResultPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveComputeResultPermission.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrieveComputeResultPermission message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.RetrieveComputeResultPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.RetrieveComputeResultPermission} RetrieveComputeResultPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveComputeResultPermission.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.RetrieveComputeResultPermission();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.computeNodeId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrieveComputeResultPermission message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.RetrieveComputeResultPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.RetrieveComputeResultPermission} RetrieveComputeResultPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveComputeResultPermission.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrieveComputeResultPermission message.
         * @function verify
         * @memberof data_room.RetrieveComputeResultPermission
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrieveComputeResultPermission.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.computeNodeId != null && message.hasOwnProperty("computeNodeId"))
                if (!$util.isString(message.computeNodeId))
                    return "computeNodeId: string expected";
            return null;
        };

        /**
         * Creates a RetrieveComputeResultPermission message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.RetrieveComputeResultPermission
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.RetrieveComputeResultPermission} RetrieveComputeResultPermission
         */
        RetrieveComputeResultPermission.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.RetrieveComputeResultPermission)
                return object;
            let message = new $root.data_room.RetrieveComputeResultPermission();
            if (object.computeNodeId != null)
                message.computeNodeId = String(object.computeNodeId);
            return message;
        };

        /**
         * Creates a plain object from a RetrieveComputeResultPermission message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.RetrieveComputeResultPermission
         * @static
         * @param {data_room.RetrieveComputeResultPermission} message RetrieveComputeResultPermission
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrieveComputeResultPermission.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.computeNodeId = "";
            if (message.computeNodeId != null && message.hasOwnProperty("computeNodeId"))
                object.computeNodeId = message.computeNodeId;
            return object;
        };

        /**
         * Converts this RetrieveComputeResultPermission to JSON.
         * @function toJSON
         * @memberof data_room.RetrieveComputeResultPermission
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrieveComputeResultPermission.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrieveComputeResultPermission
         * @function getTypeUrl
         * @memberof data_room.RetrieveComputeResultPermission
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrieveComputeResultPermission.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.RetrieveComputeResultPermission";
        };

        return RetrieveComputeResultPermission;
    })();

    data_room.CasAuxiliaryStatePermission = (function() {

        /**
         * Properties of a CasAuxiliaryStatePermission.
         * @memberof data_room
         * @interface ICasAuxiliaryStatePermission
         */

        /**
         * Constructs a new CasAuxiliaryStatePermission.
         * @memberof data_room
         * @classdesc Represents a CasAuxiliaryStatePermission.
         * @implements ICasAuxiliaryStatePermission
         * @constructor
         * @param {data_room.ICasAuxiliaryStatePermission=} [properties] Properties to set
         */
        function CasAuxiliaryStatePermission(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new CasAuxiliaryStatePermission instance using the specified properties.
         * @function create
         * @memberof data_room.CasAuxiliaryStatePermission
         * @static
         * @param {data_room.ICasAuxiliaryStatePermission=} [properties] Properties to set
         * @returns {data_room.CasAuxiliaryStatePermission} CasAuxiliaryStatePermission instance
         */
        CasAuxiliaryStatePermission.create = function create(properties) {
            return new CasAuxiliaryStatePermission(properties);
        };

        /**
         * Encodes the specified CasAuxiliaryStatePermission message. Does not implicitly {@link data_room.CasAuxiliaryStatePermission.verify|verify} messages.
         * @function encode
         * @memberof data_room.CasAuxiliaryStatePermission
         * @static
         * @param {data_room.ICasAuxiliaryStatePermission} message CasAuxiliaryStatePermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CasAuxiliaryStatePermission.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified CasAuxiliaryStatePermission message, length delimited. Does not implicitly {@link data_room.CasAuxiliaryStatePermission.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.CasAuxiliaryStatePermission
         * @static
         * @param {data_room.ICasAuxiliaryStatePermission} message CasAuxiliaryStatePermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CasAuxiliaryStatePermission.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CasAuxiliaryStatePermission message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.CasAuxiliaryStatePermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.CasAuxiliaryStatePermission} CasAuxiliaryStatePermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CasAuxiliaryStatePermission.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.CasAuxiliaryStatePermission();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CasAuxiliaryStatePermission message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.CasAuxiliaryStatePermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.CasAuxiliaryStatePermission} CasAuxiliaryStatePermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CasAuxiliaryStatePermission.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CasAuxiliaryStatePermission message.
         * @function verify
         * @memberof data_room.CasAuxiliaryStatePermission
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CasAuxiliaryStatePermission.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a CasAuxiliaryStatePermission message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.CasAuxiliaryStatePermission
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.CasAuxiliaryStatePermission} CasAuxiliaryStatePermission
         */
        CasAuxiliaryStatePermission.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.CasAuxiliaryStatePermission)
                return object;
            return new $root.data_room.CasAuxiliaryStatePermission();
        };

        /**
         * Creates a plain object from a CasAuxiliaryStatePermission message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.CasAuxiliaryStatePermission
         * @static
         * @param {data_room.CasAuxiliaryStatePermission} message CasAuxiliaryStatePermission
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CasAuxiliaryStatePermission.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this CasAuxiliaryStatePermission to JSON.
         * @function toJSON
         * @memberof data_room.CasAuxiliaryStatePermission
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CasAuxiliaryStatePermission.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CasAuxiliaryStatePermission
         * @function getTypeUrl
         * @memberof data_room.CasAuxiliaryStatePermission
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CasAuxiliaryStatePermission.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.CasAuxiliaryStatePermission";
        };

        return CasAuxiliaryStatePermission;
    })();

    data_room.ReadAuxiliaryStatePermission = (function() {

        /**
         * Properties of a ReadAuxiliaryStatePermission.
         * @memberof data_room
         * @interface IReadAuxiliaryStatePermission
         */

        /**
         * Constructs a new ReadAuxiliaryStatePermission.
         * @memberof data_room
         * @classdesc Represents a ReadAuxiliaryStatePermission.
         * @implements IReadAuxiliaryStatePermission
         * @constructor
         * @param {data_room.IReadAuxiliaryStatePermission=} [properties] Properties to set
         */
        function ReadAuxiliaryStatePermission(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ReadAuxiliaryStatePermission instance using the specified properties.
         * @function create
         * @memberof data_room.ReadAuxiliaryStatePermission
         * @static
         * @param {data_room.IReadAuxiliaryStatePermission=} [properties] Properties to set
         * @returns {data_room.ReadAuxiliaryStatePermission} ReadAuxiliaryStatePermission instance
         */
        ReadAuxiliaryStatePermission.create = function create(properties) {
            return new ReadAuxiliaryStatePermission(properties);
        };

        /**
         * Encodes the specified ReadAuxiliaryStatePermission message. Does not implicitly {@link data_room.ReadAuxiliaryStatePermission.verify|verify} messages.
         * @function encode
         * @memberof data_room.ReadAuxiliaryStatePermission
         * @static
         * @param {data_room.IReadAuxiliaryStatePermission} message ReadAuxiliaryStatePermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReadAuxiliaryStatePermission.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ReadAuxiliaryStatePermission message, length delimited. Does not implicitly {@link data_room.ReadAuxiliaryStatePermission.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_room.ReadAuxiliaryStatePermission
         * @static
         * @param {data_room.IReadAuxiliaryStatePermission} message ReadAuxiliaryStatePermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReadAuxiliaryStatePermission.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReadAuxiliaryStatePermission message from the specified reader or buffer.
         * @function decode
         * @memberof data_room.ReadAuxiliaryStatePermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_room.ReadAuxiliaryStatePermission} ReadAuxiliaryStatePermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReadAuxiliaryStatePermission.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.ReadAuxiliaryStatePermission();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReadAuxiliaryStatePermission message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_room.ReadAuxiliaryStatePermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_room.ReadAuxiliaryStatePermission} ReadAuxiliaryStatePermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReadAuxiliaryStatePermission.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReadAuxiliaryStatePermission message.
         * @function verify
         * @memberof data_room.ReadAuxiliaryStatePermission
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReadAuxiliaryStatePermission.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ReadAuxiliaryStatePermission message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_room.ReadAuxiliaryStatePermission
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_room.ReadAuxiliaryStatePermission} ReadAuxiliaryStatePermission
         */
        ReadAuxiliaryStatePermission.fromObject = function fromObject(object) {
            if (object instanceof $root.data_room.ReadAuxiliaryStatePermission)
                return object;
            return new $root.data_room.ReadAuxiliaryStatePermission();
        };

        /**
         * Creates a plain object from a ReadAuxiliaryStatePermission message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_room.ReadAuxiliaryStatePermission
         * @static
         * @param {data_room.ReadAuxiliaryStatePermission} message ReadAuxiliaryStatePermission
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReadAuxiliaryStatePermission.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ReadAuxiliaryStatePermission to JSON.
         * @function toJSON
         * @memberof data_room.ReadAuxiliaryStatePermission
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReadAuxiliaryStatePermission.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReadAuxiliaryStatePermission
         * @function getTypeUrl
         * @memberof data_room.ReadAuxiliaryStatePermission
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReadAuxiliaryStatePermission.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_room.ReadAuxiliaryStatePermission";
        };

        return ReadAuxiliaryStatePermission;
    })();

    return data_room;
})();

export const data_source_s3 = $root.data_source_s3 = (() => {

    /**
     * Namespace data_source_s3.
     * @exports data_source_s3
     * @namespace
     */
    const data_source_s3 = {};

    data_source_s3.S3Source = (function() {

        /**
         * Properties of a S3Source.
         * @memberof data_source_s3
         * @interface IS3Source
         * @property {string|null} [bucket] S3Source bucket
         * @property {string|null} [region] S3Source region
         * @property {string|null} [objectKey] S3Source objectKey
         */

        /**
         * Constructs a new S3Source.
         * @memberof data_source_s3
         * @classdesc Represents a S3Source.
         * @implements IS3Source
         * @constructor
         * @param {data_source_s3.IS3Source=} [properties] Properties to set
         */
        function S3Source(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * S3Source bucket.
         * @member {string} bucket
         * @memberof data_source_s3.S3Source
         * @instance
         */
        S3Source.prototype.bucket = "";

        /**
         * S3Source region.
         * @member {string} region
         * @memberof data_source_s3.S3Source
         * @instance
         */
        S3Source.prototype.region = "";

        /**
         * S3Source objectKey.
         * @member {string} objectKey
         * @memberof data_source_s3.S3Source
         * @instance
         */
        S3Source.prototype.objectKey = "";

        /**
         * Creates a new S3Source instance using the specified properties.
         * @function create
         * @memberof data_source_s3.S3Source
         * @static
         * @param {data_source_s3.IS3Source=} [properties] Properties to set
         * @returns {data_source_s3.S3Source} S3Source instance
         */
        S3Source.create = function create(properties) {
            return new S3Source(properties);
        };

        /**
         * Encodes the specified S3Source message. Does not implicitly {@link data_source_s3.S3Source.verify|verify} messages.
         * @function encode
         * @memberof data_source_s3.S3Source
         * @static
         * @param {data_source_s3.IS3Source} message S3Source message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        S3Source.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.bucket != null && Object.hasOwnProperty.call(message, "bucket"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.bucket);
            if (message.region != null && Object.hasOwnProperty.call(message, "region"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.region);
            if (message.objectKey != null && Object.hasOwnProperty.call(message, "objectKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.objectKey);
            return writer;
        };

        /**
         * Encodes the specified S3Source message, length delimited. Does not implicitly {@link data_source_s3.S3Source.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_source_s3.S3Source
         * @static
         * @param {data_source_s3.IS3Source} message S3Source message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        S3Source.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a S3Source message from the specified reader or buffer.
         * @function decode
         * @memberof data_source_s3.S3Source
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_source_s3.S3Source} S3Source
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        S3Source.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_source_s3.S3Source();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.bucket = reader.string();
                        break;
                    }
                case 2: {
                        message.region = reader.string();
                        break;
                    }
                case 3: {
                        message.objectKey = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a S3Source message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_source_s3.S3Source
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_source_s3.S3Source} S3Source
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        S3Source.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a S3Source message.
         * @function verify
         * @memberof data_source_s3.S3Source
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        S3Source.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.bucket != null && message.hasOwnProperty("bucket"))
                if (!$util.isString(message.bucket))
                    return "bucket: string expected";
            if (message.region != null && message.hasOwnProperty("region"))
                if (!$util.isString(message.region))
                    return "region: string expected";
            if (message.objectKey != null && message.hasOwnProperty("objectKey"))
                if (!$util.isString(message.objectKey))
                    return "objectKey: string expected";
            return null;
        };

        /**
         * Creates a S3Source message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_source_s3.S3Source
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_source_s3.S3Source} S3Source
         */
        S3Source.fromObject = function fromObject(object) {
            if (object instanceof $root.data_source_s3.S3Source)
                return object;
            let message = new $root.data_source_s3.S3Source();
            if (object.bucket != null)
                message.bucket = String(object.bucket);
            if (object.region != null)
                message.region = String(object.region);
            if (object.objectKey != null)
                message.objectKey = String(object.objectKey);
            return message;
        };

        /**
         * Creates a plain object from a S3Source message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_source_s3.S3Source
         * @static
         * @param {data_source_s3.S3Source} message S3Source
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        S3Source.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.bucket = "";
                object.region = "";
                object.objectKey = "";
            }
            if (message.bucket != null && message.hasOwnProperty("bucket"))
                object.bucket = message.bucket;
            if (message.region != null && message.hasOwnProperty("region"))
                object.region = message.region;
            if (message.objectKey != null && message.hasOwnProperty("objectKey"))
                object.objectKey = message.objectKey;
            return object;
        };

        /**
         * Converts this S3Source to JSON.
         * @function toJSON
         * @memberof data_source_s3.S3Source
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        S3Source.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for S3Source
         * @function getTypeUrl
         * @memberof data_source_s3.S3Source
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        S3Source.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_source_s3.S3Source";
        };

        return S3Source;
    })();

    /**
     * S3Provider enum.
     * @name data_source_s3.S3Provider
     * @enum {number}
     * @property {number} AWS=0 AWS value
     * @property {number} GCS=1 GCS value
     */
    data_source_s3.S3Provider = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "AWS"] = 0;
        values[valuesById[1] = "GCS"] = 1;
        return values;
    })();

    data_source_s3.DataSourceS3WorkerConfiguration = (function() {

        /**
         * Properties of a DataSourceS3WorkerConfiguration.
         * @memberof data_source_s3
         * @interface IDataSourceS3WorkerConfiguration
         * @property {data_source_s3.IS3Source|null} [source] DataSourceS3WorkerConfiguration source
         * @property {string|null} [credentialsDependency] DataSourceS3WorkerConfiguration credentialsDependency
         * @property {data_source_s3.S3Provider|null} [s3Provider] DataSourceS3WorkerConfiguration s3Provider
         */

        /**
         * Constructs a new DataSourceS3WorkerConfiguration.
         * @memberof data_source_s3
         * @classdesc Represents a DataSourceS3WorkerConfiguration.
         * @implements IDataSourceS3WorkerConfiguration
         * @constructor
         * @param {data_source_s3.IDataSourceS3WorkerConfiguration=} [properties] Properties to set
         */
        function DataSourceS3WorkerConfiguration(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DataSourceS3WorkerConfiguration source.
         * @member {data_source_s3.IS3Source|null|undefined} source
         * @memberof data_source_s3.DataSourceS3WorkerConfiguration
         * @instance
         */
        DataSourceS3WorkerConfiguration.prototype.source = null;

        /**
         * DataSourceS3WorkerConfiguration credentialsDependency.
         * @member {string} credentialsDependency
         * @memberof data_source_s3.DataSourceS3WorkerConfiguration
         * @instance
         */
        DataSourceS3WorkerConfiguration.prototype.credentialsDependency = "";

        /**
         * DataSourceS3WorkerConfiguration s3Provider.
         * @member {data_source_s3.S3Provider} s3Provider
         * @memberof data_source_s3.DataSourceS3WorkerConfiguration
         * @instance
         */
        DataSourceS3WorkerConfiguration.prototype.s3Provider = 0;

        /**
         * Creates a new DataSourceS3WorkerConfiguration instance using the specified properties.
         * @function create
         * @memberof data_source_s3.DataSourceS3WorkerConfiguration
         * @static
         * @param {data_source_s3.IDataSourceS3WorkerConfiguration=} [properties] Properties to set
         * @returns {data_source_s3.DataSourceS3WorkerConfiguration} DataSourceS3WorkerConfiguration instance
         */
        DataSourceS3WorkerConfiguration.create = function create(properties) {
            return new DataSourceS3WorkerConfiguration(properties);
        };

        /**
         * Encodes the specified DataSourceS3WorkerConfiguration message. Does not implicitly {@link data_source_s3.DataSourceS3WorkerConfiguration.verify|verify} messages.
         * @function encode
         * @memberof data_source_s3.DataSourceS3WorkerConfiguration
         * @static
         * @param {data_source_s3.IDataSourceS3WorkerConfiguration} message DataSourceS3WorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataSourceS3WorkerConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                $root.data_source_s3.S3Source.encode(message.source, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.credentialsDependency != null && Object.hasOwnProperty.call(message, "credentialsDependency"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.credentialsDependency);
            if (message.s3Provider != null && Object.hasOwnProperty.call(message, "s3Provider"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.s3Provider);
            return writer;
        };

        /**
         * Encodes the specified DataSourceS3WorkerConfiguration message, length delimited. Does not implicitly {@link data_source_s3.DataSourceS3WorkerConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_source_s3.DataSourceS3WorkerConfiguration
         * @static
         * @param {data_source_s3.IDataSourceS3WorkerConfiguration} message DataSourceS3WorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataSourceS3WorkerConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DataSourceS3WorkerConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof data_source_s3.DataSourceS3WorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_source_s3.DataSourceS3WorkerConfiguration} DataSourceS3WorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataSourceS3WorkerConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_source_s3.DataSourceS3WorkerConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.source = $root.data_source_s3.S3Source.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.credentialsDependency = reader.string();
                        break;
                    }
                case 3: {
                        message.s3Provider = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DataSourceS3WorkerConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_source_s3.DataSourceS3WorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_source_s3.DataSourceS3WorkerConfiguration} DataSourceS3WorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataSourceS3WorkerConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DataSourceS3WorkerConfiguration message.
         * @function verify
         * @memberof data_source_s3.DataSourceS3WorkerConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DataSourceS3WorkerConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.source != null && message.hasOwnProperty("source")) {
                let error = $root.data_source_s3.S3Source.verify(message.source);
                if (error)
                    return "source." + error;
            }
            if (message.credentialsDependency != null && message.hasOwnProperty("credentialsDependency"))
                if (!$util.isString(message.credentialsDependency))
                    return "credentialsDependency: string expected";
            if (message.s3Provider != null && message.hasOwnProperty("s3Provider"))
                switch (message.s3Provider) {
                default:
                    return "s3Provider: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates a DataSourceS3WorkerConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_source_s3.DataSourceS3WorkerConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_source_s3.DataSourceS3WorkerConfiguration} DataSourceS3WorkerConfiguration
         */
        DataSourceS3WorkerConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.data_source_s3.DataSourceS3WorkerConfiguration)
                return object;
            let message = new $root.data_source_s3.DataSourceS3WorkerConfiguration();
            if (object.source != null) {
                if (typeof object.source !== "object")
                    throw TypeError(".data_source_s3.DataSourceS3WorkerConfiguration.source: object expected");
                message.source = $root.data_source_s3.S3Source.fromObject(object.source);
            }
            if (object.credentialsDependency != null)
                message.credentialsDependency = String(object.credentialsDependency);
            switch (object.s3Provider) {
            default:
                if (typeof object.s3Provider === "number") {
                    message.s3Provider = object.s3Provider;
                    break;
                }
                break;
            case "AWS":
            case 0:
                message.s3Provider = 0;
                break;
            case "GCS":
            case 1:
                message.s3Provider = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a DataSourceS3WorkerConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_source_s3.DataSourceS3WorkerConfiguration
         * @static
         * @param {data_source_s3.DataSourceS3WorkerConfiguration} message DataSourceS3WorkerConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DataSourceS3WorkerConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.source = null;
                object.credentialsDependency = "";
                object.s3Provider = options.enums === String ? "AWS" : 0;
            }
            if (message.source != null && message.hasOwnProperty("source"))
                object.source = $root.data_source_s3.S3Source.toObject(message.source, options);
            if (message.credentialsDependency != null && message.hasOwnProperty("credentialsDependency"))
                object.credentialsDependency = message.credentialsDependency;
            if (message.s3Provider != null && message.hasOwnProperty("s3Provider"))
                object.s3Provider = options.enums === String ? $root.data_source_s3.S3Provider[message.s3Provider] === undefined ? message.s3Provider : $root.data_source_s3.S3Provider[message.s3Provider] : message.s3Provider;
            return object;
        };

        /**
         * Converts this DataSourceS3WorkerConfiguration to JSON.
         * @function toJSON
         * @memberof data_source_s3.DataSourceS3WorkerConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DataSourceS3WorkerConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DataSourceS3WorkerConfiguration
         * @function getTypeUrl
         * @memberof data_source_s3.DataSourceS3WorkerConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DataSourceS3WorkerConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_source_s3.DataSourceS3WorkerConfiguration";
        };

        return DataSourceS3WorkerConfiguration;
    })();

    return data_source_s3;
})();

export const data_source_snowflake = $root.data_source_snowflake = (() => {

    /**
     * Namespace data_source_snowflake.
     * @exports data_source_snowflake
     * @namespace
     */
    const data_source_snowflake = {};

    data_source_snowflake.SnowflakeSource = (function() {

        /**
         * Properties of a SnowflakeSource.
         * @memberof data_source_snowflake
         * @interface ISnowflakeSource
         * @property {string|null} [warehouseName] SnowflakeSource warehouseName
         * @property {string|null} [databaseName] SnowflakeSource databaseName
         * @property {string|null} [schemaName] SnowflakeSource schemaName
         * @property {string|null} [tableName] SnowflakeSource tableName
         * @property {string|null} [stageName] SnowflakeSource stageName
         */

        /**
         * Constructs a new SnowflakeSource.
         * @memberof data_source_snowflake
         * @classdesc Represents a SnowflakeSource.
         * @implements ISnowflakeSource
         * @constructor
         * @param {data_source_snowflake.ISnowflakeSource=} [properties] Properties to set
         */
        function SnowflakeSource(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SnowflakeSource warehouseName.
         * @member {string} warehouseName
         * @memberof data_source_snowflake.SnowflakeSource
         * @instance
         */
        SnowflakeSource.prototype.warehouseName = "";

        /**
         * SnowflakeSource databaseName.
         * @member {string} databaseName
         * @memberof data_source_snowflake.SnowflakeSource
         * @instance
         */
        SnowflakeSource.prototype.databaseName = "";

        /**
         * SnowflakeSource schemaName.
         * @member {string} schemaName
         * @memberof data_source_snowflake.SnowflakeSource
         * @instance
         */
        SnowflakeSource.prototype.schemaName = "";

        /**
         * SnowflakeSource tableName.
         * @member {string} tableName
         * @memberof data_source_snowflake.SnowflakeSource
         * @instance
         */
        SnowflakeSource.prototype.tableName = "";

        /**
         * SnowflakeSource stageName.
         * @member {string} stageName
         * @memberof data_source_snowflake.SnowflakeSource
         * @instance
         */
        SnowflakeSource.prototype.stageName = "";

        /**
         * Creates a new SnowflakeSource instance using the specified properties.
         * @function create
         * @memberof data_source_snowflake.SnowflakeSource
         * @static
         * @param {data_source_snowflake.ISnowflakeSource=} [properties] Properties to set
         * @returns {data_source_snowflake.SnowflakeSource} SnowflakeSource instance
         */
        SnowflakeSource.create = function create(properties) {
            return new SnowflakeSource(properties);
        };

        /**
         * Encodes the specified SnowflakeSource message. Does not implicitly {@link data_source_snowflake.SnowflakeSource.verify|verify} messages.
         * @function encode
         * @memberof data_source_snowflake.SnowflakeSource
         * @static
         * @param {data_source_snowflake.ISnowflakeSource} message SnowflakeSource message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SnowflakeSource.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.warehouseName != null && Object.hasOwnProperty.call(message, "warehouseName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.warehouseName);
            if (message.databaseName != null && Object.hasOwnProperty.call(message, "databaseName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.databaseName);
            if (message.schemaName != null && Object.hasOwnProperty.call(message, "schemaName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.schemaName);
            if (message.tableName != null && Object.hasOwnProperty.call(message, "tableName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.tableName);
            if (message.stageName != null && Object.hasOwnProperty.call(message, "stageName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.stageName);
            return writer;
        };

        /**
         * Encodes the specified SnowflakeSource message, length delimited. Does not implicitly {@link data_source_snowflake.SnowflakeSource.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_source_snowflake.SnowflakeSource
         * @static
         * @param {data_source_snowflake.ISnowflakeSource} message SnowflakeSource message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SnowflakeSource.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SnowflakeSource message from the specified reader or buffer.
         * @function decode
         * @memberof data_source_snowflake.SnowflakeSource
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_source_snowflake.SnowflakeSource} SnowflakeSource
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SnowflakeSource.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_source_snowflake.SnowflakeSource();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.warehouseName = reader.string();
                        break;
                    }
                case 2: {
                        message.databaseName = reader.string();
                        break;
                    }
                case 3: {
                        message.schemaName = reader.string();
                        break;
                    }
                case 4: {
                        message.tableName = reader.string();
                        break;
                    }
                case 5: {
                        message.stageName = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SnowflakeSource message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_source_snowflake.SnowflakeSource
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_source_snowflake.SnowflakeSource} SnowflakeSource
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SnowflakeSource.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SnowflakeSource message.
         * @function verify
         * @memberof data_source_snowflake.SnowflakeSource
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SnowflakeSource.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.warehouseName != null && message.hasOwnProperty("warehouseName"))
                if (!$util.isString(message.warehouseName))
                    return "warehouseName: string expected";
            if (message.databaseName != null && message.hasOwnProperty("databaseName"))
                if (!$util.isString(message.databaseName))
                    return "databaseName: string expected";
            if (message.schemaName != null && message.hasOwnProperty("schemaName"))
                if (!$util.isString(message.schemaName))
                    return "schemaName: string expected";
            if (message.tableName != null && message.hasOwnProperty("tableName"))
                if (!$util.isString(message.tableName))
                    return "tableName: string expected";
            if (message.stageName != null && message.hasOwnProperty("stageName"))
                if (!$util.isString(message.stageName))
                    return "stageName: string expected";
            return null;
        };

        /**
         * Creates a SnowflakeSource message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_source_snowflake.SnowflakeSource
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_source_snowflake.SnowflakeSource} SnowflakeSource
         */
        SnowflakeSource.fromObject = function fromObject(object) {
            if (object instanceof $root.data_source_snowflake.SnowflakeSource)
                return object;
            let message = new $root.data_source_snowflake.SnowflakeSource();
            if (object.warehouseName != null)
                message.warehouseName = String(object.warehouseName);
            if (object.databaseName != null)
                message.databaseName = String(object.databaseName);
            if (object.schemaName != null)
                message.schemaName = String(object.schemaName);
            if (object.tableName != null)
                message.tableName = String(object.tableName);
            if (object.stageName != null)
                message.stageName = String(object.stageName);
            return message;
        };

        /**
         * Creates a plain object from a SnowflakeSource message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_source_snowflake.SnowflakeSource
         * @static
         * @param {data_source_snowflake.SnowflakeSource} message SnowflakeSource
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SnowflakeSource.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.warehouseName = "";
                object.databaseName = "";
                object.schemaName = "";
                object.tableName = "";
                object.stageName = "";
            }
            if (message.warehouseName != null && message.hasOwnProperty("warehouseName"))
                object.warehouseName = message.warehouseName;
            if (message.databaseName != null && message.hasOwnProperty("databaseName"))
                object.databaseName = message.databaseName;
            if (message.schemaName != null && message.hasOwnProperty("schemaName"))
                object.schemaName = message.schemaName;
            if (message.tableName != null && message.hasOwnProperty("tableName"))
                object.tableName = message.tableName;
            if (message.stageName != null && message.hasOwnProperty("stageName"))
                object.stageName = message.stageName;
            return object;
        };

        /**
         * Converts this SnowflakeSource to JSON.
         * @function toJSON
         * @memberof data_source_snowflake.SnowflakeSource
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SnowflakeSource.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SnowflakeSource
         * @function getTypeUrl
         * @memberof data_source_snowflake.SnowflakeSource
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SnowflakeSource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_source_snowflake.SnowflakeSource";
        };

        return SnowflakeSource;
    })();

    data_source_snowflake.DataSourceSnowflakeWorkerConfiguration = (function() {

        /**
         * Properties of a DataSourceSnowflakeWorkerConfiguration.
         * @memberof data_source_snowflake
         * @interface IDataSourceSnowflakeWorkerConfiguration
         * @property {data_source_snowflake.ISnowflakeSource|null} [source] DataSourceSnowflakeWorkerConfiguration source
         * @property {string|null} [credentialsDependency] DataSourceSnowflakeWorkerConfiguration credentialsDependency
         */

        /**
         * Constructs a new DataSourceSnowflakeWorkerConfiguration.
         * @memberof data_source_snowflake
         * @classdesc Represents a DataSourceSnowflakeWorkerConfiguration.
         * @implements IDataSourceSnowflakeWorkerConfiguration
         * @constructor
         * @param {data_source_snowflake.IDataSourceSnowflakeWorkerConfiguration=} [properties] Properties to set
         */
        function DataSourceSnowflakeWorkerConfiguration(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DataSourceSnowflakeWorkerConfiguration source.
         * @member {data_source_snowflake.ISnowflakeSource|null|undefined} source
         * @memberof data_source_snowflake.DataSourceSnowflakeWorkerConfiguration
         * @instance
         */
        DataSourceSnowflakeWorkerConfiguration.prototype.source = null;

        /**
         * DataSourceSnowflakeWorkerConfiguration credentialsDependency.
         * @member {string} credentialsDependency
         * @memberof data_source_snowflake.DataSourceSnowflakeWorkerConfiguration
         * @instance
         */
        DataSourceSnowflakeWorkerConfiguration.prototype.credentialsDependency = "";

        /**
         * Creates a new DataSourceSnowflakeWorkerConfiguration instance using the specified properties.
         * @function create
         * @memberof data_source_snowflake.DataSourceSnowflakeWorkerConfiguration
         * @static
         * @param {data_source_snowflake.IDataSourceSnowflakeWorkerConfiguration=} [properties] Properties to set
         * @returns {data_source_snowflake.DataSourceSnowflakeWorkerConfiguration} DataSourceSnowflakeWorkerConfiguration instance
         */
        DataSourceSnowflakeWorkerConfiguration.create = function create(properties) {
            return new DataSourceSnowflakeWorkerConfiguration(properties);
        };

        /**
         * Encodes the specified DataSourceSnowflakeWorkerConfiguration message. Does not implicitly {@link data_source_snowflake.DataSourceSnowflakeWorkerConfiguration.verify|verify} messages.
         * @function encode
         * @memberof data_source_snowflake.DataSourceSnowflakeWorkerConfiguration
         * @static
         * @param {data_source_snowflake.IDataSourceSnowflakeWorkerConfiguration} message DataSourceSnowflakeWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataSourceSnowflakeWorkerConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                $root.data_source_snowflake.SnowflakeSource.encode(message.source, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.credentialsDependency != null && Object.hasOwnProperty.call(message, "credentialsDependency"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.credentialsDependency);
            return writer;
        };

        /**
         * Encodes the specified DataSourceSnowflakeWorkerConfiguration message, length delimited. Does not implicitly {@link data_source_snowflake.DataSourceSnowflakeWorkerConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof data_source_snowflake.DataSourceSnowflakeWorkerConfiguration
         * @static
         * @param {data_source_snowflake.IDataSourceSnowflakeWorkerConfiguration} message DataSourceSnowflakeWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataSourceSnowflakeWorkerConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DataSourceSnowflakeWorkerConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof data_source_snowflake.DataSourceSnowflakeWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {data_source_snowflake.DataSourceSnowflakeWorkerConfiguration} DataSourceSnowflakeWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataSourceSnowflakeWorkerConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_source_snowflake.DataSourceSnowflakeWorkerConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.source = $root.data_source_snowflake.SnowflakeSource.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.credentialsDependency = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DataSourceSnowflakeWorkerConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof data_source_snowflake.DataSourceSnowflakeWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {data_source_snowflake.DataSourceSnowflakeWorkerConfiguration} DataSourceSnowflakeWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataSourceSnowflakeWorkerConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DataSourceSnowflakeWorkerConfiguration message.
         * @function verify
         * @memberof data_source_snowflake.DataSourceSnowflakeWorkerConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DataSourceSnowflakeWorkerConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.source != null && message.hasOwnProperty("source")) {
                let error = $root.data_source_snowflake.SnowflakeSource.verify(message.source);
                if (error)
                    return "source." + error;
            }
            if (message.credentialsDependency != null && message.hasOwnProperty("credentialsDependency"))
                if (!$util.isString(message.credentialsDependency))
                    return "credentialsDependency: string expected";
            return null;
        };

        /**
         * Creates a DataSourceSnowflakeWorkerConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof data_source_snowflake.DataSourceSnowflakeWorkerConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {data_source_snowflake.DataSourceSnowflakeWorkerConfiguration} DataSourceSnowflakeWorkerConfiguration
         */
        DataSourceSnowflakeWorkerConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.data_source_snowflake.DataSourceSnowflakeWorkerConfiguration)
                return object;
            let message = new $root.data_source_snowflake.DataSourceSnowflakeWorkerConfiguration();
            if (object.source != null) {
                if (typeof object.source !== "object")
                    throw TypeError(".data_source_snowflake.DataSourceSnowflakeWorkerConfiguration.source: object expected");
                message.source = $root.data_source_snowflake.SnowflakeSource.fromObject(object.source);
            }
            if (object.credentialsDependency != null)
                message.credentialsDependency = String(object.credentialsDependency);
            return message;
        };

        /**
         * Creates a plain object from a DataSourceSnowflakeWorkerConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof data_source_snowflake.DataSourceSnowflakeWorkerConfiguration
         * @static
         * @param {data_source_snowflake.DataSourceSnowflakeWorkerConfiguration} message DataSourceSnowflakeWorkerConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DataSourceSnowflakeWorkerConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.source = null;
                object.credentialsDependency = "";
            }
            if (message.source != null && message.hasOwnProperty("source"))
                object.source = $root.data_source_snowflake.SnowflakeSource.toObject(message.source, options);
            if (message.credentialsDependency != null && message.hasOwnProperty("credentialsDependency"))
                object.credentialsDependency = message.credentialsDependency;
            return object;
        };

        /**
         * Converts this DataSourceSnowflakeWorkerConfiguration to JSON.
         * @function toJSON
         * @memberof data_source_snowflake.DataSourceSnowflakeWorkerConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DataSourceSnowflakeWorkerConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DataSourceSnowflakeWorkerConfiguration
         * @function getTypeUrl
         * @memberof data_source_snowflake.DataSourceSnowflakeWorkerConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DataSourceSnowflakeWorkerConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/data_source_snowflake.DataSourceSnowflakeWorkerConfiguration";
        };

        return DataSourceSnowflakeWorkerConfiguration;
    })();

    return data_source_snowflake;
})();

export const dataset_sink = $root.dataset_sink = (() => {

    /**
     * Namespace dataset_sink.
     * @exports dataset_sink
     * @namespace
     */
    const dataset_sink = {};

    dataset_sink.RawFile = (function() {

        /**
         * Properties of a RawFile.
         * @memberof dataset_sink
         * @interface IRawFile
         */

        /**
         * Constructs a new RawFile.
         * @memberof dataset_sink
         * @classdesc Represents a RawFile.
         * @implements IRawFile
         * @constructor
         * @param {dataset_sink.IRawFile=} [properties] Properties to set
         */
        function RawFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RawFile instance using the specified properties.
         * @function create
         * @memberof dataset_sink.RawFile
         * @static
         * @param {dataset_sink.IRawFile=} [properties] Properties to set
         * @returns {dataset_sink.RawFile} RawFile instance
         */
        RawFile.create = function create(properties) {
            return new RawFile(properties);
        };

        /**
         * Encodes the specified RawFile message. Does not implicitly {@link dataset_sink.RawFile.verify|verify} messages.
         * @function encode
         * @memberof dataset_sink.RawFile
         * @static
         * @param {dataset_sink.IRawFile} message RawFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RawFile message, length delimited. Does not implicitly {@link dataset_sink.RawFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof dataset_sink.RawFile
         * @static
         * @param {dataset_sink.IRawFile} message RawFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RawFile message from the specified reader or buffer.
         * @function decode
         * @memberof dataset_sink.RawFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {dataset_sink.RawFile} RawFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.dataset_sink.RawFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RawFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof dataset_sink.RawFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {dataset_sink.RawFile} RawFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RawFile message.
         * @function verify
         * @memberof dataset_sink.RawFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RawFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RawFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof dataset_sink.RawFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {dataset_sink.RawFile} RawFile
         */
        RawFile.fromObject = function fromObject(object) {
            if (object instanceof $root.dataset_sink.RawFile)
                return object;
            return new $root.dataset_sink.RawFile();
        };

        /**
         * Creates a plain object from a RawFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof dataset_sink.RawFile
         * @static
         * @param {dataset_sink.RawFile} message RawFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RawFile.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RawFile to JSON.
         * @function toJSON
         * @memberof dataset_sink.RawFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RawFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RawFile
         * @function getTypeUrl
         * @memberof dataset_sink.RawFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RawFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/dataset_sink.RawFile";
        };

        return RawFile;
    })();

    dataset_sink.AllFiles = (function() {

        /**
         * Properties of an AllFiles.
         * @memberof dataset_sink
         * @interface IAllFiles
         */

        /**
         * Constructs a new AllFiles.
         * @memberof dataset_sink
         * @classdesc Represents an AllFiles.
         * @implements IAllFiles
         * @constructor
         * @param {dataset_sink.IAllFiles=} [properties] Properties to set
         */
        function AllFiles(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new AllFiles instance using the specified properties.
         * @function create
         * @memberof dataset_sink.AllFiles
         * @static
         * @param {dataset_sink.IAllFiles=} [properties] Properties to set
         * @returns {dataset_sink.AllFiles} AllFiles instance
         */
        AllFiles.create = function create(properties) {
            return new AllFiles(properties);
        };

        /**
         * Encodes the specified AllFiles message. Does not implicitly {@link dataset_sink.AllFiles.verify|verify} messages.
         * @function encode
         * @memberof dataset_sink.AllFiles
         * @static
         * @param {dataset_sink.IAllFiles} message AllFiles message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AllFiles.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified AllFiles message, length delimited. Does not implicitly {@link dataset_sink.AllFiles.verify|verify} messages.
         * @function encodeDelimited
         * @memberof dataset_sink.AllFiles
         * @static
         * @param {dataset_sink.IAllFiles} message AllFiles message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AllFiles.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AllFiles message from the specified reader or buffer.
         * @function decode
         * @memberof dataset_sink.AllFiles
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {dataset_sink.AllFiles} AllFiles
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AllFiles.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.dataset_sink.AllFiles();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AllFiles message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof dataset_sink.AllFiles
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {dataset_sink.AllFiles} AllFiles
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AllFiles.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AllFiles message.
         * @function verify
         * @memberof dataset_sink.AllFiles
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AllFiles.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an AllFiles message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof dataset_sink.AllFiles
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {dataset_sink.AllFiles} AllFiles
         */
        AllFiles.fromObject = function fromObject(object) {
            if (object instanceof $root.dataset_sink.AllFiles)
                return object;
            return new $root.dataset_sink.AllFiles();
        };

        /**
         * Creates a plain object from an AllFiles message. Also converts values to other types if specified.
         * @function toObject
         * @memberof dataset_sink.AllFiles
         * @static
         * @param {dataset_sink.AllFiles} message AllFiles
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AllFiles.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this AllFiles to JSON.
         * @function toJSON
         * @memberof dataset_sink.AllFiles
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AllFiles.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AllFiles
         * @function getTypeUrl
         * @memberof dataset_sink.AllFiles
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AllFiles.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/dataset_sink.AllFiles";
        };

        return AllFiles;
    })();

    dataset_sink.SingleFile = (function() {

        /**
         * Properties of a SingleFile.
         * @memberof dataset_sink
         * @interface ISingleFile
         * @property {string|null} [name] SingleFile name
         * @property {string|null} [renameAs] SingleFile renameAs
         */

        /**
         * Constructs a new SingleFile.
         * @memberof dataset_sink
         * @classdesc Represents a SingleFile.
         * @implements ISingleFile
         * @constructor
         * @param {dataset_sink.ISingleFile=} [properties] Properties to set
         */
        function SingleFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SingleFile name.
         * @member {string} name
         * @memberof dataset_sink.SingleFile
         * @instance
         */
        SingleFile.prototype.name = "";

        /**
         * SingleFile renameAs.
         * @member {string|null|undefined} renameAs
         * @memberof dataset_sink.SingleFile
         * @instance
         */
        SingleFile.prototype.renameAs = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SingleFile _renameAs.
         * @member {"renameAs"|undefined} _renameAs
         * @memberof dataset_sink.SingleFile
         * @instance
         */
        Object.defineProperty(SingleFile.prototype, "_renameAs", {
            get: $util.oneOfGetter($oneOfFields = ["renameAs"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SingleFile instance using the specified properties.
         * @function create
         * @memberof dataset_sink.SingleFile
         * @static
         * @param {dataset_sink.ISingleFile=} [properties] Properties to set
         * @returns {dataset_sink.SingleFile} SingleFile instance
         */
        SingleFile.create = function create(properties) {
            return new SingleFile(properties);
        };

        /**
         * Encodes the specified SingleFile message. Does not implicitly {@link dataset_sink.SingleFile.verify|verify} messages.
         * @function encode
         * @memberof dataset_sink.SingleFile
         * @static
         * @param {dataset_sink.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.renameAs != null && Object.hasOwnProperty.call(message, "renameAs"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.renameAs);
            return writer;
        };

        /**
         * Encodes the specified SingleFile message, length delimited. Does not implicitly {@link dataset_sink.SingleFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof dataset_sink.SingleFile
         * @static
         * @param {dataset_sink.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer.
         * @function decode
         * @memberof dataset_sink.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {dataset_sink.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.dataset_sink.SingleFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.renameAs = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof dataset_sink.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {dataset_sink.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SingleFile message.
         * @function verify
         * @memberof dataset_sink.SingleFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SingleFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.renameAs != null && message.hasOwnProperty("renameAs")) {
                properties._renameAs = 1;
                if (!$util.isString(message.renameAs))
                    return "renameAs: string expected";
            }
            return null;
        };

        /**
         * Creates a SingleFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof dataset_sink.SingleFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {dataset_sink.SingleFile} SingleFile
         */
        SingleFile.fromObject = function fromObject(object) {
            if (object instanceof $root.dataset_sink.SingleFile)
                return object;
            let message = new $root.dataset_sink.SingleFile();
            if (object.name != null)
                message.name = String(object.name);
            if (object.renameAs != null)
                message.renameAs = String(object.renameAs);
            return message;
        };

        /**
         * Creates a plain object from a SingleFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof dataset_sink.SingleFile
         * @static
         * @param {dataset_sink.SingleFile} message SingleFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SingleFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.renameAs != null && message.hasOwnProperty("renameAs")) {
                object.renameAs = message.renameAs;
                if (options.oneofs)
                    object._renameAs = "renameAs";
            }
            return object;
        };

        /**
         * Converts this SingleFile to JSON.
         * @function toJSON
         * @memberof dataset_sink.SingleFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SingleFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SingleFile
         * @function getTypeUrl
         * @memberof dataset_sink.SingleFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SingleFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/dataset_sink.SingleFile";
        };

        return SingleFile;
    })();

    dataset_sink.FileSelection = (function() {

        /**
         * Properties of a FileSelection.
         * @memberof dataset_sink
         * @interface IFileSelection
         * @property {Array.<dataset_sink.ISingleFile>|null} [names] FileSelection names
         */

        /**
         * Constructs a new FileSelection.
         * @memberof dataset_sink
         * @classdesc Represents a FileSelection.
         * @implements IFileSelection
         * @constructor
         * @param {dataset_sink.IFileSelection=} [properties] Properties to set
         */
        function FileSelection(properties) {
            this.names = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FileSelection names.
         * @member {Array.<dataset_sink.ISingleFile>} names
         * @memberof dataset_sink.FileSelection
         * @instance
         */
        FileSelection.prototype.names = $util.emptyArray;

        /**
         * Creates a new FileSelection instance using the specified properties.
         * @function create
         * @memberof dataset_sink.FileSelection
         * @static
         * @param {dataset_sink.IFileSelection=} [properties] Properties to set
         * @returns {dataset_sink.FileSelection} FileSelection instance
         */
        FileSelection.create = function create(properties) {
            return new FileSelection(properties);
        };

        /**
         * Encodes the specified FileSelection message. Does not implicitly {@link dataset_sink.FileSelection.verify|verify} messages.
         * @function encode
         * @memberof dataset_sink.FileSelection
         * @static
         * @param {dataset_sink.IFileSelection} message FileSelection message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FileSelection.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.names != null && message.names.length)
                for (let i = 0; i < message.names.length; ++i)
                    $root.dataset_sink.SingleFile.encode(message.names[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified FileSelection message, length delimited. Does not implicitly {@link dataset_sink.FileSelection.verify|verify} messages.
         * @function encodeDelimited
         * @memberof dataset_sink.FileSelection
         * @static
         * @param {dataset_sink.IFileSelection} message FileSelection message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FileSelection.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FileSelection message from the specified reader or buffer.
         * @function decode
         * @memberof dataset_sink.FileSelection
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {dataset_sink.FileSelection} FileSelection
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FileSelection.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.dataset_sink.FileSelection();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.names && message.names.length))
                            message.names = [];
                        message.names.push($root.dataset_sink.SingleFile.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FileSelection message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof dataset_sink.FileSelection
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {dataset_sink.FileSelection} FileSelection
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FileSelection.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FileSelection message.
         * @function verify
         * @memberof dataset_sink.FileSelection
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FileSelection.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.names != null && message.hasOwnProperty("names")) {
                if (!Array.isArray(message.names))
                    return "names: array expected";
                for (let i = 0; i < message.names.length; ++i) {
                    let error = $root.dataset_sink.SingleFile.verify(message.names[i]);
                    if (error)
                        return "names." + error;
                }
            }
            return null;
        };

        /**
         * Creates a FileSelection message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof dataset_sink.FileSelection
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {dataset_sink.FileSelection} FileSelection
         */
        FileSelection.fromObject = function fromObject(object) {
            if (object instanceof $root.dataset_sink.FileSelection)
                return object;
            let message = new $root.dataset_sink.FileSelection();
            if (object.names) {
                if (!Array.isArray(object.names))
                    throw TypeError(".dataset_sink.FileSelection.names: array expected");
                message.names = [];
                for (let i = 0; i < object.names.length; ++i) {
                    if (typeof object.names[i] !== "object")
                        throw TypeError(".dataset_sink.FileSelection.names: object expected");
                    message.names[i] = $root.dataset_sink.SingleFile.fromObject(object.names[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a FileSelection message. Also converts values to other types if specified.
         * @function toObject
         * @memberof dataset_sink.FileSelection
         * @static
         * @param {dataset_sink.FileSelection} message FileSelection
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FileSelection.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.names = [];
            if (message.names && message.names.length) {
                object.names = [];
                for (let j = 0; j < message.names.length; ++j)
                    object.names[j] = $root.dataset_sink.SingleFile.toObject(message.names[j], options);
            }
            return object;
        };

        /**
         * Converts this FileSelection to JSON.
         * @function toJSON
         * @memberof dataset_sink.FileSelection
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FileSelection.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FileSelection
         * @function getTypeUrl
         * @memberof dataset_sink.FileSelection
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FileSelection.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/dataset_sink.FileSelection";
        };

        return FileSelection;
    })();

    dataset_sink.ZipFile = (function() {

        /**
         * Properties of a ZipFile.
         * @memberof dataset_sink
         * @interface IZipFile
         * @property {dataset_sink.IAllFiles|null} [all] ZipFile all
         * @property {dataset_sink.IFileSelection|null} [files] ZipFile files
         */

        /**
         * Constructs a new ZipFile.
         * @memberof dataset_sink
         * @classdesc Represents a ZipFile.
         * @implements IZipFile
         * @constructor
         * @param {dataset_sink.IZipFile=} [properties] Properties to set
         */
        function ZipFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ZipFile all.
         * @member {dataset_sink.IAllFiles|null|undefined} all
         * @memberof dataset_sink.ZipFile
         * @instance
         */
        ZipFile.prototype.all = null;

        /**
         * ZipFile files.
         * @member {dataset_sink.IFileSelection|null|undefined} files
         * @memberof dataset_sink.ZipFile
         * @instance
         */
        ZipFile.prototype.files = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ZipFile selection.
         * @member {"all"|"files"|undefined} selection
         * @memberof dataset_sink.ZipFile
         * @instance
         */
        Object.defineProperty(ZipFile.prototype, "selection", {
            get: $util.oneOfGetter($oneOfFields = ["all", "files"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ZipFile instance using the specified properties.
         * @function create
         * @memberof dataset_sink.ZipFile
         * @static
         * @param {dataset_sink.IZipFile=} [properties] Properties to set
         * @returns {dataset_sink.ZipFile} ZipFile instance
         */
        ZipFile.create = function create(properties) {
            return new ZipFile(properties);
        };

        /**
         * Encodes the specified ZipFile message. Does not implicitly {@link dataset_sink.ZipFile.verify|verify} messages.
         * @function encode
         * @memberof dataset_sink.ZipFile
         * @static
         * @param {dataset_sink.IZipFile} message ZipFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.all != null && Object.hasOwnProperty.call(message, "all"))
                $root.dataset_sink.AllFiles.encode(message.all, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.files != null && Object.hasOwnProperty.call(message, "files"))
                $root.dataset_sink.FileSelection.encode(message.files, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ZipFile message, length delimited. Does not implicitly {@link dataset_sink.ZipFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof dataset_sink.ZipFile
         * @static
         * @param {dataset_sink.IZipFile} message ZipFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ZipFile message from the specified reader or buffer.
         * @function decode
         * @memberof dataset_sink.ZipFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {dataset_sink.ZipFile} ZipFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.dataset_sink.ZipFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.all = $root.dataset_sink.AllFiles.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.files = $root.dataset_sink.FileSelection.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ZipFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof dataset_sink.ZipFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {dataset_sink.ZipFile} ZipFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ZipFile message.
         * @function verify
         * @memberof dataset_sink.ZipFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ZipFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.all != null && message.hasOwnProperty("all")) {
                properties.selection = 1;
                {
                    let error = $root.dataset_sink.AllFiles.verify(message.all);
                    if (error)
                        return "all." + error;
                }
            }
            if (message.files != null && message.hasOwnProperty("files")) {
                if (properties.selection === 1)
                    return "selection: multiple values";
                properties.selection = 1;
                {
                    let error = $root.dataset_sink.FileSelection.verify(message.files);
                    if (error)
                        return "files." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ZipFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof dataset_sink.ZipFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {dataset_sink.ZipFile} ZipFile
         */
        ZipFile.fromObject = function fromObject(object) {
            if (object instanceof $root.dataset_sink.ZipFile)
                return object;
            let message = new $root.dataset_sink.ZipFile();
            if (object.all != null) {
                if (typeof object.all !== "object")
                    throw TypeError(".dataset_sink.ZipFile.all: object expected");
                message.all = $root.dataset_sink.AllFiles.fromObject(object.all);
            }
            if (object.files != null) {
                if (typeof object.files !== "object")
                    throw TypeError(".dataset_sink.ZipFile.files: object expected");
                message.files = $root.dataset_sink.FileSelection.fromObject(object.files);
            }
            return message;
        };

        /**
         * Creates a plain object from a ZipFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof dataset_sink.ZipFile
         * @static
         * @param {dataset_sink.ZipFile} message ZipFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ZipFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.all != null && message.hasOwnProperty("all")) {
                object.all = $root.dataset_sink.AllFiles.toObject(message.all, options);
                if (options.oneofs)
                    object.selection = "all";
            }
            if (message.files != null && message.hasOwnProperty("files")) {
                object.files = $root.dataset_sink.FileSelection.toObject(message.files, options);
                if (options.oneofs)
                    object.selection = "files";
            }
            return object;
        };

        /**
         * Converts this ZipFile to JSON.
         * @function toJSON
         * @memberof dataset_sink.ZipFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ZipFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ZipFile
         * @function getTypeUrl
         * @memberof dataset_sink.ZipFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ZipFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/dataset_sink.ZipFile";
        };

        return ZipFile;
    })();

    dataset_sink.SinkInput = (function() {

        /**
         * Properties of a SinkInput.
         * @memberof dataset_sink
         * @interface ISinkInput
         * @property {string|null} [dependency] SinkInput dependency
         * @property {string|null} [name] SinkInput name
         * @property {dataset_sink.IRawFile|null} [raw] SinkInput raw
         * @property {dataset_sink.IZipFile|null} [zip] SinkInput zip
         */

        /**
         * Constructs a new SinkInput.
         * @memberof dataset_sink
         * @classdesc Represents a SinkInput.
         * @implements ISinkInput
         * @constructor
         * @param {dataset_sink.ISinkInput=} [properties] Properties to set
         */
        function SinkInput(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SinkInput dependency.
         * @member {string} dependency
         * @memberof dataset_sink.SinkInput
         * @instance
         */
        SinkInput.prototype.dependency = "";

        /**
         * SinkInput name.
         * @member {string} name
         * @memberof dataset_sink.SinkInput
         * @instance
         */
        SinkInput.prototype.name = "";

        /**
         * SinkInput raw.
         * @member {dataset_sink.IRawFile|null|undefined} raw
         * @memberof dataset_sink.SinkInput
         * @instance
         */
        SinkInput.prototype.raw = null;

        /**
         * SinkInput zip.
         * @member {dataset_sink.IZipFile|null|undefined} zip
         * @memberof dataset_sink.SinkInput
         * @instance
         */
        SinkInput.prototype.zip = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SinkInput file.
         * @member {"raw"|"zip"|undefined} file
         * @memberof dataset_sink.SinkInput
         * @instance
         */
        Object.defineProperty(SinkInput.prototype, "file", {
            get: $util.oneOfGetter($oneOfFields = ["raw", "zip"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SinkInput instance using the specified properties.
         * @function create
         * @memberof dataset_sink.SinkInput
         * @static
         * @param {dataset_sink.ISinkInput=} [properties] Properties to set
         * @returns {dataset_sink.SinkInput} SinkInput instance
         */
        SinkInput.create = function create(properties) {
            return new SinkInput(properties);
        };

        /**
         * Encodes the specified SinkInput message. Does not implicitly {@link dataset_sink.SinkInput.verify|verify} messages.
         * @function encode
         * @memberof dataset_sink.SinkInput
         * @static
         * @param {dataset_sink.ISinkInput} message SinkInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SinkInput.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dependency != null && Object.hasOwnProperty.call(message, "dependency"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dependency);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.raw != null && Object.hasOwnProperty.call(message, "raw"))
                $root.dataset_sink.RawFile.encode(message.raw, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.zip != null && Object.hasOwnProperty.call(message, "zip"))
                $root.dataset_sink.ZipFile.encode(message.zip, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SinkInput message, length delimited. Does not implicitly {@link dataset_sink.SinkInput.verify|verify} messages.
         * @function encodeDelimited
         * @memberof dataset_sink.SinkInput
         * @static
         * @param {dataset_sink.ISinkInput} message SinkInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SinkInput.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SinkInput message from the specified reader or buffer.
         * @function decode
         * @memberof dataset_sink.SinkInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {dataset_sink.SinkInput} SinkInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SinkInput.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.dataset_sink.SinkInput();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dependency = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.raw = $root.dataset_sink.RawFile.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.zip = $root.dataset_sink.ZipFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SinkInput message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof dataset_sink.SinkInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {dataset_sink.SinkInput} SinkInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SinkInput.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SinkInput message.
         * @function verify
         * @memberof dataset_sink.SinkInput
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SinkInput.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                if (!$util.isString(message.dependency))
                    return "dependency: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.raw != null && message.hasOwnProperty("raw")) {
                properties.file = 1;
                {
                    let error = $root.dataset_sink.RawFile.verify(message.raw);
                    if (error)
                        return "raw." + error;
                }
            }
            if (message.zip != null && message.hasOwnProperty("zip")) {
                if (properties.file === 1)
                    return "file: multiple values";
                properties.file = 1;
                {
                    let error = $root.dataset_sink.ZipFile.verify(message.zip);
                    if (error)
                        return "zip." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SinkInput message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof dataset_sink.SinkInput
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {dataset_sink.SinkInput} SinkInput
         */
        SinkInput.fromObject = function fromObject(object) {
            if (object instanceof $root.dataset_sink.SinkInput)
                return object;
            let message = new $root.dataset_sink.SinkInput();
            if (object.dependency != null)
                message.dependency = String(object.dependency);
            if (object.name != null)
                message.name = String(object.name);
            if (object.raw != null) {
                if (typeof object.raw !== "object")
                    throw TypeError(".dataset_sink.SinkInput.raw: object expected");
                message.raw = $root.dataset_sink.RawFile.fromObject(object.raw);
            }
            if (object.zip != null) {
                if (typeof object.zip !== "object")
                    throw TypeError(".dataset_sink.SinkInput.zip: object expected");
                message.zip = $root.dataset_sink.ZipFile.fromObject(object.zip);
            }
            return message;
        };

        /**
         * Creates a plain object from a SinkInput message. Also converts values to other types if specified.
         * @function toObject
         * @memberof dataset_sink.SinkInput
         * @static
         * @param {dataset_sink.SinkInput} message SinkInput
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SinkInput.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.dependency = "";
                object.name = "";
            }
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                object.dependency = message.dependency;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.raw != null && message.hasOwnProperty("raw")) {
                object.raw = $root.dataset_sink.RawFile.toObject(message.raw, options);
                if (options.oneofs)
                    object.file = "raw";
            }
            if (message.zip != null && message.hasOwnProperty("zip")) {
                object.zip = $root.dataset_sink.ZipFile.toObject(message.zip, options);
                if (options.oneofs)
                    object.file = "zip";
            }
            return object;
        };

        /**
         * Converts this SinkInput to JSON.
         * @function toJSON
         * @memberof dataset_sink.SinkInput
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SinkInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SinkInput
         * @function getTypeUrl
         * @memberof dataset_sink.SinkInput
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SinkInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/dataset_sink.SinkInput";
        };

        return SinkInput;
    })();

    dataset_sink.DatasetSinkWorkerConfiguration = (function() {

        /**
         * Properties of a DatasetSinkWorkerConfiguration.
         * @memberof dataset_sink
         * @interface IDatasetSinkWorkerConfiguration
         * @property {Array.<dataset_sink.ISinkInput>|null} [inputs] DatasetSinkWorkerConfiguration inputs
         * @property {string|null} [encryptionKeyDependency] DatasetSinkWorkerConfiguration encryptionKeyDependency
         * @property {string|null} [datasetImportId] DatasetSinkWorkerConfiguration datasetImportId
         * @property {boolean|null} [isKeyHexEncoded] DatasetSinkWorkerConfiguration isKeyHexEncoded
         */

        /**
         * Constructs a new DatasetSinkWorkerConfiguration.
         * @memberof dataset_sink
         * @classdesc Represents a DatasetSinkWorkerConfiguration.
         * @implements IDatasetSinkWorkerConfiguration
         * @constructor
         * @param {dataset_sink.IDatasetSinkWorkerConfiguration=} [properties] Properties to set
         */
        function DatasetSinkWorkerConfiguration(properties) {
            this.inputs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DatasetSinkWorkerConfiguration inputs.
         * @member {Array.<dataset_sink.ISinkInput>} inputs
         * @memberof dataset_sink.DatasetSinkWorkerConfiguration
         * @instance
         */
        DatasetSinkWorkerConfiguration.prototype.inputs = $util.emptyArray;

        /**
         * DatasetSinkWorkerConfiguration encryptionKeyDependency.
         * @member {string} encryptionKeyDependency
         * @memberof dataset_sink.DatasetSinkWorkerConfiguration
         * @instance
         */
        DatasetSinkWorkerConfiguration.prototype.encryptionKeyDependency = "";

        /**
         * DatasetSinkWorkerConfiguration datasetImportId.
         * @member {string|null|undefined} datasetImportId
         * @memberof dataset_sink.DatasetSinkWorkerConfiguration
         * @instance
         */
        DatasetSinkWorkerConfiguration.prototype.datasetImportId = null;

        /**
         * DatasetSinkWorkerConfiguration isKeyHexEncoded.
         * @member {boolean} isKeyHexEncoded
         * @memberof dataset_sink.DatasetSinkWorkerConfiguration
         * @instance
         */
        DatasetSinkWorkerConfiguration.prototype.isKeyHexEncoded = false;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * DatasetSinkWorkerConfiguration _datasetImportId.
         * @member {"datasetImportId"|undefined} _datasetImportId
         * @memberof dataset_sink.DatasetSinkWorkerConfiguration
         * @instance
         */
        Object.defineProperty(DatasetSinkWorkerConfiguration.prototype, "_datasetImportId", {
            get: $util.oneOfGetter($oneOfFields = ["datasetImportId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new DatasetSinkWorkerConfiguration instance using the specified properties.
         * @function create
         * @memberof dataset_sink.DatasetSinkWorkerConfiguration
         * @static
         * @param {dataset_sink.IDatasetSinkWorkerConfiguration=} [properties] Properties to set
         * @returns {dataset_sink.DatasetSinkWorkerConfiguration} DatasetSinkWorkerConfiguration instance
         */
        DatasetSinkWorkerConfiguration.create = function create(properties) {
            return new DatasetSinkWorkerConfiguration(properties);
        };

        /**
         * Encodes the specified DatasetSinkWorkerConfiguration message. Does not implicitly {@link dataset_sink.DatasetSinkWorkerConfiguration.verify|verify} messages.
         * @function encode
         * @memberof dataset_sink.DatasetSinkWorkerConfiguration
         * @static
         * @param {dataset_sink.IDatasetSinkWorkerConfiguration} message DatasetSinkWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DatasetSinkWorkerConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.inputs != null && message.inputs.length)
                for (let i = 0; i < message.inputs.length; ++i)
                    $root.dataset_sink.SinkInput.encode(message.inputs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.encryptionKeyDependency != null && Object.hasOwnProperty.call(message, "encryptionKeyDependency"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.encryptionKeyDependency);
            if (message.datasetImportId != null && Object.hasOwnProperty.call(message, "datasetImportId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.datasetImportId);
            if (message.isKeyHexEncoded != null && Object.hasOwnProperty.call(message, "isKeyHexEncoded"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isKeyHexEncoded);
            return writer;
        };

        /**
         * Encodes the specified DatasetSinkWorkerConfiguration message, length delimited. Does not implicitly {@link dataset_sink.DatasetSinkWorkerConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof dataset_sink.DatasetSinkWorkerConfiguration
         * @static
         * @param {dataset_sink.IDatasetSinkWorkerConfiguration} message DatasetSinkWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DatasetSinkWorkerConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DatasetSinkWorkerConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof dataset_sink.DatasetSinkWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {dataset_sink.DatasetSinkWorkerConfiguration} DatasetSinkWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DatasetSinkWorkerConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.dataset_sink.DatasetSinkWorkerConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.inputs && message.inputs.length))
                            message.inputs = [];
                        message.inputs.push($root.dataset_sink.SinkInput.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.encryptionKeyDependency = reader.string();
                        break;
                    }
                case 3: {
                        message.datasetImportId = reader.string();
                        break;
                    }
                case 4: {
                        message.isKeyHexEncoded = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DatasetSinkWorkerConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof dataset_sink.DatasetSinkWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {dataset_sink.DatasetSinkWorkerConfiguration} DatasetSinkWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DatasetSinkWorkerConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DatasetSinkWorkerConfiguration message.
         * @function verify
         * @memberof dataset_sink.DatasetSinkWorkerConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DatasetSinkWorkerConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.inputs != null && message.hasOwnProperty("inputs")) {
                if (!Array.isArray(message.inputs))
                    return "inputs: array expected";
                for (let i = 0; i < message.inputs.length; ++i) {
                    let error = $root.dataset_sink.SinkInput.verify(message.inputs[i]);
                    if (error)
                        return "inputs." + error;
                }
            }
            if (message.encryptionKeyDependency != null && message.hasOwnProperty("encryptionKeyDependency"))
                if (!$util.isString(message.encryptionKeyDependency))
                    return "encryptionKeyDependency: string expected";
            if (message.datasetImportId != null && message.hasOwnProperty("datasetImportId")) {
                properties._datasetImportId = 1;
                if (!$util.isString(message.datasetImportId))
                    return "datasetImportId: string expected";
            }
            if (message.isKeyHexEncoded != null && message.hasOwnProperty("isKeyHexEncoded"))
                if (typeof message.isKeyHexEncoded !== "boolean")
                    return "isKeyHexEncoded: boolean expected";
            return null;
        };

        /**
         * Creates a DatasetSinkWorkerConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof dataset_sink.DatasetSinkWorkerConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {dataset_sink.DatasetSinkWorkerConfiguration} DatasetSinkWorkerConfiguration
         */
        DatasetSinkWorkerConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.dataset_sink.DatasetSinkWorkerConfiguration)
                return object;
            let message = new $root.dataset_sink.DatasetSinkWorkerConfiguration();
            if (object.inputs) {
                if (!Array.isArray(object.inputs))
                    throw TypeError(".dataset_sink.DatasetSinkWorkerConfiguration.inputs: array expected");
                message.inputs = [];
                for (let i = 0; i < object.inputs.length; ++i) {
                    if (typeof object.inputs[i] !== "object")
                        throw TypeError(".dataset_sink.DatasetSinkWorkerConfiguration.inputs: object expected");
                    message.inputs[i] = $root.dataset_sink.SinkInput.fromObject(object.inputs[i]);
                }
            }
            if (object.encryptionKeyDependency != null)
                message.encryptionKeyDependency = String(object.encryptionKeyDependency);
            if (object.datasetImportId != null)
                message.datasetImportId = String(object.datasetImportId);
            if (object.isKeyHexEncoded != null)
                message.isKeyHexEncoded = Boolean(object.isKeyHexEncoded);
            return message;
        };

        /**
         * Creates a plain object from a DatasetSinkWorkerConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof dataset_sink.DatasetSinkWorkerConfiguration
         * @static
         * @param {dataset_sink.DatasetSinkWorkerConfiguration} message DatasetSinkWorkerConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DatasetSinkWorkerConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.inputs = [];
            if (options.defaults) {
                object.encryptionKeyDependency = "";
                object.isKeyHexEncoded = false;
            }
            if (message.inputs && message.inputs.length) {
                object.inputs = [];
                for (let j = 0; j < message.inputs.length; ++j)
                    object.inputs[j] = $root.dataset_sink.SinkInput.toObject(message.inputs[j], options);
            }
            if (message.encryptionKeyDependency != null && message.hasOwnProperty("encryptionKeyDependency"))
                object.encryptionKeyDependency = message.encryptionKeyDependency;
            if (message.datasetImportId != null && message.hasOwnProperty("datasetImportId")) {
                object.datasetImportId = message.datasetImportId;
                if (options.oneofs)
                    object._datasetImportId = "datasetImportId";
            }
            if (message.isKeyHexEncoded != null && message.hasOwnProperty("isKeyHexEncoded"))
                object.isKeyHexEncoded = message.isKeyHexEncoded;
            return object;
        };

        /**
         * Converts this DatasetSinkWorkerConfiguration to JSON.
         * @function toJSON
         * @memberof dataset_sink.DatasetSinkWorkerConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DatasetSinkWorkerConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DatasetSinkWorkerConfiguration
         * @function getTypeUrl
         * @memberof dataset_sink.DatasetSinkWorkerConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DatasetSinkWorkerConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/dataset_sink.DatasetSinkWorkerConfiguration";
        };

        return DatasetSinkWorkerConfiguration;
    })();

    return dataset_sink;
})();

export const delta_enclave_api = $root.delta_enclave_api = (() => {

    /**
     * Namespace delta_enclave_api.
     * @exports delta_enclave_api
     * @namespace
     */
    const delta_enclave_api = {};

    delta_enclave_api.Request = (function() {

        /**
         * Properties of a Request.
         * @memberof delta_enclave_api
         * @interface IRequest
         * @property {delta_enclave_api.IDataNoncePubkey|null} [deltaRequest] Request deltaRequest
         * @property {delta_enclave_api.IExtensionMessage|null} [extensionMessage] Request extensionMessage
         * @property {delta_enclave_api.IDataNoncePubkey|null} [deltaRequestV2] Request deltaRequestV2
         */

        /**
         * Constructs a new Request.
         * @memberof delta_enclave_api
         * @classdesc Represents a Request.
         * @implements IRequest
         * @constructor
         * @param {delta_enclave_api.IRequest=} [properties] Properties to set
         */
        function Request(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Request deltaRequest.
         * @member {delta_enclave_api.IDataNoncePubkey|null|undefined} deltaRequest
         * @memberof delta_enclave_api.Request
         * @instance
         */
        Request.prototype.deltaRequest = null;

        /**
         * Request extensionMessage.
         * @member {delta_enclave_api.IExtensionMessage|null|undefined} extensionMessage
         * @memberof delta_enclave_api.Request
         * @instance
         */
        Request.prototype.extensionMessage = null;

        /**
         * Request deltaRequestV2.
         * @member {delta_enclave_api.IDataNoncePubkey|null|undefined} deltaRequestV2
         * @memberof delta_enclave_api.Request
         * @instance
         */
        Request.prototype.deltaRequestV2 = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Request request.
         * @member {"deltaRequest"|"extensionMessage"|"deltaRequestV2"|undefined} request
         * @memberof delta_enclave_api.Request
         * @instance
         */
        Object.defineProperty(Request.prototype, "request", {
            get: $util.oneOfGetter($oneOfFields = ["deltaRequest", "extensionMessage", "deltaRequestV2"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Request instance using the specified properties.
         * @function create
         * @memberof delta_enclave_api.Request
         * @static
         * @param {delta_enclave_api.IRequest=} [properties] Properties to set
         * @returns {delta_enclave_api.Request} Request instance
         */
        Request.create = function create(properties) {
            return new Request(properties);
        };

        /**
         * Encodes the specified Request message. Does not implicitly {@link delta_enclave_api.Request.verify|verify} messages.
         * @function encode
         * @memberof delta_enclave_api.Request
         * @static
         * @param {delta_enclave_api.IRequest} message Request message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Request.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.deltaRequest != null && Object.hasOwnProperty.call(message, "deltaRequest"))
                $root.delta_enclave_api.DataNoncePubkey.encode(message.deltaRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.extensionMessage != null && Object.hasOwnProperty.call(message, "extensionMessage"))
                $root.delta_enclave_api.ExtensionMessage.encode(message.extensionMessage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.deltaRequestV2 != null && Object.hasOwnProperty.call(message, "deltaRequestV2"))
                $root.delta_enclave_api.DataNoncePubkey.encode(message.deltaRequestV2, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Request message, length delimited. Does not implicitly {@link delta_enclave_api.Request.verify|verify} messages.
         * @function encodeDelimited
         * @memberof delta_enclave_api.Request
         * @static
         * @param {delta_enclave_api.IRequest} message Request message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Request.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Request message from the specified reader or buffer.
         * @function decode
         * @memberof delta_enclave_api.Request
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {delta_enclave_api.Request} Request
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Request.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.Request();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.deltaRequest = $root.delta_enclave_api.DataNoncePubkey.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.extensionMessage = $root.delta_enclave_api.ExtensionMessage.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.deltaRequestV2 = $root.delta_enclave_api.DataNoncePubkey.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Request message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof delta_enclave_api.Request
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {delta_enclave_api.Request} Request
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Request.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Request message.
         * @function verify
         * @memberof delta_enclave_api.Request
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Request.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.deltaRequest != null && message.hasOwnProperty("deltaRequest")) {
                properties.request = 1;
                {
                    let error = $root.delta_enclave_api.DataNoncePubkey.verify(message.deltaRequest);
                    if (error)
                        return "deltaRequest." + error;
                }
            }
            if (message.extensionMessage != null && message.hasOwnProperty("extensionMessage")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.delta_enclave_api.ExtensionMessage.verify(message.extensionMessage);
                    if (error)
                        return "extensionMessage." + error;
                }
            }
            if (message.deltaRequestV2 != null && message.hasOwnProperty("deltaRequestV2")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.delta_enclave_api.DataNoncePubkey.verify(message.deltaRequestV2);
                    if (error)
                        return "deltaRequestV2." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Request message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof delta_enclave_api.Request
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {delta_enclave_api.Request} Request
         */
        Request.fromObject = function fromObject(object) {
            if (object instanceof $root.delta_enclave_api.Request)
                return object;
            let message = new $root.delta_enclave_api.Request();
            if (object.deltaRequest != null) {
                if (typeof object.deltaRequest !== "object")
                    throw TypeError(".delta_enclave_api.Request.deltaRequest: object expected");
                message.deltaRequest = $root.delta_enclave_api.DataNoncePubkey.fromObject(object.deltaRequest);
            }
            if (object.extensionMessage != null) {
                if (typeof object.extensionMessage !== "object")
                    throw TypeError(".delta_enclave_api.Request.extensionMessage: object expected");
                message.extensionMessage = $root.delta_enclave_api.ExtensionMessage.fromObject(object.extensionMessage);
            }
            if (object.deltaRequestV2 != null) {
                if (typeof object.deltaRequestV2 !== "object")
                    throw TypeError(".delta_enclave_api.Request.deltaRequestV2: object expected");
                message.deltaRequestV2 = $root.delta_enclave_api.DataNoncePubkey.fromObject(object.deltaRequestV2);
            }
            return message;
        };

        /**
         * Creates a plain object from a Request message. Also converts values to other types if specified.
         * @function toObject
         * @memberof delta_enclave_api.Request
         * @static
         * @param {delta_enclave_api.Request} message Request
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Request.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.deltaRequest != null && message.hasOwnProperty("deltaRequest")) {
                object.deltaRequest = $root.delta_enclave_api.DataNoncePubkey.toObject(message.deltaRequest, options);
                if (options.oneofs)
                    object.request = "deltaRequest";
            }
            if (message.extensionMessage != null && message.hasOwnProperty("extensionMessage")) {
                object.extensionMessage = $root.delta_enclave_api.ExtensionMessage.toObject(message.extensionMessage, options);
                if (options.oneofs)
                    object.request = "extensionMessage";
            }
            if (message.deltaRequestV2 != null && message.hasOwnProperty("deltaRequestV2")) {
                object.deltaRequestV2 = $root.delta_enclave_api.DataNoncePubkey.toObject(message.deltaRequestV2, options);
                if (options.oneofs)
                    object.request = "deltaRequestV2";
            }
            return object;
        };

        /**
         * Converts this Request to JSON.
         * @function toJSON
         * @memberof delta_enclave_api.Request
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Request.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Request
         * @function getTypeUrl
         * @memberof delta_enclave_api.Request
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/delta_enclave_api.Request";
        };

        return Request;
    })();

    delta_enclave_api.ExtensionMessage = (function() {

        /**
         * Properties of an ExtensionMessage.
         * @memberof delta_enclave_api
         * @interface IExtensionMessage
         * @property {string|null} [name] ExtensionMessage name
         * @property {Uint8Array|null} [payload] ExtensionMessage payload
         */

        /**
         * Constructs a new ExtensionMessage.
         * @memberof delta_enclave_api
         * @classdesc Represents an ExtensionMessage.
         * @implements IExtensionMessage
         * @constructor
         * @param {delta_enclave_api.IExtensionMessage=} [properties] Properties to set
         */
        function ExtensionMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExtensionMessage name.
         * @member {string} name
         * @memberof delta_enclave_api.ExtensionMessage
         * @instance
         */
        ExtensionMessage.prototype.name = "";

        /**
         * ExtensionMessage payload.
         * @member {Uint8Array} payload
         * @memberof delta_enclave_api.ExtensionMessage
         * @instance
         */
        ExtensionMessage.prototype.payload = $util.newBuffer([]);

        /**
         * Creates a new ExtensionMessage instance using the specified properties.
         * @function create
         * @memberof delta_enclave_api.ExtensionMessage
         * @static
         * @param {delta_enclave_api.IExtensionMessage=} [properties] Properties to set
         * @returns {delta_enclave_api.ExtensionMessage} ExtensionMessage instance
         */
        ExtensionMessage.create = function create(properties) {
            return new ExtensionMessage(properties);
        };

        /**
         * Encodes the specified ExtensionMessage message. Does not implicitly {@link delta_enclave_api.ExtensionMessage.verify|verify} messages.
         * @function encode
         * @memberof delta_enclave_api.ExtensionMessage
         * @static
         * @param {delta_enclave_api.IExtensionMessage} message ExtensionMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExtensionMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.payload != null && Object.hasOwnProperty.call(message, "payload"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.payload);
            return writer;
        };

        /**
         * Encodes the specified ExtensionMessage message, length delimited. Does not implicitly {@link delta_enclave_api.ExtensionMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof delta_enclave_api.ExtensionMessage
         * @static
         * @param {delta_enclave_api.IExtensionMessage} message ExtensionMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExtensionMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExtensionMessage message from the specified reader or buffer.
         * @function decode
         * @memberof delta_enclave_api.ExtensionMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {delta_enclave_api.ExtensionMessage} ExtensionMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExtensionMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.ExtensionMessage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.payload = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExtensionMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof delta_enclave_api.ExtensionMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {delta_enclave_api.ExtensionMessage} ExtensionMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExtensionMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExtensionMessage message.
         * @function verify
         * @memberof delta_enclave_api.ExtensionMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExtensionMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.payload != null && message.hasOwnProperty("payload"))
                if (!(message.payload && typeof message.payload.length === "number" || $util.isString(message.payload)))
                    return "payload: buffer expected";
            return null;
        };

        /**
         * Creates an ExtensionMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof delta_enclave_api.ExtensionMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {delta_enclave_api.ExtensionMessage} ExtensionMessage
         */
        ExtensionMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.delta_enclave_api.ExtensionMessage)
                return object;
            let message = new $root.delta_enclave_api.ExtensionMessage();
            if (object.name != null)
                message.name = String(object.name);
            if (object.payload != null)
                if (typeof object.payload === "string")
                    $util.base64.decode(object.payload, message.payload = $util.newBuffer($util.base64.length(object.payload)), 0);
                else if (object.payload.length >= 0)
                    message.payload = object.payload;
            return message;
        };

        /**
         * Creates a plain object from an ExtensionMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof delta_enclave_api.ExtensionMessage
         * @static
         * @param {delta_enclave_api.ExtensionMessage} message ExtensionMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExtensionMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                if (options.bytes === String)
                    object.payload = "";
                else {
                    object.payload = [];
                    if (options.bytes !== Array)
                        object.payload = $util.newBuffer(object.payload);
                }
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.payload != null && message.hasOwnProperty("payload"))
                object.payload = options.bytes === String ? $util.base64.encode(message.payload, 0, message.payload.length) : options.bytes === Array ? Array.prototype.slice.call(message.payload) : message.payload;
            return object;
        };

        /**
         * Converts this ExtensionMessage to JSON.
         * @function toJSON
         * @memberof delta_enclave_api.ExtensionMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExtensionMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ExtensionMessage
         * @function getTypeUrl
         * @memberof delta_enclave_api.ExtensionMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExtensionMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/delta_enclave_api.ExtensionMessage";
        };

        return ExtensionMessage;
    })();

    delta_enclave_api.Response = (function() {

        /**
         * Properties of a Response.
         * @memberof delta_enclave_api
         * @interface IResponse
         * @property {Uint8Array|null} [successfulResponse] Response successfulResponse
         * @property {string|null} [unsuccessfulResponse] Response unsuccessfulResponse
         */

        /**
         * Constructs a new Response.
         * @memberof delta_enclave_api
         * @classdesc Represents a Response.
         * @implements IResponse
         * @constructor
         * @param {delta_enclave_api.IResponse=} [properties] Properties to set
         */
        function Response(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Response successfulResponse.
         * @member {Uint8Array|null|undefined} successfulResponse
         * @memberof delta_enclave_api.Response
         * @instance
         */
        Response.prototype.successfulResponse = null;

        /**
         * Response unsuccessfulResponse.
         * @member {string|null|undefined} unsuccessfulResponse
         * @memberof delta_enclave_api.Response
         * @instance
         */
        Response.prototype.unsuccessfulResponse = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Response response.
         * @member {"successfulResponse"|"unsuccessfulResponse"|undefined} response
         * @memberof delta_enclave_api.Response
         * @instance
         */
        Object.defineProperty(Response.prototype, "response", {
            get: $util.oneOfGetter($oneOfFields = ["successfulResponse", "unsuccessfulResponse"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Response instance using the specified properties.
         * @function create
         * @memberof delta_enclave_api.Response
         * @static
         * @param {delta_enclave_api.IResponse=} [properties] Properties to set
         * @returns {delta_enclave_api.Response} Response instance
         */
        Response.create = function create(properties) {
            return new Response(properties);
        };

        /**
         * Encodes the specified Response message. Does not implicitly {@link delta_enclave_api.Response.verify|verify} messages.
         * @function encode
         * @memberof delta_enclave_api.Response
         * @static
         * @param {delta_enclave_api.IResponse} message Response message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Response.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.successfulResponse != null && Object.hasOwnProperty.call(message, "successfulResponse"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.successfulResponse);
            if (message.unsuccessfulResponse != null && Object.hasOwnProperty.call(message, "unsuccessfulResponse"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.unsuccessfulResponse);
            return writer;
        };

        /**
         * Encodes the specified Response message, length delimited. Does not implicitly {@link delta_enclave_api.Response.verify|verify} messages.
         * @function encodeDelimited
         * @memberof delta_enclave_api.Response
         * @static
         * @param {delta_enclave_api.IResponse} message Response message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Response.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Response message from the specified reader or buffer.
         * @function decode
         * @memberof delta_enclave_api.Response
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {delta_enclave_api.Response} Response
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Response.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.Response();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.successfulResponse = reader.bytes();
                        break;
                    }
                case 2: {
                        message.unsuccessfulResponse = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Response message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof delta_enclave_api.Response
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {delta_enclave_api.Response} Response
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Response.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Response message.
         * @function verify
         * @memberof delta_enclave_api.Response
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Response.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.successfulResponse != null && message.hasOwnProperty("successfulResponse")) {
                properties.response = 1;
                if (!(message.successfulResponse && typeof message.successfulResponse.length === "number" || $util.isString(message.successfulResponse)))
                    return "successfulResponse: buffer expected";
            }
            if (message.unsuccessfulResponse != null && message.hasOwnProperty("unsuccessfulResponse")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                if (!$util.isString(message.unsuccessfulResponse))
                    return "unsuccessfulResponse: string expected";
            }
            return null;
        };

        /**
         * Creates a Response message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof delta_enclave_api.Response
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {delta_enclave_api.Response} Response
         */
        Response.fromObject = function fromObject(object) {
            if (object instanceof $root.delta_enclave_api.Response)
                return object;
            let message = new $root.delta_enclave_api.Response();
            if (object.successfulResponse != null)
                if (typeof object.successfulResponse === "string")
                    $util.base64.decode(object.successfulResponse, message.successfulResponse = $util.newBuffer($util.base64.length(object.successfulResponse)), 0);
                else if (object.successfulResponse.length >= 0)
                    message.successfulResponse = object.successfulResponse;
            if (object.unsuccessfulResponse != null)
                message.unsuccessfulResponse = String(object.unsuccessfulResponse);
            return message;
        };

        /**
         * Creates a plain object from a Response message. Also converts values to other types if specified.
         * @function toObject
         * @memberof delta_enclave_api.Response
         * @static
         * @param {delta_enclave_api.Response} message Response
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Response.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.successfulResponse != null && message.hasOwnProperty("successfulResponse")) {
                object.successfulResponse = options.bytes === String ? $util.base64.encode(message.successfulResponse, 0, message.successfulResponse.length) : options.bytes === Array ? Array.prototype.slice.call(message.successfulResponse) : message.successfulResponse;
                if (options.oneofs)
                    object.response = "successfulResponse";
            }
            if (message.unsuccessfulResponse != null && message.hasOwnProperty("unsuccessfulResponse")) {
                object.unsuccessfulResponse = message.unsuccessfulResponse;
                if (options.oneofs)
                    object.response = "unsuccessfulResponse";
            }
            return object;
        };

        /**
         * Converts this Response to JSON.
         * @function toJSON
         * @memberof delta_enclave_api.Response
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Response.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Response
         * @function getTypeUrl
         * @memberof delta_enclave_api.Response
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/delta_enclave_api.Response";
        };

        return Response;
    })();

    delta_enclave_api.DataNoncePubkey = (function() {

        /**
         * Properties of a DataNoncePubkey.
         * @memberof delta_enclave_api
         * @interface IDataNoncePubkey
         * @property {Uint8Array|null} [data] DataNoncePubkey data
         * @property {Uint8Array|null} [nonce] DataNoncePubkey nonce
         * @property {Uint8Array|null} [pubkey] DataNoncePubkey pubkey
         */

        /**
         * Constructs a new DataNoncePubkey.
         * @memberof delta_enclave_api
         * @classdesc Represents a DataNoncePubkey.
         * @implements IDataNoncePubkey
         * @constructor
         * @param {delta_enclave_api.IDataNoncePubkey=} [properties] Properties to set
         */
        function DataNoncePubkey(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DataNoncePubkey data.
         * @member {Uint8Array} data
         * @memberof delta_enclave_api.DataNoncePubkey
         * @instance
         */
        DataNoncePubkey.prototype.data = $util.newBuffer([]);

        /**
         * DataNoncePubkey nonce.
         * @member {Uint8Array} nonce
         * @memberof delta_enclave_api.DataNoncePubkey
         * @instance
         */
        DataNoncePubkey.prototype.nonce = $util.newBuffer([]);

        /**
         * DataNoncePubkey pubkey.
         * @member {Uint8Array} pubkey
         * @memberof delta_enclave_api.DataNoncePubkey
         * @instance
         */
        DataNoncePubkey.prototype.pubkey = $util.newBuffer([]);

        /**
         * Creates a new DataNoncePubkey instance using the specified properties.
         * @function create
         * @memberof delta_enclave_api.DataNoncePubkey
         * @static
         * @param {delta_enclave_api.IDataNoncePubkey=} [properties] Properties to set
         * @returns {delta_enclave_api.DataNoncePubkey} DataNoncePubkey instance
         */
        DataNoncePubkey.create = function create(properties) {
            return new DataNoncePubkey(properties);
        };

        /**
         * Encodes the specified DataNoncePubkey message. Does not implicitly {@link delta_enclave_api.DataNoncePubkey.verify|verify} messages.
         * @function encode
         * @memberof delta_enclave_api.DataNoncePubkey
         * @static
         * @param {delta_enclave_api.IDataNoncePubkey} message DataNoncePubkey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataNoncePubkey.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.data);
            if (message.nonce != null && Object.hasOwnProperty.call(message, "nonce"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.nonce);
            if (message.pubkey != null && Object.hasOwnProperty.call(message, "pubkey"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.pubkey);
            return writer;
        };

        /**
         * Encodes the specified DataNoncePubkey message, length delimited. Does not implicitly {@link delta_enclave_api.DataNoncePubkey.verify|verify} messages.
         * @function encodeDelimited
         * @memberof delta_enclave_api.DataNoncePubkey
         * @static
         * @param {delta_enclave_api.IDataNoncePubkey} message DataNoncePubkey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataNoncePubkey.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DataNoncePubkey message from the specified reader or buffer.
         * @function decode
         * @memberof delta_enclave_api.DataNoncePubkey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {delta_enclave_api.DataNoncePubkey} DataNoncePubkey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataNoncePubkey.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.DataNoncePubkey();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.data = reader.bytes();
                        break;
                    }
                case 2: {
                        message.nonce = reader.bytes();
                        break;
                    }
                case 3: {
                        message.pubkey = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DataNoncePubkey message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof delta_enclave_api.DataNoncePubkey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {delta_enclave_api.DataNoncePubkey} DataNoncePubkey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataNoncePubkey.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DataNoncePubkey message.
         * @function verify
         * @memberof delta_enclave_api.DataNoncePubkey
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DataNoncePubkey.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.data != null && message.hasOwnProperty("data"))
                if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                    return "data: buffer expected";
            if (message.nonce != null && message.hasOwnProperty("nonce"))
                if (!(message.nonce && typeof message.nonce.length === "number" || $util.isString(message.nonce)))
                    return "nonce: buffer expected";
            if (message.pubkey != null && message.hasOwnProperty("pubkey"))
                if (!(message.pubkey && typeof message.pubkey.length === "number" || $util.isString(message.pubkey)))
                    return "pubkey: buffer expected";
            return null;
        };

        /**
         * Creates a DataNoncePubkey message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof delta_enclave_api.DataNoncePubkey
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {delta_enclave_api.DataNoncePubkey} DataNoncePubkey
         */
        DataNoncePubkey.fromObject = function fromObject(object) {
            if (object instanceof $root.delta_enclave_api.DataNoncePubkey)
                return object;
            let message = new $root.delta_enclave_api.DataNoncePubkey();
            if (object.data != null)
                if (typeof object.data === "string")
                    $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                else if (object.data.length >= 0)
                    message.data = object.data;
            if (object.nonce != null)
                if (typeof object.nonce === "string")
                    $util.base64.decode(object.nonce, message.nonce = $util.newBuffer($util.base64.length(object.nonce)), 0);
                else if (object.nonce.length >= 0)
                    message.nonce = object.nonce;
            if (object.pubkey != null)
                if (typeof object.pubkey === "string")
                    $util.base64.decode(object.pubkey, message.pubkey = $util.newBuffer($util.base64.length(object.pubkey)), 0);
                else if (object.pubkey.length >= 0)
                    message.pubkey = object.pubkey;
            return message;
        };

        /**
         * Creates a plain object from a DataNoncePubkey message. Also converts values to other types if specified.
         * @function toObject
         * @memberof delta_enclave_api.DataNoncePubkey
         * @static
         * @param {delta_enclave_api.DataNoncePubkey} message DataNoncePubkey
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DataNoncePubkey.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.data = "";
                else {
                    object.data = [];
                    if (options.bytes !== Array)
                        object.data = $util.newBuffer(object.data);
                }
                if (options.bytes === String)
                    object.nonce = "";
                else {
                    object.nonce = [];
                    if (options.bytes !== Array)
                        object.nonce = $util.newBuffer(object.nonce);
                }
                if (options.bytes === String)
                    object.pubkey = "";
                else {
                    object.pubkey = [];
                    if (options.bytes !== Array)
                        object.pubkey = $util.newBuffer(object.pubkey);
                }
            }
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
            if (message.nonce != null && message.hasOwnProperty("nonce"))
                object.nonce = options.bytes === String ? $util.base64.encode(message.nonce, 0, message.nonce.length) : options.bytes === Array ? Array.prototype.slice.call(message.nonce) : message.nonce;
            if (message.pubkey != null && message.hasOwnProperty("pubkey"))
                object.pubkey = options.bytes === String ? $util.base64.encode(message.pubkey, 0, message.pubkey.length) : options.bytes === Array ? Array.prototype.slice.call(message.pubkey) : message.pubkey;
            return object;
        };

        /**
         * Converts this DataNoncePubkey to JSON.
         * @function toJSON
         * @memberof delta_enclave_api.DataNoncePubkey
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DataNoncePubkey.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DataNoncePubkey
         * @function getTypeUrl
         * @memberof delta_enclave_api.DataNoncePubkey
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DataNoncePubkey.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/delta_enclave_api.DataNoncePubkey";
        };

        return DataNoncePubkey;
    })();

    delta_enclave_api.DataNonce = (function() {

        /**
         * Properties of a DataNonce.
         * @memberof delta_enclave_api
         * @interface IDataNonce
         * @property {Uint8Array|null} [data] DataNonce data
         * @property {Uint8Array|null} [nonce] DataNonce nonce
         */

        /**
         * Constructs a new DataNonce.
         * @memberof delta_enclave_api
         * @classdesc Represents a DataNonce.
         * @implements IDataNonce
         * @constructor
         * @param {delta_enclave_api.IDataNonce=} [properties] Properties to set
         */
        function DataNonce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DataNonce data.
         * @member {Uint8Array} data
         * @memberof delta_enclave_api.DataNonce
         * @instance
         */
        DataNonce.prototype.data = $util.newBuffer([]);

        /**
         * DataNonce nonce.
         * @member {Uint8Array} nonce
         * @memberof delta_enclave_api.DataNonce
         * @instance
         */
        DataNonce.prototype.nonce = $util.newBuffer([]);

        /**
         * Creates a new DataNonce instance using the specified properties.
         * @function create
         * @memberof delta_enclave_api.DataNonce
         * @static
         * @param {delta_enclave_api.IDataNonce=} [properties] Properties to set
         * @returns {delta_enclave_api.DataNonce} DataNonce instance
         */
        DataNonce.create = function create(properties) {
            return new DataNonce(properties);
        };

        /**
         * Encodes the specified DataNonce message. Does not implicitly {@link delta_enclave_api.DataNonce.verify|verify} messages.
         * @function encode
         * @memberof delta_enclave_api.DataNonce
         * @static
         * @param {delta_enclave_api.IDataNonce} message DataNonce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataNonce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.data);
            if (message.nonce != null && Object.hasOwnProperty.call(message, "nonce"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.nonce);
            return writer;
        };

        /**
         * Encodes the specified DataNonce message, length delimited. Does not implicitly {@link delta_enclave_api.DataNonce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof delta_enclave_api.DataNonce
         * @static
         * @param {delta_enclave_api.IDataNonce} message DataNonce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataNonce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DataNonce message from the specified reader or buffer.
         * @function decode
         * @memberof delta_enclave_api.DataNonce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {delta_enclave_api.DataNonce} DataNonce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataNonce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.DataNonce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.data = reader.bytes();
                        break;
                    }
                case 2: {
                        message.nonce = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DataNonce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof delta_enclave_api.DataNonce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {delta_enclave_api.DataNonce} DataNonce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataNonce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DataNonce message.
         * @function verify
         * @memberof delta_enclave_api.DataNonce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DataNonce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.data != null && message.hasOwnProperty("data"))
                if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                    return "data: buffer expected";
            if (message.nonce != null && message.hasOwnProperty("nonce"))
                if (!(message.nonce && typeof message.nonce.length === "number" || $util.isString(message.nonce)))
                    return "nonce: buffer expected";
            return null;
        };

        /**
         * Creates a DataNonce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof delta_enclave_api.DataNonce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {delta_enclave_api.DataNonce} DataNonce
         */
        DataNonce.fromObject = function fromObject(object) {
            if (object instanceof $root.delta_enclave_api.DataNonce)
                return object;
            let message = new $root.delta_enclave_api.DataNonce();
            if (object.data != null)
                if (typeof object.data === "string")
                    $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                else if (object.data.length >= 0)
                    message.data = object.data;
            if (object.nonce != null)
                if (typeof object.nonce === "string")
                    $util.base64.decode(object.nonce, message.nonce = $util.newBuffer($util.base64.length(object.nonce)), 0);
                else if (object.nonce.length >= 0)
                    message.nonce = object.nonce;
            return message;
        };

        /**
         * Creates a plain object from a DataNonce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof delta_enclave_api.DataNonce
         * @static
         * @param {delta_enclave_api.DataNonce} message DataNonce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DataNonce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.data = "";
                else {
                    object.data = [];
                    if (options.bytes !== Array)
                        object.data = $util.newBuffer(object.data);
                }
                if (options.bytes === String)
                    object.nonce = "";
                else {
                    object.nonce = [];
                    if (options.bytes !== Array)
                        object.nonce = $util.newBuffer(object.nonce);
                }
            }
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
            if (message.nonce != null && message.hasOwnProperty("nonce"))
                object.nonce = options.bytes === String ? $util.base64.encode(message.nonce, 0, message.nonce.length) : options.bytes === Array ? Array.prototype.slice.call(message.nonce) : message.nonce;
            return object;
        };

        /**
         * Converts this DataNonce to JSON.
         * @function toJSON
         * @memberof delta_enclave_api.DataNonce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DataNonce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DataNonce
         * @function getTypeUrl
         * @memberof delta_enclave_api.DataNonce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DataNonce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/delta_enclave_api.DataNonce";
        };

        return DataNonce;
    })();

    delta_enclave_api.SealedEncryptedMessage = (function() {

        /**
         * Properties of a SealedEncryptedMessage.
         * @memberof delta_enclave_api
         * @interface ISealedEncryptedMessage
         * @property {delta_enclave_api.IDataNonce|null} [encryptedMessage] SealedEncryptedMessage encryptedMessage
         * @property {Uint8Array|null} [sealingKeyParams] SealedEncryptedMessage sealingKeyParams
         */

        /**
         * Constructs a new SealedEncryptedMessage.
         * @memberof delta_enclave_api
         * @classdesc Represents a SealedEncryptedMessage.
         * @implements ISealedEncryptedMessage
         * @constructor
         * @param {delta_enclave_api.ISealedEncryptedMessage=} [properties] Properties to set
         */
        function SealedEncryptedMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SealedEncryptedMessage encryptedMessage.
         * @member {delta_enclave_api.IDataNonce|null|undefined} encryptedMessage
         * @memberof delta_enclave_api.SealedEncryptedMessage
         * @instance
         */
        SealedEncryptedMessage.prototype.encryptedMessage = null;

        /**
         * SealedEncryptedMessage sealingKeyParams.
         * @member {Uint8Array} sealingKeyParams
         * @memberof delta_enclave_api.SealedEncryptedMessage
         * @instance
         */
        SealedEncryptedMessage.prototype.sealingKeyParams = $util.newBuffer([]);

        /**
         * Creates a new SealedEncryptedMessage instance using the specified properties.
         * @function create
         * @memberof delta_enclave_api.SealedEncryptedMessage
         * @static
         * @param {delta_enclave_api.ISealedEncryptedMessage=} [properties] Properties to set
         * @returns {delta_enclave_api.SealedEncryptedMessage} SealedEncryptedMessage instance
         */
        SealedEncryptedMessage.create = function create(properties) {
            return new SealedEncryptedMessage(properties);
        };

        /**
         * Encodes the specified SealedEncryptedMessage message. Does not implicitly {@link delta_enclave_api.SealedEncryptedMessage.verify|verify} messages.
         * @function encode
         * @memberof delta_enclave_api.SealedEncryptedMessage
         * @static
         * @param {delta_enclave_api.ISealedEncryptedMessage} message SealedEncryptedMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SealedEncryptedMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.encryptedMessage != null && Object.hasOwnProperty.call(message, "encryptedMessage"))
                $root.delta_enclave_api.DataNonce.encode(message.encryptedMessage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.sealingKeyParams != null && Object.hasOwnProperty.call(message, "sealingKeyParams"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.sealingKeyParams);
            return writer;
        };

        /**
         * Encodes the specified SealedEncryptedMessage message, length delimited. Does not implicitly {@link delta_enclave_api.SealedEncryptedMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof delta_enclave_api.SealedEncryptedMessage
         * @static
         * @param {delta_enclave_api.ISealedEncryptedMessage} message SealedEncryptedMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SealedEncryptedMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SealedEncryptedMessage message from the specified reader or buffer.
         * @function decode
         * @memberof delta_enclave_api.SealedEncryptedMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {delta_enclave_api.SealedEncryptedMessage} SealedEncryptedMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SealedEncryptedMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.SealedEncryptedMessage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.encryptedMessage = $root.delta_enclave_api.DataNonce.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.sealingKeyParams = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SealedEncryptedMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof delta_enclave_api.SealedEncryptedMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {delta_enclave_api.SealedEncryptedMessage} SealedEncryptedMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SealedEncryptedMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SealedEncryptedMessage message.
         * @function verify
         * @memberof delta_enclave_api.SealedEncryptedMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SealedEncryptedMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.encryptedMessage != null && message.hasOwnProperty("encryptedMessage")) {
                let error = $root.delta_enclave_api.DataNonce.verify(message.encryptedMessage);
                if (error)
                    return "encryptedMessage." + error;
            }
            if (message.sealingKeyParams != null && message.hasOwnProperty("sealingKeyParams"))
                if (!(message.sealingKeyParams && typeof message.sealingKeyParams.length === "number" || $util.isString(message.sealingKeyParams)))
                    return "sealingKeyParams: buffer expected";
            return null;
        };

        /**
         * Creates a SealedEncryptedMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof delta_enclave_api.SealedEncryptedMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {delta_enclave_api.SealedEncryptedMessage} SealedEncryptedMessage
         */
        SealedEncryptedMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.delta_enclave_api.SealedEncryptedMessage)
                return object;
            let message = new $root.delta_enclave_api.SealedEncryptedMessage();
            if (object.encryptedMessage != null) {
                if (typeof object.encryptedMessage !== "object")
                    throw TypeError(".delta_enclave_api.SealedEncryptedMessage.encryptedMessage: object expected");
                message.encryptedMessage = $root.delta_enclave_api.DataNonce.fromObject(object.encryptedMessage);
            }
            if (object.sealingKeyParams != null)
                if (typeof object.sealingKeyParams === "string")
                    $util.base64.decode(object.sealingKeyParams, message.sealingKeyParams = $util.newBuffer($util.base64.length(object.sealingKeyParams)), 0);
                else if (object.sealingKeyParams.length >= 0)
                    message.sealingKeyParams = object.sealingKeyParams;
            return message;
        };

        /**
         * Creates a plain object from a SealedEncryptedMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof delta_enclave_api.SealedEncryptedMessage
         * @static
         * @param {delta_enclave_api.SealedEncryptedMessage} message SealedEncryptedMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SealedEncryptedMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.encryptedMessage = null;
                if (options.bytes === String)
                    object.sealingKeyParams = "";
                else {
                    object.sealingKeyParams = [];
                    if (options.bytes !== Array)
                        object.sealingKeyParams = $util.newBuffer(object.sealingKeyParams);
                }
            }
            if (message.encryptedMessage != null && message.hasOwnProperty("encryptedMessage"))
                object.encryptedMessage = $root.delta_enclave_api.DataNonce.toObject(message.encryptedMessage, options);
            if (message.sealingKeyParams != null && message.hasOwnProperty("sealingKeyParams"))
                object.sealingKeyParams = options.bytes === String ? $util.base64.encode(message.sealingKeyParams, 0, message.sealingKeyParams.length) : options.bytes === Array ? Array.prototype.slice.call(message.sealingKeyParams) : message.sealingKeyParams;
            return object;
        };

        /**
         * Converts this SealedEncryptedMessage to JSON.
         * @function toJSON
         * @memberof delta_enclave_api.SealedEncryptedMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SealedEncryptedMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SealedEncryptedMessage
         * @function getTypeUrl
         * @memberof delta_enclave_api.SealedEncryptedMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SealedEncryptedMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/delta_enclave_api.SealedEncryptedMessage";
        };

        return SealedEncryptedMessage;
    })();

    delta_enclave_api.EncryptionHeader = (function() {

        /**
         * Properties of an EncryptionHeader.
         * @memberof delta_enclave_api
         * @interface IEncryptionHeader
         * @property {delta_enclave_api.IChilyKey|null} [chilyKey] EncryptionHeader chilyKey
         */

        /**
         * Constructs a new EncryptionHeader.
         * @memberof delta_enclave_api
         * @classdesc Represents an EncryptionHeader.
         * @implements IEncryptionHeader
         * @constructor
         * @param {delta_enclave_api.IEncryptionHeader=} [properties] Properties to set
         */
        function EncryptionHeader(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EncryptionHeader chilyKey.
         * @member {delta_enclave_api.IChilyKey|null|undefined} chilyKey
         * @memberof delta_enclave_api.EncryptionHeader
         * @instance
         */
        EncryptionHeader.prototype.chilyKey = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * EncryptionHeader encryptionHeader.
         * @member {"chilyKey"|undefined} encryptionHeader
         * @memberof delta_enclave_api.EncryptionHeader
         * @instance
         */
        Object.defineProperty(EncryptionHeader.prototype, "encryptionHeader", {
            get: $util.oneOfGetter($oneOfFields = ["chilyKey"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new EncryptionHeader instance using the specified properties.
         * @function create
         * @memberof delta_enclave_api.EncryptionHeader
         * @static
         * @param {delta_enclave_api.IEncryptionHeader=} [properties] Properties to set
         * @returns {delta_enclave_api.EncryptionHeader} EncryptionHeader instance
         */
        EncryptionHeader.create = function create(properties) {
            return new EncryptionHeader(properties);
        };

        /**
         * Encodes the specified EncryptionHeader message. Does not implicitly {@link delta_enclave_api.EncryptionHeader.verify|verify} messages.
         * @function encode
         * @memberof delta_enclave_api.EncryptionHeader
         * @static
         * @param {delta_enclave_api.IEncryptionHeader} message EncryptionHeader message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EncryptionHeader.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chilyKey != null && Object.hasOwnProperty.call(message, "chilyKey"))
                $root.delta_enclave_api.ChilyKey.encode(message.chilyKey, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified EncryptionHeader message, length delimited. Does not implicitly {@link delta_enclave_api.EncryptionHeader.verify|verify} messages.
         * @function encodeDelimited
         * @memberof delta_enclave_api.EncryptionHeader
         * @static
         * @param {delta_enclave_api.IEncryptionHeader} message EncryptionHeader message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EncryptionHeader.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EncryptionHeader message from the specified reader or buffer.
         * @function decode
         * @memberof delta_enclave_api.EncryptionHeader
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {delta_enclave_api.EncryptionHeader} EncryptionHeader
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EncryptionHeader.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.EncryptionHeader();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.chilyKey = $root.delta_enclave_api.ChilyKey.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EncryptionHeader message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof delta_enclave_api.EncryptionHeader
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {delta_enclave_api.EncryptionHeader} EncryptionHeader
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EncryptionHeader.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EncryptionHeader message.
         * @function verify
         * @memberof delta_enclave_api.EncryptionHeader
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EncryptionHeader.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.chilyKey != null && message.hasOwnProperty("chilyKey")) {
                properties.encryptionHeader = 1;
                {
                    let error = $root.delta_enclave_api.ChilyKey.verify(message.chilyKey);
                    if (error)
                        return "chilyKey." + error;
                }
            }
            return null;
        };

        /**
         * Creates an EncryptionHeader message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof delta_enclave_api.EncryptionHeader
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {delta_enclave_api.EncryptionHeader} EncryptionHeader
         */
        EncryptionHeader.fromObject = function fromObject(object) {
            if (object instanceof $root.delta_enclave_api.EncryptionHeader)
                return object;
            let message = new $root.delta_enclave_api.EncryptionHeader();
            if (object.chilyKey != null) {
                if (typeof object.chilyKey !== "object")
                    throw TypeError(".delta_enclave_api.EncryptionHeader.chilyKey: object expected");
                message.chilyKey = $root.delta_enclave_api.ChilyKey.fromObject(object.chilyKey);
            }
            return message;
        };

        /**
         * Creates a plain object from an EncryptionHeader message. Also converts values to other types if specified.
         * @function toObject
         * @memberof delta_enclave_api.EncryptionHeader
         * @static
         * @param {delta_enclave_api.EncryptionHeader} message EncryptionHeader
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EncryptionHeader.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.chilyKey != null && message.hasOwnProperty("chilyKey")) {
                object.chilyKey = $root.delta_enclave_api.ChilyKey.toObject(message.chilyKey, options);
                if (options.oneofs)
                    object.encryptionHeader = "chilyKey";
            }
            return object;
        };

        /**
         * Converts this EncryptionHeader to JSON.
         * @function toJSON
         * @memberof delta_enclave_api.EncryptionHeader
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EncryptionHeader.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EncryptionHeader
         * @function getTypeUrl
         * @memberof delta_enclave_api.EncryptionHeader
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EncryptionHeader.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/delta_enclave_api.EncryptionHeader";
        };

        return EncryptionHeader;
    })();

    delta_enclave_api.ChilyKey = (function() {

        /**
         * Properties of a ChilyKey.
         * @memberof delta_enclave_api
         * @interface IChilyKey
         * @property {Uint8Array|null} [encryptionNonce] ChilyKey encryptionNonce
         */

        /**
         * Constructs a new ChilyKey.
         * @memberof delta_enclave_api
         * @classdesc Represents a ChilyKey.
         * @implements IChilyKey
         * @constructor
         * @param {delta_enclave_api.IChilyKey=} [properties] Properties to set
         */
        function ChilyKey(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChilyKey encryptionNonce.
         * @member {Uint8Array} encryptionNonce
         * @memberof delta_enclave_api.ChilyKey
         * @instance
         */
        ChilyKey.prototype.encryptionNonce = $util.newBuffer([]);

        /**
         * Creates a new ChilyKey instance using the specified properties.
         * @function create
         * @memberof delta_enclave_api.ChilyKey
         * @static
         * @param {delta_enclave_api.IChilyKey=} [properties] Properties to set
         * @returns {delta_enclave_api.ChilyKey} ChilyKey instance
         */
        ChilyKey.create = function create(properties) {
            return new ChilyKey(properties);
        };

        /**
         * Encodes the specified ChilyKey message. Does not implicitly {@link delta_enclave_api.ChilyKey.verify|verify} messages.
         * @function encode
         * @memberof delta_enclave_api.ChilyKey
         * @static
         * @param {delta_enclave_api.IChilyKey} message ChilyKey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChilyKey.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.encryptionNonce != null && Object.hasOwnProperty.call(message, "encryptionNonce"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.encryptionNonce);
            return writer;
        };

        /**
         * Encodes the specified ChilyKey message, length delimited. Does not implicitly {@link delta_enclave_api.ChilyKey.verify|verify} messages.
         * @function encodeDelimited
         * @memberof delta_enclave_api.ChilyKey
         * @static
         * @param {delta_enclave_api.IChilyKey} message ChilyKey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChilyKey.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChilyKey message from the specified reader or buffer.
         * @function decode
         * @memberof delta_enclave_api.ChilyKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {delta_enclave_api.ChilyKey} ChilyKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChilyKey.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.ChilyKey();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.encryptionNonce = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChilyKey message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof delta_enclave_api.ChilyKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {delta_enclave_api.ChilyKey} ChilyKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChilyKey.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChilyKey message.
         * @function verify
         * @memberof delta_enclave_api.ChilyKey
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChilyKey.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.encryptionNonce != null && message.hasOwnProperty("encryptionNonce"))
                if (!(message.encryptionNonce && typeof message.encryptionNonce.length === "number" || $util.isString(message.encryptionNonce)))
                    return "encryptionNonce: buffer expected";
            return null;
        };

        /**
         * Creates a ChilyKey message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof delta_enclave_api.ChilyKey
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {delta_enclave_api.ChilyKey} ChilyKey
         */
        ChilyKey.fromObject = function fromObject(object) {
            if (object instanceof $root.delta_enclave_api.ChilyKey)
                return object;
            let message = new $root.delta_enclave_api.ChilyKey();
            if (object.encryptionNonce != null)
                if (typeof object.encryptionNonce === "string")
                    $util.base64.decode(object.encryptionNonce, message.encryptionNonce = $util.newBuffer($util.base64.length(object.encryptionNonce)), 0);
                else if (object.encryptionNonce.length >= 0)
                    message.encryptionNonce = object.encryptionNonce;
            return message;
        };

        /**
         * Creates a plain object from a ChilyKey message. Also converts values to other types if specified.
         * @function toObject
         * @memberof delta_enclave_api.ChilyKey
         * @static
         * @param {delta_enclave_api.ChilyKey} message ChilyKey
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChilyKey.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.encryptionNonce = "";
                else {
                    object.encryptionNonce = [];
                    if (options.bytes !== Array)
                        object.encryptionNonce = $util.newBuffer(object.encryptionNonce);
                }
            if (message.encryptionNonce != null && message.hasOwnProperty("encryptionNonce"))
                object.encryptionNonce = options.bytes === String ? $util.base64.encode(message.encryptionNonce, 0, message.encryptionNonce.length) : options.bytes === Array ? Array.prototype.slice.call(message.encryptionNonce) : message.encryptionNonce;
            return object;
        };

        /**
         * Converts this ChilyKey to JSON.
         * @function toJSON
         * @memberof delta_enclave_api.ChilyKey
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChilyKey.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChilyKey
         * @function getTypeUrl
         * @memberof delta_enclave_api.ChilyKey
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChilyKey.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/delta_enclave_api.ChilyKey";
        };

        return ChilyKey;
    })();

    delta_enclave_api.VersionHeader = (function() {

        /**
         * Properties of a VersionHeader.
         * @memberof delta_enclave_api
         * @interface IVersionHeader
         * @property {number|Long|null} [version] VersionHeader version
         */

        /**
         * Constructs a new VersionHeader.
         * @memberof delta_enclave_api
         * @classdesc Represents a VersionHeader.
         * @implements IVersionHeader
         * @constructor
         * @param {delta_enclave_api.IVersionHeader=} [properties] Properties to set
         */
        function VersionHeader(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VersionHeader version.
         * @member {number|Long} version
         * @memberof delta_enclave_api.VersionHeader
         * @instance
         */
        VersionHeader.prototype.version = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new VersionHeader instance using the specified properties.
         * @function create
         * @memberof delta_enclave_api.VersionHeader
         * @static
         * @param {delta_enclave_api.IVersionHeader=} [properties] Properties to set
         * @returns {delta_enclave_api.VersionHeader} VersionHeader instance
         */
        VersionHeader.create = function create(properties) {
            return new VersionHeader(properties);
        };

        /**
         * Encodes the specified VersionHeader message. Does not implicitly {@link delta_enclave_api.VersionHeader.verify|verify} messages.
         * @function encode
         * @memberof delta_enclave_api.VersionHeader
         * @static
         * @param {delta_enclave_api.IVersionHeader} message VersionHeader message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VersionHeader.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.version);
            return writer;
        };

        /**
         * Encodes the specified VersionHeader message, length delimited. Does not implicitly {@link delta_enclave_api.VersionHeader.verify|verify} messages.
         * @function encodeDelimited
         * @memberof delta_enclave_api.VersionHeader
         * @static
         * @param {delta_enclave_api.IVersionHeader} message VersionHeader message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VersionHeader.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VersionHeader message from the specified reader or buffer.
         * @function decode
         * @memberof delta_enclave_api.VersionHeader
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {delta_enclave_api.VersionHeader} VersionHeader
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VersionHeader.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.VersionHeader();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.version = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VersionHeader message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof delta_enclave_api.VersionHeader
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {delta_enclave_api.VersionHeader} VersionHeader
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VersionHeader.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VersionHeader message.
         * @function verify
         * @memberof delta_enclave_api.VersionHeader
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VersionHeader.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isInteger(message.version) && !(message.version && $util.isInteger(message.version.low) && $util.isInteger(message.version.high)))
                    return "version: integer|Long expected";
            return null;
        };

        /**
         * Creates a VersionHeader message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof delta_enclave_api.VersionHeader
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {delta_enclave_api.VersionHeader} VersionHeader
         */
        VersionHeader.fromObject = function fromObject(object) {
            if (object instanceof $root.delta_enclave_api.VersionHeader)
                return object;
            let message = new $root.delta_enclave_api.VersionHeader();
            if (object.version != null)
                if ($util.Long)
                    (message.version = $util.Long.fromValue(object.version)).unsigned = false;
                else if (typeof object.version === "string")
                    message.version = parseInt(object.version, 10);
                else if (typeof object.version === "number")
                    message.version = object.version;
                else if (typeof object.version === "object")
                    message.version = new $util.LongBits(object.version.low >>> 0, object.version.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a VersionHeader message. Also converts values to other types if specified.
         * @function toObject
         * @memberof delta_enclave_api.VersionHeader
         * @static
         * @param {delta_enclave_api.VersionHeader} message VersionHeader
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VersionHeader.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.version = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.version = options.longs === String ? "0" : 0;
            if (message.version != null && message.hasOwnProperty("version"))
                if (typeof message.version === "number")
                    object.version = options.longs === String ? String(message.version) : message.version;
                else
                    object.version = options.longs === String ? $util.Long.prototype.toString.call(message.version) : options.longs === Number ? new $util.LongBits(message.version.low >>> 0, message.version.high >>> 0).toNumber() : message.version;
            return object;
        };

        /**
         * Converts this VersionHeader to JSON.
         * @function toJSON
         * @memberof delta_enclave_api.VersionHeader
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VersionHeader.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for VersionHeader
         * @function getTypeUrl
         * @memberof delta_enclave_api.VersionHeader
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        VersionHeader.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/delta_enclave_api.VersionHeader";
        };

        return VersionHeader;
    })();

    delta_enclave_api.ChunkHeader = (function() {

        /**
         * Properties of a ChunkHeader.
         * @memberof delta_enclave_api
         * @interface IChunkHeader
         * @property {Uint8Array|null} [extraEntropy] ChunkHeader extraEntropy
         * @property {number|Long|null} [untrustedContentSize] ChunkHeader untrustedContentSize
         * @property {Array.<number|Long>|null} [untrustedChunkContentSizes] ChunkHeader untrustedChunkContentSizes
         */

        /**
         * Constructs a new ChunkHeader.
         * @memberof delta_enclave_api
         * @classdesc Represents a ChunkHeader.
         * @implements IChunkHeader
         * @constructor
         * @param {delta_enclave_api.IChunkHeader=} [properties] Properties to set
         */
        function ChunkHeader(properties) {
            this.untrustedChunkContentSizes = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChunkHeader extraEntropy.
         * @member {Uint8Array} extraEntropy
         * @memberof delta_enclave_api.ChunkHeader
         * @instance
         */
        ChunkHeader.prototype.extraEntropy = $util.newBuffer([]);

        /**
         * ChunkHeader untrustedContentSize.
         * @member {number|Long|null|undefined} untrustedContentSize
         * @memberof delta_enclave_api.ChunkHeader
         * @instance
         */
        ChunkHeader.prototype.untrustedContentSize = null;

        /**
         * ChunkHeader untrustedChunkContentSizes.
         * @member {Array.<number|Long>} untrustedChunkContentSizes
         * @memberof delta_enclave_api.ChunkHeader
         * @instance
         */
        ChunkHeader.prototype.untrustedChunkContentSizes = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ChunkHeader _untrustedContentSize.
         * @member {"untrustedContentSize"|undefined} _untrustedContentSize
         * @memberof delta_enclave_api.ChunkHeader
         * @instance
         */
        Object.defineProperty(ChunkHeader.prototype, "_untrustedContentSize", {
            get: $util.oneOfGetter($oneOfFields = ["untrustedContentSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ChunkHeader instance using the specified properties.
         * @function create
         * @memberof delta_enclave_api.ChunkHeader
         * @static
         * @param {delta_enclave_api.IChunkHeader=} [properties] Properties to set
         * @returns {delta_enclave_api.ChunkHeader} ChunkHeader instance
         */
        ChunkHeader.create = function create(properties) {
            return new ChunkHeader(properties);
        };

        /**
         * Encodes the specified ChunkHeader message. Does not implicitly {@link delta_enclave_api.ChunkHeader.verify|verify} messages.
         * @function encode
         * @memberof delta_enclave_api.ChunkHeader
         * @static
         * @param {delta_enclave_api.IChunkHeader} message ChunkHeader message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChunkHeader.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.extraEntropy != null && Object.hasOwnProperty.call(message, "extraEntropy"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.extraEntropy);
            if (message.untrustedContentSize != null && Object.hasOwnProperty.call(message, "untrustedContentSize"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.untrustedContentSize);
            if (message.untrustedChunkContentSizes != null && message.untrustedChunkContentSizes.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (let i = 0; i < message.untrustedChunkContentSizes.length; ++i)
                    writer.uint64(message.untrustedChunkContentSizes[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified ChunkHeader message, length delimited. Does not implicitly {@link delta_enclave_api.ChunkHeader.verify|verify} messages.
         * @function encodeDelimited
         * @memberof delta_enclave_api.ChunkHeader
         * @static
         * @param {delta_enclave_api.IChunkHeader} message ChunkHeader message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChunkHeader.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChunkHeader message from the specified reader or buffer.
         * @function decode
         * @memberof delta_enclave_api.ChunkHeader
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {delta_enclave_api.ChunkHeader} ChunkHeader
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChunkHeader.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.ChunkHeader();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.extraEntropy = reader.bytes();
                        break;
                    }
                case 2: {
                        message.untrustedContentSize = reader.uint64();
                        break;
                    }
                case 3: {
                        if (!(message.untrustedChunkContentSizes && message.untrustedChunkContentSizes.length))
                            message.untrustedChunkContentSizes = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.untrustedChunkContentSizes.push(reader.uint64());
                        } else
                            message.untrustedChunkContentSizes.push(reader.uint64());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChunkHeader message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof delta_enclave_api.ChunkHeader
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {delta_enclave_api.ChunkHeader} ChunkHeader
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChunkHeader.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChunkHeader message.
         * @function verify
         * @memberof delta_enclave_api.ChunkHeader
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChunkHeader.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.extraEntropy != null && message.hasOwnProperty("extraEntropy"))
                if (!(message.extraEntropy && typeof message.extraEntropy.length === "number" || $util.isString(message.extraEntropy)))
                    return "extraEntropy: buffer expected";
            if (message.untrustedContentSize != null && message.hasOwnProperty("untrustedContentSize")) {
                properties._untrustedContentSize = 1;
                if (!$util.isInteger(message.untrustedContentSize) && !(message.untrustedContentSize && $util.isInteger(message.untrustedContentSize.low) && $util.isInteger(message.untrustedContentSize.high)))
                    return "untrustedContentSize: integer|Long expected";
            }
            if (message.untrustedChunkContentSizes != null && message.hasOwnProperty("untrustedChunkContentSizes")) {
                if (!Array.isArray(message.untrustedChunkContentSizes))
                    return "untrustedChunkContentSizes: array expected";
                for (let i = 0; i < message.untrustedChunkContentSizes.length; ++i)
                    if (!$util.isInteger(message.untrustedChunkContentSizes[i]) && !(message.untrustedChunkContentSizes[i] && $util.isInteger(message.untrustedChunkContentSizes[i].low) && $util.isInteger(message.untrustedChunkContentSizes[i].high)))
                        return "untrustedChunkContentSizes: integer|Long[] expected";
            }
            return null;
        };

        /**
         * Creates a ChunkHeader message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof delta_enclave_api.ChunkHeader
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {delta_enclave_api.ChunkHeader} ChunkHeader
         */
        ChunkHeader.fromObject = function fromObject(object) {
            if (object instanceof $root.delta_enclave_api.ChunkHeader)
                return object;
            let message = new $root.delta_enclave_api.ChunkHeader();
            if (object.extraEntropy != null)
                if (typeof object.extraEntropy === "string")
                    $util.base64.decode(object.extraEntropy, message.extraEntropy = $util.newBuffer($util.base64.length(object.extraEntropy)), 0);
                else if (object.extraEntropy.length >= 0)
                    message.extraEntropy = object.extraEntropy;
            if (object.untrustedContentSize != null)
                if ($util.Long)
                    (message.untrustedContentSize = $util.Long.fromValue(object.untrustedContentSize)).unsigned = true;
                else if (typeof object.untrustedContentSize === "string")
                    message.untrustedContentSize = parseInt(object.untrustedContentSize, 10);
                else if (typeof object.untrustedContentSize === "number")
                    message.untrustedContentSize = object.untrustedContentSize;
                else if (typeof object.untrustedContentSize === "object")
                    message.untrustedContentSize = new $util.LongBits(object.untrustedContentSize.low >>> 0, object.untrustedContentSize.high >>> 0).toNumber(true);
            if (object.untrustedChunkContentSizes) {
                if (!Array.isArray(object.untrustedChunkContentSizes))
                    throw TypeError(".delta_enclave_api.ChunkHeader.untrustedChunkContentSizes: array expected");
                message.untrustedChunkContentSizes = [];
                for (let i = 0; i < object.untrustedChunkContentSizes.length; ++i)
                    if ($util.Long)
                        (message.untrustedChunkContentSizes[i] = $util.Long.fromValue(object.untrustedChunkContentSizes[i])).unsigned = true;
                    else if (typeof object.untrustedChunkContentSizes[i] === "string")
                        message.untrustedChunkContentSizes[i] = parseInt(object.untrustedChunkContentSizes[i], 10);
                    else if (typeof object.untrustedChunkContentSizes[i] === "number")
                        message.untrustedChunkContentSizes[i] = object.untrustedChunkContentSizes[i];
                    else if (typeof object.untrustedChunkContentSizes[i] === "object")
                        message.untrustedChunkContentSizes[i] = new $util.LongBits(object.untrustedChunkContentSizes[i].low >>> 0, object.untrustedChunkContentSizes[i].high >>> 0).toNumber(true);
            }
            return message;
        };

        /**
         * Creates a plain object from a ChunkHeader message. Also converts values to other types if specified.
         * @function toObject
         * @memberof delta_enclave_api.ChunkHeader
         * @static
         * @param {delta_enclave_api.ChunkHeader} message ChunkHeader
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChunkHeader.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.untrustedChunkContentSizes = [];
            if (options.defaults)
                if (options.bytes === String)
                    object.extraEntropy = "";
                else {
                    object.extraEntropy = [];
                    if (options.bytes !== Array)
                        object.extraEntropy = $util.newBuffer(object.extraEntropy);
                }
            if (message.extraEntropy != null && message.hasOwnProperty("extraEntropy"))
                object.extraEntropy = options.bytes === String ? $util.base64.encode(message.extraEntropy, 0, message.extraEntropy.length) : options.bytes === Array ? Array.prototype.slice.call(message.extraEntropy) : message.extraEntropy;
            if (message.untrustedContentSize != null && message.hasOwnProperty("untrustedContentSize")) {
                if (typeof message.untrustedContentSize === "number")
                    object.untrustedContentSize = options.longs === String ? String(message.untrustedContentSize) : message.untrustedContentSize;
                else
                    object.untrustedContentSize = options.longs === String ? $util.Long.prototype.toString.call(message.untrustedContentSize) : options.longs === Number ? new $util.LongBits(message.untrustedContentSize.low >>> 0, message.untrustedContentSize.high >>> 0).toNumber(true) : message.untrustedContentSize;
                if (options.oneofs)
                    object._untrustedContentSize = "untrustedContentSize";
            }
            if (message.untrustedChunkContentSizes && message.untrustedChunkContentSizes.length) {
                object.untrustedChunkContentSizes = [];
                for (let j = 0; j < message.untrustedChunkContentSizes.length; ++j)
                    if (typeof message.untrustedChunkContentSizes[j] === "number")
                        object.untrustedChunkContentSizes[j] = options.longs === String ? String(message.untrustedChunkContentSizes[j]) : message.untrustedChunkContentSizes[j];
                    else
                        object.untrustedChunkContentSizes[j] = options.longs === String ? $util.Long.prototype.toString.call(message.untrustedChunkContentSizes[j]) : options.longs === Number ? new $util.LongBits(message.untrustedChunkContentSizes[j].low >>> 0, message.untrustedChunkContentSizes[j].high >>> 0).toNumber(true) : message.untrustedChunkContentSizes[j];
            }
            return object;
        };

        /**
         * Converts this ChunkHeader to JSON.
         * @function toJSON
         * @memberof delta_enclave_api.ChunkHeader
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChunkHeader.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChunkHeader
         * @function getTypeUrl
         * @memberof delta_enclave_api.ChunkHeader
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChunkHeader.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/delta_enclave_api.ChunkHeader";
        };

        return ChunkHeader;
    })();

    return delta_enclave_api;
})();

export const gcg = $root.gcg = (() => {

    /**
     * Namespace gcg.
     * @exports gcg
     * @namespace
     */
    const gcg = {};

    gcg.GcgRequest = (function() {

        /**
         * Properties of a GcgRequest.
         * @memberof gcg
         * @interface IGcgRequest
         * @property {gcg.IUserAuth|null} [userAuth] GcgRequest userAuth
         * @property {gcg.ICreateDataRoomRequest|null} [createDataRoomRequest] GcgRequest createDataRoomRequest
         * @property {gcg.IRetrieveDataRoomRequest|null} [retrieveDataRoomRequest] GcgRequest retrieveDataRoomRequest
         * @property {gcg.IRetrieveCurrentDataRoomConfigurationRequest|null} [retrieveCurrentDataRoomConfigurationRequest] GcgRequest retrieveCurrentDataRoomConfigurationRequest
         * @property {gcg.IRetrieveDataRoomStatusRequest|null} [retrieveDataRoomStatusRequest] GcgRequest retrieveDataRoomStatusRequest
         * @property {gcg.IUpdateDataRoomStatusRequest|null} [updateDataRoomStatusRequest] GcgRequest updateDataRoomStatusRequest
         * @property {gcg.IRetrieveAuditLogRequest|null} [retrieveAuditLogRequest] GcgRequest retrieveAuditLogRequest
         * @property {gcg.IPublishDatasetToDataRoomRequest|null} [publishDatasetToDataRoomRequest] GcgRequest publishDatasetToDataRoomRequest
         * @property {gcg.IRetrievePublishedDatasetsRequest|null} [retrievePublishedDatasetsRequest] GcgRequest retrievePublishedDatasetsRequest
         * @property {gcg.IRemovePublishedDatasetRequest|null} [removePublishedDatasetRequest] GcgRequest removePublishedDatasetRequest
         * @property {gcg.IExecuteComputeRequest|null} [executeComputeRequest] GcgRequest executeComputeRequest
         * @property {gcg.IJobStatusRequest|null} [jobStatusRequest] GcgRequest jobStatusRequest
         * @property {gcg.IGetResultsRequest|null} [getResultsRequest] GcgRequest getResultsRequest
         * @property {gcg.ICreateConfigurationCommitRequest|null} [createConfigurationCommitRequest] GcgRequest createConfigurationCommitRequest
         * @property {gcg.IRetrieveConfigurationCommitRequest|null} [retrieveConfigurationCommitRequest] GcgRequest retrieveConfigurationCommitRequest
         * @property {gcg.IExecuteDevelopmentComputeRequest|null} [executeDevelopmentComputeRequest] GcgRequest executeDevelopmentComputeRequest
         * @property {gcg.IGenerateMergeApprovalSignatureRequest|null} [generateMergeApprovalSignatureRequest] GcgRequest generateMergeApprovalSignatureRequest
         * @property {gcg.IMergeConfigurationCommitRequest|null} [mergeConfigurationCommitRequest] GcgRequest mergeConfigurationCommitRequest
         * @property {gcg.IRetrieveConfigurationCommitApproversRequest|null} [retrieveConfigurationCommitApproversRequest] GcgRequest retrieveConfigurationCommitApproversRequest
         * @property {gcg.ICasAuxiliaryStateRequest|null} [casAuxiliaryStateRequest] GcgRequest casAuxiliaryStateRequest
         * @property {gcg.IReadAuxiliaryStateRequest|null} [readAuxiliaryStateRequest] GcgRequest readAuxiliaryStateRequest
         * @property {gcg.IRetrieveUsedAirlockQuotaRequest|null} [retrieveUsedAirlockQuotaRequest] GcgRequest retrieveUsedAirlockQuotaRequest
         * @property {gcg.IGetResultsSizeRequest|null} [getResultsSizeRequest] GcgRequest getResultsSizeRequest
         * @property {identity_endorsement.IEndorsementRequest|null} [endorsementRequest] GcgRequest endorsementRequest
         */

        /**
         * Constructs a new GcgRequest.
         * @memberof gcg
         * @classdesc Represents a GcgRequest.
         * @implements IGcgRequest
         * @constructor
         * @param {gcg.IGcgRequest=} [properties] Properties to set
         */
        function GcgRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GcgRequest userAuth.
         * @member {gcg.IUserAuth|null|undefined} userAuth
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.userAuth = null;

        /**
         * GcgRequest createDataRoomRequest.
         * @member {gcg.ICreateDataRoomRequest|null|undefined} createDataRoomRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.createDataRoomRequest = null;

        /**
         * GcgRequest retrieveDataRoomRequest.
         * @member {gcg.IRetrieveDataRoomRequest|null|undefined} retrieveDataRoomRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.retrieveDataRoomRequest = null;

        /**
         * GcgRequest retrieveCurrentDataRoomConfigurationRequest.
         * @member {gcg.IRetrieveCurrentDataRoomConfigurationRequest|null|undefined} retrieveCurrentDataRoomConfigurationRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.retrieveCurrentDataRoomConfigurationRequest = null;

        /**
         * GcgRequest retrieveDataRoomStatusRequest.
         * @member {gcg.IRetrieveDataRoomStatusRequest|null|undefined} retrieveDataRoomStatusRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.retrieveDataRoomStatusRequest = null;

        /**
         * GcgRequest updateDataRoomStatusRequest.
         * @member {gcg.IUpdateDataRoomStatusRequest|null|undefined} updateDataRoomStatusRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.updateDataRoomStatusRequest = null;

        /**
         * GcgRequest retrieveAuditLogRequest.
         * @member {gcg.IRetrieveAuditLogRequest|null|undefined} retrieveAuditLogRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.retrieveAuditLogRequest = null;

        /**
         * GcgRequest publishDatasetToDataRoomRequest.
         * @member {gcg.IPublishDatasetToDataRoomRequest|null|undefined} publishDatasetToDataRoomRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.publishDatasetToDataRoomRequest = null;

        /**
         * GcgRequest retrievePublishedDatasetsRequest.
         * @member {gcg.IRetrievePublishedDatasetsRequest|null|undefined} retrievePublishedDatasetsRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.retrievePublishedDatasetsRequest = null;

        /**
         * GcgRequest removePublishedDatasetRequest.
         * @member {gcg.IRemovePublishedDatasetRequest|null|undefined} removePublishedDatasetRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.removePublishedDatasetRequest = null;

        /**
         * GcgRequest executeComputeRequest.
         * @member {gcg.IExecuteComputeRequest|null|undefined} executeComputeRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.executeComputeRequest = null;

        /**
         * GcgRequest jobStatusRequest.
         * @member {gcg.IJobStatusRequest|null|undefined} jobStatusRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.jobStatusRequest = null;

        /**
         * GcgRequest getResultsRequest.
         * @member {gcg.IGetResultsRequest|null|undefined} getResultsRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.getResultsRequest = null;

        /**
         * GcgRequest createConfigurationCommitRequest.
         * @member {gcg.ICreateConfigurationCommitRequest|null|undefined} createConfigurationCommitRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.createConfigurationCommitRequest = null;

        /**
         * GcgRequest retrieveConfigurationCommitRequest.
         * @member {gcg.IRetrieveConfigurationCommitRequest|null|undefined} retrieveConfigurationCommitRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.retrieveConfigurationCommitRequest = null;

        /**
         * GcgRequest executeDevelopmentComputeRequest.
         * @member {gcg.IExecuteDevelopmentComputeRequest|null|undefined} executeDevelopmentComputeRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.executeDevelopmentComputeRequest = null;

        /**
         * GcgRequest generateMergeApprovalSignatureRequest.
         * @member {gcg.IGenerateMergeApprovalSignatureRequest|null|undefined} generateMergeApprovalSignatureRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.generateMergeApprovalSignatureRequest = null;

        /**
         * GcgRequest mergeConfigurationCommitRequest.
         * @member {gcg.IMergeConfigurationCommitRequest|null|undefined} mergeConfigurationCommitRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.mergeConfigurationCommitRequest = null;

        /**
         * GcgRequest retrieveConfigurationCommitApproversRequest.
         * @member {gcg.IRetrieveConfigurationCommitApproversRequest|null|undefined} retrieveConfigurationCommitApproversRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.retrieveConfigurationCommitApproversRequest = null;

        /**
         * GcgRequest casAuxiliaryStateRequest.
         * @member {gcg.ICasAuxiliaryStateRequest|null|undefined} casAuxiliaryStateRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.casAuxiliaryStateRequest = null;

        /**
         * GcgRequest readAuxiliaryStateRequest.
         * @member {gcg.IReadAuxiliaryStateRequest|null|undefined} readAuxiliaryStateRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.readAuxiliaryStateRequest = null;

        /**
         * GcgRequest retrieveUsedAirlockQuotaRequest.
         * @member {gcg.IRetrieveUsedAirlockQuotaRequest|null|undefined} retrieveUsedAirlockQuotaRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.retrieveUsedAirlockQuotaRequest = null;

        /**
         * GcgRequest getResultsSizeRequest.
         * @member {gcg.IGetResultsSizeRequest|null|undefined} getResultsSizeRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.getResultsSizeRequest = null;

        /**
         * GcgRequest endorsementRequest.
         * @member {identity_endorsement.IEndorsementRequest|null|undefined} endorsementRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        GcgRequest.prototype.endorsementRequest = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * GcgRequest gcgRequest.
         * @member {"createDataRoomRequest"|"retrieveDataRoomRequest"|"retrieveCurrentDataRoomConfigurationRequest"|"retrieveDataRoomStatusRequest"|"updateDataRoomStatusRequest"|"retrieveAuditLogRequest"|"publishDatasetToDataRoomRequest"|"retrievePublishedDatasetsRequest"|"removePublishedDatasetRequest"|"executeComputeRequest"|"jobStatusRequest"|"getResultsRequest"|"createConfigurationCommitRequest"|"retrieveConfigurationCommitRequest"|"executeDevelopmentComputeRequest"|"generateMergeApprovalSignatureRequest"|"mergeConfigurationCommitRequest"|"retrieveConfigurationCommitApproversRequest"|"casAuxiliaryStateRequest"|"readAuxiliaryStateRequest"|"retrieveUsedAirlockQuotaRequest"|"getResultsSizeRequest"|"endorsementRequest"|undefined} gcgRequest
         * @memberof gcg.GcgRequest
         * @instance
         */
        Object.defineProperty(GcgRequest.prototype, "gcgRequest", {
            get: $util.oneOfGetter($oneOfFields = ["createDataRoomRequest", "retrieveDataRoomRequest", "retrieveCurrentDataRoomConfigurationRequest", "retrieveDataRoomStatusRequest", "updateDataRoomStatusRequest", "retrieveAuditLogRequest", "publishDatasetToDataRoomRequest", "retrievePublishedDatasetsRequest", "removePublishedDatasetRequest", "executeComputeRequest", "jobStatusRequest", "getResultsRequest", "createConfigurationCommitRequest", "retrieveConfigurationCommitRequest", "executeDevelopmentComputeRequest", "generateMergeApprovalSignatureRequest", "mergeConfigurationCommitRequest", "retrieveConfigurationCommitApproversRequest", "casAuxiliaryStateRequest", "readAuxiliaryStateRequest", "retrieveUsedAirlockQuotaRequest", "getResultsSizeRequest", "endorsementRequest"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GcgRequest instance using the specified properties.
         * @function create
         * @memberof gcg.GcgRequest
         * @static
         * @param {gcg.IGcgRequest=} [properties] Properties to set
         * @returns {gcg.GcgRequest} GcgRequest instance
         */
        GcgRequest.create = function create(properties) {
            return new GcgRequest(properties);
        };

        /**
         * Encodes the specified GcgRequest message. Does not implicitly {@link gcg.GcgRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.GcgRequest
         * @static
         * @param {gcg.IGcgRequest} message GcgRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GcgRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userAuth != null && Object.hasOwnProperty.call(message, "userAuth"))
                $root.gcg.UserAuth.encode(message.userAuth, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.createDataRoomRequest != null && Object.hasOwnProperty.call(message, "createDataRoomRequest"))
                $root.gcg.CreateDataRoomRequest.encode(message.createDataRoomRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.retrieveDataRoomRequest != null && Object.hasOwnProperty.call(message, "retrieveDataRoomRequest"))
                $root.gcg.RetrieveDataRoomRequest.encode(message.retrieveDataRoomRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.retrieveCurrentDataRoomConfigurationRequest != null && Object.hasOwnProperty.call(message, "retrieveCurrentDataRoomConfigurationRequest"))
                $root.gcg.RetrieveCurrentDataRoomConfigurationRequest.encode(message.retrieveCurrentDataRoomConfigurationRequest, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.retrieveDataRoomStatusRequest != null && Object.hasOwnProperty.call(message, "retrieveDataRoomStatusRequest"))
                $root.gcg.RetrieveDataRoomStatusRequest.encode(message.retrieveDataRoomStatusRequest, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.updateDataRoomStatusRequest != null && Object.hasOwnProperty.call(message, "updateDataRoomStatusRequest"))
                $root.gcg.UpdateDataRoomStatusRequest.encode(message.updateDataRoomStatusRequest, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.retrieveAuditLogRequest != null && Object.hasOwnProperty.call(message, "retrieveAuditLogRequest"))
                $root.gcg.RetrieveAuditLogRequest.encode(message.retrieveAuditLogRequest, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.publishDatasetToDataRoomRequest != null && Object.hasOwnProperty.call(message, "publishDatasetToDataRoomRequest"))
                $root.gcg.PublishDatasetToDataRoomRequest.encode(message.publishDatasetToDataRoomRequest, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.retrievePublishedDatasetsRequest != null && Object.hasOwnProperty.call(message, "retrievePublishedDatasetsRequest"))
                $root.gcg.RetrievePublishedDatasetsRequest.encode(message.retrievePublishedDatasetsRequest, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.removePublishedDatasetRequest != null && Object.hasOwnProperty.call(message, "removePublishedDatasetRequest"))
                $root.gcg.RemovePublishedDatasetRequest.encode(message.removePublishedDatasetRequest, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.executeComputeRequest != null && Object.hasOwnProperty.call(message, "executeComputeRequest"))
                $root.gcg.ExecuteComputeRequest.encode(message.executeComputeRequest, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.jobStatusRequest != null && Object.hasOwnProperty.call(message, "jobStatusRequest"))
                $root.gcg.JobStatusRequest.encode(message.jobStatusRequest, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.getResultsRequest != null && Object.hasOwnProperty.call(message, "getResultsRequest"))
                $root.gcg.GetResultsRequest.encode(message.getResultsRequest, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.createConfigurationCommitRequest != null && Object.hasOwnProperty.call(message, "createConfigurationCommitRequest"))
                $root.gcg.CreateConfigurationCommitRequest.encode(message.createConfigurationCommitRequest, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.retrieveConfigurationCommitRequest != null && Object.hasOwnProperty.call(message, "retrieveConfigurationCommitRequest"))
                $root.gcg.RetrieveConfigurationCommitRequest.encode(message.retrieveConfigurationCommitRequest, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.executeDevelopmentComputeRequest != null && Object.hasOwnProperty.call(message, "executeDevelopmentComputeRequest"))
                $root.gcg.ExecuteDevelopmentComputeRequest.encode(message.executeDevelopmentComputeRequest, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.generateMergeApprovalSignatureRequest != null && Object.hasOwnProperty.call(message, "generateMergeApprovalSignatureRequest"))
                $root.gcg.GenerateMergeApprovalSignatureRequest.encode(message.generateMergeApprovalSignatureRequest, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.mergeConfigurationCommitRequest != null && Object.hasOwnProperty.call(message, "mergeConfigurationCommitRequest"))
                $root.gcg.MergeConfigurationCommitRequest.encode(message.mergeConfigurationCommitRequest, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.retrieveConfigurationCommitApproversRequest != null && Object.hasOwnProperty.call(message, "retrieveConfigurationCommitApproversRequest"))
                $root.gcg.RetrieveConfigurationCommitApproversRequest.encode(message.retrieveConfigurationCommitApproversRequest, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.casAuxiliaryStateRequest != null && Object.hasOwnProperty.call(message, "casAuxiliaryStateRequest"))
                $root.gcg.CasAuxiliaryStateRequest.encode(message.casAuxiliaryStateRequest, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.readAuxiliaryStateRequest != null && Object.hasOwnProperty.call(message, "readAuxiliaryStateRequest"))
                $root.gcg.ReadAuxiliaryStateRequest.encode(message.readAuxiliaryStateRequest, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.retrieveUsedAirlockQuotaRequest != null && Object.hasOwnProperty.call(message, "retrieveUsedAirlockQuotaRequest"))
                $root.gcg.RetrieveUsedAirlockQuotaRequest.encode(message.retrieveUsedAirlockQuotaRequest, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.getResultsSizeRequest != null && Object.hasOwnProperty.call(message, "getResultsSizeRequest"))
                $root.gcg.GetResultsSizeRequest.encode(message.getResultsSizeRequest, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.endorsementRequest != null && Object.hasOwnProperty.call(message, "endorsementRequest"))
                $root.identity_endorsement.EndorsementRequest.encode(message.endorsementRequest, writer.uint32(/* id 101, wireType 2 =*/810).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GcgRequest message, length delimited. Does not implicitly {@link gcg.GcgRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.GcgRequest
         * @static
         * @param {gcg.IGcgRequest} message GcgRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GcgRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GcgRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.GcgRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.GcgRequest} GcgRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GcgRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.GcgRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.userAuth = $root.gcg.UserAuth.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.createDataRoomRequest = $root.gcg.CreateDataRoomRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.retrieveDataRoomRequest = $root.gcg.RetrieveDataRoomRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.retrieveCurrentDataRoomConfigurationRequest = $root.gcg.RetrieveCurrentDataRoomConfigurationRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.retrieveDataRoomStatusRequest = $root.gcg.RetrieveDataRoomStatusRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.updateDataRoomStatusRequest = $root.gcg.UpdateDataRoomStatusRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.retrieveAuditLogRequest = $root.gcg.RetrieveAuditLogRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.publishDatasetToDataRoomRequest = $root.gcg.PublishDatasetToDataRoomRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.retrievePublishedDatasetsRequest = $root.gcg.RetrievePublishedDatasetsRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.removePublishedDatasetRequest = $root.gcg.RemovePublishedDatasetRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.executeComputeRequest = $root.gcg.ExecuteComputeRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.jobStatusRequest = $root.gcg.JobStatusRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.getResultsRequest = $root.gcg.GetResultsRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 14: {
                        message.createConfigurationCommitRequest = $root.gcg.CreateConfigurationCommitRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 15: {
                        message.retrieveConfigurationCommitRequest = $root.gcg.RetrieveConfigurationCommitRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 16: {
                        message.executeDevelopmentComputeRequest = $root.gcg.ExecuteDevelopmentComputeRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 17: {
                        message.generateMergeApprovalSignatureRequest = $root.gcg.GenerateMergeApprovalSignatureRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 18: {
                        message.mergeConfigurationCommitRequest = $root.gcg.MergeConfigurationCommitRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 19: {
                        message.retrieveConfigurationCommitApproversRequest = $root.gcg.RetrieveConfigurationCommitApproversRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.casAuxiliaryStateRequest = $root.gcg.CasAuxiliaryStateRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        message.readAuxiliaryStateRequest = $root.gcg.ReadAuxiliaryStateRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 22: {
                        message.retrieveUsedAirlockQuotaRequest = $root.gcg.RetrieveUsedAirlockQuotaRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 23: {
                        message.getResultsSizeRequest = $root.gcg.GetResultsSizeRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 101: {
                        message.endorsementRequest = $root.identity_endorsement.EndorsementRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GcgRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.GcgRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.GcgRequest} GcgRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GcgRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GcgRequest message.
         * @function verify
         * @memberof gcg.GcgRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GcgRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.userAuth != null && message.hasOwnProperty("userAuth")) {
                let error = $root.gcg.UserAuth.verify(message.userAuth);
                if (error)
                    return "userAuth." + error;
            }
            if (message.createDataRoomRequest != null && message.hasOwnProperty("createDataRoomRequest")) {
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.CreateDataRoomRequest.verify(message.createDataRoomRequest);
                    if (error)
                        return "createDataRoomRequest." + error;
                }
            }
            if (message.retrieveDataRoomRequest != null && message.hasOwnProperty("retrieveDataRoomRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.RetrieveDataRoomRequest.verify(message.retrieveDataRoomRequest);
                    if (error)
                        return "retrieveDataRoomRequest." + error;
                }
            }
            if (message.retrieveCurrentDataRoomConfigurationRequest != null && message.hasOwnProperty("retrieveCurrentDataRoomConfigurationRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.RetrieveCurrentDataRoomConfigurationRequest.verify(message.retrieveCurrentDataRoomConfigurationRequest);
                    if (error)
                        return "retrieveCurrentDataRoomConfigurationRequest." + error;
                }
            }
            if (message.retrieveDataRoomStatusRequest != null && message.hasOwnProperty("retrieveDataRoomStatusRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.RetrieveDataRoomStatusRequest.verify(message.retrieveDataRoomStatusRequest);
                    if (error)
                        return "retrieveDataRoomStatusRequest." + error;
                }
            }
            if (message.updateDataRoomStatusRequest != null && message.hasOwnProperty("updateDataRoomStatusRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.UpdateDataRoomStatusRequest.verify(message.updateDataRoomStatusRequest);
                    if (error)
                        return "updateDataRoomStatusRequest." + error;
                }
            }
            if (message.retrieveAuditLogRequest != null && message.hasOwnProperty("retrieveAuditLogRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.RetrieveAuditLogRequest.verify(message.retrieveAuditLogRequest);
                    if (error)
                        return "retrieveAuditLogRequest." + error;
                }
            }
            if (message.publishDatasetToDataRoomRequest != null && message.hasOwnProperty("publishDatasetToDataRoomRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.PublishDatasetToDataRoomRequest.verify(message.publishDatasetToDataRoomRequest);
                    if (error)
                        return "publishDatasetToDataRoomRequest." + error;
                }
            }
            if (message.retrievePublishedDatasetsRequest != null && message.hasOwnProperty("retrievePublishedDatasetsRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.RetrievePublishedDatasetsRequest.verify(message.retrievePublishedDatasetsRequest);
                    if (error)
                        return "retrievePublishedDatasetsRequest." + error;
                }
            }
            if (message.removePublishedDatasetRequest != null && message.hasOwnProperty("removePublishedDatasetRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.RemovePublishedDatasetRequest.verify(message.removePublishedDatasetRequest);
                    if (error)
                        return "removePublishedDatasetRequest." + error;
                }
            }
            if (message.executeComputeRequest != null && message.hasOwnProperty("executeComputeRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.ExecuteComputeRequest.verify(message.executeComputeRequest);
                    if (error)
                        return "executeComputeRequest." + error;
                }
            }
            if (message.jobStatusRequest != null && message.hasOwnProperty("jobStatusRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.JobStatusRequest.verify(message.jobStatusRequest);
                    if (error)
                        return "jobStatusRequest." + error;
                }
            }
            if (message.getResultsRequest != null && message.hasOwnProperty("getResultsRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.GetResultsRequest.verify(message.getResultsRequest);
                    if (error)
                        return "getResultsRequest." + error;
                }
            }
            if (message.createConfigurationCommitRequest != null && message.hasOwnProperty("createConfigurationCommitRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.CreateConfigurationCommitRequest.verify(message.createConfigurationCommitRequest);
                    if (error)
                        return "createConfigurationCommitRequest." + error;
                }
            }
            if (message.retrieveConfigurationCommitRequest != null && message.hasOwnProperty("retrieveConfigurationCommitRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.RetrieveConfigurationCommitRequest.verify(message.retrieveConfigurationCommitRequest);
                    if (error)
                        return "retrieveConfigurationCommitRequest." + error;
                }
            }
            if (message.executeDevelopmentComputeRequest != null && message.hasOwnProperty("executeDevelopmentComputeRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.ExecuteDevelopmentComputeRequest.verify(message.executeDevelopmentComputeRequest);
                    if (error)
                        return "executeDevelopmentComputeRequest." + error;
                }
            }
            if (message.generateMergeApprovalSignatureRequest != null && message.hasOwnProperty("generateMergeApprovalSignatureRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.GenerateMergeApprovalSignatureRequest.verify(message.generateMergeApprovalSignatureRequest);
                    if (error)
                        return "generateMergeApprovalSignatureRequest." + error;
                }
            }
            if (message.mergeConfigurationCommitRequest != null && message.hasOwnProperty("mergeConfigurationCommitRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.MergeConfigurationCommitRequest.verify(message.mergeConfigurationCommitRequest);
                    if (error)
                        return "mergeConfigurationCommitRequest." + error;
                }
            }
            if (message.retrieveConfigurationCommitApproversRequest != null && message.hasOwnProperty("retrieveConfigurationCommitApproversRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.RetrieveConfigurationCommitApproversRequest.verify(message.retrieveConfigurationCommitApproversRequest);
                    if (error)
                        return "retrieveConfigurationCommitApproversRequest." + error;
                }
            }
            if (message.casAuxiliaryStateRequest != null && message.hasOwnProperty("casAuxiliaryStateRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.CasAuxiliaryStateRequest.verify(message.casAuxiliaryStateRequest);
                    if (error)
                        return "casAuxiliaryStateRequest." + error;
                }
            }
            if (message.readAuxiliaryStateRequest != null && message.hasOwnProperty("readAuxiliaryStateRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.ReadAuxiliaryStateRequest.verify(message.readAuxiliaryStateRequest);
                    if (error)
                        return "readAuxiliaryStateRequest." + error;
                }
            }
            if (message.retrieveUsedAirlockQuotaRequest != null && message.hasOwnProperty("retrieveUsedAirlockQuotaRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.RetrieveUsedAirlockQuotaRequest.verify(message.retrieveUsedAirlockQuotaRequest);
                    if (error)
                        return "retrieveUsedAirlockQuotaRequest." + error;
                }
            }
            if (message.getResultsSizeRequest != null && message.hasOwnProperty("getResultsSizeRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.gcg.GetResultsSizeRequest.verify(message.getResultsSizeRequest);
                    if (error)
                        return "getResultsSizeRequest." + error;
                }
            }
            if (message.endorsementRequest != null && message.hasOwnProperty("endorsementRequest")) {
                if (properties.gcgRequest === 1)
                    return "gcgRequest: multiple values";
                properties.gcgRequest = 1;
                {
                    let error = $root.identity_endorsement.EndorsementRequest.verify(message.endorsementRequest);
                    if (error)
                        return "endorsementRequest." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GcgRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.GcgRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.GcgRequest} GcgRequest
         */
        GcgRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.GcgRequest)
                return object;
            let message = new $root.gcg.GcgRequest();
            if (object.userAuth != null) {
                if (typeof object.userAuth !== "object")
                    throw TypeError(".gcg.GcgRequest.userAuth: object expected");
                message.userAuth = $root.gcg.UserAuth.fromObject(object.userAuth);
            }
            if (object.createDataRoomRequest != null) {
                if (typeof object.createDataRoomRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.createDataRoomRequest: object expected");
                message.createDataRoomRequest = $root.gcg.CreateDataRoomRequest.fromObject(object.createDataRoomRequest);
            }
            if (object.retrieveDataRoomRequest != null) {
                if (typeof object.retrieveDataRoomRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.retrieveDataRoomRequest: object expected");
                message.retrieveDataRoomRequest = $root.gcg.RetrieveDataRoomRequest.fromObject(object.retrieveDataRoomRequest);
            }
            if (object.retrieveCurrentDataRoomConfigurationRequest != null) {
                if (typeof object.retrieveCurrentDataRoomConfigurationRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.retrieveCurrentDataRoomConfigurationRequest: object expected");
                message.retrieveCurrentDataRoomConfigurationRequest = $root.gcg.RetrieveCurrentDataRoomConfigurationRequest.fromObject(object.retrieveCurrentDataRoomConfigurationRequest);
            }
            if (object.retrieveDataRoomStatusRequest != null) {
                if (typeof object.retrieveDataRoomStatusRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.retrieveDataRoomStatusRequest: object expected");
                message.retrieveDataRoomStatusRequest = $root.gcg.RetrieveDataRoomStatusRequest.fromObject(object.retrieveDataRoomStatusRequest);
            }
            if (object.updateDataRoomStatusRequest != null) {
                if (typeof object.updateDataRoomStatusRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.updateDataRoomStatusRequest: object expected");
                message.updateDataRoomStatusRequest = $root.gcg.UpdateDataRoomStatusRequest.fromObject(object.updateDataRoomStatusRequest);
            }
            if (object.retrieveAuditLogRequest != null) {
                if (typeof object.retrieveAuditLogRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.retrieveAuditLogRequest: object expected");
                message.retrieveAuditLogRequest = $root.gcg.RetrieveAuditLogRequest.fromObject(object.retrieveAuditLogRequest);
            }
            if (object.publishDatasetToDataRoomRequest != null) {
                if (typeof object.publishDatasetToDataRoomRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.publishDatasetToDataRoomRequest: object expected");
                message.publishDatasetToDataRoomRequest = $root.gcg.PublishDatasetToDataRoomRequest.fromObject(object.publishDatasetToDataRoomRequest);
            }
            if (object.retrievePublishedDatasetsRequest != null) {
                if (typeof object.retrievePublishedDatasetsRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.retrievePublishedDatasetsRequest: object expected");
                message.retrievePublishedDatasetsRequest = $root.gcg.RetrievePublishedDatasetsRequest.fromObject(object.retrievePublishedDatasetsRequest);
            }
            if (object.removePublishedDatasetRequest != null) {
                if (typeof object.removePublishedDatasetRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.removePublishedDatasetRequest: object expected");
                message.removePublishedDatasetRequest = $root.gcg.RemovePublishedDatasetRequest.fromObject(object.removePublishedDatasetRequest);
            }
            if (object.executeComputeRequest != null) {
                if (typeof object.executeComputeRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.executeComputeRequest: object expected");
                message.executeComputeRequest = $root.gcg.ExecuteComputeRequest.fromObject(object.executeComputeRequest);
            }
            if (object.jobStatusRequest != null) {
                if (typeof object.jobStatusRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.jobStatusRequest: object expected");
                message.jobStatusRequest = $root.gcg.JobStatusRequest.fromObject(object.jobStatusRequest);
            }
            if (object.getResultsRequest != null) {
                if (typeof object.getResultsRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.getResultsRequest: object expected");
                message.getResultsRequest = $root.gcg.GetResultsRequest.fromObject(object.getResultsRequest);
            }
            if (object.createConfigurationCommitRequest != null) {
                if (typeof object.createConfigurationCommitRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.createConfigurationCommitRequest: object expected");
                message.createConfigurationCommitRequest = $root.gcg.CreateConfigurationCommitRequest.fromObject(object.createConfigurationCommitRequest);
            }
            if (object.retrieveConfigurationCommitRequest != null) {
                if (typeof object.retrieveConfigurationCommitRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.retrieveConfigurationCommitRequest: object expected");
                message.retrieveConfigurationCommitRequest = $root.gcg.RetrieveConfigurationCommitRequest.fromObject(object.retrieveConfigurationCommitRequest);
            }
            if (object.executeDevelopmentComputeRequest != null) {
                if (typeof object.executeDevelopmentComputeRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.executeDevelopmentComputeRequest: object expected");
                message.executeDevelopmentComputeRequest = $root.gcg.ExecuteDevelopmentComputeRequest.fromObject(object.executeDevelopmentComputeRequest);
            }
            if (object.generateMergeApprovalSignatureRequest != null) {
                if (typeof object.generateMergeApprovalSignatureRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.generateMergeApprovalSignatureRequest: object expected");
                message.generateMergeApprovalSignatureRequest = $root.gcg.GenerateMergeApprovalSignatureRequest.fromObject(object.generateMergeApprovalSignatureRequest);
            }
            if (object.mergeConfigurationCommitRequest != null) {
                if (typeof object.mergeConfigurationCommitRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.mergeConfigurationCommitRequest: object expected");
                message.mergeConfigurationCommitRequest = $root.gcg.MergeConfigurationCommitRequest.fromObject(object.mergeConfigurationCommitRequest);
            }
            if (object.retrieveConfigurationCommitApproversRequest != null) {
                if (typeof object.retrieveConfigurationCommitApproversRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.retrieveConfigurationCommitApproversRequest: object expected");
                message.retrieveConfigurationCommitApproversRequest = $root.gcg.RetrieveConfigurationCommitApproversRequest.fromObject(object.retrieveConfigurationCommitApproversRequest);
            }
            if (object.casAuxiliaryStateRequest != null) {
                if (typeof object.casAuxiliaryStateRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.casAuxiliaryStateRequest: object expected");
                message.casAuxiliaryStateRequest = $root.gcg.CasAuxiliaryStateRequest.fromObject(object.casAuxiliaryStateRequest);
            }
            if (object.readAuxiliaryStateRequest != null) {
                if (typeof object.readAuxiliaryStateRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.readAuxiliaryStateRequest: object expected");
                message.readAuxiliaryStateRequest = $root.gcg.ReadAuxiliaryStateRequest.fromObject(object.readAuxiliaryStateRequest);
            }
            if (object.retrieveUsedAirlockQuotaRequest != null) {
                if (typeof object.retrieveUsedAirlockQuotaRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.retrieveUsedAirlockQuotaRequest: object expected");
                message.retrieveUsedAirlockQuotaRequest = $root.gcg.RetrieveUsedAirlockQuotaRequest.fromObject(object.retrieveUsedAirlockQuotaRequest);
            }
            if (object.getResultsSizeRequest != null) {
                if (typeof object.getResultsSizeRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.getResultsSizeRequest: object expected");
                message.getResultsSizeRequest = $root.gcg.GetResultsSizeRequest.fromObject(object.getResultsSizeRequest);
            }
            if (object.endorsementRequest != null) {
                if (typeof object.endorsementRequest !== "object")
                    throw TypeError(".gcg.GcgRequest.endorsementRequest: object expected");
                message.endorsementRequest = $root.identity_endorsement.EndorsementRequest.fromObject(object.endorsementRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a GcgRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.GcgRequest
         * @static
         * @param {gcg.GcgRequest} message GcgRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GcgRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.userAuth = null;
            if (message.userAuth != null && message.hasOwnProperty("userAuth"))
                object.userAuth = $root.gcg.UserAuth.toObject(message.userAuth, options);
            if (message.createDataRoomRequest != null && message.hasOwnProperty("createDataRoomRequest")) {
                object.createDataRoomRequest = $root.gcg.CreateDataRoomRequest.toObject(message.createDataRoomRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "createDataRoomRequest";
            }
            if (message.retrieveDataRoomRequest != null && message.hasOwnProperty("retrieveDataRoomRequest")) {
                object.retrieveDataRoomRequest = $root.gcg.RetrieveDataRoomRequest.toObject(message.retrieveDataRoomRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "retrieveDataRoomRequest";
            }
            if (message.retrieveCurrentDataRoomConfigurationRequest != null && message.hasOwnProperty("retrieveCurrentDataRoomConfigurationRequest")) {
                object.retrieveCurrentDataRoomConfigurationRequest = $root.gcg.RetrieveCurrentDataRoomConfigurationRequest.toObject(message.retrieveCurrentDataRoomConfigurationRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "retrieveCurrentDataRoomConfigurationRequest";
            }
            if (message.retrieveDataRoomStatusRequest != null && message.hasOwnProperty("retrieveDataRoomStatusRequest")) {
                object.retrieveDataRoomStatusRequest = $root.gcg.RetrieveDataRoomStatusRequest.toObject(message.retrieveDataRoomStatusRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "retrieveDataRoomStatusRequest";
            }
            if (message.updateDataRoomStatusRequest != null && message.hasOwnProperty("updateDataRoomStatusRequest")) {
                object.updateDataRoomStatusRequest = $root.gcg.UpdateDataRoomStatusRequest.toObject(message.updateDataRoomStatusRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "updateDataRoomStatusRequest";
            }
            if (message.retrieveAuditLogRequest != null && message.hasOwnProperty("retrieveAuditLogRequest")) {
                object.retrieveAuditLogRequest = $root.gcg.RetrieveAuditLogRequest.toObject(message.retrieveAuditLogRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "retrieveAuditLogRequest";
            }
            if (message.publishDatasetToDataRoomRequest != null && message.hasOwnProperty("publishDatasetToDataRoomRequest")) {
                object.publishDatasetToDataRoomRequest = $root.gcg.PublishDatasetToDataRoomRequest.toObject(message.publishDatasetToDataRoomRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "publishDatasetToDataRoomRequest";
            }
            if (message.retrievePublishedDatasetsRequest != null && message.hasOwnProperty("retrievePublishedDatasetsRequest")) {
                object.retrievePublishedDatasetsRequest = $root.gcg.RetrievePublishedDatasetsRequest.toObject(message.retrievePublishedDatasetsRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "retrievePublishedDatasetsRequest";
            }
            if (message.removePublishedDatasetRequest != null && message.hasOwnProperty("removePublishedDatasetRequest")) {
                object.removePublishedDatasetRequest = $root.gcg.RemovePublishedDatasetRequest.toObject(message.removePublishedDatasetRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "removePublishedDatasetRequest";
            }
            if (message.executeComputeRequest != null && message.hasOwnProperty("executeComputeRequest")) {
                object.executeComputeRequest = $root.gcg.ExecuteComputeRequest.toObject(message.executeComputeRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "executeComputeRequest";
            }
            if (message.jobStatusRequest != null && message.hasOwnProperty("jobStatusRequest")) {
                object.jobStatusRequest = $root.gcg.JobStatusRequest.toObject(message.jobStatusRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "jobStatusRequest";
            }
            if (message.getResultsRequest != null && message.hasOwnProperty("getResultsRequest")) {
                object.getResultsRequest = $root.gcg.GetResultsRequest.toObject(message.getResultsRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "getResultsRequest";
            }
            if (message.createConfigurationCommitRequest != null && message.hasOwnProperty("createConfigurationCommitRequest")) {
                object.createConfigurationCommitRequest = $root.gcg.CreateConfigurationCommitRequest.toObject(message.createConfigurationCommitRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "createConfigurationCommitRequest";
            }
            if (message.retrieveConfigurationCommitRequest != null && message.hasOwnProperty("retrieveConfigurationCommitRequest")) {
                object.retrieveConfigurationCommitRequest = $root.gcg.RetrieveConfigurationCommitRequest.toObject(message.retrieveConfigurationCommitRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "retrieveConfigurationCommitRequest";
            }
            if (message.executeDevelopmentComputeRequest != null && message.hasOwnProperty("executeDevelopmentComputeRequest")) {
                object.executeDevelopmentComputeRequest = $root.gcg.ExecuteDevelopmentComputeRequest.toObject(message.executeDevelopmentComputeRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "executeDevelopmentComputeRequest";
            }
            if (message.generateMergeApprovalSignatureRequest != null && message.hasOwnProperty("generateMergeApprovalSignatureRequest")) {
                object.generateMergeApprovalSignatureRequest = $root.gcg.GenerateMergeApprovalSignatureRequest.toObject(message.generateMergeApprovalSignatureRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "generateMergeApprovalSignatureRequest";
            }
            if (message.mergeConfigurationCommitRequest != null && message.hasOwnProperty("mergeConfigurationCommitRequest")) {
                object.mergeConfigurationCommitRequest = $root.gcg.MergeConfigurationCommitRequest.toObject(message.mergeConfigurationCommitRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "mergeConfigurationCommitRequest";
            }
            if (message.retrieveConfigurationCommitApproversRequest != null && message.hasOwnProperty("retrieveConfigurationCommitApproversRequest")) {
                object.retrieveConfigurationCommitApproversRequest = $root.gcg.RetrieveConfigurationCommitApproversRequest.toObject(message.retrieveConfigurationCommitApproversRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "retrieveConfigurationCommitApproversRequest";
            }
            if (message.casAuxiliaryStateRequest != null && message.hasOwnProperty("casAuxiliaryStateRequest")) {
                object.casAuxiliaryStateRequest = $root.gcg.CasAuxiliaryStateRequest.toObject(message.casAuxiliaryStateRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "casAuxiliaryStateRequest";
            }
            if (message.readAuxiliaryStateRequest != null && message.hasOwnProperty("readAuxiliaryStateRequest")) {
                object.readAuxiliaryStateRequest = $root.gcg.ReadAuxiliaryStateRequest.toObject(message.readAuxiliaryStateRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "readAuxiliaryStateRequest";
            }
            if (message.retrieveUsedAirlockQuotaRequest != null && message.hasOwnProperty("retrieveUsedAirlockQuotaRequest")) {
                object.retrieveUsedAirlockQuotaRequest = $root.gcg.RetrieveUsedAirlockQuotaRequest.toObject(message.retrieveUsedAirlockQuotaRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "retrieveUsedAirlockQuotaRequest";
            }
            if (message.getResultsSizeRequest != null && message.hasOwnProperty("getResultsSizeRequest")) {
                object.getResultsSizeRequest = $root.gcg.GetResultsSizeRequest.toObject(message.getResultsSizeRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "getResultsSizeRequest";
            }
            if (message.endorsementRequest != null && message.hasOwnProperty("endorsementRequest")) {
                object.endorsementRequest = $root.identity_endorsement.EndorsementRequest.toObject(message.endorsementRequest, options);
                if (options.oneofs)
                    object.gcgRequest = "endorsementRequest";
            }
            return object;
        };

        /**
         * Converts this GcgRequest to JSON.
         * @function toJSON
         * @memberof gcg.GcgRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GcgRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GcgRequest
         * @function getTypeUrl
         * @memberof gcg.GcgRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GcgRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.GcgRequest";
        };

        return GcgRequest;
    })();

    gcg.GcgResponse = (function() {

        /**
         * Properties of a GcgResponse.
         * @memberof gcg
         * @interface IGcgResponse
         * @property {string|null} [failure] GcgResponse failure
         * @property {gcg.ICreateDataRoomResponse|null} [createDataRoomResponse] GcgResponse createDataRoomResponse
         * @property {gcg.IRetrieveDataRoomResponse|null} [retrieveDataRoomResponse] GcgResponse retrieveDataRoomResponse
         * @property {gcg.IRetrieveCurrentDataRoomConfigurationResponse|null} [retrieveCurrentDataRoomConfigurationResponse] GcgResponse retrieveCurrentDataRoomConfigurationResponse
         * @property {gcg.IRetrieveDataRoomStatusResponse|null} [retrieveDataRoomStatusResponse] GcgResponse retrieveDataRoomStatusResponse
         * @property {gcg.IUpdateDataRoomStatusResponse|null} [updateDataRoomStatusResponse] GcgResponse updateDataRoomStatusResponse
         * @property {gcg.IRetrieveAuditLogResponse|null} [retrieveAuditLogResponse] GcgResponse retrieveAuditLogResponse
         * @property {gcg.IPublishDatasetToDataRoomResponse|null} [publishDatasetToDataRoomResponse] GcgResponse publishDatasetToDataRoomResponse
         * @property {gcg.IRetrievePublishedDatasetsResponse|null} [retrievePublishedDatasetsResponse] GcgResponse retrievePublishedDatasetsResponse
         * @property {gcg.IRemovePublishedDatasetResponse|null} [removePublishedDatasetResponse] GcgResponse removePublishedDatasetResponse
         * @property {gcg.IExecuteComputeResponse|null} [executeComputeResponse] GcgResponse executeComputeResponse
         * @property {gcg.IJobStatusResponse|null} [jobStatusResponse] GcgResponse jobStatusResponse
         * @property {gcg.IGetResultsResponseChunk|null} [getResultsResponseChunk] GcgResponse getResultsResponseChunk
         * @property {gcg.IGetResultsResponseFooter|null} [getResultsResponseFooter] GcgResponse getResultsResponseFooter
         * @property {gcg.ICreateConfigurationCommitResponse|null} [createConfigurationCommitResponse] GcgResponse createConfigurationCommitResponse
         * @property {gcg.IRetrieveConfigurationCommitResponse|null} [retrieveConfigurationCommitResponse] GcgResponse retrieveConfigurationCommitResponse
         * @property {gcg.IGenerateMergeApprovalSignatureResponse|null} [generateMergeApprovalSignatureResponse] GcgResponse generateMergeApprovalSignatureResponse
         * @property {gcg.IMergeConfigurationCommitResponse|null} [mergeConfigurationCommitResponse] GcgResponse mergeConfigurationCommitResponse
         * @property {gcg.IRetrieveConfigurationCommitApproversResponse|null} [retrieveConfigurationCommitApproversResponse] GcgResponse retrieveConfigurationCommitApproversResponse
         * @property {gcg.ICasAuxiliaryStateResponse|null} [casAuxiliaryStateResponse] GcgResponse casAuxiliaryStateResponse
         * @property {gcg.IReadAuxiliaryStateResponse|null} [readAuxiliaryStateResponse] GcgResponse readAuxiliaryStateResponse
         * @property {gcg.IRetrieveUsedAirlockQuotaResponse|null} [retrieveUsedAirlockQuotaResponse] GcgResponse retrieveUsedAirlockQuotaResponse
         * @property {gcg.IGetResultsSizeResponse|null} [getResultsSizeResponse] GcgResponse getResultsSizeResponse
         * @property {identity_endorsement.IEndorsementResponse|null} [endorsementResponse] GcgResponse endorsementResponse
         */

        /**
         * Constructs a new GcgResponse.
         * @memberof gcg
         * @classdesc Represents a GcgResponse.
         * @implements IGcgResponse
         * @constructor
         * @param {gcg.IGcgResponse=} [properties] Properties to set
         */
        function GcgResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GcgResponse failure.
         * @member {string|null|undefined} failure
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.failure = null;

        /**
         * GcgResponse createDataRoomResponse.
         * @member {gcg.ICreateDataRoomResponse|null|undefined} createDataRoomResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.createDataRoomResponse = null;

        /**
         * GcgResponse retrieveDataRoomResponse.
         * @member {gcg.IRetrieveDataRoomResponse|null|undefined} retrieveDataRoomResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.retrieveDataRoomResponse = null;

        /**
         * GcgResponse retrieveCurrentDataRoomConfigurationResponse.
         * @member {gcg.IRetrieveCurrentDataRoomConfigurationResponse|null|undefined} retrieveCurrentDataRoomConfigurationResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.retrieveCurrentDataRoomConfigurationResponse = null;

        /**
         * GcgResponse retrieveDataRoomStatusResponse.
         * @member {gcg.IRetrieveDataRoomStatusResponse|null|undefined} retrieveDataRoomStatusResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.retrieveDataRoomStatusResponse = null;

        /**
         * GcgResponse updateDataRoomStatusResponse.
         * @member {gcg.IUpdateDataRoomStatusResponse|null|undefined} updateDataRoomStatusResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.updateDataRoomStatusResponse = null;

        /**
         * GcgResponse retrieveAuditLogResponse.
         * @member {gcg.IRetrieveAuditLogResponse|null|undefined} retrieveAuditLogResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.retrieveAuditLogResponse = null;

        /**
         * GcgResponse publishDatasetToDataRoomResponse.
         * @member {gcg.IPublishDatasetToDataRoomResponse|null|undefined} publishDatasetToDataRoomResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.publishDatasetToDataRoomResponse = null;

        /**
         * GcgResponse retrievePublishedDatasetsResponse.
         * @member {gcg.IRetrievePublishedDatasetsResponse|null|undefined} retrievePublishedDatasetsResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.retrievePublishedDatasetsResponse = null;

        /**
         * GcgResponse removePublishedDatasetResponse.
         * @member {gcg.IRemovePublishedDatasetResponse|null|undefined} removePublishedDatasetResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.removePublishedDatasetResponse = null;

        /**
         * GcgResponse executeComputeResponse.
         * @member {gcg.IExecuteComputeResponse|null|undefined} executeComputeResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.executeComputeResponse = null;

        /**
         * GcgResponse jobStatusResponse.
         * @member {gcg.IJobStatusResponse|null|undefined} jobStatusResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.jobStatusResponse = null;

        /**
         * GcgResponse getResultsResponseChunk.
         * @member {gcg.IGetResultsResponseChunk|null|undefined} getResultsResponseChunk
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.getResultsResponseChunk = null;

        /**
         * GcgResponse getResultsResponseFooter.
         * @member {gcg.IGetResultsResponseFooter|null|undefined} getResultsResponseFooter
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.getResultsResponseFooter = null;

        /**
         * GcgResponse createConfigurationCommitResponse.
         * @member {gcg.ICreateConfigurationCommitResponse|null|undefined} createConfigurationCommitResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.createConfigurationCommitResponse = null;

        /**
         * GcgResponse retrieveConfigurationCommitResponse.
         * @member {gcg.IRetrieveConfigurationCommitResponse|null|undefined} retrieveConfigurationCommitResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.retrieveConfigurationCommitResponse = null;

        /**
         * GcgResponse generateMergeApprovalSignatureResponse.
         * @member {gcg.IGenerateMergeApprovalSignatureResponse|null|undefined} generateMergeApprovalSignatureResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.generateMergeApprovalSignatureResponse = null;

        /**
         * GcgResponse mergeConfigurationCommitResponse.
         * @member {gcg.IMergeConfigurationCommitResponse|null|undefined} mergeConfigurationCommitResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.mergeConfigurationCommitResponse = null;

        /**
         * GcgResponse retrieveConfigurationCommitApproversResponse.
         * @member {gcg.IRetrieveConfigurationCommitApproversResponse|null|undefined} retrieveConfigurationCommitApproversResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.retrieveConfigurationCommitApproversResponse = null;

        /**
         * GcgResponse casAuxiliaryStateResponse.
         * @member {gcg.ICasAuxiliaryStateResponse|null|undefined} casAuxiliaryStateResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.casAuxiliaryStateResponse = null;

        /**
         * GcgResponse readAuxiliaryStateResponse.
         * @member {gcg.IReadAuxiliaryStateResponse|null|undefined} readAuxiliaryStateResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.readAuxiliaryStateResponse = null;

        /**
         * GcgResponse retrieveUsedAirlockQuotaResponse.
         * @member {gcg.IRetrieveUsedAirlockQuotaResponse|null|undefined} retrieveUsedAirlockQuotaResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.retrieveUsedAirlockQuotaResponse = null;

        /**
         * GcgResponse getResultsSizeResponse.
         * @member {gcg.IGetResultsSizeResponse|null|undefined} getResultsSizeResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.getResultsSizeResponse = null;

        /**
         * GcgResponse endorsementResponse.
         * @member {identity_endorsement.IEndorsementResponse|null|undefined} endorsementResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        GcgResponse.prototype.endorsementResponse = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * GcgResponse gcgResponse.
         * @member {"failure"|"createDataRoomResponse"|"retrieveDataRoomResponse"|"retrieveCurrentDataRoomConfigurationResponse"|"retrieveDataRoomStatusResponse"|"updateDataRoomStatusResponse"|"retrieveAuditLogResponse"|"publishDatasetToDataRoomResponse"|"retrievePublishedDatasetsResponse"|"removePublishedDatasetResponse"|"executeComputeResponse"|"jobStatusResponse"|"getResultsResponseChunk"|"getResultsResponseFooter"|"createConfigurationCommitResponse"|"retrieveConfigurationCommitResponse"|"generateMergeApprovalSignatureResponse"|"mergeConfigurationCommitResponse"|"retrieveConfigurationCommitApproversResponse"|"casAuxiliaryStateResponse"|"readAuxiliaryStateResponse"|"retrieveUsedAirlockQuotaResponse"|"getResultsSizeResponse"|"endorsementResponse"|undefined} gcgResponse
         * @memberof gcg.GcgResponse
         * @instance
         */
        Object.defineProperty(GcgResponse.prototype, "gcgResponse", {
            get: $util.oneOfGetter($oneOfFields = ["failure", "createDataRoomResponse", "retrieveDataRoomResponse", "retrieveCurrentDataRoomConfigurationResponse", "retrieveDataRoomStatusResponse", "updateDataRoomStatusResponse", "retrieveAuditLogResponse", "publishDatasetToDataRoomResponse", "retrievePublishedDatasetsResponse", "removePublishedDatasetResponse", "executeComputeResponse", "jobStatusResponse", "getResultsResponseChunk", "getResultsResponseFooter", "createConfigurationCommitResponse", "retrieveConfigurationCommitResponse", "generateMergeApprovalSignatureResponse", "mergeConfigurationCommitResponse", "retrieveConfigurationCommitApproversResponse", "casAuxiliaryStateResponse", "readAuxiliaryStateResponse", "retrieveUsedAirlockQuotaResponse", "getResultsSizeResponse", "endorsementResponse"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GcgResponse instance using the specified properties.
         * @function create
         * @memberof gcg.GcgResponse
         * @static
         * @param {gcg.IGcgResponse=} [properties] Properties to set
         * @returns {gcg.GcgResponse} GcgResponse instance
         */
        GcgResponse.create = function create(properties) {
            return new GcgResponse(properties);
        };

        /**
         * Encodes the specified GcgResponse message. Does not implicitly {@link gcg.GcgResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.GcgResponse
         * @static
         * @param {gcg.IGcgResponse} message GcgResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GcgResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.failure != null && Object.hasOwnProperty.call(message, "failure"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.failure);
            if (message.createDataRoomResponse != null && Object.hasOwnProperty.call(message, "createDataRoomResponse"))
                $root.gcg.CreateDataRoomResponse.encode(message.createDataRoomResponse, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.retrieveDataRoomResponse != null && Object.hasOwnProperty.call(message, "retrieveDataRoomResponse"))
                $root.gcg.RetrieveDataRoomResponse.encode(message.retrieveDataRoomResponse, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.retrieveCurrentDataRoomConfigurationResponse != null && Object.hasOwnProperty.call(message, "retrieveCurrentDataRoomConfigurationResponse"))
                $root.gcg.RetrieveCurrentDataRoomConfigurationResponse.encode(message.retrieveCurrentDataRoomConfigurationResponse, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.retrieveDataRoomStatusResponse != null && Object.hasOwnProperty.call(message, "retrieveDataRoomStatusResponse"))
                $root.gcg.RetrieveDataRoomStatusResponse.encode(message.retrieveDataRoomStatusResponse, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.updateDataRoomStatusResponse != null && Object.hasOwnProperty.call(message, "updateDataRoomStatusResponse"))
                $root.gcg.UpdateDataRoomStatusResponse.encode(message.updateDataRoomStatusResponse, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.retrieveAuditLogResponse != null && Object.hasOwnProperty.call(message, "retrieveAuditLogResponse"))
                $root.gcg.RetrieveAuditLogResponse.encode(message.retrieveAuditLogResponse, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.publishDatasetToDataRoomResponse != null && Object.hasOwnProperty.call(message, "publishDatasetToDataRoomResponse"))
                $root.gcg.PublishDatasetToDataRoomResponse.encode(message.publishDatasetToDataRoomResponse, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.retrievePublishedDatasetsResponse != null && Object.hasOwnProperty.call(message, "retrievePublishedDatasetsResponse"))
                $root.gcg.RetrievePublishedDatasetsResponse.encode(message.retrievePublishedDatasetsResponse, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.removePublishedDatasetResponse != null && Object.hasOwnProperty.call(message, "removePublishedDatasetResponse"))
                $root.gcg.RemovePublishedDatasetResponse.encode(message.removePublishedDatasetResponse, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.executeComputeResponse != null && Object.hasOwnProperty.call(message, "executeComputeResponse"))
                $root.gcg.ExecuteComputeResponse.encode(message.executeComputeResponse, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.jobStatusResponse != null && Object.hasOwnProperty.call(message, "jobStatusResponse"))
                $root.gcg.JobStatusResponse.encode(message.jobStatusResponse, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.getResultsResponseChunk != null && Object.hasOwnProperty.call(message, "getResultsResponseChunk"))
                $root.gcg.GetResultsResponseChunk.encode(message.getResultsResponseChunk, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.getResultsResponseFooter != null && Object.hasOwnProperty.call(message, "getResultsResponseFooter"))
                $root.gcg.GetResultsResponseFooter.encode(message.getResultsResponseFooter, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.createConfigurationCommitResponse != null && Object.hasOwnProperty.call(message, "createConfigurationCommitResponse"))
                $root.gcg.CreateConfigurationCommitResponse.encode(message.createConfigurationCommitResponse, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.retrieveConfigurationCommitResponse != null && Object.hasOwnProperty.call(message, "retrieveConfigurationCommitResponse"))
                $root.gcg.RetrieveConfigurationCommitResponse.encode(message.retrieveConfigurationCommitResponse, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.generateMergeApprovalSignatureResponse != null && Object.hasOwnProperty.call(message, "generateMergeApprovalSignatureResponse"))
                $root.gcg.GenerateMergeApprovalSignatureResponse.encode(message.generateMergeApprovalSignatureResponse, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.mergeConfigurationCommitResponse != null && Object.hasOwnProperty.call(message, "mergeConfigurationCommitResponse"))
                $root.gcg.MergeConfigurationCommitResponse.encode(message.mergeConfigurationCommitResponse, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.retrieveConfigurationCommitApproversResponse != null && Object.hasOwnProperty.call(message, "retrieveConfigurationCommitApproversResponse"))
                $root.gcg.RetrieveConfigurationCommitApproversResponse.encode(message.retrieveConfigurationCommitApproversResponse, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.casAuxiliaryStateResponse != null && Object.hasOwnProperty.call(message, "casAuxiliaryStateResponse"))
                $root.gcg.CasAuxiliaryStateResponse.encode(message.casAuxiliaryStateResponse, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.readAuxiliaryStateResponse != null && Object.hasOwnProperty.call(message, "readAuxiliaryStateResponse"))
                $root.gcg.ReadAuxiliaryStateResponse.encode(message.readAuxiliaryStateResponse, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.retrieveUsedAirlockQuotaResponse != null && Object.hasOwnProperty.call(message, "retrieveUsedAirlockQuotaResponse"))
                $root.gcg.RetrieveUsedAirlockQuotaResponse.encode(message.retrieveUsedAirlockQuotaResponse, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.getResultsSizeResponse != null && Object.hasOwnProperty.call(message, "getResultsSizeResponse"))
                $root.gcg.GetResultsSizeResponse.encode(message.getResultsSizeResponse, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.endorsementResponse != null && Object.hasOwnProperty.call(message, "endorsementResponse"))
                $root.identity_endorsement.EndorsementResponse.encode(message.endorsementResponse, writer.uint32(/* id 101, wireType 2 =*/810).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GcgResponse message, length delimited. Does not implicitly {@link gcg.GcgResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.GcgResponse
         * @static
         * @param {gcg.IGcgResponse} message GcgResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GcgResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GcgResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.GcgResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.GcgResponse} GcgResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GcgResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.GcgResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.failure = reader.string();
                        break;
                    }
                case 2: {
                        message.createDataRoomResponse = $root.gcg.CreateDataRoomResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.retrieveDataRoomResponse = $root.gcg.RetrieveDataRoomResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.retrieveCurrentDataRoomConfigurationResponse = $root.gcg.RetrieveCurrentDataRoomConfigurationResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.retrieveDataRoomStatusResponse = $root.gcg.RetrieveDataRoomStatusResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.updateDataRoomStatusResponse = $root.gcg.UpdateDataRoomStatusResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.retrieveAuditLogResponse = $root.gcg.RetrieveAuditLogResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.publishDatasetToDataRoomResponse = $root.gcg.PublishDatasetToDataRoomResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.retrievePublishedDatasetsResponse = $root.gcg.RetrievePublishedDatasetsResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.removePublishedDatasetResponse = $root.gcg.RemovePublishedDatasetResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.executeComputeResponse = $root.gcg.ExecuteComputeResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.jobStatusResponse = $root.gcg.JobStatusResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.getResultsResponseChunk = $root.gcg.GetResultsResponseChunk.decode(reader, reader.uint32());
                        break;
                    }
                case 14: {
                        message.getResultsResponseFooter = $root.gcg.GetResultsResponseFooter.decode(reader, reader.uint32());
                        break;
                    }
                case 15: {
                        message.createConfigurationCommitResponse = $root.gcg.CreateConfigurationCommitResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 16: {
                        message.retrieveConfigurationCommitResponse = $root.gcg.RetrieveConfigurationCommitResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 17: {
                        message.generateMergeApprovalSignatureResponse = $root.gcg.GenerateMergeApprovalSignatureResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 18: {
                        message.mergeConfigurationCommitResponse = $root.gcg.MergeConfigurationCommitResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 19: {
                        message.retrieveConfigurationCommitApproversResponse = $root.gcg.RetrieveConfigurationCommitApproversResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.casAuxiliaryStateResponse = $root.gcg.CasAuxiliaryStateResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        message.readAuxiliaryStateResponse = $root.gcg.ReadAuxiliaryStateResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 22: {
                        message.retrieveUsedAirlockQuotaResponse = $root.gcg.RetrieveUsedAirlockQuotaResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 23: {
                        message.getResultsSizeResponse = $root.gcg.GetResultsSizeResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 101: {
                        message.endorsementResponse = $root.identity_endorsement.EndorsementResponse.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GcgResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.GcgResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.GcgResponse} GcgResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GcgResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GcgResponse message.
         * @function verify
         * @memberof gcg.GcgResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GcgResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.failure != null && message.hasOwnProperty("failure")) {
                properties.gcgResponse = 1;
                if (!$util.isString(message.failure))
                    return "failure: string expected";
            }
            if (message.createDataRoomResponse != null && message.hasOwnProperty("createDataRoomResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.CreateDataRoomResponse.verify(message.createDataRoomResponse);
                    if (error)
                        return "createDataRoomResponse." + error;
                }
            }
            if (message.retrieveDataRoomResponse != null && message.hasOwnProperty("retrieveDataRoomResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.RetrieveDataRoomResponse.verify(message.retrieveDataRoomResponse);
                    if (error)
                        return "retrieveDataRoomResponse." + error;
                }
            }
            if (message.retrieveCurrentDataRoomConfigurationResponse != null && message.hasOwnProperty("retrieveCurrentDataRoomConfigurationResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.RetrieveCurrentDataRoomConfigurationResponse.verify(message.retrieveCurrentDataRoomConfigurationResponse);
                    if (error)
                        return "retrieveCurrentDataRoomConfigurationResponse." + error;
                }
            }
            if (message.retrieveDataRoomStatusResponse != null && message.hasOwnProperty("retrieveDataRoomStatusResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.RetrieveDataRoomStatusResponse.verify(message.retrieveDataRoomStatusResponse);
                    if (error)
                        return "retrieveDataRoomStatusResponse." + error;
                }
            }
            if (message.updateDataRoomStatusResponse != null && message.hasOwnProperty("updateDataRoomStatusResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.UpdateDataRoomStatusResponse.verify(message.updateDataRoomStatusResponse);
                    if (error)
                        return "updateDataRoomStatusResponse." + error;
                }
            }
            if (message.retrieveAuditLogResponse != null && message.hasOwnProperty("retrieveAuditLogResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.RetrieveAuditLogResponse.verify(message.retrieveAuditLogResponse);
                    if (error)
                        return "retrieveAuditLogResponse." + error;
                }
            }
            if (message.publishDatasetToDataRoomResponse != null && message.hasOwnProperty("publishDatasetToDataRoomResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.PublishDatasetToDataRoomResponse.verify(message.publishDatasetToDataRoomResponse);
                    if (error)
                        return "publishDatasetToDataRoomResponse." + error;
                }
            }
            if (message.retrievePublishedDatasetsResponse != null && message.hasOwnProperty("retrievePublishedDatasetsResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.RetrievePublishedDatasetsResponse.verify(message.retrievePublishedDatasetsResponse);
                    if (error)
                        return "retrievePublishedDatasetsResponse." + error;
                }
            }
            if (message.removePublishedDatasetResponse != null && message.hasOwnProperty("removePublishedDatasetResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.RemovePublishedDatasetResponse.verify(message.removePublishedDatasetResponse);
                    if (error)
                        return "removePublishedDatasetResponse." + error;
                }
            }
            if (message.executeComputeResponse != null && message.hasOwnProperty("executeComputeResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.ExecuteComputeResponse.verify(message.executeComputeResponse);
                    if (error)
                        return "executeComputeResponse." + error;
                }
            }
            if (message.jobStatusResponse != null && message.hasOwnProperty("jobStatusResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.JobStatusResponse.verify(message.jobStatusResponse);
                    if (error)
                        return "jobStatusResponse." + error;
                }
            }
            if (message.getResultsResponseChunk != null && message.hasOwnProperty("getResultsResponseChunk")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.GetResultsResponseChunk.verify(message.getResultsResponseChunk);
                    if (error)
                        return "getResultsResponseChunk." + error;
                }
            }
            if (message.getResultsResponseFooter != null && message.hasOwnProperty("getResultsResponseFooter")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.GetResultsResponseFooter.verify(message.getResultsResponseFooter);
                    if (error)
                        return "getResultsResponseFooter." + error;
                }
            }
            if (message.createConfigurationCommitResponse != null && message.hasOwnProperty("createConfigurationCommitResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.CreateConfigurationCommitResponse.verify(message.createConfigurationCommitResponse);
                    if (error)
                        return "createConfigurationCommitResponse." + error;
                }
            }
            if (message.retrieveConfigurationCommitResponse != null && message.hasOwnProperty("retrieveConfigurationCommitResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.RetrieveConfigurationCommitResponse.verify(message.retrieveConfigurationCommitResponse);
                    if (error)
                        return "retrieveConfigurationCommitResponse." + error;
                }
            }
            if (message.generateMergeApprovalSignatureResponse != null && message.hasOwnProperty("generateMergeApprovalSignatureResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.GenerateMergeApprovalSignatureResponse.verify(message.generateMergeApprovalSignatureResponse);
                    if (error)
                        return "generateMergeApprovalSignatureResponse." + error;
                }
            }
            if (message.mergeConfigurationCommitResponse != null && message.hasOwnProperty("mergeConfigurationCommitResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.MergeConfigurationCommitResponse.verify(message.mergeConfigurationCommitResponse);
                    if (error)
                        return "mergeConfigurationCommitResponse." + error;
                }
            }
            if (message.retrieveConfigurationCommitApproversResponse != null && message.hasOwnProperty("retrieveConfigurationCommitApproversResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.RetrieveConfigurationCommitApproversResponse.verify(message.retrieveConfigurationCommitApproversResponse);
                    if (error)
                        return "retrieveConfigurationCommitApproversResponse." + error;
                }
            }
            if (message.casAuxiliaryStateResponse != null && message.hasOwnProperty("casAuxiliaryStateResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.CasAuxiliaryStateResponse.verify(message.casAuxiliaryStateResponse);
                    if (error)
                        return "casAuxiliaryStateResponse." + error;
                }
            }
            if (message.readAuxiliaryStateResponse != null && message.hasOwnProperty("readAuxiliaryStateResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.ReadAuxiliaryStateResponse.verify(message.readAuxiliaryStateResponse);
                    if (error)
                        return "readAuxiliaryStateResponse." + error;
                }
            }
            if (message.retrieveUsedAirlockQuotaResponse != null && message.hasOwnProperty("retrieveUsedAirlockQuotaResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.RetrieveUsedAirlockQuotaResponse.verify(message.retrieveUsedAirlockQuotaResponse);
                    if (error)
                        return "retrieveUsedAirlockQuotaResponse." + error;
                }
            }
            if (message.getResultsSizeResponse != null && message.hasOwnProperty("getResultsSizeResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.gcg.GetResultsSizeResponse.verify(message.getResultsSizeResponse);
                    if (error)
                        return "getResultsSizeResponse." + error;
                }
            }
            if (message.endorsementResponse != null && message.hasOwnProperty("endorsementResponse")) {
                if (properties.gcgResponse === 1)
                    return "gcgResponse: multiple values";
                properties.gcgResponse = 1;
                {
                    let error = $root.identity_endorsement.EndorsementResponse.verify(message.endorsementResponse);
                    if (error)
                        return "endorsementResponse." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GcgResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.GcgResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.GcgResponse} GcgResponse
         */
        GcgResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.GcgResponse)
                return object;
            let message = new $root.gcg.GcgResponse();
            if (object.failure != null)
                message.failure = String(object.failure);
            if (object.createDataRoomResponse != null) {
                if (typeof object.createDataRoomResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.createDataRoomResponse: object expected");
                message.createDataRoomResponse = $root.gcg.CreateDataRoomResponse.fromObject(object.createDataRoomResponse);
            }
            if (object.retrieveDataRoomResponse != null) {
                if (typeof object.retrieveDataRoomResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.retrieveDataRoomResponse: object expected");
                message.retrieveDataRoomResponse = $root.gcg.RetrieveDataRoomResponse.fromObject(object.retrieveDataRoomResponse);
            }
            if (object.retrieveCurrentDataRoomConfigurationResponse != null) {
                if (typeof object.retrieveCurrentDataRoomConfigurationResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.retrieveCurrentDataRoomConfigurationResponse: object expected");
                message.retrieveCurrentDataRoomConfigurationResponse = $root.gcg.RetrieveCurrentDataRoomConfigurationResponse.fromObject(object.retrieveCurrentDataRoomConfigurationResponse);
            }
            if (object.retrieveDataRoomStatusResponse != null) {
                if (typeof object.retrieveDataRoomStatusResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.retrieveDataRoomStatusResponse: object expected");
                message.retrieveDataRoomStatusResponse = $root.gcg.RetrieveDataRoomStatusResponse.fromObject(object.retrieveDataRoomStatusResponse);
            }
            if (object.updateDataRoomStatusResponse != null) {
                if (typeof object.updateDataRoomStatusResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.updateDataRoomStatusResponse: object expected");
                message.updateDataRoomStatusResponse = $root.gcg.UpdateDataRoomStatusResponse.fromObject(object.updateDataRoomStatusResponse);
            }
            if (object.retrieveAuditLogResponse != null) {
                if (typeof object.retrieveAuditLogResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.retrieveAuditLogResponse: object expected");
                message.retrieveAuditLogResponse = $root.gcg.RetrieveAuditLogResponse.fromObject(object.retrieveAuditLogResponse);
            }
            if (object.publishDatasetToDataRoomResponse != null) {
                if (typeof object.publishDatasetToDataRoomResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.publishDatasetToDataRoomResponse: object expected");
                message.publishDatasetToDataRoomResponse = $root.gcg.PublishDatasetToDataRoomResponse.fromObject(object.publishDatasetToDataRoomResponse);
            }
            if (object.retrievePublishedDatasetsResponse != null) {
                if (typeof object.retrievePublishedDatasetsResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.retrievePublishedDatasetsResponse: object expected");
                message.retrievePublishedDatasetsResponse = $root.gcg.RetrievePublishedDatasetsResponse.fromObject(object.retrievePublishedDatasetsResponse);
            }
            if (object.removePublishedDatasetResponse != null) {
                if (typeof object.removePublishedDatasetResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.removePublishedDatasetResponse: object expected");
                message.removePublishedDatasetResponse = $root.gcg.RemovePublishedDatasetResponse.fromObject(object.removePublishedDatasetResponse);
            }
            if (object.executeComputeResponse != null) {
                if (typeof object.executeComputeResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.executeComputeResponse: object expected");
                message.executeComputeResponse = $root.gcg.ExecuteComputeResponse.fromObject(object.executeComputeResponse);
            }
            if (object.jobStatusResponse != null) {
                if (typeof object.jobStatusResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.jobStatusResponse: object expected");
                message.jobStatusResponse = $root.gcg.JobStatusResponse.fromObject(object.jobStatusResponse);
            }
            if (object.getResultsResponseChunk != null) {
                if (typeof object.getResultsResponseChunk !== "object")
                    throw TypeError(".gcg.GcgResponse.getResultsResponseChunk: object expected");
                message.getResultsResponseChunk = $root.gcg.GetResultsResponseChunk.fromObject(object.getResultsResponseChunk);
            }
            if (object.getResultsResponseFooter != null) {
                if (typeof object.getResultsResponseFooter !== "object")
                    throw TypeError(".gcg.GcgResponse.getResultsResponseFooter: object expected");
                message.getResultsResponseFooter = $root.gcg.GetResultsResponseFooter.fromObject(object.getResultsResponseFooter);
            }
            if (object.createConfigurationCommitResponse != null) {
                if (typeof object.createConfigurationCommitResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.createConfigurationCommitResponse: object expected");
                message.createConfigurationCommitResponse = $root.gcg.CreateConfigurationCommitResponse.fromObject(object.createConfigurationCommitResponse);
            }
            if (object.retrieveConfigurationCommitResponse != null) {
                if (typeof object.retrieveConfigurationCommitResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.retrieveConfigurationCommitResponse: object expected");
                message.retrieveConfigurationCommitResponse = $root.gcg.RetrieveConfigurationCommitResponse.fromObject(object.retrieveConfigurationCommitResponse);
            }
            if (object.generateMergeApprovalSignatureResponse != null) {
                if (typeof object.generateMergeApprovalSignatureResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.generateMergeApprovalSignatureResponse: object expected");
                message.generateMergeApprovalSignatureResponse = $root.gcg.GenerateMergeApprovalSignatureResponse.fromObject(object.generateMergeApprovalSignatureResponse);
            }
            if (object.mergeConfigurationCommitResponse != null) {
                if (typeof object.mergeConfigurationCommitResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.mergeConfigurationCommitResponse: object expected");
                message.mergeConfigurationCommitResponse = $root.gcg.MergeConfigurationCommitResponse.fromObject(object.mergeConfigurationCommitResponse);
            }
            if (object.retrieveConfigurationCommitApproversResponse != null) {
                if (typeof object.retrieveConfigurationCommitApproversResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.retrieveConfigurationCommitApproversResponse: object expected");
                message.retrieveConfigurationCommitApproversResponse = $root.gcg.RetrieveConfigurationCommitApproversResponse.fromObject(object.retrieveConfigurationCommitApproversResponse);
            }
            if (object.casAuxiliaryStateResponse != null) {
                if (typeof object.casAuxiliaryStateResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.casAuxiliaryStateResponse: object expected");
                message.casAuxiliaryStateResponse = $root.gcg.CasAuxiliaryStateResponse.fromObject(object.casAuxiliaryStateResponse);
            }
            if (object.readAuxiliaryStateResponse != null) {
                if (typeof object.readAuxiliaryStateResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.readAuxiliaryStateResponse: object expected");
                message.readAuxiliaryStateResponse = $root.gcg.ReadAuxiliaryStateResponse.fromObject(object.readAuxiliaryStateResponse);
            }
            if (object.retrieveUsedAirlockQuotaResponse != null) {
                if (typeof object.retrieveUsedAirlockQuotaResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.retrieveUsedAirlockQuotaResponse: object expected");
                message.retrieveUsedAirlockQuotaResponse = $root.gcg.RetrieveUsedAirlockQuotaResponse.fromObject(object.retrieveUsedAirlockQuotaResponse);
            }
            if (object.getResultsSizeResponse != null) {
                if (typeof object.getResultsSizeResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.getResultsSizeResponse: object expected");
                message.getResultsSizeResponse = $root.gcg.GetResultsSizeResponse.fromObject(object.getResultsSizeResponse);
            }
            if (object.endorsementResponse != null) {
                if (typeof object.endorsementResponse !== "object")
                    throw TypeError(".gcg.GcgResponse.endorsementResponse: object expected");
                message.endorsementResponse = $root.identity_endorsement.EndorsementResponse.fromObject(object.endorsementResponse);
            }
            return message;
        };

        /**
         * Creates a plain object from a GcgResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.GcgResponse
         * @static
         * @param {gcg.GcgResponse} message GcgResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GcgResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.failure != null && message.hasOwnProperty("failure")) {
                object.failure = message.failure;
                if (options.oneofs)
                    object.gcgResponse = "failure";
            }
            if (message.createDataRoomResponse != null && message.hasOwnProperty("createDataRoomResponse")) {
                object.createDataRoomResponse = $root.gcg.CreateDataRoomResponse.toObject(message.createDataRoomResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "createDataRoomResponse";
            }
            if (message.retrieveDataRoomResponse != null && message.hasOwnProperty("retrieveDataRoomResponse")) {
                object.retrieveDataRoomResponse = $root.gcg.RetrieveDataRoomResponse.toObject(message.retrieveDataRoomResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "retrieveDataRoomResponse";
            }
            if (message.retrieveCurrentDataRoomConfigurationResponse != null && message.hasOwnProperty("retrieveCurrentDataRoomConfigurationResponse")) {
                object.retrieveCurrentDataRoomConfigurationResponse = $root.gcg.RetrieveCurrentDataRoomConfigurationResponse.toObject(message.retrieveCurrentDataRoomConfigurationResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "retrieveCurrentDataRoomConfigurationResponse";
            }
            if (message.retrieveDataRoomStatusResponse != null && message.hasOwnProperty("retrieveDataRoomStatusResponse")) {
                object.retrieveDataRoomStatusResponse = $root.gcg.RetrieveDataRoomStatusResponse.toObject(message.retrieveDataRoomStatusResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "retrieveDataRoomStatusResponse";
            }
            if (message.updateDataRoomStatusResponse != null && message.hasOwnProperty("updateDataRoomStatusResponse")) {
                object.updateDataRoomStatusResponse = $root.gcg.UpdateDataRoomStatusResponse.toObject(message.updateDataRoomStatusResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "updateDataRoomStatusResponse";
            }
            if (message.retrieveAuditLogResponse != null && message.hasOwnProperty("retrieveAuditLogResponse")) {
                object.retrieveAuditLogResponse = $root.gcg.RetrieveAuditLogResponse.toObject(message.retrieveAuditLogResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "retrieveAuditLogResponse";
            }
            if (message.publishDatasetToDataRoomResponse != null && message.hasOwnProperty("publishDatasetToDataRoomResponse")) {
                object.publishDatasetToDataRoomResponse = $root.gcg.PublishDatasetToDataRoomResponse.toObject(message.publishDatasetToDataRoomResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "publishDatasetToDataRoomResponse";
            }
            if (message.retrievePublishedDatasetsResponse != null && message.hasOwnProperty("retrievePublishedDatasetsResponse")) {
                object.retrievePublishedDatasetsResponse = $root.gcg.RetrievePublishedDatasetsResponse.toObject(message.retrievePublishedDatasetsResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "retrievePublishedDatasetsResponse";
            }
            if (message.removePublishedDatasetResponse != null && message.hasOwnProperty("removePublishedDatasetResponse")) {
                object.removePublishedDatasetResponse = $root.gcg.RemovePublishedDatasetResponse.toObject(message.removePublishedDatasetResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "removePublishedDatasetResponse";
            }
            if (message.executeComputeResponse != null && message.hasOwnProperty("executeComputeResponse")) {
                object.executeComputeResponse = $root.gcg.ExecuteComputeResponse.toObject(message.executeComputeResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "executeComputeResponse";
            }
            if (message.jobStatusResponse != null && message.hasOwnProperty("jobStatusResponse")) {
                object.jobStatusResponse = $root.gcg.JobStatusResponse.toObject(message.jobStatusResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "jobStatusResponse";
            }
            if (message.getResultsResponseChunk != null && message.hasOwnProperty("getResultsResponseChunk")) {
                object.getResultsResponseChunk = $root.gcg.GetResultsResponseChunk.toObject(message.getResultsResponseChunk, options);
                if (options.oneofs)
                    object.gcgResponse = "getResultsResponseChunk";
            }
            if (message.getResultsResponseFooter != null && message.hasOwnProperty("getResultsResponseFooter")) {
                object.getResultsResponseFooter = $root.gcg.GetResultsResponseFooter.toObject(message.getResultsResponseFooter, options);
                if (options.oneofs)
                    object.gcgResponse = "getResultsResponseFooter";
            }
            if (message.createConfigurationCommitResponse != null && message.hasOwnProperty("createConfigurationCommitResponse")) {
                object.createConfigurationCommitResponse = $root.gcg.CreateConfigurationCommitResponse.toObject(message.createConfigurationCommitResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "createConfigurationCommitResponse";
            }
            if (message.retrieveConfigurationCommitResponse != null && message.hasOwnProperty("retrieveConfigurationCommitResponse")) {
                object.retrieveConfigurationCommitResponse = $root.gcg.RetrieveConfigurationCommitResponse.toObject(message.retrieveConfigurationCommitResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "retrieveConfigurationCommitResponse";
            }
            if (message.generateMergeApprovalSignatureResponse != null && message.hasOwnProperty("generateMergeApprovalSignatureResponse")) {
                object.generateMergeApprovalSignatureResponse = $root.gcg.GenerateMergeApprovalSignatureResponse.toObject(message.generateMergeApprovalSignatureResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "generateMergeApprovalSignatureResponse";
            }
            if (message.mergeConfigurationCommitResponse != null && message.hasOwnProperty("mergeConfigurationCommitResponse")) {
                object.mergeConfigurationCommitResponse = $root.gcg.MergeConfigurationCommitResponse.toObject(message.mergeConfigurationCommitResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "mergeConfigurationCommitResponse";
            }
            if (message.retrieveConfigurationCommitApproversResponse != null && message.hasOwnProperty("retrieveConfigurationCommitApproversResponse")) {
                object.retrieveConfigurationCommitApproversResponse = $root.gcg.RetrieveConfigurationCommitApproversResponse.toObject(message.retrieveConfigurationCommitApproversResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "retrieveConfigurationCommitApproversResponse";
            }
            if (message.casAuxiliaryStateResponse != null && message.hasOwnProperty("casAuxiliaryStateResponse")) {
                object.casAuxiliaryStateResponse = $root.gcg.CasAuxiliaryStateResponse.toObject(message.casAuxiliaryStateResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "casAuxiliaryStateResponse";
            }
            if (message.readAuxiliaryStateResponse != null && message.hasOwnProperty("readAuxiliaryStateResponse")) {
                object.readAuxiliaryStateResponse = $root.gcg.ReadAuxiliaryStateResponse.toObject(message.readAuxiliaryStateResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "readAuxiliaryStateResponse";
            }
            if (message.retrieveUsedAirlockQuotaResponse != null && message.hasOwnProperty("retrieveUsedAirlockQuotaResponse")) {
                object.retrieveUsedAirlockQuotaResponse = $root.gcg.RetrieveUsedAirlockQuotaResponse.toObject(message.retrieveUsedAirlockQuotaResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "retrieveUsedAirlockQuotaResponse";
            }
            if (message.getResultsSizeResponse != null && message.hasOwnProperty("getResultsSizeResponse")) {
                object.getResultsSizeResponse = $root.gcg.GetResultsSizeResponse.toObject(message.getResultsSizeResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "getResultsSizeResponse";
            }
            if (message.endorsementResponse != null && message.hasOwnProperty("endorsementResponse")) {
                object.endorsementResponse = $root.identity_endorsement.EndorsementResponse.toObject(message.endorsementResponse, options);
                if (options.oneofs)
                    object.gcgResponse = "endorsementResponse";
            }
            return object;
        };

        /**
         * Converts this GcgResponse to JSON.
         * @function toJSON
         * @memberof gcg.GcgResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GcgResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GcgResponse
         * @function getTypeUrl
         * @memberof gcg.GcgResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GcgResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.GcgResponse";
        };

        return GcgResponse;
    })();

    gcg.UserAuth = (function() {

        /**
         * Properties of a UserAuth.
         * @memberof gcg
         * @interface IUserAuth
         * @property {gcg.IPki|null} [pki] UserAuth pki
         * @property {identity_endorsement.IEnclaveEndorsements|null} [enclaveEndorsements] UserAuth enclaveEndorsements
         */

        /**
         * Constructs a new UserAuth.
         * @memberof gcg
         * @classdesc Represents a UserAuth.
         * @implements IUserAuth
         * @constructor
         * @param {gcg.IUserAuth=} [properties] Properties to set
         */
        function UserAuth(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserAuth pki.
         * @member {gcg.IPki|null|undefined} pki
         * @memberof gcg.UserAuth
         * @instance
         */
        UserAuth.prototype.pki = null;

        /**
         * UserAuth enclaveEndorsements.
         * @member {identity_endorsement.IEnclaveEndorsements|null|undefined} enclaveEndorsements
         * @memberof gcg.UserAuth
         * @instance
         */
        UserAuth.prototype.enclaveEndorsements = null;

        /**
         * Creates a new UserAuth instance using the specified properties.
         * @function create
         * @memberof gcg.UserAuth
         * @static
         * @param {gcg.IUserAuth=} [properties] Properties to set
         * @returns {gcg.UserAuth} UserAuth instance
         */
        UserAuth.create = function create(properties) {
            return new UserAuth(properties);
        };

        /**
         * Encodes the specified UserAuth message. Does not implicitly {@link gcg.UserAuth.verify|verify} messages.
         * @function encode
         * @memberof gcg.UserAuth
         * @static
         * @param {gcg.IUserAuth} message UserAuth message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserAuth.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pki != null && Object.hasOwnProperty.call(message, "pki"))
                $root.gcg.Pki.encode(message.pki, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.enclaveEndorsements != null && Object.hasOwnProperty.call(message, "enclaveEndorsements"))
                $root.identity_endorsement.EnclaveEndorsements.encode(message.enclaveEndorsements, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UserAuth message, length delimited. Does not implicitly {@link gcg.UserAuth.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.UserAuth
         * @static
         * @param {gcg.IUserAuth} message UserAuth message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserAuth.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserAuth message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.UserAuth
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.UserAuth} UserAuth
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserAuth.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.UserAuth();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pki = $root.gcg.Pki.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.enclaveEndorsements = $root.identity_endorsement.EnclaveEndorsements.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserAuth message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.UserAuth
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.UserAuth} UserAuth
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserAuth.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserAuth message.
         * @function verify
         * @memberof gcg.UserAuth
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserAuth.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pki != null && message.hasOwnProperty("pki")) {
                let error = $root.gcg.Pki.verify(message.pki);
                if (error)
                    return "pki." + error;
            }
            if (message.enclaveEndorsements != null && message.hasOwnProperty("enclaveEndorsements")) {
                let error = $root.identity_endorsement.EnclaveEndorsements.verify(message.enclaveEndorsements);
                if (error)
                    return "enclaveEndorsements." + error;
            }
            return null;
        };

        /**
         * Creates a UserAuth message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.UserAuth
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.UserAuth} UserAuth
         */
        UserAuth.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.UserAuth)
                return object;
            let message = new $root.gcg.UserAuth();
            if (object.pki != null) {
                if (typeof object.pki !== "object")
                    throw TypeError(".gcg.UserAuth.pki: object expected");
                message.pki = $root.gcg.Pki.fromObject(object.pki);
            }
            if (object.enclaveEndorsements != null) {
                if (typeof object.enclaveEndorsements !== "object")
                    throw TypeError(".gcg.UserAuth.enclaveEndorsements: object expected");
                message.enclaveEndorsements = $root.identity_endorsement.EnclaveEndorsements.fromObject(object.enclaveEndorsements);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserAuth message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.UserAuth
         * @static
         * @param {gcg.UserAuth} message UserAuth
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserAuth.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.pki = null;
                object.enclaveEndorsements = null;
            }
            if (message.pki != null && message.hasOwnProperty("pki"))
                object.pki = $root.gcg.Pki.toObject(message.pki, options);
            if (message.enclaveEndorsements != null && message.hasOwnProperty("enclaveEndorsements"))
                object.enclaveEndorsements = $root.identity_endorsement.EnclaveEndorsements.toObject(message.enclaveEndorsements, options);
            return object;
        };

        /**
         * Converts this UserAuth to JSON.
         * @function toJSON
         * @memberof gcg.UserAuth
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserAuth.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserAuth
         * @function getTypeUrl
         * @memberof gcg.UserAuth
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserAuth.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.UserAuth";
        };

        return UserAuth;
    })();

    gcg.Pki = (function() {

        /**
         * Properties of a Pki.
         * @memberof gcg
         * @interface IPki
         * @property {Uint8Array|null} [certChainPem] Pki certChainPem
         * @property {Uint8Array|null} [signature] Pki signature
         * @property {Uint8Array|null} [idMac] Pki idMac
         */

        /**
         * Constructs a new Pki.
         * @memberof gcg
         * @classdesc Represents a Pki.
         * @implements IPki
         * @constructor
         * @param {gcg.IPki=} [properties] Properties to set
         */
        function Pki(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Pki certChainPem.
         * @member {Uint8Array} certChainPem
         * @memberof gcg.Pki
         * @instance
         */
        Pki.prototype.certChainPem = $util.newBuffer([]);

        /**
         * Pki signature.
         * @member {Uint8Array} signature
         * @memberof gcg.Pki
         * @instance
         */
        Pki.prototype.signature = $util.newBuffer([]);

        /**
         * Pki idMac.
         * @member {Uint8Array} idMac
         * @memberof gcg.Pki
         * @instance
         */
        Pki.prototype.idMac = $util.newBuffer([]);

        /**
         * Creates a new Pki instance using the specified properties.
         * @function create
         * @memberof gcg.Pki
         * @static
         * @param {gcg.IPki=} [properties] Properties to set
         * @returns {gcg.Pki} Pki instance
         */
        Pki.create = function create(properties) {
            return new Pki(properties);
        };

        /**
         * Encodes the specified Pki message. Does not implicitly {@link gcg.Pki.verify|verify} messages.
         * @function encode
         * @memberof gcg.Pki
         * @static
         * @param {gcg.IPki} message Pki message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Pki.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.certChainPem != null && Object.hasOwnProperty.call(message, "certChainPem"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.certChainPem);
            if (message.signature != null && Object.hasOwnProperty.call(message, "signature"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.signature);
            if (message.idMac != null && Object.hasOwnProperty.call(message, "idMac"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.idMac);
            return writer;
        };

        /**
         * Encodes the specified Pki message, length delimited. Does not implicitly {@link gcg.Pki.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.Pki
         * @static
         * @param {gcg.IPki} message Pki message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Pki.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Pki message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.Pki
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.Pki} Pki
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Pki.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.Pki();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.certChainPem = reader.bytes();
                        break;
                    }
                case 2: {
                        message.signature = reader.bytes();
                        break;
                    }
                case 3: {
                        message.idMac = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Pki message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.Pki
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.Pki} Pki
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Pki.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Pki message.
         * @function verify
         * @memberof gcg.Pki
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Pki.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.certChainPem != null && message.hasOwnProperty("certChainPem"))
                if (!(message.certChainPem && typeof message.certChainPem.length === "number" || $util.isString(message.certChainPem)))
                    return "certChainPem: buffer expected";
            if (message.signature != null && message.hasOwnProperty("signature"))
                if (!(message.signature && typeof message.signature.length === "number" || $util.isString(message.signature)))
                    return "signature: buffer expected";
            if (message.idMac != null && message.hasOwnProperty("idMac"))
                if (!(message.idMac && typeof message.idMac.length === "number" || $util.isString(message.idMac)))
                    return "idMac: buffer expected";
            return null;
        };

        /**
         * Creates a Pki message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.Pki
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.Pki} Pki
         */
        Pki.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.Pki)
                return object;
            let message = new $root.gcg.Pki();
            if (object.certChainPem != null)
                if (typeof object.certChainPem === "string")
                    $util.base64.decode(object.certChainPem, message.certChainPem = $util.newBuffer($util.base64.length(object.certChainPem)), 0);
                else if (object.certChainPem.length >= 0)
                    message.certChainPem = object.certChainPem;
            if (object.signature != null)
                if (typeof object.signature === "string")
                    $util.base64.decode(object.signature, message.signature = $util.newBuffer($util.base64.length(object.signature)), 0);
                else if (object.signature.length >= 0)
                    message.signature = object.signature;
            if (object.idMac != null)
                if (typeof object.idMac === "string")
                    $util.base64.decode(object.idMac, message.idMac = $util.newBuffer($util.base64.length(object.idMac)), 0);
                else if (object.idMac.length >= 0)
                    message.idMac = object.idMac;
            return message;
        };

        /**
         * Creates a plain object from a Pki message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.Pki
         * @static
         * @param {gcg.Pki} message Pki
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Pki.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.certChainPem = "";
                else {
                    object.certChainPem = [];
                    if (options.bytes !== Array)
                        object.certChainPem = $util.newBuffer(object.certChainPem);
                }
                if (options.bytes === String)
                    object.signature = "";
                else {
                    object.signature = [];
                    if (options.bytes !== Array)
                        object.signature = $util.newBuffer(object.signature);
                }
                if (options.bytes === String)
                    object.idMac = "";
                else {
                    object.idMac = [];
                    if (options.bytes !== Array)
                        object.idMac = $util.newBuffer(object.idMac);
                }
            }
            if (message.certChainPem != null && message.hasOwnProperty("certChainPem"))
                object.certChainPem = options.bytes === String ? $util.base64.encode(message.certChainPem, 0, message.certChainPem.length) : options.bytes === Array ? Array.prototype.slice.call(message.certChainPem) : message.certChainPem;
            if (message.signature != null && message.hasOwnProperty("signature"))
                object.signature = options.bytes === String ? $util.base64.encode(message.signature, 0, message.signature.length) : options.bytes === Array ? Array.prototype.slice.call(message.signature) : message.signature;
            if (message.idMac != null && message.hasOwnProperty("idMac"))
                object.idMac = options.bytes === String ? $util.base64.encode(message.idMac, 0, message.idMac.length) : options.bytes === Array ? Array.prototype.slice.call(message.idMac) : message.idMac;
            return object;
        };

        /**
         * Converts this Pki to JSON.
         * @function toJSON
         * @memberof gcg.Pki
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Pki.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Pki
         * @function getTypeUrl
         * @memberof gcg.Pki
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Pki.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.Pki";
        };

        return Pki;
    })();

    gcg.CreateDataRoomRequest = (function() {

        /**
         * Properties of a CreateDataRoomRequest.
         * @memberof gcg
         * @interface ICreateDataRoomRequest
         * @property {data_room.IDataRoom|null} [dataRoom] CreateDataRoomRequest dataRoom
         * @property {Uint8Array|null} [highLevelRepresentation] CreateDataRoomRequest highLevelRepresentation
         * @property {Uint8Array|null} [dataRoomMetadata] CreateDataRoomRequest dataRoomMetadata
         */

        /**
         * Constructs a new CreateDataRoomRequest.
         * @memberof gcg
         * @classdesc Represents a CreateDataRoomRequest.
         * @implements ICreateDataRoomRequest
         * @constructor
         * @param {gcg.ICreateDataRoomRequest=} [properties] Properties to set
         */
        function CreateDataRoomRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateDataRoomRequest dataRoom.
         * @member {data_room.IDataRoom|null|undefined} dataRoom
         * @memberof gcg.CreateDataRoomRequest
         * @instance
         */
        CreateDataRoomRequest.prototype.dataRoom = null;

        /**
         * CreateDataRoomRequest highLevelRepresentation.
         * @member {Uint8Array|null|undefined} highLevelRepresentation
         * @memberof gcg.CreateDataRoomRequest
         * @instance
         */
        CreateDataRoomRequest.prototype.highLevelRepresentation = null;

        /**
         * CreateDataRoomRequest dataRoomMetadata.
         * @member {Uint8Array|null|undefined} dataRoomMetadata
         * @memberof gcg.CreateDataRoomRequest
         * @instance
         */
        CreateDataRoomRequest.prototype.dataRoomMetadata = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * CreateDataRoomRequest _highLevelRepresentation.
         * @member {"highLevelRepresentation"|undefined} _highLevelRepresentation
         * @memberof gcg.CreateDataRoomRequest
         * @instance
         */
        Object.defineProperty(CreateDataRoomRequest.prototype, "_highLevelRepresentation", {
            get: $util.oneOfGetter($oneOfFields = ["highLevelRepresentation"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreateDataRoomRequest _dataRoomMetadata.
         * @member {"dataRoomMetadata"|undefined} _dataRoomMetadata
         * @memberof gcg.CreateDataRoomRequest
         * @instance
         */
        Object.defineProperty(CreateDataRoomRequest.prototype, "_dataRoomMetadata", {
            get: $util.oneOfGetter($oneOfFields = ["dataRoomMetadata"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CreateDataRoomRequest instance using the specified properties.
         * @function create
         * @memberof gcg.CreateDataRoomRequest
         * @static
         * @param {gcg.ICreateDataRoomRequest=} [properties] Properties to set
         * @returns {gcg.CreateDataRoomRequest} CreateDataRoomRequest instance
         */
        CreateDataRoomRequest.create = function create(properties) {
            return new CreateDataRoomRequest(properties);
        };

        /**
         * Encodes the specified CreateDataRoomRequest message. Does not implicitly {@link gcg.CreateDataRoomRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.CreateDataRoomRequest
         * @static
         * @param {gcg.ICreateDataRoomRequest} message CreateDataRoomRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateDataRoomRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dataRoom != null && Object.hasOwnProperty.call(message, "dataRoom"))
                $root.data_room.DataRoom.encode(message.dataRoom, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.highLevelRepresentation != null && Object.hasOwnProperty.call(message, "highLevelRepresentation"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.highLevelRepresentation);
            if (message.dataRoomMetadata != null && Object.hasOwnProperty.call(message, "dataRoomMetadata"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.dataRoomMetadata);
            return writer;
        };

        /**
         * Encodes the specified CreateDataRoomRequest message, length delimited. Does not implicitly {@link gcg.CreateDataRoomRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.CreateDataRoomRequest
         * @static
         * @param {gcg.ICreateDataRoomRequest} message CreateDataRoomRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateDataRoomRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateDataRoomRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.CreateDataRoomRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.CreateDataRoomRequest} CreateDataRoomRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateDataRoomRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.CreateDataRoomRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dataRoom = $root.data_room.DataRoom.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.highLevelRepresentation = reader.bytes();
                        break;
                    }
                case 3: {
                        message.dataRoomMetadata = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateDataRoomRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.CreateDataRoomRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.CreateDataRoomRequest} CreateDataRoomRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateDataRoomRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateDataRoomRequest message.
         * @function verify
         * @memberof gcg.CreateDataRoomRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateDataRoomRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.dataRoom != null && message.hasOwnProperty("dataRoom")) {
                let error = $root.data_room.DataRoom.verify(message.dataRoom);
                if (error)
                    return "dataRoom." + error;
            }
            if (message.highLevelRepresentation != null && message.hasOwnProperty("highLevelRepresentation")) {
                properties._highLevelRepresentation = 1;
                if (!(message.highLevelRepresentation && typeof message.highLevelRepresentation.length === "number" || $util.isString(message.highLevelRepresentation)))
                    return "highLevelRepresentation: buffer expected";
            }
            if (message.dataRoomMetadata != null && message.hasOwnProperty("dataRoomMetadata")) {
                properties._dataRoomMetadata = 1;
                if (!(message.dataRoomMetadata && typeof message.dataRoomMetadata.length === "number" || $util.isString(message.dataRoomMetadata)))
                    return "dataRoomMetadata: buffer expected";
            }
            return null;
        };

        /**
         * Creates a CreateDataRoomRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.CreateDataRoomRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.CreateDataRoomRequest} CreateDataRoomRequest
         */
        CreateDataRoomRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.CreateDataRoomRequest)
                return object;
            let message = new $root.gcg.CreateDataRoomRequest();
            if (object.dataRoom != null) {
                if (typeof object.dataRoom !== "object")
                    throw TypeError(".gcg.CreateDataRoomRequest.dataRoom: object expected");
                message.dataRoom = $root.data_room.DataRoom.fromObject(object.dataRoom);
            }
            if (object.highLevelRepresentation != null)
                if (typeof object.highLevelRepresentation === "string")
                    $util.base64.decode(object.highLevelRepresentation, message.highLevelRepresentation = $util.newBuffer($util.base64.length(object.highLevelRepresentation)), 0);
                else if (object.highLevelRepresentation.length >= 0)
                    message.highLevelRepresentation = object.highLevelRepresentation;
            if (object.dataRoomMetadata != null)
                if (typeof object.dataRoomMetadata === "string")
                    $util.base64.decode(object.dataRoomMetadata, message.dataRoomMetadata = $util.newBuffer($util.base64.length(object.dataRoomMetadata)), 0);
                else if (object.dataRoomMetadata.length >= 0)
                    message.dataRoomMetadata = object.dataRoomMetadata;
            return message;
        };

        /**
         * Creates a plain object from a CreateDataRoomRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.CreateDataRoomRequest
         * @static
         * @param {gcg.CreateDataRoomRequest} message CreateDataRoomRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateDataRoomRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.dataRoom = null;
            if (message.dataRoom != null && message.hasOwnProperty("dataRoom"))
                object.dataRoom = $root.data_room.DataRoom.toObject(message.dataRoom, options);
            if (message.highLevelRepresentation != null && message.hasOwnProperty("highLevelRepresentation")) {
                object.highLevelRepresentation = options.bytes === String ? $util.base64.encode(message.highLevelRepresentation, 0, message.highLevelRepresentation.length) : options.bytes === Array ? Array.prototype.slice.call(message.highLevelRepresentation) : message.highLevelRepresentation;
                if (options.oneofs)
                    object._highLevelRepresentation = "highLevelRepresentation";
            }
            if (message.dataRoomMetadata != null && message.hasOwnProperty("dataRoomMetadata")) {
                object.dataRoomMetadata = options.bytes === String ? $util.base64.encode(message.dataRoomMetadata, 0, message.dataRoomMetadata.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomMetadata) : message.dataRoomMetadata;
                if (options.oneofs)
                    object._dataRoomMetadata = "dataRoomMetadata";
            }
            return object;
        };

        /**
         * Converts this CreateDataRoomRequest to JSON.
         * @function toJSON
         * @memberof gcg.CreateDataRoomRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateDataRoomRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateDataRoomRequest
         * @function getTypeUrl
         * @memberof gcg.CreateDataRoomRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateDataRoomRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.CreateDataRoomRequest";
        };

        return CreateDataRoomRequest;
    })();

    gcg.CreateDataRoomResponse = (function() {

        /**
         * Properties of a CreateDataRoomResponse.
         * @memberof gcg
         * @interface ICreateDataRoomResponse
         * @property {Uint8Array|null} [dataRoomId] CreateDataRoomResponse dataRoomId
         * @property {gcg.IDataRoomValidationError|null} [dataRoomValidationError] CreateDataRoomResponse dataRoomValidationError
         */

        /**
         * Constructs a new CreateDataRoomResponse.
         * @memberof gcg
         * @classdesc Represents a CreateDataRoomResponse.
         * @implements ICreateDataRoomResponse
         * @constructor
         * @param {gcg.ICreateDataRoomResponse=} [properties] Properties to set
         */
        function CreateDataRoomResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateDataRoomResponse dataRoomId.
         * @member {Uint8Array|null|undefined} dataRoomId
         * @memberof gcg.CreateDataRoomResponse
         * @instance
         */
        CreateDataRoomResponse.prototype.dataRoomId = null;

        /**
         * CreateDataRoomResponse dataRoomValidationError.
         * @member {gcg.IDataRoomValidationError|null|undefined} dataRoomValidationError
         * @memberof gcg.CreateDataRoomResponse
         * @instance
         */
        CreateDataRoomResponse.prototype.dataRoomValidationError = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * CreateDataRoomResponse createDataRoomResponse.
         * @member {"dataRoomId"|"dataRoomValidationError"|undefined} createDataRoomResponse
         * @memberof gcg.CreateDataRoomResponse
         * @instance
         */
        Object.defineProperty(CreateDataRoomResponse.prototype, "createDataRoomResponse", {
            get: $util.oneOfGetter($oneOfFields = ["dataRoomId", "dataRoomValidationError"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CreateDataRoomResponse instance using the specified properties.
         * @function create
         * @memberof gcg.CreateDataRoomResponse
         * @static
         * @param {gcg.ICreateDataRoomResponse=} [properties] Properties to set
         * @returns {gcg.CreateDataRoomResponse} CreateDataRoomResponse instance
         */
        CreateDataRoomResponse.create = function create(properties) {
            return new CreateDataRoomResponse(properties);
        };

        /**
         * Encodes the specified CreateDataRoomResponse message. Does not implicitly {@link gcg.CreateDataRoomResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.CreateDataRoomResponse
         * @static
         * @param {gcg.ICreateDataRoomResponse} message CreateDataRoomResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateDataRoomResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dataRoomId != null && Object.hasOwnProperty.call(message, "dataRoomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dataRoomId);
            if (message.dataRoomValidationError != null && Object.hasOwnProperty.call(message, "dataRoomValidationError"))
                $root.gcg.DataRoomValidationError.encode(message.dataRoomValidationError, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateDataRoomResponse message, length delimited. Does not implicitly {@link gcg.CreateDataRoomResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.CreateDataRoomResponse
         * @static
         * @param {gcg.ICreateDataRoomResponse} message CreateDataRoomResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateDataRoomResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateDataRoomResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.CreateDataRoomResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.CreateDataRoomResponse} CreateDataRoomResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateDataRoomResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.CreateDataRoomResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dataRoomId = reader.bytes();
                        break;
                    }
                case 2: {
                        message.dataRoomValidationError = $root.gcg.DataRoomValidationError.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateDataRoomResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.CreateDataRoomResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.CreateDataRoomResponse} CreateDataRoomResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateDataRoomResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateDataRoomResponse message.
         * @function verify
         * @memberof gcg.CreateDataRoomResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateDataRoomResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId")) {
                properties.createDataRoomResponse = 1;
                if (!(message.dataRoomId && typeof message.dataRoomId.length === "number" || $util.isString(message.dataRoomId)))
                    return "dataRoomId: buffer expected";
            }
            if (message.dataRoomValidationError != null && message.hasOwnProperty("dataRoomValidationError")) {
                if (properties.createDataRoomResponse === 1)
                    return "createDataRoomResponse: multiple values";
                properties.createDataRoomResponse = 1;
                {
                    let error = $root.gcg.DataRoomValidationError.verify(message.dataRoomValidationError);
                    if (error)
                        return "dataRoomValidationError." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CreateDataRoomResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.CreateDataRoomResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.CreateDataRoomResponse} CreateDataRoomResponse
         */
        CreateDataRoomResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.CreateDataRoomResponse)
                return object;
            let message = new $root.gcg.CreateDataRoomResponse();
            if (object.dataRoomId != null)
                if (typeof object.dataRoomId === "string")
                    $util.base64.decode(object.dataRoomId, message.dataRoomId = $util.newBuffer($util.base64.length(object.dataRoomId)), 0);
                else if (object.dataRoomId.length >= 0)
                    message.dataRoomId = object.dataRoomId;
            if (object.dataRoomValidationError != null) {
                if (typeof object.dataRoomValidationError !== "object")
                    throw TypeError(".gcg.CreateDataRoomResponse.dataRoomValidationError: object expected");
                message.dataRoomValidationError = $root.gcg.DataRoomValidationError.fromObject(object.dataRoomValidationError);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateDataRoomResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.CreateDataRoomResponse
         * @static
         * @param {gcg.CreateDataRoomResponse} message CreateDataRoomResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateDataRoomResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId")) {
                object.dataRoomId = options.bytes === String ? $util.base64.encode(message.dataRoomId, 0, message.dataRoomId.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomId) : message.dataRoomId;
                if (options.oneofs)
                    object.createDataRoomResponse = "dataRoomId";
            }
            if (message.dataRoomValidationError != null && message.hasOwnProperty("dataRoomValidationError")) {
                object.dataRoomValidationError = $root.gcg.DataRoomValidationError.toObject(message.dataRoomValidationError, options);
                if (options.oneofs)
                    object.createDataRoomResponse = "dataRoomValidationError";
            }
            return object;
        };

        /**
         * Converts this CreateDataRoomResponse to JSON.
         * @function toJSON
         * @memberof gcg.CreateDataRoomResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateDataRoomResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateDataRoomResponse
         * @function getTypeUrl
         * @memberof gcg.CreateDataRoomResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateDataRoomResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.CreateDataRoomResponse";
        };

        return CreateDataRoomResponse;
    })();

    gcg.DataRoomValidationError = (function() {

        /**
         * Properties of a DataRoomValidationError.
         * @memberof gcg
         * @interface IDataRoomValidationError
         * @property {string|null} [message] DataRoomValidationError message
         * @property {number|Long|null} [permissionIndex] DataRoomValidationError permissionIndex
         * @property {string|null} [computeNodeId] DataRoomValidationError computeNodeId
         * @property {string|null} [userPermissionId] DataRoomValidationError userPermissionId
         * @property {string|null} [attestationSpecificationId] DataRoomValidationError attestationSpecificationId
         * @property {string|null} [authenticationMethodId] DataRoomValidationError authenticationMethodId
         */

        /**
         * Constructs a new DataRoomValidationError.
         * @memberof gcg
         * @classdesc Represents a DataRoomValidationError.
         * @implements IDataRoomValidationError
         * @constructor
         * @param {gcg.IDataRoomValidationError=} [properties] Properties to set
         */
        function DataRoomValidationError(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DataRoomValidationError message.
         * @member {string} message
         * @memberof gcg.DataRoomValidationError
         * @instance
         */
        DataRoomValidationError.prototype.message = "";

        /**
         * DataRoomValidationError permissionIndex.
         * @member {number|Long|null|undefined} permissionIndex
         * @memberof gcg.DataRoomValidationError
         * @instance
         */
        DataRoomValidationError.prototype.permissionIndex = null;

        /**
         * DataRoomValidationError computeNodeId.
         * @member {string|null|undefined} computeNodeId
         * @memberof gcg.DataRoomValidationError
         * @instance
         */
        DataRoomValidationError.prototype.computeNodeId = null;

        /**
         * DataRoomValidationError userPermissionId.
         * @member {string|null|undefined} userPermissionId
         * @memberof gcg.DataRoomValidationError
         * @instance
         */
        DataRoomValidationError.prototype.userPermissionId = null;

        /**
         * DataRoomValidationError attestationSpecificationId.
         * @member {string|null|undefined} attestationSpecificationId
         * @memberof gcg.DataRoomValidationError
         * @instance
         */
        DataRoomValidationError.prototype.attestationSpecificationId = null;

        /**
         * DataRoomValidationError authenticationMethodId.
         * @member {string|null|undefined} authenticationMethodId
         * @memberof gcg.DataRoomValidationError
         * @instance
         */
        DataRoomValidationError.prototype.authenticationMethodId = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * DataRoomValidationError _permissionIndex.
         * @member {"permissionIndex"|undefined} _permissionIndex
         * @memberof gcg.DataRoomValidationError
         * @instance
         */
        Object.defineProperty(DataRoomValidationError.prototype, "_permissionIndex", {
            get: $util.oneOfGetter($oneOfFields = ["permissionIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * DataRoomValidationError _computeNodeId.
         * @member {"computeNodeId"|undefined} _computeNodeId
         * @memberof gcg.DataRoomValidationError
         * @instance
         */
        Object.defineProperty(DataRoomValidationError.prototype, "_computeNodeId", {
            get: $util.oneOfGetter($oneOfFields = ["computeNodeId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * DataRoomValidationError _userPermissionId.
         * @member {"userPermissionId"|undefined} _userPermissionId
         * @memberof gcg.DataRoomValidationError
         * @instance
         */
        Object.defineProperty(DataRoomValidationError.prototype, "_userPermissionId", {
            get: $util.oneOfGetter($oneOfFields = ["userPermissionId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * DataRoomValidationError _attestationSpecificationId.
         * @member {"attestationSpecificationId"|undefined} _attestationSpecificationId
         * @memberof gcg.DataRoomValidationError
         * @instance
         */
        Object.defineProperty(DataRoomValidationError.prototype, "_attestationSpecificationId", {
            get: $util.oneOfGetter($oneOfFields = ["attestationSpecificationId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * DataRoomValidationError _authenticationMethodId.
         * @member {"authenticationMethodId"|undefined} _authenticationMethodId
         * @memberof gcg.DataRoomValidationError
         * @instance
         */
        Object.defineProperty(DataRoomValidationError.prototype, "_authenticationMethodId", {
            get: $util.oneOfGetter($oneOfFields = ["authenticationMethodId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new DataRoomValidationError instance using the specified properties.
         * @function create
         * @memberof gcg.DataRoomValidationError
         * @static
         * @param {gcg.IDataRoomValidationError=} [properties] Properties to set
         * @returns {gcg.DataRoomValidationError} DataRoomValidationError instance
         */
        DataRoomValidationError.create = function create(properties) {
            return new DataRoomValidationError(properties);
        };

        /**
         * Encodes the specified DataRoomValidationError message. Does not implicitly {@link gcg.DataRoomValidationError.verify|verify} messages.
         * @function encode
         * @memberof gcg.DataRoomValidationError
         * @static
         * @param {gcg.IDataRoomValidationError} message DataRoomValidationError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataRoomValidationError.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.message);
            if (message.permissionIndex != null && Object.hasOwnProperty.call(message, "permissionIndex"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.permissionIndex);
            if (message.computeNodeId != null && Object.hasOwnProperty.call(message, "computeNodeId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.computeNodeId);
            if (message.userPermissionId != null && Object.hasOwnProperty.call(message, "userPermissionId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.userPermissionId);
            if (message.attestationSpecificationId != null && Object.hasOwnProperty.call(message, "attestationSpecificationId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.attestationSpecificationId);
            if (message.authenticationMethodId != null && Object.hasOwnProperty.call(message, "authenticationMethodId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.authenticationMethodId);
            return writer;
        };

        /**
         * Encodes the specified DataRoomValidationError message, length delimited. Does not implicitly {@link gcg.DataRoomValidationError.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.DataRoomValidationError
         * @static
         * @param {gcg.IDataRoomValidationError} message DataRoomValidationError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataRoomValidationError.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DataRoomValidationError message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.DataRoomValidationError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.DataRoomValidationError} DataRoomValidationError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataRoomValidationError.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.DataRoomValidationError();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.message = reader.string();
                        break;
                    }
                case 2: {
                        message.permissionIndex = reader.uint64();
                        break;
                    }
                case 3: {
                        message.computeNodeId = reader.string();
                        break;
                    }
                case 4: {
                        message.userPermissionId = reader.string();
                        break;
                    }
                case 5: {
                        message.attestationSpecificationId = reader.string();
                        break;
                    }
                case 6: {
                        message.authenticationMethodId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DataRoomValidationError message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.DataRoomValidationError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.DataRoomValidationError} DataRoomValidationError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataRoomValidationError.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DataRoomValidationError message.
         * @function verify
         * @memberof gcg.DataRoomValidationError
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DataRoomValidationError.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            if (message.permissionIndex != null && message.hasOwnProperty("permissionIndex")) {
                properties._permissionIndex = 1;
                if (!$util.isInteger(message.permissionIndex) && !(message.permissionIndex && $util.isInteger(message.permissionIndex.low) && $util.isInteger(message.permissionIndex.high)))
                    return "permissionIndex: integer|Long expected";
            }
            if (message.computeNodeId != null && message.hasOwnProperty("computeNodeId")) {
                properties._computeNodeId = 1;
                if (!$util.isString(message.computeNodeId))
                    return "computeNodeId: string expected";
            }
            if (message.userPermissionId != null && message.hasOwnProperty("userPermissionId")) {
                properties._userPermissionId = 1;
                if (!$util.isString(message.userPermissionId))
                    return "userPermissionId: string expected";
            }
            if (message.attestationSpecificationId != null && message.hasOwnProperty("attestationSpecificationId")) {
                properties._attestationSpecificationId = 1;
                if (!$util.isString(message.attestationSpecificationId))
                    return "attestationSpecificationId: string expected";
            }
            if (message.authenticationMethodId != null && message.hasOwnProperty("authenticationMethodId")) {
                properties._authenticationMethodId = 1;
                if (!$util.isString(message.authenticationMethodId))
                    return "authenticationMethodId: string expected";
            }
            return null;
        };

        /**
         * Creates a DataRoomValidationError message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.DataRoomValidationError
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.DataRoomValidationError} DataRoomValidationError
         */
        DataRoomValidationError.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.DataRoomValidationError)
                return object;
            let message = new $root.gcg.DataRoomValidationError();
            if (object.message != null)
                message.message = String(object.message);
            if (object.permissionIndex != null)
                if ($util.Long)
                    (message.permissionIndex = $util.Long.fromValue(object.permissionIndex)).unsigned = true;
                else if (typeof object.permissionIndex === "string")
                    message.permissionIndex = parseInt(object.permissionIndex, 10);
                else if (typeof object.permissionIndex === "number")
                    message.permissionIndex = object.permissionIndex;
                else if (typeof object.permissionIndex === "object")
                    message.permissionIndex = new $util.LongBits(object.permissionIndex.low >>> 0, object.permissionIndex.high >>> 0).toNumber(true);
            if (object.computeNodeId != null)
                message.computeNodeId = String(object.computeNodeId);
            if (object.userPermissionId != null)
                message.userPermissionId = String(object.userPermissionId);
            if (object.attestationSpecificationId != null)
                message.attestationSpecificationId = String(object.attestationSpecificationId);
            if (object.authenticationMethodId != null)
                message.authenticationMethodId = String(object.authenticationMethodId);
            return message;
        };

        /**
         * Creates a plain object from a DataRoomValidationError message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.DataRoomValidationError
         * @static
         * @param {gcg.DataRoomValidationError} message DataRoomValidationError
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DataRoomValidationError.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.message = "";
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            if (message.permissionIndex != null && message.hasOwnProperty("permissionIndex")) {
                if (typeof message.permissionIndex === "number")
                    object.permissionIndex = options.longs === String ? String(message.permissionIndex) : message.permissionIndex;
                else
                    object.permissionIndex = options.longs === String ? $util.Long.prototype.toString.call(message.permissionIndex) : options.longs === Number ? new $util.LongBits(message.permissionIndex.low >>> 0, message.permissionIndex.high >>> 0).toNumber(true) : message.permissionIndex;
                if (options.oneofs)
                    object._permissionIndex = "permissionIndex";
            }
            if (message.computeNodeId != null && message.hasOwnProperty("computeNodeId")) {
                object.computeNodeId = message.computeNodeId;
                if (options.oneofs)
                    object._computeNodeId = "computeNodeId";
            }
            if (message.userPermissionId != null && message.hasOwnProperty("userPermissionId")) {
                object.userPermissionId = message.userPermissionId;
                if (options.oneofs)
                    object._userPermissionId = "userPermissionId";
            }
            if (message.attestationSpecificationId != null && message.hasOwnProperty("attestationSpecificationId")) {
                object.attestationSpecificationId = message.attestationSpecificationId;
                if (options.oneofs)
                    object._attestationSpecificationId = "attestationSpecificationId";
            }
            if (message.authenticationMethodId != null && message.hasOwnProperty("authenticationMethodId")) {
                object.authenticationMethodId = message.authenticationMethodId;
                if (options.oneofs)
                    object._authenticationMethodId = "authenticationMethodId";
            }
            return object;
        };

        /**
         * Converts this DataRoomValidationError to JSON.
         * @function toJSON
         * @memberof gcg.DataRoomValidationError
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DataRoomValidationError.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DataRoomValidationError
         * @function getTypeUrl
         * @memberof gcg.DataRoomValidationError
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DataRoomValidationError.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.DataRoomValidationError";
        };

        return DataRoomValidationError;
    })();

    gcg.PublishDatasetToDataRoomRequest = (function() {

        /**
         * Properties of a PublishDatasetToDataRoomRequest.
         * @memberof gcg
         * @interface IPublishDatasetToDataRoomRequest
         * @property {Uint8Array|null} [datasetHash] PublishDatasetToDataRoomRequest datasetHash
         * @property {Uint8Array|null} [dataRoomId] PublishDatasetToDataRoomRequest dataRoomId
         * @property {string|null} [leafId] PublishDatasetToDataRoomRequest leafId
         * @property {Uint8Array|null} [encryptionKey] PublishDatasetToDataRoomRequest encryptionKey
         * @property {Uint8Array|null} [scope] PublishDatasetToDataRoomRequest scope
         */

        /**
         * Constructs a new PublishDatasetToDataRoomRequest.
         * @memberof gcg
         * @classdesc Represents a PublishDatasetToDataRoomRequest.
         * @implements IPublishDatasetToDataRoomRequest
         * @constructor
         * @param {gcg.IPublishDatasetToDataRoomRequest=} [properties] Properties to set
         */
        function PublishDatasetToDataRoomRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PublishDatasetToDataRoomRequest datasetHash.
         * @member {Uint8Array} datasetHash
         * @memberof gcg.PublishDatasetToDataRoomRequest
         * @instance
         */
        PublishDatasetToDataRoomRequest.prototype.datasetHash = $util.newBuffer([]);

        /**
         * PublishDatasetToDataRoomRequest dataRoomId.
         * @member {Uint8Array} dataRoomId
         * @memberof gcg.PublishDatasetToDataRoomRequest
         * @instance
         */
        PublishDatasetToDataRoomRequest.prototype.dataRoomId = $util.newBuffer([]);

        /**
         * PublishDatasetToDataRoomRequest leafId.
         * @member {string} leafId
         * @memberof gcg.PublishDatasetToDataRoomRequest
         * @instance
         */
        PublishDatasetToDataRoomRequest.prototype.leafId = "";

        /**
         * PublishDatasetToDataRoomRequest encryptionKey.
         * @member {Uint8Array} encryptionKey
         * @memberof gcg.PublishDatasetToDataRoomRequest
         * @instance
         */
        PublishDatasetToDataRoomRequest.prototype.encryptionKey = $util.newBuffer([]);

        /**
         * PublishDatasetToDataRoomRequest scope.
         * @member {Uint8Array} scope
         * @memberof gcg.PublishDatasetToDataRoomRequest
         * @instance
         */
        PublishDatasetToDataRoomRequest.prototype.scope = $util.newBuffer([]);

        /**
         * Creates a new PublishDatasetToDataRoomRequest instance using the specified properties.
         * @function create
         * @memberof gcg.PublishDatasetToDataRoomRequest
         * @static
         * @param {gcg.IPublishDatasetToDataRoomRequest=} [properties] Properties to set
         * @returns {gcg.PublishDatasetToDataRoomRequest} PublishDatasetToDataRoomRequest instance
         */
        PublishDatasetToDataRoomRequest.create = function create(properties) {
            return new PublishDatasetToDataRoomRequest(properties);
        };

        /**
         * Encodes the specified PublishDatasetToDataRoomRequest message. Does not implicitly {@link gcg.PublishDatasetToDataRoomRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.PublishDatasetToDataRoomRequest
         * @static
         * @param {gcg.IPublishDatasetToDataRoomRequest} message PublishDatasetToDataRoomRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublishDatasetToDataRoomRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.datasetHash != null && Object.hasOwnProperty.call(message, "datasetHash"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.datasetHash);
            if (message.dataRoomId != null && Object.hasOwnProperty.call(message, "dataRoomId"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.dataRoomId);
            if (message.leafId != null && Object.hasOwnProperty.call(message, "leafId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.leafId);
            if (message.encryptionKey != null && Object.hasOwnProperty.call(message, "encryptionKey"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.encryptionKey);
            if (message.scope != null && Object.hasOwnProperty.call(message, "scope"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.scope);
            return writer;
        };

        /**
         * Encodes the specified PublishDatasetToDataRoomRequest message, length delimited. Does not implicitly {@link gcg.PublishDatasetToDataRoomRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.PublishDatasetToDataRoomRequest
         * @static
         * @param {gcg.IPublishDatasetToDataRoomRequest} message PublishDatasetToDataRoomRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublishDatasetToDataRoomRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PublishDatasetToDataRoomRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.PublishDatasetToDataRoomRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.PublishDatasetToDataRoomRequest} PublishDatasetToDataRoomRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublishDatasetToDataRoomRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.PublishDatasetToDataRoomRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.datasetHash = reader.bytes();
                        break;
                    }
                case 2: {
                        message.dataRoomId = reader.bytes();
                        break;
                    }
                case 3: {
                        message.leafId = reader.string();
                        break;
                    }
                case 4: {
                        message.encryptionKey = reader.bytes();
                        break;
                    }
                case 5: {
                        message.scope = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PublishDatasetToDataRoomRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.PublishDatasetToDataRoomRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.PublishDatasetToDataRoomRequest} PublishDatasetToDataRoomRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublishDatasetToDataRoomRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PublishDatasetToDataRoomRequest message.
         * @function verify
         * @memberof gcg.PublishDatasetToDataRoomRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PublishDatasetToDataRoomRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.datasetHash != null && message.hasOwnProperty("datasetHash"))
                if (!(message.datasetHash && typeof message.datasetHash.length === "number" || $util.isString(message.datasetHash)))
                    return "datasetHash: buffer expected";
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                if (!(message.dataRoomId && typeof message.dataRoomId.length === "number" || $util.isString(message.dataRoomId)))
                    return "dataRoomId: buffer expected";
            if (message.leafId != null && message.hasOwnProperty("leafId"))
                if (!$util.isString(message.leafId))
                    return "leafId: string expected";
            if (message.encryptionKey != null && message.hasOwnProperty("encryptionKey"))
                if (!(message.encryptionKey && typeof message.encryptionKey.length === "number" || $util.isString(message.encryptionKey)))
                    return "encryptionKey: buffer expected";
            if (message.scope != null && message.hasOwnProperty("scope"))
                if (!(message.scope && typeof message.scope.length === "number" || $util.isString(message.scope)))
                    return "scope: buffer expected";
            return null;
        };

        /**
         * Creates a PublishDatasetToDataRoomRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.PublishDatasetToDataRoomRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.PublishDatasetToDataRoomRequest} PublishDatasetToDataRoomRequest
         */
        PublishDatasetToDataRoomRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.PublishDatasetToDataRoomRequest)
                return object;
            let message = new $root.gcg.PublishDatasetToDataRoomRequest();
            if (object.datasetHash != null)
                if (typeof object.datasetHash === "string")
                    $util.base64.decode(object.datasetHash, message.datasetHash = $util.newBuffer($util.base64.length(object.datasetHash)), 0);
                else if (object.datasetHash.length >= 0)
                    message.datasetHash = object.datasetHash;
            if (object.dataRoomId != null)
                if (typeof object.dataRoomId === "string")
                    $util.base64.decode(object.dataRoomId, message.dataRoomId = $util.newBuffer($util.base64.length(object.dataRoomId)), 0);
                else if (object.dataRoomId.length >= 0)
                    message.dataRoomId = object.dataRoomId;
            if (object.leafId != null)
                message.leafId = String(object.leafId);
            if (object.encryptionKey != null)
                if (typeof object.encryptionKey === "string")
                    $util.base64.decode(object.encryptionKey, message.encryptionKey = $util.newBuffer($util.base64.length(object.encryptionKey)), 0);
                else if (object.encryptionKey.length >= 0)
                    message.encryptionKey = object.encryptionKey;
            if (object.scope != null)
                if (typeof object.scope === "string")
                    $util.base64.decode(object.scope, message.scope = $util.newBuffer($util.base64.length(object.scope)), 0);
                else if (object.scope.length >= 0)
                    message.scope = object.scope;
            return message;
        };

        /**
         * Creates a plain object from a PublishDatasetToDataRoomRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.PublishDatasetToDataRoomRequest
         * @static
         * @param {gcg.PublishDatasetToDataRoomRequest} message PublishDatasetToDataRoomRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PublishDatasetToDataRoomRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.datasetHash = "";
                else {
                    object.datasetHash = [];
                    if (options.bytes !== Array)
                        object.datasetHash = $util.newBuffer(object.datasetHash);
                }
                if (options.bytes === String)
                    object.dataRoomId = "";
                else {
                    object.dataRoomId = [];
                    if (options.bytes !== Array)
                        object.dataRoomId = $util.newBuffer(object.dataRoomId);
                }
                object.leafId = "";
                if (options.bytes === String)
                    object.encryptionKey = "";
                else {
                    object.encryptionKey = [];
                    if (options.bytes !== Array)
                        object.encryptionKey = $util.newBuffer(object.encryptionKey);
                }
                if (options.bytes === String)
                    object.scope = "";
                else {
                    object.scope = [];
                    if (options.bytes !== Array)
                        object.scope = $util.newBuffer(object.scope);
                }
            }
            if (message.datasetHash != null && message.hasOwnProperty("datasetHash"))
                object.datasetHash = options.bytes === String ? $util.base64.encode(message.datasetHash, 0, message.datasetHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.datasetHash) : message.datasetHash;
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                object.dataRoomId = options.bytes === String ? $util.base64.encode(message.dataRoomId, 0, message.dataRoomId.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomId) : message.dataRoomId;
            if (message.leafId != null && message.hasOwnProperty("leafId"))
                object.leafId = message.leafId;
            if (message.encryptionKey != null && message.hasOwnProperty("encryptionKey"))
                object.encryptionKey = options.bytes === String ? $util.base64.encode(message.encryptionKey, 0, message.encryptionKey.length) : options.bytes === Array ? Array.prototype.slice.call(message.encryptionKey) : message.encryptionKey;
            if (message.scope != null && message.hasOwnProperty("scope"))
                object.scope = options.bytes === String ? $util.base64.encode(message.scope, 0, message.scope.length) : options.bytes === Array ? Array.prototype.slice.call(message.scope) : message.scope;
            return object;
        };

        /**
         * Converts this PublishDatasetToDataRoomRequest to JSON.
         * @function toJSON
         * @memberof gcg.PublishDatasetToDataRoomRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PublishDatasetToDataRoomRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PublishDatasetToDataRoomRequest
         * @function getTypeUrl
         * @memberof gcg.PublishDatasetToDataRoomRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PublishDatasetToDataRoomRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.PublishDatasetToDataRoomRequest";
        };

        return PublishDatasetToDataRoomRequest;
    })();

    gcg.PublishDatasetToDataRoomResponse = (function() {

        /**
         * Properties of a PublishDatasetToDataRoomResponse.
         * @memberof gcg
         * @interface IPublishDatasetToDataRoomResponse
         */

        /**
         * Constructs a new PublishDatasetToDataRoomResponse.
         * @memberof gcg
         * @classdesc Represents a PublishDatasetToDataRoomResponse.
         * @implements IPublishDatasetToDataRoomResponse
         * @constructor
         * @param {gcg.IPublishDatasetToDataRoomResponse=} [properties] Properties to set
         */
        function PublishDatasetToDataRoomResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new PublishDatasetToDataRoomResponse instance using the specified properties.
         * @function create
         * @memberof gcg.PublishDatasetToDataRoomResponse
         * @static
         * @param {gcg.IPublishDatasetToDataRoomResponse=} [properties] Properties to set
         * @returns {gcg.PublishDatasetToDataRoomResponse} PublishDatasetToDataRoomResponse instance
         */
        PublishDatasetToDataRoomResponse.create = function create(properties) {
            return new PublishDatasetToDataRoomResponse(properties);
        };

        /**
         * Encodes the specified PublishDatasetToDataRoomResponse message. Does not implicitly {@link gcg.PublishDatasetToDataRoomResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.PublishDatasetToDataRoomResponse
         * @static
         * @param {gcg.IPublishDatasetToDataRoomResponse} message PublishDatasetToDataRoomResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublishDatasetToDataRoomResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified PublishDatasetToDataRoomResponse message, length delimited. Does not implicitly {@link gcg.PublishDatasetToDataRoomResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.PublishDatasetToDataRoomResponse
         * @static
         * @param {gcg.IPublishDatasetToDataRoomResponse} message PublishDatasetToDataRoomResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublishDatasetToDataRoomResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PublishDatasetToDataRoomResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.PublishDatasetToDataRoomResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.PublishDatasetToDataRoomResponse} PublishDatasetToDataRoomResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublishDatasetToDataRoomResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.PublishDatasetToDataRoomResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PublishDatasetToDataRoomResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.PublishDatasetToDataRoomResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.PublishDatasetToDataRoomResponse} PublishDatasetToDataRoomResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublishDatasetToDataRoomResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PublishDatasetToDataRoomResponse message.
         * @function verify
         * @memberof gcg.PublishDatasetToDataRoomResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PublishDatasetToDataRoomResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a PublishDatasetToDataRoomResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.PublishDatasetToDataRoomResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.PublishDatasetToDataRoomResponse} PublishDatasetToDataRoomResponse
         */
        PublishDatasetToDataRoomResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.PublishDatasetToDataRoomResponse)
                return object;
            return new $root.gcg.PublishDatasetToDataRoomResponse();
        };

        /**
         * Creates a plain object from a PublishDatasetToDataRoomResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.PublishDatasetToDataRoomResponse
         * @static
         * @param {gcg.PublishDatasetToDataRoomResponse} message PublishDatasetToDataRoomResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PublishDatasetToDataRoomResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this PublishDatasetToDataRoomResponse to JSON.
         * @function toJSON
         * @memberof gcg.PublishDatasetToDataRoomResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PublishDatasetToDataRoomResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PublishDatasetToDataRoomResponse
         * @function getTypeUrl
         * @memberof gcg.PublishDatasetToDataRoomResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PublishDatasetToDataRoomResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.PublishDatasetToDataRoomResponse";
        };

        return PublishDatasetToDataRoomResponse;
    })();

    gcg.ExecuteComputeRequest = (function() {

        /**
         * Properties of an ExecuteComputeRequest.
         * @memberof gcg
         * @interface IExecuteComputeRequest
         * @property {Uint8Array|null} [dataRoomId] ExecuteComputeRequest dataRoomId
         * @property {Array.<string>|null} [computeNodeIds] ExecuteComputeRequest computeNodeIds
         * @property {boolean|null} [isDryRun] ExecuteComputeRequest isDryRun
         * @property {Uint8Array|null} [scope] ExecuteComputeRequest scope
         * @property {Object.<string,string>|null} [parameters] ExecuteComputeRequest parameters
         * @property {Object.<string,gcg.ITestDataset>|null} [testDatasets] ExecuteComputeRequest testDatasets
         */

        /**
         * Constructs a new ExecuteComputeRequest.
         * @memberof gcg
         * @classdesc Represents an ExecuteComputeRequest.
         * @implements IExecuteComputeRequest
         * @constructor
         * @param {gcg.IExecuteComputeRequest=} [properties] Properties to set
         */
        function ExecuteComputeRequest(properties) {
            this.computeNodeIds = [];
            this.parameters = {};
            this.testDatasets = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExecuteComputeRequest dataRoomId.
         * @member {Uint8Array} dataRoomId
         * @memberof gcg.ExecuteComputeRequest
         * @instance
         */
        ExecuteComputeRequest.prototype.dataRoomId = $util.newBuffer([]);

        /**
         * ExecuteComputeRequest computeNodeIds.
         * @member {Array.<string>} computeNodeIds
         * @memberof gcg.ExecuteComputeRequest
         * @instance
         */
        ExecuteComputeRequest.prototype.computeNodeIds = $util.emptyArray;

        /**
         * ExecuteComputeRequest isDryRun.
         * @member {boolean} isDryRun
         * @memberof gcg.ExecuteComputeRequest
         * @instance
         */
        ExecuteComputeRequest.prototype.isDryRun = false;

        /**
         * ExecuteComputeRequest scope.
         * @member {Uint8Array} scope
         * @memberof gcg.ExecuteComputeRequest
         * @instance
         */
        ExecuteComputeRequest.prototype.scope = $util.newBuffer([]);

        /**
         * ExecuteComputeRequest parameters.
         * @member {Object.<string,string>} parameters
         * @memberof gcg.ExecuteComputeRequest
         * @instance
         */
        ExecuteComputeRequest.prototype.parameters = $util.emptyObject;

        /**
         * ExecuteComputeRequest testDatasets.
         * @member {Object.<string,gcg.ITestDataset>} testDatasets
         * @memberof gcg.ExecuteComputeRequest
         * @instance
         */
        ExecuteComputeRequest.prototype.testDatasets = $util.emptyObject;

        /**
         * Creates a new ExecuteComputeRequest instance using the specified properties.
         * @function create
         * @memberof gcg.ExecuteComputeRequest
         * @static
         * @param {gcg.IExecuteComputeRequest=} [properties] Properties to set
         * @returns {gcg.ExecuteComputeRequest} ExecuteComputeRequest instance
         */
        ExecuteComputeRequest.create = function create(properties) {
            return new ExecuteComputeRequest(properties);
        };

        /**
         * Encodes the specified ExecuteComputeRequest message. Does not implicitly {@link gcg.ExecuteComputeRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.ExecuteComputeRequest
         * @static
         * @param {gcg.IExecuteComputeRequest} message ExecuteComputeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecuteComputeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dataRoomId != null && Object.hasOwnProperty.call(message, "dataRoomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dataRoomId);
            if (message.computeNodeIds != null && message.computeNodeIds.length)
                for (let i = 0; i < message.computeNodeIds.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.computeNodeIds[i]);
            if (message.isDryRun != null && Object.hasOwnProperty.call(message, "isDryRun"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isDryRun);
            if (message.scope != null && Object.hasOwnProperty.call(message, "scope"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.scope);
            if (message.parameters != null && Object.hasOwnProperty.call(message, "parameters"))
                for (let keys = Object.keys(message.parameters), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 5, wireType 2 =*/42).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.parameters[keys[i]]).ldelim();
            if (message.testDatasets != null && Object.hasOwnProperty.call(message, "testDatasets"))
                for (let keys = Object.keys(message.testDatasets), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 6, wireType 2 =*/50).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                    $root.gcg.TestDataset.encode(message.testDatasets[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            return writer;
        };

        /**
         * Encodes the specified ExecuteComputeRequest message, length delimited. Does not implicitly {@link gcg.ExecuteComputeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.ExecuteComputeRequest
         * @static
         * @param {gcg.IExecuteComputeRequest} message ExecuteComputeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecuteComputeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExecuteComputeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.ExecuteComputeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.ExecuteComputeRequest} ExecuteComputeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecuteComputeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.ExecuteComputeRequest(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dataRoomId = reader.bytes();
                        break;
                    }
                case 2: {
                        if (!(message.computeNodeIds && message.computeNodeIds.length))
                            message.computeNodeIds = [];
                        message.computeNodeIds.push(reader.string());
                        break;
                    }
                case 3: {
                        message.isDryRun = reader.bool();
                        break;
                    }
                case 4: {
                        message.scope = reader.bytes();
                        break;
                    }
                case 5: {
                        if (message.parameters === $util.emptyObject)
                            message.parameters = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = "";
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.string();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.parameters[key] = value;
                        break;
                    }
                case 6: {
                        if (message.testDatasets === $util.emptyObject)
                            message.testDatasets = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = $root.gcg.TestDataset.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.testDatasets[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExecuteComputeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.ExecuteComputeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.ExecuteComputeRequest} ExecuteComputeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecuteComputeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExecuteComputeRequest message.
         * @function verify
         * @memberof gcg.ExecuteComputeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExecuteComputeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                if (!(message.dataRoomId && typeof message.dataRoomId.length === "number" || $util.isString(message.dataRoomId)))
                    return "dataRoomId: buffer expected";
            if (message.computeNodeIds != null && message.hasOwnProperty("computeNodeIds")) {
                if (!Array.isArray(message.computeNodeIds))
                    return "computeNodeIds: array expected";
                for (let i = 0; i < message.computeNodeIds.length; ++i)
                    if (!$util.isString(message.computeNodeIds[i]))
                        return "computeNodeIds: string[] expected";
            }
            if (message.isDryRun != null && message.hasOwnProperty("isDryRun"))
                if (typeof message.isDryRun !== "boolean")
                    return "isDryRun: boolean expected";
            if (message.scope != null && message.hasOwnProperty("scope"))
                if (!(message.scope && typeof message.scope.length === "number" || $util.isString(message.scope)))
                    return "scope: buffer expected";
            if (message.parameters != null && message.hasOwnProperty("parameters")) {
                if (!$util.isObject(message.parameters))
                    return "parameters: object expected";
                let key = Object.keys(message.parameters);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.parameters[key[i]]))
                        return "parameters: string{k:string} expected";
            }
            if (message.testDatasets != null && message.hasOwnProperty("testDatasets")) {
                if (!$util.isObject(message.testDatasets))
                    return "testDatasets: object expected";
                let key = Object.keys(message.testDatasets);
                for (let i = 0; i < key.length; ++i) {
                    let error = $root.gcg.TestDataset.verify(message.testDatasets[key[i]]);
                    if (error)
                        return "testDatasets." + error;
                }
            }
            return null;
        };

        /**
         * Creates an ExecuteComputeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.ExecuteComputeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.ExecuteComputeRequest} ExecuteComputeRequest
         */
        ExecuteComputeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.ExecuteComputeRequest)
                return object;
            let message = new $root.gcg.ExecuteComputeRequest();
            if (object.dataRoomId != null)
                if (typeof object.dataRoomId === "string")
                    $util.base64.decode(object.dataRoomId, message.dataRoomId = $util.newBuffer($util.base64.length(object.dataRoomId)), 0);
                else if (object.dataRoomId.length >= 0)
                    message.dataRoomId = object.dataRoomId;
            if (object.computeNodeIds) {
                if (!Array.isArray(object.computeNodeIds))
                    throw TypeError(".gcg.ExecuteComputeRequest.computeNodeIds: array expected");
                message.computeNodeIds = [];
                for (let i = 0; i < object.computeNodeIds.length; ++i)
                    message.computeNodeIds[i] = String(object.computeNodeIds[i]);
            }
            if (object.isDryRun != null)
                message.isDryRun = Boolean(object.isDryRun);
            if (object.scope != null)
                if (typeof object.scope === "string")
                    $util.base64.decode(object.scope, message.scope = $util.newBuffer($util.base64.length(object.scope)), 0);
                else if (object.scope.length >= 0)
                    message.scope = object.scope;
            if (object.parameters) {
                if (typeof object.parameters !== "object")
                    throw TypeError(".gcg.ExecuteComputeRequest.parameters: object expected");
                message.parameters = {};
                for (let keys = Object.keys(object.parameters), i = 0; i < keys.length; ++i)
                    message.parameters[keys[i]] = String(object.parameters[keys[i]]);
            }
            if (object.testDatasets) {
                if (typeof object.testDatasets !== "object")
                    throw TypeError(".gcg.ExecuteComputeRequest.testDatasets: object expected");
                message.testDatasets = {};
                for (let keys = Object.keys(object.testDatasets), i = 0; i < keys.length; ++i) {
                    if (typeof object.testDatasets[keys[i]] !== "object")
                        throw TypeError(".gcg.ExecuteComputeRequest.testDatasets: object expected");
                    message.testDatasets[keys[i]] = $root.gcg.TestDataset.fromObject(object.testDatasets[keys[i]]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an ExecuteComputeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.ExecuteComputeRequest
         * @static
         * @param {gcg.ExecuteComputeRequest} message ExecuteComputeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExecuteComputeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.computeNodeIds = [];
            if (options.objects || options.defaults) {
                object.parameters = {};
                object.testDatasets = {};
            }
            if (options.defaults) {
                if (options.bytes === String)
                    object.dataRoomId = "";
                else {
                    object.dataRoomId = [];
                    if (options.bytes !== Array)
                        object.dataRoomId = $util.newBuffer(object.dataRoomId);
                }
                object.isDryRun = false;
                if (options.bytes === String)
                    object.scope = "";
                else {
                    object.scope = [];
                    if (options.bytes !== Array)
                        object.scope = $util.newBuffer(object.scope);
                }
            }
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                object.dataRoomId = options.bytes === String ? $util.base64.encode(message.dataRoomId, 0, message.dataRoomId.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomId) : message.dataRoomId;
            if (message.computeNodeIds && message.computeNodeIds.length) {
                object.computeNodeIds = [];
                for (let j = 0; j < message.computeNodeIds.length; ++j)
                    object.computeNodeIds[j] = message.computeNodeIds[j];
            }
            if (message.isDryRun != null && message.hasOwnProperty("isDryRun"))
                object.isDryRun = message.isDryRun;
            if (message.scope != null && message.hasOwnProperty("scope"))
                object.scope = options.bytes === String ? $util.base64.encode(message.scope, 0, message.scope.length) : options.bytes === Array ? Array.prototype.slice.call(message.scope) : message.scope;
            let keys2;
            if (message.parameters && (keys2 = Object.keys(message.parameters)).length) {
                object.parameters = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.parameters[keys2[j]] = message.parameters[keys2[j]];
            }
            if (message.testDatasets && (keys2 = Object.keys(message.testDatasets)).length) {
                object.testDatasets = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.testDatasets[keys2[j]] = $root.gcg.TestDataset.toObject(message.testDatasets[keys2[j]], options);
            }
            return object;
        };

        /**
         * Converts this ExecuteComputeRequest to JSON.
         * @function toJSON
         * @memberof gcg.ExecuteComputeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExecuteComputeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ExecuteComputeRequest
         * @function getTypeUrl
         * @memberof gcg.ExecuteComputeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExecuteComputeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.ExecuteComputeRequest";
        };

        return ExecuteComputeRequest;
    })();

    gcg.ExecuteDevelopmentComputeRequest = (function() {

        /**
         * Properties of an ExecuteDevelopmentComputeRequest.
         * @memberof gcg
         * @interface IExecuteDevelopmentComputeRequest
         * @property {Uint8Array|null} [configurationCommitId] ExecuteDevelopmentComputeRequest configurationCommitId
         * @property {Array.<string>|null} [computeNodeIds] ExecuteDevelopmentComputeRequest computeNodeIds
         * @property {boolean|null} [isDryRun] ExecuteDevelopmentComputeRequest isDryRun
         * @property {Uint8Array|null} [scope] ExecuteDevelopmentComputeRequest scope
         * @property {Object.<string,string>|null} [parameters] ExecuteDevelopmentComputeRequest parameters
         * @property {Object.<string,gcg.ITestDataset>|null} [testDatasets] ExecuteDevelopmentComputeRequest testDatasets
         */

        /**
         * Constructs a new ExecuteDevelopmentComputeRequest.
         * @memberof gcg
         * @classdesc Represents an ExecuteDevelopmentComputeRequest.
         * @implements IExecuteDevelopmentComputeRequest
         * @constructor
         * @param {gcg.IExecuteDevelopmentComputeRequest=} [properties] Properties to set
         */
        function ExecuteDevelopmentComputeRequest(properties) {
            this.computeNodeIds = [];
            this.parameters = {};
            this.testDatasets = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExecuteDevelopmentComputeRequest configurationCommitId.
         * @member {Uint8Array} configurationCommitId
         * @memberof gcg.ExecuteDevelopmentComputeRequest
         * @instance
         */
        ExecuteDevelopmentComputeRequest.prototype.configurationCommitId = $util.newBuffer([]);

        /**
         * ExecuteDevelopmentComputeRequest computeNodeIds.
         * @member {Array.<string>} computeNodeIds
         * @memberof gcg.ExecuteDevelopmentComputeRequest
         * @instance
         */
        ExecuteDevelopmentComputeRequest.prototype.computeNodeIds = $util.emptyArray;

        /**
         * ExecuteDevelopmentComputeRequest isDryRun.
         * @member {boolean} isDryRun
         * @memberof gcg.ExecuteDevelopmentComputeRequest
         * @instance
         */
        ExecuteDevelopmentComputeRequest.prototype.isDryRun = false;

        /**
         * ExecuteDevelopmentComputeRequest scope.
         * @member {Uint8Array} scope
         * @memberof gcg.ExecuteDevelopmentComputeRequest
         * @instance
         */
        ExecuteDevelopmentComputeRequest.prototype.scope = $util.newBuffer([]);

        /**
         * ExecuteDevelopmentComputeRequest parameters.
         * @member {Object.<string,string>} parameters
         * @memberof gcg.ExecuteDevelopmentComputeRequest
         * @instance
         */
        ExecuteDevelopmentComputeRequest.prototype.parameters = $util.emptyObject;

        /**
         * ExecuteDevelopmentComputeRequest testDatasets.
         * @member {Object.<string,gcg.ITestDataset>} testDatasets
         * @memberof gcg.ExecuteDevelopmentComputeRequest
         * @instance
         */
        ExecuteDevelopmentComputeRequest.prototype.testDatasets = $util.emptyObject;

        /**
         * Creates a new ExecuteDevelopmentComputeRequest instance using the specified properties.
         * @function create
         * @memberof gcg.ExecuteDevelopmentComputeRequest
         * @static
         * @param {gcg.IExecuteDevelopmentComputeRequest=} [properties] Properties to set
         * @returns {gcg.ExecuteDevelopmentComputeRequest} ExecuteDevelopmentComputeRequest instance
         */
        ExecuteDevelopmentComputeRequest.create = function create(properties) {
            return new ExecuteDevelopmentComputeRequest(properties);
        };

        /**
         * Encodes the specified ExecuteDevelopmentComputeRequest message. Does not implicitly {@link gcg.ExecuteDevelopmentComputeRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.ExecuteDevelopmentComputeRequest
         * @static
         * @param {gcg.IExecuteDevelopmentComputeRequest} message ExecuteDevelopmentComputeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecuteDevelopmentComputeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.configurationCommitId != null && Object.hasOwnProperty.call(message, "configurationCommitId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.configurationCommitId);
            if (message.computeNodeIds != null && message.computeNodeIds.length)
                for (let i = 0; i < message.computeNodeIds.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.computeNodeIds[i]);
            if (message.isDryRun != null && Object.hasOwnProperty.call(message, "isDryRun"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isDryRun);
            if (message.scope != null && Object.hasOwnProperty.call(message, "scope"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.scope);
            if (message.parameters != null && Object.hasOwnProperty.call(message, "parameters"))
                for (let keys = Object.keys(message.parameters), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 5, wireType 2 =*/42).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.parameters[keys[i]]).ldelim();
            if (message.testDatasets != null && Object.hasOwnProperty.call(message, "testDatasets"))
                for (let keys = Object.keys(message.testDatasets), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 6, wireType 2 =*/50).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                    $root.gcg.TestDataset.encode(message.testDatasets[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            return writer;
        };

        /**
         * Encodes the specified ExecuteDevelopmentComputeRequest message, length delimited. Does not implicitly {@link gcg.ExecuteDevelopmentComputeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.ExecuteDevelopmentComputeRequest
         * @static
         * @param {gcg.IExecuteDevelopmentComputeRequest} message ExecuteDevelopmentComputeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecuteDevelopmentComputeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExecuteDevelopmentComputeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.ExecuteDevelopmentComputeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.ExecuteDevelopmentComputeRequest} ExecuteDevelopmentComputeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecuteDevelopmentComputeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.ExecuteDevelopmentComputeRequest(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.configurationCommitId = reader.bytes();
                        break;
                    }
                case 2: {
                        if (!(message.computeNodeIds && message.computeNodeIds.length))
                            message.computeNodeIds = [];
                        message.computeNodeIds.push(reader.string());
                        break;
                    }
                case 3: {
                        message.isDryRun = reader.bool();
                        break;
                    }
                case 4: {
                        message.scope = reader.bytes();
                        break;
                    }
                case 5: {
                        if (message.parameters === $util.emptyObject)
                            message.parameters = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = "";
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.string();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.parameters[key] = value;
                        break;
                    }
                case 6: {
                        if (message.testDatasets === $util.emptyObject)
                            message.testDatasets = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = $root.gcg.TestDataset.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.testDatasets[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExecuteDevelopmentComputeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.ExecuteDevelopmentComputeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.ExecuteDevelopmentComputeRequest} ExecuteDevelopmentComputeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecuteDevelopmentComputeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExecuteDevelopmentComputeRequest message.
         * @function verify
         * @memberof gcg.ExecuteDevelopmentComputeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExecuteDevelopmentComputeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.configurationCommitId != null && message.hasOwnProperty("configurationCommitId"))
                if (!(message.configurationCommitId && typeof message.configurationCommitId.length === "number" || $util.isString(message.configurationCommitId)))
                    return "configurationCommitId: buffer expected";
            if (message.computeNodeIds != null && message.hasOwnProperty("computeNodeIds")) {
                if (!Array.isArray(message.computeNodeIds))
                    return "computeNodeIds: array expected";
                for (let i = 0; i < message.computeNodeIds.length; ++i)
                    if (!$util.isString(message.computeNodeIds[i]))
                        return "computeNodeIds: string[] expected";
            }
            if (message.isDryRun != null && message.hasOwnProperty("isDryRun"))
                if (typeof message.isDryRun !== "boolean")
                    return "isDryRun: boolean expected";
            if (message.scope != null && message.hasOwnProperty("scope"))
                if (!(message.scope && typeof message.scope.length === "number" || $util.isString(message.scope)))
                    return "scope: buffer expected";
            if (message.parameters != null && message.hasOwnProperty("parameters")) {
                if (!$util.isObject(message.parameters))
                    return "parameters: object expected";
                let key = Object.keys(message.parameters);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.parameters[key[i]]))
                        return "parameters: string{k:string} expected";
            }
            if (message.testDatasets != null && message.hasOwnProperty("testDatasets")) {
                if (!$util.isObject(message.testDatasets))
                    return "testDatasets: object expected";
                let key = Object.keys(message.testDatasets);
                for (let i = 0; i < key.length; ++i) {
                    let error = $root.gcg.TestDataset.verify(message.testDatasets[key[i]]);
                    if (error)
                        return "testDatasets." + error;
                }
            }
            return null;
        };

        /**
         * Creates an ExecuteDevelopmentComputeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.ExecuteDevelopmentComputeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.ExecuteDevelopmentComputeRequest} ExecuteDevelopmentComputeRequest
         */
        ExecuteDevelopmentComputeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.ExecuteDevelopmentComputeRequest)
                return object;
            let message = new $root.gcg.ExecuteDevelopmentComputeRequest();
            if (object.configurationCommitId != null)
                if (typeof object.configurationCommitId === "string")
                    $util.base64.decode(object.configurationCommitId, message.configurationCommitId = $util.newBuffer($util.base64.length(object.configurationCommitId)), 0);
                else if (object.configurationCommitId.length >= 0)
                    message.configurationCommitId = object.configurationCommitId;
            if (object.computeNodeIds) {
                if (!Array.isArray(object.computeNodeIds))
                    throw TypeError(".gcg.ExecuteDevelopmentComputeRequest.computeNodeIds: array expected");
                message.computeNodeIds = [];
                for (let i = 0; i < object.computeNodeIds.length; ++i)
                    message.computeNodeIds[i] = String(object.computeNodeIds[i]);
            }
            if (object.isDryRun != null)
                message.isDryRun = Boolean(object.isDryRun);
            if (object.scope != null)
                if (typeof object.scope === "string")
                    $util.base64.decode(object.scope, message.scope = $util.newBuffer($util.base64.length(object.scope)), 0);
                else if (object.scope.length >= 0)
                    message.scope = object.scope;
            if (object.parameters) {
                if (typeof object.parameters !== "object")
                    throw TypeError(".gcg.ExecuteDevelopmentComputeRequest.parameters: object expected");
                message.parameters = {};
                for (let keys = Object.keys(object.parameters), i = 0; i < keys.length; ++i)
                    message.parameters[keys[i]] = String(object.parameters[keys[i]]);
            }
            if (object.testDatasets) {
                if (typeof object.testDatasets !== "object")
                    throw TypeError(".gcg.ExecuteDevelopmentComputeRequest.testDatasets: object expected");
                message.testDatasets = {};
                for (let keys = Object.keys(object.testDatasets), i = 0; i < keys.length; ++i) {
                    if (typeof object.testDatasets[keys[i]] !== "object")
                        throw TypeError(".gcg.ExecuteDevelopmentComputeRequest.testDatasets: object expected");
                    message.testDatasets[keys[i]] = $root.gcg.TestDataset.fromObject(object.testDatasets[keys[i]]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an ExecuteDevelopmentComputeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.ExecuteDevelopmentComputeRequest
         * @static
         * @param {gcg.ExecuteDevelopmentComputeRequest} message ExecuteDevelopmentComputeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExecuteDevelopmentComputeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.computeNodeIds = [];
            if (options.objects || options.defaults) {
                object.parameters = {};
                object.testDatasets = {};
            }
            if (options.defaults) {
                if (options.bytes === String)
                    object.configurationCommitId = "";
                else {
                    object.configurationCommitId = [];
                    if (options.bytes !== Array)
                        object.configurationCommitId = $util.newBuffer(object.configurationCommitId);
                }
                object.isDryRun = false;
                if (options.bytes === String)
                    object.scope = "";
                else {
                    object.scope = [];
                    if (options.bytes !== Array)
                        object.scope = $util.newBuffer(object.scope);
                }
            }
            if (message.configurationCommitId != null && message.hasOwnProperty("configurationCommitId"))
                object.configurationCommitId = options.bytes === String ? $util.base64.encode(message.configurationCommitId, 0, message.configurationCommitId.length) : options.bytes === Array ? Array.prototype.slice.call(message.configurationCommitId) : message.configurationCommitId;
            if (message.computeNodeIds && message.computeNodeIds.length) {
                object.computeNodeIds = [];
                for (let j = 0; j < message.computeNodeIds.length; ++j)
                    object.computeNodeIds[j] = message.computeNodeIds[j];
            }
            if (message.isDryRun != null && message.hasOwnProperty("isDryRun"))
                object.isDryRun = message.isDryRun;
            if (message.scope != null && message.hasOwnProperty("scope"))
                object.scope = options.bytes === String ? $util.base64.encode(message.scope, 0, message.scope.length) : options.bytes === Array ? Array.prototype.slice.call(message.scope) : message.scope;
            let keys2;
            if (message.parameters && (keys2 = Object.keys(message.parameters)).length) {
                object.parameters = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.parameters[keys2[j]] = message.parameters[keys2[j]];
            }
            if (message.testDatasets && (keys2 = Object.keys(message.testDatasets)).length) {
                object.testDatasets = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.testDatasets[keys2[j]] = $root.gcg.TestDataset.toObject(message.testDatasets[keys2[j]], options);
            }
            return object;
        };

        /**
         * Converts this ExecuteDevelopmentComputeRequest to JSON.
         * @function toJSON
         * @memberof gcg.ExecuteDevelopmentComputeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExecuteDevelopmentComputeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ExecuteDevelopmentComputeRequest
         * @function getTypeUrl
         * @memberof gcg.ExecuteDevelopmentComputeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExecuteDevelopmentComputeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.ExecuteDevelopmentComputeRequest";
        };

        return ExecuteDevelopmentComputeRequest;
    })();

    gcg.TestDataset = (function() {

        /**
         * Properties of a TestDataset.
         * @memberof gcg
         * @interface ITestDataset
         * @property {Uint8Array|null} [encryptionKey] TestDataset encryptionKey
         * @property {Uint8Array|null} [manifestHash] TestDataset manifestHash
         */

        /**
         * Constructs a new TestDataset.
         * @memberof gcg
         * @classdesc Represents a TestDataset.
         * @implements ITestDataset
         * @constructor
         * @param {gcg.ITestDataset=} [properties] Properties to set
         */
        function TestDataset(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TestDataset encryptionKey.
         * @member {Uint8Array} encryptionKey
         * @memberof gcg.TestDataset
         * @instance
         */
        TestDataset.prototype.encryptionKey = $util.newBuffer([]);

        /**
         * TestDataset manifestHash.
         * @member {Uint8Array} manifestHash
         * @memberof gcg.TestDataset
         * @instance
         */
        TestDataset.prototype.manifestHash = $util.newBuffer([]);

        /**
         * Creates a new TestDataset instance using the specified properties.
         * @function create
         * @memberof gcg.TestDataset
         * @static
         * @param {gcg.ITestDataset=} [properties] Properties to set
         * @returns {gcg.TestDataset} TestDataset instance
         */
        TestDataset.create = function create(properties) {
            return new TestDataset(properties);
        };

        /**
         * Encodes the specified TestDataset message. Does not implicitly {@link gcg.TestDataset.verify|verify} messages.
         * @function encode
         * @memberof gcg.TestDataset
         * @static
         * @param {gcg.ITestDataset} message TestDataset message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TestDataset.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.encryptionKey != null && Object.hasOwnProperty.call(message, "encryptionKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.encryptionKey);
            if (message.manifestHash != null && Object.hasOwnProperty.call(message, "manifestHash"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.manifestHash);
            return writer;
        };

        /**
         * Encodes the specified TestDataset message, length delimited. Does not implicitly {@link gcg.TestDataset.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.TestDataset
         * @static
         * @param {gcg.ITestDataset} message TestDataset message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TestDataset.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TestDataset message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.TestDataset
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.TestDataset} TestDataset
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TestDataset.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.TestDataset();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.encryptionKey = reader.bytes();
                        break;
                    }
                case 2: {
                        message.manifestHash = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TestDataset message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.TestDataset
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.TestDataset} TestDataset
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TestDataset.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TestDataset message.
         * @function verify
         * @memberof gcg.TestDataset
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TestDataset.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.encryptionKey != null && message.hasOwnProperty("encryptionKey"))
                if (!(message.encryptionKey && typeof message.encryptionKey.length === "number" || $util.isString(message.encryptionKey)))
                    return "encryptionKey: buffer expected";
            if (message.manifestHash != null && message.hasOwnProperty("manifestHash"))
                if (!(message.manifestHash && typeof message.manifestHash.length === "number" || $util.isString(message.manifestHash)))
                    return "manifestHash: buffer expected";
            return null;
        };

        /**
         * Creates a TestDataset message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.TestDataset
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.TestDataset} TestDataset
         */
        TestDataset.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.TestDataset)
                return object;
            let message = new $root.gcg.TestDataset();
            if (object.encryptionKey != null)
                if (typeof object.encryptionKey === "string")
                    $util.base64.decode(object.encryptionKey, message.encryptionKey = $util.newBuffer($util.base64.length(object.encryptionKey)), 0);
                else if (object.encryptionKey.length >= 0)
                    message.encryptionKey = object.encryptionKey;
            if (object.manifestHash != null)
                if (typeof object.manifestHash === "string")
                    $util.base64.decode(object.manifestHash, message.manifestHash = $util.newBuffer($util.base64.length(object.manifestHash)), 0);
                else if (object.manifestHash.length >= 0)
                    message.manifestHash = object.manifestHash;
            return message;
        };

        /**
         * Creates a plain object from a TestDataset message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.TestDataset
         * @static
         * @param {gcg.TestDataset} message TestDataset
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TestDataset.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.encryptionKey = "";
                else {
                    object.encryptionKey = [];
                    if (options.bytes !== Array)
                        object.encryptionKey = $util.newBuffer(object.encryptionKey);
                }
                if (options.bytes === String)
                    object.manifestHash = "";
                else {
                    object.manifestHash = [];
                    if (options.bytes !== Array)
                        object.manifestHash = $util.newBuffer(object.manifestHash);
                }
            }
            if (message.encryptionKey != null && message.hasOwnProperty("encryptionKey"))
                object.encryptionKey = options.bytes === String ? $util.base64.encode(message.encryptionKey, 0, message.encryptionKey.length) : options.bytes === Array ? Array.prototype.slice.call(message.encryptionKey) : message.encryptionKey;
            if (message.manifestHash != null && message.hasOwnProperty("manifestHash"))
                object.manifestHash = options.bytes === String ? $util.base64.encode(message.manifestHash, 0, message.manifestHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.manifestHash) : message.manifestHash;
            return object;
        };

        /**
         * Converts this TestDataset to JSON.
         * @function toJSON
         * @memberof gcg.TestDataset
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TestDataset.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TestDataset
         * @function getTypeUrl
         * @memberof gcg.TestDataset
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TestDataset.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.TestDataset";
        };

        return TestDataset;
    })();

    gcg.ExecuteComputeResponse = (function() {

        /**
         * Properties of an ExecuteComputeResponse.
         * @memberof gcg
         * @interface IExecuteComputeResponse
         * @property {Uint8Array|null} [jobId] ExecuteComputeResponse jobId
         */

        /**
         * Constructs a new ExecuteComputeResponse.
         * @memberof gcg
         * @classdesc Represents an ExecuteComputeResponse.
         * @implements IExecuteComputeResponse
         * @constructor
         * @param {gcg.IExecuteComputeResponse=} [properties] Properties to set
         */
        function ExecuteComputeResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExecuteComputeResponse jobId.
         * @member {Uint8Array} jobId
         * @memberof gcg.ExecuteComputeResponse
         * @instance
         */
        ExecuteComputeResponse.prototype.jobId = $util.newBuffer([]);

        /**
         * Creates a new ExecuteComputeResponse instance using the specified properties.
         * @function create
         * @memberof gcg.ExecuteComputeResponse
         * @static
         * @param {gcg.IExecuteComputeResponse=} [properties] Properties to set
         * @returns {gcg.ExecuteComputeResponse} ExecuteComputeResponse instance
         */
        ExecuteComputeResponse.create = function create(properties) {
            return new ExecuteComputeResponse(properties);
        };

        /**
         * Encodes the specified ExecuteComputeResponse message. Does not implicitly {@link gcg.ExecuteComputeResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.ExecuteComputeResponse
         * @static
         * @param {gcg.IExecuteComputeResponse} message ExecuteComputeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecuteComputeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.jobId != null && Object.hasOwnProperty.call(message, "jobId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.jobId);
            return writer;
        };

        /**
         * Encodes the specified ExecuteComputeResponse message, length delimited. Does not implicitly {@link gcg.ExecuteComputeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.ExecuteComputeResponse
         * @static
         * @param {gcg.IExecuteComputeResponse} message ExecuteComputeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecuteComputeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExecuteComputeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.ExecuteComputeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.ExecuteComputeResponse} ExecuteComputeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecuteComputeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.ExecuteComputeResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.jobId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExecuteComputeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.ExecuteComputeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.ExecuteComputeResponse} ExecuteComputeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecuteComputeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExecuteComputeResponse message.
         * @function verify
         * @memberof gcg.ExecuteComputeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExecuteComputeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.jobId != null && message.hasOwnProperty("jobId"))
                if (!(message.jobId && typeof message.jobId.length === "number" || $util.isString(message.jobId)))
                    return "jobId: buffer expected";
            return null;
        };

        /**
         * Creates an ExecuteComputeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.ExecuteComputeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.ExecuteComputeResponse} ExecuteComputeResponse
         */
        ExecuteComputeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.ExecuteComputeResponse)
                return object;
            let message = new $root.gcg.ExecuteComputeResponse();
            if (object.jobId != null)
                if (typeof object.jobId === "string")
                    $util.base64.decode(object.jobId, message.jobId = $util.newBuffer($util.base64.length(object.jobId)), 0);
                else if (object.jobId.length >= 0)
                    message.jobId = object.jobId;
            return message;
        };

        /**
         * Creates a plain object from an ExecuteComputeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.ExecuteComputeResponse
         * @static
         * @param {gcg.ExecuteComputeResponse} message ExecuteComputeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExecuteComputeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.jobId = "";
                else {
                    object.jobId = [];
                    if (options.bytes !== Array)
                        object.jobId = $util.newBuffer(object.jobId);
                }
            if (message.jobId != null && message.hasOwnProperty("jobId"))
                object.jobId = options.bytes === String ? $util.base64.encode(message.jobId, 0, message.jobId.length) : options.bytes === Array ? Array.prototype.slice.call(message.jobId) : message.jobId;
            return object;
        };

        /**
         * Converts this ExecuteComputeResponse to JSON.
         * @function toJSON
         * @memberof gcg.ExecuteComputeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExecuteComputeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ExecuteComputeResponse
         * @function getTypeUrl
         * @memberof gcg.ExecuteComputeResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExecuteComputeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.ExecuteComputeResponse";
        };

        return ExecuteComputeResponse;
    })();

    gcg.JobStatusRequest = (function() {

        /**
         * Properties of a JobStatusRequest.
         * @memberof gcg
         * @interface IJobStatusRequest
         * @property {Uint8Array|null} [jobId] JobStatusRequest jobId
         */

        /**
         * Constructs a new JobStatusRequest.
         * @memberof gcg
         * @classdesc Represents a JobStatusRequest.
         * @implements IJobStatusRequest
         * @constructor
         * @param {gcg.IJobStatusRequest=} [properties] Properties to set
         */
        function JobStatusRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * JobStatusRequest jobId.
         * @member {Uint8Array} jobId
         * @memberof gcg.JobStatusRequest
         * @instance
         */
        JobStatusRequest.prototype.jobId = $util.newBuffer([]);

        /**
         * Creates a new JobStatusRequest instance using the specified properties.
         * @function create
         * @memberof gcg.JobStatusRequest
         * @static
         * @param {gcg.IJobStatusRequest=} [properties] Properties to set
         * @returns {gcg.JobStatusRequest} JobStatusRequest instance
         */
        JobStatusRequest.create = function create(properties) {
            return new JobStatusRequest(properties);
        };

        /**
         * Encodes the specified JobStatusRequest message. Does not implicitly {@link gcg.JobStatusRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.JobStatusRequest
         * @static
         * @param {gcg.IJobStatusRequest} message JobStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobStatusRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.jobId != null && Object.hasOwnProperty.call(message, "jobId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.jobId);
            return writer;
        };

        /**
         * Encodes the specified JobStatusRequest message, length delimited. Does not implicitly {@link gcg.JobStatusRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.JobStatusRequest
         * @static
         * @param {gcg.IJobStatusRequest} message JobStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a JobStatusRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.JobStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.JobStatusRequest} JobStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobStatusRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.JobStatusRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.jobId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a JobStatusRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.JobStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.JobStatusRequest} JobStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobStatusRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a JobStatusRequest message.
         * @function verify
         * @memberof gcg.JobStatusRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JobStatusRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.jobId != null && message.hasOwnProperty("jobId"))
                if (!(message.jobId && typeof message.jobId.length === "number" || $util.isString(message.jobId)))
                    return "jobId: buffer expected";
            return null;
        };

        /**
         * Creates a JobStatusRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.JobStatusRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.JobStatusRequest} JobStatusRequest
         */
        JobStatusRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.JobStatusRequest)
                return object;
            let message = new $root.gcg.JobStatusRequest();
            if (object.jobId != null)
                if (typeof object.jobId === "string")
                    $util.base64.decode(object.jobId, message.jobId = $util.newBuffer($util.base64.length(object.jobId)), 0);
                else if (object.jobId.length >= 0)
                    message.jobId = object.jobId;
            return message;
        };

        /**
         * Creates a plain object from a JobStatusRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.JobStatusRequest
         * @static
         * @param {gcg.JobStatusRequest} message JobStatusRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JobStatusRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.jobId = "";
                else {
                    object.jobId = [];
                    if (options.bytes !== Array)
                        object.jobId = $util.newBuffer(object.jobId);
                }
            if (message.jobId != null && message.hasOwnProperty("jobId"))
                object.jobId = options.bytes === String ? $util.base64.encode(message.jobId, 0, message.jobId.length) : options.bytes === Array ? Array.prototype.slice.call(message.jobId) : message.jobId;
            return object;
        };

        /**
         * Converts this JobStatusRequest to JSON.
         * @function toJSON
         * @memberof gcg.JobStatusRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JobStatusRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for JobStatusRequest
         * @function getTypeUrl
         * @memberof gcg.JobStatusRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        JobStatusRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.JobStatusRequest";
        };

        return JobStatusRequest;
    })();

    gcg.JobStatusResponse = (function() {

        /**
         * Properties of a JobStatusResponse.
         * @memberof gcg
         * @interface IJobStatusResponse
         * @property {Array.<string>|null} [completeComputeNodeIds] JobStatusResponse completeComputeNodeIds
         */

        /**
         * Constructs a new JobStatusResponse.
         * @memberof gcg
         * @classdesc Represents a JobStatusResponse.
         * @implements IJobStatusResponse
         * @constructor
         * @param {gcg.IJobStatusResponse=} [properties] Properties to set
         */
        function JobStatusResponse(properties) {
            this.completeComputeNodeIds = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * JobStatusResponse completeComputeNodeIds.
         * @member {Array.<string>} completeComputeNodeIds
         * @memberof gcg.JobStatusResponse
         * @instance
         */
        JobStatusResponse.prototype.completeComputeNodeIds = $util.emptyArray;

        /**
         * Creates a new JobStatusResponse instance using the specified properties.
         * @function create
         * @memberof gcg.JobStatusResponse
         * @static
         * @param {gcg.IJobStatusResponse=} [properties] Properties to set
         * @returns {gcg.JobStatusResponse} JobStatusResponse instance
         */
        JobStatusResponse.create = function create(properties) {
            return new JobStatusResponse(properties);
        };

        /**
         * Encodes the specified JobStatusResponse message. Does not implicitly {@link gcg.JobStatusResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.JobStatusResponse
         * @static
         * @param {gcg.IJobStatusResponse} message JobStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobStatusResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.completeComputeNodeIds != null && message.completeComputeNodeIds.length)
                for (let i = 0; i < message.completeComputeNodeIds.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.completeComputeNodeIds[i]);
            return writer;
        };

        /**
         * Encodes the specified JobStatusResponse message, length delimited. Does not implicitly {@link gcg.JobStatusResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.JobStatusResponse
         * @static
         * @param {gcg.IJobStatusResponse} message JobStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a JobStatusResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.JobStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.JobStatusResponse} JobStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobStatusResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.JobStatusResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.completeComputeNodeIds && message.completeComputeNodeIds.length))
                            message.completeComputeNodeIds = [];
                        message.completeComputeNodeIds.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a JobStatusResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.JobStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.JobStatusResponse} JobStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobStatusResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a JobStatusResponse message.
         * @function verify
         * @memberof gcg.JobStatusResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JobStatusResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.completeComputeNodeIds != null && message.hasOwnProperty("completeComputeNodeIds")) {
                if (!Array.isArray(message.completeComputeNodeIds))
                    return "completeComputeNodeIds: array expected";
                for (let i = 0; i < message.completeComputeNodeIds.length; ++i)
                    if (!$util.isString(message.completeComputeNodeIds[i]))
                        return "completeComputeNodeIds: string[] expected";
            }
            return null;
        };

        /**
         * Creates a JobStatusResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.JobStatusResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.JobStatusResponse} JobStatusResponse
         */
        JobStatusResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.JobStatusResponse)
                return object;
            let message = new $root.gcg.JobStatusResponse();
            if (object.completeComputeNodeIds) {
                if (!Array.isArray(object.completeComputeNodeIds))
                    throw TypeError(".gcg.JobStatusResponse.completeComputeNodeIds: array expected");
                message.completeComputeNodeIds = [];
                for (let i = 0; i < object.completeComputeNodeIds.length; ++i)
                    message.completeComputeNodeIds[i] = String(object.completeComputeNodeIds[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a JobStatusResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.JobStatusResponse
         * @static
         * @param {gcg.JobStatusResponse} message JobStatusResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JobStatusResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.completeComputeNodeIds = [];
            if (message.completeComputeNodeIds && message.completeComputeNodeIds.length) {
                object.completeComputeNodeIds = [];
                for (let j = 0; j < message.completeComputeNodeIds.length; ++j)
                    object.completeComputeNodeIds[j] = message.completeComputeNodeIds[j];
            }
            return object;
        };

        /**
         * Converts this JobStatusResponse to JSON.
         * @function toJSON
         * @memberof gcg.JobStatusResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JobStatusResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for JobStatusResponse
         * @function getTypeUrl
         * @memberof gcg.JobStatusResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        JobStatusResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.JobStatusResponse";
        };

        return JobStatusResponse;
    })();

    gcg.GetResultsRequest = (function() {

        /**
         * Properties of a GetResultsRequest.
         * @memberof gcg
         * @interface IGetResultsRequest
         * @property {Uint8Array|null} [jobId] GetResultsRequest jobId
         * @property {string|null} [computeNodeId] GetResultsRequest computeNodeId
         */

        /**
         * Constructs a new GetResultsRequest.
         * @memberof gcg
         * @classdesc Represents a GetResultsRequest.
         * @implements IGetResultsRequest
         * @constructor
         * @param {gcg.IGetResultsRequest=} [properties] Properties to set
         */
        function GetResultsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetResultsRequest jobId.
         * @member {Uint8Array} jobId
         * @memberof gcg.GetResultsRequest
         * @instance
         */
        GetResultsRequest.prototype.jobId = $util.newBuffer([]);

        /**
         * GetResultsRequest computeNodeId.
         * @member {string} computeNodeId
         * @memberof gcg.GetResultsRequest
         * @instance
         */
        GetResultsRequest.prototype.computeNodeId = "";

        /**
         * Creates a new GetResultsRequest instance using the specified properties.
         * @function create
         * @memberof gcg.GetResultsRequest
         * @static
         * @param {gcg.IGetResultsRequest=} [properties] Properties to set
         * @returns {gcg.GetResultsRequest} GetResultsRequest instance
         */
        GetResultsRequest.create = function create(properties) {
            return new GetResultsRequest(properties);
        };

        /**
         * Encodes the specified GetResultsRequest message. Does not implicitly {@link gcg.GetResultsRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.GetResultsRequest
         * @static
         * @param {gcg.IGetResultsRequest} message GetResultsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetResultsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.jobId != null && Object.hasOwnProperty.call(message, "jobId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.jobId);
            if (message.computeNodeId != null && Object.hasOwnProperty.call(message, "computeNodeId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.computeNodeId);
            return writer;
        };

        /**
         * Encodes the specified GetResultsRequest message, length delimited. Does not implicitly {@link gcg.GetResultsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.GetResultsRequest
         * @static
         * @param {gcg.IGetResultsRequest} message GetResultsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetResultsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetResultsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.GetResultsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.GetResultsRequest} GetResultsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetResultsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.GetResultsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.jobId = reader.bytes();
                        break;
                    }
                case 2: {
                        message.computeNodeId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetResultsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.GetResultsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.GetResultsRequest} GetResultsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetResultsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetResultsRequest message.
         * @function verify
         * @memberof gcg.GetResultsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetResultsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.jobId != null && message.hasOwnProperty("jobId"))
                if (!(message.jobId && typeof message.jobId.length === "number" || $util.isString(message.jobId)))
                    return "jobId: buffer expected";
            if (message.computeNodeId != null && message.hasOwnProperty("computeNodeId"))
                if (!$util.isString(message.computeNodeId))
                    return "computeNodeId: string expected";
            return null;
        };

        /**
         * Creates a GetResultsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.GetResultsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.GetResultsRequest} GetResultsRequest
         */
        GetResultsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.GetResultsRequest)
                return object;
            let message = new $root.gcg.GetResultsRequest();
            if (object.jobId != null)
                if (typeof object.jobId === "string")
                    $util.base64.decode(object.jobId, message.jobId = $util.newBuffer($util.base64.length(object.jobId)), 0);
                else if (object.jobId.length >= 0)
                    message.jobId = object.jobId;
            if (object.computeNodeId != null)
                message.computeNodeId = String(object.computeNodeId);
            return message;
        };

        /**
         * Creates a plain object from a GetResultsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.GetResultsRequest
         * @static
         * @param {gcg.GetResultsRequest} message GetResultsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetResultsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.jobId = "";
                else {
                    object.jobId = [];
                    if (options.bytes !== Array)
                        object.jobId = $util.newBuffer(object.jobId);
                }
                object.computeNodeId = "";
            }
            if (message.jobId != null && message.hasOwnProperty("jobId"))
                object.jobId = options.bytes === String ? $util.base64.encode(message.jobId, 0, message.jobId.length) : options.bytes === Array ? Array.prototype.slice.call(message.jobId) : message.jobId;
            if (message.computeNodeId != null && message.hasOwnProperty("computeNodeId"))
                object.computeNodeId = message.computeNodeId;
            return object;
        };

        /**
         * Converts this GetResultsRequest to JSON.
         * @function toJSON
         * @memberof gcg.GetResultsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetResultsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetResultsRequest
         * @function getTypeUrl
         * @memberof gcg.GetResultsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetResultsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.GetResultsRequest";
        };

        return GetResultsRequest;
    })();

    gcg.GetResultsSizeRequest = (function() {

        /**
         * Properties of a GetResultsSizeRequest.
         * @memberof gcg
         * @interface IGetResultsSizeRequest
         * @property {Uint8Array|null} [jobId] GetResultsSizeRequest jobId
         * @property {string|null} [computeNodeId] GetResultsSizeRequest computeNodeId
         */

        /**
         * Constructs a new GetResultsSizeRequest.
         * @memberof gcg
         * @classdesc Represents a GetResultsSizeRequest.
         * @implements IGetResultsSizeRequest
         * @constructor
         * @param {gcg.IGetResultsSizeRequest=} [properties] Properties to set
         */
        function GetResultsSizeRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetResultsSizeRequest jobId.
         * @member {Uint8Array} jobId
         * @memberof gcg.GetResultsSizeRequest
         * @instance
         */
        GetResultsSizeRequest.prototype.jobId = $util.newBuffer([]);

        /**
         * GetResultsSizeRequest computeNodeId.
         * @member {string} computeNodeId
         * @memberof gcg.GetResultsSizeRequest
         * @instance
         */
        GetResultsSizeRequest.prototype.computeNodeId = "";

        /**
         * Creates a new GetResultsSizeRequest instance using the specified properties.
         * @function create
         * @memberof gcg.GetResultsSizeRequest
         * @static
         * @param {gcg.IGetResultsSizeRequest=} [properties] Properties to set
         * @returns {gcg.GetResultsSizeRequest} GetResultsSizeRequest instance
         */
        GetResultsSizeRequest.create = function create(properties) {
            return new GetResultsSizeRequest(properties);
        };

        /**
         * Encodes the specified GetResultsSizeRequest message. Does not implicitly {@link gcg.GetResultsSizeRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.GetResultsSizeRequest
         * @static
         * @param {gcg.IGetResultsSizeRequest} message GetResultsSizeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetResultsSizeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.jobId != null && Object.hasOwnProperty.call(message, "jobId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.jobId);
            if (message.computeNodeId != null && Object.hasOwnProperty.call(message, "computeNodeId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.computeNodeId);
            return writer;
        };

        /**
         * Encodes the specified GetResultsSizeRequest message, length delimited. Does not implicitly {@link gcg.GetResultsSizeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.GetResultsSizeRequest
         * @static
         * @param {gcg.IGetResultsSizeRequest} message GetResultsSizeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetResultsSizeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetResultsSizeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.GetResultsSizeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.GetResultsSizeRequest} GetResultsSizeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetResultsSizeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.GetResultsSizeRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.jobId = reader.bytes();
                        break;
                    }
                case 2: {
                        message.computeNodeId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetResultsSizeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.GetResultsSizeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.GetResultsSizeRequest} GetResultsSizeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetResultsSizeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetResultsSizeRequest message.
         * @function verify
         * @memberof gcg.GetResultsSizeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetResultsSizeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.jobId != null && message.hasOwnProperty("jobId"))
                if (!(message.jobId && typeof message.jobId.length === "number" || $util.isString(message.jobId)))
                    return "jobId: buffer expected";
            if (message.computeNodeId != null && message.hasOwnProperty("computeNodeId"))
                if (!$util.isString(message.computeNodeId))
                    return "computeNodeId: string expected";
            return null;
        };

        /**
         * Creates a GetResultsSizeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.GetResultsSizeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.GetResultsSizeRequest} GetResultsSizeRequest
         */
        GetResultsSizeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.GetResultsSizeRequest)
                return object;
            let message = new $root.gcg.GetResultsSizeRequest();
            if (object.jobId != null)
                if (typeof object.jobId === "string")
                    $util.base64.decode(object.jobId, message.jobId = $util.newBuffer($util.base64.length(object.jobId)), 0);
                else if (object.jobId.length >= 0)
                    message.jobId = object.jobId;
            if (object.computeNodeId != null)
                message.computeNodeId = String(object.computeNodeId);
            return message;
        };

        /**
         * Creates a plain object from a GetResultsSizeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.GetResultsSizeRequest
         * @static
         * @param {gcg.GetResultsSizeRequest} message GetResultsSizeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetResultsSizeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.jobId = "";
                else {
                    object.jobId = [];
                    if (options.bytes !== Array)
                        object.jobId = $util.newBuffer(object.jobId);
                }
                object.computeNodeId = "";
            }
            if (message.jobId != null && message.hasOwnProperty("jobId"))
                object.jobId = options.bytes === String ? $util.base64.encode(message.jobId, 0, message.jobId.length) : options.bytes === Array ? Array.prototype.slice.call(message.jobId) : message.jobId;
            if (message.computeNodeId != null && message.hasOwnProperty("computeNodeId"))
                object.computeNodeId = message.computeNodeId;
            return object;
        };

        /**
         * Converts this GetResultsSizeRequest to JSON.
         * @function toJSON
         * @memberof gcg.GetResultsSizeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetResultsSizeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetResultsSizeRequest
         * @function getTypeUrl
         * @memberof gcg.GetResultsSizeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetResultsSizeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.GetResultsSizeRequest";
        };

        return GetResultsSizeRequest;
    })();

    gcg.GetResultsResponseChunk = (function() {

        /**
         * Properties of a GetResultsResponseChunk.
         * @memberof gcg
         * @interface IGetResultsResponseChunk
         * @property {Uint8Array|null} [data] GetResultsResponseChunk data
         */

        /**
         * Constructs a new GetResultsResponseChunk.
         * @memberof gcg
         * @classdesc Represents a GetResultsResponseChunk.
         * @implements IGetResultsResponseChunk
         * @constructor
         * @param {gcg.IGetResultsResponseChunk=} [properties] Properties to set
         */
        function GetResultsResponseChunk(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetResultsResponseChunk data.
         * @member {Uint8Array} data
         * @memberof gcg.GetResultsResponseChunk
         * @instance
         */
        GetResultsResponseChunk.prototype.data = $util.newBuffer([]);

        /**
         * Creates a new GetResultsResponseChunk instance using the specified properties.
         * @function create
         * @memberof gcg.GetResultsResponseChunk
         * @static
         * @param {gcg.IGetResultsResponseChunk=} [properties] Properties to set
         * @returns {gcg.GetResultsResponseChunk} GetResultsResponseChunk instance
         */
        GetResultsResponseChunk.create = function create(properties) {
            return new GetResultsResponseChunk(properties);
        };

        /**
         * Encodes the specified GetResultsResponseChunk message. Does not implicitly {@link gcg.GetResultsResponseChunk.verify|verify} messages.
         * @function encode
         * @memberof gcg.GetResultsResponseChunk
         * @static
         * @param {gcg.IGetResultsResponseChunk} message GetResultsResponseChunk message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetResultsResponseChunk.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.data);
            return writer;
        };

        /**
         * Encodes the specified GetResultsResponseChunk message, length delimited. Does not implicitly {@link gcg.GetResultsResponseChunk.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.GetResultsResponseChunk
         * @static
         * @param {gcg.IGetResultsResponseChunk} message GetResultsResponseChunk message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetResultsResponseChunk.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetResultsResponseChunk message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.GetResultsResponseChunk
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.GetResultsResponseChunk} GetResultsResponseChunk
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetResultsResponseChunk.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.GetResultsResponseChunk();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.data = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetResultsResponseChunk message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.GetResultsResponseChunk
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.GetResultsResponseChunk} GetResultsResponseChunk
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetResultsResponseChunk.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetResultsResponseChunk message.
         * @function verify
         * @memberof gcg.GetResultsResponseChunk
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetResultsResponseChunk.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.data != null && message.hasOwnProperty("data"))
                if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                    return "data: buffer expected";
            return null;
        };

        /**
         * Creates a GetResultsResponseChunk message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.GetResultsResponseChunk
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.GetResultsResponseChunk} GetResultsResponseChunk
         */
        GetResultsResponseChunk.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.GetResultsResponseChunk)
                return object;
            let message = new $root.gcg.GetResultsResponseChunk();
            if (object.data != null)
                if (typeof object.data === "string")
                    $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                else if (object.data.length >= 0)
                    message.data = object.data;
            return message;
        };

        /**
         * Creates a plain object from a GetResultsResponseChunk message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.GetResultsResponseChunk
         * @static
         * @param {gcg.GetResultsResponseChunk} message GetResultsResponseChunk
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetResultsResponseChunk.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.data = "";
                else {
                    object.data = [];
                    if (options.bytes !== Array)
                        object.data = $util.newBuffer(object.data);
                }
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
            return object;
        };

        /**
         * Converts this GetResultsResponseChunk to JSON.
         * @function toJSON
         * @memberof gcg.GetResultsResponseChunk
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetResultsResponseChunk.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetResultsResponseChunk
         * @function getTypeUrl
         * @memberof gcg.GetResultsResponseChunk
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetResultsResponseChunk.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.GetResultsResponseChunk";
        };

        return GetResultsResponseChunk;
    })();

    gcg.GetResultsResponseFooter = (function() {

        /**
         * Properties of a GetResultsResponseFooter.
         * @memberof gcg
         * @interface IGetResultsResponseFooter
         */

        /**
         * Constructs a new GetResultsResponseFooter.
         * @memberof gcg
         * @classdesc Represents a GetResultsResponseFooter.
         * @implements IGetResultsResponseFooter
         * @constructor
         * @param {gcg.IGetResultsResponseFooter=} [properties] Properties to set
         */
        function GetResultsResponseFooter(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetResultsResponseFooter instance using the specified properties.
         * @function create
         * @memberof gcg.GetResultsResponseFooter
         * @static
         * @param {gcg.IGetResultsResponseFooter=} [properties] Properties to set
         * @returns {gcg.GetResultsResponseFooter} GetResultsResponseFooter instance
         */
        GetResultsResponseFooter.create = function create(properties) {
            return new GetResultsResponseFooter(properties);
        };

        /**
         * Encodes the specified GetResultsResponseFooter message. Does not implicitly {@link gcg.GetResultsResponseFooter.verify|verify} messages.
         * @function encode
         * @memberof gcg.GetResultsResponseFooter
         * @static
         * @param {gcg.IGetResultsResponseFooter} message GetResultsResponseFooter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetResultsResponseFooter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetResultsResponseFooter message, length delimited. Does not implicitly {@link gcg.GetResultsResponseFooter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.GetResultsResponseFooter
         * @static
         * @param {gcg.IGetResultsResponseFooter} message GetResultsResponseFooter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetResultsResponseFooter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetResultsResponseFooter message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.GetResultsResponseFooter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.GetResultsResponseFooter} GetResultsResponseFooter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetResultsResponseFooter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.GetResultsResponseFooter();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetResultsResponseFooter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.GetResultsResponseFooter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.GetResultsResponseFooter} GetResultsResponseFooter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetResultsResponseFooter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetResultsResponseFooter message.
         * @function verify
         * @memberof gcg.GetResultsResponseFooter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetResultsResponseFooter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetResultsResponseFooter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.GetResultsResponseFooter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.GetResultsResponseFooter} GetResultsResponseFooter
         */
        GetResultsResponseFooter.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.GetResultsResponseFooter)
                return object;
            return new $root.gcg.GetResultsResponseFooter();
        };

        /**
         * Creates a plain object from a GetResultsResponseFooter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.GetResultsResponseFooter
         * @static
         * @param {gcg.GetResultsResponseFooter} message GetResultsResponseFooter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetResultsResponseFooter.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetResultsResponseFooter to JSON.
         * @function toJSON
         * @memberof gcg.GetResultsResponseFooter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetResultsResponseFooter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetResultsResponseFooter
         * @function getTypeUrl
         * @memberof gcg.GetResultsResponseFooter
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetResultsResponseFooter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.GetResultsResponseFooter";
        };

        return GetResultsResponseFooter;
    })();

    gcg.RetrieveDataRoomRequest = (function() {

        /**
         * Properties of a RetrieveDataRoomRequest.
         * @memberof gcg
         * @interface IRetrieveDataRoomRequest
         * @property {Uint8Array|null} [dataRoomId] RetrieveDataRoomRequest dataRoomId
         */

        /**
         * Constructs a new RetrieveDataRoomRequest.
         * @memberof gcg
         * @classdesc Represents a RetrieveDataRoomRequest.
         * @implements IRetrieveDataRoomRequest
         * @constructor
         * @param {gcg.IRetrieveDataRoomRequest=} [properties] Properties to set
         */
        function RetrieveDataRoomRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RetrieveDataRoomRequest dataRoomId.
         * @member {Uint8Array} dataRoomId
         * @memberof gcg.RetrieveDataRoomRequest
         * @instance
         */
        RetrieveDataRoomRequest.prototype.dataRoomId = $util.newBuffer([]);

        /**
         * Creates a new RetrieveDataRoomRequest instance using the specified properties.
         * @function create
         * @memberof gcg.RetrieveDataRoomRequest
         * @static
         * @param {gcg.IRetrieveDataRoomRequest=} [properties] Properties to set
         * @returns {gcg.RetrieveDataRoomRequest} RetrieveDataRoomRequest instance
         */
        RetrieveDataRoomRequest.create = function create(properties) {
            return new RetrieveDataRoomRequest(properties);
        };

        /**
         * Encodes the specified RetrieveDataRoomRequest message. Does not implicitly {@link gcg.RetrieveDataRoomRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.RetrieveDataRoomRequest
         * @static
         * @param {gcg.IRetrieveDataRoomRequest} message RetrieveDataRoomRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveDataRoomRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dataRoomId != null && Object.hasOwnProperty.call(message, "dataRoomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dataRoomId);
            return writer;
        };

        /**
         * Encodes the specified RetrieveDataRoomRequest message, length delimited. Does not implicitly {@link gcg.RetrieveDataRoomRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.RetrieveDataRoomRequest
         * @static
         * @param {gcg.IRetrieveDataRoomRequest} message RetrieveDataRoomRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveDataRoomRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrieveDataRoomRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.RetrieveDataRoomRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.RetrieveDataRoomRequest} RetrieveDataRoomRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveDataRoomRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.RetrieveDataRoomRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dataRoomId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrieveDataRoomRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.RetrieveDataRoomRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.RetrieveDataRoomRequest} RetrieveDataRoomRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveDataRoomRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrieveDataRoomRequest message.
         * @function verify
         * @memberof gcg.RetrieveDataRoomRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrieveDataRoomRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                if (!(message.dataRoomId && typeof message.dataRoomId.length === "number" || $util.isString(message.dataRoomId)))
                    return "dataRoomId: buffer expected";
            return null;
        };

        /**
         * Creates a RetrieveDataRoomRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.RetrieveDataRoomRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.RetrieveDataRoomRequest} RetrieveDataRoomRequest
         */
        RetrieveDataRoomRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.RetrieveDataRoomRequest)
                return object;
            let message = new $root.gcg.RetrieveDataRoomRequest();
            if (object.dataRoomId != null)
                if (typeof object.dataRoomId === "string")
                    $util.base64.decode(object.dataRoomId, message.dataRoomId = $util.newBuffer($util.base64.length(object.dataRoomId)), 0);
                else if (object.dataRoomId.length >= 0)
                    message.dataRoomId = object.dataRoomId;
            return message;
        };

        /**
         * Creates a plain object from a RetrieveDataRoomRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.RetrieveDataRoomRequest
         * @static
         * @param {gcg.RetrieveDataRoomRequest} message RetrieveDataRoomRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrieveDataRoomRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.dataRoomId = "";
                else {
                    object.dataRoomId = [];
                    if (options.bytes !== Array)
                        object.dataRoomId = $util.newBuffer(object.dataRoomId);
                }
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                object.dataRoomId = options.bytes === String ? $util.base64.encode(message.dataRoomId, 0, message.dataRoomId.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomId) : message.dataRoomId;
            return object;
        };

        /**
         * Converts this RetrieveDataRoomRequest to JSON.
         * @function toJSON
         * @memberof gcg.RetrieveDataRoomRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrieveDataRoomRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrieveDataRoomRequest
         * @function getTypeUrl
         * @memberof gcg.RetrieveDataRoomRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrieveDataRoomRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.RetrieveDataRoomRequest";
        };

        return RetrieveDataRoomRequest;
    })();

    gcg.RetrieveDataRoomResponse = (function() {

        /**
         * Properties of a RetrieveDataRoomResponse.
         * @memberof gcg
         * @interface IRetrieveDataRoomResponse
         * @property {data_room.IDataRoom|null} [dataRoom] RetrieveDataRoomResponse dataRoom
         * @property {Array.<data_room.IConfigurationCommit>|null} [commits] RetrieveDataRoomResponse commits
         * @property {Uint8Array|null} [highLevelRepresentation] RetrieveDataRoomResponse highLevelRepresentation
         */

        /**
         * Constructs a new RetrieveDataRoomResponse.
         * @memberof gcg
         * @classdesc Represents a RetrieveDataRoomResponse.
         * @implements IRetrieveDataRoomResponse
         * @constructor
         * @param {gcg.IRetrieveDataRoomResponse=} [properties] Properties to set
         */
        function RetrieveDataRoomResponse(properties) {
            this.commits = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RetrieveDataRoomResponse dataRoom.
         * @member {data_room.IDataRoom|null|undefined} dataRoom
         * @memberof gcg.RetrieveDataRoomResponse
         * @instance
         */
        RetrieveDataRoomResponse.prototype.dataRoom = null;

        /**
         * RetrieveDataRoomResponse commits.
         * @member {Array.<data_room.IConfigurationCommit>} commits
         * @memberof gcg.RetrieveDataRoomResponse
         * @instance
         */
        RetrieveDataRoomResponse.prototype.commits = $util.emptyArray;

        /**
         * RetrieveDataRoomResponse highLevelRepresentation.
         * @member {Uint8Array|null|undefined} highLevelRepresentation
         * @memberof gcg.RetrieveDataRoomResponse
         * @instance
         */
        RetrieveDataRoomResponse.prototype.highLevelRepresentation = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * RetrieveDataRoomResponse _highLevelRepresentation.
         * @member {"highLevelRepresentation"|undefined} _highLevelRepresentation
         * @memberof gcg.RetrieveDataRoomResponse
         * @instance
         */
        Object.defineProperty(RetrieveDataRoomResponse.prototype, "_highLevelRepresentation", {
            get: $util.oneOfGetter($oneOfFields = ["highLevelRepresentation"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new RetrieveDataRoomResponse instance using the specified properties.
         * @function create
         * @memberof gcg.RetrieveDataRoomResponse
         * @static
         * @param {gcg.IRetrieveDataRoomResponse=} [properties] Properties to set
         * @returns {gcg.RetrieveDataRoomResponse} RetrieveDataRoomResponse instance
         */
        RetrieveDataRoomResponse.create = function create(properties) {
            return new RetrieveDataRoomResponse(properties);
        };

        /**
         * Encodes the specified RetrieveDataRoomResponse message. Does not implicitly {@link gcg.RetrieveDataRoomResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.RetrieveDataRoomResponse
         * @static
         * @param {gcg.IRetrieveDataRoomResponse} message RetrieveDataRoomResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveDataRoomResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dataRoom != null && Object.hasOwnProperty.call(message, "dataRoom"))
                $root.data_room.DataRoom.encode(message.dataRoom, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.commits != null && message.commits.length)
                for (let i = 0; i < message.commits.length; ++i)
                    $root.data_room.ConfigurationCommit.encode(message.commits[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.highLevelRepresentation != null && Object.hasOwnProperty.call(message, "highLevelRepresentation"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.highLevelRepresentation);
            return writer;
        };

        /**
         * Encodes the specified RetrieveDataRoomResponse message, length delimited. Does not implicitly {@link gcg.RetrieveDataRoomResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.RetrieveDataRoomResponse
         * @static
         * @param {gcg.IRetrieveDataRoomResponse} message RetrieveDataRoomResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveDataRoomResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrieveDataRoomResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.RetrieveDataRoomResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.RetrieveDataRoomResponse} RetrieveDataRoomResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveDataRoomResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.RetrieveDataRoomResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dataRoom = $root.data_room.DataRoom.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        if (!(message.commits && message.commits.length))
                            message.commits = [];
                        message.commits.push($root.data_room.ConfigurationCommit.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.highLevelRepresentation = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrieveDataRoomResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.RetrieveDataRoomResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.RetrieveDataRoomResponse} RetrieveDataRoomResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveDataRoomResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrieveDataRoomResponse message.
         * @function verify
         * @memberof gcg.RetrieveDataRoomResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrieveDataRoomResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.dataRoom != null && message.hasOwnProperty("dataRoom")) {
                let error = $root.data_room.DataRoom.verify(message.dataRoom);
                if (error)
                    return "dataRoom." + error;
            }
            if (message.commits != null && message.hasOwnProperty("commits")) {
                if (!Array.isArray(message.commits))
                    return "commits: array expected";
                for (let i = 0; i < message.commits.length; ++i) {
                    let error = $root.data_room.ConfigurationCommit.verify(message.commits[i]);
                    if (error)
                        return "commits." + error;
                }
            }
            if (message.highLevelRepresentation != null && message.hasOwnProperty("highLevelRepresentation")) {
                properties._highLevelRepresentation = 1;
                if (!(message.highLevelRepresentation && typeof message.highLevelRepresentation.length === "number" || $util.isString(message.highLevelRepresentation)))
                    return "highLevelRepresentation: buffer expected";
            }
            return null;
        };

        /**
         * Creates a RetrieveDataRoomResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.RetrieveDataRoomResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.RetrieveDataRoomResponse} RetrieveDataRoomResponse
         */
        RetrieveDataRoomResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.RetrieveDataRoomResponse)
                return object;
            let message = new $root.gcg.RetrieveDataRoomResponse();
            if (object.dataRoom != null) {
                if (typeof object.dataRoom !== "object")
                    throw TypeError(".gcg.RetrieveDataRoomResponse.dataRoom: object expected");
                message.dataRoom = $root.data_room.DataRoom.fromObject(object.dataRoom);
            }
            if (object.commits) {
                if (!Array.isArray(object.commits))
                    throw TypeError(".gcg.RetrieveDataRoomResponse.commits: array expected");
                message.commits = [];
                for (let i = 0; i < object.commits.length; ++i) {
                    if (typeof object.commits[i] !== "object")
                        throw TypeError(".gcg.RetrieveDataRoomResponse.commits: object expected");
                    message.commits[i] = $root.data_room.ConfigurationCommit.fromObject(object.commits[i]);
                }
            }
            if (object.highLevelRepresentation != null)
                if (typeof object.highLevelRepresentation === "string")
                    $util.base64.decode(object.highLevelRepresentation, message.highLevelRepresentation = $util.newBuffer($util.base64.length(object.highLevelRepresentation)), 0);
                else if (object.highLevelRepresentation.length >= 0)
                    message.highLevelRepresentation = object.highLevelRepresentation;
            return message;
        };

        /**
         * Creates a plain object from a RetrieveDataRoomResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.RetrieveDataRoomResponse
         * @static
         * @param {gcg.RetrieveDataRoomResponse} message RetrieveDataRoomResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrieveDataRoomResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.commits = [];
            if (options.defaults)
                object.dataRoom = null;
            if (message.dataRoom != null && message.hasOwnProperty("dataRoom"))
                object.dataRoom = $root.data_room.DataRoom.toObject(message.dataRoom, options);
            if (message.commits && message.commits.length) {
                object.commits = [];
                for (let j = 0; j < message.commits.length; ++j)
                    object.commits[j] = $root.data_room.ConfigurationCommit.toObject(message.commits[j], options);
            }
            if (message.highLevelRepresentation != null && message.hasOwnProperty("highLevelRepresentation")) {
                object.highLevelRepresentation = options.bytes === String ? $util.base64.encode(message.highLevelRepresentation, 0, message.highLevelRepresentation.length) : options.bytes === Array ? Array.prototype.slice.call(message.highLevelRepresentation) : message.highLevelRepresentation;
                if (options.oneofs)
                    object._highLevelRepresentation = "highLevelRepresentation";
            }
            return object;
        };

        /**
         * Converts this RetrieveDataRoomResponse to JSON.
         * @function toJSON
         * @memberof gcg.RetrieveDataRoomResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrieveDataRoomResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrieveDataRoomResponse
         * @function getTypeUrl
         * @memberof gcg.RetrieveDataRoomResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrieveDataRoomResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.RetrieveDataRoomResponse";
        };

        return RetrieveDataRoomResponse;
    })();

    gcg.RetrieveAuditLogRequest = (function() {

        /**
         * Properties of a RetrieveAuditLogRequest.
         * @memberof gcg
         * @interface IRetrieveAuditLogRequest
         * @property {Uint8Array|null} [dataRoomId] RetrieveAuditLogRequest dataRoomId
         */

        /**
         * Constructs a new RetrieveAuditLogRequest.
         * @memberof gcg
         * @classdesc Represents a RetrieveAuditLogRequest.
         * @implements IRetrieveAuditLogRequest
         * @constructor
         * @param {gcg.IRetrieveAuditLogRequest=} [properties] Properties to set
         */
        function RetrieveAuditLogRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RetrieveAuditLogRequest dataRoomId.
         * @member {Uint8Array} dataRoomId
         * @memberof gcg.RetrieveAuditLogRequest
         * @instance
         */
        RetrieveAuditLogRequest.prototype.dataRoomId = $util.newBuffer([]);

        /**
         * Creates a new RetrieveAuditLogRequest instance using the specified properties.
         * @function create
         * @memberof gcg.RetrieveAuditLogRequest
         * @static
         * @param {gcg.IRetrieveAuditLogRequest=} [properties] Properties to set
         * @returns {gcg.RetrieveAuditLogRequest} RetrieveAuditLogRequest instance
         */
        RetrieveAuditLogRequest.create = function create(properties) {
            return new RetrieveAuditLogRequest(properties);
        };

        /**
         * Encodes the specified RetrieveAuditLogRequest message. Does not implicitly {@link gcg.RetrieveAuditLogRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.RetrieveAuditLogRequest
         * @static
         * @param {gcg.IRetrieveAuditLogRequest} message RetrieveAuditLogRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveAuditLogRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dataRoomId != null && Object.hasOwnProperty.call(message, "dataRoomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dataRoomId);
            return writer;
        };

        /**
         * Encodes the specified RetrieveAuditLogRequest message, length delimited. Does not implicitly {@link gcg.RetrieveAuditLogRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.RetrieveAuditLogRequest
         * @static
         * @param {gcg.IRetrieveAuditLogRequest} message RetrieveAuditLogRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveAuditLogRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrieveAuditLogRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.RetrieveAuditLogRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.RetrieveAuditLogRequest} RetrieveAuditLogRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveAuditLogRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.RetrieveAuditLogRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dataRoomId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrieveAuditLogRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.RetrieveAuditLogRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.RetrieveAuditLogRequest} RetrieveAuditLogRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveAuditLogRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrieveAuditLogRequest message.
         * @function verify
         * @memberof gcg.RetrieveAuditLogRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrieveAuditLogRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                if (!(message.dataRoomId && typeof message.dataRoomId.length === "number" || $util.isString(message.dataRoomId)))
                    return "dataRoomId: buffer expected";
            return null;
        };

        /**
         * Creates a RetrieveAuditLogRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.RetrieveAuditLogRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.RetrieveAuditLogRequest} RetrieveAuditLogRequest
         */
        RetrieveAuditLogRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.RetrieveAuditLogRequest)
                return object;
            let message = new $root.gcg.RetrieveAuditLogRequest();
            if (object.dataRoomId != null)
                if (typeof object.dataRoomId === "string")
                    $util.base64.decode(object.dataRoomId, message.dataRoomId = $util.newBuffer($util.base64.length(object.dataRoomId)), 0);
                else if (object.dataRoomId.length >= 0)
                    message.dataRoomId = object.dataRoomId;
            return message;
        };

        /**
         * Creates a plain object from a RetrieveAuditLogRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.RetrieveAuditLogRequest
         * @static
         * @param {gcg.RetrieveAuditLogRequest} message RetrieveAuditLogRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrieveAuditLogRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.dataRoomId = "";
                else {
                    object.dataRoomId = [];
                    if (options.bytes !== Array)
                        object.dataRoomId = $util.newBuffer(object.dataRoomId);
                }
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                object.dataRoomId = options.bytes === String ? $util.base64.encode(message.dataRoomId, 0, message.dataRoomId.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomId) : message.dataRoomId;
            return object;
        };

        /**
         * Converts this RetrieveAuditLogRequest to JSON.
         * @function toJSON
         * @memberof gcg.RetrieveAuditLogRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrieveAuditLogRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrieveAuditLogRequest
         * @function getTypeUrl
         * @memberof gcg.RetrieveAuditLogRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrieveAuditLogRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.RetrieveAuditLogRequest";
        };

        return RetrieveAuditLogRequest;
    })();

    gcg.RetrieveAuditLogResponse = (function() {

        /**
         * Properties of a RetrieveAuditLogResponse.
         * @memberof gcg
         * @interface IRetrieveAuditLogResponse
         * @property {Uint8Array|null} [log] RetrieveAuditLogResponse log
         */

        /**
         * Constructs a new RetrieveAuditLogResponse.
         * @memberof gcg
         * @classdesc Represents a RetrieveAuditLogResponse.
         * @implements IRetrieveAuditLogResponse
         * @constructor
         * @param {gcg.IRetrieveAuditLogResponse=} [properties] Properties to set
         */
        function RetrieveAuditLogResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RetrieveAuditLogResponse log.
         * @member {Uint8Array} log
         * @memberof gcg.RetrieveAuditLogResponse
         * @instance
         */
        RetrieveAuditLogResponse.prototype.log = $util.newBuffer([]);

        /**
         * Creates a new RetrieveAuditLogResponse instance using the specified properties.
         * @function create
         * @memberof gcg.RetrieveAuditLogResponse
         * @static
         * @param {gcg.IRetrieveAuditLogResponse=} [properties] Properties to set
         * @returns {gcg.RetrieveAuditLogResponse} RetrieveAuditLogResponse instance
         */
        RetrieveAuditLogResponse.create = function create(properties) {
            return new RetrieveAuditLogResponse(properties);
        };

        /**
         * Encodes the specified RetrieveAuditLogResponse message. Does not implicitly {@link gcg.RetrieveAuditLogResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.RetrieveAuditLogResponse
         * @static
         * @param {gcg.IRetrieveAuditLogResponse} message RetrieveAuditLogResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveAuditLogResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.log != null && Object.hasOwnProperty.call(message, "log"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.log);
            return writer;
        };

        /**
         * Encodes the specified RetrieveAuditLogResponse message, length delimited. Does not implicitly {@link gcg.RetrieveAuditLogResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.RetrieveAuditLogResponse
         * @static
         * @param {gcg.IRetrieveAuditLogResponse} message RetrieveAuditLogResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveAuditLogResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrieveAuditLogResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.RetrieveAuditLogResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.RetrieveAuditLogResponse} RetrieveAuditLogResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveAuditLogResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.RetrieveAuditLogResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.log = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrieveAuditLogResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.RetrieveAuditLogResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.RetrieveAuditLogResponse} RetrieveAuditLogResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveAuditLogResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrieveAuditLogResponse message.
         * @function verify
         * @memberof gcg.RetrieveAuditLogResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrieveAuditLogResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.log != null && message.hasOwnProperty("log"))
                if (!(message.log && typeof message.log.length === "number" || $util.isString(message.log)))
                    return "log: buffer expected";
            return null;
        };

        /**
         * Creates a RetrieveAuditLogResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.RetrieveAuditLogResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.RetrieveAuditLogResponse} RetrieveAuditLogResponse
         */
        RetrieveAuditLogResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.RetrieveAuditLogResponse)
                return object;
            let message = new $root.gcg.RetrieveAuditLogResponse();
            if (object.log != null)
                if (typeof object.log === "string")
                    $util.base64.decode(object.log, message.log = $util.newBuffer($util.base64.length(object.log)), 0);
                else if (object.log.length >= 0)
                    message.log = object.log;
            return message;
        };

        /**
         * Creates a plain object from a RetrieveAuditLogResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.RetrieveAuditLogResponse
         * @static
         * @param {gcg.RetrieveAuditLogResponse} message RetrieveAuditLogResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrieveAuditLogResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.log = "";
                else {
                    object.log = [];
                    if (options.bytes !== Array)
                        object.log = $util.newBuffer(object.log);
                }
            if (message.log != null && message.hasOwnProperty("log"))
                object.log = options.bytes === String ? $util.base64.encode(message.log, 0, message.log.length) : options.bytes === Array ? Array.prototype.slice.call(message.log) : message.log;
            return object;
        };

        /**
         * Converts this RetrieveAuditLogResponse to JSON.
         * @function toJSON
         * @memberof gcg.RetrieveAuditLogResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrieveAuditLogResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrieveAuditLogResponse
         * @function getTypeUrl
         * @memberof gcg.RetrieveAuditLogResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrieveAuditLogResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.RetrieveAuditLogResponse";
        };

        return RetrieveAuditLogResponse;
    })();

    /**
     * DataRoomStatus enum.
     * @name gcg.DataRoomStatus
     * @enum {number}
     * @property {number} Active=0 Active value
     * @property {number} Stopped=1 Stopped value
     */
    gcg.DataRoomStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Active"] = 0;
        values[valuesById[1] = "Stopped"] = 1;
        return values;
    })();

    gcg.RetrieveDataRoomStatusRequest = (function() {

        /**
         * Properties of a RetrieveDataRoomStatusRequest.
         * @memberof gcg
         * @interface IRetrieveDataRoomStatusRequest
         * @property {Uint8Array|null} [dataRoomId] RetrieveDataRoomStatusRequest dataRoomId
         */

        /**
         * Constructs a new RetrieveDataRoomStatusRequest.
         * @memberof gcg
         * @classdesc Represents a RetrieveDataRoomStatusRequest.
         * @implements IRetrieveDataRoomStatusRequest
         * @constructor
         * @param {gcg.IRetrieveDataRoomStatusRequest=} [properties] Properties to set
         */
        function RetrieveDataRoomStatusRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RetrieveDataRoomStatusRequest dataRoomId.
         * @member {Uint8Array} dataRoomId
         * @memberof gcg.RetrieveDataRoomStatusRequest
         * @instance
         */
        RetrieveDataRoomStatusRequest.prototype.dataRoomId = $util.newBuffer([]);

        /**
         * Creates a new RetrieveDataRoomStatusRequest instance using the specified properties.
         * @function create
         * @memberof gcg.RetrieveDataRoomStatusRequest
         * @static
         * @param {gcg.IRetrieveDataRoomStatusRequest=} [properties] Properties to set
         * @returns {gcg.RetrieveDataRoomStatusRequest} RetrieveDataRoomStatusRequest instance
         */
        RetrieveDataRoomStatusRequest.create = function create(properties) {
            return new RetrieveDataRoomStatusRequest(properties);
        };

        /**
         * Encodes the specified RetrieveDataRoomStatusRequest message. Does not implicitly {@link gcg.RetrieveDataRoomStatusRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.RetrieveDataRoomStatusRequest
         * @static
         * @param {gcg.IRetrieveDataRoomStatusRequest} message RetrieveDataRoomStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveDataRoomStatusRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dataRoomId != null && Object.hasOwnProperty.call(message, "dataRoomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dataRoomId);
            return writer;
        };

        /**
         * Encodes the specified RetrieveDataRoomStatusRequest message, length delimited. Does not implicitly {@link gcg.RetrieveDataRoomStatusRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.RetrieveDataRoomStatusRequest
         * @static
         * @param {gcg.IRetrieveDataRoomStatusRequest} message RetrieveDataRoomStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveDataRoomStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrieveDataRoomStatusRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.RetrieveDataRoomStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.RetrieveDataRoomStatusRequest} RetrieveDataRoomStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveDataRoomStatusRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.RetrieveDataRoomStatusRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dataRoomId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrieveDataRoomStatusRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.RetrieveDataRoomStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.RetrieveDataRoomStatusRequest} RetrieveDataRoomStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveDataRoomStatusRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrieveDataRoomStatusRequest message.
         * @function verify
         * @memberof gcg.RetrieveDataRoomStatusRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrieveDataRoomStatusRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                if (!(message.dataRoomId && typeof message.dataRoomId.length === "number" || $util.isString(message.dataRoomId)))
                    return "dataRoomId: buffer expected";
            return null;
        };

        /**
         * Creates a RetrieveDataRoomStatusRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.RetrieveDataRoomStatusRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.RetrieveDataRoomStatusRequest} RetrieveDataRoomStatusRequest
         */
        RetrieveDataRoomStatusRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.RetrieveDataRoomStatusRequest)
                return object;
            let message = new $root.gcg.RetrieveDataRoomStatusRequest();
            if (object.dataRoomId != null)
                if (typeof object.dataRoomId === "string")
                    $util.base64.decode(object.dataRoomId, message.dataRoomId = $util.newBuffer($util.base64.length(object.dataRoomId)), 0);
                else if (object.dataRoomId.length >= 0)
                    message.dataRoomId = object.dataRoomId;
            return message;
        };

        /**
         * Creates a plain object from a RetrieveDataRoomStatusRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.RetrieveDataRoomStatusRequest
         * @static
         * @param {gcg.RetrieveDataRoomStatusRequest} message RetrieveDataRoomStatusRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrieveDataRoomStatusRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.dataRoomId = "";
                else {
                    object.dataRoomId = [];
                    if (options.bytes !== Array)
                        object.dataRoomId = $util.newBuffer(object.dataRoomId);
                }
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                object.dataRoomId = options.bytes === String ? $util.base64.encode(message.dataRoomId, 0, message.dataRoomId.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomId) : message.dataRoomId;
            return object;
        };

        /**
         * Converts this RetrieveDataRoomStatusRequest to JSON.
         * @function toJSON
         * @memberof gcg.RetrieveDataRoomStatusRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrieveDataRoomStatusRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrieveDataRoomStatusRequest
         * @function getTypeUrl
         * @memberof gcg.RetrieveDataRoomStatusRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrieveDataRoomStatusRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.RetrieveDataRoomStatusRequest";
        };

        return RetrieveDataRoomStatusRequest;
    })();

    gcg.RetrieveDataRoomStatusResponse = (function() {

        /**
         * Properties of a RetrieveDataRoomStatusResponse.
         * @memberof gcg
         * @interface IRetrieveDataRoomStatusResponse
         * @property {gcg.DataRoomStatus|null} [status] RetrieveDataRoomStatusResponse status
         */

        /**
         * Constructs a new RetrieveDataRoomStatusResponse.
         * @memberof gcg
         * @classdesc Represents a RetrieveDataRoomStatusResponse.
         * @implements IRetrieveDataRoomStatusResponse
         * @constructor
         * @param {gcg.IRetrieveDataRoomStatusResponse=} [properties] Properties to set
         */
        function RetrieveDataRoomStatusResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RetrieveDataRoomStatusResponse status.
         * @member {gcg.DataRoomStatus} status
         * @memberof gcg.RetrieveDataRoomStatusResponse
         * @instance
         */
        RetrieveDataRoomStatusResponse.prototype.status = 0;

        /**
         * Creates a new RetrieveDataRoomStatusResponse instance using the specified properties.
         * @function create
         * @memberof gcg.RetrieveDataRoomStatusResponse
         * @static
         * @param {gcg.IRetrieveDataRoomStatusResponse=} [properties] Properties to set
         * @returns {gcg.RetrieveDataRoomStatusResponse} RetrieveDataRoomStatusResponse instance
         */
        RetrieveDataRoomStatusResponse.create = function create(properties) {
            return new RetrieveDataRoomStatusResponse(properties);
        };

        /**
         * Encodes the specified RetrieveDataRoomStatusResponse message. Does not implicitly {@link gcg.RetrieveDataRoomStatusResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.RetrieveDataRoomStatusResponse
         * @static
         * @param {gcg.IRetrieveDataRoomStatusResponse} message RetrieveDataRoomStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveDataRoomStatusResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified RetrieveDataRoomStatusResponse message, length delimited. Does not implicitly {@link gcg.RetrieveDataRoomStatusResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.RetrieveDataRoomStatusResponse
         * @static
         * @param {gcg.IRetrieveDataRoomStatusResponse} message RetrieveDataRoomStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveDataRoomStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrieveDataRoomStatusResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.RetrieveDataRoomStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.RetrieveDataRoomStatusResponse} RetrieveDataRoomStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveDataRoomStatusResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.RetrieveDataRoomStatusResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrieveDataRoomStatusResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.RetrieveDataRoomStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.RetrieveDataRoomStatusResponse} RetrieveDataRoomStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveDataRoomStatusResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrieveDataRoomStatusResponse message.
         * @function verify
         * @memberof gcg.RetrieveDataRoomStatusResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrieveDataRoomStatusResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates a RetrieveDataRoomStatusResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.RetrieveDataRoomStatusResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.RetrieveDataRoomStatusResponse} RetrieveDataRoomStatusResponse
         */
        RetrieveDataRoomStatusResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.RetrieveDataRoomStatusResponse)
                return object;
            let message = new $root.gcg.RetrieveDataRoomStatusResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "Active":
            case 0:
                message.status = 0;
                break;
            case "Stopped":
            case 1:
                message.status = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a RetrieveDataRoomStatusResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.RetrieveDataRoomStatusResponse
         * @static
         * @param {gcg.RetrieveDataRoomStatusResponse} message RetrieveDataRoomStatusResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrieveDataRoomStatusResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.status = options.enums === String ? "Active" : 0;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.gcg.DataRoomStatus[message.status] === undefined ? message.status : $root.gcg.DataRoomStatus[message.status] : message.status;
            return object;
        };

        /**
         * Converts this RetrieveDataRoomStatusResponse to JSON.
         * @function toJSON
         * @memberof gcg.RetrieveDataRoomStatusResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrieveDataRoomStatusResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrieveDataRoomStatusResponse
         * @function getTypeUrl
         * @memberof gcg.RetrieveDataRoomStatusResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrieveDataRoomStatusResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.RetrieveDataRoomStatusResponse";
        };

        return RetrieveDataRoomStatusResponse;
    })();

    gcg.UpdateDataRoomStatusRequest = (function() {

        /**
         * Properties of an UpdateDataRoomStatusRequest.
         * @memberof gcg
         * @interface IUpdateDataRoomStatusRequest
         * @property {Uint8Array|null} [dataRoomId] UpdateDataRoomStatusRequest dataRoomId
         * @property {gcg.DataRoomStatus|null} [status] UpdateDataRoomStatusRequest status
         */

        /**
         * Constructs a new UpdateDataRoomStatusRequest.
         * @memberof gcg
         * @classdesc Represents an UpdateDataRoomStatusRequest.
         * @implements IUpdateDataRoomStatusRequest
         * @constructor
         * @param {gcg.IUpdateDataRoomStatusRequest=} [properties] Properties to set
         */
        function UpdateDataRoomStatusRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateDataRoomStatusRequest dataRoomId.
         * @member {Uint8Array} dataRoomId
         * @memberof gcg.UpdateDataRoomStatusRequest
         * @instance
         */
        UpdateDataRoomStatusRequest.prototype.dataRoomId = $util.newBuffer([]);

        /**
         * UpdateDataRoomStatusRequest status.
         * @member {gcg.DataRoomStatus} status
         * @memberof gcg.UpdateDataRoomStatusRequest
         * @instance
         */
        UpdateDataRoomStatusRequest.prototype.status = 0;

        /**
         * Creates a new UpdateDataRoomStatusRequest instance using the specified properties.
         * @function create
         * @memberof gcg.UpdateDataRoomStatusRequest
         * @static
         * @param {gcg.IUpdateDataRoomStatusRequest=} [properties] Properties to set
         * @returns {gcg.UpdateDataRoomStatusRequest} UpdateDataRoomStatusRequest instance
         */
        UpdateDataRoomStatusRequest.create = function create(properties) {
            return new UpdateDataRoomStatusRequest(properties);
        };

        /**
         * Encodes the specified UpdateDataRoomStatusRequest message. Does not implicitly {@link gcg.UpdateDataRoomStatusRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.UpdateDataRoomStatusRequest
         * @static
         * @param {gcg.IUpdateDataRoomStatusRequest} message UpdateDataRoomStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateDataRoomStatusRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dataRoomId != null && Object.hasOwnProperty.call(message, "dataRoomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dataRoomId);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified UpdateDataRoomStatusRequest message, length delimited. Does not implicitly {@link gcg.UpdateDataRoomStatusRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.UpdateDataRoomStatusRequest
         * @static
         * @param {gcg.IUpdateDataRoomStatusRequest} message UpdateDataRoomStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateDataRoomStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateDataRoomStatusRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.UpdateDataRoomStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.UpdateDataRoomStatusRequest} UpdateDataRoomStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateDataRoomStatusRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.UpdateDataRoomStatusRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dataRoomId = reader.bytes();
                        break;
                    }
                case 2: {
                        message.status = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateDataRoomStatusRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.UpdateDataRoomStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.UpdateDataRoomStatusRequest} UpdateDataRoomStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateDataRoomStatusRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateDataRoomStatusRequest message.
         * @function verify
         * @memberof gcg.UpdateDataRoomStatusRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateDataRoomStatusRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                if (!(message.dataRoomId && typeof message.dataRoomId.length === "number" || $util.isString(message.dataRoomId)))
                    return "dataRoomId: buffer expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates an UpdateDataRoomStatusRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.UpdateDataRoomStatusRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.UpdateDataRoomStatusRequest} UpdateDataRoomStatusRequest
         */
        UpdateDataRoomStatusRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.UpdateDataRoomStatusRequest)
                return object;
            let message = new $root.gcg.UpdateDataRoomStatusRequest();
            if (object.dataRoomId != null)
                if (typeof object.dataRoomId === "string")
                    $util.base64.decode(object.dataRoomId, message.dataRoomId = $util.newBuffer($util.base64.length(object.dataRoomId)), 0);
                else if (object.dataRoomId.length >= 0)
                    message.dataRoomId = object.dataRoomId;
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "Active":
            case 0:
                message.status = 0;
                break;
            case "Stopped":
            case 1:
                message.status = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateDataRoomStatusRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.UpdateDataRoomStatusRequest
         * @static
         * @param {gcg.UpdateDataRoomStatusRequest} message UpdateDataRoomStatusRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateDataRoomStatusRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.dataRoomId = "";
                else {
                    object.dataRoomId = [];
                    if (options.bytes !== Array)
                        object.dataRoomId = $util.newBuffer(object.dataRoomId);
                }
                object.status = options.enums === String ? "Active" : 0;
            }
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                object.dataRoomId = options.bytes === String ? $util.base64.encode(message.dataRoomId, 0, message.dataRoomId.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomId) : message.dataRoomId;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.gcg.DataRoomStatus[message.status] === undefined ? message.status : $root.gcg.DataRoomStatus[message.status] : message.status;
            return object;
        };

        /**
         * Converts this UpdateDataRoomStatusRequest to JSON.
         * @function toJSON
         * @memberof gcg.UpdateDataRoomStatusRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateDataRoomStatusRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateDataRoomStatusRequest
         * @function getTypeUrl
         * @memberof gcg.UpdateDataRoomStatusRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateDataRoomStatusRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.UpdateDataRoomStatusRequest";
        };

        return UpdateDataRoomStatusRequest;
    })();

    gcg.UpdateDataRoomStatusResponse = (function() {

        /**
         * Properties of an UpdateDataRoomStatusResponse.
         * @memberof gcg
         * @interface IUpdateDataRoomStatusResponse
         */

        /**
         * Constructs a new UpdateDataRoomStatusResponse.
         * @memberof gcg
         * @classdesc Represents an UpdateDataRoomStatusResponse.
         * @implements IUpdateDataRoomStatusResponse
         * @constructor
         * @param {gcg.IUpdateDataRoomStatusResponse=} [properties] Properties to set
         */
        function UpdateDataRoomStatusResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateDataRoomStatusResponse instance using the specified properties.
         * @function create
         * @memberof gcg.UpdateDataRoomStatusResponse
         * @static
         * @param {gcg.IUpdateDataRoomStatusResponse=} [properties] Properties to set
         * @returns {gcg.UpdateDataRoomStatusResponse} UpdateDataRoomStatusResponse instance
         */
        UpdateDataRoomStatusResponse.create = function create(properties) {
            return new UpdateDataRoomStatusResponse(properties);
        };

        /**
         * Encodes the specified UpdateDataRoomStatusResponse message. Does not implicitly {@link gcg.UpdateDataRoomStatusResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.UpdateDataRoomStatusResponse
         * @static
         * @param {gcg.IUpdateDataRoomStatusResponse} message UpdateDataRoomStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateDataRoomStatusResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateDataRoomStatusResponse message, length delimited. Does not implicitly {@link gcg.UpdateDataRoomStatusResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.UpdateDataRoomStatusResponse
         * @static
         * @param {gcg.IUpdateDataRoomStatusResponse} message UpdateDataRoomStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateDataRoomStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateDataRoomStatusResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.UpdateDataRoomStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.UpdateDataRoomStatusResponse} UpdateDataRoomStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateDataRoomStatusResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.UpdateDataRoomStatusResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateDataRoomStatusResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.UpdateDataRoomStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.UpdateDataRoomStatusResponse} UpdateDataRoomStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateDataRoomStatusResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateDataRoomStatusResponse message.
         * @function verify
         * @memberof gcg.UpdateDataRoomStatusResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateDataRoomStatusResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateDataRoomStatusResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.UpdateDataRoomStatusResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.UpdateDataRoomStatusResponse} UpdateDataRoomStatusResponse
         */
        UpdateDataRoomStatusResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.UpdateDataRoomStatusResponse)
                return object;
            return new $root.gcg.UpdateDataRoomStatusResponse();
        };

        /**
         * Creates a plain object from an UpdateDataRoomStatusResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.UpdateDataRoomStatusResponse
         * @static
         * @param {gcg.UpdateDataRoomStatusResponse} message UpdateDataRoomStatusResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateDataRoomStatusResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateDataRoomStatusResponse to JSON.
         * @function toJSON
         * @memberof gcg.UpdateDataRoomStatusResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateDataRoomStatusResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateDataRoomStatusResponse
         * @function getTypeUrl
         * @memberof gcg.UpdateDataRoomStatusResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateDataRoomStatusResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.UpdateDataRoomStatusResponse";
        };

        return UpdateDataRoomStatusResponse;
    })();

    gcg.RetrievePublishedDatasetsRequest = (function() {

        /**
         * Properties of a RetrievePublishedDatasetsRequest.
         * @memberof gcg
         * @interface IRetrievePublishedDatasetsRequest
         * @property {Uint8Array|null} [dataRoomId] RetrievePublishedDatasetsRequest dataRoomId
         */

        /**
         * Constructs a new RetrievePublishedDatasetsRequest.
         * @memberof gcg
         * @classdesc Represents a RetrievePublishedDatasetsRequest.
         * @implements IRetrievePublishedDatasetsRequest
         * @constructor
         * @param {gcg.IRetrievePublishedDatasetsRequest=} [properties] Properties to set
         */
        function RetrievePublishedDatasetsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RetrievePublishedDatasetsRequest dataRoomId.
         * @member {Uint8Array} dataRoomId
         * @memberof gcg.RetrievePublishedDatasetsRequest
         * @instance
         */
        RetrievePublishedDatasetsRequest.prototype.dataRoomId = $util.newBuffer([]);

        /**
         * Creates a new RetrievePublishedDatasetsRequest instance using the specified properties.
         * @function create
         * @memberof gcg.RetrievePublishedDatasetsRequest
         * @static
         * @param {gcg.IRetrievePublishedDatasetsRequest=} [properties] Properties to set
         * @returns {gcg.RetrievePublishedDatasetsRequest} RetrievePublishedDatasetsRequest instance
         */
        RetrievePublishedDatasetsRequest.create = function create(properties) {
            return new RetrievePublishedDatasetsRequest(properties);
        };

        /**
         * Encodes the specified RetrievePublishedDatasetsRequest message. Does not implicitly {@link gcg.RetrievePublishedDatasetsRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.RetrievePublishedDatasetsRequest
         * @static
         * @param {gcg.IRetrievePublishedDatasetsRequest} message RetrievePublishedDatasetsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrievePublishedDatasetsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dataRoomId != null && Object.hasOwnProperty.call(message, "dataRoomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dataRoomId);
            return writer;
        };

        /**
         * Encodes the specified RetrievePublishedDatasetsRequest message, length delimited. Does not implicitly {@link gcg.RetrievePublishedDatasetsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.RetrievePublishedDatasetsRequest
         * @static
         * @param {gcg.IRetrievePublishedDatasetsRequest} message RetrievePublishedDatasetsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrievePublishedDatasetsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrievePublishedDatasetsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.RetrievePublishedDatasetsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.RetrievePublishedDatasetsRequest} RetrievePublishedDatasetsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrievePublishedDatasetsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.RetrievePublishedDatasetsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dataRoomId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrievePublishedDatasetsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.RetrievePublishedDatasetsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.RetrievePublishedDatasetsRequest} RetrievePublishedDatasetsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrievePublishedDatasetsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrievePublishedDatasetsRequest message.
         * @function verify
         * @memberof gcg.RetrievePublishedDatasetsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrievePublishedDatasetsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                if (!(message.dataRoomId && typeof message.dataRoomId.length === "number" || $util.isString(message.dataRoomId)))
                    return "dataRoomId: buffer expected";
            return null;
        };

        /**
         * Creates a RetrievePublishedDatasetsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.RetrievePublishedDatasetsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.RetrievePublishedDatasetsRequest} RetrievePublishedDatasetsRequest
         */
        RetrievePublishedDatasetsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.RetrievePublishedDatasetsRequest)
                return object;
            let message = new $root.gcg.RetrievePublishedDatasetsRequest();
            if (object.dataRoomId != null)
                if (typeof object.dataRoomId === "string")
                    $util.base64.decode(object.dataRoomId, message.dataRoomId = $util.newBuffer($util.base64.length(object.dataRoomId)), 0);
                else if (object.dataRoomId.length >= 0)
                    message.dataRoomId = object.dataRoomId;
            return message;
        };

        /**
         * Creates a plain object from a RetrievePublishedDatasetsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.RetrievePublishedDatasetsRequest
         * @static
         * @param {gcg.RetrievePublishedDatasetsRequest} message RetrievePublishedDatasetsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrievePublishedDatasetsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.dataRoomId = "";
                else {
                    object.dataRoomId = [];
                    if (options.bytes !== Array)
                        object.dataRoomId = $util.newBuffer(object.dataRoomId);
                }
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                object.dataRoomId = options.bytes === String ? $util.base64.encode(message.dataRoomId, 0, message.dataRoomId.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomId) : message.dataRoomId;
            return object;
        };

        /**
         * Converts this RetrievePublishedDatasetsRequest to JSON.
         * @function toJSON
         * @memberof gcg.RetrievePublishedDatasetsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrievePublishedDatasetsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrievePublishedDatasetsRequest
         * @function getTypeUrl
         * @memberof gcg.RetrievePublishedDatasetsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrievePublishedDatasetsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.RetrievePublishedDatasetsRequest";
        };

        return RetrievePublishedDatasetsRequest;
    })();

    gcg.PublishedDataset = (function() {

        /**
         * Properties of a PublishedDataset.
         * @memberof gcg
         * @interface IPublishedDataset
         * @property {string|null} [leafId] PublishedDataset leafId
         * @property {string|null} [user] PublishedDataset user
         * @property {number|Long|null} [timestamp] PublishedDataset timestamp
         * @property {Uint8Array|null} [datasetHash] PublishedDataset datasetHash
         */

        /**
         * Constructs a new PublishedDataset.
         * @memberof gcg
         * @classdesc Represents a PublishedDataset.
         * @implements IPublishedDataset
         * @constructor
         * @param {gcg.IPublishedDataset=} [properties] Properties to set
         */
        function PublishedDataset(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PublishedDataset leafId.
         * @member {string} leafId
         * @memberof gcg.PublishedDataset
         * @instance
         */
        PublishedDataset.prototype.leafId = "";

        /**
         * PublishedDataset user.
         * @member {string} user
         * @memberof gcg.PublishedDataset
         * @instance
         */
        PublishedDataset.prototype.user = "";

        /**
         * PublishedDataset timestamp.
         * @member {number|Long} timestamp
         * @memberof gcg.PublishedDataset
         * @instance
         */
        PublishedDataset.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * PublishedDataset datasetHash.
         * @member {Uint8Array} datasetHash
         * @memberof gcg.PublishedDataset
         * @instance
         */
        PublishedDataset.prototype.datasetHash = $util.newBuffer([]);

        /**
         * Creates a new PublishedDataset instance using the specified properties.
         * @function create
         * @memberof gcg.PublishedDataset
         * @static
         * @param {gcg.IPublishedDataset=} [properties] Properties to set
         * @returns {gcg.PublishedDataset} PublishedDataset instance
         */
        PublishedDataset.create = function create(properties) {
            return new PublishedDataset(properties);
        };

        /**
         * Encodes the specified PublishedDataset message. Does not implicitly {@link gcg.PublishedDataset.verify|verify} messages.
         * @function encode
         * @memberof gcg.PublishedDataset
         * @static
         * @param {gcg.IPublishedDataset} message PublishedDataset message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublishedDataset.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.leafId != null && Object.hasOwnProperty.call(message, "leafId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.leafId);
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.user);
            if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.timestamp);
            if (message.datasetHash != null && Object.hasOwnProperty.call(message, "datasetHash"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.datasetHash);
            return writer;
        };

        /**
         * Encodes the specified PublishedDataset message, length delimited. Does not implicitly {@link gcg.PublishedDataset.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.PublishedDataset
         * @static
         * @param {gcg.IPublishedDataset} message PublishedDataset message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublishedDataset.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PublishedDataset message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.PublishedDataset
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.PublishedDataset} PublishedDataset
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublishedDataset.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.PublishedDataset();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.leafId = reader.string();
                        break;
                    }
                case 2: {
                        message.user = reader.string();
                        break;
                    }
                case 3: {
                        message.timestamp = reader.uint64();
                        break;
                    }
                case 4: {
                        message.datasetHash = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PublishedDataset message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.PublishedDataset
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.PublishedDataset} PublishedDataset
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublishedDataset.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PublishedDataset message.
         * @function verify
         * @memberof gcg.PublishedDataset
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PublishedDataset.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.leafId != null && message.hasOwnProperty("leafId"))
                if (!$util.isString(message.leafId))
                    return "leafId: string expected";
            if (message.user != null && message.hasOwnProperty("user"))
                if (!$util.isString(message.user))
                    return "user: string expected";
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                    return "timestamp: integer|Long expected";
            if (message.datasetHash != null && message.hasOwnProperty("datasetHash"))
                if (!(message.datasetHash && typeof message.datasetHash.length === "number" || $util.isString(message.datasetHash)))
                    return "datasetHash: buffer expected";
            return null;
        };

        /**
         * Creates a PublishedDataset message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.PublishedDataset
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.PublishedDataset} PublishedDataset
         */
        PublishedDataset.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.PublishedDataset)
                return object;
            let message = new $root.gcg.PublishedDataset();
            if (object.leafId != null)
                message.leafId = String(object.leafId);
            if (object.user != null)
                message.user = String(object.user);
            if (object.timestamp != null)
                if ($util.Long)
                    (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = true;
                else if (typeof object.timestamp === "string")
                    message.timestamp = parseInt(object.timestamp, 10);
                else if (typeof object.timestamp === "number")
                    message.timestamp = object.timestamp;
                else if (typeof object.timestamp === "object")
                    message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber(true);
            if (object.datasetHash != null)
                if (typeof object.datasetHash === "string")
                    $util.base64.decode(object.datasetHash, message.datasetHash = $util.newBuffer($util.base64.length(object.datasetHash)), 0);
                else if (object.datasetHash.length >= 0)
                    message.datasetHash = object.datasetHash;
            return message;
        };

        /**
         * Creates a plain object from a PublishedDataset message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.PublishedDataset
         * @static
         * @param {gcg.PublishedDataset} message PublishedDataset
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PublishedDataset.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.leafId = "";
                object.user = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timestamp = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.datasetHash = "";
                else {
                    object.datasetHash = [];
                    if (options.bytes !== Array)
                        object.datasetHash = $util.newBuffer(object.datasetHash);
                }
            }
            if (message.leafId != null && message.hasOwnProperty("leafId"))
                object.leafId = message.leafId;
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = message.user;
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (typeof message.timestamp === "number")
                    object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                else
                    object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber(true) : message.timestamp;
            if (message.datasetHash != null && message.hasOwnProperty("datasetHash"))
                object.datasetHash = options.bytes === String ? $util.base64.encode(message.datasetHash, 0, message.datasetHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.datasetHash) : message.datasetHash;
            return object;
        };

        /**
         * Converts this PublishedDataset to JSON.
         * @function toJSON
         * @memberof gcg.PublishedDataset
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PublishedDataset.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PublishedDataset
         * @function getTypeUrl
         * @memberof gcg.PublishedDataset
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PublishedDataset.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.PublishedDataset";
        };

        return PublishedDataset;
    })();

    gcg.RetrievePublishedDatasetsResponse = (function() {

        /**
         * Properties of a RetrievePublishedDatasetsResponse.
         * @memberof gcg
         * @interface IRetrievePublishedDatasetsResponse
         * @property {Array.<gcg.IPublishedDataset>|null} [publishedDatasets] RetrievePublishedDatasetsResponse publishedDatasets
         */

        /**
         * Constructs a new RetrievePublishedDatasetsResponse.
         * @memberof gcg
         * @classdesc Represents a RetrievePublishedDatasetsResponse.
         * @implements IRetrievePublishedDatasetsResponse
         * @constructor
         * @param {gcg.IRetrievePublishedDatasetsResponse=} [properties] Properties to set
         */
        function RetrievePublishedDatasetsResponse(properties) {
            this.publishedDatasets = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RetrievePublishedDatasetsResponse publishedDatasets.
         * @member {Array.<gcg.IPublishedDataset>} publishedDatasets
         * @memberof gcg.RetrievePublishedDatasetsResponse
         * @instance
         */
        RetrievePublishedDatasetsResponse.prototype.publishedDatasets = $util.emptyArray;

        /**
         * Creates a new RetrievePublishedDatasetsResponse instance using the specified properties.
         * @function create
         * @memberof gcg.RetrievePublishedDatasetsResponse
         * @static
         * @param {gcg.IRetrievePublishedDatasetsResponse=} [properties] Properties to set
         * @returns {gcg.RetrievePublishedDatasetsResponse} RetrievePublishedDatasetsResponse instance
         */
        RetrievePublishedDatasetsResponse.create = function create(properties) {
            return new RetrievePublishedDatasetsResponse(properties);
        };

        /**
         * Encodes the specified RetrievePublishedDatasetsResponse message. Does not implicitly {@link gcg.RetrievePublishedDatasetsResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.RetrievePublishedDatasetsResponse
         * @static
         * @param {gcg.IRetrievePublishedDatasetsResponse} message RetrievePublishedDatasetsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrievePublishedDatasetsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.publishedDatasets != null && message.publishedDatasets.length)
                for (let i = 0; i < message.publishedDatasets.length; ++i)
                    $root.gcg.PublishedDataset.encode(message.publishedDatasets[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RetrievePublishedDatasetsResponse message, length delimited. Does not implicitly {@link gcg.RetrievePublishedDatasetsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.RetrievePublishedDatasetsResponse
         * @static
         * @param {gcg.IRetrievePublishedDatasetsResponse} message RetrievePublishedDatasetsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrievePublishedDatasetsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrievePublishedDatasetsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.RetrievePublishedDatasetsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.RetrievePublishedDatasetsResponse} RetrievePublishedDatasetsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrievePublishedDatasetsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.RetrievePublishedDatasetsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.publishedDatasets && message.publishedDatasets.length))
                            message.publishedDatasets = [];
                        message.publishedDatasets.push($root.gcg.PublishedDataset.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrievePublishedDatasetsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.RetrievePublishedDatasetsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.RetrievePublishedDatasetsResponse} RetrievePublishedDatasetsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrievePublishedDatasetsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrievePublishedDatasetsResponse message.
         * @function verify
         * @memberof gcg.RetrievePublishedDatasetsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrievePublishedDatasetsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.publishedDatasets != null && message.hasOwnProperty("publishedDatasets")) {
                if (!Array.isArray(message.publishedDatasets))
                    return "publishedDatasets: array expected";
                for (let i = 0; i < message.publishedDatasets.length; ++i) {
                    let error = $root.gcg.PublishedDataset.verify(message.publishedDatasets[i]);
                    if (error)
                        return "publishedDatasets." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RetrievePublishedDatasetsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.RetrievePublishedDatasetsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.RetrievePublishedDatasetsResponse} RetrievePublishedDatasetsResponse
         */
        RetrievePublishedDatasetsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.RetrievePublishedDatasetsResponse)
                return object;
            let message = new $root.gcg.RetrievePublishedDatasetsResponse();
            if (object.publishedDatasets) {
                if (!Array.isArray(object.publishedDatasets))
                    throw TypeError(".gcg.RetrievePublishedDatasetsResponse.publishedDatasets: array expected");
                message.publishedDatasets = [];
                for (let i = 0; i < object.publishedDatasets.length; ++i) {
                    if (typeof object.publishedDatasets[i] !== "object")
                        throw TypeError(".gcg.RetrievePublishedDatasetsResponse.publishedDatasets: object expected");
                    message.publishedDatasets[i] = $root.gcg.PublishedDataset.fromObject(object.publishedDatasets[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a RetrievePublishedDatasetsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.RetrievePublishedDatasetsResponse
         * @static
         * @param {gcg.RetrievePublishedDatasetsResponse} message RetrievePublishedDatasetsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrievePublishedDatasetsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.publishedDatasets = [];
            if (message.publishedDatasets && message.publishedDatasets.length) {
                object.publishedDatasets = [];
                for (let j = 0; j < message.publishedDatasets.length; ++j)
                    object.publishedDatasets[j] = $root.gcg.PublishedDataset.toObject(message.publishedDatasets[j], options);
            }
            return object;
        };

        /**
         * Converts this RetrievePublishedDatasetsResponse to JSON.
         * @function toJSON
         * @memberof gcg.RetrievePublishedDatasetsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrievePublishedDatasetsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrievePublishedDatasetsResponse
         * @function getTypeUrl
         * @memberof gcg.RetrievePublishedDatasetsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrievePublishedDatasetsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.RetrievePublishedDatasetsResponse";
        };

        return RetrievePublishedDatasetsResponse;
    })();

    gcg.RemovePublishedDatasetRequest = (function() {

        /**
         * Properties of a RemovePublishedDatasetRequest.
         * @memberof gcg
         * @interface IRemovePublishedDatasetRequest
         * @property {Uint8Array|null} [dataRoomId] RemovePublishedDatasetRequest dataRoomId
         * @property {string|null} [leafId] RemovePublishedDatasetRequest leafId
         */

        /**
         * Constructs a new RemovePublishedDatasetRequest.
         * @memberof gcg
         * @classdesc Represents a RemovePublishedDatasetRequest.
         * @implements IRemovePublishedDatasetRequest
         * @constructor
         * @param {gcg.IRemovePublishedDatasetRequest=} [properties] Properties to set
         */
        function RemovePublishedDatasetRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RemovePublishedDatasetRequest dataRoomId.
         * @member {Uint8Array} dataRoomId
         * @memberof gcg.RemovePublishedDatasetRequest
         * @instance
         */
        RemovePublishedDatasetRequest.prototype.dataRoomId = $util.newBuffer([]);

        /**
         * RemovePublishedDatasetRequest leafId.
         * @member {string} leafId
         * @memberof gcg.RemovePublishedDatasetRequest
         * @instance
         */
        RemovePublishedDatasetRequest.prototype.leafId = "";

        /**
         * Creates a new RemovePublishedDatasetRequest instance using the specified properties.
         * @function create
         * @memberof gcg.RemovePublishedDatasetRequest
         * @static
         * @param {gcg.IRemovePublishedDatasetRequest=} [properties] Properties to set
         * @returns {gcg.RemovePublishedDatasetRequest} RemovePublishedDatasetRequest instance
         */
        RemovePublishedDatasetRequest.create = function create(properties) {
            return new RemovePublishedDatasetRequest(properties);
        };

        /**
         * Encodes the specified RemovePublishedDatasetRequest message. Does not implicitly {@link gcg.RemovePublishedDatasetRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.RemovePublishedDatasetRequest
         * @static
         * @param {gcg.IRemovePublishedDatasetRequest} message RemovePublishedDatasetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemovePublishedDatasetRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dataRoomId != null && Object.hasOwnProperty.call(message, "dataRoomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dataRoomId);
            if (message.leafId != null && Object.hasOwnProperty.call(message, "leafId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.leafId);
            return writer;
        };

        /**
         * Encodes the specified RemovePublishedDatasetRequest message, length delimited. Does not implicitly {@link gcg.RemovePublishedDatasetRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.RemovePublishedDatasetRequest
         * @static
         * @param {gcg.IRemovePublishedDatasetRequest} message RemovePublishedDatasetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemovePublishedDatasetRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RemovePublishedDatasetRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.RemovePublishedDatasetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.RemovePublishedDatasetRequest} RemovePublishedDatasetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemovePublishedDatasetRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.RemovePublishedDatasetRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dataRoomId = reader.bytes();
                        break;
                    }
                case 2: {
                        message.leafId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RemovePublishedDatasetRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.RemovePublishedDatasetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.RemovePublishedDatasetRequest} RemovePublishedDatasetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemovePublishedDatasetRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RemovePublishedDatasetRequest message.
         * @function verify
         * @memberof gcg.RemovePublishedDatasetRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RemovePublishedDatasetRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                if (!(message.dataRoomId && typeof message.dataRoomId.length === "number" || $util.isString(message.dataRoomId)))
                    return "dataRoomId: buffer expected";
            if (message.leafId != null && message.hasOwnProperty("leafId"))
                if (!$util.isString(message.leafId))
                    return "leafId: string expected";
            return null;
        };

        /**
         * Creates a RemovePublishedDatasetRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.RemovePublishedDatasetRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.RemovePublishedDatasetRequest} RemovePublishedDatasetRequest
         */
        RemovePublishedDatasetRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.RemovePublishedDatasetRequest)
                return object;
            let message = new $root.gcg.RemovePublishedDatasetRequest();
            if (object.dataRoomId != null)
                if (typeof object.dataRoomId === "string")
                    $util.base64.decode(object.dataRoomId, message.dataRoomId = $util.newBuffer($util.base64.length(object.dataRoomId)), 0);
                else if (object.dataRoomId.length >= 0)
                    message.dataRoomId = object.dataRoomId;
            if (object.leafId != null)
                message.leafId = String(object.leafId);
            return message;
        };

        /**
         * Creates a plain object from a RemovePublishedDatasetRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.RemovePublishedDatasetRequest
         * @static
         * @param {gcg.RemovePublishedDatasetRequest} message RemovePublishedDatasetRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RemovePublishedDatasetRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.dataRoomId = "";
                else {
                    object.dataRoomId = [];
                    if (options.bytes !== Array)
                        object.dataRoomId = $util.newBuffer(object.dataRoomId);
                }
                object.leafId = "";
            }
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                object.dataRoomId = options.bytes === String ? $util.base64.encode(message.dataRoomId, 0, message.dataRoomId.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomId) : message.dataRoomId;
            if (message.leafId != null && message.hasOwnProperty("leafId"))
                object.leafId = message.leafId;
            return object;
        };

        /**
         * Converts this RemovePublishedDatasetRequest to JSON.
         * @function toJSON
         * @memberof gcg.RemovePublishedDatasetRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RemovePublishedDatasetRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RemovePublishedDatasetRequest
         * @function getTypeUrl
         * @memberof gcg.RemovePublishedDatasetRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RemovePublishedDatasetRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.RemovePublishedDatasetRequest";
        };

        return RemovePublishedDatasetRequest;
    })();

    gcg.RemovePublishedDatasetResponse = (function() {

        /**
         * Properties of a RemovePublishedDatasetResponse.
         * @memberof gcg
         * @interface IRemovePublishedDatasetResponse
         */

        /**
         * Constructs a new RemovePublishedDatasetResponse.
         * @memberof gcg
         * @classdesc Represents a RemovePublishedDatasetResponse.
         * @implements IRemovePublishedDatasetResponse
         * @constructor
         * @param {gcg.IRemovePublishedDatasetResponse=} [properties] Properties to set
         */
        function RemovePublishedDatasetResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RemovePublishedDatasetResponse instance using the specified properties.
         * @function create
         * @memberof gcg.RemovePublishedDatasetResponse
         * @static
         * @param {gcg.IRemovePublishedDatasetResponse=} [properties] Properties to set
         * @returns {gcg.RemovePublishedDatasetResponse} RemovePublishedDatasetResponse instance
         */
        RemovePublishedDatasetResponse.create = function create(properties) {
            return new RemovePublishedDatasetResponse(properties);
        };

        /**
         * Encodes the specified RemovePublishedDatasetResponse message. Does not implicitly {@link gcg.RemovePublishedDatasetResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.RemovePublishedDatasetResponse
         * @static
         * @param {gcg.IRemovePublishedDatasetResponse} message RemovePublishedDatasetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemovePublishedDatasetResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RemovePublishedDatasetResponse message, length delimited. Does not implicitly {@link gcg.RemovePublishedDatasetResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.RemovePublishedDatasetResponse
         * @static
         * @param {gcg.IRemovePublishedDatasetResponse} message RemovePublishedDatasetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemovePublishedDatasetResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RemovePublishedDatasetResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.RemovePublishedDatasetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.RemovePublishedDatasetResponse} RemovePublishedDatasetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemovePublishedDatasetResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.RemovePublishedDatasetResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RemovePublishedDatasetResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.RemovePublishedDatasetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.RemovePublishedDatasetResponse} RemovePublishedDatasetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemovePublishedDatasetResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RemovePublishedDatasetResponse message.
         * @function verify
         * @memberof gcg.RemovePublishedDatasetResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RemovePublishedDatasetResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RemovePublishedDatasetResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.RemovePublishedDatasetResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.RemovePublishedDatasetResponse} RemovePublishedDatasetResponse
         */
        RemovePublishedDatasetResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.RemovePublishedDatasetResponse)
                return object;
            return new $root.gcg.RemovePublishedDatasetResponse();
        };

        /**
         * Creates a plain object from a RemovePublishedDatasetResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.RemovePublishedDatasetResponse
         * @static
         * @param {gcg.RemovePublishedDatasetResponse} message RemovePublishedDatasetResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RemovePublishedDatasetResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RemovePublishedDatasetResponse to JSON.
         * @function toJSON
         * @memberof gcg.RemovePublishedDatasetResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RemovePublishedDatasetResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RemovePublishedDatasetResponse
         * @function getTypeUrl
         * @memberof gcg.RemovePublishedDatasetResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RemovePublishedDatasetResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.RemovePublishedDatasetResponse";
        };

        return RemovePublishedDatasetResponse;
    })();

    gcg.CreateConfigurationCommitRequest = (function() {

        /**
         * Properties of a CreateConfigurationCommitRequest.
         * @memberof gcg
         * @interface ICreateConfigurationCommitRequest
         * @property {data_room.IConfigurationCommit|null} [commit] CreateConfigurationCommitRequest commit
         * @property {Uint8Array|null} [highLevelRepresentation] CreateConfigurationCommitRequest highLevelRepresentation
         */

        /**
         * Constructs a new CreateConfigurationCommitRequest.
         * @memberof gcg
         * @classdesc Represents a CreateConfigurationCommitRequest.
         * @implements ICreateConfigurationCommitRequest
         * @constructor
         * @param {gcg.ICreateConfigurationCommitRequest=} [properties] Properties to set
         */
        function CreateConfigurationCommitRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateConfigurationCommitRequest commit.
         * @member {data_room.IConfigurationCommit|null|undefined} commit
         * @memberof gcg.CreateConfigurationCommitRequest
         * @instance
         */
        CreateConfigurationCommitRequest.prototype.commit = null;

        /**
         * CreateConfigurationCommitRequest highLevelRepresentation.
         * @member {Uint8Array|null|undefined} highLevelRepresentation
         * @memberof gcg.CreateConfigurationCommitRequest
         * @instance
         */
        CreateConfigurationCommitRequest.prototype.highLevelRepresentation = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * CreateConfigurationCommitRequest _highLevelRepresentation.
         * @member {"highLevelRepresentation"|undefined} _highLevelRepresentation
         * @memberof gcg.CreateConfigurationCommitRequest
         * @instance
         */
        Object.defineProperty(CreateConfigurationCommitRequest.prototype, "_highLevelRepresentation", {
            get: $util.oneOfGetter($oneOfFields = ["highLevelRepresentation"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CreateConfigurationCommitRequest instance using the specified properties.
         * @function create
         * @memberof gcg.CreateConfigurationCommitRequest
         * @static
         * @param {gcg.ICreateConfigurationCommitRequest=} [properties] Properties to set
         * @returns {gcg.CreateConfigurationCommitRequest} CreateConfigurationCommitRequest instance
         */
        CreateConfigurationCommitRequest.create = function create(properties) {
            return new CreateConfigurationCommitRequest(properties);
        };

        /**
         * Encodes the specified CreateConfigurationCommitRequest message. Does not implicitly {@link gcg.CreateConfigurationCommitRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.CreateConfigurationCommitRequest
         * @static
         * @param {gcg.ICreateConfigurationCommitRequest} message CreateConfigurationCommitRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateConfigurationCommitRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commit != null && Object.hasOwnProperty.call(message, "commit"))
                $root.data_room.ConfigurationCommit.encode(message.commit, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.highLevelRepresentation != null && Object.hasOwnProperty.call(message, "highLevelRepresentation"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.highLevelRepresentation);
            return writer;
        };

        /**
         * Encodes the specified CreateConfigurationCommitRequest message, length delimited. Does not implicitly {@link gcg.CreateConfigurationCommitRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.CreateConfigurationCommitRequest
         * @static
         * @param {gcg.ICreateConfigurationCommitRequest} message CreateConfigurationCommitRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateConfigurationCommitRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateConfigurationCommitRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.CreateConfigurationCommitRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.CreateConfigurationCommitRequest} CreateConfigurationCommitRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateConfigurationCommitRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.CreateConfigurationCommitRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.commit = $root.data_room.ConfigurationCommit.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.highLevelRepresentation = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateConfigurationCommitRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.CreateConfigurationCommitRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.CreateConfigurationCommitRequest} CreateConfigurationCommitRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateConfigurationCommitRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateConfigurationCommitRequest message.
         * @function verify
         * @memberof gcg.CreateConfigurationCommitRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateConfigurationCommitRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.commit != null && message.hasOwnProperty("commit")) {
                let error = $root.data_room.ConfigurationCommit.verify(message.commit);
                if (error)
                    return "commit." + error;
            }
            if (message.highLevelRepresentation != null && message.hasOwnProperty("highLevelRepresentation")) {
                properties._highLevelRepresentation = 1;
                if (!(message.highLevelRepresentation && typeof message.highLevelRepresentation.length === "number" || $util.isString(message.highLevelRepresentation)))
                    return "highLevelRepresentation: buffer expected";
            }
            return null;
        };

        /**
         * Creates a CreateConfigurationCommitRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.CreateConfigurationCommitRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.CreateConfigurationCommitRequest} CreateConfigurationCommitRequest
         */
        CreateConfigurationCommitRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.CreateConfigurationCommitRequest)
                return object;
            let message = new $root.gcg.CreateConfigurationCommitRequest();
            if (object.commit != null) {
                if (typeof object.commit !== "object")
                    throw TypeError(".gcg.CreateConfigurationCommitRequest.commit: object expected");
                message.commit = $root.data_room.ConfigurationCommit.fromObject(object.commit);
            }
            if (object.highLevelRepresentation != null)
                if (typeof object.highLevelRepresentation === "string")
                    $util.base64.decode(object.highLevelRepresentation, message.highLevelRepresentation = $util.newBuffer($util.base64.length(object.highLevelRepresentation)), 0);
                else if (object.highLevelRepresentation.length >= 0)
                    message.highLevelRepresentation = object.highLevelRepresentation;
            return message;
        };

        /**
         * Creates a plain object from a CreateConfigurationCommitRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.CreateConfigurationCommitRequest
         * @static
         * @param {gcg.CreateConfigurationCommitRequest} message CreateConfigurationCommitRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateConfigurationCommitRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.commit = null;
            if (message.commit != null && message.hasOwnProperty("commit"))
                object.commit = $root.data_room.ConfigurationCommit.toObject(message.commit, options);
            if (message.highLevelRepresentation != null && message.hasOwnProperty("highLevelRepresentation")) {
                object.highLevelRepresentation = options.bytes === String ? $util.base64.encode(message.highLevelRepresentation, 0, message.highLevelRepresentation.length) : options.bytes === Array ? Array.prototype.slice.call(message.highLevelRepresentation) : message.highLevelRepresentation;
                if (options.oneofs)
                    object._highLevelRepresentation = "highLevelRepresentation";
            }
            return object;
        };

        /**
         * Converts this CreateConfigurationCommitRequest to JSON.
         * @function toJSON
         * @memberof gcg.CreateConfigurationCommitRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateConfigurationCommitRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateConfigurationCommitRequest
         * @function getTypeUrl
         * @memberof gcg.CreateConfigurationCommitRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateConfigurationCommitRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.CreateConfigurationCommitRequest";
        };

        return CreateConfigurationCommitRequest;
    })();

    gcg.CreateConfigurationCommitResponse = (function() {

        /**
         * Properties of a CreateConfigurationCommitResponse.
         * @memberof gcg
         * @interface ICreateConfigurationCommitResponse
         * @property {Uint8Array|null} [commitId] CreateConfigurationCommitResponse commitId
         */

        /**
         * Constructs a new CreateConfigurationCommitResponse.
         * @memberof gcg
         * @classdesc Represents a CreateConfigurationCommitResponse.
         * @implements ICreateConfigurationCommitResponse
         * @constructor
         * @param {gcg.ICreateConfigurationCommitResponse=} [properties] Properties to set
         */
        function CreateConfigurationCommitResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateConfigurationCommitResponse commitId.
         * @member {Uint8Array} commitId
         * @memberof gcg.CreateConfigurationCommitResponse
         * @instance
         */
        CreateConfigurationCommitResponse.prototype.commitId = $util.newBuffer([]);

        /**
         * Creates a new CreateConfigurationCommitResponse instance using the specified properties.
         * @function create
         * @memberof gcg.CreateConfigurationCommitResponse
         * @static
         * @param {gcg.ICreateConfigurationCommitResponse=} [properties] Properties to set
         * @returns {gcg.CreateConfigurationCommitResponse} CreateConfigurationCommitResponse instance
         */
        CreateConfigurationCommitResponse.create = function create(properties) {
            return new CreateConfigurationCommitResponse(properties);
        };

        /**
         * Encodes the specified CreateConfigurationCommitResponse message. Does not implicitly {@link gcg.CreateConfigurationCommitResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.CreateConfigurationCommitResponse
         * @static
         * @param {gcg.ICreateConfigurationCommitResponse} message CreateConfigurationCommitResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateConfigurationCommitResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commitId != null && Object.hasOwnProperty.call(message, "commitId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.commitId);
            return writer;
        };

        /**
         * Encodes the specified CreateConfigurationCommitResponse message, length delimited. Does not implicitly {@link gcg.CreateConfigurationCommitResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.CreateConfigurationCommitResponse
         * @static
         * @param {gcg.ICreateConfigurationCommitResponse} message CreateConfigurationCommitResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateConfigurationCommitResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateConfigurationCommitResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.CreateConfigurationCommitResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.CreateConfigurationCommitResponse} CreateConfigurationCommitResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateConfigurationCommitResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.CreateConfigurationCommitResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.commitId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateConfigurationCommitResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.CreateConfigurationCommitResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.CreateConfigurationCommitResponse} CreateConfigurationCommitResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateConfigurationCommitResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateConfigurationCommitResponse message.
         * @function verify
         * @memberof gcg.CreateConfigurationCommitResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateConfigurationCommitResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commitId != null && message.hasOwnProperty("commitId"))
                if (!(message.commitId && typeof message.commitId.length === "number" || $util.isString(message.commitId)))
                    return "commitId: buffer expected";
            return null;
        };

        /**
         * Creates a CreateConfigurationCommitResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.CreateConfigurationCommitResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.CreateConfigurationCommitResponse} CreateConfigurationCommitResponse
         */
        CreateConfigurationCommitResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.CreateConfigurationCommitResponse)
                return object;
            let message = new $root.gcg.CreateConfigurationCommitResponse();
            if (object.commitId != null)
                if (typeof object.commitId === "string")
                    $util.base64.decode(object.commitId, message.commitId = $util.newBuffer($util.base64.length(object.commitId)), 0);
                else if (object.commitId.length >= 0)
                    message.commitId = object.commitId;
            return message;
        };

        /**
         * Creates a plain object from a CreateConfigurationCommitResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.CreateConfigurationCommitResponse
         * @static
         * @param {gcg.CreateConfigurationCommitResponse} message CreateConfigurationCommitResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateConfigurationCommitResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.commitId = "";
                else {
                    object.commitId = [];
                    if (options.bytes !== Array)
                        object.commitId = $util.newBuffer(object.commitId);
                }
            if (message.commitId != null && message.hasOwnProperty("commitId"))
                object.commitId = options.bytes === String ? $util.base64.encode(message.commitId, 0, message.commitId.length) : options.bytes === Array ? Array.prototype.slice.call(message.commitId) : message.commitId;
            return object;
        };

        /**
         * Converts this CreateConfigurationCommitResponse to JSON.
         * @function toJSON
         * @memberof gcg.CreateConfigurationCommitResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateConfigurationCommitResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateConfigurationCommitResponse
         * @function getTypeUrl
         * @memberof gcg.CreateConfigurationCommitResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateConfigurationCommitResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.CreateConfigurationCommitResponse";
        };

        return CreateConfigurationCommitResponse;
    })();

    gcg.GenerateMergeApprovalSignatureRequest = (function() {

        /**
         * Properties of a GenerateMergeApprovalSignatureRequest.
         * @memberof gcg
         * @interface IGenerateMergeApprovalSignatureRequest
         * @property {Uint8Array|null} [commitId] GenerateMergeApprovalSignatureRequest commitId
         */

        /**
         * Constructs a new GenerateMergeApprovalSignatureRequest.
         * @memberof gcg
         * @classdesc Represents a GenerateMergeApprovalSignatureRequest.
         * @implements IGenerateMergeApprovalSignatureRequest
         * @constructor
         * @param {gcg.IGenerateMergeApprovalSignatureRequest=} [properties] Properties to set
         */
        function GenerateMergeApprovalSignatureRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GenerateMergeApprovalSignatureRequest commitId.
         * @member {Uint8Array} commitId
         * @memberof gcg.GenerateMergeApprovalSignatureRequest
         * @instance
         */
        GenerateMergeApprovalSignatureRequest.prototype.commitId = $util.newBuffer([]);

        /**
         * Creates a new GenerateMergeApprovalSignatureRequest instance using the specified properties.
         * @function create
         * @memberof gcg.GenerateMergeApprovalSignatureRequest
         * @static
         * @param {gcg.IGenerateMergeApprovalSignatureRequest=} [properties] Properties to set
         * @returns {gcg.GenerateMergeApprovalSignatureRequest} GenerateMergeApprovalSignatureRequest instance
         */
        GenerateMergeApprovalSignatureRequest.create = function create(properties) {
            return new GenerateMergeApprovalSignatureRequest(properties);
        };

        /**
         * Encodes the specified GenerateMergeApprovalSignatureRequest message. Does not implicitly {@link gcg.GenerateMergeApprovalSignatureRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.GenerateMergeApprovalSignatureRequest
         * @static
         * @param {gcg.IGenerateMergeApprovalSignatureRequest} message GenerateMergeApprovalSignatureRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateMergeApprovalSignatureRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commitId != null && Object.hasOwnProperty.call(message, "commitId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.commitId);
            return writer;
        };

        /**
         * Encodes the specified GenerateMergeApprovalSignatureRequest message, length delimited. Does not implicitly {@link gcg.GenerateMergeApprovalSignatureRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.GenerateMergeApprovalSignatureRequest
         * @static
         * @param {gcg.IGenerateMergeApprovalSignatureRequest} message GenerateMergeApprovalSignatureRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateMergeApprovalSignatureRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GenerateMergeApprovalSignatureRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.GenerateMergeApprovalSignatureRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.GenerateMergeApprovalSignatureRequest} GenerateMergeApprovalSignatureRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateMergeApprovalSignatureRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.GenerateMergeApprovalSignatureRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.commitId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GenerateMergeApprovalSignatureRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.GenerateMergeApprovalSignatureRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.GenerateMergeApprovalSignatureRequest} GenerateMergeApprovalSignatureRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateMergeApprovalSignatureRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GenerateMergeApprovalSignatureRequest message.
         * @function verify
         * @memberof gcg.GenerateMergeApprovalSignatureRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GenerateMergeApprovalSignatureRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commitId != null && message.hasOwnProperty("commitId"))
                if (!(message.commitId && typeof message.commitId.length === "number" || $util.isString(message.commitId)))
                    return "commitId: buffer expected";
            return null;
        };

        /**
         * Creates a GenerateMergeApprovalSignatureRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.GenerateMergeApprovalSignatureRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.GenerateMergeApprovalSignatureRequest} GenerateMergeApprovalSignatureRequest
         */
        GenerateMergeApprovalSignatureRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.GenerateMergeApprovalSignatureRequest)
                return object;
            let message = new $root.gcg.GenerateMergeApprovalSignatureRequest();
            if (object.commitId != null)
                if (typeof object.commitId === "string")
                    $util.base64.decode(object.commitId, message.commitId = $util.newBuffer($util.base64.length(object.commitId)), 0);
                else if (object.commitId.length >= 0)
                    message.commitId = object.commitId;
            return message;
        };

        /**
         * Creates a plain object from a GenerateMergeApprovalSignatureRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.GenerateMergeApprovalSignatureRequest
         * @static
         * @param {gcg.GenerateMergeApprovalSignatureRequest} message GenerateMergeApprovalSignatureRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GenerateMergeApprovalSignatureRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.commitId = "";
                else {
                    object.commitId = [];
                    if (options.bytes !== Array)
                        object.commitId = $util.newBuffer(object.commitId);
                }
            if (message.commitId != null && message.hasOwnProperty("commitId"))
                object.commitId = options.bytes === String ? $util.base64.encode(message.commitId, 0, message.commitId.length) : options.bytes === Array ? Array.prototype.slice.call(message.commitId) : message.commitId;
            return object;
        };

        /**
         * Converts this GenerateMergeApprovalSignatureRequest to JSON.
         * @function toJSON
         * @memberof gcg.GenerateMergeApprovalSignatureRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GenerateMergeApprovalSignatureRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GenerateMergeApprovalSignatureRequest
         * @function getTypeUrl
         * @memberof gcg.GenerateMergeApprovalSignatureRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GenerateMergeApprovalSignatureRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.GenerateMergeApprovalSignatureRequest";
        };

        return GenerateMergeApprovalSignatureRequest;
    })();

    gcg.GenerateMergeApprovalSignatureResponse = (function() {

        /**
         * Properties of a GenerateMergeApprovalSignatureResponse.
         * @memberof gcg
         * @interface IGenerateMergeApprovalSignatureResponse
         * @property {Uint8Array|null} [signature] GenerateMergeApprovalSignatureResponse signature
         */

        /**
         * Constructs a new GenerateMergeApprovalSignatureResponse.
         * @memberof gcg
         * @classdesc Represents a GenerateMergeApprovalSignatureResponse.
         * @implements IGenerateMergeApprovalSignatureResponse
         * @constructor
         * @param {gcg.IGenerateMergeApprovalSignatureResponse=} [properties] Properties to set
         */
        function GenerateMergeApprovalSignatureResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GenerateMergeApprovalSignatureResponse signature.
         * @member {Uint8Array} signature
         * @memberof gcg.GenerateMergeApprovalSignatureResponse
         * @instance
         */
        GenerateMergeApprovalSignatureResponse.prototype.signature = $util.newBuffer([]);

        /**
         * Creates a new GenerateMergeApprovalSignatureResponse instance using the specified properties.
         * @function create
         * @memberof gcg.GenerateMergeApprovalSignatureResponse
         * @static
         * @param {gcg.IGenerateMergeApprovalSignatureResponse=} [properties] Properties to set
         * @returns {gcg.GenerateMergeApprovalSignatureResponse} GenerateMergeApprovalSignatureResponse instance
         */
        GenerateMergeApprovalSignatureResponse.create = function create(properties) {
            return new GenerateMergeApprovalSignatureResponse(properties);
        };

        /**
         * Encodes the specified GenerateMergeApprovalSignatureResponse message. Does not implicitly {@link gcg.GenerateMergeApprovalSignatureResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.GenerateMergeApprovalSignatureResponse
         * @static
         * @param {gcg.IGenerateMergeApprovalSignatureResponse} message GenerateMergeApprovalSignatureResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateMergeApprovalSignatureResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.signature != null && Object.hasOwnProperty.call(message, "signature"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.signature);
            return writer;
        };

        /**
         * Encodes the specified GenerateMergeApprovalSignatureResponse message, length delimited. Does not implicitly {@link gcg.GenerateMergeApprovalSignatureResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.GenerateMergeApprovalSignatureResponse
         * @static
         * @param {gcg.IGenerateMergeApprovalSignatureResponse} message GenerateMergeApprovalSignatureResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateMergeApprovalSignatureResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GenerateMergeApprovalSignatureResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.GenerateMergeApprovalSignatureResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.GenerateMergeApprovalSignatureResponse} GenerateMergeApprovalSignatureResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateMergeApprovalSignatureResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.GenerateMergeApprovalSignatureResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.signature = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GenerateMergeApprovalSignatureResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.GenerateMergeApprovalSignatureResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.GenerateMergeApprovalSignatureResponse} GenerateMergeApprovalSignatureResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateMergeApprovalSignatureResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GenerateMergeApprovalSignatureResponse message.
         * @function verify
         * @memberof gcg.GenerateMergeApprovalSignatureResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GenerateMergeApprovalSignatureResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.signature != null && message.hasOwnProperty("signature"))
                if (!(message.signature && typeof message.signature.length === "number" || $util.isString(message.signature)))
                    return "signature: buffer expected";
            return null;
        };

        /**
         * Creates a GenerateMergeApprovalSignatureResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.GenerateMergeApprovalSignatureResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.GenerateMergeApprovalSignatureResponse} GenerateMergeApprovalSignatureResponse
         */
        GenerateMergeApprovalSignatureResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.GenerateMergeApprovalSignatureResponse)
                return object;
            let message = new $root.gcg.GenerateMergeApprovalSignatureResponse();
            if (object.signature != null)
                if (typeof object.signature === "string")
                    $util.base64.decode(object.signature, message.signature = $util.newBuffer($util.base64.length(object.signature)), 0);
                else if (object.signature.length >= 0)
                    message.signature = object.signature;
            return message;
        };

        /**
         * Creates a plain object from a GenerateMergeApprovalSignatureResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.GenerateMergeApprovalSignatureResponse
         * @static
         * @param {gcg.GenerateMergeApprovalSignatureResponse} message GenerateMergeApprovalSignatureResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GenerateMergeApprovalSignatureResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.signature = "";
                else {
                    object.signature = [];
                    if (options.bytes !== Array)
                        object.signature = $util.newBuffer(object.signature);
                }
            if (message.signature != null && message.hasOwnProperty("signature"))
                object.signature = options.bytes === String ? $util.base64.encode(message.signature, 0, message.signature.length) : options.bytes === Array ? Array.prototype.slice.call(message.signature) : message.signature;
            return object;
        };

        /**
         * Converts this GenerateMergeApprovalSignatureResponse to JSON.
         * @function toJSON
         * @memberof gcg.GenerateMergeApprovalSignatureResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GenerateMergeApprovalSignatureResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GenerateMergeApprovalSignatureResponse
         * @function getTypeUrl
         * @memberof gcg.GenerateMergeApprovalSignatureResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GenerateMergeApprovalSignatureResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.GenerateMergeApprovalSignatureResponse";
        };

        return GenerateMergeApprovalSignatureResponse;
    })();

    gcg.MergeConfigurationCommitRequest = (function() {

        /**
         * Properties of a MergeConfigurationCommitRequest.
         * @memberof gcg
         * @interface IMergeConfigurationCommitRequest
         * @property {Uint8Array|null} [commitId] MergeConfigurationCommitRequest commitId
         * @property {Object.<string,Uint8Array>|null} [approvalSignatures] MergeConfigurationCommitRequest approvalSignatures
         * @property {Uint8Array|null} [newDataRoomHighLevelRepresentation] MergeConfigurationCommitRequest newDataRoomHighLevelRepresentation
         */

        /**
         * Constructs a new MergeConfigurationCommitRequest.
         * @memberof gcg
         * @classdesc Represents a MergeConfigurationCommitRequest.
         * @implements IMergeConfigurationCommitRequest
         * @constructor
         * @param {gcg.IMergeConfigurationCommitRequest=} [properties] Properties to set
         */
        function MergeConfigurationCommitRequest(properties) {
            this.approvalSignatures = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MergeConfigurationCommitRequest commitId.
         * @member {Uint8Array} commitId
         * @memberof gcg.MergeConfigurationCommitRequest
         * @instance
         */
        MergeConfigurationCommitRequest.prototype.commitId = $util.newBuffer([]);

        /**
         * MergeConfigurationCommitRequest approvalSignatures.
         * @member {Object.<string,Uint8Array>} approvalSignatures
         * @memberof gcg.MergeConfigurationCommitRequest
         * @instance
         */
        MergeConfigurationCommitRequest.prototype.approvalSignatures = $util.emptyObject;

        /**
         * MergeConfigurationCommitRequest newDataRoomHighLevelRepresentation.
         * @member {Uint8Array|null|undefined} newDataRoomHighLevelRepresentation
         * @memberof gcg.MergeConfigurationCommitRequest
         * @instance
         */
        MergeConfigurationCommitRequest.prototype.newDataRoomHighLevelRepresentation = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MergeConfigurationCommitRequest _newDataRoomHighLevelRepresentation.
         * @member {"newDataRoomHighLevelRepresentation"|undefined} _newDataRoomHighLevelRepresentation
         * @memberof gcg.MergeConfigurationCommitRequest
         * @instance
         */
        Object.defineProperty(MergeConfigurationCommitRequest.prototype, "_newDataRoomHighLevelRepresentation", {
            get: $util.oneOfGetter($oneOfFields = ["newDataRoomHighLevelRepresentation"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MergeConfigurationCommitRequest instance using the specified properties.
         * @function create
         * @memberof gcg.MergeConfigurationCommitRequest
         * @static
         * @param {gcg.IMergeConfigurationCommitRequest=} [properties] Properties to set
         * @returns {gcg.MergeConfigurationCommitRequest} MergeConfigurationCommitRequest instance
         */
        MergeConfigurationCommitRequest.create = function create(properties) {
            return new MergeConfigurationCommitRequest(properties);
        };

        /**
         * Encodes the specified MergeConfigurationCommitRequest message. Does not implicitly {@link gcg.MergeConfigurationCommitRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.MergeConfigurationCommitRequest
         * @static
         * @param {gcg.IMergeConfigurationCommitRequest} message MergeConfigurationCommitRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MergeConfigurationCommitRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commitId != null && Object.hasOwnProperty.call(message, "commitId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.commitId);
            if (message.approvalSignatures != null && Object.hasOwnProperty.call(message, "approvalSignatures"))
                for (let keys = Object.keys(message.approvalSignatures), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).bytes(message.approvalSignatures[keys[i]]).ldelim();
            if (message.newDataRoomHighLevelRepresentation != null && Object.hasOwnProperty.call(message, "newDataRoomHighLevelRepresentation"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.newDataRoomHighLevelRepresentation);
            return writer;
        };

        /**
         * Encodes the specified MergeConfigurationCommitRequest message, length delimited. Does not implicitly {@link gcg.MergeConfigurationCommitRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.MergeConfigurationCommitRequest
         * @static
         * @param {gcg.IMergeConfigurationCommitRequest} message MergeConfigurationCommitRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MergeConfigurationCommitRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MergeConfigurationCommitRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.MergeConfigurationCommitRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.MergeConfigurationCommitRequest} MergeConfigurationCommitRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MergeConfigurationCommitRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.MergeConfigurationCommitRequest(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.commitId = reader.bytes();
                        break;
                    }
                case 2: {
                        if (message.approvalSignatures === $util.emptyObject)
                            message.approvalSignatures = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = [];
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.bytes();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.approvalSignatures[key] = value;
                        break;
                    }
                case 3: {
                        message.newDataRoomHighLevelRepresentation = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MergeConfigurationCommitRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.MergeConfigurationCommitRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.MergeConfigurationCommitRequest} MergeConfigurationCommitRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MergeConfigurationCommitRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MergeConfigurationCommitRequest message.
         * @function verify
         * @memberof gcg.MergeConfigurationCommitRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MergeConfigurationCommitRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.commitId != null && message.hasOwnProperty("commitId"))
                if (!(message.commitId && typeof message.commitId.length === "number" || $util.isString(message.commitId)))
                    return "commitId: buffer expected";
            if (message.approvalSignatures != null && message.hasOwnProperty("approvalSignatures")) {
                if (!$util.isObject(message.approvalSignatures))
                    return "approvalSignatures: object expected";
                let key = Object.keys(message.approvalSignatures);
                for (let i = 0; i < key.length; ++i)
                    if (!(message.approvalSignatures[key[i]] && typeof message.approvalSignatures[key[i]].length === "number" || $util.isString(message.approvalSignatures[key[i]])))
                        return "approvalSignatures: buffer{k:string} expected";
            }
            if (message.newDataRoomHighLevelRepresentation != null && message.hasOwnProperty("newDataRoomHighLevelRepresentation")) {
                properties._newDataRoomHighLevelRepresentation = 1;
                if (!(message.newDataRoomHighLevelRepresentation && typeof message.newDataRoomHighLevelRepresentation.length === "number" || $util.isString(message.newDataRoomHighLevelRepresentation)))
                    return "newDataRoomHighLevelRepresentation: buffer expected";
            }
            return null;
        };

        /**
         * Creates a MergeConfigurationCommitRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.MergeConfigurationCommitRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.MergeConfigurationCommitRequest} MergeConfigurationCommitRequest
         */
        MergeConfigurationCommitRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.MergeConfigurationCommitRequest)
                return object;
            let message = new $root.gcg.MergeConfigurationCommitRequest();
            if (object.commitId != null)
                if (typeof object.commitId === "string")
                    $util.base64.decode(object.commitId, message.commitId = $util.newBuffer($util.base64.length(object.commitId)), 0);
                else if (object.commitId.length >= 0)
                    message.commitId = object.commitId;
            if (object.approvalSignatures) {
                if (typeof object.approvalSignatures !== "object")
                    throw TypeError(".gcg.MergeConfigurationCommitRequest.approvalSignatures: object expected");
                message.approvalSignatures = {};
                for (let keys = Object.keys(object.approvalSignatures), i = 0; i < keys.length; ++i)
                    if (typeof object.approvalSignatures[keys[i]] === "string")
                        $util.base64.decode(object.approvalSignatures[keys[i]], message.approvalSignatures[keys[i]] = $util.newBuffer($util.base64.length(object.approvalSignatures[keys[i]])), 0);
                    else if (object.approvalSignatures[keys[i]].length >= 0)
                        message.approvalSignatures[keys[i]] = object.approvalSignatures[keys[i]];
            }
            if (object.newDataRoomHighLevelRepresentation != null)
                if (typeof object.newDataRoomHighLevelRepresentation === "string")
                    $util.base64.decode(object.newDataRoomHighLevelRepresentation, message.newDataRoomHighLevelRepresentation = $util.newBuffer($util.base64.length(object.newDataRoomHighLevelRepresentation)), 0);
                else if (object.newDataRoomHighLevelRepresentation.length >= 0)
                    message.newDataRoomHighLevelRepresentation = object.newDataRoomHighLevelRepresentation;
            return message;
        };

        /**
         * Creates a plain object from a MergeConfigurationCommitRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.MergeConfigurationCommitRequest
         * @static
         * @param {gcg.MergeConfigurationCommitRequest} message MergeConfigurationCommitRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MergeConfigurationCommitRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.approvalSignatures = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.commitId = "";
                else {
                    object.commitId = [];
                    if (options.bytes !== Array)
                        object.commitId = $util.newBuffer(object.commitId);
                }
            if (message.commitId != null && message.hasOwnProperty("commitId"))
                object.commitId = options.bytes === String ? $util.base64.encode(message.commitId, 0, message.commitId.length) : options.bytes === Array ? Array.prototype.slice.call(message.commitId) : message.commitId;
            let keys2;
            if (message.approvalSignatures && (keys2 = Object.keys(message.approvalSignatures)).length) {
                object.approvalSignatures = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.approvalSignatures[keys2[j]] = options.bytes === String ? $util.base64.encode(message.approvalSignatures[keys2[j]], 0, message.approvalSignatures[keys2[j]].length) : options.bytes === Array ? Array.prototype.slice.call(message.approvalSignatures[keys2[j]]) : message.approvalSignatures[keys2[j]];
            }
            if (message.newDataRoomHighLevelRepresentation != null && message.hasOwnProperty("newDataRoomHighLevelRepresentation")) {
                object.newDataRoomHighLevelRepresentation = options.bytes === String ? $util.base64.encode(message.newDataRoomHighLevelRepresentation, 0, message.newDataRoomHighLevelRepresentation.length) : options.bytes === Array ? Array.prototype.slice.call(message.newDataRoomHighLevelRepresentation) : message.newDataRoomHighLevelRepresentation;
                if (options.oneofs)
                    object._newDataRoomHighLevelRepresentation = "newDataRoomHighLevelRepresentation";
            }
            return object;
        };

        /**
         * Converts this MergeConfigurationCommitRequest to JSON.
         * @function toJSON
         * @memberof gcg.MergeConfigurationCommitRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MergeConfigurationCommitRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MergeConfigurationCommitRequest
         * @function getTypeUrl
         * @memberof gcg.MergeConfigurationCommitRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MergeConfigurationCommitRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.MergeConfigurationCommitRequest";
        };

        return MergeConfigurationCommitRequest;
    })();

    gcg.RetrieveCurrentDataRoomConfigurationRequest = (function() {

        /**
         * Properties of a RetrieveCurrentDataRoomConfigurationRequest.
         * @memberof gcg
         * @interface IRetrieveCurrentDataRoomConfigurationRequest
         * @property {Uint8Array|null} [dataRoomId] RetrieveCurrentDataRoomConfigurationRequest dataRoomId
         */

        /**
         * Constructs a new RetrieveCurrentDataRoomConfigurationRequest.
         * @memberof gcg
         * @classdesc Represents a RetrieveCurrentDataRoomConfigurationRequest.
         * @implements IRetrieveCurrentDataRoomConfigurationRequest
         * @constructor
         * @param {gcg.IRetrieveCurrentDataRoomConfigurationRequest=} [properties] Properties to set
         */
        function RetrieveCurrentDataRoomConfigurationRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RetrieveCurrentDataRoomConfigurationRequest dataRoomId.
         * @member {Uint8Array} dataRoomId
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationRequest
         * @instance
         */
        RetrieveCurrentDataRoomConfigurationRequest.prototype.dataRoomId = $util.newBuffer([]);

        /**
         * Creates a new RetrieveCurrentDataRoomConfigurationRequest instance using the specified properties.
         * @function create
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationRequest
         * @static
         * @param {gcg.IRetrieveCurrentDataRoomConfigurationRequest=} [properties] Properties to set
         * @returns {gcg.RetrieveCurrentDataRoomConfigurationRequest} RetrieveCurrentDataRoomConfigurationRequest instance
         */
        RetrieveCurrentDataRoomConfigurationRequest.create = function create(properties) {
            return new RetrieveCurrentDataRoomConfigurationRequest(properties);
        };

        /**
         * Encodes the specified RetrieveCurrentDataRoomConfigurationRequest message. Does not implicitly {@link gcg.RetrieveCurrentDataRoomConfigurationRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationRequest
         * @static
         * @param {gcg.IRetrieveCurrentDataRoomConfigurationRequest} message RetrieveCurrentDataRoomConfigurationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveCurrentDataRoomConfigurationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dataRoomId != null && Object.hasOwnProperty.call(message, "dataRoomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dataRoomId);
            return writer;
        };

        /**
         * Encodes the specified RetrieveCurrentDataRoomConfigurationRequest message, length delimited. Does not implicitly {@link gcg.RetrieveCurrentDataRoomConfigurationRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationRequest
         * @static
         * @param {gcg.IRetrieveCurrentDataRoomConfigurationRequest} message RetrieveCurrentDataRoomConfigurationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveCurrentDataRoomConfigurationRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrieveCurrentDataRoomConfigurationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.RetrieveCurrentDataRoomConfigurationRequest} RetrieveCurrentDataRoomConfigurationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveCurrentDataRoomConfigurationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.RetrieveCurrentDataRoomConfigurationRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dataRoomId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrieveCurrentDataRoomConfigurationRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.RetrieveCurrentDataRoomConfigurationRequest} RetrieveCurrentDataRoomConfigurationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveCurrentDataRoomConfigurationRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrieveCurrentDataRoomConfigurationRequest message.
         * @function verify
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrieveCurrentDataRoomConfigurationRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                if (!(message.dataRoomId && typeof message.dataRoomId.length === "number" || $util.isString(message.dataRoomId)))
                    return "dataRoomId: buffer expected";
            return null;
        };

        /**
         * Creates a RetrieveCurrentDataRoomConfigurationRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.RetrieveCurrentDataRoomConfigurationRequest} RetrieveCurrentDataRoomConfigurationRequest
         */
        RetrieveCurrentDataRoomConfigurationRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.RetrieveCurrentDataRoomConfigurationRequest)
                return object;
            let message = new $root.gcg.RetrieveCurrentDataRoomConfigurationRequest();
            if (object.dataRoomId != null)
                if (typeof object.dataRoomId === "string")
                    $util.base64.decode(object.dataRoomId, message.dataRoomId = $util.newBuffer($util.base64.length(object.dataRoomId)), 0);
                else if (object.dataRoomId.length >= 0)
                    message.dataRoomId = object.dataRoomId;
            return message;
        };

        /**
         * Creates a plain object from a RetrieveCurrentDataRoomConfigurationRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationRequest
         * @static
         * @param {gcg.RetrieveCurrentDataRoomConfigurationRequest} message RetrieveCurrentDataRoomConfigurationRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrieveCurrentDataRoomConfigurationRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.dataRoomId = "";
                else {
                    object.dataRoomId = [];
                    if (options.bytes !== Array)
                        object.dataRoomId = $util.newBuffer(object.dataRoomId);
                }
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                object.dataRoomId = options.bytes === String ? $util.base64.encode(message.dataRoomId, 0, message.dataRoomId.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomId) : message.dataRoomId;
            return object;
        };

        /**
         * Converts this RetrieveCurrentDataRoomConfigurationRequest to JSON.
         * @function toJSON
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrieveCurrentDataRoomConfigurationRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrieveCurrentDataRoomConfigurationRequest
         * @function getTypeUrl
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrieveCurrentDataRoomConfigurationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.RetrieveCurrentDataRoomConfigurationRequest";
        };

        return RetrieveCurrentDataRoomConfigurationRequest;
    })();

    gcg.RetrieveCurrentDataRoomConfigurationResponse = (function() {

        /**
         * Properties of a RetrieveCurrentDataRoomConfigurationResponse.
         * @memberof gcg
         * @interface IRetrieveCurrentDataRoomConfigurationResponse
         * @property {data_room.IDataRoomConfiguration|null} [configuration] RetrieveCurrentDataRoomConfigurationResponse configuration
         * @property {Uint8Array|null} [pin] RetrieveCurrentDataRoomConfigurationResponse pin
         */

        /**
         * Constructs a new RetrieveCurrentDataRoomConfigurationResponse.
         * @memberof gcg
         * @classdesc Represents a RetrieveCurrentDataRoomConfigurationResponse.
         * @implements IRetrieveCurrentDataRoomConfigurationResponse
         * @constructor
         * @param {gcg.IRetrieveCurrentDataRoomConfigurationResponse=} [properties] Properties to set
         */
        function RetrieveCurrentDataRoomConfigurationResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RetrieveCurrentDataRoomConfigurationResponse configuration.
         * @member {data_room.IDataRoomConfiguration|null|undefined} configuration
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationResponse
         * @instance
         */
        RetrieveCurrentDataRoomConfigurationResponse.prototype.configuration = null;

        /**
         * RetrieveCurrentDataRoomConfigurationResponse pin.
         * @member {Uint8Array} pin
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationResponse
         * @instance
         */
        RetrieveCurrentDataRoomConfigurationResponse.prototype.pin = $util.newBuffer([]);

        /**
         * Creates a new RetrieveCurrentDataRoomConfigurationResponse instance using the specified properties.
         * @function create
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationResponse
         * @static
         * @param {gcg.IRetrieveCurrentDataRoomConfigurationResponse=} [properties] Properties to set
         * @returns {gcg.RetrieveCurrentDataRoomConfigurationResponse} RetrieveCurrentDataRoomConfigurationResponse instance
         */
        RetrieveCurrentDataRoomConfigurationResponse.create = function create(properties) {
            return new RetrieveCurrentDataRoomConfigurationResponse(properties);
        };

        /**
         * Encodes the specified RetrieveCurrentDataRoomConfigurationResponse message. Does not implicitly {@link gcg.RetrieveCurrentDataRoomConfigurationResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationResponse
         * @static
         * @param {gcg.IRetrieveCurrentDataRoomConfigurationResponse} message RetrieveCurrentDataRoomConfigurationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveCurrentDataRoomConfigurationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.configuration != null && Object.hasOwnProperty.call(message, "configuration"))
                $root.data_room.DataRoomConfiguration.encode(message.configuration, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.pin != null && Object.hasOwnProperty.call(message, "pin"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.pin);
            return writer;
        };

        /**
         * Encodes the specified RetrieveCurrentDataRoomConfigurationResponse message, length delimited. Does not implicitly {@link gcg.RetrieveCurrentDataRoomConfigurationResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationResponse
         * @static
         * @param {gcg.IRetrieveCurrentDataRoomConfigurationResponse} message RetrieveCurrentDataRoomConfigurationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveCurrentDataRoomConfigurationResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrieveCurrentDataRoomConfigurationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.RetrieveCurrentDataRoomConfigurationResponse} RetrieveCurrentDataRoomConfigurationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveCurrentDataRoomConfigurationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.RetrieveCurrentDataRoomConfigurationResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.configuration = $root.data_room.DataRoomConfiguration.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.pin = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrieveCurrentDataRoomConfigurationResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.RetrieveCurrentDataRoomConfigurationResponse} RetrieveCurrentDataRoomConfigurationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveCurrentDataRoomConfigurationResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrieveCurrentDataRoomConfigurationResponse message.
         * @function verify
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrieveCurrentDataRoomConfigurationResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.configuration != null && message.hasOwnProperty("configuration")) {
                let error = $root.data_room.DataRoomConfiguration.verify(message.configuration);
                if (error)
                    return "configuration." + error;
            }
            if (message.pin != null && message.hasOwnProperty("pin"))
                if (!(message.pin && typeof message.pin.length === "number" || $util.isString(message.pin)))
                    return "pin: buffer expected";
            return null;
        };

        /**
         * Creates a RetrieveCurrentDataRoomConfigurationResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.RetrieveCurrentDataRoomConfigurationResponse} RetrieveCurrentDataRoomConfigurationResponse
         */
        RetrieveCurrentDataRoomConfigurationResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.RetrieveCurrentDataRoomConfigurationResponse)
                return object;
            let message = new $root.gcg.RetrieveCurrentDataRoomConfigurationResponse();
            if (object.configuration != null) {
                if (typeof object.configuration !== "object")
                    throw TypeError(".gcg.RetrieveCurrentDataRoomConfigurationResponse.configuration: object expected");
                message.configuration = $root.data_room.DataRoomConfiguration.fromObject(object.configuration);
            }
            if (object.pin != null)
                if (typeof object.pin === "string")
                    $util.base64.decode(object.pin, message.pin = $util.newBuffer($util.base64.length(object.pin)), 0);
                else if (object.pin.length >= 0)
                    message.pin = object.pin;
            return message;
        };

        /**
         * Creates a plain object from a RetrieveCurrentDataRoomConfigurationResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationResponse
         * @static
         * @param {gcg.RetrieveCurrentDataRoomConfigurationResponse} message RetrieveCurrentDataRoomConfigurationResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrieveCurrentDataRoomConfigurationResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.configuration = null;
                if (options.bytes === String)
                    object.pin = "";
                else {
                    object.pin = [];
                    if (options.bytes !== Array)
                        object.pin = $util.newBuffer(object.pin);
                }
            }
            if (message.configuration != null && message.hasOwnProperty("configuration"))
                object.configuration = $root.data_room.DataRoomConfiguration.toObject(message.configuration, options);
            if (message.pin != null && message.hasOwnProperty("pin"))
                object.pin = options.bytes === String ? $util.base64.encode(message.pin, 0, message.pin.length) : options.bytes === Array ? Array.prototype.slice.call(message.pin) : message.pin;
            return object;
        };

        /**
         * Converts this RetrieveCurrentDataRoomConfigurationResponse to JSON.
         * @function toJSON
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrieveCurrentDataRoomConfigurationResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrieveCurrentDataRoomConfigurationResponse
         * @function getTypeUrl
         * @memberof gcg.RetrieveCurrentDataRoomConfigurationResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrieveCurrentDataRoomConfigurationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.RetrieveCurrentDataRoomConfigurationResponse";
        };

        return RetrieveCurrentDataRoomConfigurationResponse;
    })();

    gcg.RetrieveConfigurationCommitApproversRequest = (function() {

        /**
         * Properties of a RetrieveConfigurationCommitApproversRequest.
         * @memberof gcg
         * @interface IRetrieveConfigurationCommitApproversRequest
         * @property {Uint8Array|null} [commitId] RetrieveConfigurationCommitApproversRequest commitId
         */

        /**
         * Constructs a new RetrieveConfigurationCommitApproversRequest.
         * @memberof gcg
         * @classdesc Represents a RetrieveConfigurationCommitApproversRequest.
         * @implements IRetrieveConfigurationCommitApproversRequest
         * @constructor
         * @param {gcg.IRetrieveConfigurationCommitApproversRequest=} [properties] Properties to set
         */
        function RetrieveConfigurationCommitApproversRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RetrieveConfigurationCommitApproversRequest commitId.
         * @member {Uint8Array} commitId
         * @memberof gcg.RetrieveConfigurationCommitApproversRequest
         * @instance
         */
        RetrieveConfigurationCommitApproversRequest.prototype.commitId = $util.newBuffer([]);

        /**
         * Creates a new RetrieveConfigurationCommitApproversRequest instance using the specified properties.
         * @function create
         * @memberof gcg.RetrieveConfigurationCommitApproversRequest
         * @static
         * @param {gcg.IRetrieveConfigurationCommitApproversRequest=} [properties] Properties to set
         * @returns {gcg.RetrieveConfigurationCommitApproversRequest} RetrieveConfigurationCommitApproversRequest instance
         */
        RetrieveConfigurationCommitApproversRequest.create = function create(properties) {
            return new RetrieveConfigurationCommitApproversRequest(properties);
        };

        /**
         * Encodes the specified RetrieveConfigurationCommitApproversRequest message. Does not implicitly {@link gcg.RetrieveConfigurationCommitApproversRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.RetrieveConfigurationCommitApproversRequest
         * @static
         * @param {gcg.IRetrieveConfigurationCommitApproversRequest} message RetrieveConfigurationCommitApproversRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveConfigurationCommitApproversRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commitId != null && Object.hasOwnProperty.call(message, "commitId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.commitId);
            return writer;
        };

        /**
         * Encodes the specified RetrieveConfigurationCommitApproversRequest message, length delimited. Does not implicitly {@link gcg.RetrieveConfigurationCommitApproversRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.RetrieveConfigurationCommitApproversRequest
         * @static
         * @param {gcg.IRetrieveConfigurationCommitApproversRequest} message RetrieveConfigurationCommitApproversRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveConfigurationCommitApproversRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrieveConfigurationCommitApproversRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.RetrieveConfigurationCommitApproversRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.RetrieveConfigurationCommitApproversRequest} RetrieveConfigurationCommitApproversRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveConfigurationCommitApproversRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.RetrieveConfigurationCommitApproversRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.commitId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrieveConfigurationCommitApproversRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.RetrieveConfigurationCommitApproversRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.RetrieveConfigurationCommitApproversRequest} RetrieveConfigurationCommitApproversRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveConfigurationCommitApproversRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrieveConfigurationCommitApproversRequest message.
         * @function verify
         * @memberof gcg.RetrieveConfigurationCommitApproversRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrieveConfigurationCommitApproversRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commitId != null && message.hasOwnProperty("commitId"))
                if (!(message.commitId && typeof message.commitId.length === "number" || $util.isString(message.commitId)))
                    return "commitId: buffer expected";
            return null;
        };

        /**
         * Creates a RetrieveConfigurationCommitApproversRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.RetrieveConfigurationCommitApproversRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.RetrieveConfigurationCommitApproversRequest} RetrieveConfigurationCommitApproversRequest
         */
        RetrieveConfigurationCommitApproversRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.RetrieveConfigurationCommitApproversRequest)
                return object;
            let message = new $root.gcg.RetrieveConfigurationCommitApproversRequest();
            if (object.commitId != null)
                if (typeof object.commitId === "string")
                    $util.base64.decode(object.commitId, message.commitId = $util.newBuffer($util.base64.length(object.commitId)), 0);
                else if (object.commitId.length >= 0)
                    message.commitId = object.commitId;
            return message;
        };

        /**
         * Creates a plain object from a RetrieveConfigurationCommitApproversRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.RetrieveConfigurationCommitApproversRequest
         * @static
         * @param {gcg.RetrieveConfigurationCommitApproversRequest} message RetrieveConfigurationCommitApproversRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrieveConfigurationCommitApproversRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.commitId = "";
                else {
                    object.commitId = [];
                    if (options.bytes !== Array)
                        object.commitId = $util.newBuffer(object.commitId);
                }
            if (message.commitId != null && message.hasOwnProperty("commitId"))
                object.commitId = options.bytes === String ? $util.base64.encode(message.commitId, 0, message.commitId.length) : options.bytes === Array ? Array.prototype.slice.call(message.commitId) : message.commitId;
            return object;
        };

        /**
         * Converts this RetrieveConfigurationCommitApproversRequest to JSON.
         * @function toJSON
         * @memberof gcg.RetrieveConfigurationCommitApproversRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrieveConfigurationCommitApproversRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrieveConfigurationCommitApproversRequest
         * @function getTypeUrl
         * @memberof gcg.RetrieveConfigurationCommitApproversRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrieveConfigurationCommitApproversRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.RetrieveConfigurationCommitApproversRequest";
        };

        return RetrieveConfigurationCommitApproversRequest;
    })();

    gcg.RetrieveConfigurationCommitApproversResponse = (function() {

        /**
         * Properties of a RetrieveConfigurationCommitApproversResponse.
         * @memberof gcg
         * @interface IRetrieveConfigurationCommitApproversResponse
         * @property {Array.<string>|null} [approvers] RetrieveConfigurationCommitApproversResponse approvers
         */

        /**
         * Constructs a new RetrieveConfigurationCommitApproversResponse.
         * @memberof gcg
         * @classdesc Represents a RetrieveConfigurationCommitApproversResponse.
         * @implements IRetrieveConfigurationCommitApproversResponse
         * @constructor
         * @param {gcg.IRetrieveConfigurationCommitApproversResponse=} [properties] Properties to set
         */
        function RetrieveConfigurationCommitApproversResponse(properties) {
            this.approvers = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RetrieveConfigurationCommitApproversResponse approvers.
         * @member {Array.<string>} approvers
         * @memberof gcg.RetrieveConfigurationCommitApproversResponse
         * @instance
         */
        RetrieveConfigurationCommitApproversResponse.prototype.approvers = $util.emptyArray;

        /**
         * Creates a new RetrieveConfigurationCommitApproversResponse instance using the specified properties.
         * @function create
         * @memberof gcg.RetrieveConfigurationCommitApproversResponse
         * @static
         * @param {gcg.IRetrieveConfigurationCommitApproversResponse=} [properties] Properties to set
         * @returns {gcg.RetrieveConfigurationCommitApproversResponse} RetrieveConfigurationCommitApproversResponse instance
         */
        RetrieveConfigurationCommitApproversResponse.create = function create(properties) {
            return new RetrieveConfigurationCommitApproversResponse(properties);
        };

        /**
         * Encodes the specified RetrieveConfigurationCommitApproversResponse message. Does not implicitly {@link gcg.RetrieveConfigurationCommitApproversResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.RetrieveConfigurationCommitApproversResponse
         * @static
         * @param {gcg.IRetrieveConfigurationCommitApproversResponse} message RetrieveConfigurationCommitApproversResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveConfigurationCommitApproversResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.approvers != null && message.approvers.length)
                for (let i = 0; i < message.approvers.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.approvers[i]);
            return writer;
        };

        /**
         * Encodes the specified RetrieveConfigurationCommitApproversResponse message, length delimited. Does not implicitly {@link gcg.RetrieveConfigurationCommitApproversResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.RetrieveConfigurationCommitApproversResponse
         * @static
         * @param {gcg.IRetrieveConfigurationCommitApproversResponse} message RetrieveConfigurationCommitApproversResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveConfigurationCommitApproversResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrieveConfigurationCommitApproversResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.RetrieveConfigurationCommitApproversResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.RetrieveConfigurationCommitApproversResponse} RetrieveConfigurationCommitApproversResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveConfigurationCommitApproversResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.RetrieveConfigurationCommitApproversResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.approvers && message.approvers.length))
                            message.approvers = [];
                        message.approvers.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrieveConfigurationCommitApproversResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.RetrieveConfigurationCommitApproversResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.RetrieveConfigurationCommitApproversResponse} RetrieveConfigurationCommitApproversResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveConfigurationCommitApproversResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrieveConfigurationCommitApproversResponse message.
         * @function verify
         * @memberof gcg.RetrieveConfigurationCommitApproversResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrieveConfigurationCommitApproversResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.approvers != null && message.hasOwnProperty("approvers")) {
                if (!Array.isArray(message.approvers))
                    return "approvers: array expected";
                for (let i = 0; i < message.approvers.length; ++i)
                    if (!$util.isString(message.approvers[i]))
                        return "approvers: string[] expected";
            }
            return null;
        };

        /**
         * Creates a RetrieveConfigurationCommitApproversResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.RetrieveConfigurationCommitApproversResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.RetrieveConfigurationCommitApproversResponse} RetrieveConfigurationCommitApproversResponse
         */
        RetrieveConfigurationCommitApproversResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.RetrieveConfigurationCommitApproversResponse)
                return object;
            let message = new $root.gcg.RetrieveConfigurationCommitApproversResponse();
            if (object.approvers) {
                if (!Array.isArray(object.approvers))
                    throw TypeError(".gcg.RetrieveConfigurationCommitApproversResponse.approvers: array expected");
                message.approvers = [];
                for (let i = 0; i < object.approvers.length; ++i)
                    message.approvers[i] = String(object.approvers[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a RetrieveConfigurationCommitApproversResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.RetrieveConfigurationCommitApproversResponse
         * @static
         * @param {gcg.RetrieveConfigurationCommitApproversResponse} message RetrieveConfigurationCommitApproversResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrieveConfigurationCommitApproversResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.approvers = [];
            if (message.approvers && message.approvers.length) {
                object.approvers = [];
                for (let j = 0; j < message.approvers.length; ++j)
                    object.approvers[j] = message.approvers[j];
            }
            return object;
        };

        /**
         * Converts this RetrieveConfigurationCommitApproversResponse to JSON.
         * @function toJSON
         * @memberof gcg.RetrieveConfigurationCommitApproversResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrieveConfigurationCommitApproversResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrieveConfigurationCommitApproversResponse
         * @function getTypeUrl
         * @memberof gcg.RetrieveConfigurationCommitApproversResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrieveConfigurationCommitApproversResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.RetrieveConfigurationCommitApproversResponse";
        };

        return RetrieveConfigurationCommitApproversResponse;
    })();

    gcg.RetrieveConfigurationCommitRequest = (function() {

        /**
         * Properties of a RetrieveConfigurationCommitRequest.
         * @memberof gcg
         * @interface IRetrieveConfigurationCommitRequest
         * @property {Uint8Array|null} [commitId] RetrieveConfigurationCommitRequest commitId
         */

        /**
         * Constructs a new RetrieveConfigurationCommitRequest.
         * @memberof gcg
         * @classdesc Represents a RetrieveConfigurationCommitRequest.
         * @implements IRetrieveConfigurationCommitRequest
         * @constructor
         * @param {gcg.IRetrieveConfigurationCommitRequest=} [properties] Properties to set
         */
        function RetrieveConfigurationCommitRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RetrieveConfigurationCommitRequest commitId.
         * @member {Uint8Array} commitId
         * @memberof gcg.RetrieveConfigurationCommitRequest
         * @instance
         */
        RetrieveConfigurationCommitRequest.prototype.commitId = $util.newBuffer([]);

        /**
         * Creates a new RetrieveConfigurationCommitRequest instance using the specified properties.
         * @function create
         * @memberof gcg.RetrieveConfigurationCommitRequest
         * @static
         * @param {gcg.IRetrieveConfigurationCommitRequest=} [properties] Properties to set
         * @returns {gcg.RetrieveConfigurationCommitRequest} RetrieveConfigurationCommitRequest instance
         */
        RetrieveConfigurationCommitRequest.create = function create(properties) {
            return new RetrieveConfigurationCommitRequest(properties);
        };

        /**
         * Encodes the specified RetrieveConfigurationCommitRequest message. Does not implicitly {@link gcg.RetrieveConfigurationCommitRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.RetrieveConfigurationCommitRequest
         * @static
         * @param {gcg.IRetrieveConfigurationCommitRequest} message RetrieveConfigurationCommitRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveConfigurationCommitRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commitId != null && Object.hasOwnProperty.call(message, "commitId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.commitId);
            return writer;
        };

        /**
         * Encodes the specified RetrieveConfigurationCommitRequest message, length delimited. Does not implicitly {@link gcg.RetrieveConfigurationCommitRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.RetrieveConfigurationCommitRequest
         * @static
         * @param {gcg.IRetrieveConfigurationCommitRequest} message RetrieveConfigurationCommitRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveConfigurationCommitRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrieveConfigurationCommitRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.RetrieveConfigurationCommitRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.RetrieveConfigurationCommitRequest} RetrieveConfigurationCommitRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveConfigurationCommitRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.RetrieveConfigurationCommitRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.commitId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrieveConfigurationCommitRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.RetrieveConfigurationCommitRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.RetrieveConfigurationCommitRequest} RetrieveConfigurationCommitRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveConfigurationCommitRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrieveConfigurationCommitRequest message.
         * @function verify
         * @memberof gcg.RetrieveConfigurationCommitRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrieveConfigurationCommitRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commitId != null && message.hasOwnProperty("commitId"))
                if (!(message.commitId && typeof message.commitId.length === "number" || $util.isString(message.commitId)))
                    return "commitId: buffer expected";
            return null;
        };

        /**
         * Creates a RetrieveConfigurationCommitRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.RetrieveConfigurationCommitRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.RetrieveConfigurationCommitRequest} RetrieveConfigurationCommitRequest
         */
        RetrieveConfigurationCommitRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.RetrieveConfigurationCommitRequest)
                return object;
            let message = new $root.gcg.RetrieveConfigurationCommitRequest();
            if (object.commitId != null)
                if (typeof object.commitId === "string")
                    $util.base64.decode(object.commitId, message.commitId = $util.newBuffer($util.base64.length(object.commitId)), 0);
                else if (object.commitId.length >= 0)
                    message.commitId = object.commitId;
            return message;
        };

        /**
         * Creates a plain object from a RetrieveConfigurationCommitRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.RetrieveConfigurationCommitRequest
         * @static
         * @param {gcg.RetrieveConfigurationCommitRequest} message RetrieveConfigurationCommitRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrieveConfigurationCommitRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.commitId = "";
                else {
                    object.commitId = [];
                    if (options.bytes !== Array)
                        object.commitId = $util.newBuffer(object.commitId);
                }
            if (message.commitId != null && message.hasOwnProperty("commitId"))
                object.commitId = options.bytes === String ? $util.base64.encode(message.commitId, 0, message.commitId.length) : options.bytes === Array ? Array.prototype.slice.call(message.commitId) : message.commitId;
            return object;
        };

        /**
         * Converts this RetrieveConfigurationCommitRequest to JSON.
         * @function toJSON
         * @memberof gcg.RetrieveConfigurationCommitRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrieveConfigurationCommitRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrieveConfigurationCommitRequest
         * @function getTypeUrl
         * @memberof gcg.RetrieveConfigurationCommitRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrieveConfigurationCommitRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.RetrieveConfigurationCommitRequest";
        };

        return RetrieveConfigurationCommitRequest;
    })();

    gcg.RetrieveConfigurationCommitResponse = (function() {

        /**
         * Properties of a RetrieveConfigurationCommitResponse.
         * @memberof gcg
         * @interface IRetrieveConfigurationCommitResponse
         * @property {data_room.IConfigurationCommit|null} [commit] RetrieveConfigurationCommitResponse commit
         * @property {Uint8Array|null} [highLevelRepresentation] RetrieveConfigurationCommitResponse highLevelRepresentation
         */

        /**
         * Constructs a new RetrieveConfigurationCommitResponse.
         * @memberof gcg
         * @classdesc Represents a RetrieveConfigurationCommitResponse.
         * @implements IRetrieveConfigurationCommitResponse
         * @constructor
         * @param {gcg.IRetrieveConfigurationCommitResponse=} [properties] Properties to set
         */
        function RetrieveConfigurationCommitResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RetrieveConfigurationCommitResponse commit.
         * @member {data_room.IConfigurationCommit|null|undefined} commit
         * @memberof gcg.RetrieveConfigurationCommitResponse
         * @instance
         */
        RetrieveConfigurationCommitResponse.prototype.commit = null;

        /**
         * RetrieveConfigurationCommitResponse highLevelRepresentation.
         * @member {Uint8Array|null|undefined} highLevelRepresentation
         * @memberof gcg.RetrieveConfigurationCommitResponse
         * @instance
         */
        RetrieveConfigurationCommitResponse.prototype.highLevelRepresentation = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * RetrieveConfigurationCommitResponse _highLevelRepresentation.
         * @member {"highLevelRepresentation"|undefined} _highLevelRepresentation
         * @memberof gcg.RetrieveConfigurationCommitResponse
         * @instance
         */
        Object.defineProperty(RetrieveConfigurationCommitResponse.prototype, "_highLevelRepresentation", {
            get: $util.oneOfGetter($oneOfFields = ["highLevelRepresentation"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new RetrieveConfigurationCommitResponse instance using the specified properties.
         * @function create
         * @memberof gcg.RetrieveConfigurationCommitResponse
         * @static
         * @param {gcg.IRetrieveConfigurationCommitResponse=} [properties] Properties to set
         * @returns {gcg.RetrieveConfigurationCommitResponse} RetrieveConfigurationCommitResponse instance
         */
        RetrieveConfigurationCommitResponse.create = function create(properties) {
            return new RetrieveConfigurationCommitResponse(properties);
        };

        /**
         * Encodes the specified RetrieveConfigurationCommitResponse message. Does not implicitly {@link gcg.RetrieveConfigurationCommitResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.RetrieveConfigurationCommitResponse
         * @static
         * @param {gcg.IRetrieveConfigurationCommitResponse} message RetrieveConfigurationCommitResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveConfigurationCommitResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commit != null && Object.hasOwnProperty.call(message, "commit"))
                $root.data_room.ConfigurationCommit.encode(message.commit, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.highLevelRepresentation != null && Object.hasOwnProperty.call(message, "highLevelRepresentation"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.highLevelRepresentation);
            return writer;
        };

        /**
         * Encodes the specified RetrieveConfigurationCommitResponse message, length delimited. Does not implicitly {@link gcg.RetrieveConfigurationCommitResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.RetrieveConfigurationCommitResponse
         * @static
         * @param {gcg.IRetrieveConfigurationCommitResponse} message RetrieveConfigurationCommitResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveConfigurationCommitResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrieveConfigurationCommitResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.RetrieveConfigurationCommitResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.RetrieveConfigurationCommitResponse} RetrieveConfigurationCommitResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveConfigurationCommitResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.RetrieveConfigurationCommitResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.commit = $root.data_room.ConfigurationCommit.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.highLevelRepresentation = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrieveConfigurationCommitResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.RetrieveConfigurationCommitResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.RetrieveConfigurationCommitResponse} RetrieveConfigurationCommitResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveConfigurationCommitResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrieveConfigurationCommitResponse message.
         * @function verify
         * @memberof gcg.RetrieveConfigurationCommitResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrieveConfigurationCommitResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.commit != null && message.hasOwnProperty("commit")) {
                let error = $root.data_room.ConfigurationCommit.verify(message.commit);
                if (error)
                    return "commit." + error;
            }
            if (message.highLevelRepresentation != null && message.hasOwnProperty("highLevelRepresentation")) {
                properties._highLevelRepresentation = 1;
                if (!(message.highLevelRepresentation && typeof message.highLevelRepresentation.length === "number" || $util.isString(message.highLevelRepresentation)))
                    return "highLevelRepresentation: buffer expected";
            }
            return null;
        };

        /**
         * Creates a RetrieveConfigurationCommitResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.RetrieveConfigurationCommitResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.RetrieveConfigurationCommitResponse} RetrieveConfigurationCommitResponse
         */
        RetrieveConfigurationCommitResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.RetrieveConfigurationCommitResponse)
                return object;
            let message = new $root.gcg.RetrieveConfigurationCommitResponse();
            if (object.commit != null) {
                if (typeof object.commit !== "object")
                    throw TypeError(".gcg.RetrieveConfigurationCommitResponse.commit: object expected");
                message.commit = $root.data_room.ConfigurationCommit.fromObject(object.commit);
            }
            if (object.highLevelRepresentation != null)
                if (typeof object.highLevelRepresentation === "string")
                    $util.base64.decode(object.highLevelRepresentation, message.highLevelRepresentation = $util.newBuffer($util.base64.length(object.highLevelRepresentation)), 0);
                else if (object.highLevelRepresentation.length >= 0)
                    message.highLevelRepresentation = object.highLevelRepresentation;
            return message;
        };

        /**
         * Creates a plain object from a RetrieveConfigurationCommitResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.RetrieveConfigurationCommitResponse
         * @static
         * @param {gcg.RetrieveConfigurationCommitResponse} message RetrieveConfigurationCommitResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrieveConfigurationCommitResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.commit = null;
            if (message.commit != null && message.hasOwnProperty("commit"))
                object.commit = $root.data_room.ConfigurationCommit.toObject(message.commit, options);
            if (message.highLevelRepresentation != null && message.hasOwnProperty("highLevelRepresentation")) {
                object.highLevelRepresentation = options.bytes === String ? $util.base64.encode(message.highLevelRepresentation, 0, message.highLevelRepresentation.length) : options.bytes === Array ? Array.prototype.slice.call(message.highLevelRepresentation) : message.highLevelRepresentation;
                if (options.oneofs)
                    object._highLevelRepresentation = "highLevelRepresentation";
            }
            return object;
        };

        /**
         * Converts this RetrieveConfigurationCommitResponse to JSON.
         * @function toJSON
         * @memberof gcg.RetrieveConfigurationCommitResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrieveConfigurationCommitResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrieveConfigurationCommitResponse
         * @function getTypeUrl
         * @memberof gcg.RetrieveConfigurationCommitResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrieveConfigurationCommitResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.RetrieveConfigurationCommitResponse";
        };

        return RetrieveConfigurationCommitResponse;
    })();

    gcg.CasAuxiliaryStateRequest = (function() {

        /**
         * Properties of a CasAuxiliaryStateRequest.
         * @memberof gcg
         * @interface ICasAuxiliaryStateRequest
         * @property {Uint8Array|null} [dataRoomId] CasAuxiliaryStateRequest dataRoomId
         * @property {number|Long|null} [index] CasAuxiliaryStateRequest index
         * @property {Uint8Array|null} [value] CasAuxiliaryStateRequest value
         */

        /**
         * Constructs a new CasAuxiliaryStateRequest.
         * @memberof gcg
         * @classdesc Represents a CasAuxiliaryStateRequest.
         * @implements ICasAuxiliaryStateRequest
         * @constructor
         * @param {gcg.ICasAuxiliaryStateRequest=} [properties] Properties to set
         */
        function CasAuxiliaryStateRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CasAuxiliaryStateRequest dataRoomId.
         * @member {Uint8Array} dataRoomId
         * @memberof gcg.CasAuxiliaryStateRequest
         * @instance
         */
        CasAuxiliaryStateRequest.prototype.dataRoomId = $util.newBuffer([]);

        /**
         * CasAuxiliaryStateRequest index.
         * @member {number|Long} index
         * @memberof gcg.CasAuxiliaryStateRequest
         * @instance
         */
        CasAuxiliaryStateRequest.prototype.index = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * CasAuxiliaryStateRequest value.
         * @member {Uint8Array|null|undefined} value
         * @memberof gcg.CasAuxiliaryStateRequest
         * @instance
         */
        CasAuxiliaryStateRequest.prototype.value = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * CasAuxiliaryStateRequest _value.
         * @member {"value"|undefined} _value
         * @memberof gcg.CasAuxiliaryStateRequest
         * @instance
         */
        Object.defineProperty(CasAuxiliaryStateRequest.prototype, "_value", {
            get: $util.oneOfGetter($oneOfFields = ["value"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CasAuxiliaryStateRequest instance using the specified properties.
         * @function create
         * @memberof gcg.CasAuxiliaryStateRequest
         * @static
         * @param {gcg.ICasAuxiliaryStateRequest=} [properties] Properties to set
         * @returns {gcg.CasAuxiliaryStateRequest} CasAuxiliaryStateRequest instance
         */
        CasAuxiliaryStateRequest.create = function create(properties) {
            return new CasAuxiliaryStateRequest(properties);
        };

        /**
         * Encodes the specified CasAuxiliaryStateRequest message. Does not implicitly {@link gcg.CasAuxiliaryStateRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.CasAuxiliaryStateRequest
         * @static
         * @param {gcg.ICasAuxiliaryStateRequest} message CasAuxiliaryStateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CasAuxiliaryStateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dataRoomId != null && Object.hasOwnProperty.call(message, "dataRoomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dataRoomId);
            if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.index);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.value);
            return writer;
        };

        /**
         * Encodes the specified CasAuxiliaryStateRequest message, length delimited. Does not implicitly {@link gcg.CasAuxiliaryStateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.CasAuxiliaryStateRequest
         * @static
         * @param {gcg.ICasAuxiliaryStateRequest} message CasAuxiliaryStateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CasAuxiliaryStateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CasAuxiliaryStateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.CasAuxiliaryStateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.CasAuxiliaryStateRequest} CasAuxiliaryStateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CasAuxiliaryStateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.CasAuxiliaryStateRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dataRoomId = reader.bytes();
                        break;
                    }
                case 2: {
                        message.index = reader.uint64();
                        break;
                    }
                case 3: {
                        message.value = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CasAuxiliaryStateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.CasAuxiliaryStateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.CasAuxiliaryStateRequest} CasAuxiliaryStateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CasAuxiliaryStateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CasAuxiliaryStateRequest message.
         * @function verify
         * @memberof gcg.CasAuxiliaryStateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CasAuxiliaryStateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                if (!(message.dataRoomId && typeof message.dataRoomId.length === "number" || $util.isString(message.dataRoomId)))
                    return "dataRoomId: buffer expected";
            if (message.index != null && message.hasOwnProperty("index"))
                if (!$util.isInteger(message.index) && !(message.index && $util.isInteger(message.index.low) && $util.isInteger(message.index.high)))
                    return "index: integer|Long expected";
            if (message.value != null && message.hasOwnProperty("value")) {
                properties._value = 1;
                if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                    return "value: buffer expected";
            }
            return null;
        };

        /**
         * Creates a CasAuxiliaryStateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.CasAuxiliaryStateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.CasAuxiliaryStateRequest} CasAuxiliaryStateRequest
         */
        CasAuxiliaryStateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.CasAuxiliaryStateRequest)
                return object;
            let message = new $root.gcg.CasAuxiliaryStateRequest();
            if (object.dataRoomId != null)
                if (typeof object.dataRoomId === "string")
                    $util.base64.decode(object.dataRoomId, message.dataRoomId = $util.newBuffer($util.base64.length(object.dataRoomId)), 0);
                else if (object.dataRoomId.length >= 0)
                    message.dataRoomId = object.dataRoomId;
            if (object.index != null)
                if ($util.Long)
                    (message.index = $util.Long.fromValue(object.index)).unsigned = true;
                else if (typeof object.index === "string")
                    message.index = parseInt(object.index, 10);
                else if (typeof object.index === "number")
                    message.index = object.index;
                else if (typeof object.index === "object")
                    message.index = new $util.LongBits(object.index.low >>> 0, object.index.high >>> 0).toNumber(true);
            if (object.value != null)
                if (typeof object.value === "string")
                    $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                else if (object.value.length >= 0)
                    message.value = object.value;
            return message;
        };

        /**
         * Creates a plain object from a CasAuxiliaryStateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.CasAuxiliaryStateRequest
         * @static
         * @param {gcg.CasAuxiliaryStateRequest} message CasAuxiliaryStateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CasAuxiliaryStateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.dataRoomId = "";
                else {
                    object.dataRoomId = [];
                    if (options.bytes !== Array)
                        object.dataRoomId = $util.newBuffer(object.dataRoomId);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.index = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.index = options.longs === String ? "0" : 0;
            }
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                object.dataRoomId = options.bytes === String ? $util.base64.encode(message.dataRoomId, 0, message.dataRoomId.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomId) : message.dataRoomId;
            if (message.index != null && message.hasOwnProperty("index"))
                if (typeof message.index === "number")
                    object.index = options.longs === String ? String(message.index) : message.index;
                else
                    object.index = options.longs === String ? $util.Long.prototype.toString.call(message.index) : options.longs === Number ? new $util.LongBits(message.index.low >>> 0, message.index.high >>> 0).toNumber(true) : message.index;
            if (message.value != null && message.hasOwnProperty("value")) {
                object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
                if (options.oneofs)
                    object._value = "value";
            }
            return object;
        };

        /**
         * Converts this CasAuxiliaryStateRequest to JSON.
         * @function toJSON
         * @memberof gcg.CasAuxiliaryStateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CasAuxiliaryStateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CasAuxiliaryStateRequest
         * @function getTypeUrl
         * @memberof gcg.CasAuxiliaryStateRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CasAuxiliaryStateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.CasAuxiliaryStateRequest";
        };

        return CasAuxiliaryStateRequest;
    })();

    gcg.CasAuxiliaryStateResponse = (function() {

        /**
         * Properties of a CasAuxiliaryStateResponse.
         * @memberof gcg
         * @interface ICasAuxiliaryStateResponse
         * @property {boolean|null} [success] CasAuxiliaryStateResponse success
         * @property {number|Long|null} [index] CasAuxiliaryStateResponse index
         * @property {Uint8Array|null} [value] CasAuxiliaryStateResponse value
         */

        /**
         * Constructs a new CasAuxiliaryStateResponse.
         * @memberof gcg
         * @classdesc Represents a CasAuxiliaryStateResponse.
         * @implements ICasAuxiliaryStateResponse
         * @constructor
         * @param {gcg.ICasAuxiliaryStateResponse=} [properties] Properties to set
         */
        function CasAuxiliaryStateResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CasAuxiliaryStateResponse success.
         * @member {boolean} success
         * @memberof gcg.CasAuxiliaryStateResponse
         * @instance
         */
        CasAuxiliaryStateResponse.prototype.success = false;

        /**
         * CasAuxiliaryStateResponse index.
         * @member {number|Long} index
         * @memberof gcg.CasAuxiliaryStateResponse
         * @instance
         */
        CasAuxiliaryStateResponse.prototype.index = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * CasAuxiliaryStateResponse value.
         * @member {Uint8Array|null|undefined} value
         * @memberof gcg.CasAuxiliaryStateResponse
         * @instance
         */
        CasAuxiliaryStateResponse.prototype.value = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * CasAuxiliaryStateResponse _value.
         * @member {"value"|undefined} _value
         * @memberof gcg.CasAuxiliaryStateResponse
         * @instance
         */
        Object.defineProperty(CasAuxiliaryStateResponse.prototype, "_value", {
            get: $util.oneOfGetter($oneOfFields = ["value"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CasAuxiliaryStateResponse instance using the specified properties.
         * @function create
         * @memberof gcg.CasAuxiliaryStateResponse
         * @static
         * @param {gcg.ICasAuxiliaryStateResponse=} [properties] Properties to set
         * @returns {gcg.CasAuxiliaryStateResponse} CasAuxiliaryStateResponse instance
         */
        CasAuxiliaryStateResponse.create = function create(properties) {
            return new CasAuxiliaryStateResponse(properties);
        };

        /**
         * Encodes the specified CasAuxiliaryStateResponse message. Does not implicitly {@link gcg.CasAuxiliaryStateResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.CasAuxiliaryStateResponse
         * @static
         * @param {gcg.ICasAuxiliaryStateResponse} message CasAuxiliaryStateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CasAuxiliaryStateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
            if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.index);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.value);
            return writer;
        };

        /**
         * Encodes the specified CasAuxiliaryStateResponse message, length delimited. Does not implicitly {@link gcg.CasAuxiliaryStateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.CasAuxiliaryStateResponse
         * @static
         * @param {gcg.ICasAuxiliaryStateResponse} message CasAuxiliaryStateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CasAuxiliaryStateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CasAuxiliaryStateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.CasAuxiliaryStateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.CasAuxiliaryStateResponse} CasAuxiliaryStateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CasAuxiliaryStateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.CasAuxiliaryStateResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.success = reader.bool();
                        break;
                    }
                case 2: {
                        message.index = reader.uint64();
                        break;
                    }
                case 3: {
                        message.value = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CasAuxiliaryStateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.CasAuxiliaryStateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.CasAuxiliaryStateResponse} CasAuxiliaryStateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CasAuxiliaryStateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CasAuxiliaryStateResponse message.
         * @function verify
         * @memberof gcg.CasAuxiliaryStateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CasAuxiliaryStateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.success != null && message.hasOwnProperty("success"))
                if (typeof message.success !== "boolean")
                    return "success: boolean expected";
            if (message.index != null && message.hasOwnProperty("index"))
                if (!$util.isInteger(message.index) && !(message.index && $util.isInteger(message.index.low) && $util.isInteger(message.index.high)))
                    return "index: integer|Long expected";
            if (message.value != null && message.hasOwnProperty("value")) {
                properties._value = 1;
                if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                    return "value: buffer expected";
            }
            return null;
        };

        /**
         * Creates a CasAuxiliaryStateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.CasAuxiliaryStateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.CasAuxiliaryStateResponse} CasAuxiliaryStateResponse
         */
        CasAuxiliaryStateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.CasAuxiliaryStateResponse)
                return object;
            let message = new $root.gcg.CasAuxiliaryStateResponse();
            if (object.success != null)
                message.success = Boolean(object.success);
            if (object.index != null)
                if ($util.Long)
                    (message.index = $util.Long.fromValue(object.index)).unsigned = true;
                else if (typeof object.index === "string")
                    message.index = parseInt(object.index, 10);
                else if (typeof object.index === "number")
                    message.index = object.index;
                else if (typeof object.index === "object")
                    message.index = new $util.LongBits(object.index.low >>> 0, object.index.high >>> 0).toNumber(true);
            if (object.value != null)
                if (typeof object.value === "string")
                    $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                else if (object.value.length >= 0)
                    message.value = object.value;
            return message;
        };

        /**
         * Creates a plain object from a CasAuxiliaryStateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.CasAuxiliaryStateResponse
         * @static
         * @param {gcg.CasAuxiliaryStateResponse} message CasAuxiliaryStateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CasAuxiliaryStateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.success = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.index = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.index = options.longs === String ? "0" : 0;
            }
            if (message.success != null && message.hasOwnProperty("success"))
                object.success = message.success;
            if (message.index != null && message.hasOwnProperty("index"))
                if (typeof message.index === "number")
                    object.index = options.longs === String ? String(message.index) : message.index;
                else
                    object.index = options.longs === String ? $util.Long.prototype.toString.call(message.index) : options.longs === Number ? new $util.LongBits(message.index.low >>> 0, message.index.high >>> 0).toNumber(true) : message.index;
            if (message.value != null && message.hasOwnProperty("value")) {
                object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
                if (options.oneofs)
                    object._value = "value";
            }
            return object;
        };

        /**
         * Converts this CasAuxiliaryStateResponse to JSON.
         * @function toJSON
         * @memberof gcg.CasAuxiliaryStateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CasAuxiliaryStateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CasAuxiliaryStateResponse
         * @function getTypeUrl
         * @memberof gcg.CasAuxiliaryStateResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CasAuxiliaryStateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.CasAuxiliaryStateResponse";
        };

        return CasAuxiliaryStateResponse;
    })();

    gcg.ReadAuxiliaryStateRequest = (function() {

        /**
         * Properties of a ReadAuxiliaryStateRequest.
         * @memberof gcg
         * @interface IReadAuxiliaryStateRequest
         * @property {Uint8Array|null} [dataRoomId] ReadAuxiliaryStateRequest dataRoomId
         */

        /**
         * Constructs a new ReadAuxiliaryStateRequest.
         * @memberof gcg
         * @classdesc Represents a ReadAuxiliaryStateRequest.
         * @implements IReadAuxiliaryStateRequest
         * @constructor
         * @param {gcg.IReadAuxiliaryStateRequest=} [properties] Properties to set
         */
        function ReadAuxiliaryStateRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReadAuxiliaryStateRequest dataRoomId.
         * @member {Uint8Array} dataRoomId
         * @memberof gcg.ReadAuxiliaryStateRequest
         * @instance
         */
        ReadAuxiliaryStateRequest.prototype.dataRoomId = $util.newBuffer([]);

        /**
         * Creates a new ReadAuxiliaryStateRequest instance using the specified properties.
         * @function create
         * @memberof gcg.ReadAuxiliaryStateRequest
         * @static
         * @param {gcg.IReadAuxiliaryStateRequest=} [properties] Properties to set
         * @returns {gcg.ReadAuxiliaryStateRequest} ReadAuxiliaryStateRequest instance
         */
        ReadAuxiliaryStateRequest.create = function create(properties) {
            return new ReadAuxiliaryStateRequest(properties);
        };

        /**
         * Encodes the specified ReadAuxiliaryStateRequest message. Does not implicitly {@link gcg.ReadAuxiliaryStateRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.ReadAuxiliaryStateRequest
         * @static
         * @param {gcg.IReadAuxiliaryStateRequest} message ReadAuxiliaryStateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReadAuxiliaryStateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dataRoomId != null && Object.hasOwnProperty.call(message, "dataRoomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dataRoomId);
            return writer;
        };

        /**
         * Encodes the specified ReadAuxiliaryStateRequest message, length delimited. Does not implicitly {@link gcg.ReadAuxiliaryStateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.ReadAuxiliaryStateRequest
         * @static
         * @param {gcg.IReadAuxiliaryStateRequest} message ReadAuxiliaryStateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReadAuxiliaryStateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReadAuxiliaryStateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.ReadAuxiliaryStateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.ReadAuxiliaryStateRequest} ReadAuxiliaryStateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReadAuxiliaryStateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.ReadAuxiliaryStateRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dataRoomId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReadAuxiliaryStateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.ReadAuxiliaryStateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.ReadAuxiliaryStateRequest} ReadAuxiliaryStateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReadAuxiliaryStateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReadAuxiliaryStateRequest message.
         * @function verify
         * @memberof gcg.ReadAuxiliaryStateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReadAuxiliaryStateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                if (!(message.dataRoomId && typeof message.dataRoomId.length === "number" || $util.isString(message.dataRoomId)))
                    return "dataRoomId: buffer expected";
            return null;
        };

        /**
         * Creates a ReadAuxiliaryStateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.ReadAuxiliaryStateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.ReadAuxiliaryStateRequest} ReadAuxiliaryStateRequest
         */
        ReadAuxiliaryStateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.ReadAuxiliaryStateRequest)
                return object;
            let message = new $root.gcg.ReadAuxiliaryStateRequest();
            if (object.dataRoomId != null)
                if (typeof object.dataRoomId === "string")
                    $util.base64.decode(object.dataRoomId, message.dataRoomId = $util.newBuffer($util.base64.length(object.dataRoomId)), 0);
                else if (object.dataRoomId.length >= 0)
                    message.dataRoomId = object.dataRoomId;
            return message;
        };

        /**
         * Creates a plain object from a ReadAuxiliaryStateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.ReadAuxiliaryStateRequest
         * @static
         * @param {gcg.ReadAuxiliaryStateRequest} message ReadAuxiliaryStateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReadAuxiliaryStateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.dataRoomId = "";
                else {
                    object.dataRoomId = [];
                    if (options.bytes !== Array)
                        object.dataRoomId = $util.newBuffer(object.dataRoomId);
                }
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                object.dataRoomId = options.bytes === String ? $util.base64.encode(message.dataRoomId, 0, message.dataRoomId.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomId) : message.dataRoomId;
            return object;
        };

        /**
         * Converts this ReadAuxiliaryStateRequest to JSON.
         * @function toJSON
         * @memberof gcg.ReadAuxiliaryStateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReadAuxiliaryStateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReadAuxiliaryStateRequest
         * @function getTypeUrl
         * @memberof gcg.ReadAuxiliaryStateRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReadAuxiliaryStateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.ReadAuxiliaryStateRequest";
        };

        return ReadAuxiliaryStateRequest;
    })();

    gcg.ReadAuxiliaryStateResponse = (function() {

        /**
         * Properties of a ReadAuxiliaryStateResponse.
         * @memberof gcg
         * @interface IReadAuxiliaryStateResponse
         * @property {Array.<gcg.IAuxiliaryStateValue>|null} [values] ReadAuxiliaryStateResponse values
         */

        /**
         * Constructs a new ReadAuxiliaryStateResponse.
         * @memberof gcg
         * @classdesc Represents a ReadAuxiliaryStateResponse.
         * @implements IReadAuxiliaryStateResponse
         * @constructor
         * @param {gcg.IReadAuxiliaryStateResponse=} [properties] Properties to set
         */
        function ReadAuxiliaryStateResponse(properties) {
            this.values = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReadAuxiliaryStateResponse values.
         * @member {Array.<gcg.IAuxiliaryStateValue>} values
         * @memberof gcg.ReadAuxiliaryStateResponse
         * @instance
         */
        ReadAuxiliaryStateResponse.prototype.values = $util.emptyArray;

        /**
         * Creates a new ReadAuxiliaryStateResponse instance using the specified properties.
         * @function create
         * @memberof gcg.ReadAuxiliaryStateResponse
         * @static
         * @param {gcg.IReadAuxiliaryStateResponse=} [properties] Properties to set
         * @returns {gcg.ReadAuxiliaryStateResponse} ReadAuxiliaryStateResponse instance
         */
        ReadAuxiliaryStateResponse.create = function create(properties) {
            return new ReadAuxiliaryStateResponse(properties);
        };

        /**
         * Encodes the specified ReadAuxiliaryStateResponse message. Does not implicitly {@link gcg.ReadAuxiliaryStateResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.ReadAuxiliaryStateResponse
         * @static
         * @param {gcg.IReadAuxiliaryStateResponse} message ReadAuxiliaryStateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReadAuxiliaryStateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.values != null && message.values.length)
                for (let i = 0; i < message.values.length; ++i)
                    $root.gcg.AuxiliaryStateValue.encode(message.values[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ReadAuxiliaryStateResponse message, length delimited. Does not implicitly {@link gcg.ReadAuxiliaryStateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.ReadAuxiliaryStateResponse
         * @static
         * @param {gcg.IReadAuxiliaryStateResponse} message ReadAuxiliaryStateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReadAuxiliaryStateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReadAuxiliaryStateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.ReadAuxiliaryStateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.ReadAuxiliaryStateResponse} ReadAuxiliaryStateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReadAuxiliaryStateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.ReadAuxiliaryStateResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        if (!(message.values && message.values.length))
                            message.values = [];
                        message.values.push($root.gcg.AuxiliaryStateValue.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReadAuxiliaryStateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.ReadAuxiliaryStateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.ReadAuxiliaryStateResponse} ReadAuxiliaryStateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReadAuxiliaryStateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReadAuxiliaryStateResponse message.
         * @function verify
         * @memberof gcg.ReadAuxiliaryStateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReadAuxiliaryStateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.values != null && message.hasOwnProperty("values")) {
                if (!Array.isArray(message.values))
                    return "values: array expected";
                for (let i = 0; i < message.values.length; ++i) {
                    let error = $root.gcg.AuxiliaryStateValue.verify(message.values[i]);
                    if (error)
                        return "values." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ReadAuxiliaryStateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.ReadAuxiliaryStateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.ReadAuxiliaryStateResponse} ReadAuxiliaryStateResponse
         */
        ReadAuxiliaryStateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.ReadAuxiliaryStateResponse)
                return object;
            let message = new $root.gcg.ReadAuxiliaryStateResponse();
            if (object.values) {
                if (!Array.isArray(object.values))
                    throw TypeError(".gcg.ReadAuxiliaryStateResponse.values: array expected");
                message.values = [];
                for (let i = 0; i < object.values.length; ++i) {
                    if (typeof object.values[i] !== "object")
                        throw TypeError(".gcg.ReadAuxiliaryStateResponse.values: object expected");
                    message.values[i] = $root.gcg.AuxiliaryStateValue.fromObject(object.values[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ReadAuxiliaryStateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.ReadAuxiliaryStateResponse
         * @static
         * @param {gcg.ReadAuxiliaryStateResponse} message ReadAuxiliaryStateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReadAuxiliaryStateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.values = [];
            if (message.values && message.values.length) {
                object.values = [];
                for (let j = 0; j < message.values.length; ++j)
                    object.values[j] = $root.gcg.AuxiliaryStateValue.toObject(message.values[j], options);
            }
            return object;
        };

        /**
         * Converts this ReadAuxiliaryStateResponse to JSON.
         * @function toJSON
         * @memberof gcg.ReadAuxiliaryStateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReadAuxiliaryStateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReadAuxiliaryStateResponse
         * @function getTypeUrl
         * @memberof gcg.ReadAuxiliaryStateResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReadAuxiliaryStateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.ReadAuxiliaryStateResponse";
        };

        return ReadAuxiliaryStateResponse;
    })();

    gcg.AuxiliaryStateValue = (function() {

        /**
         * Properties of an AuxiliaryStateValue.
         * @memberof gcg
         * @interface IAuxiliaryStateValue
         * @property {string|null} [user] AuxiliaryStateValue user
         * @property {number|Long|null} [index] AuxiliaryStateValue index
         * @property {Uint8Array|null} [value] AuxiliaryStateValue value
         */

        /**
         * Constructs a new AuxiliaryStateValue.
         * @memberof gcg
         * @classdesc Represents an AuxiliaryStateValue.
         * @implements IAuxiliaryStateValue
         * @constructor
         * @param {gcg.IAuxiliaryStateValue=} [properties] Properties to set
         */
        function AuxiliaryStateValue(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AuxiliaryStateValue user.
         * @member {string} user
         * @memberof gcg.AuxiliaryStateValue
         * @instance
         */
        AuxiliaryStateValue.prototype.user = "";

        /**
         * AuxiliaryStateValue index.
         * @member {number|Long} index
         * @memberof gcg.AuxiliaryStateValue
         * @instance
         */
        AuxiliaryStateValue.prototype.index = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AuxiliaryStateValue value.
         * @member {Uint8Array} value
         * @memberof gcg.AuxiliaryStateValue
         * @instance
         */
        AuxiliaryStateValue.prototype.value = $util.newBuffer([]);

        /**
         * Creates a new AuxiliaryStateValue instance using the specified properties.
         * @function create
         * @memberof gcg.AuxiliaryStateValue
         * @static
         * @param {gcg.IAuxiliaryStateValue=} [properties] Properties to set
         * @returns {gcg.AuxiliaryStateValue} AuxiliaryStateValue instance
         */
        AuxiliaryStateValue.create = function create(properties) {
            return new AuxiliaryStateValue(properties);
        };

        /**
         * Encodes the specified AuxiliaryStateValue message. Does not implicitly {@link gcg.AuxiliaryStateValue.verify|verify} messages.
         * @function encode
         * @memberof gcg.AuxiliaryStateValue
         * @static
         * @param {gcg.IAuxiliaryStateValue} message AuxiliaryStateValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuxiliaryStateValue.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.user);
            if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.index);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.value);
            return writer;
        };

        /**
         * Encodes the specified AuxiliaryStateValue message, length delimited. Does not implicitly {@link gcg.AuxiliaryStateValue.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.AuxiliaryStateValue
         * @static
         * @param {gcg.IAuxiliaryStateValue} message AuxiliaryStateValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuxiliaryStateValue.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AuxiliaryStateValue message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.AuxiliaryStateValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.AuxiliaryStateValue} AuxiliaryStateValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuxiliaryStateValue.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.AuxiliaryStateValue();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.user = reader.string();
                        break;
                    }
                case 2: {
                        message.index = reader.uint64();
                        break;
                    }
                case 3: {
                        message.value = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AuxiliaryStateValue message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.AuxiliaryStateValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.AuxiliaryStateValue} AuxiliaryStateValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuxiliaryStateValue.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AuxiliaryStateValue message.
         * @function verify
         * @memberof gcg.AuxiliaryStateValue
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AuxiliaryStateValue.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.user != null && message.hasOwnProperty("user"))
                if (!$util.isString(message.user))
                    return "user: string expected";
            if (message.index != null && message.hasOwnProperty("index"))
                if (!$util.isInteger(message.index) && !(message.index && $util.isInteger(message.index.low) && $util.isInteger(message.index.high)))
                    return "index: integer|Long expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                    return "value: buffer expected";
            return null;
        };

        /**
         * Creates an AuxiliaryStateValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.AuxiliaryStateValue
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.AuxiliaryStateValue} AuxiliaryStateValue
         */
        AuxiliaryStateValue.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.AuxiliaryStateValue)
                return object;
            let message = new $root.gcg.AuxiliaryStateValue();
            if (object.user != null)
                message.user = String(object.user);
            if (object.index != null)
                if ($util.Long)
                    (message.index = $util.Long.fromValue(object.index)).unsigned = true;
                else if (typeof object.index === "string")
                    message.index = parseInt(object.index, 10);
                else if (typeof object.index === "number")
                    message.index = object.index;
                else if (typeof object.index === "object")
                    message.index = new $util.LongBits(object.index.low >>> 0, object.index.high >>> 0).toNumber(true);
            if (object.value != null)
                if (typeof object.value === "string")
                    $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                else if (object.value.length >= 0)
                    message.value = object.value;
            return message;
        };

        /**
         * Creates a plain object from an AuxiliaryStateValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.AuxiliaryStateValue
         * @static
         * @param {gcg.AuxiliaryStateValue} message AuxiliaryStateValue
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuxiliaryStateValue.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.user = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.index = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.index = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.value = "";
                else {
                    object.value = [];
                    if (options.bytes !== Array)
                        object.value = $util.newBuffer(object.value);
                }
            }
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = message.user;
            if (message.index != null && message.hasOwnProperty("index"))
                if (typeof message.index === "number")
                    object.index = options.longs === String ? String(message.index) : message.index;
                else
                    object.index = options.longs === String ? $util.Long.prototype.toString.call(message.index) : options.longs === Number ? new $util.LongBits(message.index.low >>> 0, message.index.high >>> 0).toNumber(true) : message.index;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
            return object;
        };

        /**
         * Converts this AuxiliaryStateValue to JSON.
         * @function toJSON
         * @memberof gcg.AuxiliaryStateValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuxiliaryStateValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AuxiliaryStateValue
         * @function getTypeUrl
         * @memberof gcg.AuxiliaryStateValue
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AuxiliaryStateValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.AuxiliaryStateValue";
        };

        return AuxiliaryStateValue;
    })();

    gcg.MergeConfigurationCommitResponse = (function() {

        /**
         * Properties of a MergeConfigurationCommitResponse.
         * @memberof gcg
         * @interface IMergeConfigurationCommitResponse
         */

        /**
         * Constructs a new MergeConfigurationCommitResponse.
         * @memberof gcg
         * @classdesc Represents a MergeConfigurationCommitResponse.
         * @implements IMergeConfigurationCommitResponse
         * @constructor
         * @param {gcg.IMergeConfigurationCommitResponse=} [properties] Properties to set
         */
        function MergeConfigurationCommitResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new MergeConfigurationCommitResponse instance using the specified properties.
         * @function create
         * @memberof gcg.MergeConfigurationCommitResponse
         * @static
         * @param {gcg.IMergeConfigurationCommitResponse=} [properties] Properties to set
         * @returns {gcg.MergeConfigurationCommitResponse} MergeConfigurationCommitResponse instance
         */
        MergeConfigurationCommitResponse.create = function create(properties) {
            return new MergeConfigurationCommitResponse(properties);
        };

        /**
         * Encodes the specified MergeConfigurationCommitResponse message. Does not implicitly {@link gcg.MergeConfigurationCommitResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.MergeConfigurationCommitResponse
         * @static
         * @param {gcg.IMergeConfigurationCommitResponse} message MergeConfigurationCommitResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MergeConfigurationCommitResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified MergeConfigurationCommitResponse message, length delimited. Does not implicitly {@link gcg.MergeConfigurationCommitResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.MergeConfigurationCommitResponse
         * @static
         * @param {gcg.IMergeConfigurationCommitResponse} message MergeConfigurationCommitResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MergeConfigurationCommitResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MergeConfigurationCommitResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.MergeConfigurationCommitResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.MergeConfigurationCommitResponse} MergeConfigurationCommitResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MergeConfigurationCommitResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.MergeConfigurationCommitResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MergeConfigurationCommitResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.MergeConfigurationCommitResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.MergeConfigurationCommitResponse} MergeConfigurationCommitResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MergeConfigurationCommitResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MergeConfigurationCommitResponse message.
         * @function verify
         * @memberof gcg.MergeConfigurationCommitResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MergeConfigurationCommitResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a MergeConfigurationCommitResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.MergeConfigurationCommitResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.MergeConfigurationCommitResponse} MergeConfigurationCommitResponse
         */
        MergeConfigurationCommitResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.MergeConfigurationCommitResponse)
                return object;
            return new $root.gcg.MergeConfigurationCommitResponse();
        };

        /**
         * Creates a plain object from a MergeConfigurationCommitResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.MergeConfigurationCommitResponse
         * @static
         * @param {gcg.MergeConfigurationCommitResponse} message MergeConfigurationCommitResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MergeConfigurationCommitResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this MergeConfigurationCommitResponse to JSON.
         * @function toJSON
         * @memberof gcg.MergeConfigurationCommitResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MergeConfigurationCommitResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MergeConfigurationCommitResponse
         * @function getTypeUrl
         * @memberof gcg.MergeConfigurationCommitResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MergeConfigurationCommitResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.MergeConfigurationCommitResponse";
        };

        return MergeConfigurationCommitResponse;
    })();

    gcg.DriverTaskConfig = (function() {

        /**
         * Properties of a DriverTaskConfig.
         * @memberof gcg
         * @interface IDriverTaskConfig
         * @property {gcg.INoopConfig|null} [noop] DriverTaskConfig noop
         * @property {gcg.IStaticContentConfig|null} [staticContent] DriverTaskConfig staticContent
         */

        /**
         * Constructs a new DriverTaskConfig.
         * @memberof gcg
         * @classdesc Represents a DriverTaskConfig.
         * @implements IDriverTaskConfig
         * @constructor
         * @param {gcg.IDriverTaskConfig=} [properties] Properties to set
         */
        function DriverTaskConfig(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DriverTaskConfig noop.
         * @member {gcg.INoopConfig|null|undefined} noop
         * @memberof gcg.DriverTaskConfig
         * @instance
         */
        DriverTaskConfig.prototype.noop = null;

        /**
         * DriverTaskConfig staticContent.
         * @member {gcg.IStaticContentConfig|null|undefined} staticContent
         * @memberof gcg.DriverTaskConfig
         * @instance
         */
        DriverTaskConfig.prototype.staticContent = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * DriverTaskConfig driverTaskConfig.
         * @member {"noop"|"staticContent"|undefined} driverTaskConfig
         * @memberof gcg.DriverTaskConfig
         * @instance
         */
        Object.defineProperty(DriverTaskConfig.prototype, "driverTaskConfig", {
            get: $util.oneOfGetter($oneOfFields = ["noop", "staticContent"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new DriverTaskConfig instance using the specified properties.
         * @function create
         * @memberof gcg.DriverTaskConfig
         * @static
         * @param {gcg.IDriverTaskConfig=} [properties] Properties to set
         * @returns {gcg.DriverTaskConfig} DriverTaskConfig instance
         */
        DriverTaskConfig.create = function create(properties) {
            return new DriverTaskConfig(properties);
        };

        /**
         * Encodes the specified DriverTaskConfig message. Does not implicitly {@link gcg.DriverTaskConfig.verify|verify} messages.
         * @function encode
         * @memberof gcg.DriverTaskConfig
         * @static
         * @param {gcg.IDriverTaskConfig} message DriverTaskConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DriverTaskConfig.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.noop != null && Object.hasOwnProperty.call(message, "noop"))
                $root.gcg.NoopConfig.encode(message.noop, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.staticContent != null && Object.hasOwnProperty.call(message, "staticContent"))
                $root.gcg.StaticContentConfig.encode(message.staticContent, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DriverTaskConfig message, length delimited. Does not implicitly {@link gcg.DriverTaskConfig.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.DriverTaskConfig
         * @static
         * @param {gcg.IDriverTaskConfig} message DriverTaskConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DriverTaskConfig.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DriverTaskConfig message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.DriverTaskConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.DriverTaskConfig} DriverTaskConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DriverTaskConfig.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.DriverTaskConfig();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.noop = $root.gcg.NoopConfig.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.staticContent = $root.gcg.StaticContentConfig.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DriverTaskConfig message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.DriverTaskConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.DriverTaskConfig} DriverTaskConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DriverTaskConfig.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DriverTaskConfig message.
         * @function verify
         * @memberof gcg.DriverTaskConfig
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DriverTaskConfig.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.noop != null && message.hasOwnProperty("noop")) {
                properties.driverTaskConfig = 1;
                {
                    let error = $root.gcg.NoopConfig.verify(message.noop);
                    if (error)
                        return "noop." + error;
                }
            }
            if (message.staticContent != null && message.hasOwnProperty("staticContent")) {
                if (properties.driverTaskConfig === 1)
                    return "driverTaskConfig: multiple values";
                properties.driverTaskConfig = 1;
                {
                    let error = $root.gcg.StaticContentConfig.verify(message.staticContent);
                    if (error)
                        return "staticContent." + error;
                }
            }
            return null;
        };

        /**
         * Creates a DriverTaskConfig message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.DriverTaskConfig
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.DriverTaskConfig} DriverTaskConfig
         */
        DriverTaskConfig.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.DriverTaskConfig)
                return object;
            let message = new $root.gcg.DriverTaskConfig();
            if (object.noop != null) {
                if (typeof object.noop !== "object")
                    throw TypeError(".gcg.DriverTaskConfig.noop: object expected");
                message.noop = $root.gcg.NoopConfig.fromObject(object.noop);
            }
            if (object.staticContent != null) {
                if (typeof object.staticContent !== "object")
                    throw TypeError(".gcg.DriverTaskConfig.staticContent: object expected");
                message.staticContent = $root.gcg.StaticContentConfig.fromObject(object.staticContent);
            }
            return message;
        };

        /**
         * Creates a plain object from a DriverTaskConfig message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.DriverTaskConfig
         * @static
         * @param {gcg.DriverTaskConfig} message DriverTaskConfig
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DriverTaskConfig.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.noop != null && message.hasOwnProperty("noop")) {
                object.noop = $root.gcg.NoopConfig.toObject(message.noop, options);
                if (options.oneofs)
                    object.driverTaskConfig = "noop";
            }
            if (message.staticContent != null && message.hasOwnProperty("staticContent")) {
                object.staticContent = $root.gcg.StaticContentConfig.toObject(message.staticContent, options);
                if (options.oneofs)
                    object.driverTaskConfig = "staticContent";
            }
            return object;
        };

        /**
         * Converts this DriverTaskConfig to JSON.
         * @function toJSON
         * @memberof gcg.DriverTaskConfig
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DriverTaskConfig.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DriverTaskConfig
         * @function getTypeUrl
         * @memberof gcg.DriverTaskConfig
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DriverTaskConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.DriverTaskConfig";
        };

        return DriverTaskConfig;
    })();

    gcg.NoopConfig = (function() {

        /**
         * Properties of a NoopConfig.
         * @memberof gcg
         * @interface INoopConfig
         */

        /**
         * Constructs a new NoopConfig.
         * @memberof gcg
         * @classdesc Represents a NoopConfig.
         * @implements INoopConfig
         * @constructor
         * @param {gcg.INoopConfig=} [properties] Properties to set
         */
        function NoopConfig(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new NoopConfig instance using the specified properties.
         * @function create
         * @memberof gcg.NoopConfig
         * @static
         * @param {gcg.INoopConfig=} [properties] Properties to set
         * @returns {gcg.NoopConfig} NoopConfig instance
         */
        NoopConfig.create = function create(properties) {
            return new NoopConfig(properties);
        };

        /**
         * Encodes the specified NoopConfig message. Does not implicitly {@link gcg.NoopConfig.verify|verify} messages.
         * @function encode
         * @memberof gcg.NoopConfig
         * @static
         * @param {gcg.INoopConfig} message NoopConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NoopConfig.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified NoopConfig message, length delimited. Does not implicitly {@link gcg.NoopConfig.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.NoopConfig
         * @static
         * @param {gcg.INoopConfig} message NoopConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NoopConfig.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NoopConfig message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.NoopConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.NoopConfig} NoopConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NoopConfig.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.NoopConfig();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NoopConfig message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.NoopConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.NoopConfig} NoopConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NoopConfig.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NoopConfig message.
         * @function verify
         * @memberof gcg.NoopConfig
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NoopConfig.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a NoopConfig message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.NoopConfig
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.NoopConfig} NoopConfig
         */
        NoopConfig.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.NoopConfig)
                return object;
            return new $root.gcg.NoopConfig();
        };

        /**
         * Creates a plain object from a NoopConfig message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.NoopConfig
         * @static
         * @param {gcg.NoopConfig} message NoopConfig
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NoopConfig.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this NoopConfig to JSON.
         * @function toJSON
         * @memberof gcg.NoopConfig
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NoopConfig.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NoopConfig
         * @function getTypeUrl
         * @memberof gcg.NoopConfig
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NoopConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.NoopConfig";
        };

        return NoopConfig;
    })();

    gcg.StaticContentConfig = (function() {

        /**
         * Properties of a StaticContentConfig.
         * @memberof gcg
         * @interface IStaticContentConfig
         * @property {Uint8Array|null} [content] StaticContentConfig content
         */

        /**
         * Constructs a new StaticContentConfig.
         * @memberof gcg
         * @classdesc Represents a StaticContentConfig.
         * @implements IStaticContentConfig
         * @constructor
         * @param {gcg.IStaticContentConfig=} [properties] Properties to set
         */
        function StaticContentConfig(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StaticContentConfig content.
         * @member {Uint8Array} content
         * @memberof gcg.StaticContentConfig
         * @instance
         */
        StaticContentConfig.prototype.content = $util.newBuffer([]);

        /**
         * Creates a new StaticContentConfig instance using the specified properties.
         * @function create
         * @memberof gcg.StaticContentConfig
         * @static
         * @param {gcg.IStaticContentConfig=} [properties] Properties to set
         * @returns {gcg.StaticContentConfig} StaticContentConfig instance
         */
        StaticContentConfig.create = function create(properties) {
            return new StaticContentConfig(properties);
        };

        /**
         * Encodes the specified StaticContentConfig message. Does not implicitly {@link gcg.StaticContentConfig.verify|verify} messages.
         * @function encode
         * @memberof gcg.StaticContentConfig
         * @static
         * @param {gcg.IStaticContentConfig} message StaticContentConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StaticContentConfig.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.content);
            return writer;
        };

        /**
         * Encodes the specified StaticContentConfig message, length delimited. Does not implicitly {@link gcg.StaticContentConfig.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.StaticContentConfig
         * @static
         * @param {gcg.IStaticContentConfig} message StaticContentConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StaticContentConfig.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StaticContentConfig message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.StaticContentConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.StaticContentConfig} StaticContentConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StaticContentConfig.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.StaticContentConfig();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.content = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StaticContentConfig message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.StaticContentConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.StaticContentConfig} StaticContentConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StaticContentConfig.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StaticContentConfig message.
         * @function verify
         * @memberof gcg.StaticContentConfig
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StaticContentConfig.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.content != null && message.hasOwnProperty("content"))
                if (!(message.content && typeof message.content.length === "number" || $util.isString(message.content)))
                    return "content: buffer expected";
            return null;
        };

        /**
         * Creates a StaticContentConfig message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.StaticContentConfig
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.StaticContentConfig} StaticContentConfig
         */
        StaticContentConfig.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.StaticContentConfig)
                return object;
            let message = new $root.gcg.StaticContentConfig();
            if (object.content != null)
                if (typeof object.content === "string")
                    $util.base64.decode(object.content, message.content = $util.newBuffer($util.base64.length(object.content)), 0);
                else if (object.content.length >= 0)
                    message.content = object.content;
            return message;
        };

        /**
         * Creates a plain object from a StaticContentConfig message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.StaticContentConfig
         * @static
         * @param {gcg.StaticContentConfig} message StaticContentConfig
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StaticContentConfig.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.content = "";
                else {
                    object.content = [];
                    if (options.bytes !== Array)
                        object.content = $util.newBuffer(object.content);
                }
            if (message.content != null && message.hasOwnProperty("content"))
                object.content = options.bytes === String ? $util.base64.encode(message.content, 0, message.content.length) : options.bytes === Array ? Array.prototype.slice.call(message.content) : message.content;
            return object;
        };

        /**
         * Converts this StaticContentConfig to JSON.
         * @function toJSON
         * @memberof gcg.StaticContentConfig
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StaticContentConfig.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StaticContentConfig
         * @function getTypeUrl
         * @memberof gcg.StaticContentConfig
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StaticContentConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.StaticContentConfig";
        };

        return StaticContentConfig;
    })();

    gcg.RetrieveUsedAirlockQuotaRequest = (function() {

        /**
         * Properties of a RetrieveUsedAirlockQuotaRequest.
         * @memberof gcg
         * @interface IRetrieveUsedAirlockQuotaRequest
         * @property {Uint8Array|null} [dataRoomId] RetrieveUsedAirlockQuotaRequest dataRoomId
         */

        /**
         * Constructs a new RetrieveUsedAirlockQuotaRequest.
         * @memberof gcg
         * @classdesc Represents a RetrieveUsedAirlockQuotaRequest.
         * @implements IRetrieveUsedAirlockQuotaRequest
         * @constructor
         * @param {gcg.IRetrieveUsedAirlockQuotaRequest=} [properties] Properties to set
         */
        function RetrieveUsedAirlockQuotaRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RetrieveUsedAirlockQuotaRequest dataRoomId.
         * @member {Uint8Array} dataRoomId
         * @memberof gcg.RetrieveUsedAirlockQuotaRequest
         * @instance
         */
        RetrieveUsedAirlockQuotaRequest.prototype.dataRoomId = $util.newBuffer([]);

        /**
         * Creates a new RetrieveUsedAirlockQuotaRequest instance using the specified properties.
         * @function create
         * @memberof gcg.RetrieveUsedAirlockQuotaRequest
         * @static
         * @param {gcg.IRetrieveUsedAirlockQuotaRequest=} [properties] Properties to set
         * @returns {gcg.RetrieveUsedAirlockQuotaRequest} RetrieveUsedAirlockQuotaRequest instance
         */
        RetrieveUsedAirlockQuotaRequest.create = function create(properties) {
            return new RetrieveUsedAirlockQuotaRequest(properties);
        };

        /**
         * Encodes the specified RetrieveUsedAirlockQuotaRequest message. Does not implicitly {@link gcg.RetrieveUsedAirlockQuotaRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.RetrieveUsedAirlockQuotaRequest
         * @static
         * @param {gcg.IRetrieveUsedAirlockQuotaRequest} message RetrieveUsedAirlockQuotaRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveUsedAirlockQuotaRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dataRoomId != null && Object.hasOwnProperty.call(message, "dataRoomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dataRoomId);
            return writer;
        };

        /**
         * Encodes the specified RetrieveUsedAirlockQuotaRequest message, length delimited. Does not implicitly {@link gcg.RetrieveUsedAirlockQuotaRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.RetrieveUsedAirlockQuotaRequest
         * @static
         * @param {gcg.IRetrieveUsedAirlockQuotaRequest} message RetrieveUsedAirlockQuotaRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveUsedAirlockQuotaRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrieveUsedAirlockQuotaRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.RetrieveUsedAirlockQuotaRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.RetrieveUsedAirlockQuotaRequest} RetrieveUsedAirlockQuotaRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveUsedAirlockQuotaRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.RetrieveUsedAirlockQuotaRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dataRoomId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrieveUsedAirlockQuotaRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.RetrieveUsedAirlockQuotaRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.RetrieveUsedAirlockQuotaRequest} RetrieveUsedAirlockQuotaRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveUsedAirlockQuotaRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrieveUsedAirlockQuotaRequest message.
         * @function verify
         * @memberof gcg.RetrieveUsedAirlockQuotaRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrieveUsedAirlockQuotaRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                if (!(message.dataRoomId && typeof message.dataRoomId.length === "number" || $util.isString(message.dataRoomId)))
                    return "dataRoomId: buffer expected";
            return null;
        };

        /**
         * Creates a RetrieveUsedAirlockQuotaRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.RetrieveUsedAirlockQuotaRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.RetrieveUsedAirlockQuotaRequest} RetrieveUsedAirlockQuotaRequest
         */
        RetrieveUsedAirlockQuotaRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.RetrieveUsedAirlockQuotaRequest)
                return object;
            let message = new $root.gcg.RetrieveUsedAirlockQuotaRequest();
            if (object.dataRoomId != null)
                if (typeof object.dataRoomId === "string")
                    $util.base64.decode(object.dataRoomId, message.dataRoomId = $util.newBuffer($util.base64.length(object.dataRoomId)), 0);
                else if (object.dataRoomId.length >= 0)
                    message.dataRoomId = object.dataRoomId;
            return message;
        };

        /**
         * Creates a plain object from a RetrieveUsedAirlockQuotaRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.RetrieveUsedAirlockQuotaRequest
         * @static
         * @param {gcg.RetrieveUsedAirlockQuotaRequest} message RetrieveUsedAirlockQuotaRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrieveUsedAirlockQuotaRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.dataRoomId = "";
                else {
                    object.dataRoomId = [];
                    if (options.bytes !== Array)
                        object.dataRoomId = $util.newBuffer(object.dataRoomId);
                }
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                object.dataRoomId = options.bytes === String ? $util.base64.encode(message.dataRoomId, 0, message.dataRoomId.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomId) : message.dataRoomId;
            return object;
        };

        /**
         * Converts this RetrieveUsedAirlockQuotaRequest to JSON.
         * @function toJSON
         * @memberof gcg.RetrieveUsedAirlockQuotaRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrieveUsedAirlockQuotaRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrieveUsedAirlockQuotaRequest
         * @function getTypeUrl
         * @memberof gcg.RetrieveUsedAirlockQuotaRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrieveUsedAirlockQuotaRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.RetrieveUsedAirlockQuotaRequest";
        };

        return RetrieveUsedAirlockQuotaRequest;
    })();

    gcg.RetrieveUsedAirlockQuotaResponse = (function() {

        /**
         * Properties of a RetrieveUsedAirlockQuotaResponse.
         * @memberof gcg
         * @interface IRetrieveUsedAirlockQuotaResponse
         * @property {Array.<gcg.IAirlockQuotaInfo>|null} [airlockQuotas] RetrieveUsedAirlockQuotaResponse airlockQuotas
         */

        /**
         * Constructs a new RetrieveUsedAirlockQuotaResponse.
         * @memberof gcg
         * @classdesc Represents a RetrieveUsedAirlockQuotaResponse.
         * @implements IRetrieveUsedAirlockQuotaResponse
         * @constructor
         * @param {gcg.IRetrieveUsedAirlockQuotaResponse=} [properties] Properties to set
         */
        function RetrieveUsedAirlockQuotaResponse(properties) {
            this.airlockQuotas = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RetrieveUsedAirlockQuotaResponse airlockQuotas.
         * @member {Array.<gcg.IAirlockQuotaInfo>} airlockQuotas
         * @memberof gcg.RetrieveUsedAirlockQuotaResponse
         * @instance
         */
        RetrieveUsedAirlockQuotaResponse.prototype.airlockQuotas = $util.emptyArray;

        /**
         * Creates a new RetrieveUsedAirlockQuotaResponse instance using the specified properties.
         * @function create
         * @memberof gcg.RetrieveUsedAirlockQuotaResponse
         * @static
         * @param {gcg.IRetrieveUsedAirlockQuotaResponse=} [properties] Properties to set
         * @returns {gcg.RetrieveUsedAirlockQuotaResponse} RetrieveUsedAirlockQuotaResponse instance
         */
        RetrieveUsedAirlockQuotaResponse.create = function create(properties) {
            return new RetrieveUsedAirlockQuotaResponse(properties);
        };

        /**
         * Encodes the specified RetrieveUsedAirlockQuotaResponse message. Does not implicitly {@link gcg.RetrieveUsedAirlockQuotaResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.RetrieveUsedAirlockQuotaResponse
         * @static
         * @param {gcg.IRetrieveUsedAirlockQuotaResponse} message RetrieveUsedAirlockQuotaResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveUsedAirlockQuotaResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.airlockQuotas != null && message.airlockQuotas.length)
                for (let i = 0; i < message.airlockQuotas.length; ++i)
                    $root.gcg.AirlockQuotaInfo.encode(message.airlockQuotas[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RetrieveUsedAirlockQuotaResponse message, length delimited. Does not implicitly {@link gcg.RetrieveUsedAirlockQuotaResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.RetrieveUsedAirlockQuotaResponse
         * @static
         * @param {gcg.IRetrieveUsedAirlockQuotaResponse} message RetrieveUsedAirlockQuotaResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveUsedAirlockQuotaResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RetrieveUsedAirlockQuotaResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.RetrieveUsedAirlockQuotaResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.RetrieveUsedAirlockQuotaResponse} RetrieveUsedAirlockQuotaResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveUsedAirlockQuotaResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.RetrieveUsedAirlockQuotaResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.airlockQuotas && message.airlockQuotas.length))
                            message.airlockQuotas = [];
                        message.airlockQuotas.push($root.gcg.AirlockQuotaInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RetrieveUsedAirlockQuotaResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.RetrieveUsedAirlockQuotaResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.RetrieveUsedAirlockQuotaResponse} RetrieveUsedAirlockQuotaResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveUsedAirlockQuotaResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RetrieveUsedAirlockQuotaResponse message.
         * @function verify
         * @memberof gcg.RetrieveUsedAirlockQuotaResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RetrieveUsedAirlockQuotaResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.airlockQuotas != null && message.hasOwnProperty("airlockQuotas")) {
                if (!Array.isArray(message.airlockQuotas))
                    return "airlockQuotas: array expected";
                for (let i = 0; i < message.airlockQuotas.length; ++i) {
                    let error = $root.gcg.AirlockQuotaInfo.verify(message.airlockQuotas[i]);
                    if (error)
                        return "airlockQuotas." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RetrieveUsedAirlockQuotaResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.RetrieveUsedAirlockQuotaResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.RetrieveUsedAirlockQuotaResponse} RetrieveUsedAirlockQuotaResponse
         */
        RetrieveUsedAirlockQuotaResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.RetrieveUsedAirlockQuotaResponse)
                return object;
            let message = new $root.gcg.RetrieveUsedAirlockQuotaResponse();
            if (object.airlockQuotas) {
                if (!Array.isArray(object.airlockQuotas))
                    throw TypeError(".gcg.RetrieveUsedAirlockQuotaResponse.airlockQuotas: array expected");
                message.airlockQuotas = [];
                for (let i = 0; i < object.airlockQuotas.length; ++i) {
                    if (typeof object.airlockQuotas[i] !== "object")
                        throw TypeError(".gcg.RetrieveUsedAirlockQuotaResponse.airlockQuotas: object expected");
                    message.airlockQuotas[i] = $root.gcg.AirlockQuotaInfo.fromObject(object.airlockQuotas[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a RetrieveUsedAirlockQuotaResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.RetrieveUsedAirlockQuotaResponse
         * @static
         * @param {gcg.RetrieveUsedAirlockQuotaResponse} message RetrieveUsedAirlockQuotaResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetrieveUsedAirlockQuotaResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.airlockQuotas = [];
            if (message.airlockQuotas && message.airlockQuotas.length) {
                object.airlockQuotas = [];
                for (let j = 0; j < message.airlockQuotas.length; ++j)
                    object.airlockQuotas[j] = $root.gcg.AirlockQuotaInfo.toObject(message.airlockQuotas[j], options);
            }
            return object;
        };

        /**
         * Converts this RetrieveUsedAirlockQuotaResponse to JSON.
         * @function toJSON
         * @memberof gcg.RetrieveUsedAirlockQuotaResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetrieveUsedAirlockQuotaResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetrieveUsedAirlockQuotaResponse
         * @function getTypeUrl
         * @memberof gcg.RetrieveUsedAirlockQuotaResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetrieveUsedAirlockQuotaResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.RetrieveUsedAirlockQuotaResponse";
        };

        return RetrieveUsedAirlockQuotaResponse;
    })();

    gcg.AirlockQuotaInfo = (function() {

        /**
         * Properties of an AirlockQuotaInfo.
         * @memberof gcg
         * @interface IAirlockQuotaInfo
         * @property {string|null} [airlockNodeId] AirlockQuotaInfo airlockNodeId
         * @property {number|Long|null} [quotaBytes] AirlockQuotaInfo quotaBytes
         * @property {number|Long|null} [usedQuotaBytes] AirlockQuotaInfo usedQuotaBytes
         */

        /**
         * Constructs a new AirlockQuotaInfo.
         * @memberof gcg
         * @classdesc Represents an AirlockQuotaInfo.
         * @implements IAirlockQuotaInfo
         * @constructor
         * @param {gcg.IAirlockQuotaInfo=} [properties] Properties to set
         */
        function AirlockQuotaInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AirlockQuotaInfo airlockNodeId.
         * @member {string} airlockNodeId
         * @memberof gcg.AirlockQuotaInfo
         * @instance
         */
        AirlockQuotaInfo.prototype.airlockNodeId = "";

        /**
         * AirlockQuotaInfo quotaBytes.
         * @member {number|Long} quotaBytes
         * @memberof gcg.AirlockQuotaInfo
         * @instance
         */
        AirlockQuotaInfo.prototype.quotaBytes = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AirlockQuotaInfo usedQuotaBytes.
         * @member {number|Long} usedQuotaBytes
         * @memberof gcg.AirlockQuotaInfo
         * @instance
         */
        AirlockQuotaInfo.prototype.usedQuotaBytes = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new AirlockQuotaInfo instance using the specified properties.
         * @function create
         * @memberof gcg.AirlockQuotaInfo
         * @static
         * @param {gcg.IAirlockQuotaInfo=} [properties] Properties to set
         * @returns {gcg.AirlockQuotaInfo} AirlockQuotaInfo instance
         */
        AirlockQuotaInfo.create = function create(properties) {
            return new AirlockQuotaInfo(properties);
        };

        /**
         * Encodes the specified AirlockQuotaInfo message. Does not implicitly {@link gcg.AirlockQuotaInfo.verify|verify} messages.
         * @function encode
         * @memberof gcg.AirlockQuotaInfo
         * @static
         * @param {gcg.IAirlockQuotaInfo} message AirlockQuotaInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AirlockQuotaInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.airlockNodeId != null && Object.hasOwnProperty.call(message, "airlockNodeId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.airlockNodeId);
            if (message.quotaBytes != null && Object.hasOwnProperty.call(message, "quotaBytes"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.quotaBytes);
            if (message.usedQuotaBytes != null && Object.hasOwnProperty.call(message, "usedQuotaBytes"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.usedQuotaBytes);
            return writer;
        };

        /**
         * Encodes the specified AirlockQuotaInfo message, length delimited. Does not implicitly {@link gcg.AirlockQuotaInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.AirlockQuotaInfo
         * @static
         * @param {gcg.IAirlockQuotaInfo} message AirlockQuotaInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AirlockQuotaInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AirlockQuotaInfo message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.AirlockQuotaInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.AirlockQuotaInfo} AirlockQuotaInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AirlockQuotaInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.AirlockQuotaInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.airlockNodeId = reader.string();
                        break;
                    }
                case 2: {
                        message.quotaBytes = reader.uint64();
                        break;
                    }
                case 3: {
                        message.usedQuotaBytes = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AirlockQuotaInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.AirlockQuotaInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.AirlockQuotaInfo} AirlockQuotaInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AirlockQuotaInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AirlockQuotaInfo message.
         * @function verify
         * @memberof gcg.AirlockQuotaInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AirlockQuotaInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.airlockNodeId != null && message.hasOwnProperty("airlockNodeId"))
                if (!$util.isString(message.airlockNodeId))
                    return "airlockNodeId: string expected";
            if (message.quotaBytes != null && message.hasOwnProperty("quotaBytes"))
                if (!$util.isInteger(message.quotaBytes) && !(message.quotaBytes && $util.isInteger(message.quotaBytes.low) && $util.isInteger(message.quotaBytes.high)))
                    return "quotaBytes: integer|Long expected";
            if (message.usedQuotaBytes != null && message.hasOwnProperty("usedQuotaBytes"))
                if (!$util.isInteger(message.usedQuotaBytes) && !(message.usedQuotaBytes && $util.isInteger(message.usedQuotaBytes.low) && $util.isInteger(message.usedQuotaBytes.high)))
                    return "usedQuotaBytes: integer|Long expected";
            return null;
        };

        /**
         * Creates an AirlockQuotaInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.AirlockQuotaInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.AirlockQuotaInfo} AirlockQuotaInfo
         */
        AirlockQuotaInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.AirlockQuotaInfo)
                return object;
            let message = new $root.gcg.AirlockQuotaInfo();
            if (object.airlockNodeId != null)
                message.airlockNodeId = String(object.airlockNodeId);
            if (object.quotaBytes != null)
                if ($util.Long)
                    (message.quotaBytes = $util.Long.fromValue(object.quotaBytes)).unsigned = true;
                else if (typeof object.quotaBytes === "string")
                    message.quotaBytes = parseInt(object.quotaBytes, 10);
                else if (typeof object.quotaBytes === "number")
                    message.quotaBytes = object.quotaBytes;
                else if (typeof object.quotaBytes === "object")
                    message.quotaBytes = new $util.LongBits(object.quotaBytes.low >>> 0, object.quotaBytes.high >>> 0).toNumber(true);
            if (object.usedQuotaBytes != null)
                if ($util.Long)
                    (message.usedQuotaBytes = $util.Long.fromValue(object.usedQuotaBytes)).unsigned = true;
                else if (typeof object.usedQuotaBytes === "string")
                    message.usedQuotaBytes = parseInt(object.usedQuotaBytes, 10);
                else if (typeof object.usedQuotaBytes === "number")
                    message.usedQuotaBytes = object.usedQuotaBytes;
                else if (typeof object.usedQuotaBytes === "object")
                    message.usedQuotaBytes = new $util.LongBits(object.usedQuotaBytes.low >>> 0, object.usedQuotaBytes.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an AirlockQuotaInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.AirlockQuotaInfo
         * @static
         * @param {gcg.AirlockQuotaInfo} message AirlockQuotaInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AirlockQuotaInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.airlockNodeId = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.quotaBytes = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.quotaBytes = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.usedQuotaBytes = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.usedQuotaBytes = options.longs === String ? "0" : 0;
            }
            if (message.airlockNodeId != null && message.hasOwnProperty("airlockNodeId"))
                object.airlockNodeId = message.airlockNodeId;
            if (message.quotaBytes != null && message.hasOwnProperty("quotaBytes"))
                if (typeof message.quotaBytes === "number")
                    object.quotaBytes = options.longs === String ? String(message.quotaBytes) : message.quotaBytes;
                else
                    object.quotaBytes = options.longs === String ? $util.Long.prototype.toString.call(message.quotaBytes) : options.longs === Number ? new $util.LongBits(message.quotaBytes.low >>> 0, message.quotaBytes.high >>> 0).toNumber(true) : message.quotaBytes;
            if (message.usedQuotaBytes != null && message.hasOwnProperty("usedQuotaBytes"))
                if (typeof message.usedQuotaBytes === "number")
                    object.usedQuotaBytes = options.longs === String ? String(message.usedQuotaBytes) : message.usedQuotaBytes;
                else
                    object.usedQuotaBytes = options.longs === String ? $util.Long.prototype.toString.call(message.usedQuotaBytes) : options.longs === Number ? new $util.LongBits(message.usedQuotaBytes.low >>> 0, message.usedQuotaBytes.high >>> 0).toNumber(true) : message.usedQuotaBytes;
            return object;
        };

        /**
         * Converts this AirlockQuotaInfo to JSON.
         * @function toJSON
         * @memberof gcg.AirlockQuotaInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AirlockQuotaInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AirlockQuotaInfo
         * @function getTypeUrl
         * @memberof gcg.AirlockQuotaInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AirlockQuotaInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.AirlockQuotaInfo";
        };

        return AirlockQuotaInfo;
    })();

    gcg.GetResultsSizeResponse = (function() {

        /**
         * Properties of a GetResultsSizeResponse.
         * @memberof gcg
         * @interface IGetResultsSizeResponse
         * @property {number|Long|null} [sizeBytes] GetResultsSizeResponse sizeBytes
         */

        /**
         * Constructs a new GetResultsSizeResponse.
         * @memberof gcg
         * @classdesc Represents a GetResultsSizeResponse.
         * @implements IGetResultsSizeResponse
         * @constructor
         * @param {gcg.IGetResultsSizeResponse=} [properties] Properties to set
         */
        function GetResultsSizeResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetResultsSizeResponse sizeBytes.
         * @member {number|Long} sizeBytes
         * @memberof gcg.GetResultsSizeResponse
         * @instance
         */
        GetResultsSizeResponse.prototype.sizeBytes = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new GetResultsSizeResponse instance using the specified properties.
         * @function create
         * @memberof gcg.GetResultsSizeResponse
         * @static
         * @param {gcg.IGetResultsSizeResponse=} [properties] Properties to set
         * @returns {gcg.GetResultsSizeResponse} GetResultsSizeResponse instance
         */
        GetResultsSizeResponse.create = function create(properties) {
            return new GetResultsSizeResponse(properties);
        };

        /**
         * Encodes the specified GetResultsSizeResponse message. Does not implicitly {@link gcg.GetResultsSizeResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.GetResultsSizeResponse
         * @static
         * @param {gcg.IGetResultsSizeResponse} message GetResultsSizeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetResultsSizeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sizeBytes != null && Object.hasOwnProperty.call(message, "sizeBytes"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.sizeBytes);
            return writer;
        };

        /**
         * Encodes the specified GetResultsSizeResponse message, length delimited. Does not implicitly {@link gcg.GetResultsSizeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.GetResultsSizeResponse
         * @static
         * @param {gcg.IGetResultsSizeResponse} message GetResultsSizeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetResultsSizeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetResultsSizeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.GetResultsSizeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.GetResultsSizeResponse} GetResultsSizeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetResultsSizeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.GetResultsSizeResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sizeBytes = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetResultsSizeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.GetResultsSizeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.GetResultsSizeResponse} GetResultsSizeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetResultsSizeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetResultsSizeResponse message.
         * @function verify
         * @memberof gcg.GetResultsSizeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetResultsSizeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sizeBytes != null && message.hasOwnProperty("sizeBytes"))
                if (!$util.isInteger(message.sizeBytes) && !(message.sizeBytes && $util.isInteger(message.sizeBytes.low) && $util.isInteger(message.sizeBytes.high)))
                    return "sizeBytes: integer|Long expected";
            return null;
        };

        /**
         * Creates a GetResultsSizeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.GetResultsSizeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.GetResultsSizeResponse} GetResultsSizeResponse
         */
        GetResultsSizeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.GetResultsSizeResponse)
                return object;
            let message = new $root.gcg.GetResultsSizeResponse();
            if (object.sizeBytes != null)
                if ($util.Long)
                    (message.sizeBytes = $util.Long.fromValue(object.sizeBytes)).unsigned = true;
                else if (typeof object.sizeBytes === "string")
                    message.sizeBytes = parseInt(object.sizeBytes, 10);
                else if (typeof object.sizeBytes === "number")
                    message.sizeBytes = object.sizeBytes;
                else if (typeof object.sizeBytes === "object")
                    message.sizeBytes = new $util.LongBits(object.sizeBytes.low >>> 0, object.sizeBytes.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a GetResultsSizeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.GetResultsSizeResponse
         * @static
         * @param {gcg.GetResultsSizeResponse} message GetResultsSizeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetResultsSizeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.sizeBytes = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.sizeBytes = options.longs === String ? "0" : 0;
            if (message.sizeBytes != null && message.hasOwnProperty("sizeBytes"))
                if (typeof message.sizeBytes === "number")
                    object.sizeBytes = options.longs === String ? String(message.sizeBytes) : message.sizeBytes;
                else
                    object.sizeBytes = options.longs === String ? $util.Long.prototype.toString.call(message.sizeBytes) : options.longs === Number ? new $util.LongBits(message.sizeBytes.low >>> 0, message.sizeBytes.high >>> 0).toNumber(true) : message.sizeBytes;
            return object;
        };

        /**
         * Converts this GetResultsSizeResponse to JSON.
         * @function toJSON
         * @memberof gcg.GetResultsSizeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetResultsSizeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetResultsSizeResponse
         * @function getTypeUrl
         * @memberof gcg.GetResultsSizeResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetResultsSizeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.GetResultsSizeResponse";
        };

        return GetResultsSizeResponse;
    })();

    gcg.GcgRequestV2 = (function() {

        /**
         * Properties of a GcgRequestV2.
         * @memberof gcg
         * @interface IGcgRequestV2
         * @property {gcg.IUnauthenticatedRequest|null} [unauthenticated] GcgRequestV2 unauthenticated
         * @property {gcg.IAuthenticatedRequest|null} [authenticated] GcgRequestV2 authenticated
         */

        /**
         * Constructs a new GcgRequestV2.
         * @memberof gcg
         * @classdesc Represents a GcgRequestV2.
         * @implements IGcgRequestV2
         * @constructor
         * @param {gcg.IGcgRequestV2=} [properties] Properties to set
         */
        function GcgRequestV2(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GcgRequestV2 unauthenticated.
         * @member {gcg.IUnauthenticatedRequest|null|undefined} unauthenticated
         * @memberof gcg.GcgRequestV2
         * @instance
         */
        GcgRequestV2.prototype.unauthenticated = null;

        /**
         * GcgRequestV2 authenticated.
         * @member {gcg.IAuthenticatedRequest|null|undefined} authenticated
         * @memberof gcg.GcgRequestV2
         * @instance
         */
        GcgRequestV2.prototype.authenticated = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * GcgRequestV2 request.
         * @member {"unauthenticated"|"authenticated"|undefined} request
         * @memberof gcg.GcgRequestV2
         * @instance
         */
        Object.defineProperty(GcgRequestV2.prototype, "request", {
            get: $util.oneOfGetter($oneOfFields = ["unauthenticated", "authenticated"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GcgRequestV2 instance using the specified properties.
         * @function create
         * @memberof gcg.GcgRequestV2
         * @static
         * @param {gcg.IGcgRequestV2=} [properties] Properties to set
         * @returns {gcg.GcgRequestV2} GcgRequestV2 instance
         */
        GcgRequestV2.create = function create(properties) {
            return new GcgRequestV2(properties);
        };

        /**
         * Encodes the specified GcgRequestV2 message. Does not implicitly {@link gcg.GcgRequestV2.verify|verify} messages.
         * @function encode
         * @memberof gcg.GcgRequestV2
         * @static
         * @param {gcg.IGcgRequestV2} message GcgRequestV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GcgRequestV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.unauthenticated != null && Object.hasOwnProperty.call(message, "unauthenticated"))
                $root.gcg.UnauthenticatedRequest.encode(message.unauthenticated, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.authenticated != null && Object.hasOwnProperty.call(message, "authenticated"))
                $root.gcg.AuthenticatedRequest.encode(message.authenticated, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GcgRequestV2 message, length delimited. Does not implicitly {@link gcg.GcgRequestV2.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.GcgRequestV2
         * @static
         * @param {gcg.IGcgRequestV2} message GcgRequestV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GcgRequestV2.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GcgRequestV2 message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.GcgRequestV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.GcgRequestV2} GcgRequestV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GcgRequestV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.GcgRequestV2();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.unauthenticated = $root.gcg.UnauthenticatedRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.authenticated = $root.gcg.AuthenticatedRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GcgRequestV2 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.GcgRequestV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.GcgRequestV2} GcgRequestV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GcgRequestV2.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GcgRequestV2 message.
         * @function verify
         * @memberof gcg.GcgRequestV2
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GcgRequestV2.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.unauthenticated != null && message.hasOwnProperty("unauthenticated")) {
                properties.request = 1;
                {
                    let error = $root.gcg.UnauthenticatedRequest.verify(message.unauthenticated);
                    if (error)
                        return "unauthenticated." + error;
                }
            }
            if (message.authenticated != null && message.hasOwnProperty("authenticated")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.gcg.AuthenticatedRequest.verify(message.authenticated);
                    if (error)
                        return "authenticated." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GcgRequestV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.GcgRequestV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.GcgRequestV2} GcgRequestV2
         */
        GcgRequestV2.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.GcgRequestV2)
                return object;
            let message = new $root.gcg.GcgRequestV2();
            if (object.unauthenticated != null) {
                if (typeof object.unauthenticated !== "object")
                    throw TypeError(".gcg.GcgRequestV2.unauthenticated: object expected");
                message.unauthenticated = $root.gcg.UnauthenticatedRequest.fromObject(object.unauthenticated);
            }
            if (object.authenticated != null) {
                if (typeof object.authenticated !== "object")
                    throw TypeError(".gcg.GcgRequestV2.authenticated: object expected");
                message.authenticated = $root.gcg.AuthenticatedRequest.fromObject(object.authenticated);
            }
            return message;
        };

        /**
         * Creates a plain object from a GcgRequestV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.GcgRequestV2
         * @static
         * @param {gcg.GcgRequestV2} message GcgRequestV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GcgRequestV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.unauthenticated != null && message.hasOwnProperty("unauthenticated")) {
                object.unauthenticated = $root.gcg.UnauthenticatedRequest.toObject(message.unauthenticated, options);
                if (options.oneofs)
                    object.request = "unauthenticated";
            }
            if (message.authenticated != null && message.hasOwnProperty("authenticated")) {
                object.authenticated = $root.gcg.AuthenticatedRequest.toObject(message.authenticated, options);
                if (options.oneofs)
                    object.request = "authenticated";
            }
            return object;
        };

        /**
         * Converts this GcgRequestV2 to JSON.
         * @function toJSON
         * @memberof gcg.GcgRequestV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GcgRequestV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GcgRequestV2
         * @function getTypeUrl
         * @memberof gcg.GcgRequestV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GcgRequestV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.GcgRequestV2";
        };

        return GcgRequestV2;
    })();

    gcg.AuthenticatedRequest = (function() {

        /**
         * Properties of an AuthenticatedRequest.
         * @memberof gcg
         * @interface IAuthenticatedRequest
         * @property {string|null} [accessToken] AuthenticatedRequest accessToken
         * @property {string|null} [apiToken] AuthenticatedRequest apiToken
         * @property {gcg.ICreatePolicyRequest|null} [createPolicy] AuthenticatedRequest createPolicy
         * @property {secret_store_api.ISecretStoreRequest|null} [secretStore] AuthenticatedRequest secretStore
         * @property {gcg.IUpdateDataRoomStatusRequest|null} [updateStatus] AuthenticatedRequest updateStatus
         * @property {gcg.IGetVerificationKeyRequest|null} [getVerificationKey] AuthenticatedRequest getVerificationKey
         * @property {gcg.IDcrActionRequest|null} [dcrAction] AuthenticatedRequest dcrAction
         * @property {gcg.ICreateApiTokenRequest|null} [createApiToken] AuthenticatedRequest createApiToken
         * @property {gcg.IGetApiTokensRequest|null} [getApiTokens] AuthenticatedRequest getApiTokens
         * @property {gcg.IDeleteApiTokenRequest|null} [deleteApiToken] AuthenticatedRequest deleteApiToken
         */

        /**
         * Constructs a new AuthenticatedRequest.
         * @memberof gcg
         * @classdesc Represents an AuthenticatedRequest.
         * @implements IAuthenticatedRequest
         * @constructor
         * @param {gcg.IAuthenticatedRequest=} [properties] Properties to set
         */
        function AuthenticatedRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AuthenticatedRequest accessToken.
         * @member {string|null|undefined} accessToken
         * @memberof gcg.AuthenticatedRequest
         * @instance
         */
        AuthenticatedRequest.prototype.accessToken = null;

        /**
         * AuthenticatedRequest apiToken.
         * @member {string|null|undefined} apiToken
         * @memberof gcg.AuthenticatedRequest
         * @instance
         */
        AuthenticatedRequest.prototype.apiToken = null;

        /**
         * AuthenticatedRequest createPolicy.
         * @member {gcg.ICreatePolicyRequest|null|undefined} createPolicy
         * @memberof gcg.AuthenticatedRequest
         * @instance
         */
        AuthenticatedRequest.prototype.createPolicy = null;

        /**
         * AuthenticatedRequest secretStore.
         * @member {secret_store_api.ISecretStoreRequest|null|undefined} secretStore
         * @memberof gcg.AuthenticatedRequest
         * @instance
         */
        AuthenticatedRequest.prototype.secretStore = null;

        /**
         * AuthenticatedRequest updateStatus.
         * @member {gcg.IUpdateDataRoomStatusRequest|null|undefined} updateStatus
         * @memberof gcg.AuthenticatedRequest
         * @instance
         */
        AuthenticatedRequest.prototype.updateStatus = null;

        /**
         * AuthenticatedRequest getVerificationKey.
         * @member {gcg.IGetVerificationKeyRequest|null|undefined} getVerificationKey
         * @memberof gcg.AuthenticatedRequest
         * @instance
         */
        AuthenticatedRequest.prototype.getVerificationKey = null;

        /**
         * AuthenticatedRequest dcrAction.
         * @member {gcg.IDcrActionRequest|null|undefined} dcrAction
         * @memberof gcg.AuthenticatedRequest
         * @instance
         */
        AuthenticatedRequest.prototype.dcrAction = null;

        /**
         * AuthenticatedRequest createApiToken.
         * @member {gcg.ICreateApiTokenRequest|null|undefined} createApiToken
         * @memberof gcg.AuthenticatedRequest
         * @instance
         */
        AuthenticatedRequest.prototype.createApiToken = null;

        /**
         * AuthenticatedRequest getApiTokens.
         * @member {gcg.IGetApiTokensRequest|null|undefined} getApiTokens
         * @memberof gcg.AuthenticatedRequest
         * @instance
         */
        AuthenticatedRequest.prototype.getApiTokens = null;

        /**
         * AuthenticatedRequest deleteApiToken.
         * @member {gcg.IDeleteApiTokenRequest|null|undefined} deleteApiToken
         * @memberof gcg.AuthenticatedRequest
         * @instance
         */
        AuthenticatedRequest.prototype.deleteApiToken = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * AuthenticatedRequest token.
         * @member {"accessToken"|"apiToken"|undefined} token
         * @memberof gcg.AuthenticatedRequest
         * @instance
         */
        Object.defineProperty(AuthenticatedRequest.prototype, "token", {
            get: $util.oneOfGetter($oneOfFields = ["accessToken", "apiToken"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * AuthenticatedRequest request.
         * @member {"createPolicy"|"secretStore"|"updateStatus"|"getVerificationKey"|"dcrAction"|"createApiToken"|"getApiTokens"|"deleteApiToken"|undefined} request
         * @memberof gcg.AuthenticatedRequest
         * @instance
         */
        Object.defineProperty(AuthenticatedRequest.prototype, "request", {
            get: $util.oneOfGetter($oneOfFields = ["createPolicy", "secretStore", "updateStatus", "getVerificationKey", "dcrAction", "createApiToken", "getApiTokens", "deleteApiToken"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new AuthenticatedRequest instance using the specified properties.
         * @function create
         * @memberof gcg.AuthenticatedRequest
         * @static
         * @param {gcg.IAuthenticatedRequest=} [properties] Properties to set
         * @returns {gcg.AuthenticatedRequest} AuthenticatedRequest instance
         */
        AuthenticatedRequest.create = function create(properties) {
            return new AuthenticatedRequest(properties);
        };

        /**
         * Encodes the specified AuthenticatedRequest message. Does not implicitly {@link gcg.AuthenticatedRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.AuthenticatedRequest
         * @static
         * @param {gcg.IAuthenticatedRequest} message AuthenticatedRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticatedRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.accessToken != null && Object.hasOwnProperty.call(message, "accessToken"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.accessToken);
            if (message.apiToken != null && Object.hasOwnProperty.call(message, "apiToken"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.apiToken);
            if (message.createPolicy != null && Object.hasOwnProperty.call(message, "createPolicy"))
                $root.gcg.CreatePolicyRequest.encode(message.createPolicy, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.secretStore != null && Object.hasOwnProperty.call(message, "secretStore"))
                $root.secret_store_api.SecretStoreRequest.encode(message.secretStore, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.updateStatus != null && Object.hasOwnProperty.call(message, "updateStatus"))
                $root.gcg.UpdateDataRoomStatusRequest.encode(message.updateStatus, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.getVerificationKey != null && Object.hasOwnProperty.call(message, "getVerificationKey"))
                $root.gcg.GetVerificationKeyRequest.encode(message.getVerificationKey, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.dcrAction != null && Object.hasOwnProperty.call(message, "dcrAction"))
                $root.gcg.DcrActionRequest.encode(message.dcrAction, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.createApiToken != null && Object.hasOwnProperty.call(message, "createApiToken"))
                $root.gcg.CreateApiTokenRequest.encode(message.createApiToken, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.getApiTokens != null && Object.hasOwnProperty.call(message, "getApiTokens"))
                $root.gcg.GetApiTokensRequest.encode(message.getApiTokens, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.deleteApiToken != null && Object.hasOwnProperty.call(message, "deleteApiToken"))
                $root.gcg.DeleteApiTokenRequest.encode(message.deleteApiToken, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AuthenticatedRequest message, length delimited. Does not implicitly {@link gcg.AuthenticatedRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.AuthenticatedRequest
         * @static
         * @param {gcg.IAuthenticatedRequest} message AuthenticatedRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticatedRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AuthenticatedRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.AuthenticatedRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.AuthenticatedRequest} AuthenticatedRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticatedRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.AuthenticatedRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.accessToken = reader.string();
                        break;
                    }
                case 2: {
                        message.apiToken = reader.string();
                        break;
                    }
                case 3: {
                        message.createPolicy = $root.gcg.CreatePolicyRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.secretStore = $root.secret_store_api.SecretStoreRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.updateStatus = $root.gcg.UpdateDataRoomStatusRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.getVerificationKey = $root.gcg.GetVerificationKeyRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.dcrAction = $root.gcg.DcrActionRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.createApiToken = $root.gcg.CreateApiTokenRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.getApiTokens = $root.gcg.GetApiTokensRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.deleteApiToken = $root.gcg.DeleteApiTokenRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AuthenticatedRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.AuthenticatedRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.AuthenticatedRequest} AuthenticatedRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticatedRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AuthenticatedRequest message.
         * @function verify
         * @memberof gcg.AuthenticatedRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AuthenticatedRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.accessToken != null && message.hasOwnProperty("accessToken")) {
                properties.token = 1;
                if (!$util.isString(message.accessToken))
                    return "accessToken: string expected";
            }
            if (message.apiToken != null && message.hasOwnProperty("apiToken")) {
                if (properties.token === 1)
                    return "token: multiple values";
                properties.token = 1;
                if (!$util.isString(message.apiToken))
                    return "apiToken: string expected";
            }
            if (message.createPolicy != null && message.hasOwnProperty("createPolicy")) {
                properties.request = 1;
                {
                    let error = $root.gcg.CreatePolicyRequest.verify(message.createPolicy);
                    if (error)
                        return "createPolicy." + error;
                }
            }
            if (message.secretStore != null && message.hasOwnProperty("secretStore")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.secret_store_api.SecretStoreRequest.verify(message.secretStore);
                    if (error)
                        return "secretStore." + error;
                }
            }
            if (message.updateStatus != null && message.hasOwnProperty("updateStatus")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.gcg.UpdateDataRoomStatusRequest.verify(message.updateStatus);
                    if (error)
                        return "updateStatus." + error;
                }
            }
            if (message.getVerificationKey != null && message.hasOwnProperty("getVerificationKey")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.gcg.GetVerificationKeyRequest.verify(message.getVerificationKey);
                    if (error)
                        return "getVerificationKey." + error;
                }
            }
            if (message.dcrAction != null && message.hasOwnProperty("dcrAction")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.gcg.DcrActionRequest.verify(message.dcrAction);
                    if (error)
                        return "dcrAction." + error;
                }
            }
            if (message.createApiToken != null && message.hasOwnProperty("createApiToken")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.gcg.CreateApiTokenRequest.verify(message.createApiToken);
                    if (error)
                        return "createApiToken." + error;
                }
            }
            if (message.getApiTokens != null && message.hasOwnProperty("getApiTokens")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.gcg.GetApiTokensRequest.verify(message.getApiTokens);
                    if (error)
                        return "getApiTokens." + error;
                }
            }
            if (message.deleteApiToken != null && message.hasOwnProperty("deleteApiToken")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.gcg.DeleteApiTokenRequest.verify(message.deleteApiToken);
                    if (error)
                        return "deleteApiToken." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AuthenticatedRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.AuthenticatedRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.AuthenticatedRequest} AuthenticatedRequest
         */
        AuthenticatedRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.AuthenticatedRequest)
                return object;
            let message = new $root.gcg.AuthenticatedRequest();
            if (object.accessToken != null)
                message.accessToken = String(object.accessToken);
            if (object.apiToken != null)
                message.apiToken = String(object.apiToken);
            if (object.createPolicy != null) {
                if (typeof object.createPolicy !== "object")
                    throw TypeError(".gcg.AuthenticatedRequest.createPolicy: object expected");
                message.createPolicy = $root.gcg.CreatePolicyRequest.fromObject(object.createPolicy);
            }
            if (object.secretStore != null) {
                if (typeof object.secretStore !== "object")
                    throw TypeError(".gcg.AuthenticatedRequest.secretStore: object expected");
                message.secretStore = $root.secret_store_api.SecretStoreRequest.fromObject(object.secretStore);
            }
            if (object.updateStatus != null) {
                if (typeof object.updateStatus !== "object")
                    throw TypeError(".gcg.AuthenticatedRequest.updateStatus: object expected");
                message.updateStatus = $root.gcg.UpdateDataRoomStatusRequest.fromObject(object.updateStatus);
            }
            if (object.getVerificationKey != null) {
                if (typeof object.getVerificationKey !== "object")
                    throw TypeError(".gcg.AuthenticatedRequest.getVerificationKey: object expected");
                message.getVerificationKey = $root.gcg.GetVerificationKeyRequest.fromObject(object.getVerificationKey);
            }
            if (object.dcrAction != null) {
                if (typeof object.dcrAction !== "object")
                    throw TypeError(".gcg.AuthenticatedRequest.dcrAction: object expected");
                message.dcrAction = $root.gcg.DcrActionRequest.fromObject(object.dcrAction);
            }
            if (object.createApiToken != null) {
                if (typeof object.createApiToken !== "object")
                    throw TypeError(".gcg.AuthenticatedRequest.createApiToken: object expected");
                message.createApiToken = $root.gcg.CreateApiTokenRequest.fromObject(object.createApiToken);
            }
            if (object.getApiTokens != null) {
                if (typeof object.getApiTokens !== "object")
                    throw TypeError(".gcg.AuthenticatedRequest.getApiTokens: object expected");
                message.getApiTokens = $root.gcg.GetApiTokensRequest.fromObject(object.getApiTokens);
            }
            if (object.deleteApiToken != null) {
                if (typeof object.deleteApiToken !== "object")
                    throw TypeError(".gcg.AuthenticatedRequest.deleteApiToken: object expected");
                message.deleteApiToken = $root.gcg.DeleteApiTokenRequest.fromObject(object.deleteApiToken);
            }
            return message;
        };

        /**
         * Creates a plain object from an AuthenticatedRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.AuthenticatedRequest
         * @static
         * @param {gcg.AuthenticatedRequest} message AuthenticatedRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuthenticatedRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.accessToken != null && message.hasOwnProperty("accessToken")) {
                object.accessToken = message.accessToken;
                if (options.oneofs)
                    object.token = "accessToken";
            }
            if (message.apiToken != null && message.hasOwnProperty("apiToken")) {
                object.apiToken = message.apiToken;
                if (options.oneofs)
                    object.token = "apiToken";
            }
            if (message.createPolicy != null && message.hasOwnProperty("createPolicy")) {
                object.createPolicy = $root.gcg.CreatePolicyRequest.toObject(message.createPolicy, options);
                if (options.oneofs)
                    object.request = "createPolicy";
            }
            if (message.secretStore != null && message.hasOwnProperty("secretStore")) {
                object.secretStore = $root.secret_store_api.SecretStoreRequest.toObject(message.secretStore, options);
                if (options.oneofs)
                    object.request = "secretStore";
            }
            if (message.updateStatus != null && message.hasOwnProperty("updateStatus")) {
                object.updateStatus = $root.gcg.UpdateDataRoomStatusRequest.toObject(message.updateStatus, options);
                if (options.oneofs)
                    object.request = "updateStatus";
            }
            if (message.getVerificationKey != null && message.hasOwnProperty("getVerificationKey")) {
                object.getVerificationKey = $root.gcg.GetVerificationKeyRequest.toObject(message.getVerificationKey, options);
                if (options.oneofs)
                    object.request = "getVerificationKey";
            }
            if (message.dcrAction != null && message.hasOwnProperty("dcrAction")) {
                object.dcrAction = $root.gcg.DcrActionRequest.toObject(message.dcrAction, options);
                if (options.oneofs)
                    object.request = "dcrAction";
            }
            if (message.createApiToken != null && message.hasOwnProperty("createApiToken")) {
                object.createApiToken = $root.gcg.CreateApiTokenRequest.toObject(message.createApiToken, options);
                if (options.oneofs)
                    object.request = "createApiToken";
            }
            if (message.getApiTokens != null && message.hasOwnProperty("getApiTokens")) {
                object.getApiTokens = $root.gcg.GetApiTokensRequest.toObject(message.getApiTokens, options);
                if (options.oneofs)
                    object.request = "getApiTokens";
            }
            if (message.deleteApiToken != null && message.hasOwnProperty("deleteApiToken")) {
                object.deleteApiToken = $root.gcg.DeleteApiTokenRequest.toObject(message.deleteApiToken, options);
                if (options.oneofs)
                    object.request = "deleteApiToken";
            }
            return object;
        };

        /**
         * Converts this AuthenticatedRequest to JSON.
         * @function toJSON
         * @memberof gcg.AuthenticatedRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuthenticatedRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AuthenticatedRequest
         * @function getTypeUrl
         * @memberof gcg.AuthenticatedRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AuthenticatedRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.AuthenticatedRequest";
        };

        return AuthenticatedRequest;
    })();

    gcg.AuthenticatedResponse = (function() {

        /**
         * Properties of an AuthenticatedResponse.
         * @memberof gcg
         * @interface IAuthenticatedResponse
         * @property {gcg.ICreatePolicyResponse|null} [createPolicy] AuthenticatedResponse createPolicy
         * @property {secret_store_api.ISecretStoreResponse|null} [secretStore] AuthenticatedResponse secretStore
         * @property {gcg.IUpdateDataRoomStatusResponse|null} [updateStatus] AuthenticatedResponse updateStatus
         * @property {gcg.IGetVerificationKeyResponse|null} [getVerificationKey] AuthenticatedResponse getVerificationKey
         * @property {gcg.IDcrActionResponse|null} [dcrAction] AuthenticatedResponse dcrAction
         * @property {gcg.ICreateApiTokenResponse|null} [createApiToken] AuthenticatedResponse createApiToken
         * @property {gcg.IGetApiTokensResponse|null} [getApiTokens] AuthenticatedResponse getApiTokens
         * @property {gcg.IDeleteApiTokenResponse|null} [deleteApiToken] AuthenticatedResponse deleteApiToken
         */

        /**
         * Constructs a new AuthenticatedResponse.
         * @memberof gcg
         * @classdesc Represents an AuthenticatedResponse.
         * @implements IAuthenticatedResponse
         * @constructor
         * @param {gcg.IAuthenticatedResponse=} [properties] Properties to set
         */
        function AuthenticatedResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AuthenticatedResponse createPolicy.
         * @member {gcg.ICreatePolicyResponse|null|undefined} createPolicy
         * @memberof gcg.AuthenticatedResponse
         * @instance
         */
        AuthenticatedResponse.prototype.createPolicy = null;

        /**
         * AuthenticatedResponse secretStore.
         * @member {secret_store_api.ISecretStoreResponse|null|undefined} secretStore
         * @memberof gcg.AuthenticatedResponse
         * @instance
         */
        AuthenticatedResponse.prototype.secretStore = null;

        /**
         * AuthenticatedResponse updateStatus.
         * @member {gcg.IUpdateDataRoomStatusResponse|null|undefined} updateStatus
         * @memberof gcg.AuthenticatedResponse
         * @instance
         */
        AuthenticatedResponse.prototype.updateStatus = null;

        /**
         * AuthenticatedResponse getVerificationKey.
         * @member {gcg.IGetVerificationKeyResponse|null|undefined} getVerificationKey
         * @memberof gcg.AuthenticatedResponse
         * @instance
         */
        AuthenticatedResponse.prototype.getVerificationKey = null;

        /**
         * AuthenticatedResponse dcrAction.
         * @member {gcg.IDcrActionResponse|null|undefined} dcrAction
         * @memberof gcg.AuthenticatedResponse
         * @instance
         */
        AuthenticatedResponse.prototype.dcrAction = null;

        /**
         * AuthenticatedResponse createApiToken.
         * @member {gcg.ICreateApiTokenResponse|null|undefined} createApiToken
         * @memberof gcg.AuthenticatedResponse
         * @instance
         */
        AuthenticatedResponse.prototype.createApiToken = null;

        /**
         * AuthenticatedResponse getApiTokens.
         * @member {gcg.IGetApiTokensResponse|null|undefined} getApiTokens
         * @memberof gcg.AuthenticatedResponse
         * @instance
         */
        AuthenticatedResponse.prototype.getApiTokens = null;

        /**
         * AuthenticatedResponse deleteApiToken.
         * @member {gcg.IDeleteApiTokenResponse|null|undefined} deleteApiToken
         * @memberof gcg.AuthenticatedResponse
         * @instance
         */
        AuthenticatedResponse.prototype.deleteApiToken = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * AuthenticatedResponse response.
         * @member {"createPolicy"|"secretStore"|"updateStatus"|"getVerificationKey"|"dcrAction"|"createApiToken"|"getApiTokens"|"deleteApiToken"|undefined} response
         * @memberof gcg.AuthenticatedResponse
         * @instance
         */
        Object.defineProperty(AuthenticatedResponse.prototype, "response", {
            get: $util.oneOfGetter($oneOfFields = ["createPolicy", "secretStore", "updateStatus", "getVerificationKey", "dcrAction", "createApiToken", "getApiTokens", "deleteApiToken"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new AuthenticatedResponse instance using the specified properties.
         * @function create
         * @memberof gcg.AuthenticatedResponse
         * @static
         * @param {gcg.IAuthenticatedResponse=} [properties] Properties to set
         * @returns {gcg.AuthenticatedResponse} AuthenticatedResponse instance
         */
        AuthenticatedResponse.create = function create(properties) {
            return new AuthenticatedResponse(properties);
        };

        /**
         * Encodes the specified AuthenticatedResponse message. Does not implicitly {@link gcg.AuthenticatedResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.AuthenticatedResponse
         * @static
         * @param {gcg.IAuthenticatedResponse} message AuthenticatedResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticatedResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.createPolicy != null && Object.hasOwnProperty.call(message, "createPolicy"))
                $root.gcg.CreatePolicyResponse.encode(message.createPolicy, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.secretStore != null && Object.hasOwnProperty.call(message, "secretStore"))
                $root.secret_store_api.SecretStoreResponse.encode(message.secretStore, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.updateStatus != null && Object.hasOwnProperty.call(message, "updateStatus"))
                $root.gcg.UpdateDataRoomStatusResponse.encode(message.updateStatus, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.getVerificationKey != null && Object.hasOwnProperty.call(message, "getVerificationKey"))
                $root.gcg.GetVerificationKeyResponse.encode(message.getVerificationKey, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.dcrAction != null && Object.hasOwnProperty.call(message, "dcrAction"))
                $root.gcg.DcrActionResponse.encode(message.dcrAction, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.createApiToken != null && Object.hasOwnProperty.call(message, "createApiToken"))
                $root.gcg.CreateApiTokenResponse.encode(message.createApiToken, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.getApiTokens != null && Object.hasOwnProperty.call(message, "getApiTokens"))
                $root.gcg.GetApiTokensResponse.encode(message.getApiTokens, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.deleteApiToken != null && Object.hasOwnProperty.call(message, "deleteApiToken"))
                $root.gcg.DeleteApiTokenResponse.encode(message.deleteApiToken, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AuthenticatedResponse message, length delimited. Does not implicitly {@link gcg.AuthenticatedResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.AuthenticatedResponse
         * @static
         * @param {gcg.IAuthenticatedResponse} message AuthenticatedResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticatedResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AuthenticatedResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.AuthenticatedResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.AuthenticatedResponse} AuthenticatedResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticatedResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.AuthenticatedResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.createPolicy = $root.gcg.CreatePolicyResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.secretStore = $root.secret_store_api.SecretStoreResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.updateStatus = $root.gcg.UpdateDataRoomStatusResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.getVerificationKey = $root.gcg.GetVerificationKeyResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.dcrAction = $root.gcg.DcrActionResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.createApiToken = $root.gcg.CreateApiTokenResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.getApiTokens = $root.gcg.GetApiTokensResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.deleteApiToken = $root.gcg.DeleteApiTokenResponse.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AuthenticatedResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.AuthenticatedResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.AuthenticatedResponse} AuthenticatedResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticatedResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AuthenticatedResponse message.
         * @function verify
         * @memberof gcg.AuthenticatedResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AuthenticatedResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.createPolicy != null && message.hasOwnProperty("createPolicy")) {
                properties.response = 1;
                {
                    let error = $root.gcg.CreatePolicyResponse.verify(message.createPolicy);
                    if (error)
                        return "createPolicy." + error;
                }
            }
            if (message.secretStore != null && message.hasOwnProperty("secretStore")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.secret_store_api.SecretStoreResponse.verify(message.secretStore);
                    if (error)
                        return "secretStore." + error;
                }
            }
            if (message.updateStatus != null && message.hasOwnProperty("updateStatus")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.gcg.UpdateDataRoomStatusResponse.verify(message.updateStatus);
                    if (error)
                        return "updateStatus." + error;
                }
            }
            if (message.getVerificationKey != null && message.hasOwnProperty("getVerificationKey")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.gcg.GetVerificationKeyResponse.verify(message.getVerificationKey);
                    if (error)
                        return "getVerificationKey." + error;
                }
            }
            if (message.dcrAction != null && message.hasOwnProperty("dcrAction")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.gcg.DcrActionResponse.verify(message.dcrAction);
                    if (error)
                        return "dcrAction." + error;
                }
            }
            if (message.createApiToken != null && message.hasOwnProperty("createApiToken")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.gcg.CreateApiTokenResponse.verify(message.createApiToken);
                    if (error)
                        return "createApiToken." + error;
                }
            }
            if (message.getApiTokens != null && message.hasOwnProperty("getApiTokens")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.gcg.GetApiTokensResponse.verify(message.getApiTokens);
                    if (error)
                        return "getApiTokens." + error;
                }
            }
            if (message.deleteApiToken != null && message.hasOwnProperty("deleteApiToken")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.gcg.DeleteApiTokenResponse.verify(message.deleteApiToken);
                    if (error)
                        return "deleteApiToken." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AuthenticatedResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.AuthenticatedResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.AuthenticatedResponse} AuthenticatedResponse
         */
        AuthenticatedResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.AuthenticatedResponse)
                return object;
            let message = new $root.gcg.AuthenticatedResponse();
            if (object.createPolicy != null) {
                if (typeof object.createPolicy !== "object")
                    throw TypeError(".gcg.AuthenticatedResponse.createPolicy: object expected");
                message.createPolicy = $root.gcg.CreatePolicyResponse.fromObject(object.createPolicy);
            }
            if (object.secretStore != null) {
                if (typeof object.secretStore !== "object")
                    throw TypeError(".gcg.AuthenticatedResponse.secretStore: object expected");
                message.secretStore = $root.secret_store_api.SecretStoreResponse.fromObject(object.secretStore);
            }
            if (object.updateStatus != null) {
                if (typeof object.updateStatus !== "object")
                    throw TypeError(".gcg.AuthenticatedResponse.updateStatus: object expected");
                message.updateStatus = $root.gcg.UpdateDataRoomStatusResponse.fromObject(object.updateStatus);
            }
            if (object.getVerificationKey != null) {
                if (typeof object.getVerificationKey !== "object")
                    throw TypeError(".gcg.AuthenticatedResponse.getVerificationKey: object expected");
                message.getVerificationKey = $root.gcg.GetVerificationKeyResponse.fromObject(object.getVerificationKey);
            }
            if (object.dcrAction != null) {
                if (typeof object.dcrAction !== "object")
                    throw TypeError(".gcg.AuthenticatedResponse.dcrAction: object expected");
                message.dcrAction = $root.gcg.DcrActionResponse.fromObject(object.dcrAction);
            }
            if (object.createApiToken != null) {
                if (typeof object.createApiToken !== "object")
                    throw TypeError(".gcg.AuthenticatedResponse.createApiToken: object expected");
                message.createApiToken = $root.gcg.CreateApiTokenResponse.fromObject(object.createApiToken);
            }
            if (object.getApiTokens != null) {
                if (typeof object.getApiTokens !== "object")
                    throw TypeError(".gcg.AuthenticatedResponse.getApiTokens: object expected");
                message.getApiTokens = $root.gcg.GetApiTokensResponse.fromObject(object.getApiTokens);
            }
            if (object.deleteApiToken != null) {
                if (typeof object.deleteApiToken !== "object")
                    throw TypeError(".gcg.AuthenticatedResponse.deleteApiToken: object expected");
                message.deleteApiToken = $root.gcg.DeleteApiTokenResponse.fromObject(object.deleteApiToken);
            }
            return message;
        };

        /**
         * Creates a plain object from an AuthenticatedResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.AuthenticatedResponse
         * @static
         * @param {gcg.AuthenticatedResponse} message AuthenticatedResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuthenticatedResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.createPolicy != null && message.hasOwnProperty("createPolicy")) {
                object.createPolicy = $root.gcg.CreatePolicyResponse.toObject(message.createPolicy, options);
                if (options.oneofs)
                    object.response = "createPolicy";
            }
            if (message.secretStore != null && message.hasOwnProperty("secretStore")) {
                object.secretStore = $root.secret_store_api.SecretStoreResponse.toObject(message.secretStore, options);
                if (options.oneofs)
                    object.response = "secretStore";
            }
            if (message.updateStatus != null && message.hasOwnProperty("updateStatus")) {
                object.updateStatus = $root.gcg.UpdateDataRoomStatusResponse.toObject(message.updateStatus, options);
                if (options.oneofs)
                    object.response = "updateStatus";
            }
            if (message.getVerificationKey != null && message.hasOwnProperty("getVerificationKey")) {
                object.getVerificationKey = $root.gcg.GetVerificationKeyResponse.toObject(message.getVerificationKey, options);
                if (options.oneofs)
                    object.response = "getVerificationKey";
            }
            if (message.dcrAction != null && message.hasOwnProperty("dcrAction")) {
                object.dcrAction = $root.gcg.DcrActionResponse.toObject(message.dcrAction, options);
                if (options.oneofs)
                    object.response = "dcrAction";
            }
            if (message.createApiToken != null && message.hasOwnProperty("createApiToken")) {
                object.createApiToken = $root.gcg.CreateApiTokenResponse.toObject(message.createApiToken, options);
                if (options.oneofs)
                    object.response = "createApiToken";
            }
            if (message.getApiTokens != null && message.hasOwnProperty("getApiTokens")) {
                object.getApiTokens = $root.gcg.GetApiTokensResponse.toObject(message.getApiTokens, options);
                if (options.oneofs)
                    object.response = "getApiTokens";
            }
            if (message.deleteApiToken != null && message.hasOwnProperty("deleteApiToken")) {
                object.deleteApiToken = $root.gcg.DeleteApiTokenResponse.toObject(message.deleteApiToken, options);
                if (options.oneofs)
                    object.response = "deleteApiToken";
            }
            return object;
        };

        /**
         * Converts this AuthenticatedResponse to JSON.
         * @function toJSON
         * @memberof gcg.AuthenticatedResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuthenticatedResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AuthenticatedResponse
         * @function getTypeUrl
         * @memberof gcg.AuthenticatedResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AuthenticatedResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.AuthenticatedResponse";
        };

        return AuthenticatedResponse;
    })();

    gcg.UnauthenticatedRequest = (function() {

        /**
         * Properties of an UnauthenticatedRequest.
         * @memberof gcg
         * @interface IUnauthenticatedRequest
         * @property {gcg.IAuthenticationRequest|null} [authentication] UnauthenticatedRequest authentication
         */

        /**
         * Constructs a new UnauthenticatedRequest.
         * @memberof gcg
         * @classdesc Represents an UnauthenticatedRequest.
         * @implements IUnauthenticatedRequest
         * @constructor
         * @param {gcg.IUnauthenticatedRequest=} [properties] Properties to set
         */
        function UnauthenticatedRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UnauthenticatedRequest authentication.
         * @member {gcg.IAuthenticationRequest|null|undefined} authentication
         * @memberof gcg.UnauthenticatedRequest
         * @instance
         */
        UnauthenticatedRequest.prototype.authentication = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * UnauthenticatedRequest request.
         * @member {"authentication"|undefined} request
         * @memberof gcg.UnauthenticatedRequest
         * @instance
         */
        Object.defineProperty(UnauthenticatedRequest.prototype, "request", {
            get: $util.oneOfGetter($oneOfFields = ["authentication"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UnauthenticatedRequest instance using the specified properties.
         * @function create
         * @memberof gcg.UnauthenticatedRequest
         * @static
         * @param {gcg.IUnauthenticatedRequest=} [properties] Properties to set
         * @returns {gcg.UnauthenticatedRequest} UnauthenticatedRequest instance
         */
        UnauthenticatedRequest.create = function create(properties) {
            return new UnauthenticatedRequest(properties);
        };

        /**
         * Encodes the specified UnauthenticatedRequest message. Does not implicitly {@link gcg.UnauthenticatedRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.UnauthenticatedRequest
         * @static
         * @param {gcg.IUnauthenticatedRequest} message UnauthenticatedRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnauthenticatedRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.authentication != null && Object.hasOwnProperty.call(message, "authentication"))
                $root.gcg.AuthenticationRequest.encode(message.authentication, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UnauthenticatedRequest message, length delimited. Does not implicitly {@link gcg.UnauthenticatedRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.UnauthenticatedRequest
         * @static
         * @param {gcg.IUnauthenticatedRequest} message UnauthenticatedRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnauthenticatedRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UnauthenticatedRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.UnauthenticatedRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.UnauthenticatedRequest} UnauthenticatedRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnauthenticatedRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.UnauthenticatedRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.authentication = $root.gcg.AuthenticationRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UnauthenticatedRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.UnauthenticatedRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.UnauthenticatedRequest} UnauthenticatedRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnauthenticatedRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UnauthenticatedRequest message.
         * @function verify
         * @memberof gcg.UnauthenticatedRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UnauthenticatedRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.authentication != null && message.hasOwnProperty("authentication")) {
                properties.request = 1;
                {
                    let error = $root.gcg.AuthenticationRequest.verify(message.authentication);
                    if (error)
                        return "authentication." + error;
                }
            }
            return null;
        };

        /**
         * Creates an UnauthenticatedRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.UnauthenticatedRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.UnauthenticatedRequest} UnauthenticatedRequest
         */
        UnauthenticatedRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.UnauthenticatedRequest)
                return object;
            let message = new $root.gcg.UnauthenticatedRequest();
            if (object.authentication != null) {
                if (typeof object.authentication !== "object")
                    throw TypeError(".gcg.UnauthenticatedRequest.authentication: object expected");
                message.authentication = $root.gcg.AuthenticationRequest.fromObject(object.authentication);
            }
            return message;
        };

        /**
         * Creates a plain object from an UnauthenticatedRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.UnauthenticatedRequest
         * @static
         * @param {gcg.UnauthenticatedRequest} message UnauthenticatedRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UnauthenticatedRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.authentication != null && message.hasOwnProperty("authentication")) {
                object.authentication = $root.gcg.AuthenticationRequest.toObject(message.authentication, options);
                if (options.oneofs)
                    object.request = "authentication";
            }
            return object;
        };

        /**
         * Converts this UnauthenticatedRequest to JSON.
         * @function toJSON
         * @memberof gcg.UnauthenticatedRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UnauthenticatedRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UnauthenticatedRequest
         * @function getTypeUrl
         * @memberof gcg.UnauthenticatedRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UnauthenticatedRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.UnauthenticatedRequest";
        };

        return UnauthenticatedRequest;
    })();

    gcg.GcgResponseV2 = (function() {

        /**
         * Properties of a GcgResponseV2.
         * @memberof gcg
         * @interface IGcgResponseV2
         * @property {gcg.IGcgSuccessfulResponseV2|null} [success] GcgResponseV2 success
         * @property {string|null} [failure] GcgResponseV2 failure
         */

        /**
         * Constructs a new GcgResponseV2.
         * @memberof gcg
         * @classdesc Represents a GcgResponseV2.
         * @implements IGcgResponseV2
         * @constructor
         * @param {gcg.IGcgResponseV2=} [properties] Properties to set
         */
        function GcgResponseV2(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GcgResponseV2 success.
         * @member {gcg.IGcgSuccessfulResponseV2|null|undefined} success
         * @memberof gcg.GcgResponseV2
         * @instance
         */
        GcgResponseV2.prototype.success = null;

        /**
         * GcgResponseV2 failure.
         * @member {string|null|undefined} failure
         * @memberof gcg.GcgResponseV2
         * @instance
         */
        GcgResponseV2.prototype.failure = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * GcgResponseV2 response.
         * @member {"success"|"failure"|undefined} response
         * @memberof gcg.GcgResponseV2
         * @instance
         */
        Object.defineProperty(GcgResponseV2.prototype, "response", {
            get: $util.oneOfGetter($oneOfFields = ["success", "failure"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GcgResponseV2 instance using the specified properties.
         * @function create
         * @memberof gcg.GcgResponseV2
         * @static
         * @param {gcg.IGcgResponseV2=} [properties] Properties to set
         * @returns {gcg.GcgResponseV2} GcgResponseV2 instance
         */
        GcgResponseV2.create = function create(properties) {
            return new GcgResponseV2(properties);
        };

        /**
         * Encodes the specified GcgResponseV2 message. Does not implicitly {@link gcg.GcgResponseV2.verify|verify} messages.
         * @function encode
         * @memberof gcg.GcgResponseV2
         * @static
         * @param {gcg.IGcgResponseV2} message GcgResponseV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GcgResponseV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                $root.gcg.GcgSuccessfulResponseV2.encode(message.success, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.failure != null && Object.hasOwnProperty.call(message, "failure"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.failure);
            return writer;
        };

        /**
         * Encodes the specified GcgResponseV2 message, length delimited. Does not implicitly {@link gcg.GcgResponseV2.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.GcgResponseV2
         * @static
         * @param {gcg.IGcgResponseV2} message GcgResponseV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GcgResponseV2.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GcgResponseV2 message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.GcgResponseV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.GcgResponseV2} GcgResponseV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GcgResponseV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.GcgResponseV2();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.success = $root.gcg.GcgSuccessfulResponseV2.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.failure = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GcgResponseV2 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.GcgResponseV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.GcgResponseV2} GcgResponseV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GcgResponseV2.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GcgResponseV2 message.
         * @function verify
         * @memberof gcg.GcgResponseV2
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GcgResponseV2.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.success != null && message.hasOwnProperty("success")) {
                properties.response = 1;
                {
                    let error = $root.gcg.GcgSuccessfulResponseV2.verify(message.success);
                    if (error)
                        return "success." + error;
                }
            }
            if (message.failure != null && message.hasOwnProperty("failure")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                if (!$util.isString(message.failure))
                    return "failure: string expected";
            }
            return null;
        };

        /**
         * Creates a GcgResponseV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.GcgResponseV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.GcgResponseV2} GcgResponseV2
         */
        GcgResponseV2.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.GcgResponseV2)
                return object;
            let message = new $root.gcg.GcgResponseV2();
            if (object.success != null) {
                if (typeof object.success !== "object")
                    throw TypeError(".gcg.GcgResponseV2.success: object expected");
                message.success = $root.gcg.GcgSuccessfulResponseV2.fromObject(object.success);
            }
            if (object.failure != null)
                message.failure = String(object.failure);
            return message;
        };

        /**
         * Creates a plain object from a GcgResponseV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.GcgResponseV2
         * @static
         * @param {gcg.GcgResponseV2} message GcgResponseV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GcgResponseV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.success != null && message.hasOwnProperty("success")) {
                object.success = $root.gcg.GcgSuccessfulResponseV2.toObject(message.success, options);
                if (options.oneofs)
                    object.response = "success";
            }
            if (message.failure != null && message.hasOwnProperty("failure")) {
                object.failure = message.failure;
                if (options.oneofs)
                    object.response = "failure";
            }
            return object;
        };

        /**
         * Converts this GcgResponseV2 to JSON.
         * @function toJSON
         * @memberof gcg.GcgResponseV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GcgResponseV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GcgResponseV2
         * @function getTypeUrl
         * @memberof gcg.GcgResponseV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GcgResponseV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.GcgResponseV2";
        };

        return GcgResponseV2;
    })();

    gcg.AuthenticationRequest = (function() {

        /**
         * Properties of an AuthenticationRequest.
         * @memberof gcg
         * @interface IAuthenticationRequest
         * @property {gcg.IEmailStartAuthenticationRequest|null} [emailStart] AuthenticationRequest emailStart
         * @property {gcg.IEmailFinishAuthenticationRequest|null} [emailFinish] AuthenticationRequest emailFinish
         */

        /**
         * Constructs a new AuthenticationRequest.
         * @memberof gcg
         * @classdesc Represents an AuthenticationRequest.
         * @implements IAuthenticationRequest
         * @constructor
         * @param {gcg.IAuthenticationRequest=} [properties] Properties to set
         */
        function AuthenticationRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AuthenticationRequest emailStart.
         * @member {gcg.IEmailStartAuthenticationRequest|null|undefined} emailStart
         * @memberof gcg.AuthenticationRequest
         * @instance
         */
        AuthenticationRequest.prototype.emailStart = null;

        /**
         * AuthenticationRequest emailFinish.
         * @member {gcg.IEmailFinishAuthenticationRequest|null|undefined} emailFinish
         * @memberof gcg.AuthenticationRequest
         * @instance
         */
        AuthenticationRequest.prototype.emailFinish = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * AuthenticationRequest request.
         * @member {"emailStart"|"emailFinish"|undefined} request
         * @memberof gcg.AuthenticationRequest
         * @instance
         */
        Object.defineProperty(AuthenticationRequest.prototype, "request", {
            get: $util.oneOfGetter($oneOfFields = ["emailStart", "emailFinish"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new AuthenticationRequest instance using the specified properties.
         * @function create
         * @memberof gcg.AuthenticationRequest
         * @static
         * @param {gcg.IAuthenticationRequest=} [properties] Properties to set
         * @returns {gcg.AuthenticationRequest} AuthenticationRequest instance
         */
        AuthenticationRequest.create = function create(properties) {
            return new AuthenticationRequest(properties);
        };

        /**
         * Encodes the specified AuthenticationRequest message. Does not implicitly {@link gcg.AuthenticationRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.AuthenticationRequest
         * @static
         * @param {gcg.IAuthenticationRequest} message AuthenticationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.emailStart != null && Object.hasOwnProperty.call(message, "emailStart"))
                $root.gcg.EmailStartAuthenticationRequest.encode(message.emailStart, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.emailFinish != null && Object.hasOwnProperty.call(message, "emailFinish"))
                $root.gcg.EmailFinishAuthenticationRequest.encode(message.emailFinish, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AuthenticationRequest message, length delimited. Does not implicitly {@link gcg.AuthenticationRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.AuthenticationRequest
         * @static
         * @param {gcg.IAuthenticationRequest} message AuthenticationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticationRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AuthenticationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.AuthenticationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.AuthenticationRequest} AuthenticationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.AuthenticationRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.emailStart = $root.gcg.EmailStartAuthenticationRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.emailFinish = $root.gcg.EmailFinishAuthenticationRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AuthenticationRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.AuthenticationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.AuthenticationRequest} AuthenticationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticationRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AuthenticationRequest message.
         * @function verify
         * @memberof gcg.AuthenticationRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AuthenticationRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.emailStart != null && message.hasOwnProperty("emailStart")) {
                properties.request = 1;
                {
                    let error = $root.gcg.EmailStartAuthenticationRequest.verify(message.emailStart);
                    if (error)
                        return "emailStart." + error;
                }
            }
            if (message.emailFinish != null && message.hasOwnProperty("emailFinish")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.gcg.EmailFinishAuthenticationRequest.verify(message.emailFinish);
                    if (error)
                        return "emailFinish." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AuthenticationRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.AuthenticationRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.AuthenticationRequest} AuthenticationRequest
         */
        AuthenticationRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.AuthenticationRequest)
                return object;
            let message = new $root.gcg.AuthenticationRequest();
            if (object.emailStart != null) {
                if (typeof object.emailStart !== "object")
                    throw TypeError(".gcg.AuthenticationRequest.emailStart: object expected");
                message.emailStart = $root.gcg.EmailStartAuthenticationRequest.fromObject(object.emailStart);
            }
            if (object.emailFinish != null) {
                if (typeof object.emailFinish !== "object")
                    throw TypeError(".gcg.AuthenticationRequest.emailFinish: object expected");
                message.emailFinish = $root.gcg.EmailFinishAuthenticationRequest.fromObject(object.emailFinish);
            }
            return message;
        };

        /**
         * Creates a plain object from an AuthenticationRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.AuthenticationRequest
         * @static
         * @param {gcg.AuthenticationRequest} message AuthenticationRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuthenticationRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.emailStart != null && message.hasOwnProperty("emailStart")) {
                object.emailStart = $root.gcg.EmailStartAuthenticationRequest.toObject(message.emailStart, options);
                if (options.oneofs)
                    object.request = "emailStart";
            }
            if (message.emailFinish != null && message.hasOwnProperty("emailFinish")) {
                object.emailFinish = $root.gcg.EmailFinishAuthenticationRequest.toObject(message.emailFinish, options);
                if (options.oneofs)
                    object.request = "emailFinish";
            }
            return object;
        };

        /**
         * Converts this AuthenticationRequest to JSON.
         * @function toJSON
         * @memberof gcg.AuthenticationRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuthenticationRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AuthenticationRequest
         * @function getTypeUrl
         * @memberof gcg.AuthenticationRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AuthenticationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.AuthenticationRequest";
        };

        return AuthenticationRequest;
    })();

    gcg.AuthenticationResponse = (function() {

        /**
         * Properties of an AuthenticationResponse.
         * @memberof gcg
         * @interface IAuthenticationResponse
         * @property {gcg.IEmailStartAuthenticationResponse|null} [emailStart] AuthenticationResponse emailStart
         * @property {gcg.IEmailFinishAuthenticationResponse|null} [emailFinish] AuthenticationResponse emailFinish
         */

        /**
         * Constructs a new AuthenticationResponse.
         * @memberof gcg
         * @classdesc Represents an AuthenticationResponse.
         * @implements IAuthenticationResponse
         * @constructor
         * @param {gcg.IAuthenticationResponse=} [properties] Properties to set
         */
        function AuthenticationResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AuthenticationResponse emailStart.
         * @member {gcg.IEmailStartAuthenticationResponse|null|undefined} emailStart
         * @memberof gcg.AuthenticationResponse
         * @instance
         */
        AuthenticationResponse.prototype.emailStart = null;

        /**
         * AuthenticationResponse emailFinish.
         * @member {gcg.IEmailFinishAuthenticationResponse|null|undefined} emailFinish
         * @memberof gcg.AuthenticationResponse
         * @instance
         */
        AuthenticationResponse.prototype.emailFinish = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * AuthenticationResponse response.
         * @member {"emailStart"|"emailFinish"|undefined} response
         * @memberof gcg.AuthenticationResponse
         * @instance
         */
        Object.defineProperty(AuthenticationResponse.prototype, "response", {
            get: $util.oneOfGetter($oneOfFields = ["emailStart", "emailFinish"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new AuthenticationResponse instance using the specified properties.
         * @function create
         * @memberof gcg.AuthenticationResponse
         * @static
         * @param {gcg.IAuthenticationResponse=} [properties] Properties to set
         * @returns {gcg.AuthenticationResponse} AuthenticationResponse instance
         */
        AuthenticationResponse.create = function create(properties) {
            return new AuthenticationResponse(properties);
        };

        /**
         * Encodes the specified AuthenticationResponse message. Does not implicitly {@link gcg.AuthenticationResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.AuthenticationResponse
         * @static
         * @param {gcg.IAuthenticationResponse} message AuthenticationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.emailStart != null && Object.hasOwnProperty.call(message, "emailStart"))
                $root.gcg.EmailStartAuthenticationResponse.encode(message.emailStart, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.emailFinish != null && Object.hasOwnProperty.call(message, "emailFinish"))
                $root.gcg.EmailFinishAuthenticationResponse.encode(message.emailFinish, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AuthenticationResponse message, length delimited. Does not implicitly {@link gcg.AuthenticationResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.AuthenticationResponse
         * @static
         * @param {gcg.IAuthenticationResponse} message AuthenticationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticationResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AuthenticationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.AuthenticationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.AuthenticationResponse} AuthenticationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.AuthenticationResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.emailStart = $root.gcg.EmailStartAuthenticationResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.emailFinish = $root.gcg.EmailFinishAuthenticationResponse.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AuthenticationResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.AuthenticationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.AuthenticationResponse} AuthenticationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticationResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AuthenticationResponse message.
         * @function verify
         * @memberof gcg.AuthenticationResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AuthenticationResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.emailStart != null && message.hasOwnProperty("emailStart")) {
                properties.response = 1;
                {
                    let error = $root.gcg.EmailStartAuthenticationResponse.verify(message.emailStart);
                    if (error)
                        return "emailStart." + error;
                }
            }
            if (message.emailFinish != null && message.hasOwnProperty("emailFinish")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.gcg.EmailFinishAuthenticationResponse.verify(message.emailFinish);
                    if (error)
                        return "emailFinish." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AuthenticationResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.AuthenticationResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.AuthenticationResponse} AuthenticationResponse
         */
        AuthenticationResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.AuthenticationResponse)
                return object;
            let message = new $root.gcg.AuthenticationResponse();
            if (object.emailStart != null) {
                if (typeof object.emailStart !== "object")
                    throw TypeError(".gcg.AuthenticationResponse.emailStart: object expected");
                message.emailStart = $root.gcg.EmailStartAuthenticationResponse.fromObject(object.emailStart);
            }
            if (object.emailFinish != null) {
                if (typeof object.emailFinish !== "object")
                    throw TypeError(".gcg.AuthenticationResponse.emailFinish: object expected");
                message.emailFinish = $root.gcg.EmailFinishAuthenticationResponse.fromObject(object.emailFinish);
            }
            return message;
        };

        /**
         * Creates a plain object from an AuthenticationResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.AuthenticationResponse
         * @static
         * @param {gcg.AuthenticationResponse} message AuthenticationResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuthenticationResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.emailStart != null && message.hasOwnProperty("emailStart")) {
                object.emailStart = $root.gcg.EmailStartAuthenticationResponse.toObject(message.emailStart, options);
                if (options.oneofs)
                    object.response = "emailStart";
            }
            if (message.emailFinish != null && message.hasOwnProperty("emailFinish")) {
                object.emailFinish = $root.gcg.EmailFinishAuthenticationResponse.toObject(message.emailFinish, options);
                if (options.oneofs)
                    object.response = "emailFinish";
            }
            return object;
        };

        /**
         * Converts this AuthenticationResponse to JSON.
         * @function toJSON
         * @memberof gcg.AuthenticationResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuthenticationResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AuthenticationResponse
         * @function getTypeUrl
         * @memberof gcg.AuthenticationResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AuthenticationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.AuthenticationResponse";
        };

        return AuthenticationResponse;
    })();

    gcg.EmailStartAuthenticationRequest = (function() {

        /**
         * Properties of an EmailStartAuthenticationRequest.
         * @memberof gcg
         * @interface IEmailStartAuthenticationRequest
         * @property {string|null} [email] EmailStartAuthenticationRequest email
         */

        /**
         * Constructs a new EmailStartAuthenticationRequest.
         * @memberof gcg
         * @classdesc Represents an EmailStartAuthenticationRequest.
         * @implements IEmailStartAuthenticationRequest
         * @constructor
         * @param {gcg.IEmailStartAuthenticationRequest=} [properties] Properties to set
         */
        function EmailStartAuthenticationRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmailStartAuthenticationRequest email.
         * @member {string} email
         * @memberof gcg.EmailStartAuthenticationRequest
         * @instance
         */
        EmailStartAuthenticationRequest.prototype.email = "";

        /**
         * Creates a new EmailStartAuthenticationRequest instance using the specified properties.
         * @function create
         * @memberof gcg.EmailStartAuthenticationRequest
         * @static
         * @param {gcg.IEmailStartAuthenticationRequest=} [properties] Properties to set
         * @returns {gcg.EmailStartAuthenticationRequest} EmailStartAuthenticationRequest instance
         */
        EmailStartAuthenticationRequest.create = function create(properties) {
            return new EmailStartAuthenticationRequest(properties);
        };

        /**
         * Encodes the specified EmailStartAuthenticationRequest message. Does not implicitly {@link gcg.EmailStartAuthenticationRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.EmailStartAuthenticationRequest
         * @static
         * @param {gcg.IEmailStartAuthenticationRequest} message EmailStartAuthenticationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailStartAuthenticationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
            return writer;
        };

        /**
         * Encodes the specified EmailStartAuthenticationRequest message, length delimited. Does not implicitly {@link gcg.EmailStartAuthenticationRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.EmailStartAuthenticationRequest
         * @static
         * @param {gcg.IEmailStartAuthenticationRequest} message EmailStartAuthenticationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailStartAuthenticationRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmailStartAuthenticationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.EmailStartAuthenticationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.EmailStartAuthenticationRequest} EmailStartAuthenticationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailStartAuthenticationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.EmailStartAuthenticationRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.email = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EmailStartAuthenticationRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.EmailStartAuthenticationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.EmailStartAuthenticationRequest} EmailStartAuthenticationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailStartAuthenticationRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmailStartAuthenticationRequest message.
         * @function verify
         * @memberof gcg.EmailStartAuthenticationRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmailStartAuthenticationRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            return null;
        };

        /**
         * Creates an EmailStartAuthenticationRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.EmailStartAuthenticationRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.EmailStartAuthenticationRequest} EmailStartAuthenticationRequest
         */
        EmailStartAuthenticationRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.EmailStartAuthenticationRequest)
                return object;
            let message = new $root.gcg.EmailStartAuthenticationRequest();
            if (object.email != null)
                message.email = String(object.email);
            return message;
        };

        /**
         * Creates a plain object from an EmailStartAuthenticationRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.EmailStartAuthenticationRequest
         * @static
         * @param {gcg.EmailStartAuthenticationRequest} message EmailStartAuthenticationRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmailStartAuthenticationRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.email = "";
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            return object;
        };

        /**
         * Converts this EmailStartAuthenticationRequest to JSON.
         * @function toJSON
         * @memberof gcg.EmailStartAuthenticationRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmailStartAuthenticationRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EmailStartAuthenticationRequest
         * @function getTypeUrl
         * @memberof gcg.EmailStartAuthenticationRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmailStartAuthenticationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.EmailStartAuthenticationRequest";
        };

        return EmailStartAuthenticationRequest;
    })();

    gcg.EmailStartAuthenticationResponse = (function() {

        /**
         * Properties of an EmailStartAuthenticationResponse.
         * @memberof gcg
         * @interface IEmailStartAuthenticationResponse
         */

        /**
         * Constructs a new EmailStartAuthenticationResponse.
         * @memberof gcg
         * @classdesc Represents an EmailStartAuthenticationResponse.
         * @implements IEmailStartAuthenticationResponse
         * @constructor
         * @param {gcg.IEmailStartAuthenticationResponse=} [properties] Properties to set
         */
        function EmailStartAuthenticationResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new EmailStartAuthenticationResponse instance using the specified properties.
         * @function create
         * @memberof gcg.EmailStartAuthenticationResponse
         * @static
         * @param {gcg.IEmailStartAuthenticationResponse=} [properties] Properties to set
         * @returns {gcg.EmailStartAuthenticationResponse} EmailStartAuthenticationResponse instance
         */
        EmailStartAuthenticationResponse.create = function create(properties) {
            return new EmailStartAuthenticationResponse(properties);
        };

        /**
         * Encodes the specified EmailStartAuthenticationResponse message. Does not implicitly {@link gcg.EmailStartAuthenticationResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.EmailStartAuthenticationResponse
         * @static
         * @param {gcg.IEmailStartAuthenticationResponse} message EmailStartAuthenticationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailStartAuthenticationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified EmailStartAuthenticationResponse message, length delimited. Does not implicitly {@link gcg.EmailStartAuthenticationResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.EmailStartAuthenticationResponse
         * @static
         * @param {gcg.IEmailStartAuthenticationResponse} message EmailStartAuthenticationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailStartAuthenticationResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmailStartAuthenticationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.EmailStartAuthenticationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.EmailStartAuthenticationResponse} EmailStartAuthenticationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailStartAuthenticationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.EmailStartAuthenticationResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EmailStartAuthenticationResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.EmailStartAuthenticationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.EmailStartAuthenticationResponse} EmailStartAuthenticationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailStartAuthenticationResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmailStartAuthenticationResponse message.
         * @function verify
         * @memberof gcg.EmailStartAuthenticationResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmailStartAuthenticationResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an EmailStartAuthenticationResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.EmailStartAuthenticationResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.EmailStartAuthenticationResponse} EmailStartAuthenticationResponse
         */
        EmailStartAuthenticationResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.EmailStartAuthenticationResponse)
                return object;
            return new $root.gcg.EmailStartAuthenticationResponse();
        };

        /**
         * Creates a plain object from an EmailStartAuthenticationResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.EmailStartAuthenticationResponse
         * @static
         * @param {gcg.EmailStartAuthenticationResponse} message EmailStartAuthenticationResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmailStartAuthenticationResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this EmailStartAuthenticationResponse to JSON.
         * @function toJSON
         * @memberof gcg.EmailStartAuthenticationResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmailStartAuthenticationResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EmailStartAuthenticationResponse
         * @function getTypeUrl
         * @memberof gcg.EmailStartAuthenticationResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmailStartAuthenticationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.EmailStartAuthenticationResponse";
        };

        return EmailStartAuthenticationResponse;
    })();

    gcg.EmailFinishAuthenticationRequest = (function() {

        /**
         * Properties of an EmailFinishAuthenticationRequest.
         * @memberof gcg
         * @interface IEmailFinishAuthenticationRequest
         * @property {string|null} [email] EmailFinishAuthenticationRequest email
         * @property {string|null} [code] EmailFinishAuthenticationRequest code
         */

        /**
         * Constructs a new EmailFinishAuthenticationRequest.
         * @memberof gcg
         * @classdesc Represents an EmailFinishAuthenticationRequest.
         * @implements IEmailFinishAuthenticationRequest
         * @constructor
         * @param {gcg.IEmailFinishAuthenticationRequest=} [properties] Properties to set
         */
        function EmailFinishAuthenticationRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmailFinishAuthenticationRequest email.
         * @member {string} email
         * @memberof gcg.EmailFinishAuthenticationRequest
         * @instance
         */
        EmailFinishAuthenticationRequest.prototype.email = "";

        /**
         * EmailFinishAuthenticationRequest code.
         * @member {string} code
         * @memberof gcg.EmailFinishAuthenticationRequest
         * @instance
         */
        EmailFinishAuthenticationRequest.prototype.code = "";

        /**
         * Creates a new EmailFinishAuthenticationRequest instance using the specified properties.
         * @function create
         * @memberof gcg.EmailFinishAuthenticationRequest
         * @static
         * @param {gcg.IEmailFinishAuthenticationRequest=} [properties] Properties to set
         * @returns {gcg.EmailFinishAuthenticationRequest} EmailFinishAuthenticationRequest instance
         */
        EmailFinishAuthenticationRequest.create = function create(properties) {
            return new EmailFinishAuthenticationRequest(properties);
        };

        /**
         * Encodes the specified EmailFinishAuthenticationRequest message. Does not implicitly {@link gcg.EmailFinishAuthenticationRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.EmailFinishAuthenticationRequest
         * @static
         * @param {gcg.IEmailFinishAuthenticationRequest} message EmailFinishAuthenticationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailFinishAuthenticationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
            return writer;
        };

        /**
         * Encodes the specified EmailFinishAuthenticationRequest message, length delimited. Does not implicitly {@link gcg.EmailFinishAuthenticationRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.EmailFinishAuthenticationRequest
         * @static
         * @param {gcg.IEmailFinishAuthenticationRequest} message EmailFinishAuthenticationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailFinishAuthenticationRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmailFinishAuthenticationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.EmailFinishAuthenticationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.EmailFinishAuthenticationRequest} EmailFinishAuthenticationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailFinishAuthenticationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.EmailFinishAuthenticationRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.email = reader.string();
                        break;
                    }
                case 2: {
                        message.code = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EmailFinishAuthenticationRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.EmailFinishAuthenticationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.EmailFinishAuthenticationRequest} EmailFinishAuthenticationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailFinishAuthenticationRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmailFinishAuthenticationRequest message.
         * @function verify
         * @memberof gcg.EmailFinishAuthenticationRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmailFinishAuthenticationRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            return null;
        };

        /**
         * Creates an EmailFinishAuthenticationRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.EmailFinishAuthenticationRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.EmailFinishAuthenticationRequest} EmailFinishAuthenticationRequest
         */
        EmailFinishAuthenticationRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.EmailFinishAuthenticationRequest)
                return object;
            let message = new $root.gcg.EmailFinishAuthenticationRequest();
            if (object.email != null)
                message.email = String(object.email);
            if (object.code != null)
                message.code = String(object.code);
            return message;
        };

        /**
         * Creates a plain object from an EmailFinishAuthenticationRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.EmailFinishAuthenticationRequest
         * @static
         * @param {gcg.EmailFinishAuthenticationRequest} message EmailFinishAuthenticationRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmailFinishAuthenticationRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.email = "";
                object.code = "";
            }
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            return object;
        };

        /**
         * Converts this EmailFinishAuthenticationRequest to JSON.
         * @function toJSON
         * @memberof gcg.EmailFinishAuthenticationRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmailFinishAuthenticationRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EmailFinishAuthenticationRequest
         * @function getTypeUrl
         * @memberof gcg.EmailFinishAuthenticationRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmailFinishAuthenticationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.EmailFinishAuthenticationRequest";
        };

        return EmailFinishAuthenticationRequest;
    })();

    gcg.EmailFinishAuthenticationResponse = (function() {

        /**
         * Properties of an EmailFinishAuthenticationResponse.
         * @memberof gcg
         * @interface IEmailFinishAuthenticationResponse
         * @property {gcg.IEmailFinishAuthenticationResponseIncorrectCode|null} [incorrectCode] EmailFinishAuthenticationResponse incorrectCode
         * @property {gcg.IEmailFinishAuthenticationResponseNotFound|null} [notFound] EmailFinishAuthenticationResponse notFound
         * @property {gcg.IEmailFinishAuthenticationResponseSuccess|null} [success] EmailFinishAuthenticationResponse success
         */

        /**
         * Constructs a new EmailFinishAuthenticationResponse.
         * @memberof gcg
         * @classdesc Represents an EmailFinishAuthenticationResponse.
         * @implements IEmailFinishAuthenticationResponse
         * @constructor
         * @param {gcg.IEmailFinishAuthenticationResponse=} [properties] Properties to set
         */
        function EmailFinishAuthenticationResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmailFinishAuthenticationResponse incorrectCode.
         * @member {gcg.IEmailFinishAuthenticationResponseIncorrectCode|null|undefined} incorrectCode
         * @memberof gcg.EmailFinishAuthenticationResponse
         * @instance
         */
        EmailFinishAuthenticationResponse.prototype.incorrectCode = null;

        /**
         * EmailFinishAuthenticationResponse notFound.
         * @member {gcg.IEmailFinishAuthenticationResponseNotFound|null|undefined} notFound
         * @memberof gcg.EmailFinishAuthenticationResponse
         * @instance
         */
        EmailFinishAuthenticationResponse.prototype.notFound = null;

        /**
         * EmailFinishAuthenticationResponse success.
         * @member {gcg.IEmailFinishAuthenticationResponseSuccess|null|undefined} success
         * @memberof gcg.EmailFinishAuthenticationResponse
         * @instance
         */
        EmailFinishAuthenticationResponse.prototype.success = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * EmailFinishAuthenticationResponse response.
         * @member {"incorrectCode"|"notFound"|"success"|undefined} response
         * @memberof gcg.EmailFinishAuthenticationResponse
         * @instance
         */
        Object.defineProperty(EmailFinishAuthenticationResponse.prototype, "response", {
            get: $util.oneOfGetter($oneOfFields = ["incorrectCode", "notFound", "success"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new EmailFinishAuthenticationResponse instance using the specified properties.
         * @function create
         * @memberof gcg.EmailFinishAuthenticationResponse
         * @static
         * @param {gcg.IEmailFinishAuthenticationResponse=} [properties] Properties to set
         * @returns {gcg.EmailFinishAuthenticationResponse} EmailFinishAuthenticationResponse instance
         */
        EmailFinishAuthenticationResponse.create = function create(properties) {
            return new EmailFinishAuthenticationResponse(properties);
        };

        /**
         * Encodes the specified EmailFinishAuthenticationResponse message. Does not implicitly {@link gcg.EmailFinishAuthenticationResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.EmailFinishAuthenticationResponse
         * @static
         * @param {gcg.IEmailFinishAuthenticationResponse} message EmailFinishAuthenticationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailFinishAuthenticationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.incorrectCode != null && Object.hasOwnProperty.call(message, "incorrectCode"))
                $root.gcg.EmailFinishAuthenticationResponseIncorrectCode.encode(message.incorrectCode, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.notFound != null && Object.hasOwnProperty.call(message, "notFound"))
                $root.gcg.EmailFinishAuthenticationResponseNotFound.encode(message.notFound, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                $root.gcg.EmailFinishAuthenticationResponseSuccess.encode(message.success, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified EmailFinishAuthenticationResponse message, length delimited. Does not implicitly {@link gcg.EmailFinishAuthenticationResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.EmailFinishAuthenticationResponse
         * @static
         * @param {gcg.IEmailFinishAuthenticationResponse} message EmailFinishAuthenticationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailFinishAuthenticationResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmailFinishAuthenticationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.EmailFinishAuthenticationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.EmailFinishAuthenticationResponse} EmailFinishAuthenticationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailFinishAuthenticationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.EmailFinishAuthenticationResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.incorrectCode = $root.gcg.EmailFinishAuthenticationResponseIncorrectCode.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.notFound = $root.gcg.EmailFinishAuthenticationResponseNotFound.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.success = $root.gcg.EmailFinishAuthenticationResponseSuccess.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EmailFinishAuthenticationResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.EmailFinishAuthenticationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.EmailFinishAuthenticationResponse} EmailFinishAuthenticationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailFinishAuthenticationResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmailFinishAuthenticationResponse message.
         * @function verify
         * @memberof gcg.EmailFinishAuthenticationResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmailFinishAuthenticationResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.incorrectCode != null && message.hasOwnProperty("incorrectCode")) {
                properties.response = 1;
                {
                    let error = $root.gcg.EmailFinishAuthenticationResponseIncorrectCode.verify(message.incorrectCode);
                    if (error)
                        return "incorrectCode." + error;
                }
            }
            if (message.notFound != null && message.hasOwnProperty("notFound")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.gcg.EmailFinishAuthenticationResponseNotFound.verify(message.notFound);
                    if (error)
                        return "notFound." + error;
                }
            }
            if (message.success != null && message.hasOwnProperty("success")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.gcg.EmailFinishAuthenticationResponseSuccess.verify(message.success);
                    if (error)
                        return "success." + error;
                }
            }
            return null;
        };

        /**
         * Creates an EmailFinishAuthenticationResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.EmailFinishAuthenticationResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.EmailFinishAuthenticationResponse} EmailFinishAuthenticationResponse
         */
        EmailFinishAuthenticationResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.EmailFinishAuthenticationResponse)
                return object;
            let message = new $root.gcg.EmailFinishAuthenticationResponse();
            if (object.incorrectCode != null) {
                if (typeof object.incorrectCode !== "object")
                    throw TypeError(".gcg.EmailFinishAuthenticationResponse.incorrectCode: object expected");
                message.incorrectCode = $root.gcg.EmailFinishAuthenticationResponseIncorrectCode.fromObject(object.incorrectCode);
            }
            if (object.notFound != null) {
                if (typeof object.notFound !== "object")
                    throw TypeError(".gcg.EmailFinishAuthenticationResponse.notFound: object expected");
                message.notFound = $root.gcg.EmailFinishAuthenticationResponseNotFound.fromObject(object.notFound);
            }
            if (object.success != null) {
                if (typeof object.success !== "object")
                    throw TypeError(".gcg.EmailFinishAuthenticationResponse.success: object expected");
                message.success = $root.gcg.EmailFinishAuthenticationResponseSuccess.fromObject(object.success);
            }
            return message;
        };

        /**
         * Creates a plain object from an EmailFinishAuthenticationResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.EmailFinishAuthenticationResponse
         * @static
         * @param {gcg.EmailFinishAuthenticationResponse} message EmailFinishAuthenticationResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmailFinishAuthenticationResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.incorrectCode != null && message.hasOwnProperty("incorrectCode")) {
                object.incorrectCode = $root.gcg.EmailFinishAuthenticationResponseIncorrectCode.toObject(message.incorrectCode, options);
                if (options.oneofs)
                    object.response = "incorrectCode";
            }
            if (message.notFound != null && message.hasOwnProperty("notFound")) {
                object.notFound = $root.gcg.EmailFinishAuthenticationResponseNotFound.toObject(message.notFound, options);
                if (options.oneofs)
                    object.response = "notFound";
            }
            if (message.success != null && message.hasOwnProperty("success")) {
                object.success = $root.gcg.EmailFinishAuthenticationResponseSuccess.toObject(message.success, options);
                if (options.oneofs)
                    object.response = "success";
            }
            return object;
        };

        /**
         * Converts this EmailFinishAuthenticationResponse to JSON.
         * @function toJSON
         * @memberof gcg.EmailFinishAuthenticationResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmailFinishAuthenticationResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EmailFinishAuthenticationResponse
         * @function getTypeUrl
         * @memberof gcg.EmailFinishAuthenticationResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmailFinishAuthenticationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.EmailFinishAuthenticationResponse";
        };

        return EmailFinishAuthenticationResponse;
    })();

    gcg.EmailFinishAuthenticationResponseIncorrectCode = (function() {

        /**
         * Properties of an EmailFinishAuthenticationResponseIncorrectCode.
         * @memberof gcg
         * @interface IEmailFinishAuthenticationResponseIncorrectCode
         */

        /**
         * Constructs a new EmailFinishAuthenticationResponseIncorrectCode.
         * @memberof gcg
         * @classdesc Represents an EmailFinishAuthenticationResponseIncorrectCode.
         * @implements IEmailFinishAuthenticationResponseIncorrectCode
         * @constructor
         * @param {gcg.IEmailFinishAuthenticationResponseIncorrectCode=} [properties] Properties to set
         */
        function EmailFinishAuthenticationResponseIncorrectCode(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new EmailFinishAuthenticationResponseIncorrectCode instance using the specified properties.
         * @function create
         * @memberof gcg.EmailFinishAuthenticationResponseIncorrectCode
         * @static
         * @param {gcg.IEmailFinishAuthenticationResponseIncorrectCode=} [properties] Properties to set
         * @returns {gcg.EmailFinishAuthenticationResponseIncorrectCode} EmailFinishAuthenticationResponseIncorrectCode instance
         */
        EmailFinishAuthenticationResponseIncorrectCode.create = function create(properties) {
            return new EmailFinishAuthenticationResponseIncorrectCode(properties);
        };

        /**
         * Encodes the specified EmailFinishAuthenticationResponseIncorrectCode message. Does not implicitly {@link gcg.EmailFinishAuthenticationResponseIncorrectCode.verify|verify} messages.
         * @function encode
         * @memberof gcg.EmailFinishAuthenticationResponseIncorrectCode
         * @static
         * @param {gcg.IEmailFinishAuthenticationResponseIncorrectCode} message EmailFinishAuthenticationResponseIncorrectCode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailFinishAuthenticationResponseIncorrectCode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified EmailFinishAuthenticationResponseIncorrectCode message, length delimited. Does not implicitly {@link gcg.EmailFinishAuthenticationResponseIncorrectCode.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.EmailFinishAuthenticationResponseIncorrectCode
         * @static
         * @param {gcg.IEmailFinishAuthenticationResponseIncorrectCode} message EmailFinishAuthenticationResponseIncorrectCode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailFinishAuthenticationResponseIncorrectCode.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmailFinishAuthenticationResponseIncorrectCode message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.EmailFinishAuthenticationResponseIncorrectCode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.EmailFinishAuthenticationResponseIncorrectCode} EmailFinishAuthenticationResponseIncorrectCode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailFinishAuthenticationResponseIncorrectCode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.EmailFinishAuthenticationResponseIncorrectCode();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EmailFinishAuthenticationResponseIncorrectCode message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.EmailFinishAuthenticationResponseIncorrectCode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.EmailFinishAuthenticationResponseIncorrectCode} EmailFinishAuthenticationResponseIncorrectCode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailFinishAuthenticationResponseIncorrectCode.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmailFinishAuthenticationResponseIncorrectCode message.
         * @function verify
         * @memberof gcg.EmailFinishAuthenticationResponseIncorrectCode
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmailFinishAuthenticationResponseIncorrectCode.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an EmailFinishAuthenticationResponseIncorrectCode message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.EmailFinishAuthenticationResponseIncorrectCode
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.EmailFinishAuthenticationResponseIncorrectCode} EmailFinishAuthenticationResponseIncorrectCode
         */
        EmailFinishAuthenticationResponseIncorrectCode.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.EmailFinishAuthenticationResponseIncorrectCode)
                return object;
            return new $root.gcg.EmailFinishAuthenticationResponseIncorrectCode();
        };

        /**
         * Creates a plain object from an EmailFinishAuthenticationResponseIncorrectCode message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.EmailFinishAuthenticationResponseIncorrectCode
         * @static
         * @param {gcg.EmailFinishAuthenticationResponseIncorrectCode} message EmailFinishAuthenticationResponseIncorrectCode
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmailFinishAuthenticationResponseIncorrectCode.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this EmailFinishAuthenticationResponseIncorrectCode to JSON.
         * @function toJSON
         * @memberof gcg.EmailFinishAuthenticationResponseIncorrectCode
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmailFinishAuthenticationResponseIncorrectCode.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EmailFinishAuthenticationResponseIncorrectCode
         * @function getTypeUrl
         * @memberof gcg.EmailFinishAuthenticationResponseIncorrectCode
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmailFinishAuthenticationResponseIncorrectCode.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.EmailFinishAuthenticationResponseIncorrectCode";
        };

        return EmailFinishAuthenticationResponseIncorrectCode;
    })();

    gcg.EmailFinishAuthenticationResponseNotFound = (function() {

        /**
         * Properties of an EmailFinishAuthenticationResponseNotFound.
         * @memberof gcg
         * @interface IEmailFinishAuthenticationResponseNotFound
         */

        /**
         * Constructs a new EmailFinishAuthenticationResponseNotFound.
         * @memberof gcg
         * @classdesc Represents an EmailFinishAuthenticationResponseNotFound.
         * @implements IEmailFinishAuthenticationResponseNotFound
         * @constructor
         * @param {gcg.IEmailFinishAuthenticationResponseNotFound=} [properties] Properties to set
         */
        function EmailFinishAuthenticationResponseNotFound(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new EmailFinishAuthenticationResponseNotFound instance using the specified properties.
         * @function create
         * @memberof gcg.EmailFinishAuthenticationResponseNotFound
         * @static
         * @param {gcg.IEmailFinishAuthenticationResponseNotFound=} [properties] Properties to set
         * @returns {gcg.EmailFinishAuthenticationResponseNotFound} EmailFinishAuthenticationResponseNotFound instance
         */
        EmailFinishAuthenticationResponseNotFound.create = function create(properties) {
            return new EmailFinishAuthenticationResponseNotFound(properties);
        };

        /**
         * Encodes the specified EmailFinishAuthenticationResponseNotFound message. Does not implicitly {@link gcg.EmailFinishAuthenticationResponseNotFound.verify|verify} messages.
         * @function encode
         * @memberof gcg.EmailFinishAuthenticationResponseNotFound
         * @static
         * @param {gcg.IEmailFinishAuthenticationResponseNotFound} message EmailFinishAuthenticationResponseNotFound message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailFinishAuthenticationResponseNotFound.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified EmailFinishAuthenticationResponseNotFound message, length delimited. Does not implicitly {@link gcg.EmailFinishAuthenticationResponseNotFound.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.EmailFinishAuthenticationResponseNotFound
         * @static
         * @param {gcg.IEmailFinishAuthenticationResponseNotFound} message EmailFinishAuthenticationResponseNotFound message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailFinishAuthenticationResponseNotFound.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmailFinishAuthenticationResponseNotFound message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.EmailFinishAuthenticationResponseNotFound
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.EmailFinishAuthenticationResponseNotFound} EmailFinishAuthenticationResponseNotFound
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailFinishAuthenticationResponseNotFound.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.EmailFinishAuthenticationResponseNotFound();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EmailFinishAuthenticationResponseNotFound message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.EmailFinishAuthenticationResponseNotFound
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.EmailFinishAuthenticationResponseNotFound} EmailFinishAuthenticationResponseNotFound
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailFinishAuthenticationResponseNotFound.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmailFinishAuthenticationResponseNotFound message.
         * @function verify
         * @memberof gcg.EmailFinishAuthenticationResponseNotFound
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmailFinishAuthenticationResponseNotFound.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an EmailFinishAuthenticationResponseNotFound message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.EmailFinishAuthenticationResponseNotFound
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.EmailFinishAuthenticationResponseNotFound} EmailFinishAuthenticationResponseNotFound
         */
        EmailFinishAuthenticationResponseNotFound.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.EmailFinishAuthenticationResponseNotFound)
                return object;
            return new $root.gcg.EmailFinishAuthenticationResponseNotFound();
        };

        /**
         * Creates a plain object from an EmailFinishAuthenticationResponseNotFound message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.EmailFinishAuthenticationResponseNotFound
         * @static
         * @param {gcg.EmailFinishAuthenticationResponseNotFound} message EmailFinishAuthenticationResponseNotFound
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmailFinishAuthenticationResponseNotFound.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this EmailFinishAuthenticationResponseNotFound to JSON.
         * @function toJSON
         * @memberof gcg.EmailFinishAuthenticationResponseNotFound
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmailFinishAuthenticationResponseNotFound.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EmailFinishAuthenticationResponseNotFound
         * @function getTypeUrl
         * @memberof gcg.EmailFinishAuthenticationResponseNotFound
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmailFinishAuthenticationResponseNotFound.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.EmailFinishAuthenticationResponseNotFound";
        };

        return EmailFinishAuthenticationResponseNotFound;
    })();

    gcg.EmailFinishAuthenticationResponseSuccess = (function() {

        /**
         * Properties of an EmailFinishAuthenticationResponseSuccess.
         * @memberof gcg
         * @interface IEmailFinishAuthenticationResponseSuccess
         * @property {string|null} [token] EmailFinishAuthenticationResponseSuccess token
         */

        /**
         * Constructs a new EmailFinishAuthenticationResponseSuccess.
         * @memberof gcg
         * @classdesc Represents an EmailFinishAuthenticationResponseSuccess.
         * @implements IEmailFinishAuthenticationResponseSuccess
         * @constructor
         * @param {gcg.IEmailFinishAuthenticationResponseSuccess=} [properties] Properties to set
         */
        function EmailFinishAuthenticationResponseSuccess(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmailFinishAuthenticationResponseSuccess token.
         * @member {string} token
         * @memberof gcg.EmailFinishAuthenticationResponseSuccess
         * @instance
         */
        EmailFinishAuthenticationResponseSuccess.prototype.token = "";

        /**
         * Creates a new EmailFinishAuthenticationResponseSuccess instance using the specified properties.
         * @function create
         * @memberof gcg.EmailFinishAuthenticationResponseSuccess
         * @static
         * @param {gcg.IEmailFinishAuthenticationResponseSuccess=} [properties] Properties to set
         * @returns {gcg.EmailFinishAuthenticationResponseSuccess} EmailFinishAuthenticationResponseSuccess instance
         */
        EmailFinishAuthenticationResponseSuccess.create = function create(properties) {
            return new EmailFinishAuthenticationResponseSuccess(properties);
        };

        /**
         * Encodes the specified EmailFinishAuthenticationResponseSuccess message. Does not implicitly {@link gcg.EmailFinishAuthenticationResponseSuccess.verify|verify} messages.
         * @function encode
         * @memberof gcg.EmailFinishAuthenticationResponseSuccess
         * @static
         * @param {gcg.IEmailFinishAuthenticationResponseSuccess} message EmailFinishAuthenticationResponseSuccess message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailFinishAuthenticationResponseSuccess.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
            return writer;
        };

        /**
         * Encodes the specified EmailFinishAuthenticationResponseSuccess message, length delimited. Does not implicitly {@link gcg.EmailFinishAuthenticationResponseSuccess.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.EmailFinishAuthenticationResponseSuccess
         * @static
         * @param {gcg.IEmailFinishAuthenticationResponseSuccess} message EmailFinishAuthenticationResponseSuccess message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailFinishAuthenticationResponseSuccess.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmailFinishAuthenticationResponseSuccess message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.EmailFinishAuthenticationResponseSuccess
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.EmailFinishAuthenticationResponseSuccess} EmailFinishAuthenticationResponseSuccess
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailFinishAuthenticationResponseSuccess.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.EmailFinishAuthenticationResponseSuccess();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.token = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EmailFinishAuthenticationResponseSuccess message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.EmailFinishAuthenticationResponseSuccess
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.EmailFinishAuthenticationResponseSuccess} EmailFinishAuthenticationResponseSuccess
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailFinishAuthenticationResponseSuccess.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmailFinishAuthenticationResponseSuccess message.
         * @function verify
         * @memberof gcg.EmailFinishAuthenticationResponseSuccess
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmailFinishAuthenticationResponseSuccess.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            return null;
        };

        /**
         * Creates an EmailFinishAuthenticationResponseSuccess message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.EmailFinishAuthenticationResponseSuccess
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.EmailFinishAuthenticationResponseSuccess} EmailFinishAuthenticationResponseSuccess
         */
        EmailFinishAuthenticationResponseSuccess.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.EmailFinishAuthenticationResponseSuccess)
                return object;
            let message = new $root.gcg.EmailFinishAuthenticationResponseSuccess();
            if (object.token != null)
                message.token = String(object.token);
            return message;
        };

        /**
         * Creates a plain object from an EmailFinishAuthenticationResponseSuccess message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.EmailFinishAuthenticationResponseSuccess
         * @static
         * @param {gcg.EmailFinishAuthenticationResponseSuccess} message EmailFinishAuthenticationResponseSuccess
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmailFinishAuthenticationResponseSuccess.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.token = "";
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            return object;
        };

        /**
         * Converts this EmailFinishAuthenticationResponseSuccess to JSON.
         * @function toJSON
         * @memberof gcg.EmailFinishAuthenticationResponseSuccess
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmailFinishAuthenticationResponseSuccess.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EmailFinishAuthenticationResponseSuccess
         * @function getTypeUrl
         * @memberof gcg.EmailFinishAuthenticationResponseSuccess
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmailFinishAuthenticationResponseSuccess.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.EmailFinishAuthenticationResponseSuccess";
        };

        return EmailFinishAuthenticationResponseSuccess;
    })();

    gcg.GcgSuccessfulResponseV2 = (function() {

        /**
         * Properties of a GcgSuccessfulResponseV2.
         * @memberof gcg
         * @interface IGcgSuccessfulResponseV2
         * @property {gcg.IUnauthenticatedResponse|null} [unauthenticated] GcgSuccessfulResponseV2 unauthenticated
         * @property {gcg.IAuthenticatedResponse|null} [authenticated] GcgSuccessfulResponseV2 authenticated
         */

        /**
         * Constructs a new GcgSuccessfulResponseV2.
         * @memberof gcg
         * @classdesc Represents a GcgSuccessfulResponseV2.
         * @implements IGcgSuccessfulResponseV2
         * @constructor
         * @param {gcg.IGcgSuccessfulResponseV2=} [properties] Properties to set
         */
        function GcgSuccessfulResponseV2(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GcgSuccessfulResponseV2 unauthenticated.
         * @member {gcg.IUnauthenticatedResponse|null|undefined} unauthenticated
         * @memberof gcg.GcgSuccessfulResponseV2
         * @instance
         */
        GcgSuccessfulResponseV2.prototype.unauthenticated = null;

        /**
         * GcgSuccessfulResponseV2 authenticated.
         * @member {gcg.IAuthenticatedResponse|null|undefined} authenticated
         * @memberof gcg.GcgSuccessfulResponseV2
         * @instance
         */
        GcgSuccessfulResponseV2.prototype.authenticated = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * GcgSuccessfulResponseV2 response.
         * @member {"unauthenticated"|"authenticated"|undefined} response
         * @memberof gcg.GcgSuccessfulResponseV2
         * @instance
         */
        Object.defineProperty(GcgSuccessfulResponseV2.prototype, "response", {
            get: $util.oneOfGetter($oneOfFields = ["unauthenticated", "authenticated"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GcgSuccessfulResponseV2 instance using the specified properties.
         * @function create
         * @memberof gcg.GcgSuccessfulResponseV2
         * @static
         * @param {gcg.IGcgSuccessfulResponseV2=} [properties] Properties to set
         * @returns {gcg.GcgSuccessfulResponseV2} GcgSuccessfulResponseV2 instance
         */
        GcgSuccessfulResponseV2.create = function create(properties) {
            return new GcgSuccessfulResponseV2(properties);
        };

        /**
         * Encodes the specified GcgSuccessfulResponseV2 message. Does not implicitly {@link gcg.GcgSuccessfulResponseV2.verify|verify} messages.
         * @function encode
         * @memberof gcg.GcgSuccessfulResponseV2
         * @static
         * @param {gcg.IGcgSuccessfulResponseV2} message GcgSuccessfulResponseV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GcgSuccessfulResponseV2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.unauthenticated != null && Object.hasOwnProperty.call(message, "unauthenticated"))
                $root.gcg.UnauthenticatedResponse.encode(message.unauthenticated, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.authenticated != null && Object.hasOwnProperty.call(message, "authenticated"))
                $root.gcg.AuthenticatedResponse.encode(message.authenticated, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GcgSuccessfulResponseV2 message, length delimited. Does not implicitly {@link gcg.GcgSuccessfulResponseV2.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.GcgSuccessfulResponseV2
         * @static
         * @param {gcg.IGcgSuccessfulResponseV2} message GcgSuccessfulResponseV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GcgSuccessfulResponseV2.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GcgSuccessfulResponseV2 message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.GcgSuccessfulResponseV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.GcgSuccessfulResponseV2} GcgSuccessfulResponseV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GcgSuccessfulResponseV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.GcgSuccessfulResponseV2();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.unauthenticated = $root.gcg.UnauthenticatedResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.authenticated = $root.gcg.AuthenticatedResponse.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GcgSuccessfulResponseV2 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.GcgSuccessfulResponseV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.GcgSuccessfulResponseV2} GcgSuccessfulResponseV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GcgSuccessfulResponseV2.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GcgSuccessfulResponseV2 message.
         * @function verify
         * @memberof gcg.GcgSuccessfulResponseV2
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GcgSuccessfulResponseV2.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.unauthenticated != null && message.hasOwnProperty("unauthenticated")) {
                properties.response = 1;
                {
                    let error = $root.gcg.UnauthenticatedResponse.verify(message.unauthenticated);
                    if (error)
                        return "unauthenticated." + error;
                }
            }
            if (message.authenticated != null && message.hasOwnProperty("authenticated")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.gcg.AuthenticatedResponse.verify(message.authenticated);
                    if (error)
                        return "authenticated." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GcgSuccessfulResponseV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.GcgSuccessfulResponseV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.GcgSuccessfulResponseV2} GcgSuccessfulResponseV2
         */
        GcgSuccessfulResponseV2.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.GcgSuccessfulResponseV2)
                return object;
            let message = new $root.gcg.GcgSuccessfulResponseV2();
            if (object.unauthenticated != null) {
                if (typeof object.unauthenticated !== "object")
                    throw TypeError(".gcg.GcgSuccessfulResponseV2.unauthenticated: object expected");
                message.unauthenticated = $root.gcg.UnauthenticatedResponse.fromObject(object.unauthenticated);
            }
            if (object.authenticated != null) {
                if (typeof object.authenticated !== "object")
                    throw TypeError(".gcg.GcgSuccessfulResponseV2.authenticated: object expected");
                message.authenticated = $root.gcg.AuthenticatedResponse.fromObject(object.authenticated);
            }
            return message;
        };

        /**
         * Creates a plain object from a GcgSuccessfulResponseV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.GcgSuccessfulResponseV2
         * @static
         * @param {gcg.GcgSuccessfulResponseV2} message GcgSuccessfulResponseV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GcgSuccessfulResponseV2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.unauthenticated != null && message.hasOwnProperty("unauthenticated")) {
                object.unauthenticated = $root.gcg.UnauthenticatedResponse.toObject(message.unauthenticated, options);
                if (options.oneofs)
                    object.response = "unauthenticated";
            }
            if (message.authenticated != null && message.hasOwnProperty("authenticated")) {
                object.authenticated = $root.gcg.AuthenticatedResponse.toObject(message.authenticated, options);
                if (options.oneofs)
                    object.response = "authenticated";
            }
            return object;
        };

        /**
         * Converts this GcgSuccessfulResponseV2 to JSON.
         * @function toJSON
         * @memberof gcg.GcgSuccessfulResponseV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GcgSuccessfulResponseV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GcgSuccessfulResponseV2
         * @function getTypeUrl
         * @memberof gcg.GcgSuccessfulResponseV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GcgSuccessfulResponseV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.GcgSuccessfulResponseV2";
        };

        return GcgSuccessfulResponseV2;
    })();

    gcg.UnauthenticatedResponse = (function() {

        /**
         * Properties of an UnauthenticatedResponse.
         * @memberof gcg
         * @interface IUnauthenticatedResponse
         * @property {gcg.IAuthenticationResponse|null} [authentication] UnauthenticatedResponse authentication
         */

        /**
         * Constructs a new UnauthenticatedResponse.
         * @memberof gcg
         * @classdesc Represents an UnauthenticatedResponse.
         * @implements IUnauthenticatedResponse
         * @constructor
         * @param {gcg.IUnauthenticatedResponse=} [properties] Properties to set
         */
        function UnauthenticatedResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UnauthenticatedResponse authentication.
         * @member {gcg.IAuthenticationResponse|null|undefined} authentication
         * @memberof gcg.UnauthenticatedResponse
         * @instance
         */
        UnauthenticatedResponse.prototype.authentication = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * UnauthenticatedResponse response.
         * @member {"authentication"|undefined} response
         * @memberof gcg.UnauthenticatedResponse
         * @instance
         */
        Object.defineProperty(UnauthenticatedResponse.prototype, "response", {
            get: $util.oneOfGetter($oneOfFields = ["authentication"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UnauthenticatedResponse instance using the specified properties.
         * @function create
         * @memberof gcg.UnauthenticatedResponse
         * @static
         * @param {gcg.IUnauthenticatedResponse=} [properties] Properties to set
         * @returns {gcg.UnauthenticatedResponse} UnauthenticatedResponse instance
         */
        UnauthenticatedResponse.create = function create(properties) {
            return new UnauthenticatedResponse(properties);
        };

        /**
         * Encodes the specified UnauthenticatedResponse message. Does not implicitly {@link gcg.UnauthenticatedResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.UnauthenticatedResponse
         * @static
         * @param {gcg.IUnauthenticatedResponse} message UnauthenticatedResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnauthenticatedResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.authentication != null && Object.hasOwnProperty.call(message, "authentication"))
                $root.gcg.AuthenticationResponse.encode(message.authentication, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UnauthenticatedResponse message, length delimited. Does not implicitly {@link gcg.UnauthenticatedResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.UnauthenticatedResponse
         * @static
         * @param {gcg.IUnauthenticatedResponse} message UnauthenticatedResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnauthenticatedResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UnauthenticatedResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.UnauthenticatedResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.UnauthenticatedResponse} UnauthenticatedResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnauthenticatedResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.UnauthenticatedResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.authentication = $root.gcg.AuthenticationResponse.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UnauthenticatedResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.UnauthenticatedResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.UnauthenticatedResponse} UnauthenticatedResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnauthenticatedResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UnauthenticatedResponse message.
         * @function verify
         * @memberof gcg.UnauthenticatedResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UnauthenticatedResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.authentication != null && message.hasOwnProperty("authentication")) {
                properties.response = 1;
                {
                    let error = $root.gcg.AuthenticationResponse.verify(message.authentication);
                    if (error)
                        return "authentication." + error;
                }
            }
            return null;
        };

        /**
         * Creates an UnauthenticatedResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.UnauthenticatedResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.UnauthenticatedResponse} UnauthenticatedResponse
         */
        UnauthenticatedResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.UnauthenticatedResponse)
                return object;
            let message = new $root.gcg.UnauthenticatedResponse();
            if (object.authentication != null) {
                if (typeof object.authentication !== "object")
                    throw TypeError(".gcg.UnauthenticatedResponse.authentication: object expected");
                message.authentication = $root.gcg.AuthenticationResponse.fromObject(object.authentication);
            }
            return message;
        };

        /**
         * Creates a plain object from an UnauthenticatedResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.UnauthenticatedResponse
         * @static
         * @param {gcg.UnauthenticatedResponse} message UnauthenticatedResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UnauthenticatedResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.authentication != null && message.hasOwnProperty("authentication")) {
                object.authentication = $root.gcg.AuthenticationResponse.toObject(message.authentication, options);
                if (options.oneofs)
                    object.response = "authentication";
            }
            return object;
        };

        /**
         * Converts this UnauthenticatedResponse to JSON.
         * @function toJSON
         * @memberof gcg.UnauthenticatedResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UnauthenticatedResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UnauthenticatedResponse
         * @function getTypeUrl
         * @memberof gcg.UnauthenticatedResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UnauthenticatedResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.UnauthenticatedResponse";
        };

        return UnauthenticatedResponse;
    })();

    gcg.GetVerificationKeyRequest = (function() {

        /**
         * Properties of a GetVerificationKeyRequest.
         * @memberof gcg
         * @interface IGetVerificationKeyRequest
         */

        /**
         * Constructs a new GetVerificationKeyRequest.
         * @memberof gcg
         * @classdesc Represents a GetVerificationKeyRequest.
         * @implements IGetVerificationKeyRequest
         * @constructor
         * @param {gcg.IGetVerificationKeyRequest=} [properties] Properties to set
         */
        function GetVerificationKeyRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetVerificationKeyRequest instance using the specified properties.
         * @function create
         * @memberof gcg.GetVerificationKeyRequest
         * @static
         * @param {gcg.IGetVerificationKeyRequest=} [properties] Properties to set
         * @returns {gcg.GetVerificationKeyRequest} GetVerificationKeyRequest instance
         */
        GetVerificationKeyRequest.create = function create(properties) {
            return new GetVerificationKeyRequest(properties);
        };

        /**
         * Encodes the specified GetVerificationKeyRequest message. Does not implicitly {@link gcg.GetVerificationKeyRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.GetVerificationKeyRequest
         * @static
         * @param {gcg.IGetVerificationKeyRequest} message GetVerificationKeyRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetVerificationKeyRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetVerificationKeyRequest message, length delimited. Does not implicitly {@link gcg.GetVerificationKeyRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.GetVerificationKeyRequest
         * @static
         * @param {gcg.IGetVerificationKeyRequest} message GetVerificationKeyRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetVerificationKeyRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetVerificationKeyRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.GetVerificationKeyRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.GetVerificationKeyRequest} GetVerificationKeyRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetVerificationKeyRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.GetVerificationKeyRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetVerificationKeyRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.GetVerificationKeyRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.GetVerificationKeyRequest} GetVerificationKeyRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetVerificationKeyRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetVerificationKeyRequest message.
         * @function verify
         * @memberof gcg.GetVerificationKeyRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetVerificationKeyRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetVerificationKeyRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.GetVerificationKeyRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.GetVerificationKeyRequest} GetVerificationKeyRequest
         */
        GetVerificationKeyRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.GetVerificationKeyRequest)
                return object;
            return new $root.gcg.GetVerificationKeyRequest();
        };

        /**
         * Creates a plain object from a GetVerificationKeyRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.GetVerificationKeyRequest
         * @static
         * @param {gcg.GetVerificationKeyRequest} message GetVerificationKeyRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetVerificationKeyRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetVerificationKeyRequest to JSON.
         * @function toJSON
         * @memberof gcg.GetVerificationKeyRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetVerificationKeyRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetVerificationKeyRequest
         * @function getTypeUrl
         * @memberof gcg.GetVerificationKeyRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetVerificationKeyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.GetVerificationKeyRequest";
        };

        return GetVerificationKeyRequest;
    })();

    gcg.GetVerificationKeyResponse = (function() {

        /**
         * Properties of a GetVerificationKeyResponse.
         * @memberof gcg
         * @interface IGetVerificationKeyResponse
         * @property {Uint8Array|null} [verificationKey] GetVerificationKeyResponse verificationKey
         */

        /**
         * Constructs a new GetVerificationKeyResponse.
         * @memberof gcg
         * @classdesc Represents a GetVerificationKeyResponse.
         * @implements IGetVerificationKeyResponse
         * @constructor
         * @param {gcg.IGetVerificationKeyResponse=} [properties] Properties to set
         */
        function GetVerificationKeyResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetVerificationKeyResponse verificationKey.
         * @member {Uint8Array} verificationKey
         * @memberof gcg.GetVerificationKeyResponse
         * @instance
         */
        GetVerificationKeyResponse.prototype.verificationKey = $util.newBuffer([]);

        /**
         * Creates a new GetVerificationKeyResponse instance using the specified properties.
         * @function create
         * @memberof gcg.GetVerificationKeyResponse
         * @static
         * @param {gcg.IGetVerificationKeyResponse=} [properties] Properties to set
         * @returns {gcg.GetVerificationKeyResponse} GetVerificationKeyResponse instance
         */
        GetVerificationKeyResponse.create = function create(properties) {
            return new GetVerificationKeyResponse(properties);
        };

        /**
         * Encodes the specified GetVerificationKeyResponse message. Does not implicitly {@link gcg.GetVerificationKeyResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.GetVerificationKeyResponse
         * @static
         * @param {gcg.IGetVerificationKeyResponse} message GetVerificationKeyResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetVerificationKeyResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.verificationKey != null && Object.hasOwnProperty.call(message, "verificationKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.verificationKey);
            return writer;
        };

        /**
         * Encodes the specified GetVerificationKeyResponse message, length delimited. Does not implicitly {@link gcg.GetVerificationKeyResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.GetVerificationKeyResponse
         * @static
         * @param {gcg.IGetVerificationKeyResponse} message GetVerificationKeyResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetVerificationKeyResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetVerificationKeyResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.GetVerificationKeyResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.GetVerificationKeyResponse} GetVerificationKeyResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetVerificationKeyResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.GetVerificationKeyResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.verificationKey = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetVerificationKeyResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.GetVerificationKeyResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.GetVerificationKeyResponse} GetVerificationKeyResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetVerificationKeyResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetVerificationKeyResponse message.
         * @function verify
         * @memberof gcg.GetVerificationKeyResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetVerificationKeyResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.verificationKey != null && message.hasOwnProperty("verificationKey"))
                if (!(message.verificationKey && typeof message.verificationKey.length === "number" || $util.isString(message.verificationKey)))
                    return "verificationKey: buffer expected";
            return null;
        };

        /**
         * Creates a GetVerificationKeyResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.GetVerificationKeyResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.GetVerificationKeyResponse} GetVerificationKeyResponse
         */
        GetVerificationKeyResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.GetVerificationKeyResponse)
                return object;
            let message = new $root.gcg.GetVerificationKeyResponse();
            if (object.verificationKey != null)
                if (typeof object.verificationKey === "string")
                    $util.base64.decode(object.verificationKey, message.verificationKey = $util.newBuffer($util.base64.length(object.verificationKey)), 0);
                else if (object.verificationKey.length >= 0)
                    message.verificationKey = object.verificationKey;
            return message;
        };

        /**
         * Creates a plain object from a GetVerificationKeyResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.GetVerificationKeyResponse
         * @static
         * @param {gcg.GetVerificationKeyResponse} message GetVerificationKeyResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetVerificationKeyResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.verificationKey = "";
                else {
                    object.verificationKey = [];
                    if (options.bytes !== Array)
                        object.verificationKey = $util.newBuffer(object.verificationKey);
                }
            if (message.verificationKey != null && message.hasOwnProperty("verificationKey"))
                object.verificationKey = options.bytes === String ? $util.base64.encode(message.verificationKey, 0, message.verificationKey.length) : options.bytes === Array ? Array.prototype.slice.call(message.verificationKey) : message.verificationKey;
            return object;
        };

        /**
         * Converts this GetVerificationKeyResponse to JSON.
         * @function toJSON
         * @memberof gcg.GetVerificationKeyResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetVerificationKeyResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetVerificationKeyResponse
         * @function getTypeUrl
         * @memberof gcg.GetVerificationKeyResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetVerificationKeyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.GetVerificationKeyResponse";
        };

        return GetVerificationKeyResponse;
    })();

    gcg.CreatePolicyRequest = (function() {

        /**
         * Properties of a CreatePolicyRequest.
         * @memberof gcg
         * @interface ICreatePolicyRequest
         * @property {release_policy_api.IReleasePolicy|null} [policy] CreatePolicyRequest policy
         * @property {Uint8Array|null} [scope] Required if referencing a dataset as part of the policy
         */

        /**
         * Constructs a new CreatePolicyRequest.
         * @memberof gcg
         * @classdesc Represents a CreatePolicyRequest.
         * @implements ICreatePolicyRequest
         * @constructor
         * @param {gcg.ICreatePolicyRequest=} [properties] Properties to set
         */
        function CreatePolicyRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreatePolicyRequest policy.
         * @member {release_policy_api.IReleasePolicy|null|undefined} policy
         * @memberof gcg.CreatePolicyRequest
         * @instance
         */
        CreatePolicyRequest.prototype.policy = null;

        /**
         * Required if referencing a dataset as part of the policy
         * @member {Uint8Array|null|undefined} scope
         * @memberof gcg.CreatePolicyRequest
         * @instance
         */
        CreatePolicyRequest.prototype.scope = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * CreatePolicyRequest _scope.
         * @member {"scope"|undefined} _scope
         * @memberof gcg.CreatePolicyRequest
         * @instance
         */
        Object.defineProperty(CreatePolicyRequest.prototype, "_scope", {
            get: $util.oneOfGetter($oneOfFields = ["scope"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CreatePolicyRequest instance using the specified properties.
         * @function create
         * @memberof gcg.CreatePolicyRequest
         * @static
         * @param {gcg.ICreatePolicyRequest=} [properties] Properties to set
         * @returns {gcg.CreatePolicyRequest} CreatePolicyRequest instance
         */
        CreatePolicyRequest.create = function create(properties) {
            return new CreatePolicyRequest(properties);
        };

        /**
         * Encodes the specified CreatePolicyRequest message. Does not implicitly {@link gcg.CreatePolicyRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.CreatePolicyRequest
         * @static
         * @param {gcg.ICreatePolicyRequest} message CreatePolicyRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreatePolicyRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.policy != null && Object.hasOwnProperty.call(message, "policy"))
                $root.release_policy_api.ReleasePolicy.encode(message.policy, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.scope != null && Object.hasOwnProperty.call(message, "scope"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.scope);
            return writer;
        };

        /**
         * Encodes the specified CreatePolicyRequest message, length delimited. Does not implicitly {@link gcg.CreatePolicyRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.CreatePolicyRequest
         * @static
         * @param {gcg.ICreatePolicyRequest} message CreatePolicyRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreatePolicyRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreatePolicyRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.CreatePolicyRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.CreatePolicyRequest} CreatePolicyRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreatePolicyRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.CreatePolicyRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.policy = $root.release_policy_api.ReleasePolicy.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.scope = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreatePolicyRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.CreatePolicyRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.CreatePolicyRequest} CreatePolicyRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreatePolicyRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreatePolicyRequest message.
         * @function verify
         * @memberof gcg.CreatePolicyRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreatePolicyRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.policy != null && message.hasOwnProperty("policy")) {
                let error = $root.release_policy_api.ReleasePolicy.verify(message.policy);
                if (error)
                    return "policy." + error;
            }
            if (message.scope != null && message.hasOwnProperty("scope")) {
                properties._scope = 1;
                if (!(message.scope && typeof message.scope.length === "number" || $util.isString(message.scope)))
                    return "scope: buffer expected";
            }
            return null;
        };

        /**
         * Creates a CreatePolicyRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.CreatePolicyRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.CreatePolicyRequest} CreatePolicyRequest
         */
        CreatePolicyRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.CreatePolicyRequest)
                return object;
            let message = new $root.gcg.CreatePolicyRequest();
            if (object.policy != null) {
                if (typeof object.policy !== "object")
                    throw TypeError(".gcg.CreatePolicyRequest.policy: object expected");
                message.policy = $root.release_policy_api.ReleasePolicy.fromObject(object.policy);
            }
            if (object.scope != null)
                if (typeof object.scope === "string")
                    $util.base64.decode(object.scope, message.scope = $util.newBuffer($util.base64.length(object.scope)), 0);
                else if (object.scope.length >= 0)
                    message.scope = object.scope;
            return message;
        };

        /**
         * Creates a plain object from a CreatePolicyRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.CreatePolicyRequest
         * @static
         * @param {gcg.CreatePolicyRequest} message CreatePolicyRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreatePolicyRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.policy = null;
            if (message.policy != null && message.hasOwnProperty("policy"))
                object.policy = $root.release_policy_api.ReleasePolicy.toObject(message.policy, options);
            if (message.scope != null && message.hasOwnProperty("scope")) {
                object.scope = options.bytes === String ? $util.base64.encode(message.scope, 0, message.scope.length) : options.bytes === Array ? Array.prototype.slice.call(message.scope) : message.scope;
                if (options.oneofs)
                    object._scope = "scope";
            }
            return object;
        };

        /**
         * Converts this CreatePolicyRequest to JSON.
         * @function toJSON
         * @memberof gcg.CreatePolicyRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreatePolicyRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreatePolicyRequest
         * @function getTypeUrl
         * @memberof gcg.CreatePolicyRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreatePolicyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.CreatePolicyRequest";
        };

        return CreatePolicyRequest;
    })();

    gcg.CreatePolicyResponse = (function() {

        /**
         * Properties of a CreatePolicyResponse.
         * @memberof gcg
         * @interface ICreatePolicyResponse
         * @property {string|null} [policyId] CreatePolicyResponse policyId
         */

        /**
         * Constructs a new CreatePolicyResponse.
         * @memberof gcg
         * @classdesc Represents a CreatePolicyResponse.
         * @implements ICreatePolicyResponse
         * @constructor
         * @param {gcg.ICreatePolicyResponse=} [properties] Properties to set
         */
        function CreatePolicyResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreatePolicyResponse policyId.
         * @member {string} policyId
         * @memberof gcg.CreatePolicyResponse
         * @instance
         */
        CreatePolicyResponse.prototype.policyId = "";

        /**
         * Creates a new CreatePolicyResponse instance using the specified properties.
         * @function create
         * @memberof gcg.CreatePolicyResponse
         * @static
         * @param {gcg.ICreatePolicyResponse=} [properties] Properties to set
         * @returns {gcg.CreatePolicyResponse} CreatePolicyResponse instance
         */
        CreatePolicyResponse.create = function create(properties) {
            return new CreatePolicyResponse(properties);
        };

        /**
         * Encodes the specified CreatePolicyResponse message. Does not implicitly {@link gcg.CreatePolicyResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.CreatePolicyResponse
         * @static
         * @param {gcg.ICreatePolicyResponse} message CreatePolicyResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreatePolicyResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.policyId != null && Object.hasOwnProperty.call(message, "policyId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.policyId);
            return writer;
        };

        /**
         * Encodes the specified CreatePolicyResponse message, length delimited. Does not implicitly {@link gcg.CreatePolicyResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.CreatePolicyResponse
         * @static
         * @param {gcg.ICreatePolicyResponse} message CreatePolicyResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreatePolicyResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreatePolicyResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.CreatePolicyResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.CreatePolicyResponse} CreatePolicyResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreatePolicyResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.CreatePolicyResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.policyId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreatePolicyResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.CreatePolicyResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.CreatePolicyResponse} CreatePolicyResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreatePolicyResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreatePolicyResponse message.
         * @function verify
         * @memberof gcg.CreatePolicyResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreatePolicyResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.policyId != null && message.hasOwnProperty("policyId"))
                if (!$util.isString(message.policyId))
                    return "policyId: string expected";
            return null;
        };

        /**
         * Creates a CreatePolicyResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.CreatePolicyResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.CreatePolicyResponse} CreatePolicyResponse
         */
        CreatePolicyResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.CreatePolicyResponse)
                return object;
            let message = new $root.gcg.CreatePolicyResponse();
            if (object.policyId != null)
                message.policyId = String(object.policyId);
            return message;
        };

        /**
         * Creates a plain object from a CreatePolicyResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.CreatePolicyResponse
         * @static
         * @param {gcg.CreatePolicyResponse} message CreatePolicyResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreatePolicyResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.policyId = "";
            if (message.policyId != null && message.hasOwnProperty("policyId"))
                object.policyId = message.policyId;
            return object;
        };

        /**
         * Converts this CreatePolicyResponse to JSON.
         * @function toJSON
         * @memberof gcg.CreatePolicyResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreatePolicyResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreatePolicyResponse
         * @function getTypeUrl
         * @memberof gcg.CreatePolicyResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreatePolicyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.CreatePolicyResponse";
        };

        return CreatePolicyResponse;
    })();

    gcg.DcrActionRequest = (function() {

        /**
         * Properties of a DcrActionRequest.
         * @memberof gcg
         * @interface IDcrActionRequest
         * @property {Uint8Array|null} [dataRoomId] DcrActionRequest dataRoomId
         * @property {Uint8Array|null} [action] DcrActionRequest action
         */

        /**
         * Constructs a new DcrActionRequest.
         * @memberof gcg
         * @classdesc Represents a DcrActionRequest.
         * @implements IDcrActionRequest
         * @constructor
         * @param {gcg.IDcrActionRequest=} [properties] Properties to set
         */
        function DcrActionRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DcrActionRequest dataRoomId.
         * @member {Uint8Array} dataRoomId
         * @memberof gcg.DcrActionRequest
         * @instance
         */
        DcrActionRequest.prototype.dataRoomId = $util.newBuffer([]);

        /**
         * DcrActionRequest action.
         * @member {Uint8Array} action
         * @memberof gcg.DcrActionRequest
         * @instance
         */
        DcrActionRequest.prototype.action = $util.newBuffer([]);

        /**
         * Creates a new DcrActionRequest instance using the specified properties.
         * @function create
         * @memberof gcg.DcrActionRequest
         * @static
         * @param {gcg.IDcrActionRequest=} [properties] Properties to set
         * @returns {gcg.DcrActionRequest} DcrActionRequest instance
         */
        DcrActionRequest.create = function create(properties) {
            return new DcrActionRequest(properties);
        };

        /**
         * Encodes the specified DcrActionRequest message. Does not implicitly {@link gcg.DcrActionRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.DcrActionRequest
         * @static
         * @param {gcg.IDcrActionRequest} message DcrActionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrActionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dataRoomId != null && Object.hasOwnProperty.call(message, "dataRoomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dataRoomId);
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.action);
            return writer;
        };

        /**
         * Encodes the specified DcrActionRequest message, length delimited. Does not implicitly {@link gcg.DcrActionRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.DcrActionRequest
         * @static
         * @param {gcg.IDcrActionRequest} message DcrActionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrActionRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DcrActionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.DcrActionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.DcrActionRequest} DcrActionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrActionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.DcrActionRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dataRoomId = reader.bytes();
                        break;
                    }
                case 2: {
                        message.action = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DcrActionRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.DcrActionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.DcrActionRequest} DcrActionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrActionRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DcrActionRequest message.
         * @function verify
         * @memberof gcg.DcrActionRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DcrActionRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                if (!(message.dataRoomId && typeof message.dataRoomId.length === "number" || $util.isString(message.dataRoomId)))
                    return "dataRoomId: buffer expected";
            if (message.action != null && message.hasOwnProperty("action"))
                if (!(message.action && typeof message.action.length === "number" || $util.isString(message.action)))
                    return "action: buffer expected";
            return null;
        };

        /**
         * Creates a DcrActionRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.DcrActionRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.DcrActionRequest} DcrActionRequest
         */
        DcrActionRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.DcrActionRequest)
                return object;
            let message = new $root.gcg.DcrActionRequest();
            if (object.dataRoomId != null)
                if (typeof object.dataRoomId === "string")
                    $util.base64.decode(object.dataRoomId, message.dataRoomId = $util.newBuffer($util.base64.length(object.dataRoomId)), 0);
                else if (object.dataRoomId.length >= 0)
                    message.dataRoomId = object.dataRoomId;
            if (object.action != null)
                if (typeof object.action === "string")
                    $util.base64.decode(object.action, message.action = $util.newBuffer($util.base64.length(object.action)), 0);
                else if (object.action.length >= 0)
                    message.action = object.action;
            return message;
        };

        /**
         * Creates a plain object from a DcrActionRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.DcrActionRequest
         * @static
         * @param {gcg.DcrActionRequest} message DcrActionRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DcrActionRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.dataRoomId = "";
                else {
                    object.dataRoomId = [];
                    if (options.bytes !== Array)
                        object.dataRoomId = $util.newBuffer(object.dataRoomId);
                }
                if (options.bytes === String)
                    object.action = "";
                else {
                    object.action = [];
                    if (options.bytes !== Array)
                        object.action = $util.newBuffer(object.action);
                }
            }
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                object.dataRoomId = options.bytes === String ? $util.base64.encode(message.dataRoomId, 0, message.dataRoomId.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomId) : message.dataRoomId;
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = options.bytes === String ? $util.base64.encode(message.action, 0, message.action.length) : options.bytes === Array ? Array.prototype.slice.call(message.action) : message.action;
            return object;
        };

        /**
         * Converts this DcrActionRequest to JSON.
         * @function toJSON
         * @memberof gcg.DcrActionRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DcrActionRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DcrActionRequest
         * @function getTypeUrl
         * @memberof gcg.DcrActionRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DcrActionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.DcrActionRequest";
        };

        return DcrActionRequest;
    })();

    gcg.DcrActionResponse = (function() {

        /**
         * Properties of a DcrActionResponse.
         * @memberof gcg
         * @interface IDcrActionResponse
         * @property {Uint8Array|null} [response] DcrActionResponse response
         */

        /**
         * Constructs a new DcrActionResponse.
         * @memberof gcg
         * @classdesc Represents a DcrActionResponse.
         * @implements IDcrActionResponse
         * @constructor
         * @param {gcg.IDcrActionResponse=} [properties] Properties to set
         */
        function DcrActionResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DcrActionResponse response.
         * @member {Uint8Array} response
         * @memberof gcg.DcrActionResponse
         * @instance
         */
        DcrActionResponse.prototype.response = $util.newBuffer([]);

        /**
         * Creates a new DcrActionResponse instance using the specified properties.
         * @function create
         * @memberof gcg.DcrActionResponse
         * @static
         * @param {gcg.IDcrActionResponse=} [properties] Properties to set
         * @returns {gcg.DcrActionResponse} DcrActionResponse instance
         */
        DcrActionResponse.create = function create(properties) {
            return new DcrActionResponse(properties);
        };

        /**
         * Encodes the specified DcrActionResponse message. Does not implicitly {@link gcg.DcrActionResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.DcrActionResponse
         * @static
         * @param {gcg.IDcrActionResponse} message DcrActionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrActionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.response != null && Object.hasOwnProperty.call(message, "response"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.response);
            return writer;
        };

        /**
         * Encodes the specified DcrActionResponse message, length delimited. Does not implicitly {@link gcg.DcrActionResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.DcrActionResponse
         * @static
         * @param {gcg.IDcrActionResponse} message DcrActionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrActionResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DcrActionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.DcrActionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.DcrActionResponse} DcrActionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrActionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.DcrActionResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.response = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DcrActionResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.DcrActionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.DcrActionResponse} DcrActionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrActionResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DcrActionResponse message.
         * @function verify
         * @memberof gcg.DcrActionResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DcrActionResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.response != null && message.hasOwnProperty("response"))
                if (!(message.response && typeof message.response.length === "number" || $util.isString(message.response)))
                    return "response: buffer expected";
            return null;
        };

        /**
         * Creates a DcrActionResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.DcrActionResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.DcrActionResponse} DcrActionResponse
         */
        DcrActionResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.DcrActionResponse)
                return object;
            let message = new $root.gcg.DcrActionResponse();
            if (object.response != null)
                if (typeof object.response === "string")
                    $util.base64.decode(object.response, message.response = $util.newBuffer($util.base64.length(object.response)), 0);
                else if (object.response.length >= 0)
                    message.response = object.response;
            return message;
        };

        /**
         * Creates a plain object from a DcrActionResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.DcrActionResponse
         * @static
         * @param {gcg.DcrActionResponse} message DcrActionResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DcrActionResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.response = "";
                else {
                    object.response = [];
                    if (options.bytes !== Array)
                        object.response = $util.newBuffer(object.response);
                }
            if (message.response != null && message.hasOwnProperty("response"))
                object.response = options.bytes === String ? $util.base64.encode(message.response, 0, message.response.length) : options.bytes === Array ? Array.prototype.slice.call(message.response) : message.response;
            return object;
        };

        /**
         * Converts this DcrActionResponse to JSON.
         * @function toJSON
         * @memberof gcg.DcrActionResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DcrActionResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DcrActionResponse
         * @function getTypeUrl
         * @memberof gcg.DcrActionResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DcrActionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.DcrActionResponse";
        };

        return DcrActionResponse;
    })();

    gcg.CreateApiTokenRequest = (function() {

        /**
         * Properties of a CreateApiTokenRequest.
         * @memberof gcg
         * @interface ICreateApiTokenRequest
         * @property {string|null} [name] CreateApiTokenRequest name
         */

        /**
         * Constructs a new CreateApiTokenRequest.
         * @memberof gcg
         * @classdesc Represents a CreateApiTokenRequest.
         * @implements ICreateApiTokenRequest
         * @constructor
         * @param {gcg.ICreateApiTokenRequest=} [properties] Properties to set
         */
        function CreateApiTokenRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateApiTokenRequest name.
         * @member {string} name
         * @memberof gcg.CreateApiTokenRequest
         * @instance
         */
        CreateApiTokenRequest.prototype.name = "";

        /**
         * Creates a new CreateApiTokenRequest instance using the specified properties.
         * @function create
         * @memberof gcg.CreateApiTokenRequest
         * @static
         * @param {gcg.ICreateApiTokenRequest=} [properties] Properties to set
         * @returns {gcg.CreateApiTokenRequest} CreateApiTokenRequest instance
         */
        CreateApiTokenRequest.create = function create(properties) {
            return new CreateApiTokenRequest(properties);
        };

        /**
         * Encodes the specified CreateApiTokenRequest message. Does not implicitly {@link gcg.CreateApiTokenRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.CreateApiTokenRequest
         * @static
         * @param {gcg.ICreateApiTokenRequest} message CreateApiTokenRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateApiTokenRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified CreateApiTokenRequest message, length delimited. Does not implicitly {@link gcg.CreateApiTokenRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.CreateApiTokenRequest
         * @static
         * @param {gcg.ICreateApiTokenRequest} message CreateApiTokenRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateApiTokenRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateApiTokenRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.CreateApiTokenRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.CreateApiTokenRequest} CreateApiTokenRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateApiTokenRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.CreateApiTokenRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateApiTokenRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.CreateApiTokenRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.CreateApiTokenRequest} CreateApiTokenRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateApiTokenRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateApiTokenRequest message.
         * @function verify
         * @memberof gcg.CreateApiTokenRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateApiTokenRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a CreateApiTokenRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.CreateApiTokenRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.CreateApiTokenRequest} CreateApiTokenRequest
         */
        CreateApiTokenRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.CreateApiTokenRequest)
                return object;
            let message = new $root.gcg.CreateApiTokenRequest();
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a CreateApiTokenRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.CreateApiTokenRequest
         * @static
         * @param {gcg.CreateApiTokenRequest} message CreateApiTokenRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateApiTokenRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this CreateApiTokenRequest to JSON.
         * @function toJSON
         * @memberof gcg.CreateApiTokenRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateApiTokenRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateApiTokenRequest
         * @function getTypeUrl
         * @memberof gcg.CreateApiTokenRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateApiTokenRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.CreateApiTokenRequest";
        };

        return CreateApiTokenRequest;
    })();

    gcg.CreateApiTokenResponse = (function() {

        /**
         * Properties of a CreateApiTokenResponse.
         * @memberof gcg
         * @interface ICreateApiTokenResponse
         * @property {string|null} [token] CreateApiTokenResponse token
         */

        /**
         * Constructs a new CreateApiTokenResponse.
         * @memberof gcg
         * @classdesc Represents a CreateApiTokenResponse.
         * @implements ICreateApiTokenResponse
         * @constructor
         * @param {gcg.ICreateApiTokenResponse=} [properties] Properties to set
         */
        function CreateApiTokenResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateApiTokenResponse token.
         * @member {string} token
         * @memberof gcg.CreateApiTokenResponse
         * @instance
         */
        CreateApiTokenResponse.prototype.token = "";

        /**
         * Creates a new CreateApiTokenResponse instance using the specified properties.
         * @function create
         * @memberof gcg.CreateApiTokenResponse
         * @static
         * @param {gcg.ICreateApiTokenResponse=} [properties] Properties to set
         * @returns {gcg.CreateApiTokenResponse} CreateApiTokenResponse instance
         */
        CreateApiTokenResponse.create = function create(properties) {
            return new CreateApiTokenResponse(properties);
        };

        /**
         * Encodes the specified CreateApiTokenResponse message. Does not implicitly {@link gcg.CreateApiTokenResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.CreateApiTokenResponse
         * @static
         * @param {gcg.ICreateApiTokenResponse} message CreateApiTokenResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateApiTokenResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
            return writer;
        };

        /**
         * Encodes the specified CreateApiTokenResponse message, length delimited. Does not implicitly {@link gcg.CreateApiTokenResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.CreateApiTokenResponse
         * @static
         * @param {gcg.ICreateApiTokenResponse} message CreateApiTokenResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateApiTokenResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateApiTokenResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.CreateApiTokenResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.CreateApiTokenResponse} CreateApiTokenResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateApiTokenResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.CreateApiTokenResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.token = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateApiTokenResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.CreateApiTokenResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.CreateApiTokenResponse} CreateApiTokenResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateApiTokenResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateApiTokenResponse message.
         * @function verify
         * @memberof gcg.CreateApiTokenResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateApiTokenResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            return null;
        };

        /**
         * Creates a CreateApiTokenResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.CreateApiTokenResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.CreateApiTokenResponse} CreateApiTokenResponse
         */
        CreateApiTokenResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.CreateApiTokenResponse)
                return object;
            let message = new $root.gcg.CreateApiTokenResponse();
            if (object.token != null)
                message.token = String(object.token);
            return message;
        };

        /**
         * Creates a plain object from a CreateApiTokenResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.CreateApiTokenResponse
         * @static
         * @param {gcg.CreateApiTokenResponse} message CreateApiTokenResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateApiTokenResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.token = "";
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            return object;
        };

        /**
         * Converts this CreateApiTokenResponse to JSON.
         * @function toJSON
         * @memberof gcg.CreateApiTokenResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateApiTokenResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateApiTokenResponse
         * @function getTypeUrl
         * @memberof gcg.CreateApiTokenResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateApiTokenResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.CreateApiTokenResponse";
        };

        return CreateApiTokenResponse;
    })();

    gcg.GetApiTokensRequest = (function() {

        /**
         * Properties of a GetApiTokensRequest.
         * @memberof gcg
         * @interface IGetApiTokensRequest
         */

        /**
         * Constructs a new GetApiTokensRequest.
         * @memberof gcg
         * @classdesc Represents a GetApiTokensRequest.
         * @implements IGetApiTokensRequest
         * @constructor
         * @param {gcg.IGetApiTokensRequest=} [properties] Properties to set
         */
        function GetApiTokensRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetApiTokensRequest instance using the specified properties.
         * @function create
         * @memberof gcg.GetApiTokensRequest
         * @static
         * @param {gcg.IGetApiTokensRequest=} [properties] Properties to set
         * @returns {gcg.GetApiTokensRequest} GetApiTokensRequest instance
         */
        GetApiTokensRequest.create = function create(properties) {
            return new GetApiTokensRequest(properties);
        };

        /**
         * Encodes the specified GetApiTokensRequest message. Does not implicitly {@link gcg.GetApiTokensRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.GetApiTokensRequest
         * @static
         * @param {gcg.IGetApiTokensRequest} message GetApiTokensRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetApiTokensRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetApiTokensRequest message, length delimited. Does not implicitly {@link gcg.GetApiTokensRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.GetApiTokensRequest
         * @static
         * @param {gcg.IGetApiTokensRequest} message GetApiTokensRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetApiTokensRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetApiTokensRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.GetApiTokensRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.GetApiTokensRequest} GetApiTokensRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetApiTokensRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.GetApiTokensRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetApiTokensRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.GetApiTokensRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.GetApiTokensRequest} GetApiTokensRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetApiTokensRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetApiTokensRequest message.
         * @function verify
         * @memberof gcg.GetApiTokensRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetApiTokensRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetApiTokensRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.GetApiTokensRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.GetApiTokensRequest} GetApiTokensRequest
         */
        GetApiTokensRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.GetApiTokensRequest)
                return object;
            return new $root.gcg.GetApiTokensRequest();
        };

        /**
         * Creates a plain object from a GetApiTokensRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.GetApiTokensRequest
         * @static
         * @param {gcg.GetApiTokensRequest} message GetApiTokensRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetApiTokensRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetApiTokensRequest to JSON.
         * @function toJSON
         * @memberof gcg.GetApiTokensRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetApiTokensRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetApiTokensRequest
         * @function getTypeUrl
         * @memberof gcg.GetApiTokensRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetApiTokensRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.GetApiTokensRequest";
        };

        return GetApiTokensRequest;
    })();

    gcg.GetApiTokensResponse = (function() {

        /**
         * Properties of a GetApiTokensResponse.
         * @memberof gcg
         * @interface IGetApiTokensResponse
         * @property {Array.<gcg.IGetApiTokenResult>|null} [tokens] GetApiTokensResponse tokens
         */

        /**
         * Constructs a new GetApiTokensResponse.
         * @memberof gcg
         * @classdesc Represents a GetApiTokensResponse.
         * @implements IGetApiTokensResponse
         * @constructor
         * @param {gcg.IGetApiTokensResponse=} [properties] Properties to set
         */
        function GetApiTokensResponse(properties) {
            this.tokens = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetApiTokensResponse tokens.
         * @member {Array.<gcg.IGetApiTokenResult>} tokens
         * @memberof gcg.GetApiTokensResponse
         * @instance
         */
        GetApiTokensResponse.prototype.tokens = $util.emptyArray;

        /**
         * Creates a new GetApiTokensResponse instance using the specified properties.
         * @function create
         * @memberof gcg.GetApiTokensResponse
         * @static
         * @param {gcg.IGetApiTokensResponse=} [properties] Properties to set
         * @returns {gcg.GetApiTokensResponse} GetApiTokensResponse instance
         */
        GetApiTokensResponse.create = function create(properties) {
            return new GetApiTokensResponse(properties);
        };

        /**
         * Encodes the specified GetApiTokensResponse message. Does not implicitly {@link gcg.GetApiTokensResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.GetApiTokensResponse
         * @static
         * @param {gcg.IGetApiTokensResponse} message GetApiTokensResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetApiTokensResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tokens != null && message.tokens.length)
                for (let i = 0; i < message.tokens.length; ++i)
                    $root.gcg.GetApiTokenResult.encode(message.tokens[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetApiTokensResponse message, length delimited. Does not implicitly {@link gcg.GetApiTokensResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.GetApiTokensResponse
         * @static
         * @param {gcg.IGetApiTokensResponse} message GetApiTokensResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetApiTokensResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetApiTokensResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.GetApiTokensResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.GetApiTokensResponse} GetApiTokensResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetApiTokensResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.GetApiTokensResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.tokens && message.tokens.length))
                            message.tokens = [];
                        message.tokens.push($root.gcg.GetApiTokenResult.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetApiTokensResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.GetApiTokensResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.GetApiTokensResponse} GetApiTokensResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetApiTokensResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetApiTokensResponse message.
         * @function verify
         * @memberof gcg.GetApiTokensResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetApiTokensResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tokens != null && message.hasOwnProperty("tokens")) {
                if (!Array.isArray(message.tokens))
                    return "tokens: array expected";
                for (let i = 0; i < message.tokens.length; ++i) {
                    let error = $root.gcg.GetApiTokenResult.verify(message.tokens[i]);
                    if (error)
                        return "tokens." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetApiTokensResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.GetApiTokensResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.GetApiTokensResponse} GetApiTokensResponse
         */
        GetApiTokensResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.GetApiTokensResponse)
                return object;
            let message = new $root.gcg.GetApiTokensResponse();
            if (object.tokens) {
                if (!Array.isArray(object.tokens))
                    throw TypeError(".gcg.GetApiTokensResponse.tokens: array expected");
                message.tokens = [];
                for (let i = 0; i < object.tokens.length; ++i) {
                    if (typeof object.tokens[i] !== "object")
                        throw TypeError(".gcg.GetApiTokensResponse.tokens: object expected");
                    message.tokens[i] = $root.gcg.GetApiTokenResult.fromObject(object.tokens[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetApiTokensResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.GetApiTokensResponse
         * @static
         * @param {gcg.GetApiTokensResponse} message GetApiTokensResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetApiTokensResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.tokens = [];
            if (message.tokens && message.tokens.length) {
                object.tokens = [];
                for (let j = 0; j < message.tokens.length; ++j)
                    object.tokens[j] = $root.gcg.GetApiTokenResult.toObject(message.tokens[j], options);
            }
            return object;
        };

        /**
         * Converts this GetApiTokensResponse to JSON.
         * @function toJSON
         * @memberof gcg.GetApiTokensResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetApiTokensResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetApiTokensResponse
         * @function getTypeUrl
         * @memberof gcg.GetApiTokensResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetApiTokensResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.GetApiTokensResponse";
        };

        return GetApiTokensResponse;
    })();

    gcg.GetApiTokenResult = (function() {

        /**
         * Properties of a GetApiTokenResult.
         * @memberof gcg
         * @interface IGetApiTokenResult
         * @property {string|null} [name] GetApiTokenResult name
         * @property {string|null} [token] GetApiTokenResult token
         */

        /**
         * Constructs a new GetApiTokenResult.
         * @memberof gcg
         * @classdesc Represents a GetApiTokenResult.
         * @implements IGetApiTokenResult
         * @constructor
         * @param {gcg.IGetApiTokenResult=} [properties] Properties to set
         */
        function GetApiTokenResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetApiTokenResult name.
         * @member {string} name
         * @memberof gcg.GetApiTokenResult
         * @instance
         */
        GetApiTokenResult.prototype.name = "";

        /**
         * GetApiTokenResult token.
         * @member {string} token
         * @memberof gcg.GetApiTokenResult
         * @instance
         */
        GetApiTokenResult.prototype.token = "";

        /**
         * Creates a new GetApiTokenResult instance using the specified properties.
         * @function create
         * @memberof gcg.GetApiTokenResult
         * @static
         * @param {gcg.IGetApiTokenResult=} [properties] Properties to set
         * @returns {gcg.GetApiTokenResult} GetApiTokenResult instance
         */
        GetApiTokenResult.create = function create(properties) {
            return new GetApiTokenResult(properties);
        };

        /**
         * Encodes the specified GetApiTokenResult message. Does not implicitly {@link gcg.GetApiTokenResult.verify|verify} messages.
         * @function encode
         * @memberof gcg.GetApiTokenResult
         * @static
         * @param {gcg.IGetApiTokenResult} message GetApiTokenResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetApiTokenResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.token);
            return writer;
        };

        /**
         * Encodes the specified GetApiTokenResult message, length delimited. Does not implicitly {@link gcg.GetApiTokenResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.GetApiTokenResult
         * @static
         * @param {gcg.IGetApiTokenResult} message GetApiTokenResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetApiTokenResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetApiTokenResult message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.GetApiTokenResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.GetApiTokenResult} GetApiTokenResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetApiTokenResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.GetApiTokenResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.token = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetApiTokenResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.GetApiTokenResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.GetApiTokenResult} GetApiTokenResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetApiTokenResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetApiTokenResult message.
         * @function verify
         * @memberof gcg.GetApiTokenResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetApiTokenResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            return null;
        };

        /**
         * Creates a GetApiTokenResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.GetApiTokenResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.GetApiTokenResult} GetApiTokenResult
         */
        GetApiTokenResult.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.GetApiTokenResult)
                return object;
            let message = new $root.gcg.GetApiTokenResult();
            if (object.name != null)
                message.name = String(object.name);
            if (object.token != null)
                message.token = String(object.token);
            return message;
        };

        /**
         * Creates a plain object from a GetApiTokenResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.GetApiTokenResult
         * @static
         * @param {gcg.GetApiTokenResult} message GetApiTokenResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetApiTokenResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.token = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            return object;
        };

        /**
         * Converts this GetApiTokenResult to JSON.
         * @function toJSON
         * @memberof gcg.GetApiTokenResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetApiTokenResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetApiTokenResult
         * @function getTypeUrl
         * @memberof gcg.GetApiTokenResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetApiTokenResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.GetApiTokenResult";
        };

        return GetApiTokenResult;
    })();

    gcg.DeleteApiTokenRequest = (function() {

        /**
         * Properties of a DeleteApiTokenRequest.
         * @memberof gcg
         * @interface IDeleteApiTokenRequest
         * @property {string|null} [token] DeleteApiTokenRequest token
         */

        /**
         * Constructs a new DeleteApiTokenRequest.
         * @memberof gcg
         * @classdesc Represents a DeleteApiTokenRequest.
         * @implements IDeleteApiTokenRequest
         * @constructor
         * @param {gcg.IDeleteApiTokenRequest=} [properties] Properties to set
         */
        function DeleteApiTokenRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteApiTokenRequest token.
         * @member {string} token
         * @memberof gcg.DeleteApiTokenRequest
         * @instance
         */
        DeleteApiTokenRequest.prototype.token = "";

        /**
         * Creates a new DeleteApiTokenRequest instance using the specified properties.
         * @function create
         * @memberof gcg.DeleteApiTokenRequest
         * @static
         * @param {gcg.IDeleteApiTokenRequest=} [properties] Properties to set
         * @returns {gcg.DeleteApiTokenRequest} DeleteApiTokenRequest instance
         */
        DeleteApiTokenRequest.create = function create(properties) {
            return new DeleteApiTokenRequest(properties);
        };

        /**
         * Encodes the specified DeleteApiTokenRequest message. Does not implicitly {@link gcg.DeleteApiTokenRequest.verify|verify} messages.
         * @function encode
         * @memberof gcg.DeleteApiTokenRequest
         * @static
         * @param {gcg.IDeleteApiTokenRequest} message DeleteApiTokenRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteApiTokenRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
            return writer;
        };

        /**
         * Encodes the specified DeleteApiTokenRequest message, length delimited. Does not implicitly {@link gcg.DeleteApiTokenRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.DeleteApiTokenRequest
         * @static
         * @param {gcg.IDeleteApiTokenRequest} message DeleteApiTokenRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteApiTokenRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteApiTokenRequest message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.DeleteApiTokenRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.DeleteApiTokenRequest} DeleteApiTokenRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteApiTokenRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.DeleteApiTokenRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.token = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteApiTokenRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.DeleteApiTokenRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.DeleteApiTokenRequest} DeleteApiTokenRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteApiTokenRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteApiTokenRequest message.
         * @function verify
         * @memberof gcg.DeleteApiTokenRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteApiTokenRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            return null;
        };

        /**
         * Creates a DeleteApiTokenRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.DeleteApiTokenRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.DeleteApiTokenRequest} DeleteApiTokenRequest
         */
        DeleteApiTokenRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.DeleteApiTokenRequest)
                return object;
            let message = new $root.gcg.DeleteApiTokenRequest();
            if (object.token != null)
                message.token = String(object.token);
            return message;
        };

        /**
         * Creates a plain object from a DeleteApiTokenRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.DeleteApiTokenRequest
         * @static
         * @param {gcg.DeleteApiTokenRequest} message DeleteApiTokenRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteApiTokenRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.token = "";
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            return object;
        };

        /**
         * Converts this DeleteApiTokenRequest to JSON.
         * @function toJSON
         * @memberof gcg.DeleteApiTokenRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteApiTokenRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteApiTokenRequest
         * @function getTypeUrl
         * @memberof gcg.DeleteApiTokenRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteApiTokenRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.DeleteApiTokenRequest";
        };

        return DeleteApiTokenRequest;
    })();

    gcg.DeleteApiTokenResponse = (function() {

        /**
         * Properties of a DeleteApiTokenResponse.
         * @memberof gcg
         * @interface IDeleteApiTokenResponse
         */

        /**
         * Constructs a new DeleteApiTokenResponse.
         * @memberof gcg
         * @classdesc Represents a DeleteApiTokenResponse.
         * @implements IDeleteApiTokenResponse
         * @constructor
         * @param {gcg.IDeleteApiTokenResponse=} [properties] Properties to set
         */
        function DeleteApiTokenResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteApiTokenResponse instance using the specified properties.
         * @function create
         * @memberof gcg.DeleteApiTokenResponse
         * @static
         * @param {gcg.IDeleteApiTokenResponse=} [properties] Properties to set
         * @returns {gcg.DeleteApiTokenResponse} DeleteApiTokenResponse instance
         */
        DeleteApiTokenResponse.create = function create(properties) {
            return new DeleteApiTokenResponse(properties);
        };

        /**
         * Encodes the specified DeleteApiTokenResponse message. Does not implicitly {@link gcg.DeleteApiTokenResponse.verify|verify} messages.
         * @function encode
         * @memberof gcg.DeleteApiTokenResponse
         * @static
         * @param {gcg.IDeleteApiTokenResponse} message DeleteApiTokenResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteApiTokenResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteApiTokenResponse message, length delimited. Does not implicitly {@link gcg.DeleteApiTokenResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gcg.DeleteApiTokenResponse
         * @static
         * @param {gcg.IDeleteApiTokenResponse} message DeleteApiTokenResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteApiTokenResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteApiTokenResponse message from the specified reader or buffer.
         * @function decode
         * @memberof gcg.DeleteApiTokenResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gcg.DeleteApiTokenResponse} DeleteApiTokenResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteApiTokenResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gcg.DeleteApiTokenResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteApiTokenResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gcg.DeleteApiTokenResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gcg.DeleteApiTokenResponse} DeleteApiTokenResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteApiTokenResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteApiTokenResponse message.
         * @function verify
         * @memberof gcg.DeleteApiTokenResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteApiTokenResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteApiTokenResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gcg.DeleteApiTokenResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gcg.DeleteApiTokenResponse} DeleteApiTokenResponse
         */
        DeleteApiTokenResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.gcg.DeleteApiTokenResponse)
                return object;
            return new $root.gcg.DeleteApiTokenResponse();
        };

        /**
         * Creates a plain object from a DeleteApiTokenResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gcg.DeleteApiTokenResponse
         * @static
         * @param {gcg.DeleteApiTokenResponse} message DeleteApiTokenResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteApiTokenResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteApiTokenResponse to JSON.
         * @function toJSON
         * @memberof gcg.DeleteApiTokenResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteApiTokenResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteApiTokenResponse
         * @function getTypeUrl
         * @memberof gcg.DeleteApiTokenResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteApiTokenResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gcg.DeleteApiTokenResponse";
        };

        return DeleteApiTokenResponse;
    })();

    return gcg;
})();

export const identity_endorsement = $root.identity_endorsement = (() => {

    /**
     * Namespace identity_endorsement.
     * @exports identity_endorsement
     * @namespace
     */
    const identity_endorsement = {};

    identity_endorsement.EndorsementRequest = (function() {

        /**
         * Properties of an EndorsementRequest.
         * @memberof identity_endorsement
         * @interface IEndorsementRequest
         * @property {identity_endorsement.IPkiEndorsementRequest|null} [pkiEndorsementRequest] EndorsementRequest pkiEndorsementRequest
         * @property {identity_endorsement.IDcrSecretEndorsementRequest|null} [dcrSecretEndorsementRequest] EndorsementRequest dcrSecretEndorsementRequest
         */

        /**
         * Constructs a new EndorsementRequest.
         * @memberof identity_endorsement
         * @classdesc Represents an EndorsementRequest.
         * @implements IEndorsementRequest
         * @constructor
         * @param {identity_endorsement.IEndorsementRequest=} [properties] Properties to set
         */
        function EndorsementRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EndorsementRequest pkiEndorsementRequest.
         * @member {identity_endorsement.IPkiEndorsementRequest|null|undefined} pkiEndorsementRequest
         * @memberof identity_endorsement.EndorsementRequest
         * @instance
         */
        EndorsementRequest.prototype.pkiEndorsementRequest = null;

        /**
         * EndorsementRequest dcrSecretEndorsementRequest.
         * @member {identity_endorsement.IDcrSecretEndorsementRequest|null|undefined} dcrSecretEndorsementRequest
         * @memberof identity_endorsement.EndorsementRequest
         * @instance
         */
        EndorsementRequest.prototype.dcrSecretEndorsementRequest = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * EndorsementRequest endorsementRequest.
         * @member {"pkiEndorsementRequest"|"dcrSecretEndorsementRequest"|undefined} endorsementRequest
         * @memberof identity_endorsement.EndorsementRequest
         * @instance
         */
        Object.defineProperty(EndorsementRequest.prototype, "endorsementRequest", {
            get: $util.oneOfGetter($oneOfFields = ["pkiEndorsementRequest", "dcrSecretEndorsementRequest"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new EndorsementRequest instance using the specified properties.
         * @function create
         * @memberof identity_endorsement.EndorsementRequest
         * @static
         * @param {identity_endorsement.IEndorsementRequest=} [properties] Properties to set
         * @returns {identity_endorsement.EndorsementRequest} EndorsementRequest instance
         */
        EndorsementRequest.create = function create(properties) {
            return new EndorsementRequest(properties);
        };

        /**
         * Encodes the specified EndorsementRequest message. Does not implicitly {@link identity_endorsement.EndorsementRequest.verify|verify} messages.
         * @function encode
         * @memberof identity_endorsement.EndorsementRequest
         * @static
         * @param {identity_endorsement.IEndorsementRequest} message EndorsementRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EndorsementRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pkiEndorsementRequest != null && Object.hasOwnProperty.call(message, "pkiEndorsementRequest"))
                $root.identity_endorsement.PkiEndorsementRequest.encode(message.pkiEndorsementRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.dcrSecretEndorsementRequest != null && Object.hasOwnProperty.call(message, "dcrSecretEndorsementRequest"))
                $root.identity_endorsement.DcrSecretEndorsementRequest.encode(message.dcrSecretEndorsementRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified EndorsementRequest message, length delimited. Does not implicitly {@link identity_endorsement.EndorsementRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof identity_endorsement.EndorsementRequest
         * @static
         * @param {identity_endorsement.IEndorsementRequest} message EndorsementRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EndorsementRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EndorsementRequest message from the specified reader or buffer.
         * @function decode
         * @memberof identity_endorsement.EndorsementRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {identity_endorsement.EndorsementRequest} EndorsementRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EndorsementRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.identity_endorsement.EndorsementRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.pkiEndorsementRequest = $root.identity_endorsement.PkiEndorsementRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.dcrSecretEndorsementRequest = $root.identity_endorsement.DcrSecretEndorsementRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EndorsementRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof identity_endorsement.EndorsementRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {identity_endorsement.EndorsementRequest} EndorsementRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EndorsementRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EndorsementRequest message.
         * @function verify
         * @memberof identity_endorsement.EndorsementRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EndorsementRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.pkiEndorsementRequest != null && message.hasOwnProperty("pkiEndorsementRequest")) {
                properties.endorsementRequest = 1;
                {
                    let error = $root.identity_endorsement.PkiEndorsementRequest.verify(message.pkiEndorsementRequest);
                    if (error)
                        return "pkiEndorsementRequest." + error;
                }
            }
            if (message.dcrSecretEndorsementRequest != null && message.hasOwnProperty("dcrSecretEndorsementRequest")) {
                if (properties.endorsementRequest === 1)
                    return "endorsementRequest: multiple values";
                properties.endorsementRequest = 1;
                {
                    let error = $root.identity_endorsement.DcrSecretEndorsementRequest.verify(message.dcrSecretEndorsementRequest);
                    if (error)
                        return "dcrSecretEndorsementRequest." + error;
                }
            }
            return null;
        };

        /**
         * Creates an EndorsementRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof identity_endorsement.EndorsementRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {identity_endorsement.EndorsementRequest} EndorsementRequest
         */
        EndorsementRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.identity_endorsement.EndorsementRequest)
                return object;
            let message = new $root.identity_endorsement.EndorsementRequest();
            if (object.pkiEndorsementRequest != null) {
                if (typeof object.pkiEndorsementRequest !== "object")
                    throw TypeError(".identity_endorsement.EndorsementRequest.pkiEndorsementRequest: object expected");
                message.pkiEndorsementRequest = $root.identity_endorsement.PkiEndorsementRequest.fromObject(object.pkiEndorsementRequest);
            }
            if (object.dcrSecretEndorsementRequest != null) {
                if (typeof object.dcrSecretEndorsementRequest !== "object")
                    throw TypeError(".identity_endorsement.EndorsementRequest.dcrSecretEndorsementRequest: object expected");
                message.dcrSecretEndorsementRequest = $root.identity_endorsement.DcrSecretEndorsementRequest.fromObject(object.dcrSecretEndorsementRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from an EndorsementRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof identity_endorsement.EndorsementRequest
         * @static
         * @param {identity_endorsement.EndorsementRequest} message EndorsementRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EndorsementRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.pkiEndorsementRequest != null && message.hasOwnProperty("pkiEndorsementRequest")) {
                object.pkiEndorsementRequest = $root.identity_endorsement.PkiEndorsementRequest.toObject(message.pkiEndorsementRequest, options);
                if (options.oneofs)
                    object.endorsementRequest = "pkiEndorsementRequest";
            }
            if (message.dcrSecretEndorsementRequest != null && message.hasOwnProperty("dcrSecretEndorsementRequest")) {
                object.dcrSecretEndorsementRequest = $root.identity_endorsement.DcrSecretEndorsementRequest.toObject(message.dcrSecretEndorsementRequest, options);
                if (options.oneofs)
                    object.endorsementRequest = "dcrSecretEndorsementRequest";
            }
            return object;
        };

        /**
         * Converts this EndorsementRequest to JSON.
         * @function toJSON
         * @memberof identity_endorsement.EndorsementRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EndorsementRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EndorsementRequest
         * @function getTypeUrl
         * @memberof identity_endorsement.EndorsementRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EndorsementRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/identity_endorsement.EndorsementRequest";
        };

        return EndorsementRequest;
    })();

    identity_endorsement.EndorsementResponse = (function() {

        /**
         * Properties of an EndorsementResponse.
         * @memberof identity_endorsement
         * @interface IEndorsementResponse
         * @property {identity_endorsement.IPkiEndorsementResponse|null} [pkiEndorsementResponse] EndorsementResponse pkiEndorsementResponse
         * @property {identity_endorsement.IDcrSecretEndorsementResponse|null} [dcrSecretEndorsementResponse] EndorsementResponse dcrSecretEndorsementResponse
         */

        /**
         * Constructs a new EndorsementResponse.
         * @memberof identity_endorsement
         * @classdesc Represents an EndorsementResponse.
         * @implements IEndorsementResponse
         * @constructor
         * @param {identity_endorsement.IEndorsementResponse=} [properties] Properties to set
         */
        function EndorsementResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EndorsementResponse pkiEndorsementResponse.
         * @member {identity_endorsement.IPkiEndorsementResponse|null|undefined} pkiEndorsementResponse
         * @memberof identity_endorsement.EndorsementResponse
         * @instance
         */
        EndorsementResponse.prototype.pkiEndorsementResponse = null;

        /**
         * EndorsementResponse dcrSecretEndorsementResponse.
         * @member {identity_endorsement.IDcrSecretEndorsementResponse|null|undefined} dcrSecretEndorsementResponse
         * @memberof identity_endorsement.EndorsementResponse
         * @instance
         */
        EndorsementResponse.prototype.dcrSecretEndorsementResponse = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * EndorsementResponse endorsementResponse.
         * @member {"pkiEndorsementResponse"|"dcrSecretEndorsementResponse"|undefined} endorsementResponse
         * @memberof identity_endorsement.EndorsementResponse
         * @instance
         */
        Object.defineProperty(EndorsementResponse.prototype, "endorsementResponse", {
            get: $util.oneOfGetter($oneOfFields = ["pkiEndorsementResponse", "dcrSecretEndorsementResponse"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new EndorsementResponse instance using the specified properties.
         * @function create
         * @memberof identity_endorsement.EndorsementResponse
         * @static
         * @param {identity_endorsement.IEndorsementResponse=} [properties] Properties to set
         * @returns {identity_endorsement.EndorsementResponse} EndorsementResponse instance
         */
        EndorsementResponse.create = function create(properties) {
            return new EndorsementResponse(properties);
        };

        /**
         * Encodes the specified EndorsementResponse message. Does not implicitly {@link identity_endorsement.EndorsementResponse.verify|verify} messages.
         * @function encode
         * @memberof identity_endorsement.EndorsementResponse
         * @static
         * @param {identity_endorsement.IEndorsementResponse} message EndorsementResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EndorsementResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pkiEndorsementResponse != null && Object.hasOwnProperty.call(message, "pkiEndorsementResponse"))
                $root.identity_endorsement.PkiEndorsementResponse.encode(message.pkiEndorsementResponse, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.dcrSecretEndorsementResponse != null && Object.hasOwnProperty.call(message, "dcrSecretEndorsementResponse"))
                $root.identity_endorsement.DcrSecretEndorsementResponse.encode(message.dcrSecretEndorsementResponse, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified EndorsementResponse message, length delimited. Does not implicitly {@link identity_endorsement.EndorsementResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof identity_endorsement.EndorsementResponse
         * @static
         * @param {identity_endorsement.IEndorsementResponse} message EndorsementResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EndorsementResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EndorsementResponse message from the specified reader or buffer.
         * @function decode
         * @memberof identity_endorsement.EndorsementResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {identity_endorsement.EndorsementResponse} EndorsementResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EndorsementResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.identity_endorsement.EndorsementResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.pkiEndorsementResponse = $root.identity_endorsement.PkiEndorsementResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.dcrSecretEndorsementResponse = $root.identity_endorsement.DcrSecretEndorsementResponse.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EndorsementResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof identity_endorsement.EndorsementResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {identity_endorsement.EndorsementResponse} EndorsementResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EndorsementResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EndorsementResponse message.
         * @function verify
         * @memberof identity_endorsement.EndorsementResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EndorsementResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.pkiEndorsementResponse != null && message.hasOwnProperty("pkiEndorsementResponse")) {
                properties.endorsementResponse = 1;
                {
                    let error = $root.identity_endorsement.PkiEndorsementResponse.verify(message.pkiEndorsementResponse);
                    if (error)
                        return "pkiEndorsementResponse." + error;
                }
            }
            if (message.dcrSecretEndorsementResponse != null && message.hasOwnProperty("dcrSecretEndorsementResponse")) {
                if (properties.endorsementResponse === 1)
                    return "endorsementResponse: multiple values";
                properties.endorsementResponse = 1;
                {
                    let error = $root.identity_endorsement.DcrSecretEndorsementResponse.verify(message.dcrSecretEndorsementResponse);
                    if (error)
                        return "dcrSecretEndorsementResponse." + error;
                }
            }
            return null;
        };

        /**
         * Creates an EndorsementResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof identity_endorsement.EndorsementResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {identity_endorsement.EndorsementResponse} EndorsementResponse
         */
        EndorsementResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.identity_endorsement.EndorsementResponse)
                return object;
            let message = new $root.identity_endorsement.EndorsementResponse();
            if (object.pkiEndorsementResponse != null) {
                if (typeof object.pkiEndorsementResponse !== "object")
                    throw TypeError(".identity_endorsement.EndorsementResponse.pkiEndorsementResponse: object expected");
                message.pkiEndorsementResponse = $root.identity_endorsement.PkiEndorsementResponse.fromObject(object.pkiEndorsementResponse);
            }
            if (object.dcrSecretEndorsementResponse != null) {
                if (typeof object.dcrSecretEndorsementResponse !== "object")
                    throw TypeError(".identity_endorsement.EndorsementResponse.dcrSecretEndorsementResponse: object expected");
                message.dcrSecretEndorsementResponse = $root.identity_endorsement.DcrSecretEndorsementResponse.fromObject(object.dcrSecretEndorsementResponse);
            }
            return message;
        };

        /**
         * Creates a plain object from an EndorsementResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof identity_endorsement.EndorsementResponse
         * @static
         * @param {identity_endorsement.EndorsementResponse} message EndorsementResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EndorsementResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.pkiEndorsementResponse != null && message.hasOwnProperty("pkiEndorsementResponse")) {
                object.pkiEndorsementResponse = $root.identity_endorsement.PkiEndorsementResponse.toObject(message.pkiEndorsementResponse, options);
                if (options.oneofs)
                    object.endorsementResponse = "pkiEndorsementResponse";
            }
            if (message.dcrSecretEndorsementResponse != null && message.hasOwnProperty("dcrSecretEndorsementResponse")) {
                object.dcrSecretEndorsementResponse = $root.identity_endorsement.DcrSecretEndorsementResponse.toObject(message.dcrSecretEndorsementResponse, options);
                if (options.oneofs)
                    object.endorsementResponse = "dcrSecretEndorsementResponse";
            }
            return object;
        };

        /**
         * Converts this EndorsementResponse to JSON.
         * @function toJSON
         * @memberof identity_endorsement.EndorsementResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EndorsementResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EndorsementResponse
         * @function getTypeUrl
         * @memberof identity_endorsement.EndorsementResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EndorsementResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/identity_endorsement.EndorsementResponse";
        };

        return EndorsementResponse;
    })();

    identity_endorsement.PkiEndorsementRequest = (function() {

        /**
         * Properties of a PkiEndorsementRequest.
         * @memberof identity_endorsement
         * @interface IPkiEndorsementRequest
         * @property {Uint8Array|null} [certificateChainPem] PkiEndorsementRequest certificateChainPem
         */

        /**
         * Constructs a new PkiEndorsementRequest.
         * @memberof identity_endorsement
         * @classdesc Represents a PkiEndorsementRequest.
         * @implements IPkiEndorsementRequest
         * @constructor
         * @param {identity_endorsement.IPkiEndorsementRequest=} [properties] Properties to set
         */
        function PkiEndorsementRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PkiEndorsementRequest certificateChainPem.
         * @member {Uint8Array} certificateChainPem
         * @memberof identity_endorsement.PkiEndorsementRequest
         * @instance
         */
        PkiEndorsementRequest.prototype.certificateChainPem = $util.newBuffer([]);

        /**
         * Creates a new PkiEndorsementRequest instance using the specified properties.
         * @function create
         * @memberof identity_endorsement.PkiEndorsementRequest
         * @static
         * @param {identity_endorsement.IPkiEndorsementRequest=} [properties] Properties to set
         * @returns {identity_endorsement.PkiEndorsementRequest} PkiEndorsementRequest instance
         */
        PkiEndorsementRequest.create = function create(properties) {
            return new PkiEndorsementRequest(properties);
        };

        /**
         * Encodes the specified PkiEndorsementRequest message. Does not implicitly {@link identity_endorsement.PkiEndorsementRequest.verify|verify} messages.
         * @function encode
         * @memberof identity_endorsement.PkiEndorsementRequest
         * @static
         * @param {identity_endorsement.IPkiEndorsementRequest} message PkiEndorsementRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PkiEndorsementRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.certificateChainPem != null && Object.hasOwnProperty.call(message, "certificateChainPem"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.certificateChainPem);
            return writer;
        };

        /**
         * Encodes the specified PkiEndorsementRequest message, length delimited. Does not implicitly {@link identity_endorsement.PkiEndorsementRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof identity_endorsement.PkiEndorsementRequest
         * @static
         * @param {identity_endorsement.IPkiEndorsementRequest} message PkiEndorsementRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PkiEndorsementRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PkiEndorsementRequest message from the specified reader or buffer.
         * @function decode
         * @memberof identity_endorsement.PkiEndorsementRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {identity_endorsement.PkiEndorsementRequest} PkiEndorsementRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PkiEndorsementRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.identity_endorsement.PkiEndorsementRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.certificateChainPem = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PkiEndorsementRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof identity_endorsement.PkiEndorsementRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {identity_endorsement.PkiEndorsementRequest} PkiEndorsementRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PkiEndorsementRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PkiEndorsementRequest message.
         * @function verify
         * @memberof identity_endorsement.PkiEndorsementRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PkiEndorsementRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.certificateChainPem != null && message.hasOwnProperty("certificateChainPem"))
                if (!(message.certificateChainPem && typeof message.certificateChainPem.length === "number" || $util.isString(message.certificateChainPem)))
                    return "certificateChainPem: buffer expected";
            return null;
        };

        /**
         * Creates a PkiEndorsementRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof identity_endorsement.PkiEndorsementRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {identity_endorsement.PkiEndorsementRequest} PkiEndorsementRequest
         */
        PkiEndorsementRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.identity_endorsement.PkiEndorsementRequest)
                return object;
            let message = new $root.identity_endorsement.PkiEndorsementRequest();
            if (object.certificateChainPem != null)
                if (typeof object.certificateChainPem === "string")
                    $util.base64.decode(object.certificateChainPem, message.certificateChainPem = $util.newBuffer($util.base64.length(object.certificateChainPem)), 0);
                else if (object.certificateChainPem.length >= 0)
                    message.certificateChainPem = object.certificateChainPem;
            return message;
        };

        /**
         * Creates a plain object from a PkiEndorsementRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof identity_endorsement.PkiEndorsementRequest
         * @static
         * @param {identity_endorsement.PkiEndorsementRequest} message PkiEndorsementRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PkiEndorsementRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.certificateChainPem = "";
                else {
                    object.certificateChainPem = [];
                    if (options.bytes !== Array)
                        object.certificateChainPem = $util.newBuffer(object.certificateChainPem);
                }
            if (message.certificateChainPem != null && message.hasOwnProperty("certificateChainPem"))
                object.certificateChainPem = options.bytes === String ? $util.base64.encode(message.certificateChainPem, 0, message.certificateChainPem.length) : options.bytes === Array ? Array.prototype.slice.call(message.certificateChainPem) : message.certificateChainPem;
            return object;
        };

        /**
         * Converts this PkiEndorsementRequest to JSON.
         * @function toJSON
         * @memberof identity_endorsement.PkiEndorsementRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PkiEndorsementRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PkiEndorsementRequest
         * @function getTypeUrl
         * @memberof identity_endorsement.PkiEndorsementRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PkiEndorsementRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/identity_endorsement.PkiEndorsementRequest";
        };

        return PkiEndorsementRequest;
    })();

    identity_endorsement.PkiEndorsementResponse = (function() {

        /**
         * Properties of a PkiEndorsementResponse.
         * @memberof identity_endorsement
         * @interface IPkiEndorsementResponse
         * @property {identity_endorsement.IEnclaveEndorsement|null} [pkiEndorsement] PkiEndorsementResponse pkiEndorsement
         */

        /**
         * Constructs a new PkiEndorsementResponse.
         * @memberof identity_endorsement
         * @classdesc Represents a PkiEndorsementResponse.
         * @implements IPkiEndorsementResponse
         * @constructor
         * @param {identity_endorsement.IPkiEndorsementResponse=} [properties] Properties to set
         */
        function PkiEndorsementResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PkiEndorsementResponse pkiEndorsement.
         * @member {identity_endorsement.IEnclaveEndorsement|null|undefined} pkiEndorsement
         * @memberof identity_endorsement.PkiEndorsementResponse
         * @instance
         */
        PkiEndorsementResponse.prototype.pkiEndorsement = null;

        /**
         * Creates a new PkiEndorsementResponse instance using the specified properties.
         * @function create
         * @memberof identity_endorsement.PkiEndorsementResponse
         * @static
         * @param {identity_endorsement.IPkiEndorsementResponse=} [properties] Properties to set
         * @returns {identity_endorsement.PkiEndorsementResponse} PkiEndorsementResponse instance
         */
        PkiEndorsementResponse.create = function create(properties) {
            return new PkiEndorsementResponse(properties);
        };

        /**
         * Encodes the specified PkiEndorsementResponse message. Does not implicitly {@link identity_endorsement.PkiEndorsementResponse.verify|verify} messages.
         * @function encode
         * @memberof identity_endorsement.PkiEndorsementResponse
         * @static
         * @param {identity_endorsement.IPkiEndorsementResponse} message PkiEndorsementResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PkiEndorsementResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pkiEndorsement != null && Object.hasOwnProperty.call(message, "pkiEndorsement"))
                $root.identity_endorsement.EnclaveEndorsement.encode(message.pkiEndorsement, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PkiEndorsementResponse message, length delimited. Does not implicitly {@link identity_endorsement.PkiEndorsementResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof identity_endorsement.PkiEndorsementResponse
         * @static
         * @param {identity_endorsement.IPkiEndorsementResponse} message PkiEndorsementResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PkiEndorsementResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PkiEndorsementResponse message from the specified reader or buffer.
         * @function decode
         * @memberof identity_endorsement.PkiEndorsementResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {identity_endorsement.PkiEndorsementResponse} PkiEndorsementResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PkiEndorsementResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.identity_endorsement.PkiEndorsementResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pkiEndorsement = $root.identity_endorsement.EnclaveEndorsement.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PkiEndorsementResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof identity_endorsement.PkiEndorsementResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {identity_endorsement.PkiEndorsementResponse} PkiEndorsementResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PkiEndorsementResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PkiEndorsementResponse message.
         * @function verify
         * @memberof identity_endorsement.PkiEndorsementResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PkiEndorsementResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pkiEndorsement != null && message.hasOwnProperty("pkiEndorsement")) {
                let error = $root.identity_endorsement.EnclaveEndorsement.verify(message.pkiEndorsement);
                if (error)
                    return "pkiEndorsement." + error;
            }
            return null;
        };

        /**
         * Creates a PkiEndorsementResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof identity_endorsement.PkiEndorsementResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {identity_endorsement.PkiEndorsementResponse} PkiEndorsementResponse
         */
        PkiEndorsementResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.identity_endorsement.PkiEndorsementResponse)
                return object;
            let message = new $root.identity_endorsement.PkiEndorsementResponse();
            if (object.pkiEndorsement != null) {
                if (typeof object.pkiEndorsement !== "object")
                    throw TypeError(".identity_endorsement.PkiEndorsementResponse.pkiEndorsement: object expected");
                message.pkiEndorsement = $root.identity_endorsement.EnclaveEndorsement.fromObject(object.pkiEndorsement);
            }
            return message;
        };

        /**
         * Creates a plain object from a PkiEndorsementResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof identity_endorsement.PkiEndorsementResponse
         * @static
         * @param {identity_endorsement.PkiEndorsementResponse} message PkiEndorsementResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PkiEndorsementResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.pkiEndorsement = null;
            if (message.pkiEndorsement != null && message.hasOwnProperty("pkiEndorsement"))
                object.pkiEndorsement = $root.identity_endorsement.EnclaveEndorsement.toObject(message.pkiEndorsement, options);
            return object;
        };

        /**
         * Converts this PkiEndorsementResponse to JSON.
         * @function toJSON
         * @memberof identity_endorsement.PkiEndorsementResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PkiEndorsementResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PkiEndorsementResponse
         * @function getTypeUrl
         * @memberof identity_endorsement.PkiEndorsementResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PkiEndorsementResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/identity_endorsement.PkiEndorsementResponse";
        };

        return PkiEndorsementResponse;
    })();

    identity_endorsement.DcrSecretEndorsementRequest = (function() {

        /**
         * Properties of a DcrSecretEndorsementRequest.
         * @memberof identity_endorsement
         * @interface IDcrSecretEndorsementRequest
         * @property {string|null} [dcrSecret] DcrSecretEndorsementRequest dcrSecret
         */

        /**
         * Constructs a new DcrSecretEndorsementRequest.
         * @memberof identity_endorsement
         * @classdesc Represents a DcrSecretEndorsementRequest.
         * @implements IDcrSecretEndorsementRequest
         * @constructor
         * @param {identity_endorsement.IDcrSecretEndorsementRequest=} [properties] Properties to set
         */
        function DcrSecretEndorsementRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DcrSecretEndorsementRequest dcrSecret.
         * @member {string} dcrSecret
         * @memberof identity_endorsement.DcrSecretEndorsementRequest
         * @instance
         */
        DcrSecretEndorsementRequest.prototype.dcrSecret = "";

        /**
         * Creates a new DcrSecretEndorsementRequest instance using the specified properties.
         * @function create
         * @memberof identity_endorsement.DcrSecretEndorsementRequest
         * @static
         * @param {identity_endorsement.IDcrSecretEndorsementRequest=} [properties] Properties to set
         * @returns {identity_endorsement.DcrSecretEndorsementRequest} DcrSecretEndorsementRequest instance
         */
        DcrSecretEndorsementRequest.create = function create(properties) {
            return new DcrSecretEndorsementRequest(properties);
        };

        /**
         * Encodes the specified DcrSecretEndorsementRequest message. Does not implicitly {@link identity_endorsement.DcrSecretEndorsementRequest.verify|verify} messages.
         * @function encode
         * @memberof identity_endorsement.DcrSecretEndorsementRequest
         * @static
         * @param {identity_endorsement.IDcrSecretEndorsementRequest} message DcrSecretEndorsementRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrSecretEndorsementRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dcrSecret != null && Object.hasOwnProperty.call(message, "dcrSecret"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dcrSecret);
            return writer;
        };

        /**
         * Encodes the specified DcrSecretEndorsementRequest message, length delimited. Does not implicitly {@link identity_endorsement.DcrSecretEndorsementRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof identity_endorsement.DcrSecretEndorsementRequest
         * @static
         * @param {identity_endorsement.IDcrSecretEndorsementRequest} message DcrSecretEndorsementRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrSecretEndorsementRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DcrSecretEndorsementRequest message from the specified reader or buffer.
         * @function decode
         * @memberof identity_endorsement.DcrSecretEndorsementRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {identity_endorsement.DcrSecretEndorsementRequest} DcrSecretEndorsementRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrSecretEndorsementRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.identity_endorsement.DcrSecretEndorsementRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dcrSecret = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DcrSecretEndorsementRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof identity_endorsement.DcrSecretEndorsementRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {identity_endorsement.DcrSecretEndorsementRequest} DcrSecretEndorsementRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrSecretEndorsementRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DcrSecretEndorsementRequest message.
         * @function verify
         * @memberof identity_endorsement.DcrSecretEndorsementRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DcrSecretEndorsementRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dcrSecret != null && message.hasOwnProperty("dcrSecret"))
                if (!$util.isString(message.dcrSecret))
                    return "dcrSecret: string expected";
            return null;
        };

        /**
         * Creates a DcrSecretEndorsementRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof identity_endorsement.DcrSecretEndorsementRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {identity_endorsement.DcrSecretEndorsementRequest} DcrSecretEndorsementRequest
         */
        DcrSecretEndorsementRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.identity_endorsement.DcrSecretEndorsementRequest)
                return object;
            let message = new $root.identity_endorsement.DcrSecretEndorsementRequest();
            if (object.dcrSecret != null)
                message.dcrSecret = String(object.dcrSecret);
            return message;
        };

        /**
         * Creates a plain object from a DcrSecretEndorsementRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof identity_endorsement.DcrSecretEndorsementRequest
         * @static
         * @param {identity_endorsement.DcrSecretEndorsementRequest} message DcrSecretEndorsementRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DcrSecretEndorsementRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.dcrSecret = "";
            if (message.dcrSecret != null && message.hasOwnProperty("dcrSecret"))
                object.dcrSecret = message.dcrSecret;
            return object;
        };

        /**
         * Converts this DcrSecretEndorsementRequest to JSON.
         * @function toJSON
         * @memberof identity_endorsement.DcrSecretEndorsementRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DcrSecretEndorsementRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DcrSecretEndorsementRequest
         * @function getTypeUrl
         * @memberof identity_endorsement.DcrSecretEndorsementRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DcrSecretEndorsementRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/identity_endorsement.DcrSecretEndorsementRequest";
        };

        return DcrSecretEndorsementRequest;
    })();

    identity_endorsement.DcrSecretEndorsementResponse = (function() {

        /**
         * Properties of a DcrSecretEndorsementResponse.
         * @memberof identity_endorsement
         * @interface IDcrSecretEndorsementResponse
         * @property {identity_endorsement.IEnclaveEndorsement|null} [dcrSecretEndorsement] DcrSecretEndorsementResponse dcrSecretEndorsement
         * @property {Uint8Array|null} [dcrSecretId] DcrSecretEndorsementResponse dcrSecretId
         */

        /**
         * Constructs a new DcrSecretEndorsementResponse.
         * @memberof identity_endorsement
         * @classdesc Represents a DcrSecretEndorsementResponse.
         * @implements IDcrSecretEndorsementResponse
         * @constructor
         * @param {identity_endorsement.IDcrSecretEndorsementResponse=} [properties] Properties to set
         */
        function DcrSecretEndorsementResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DcrSecretEndorsementResponse dcrSecretEndorsement.
         * @member {identity_endorsement.IEnclaveEndorsement|null|undefined} dcrSecretEndorsement
         * @memberof identity_endorsement.DcrSecretEndorsementResponse
         * @instance
         */
        DcrSecretEndorsementResponse.prototype.dcrSecretEndorsement = null;

        /**
         * DcrSecretEndorsementResponse dcrSecretId.
         * @member {Uint8Array} dcrSecretId
         * @memberof identity_endorsement.DcrSecretEndorsementResponse
         * @instance
         */
        DcrSecretEndorsementResponse.prototype.dcrSecretId = $util.newBuffer([]);

        /**
         * Creates a new DcrSecretEndorsementResponse instance using the specified properties.
         * @function create
         * @memberof identity_endorsement.DcrSecretEndorsementResponse
         * @static
         * @param {identity_endorsement.IDcrSecretEndorsementResponse=} [properties] Properties to set
         * @returns {identity_endorsement.DcrSecretEndorsementResponse} DcrSecretEndorsementResponse instance
         */
        DcrSecretEndorsementResponse.create = function create(properties) {
            return new DcrSecretEndorsementResponse(properties);
        };

        /**
         * Encodes the specified DcrSecretEndorsementResponse message. Does not implicitly {@link identity_endorsement.DcrSecretEndorsementResponse.verify|verify} messages.
         * @function encode
         * @memberof identity_endorsement.DcrSecretEndorsementResponse
         * @static
         * @param {identity_endorsement.IDcrSecretEndorsementResponse} message DcrSecretEndorsementResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrSecretEndorsementResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dcrSecretEndorsement != null && Object.hasOwnProperty.call(message, "dcrSecretEndorsement"))
                $root.identity_endorsement.EnclaveEndorsement.encode(message.dcrSecretEndorsement, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.dcrSecretId != null && Object.hasOwnProperty.call(message, "dcrSecretId"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.dcrSecretId);
            return writer;
        };

        /**
         * Encodes the specified DcrSecretEndorsementResponse message, length delimited. Does not implicitly {@link identity_endorsement.DcrSecretEndorsementResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof identity_endorsement.DcrSecretEndorsementResponse
         * @static
         * @param {identity_endorsement.IDcrSecretEndorsementResponse} message DcrSecretEndorsementResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrSecretEndorsementResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DcrSecretEndorsementResponse message from the specified reader or buffer.
         * @function decode
         * @memberof identity_endorsement.DcrSecretEndorsementResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {identity_endorsement.DcrSecretEndorsementResponse} DcrSecretEndorsementResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrSecretEndorsementResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.identity_endorsement.DcrSecretEndorsementResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dcrSecretEndorsement = $root.identity_endorsement.EnclaveEndorsement.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.dcrSecretId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DcrSecretEndorsementResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof identity_endorsement.DcrSecretEndorsementResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {identity_endorsement.DcrSecretEndorsementResponse} DcrSecretEndorsementResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrSecretEndorsementResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DcrSecretEndorsementResponse message.
         * @function verify
         * @memberof identity_endorsement.DcrSecretEndorsementResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DcrSecretEndorsementResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dcrSecretEndorsement != null && message.hasOwnProperty("dcrSecretEndorsement")) {
                let error = $root.identity_endorsement.EnclaveEndorsement.verify(message.dcrSecretEndorsement);
                if (error)
                    return "dcrSecretEndorsement." + error;
            }
            if (message.dcrSecretId != null && message.hasOwnProperty("dcrSecretId"))
                if (!(message.dcrSecretId && typeof message.dcrSecretId.length === "number" || $util.isString(message.dcrSecretId)))
                    return "dcrSecretId: buffer expected";
            return null;
        };

        /**
         * Creates a DcrSecretEndorsementResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof identity_endorsement.DcrSecretEndorsementResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {identity_endorsement.DcrSecretEndorsementResponse} DcrSecretEndorsementResponse
         */
        DcrSecretEndorsementResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.identity_endorsement.DcrSecretEndorsementResponse)
                return object;
            let message = new $root.identity_endorsement.DcrSecretEndorsementResponse();
            if (object.dcrSecretEndorsement != null) {
                if (typeof object.dcrSecretEndorsement !== "object")
                    throw TypeError(".identity_endorsement.DcrSecretEndorsementResponse.dcrSecretEndorsement: object expected");
                message.dcrSecretEndorsement = $root.identity_endorsement.EnclaveEndorsement.fromObject(object.dcrSecretEndorsement);
            }
            if (object.dcrSecretId != null)
                if (typeof object.dcrSecretId === "string")
                    $util.base64.decode(object.dcrSecretId, message.dcrSecretId = $util.newBuffer($util.base64.length(object.dcrSecretId)), 0);
                else if (object.dcrSecretId.length >= 0)
                    message.dcrSecretId = object.dcrSecretId;
            return message;
        };

        /**
         * Creates a plain object from a DcrSecretEndorsementResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof identity_endorsement.DcrSecretEndorsementResponse
         * @static
         * @param {identity_endorsement.DcrSecretEndorsementResponse} message DcrSecretEndorsementResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DcrSecretEndorsementResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.dcrSecretEndorsement = null;
                if (options.bytes === String)
                    object.dcrSecretId = "";
                else {
                    object.dcrSecretId = [];
                    if (options.bytes !== Array)
                        object.dcrSecretId = $util.newBuffer(object.dcrSecretId);
                }
            }
            if (message.dcrSecretEndorsement != null && message.hasOwnProperty("dcrSecretEndorsement"))
                object.dcrSecretEndorsement = $root.identity_endorsement.EnclaveEndorsement.toObject(message.dcrSecretEndorsement, options);
            if (message.dcrSecretId != null && message.hasOwnProperty("dcrSecretId"))
                object.dcrSecretId = options.bytes === String ? $util.base64.encode(message.dcrSecretId, 0, message.dcrSecretId.length) : options.bytes === Array ? Array.prototype.slice.call(message.dcrSecretId) : message.dcrSecretId;
            return object;
        };

        /**
         * Converts this DcrSecretEndorsementResponse to JSON.
         * @function toJSON
         * @memberof identity_endorsement.DcrSecretEndorsementResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DcrSecretEndorsementResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DcrSecretEndorsementResponse
         * @function getTypeUrl
         * @memberof identity_endorsement.DcrSecretEndorsementResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DcrSecretEndorsementResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/identity_endorsement.DcrSecretEndorsementResponse";
        };

        return DcrSecretEndorsementResponse;
    })();

    identity_endorsement.EnclaveEndorsements = (function() {

        /**
         * Properties of an EnclaveEndorsements.
         * @memberof identity_endorsement
         * @interface IEnclaveEndorsements
         * @property {identity_endorsement.IEnclaveEndorsement|null} [personalPki] EnclaveEndorsements personalPki
         * @property {identity_endorsement.IEnclaveEndorsement|null} [dqPki] EnclaveEndorsements dqPki
         * @property {identity_endorsement.IEnclaveEndorsement|null} [dcrSecret] EnclaveEndorsements dcrSecret
         */

        /**
         * Constructs a new EnclaveEndorsements.
         * @memberof identity_endorsement
         * @classdesc Represents an EnclaveEndorsements.
         * @implements IEnclaveEndorsements
         * @constructor
         * @param {identity_endorsement.IEnclaveEndorsements=} [properties] Properties to set
         */
        function EnclaveEndorsements(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EnclaveEndorsements personalPki.
         * @member {identity_endorsement.IEnclaveEndorsement|null|undefined} personalPki
         * @memberof identity_endorsement.EnclaveEndorsements
         * @instance
         */
        EnclaveEndorsements.prototype.personalPki = null;

        /**
         * EnclaveEndorsements dqPki.
         * @member {identity_endorsement.IEnclaveEndorsement|null|undefined} dqPki
         * @memberof identity_endorsement.EnclaveEndorsements
         * @instance
         */
        EnclaveEndorsements.prototype.dqPki = null;

        /**
         * EnclaveEndorsements dcrSecret.
         * @member {identity_endorsement.IEnclaveEndorsement|null|undefined} dcrSecret
         * @memberof identity_endorsement.EnclaveEndorsements
         * @instance
         */
        EnclaveEndorsements.prototype.dcrSecret = null;

        /**
         * Creates a new EnclaveEndorsements instance using the specified properties.
         * @function create
         * @memberof identity_endorsement.EnclaveEndorsements
         * @static
         * @param {identity_endorsement.IEnclaveEndorsements=} [properties] Properties to set
         * @returns {identity_endorsement.EnclaveEndorsements} EnclaveEndorsements instance
         */
        EnclaveEndorsements.create = function create(properties) {
            return new EnclaveEndorsements(properties);
        };

        /**
         * Encodes the specified EnclaveEndorsements message. Does not implicitly {@link identity_endorsement.EnclaveEndorsements.verify|verify} messages.
         * @function encode
         * @memberof identity_endorsement.EnclaveEndorsements
         * @static
         * @param {identity_endorsement.IEnclaveEndorsements} message EnclaveEndorsements message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnclaveEndorsements.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.personalPki != null && Object.hasOwnProperty.call(message, "personalPki"))
                $root.identity_endorsement.EnclaveEndorsement.encode(message.personalPki, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.dqPki != null && Object.hasOwnProperty.call(message, "dqPki"))
                $root.identity_endorsement.EnclaveEndorsement.encode(message.dqPki, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.dcrSecret != null && Object.hasOwnProperty.call(message, "dcrSecret"))
                $root.identity_endorsement.EnclaveEndorsement.encode(message.dcrSecret, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified EnclaveEndorsements message, length delimited. Does not implicitly {@link identity_endorsement.EnclaveEndorsements.verify|verify} messages.
         * @function encodeDelimited
         * @memberof identity_endorsement.EnclaveEndorsements
         * @static
         * @param {identity_endorsement.IEnclaveEndorsements} message EnclaveEndorsements message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnclaveEndorsements.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EnclaveEndorsements message from the specified reader or buffer.
         * @function decode
         * @memberof identity_endorsement.EnclaveEndorsements
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {identity_endorsement.EnclaveEndorsements} EnclaveEndorsements
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnclaveEndorsements.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.identity_endorsement.EnclaveEndorsements();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.personalPki = $root.identity_endorsement.EnclaveEndorsement.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.dqPki = $root.identity_endorsement.EnclaveEndorsement.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.dcrSecret = $root.identity_endorsement.EnclaveEndorsement.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EnclaveEndorsements message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof identity_endorsement.EnclaveEndorsements
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {identity_endorsement.EnclaveEndorsements} EnclaveEndorsements
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnclaveEndorsements.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EnclaveEndorsements message.
         * @function verify
         * @memberof identity_endorsement.EnclaveEndorsements
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EnclaveEndorsements.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.personalPki != null && message.hasOwnProperty("personalPki")) {
                let error = $root.identity_endorsement.EnclaveEndorsement.verify(message.personalPki);
                if (error)
                    return "personalPki." + error;
            }
            if (message.dqPki != null && message.hasOwnProperty("dqPki")) {
                let error = $root.identity_endorsement.EnclaveEndorsement.verify(message.dqPki);
                if (error)
                    return "dqPki." + error;
            }
            if (message.dcrSecret != null && message.hasOwnProperty("dcrSecret")) {
                let error = $root.identity_endorsement.EnclaveEndorsement.verify(message.dcrSecret);
                if (error)
                    return "dcrSecret." + error;
            }
            return null;
        };

        /**
         * Creates an EnclaveEndorsements message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof identity_endorsement.EnclaveEndorsements
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {identity_endorsement.EnclaveEndorsements} EnclaveEndorsements
         */
        EnclaveEndorsements.fromObject = function fromObject(object) {
            if (object instanceof $root.identity_endorsement.EnclaveEndorsements)
                return object;
            let message = new $root.identity_endorsement.EnclaveEndorsements();
            if (object.personalPki != null) {
                if (typeof object.personalPki !== "object")
                    throw TypeError(".identity_endorsement.EnclaveEndorsements.personalPki: object expected");
                message.personalPki = $root.identity_endorsement.EnclaveEndorsement.fromObject(object.personalPki);
            }
            if (object.dqPki != null) {
                if (typeof object.dqPki !== "object")
                    throw TypeError(".identity_endorsement.EnclaveEndorsements.dqPki: object expected");
                message.dqPki = $root.identity_endorsement.EnclaveEndorsement.fromObject(object.dqPki);
            }
            if (object.dcrSecret != null) {
                if (typeof object.dcrSecret !== "object")
                    throw TypeError(".identity_endorsement.EnclaveEndorsements.dcrSecret: object expected");
                message.dcrSecret = $root.identity_endorsement.EnclaveEndorsement.fromObject(object.dcrSecret);
            }
            return message;
        };

        /**
         * Creates a plain object from an EnclaveEndorsements message. Also converts values to other types if specified.
         * @function toObject
         * @memberof identity_endorsement.EnclaveEndorsements
         * @static
         * @param {identity_endorsement.EnclaveEndorsements} message EnclaveEndorsements
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EnclaveEndorsements.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.personalPki = null;
                object.dqPki = null;
                object.dcrSecret = null;
            }
            if (message.personalPki != null && message.hasOwnProperty("personalPki"))
                object.personalPki = $root.identity_endorsement.EnclaveEndorsement.toObject(message.personalPki, options);
            if (message.dqPki != null && message.hasOwnProperty("dqPki"))
                object.dqPki = $root.identity_endorsement.EnclaveEndorsement.toObject(message.dqPki, options);
            if (message.dcrSecret != null && message.hasOwnProperty("dcrSecret"))
                object.dcrSecret = $root.identity_endorsement.EnclaveEndorsement.toObject(message.dcrSecret, options);
            return object;
        };

        /**
         * Converts this EnclaveEndorsements to JSON.
         * @function toJSON
         * @memberof identity_endorsement.EnclaveEndorsements
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EnclaveEndorsements.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EnclaveEndorsements
         * @function getTypeUrl
         * @memberof identity_endorsement.EnclaveEndorsements
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EnclaveEndorsements.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/identity_endorsement.EnclaveEndorsements";
        };

        return EnclaveEndorsements;
    })();

    identity_endorsement.EnclaveEndorsement = (function() {

        /**
         * Properties of an EnclaveEndorsement.
         * @memberof identity_endorsement
         * @interface IEnclaveEndorsement
         * @property {Uint8Array|null} [endorsementCertificateDer] EnclaveEndorsement endorsementCertificateDer
         */

        /**
         * Constructs a new EnclaveEndorsement.
         * @memberof identity_endorsement
         * @classdesc Represents an EnclaveEndorsement.
         * @implements IEnclaveEndorsement
         * @constructor
         * @param {identity_endorsement.IEnclaveEndorsement=} [properties] Properties to set
         */
        function EnclaveEndorsement(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EnclaveEndorsement endorsementCertificateDer.
         * @member {Uint8Array} endorsementCertificateDer
         * @memberof identity_endorsement.EnclaveEndorsement
         * @instance
         */
        EnclaveEndorsement.prototype.endorsementCertificateDer = $util.newBuffer([]);

        /**
         * Creates a new EnclaveEndorsement instance using the specified properties.
         * @function create
         * @memberof identity_endorsement.EnclaveEndorsement
         * @static
         * @param {identity_endorsement.IEnclaveEndorsement=} [properties] Properties to set
         * @returns {identity_endorsement.EnclaveEndorsement} EnclaveEndorsement instance
         */
        EnclaveEndorsement.create = function create(properties) {
            return new EnclaveEndorsement(properties);
        };

        /**
         * Encodes the specified EnclaveEndorsement message. Does not implicitly {@link identity_endorsement.EnclaveEndorsement.verify|verify} messages.
         * @function encode
         * @memberof identity_endorsement.EnclaveEndorsement
         * @static
         * @param {identity_endorsement.IEnclaveEndorsement} message EnclaveEndorsement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnclaveEndorsement.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.endorsementCertificateDer != null && Object.hasOwnProperty.call(message, "endorsementCertificateDer"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.endorsementCertificateDer);
            return writer;
        };

        /**
         * Encodes the specified EnclaveEndorsement message, length delimited. Does not implicitly {@link identity_endorsement.EnclaveEndorsement.verify|verify} messages.
         * @function encodeDelimited
         * @memberof identity_endorsement.EnclaveEndorsement
         * @static
         * @param {identity_endorsement.IEnclaveEndorsement} message EnclaveEndorsement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnclaveEndorsement.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EnclaveEndorsement message from the specified reader or buffer.
         * @function decode
         * @memberof identity_endorsement.EnclaveEndorsement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {identity_endorsement.EnclaveEndorsement} EnclaveEndorsement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnclaveEndorsement.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.identity_endorsement.EnclaveEndorsement();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.endorsementCertificateDer = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EnclaveEndorsement message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof identity_endorsement.EnclaveEndorsement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {identity_endorsement.EnclaveEndorsement} EnclaveEndorsement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnclaveEndorsement.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EnclaveEndorsement message.
         * @function verify
         * @memberof identity_endorsement.EnclaveEndorsement
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EnclaveEndorsement.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.endorsementCertificateDer != null && message.hasOwnProperty("endorsementCertificateDer"))
                if (!(message.endorsementCertificateDer && typeof message.endorsementCertificateDer.length === "number" || $util.isString(message.endorsementCertificateDer)))
                    return "endorsementCertificateDer: buffer expected";
            return null;
        };

        /**
         * Creates an EnclaveEndorsement message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof identity_endorsement.EnclaveEndorsement
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {identity_endorsement.EnclaveEndorsement} EnclaveEndorsement
         */
        EnclaveEndorsement.fromObject = function fromObject(object) {
            if (object instanceof $root.identity_endorsement.EnclaveEndorsement)
                return object;
            let message = new $root.identity_endorsement.EnclaveEndorsement();
            if (object.endorsementCertificateDer != null)
                if (typeof object.endorsementCertificateDer === "string")
                    $util.base64.decode(object.endorsementCertificateDer, message.endorsementCertificateDer = $util.newBuffer($util.base64.length(object.endorsementCertificateDer)), 0);
                else if (object.endorsementCertificateDer.length >= 0)
                    message.endorsementCertificateDer = object.endorsementCertificateDer;
            return message;
        };

        /**
         * Creates a plain object from an EnclaveEndorsement message. Also converts values to other types if specified.
         * @function toObject
         * @memberof identity_endorsement.EnclaveEndorsement
         * @static
         * @param {identity_endorsement.EnclaveEndorsement} message EnclaveEndorsement
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EnclaveEndorsement.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.endorsementCertificateDer = "";
                else {
                    object.endorsementCertificateDer = [];
                    if (options.bytes !== Array)
                        object.endorsementCertificateDer = $util.newBuffer(object.endorsementCertificateDer);
                }
            if (message.endorsementCertificateDer != null && message.hasOwnProperty("endorsementCertificateDer"))
                object.endorsementCertificateDer = options.bytes === String ? $util.base64.encode(message.endorsementCertificateDer, 0, message.endorsementCertificateDer.length) : options.bytes === Array ? Array.prototype.slice.call(message.endorsementCertificateDer) : message.endorsementCertificateDer;
            return object;
        };

        /**
         * Converts this EnclaveEndorsement to JSON.
         * @function toJSON
         * @memberof identity_endorsement.EnclaveEndorsement
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EnclaveEndorsement.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EnclaveEndorsement
         * @function getTypeUrl
         * @memberof identity_endorsement.EnclaveEndorsement
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EnclaveEndorsement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/identity_endorsement.EnclaveEndorsement";
        };

        return EnclaveEndorsement;
    })();

    identity_endorsement.PkiClaim = (function() {

        /**
         * Properties of a PkiClaim.
         * @memberof identity_endorsement
         * @interface IPkiClaim
         * @property {Uint8Array|null} [rootCertificateDer] PkiClaim rootCertificateDer
         */

        /**
         * Constructs a new PkiClaim.
         * @memberof identity_endorsement
         * @classdesc Represents a PkiClaim.
         * @implements IPkiClaim
         * @constructor
         * @param {identity_endorsement.IPkiClaim=} [properties] Properties to set
         */
        function PkiClaim(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PkiClaim rootCertificateDer.
         * @member {Uint8Array} rootCertificateDer
         * @memberof identity_endorsement.PkiClaim
         * @instance
         */
        PkiClaim.prototype.rootCertificateDer = $util.newBuffer([]);

        /**
         * Creates a new PkiClaim instance using the specified properties.
         * @function create
         * @memberof identity_endorsement.PkiClaim
         * @static
         * @param {identity_endorsement.IPkiClaim=} [properties] Properties to set
         * @returns {identity_endorsement.PkiClaim} PkiClaim instance
         */
        PkiClaim.create = function create(properties) {
            return new PkiClaim(properties);
        };

        /**
         * Encodes the specified PkiClaim message. Does not implicitly {@link identity_endorsement.PkiClaim.verify|verify} messages.
         * @function encode
         * @memberof identity_endorsement.PkiClaim
         * @static
         * @param {identity_endorsement.IPkiClaim} message PkiClaim message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PkiClaim.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rootCertificateDer != null && Object.hasOwnProperty.call(message, "rootCertificateDer"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.rootCertificateDer);
            return writer;
        };

        /**
         * Encodes the specified PkiClaim message, length delimited. Does not implicitly {@link identity_endorsement.PkiClaim.verify|verify} messages.
         * @function encodeDelimited
         * @memberof identity_endorsement.PkiClaim
         * @static
         * @param {identity_endorsement.IPkiClaim} message PkiClaim message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PkiClaim.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PkiClaim message from the specified reader or buffer.
         * @function decode
         * @memberof identity_endorsement.PkiClaim
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {identity_endorsement.PkiClaim} PkiClaim
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PkiClaim.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.identity_endorsement.PkiClaim();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rootCertificateDer = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PkiClaim message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof identity_endorsement.PkiClaim
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {identity_endorsement.PkiClaim} PkiClaim
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PkiClaim.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PkiClaim message.
         * @function verify
         * @memberof identity_endorsement.PkiClaim
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PkiClaim.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rootCertificateDer != null && message.hasOwnProperty("rootCertificateDer"))
                if (!(message.rootCertificateDer && typeof message.rootCertificateDer.length === "number" || $util.isString(message.rootCertificateDer)))
                    return "rootCertificateDer: buffer expected";
            return null;
        };

        /**
         * Creates a PkiClaim message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof identity_endorsement.PkiClaim
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {identity_endorsement.PkiClaim} PkiClaim
         */
        PkiClaim.fromObject = function fromObject(object) {
            if (object instanceof $root.identity_endorsement.PkiClaim)
                return object;
            let message = new $root.identity_endorsement.PkiClaim();
            if (object.rootCertificateDer != null)
                if (typeof object.rootCertificateDer === "string")
                    $util.base64.decode(object.rootCertificateDer, message.rootCertificateDer = $util.newBuffer($util.base64.length(object.rootCertificateDer)), 0);
                else if (object.rootCertificateDer.length >= 0)
                    message.rootCertificateDer = object.rootCertificateDer;
            return message;
        };

        /**
         * Creates a plain object from a PkiClaim message. Also converts values to other types if specified.
         * @function toObject
         * @memberof identity_endorsement.PkiClaim
         * @static
         * @param {identity_endorsement.PkiClaim} message PkiClaim
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PkiClaim.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.rootCertificateDer = "";
                else {
                    object.rootCertificateDer = [];
                    if (options.bytes !== Array)
                        object.rootCertificateDer = $util.newBuffer(object.rootCertificateDer);
                }
            if (message.rootCertificateDer != null && message.hasOwnProperty("rootCertificateDer"))
                object.rootCertificateDer = options.bytes === String ? $util.base64.encode(message.rootCertificateDer, 0, message.rootCertificateDer.length) : options.bytes === Array ? Array.prototype.slice.call(message.rootCertificateDer) : message.rootCertificateDer;
            return object;
        };

        /**
         * Converts this PkiClaim to JSON.
         * @function toJSON
         * @memberof identity_endorsement.PkiClaim
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PkiClaim.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PkiClaim
         * @function getTypeUrl
         * @memberof identity_endorsement.PkiClaim
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PkiClaim.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/identity_endorsement.PkiClaim";
        };

        return PkiClaim;
    })();

    identity_endorsement.DcrSecretClaim = (function() {

        /**
         * Properties of a DcrSecretClaim.
         * @memberof identity_endorsement
         * @interface IDcrSecretClaim
         * @property {Uint8Array|null} [dcrSecretId] DcrSecretClaim dcrSecretId
         */

        /**
         * Constructs a new DcrSecretClaim.
         * @memberof identity_endorsement
         * @classdesc Represents a DcrSecretClaim.
         * @implements IDcrSecretClaim
         * @constructor
         * @param {identity_endorsement.IDcrSecretClaim=} [properties] Properties to set
         */
        function DcrSecretClaim(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DcrSecretClaim dcrSecretId.
         * @member {Uint8Array} dcrSecretId
         * @memberof identity_endorsement.DcrSecretClaim
         * @instance
         */
        DcrSecretClaim.prototype.dcrSecretId = $util.newBuffer([]);

        /**
         * Creates a new DcrSecretClaim instance using the specified properties.
         * @function create
         * @memberof identity_endorsement.DcrSecretClaim
         * @static
         * @param {identity_endorsement.IDcrSecretClaim=} [properties] Properties to set
         * @returns {identity_endorsement.DcrSecretClaim} DcrSecretClaim instance
         */
        DcrSecretClaim.create = function create(properties) {
            return new DcrSecretClaim(properties);
        };

        /**
         * Encodes the specified DcrSecretClaim message. Does not implicitly {@link identity_endorsement.DcrSecretClaim.verify|verify} messages.
         * @function encode
         * @memberof identity_endorsement.DcrSecretClaim
         * @static
         * @param {identity_endorsement.IDcrSecretClaim} message DcrSecretClaim message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrSecretClaim.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dcrSecretId != null && Object.hasOwnProperty.call(message, "dcrSecretId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dcrSecretId);
            return writer;
        };

        /**
         * Encodes the specified DcrSecretClaim message, length delimited. Does not implicitly {@link identity_endorsement.DcrSecretClaim.verify|verify} messages.
         * @function encodeDelimited
         * @memberof identity_endorsement.DcrSecretClaim
         * @static
         * @param {identity_endorsement.IDcrSecretClaim} message DcrSecretClaim message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrSecretClaim.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DcrSecretClaim message from the specified reader or buffer.
         * @function decode
         * @memberof identity_endorsement.DcrSecretClaim
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {identity_endorsement.DcrSecretClaim} DcrSecretClaim
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrSecretClaim.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.identity_endorsement.DcrSecretClaim();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dcrSecretId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DcrSecretClaim message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof identity_endorsement.DcrSecretClaim
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {identity_endorsement.DcrSecretClaim} DcrSecretClaim
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrSecretClaim.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DcrSecretClaim message.
         * @function verify
         * @memberof identity_endorsement.DcrSecretClaim
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DcrSecretClaim.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dcrSecretId != null && message.hasOwnProperty("dcrSecretId"))
                if (!(message.dcrSecretId && typeof message.dcrSecretId.length === "number" || $util.isString(message.dcrSecretId)))
                    return "dcrSecretId: buffer expected";
            return null;
        };

        /**
         * Creates a DcrSecretClaim message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof identity_endorsement.DcrSecretClaim
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {identity_endorsement.DcrSecretClaim} DcrSecretClaim
         */
        DcrSecretClaim.fromObject = function fromObject(object) {
            if (object instanceof $root.identity_endorsement.DcrSecretClaim)
                return object;
            let message = new $root.identity_endorsement.DcrSecretClaim();
            if (object.dcrSecretId != null)
                if (typeof object.dcrSecretId === "string")
                    $util.base64.decode(object.dcrSecretId, message.dcrSecretId = $util.newBuffer($util.base64.length(object.dcrSecretId)), 0);
                else if (object.dcrSecretId.length >= 0)
                    message.dcrSecretId = object.dcrSecretId;
            return message;
        };

        /**
         * Creates a plain object from a DcrSecretClaim message. Also converts values to other types if specified.
         * @function toObject
         * @memberof identity_endorsement.DcrSecretClaim
         * @static
         * @param {identity_endorsement.DcrSecretClaim} message DcrSecretClaim
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DcrSecretClaim.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.dcrSecretId = "";
                else {
                    object.dcrSecretId = [];
                    if (options.bytes !== Array)
                        object.dcrSecretId = $util.newBuffer(object.dcrSecretId);
                }
            if (message.dcrSecretId != null && message.hasOwnProperty("dcrSecretId"))
                object.dcrSecretId = options.bytes === String ? $util.base64.encode(message.dcrSecretId, 0, message.dcrSecretId.length) : options.bytes === Array ? Array.prototype.slice.call(message.dcrSecretId) : message.dcrSecretId;
            return object;
        };

        /**
         * Converts this DcrSecretClaim to JSON.
         * @function toJSON
         * @memberof identity_endorsement.DcrSecretClaim
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DcrSecretClaim.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DcrSecretClaim
         * @function getTypeUrl
         * @memberof identity_endorsement.DcrSecretClaim
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DcrSecretClaim.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/identity_endorsement.DcrSecretClaim";
        };

        return DcrSecretClaim;
    })();

    return identity_endorsement;
})();

export const release_policy_api = $root.release_policy_api = (() => {

    /**
     * Namespace release_policy_api.
     * @exports release_policy_api
     * @namespace
     */
    const release_policy_api = {};

    release_policy_api.DcrComputeContext = (function() {

        /**
         * Properties of a DcrComputeContext.
         * @memberof release_policy_api
         * @interface IDcrComputeContext
         * @property {string|null} [dataRoomId] DcrComputeContext dataRoomId
         */

        /**
         * Constructs a new DcrComputeContext.
         * @memberof release_policy_api
         * @classdesc Identifies a particular DCR
         * @implements IDcrComputeContext
         * @constructor
         * @param {release_policy_api.IDcrComputeContext=} [properties] Properties to set
         */
        function DcrComputeContext(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DcrComputeContext dataRoomId.
         * @member {string} dataRoomId
         * @memberof release_policy_api.DcrComputeContext
         * @instance
         */
        DcrComputeContext.prototype.dataRoomId = "";

        /**
         * Creates a new DcrComputeContext instance using the specified properties.
         * @function create
         * @memberof release_policy_api.DcrComputeContext
         * @static
         * @param {release_policy_api.IDcrComputeContext=} [properties] Properties to set
         * @returns {release_policy_api.DcrComputeContext} DcrComputeContext instance
         */
        DcrComputeContext.create = function create(properties) {
            return new DcrComputeContext(properties);
        };

        /**
         * Encodes the specified DcrComputeContext message. Does not implicitly {@link release_policy_api.DcrComputeContext.verify|verify} messages.
         * @function encode
         * @memberof release_policy_api.DcrComputeContext
         * @static
         * @param {release_policy_api.IDcrComputeContext} message DcrComputeContext message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrComputeContext.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dataRoomId != null && Object.hasOwnProperty.call(message, "dataRoomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dataRoomId);
            return writer;
        };

        /**
         * Encodes the specified DcrComputeContext message, length delimited. Does not implicitly {@link release_policy_api.DcrComputeContext.verify|verify} messages.
         * @function encodeDelimited
         * @memberof release_policy_api.DcrComputeContext
         * @static
         * @param {release_policy_api.IDcrComputeContext} message DcrComputeContext message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrComputeContext.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DcrComputeContext message from the specified reader or buffer.
         * @function decode
         * @memberof release_policy_api.DcrComputeContext
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {release_policy_api.DcrComputeContext} DcrComputeContext
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrComputeContext.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.release_policy_api.DcrComputeContext();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dataRoomId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DcrComputeContext message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof release_policy_api.DcrComputeContext
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {release_policy_api.DcrComputeContext} DcrComputeContext
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrComputeContext.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DcrComputeContext message.
         * @function verify
         * @memberof release_policy_api.DcrComputeContext
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DcrComputeContext.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                if (!$util.isString(message.dataRoomId))
                    return "dataRoomId: string expected";
            return null;
        };

        /**
         * Creates a DcrComputeContext message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof release_policy_api.DcrComputeContext
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {release_policy_api.DcrComputeContext} DcrComputeContext
         */
        DcrComputeContext.fromObject = function fromObject(object) {
            if (object instanceof $root.release_policy_api.DcrComputeContext)
                return object;
            let message = new $root.release_policy_api.DcrComputeContext();
            if (object.dataRoomId != null)
                message.dataRoomId = String(object.dataRoomId);
            return message;
        };

        /**
         * Creates a plain object from a DcrComputeContext message. Also converts values to other types if specified.
         * @function toObject
         * @memberof release_policy_api.DcrComputeContext
         * @static
         * @param {release_policy_api.DcrComputeContext} message DcrComputeContext
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DcrComputeContext.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.dataRoomId = "";
            if (message.dataRoomId != null && message.hasOwnProperty("dataRoomId"))
                object.dataRoomId = message.dataRoomId;
            return object;
        };

        /**
         * Converts this DcrComputeContext to JSON.
         * @function toJSON
         * @memberof release_policy_api.DcrComputeContext
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DcrComputeContext.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DcrComputeContext
         * @function getTypeUrl
         * @memberof release_policy_api.DcrComputeContext
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DcrComputeContext.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/release_policy_api.DcrComputeContext";
        };

        return DcrComputeContext;
    })();

    release_policy_api.AllowedComputeContext = (function() {

        /**
         * Properties of an AllowedComputeContext.
         * @memberof release_policy_api
         * @interface IAllowedComputeContext
         * @property {release_policy_api.IDcrComputeContext|null} [dcr] AllowedComputeContext dcr
         */

        /**
         * Constructs a new AllowedComputeContext.
         * @memberof release_policy_api
         * @classdesc Represents an AllowedComputeContext.
         * @implements IAllowedComputeContext
         * @constructor
         * @param {release_policy_api.IAllowedComputeContext=} [properties] Properties to set
         */
        function AllowedComputeContext(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AllowedComputeContext dcr.
         * @member {release_policy_api.IDcrComputeContext|null|undefined} dcr
         * @memberof release_policy_api.AllowedComputeContext
         * @instance
         */
        AllowedComputeContext.prototype.dcr = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * AllowedComputeContext kind.
         * @member {"dcr"|undefined} kind
         * @memberof release_policy_api.AllowedComputeContext
         * @instance
         */
        Object.defineProperty(AllowedComputeContext.prototype, "kind", {
            get: $util.oneOfGetter($oneOfFields = ["dcr"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new AllowedComputeContext instance using the specified properties.
         * @function create
         * @memberof release_policy_api.AllowedComputeContext
         * @static
         * @param {release_policy_api.IAllowedComputeContext=} [properties] Properties to set
         * @returns {release_policy_api.AllowedComputeContext} AllowedComputeContext instance
         */
        AllowedComputeContext.create = function create(properties) {
            return new AllowedComputeContext(properties);
        };

        /**
         * Encodes the specified AllowedComputeContext message. Does not implicitly {@link release_policy_api.AllowedComputeContext.verify|verify} messages.
         * @function encode
         * @memberof release_policy_api.AllowedComputeContext
         * @static
         * @param {release_policy_api.IAllowedComputeContext} message AllowedComputeContext message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AllowedComputeContext.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dcr != null && Object.hasOwnProperty.call(message, "dcr"))
                $root.release_policy_api.DcrComputeContext.encode(message.dcr, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AllowedComputeContext message, length delimited. Does not implicitly {@link release_policy_api.AllowedComputeContext.verify|verify} messages.
         * @function encodeDelimited
         * @memberof release_policy_api.AllowedComputeContext
         * @static
         * @param {release_policy_api.IAllowedComputeContext} message AllowedComputeContext message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AllowedComputeContext.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AllowedComputeContext message from the specified reader or buffer.
         * @function decode
         * @memberof release_policy_api.AllowedComputeContext
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {release_policy_api.AllowedComputeContext} AllowedComputeContext
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AllowedComputeContext.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.release_policy_api.AllowedComputeContext();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dcr = $root.release_policy_api.DcrComputeContext.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AllowedComputeContext message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof release_policy_api.AllowedComputeContext
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {release_policy_api.AllowedComputeContext} AllowedComputeContext
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AllowedComputeContext.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AllowedComputeContext message.
         * @function verify
         * @memberof release_policy_api.AllowedComputeContext
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AllowedComputeContext.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.dcr != null && message.hasOwnProperty("dcr")) {
                properties.kind = 1;
                {
                    let error = $root.release_policy_api.DcrComputeContext.verify(message.dcr);
                    if (error)
                        return "dcr." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AllowedComputeContext message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof release_policy_api.AllowedComputeContext
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {release_policy_api.AllowedComputeContext} AllowedComputeContext
         */
        AllowedComputeContext.fromObject = function fromObject(object) {
            if (object instanceof $root.release_policy_api.AllowedComputeContext)
                return object;
            let message = new $root.release_policy_api.AllowedComputeContext();
            if (object.dcr != null) {
                if (typeof object.dcr !== "object")
                    throw TypeError(".release_policy_api.AllowedComputeContext.dcr: object expected");
                message.dcr = $root.release_policy_api.DcrComputeContext.fromObject(object.dcr);
            }
            return message;
        };

        /**
         * Creates a plain object from an AllowedComputeContext message. Also converts values to other types if specified.
         * @function toObject
         * @memberof release_policy_api.AllowedComputeContext
         * @static
         * @param {release_policy_api.AllowedComputeContext} message AllowedComputeContext
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AllowedComputeContext.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.dcr != null && message.hasOwnProperty("dcr")) {
                object.dcr = $root.release_policy_api.DcrComputeContext.toObject(message.dcr, options);
                if (options.oneofs)
                    object.kind = "dcr";
            }
            return object;
        };

        /**
         * Converts this AllowedComputeContext to JSON.
         * @function toJSON
         * @memberof release_policy_api.AllowedComputeContext
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AllowedComputeContext.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AllowedComputeContext
         * @function getTypeUrl
         * @memberof release_policy_api.AllowedComputeContext
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AllowedComputeContext.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/release_policy_api.AllowedComputeContext";
        };

        return AllowedComputeContext;
    })();

    release_policy_api.ReleasePolicyV0 = (function() {

        /**
         * Properties of a ReleasePolicyV0.
         * @memberof release_policy_api
         * @interface IReleasePolicyV0
         * @property {release_policy_api.ISecretSource|null} [secretSource] ReleasePolicyV0 secretSource
         * @property {release_policy_api.IAllowedComputeContext|null} [allowedComputeContext] Determines in which computation context a secret can be released.
         * @property {release_policy_api.IDataSource|null} [dataSource] Identifies to which data this policy applies
         */

        /**
         * Constructs a new ReleasePolicyV0.
         * @memberof release_policy_api
         * @classdesc Represents a ReleasePolicyV0.
         * @implements IReleasePolicyV0
         * @constructor
         * @param {release_policy_api.IReleasePolicyV0=} [properties] Properties to set
         */
        function ReleasePolicyV0(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReleasePolicyV0 secretSource.
         * @member {release_policy_api.ISecretSource|null|undefined} secretSource
         * @memberof release_policy_api.ReleasePolicyV0
         * @instance
         */
        ReleasePolicyV0.prototype.secretSource = null;

        /**
         * Determines in which computation context a secret can be released.
         * @member {release_policy_api.IAllowedComputeContext|null|undefined} allowedComputeContext
         * @memberof release_policy_api.ReleasePolicyV0
         * @instance
         */
        ReleasePolicyV0.prototype.allowedComputeContext = null;

        /**
         * Identifies to which data this policy applies
         * @member {release_policy_api.IDataSource|null|undefined} dataSource
         * @memberof release_policy_api.ReleasePolicyV0
         * @instance
         */
        ReleasePolicyV0.prototype.dataSource = null;

        /**
         * Creates a new ReleasePolicyV0 instance using the specified properties.
         * @function create
         * @memberof release_policy_api.ReleasePolicyV0
         * @static
         * @param {release_policy_api.IReleasePolicyV0=} [properties] Properties to set
         * @returns {release_policy_api.ReleasePolicyV0} ReleasePolicyV0 instance
         */
        ReleasePolicyV0.create = function create(properties) {
            return new ReleasePolicyV0(properties);
        };

        /**
         * Encodes the specified ReleasePolicyV0 message. Does not implicitly {@link release_policy_api.ReleasePolicyV0.verify|verify} messages.
         * @function encode
         * @memberof release_policy_api.ReleasePolicyV0
         * @static
         * @param {release_policy_api.IReleasePolicyV0} message ReleasePolicyV0 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReleasePolicyV0.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.secretSource != null && Object.hasOwnProperty.call(message, "secretSource"))
                $root.release_policy_api.SecretSource.encode(message.secretSource, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.allowedComputeContext != null && Object.hasOwnProperty.call(message, "allowedComputeContext"))
                $root.release_policy_api.AllowedComputeContext.encode(message.allowedComputeContext, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.dataSource != null && Object.hasOwnProperty.call(message, "dataSource"))
                $root.release_policy_api.DataSource.encode(message.dataSource, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ReleasePolicyV0 message, length delimited. Does not implicitly {@link release_policy_api.ReleasePolicyV0.verify|verify} messages.
         * @function encodeDelimited
         * @memberof release_policy_api.ReleasePolicyV0
         * @static
         * @param {release_policy_api.IReleasePolicyV0} message ReleasePolicyV0 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReleasePolicyV0.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReleasePolicyV0 message from the specified reader or buffer.
         * @function decode
         * @memberof release_policy_api.ReleasePolicyV0
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {release_policy_api.ReleasePolicyV0} ReleasePolicyV0
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReleasePolicyV0.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.release_policy_api.ReleasePolicyV0();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.secretSource = $root.release_policy_api.SecretSource.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.allowedComputeContext = $root.release_policy_api.AllowedComputeContext.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.dataSource = $root.release_policy_api.DataSource.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReleasePolicyV0 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof release_policy_api.ReleasePolicyV0
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {release_policy_api.ReleasePolicyV0} ReleasePolicyV0
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReleasePolicyV0.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReleasePolicyV0 message.
         * @function verify
         * @memberof release_policy_api.ReleasePolicyV0
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReleasePolicyV0.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.secretSource != null && message.hasOwnProperty("secretSource")) {
                let error = $root.release_policy_api.SecretSource.verify(message.secretSource);
                if (error)
                    return "secretSource." + error;
            }
            if (message.allowedComputeContext != null && message.hasOwnProperty("allowedComputeContext")) {
                let error = $root.release_policy_api.AllowedComputeContext.verify(message.allowedComputeContext);
                if (error)
                    return "allowedComputeContext." + error;
            }
            if (message.dataSource != null && message.hasOwnProperty("dataSource")) {
                let error = $root.release_policy_api.DataSource.verify(message.dataSource);
                if (error)
                    return "dataSource." + error;
            }
            return null;
        };

        /**
         * Creates a ReleasePolicyV0 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof release_policy_api.ReleasePolicyV0
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {release_policy_api.ReleasePolicyV0} ReleasePolicyV0
         */
        ReleasePolicyV0.fromObject = function fromObject(object) {
            if (object instanceof $root.release_policy_api.ReleasePolicyV0)
                return object;
            let message = new $root.release_policy_api.ReleasePolicyV0();
            if (object.secretSource != null) {
                if (typeof object.secretSource !== "object")
                    throw TypeError(".release_policy_api.ReleasePolicyV0.secretSource: object expected");
                message.secretSource = $root.release_policy_api.SecretSource.fromObject(object.secretSource);
            }
            if (object.allowedComputeContext != null) {
                if (typeof object.allowedComputeContext !== "object")
                    throw TypeError(".release_policy_api.ReleasePolicyV0.allowedComputeContext: object expected");
                message.allowedComputeContext = $root.release_policy_api.AllowedComputeContext.fromObject(object.allowedComputeContext);
            }
            if (object.dataSource != null) {
                if (typeof object.dataSource !== "object")
                    throw TypeError(".release_policy_api.ReleasePolicyV0.dataSource: object expected");
                message.dataSource = $root.release_policy_api.DataSource.fromObject(object.dataSource);
            }
            return message;
        };

        /**
         * Creates a plain object from a ReleasePolicyV0 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof release_policy_api.ReleasePolicyV0
         * @static
         * @param {release_policy_api.ReleasePolicyV0} message ReleasePolicyV0
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReleasePolicyV0.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.secretSource = null;
                object.allowedComputeContext = null;
                object.dataSource = null;
            }
            if (message.secretSource != null && message.hasOwnProperty("secretSource"))
                object.secretSource = $root.release_policy_api.SecretSource.toObject(message.secretSource, options);
            if (message.allowedComputeContext != null && message.hasOwnProperty("allowedComputeContext"))
                object.allowedComputeContext = $root.release_policy_api.AllowedComputeContext.toObject(message.allowedComputeContext, options);
            if (message.dataSource != null && message.hasOwnProperty("dataSource"))
                object.dataSource = $root.release_policy_api.DataSource.toObject(message.dataSource, options);
            return object;
        };

        /**
         * Converts this ReleasePolicyV0 to JSON.
         * @function toJSON
         * @memberof release_policy_api.ReleasePolicyV0
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReleasePolicyV0.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReleasePolicyV0
         * @function getTypeUrl
         * @memberof release_policy_api.ReleasePolicyV0
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReleasePolicyV0.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/release_policy_api.ReleasePolicyV0";
        };

        return ReleasePolicyV0;
    })();

    release_policy_api.ReleasePolicy = (function() {

        /**
         * Properties of a ReleasePolicy.
         * @memberof release_policy_api
         * @interface IReleasePolicy
         * @property {release_policy_api.IReleasePolicyV0|null} [v0] ReleasePolicy v0
         */

        /**
         * Constructs a new ReleasePolicy.
         * @memberof release_policy_api
         * @classdesc Represents a ReleasePolicy.
         * @implements IReleasePolicy
         * @constructor
         * @param {release_policy_api.IReleasePolicy=} [properties] Properties to set
         */
        function ReleasePolicy(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReleasePolicy v0.
         * @member {release_policy_api.IReleasePolicyV0|null|undefined} v0
         * @memberof release_policy_api.ReleasePolicy
         * @instance
         */
        ReleasePolicy.prototype.v0 = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ReleasePolicy version.
         * @member {"v0"|undefined} version
         * @memberof release_policy_api.ReleasePolicy
         * @instance
         */
        Object.defineProperty(ReleasePolicy.prototype, "version", {
            get: $util.oneOfGetter($oneOfFields = ["v0"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ReleasePolicy instance using the specified properties.
         * @function create
         * @memberof release_policy_api.ReleasePolicy
         * @static
         * @param {release_policy_api.IReleasePolicy=} [properties] Properties to set
         * @returns {release_policy_api.ReleasePolicy} ReleasePolicy instance
         */
        ReleasePolicy.create = function create(properties) {
            return new ReleasePolicy(properties);
        };

        /**
         * Encodes the specified ReleasePolicy message. Does not implicitly {@link release_policy_api.ReleasePolicy.verify|verify} messages.
         * @function encode
         * @memberof release_policy_api.ReleasePolicy
         * @static
         * @param {release_policy_api.IReleasePolicy} message ReleasePolicy message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReleasePolicy.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.v0 != null && Object.hasOwnProperty.call(message, "v0"))
                $root.release_policy_api.ReleasePolicyV0.encode(message.v0, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ReleasePolicy message, length delimited. Does not implicitly {@link release_policy_api.ReleasePolicy.verify|verify} messages.
         * @function encodeDelimited
         * @memberof release_policy_api.ReleasePolicy
         * @static
         * @param {release_policy_api.IReleasePolicy} message ReleasePolicy message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReleasePolicy.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReleasePolicy message from the specified reader or buffer.
         * @function decode
         * @memberof release_policy_api.ReleasePolicy
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {release_policy_api.ReleasePolicy} ReleasePolicy
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReleasePolicy.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.release_policy_api.ReleasePolicy();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.v0 = $root.release_policy_api.ReleasePolicyV0.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReleasePolicy message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof release_policy_api.ReleasePolicy
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {release_policy_api.ReleasePolicy} ReleasePolicy
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReleasePolicy.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReleasePolicy message.
         * @function verify
         * @memberof release_policy_api.ReleasePolicy
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReleasePolicy.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.v0 != null && message.hasOwnProperty("v0")) {
                properties.version = 1;
                {
                    let error = $root.release_policy_api.ReleasePolicyV0.verify(message.v0);
                    if (error)
                        return "v0." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ReleasePolicy message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof release_policy_api.ReleasePolicy
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {release_policy_api.ReleasePolicy} ReleasePolicy
         */
        ReleasePolicy.fromObject = function fromObject(object) {
            if (object instanceof $root.release_policy_api.ReleasePolicy)
                return object;
            let message = new $root.release_policy_api.ReleasePolicy();
            if (object.v0 != null) {
                if (typeof object.v0 !== "object")
                    throw TypeError(".release_policy_api.ReleasePolicy.v0: object expected");
                message.v0 = $root.release_policy_api.ReleasePolicyV0.fromObject(object.v0);
            }
            return message;
        };

        /**
         * Creates a plain object from a ReleasePolicy message. Also converts values to other types if specified.
         * @function toObject
         * @memberof release_policy_api.ReleasePolicy
         * @static
         * @param {release_policy_api.ReleasePolicy} message ReleasePolicy
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReleasePolicy.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.v0 != null && message.hasOwnProperty("v0")) {
                object.v0 = $root.release_policy_api.ReleasePolicyV0.toObject(message.v0, options);
                if (options.oneofs)
                    object.version = "v0";
            }
            return object;
        };

        /**
         * Converts this ReleasePolicy to JSON.
         * @function toJSON
         * @memberof release_policy_api.ReleasePolicy
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReleasePolicy.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReleasePolicy
         * @function getTypeUrl
         * @memberof release_policy_api.ReleasePolicy
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReleasePolicy.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/release_policy_api.ReleasePolicy";
        };

        return ReleasePolicy;
    })();

    release_policy_api.ComputationResultReference = (function() {

        /**
         * Properties of a ComputationResultReference.
         * @memberof release_policy_api
         * @interface IComputationResultReference
         * @property {string|null} [jobId] ComputationResultReference jobId
         * @property {string|null} [resultHash] ComputationResultReference resultHash
         */

        /**
         * Constructs a new ComputationResultReference.
         * @memberof release_policy_api
         * @classdesc the task result with the given hash.
         * @implements IComputationResultReference
         * @constructor
         * @param {release_policy_api.IComputationResultReference=} [properties] Properties to set
         */
        function ComputationResultReference(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ComputationResultReference jobId.
         * @member {string} jobId
         * @memberof release_policy_api.ComputationResultReference
         * @instance
         */
        ComputationResultReference.prototype.jobId = "";

        /**
         * ComputationResultReference resultHash.
         * @member {string} resultHash
         * @memberof release_policy_api.ComputationResultReference
         * @instance
         */
        ComputationResultReference.prototype.resultHash = "";

        /**
         * Creates a new ComputationResultReference instance using the specified properties.
         * @function create
         * @memberof release_policy_api.ComputationResultReference
         * @static
         * @param {release_policy_api.IComputationResultReference=} [properties] Properties to set
         * @returns {release_policy_api.ComputationResultReference} ComputationResultReference instance
         */
        ComputationResultReference.create = function create(properties) {
            return new ComputationResultReference(properties);
        };

        /**
         * Encodes the specified ComputationResultReference message. Does not implicitly {@link release_policy_api.ComputationResultReference.verify|verify} messages.
         * @function encode
         * @memberof release_policy_api.ComputationResultReference
         * @static
         * @param {release_policy_api.IComputationResultReference} message ComputationResultReference message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComputationResultReference.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.jobId != null && Object.hasOwnProperty.call(message, "jobId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.jobId);
            if (message.resultHash != null && Object.hasOwnProperty.call(message, "resultHash"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.resultHash);
            return writer;
        };

        /**
         * Encodes the specified ComputationResultReference message, length delimited. Does not implicitly {@link release_policy_api.ComputationResultReference.verify|verify} messages.
         * @function encodeDelimited
         * @memberof release_policy_api.ComputationResultReference
         * @static
         * @param {release_policy_api.IComputationResultReference} message ComputationResultReference message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComputationResultReference.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ComputationResultReference message from the specified reader or buffer.
         * @function decode
         * @memberof release_policy_api.ComputationResultReference
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {release_policy_api.ComputationResultReference} ComputationResultReference
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComputationResultReference.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.release_policy_api.ComputationResultReference();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.jobId = reader.string();
                        break;
                    }
                case 2: {
                        message.resultHash = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ComputationResultReference message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof release_policy_api.ComputationResultReference
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {release_policy_api.ComputationResultReference} ComputationResultReference
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComputationResultReference.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ComputationResultReference message.
         * @function verify
         * @memberof release_policy_api.ComputationResultReference
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ComputationResultReference.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.jobId != null && message.hasOwnProperty("jobId"))
                if (!$util.isString(message.jobId))
                    return "jobId: string expected";
            if (message.resultHash != null && message.hasOwnProperty("resultHash"))
                if (!$util.isString(message.resultHash))
                    return "resultHash: string expected";
            return null;
        };

        /**
         * Creates a ComputationResultReference message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof release_policy_api.ComputationResultReference
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {release_policy_api.ComputationResultReference} ComputationResultReference
         */
        ComputationResultReference.fromObject = function fromObject(object) {
            if (object instanceof $root.release_policy_api.ComputationResultReference)
                return object;
            let message = new $root.release_policy_api.ComputationResultReference();
            if (object.jobId != null)
                message.jobId = String(object.jobId);
            if (object.resultHash != null)
                message.resultHash = String(object.resultHash);
            return message;
        };

        /**
         * Creates a plain object from a ComputationResultReference message. Also converts values to other types if specified.
         * @function toObject
         * @memberof release_policy_api.ComputationResultReference
         * @static
         * @param {release_policy_api.ComputationResultReference} message ComputationResultReference
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ComputationResultReference.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.jobId = "";
                object.resultHash = "";
            }
            if (message.jobId != null && message.hasOwnProperty("jobId"))
                object.jobId = message.jobId;
            if (message.resultHash != null && message.hasOwnProperty("resultHash"))
                object.resultHash = message.resultHash;
            return object;
        };

        /**
         * Converts this ComputationResultReference to JSON.
         * @function toJSON
         * @memberof release_policy_api.ComputationResultReference
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ComputationResultReference.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ComputationResultReference
         * @function getTypeUrl
         * @memberof release_policy_api.ComputationResultReference
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ComputationResultReference.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/release_policy_api.ComputationResultReference";
        };

        return ComputationResultReference;
    })();

    release_policy_api.SecretSource = (function() {

        /**
         * Properties of a SecretSource.
         * @memberof release_policy_api
         * @interface ISecretSource
         * @property {string|null} [secretStoreId] SecretSource secretStoreId
         */

        /**
         * Constructs a new SecretSource.
         * @memberof release_policy_api
         * @classdesc (such as an encryption key) from.
         * @implements ISecretSource
         * @constructor
         * @param {release_policy_api.ISecretSource=} [properties] Properties to set
         */
        function SecretSource(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SecretSource secretStoreId.
         * @member {string|null|undefined} secretStoreId
         * @memberof release_policy_api.SecretSource
         * @instance
         */
        SecretSource.prototype.secretStoreId = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SecretSource kind.
         * @member {"secretStoreId"|undefined} kind
         * @memberof release_policy_api.SecretSource
         * @instance
         */
        Object.defineProperty(SecretSource.prototype, "kind", {
            get: $util.oneOfGetter($oneOfFields = ["secretStoreId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SecretSource instance using the specified properties.
         * @function create
         * @memberof release_policy_api.SecretSource
         * @static
         * @param {release_policy_api.ISecretSource=} [properties] Properties to set
         * @returns {release_policy_api.SecretSource} SecretSource instance
         */
        SecretSource.create = function create(properties) {
            return new SecretSource(properties);
        };

        /**
         * Encodes the specified SecretSource message. Does not implicitly {@link release_policy_api.SecretSource.verify|verify} messages.
         * @function encode
         * @memberof release_policy_api.SecretSource
         * @static
         * @param {release_policy_api.ISecretSource} message SecretSource message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SecretSource.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.secretStoreId != null && Object.hasOwnProperty.call(message, "secretStoreId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.secretStoreId);
            return writer;
        };

        /**
         * Encodes the specified SecretSource message, length delimited. Does not implicitly {@link release_policy_api.SecretSource.verify|verify} messages.
         * @function encodeDelimited
         * @memberof release_policy_api.SecretSource
         * @static
         * @param {release_policy_api.ISecretSource} message SecretSource message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SecretSource.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SecretSource message from the specified reader or buffer.
         * @function decode
         * @memberof release_policy_api.SecretSource
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {release_policy_api.SecretSource} SecretSource
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SecretSource.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.release_policy_api.SecretSource();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.secretStoreId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SecretSource message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof release_policy_api.SecretSource
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {release_policy_api.SecretSource} SecretSource
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SecretSource.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SecretSource message.
         * @function verify
         * @memberof release_policy_api.SecretSource
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SecretSource.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.secretStoreId != null && message.hasOwnProperty("secretStoreId")) {
                properties.kind = 1;
                if (!$util.isString(message.secretStoreId))
                    return "secretStoreId: string expected";
            }
            return null;
        };

        /**
         * Creates a SecretSource message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof release_policy_api.SecretSource
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {release_policy_api.SecretSource} SecretSource
         */
        SecretSource.fromObject = function fromObject(object) {
            if (object instanceof $root.release_policy_api.SecretSource)
                return object;
            let message = new $root.release_policy_api.SecretSource();
            if (object.secretStoreId != null)
                message.secretStoreId = String(object.secretStoreId);
            return message;
        };

        /**
         * Creates a plain object from a SecretSource message. Also converts values to other types if specified.
         * @function toObject
         * @memberof release_policy_api.SecretSource
         * @static
         * @param {release_policy_api.SecretSource} message SecretSource
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SecretSource.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.secretStoreId != null && message.hasOwnProperty("secretStoreId")) {
                object.secretStoreId = message.secretStoreId;
                if (options.oneofs)
                    object.kind = "secretStoreId";
            }
            return object;
        };

        /**
         * Converts this SecretSource to JSON.
         * @function toJSON
         * @memberof release_policy_api.SecretSource
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SecretSource.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SecretSource
         * @function getTypeUrl
         * @memberof release_policy_api.SecretSource
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SecretSource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/release_policy_api.SecretSource";
        };

        return SecretSource;
    })();

    release_policy_api.DataSource = (function() {

        /**
         * Properties of a DataSource.
         * @memberof release_policy_api
         * @interface IDataSource
         * @property {release_policy_api.IDatasetDataSource|null} [dataset] DataSource dataset
         * @property {release_policy_api.IComputeResultDataSource|null} [computeResult] DataSource computeResult
         */

        /**
         * Constructs a new DataSource.
         * @memberof release_policy_api
         * @classdesc Represents a DataSource.
         * @implements IDataSource
         * @constructor
         * @param {release_policy_api.IDataSource=} [properties] Properties to set
         */
        function DataSource(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DataSource dataset.
         * @member {release_policy_api.IDatasetDataSource|null|undefined} dataset
         * @memberof release_policy_api.DataSource
         * @instance
         */
        DataSource.prototype.dataset = null;

        /**
         * DataSource computeResult.
         * @member {release_policy_api.IComputeResultDataSource|null|undefined} computeResult
         * @memberof release_policy_api.DataSource
         * @instance
         */
        DataSource.prototype.computeResult = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * DataSource kind.
         * @member {"dataset"|"computeResult"|undefined} kind
         * @memberof release_policy_api.DataSource
         * @instance
         */
        Object.defineProperty(DataSource.prototype, "kind", {
            get: $util.oneOfGetter($oneOfFields = ["dataset", "computeResult"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new DataSource instance using the specified properties.
         * @function create
         * @memberof release_policy_api.DataSource
         * @static
         * @param {release_policy_api.IDataSource=} [properties] Properties to set
         * @returns {release_policy_api.DataSource} DataSource instance
         */
        DataSource.create = function create(properties) {
            return new DataSource(properties);
        };

        /**
         * Encodes the specified DataSource message. Does not implicitly {@link release_policy_api.DataSource.verify|verify} messages.
         * @function encode
         * @memberof release_policy_api.DataSource
         * @static
         * @param {release_policy_api.IDataSource} message DataSource message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataSource.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dataset != null && Object.hasOwnProperty.call(message, "dataset"))
                $root.release_policy_api.DatasetDataSource.encode(message.dataset, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.computeResult != null && Object.hasOwnProperty.call(message, "computeResult"))
                $root.release_policy_api.ComputeResultDataSource.encode(message.computeResult, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DataSource message, length delimited. Does not implicitly {@link release_policy_api.DataSource.verify|verify} messages.
         * @function encodeDelimited
         * @memberof release_policy_api.DataSource
         * @static
         * @param {release_policy_api.IDataSource} message DataSource message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataSource.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DataSource message from the specified reader or buffer.
         * @function decode
         * @memberof release_policy_api.DataSource
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {release_policy_api.DataSource} DataSource
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataSource.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.release_policy_api.DataSource();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dataset = $root.release_policy_api.DatasetDataSource.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.computeResult = $root.release_policy_api.ComputeResultDataSource.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DataSource message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof release_policy_api.DataSource
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {release_policy_api.DataSource} DataSource
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataSource.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DataSource message.
         * @function verify
         * @memberof release_policy_api.DataSource
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DataSource.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.dataset != null && message.hasOwnProperty("dataset")) {
                properties.kind = 1;
                {
                    let error = $root.release_policy_api.DatasetDataSource.verify(message.dataset);
                    if (error)
                        return "dataset." + error;
                }
            }
            if (message.computeResult != null && message.hasOwnProperty("computeResult")) {
                if (properties.kind === 1)
                    return "kind: multiple values";
                properties.kind = 1;
                {
                    let error = $root.release_policy_api.ComputeResultDataSource.verify(message.computeResult);
                    if (error)
                        return "computeResult." + error;
                }
            }
            return null;
        };

        /**
         * Creates a DataSource message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof release_policy_api.DataSource
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {release_policy_api.DataSource} DataSource
         */
        DataSource.fromObject = function fromObject(object) {
            if (object instanceof $root.release_policy_api.DataSource)
                return object;
            let message = new $root.release_policy_api.DataSource();
            if (object.dataset != null) {
                if (typeof object.dataset !== "object")
                    throw TypeError(".release_policy_api.DataSource.dataset: object expected");
                message.dataset = $root.release_policy_api.DatasetDataSource.fromObject(object.dataset);
            }
            if (object.computeResult != null) {
                if (typeof object.computeResult !== "object")
                    throw TypeError(".release_policy_api.DataSource.computeResult: object expected");
                message.computeResult = $root.release_policy_api.ComputeResultDataSource.fromObject(object.computeResult);
            }
            return message;
        };

        /**
         * Creates a plain object from a DataSource message. Also converts values to other types if specified.
         * @function toObject
         * @memberof release_policy_api.DataSource
         * @static
         * @param {release_policy_api.DataSource} message DataSource
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DataSource.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.dataset != null && message.hasOwnProperty("dataset")) {
                object.dataset = $root.release_policy_api.DatasetDataSource.toObject(message.dataset, options);
                if (options.oneofs)
                    object.kind = "dataset";
            }
            if (message.computeResult != null && message.hasOwnProperty("computeResult")) {
                object.computeResult = $root.release_policy_api.ComputeResultDataSource.toObject(message.computeResult, options);
                if (options.oneofs)
                    object.kind = "computeResult";
            }
            return object;
        };

        /**
         * Converts this DataSource to JSON.
         * @function toJSON
         * @memberof release_policy_api.DataSource
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DataSource.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DataSource
         * @function getTypeUrl
         * @memberof release_policy_api.DataSource
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DataSource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/release_policy_api.DataSource";
        };

        return DataSource;
    })();

    release_policy_api.DatasetDataSource = (function() {

        /**
         * Properties of a DatasetDataSource.
         * @memberof release_policy_api
         * @interface IDatasetDataSource
         * @property {string|null} [manifestHash] DatasetDataSource manifestHash
         */

        /**
         * Constructs a new DatasetDataSource.
         * @memberof release_policy_api
         * @classdesc Represents a DatasetDataSource.
         * @implements IDatasetDataSource
         * @constructor
         * @param {release_policy_api.IDatasetDataSource=} [properties] Properties to set
         */
        function DatasetDataSource(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DatasetDataSource manifestHash.
         * @member {string} manifestHash
         * @memberof release_policy_api.DatasetDataSource
         * @instance
         */
        DatasetDataSource.prototype.manifestHash = "";

        /**
         * Creates a new DatasetDataSource instance using the specified properties.
         * @function create
         * @memberof release_policy_api.DatasetDataSource
         * @static
         * @param {release_policy_api.IDatasetDataSource=} [properties] Properties to set
         * @returns {release_policy_api.DatasetDataSource} DatasetDataSource instance
         */
        DatasetDataSource.create = function create(properties) {
            return new DatasetDataSource(properties);
        };

        /**
         * Encodes the specified DatasetDataSource message. Does not implicitly {@link release_policy_api.DatasetDataSource.verify|verify} messages.
         * @function encode
         * @memberof release_policy_api.DatasetDataSource
         * @static
         * @param {release_policy_api.IDatasetDataSource} message DatasetDataSource message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DatasetDataSource.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.manifestHash != null && Object.hasOwnProperty.call(message, "manifestHash"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.manifestHash);
            return writer;
        };

        /**
         * Encodes the specified DatasetDataSource message, length delimited. Does not implicitly {@link release_policy_api.DatasetDataSource.verify|verify} messages.
         * @function encodeDelimited
         * @memberof release_policy_api.DatasetDataSource
         * @static
         * @param {release_policy_api.IDatasetDataSource} message DatasetDataSource message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DatasetDataSource.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DatasetDataSource message from the specified reader or buffer.
         * @function decode
         * @memberof release_policy_api.DatasetDataSource
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {release_policy_api.DatasetDataSource} DatasetDataSource
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DatasetDataSource.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.release_policy_api.DatasetDataSource();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.manifestHash = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DatasetDataSource message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof release_policy_api.DatasetDataSource
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {release_policy_api.DatasetDataSource} DatasetDataSource
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DatasetDataSource.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DatasetDataSource message.
         * @function verify
         * @memberof release_policy_api.DatasetDataSource
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DatasetDataSource.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.manifestHash != null && message.hasOwnProperty("manifestHash"))
                if (!$util.isString(message.manifestHash))
                    return "manifestHash: string expected";
            return null;
        };

        /**
         * Creates a DatasetDataSource message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof release_policy_api.DatasetDataSource
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {release_policy_api.DatasetDataSource} DatasetDataSource
         */
        DatasetDataSource.fromObject = function fromObject(object) {
            if (object instanceof $root.release_policy_api.DatasetDataSource)
                return object;
            let message = new $root.release_policy_api.DatasetDataSource();
            if (object.manifestHash != null)
                message.manifestHash = String(object.manifestHash);
            return message;
        };

        /**
         * Creates a plain object from a DatasetDataSource message. Also converts values to other types if specified.
         * @function toObject
         * @memberof release_policy_api.DatasetDataSource
         * @static
         * @param {release_policy_api.DatasetDataSource} message DatasetDataSource
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DatasetDataSource.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.manifestHash = "";
            if (message.manifestHash != null && message.hasOwnProperty("manifestHash"))
                object.manifestHash = message.manifestHash;
            return object;
        };

        /**
         * Converts this DatasetDataSource to JSON.
         * @function toJSON
         * @memberof release_policy_api.DatasetDataSource
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DatasetDataSource.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DatasetDataSource
         * @function getTypeUrl
         * @memberof release_policy_api.DatasetDataSource
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DatasetDataSource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/release_policy_api.DatasetDataSource";
        };

        return DatasetDataSource;
    })();

    release_policy_api.ComputeResultDataSource = (function() {

        /**
         * Properties of a ComputeResultDataSource.
         * @memberof release_policy_api
         * @interface IComputeResultDataSource
         * @property {string|null} [resultHash] ComputeResultDataSource resultHash
         */

        /**
         * Constructs a new ComputeResultDataSource.
         * @memberof release_policy_api
         * @classdesc Represents a ComputeResultDataSource.
         * @implements IComputeResultDataSource
         * @constructor
         * @param {release_policy_api.IComputeResultDataSource=} [properties] Properties to set
         */
        function ComputeResultDataSource(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ComputeResultDataSource resultHash.
         * @member {string} resultHash
         * @memberof release_policy_api.ComputeResultDataSource
         * @instance
         */
        ComputeResultDataSource.prototype.resultHash = "";

        /**
         * Creates a new ComputeResultDataSource instance using the specified properties.
         * @function create
         * @memberof release_policy_api.ComputeResultDataSource
         * @static
         * @param {release_policy_api.IComputeResultDataSource=} [properties] Properties to set
         * @returns {release_policy_api.ComputeResultDataSource} ComputeResultDataSource instance
         */
        ComputeResultDataSource.create = function create(properties) {
            return new ComputeResultDataSource(properties);
        };

        /**
         * Encodes the specified ComputeResultDataSource message. Does not implicitly {@link release_policy_api.ComputeResultDataSource.verify|verify} messages.
         * @function encode
         * @memberof release_policy_api.ComputeResultDataSource
         * @static
         * @param {release_policy_api.IComputeResultDataSource} message ComputeResultDataSource message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComputeResultDataSource.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.resultHash != null && Object.hasOwnProperty.call(message, "resultHash"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.resultHash);
            return writer;
        };

        /**
         * Encodes the specified ComputeResultDataSource message, length delimited. Does not implicitly {@link release_policy_api.ComputeResultDataSource.verify|verify} messages.
         * @function encodeDelimited
         * @memberof release_policy_api.ComputeResultDataSource
         * @static
         * @param {release_policy_api.IComputeResultDataSource} message ComputeResultDataSource message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComputeResultDataSource.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ComputeResultDataSource message from the specified reader or buffer.
         * @function decode
         * @memberof release_policy_api.ComputeResultDataSource
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {release_policy_api.ComputeResultDataSource} ComputeResultDataSource
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComputeResultDataSource.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.release_policy_api.ComputeResultDataSource();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.resultHash = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ComputeResultDataSource message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof release_policy_api.ComputeResultDataSource
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {release_policy_api.ComputeResultDataSource} ComputeResultDataSource
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComputeResultDataSource.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ComputeResultDataSource message.
         * @function verify
         * @memberof release_policy_api.ComputeResultDataSource
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ComputeResultDataSource.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.resultHash != null && message.hasOwnProperty("resultHash"))
                if (!$util.isString(message.resultHash))
                    return "resultHash: string expected";
            return null;
        };

        /**
         * Creates a ComputeResultDataSource message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof release_policy_api.ComputeResultDataSource
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {release_policy_api.ComputeResultDataSource} ComputeResultDataSource
         */
        ComputeResultDataSource.fromObject = function fromObject(object) {
            if (object instanceof $root.release_policy_api.ComputeResultDataSource)
                return object;
            let message = new $root.release_policy_api.ComputeResultDataSource();
            if (object.resultHash != null)
                message.resultHash = String(object.resultHash);
            return message;
        };

        /**
         * Creates a plain object from a ComputeResultDataSource message. Also converts values to other types if specified.
         * @function toObject
         * @memberof release_policy_api.ComputeResultDataSource
         * @static
         * @param {release_policy_api.ComputeResultDataSource} message ComputeResultDataSource
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ComputeResultDataSource.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.resultHash = "";
            if (message.resultHash != null && message.hasOwnProperty("resultHash"))
                object.resultHash = message.resultHash;
            return object;
        };

        /**
         * Converts this ComputeResultDataSource to JSON.
         * @function toJSON
         * @memberof release_policy_api.ComputeResultDataSource
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ComputeResultDataSource.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ComputeResultDataSource
         * @function getTypeUrl
         * @memberof release_policy_api.ComputeResultDataSource
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ComputeResultDataSource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/release_policy_api.ComputeResultDataSource";
        };

        return ComputeResultDataSource;
    })();

    return release_policy_api;
})();

export const secret_store_api = $root.secret_store_api = (() => {

    /**
     * Namespace secret_store_api.
     * @exports secret_store_api
     * @namespace
     */
    const secret_store_api = {};

    secret_store_api.SecretStoreEntry = (function() {

        /**
         * Properties of a SecretStoreEntry.
         * @memberof secret_store_api
         * @interface ISecretStoreEntry
         * @property {Uint8Array|null} [content] SecretStoreEntry content
         * @property {Uint8Array|null} [state] SecretStoreEntry state
         */

        /**
         * Constructs a new SecretStoreEntry.
         * @memberof secret_store_api
         * @classdesc Represents a SecretStoreEntry.
         * @implements ISecretStoreEntry
         * @constructor
         * @param {secret_store_api.ISecretStoreEntry=} [properties] Properties to set
         */
        function SecretStoreEntry(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SecretStoreEntry content.
         * @member {Uint8Array} content
         * @memberof secret_store_api.SecretStoreEntry
         * @instance
         */
        SecretStoreEntry.prototype.content = $util.newBuffer([]);

        /**
         * SecretStoreEntry state.
         * @member {Uint8Array} state
         * @memberof secret_store_api.SecretStoreEntry
         * @instance
         */
        SecretStoreEntry.prototype.state = $util.newBuffer([]);

        /**
         * Creates a new SecretStoreEntry instance using the specified properties.
         * @function create
         * @memberof secret_store_api.SecretStoreEntry
         * @static
         * @param {secret_store_api.ISecretStoreEntry=} [properties] Properties to set
         * @returns {secret_store_api.SecretStoreEntry} SecretStoreEntry instance
         */
        SecretStoreEntry.create = function create(properties) {
            return new SecretStoreEntry(properties);
        };

        /**
         * Encodes the specified SecretStoreEntry message. Does not implicitly {@link secret_store_api.SecretStoreEntry.verify|verify} messages.
         * @function encode
         * @memberof secret_store_api.SecretStoreEntry
         * @static
         * @param {secret_store_api.ISecretStoreEntry} message SecretStoreEntry message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SecretStoreEntry.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.content);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.state);
            return writer;
        };

        /**
         * Encodes the specified SecretStoreEntry message, length delimited. Does not implicitly {@link secret_store_api.SecretStoreEntry.verify|verify} messages.
         * @function encodeDelimited
         * @memberof secret_store_api.SecretStoreEntry
         * @static
         * @param {secret_store_api.ISecretStoreEntry} message SecretStoreEntry message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SecretStoreEntry.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SecretStoreEntry message from the specified reader or buffer.
         * @function decode
         * @memberof secret_store_api.SecretStoreEntry
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {secret_store_api.SecretStoreEntry} SecretStoreEntry
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SecretStoreEntry.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.secret_store_api.SecretStoreEntry();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.content = reader.bytes();
                        break;
                    }
                case 2: {
                        message.state = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SecretStoreEntry message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof secret_store_api.SecretStoreEntry
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {secret_store_api.SecretStoreEntry} SecretStoreEntry
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SecretStoreEntry.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SecretStoreEntry message.
         * @function verify
         * @memberof secret_store_api.SecretStoreEntry
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SecretStoreEntry.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.content != null && message.hasOwnProperty("content"))
                if (!(message.content && typeof message.content.length === "number" || $util.isString(message.content)))
                    return "content: buffer expected";
            if (message.state != null && message.hasOwnProperty("state"))
                if (!(message.state && typeof message.state.length === "number" || $util.isString(message.state)))
                    return "state: buffer expected";
            return null;
        };

        /**
         * Creates a SecretStoreEntry message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof secret_store_api.SecretStoreEntry
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {secret_store_api.SecretStoreEntry} SecretStoreEntry
         */
        SecretStoreEntry.fromObject = function fromObject(object) {
            if (object instanceof $root.secret_store_api.SecretStoreEntry)
                return object;
            let message = new $root.secret_store_api.SecretStoreEntry();
            if (object.content != null)
                if (typeof object.content === "string")
                    $util.base64.decode(object.content, message.content = $util.newBuffer($util.base64.length(object.content)), 0);
                else if (object.content.length >= 0)
                    message.content = object.content;
            if (object.state != null)
                if (typeof object.state === "string")
                    $util.base64.decode(object.state, message.state = $util.newBuffer($util.base64.length(object.state)), 0);
                else if (object.state.length >= 0)
                    message.state = object.state;
            return message;
        };

        /**
         * Creates a plain object from a SecretStoreEntry message. Also converts values to other types if specified.
         * @function toObject
         * @memberof secret_store_api.SecretStoreEntry
         * @static
         * @param {secret_store_api.SecretStoreEntry} message SecretStoreEntry
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SecretStoreEntry.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.content = "";
                else {
                    object.content = [];
                    if (options.bytes !== Array)
                        object.content = $util.newBuffer(object.content);
                }
                if (options.bytes === String)
                    object.state = "";
                else {
                    object.state = [];
                    if (options.bytes !== Array)
                        object.state = $util.newBuffer(object.state);
                }
            }
            if (message.content != null && message.hasOwnProperty("content"))
                object.content = options.bytes === String ? $util.base64.encode(message.content, 0, message.content.length) : options.bytes === Array ? Array.prototype.slice.call(message.content) : message.content;
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = options.bytes === String ? $util.base64.encode(message.state, 0, message.state.length) : options.bytes === Array ? Array.prototype.slice.call(message.state) : message.state;
            return object;
        };

        /**
         * Converts this SecretStoreEntry to JSON.
         * @function toJSON
         * @memberof secret_store_api.SecretStoreEntry
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SecretStoreEntry.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SecretStoreEntry
         * @function getTypeUrl
         * @memberof secret_store_api.SecretStoreEntry
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SecretStoreEntry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/secret_store_api.SecretStoreEntry";
        };

        return SecretStoreEntry;
    })();

    secret_store_api.CreateSecretRequest = (function() {

        /**
         * Properties of a CreateSecretRequest.
         * @memberof secret_store_api
         * @interface ICreateSecretRequest
         * @property {secret_store_api.ISecretStoreEntry|null} [secret] CreateSecretRequest secret
         */

        /**
         * Constructs a new CreateSecretRequest.
         * @memberof secret_store_api
         * @classdesc Add a new secret to the store
         * @implements ICreateSecretRequest
         * @constructor
         * @param {secret_store_api.ICreateSecretRequest=} [properties] Properties to set
         */
        function CreateSecretRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateSecretRequest secret.
         * @member {secret_store_api.ISecretStoreEntry|null|undefined} secret
         * @memberof secret_store_api.CreateSecretRequest
         * @instance
         */
        CreateSecretRequest.prototype.secret = null;

        /**
         * Creates a new CreateSecretRequest instance using the specified properties.
         * @function create
         * @memberof secret_store_api.CreateSecretRequest
         * @static
         * @param {secret_store_api.ICreateSecretRequest=} [properties] Properties to set
         * @returns {secret_store_api.CreateSecretRequest} CreateSecretRequest instance
         */
        CreateSecretRequest.create = function create(properties) {
            return new CreateSecretRequest(properties);
        };

        /**
         * Encodes the specified CreateSecretRequest message. Does not implicitly {@link secret_store_api.CreateSecretRequest.verify|verify} messages.
         * @function encode
         * @memberof secret_store_api.CreateSecretRequest
         * @static
         * @param {secret_store_api.ICreateSecretRequest} message CreateSecretRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateSecretRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.secret != null && Object.hasOwnProperty.call(message, "secret"))
                $root.secret_store_api.SecretStoreEntry.encode(message.secret, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateSecretRequest message, length delimited. Does not implicitly {@link secret_store_api.CreateSecretRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof secret_store_api.CreateSecretRequest
         * @static
         * @param {secret_store_api.ICreateSecretRequest} message CreateSecretRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateSecretRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateSecretRequest message from the specified reader or buffer.
         * @function decode
         * @memberof secret_store_api.CreateSecretRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {secret_store_api.CreateSecretRequest} CreateSecretRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateSecretRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.secret_store_api.CreateSecretRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.secret = $root.secret_store_api.SecretStoreEntry.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateSecretRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof secret_store_api.CreateSecretRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {secret_store_api.CreateSecretRequest} CreateSecretRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateSecretRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateSecretRequest message.
         * @function verify
         * @memberof secret_store_api.CreateSecretRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateSecretRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.secret != null && message.hasOwnProperty("secret")) {
                let error = $root.secret_store_api.SecretStoreEntry.verify(message.secret);
                if (error)
                    return "secret." + error;
            }
            return null;
        };

        /**
         * Creates a CreateSecretRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof secret_store_api.CreateSecretRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {secret_store_api.CreateSecretRequest} CreateSecretRequest
         */
        CreateSecretRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.secret_store_api.CreateSecretRequest)
                return object;
            let message = new $root.secret_store_api.CreateSecretRequest();
            if (object.secret != null) {
                if (typeof object.secret !== "object")
                    throw TypeError(".secret_store_api.CreateSecretRequest.secret: object expected");
                message.secret = $root.secret_store_api.SecretStoreEntry.fromObject(object.secret);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateSecretRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof secret_store_api.CreateSecretRequest
         * @static
         * @param {secret_store_api.CreateSecretRequest} message CreateSecretRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateSecretRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.secret = null;
            if (message.secret != null && message.hasOwnProperty("secret"))
                object.secret = $root.secret_store_api.SecretStoreEntry.toObject(message.secret, options);
            return object;
        };

        /**
         * Converts this CreateSecretRequest to JSON.
         * @function toJSON
         * @memberof secret_store_api.CreateSecretRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateSecretRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateSecretRequest
         * @function getTypeUrl
         * @memberof secret_store_api.CreateSecretRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateSecretRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/secret_store_api.CreateSecretRequest";
        };

        return CreateSecretRequest;
    })();

    secret_store_api.CreateSecretResponse = (function() {

        /**
         * Properties of a CreateSecretResponse.
         * @memberof secret_store_api
         * @interface ICreateSecretResponse
         * @property {string|null} [id] CreateSecretResponse id
         */

        /**
         * Constructs a new CreateSecretResponse.
         * @memberof secret_store_api
         * @classdesc Represents a CreateSecretResponse.
         * @implements ICreateSecretResponse
         * @constructor
         * @param {secret_store_api.ICreateSecretResponse=} [properties] Properties to set
         */
        function CreateSecretResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateSecretResponse id.
         * @member {string} id
         * @memberof secret_store_api.CreateSecretResponse
         * @instance
         */
        CreateSecretResponse.prototype.id = "";

        /**
         * Creates a new CreateSecretResponse instance using the specified properties.
         * @function create
         * @memberof secret_store_api.CreateSecretResponse
         * @static
         * @param {secret_store_api.ICreateSecretResponse=} [properties] Properties to set
         * @returns {secret_store_api.CreateSecretResponse} CreateSecretResponse instance
         */
        CreateSecretResponse.create = function create(properties) {
            return new CreateSecretResponse(properties);
        };

        /**
         * Encodes the specified CreateSecretResponse message. Does not implicitly {@link secret_store_api.CreateSecretResponse.verify|verify} messages.
         * @function encode
         * @memberof secret_store_api.CreateSecretResponse
         * @static
         * @param {secret_store_api.ICreateSecretResponse} message CreateSecretResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateSecretResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified CreateSecretResponse message, length delimited. Does not implicitly {@link secret_store_api.CreateSecretResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof secret_store_api.CreateSecretResponse
         * @static
         * @param {secret_store_api.ICreateSecretResponse} message CreateSecretResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateSecretResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateSecretResponse message from the specified reader or buffer.
         * @function decode
         * @memberof secret_store_api.CreateSecretResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {secret_store_api.CreateSecretResponse} CreateSecretResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateSecretResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.secret_store_api.CreateSecretResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateSecretResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof secret_store_api.CreateSecretResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {secret_store_api.CreateSecretResponse} CreateSecretResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateSecretResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateSecretResponse message.
         * @function verify
         * @memberof secret_store_api.CreateSecretResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateSecretResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates a CreateSecretResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof secret_store_api.CreateSecretResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {secret_store_api.CreateSecretResponse} CreateSecretResponse
         */
        CreateSecretResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.secret_store_api.CreateSecretResponse)
                return object;
            let message = new $root.secret_store_api.CreateSecretResponse();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a CreateSecretResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof secret_store_api.CreateSecretResponse
         * @static
         * @param {secret_store_api.CreateSecretResponse} message CreateSecretResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateSecretResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this CreateSecretResponse to JSON.
         * @function toJSON
         * @memberof secret_store_api.CreateSecretResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateSecretResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateSecretResponse
         * @function getTypeUrl
         * @memberof secret_store_api.CreateSecretResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateSecretResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/secret_store_api.CreateSecretResponse";
        };

        return CreateSecretResponse;
    })();

    secret_store_api.GetSecretRequest = (function() {

        /**
         * Properties of a GetSecretRequest.
         * @memberof secret_store_api
         * @interface IGetSecretRequest
         * @property {string|null} [id] GetSecretRequest id
         * @property {number|null} [version] GetSecretRequest version
         */

        /**
         * Constructs a new GetSecretRequest.
         * @memberof secret_store_api
         * @classdesc Get a single secret from the secret store
         * @implements IGetSecretRequest
         * @constructor
         * @param {secret_store_api.IGetSecretRequest=} [properties] Properties to set
         */
        function GetSecretRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetSecretRequest id.
         * @member {string} id
         * @memberof secret_store_api.GetSecretRequest
         * @instance
         */
        GetSecretRequest.prototype.id = "";

        /**
         * GetSecretRequest version.
         * @member {number} version
         * @memberof secret_store_api.GetSecretRequest
         * @instance
         */
        GetSecretRequest.prototype.version = 0;

        /**
         * Creates a new GetSecretRequest instance using the specified properties.
         * @function create
         * @memberof secret_store_api.GetSecretRequest
         * @static
         * @param {secret_store_api.IGetSecretRequest=} [properties] Properties to set
         * @returns {secret_store_api.GetSecretRequest} GetSecretRequest instance
         */
        GetSecretRequest.create = function create(properties) {
            return new GetSecretRequest(properties);
        };

        /**
         * Encodes the specified GetSecretRequest message. Does not implicitly {@link secret_store_api.GetSecretRequest.verify|verify} messages.
         * @function encode
         * @memberof secret_store_api.GetSecretRequest
         * @static
         * @param {secret_store_api.IGetSecretRequest} message GetSecretRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetSecretRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.version);
            return writer;
        };

        /**
         * Encodes the specified GetSecretRequest message, length delimited. Does not implicitly {@link secret_store_api.GetSecretRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof secret_store_api.GetSecretRequest
         * @static
         * @param {secret_store_api.IGetSecretRequest} message GetSecretRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetSecretRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetSecretRequest message from the specified reader or buffer.
         * @function decode
         * @memberof secret_store_api.GetSecretRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {secret_store_api.GetSecretRequest} GetSecretRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetSecretRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.secret_store_api.GetSecretRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.version = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetSecretRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof secret_store_api.GetSecretRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {secret_store_api.GetSecretRequest} GetSecretRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetSecretRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetSecretRequest message.
         * @function verify
         * @memberof secret_store_api.GetSecretRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetSecretRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isInteger(message.version))
                    return "version: integer expected";
            return null;
        };

        /**
         * Creates a GetSecretRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof secret_store_api.GetSecretRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {secret_store_api.GetSecretRequest} GetSecretRequest
         */
        GetSecretRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.secret_store_api.GetSecretRequest)
                return object;
            let message = new $root.secret_store_api.GetSecretRequest();
            if (object.id != null)
                message.id = String(object.id);
            if (object.version != null)
                message.version = object.version >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a GetSecretRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof secret_store_api.GetSecretRequest
         * @static
         * @param {secret_store_api.GetSecretRequest} message GetSecretRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetSecretRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.version = 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.version != null && message.hasOwnProperty("version"))
                object.version = message.version;
            return object;
        };

        /**
         * Converts this GetSecretRequest to JSON.
         * @function toJSON
         * @memberof secret_store_api.GetSecretRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetSecretRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetSecretRequest
         * @function getTypeUrl
         * @memberof secret_store_api.GetSecretRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetSecretRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/secret_store_api.GetSecretRequest";
        };

        return GetSecretRequest;
    })();

    secret_store_api.GetSecretResponse = (function() {

        /**
         * Properties of a GetSecretResponse.
         * @memberof secret_store_api
         * @interface IGetSecretResponse
         * @property {secret_store_api.ISecretStoreEntry|null} [secret] GetSecretResponse secret
         * @property {number|Long|null} [casIndex] GetSecretResponse casIndex
         */

        /**
         * Constructs a new GetSecretResponse.
         * @memberof secret_store_api
         * @classdesc Represents a GetSecretResponse.
         * @implements IGetSecretResponse
         * @constructor
         * @param {secret_store_api.IGetSecretResponse=} [properties] Properties to set
         */
        function GetSecretResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetSecretResponse secret.
         * @member {secret_store_api.ISecretStoreEntry|null|undefined} secret
         * @memberof secret_store_api.GetSecretResponse
         * @instance
         */
        GetSecretResponse.prototype.secret = null;

        /**
         * GetSecretResponse casIndex.
         * @member {number|Long} casIndex
         * @memberof secret_store_api.GetSecretResponse
         * @instance
         */
        GetSecretResponse.prototype.casIndex = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new GetSecretResponse instance using the specified properties.
         * @function create
         * @memberof secret_store_api.GetSecretResponse
         * @static
         * @param {secret_store_api.IGetSecretResponse=} [properties] Properties to set
         * @returns {secret_store_api.GetSecretResponse} GetSecretResponse instance
         */
        GetSecretResponse.create = function create(properties) {
            return new GetSecretResponse(properties);
        };

        /**
         * Encodes the specified GetSecretResponse message. Does not implicitly {@link secret_store_api.GetSecretResponse.verify|verify} messages.
         * @function encode
         * @memberof secret_store_api.GetSecretResponse
         * @static
         * @param {secret_store_api.IGetSecretResponse} message GetSecretResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetSecretResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.secret != null && Object.hasOwnProperty.call(message, "secret"))
                $root.secret_store_api.SecretStoreEntry.encode(message.secret, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.casIndex != null && Object.hasOwnProperty.call(message, "casIndex"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.casIndex);
            return writer;
        };

        /**
         * Encodes the specified GetSecretResponse message, length delimited. Does not implicitly {@link secret_store_api.GetSecretResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof secret_store_api.GetSecretResponse
         * @static
         * @param {secret_store_api.IGetSecretResponse} message GetSecretResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetSecretResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetSecretResponse message from the specified reader or buffer.
         * @function decode
         * @memberof secret_store_api.GetSecretResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {secret_store_api.GetSecretResponse} GetSecretResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetSecretResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.secret_store_api.GetSecretResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.secret = $root.secret_store_api.SecretStoreEntry.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.casIndex = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetSecretResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof secret_store_api.GetSecretResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {secret_store_api.GetSecretResponse} GetSecretResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetSecretResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetSecretResponse message.
         * @function verify
         * @memberof secret_store_api.GetSecretResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetSecretResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.secret != null && message.hasOwnProperty("secret")) {
                let error = $root.secret_store_api.SecretStoreEntry.verify(message.secret);
                if (error)
                    return "secret." + error;
            }
            if (message.casIndex != null && message.hasOwnProperty("casIndex"))
                if (!$util.isInteger(message.casIndex) && !(message.casIndex && $util.isInteger(message.casIndex.low) && $util.isInteger(message.casIndex.high)))
                    return "casIndex: integer|Long expected";
            return null;
        };

        /**
         * Creates a GetSecretResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof secret_store_api.GetSecretResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {secret_store_api.GetSecretResponse} GetSecretResponse
         */
        GetSecretResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.secret_store_api.GetSecretResponse)
                return object;
            let message = new $root.secret_store_api.GetSecretResponse();
            if (object.secret != null) {
                if (typeof object.secret !== "object")
                    throw TypeError(".secret_store_api.GetSecretResponse.secret: object expected");
                message.secret = $root.secret_store_api.SecretStoreEntry.fromObject(object.secret);
            }
            if (object.casIndex != null)
                if ($util.Long)
                    (message.casIndex = $util.Long.fromValue(object.casIndex)).unsigned = true;
                else if (typeof object.casIndex === "string")
                    message.casIndex = parseInt(object.casIndex, 10);
                else if (typeof object.casIndex === "number")
                    message.casIndex = object.casIndex;
                else if (typeof object.casIndex === "object")
                    message.casIndex = new $util.LongBits(object.casIndex.low >>> 0, object.casIndex.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a GetSecretResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof secret_store_api.GetSecretResponse
         * @static
         * @param {secret_store_api.GetSecretResponse} message GetSecretResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetSecretResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.secret = null;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.casIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.casIndex = options.longs === String ? "0" : 0;
            }
            if (message.secret != null && message.hasOwnProperty("secret"))
                object.secret = $root.secret_store_api.SecretStoreEntry.toObject(message.secret, options);
            if (message.casIndex != null && message.hasOwnProperty("casIndex"))
                if (typeof message.casIndex === "number")
                    object.casIndex = options.longs === String ? String(message.casIndex) : message.casIndex;
                else
                    object.casIndex = options.longs === String ? $util.Long.prototype.toString.call(message.casIndex) : options.longs === Number ? new $util.LongBits(message.casIndex.low >>> 0, message.casIndex.high >>> 0).toNumber(true) : message.casIndex;
            return object;
        };

        /**
         * Converts this GetSecretResponse to JSON.
         * @function toJSON
         * @memberof secret_store_api.GetSecretResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetSecretResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetSecretResponse
         * @function getTypeUrl
         * @memberof secret_store_api.GetSecretResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetSecretResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/secret_store_api.GetSecretResponse";
        };

        return GetSecretResponse;
    })();

    secret_store_api.RemoveSecretRequest = (function() {

        /**
         * Properties of a RemoveSecretRequest.
         * @memberof secret_store_api
         * @interface IRemoveSecretRequest
         * @property {string|null} [id] RemoveSecretRequest id
         * @property {number|Long|null} [expectedCasIndex] RemoveSecretRequest expectedCasIndex
         */

        /**
         * Constructs a new RemoveSecretRequest.
         * @memberof secret_store_api
         * @classdesc Remove a particular secret
         * @implements IRemoveSecretRequest
         * @constructor
         * @param {secret_store_api.IRemoveSecretRequest=} [properties] Properties to set
         */
        function RemoveSecretRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RemoveSecretRequest id.
         * @member {string} id
         * @memberof secret_store_api.RemoveSecretRequest
         * @instance
         */
        RemoveSecretRequest.prototype.id = "";

        /**
         * RemoveSecretRequest expectedCasIndex.
         * @member {number|Long} expectedCasIndex
         * @memberof secret_store_api.RemoveSecretRequest
         * @instance
         */
        RemoveSecretRequest.prototype.expectedCasIndex = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new RemoveSecretRequest instance using the specified properties.
         * @function create
         * @memberof secret_store_api.RemoveSecretRequest
         * @static
         * @param {secret_store_api.IRemoveSecretRequest=} [properties] Properties to set
         * @returns {secret_store_api.RemoveSecretRequest} RemoveSecretRequest instance
         */
        RemoveSecretRequest.create = function create(properties) {
            return new RemoveSecretRequest(properties);
        };

        /**
         * Encodes the specified RemoveSecretRequest message. Does not implicitly {@link secret_store_api.RemoveSecretRequest.verify|verify} messages.
         * @function encode
         * @memberof secret_store_api.RemoveSecretRequest
         * @static
         * @param {secret_store_api.IRemoveSecretRequest} message RemoveSecretRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveSecretRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.expectedCasIndex != null && Object.hasOwnProperty.call(message, "expectedCasIndex"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.expectedCasIndex);
            return writer;
        };

        /**
         * Encodes the specified RemoveSecretRequest message, length delimited. Does not implicitly {@link secret_store_api.RemoveSecretRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof secret_store_api.RemoveSecretRequest
         * @static
         * @param {secret_store_api.IRemoveSecretRequest} message RemoveSecretRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveSecretRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RemoveSecretRequest message from the specified reader or buffer.
         * @function decode
         * @memberof secret_store_api.RemoveSecretRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {secret_store_api.RemoveSecretRequest} RemoveSecretRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveSecretRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.secret_store_api.RemoveSecretRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.expectedCasIndex = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RemoveSecretRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof secret_store_api.RemoveSecretRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {secret_store_api.RemoveSecretRequest} RemoveSecretRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveSecretRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RemoveSecretRequest message.
         * @function verify
         * @memberof secret_store_api.RemoveSecretRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RemoveSecretRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.expectedCasIndex != null && message.hasOwnProperty("expectedCasIndex"))
                if (!$util.isInteger(message.expectedCasIndex) && !(message.expectedCasIndex && $util.isInteger(message.expectedCasIndex.low) && $util.isInteger(message.expectedCasIndex.high)))
                    return "expectedCasIndex: integer|Long expected";
            return null;
        };

        /**
         * Creates a RemoveSecretRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof secret_store_api.RemoveSecretRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {secret_store_api.RemoveSecretRequest} RemoveSecretRequest
         */
        RemoveSecretRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.secret_store_api.RemoveSecretRequest)
                return object;
            let message = new $root.secret_store_api.RemoveSecretRequest();
            if (object.id != null)
                message.id = String(object.id);
            if (object.expectedCasIndex != null)
                if ($util.Long)
                    (message.expectedCasIndex = $util.Long.fromValue(object.expectedCasIndex)).unsigned = true;
                else if (typeof object.expectedCasIndex === "string")
                    message.expectedCasIndex = parseInt(object.expectedCasIndex, 10);
                else if (typeof object.expectedCasIndex === "number")
                    message.expectedCasIndex = object.expectedCasIndex;
                else if (typeof object.expectedCasIndex === "object")
                    message.expectedCasIndex = new $util.LongBits(object.expectedCasIndex.low >>> 0, object.expectedCasIndex.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a RemoveSecretRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof secret_store_api.RemoveSecretRequest
         * @static
         * @param {secret_store_api.RemoveSecretRequest} message RemoveSecretRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RemoveSecretRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.expectedCasIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.expectedCasIndex = options.longs === String ? "0" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.expectedCasIndex != null && message.hasOwnProperty("expectedCasIndex"))
                if (typeof message.expectedCasIndex === "number")
                    object.expectedCasIndex = options.longs === String ? String(message.expectedCasIndex) : message.expectedCasIndex;
                else
                    object.expectedCasIndex = options.longs === String ? $util.Long.prototype.toString.call(message.expectedCasIndex) : options.longs === Number ? new $util.LongBits(message.expectedCasIndex.low >>> 0, message.expectedCasIndex.high >>> 0).toNumber(true) : message.expectedCasIndex;
            return object;
        };

        /**
         * Converts this RemoveSecretRequest to JSON.
         * @function toJSON
         * @memberof secret_store_api.RemoveSecretRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RemoveSecretRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RemoveSecretRequest
         * @function getTypeUrl
         * @memberof secret_store_api.RemoveSecretRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RemoveSecretRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/secret_store_api.RemoveSecretRequest";
        };

        return RemoveSecretRequest;
    })();

    secret_store_api.RemoveSecretResponse = (function() {

        /**
         * Properties of a RemoveSecretResponse.
         * @memberof secret_store_api
         * @interface IRemoveSecretResponse
         * @property {boolean|null} [removed] Removal can fail because the expected cas index doesn't match
         */

        /**
         * Constructs a new RemoveSecretResponse.
         * @memberof secret_store_api
         * @classdesc Represents a RemoveSecretResponse.
         * @implements IRemoveSecretResponse
         * @constructor
         * @param {secret_store_api.IRemoveSecretResponse=} [properties] Properties to set
         */
        function RemoveSecretResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Removal can fail because the expected cas index doesn't match
         * @member {boolean} removed
         * @memberof secret_store_api.RemoveSecretResponse
         * @instance
         */
        RemoveSecretResponse.prototype.removed = false;

        /**
         * Creates a new RemoveSecretResponse instance using the specified properties.
         * @function create
         * @memberof secret_store_api.RemoveSecretResponse
         * @static
         * @param {secret_store_api.IRemoveSecretResponse=} [properties] Properties to set
         * @returns {secret_store_api.RemoveSecretResponse} RemoveSecretResponse instance
         */
        RemoveSecretResponse.create = function create(properties) {
            return new RemoveSecretResponse(properties);
        };

        /**
         * Encodes the specified RemoveSecretResponse message. Does not implicitly {@link secret_store_api.RemoveSecretResponse.verify|verify} messages.
         * @function encode
         * @memberof secret_store_api.RemoveSecretResponse
         * @static
         * @param {secret_store_api.IRemoveSecretResponse} message RemoveSecretResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveSecretResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.removed != null && Object.hasOwnProperty.call(message, "removed"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.removed);
            return writer;
        };

        /**
         * Encodes the specified RemoveSecretResponse message, length delimited. Does not implicitly {@link secret_store_api.RemoveSecretResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof secret_store_api.RemoveSecretResponse
         * @static
         * @param {secret_store_api.IRemoveSecretResponse} message RemoveSecretResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveSecretResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RemoveSecretResponse message from the specified reader or buffer.
         * @function decode
         * @memberof secret_store_api.RemoveSecretResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {secret_store_api.RemoveSecretResponse} RemoveSecretResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveSecretResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.secret_store_api.RemoveSecretResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.removed = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RemoveSecretResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof secret_store_api.RemoveSecretResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {secret_store_api.RemoveSecretResponse} RemoveSecretResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveSecretResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RemoveSecretResponse message.
         * @function verify
         * @memberof secret_store_api.RemoveSecretResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RemoveSecretResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.removed != null && message.hasOwnProperty("removed"))
                if (typeof message.removed !== "boolean")
                    return "removed: boolean expected";
            return null;
        };

        /**
         * Creates a RemoveSecretResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof secret_store_api.RemoveSecretResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {secret_store_api.RemoveSecretResponse} RemoveSecretResponse
         */
        RemoveSecretResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.secret_store_api.RemoveSecretResponse)
                return object;
            let message = new $root.secret_store_api.RemoveSecretResponse();
            if (object.removed != null)
                message.removed = Boolean(object.removed);
            return message;
        };

        /**
         * Creates a plain object from a RemoveSecretResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof secret_store_api.RemoveSecretResponse
         * @static
         * @param {secret_store_api.RemoveSecretResponse} message RemoveSecretResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RemoveSecretResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.removed = false;
            if (message.removed != null && message.hasOwnProperty("removed"))
                object.removed = message.removed;
            return object;
        };

        /**
         * Converts this RemoveSecretResponse to JSON.
         * @function toJSON
         * @memberof secret_store_api.RemoveSecretResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RemoveSecretResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RemoveSecretResponse
         * @function getTypeUrl
         * @memberof secret_store_api.RemoveSecretResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RemoveSecretResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/secret_store_api.RemoveSecretResponse";
        };

        return RemoveSecretResponse;
    })();

    secret_store_api.UpdateSecretAclRequest = (function() {

        /**
         * Properties of an UpdateSecretAclRequest.
         * @memberof secret_store_api
         * @interface IUpdateSecretAclRequest
         * @property {string|null} [id] UpdateSecretAclRequest id
         * @property {Uint8Array|null} [newAcl] UpdateSecretAclRequest newAcl
         * @property {number|null} [version] UpdateSecretAclRequest version
         * @property {number|Long|null} [expectedCasIndex] UpdateSecretAclRequest expectedCasIndex
         */

        /**
         * Constructs a new UpdateSecretAclRequest.
         * @memberof secret_store_api
         * @classdesc Remove a particular secret
         * @implements IUpdateSecretAclRequest
         * @constructor
         * @param {secret_store_api.IUpdateSecretAclRequest=} [properties] Properties to set
         */
        function UpdateSecretAclRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateSecretAclRequest id.
         * @member {string} id
         * @memberof secret_store_api.UpdateSecretAclRequest
         * @instance
         */
        UpdateSecretAclRequest.prototype.id = "";

        /**
         * UpdateSecretAclRequest newAcl.
         * @member {Uint8Array} newAcl
         * @memberof secret_store_api.UpdateSecretAclRequest
         * @instance
         */
        UpdateSecretAclRequest.prototype.newAcl = $util.newBuffer([]);

        /**
         * UpdateSecretAclRequest version.
         * @member {number} version
         * @memberof secret_store_api.UpdateSecretAclRequest
         * @instance
         */
        UpdateSecretAclRequest.prototype.version = 0;

        /**
         * UpdateSecretAclRequest expectedCasIndex.
         * @member {number|Long} expectedCasIndex
         * @memberof secret_store_api.UpdateSecretAclRequest
         * @instance
         */
        UpdateSecretAclRequest.prototype.expectedCasIndex = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new UpdateSecretAclRequest instance using the specified properties.
         * @function create
         * @memberof secret_store_api.UpdateSecretAclRequest
         * @static
         * @param {secret_store_api.IUpdateSecretAclRequest=} [properties] Properties to set
         * @returns {secret_store_api.UpdateSecretAclRequest} UpdateSecretAclRequest instance
         */
        UpdateSecretAclRequest.create = function create(properties) {
            return new UpdateSecretAclRequest(properties);
        };

        /**
         * Encodes the specified UpdateSecretAclRequest message. Does not implicitly {@link secret_store_api.UpdateSecretAclRequest.verify|verify} messages.
         * @function encode
         * @memberof secret_store_api.UpdateSecretAclRequest
         * @static
         * @param {secret_store_api.IUpdateSecretAclRequest} message UpdateSecretAclRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateSecretAclRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.newAcl != null && Object.hasOwnProperty.call(message, "newAcl"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.newAcl);
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.version);
            if (message.expectedCasIndex != null && Object.hasOwnProperty.call(message, "expectedCasIndex"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.expectedCasIndex);
            return writer;
        };

        /**
         * Encodes the specified UpdateSecretAclRequest message, length delimited. Does not implicitly {@link secret_store_api.UpdateSecretAclRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof secret_store_api.UpdateSecretAclRequest
         * @static
         * @param {secret_store_api.IUpdateSecretAclRequest} message UpdateSecretAclRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateSecretAclRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateSecretAclRequest message from the specified reader or buffer.
         * @function decode
         * @memberof secret_store_api.UpdateSecretAclRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {secret_store_api.UpdateSecretAclRequest} UpdateSecretAclRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateSecretAclRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.secret_store_api.UpdateSecretAclRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.newAcl = reader.bytes();
                        break;
                    }
                case 3: {
                        message.version = reader.uint32();
                        break;
                    }
                case 4: {
                        message.expectedCasIndex = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateSecretAclRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof secret_store_api.UpdateSecretAclRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {secret_store_api.UpdateSecretAclRequest} UpdateSecretAclRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateSecretAclRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateSecretAclRequest message.
         * @function verify
         * @memberof secret_store_api.UpdateSecretAclRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateSecretAclRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.newAcl != null && message.hasOwnProperty("newAcl"))
                if (!(message.newAcl && typeof message.newAcl.length === "number" || $util.isString(message.newAcl)))
                    return "newAcl: buffer expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isInteger(message.version))
                    return "version: integer expected";
            if (message.expectedCasIndex != null && message.hasOwnProperty("expectedCasIndex"))
                if (!$util.isInteger(message.expectedCasIndex) && !(message.expectedCasIndex && $util.isInteger(message.expectedCasIndex.low) && $util.isInteger(message.expectedCasIndex.high)))
                    return "expectedCasIndex: integer|Long expected";
            return null;
        };

        /**
         * Creates an UpdateSecretAclRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof secret_store_api.UpdateSecretAclRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {secret_store_api.UpdateSecretAclRequest} UpdateSecretAclRequest
         */
        UpdateSecretAclRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.secret_store_api.UpdateSecretAclRequest)
                return object;
            let message = new $root.secret_store_api.UpdateSecretAclRequest();
            if (object.id != null)
                message.id = String(object.id);
            if (object.newAcl != null)
                if (typeof object.newAcl === "string")
                    $util.base64.decode(object.newAcl, message.newAcl = $util.newBuffer($util.base64.length(object.newAcl)), 0);
                else if (object.newAcl.length >= 0)
                    message.newAcl = object.newAcl;
            if (object.version != null)
                message.version = object.version >>> 0;
            if (object.expectedCasIndex != null)
                if ($util.Long)
                    (message.expectedCasIndex = $util.Long.fromValue(object.expectedCasIndex)).unsigned = true;
                else if (typeof object.expectedCasIndex === "string")
                    message.expectedCasIndex = parseInt(object.expectedCasIndex, 10);
                else if (typeof object.expectedCasIndex === "number")
                    message.expectedCasIndex = object.expectedCasIndex;
                else if (typeof object.expectedCasIndex === "object")
                    message.expectedCasIndex = new $util.LongBits(object.expectedCasIndex.low >>> 0, object.expectedCasIndex.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an UpdateSecretAclRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof secret_store_api.UpdateSecretAclRequest
         * @static
         * @param {secret_store_api.UpdateSecretAclRequest} message UpdateSecretAclRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateSecretAclRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                if (options.bytes === String)
                    object.newAcl = "";
                else {
                    object.newAcl = [];
                    if (options.bytes !== Array)
                        object.newAcl = $util.newBuffer(object.newAcl);
                }
                object.version = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.expectedCasIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.expectedCasIndex = options.longs === String ? "0" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.newAcl != null && message.hasOwnProperty("newAcl"))
                object.newAcl = options.bytes === String ? $util.base64.encode(message.newAcl, 0, message.newAcl.length) : options.bytes === Array ? Array.prototype.slice.call(message.newAcl) : message.newAcl;
            if (message.version != null && message.hasOwnProperty("version"))
                object.version = message.version;
            if (message.expectedCasIndex != null && message.hasOwnProperty("expectedCasIndex"))
                if (typeof message.expectedCasIndex === "number")
                    object.expectedCasIndex = options.longs === String ? String(message.expectedCasIndex) : message.expectedCasIndex;
                else
                    object.expectedCasIndex = options.longs === String ? $util.Long.prototype.toString.call(message.expectedCasIndex) : options.longs === Number ? new $util.LongBits(message.expectedCasIndex.low >>> 0, message.expectedCasIndex.high >>> 0).toNumber(true) : message.expectedCasIndex;
            return object;
        };

        /**
         * Converts this UpdateSecretAclRequest to JSON.
         * @function toJSON
         * @memberof secret_store_api.UpdateSecretAclRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateSecretAclRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateSecretAclRequest
         * @function getTypeUrl
         * @memberof secret_store_api.UpdateSecretAclRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateSecretAclRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/secret_store_api.UpdateSecretAclRequest";
        };

        return UpdateSecretAclRequest;
    })();

    secret_store_api.UpdateSecretAclResponse = (function() {

        /**
         * Properties of an UpdateSecretAclResponse.
         * @memberof secret_store_api
         * @interface IUpdateSecretAclResponse
         * @property {boolean|null} [updated] Update can fail because the expected cas index doesn't match
         */

        /**
         * Constructs a new UpdateSecretAclResponse.
         * @memberof secret_store_api
         * @classdesc Represents an UpdateSecretAclResponse.
         * @implements IUpdateSecretAclResponse
         * @constructor
         * @param {secret_store_api.IUpdateSecretAclResponse=} [properties] Properties to set
         */
        function UpdateSecretAclResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Update can fail because the expected cas index doesn't match
         * @member {boolean} updated
         * @memberof secret_store_api.UpdateSecretAclResponse
         * @instance
         */
        UpdateSecretAclResponse.prototype.updated = false;

        /**
         * Creates a new UpdateSecretAclResponse instance using the specified properties.
         * @function create
         * @memberof secret_store_api.UpdateSecretAclResponse
         * @static
         * @param {secret_store_api.IUpdateSecretAclResponse=} [properties] Properties to set
         * @returns {secret_store_api.UpdateSecretAclResponse} UpdateSecretAclResponse instance
         */
        UpdateSecretAclResponse.create = function create(properties) {
            return new UpdateSecretAclResponse(properties);
        };

        /**
         * Encodes the specified UpdateSecretAclResponse message. Does not implicitly {@link secret_store_api.UpdateSecretAclResponse.verify|verify} messages.
         * @function encode
         * @memberof secret_store_api.UpdateSecretAclResponse
         * @static
         * @param {secret_store_api.IUpdateSecretAclResponse} message UpdateSecretAclResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateSecretAclResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.updated != null && Object.hasOwnProperty.call(message, "updated"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.updated);
            return writer;
        };

        /**
         * Encodes the specified UpdateSecretAclResponse message, length delimited. Does not implicitly {@link secret_store_api.UpdateSecretAclResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof secret_store_api.UpdateSecretAclResponse
         * @static
         * @param {secret_store_api.IUpdateSecretAclResponse} message UpdateSecretAclResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateSecretAclResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateSecretAclResponse message from the specified reader or buffer.
         * @function decode
         * @memberof secret_store_api.UpdateSecretAclResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {secret_store_api.UpdateSecretAclResponse} UpdateSecretAclResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateSecretAclResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.secret_store_api.UpdateSecretAclResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.updated = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateSecretAclResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof secret_store_api.UpdateSecretAclResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {secret_store_api.UpdateSecretAclResponse} UpdateSecretAclResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateSecretAclResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateSecretAclResponse message.
         * @function verify
         * @memberof secret_store_api.UpdateSecretAclResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateSecretAclResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.updated != null && message.hasOwnProperty("updated"))
                if (typeof message.updated !== "boolean")
                    return "updated: boolean expected";
            return null;
        };

        /**
         * Creates an UpdateSecretAclResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof secret_store_api.UpdateSecretAclResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {secret_store_api.UpdateSecretAclResponse} UpdateSecretAclResponse
         */
        UpdateSecretAclResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.secret_store_api.UpdateSecretAclResponse)
                return object;
            let message = new $root.secret_store_api.UpdateSecretAclResponse();
            if (object.updated != null)
                message.updated = Boolean(object.updated);
            return message;
        };

        /**
         * Creates a plain object from an UpdateSecretAclResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof secret_store_api.UpdateSecretAclResponse
         * @static
         * @param {secret_store_api.UpdateSecretAclResponse} message UpdateSecretAclResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateSecretAclResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.updated = false;
            if (message.updated != null && message.hasOwnProperty("updated"))
                object.updated = message.updated;
            return object;
        };

        /**
         * Converts this UpdateSecretAclResponse to JSON.
         * @function toJSON
         * @memberof secret_store_api.UpdateSecretAclResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateSecretAclResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateSecretAclResponse
         * @function getTypeUrl
         * @memberof secret_store_api.UpdateSecretAclResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateSecretAclResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/secret_store_api.UpdateSecretAclResponse";
        };

        return UpdateSecretAclResponse;
    })();

    secret_store_api.SecretStoreRequest = (function() {

        /**
         * Properties of a SecretStoreRequest.
         * @memberof secret_store_api
         * @interface ISecretStoreRequest
         * @property {secret_store_api.ICreateSecretRequest|null} [createSecret] SecretStoreRequest createSecret
         * @property {secret_store_api.IGetSecretRequest|null} [getSecret] SecretStoreRequest getSecret
         * @property {secret_store_api.IRemoveSecretRequest|null} [removeSecret] SecretStoreRequest removeSecret
         * @property {secret_store_api.IUpdateSecretAclRequest|null} [updateSecretAcl] SecretStoreRequest updateSecretAcl
         */

        /**
         * Constructs a new SecretStoreRequest.
         * @memberof secret_store_api
         * @classdesc Represents a SecretStoreRequest.
         * @implements ISecretStoreRequest
         * @constructor
         * @param {secret_store_api.ISecretStoreRequest=} [properties] Properties to set
         */
        function SecretStoreRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SecretStoreRequest createSecret.
         * @member {secret_store_api.ICreateSecretRequest|null|undefined} createSecret
         * @memberof secret_store_api.SecretStoreRequest
         * @instance
         */
        SecretStoreRequest.prototype.createSecret = null;

        /**
         * SecretStoreRequest getSecret.
         * @member {secret_store_api.IGetSecretRequest|null|undefined} getSecret
         * @memberof secret_store_api.SecretStoreRequest
         * @instance
         */
        SecretStoreRequest.prototype.getSecret = null;

        /**
         * SecretStoreRequest removeSecret.
         * @member {secret_store_api.IRemoveSecretRequest|null|undefined} removeSecret
         * @memberof secret_store_api.SecretStoreRequest
         * @instance
         */
        SecretStoreRequest.prototype.removeSecret = null;

        /**
         * SecretStoreRequest updateSecretAcl.
         * @member {secret_store_api.IUpdateSecretAclRequest|null|undefined} updateSecretAcl
         * @memberof secret_store_api.SecretStoreRequest
         * @instance
         */
        SecretStoreRequest.prototype.updateSecretAcl = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SecretStoreRequest request.
         * @member {"createSecret"|"getSecret"|"removeSecret"|"updateSecretAcl"|undefined} request
         * @memberof secret_store_api.SecretStoreRequest
         * @instance
         */
        Object.defineProperty(SecretStoreRequest.prototype, "request", {
            get: $util.oneOfGetter($oneOfFields = ["createSecret", "getSecret", "removeSecret", "updateSecretAcl"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SecretStoreRequest instance using the specified properties.
         * @function create
         * @memberof secret_store_api.SecretStoreRequest
         * @static
         * @param {secret_store_api.ISecretStoreRequest=} [properties] Properties to set
         * @returns {secret_store_api.SecretStoreRequest} SecretStoreRequest instance
         */
        SecretStoreRequest.create = function create(properties) {
            return new SecretStoreRequest(properties);
        };

        /**
         * Encodes the specified SecretStoreRequest message. Does not implicitly {@link secret_store_api.SecretStoreRequest.verify|verify} messages.
         * @function encode
         * @memberof secret_store_api.SecretStoreRequest
         * @static
         * @param {secret_store_api.ISecretStoreRequest} message SecretStoreRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SecretStoreRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.createSecret != null && Object.hasOwnProperty.call(message, "createSecret"))
                $root.secret_store_api.CreateSecretRequest.encode(message.createSecret, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.getSecret != null && Object.hasOwnProperty.call(message, "getSecret"))
                $root.secret_store_api.GetSecretRequest.encode(message.getSecret, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.removeSecret != null && Object.hasOwnProperty.call(message, "removeSecret"))
                $root.secret_store_api.RemoveSecretRequest.encode(message.removeSecret, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.updateSecretAcl != null && Object.hasOwnProperty.call(message, "updateSecretAcl"))
                $root.secret_store_api.UpdateSecretAclRequest.encode(message.updateSecretAcl, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SecretStoreRequest message, length delimited. Does not implicitly {@link secret_store_api.SecretStoreRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof secret_store_api.SecretStoreRequest
         * @static
         * @param {secret_store_api.ISecretStoreRequest} message SecretStoreRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SecretStoreRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SecretStoreRequest message from the specified reader or buffer.
         * @function decode
         * @memberof secret_store_api.SecretStoreRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {secret_store_api.SecretStoreRequest} SecretStoreRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SecretStoreRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.secret_store_api.SecretStoreRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.createSecret = $root.secret_store_api.CreateSecretRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.getSecret = $root.secret_store_api.GetSecretRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.removeSecret = $root.secret_store_api.RemoveSecretRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.updateSecretAcl = $root.secret_store_api.UpdateSecretAclRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SecretStoreRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof secret_store_api.SecretStoreRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {secret_store_api.SecretStoreRequest} SecretStoreRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SecretStoreRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SecretStoreRequest message.
         * @function verify
         * @memberof secret_store_api.SecretStoreRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SecretStoreRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.createSecret != null && message.hasOwnProperty("createSecret")) {
                properties.request = 1;
                {
                    let error = $root.secret_store_api.CreateSecretRequest.verify(message.createSecret);
                    if (error)
                        return "createSecret." + error;
                }
            }
            if (message.getSecret != null && message.hasOwnProperty("getSecret")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.secret_store_api.GetSecretRequest.verify(message.getSecret);
                    if (error)
                        return "getSecret." + error;
                }
            }
            if (message.removeSecret != null && message.hasOwnProperty("removeSecret")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.secret_store_api.RemoveSecretRequest.verify(message.removeSecret);
                    if (error)
                        return "removeSecret." + error;
                }
            }
            if (message.updateSecretAcl != null && message.hasOwnProperty("updateSecretAcl")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.secret_store_api.UpdateSecretAclRequest.verify(message.updateSecretAcl);
                    if (error)
                        return "updateSecretAcl." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SecretStoreRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof secret_store_api.SecretStoreRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {secret_store_api.SecretStoreRequest} SecretStoreRequest
         */
        SecretStoreRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.secret_store_api.SecretStoreRequest)
                return object;
            let message = new $root.secret_store_api.SecretStoreRequest();
            if (object.createSecret != null) {
                if (typeof object.createSecret !== "object")
                    throw TypeError(".secret_store_api.SecretStoreRequest.createSecret: object expected");
                message.createSecret = $root.secret_store_api.CreateSecretRequest.fromObject(object.createSecret);
            }
            if (object.getSecret != null) {
                if (typeof object.getSecret !== "object")
                    throw TypeError(".secret_store_api.SecretStoreRequest.getSecret: object expected");
                message.getSecret = $root.secret_store_api.GetSecretRequest.fromObject(object.getSecret);
            }
            if (object.removeSecret != null) {
                if (typeof object.removeSecret !== "object")
                    throw TypeError(".secret_store_api.SecretStoreRequest.removeSecret: object expected");
                message.removeSecret = $root.secret_store_api.RemoveSecretRequest.fromObject(object.removeSecret);
            }
            if (object.updateSecretAcl != null) {
                if (typeof object.updateSecretAcl !== "object")
                    throw TypeError(".secret_store_api.SecretStoreRequest.updateSecretAcl: object expected");
                message.updateSecretAcl = $root.secret_store_api.UpdateSecretAclRequest.fromObject(object.updateSecretAcl);
            }
            return message;
        };

        /**
         * Creates a plain object from a SecretStoreRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof secret_store_api.SecretStoreRequest
         * @static
         * @param {secret_store_api.SecretStoreRequest} message SecretStoreRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SecretStoreRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.createSecret != null && message.hasOwnProperty("createSecret")) {
                object.createSecret = $root.secret_store_api.CreateSecretRequest.toObject(message.createSecret, options);
                if (options.oneofs)
                    object.request = "createSecret";
            }
            if (message.getSecret != null && message.hasOwnProperty("getSecret")) {
                object.getSecret = $root.secret_store_api.GetSecretRequest.toObject(message.getSecret, options);
                if (options.oneofs)
                    object.request = "getSecret";
            }
            if (message.removeSecret != null && message.hasOwnProperty("removeSecret")) {
                object.removeSecret = $root.secret_store_api.RemoveSecretRequest.toObject(message.removeSecret, options);
                if (options.oneofs)
                    object.request = "removeSecret";
            }
            if (message.updateSecretAcl != null && message.hasOwnProperty("updateSecretAcl")) {
                object.updateSecretAcl = $root.secret_store_api.UpdateSecretAclRequest.toObject(message.updateSecretAcl, options);
                if (options.oneofs)
                    object.request = "updateSecretAcl";
            }
            return object;
        };

        /**
         * Converts this SecretStoreRequest to JSON.
         * @function toJSON
         * @memberof secret_store_api.SecretStoreRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SecretStoreRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SecretStoreRequest
         * @function getTypeUrl
         * @memberof secret_store_api.SecretStoreRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SecretStoreRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/secret_store_api.SecretStoreRequest";
        };

        return SecretStoreRequest;
    })();

    secret_store_api.SecretStoreResponse = (function() {

        /**
         * Properties of a SecretStoreResponse.
         * @memberof secret_store_api
         * @interface ISecretStoreResponse
         * @property {secret_store_api.ICreateSecretResponse|null} [createSecret] SecretStoreResponse createSecret
         * @property {secret_store_api.IGetSecretResponse|null} [getSecret] SecretStoreResponse getSecret
         * @property {secret_store_api.IRemoveSecretResponse|null} [removeSecret] SecretStoreResponse removeSecret
         * @property {secret_store_api.IUpdateSecretAclResponse|null} [updateSecretAcl] SecretStoreResponse updateSecretAcl
         */

        /**
         * Constructs a new SecretStoreResponse.
         * @memberof secret_store_api
         * @classdesc Represents a SecretStoreResponse.
         * @implements ISecretStoreResponse
         * @constructor
         * @param {secret_store_api.ISecretStoreResponse=} [properties] Properties to set
         */
        function SecretStoreResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SecretStoreResponse createSecret.
         * @member {secret_store_api.ICreateSecretResponse|null|undefined} createSecret
         * @memberof secret_store_api.SecretStoreResponse
         * @instance
         */
        SecretStoreResponse.prototype.createSecret = null;

        /**
         * SecretStoreResponse getSecret.
         * @member {secret_store_api.IGetSecretResponse|null|undefined} getSecret
         * @memberof secret_store_api.SecretStoreResponse
         * @instance
         */
        SecretStoreResponse.prototype.getSecret = null;

        /**
         * SecretStoreResponse removeSecret.
         * @member {secret_store_api.IRemoveSecretResponse|null|undefined} removeSecret
         * @memberof secret_store_api.SecretStoreResponse
         * @instance
         */
        SecretStoreResponse.prototype.removeSecret = null;

        /**
         * SecretStoreResponse updateSecretAcl.
         * @member {secret_store_api.IUpdateSecretAclResponse|null|undefined} updateSecretAcl
         * @memberof secret_store_api.SecretStoreResponse
         * @instance
         */
        SecretStoreResponse.prototype.updateSecretAcl = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SecretStoreResponse response.
         * @member {"createSecret"|"getSecret"|"removeSecret"|"updateSecretAcl"|undefined} response
         * @memberof secret_store_api.SecretStoreResponse
         * @instance
         */
        Object.defineProperty(SecretStoreResponse.prototype, "response", {
            get: $util.oneOfGetter($oneOfFields = ["createSecret", "getSecret", "removeSecret", "updateSecretAcl"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SecretStoreResponse instance using the specified properties.
         * @function create
         * @memberof secret_store_api.SecretStoreResponse
         * @static
         * @param {secret_store_api.ISecretStoreResponse=} [properties] Properties to set
         * @returns {secret_store_api.SecretStoreResponse} SecretStoreResponse instance
         */
        SecretStoreResponse.create = function create(properties) {
            return new SecretStoreResponse(properties);
        };

        /**
         * Encodes the specified SecretStoreResponse message. Does not implicitly {@link secret_store_api.SecretStoreResponse.verify|verify} messages.
         * @function encode
         * @memberof secret_store_api.SecretStoreResponse
         * @static
         * @param {secret_store_api.ISecretStoreResponse} message SecretStoreResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SecretStoreResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.createSecret != null && Object.hasOwnProperty.call(message, "createSecret"))
                $root.secret_store_api.CreateSecretResponse.encode(message.createSecret, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.getSecret != null && Object.hasOwnProperty.call(message, "getSecret"))
                $root.secret_store_api.GetSecretResponse.encode(message.getSecret, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.removeSecret != null && Object.hasOwnProperty.call(message, "removeSecret"))
                $root.secret_store_api.RemoveSecretResponse.encode(message.removeSecret, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.updateSecretAcl != null && Object.hasOwnProperty.call(message, "updateSecretAcl"))
                $root.secret_store_api.UpdateSecretAclResponse.encode(message.updateSecretAcl, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SecretStoreResponse message, length delimited. Does not implicitly {@link secret_store_api.SecretStoreResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof secret_store_api.SecretStoreResponse
         * @static
         * @param {secret_store_api.ISecretStoreResponse} message SecretStoreResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SecretStoreResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SecretStoreResponse message from the specified reader or buffer.
         * @function decode
         * @memberof secret_store_api.SecretStoreResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {secret_store_api.SecretStoreResponse} SecretStoreResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SecretStoreResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.secret_store_api.SecretStoreResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.createSecret = $root.secret_store_api.CreateSecretResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.getSecret = $root.secret_store_api.GetSecretResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.removeSecret = $root.secret_store_api.RemoveSecretResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.updateSecretAcl = $root.secret_store_api.UpdateSecretAclResponse.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SecretStoreResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof secret_store_api.SecretStoreResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {secret_store_api.SecretStoreResponse} SecretStoreResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SecretStoreResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SecretStoreResponse message.
         * @function verify
         * @memberof secret_store_api.SecretStoreResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SecretStoreResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.createSecret != null && message.hasOwnProperty("createSecret")) {
                properties.response = 1;
                {
                    let error = $root.secret_store_api.CreateSecretResponse.verify(message.createSecret);
                    if (error)
                        return "createSecret." + error;
                }
            }
            if (message.getSecret != null && message.hasOwnProperty("getSecret")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.secret_store_api.GetSecretResponse.verify(message.getSecret);
                    if (error)
                        return "getSecret." + error;
                }
            }
            if (message.removeSecret != null && message.hasOwnProperty("removeSecret")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.secret_store_api.RemoveSecretResponse.verify(message.removeSecret);
                    if (error)
                        return "removeSecret." + error;
                }
            }
            if (message.updateSecretAcl != null && message.hasOwnProperty("updateSecretAcl")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.secret_store_api.UpdateSecretAclResponse.verify(message.updateSecretAcl);
                    if (error)
                        return "updateSecretAcl." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SecretStoreResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof secret_store_api.SecretStoreResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {secret_store_api.SecretStoreResponse} SecretStoreResponse
         */
        SecretStoreResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.secret_store_api.SecretStoreResponse)
                return object;
            let message = new $root.secret_store_api.SecretStoreResponse();
            if (object.createSecret != null) {
                if (typeof object.createSecret !== "object")
                    throw TypeError(".secret_store_api.SecretStoreResponse.createSecret: object expected");
                message.createSecret = $root.secret_store_api.CreateSecretResponse.fromObject(object.createSecret);
            }
            if (object.getSecret != null) {
                if (typeof object.getSecret !== "object")
                    throw TypeError(".secret_store_api.SecretStoreResponse.getSecret: object expected");
                message.getSecret = $root.secret_store_api.GetSecretResponse.fromObject(object.getSecret);
            }
            if (object.removeSecret != null) {
                if (typeof object.removeSecret !== "object")
                    throw TypeError(".secret_store_api.SecretStoreResponse.removeSecret: object expected");
                message.removeSecret = $root.secret_store_api.RemoveSecretResponse.fromObject(object.removeSecret);
            }
            if (object.updateSecretAcl != null) {
                if (typeof object.updateSecretAcl !== "object")
                    throw TypeError(".secret_store_api.SecretStoreResponse.updateSecretAcl: object expected");
                message.updateSecretAcl = $root.secret_store_api.UpdateSecretAclResponse.fromObject(object.updateSecretAcl);
            }
            return message;
        };

        /**
         * Creates a plain object from a SecretStoreResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof secret_store_api.SecretStoreResponse
         * @static
         * @param {secret_store_api.SecretStoreResponse} message SecretStoreResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SecretStoreResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.createSecret != null && message.hasOwnProperty("createSecret")) {
                object.createSecret = $root.secret_store_api.CreateSecretResponse.toObject(message.createSecret, options);
                if (options.oneofs)
                    object.response = "createSecret";
            }
            if (message.getSecret != null && message.hasOwnProperty("getSecret")) {
                object.getSecret = $root.secret_store_api.GetSecretResponse.toObject(message.getSecret, options);
                if (options.oneofs)
                    object.response = "getSecret";
            }
            if (message.removeSecret != null && message.hasOwnProperty("removeSecret")) {
                object.removeSecret = $root.secret_store_api.RemoveSecretResponse.toObject(message.removeSecret, options);
                if (options.oneofs)
                    object.response = "removeSecret";
            }
            if (message.updateSecretAcl != null && message.hasOwnProperty("updateSecretAcl")) {
                object.updateSecretAcl = $root.secret_store_api.UpdateSecretAclResponse.toObject(message.updateSecretAcl, options);
                if (options.oneofs)
                    object.response = "updateSecretAcl";
            }
            return object;
        };

        /**
         * Converts this SecretStoreResponse to JSON.
         * @function toJSON
         * @memberof secret_store_api.SecretStoreResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SecretStoreResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SecretStoreResponse
         * @function getTypeUrl
         * @memberof secret_store_api.SecretStoreResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SecretStoreResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/secret_store_api.SecretStoreResponse";
        };

        return SecretStoreResponse;
    })();

    return secret_store_api;
})();

export const google_ad_manager = $root.google_ad_manager = (() => {

    /**
     * Namespace google_ad_manager.
     * @exports google_ad_manager
     * @namespace
     */
    const google_ad_manager = {};

    google_ad_manager.SingleFile = (function() {

        /**
         * Properties of a SingleFile.
         * @memberof google_ad_manager
         * @interface ISingleFile
         * @property {string|null} [name] SingleFile name
         */

        /**
         * Constructs a new SingleFile.
         * @memberof google_ad_manager
         * @classdesc Represents a SingleFile.
         * @implements ISingleFile
         * @constructor
         * @param {google_ad_manager.ISingleFile=} [properties] Properties to set
         */
        function SingleFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SingleFile name.
         * @member {string} name
         * @memberof google_ad_manager.SingleFile
         * @instance
         */
        SingleFile.prototype.name = "";

        /**
         * Creates a new SingleFile instance using the specified properties.
         * @function create
         * @memberof google_ad_manager.SingleFile
         * @static
         * @param {google_ad_manager.ISingleFile=} [properties] Properties to set
         * @returns {google_ad_manager.SingleFile} SingleFile instance
         */
        SingleFile.create = function create(properties) {
            return new SingleFile(properties);
        };

        /**
         * Encodes the specified SingleFile message. Does not implicitly {@link google_ad_manager.SingleFile.verify|verify} messages.
         * @function encode
         * @memberof google_ad_manager.SingleFile
         * @static
         * @param {google_ad_manager.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified SingleFile message, length delimited. Does not implicitly {@link google_ad_manager.SingleFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof google_ad_manager.SingleFile
         * @static
         * @param {google_ad_manager.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer.
         * @function decode
         * @memberof google_ad_manager.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {google_ad_manager.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google_ad_manager.SingleFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof google_ad_manager.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {google_ad_manager.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SingleFile message.
         * @function verify
         * @memberof google_ad_manager.SingleFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SingleFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a SingleFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof google_ad_manager.SingleFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {google_ad_manager.SingleFile} SingleFile
         */
        SingleFile.fromObject = function fromObject(object) {
            if (object instanceof $root.google_ad_manager.SingleFile)
                return object;
            let message = new $root.google_ad_manager.SingleFile();
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a SingleFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof google_ad_manager.SingleFile
         * @static
         * @param {google_ad_manager.SingleFile} message SingleFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SingleFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this SingleFile to JSON.
         * @function toJSON
         * @memberof google_ad_manager.SingleFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SingleFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SingleFile
         * @function getTypeUrl
         * @memberof google_ad_manager.SingleFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SingleFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/google_ad_manager.SingleFile";
        };

        return SingleFile;
    })();

    google_ad_manager.RawFile = (function() {

        /**
         * Properties of a RawFile.
         * @memberof google_ad_manager
         * @interface IRawFile
         */

        /**
         * Constructs a new RawFile.
         * @memberof google_ad_manager
         * @classdesc Represents a RawFile.
         * @implements IRawFile
         * @constructor
         * @param {google_ad_manager.IRawFile=} [properties] Properties to set
         */
        function RawFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RawFile instance using the specified properties.
         * @function create
         * @memberof google_ad_manager.RawFile
         * @static
         * @param {google_ad_manager.IRawFile=} [properties] Properties to set
         * @returns {google_ad_manager.RawFile} RawFile instance
         */
        RawFile.create = function create(properties) {
            return new RawFile(properties);
        };

        /**
         * Encodes the specified RawFile message. Does not implicitly {@link google_ad_manager.RawFile.verify|verify} messages.
         * @function encode
         * @memberof google_ad_manager.RawFile
         * @static
         * @param {google_ad_manager.IRawFile} message RawFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RawFile message, length delimited. Does not implicitly {@link google_ad_manager.RawFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof google_ad_manager.RawFile
         * @static
         * @param {google_ad_manager.IRawFile} message RawFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RawFile message from the specified reader or buffer.
         * @function decode
         * @memberof google_ad_manager.RawFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {google_ad_manager.RawFile} RawFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google_ad_manager.RawFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RawFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof google_ad_manager.RawFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {google_ad_manager.RawFile} RawFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RawFile message.
         * @function verify
         * @memberof google_ad_manager.RawFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RawFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RawFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof google_ad_manager.RawFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {google_ad_manager.RawFile} RawFile
         */
        RawFile.fromObject = function fromObject(object) {
            if (object instanceof $root.google_ad_manager.RawFile)
                return object;
            return new $root.google_ad_manager.RawFile();
        };

        /**
         * Creates a plain object from a RawFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof google_ad_manager.RawFile
         * @static
         * @param {google_ad_manager.RawFile} message RawFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RawFile.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RawFile to JSON.
         * @function toJSON
         * @memberof google_ad_manager.RawFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RawFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RawFile
         * @function getTypeUrl
         * @memberof google_ad_manager.RawFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RawFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/google_ad_manager.RawFile";
        };

        return RawFile;
    })();

    google_ad_manager.ZipFile = (function() {

        /**
         * Properties of a ZipFile.
         * @memberof google_ad_manager
         * @interface IZipFile
         * @property {google_ad_manager.ISingleFile|null} [singleFile] ZipFile singleFile
         */

        /**
         * Constructs a new ZipFile.
         * @memberof google_ad_manager
         * @classdesc Represents a ZipFile.
         * @implements IZipFile
         * @constructor
         * @param {google_ad_manager.IZipFile=} [properties] Properties to set
         */
        function ZipFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ZipFile singleFile.
         * @member {google_ad_manager.ISingleFile|null|undefined} singleFile
         * @memberof google_ad_manager.ZipFile
         * @instance
         */
        ZipFile.prototype.singleFile = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ZipFile selection.
         * @member {"singleFile"|undefined} selection
         * @memberof google_ad_manager.ZipFile
         * @instance
         */
        Object.defineProperty(ZipFile.prototype, "selection", {
            get: $util.oneOfGetter($oneOfFields = ["singleFile"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ZipFile instance using the specified properties.
         * @function create
         * @memberof google_ad_manager.ZipFile
         * @static
         * @param {google_ad_manager.IZipFile=} [properties] Properties to set
         * @returns {google_ad_manager.ZipFile} ZipFile instance
         */
        ZipFile.create = function create(properties) {
            return new ZipFile(properties);
        };

        /**
         * Encodes the specified ZipFile message. Does not implicitly {@link google_ad_manager.ZipFile.verify|verify} messages.
         * @function encode
         * @memberof google_ad_manager.ZipFile
         * @static
         * @param {google_ad_manager.IZipFile} message ZipFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.singleFile != null && Object.hasOwnProperty.call(message, "singleFile"))
                $root.google_ad_manager.SingleFile.encode(message.singleFile, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ZipFile message, length delimited. Does not implicitly {@link google_ad_manager.ZipFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof google_ad_manager.ZipFile
         * @static
         * @param {google_ad_manager.IZipFile} message ZipFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ZipFile message from the specified reader or buffer.
         * @function decode
         * @memberof google_ad_manager.ZipFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {google_ad_manager.ZipFile} ZipFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google_ad_manager.ZipFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.singleFile = $root.google_ad_manager.SingleFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ZipFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof google_ad_manager.ZipFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {google_ad_manager.ZipFile} ZipFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ZipFile message.
         * @function verify
         * @memberof google_ad_manager.ZipFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ZipFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.singleFile != null && message.hasOwnProperty("singleFile")) {
                properties.selection = 1;
                {
                    let error = $root.google_ad_manager.SingleFile.verify(message.singleFile);
                    if (error)
                        return "singleFile." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ZipFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof google_ad_manager.ZipFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {google_ad_manager.ZipFile} ZipFile
         */
        ZipFile.fromObject = function fromObject(object) {
            if (object instanceof $root.google_ad_manager.ZipFile)
                return object;
            let message = new $root.google_ad_manager.ZipFile();
            if (object.singleFile != null) {
                if (typeof object.singleFile !== "object")
                    throw TypeError(".google_ad_manager.ZipFile.singleFile: object expected");
                message.singleFile = $root.google_ad_manager.SingleFile.fromObject(object.singleFile);
            }
            return message;
        };

        /**
         * Creates a plain object from a ZipFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof google_ad_manager.ZipFile
         * @static
         * @param {google_ad_manager.ZipFile} message ZipFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ZipFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.singleFile != null && message.hasOwnProperty("singleFile")) {
                object.singleFile = $root.google_ad_manager.SingleFile.toObject(message.singleFile, options);
                if (options.oneofs)
                    object.selection = "singleFile";
            }
            return object;
        };

        /**
         * Converts this ZipFile to JSON.
         * @function toJSON
         * @memberof google_ad_manager.ZipFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ZipFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ZipFile
         * @function getTypeUrl
         * @memberof google_ad_manager.ZipFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ZipFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/google_ad_manager.ZipFile";
        };

        return ZipFile;
    })();

    google_ad_manager.SinkInput = (function() {

        /**
         * Properties of a SinkInput.
         * @memberof google_ad_manager
         * @interface ISinkInput
         * @property {string|null} [dependency] SinkInput dependency
         * @property {string|null} [name] SinkInput name
         * @property {google_ad_manager.IRawFile|null} [raw] SinkInput raw
         * @property {google_ad_manager.IZipFile|null} [zip] SinkInput zip
         */

        /**
         * Constructs a new SinkInput.
         * @memberof google_ad_manager
         * @classdesc Represents a SinkInput.
         * @implements ISinkInput
         * @constructor
         * @param {google_ad_manager.ISinkInput=} [properties] Properties to set
         */
        function SinkInput(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SinkInput dependency.
         * @member {string} dependency
         * @memberof google_ad_manager.SinkInput
         * @instance
         */
        SinkInput.prototype.dependency = "";

        /**
         * SinkInput name.
         * @member {string} name
         * @memberof google_ad_manager.SinkInput
         * @instance
         */
        SinkInput.prototype.name = "";

        /**
         * SinkInput raw.
         * @member {google_ad_manager.IRawFile|null|undefined} raw
         * @memberof google_ad_manager.SinkInput
         * @instance
         */
        SinkInput.prototype.raw = null;

        /**
         * SinkInput zip.
         * @member {google_ad_manager.IZipFile|null|undefined} zip
         * @memberof google_ad_manager.SinkInput
         * @instance
         */
        SinkInput.prototype.zip = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SinkInput file.
         * @member {"raw"|"zip"|undefined} file
         * @memberof google_ad_manager.SinkInput
         * @instance
         */
        Object.defineProperty(SinkInput.prototype, "file", {
            get: $util.oneOfGetter($oneOfFields = ["raw", "zip"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SinkInput instance using the specified properties.
         * @function create
         * @memberof google_ad_manager.SinkInput
         * @static
         * @param {google_ad_manager.ISinkInput=} [properties] Properties to set
         * @returns {google_ad_manager.SinkInput} SinkInput instance
         */
        SinkInput.create = function create(properties) {
            return new SinkInput(properties);
        };

        /**
         * Encodes the specified SinkInput message. Does not implicitly {@link google_ad_manager.SinkInput.verify|verify} messages.
         * @function encode
         * @memberof google_ad_manager.SinkInput
         * @static
         * @param {google_ad_manager.ISinkInput} message SinkInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SinkInput.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dependency != null && Object.hasOwnProperty.call(message, "dependency"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dependency);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.raw != null && Object.hasOwnProperty.call(message, "raw"))
                $root.google_ad_manager.RawFile.encode(message.raw, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.zip != null && Object.hasOwnProperty.call(message, "zip"))
                $root.google_ad_manager.ZipFile.encode(message.zip, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SinkInput message, length delimited. Does not implicitly {@link google_ad_manager.SinkInput.verify|verify} messages.
         * @function encodeDelimited
         * @memberof google_ad_manager.SinkInput
         * @static
         * @param {google_ad_manager.ISinkInput} message SinkInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SinkInput.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SinkInput message from the specified reader or buffer.
         * @function decode
         * @memberof google_ad_manager.SinkInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {google_ad_manager.SinkInput} SinkInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SinkInput.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google_ad_manager.SinkInput();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dependency = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.raw = $root.google_ad_manager.RawFile.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.zip = $root.google_ad_manager.ZipFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SinkInput message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof google_ad_manager.SinkInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {google_ad_manager.SinkInput} SinkInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SinkInput.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SinkInput message.
         * @function verify
         * @memberof google_ad_manager.SinkInput
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SinkInput.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                if (!$util.isString(message.dependency))
                    return "dependency: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.raw != null && message.hasOwnProperty("raw")) {
                properties.file = 1;
                {
                    let error = $root.google_ad_manager.RawFile.verify(message.raw);
                    if (error)
                        return "raw." + error;
                }
            }
            if (message.zip != null && message.hasOwnProperty("zip")) {
                if (properties.file === 1)
                    return "file: multiple values";
                properties.file = 1;
                {
                    let error = $root.google_ad_manager.ZipFile.verify(message.zip);
                    if (error)
                        return "zip." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SinkInput message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof google_ad_manager.SinkInput
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {google_ad_manager.SinkInput} SinkInput
         */
        SinkInput.fromObject = function fromObject(object) {
            if (object instanceof $root.google_ad_manager.SinkInput)
                return object;
            let message = new $root.google_ad_manager.SinkInput();
            if (object.dependency != null)
                message.dependency = String(object.dependency);
            if (object.name != null)
                message.name = String(object.name);
            if (object.raw != null) {
                if (typeof object.raw !== "object")
                    throw TypeError(".google_ad_manager.SinkInput.raw: object expected");
                message.raw = $root.google_ad_manager.RawFile.fromObject(object.raw);
            }
            if (object.zip != null) {
                if (typeof object.zip !== "object")
                    throw TypeError(".google_ad_manager.SinkInput.zip: object expected");
                message.zip = $root.google_ad_manager.ZipFile.fromObject(object.zip);
            }
            return message;
        };

        /**
         * Creates a plain object from a SinkInput message. Also converts values to other types if specified.
         * @function toObject
         * @memberof google_ad_manager.SinkInput
         * @static
         * @param {google_ad_manager.SinkInput} message SinkInput
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SinkInput.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.dependency = "";
                object.name = "";
            }
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                object.dependency = message.dependency;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.raw != null && message.hasOwnProperty("raw")) {
                object.raw = $root.google_ad_manager.RawFile.toObject(message.raw, options);
                if (options.oneofs)
                    object.file = "raw";
            }
            if (message.zip != null && message.hasOwnProperty("zip")) {
                object.zip = $root.google_ad_manager.ZipFile.toObject(message.zip, options);
                if (options.oneofs)
                    object.file = "zip";
            }
            return object;
        };

        /**
         * Converts this SinkInput to JSON.
         * @function toJSON
         * @memberof google_ad_manager.SinkInput
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SinkInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SinkInput
         * @function getTypeUrl
         * @memberof google_ad_manager.SinkInput
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SinkInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/google_ad_manager.SinkInput";
        };

        return SinkInput;
    })();

    google_ad_manager.GoogleAdManagerWorkerConfiguration = (function() {

        /**
         * Properties of a GoogleAdManagerWorkerConfiguration.
         * @memberof google_ad_manager
         * @interface IGoogleAdManagerWorkerConfiguration
         * @property {google_ad_manager.ISinkInput|null} [input] GoogleAdManagerWorkerConfiguration input
         * @property {string|null} [credentialsDependency] GoogleAdManagerWorkerConfiguration credentialsDependency
         * @property {string|null} [identifierKind] GoogleAdManagerWorkerConfiguration identifierKind
         * @property {string|null} [listId] GoogleAdManagerWorkerConfiguration listId
         * @property {boolean|null} [inputHasHeaders] GoogleAdManagerWorkerConfiguration inputHasHeaders
         * @property {string|null} [bucket] GoogleAdManagerWorkerConfiguration bucket
         * @property {string|null} [objectName] GoogleAdManagerWorkerConfiguration objectName
         */

        /**
         * Constructs a new GoogleAdManagerWorkerConfiguration.
         * @memberof google_ad_manager
         * @classdesc Represents a GoogleAdManagerWorkerConfiguration.
         * @implements IGoogleAdManagerWorkerConfiguration
         * @constructor
         * @param {google_ad_manager.IGoogleAdManagerWorkerConfiguration=} [properties] Properties to set
         */
        function GoogleAdManagerWorkerConfiguration(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GoogleAdManagerWorkerConfiguration input.
         * @member {google_ad_manager.ISinkInput|null|undefined} input
         * @memberof google_ad_manager.GoogleAdManagerWorkerConfiguration
         * @instance
         */
        GoogleAdManagerWorkerConfiguration.prototype.input = null;

        /**
         * GoogleAdManagerWorkerConfiguration credentialsDependency.
         * @member {string} credentialsDependency
         * @memberof google_ad_manager.GoogleAdManagerWorkerConfiguration
         * @instance
         */
        GoogleAdManagerWorkerConfiguration.prototype.credentialsDependency = "";

        /**
         * GoogleAdManagerWorkerConfiguration identifierKind.
         * @member {string} identifierKind
         * @memberof google_ad_manager.GoogleAdManagerWorkerConfiguration
         * @instance
         */
        GoogleAdManagerWorkerConfiguration.prototype.identifierKind = "";

        /**
         * GoogleAdManagerWorkerConfiguration listId.
         * @member {string} listId
         * @memberof google_ad_manager.GoogleAdManagerWorkerConfiguration
         * @instance
         */
        GoogleAdManagerWorkerConfiguration.prototype.listId = "";

        /**
         * GoogleAdManagerWorkerConfiguration inputHasHeaders.
         * @member {boolean} inputHasHeaders
         * @memberof google_ad_manager.GoogleAdManagerWorkerConfiguration
         * @instance
         */
        GoogleAdManagerWorkerConfiguration.prototype.inputHasHeaders = false;

        /**
         * GoogleAdManagerWorkerConfiguration bucket.
         * @member {string} bucket
         * @memberof google_ad_manager.GoogleAdManagerWorkerConfiguration
         * @instance
         */
        GoogleAdManagerWorkerConfiguration.prototype.bucket = "";

        /**
         * GoogleAdManagerWorkerConfiguration objectName.
         * @member {string} objectName
         * @memberof google_ad_manager.GoogleAdManagerWorkerConfiguration
         * @instance
         */
        GoogleAdManagerWorkerConfiguration.prototype.objectName = "";

        /**
         * Creates a new GoogleAdManagerWorkerConfiguration instance using the specified properties.
         * @function create
         * @memberof google_ad_manager.GoogleAdManagerWorkerConfiguration
         * @static
         * @param {google_ad_manager.IGoogleAdManagerWorkerConfiguration=} [properties] Properties to set
         * @returns {google_ad_manager.GoogleAdManagerWorkerConfiguration} GoogleAdManagerWorkerConfiguration instance
         */
        GoogleAdManagerWorkerConfiguration.create = function create(properties) {
            return new GoogleAdManagerWorkerConfiguration(properties);
        };

        /**
         * Encodes the specified GoogleAdManagerWorkerConfiguration message. Does not implicitly {@link google_ad_manager.GoogleAdManagerWorkerConfiguration.verify|verify} messages.
         * @function encode
         * @memberof google_ad_manager.GoogleAdManagerWorkerConfiguration
         * @static
         * @param {google_ad_manager.IGoogleAdManagerWorkerConfiguration} message GoogleAdManagerWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GoogleAdManagerWorkerConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.input != null && Object.hasOwnProperty.call(message, "input"))
                $root.google_ad_manager.SinkInput.encode(message.input, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.credentialsDependency != null && Object.hasOwnProperty.call(message, "credentialsDependency"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.credentialsDependency);
            if (message.identifierKind != null && Object.hasOwnProperty.call(message, "identifierKind"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.identifierKind);
            if (message.listId != null && Object.hasOwnProperty.call(message, "listId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.listId);
            if (message.inputHasHeaders != null && Object.hasOwnProperty.call(message, "inputHasHeaders"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.inputHasHeaders);
            if (message.bucket != null && Object.hasOwnProperty.call(message, "bucket"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.bucket);
            if (message.objectName != null && Object.hasOwnProperty.call(message, "objectName"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.objectName);
            return writer;
        };

        /**
         * Encodes the specified GoogleAdManagerWorkerConfiguration message, length delimited. Does not implicitly {@link google_ad_manager.GoogleAdManagerWorkerConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof google_ad_manager.GoogleAdManagerWorkerConfiguration
         * @static
         * @param {google_ad_manager.IGoogleAdManagerWorkerConfiguration} message GoogleAdManagerWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GoogleAdManagerWorkerConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GoogleAdManagerWorkerConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof google_ad_manager.GoogleAdManagerWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {google_ad_manager.GoogleAdManagerWorkerConfiguration} GoogleAdManagerWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GoogleAdManagerWorkerConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google_ad_manager.GoogleAdManagerWorkerConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.input = $root.google_ad_manager.SinkInput.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.credentialsDependency = reader.string();
                        break;
                    }
                case 3: {
                        message.identifierKind = reader.string();
                        break;
                    }
                case 4: {
                        message.listId = reader.string();
                        break;
                    }
                case 5: {
                        message.inputHasHeaders = reader.bool();
                        break;
                    }
                case 6: {
                        message.bucket = reader.string();
                        break;
                    }
                case 7: {
                        message.objectName = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GoogleAdManagerWorkerConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof google_ad_manager.GoogleAdManagerWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {google_ad_manager.GoogleAdManagerWorkerConfiguration} GoogleAdManagerWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GoogleAdManagerWorkerConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GoogleAdManagerWorkerConfiguration message.
         * @function verify
         * @memberof google_ad_manager.GoogleAdManagerWorkerConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GoogleAdManagerWorkerConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.input != null && message.hasOwnProperty("input")) {
                let error = $root.google_ad_manager.SinkInput.verify(message.input);
                if (error)
                    return "input." + error;
            }
            if (message.credentialsDependency != null && message.hasOwnProperty("credentialsDependency"))
                if (!$util.isString(message.credentialsDependency))
                    return "credentialsDependency: string expected";
            if (message.identifierKind != null && message.hasOwnProperty("identifierKind"))
                if (!$util.isString(message.identifierKind))
                    return "identifierKind: string expected";
            if (message.listId != null && message.hasOwnProperty("listId"))
                if (!$util.isString(message.listId))
                    return "listId: string expected";
            if (message.inputHasHeaders != null && message.hasOwnProperty("inputHasHeaders"))
                if (typeof message.inputHasHeaders !== "boolean")
                    return "inputHasHeaders: boolean expected";
            if (message.bucket != null && message.hasOwnProperty("bucket"))
                if (!$util.isString(message.bucket))
                    return "bucket: string expected";
            if (message.objectName != null && message.hasOwnProperty("objectName"))
                if (!$util.isString(message.objectName))
                    return "objectName: string expected";
            return null;
        };

        /**
         * Creates a GoogleAdManagerWorkerConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof google_ad_manager.GoogleAdManagerWorkerConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {google_ad_manager.GoogleAdManagerWorkerConfiguration} GoogleAdManagerWorkerConfiguration
         */
        GoogleAdManagerWorkerConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.google_ad_manager.GoogleAdManagerWorkerConfiguration)
                return object;
            let message = new $root.google_ad_manager.GoogleAdManagerWorkerConfiguration();
            if (object.input != null) {
                if (typeof object.input !== "object")
                    throw TypeError(".google_ad_manager.GoogleAdManagerWorkerConfiguration.input: object expected");
                message.input = $root.google_ad_manager.SinkInput.fromObject(object.input);
            }
            if (object.credentialsDependency != null)
                message.credentialsDependency = String(object.credentialsDependency);
            if (object.identifierKind != null)
                message.identifierKind = String(object.identifierKind);
            if (object.listId != null)
                message.listId = String(object.listId);
            if (object.inputHasHeaders != null)
                message.inputHasHeaders = Boolean(object.inputHasHeaders);
            if (object.bucket != null)
                message.bucket = String(object.bucket);
            if (object.objectName != null)
                message.objectName = String(object.objectName);
            return message;
        };

        /**
         * Creates a plain object from a GoogleAdManagerWorkerConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof google_ad_manager.GoogleAdManagerWorkerConfiguration
         * @static
         * @param {google_ad_manager.GoogleAdManagerWorkerConfiguration} message GoogleAdManagerWorkerConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GoogleAdManagerWorkerConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.input = null;
                object.credentialsDependency = "";
                object.identifierKind = "";
                object.listId = "";
                object.inputHasHeaders = false;
                object.bucket = "";
                object.objectName = "";
            }
            if (message.input != null && message.hasOwnProperty("input"))
                object.input = $root.google_ad_manager.SinkInput.toObject(message.input, options);
            if (message.credentialsDependency != null && message.hasOwnProperty("credentialsDependency"))
                object.credentialsDependency = message.credentialsDependency;
            if (message.identifierKind != null && message.hasOwnProperty("identifierKind"))
                object.identifierKind = message.identifierKind;
            if (message.listId != null && message.hasOwnProperty("listId"))
                object.listId = message.listId;
            if (message.inputHasHeaders != null && message.hasOwnProperty("inputHasHeaders"))
                object.inputHasHeaders = message.inputHasHeaders;
            if (message.bucket != null && message.hasOwnProperty("bucket"))
                object.bucket = message.bucket;
            if (message.objectName != null && message.hasOwnProperty("objectName"))
                object.objectName = message.objectName;
            return object;
        };

        /**
         * Converts this GoogleAdManagerWorkerConfiguration to JSON.
         * @function toJSON
         * @memberof google_ad_manager.GoogleAdManagerWorkerConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GoogleAdManagerWorkerConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GoogleAdManagerWorkerConfiguration
         * @function getTypeUrl
         * @memberof google_ad_manager.GoogleAdManagerWorkerConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GoogleAdManagerWorkerConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/google_ad_manager.GoogleAdManagerWorkerConfiguration";
        };

        return GoogleAdManagerWorkerConfiguration;
    })();

    return google_ad_manager;
})();

export const google_dv_360_sink = $root.google_dv_360_sink = (() => {

    /**
     * Namespace google_dv_360_sink.
     * @exports google_dv_360_sink
     * @namespace
     */
    const google_dv_360_sink = {};

    google_dv_360_sink.SingleFile = (function() {

        /**
         * Properties of a SingleFile.
         * @memberof google_dv_360_sink
         * @interface ISingleFile
         * @property {string|null} [name] SingleFile name
         */

        /**
         * Constructs a new SingleFile.
         * @memberof google_dv_360_sink
         * @classdesc Represents a SingleFile.
         * @implements ISingleFile
         * @constructor
         * @param {google_dv_360_sink.ISingleFile=} [properties] Properties to set
         */
        function SingleFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SingleFile name.
         * @member {string} name
         * @memberof google_dv_360_sink.SingleFile
         * @instance
         */
        SingleFile.prototype.name = "";

        /**
         * Creates a new SingleFile instance using the specified properties.
         * @function create
         * @memberof google_dv_360_sink.SingleFile
         * @static
         * @param {google_dv_360_sink.ISingleFile=} [properties] Properties to set
         * @returns {google_dv_360_sink.SingleFile} SingleFile instance
         */
        SingleFile.create = function create(properties) {
            return new SingleFile(properties);
        };

        /**
         * Encodes the specified SingleFile message. Does not implicitly {@link google_dv_360_sink.SingleFile.verify|verify} messages.
         * @function encode
         * @memberof google_dv_360_sink.SingleFile
         * @static
         * @param {google_dv_360_sink.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified SingleFile message, length delimited. Does not implicitly {@link google_dv_360_sink.SingleFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof google_dv_360_sink.SingleFile
         * @static
         * @param {google_dv_360_sink.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer.
         * @function decode
         * @memberof google_dv_360_sink.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {google_dv_360_sink.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google_dv_360_sink.SingleFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof google_dv_360_sink.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {google_dv_360_sink.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SingleFile message.
         * @function verify
         * @memberof google_dv_360_sink.SingleFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SingleFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a SingleFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof google_dv_360_sink.SingleFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {google_dv_360_sink.SingleFile} SingleFile
         */
        SingleFile.fromObject = function fromObject(object) {
            if (object instanceof $root.google_dv_360_sink.SingleFile)
                return object;
            let message = new $root.google_dv_360_sink.SingleFile();
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a SingleFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof google_dv_360_sink.SingleFile
         * @static
         * @param {google_dv_360_sink.SingleFile} message SingleFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SingleFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this SingleFile to JSON.
         * @function toJSON
         * @memberof google_dv_360_sink.SingleFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SingleFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SingleFile
         * @function getTypeUrl
         * @memberof google_dv_360_sink.SingleFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SingleFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/google_dv_360_sink.SingleFile";
        };

        return SingleFile;
    })();

    google_dv_360_sink.RawFile = (function() {

        /**
         * Properties of a RawFile.
         * @memberof google_dv_360_sink
         * @interface IRawFile
         */

        /**
         * Constructs a new RawFile.
         * @memberof google_dv_360_sink
         * @classdesc Represents a RawFile.
         * @implements IRawFile
         * @constructor
         * @param {google_dv_360_sink.IRawFile=} [properties] Properties to set
         */
        function RawFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RawFile instance using the specified properties.
         * @function create
         * @memberof google_dv_360_sink.RawFile
         * @static
         * @param {google_dv_360_sink.IRawFile=} [properties] Properties to set
         * @returns {google_dv_360_sink.RawFile} RawFile instance
         */
        RawFile.create = function create(properties) {
            return new RawFile(properties);
        };

        /**
         * Encodes the specified RawFile message. Does not implicitly {@link google_dv_360_sink.RawFile.verify|verify} messages.
         * @function encode
         * @memberof google_dv_360_sink.RawFile
         * @static
         * @param {google_dv_360_sink.IRawFile} message RawFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RawFile message, length delimited. Does not implicitly {@link google_dv_360_sink.RawFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof google_dv_360_sink.RawFile
         * @static
         * @param {google_dv_360_sink.IRawFile} message RawFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RawFile message from the specified reader or buffer.
         * @function decode
         * @memberof google_dv_360_sink.RawFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {google_dv_360_sink.RawFile} RawFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google_dv_360_sink.RawFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RawFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof google_dv_360_sink.RawFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {google_dv_360_sink.RawFile} RawFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RawFile message.
         * @function verify
         * @memberof google_dv_360_sink.RawFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RawFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RawFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof google_dv_360_sink.RawFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {google_dv_360_sink.RawFile} RawFile
         */
        RawFile.fromObject = function fromObject(object) {
            if (object instanceof $root.google_dv_360_sink.RawFile)
                return object;
            return new $root.google_dv_360_sink.RawFile();
        };

        /**
         * Creates a plain object from a RawFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof google_dv_360_sink.RawFile
         * @static
         * @param {google_dv_360_sink.RawFile} message RawFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RawFile.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RawFile to JSON.
         * @function toJSON
         * @memberof google_dv_360_sink.RawFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RawFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RawFile
         * @function getTypeUrl
         * @memberof google_dv_360_sink.RawFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RawFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/google_dv_360_sink.RawFile";
        };

        return RawFile;
    })();

    google_dv_360_sink.ZipFile = (function() {

        /**
         * Properties of a ZipFile.
         * @memberof google_dv_360_sink
         * @interface IZipFile
         * @property {google_dv_360_sink.ISingleFile|null} [singleFile] ZipFile singleFile
         */

        /**
         * Constructs a new ZipFile.
         * @memberof google_dv_360_sink
         * @classdesc Represents a ZipFile.
         * @implements IZipFile
         * @constructor
         * @param {google_dv_360_sink.IZipFile=} [properties] Properties to set
         */
        function ZipFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ZipFile singleFile.
         * @member {google_dv_360_sink.ISingleFile|null|undefined} singleFile
         * @memberof google_dv_360_sink.ZipFile
         * @instance
         */
        ZipFile.prototype.singleFile = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ZipFile selection.
         * @member {"singleFile"|undefined} selection
         * @memberof google_dv_360_sink.ZipFile
         * @instance
         */
        Object.defineProperty(ZipFile.prototype, "selection", {
            get: $util.oneOfGetter($oneOfFields = ["singleFile"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ZipFile instance using the specified properties.
         * @function create
         * @memberof google_dv_360_sink.ZipFile
         * @static
         * @param {google_dv_360_sink.IZipFile=} [properties] Properties to set
         * @returns {google_dv_360_sink.ZipFile} ZipFile instance
         */
        ZipFile.create = function create(properties) {
            return new ZipFile(properties);
        };

        /**
         * Encodes the specified ZipFile message. Does not implicitly {@link google_dv_360_sink.ZipFile.verify|verify} messages.
         * @function encode
         * @memberof google_dv_360_sink.ZipFile
         * @static
         * @param {google_dv_360_sink.IZipFile} message ZipFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.singleFile != null && Object.hasOwnProperty.call(message, "singleFile"))
                $root.google_dv_360_sink.SingleFile.encode(message.singleFile, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ZipFile message, length delimited. Does not implicitly {@link google_dv_360_sink.ZipFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof google_dv_360_sink.ZipFile
         * @static
         * @param {google_dv_360_sink.IZipFile} message ZipFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ZipFile message from the specified reader or buffer.
         * @function decode
         * @memberof google_dv_360_sink.ZipFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {google_dv_360_sink.ZipFile} ZipFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google_dv_360_sink.ZipFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.singleFile = $root.google_dv_360_sink.SingleFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ZipFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof google_dv_360_sink.ZipFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {google_dv_360_sink.ZipFile} ZipFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ZipFile message.
         * @function verify
         * @memberof google_dv_360_sink.ZipFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ZipFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.singleFile != null && message.hasOwnProperty("singleFile")) {
                properties.selection = 1;
                {
                    let error = $root.google_dv_360_sink.SingleFile.verify(message.singleFile);
                    if (error)
                        return "singleFile." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ZipFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof google_dv_360_sink.ZipFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {google_dv_360_sink.ZipFile} ZipFile
         */
        ZipFile.fromObject = function fromObject(object) {
            if (object instanceof $root.google_dv_360_sink.ZipFile)
                return object;
            let message = new $root.google_dv_360_sink.ZipFile();
            if (object.singleFile != null) {
                if (typeof object.singleFile !== "object")
                    throw TypeError(".google_dv_360_sink.ZipFile.singleFile: object expected");
                message.singleFile = $root.google_dv_360_sink.SingleFile.fromObject(object.singleFile);
            }
            return message;
        };

        /**
         * Creates a plain object from a ZipFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof google_dv_360_sink.ZipFile
         * @static
         * @param {google_dv_360_sink.ZipFile} message ZipFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ZipFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.singleFile != null && message.hasOwnProperty("singleFile")) {
                object.singleFile = $root.google_dv_360_sink.SingleFile.toObject(message.singleFile, options);
                if (options.oneofs)
                    object.selection = "singleFile";
            }
            return object;
        };

        /**
         * Converts this ZipFile to JSON.
         * @function toJSON
         * @memberof google_dv_360_sink.ZipFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ZipFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ZipFile
         * @function getTypeUrl
         * @memberof google_dv_360_sink.ZipFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ZipFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/google_dv_360_sink.ZipFile";
        };

        return ZipFile;
    })();

    google_dv_360_sink.SinkInput = (function() {

        /**
         * Properties of a SinkInput.
         * @memberof google_dv_360_sink
         * @interface ISinkInput
         * @property {string|null} [dependency] SinkInput dependency
         * @property {string|null} [name] SinkInput name
         * @property {google_dv_360_sink.IRawFile|null} [raw] SinkInput raw
         * @property {google_dv_360_sink.IZipFile|null} [zip] SinkInput zip
         */

        /**
         * Constructs a new SinkInput.
         * @memberof google_dv_360_sink
         * @classdesc Represents a SinkInput.
         * @implements ISinkInput
         * @constructor
         * @param {google_dv_360_sink.ISinkInput=} [properties] Properties to set
         */
        function SinkInput(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SinkInput dependency.
         * @member {string} dependency
         * @memberof google_dv_360_sink.SinkInput
         * @instance
         */
        SinkInput.prototype.dependency = "";

        /**
         * SinkInput name.
         * @member {string} name
         * @memberof google_dv_360_sink.SinkInput
         * @instance
         */
        SinkInput.prototype.name = "";

        /**
         * SinkInput raw.
         * @member {google_dv_360_sink.IRawFile|null|undefined} raw
         * @memberof google_dv_360_sink.SinkInput
         * @instance
         */
        SinkInput.prototype.raw = null;

        /**
         * SinkInput zip.
         * @member {google_dv_360_sink.IZipFile|null|undefined} zip
         * @memberof google_dv_360_sink.SinkInput
         * @instance
         */
        SinkInput.prototype.zip = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SinkInput file.
         * @member {"raw"|"zip"|undefined} file
         * @memberof google_dv_360_sink.SinkInput
         * @instance
         */
        Object.defineProperty(SinkInput.prototype, "file", {
            get: $util.oneOfGetter($oneOfFields = ["raw", "zip"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SinkInput instance using the specified properties.
         * @function create
         * @memberof google_dv_360_sink.SinkInput
         * @static
         * @param {google_dv_360_sink.ISinkInput=} [properties] Properties to set
         * @returns {google_dv_360_sink.SinkInput} SinkInput instance
         */
        SinkInput.create = function create(properties) {
            return new SinkInput(properties);
        };

        /**
         * Encodes the specified SinkInput message. Does not implicitly {@link google_dv_360_sink.SinkInput.verify|verify} messages.
         * @function encode
         * @memberof google_dv_360_sink.SinkInput
         * @static
         * @param {google_dv_360_sink.ISinkInput} message SinkInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SinkInput.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dependency != null && Object.hasOwnProperty.call(message, "dependency"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dependency);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.raw != null && Object.hasOwnProperty.call(message, "raw"))
                $root.google_dv_360_sink.RawFile.encode(message.raw, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.zip != null && Object.hasOwnProperty.call(message, "zip"))
                $root.google_dv_360_sink.ZipFile.encode(message.zip, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SinkInput message, length delimited. Does not implicitly {@link google_dv_360_sink.SinkInput.verify|verify} messages.
         * @function encodeDelimited
         * @memberof google_dv_360_sink.SinkInput
         * @static
         * @param {google_dv_360_sink.ISinkInput} message SinkInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SinkInput.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SinkInput message from the specified reader or buffer.
         * @function decode
         * @memberof google_dv_360_sink.SinkInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {google_dv_360_sink.SinkInput} SinkInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SinkInput.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google_dv_360_sink.SinkInput();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dependency = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.raw = $root.google_dv_360_sink.RawFile.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.zip = $root.google_dv_360_sink.ZipFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SinkInput message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof google_dv_360_sink.SinkInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {google_dv_360_sink.SinkInput} SinkInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SinkInput.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SinkInput message.
         * @function verify
         * @memberof google_dv_360_sink.SinkInput
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SinkInput.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                if (!$util.isString(message.dependency))
                    return "dependency: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.raw != null && message.hasOwnProperty("raw")) {
                properties.file = 1;
                {
                    let error = $root.google_dv_360_sink.RawFile.verify(message.raw);
                    if (error)
                        return "raw." + error;
                }
            }
            if (message.zip != null && message.hasOwnProperty("zip")) {
                if (properties.file === 1)
                    return "file: multiple values";
                properties.file = 1;
                {
                    let error = $root.google_dv_360_sink.ZipFile.verify(message.zip);
                    if (error)
                        return "zip." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SinkInput message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof google_dv_360_sink.SinkInput
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {google_dv_360_sink.SinkInput} SinkInput
         */
        SinkInput.fromObject = function fromObject(object) {
            if (object instanceof $root.google_dv_360_sink.SinkInput)
                return object;
            let message = new $root.google_dv_360_sink.SinkInput();
            if (object.dependency != null)
                message.dependency = String(object.dependency);
            if (object.name != null)
                message.name = String(object.name);
            if (object.raw != null) {
                if (typeof object.raw !== "object")
                    throw TypeError(".google_dv_360_sink.SinkInput.raw: object expected");
                message.raw = $root.google_dv_360_sink.RawFile.fromObject(object.raw);
            }
            if (object.zip != null) {
                if (typeof object.zip !== "object")
                    throw TypeError(".google_dv_360_sink.SinkInput.zip: object expected");
                message.zip = $root.google_dv_360_sink.ZipFile.fromObject(object.zip);
            }
            return message;
        };

        /**
         * Creates a plain object from a SinkInput message. Also converts values to other types if specified.
         * @function toObject
         * @memberof google_dv_360_sink.SinkInput
         * @static
         * @param {google_dv_360_sink.SinkInput} message SinkInput
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SinkInput.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.dependency = "";
                object.name = "";
            }
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                object.dependency = message.dependency;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.raw != null && message.hasOwnProperty("raw")) {
                object.raw = $root.google_dv_360_sink.RawFile.toObject(message.raw, options);
                if (options.oneofs)
                    object.file = "raw";
            }
            if (message.zip != null && message.hasOwnProperty("zip")) {
                object.zip = $root.google_dv_360_sink.ZipFile.toObject(message.zip, options);
                if (options.oneofs)
                    object.file = "zip";
            }
            return object;
        };

        /**
         * Converts this SinkInput to JSON.
         * @function toJSON
         * @memberof google_dv_360_sink.SinkInput
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SinkInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SinkInput
         * @function getTypeUrl
         * @memberof google_dv_360_sink.SinkInput
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SinkInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/google_dv_360_sink.SinkInput";
        };

        return SinkInput;
    })();

    google_dv_360_sink.GoogleDv360SinkWorkerConfiguration = (function() {

        /**
         * Properties of a GoogleDv360SinkWorkerConfiguration.
         * @memberof google_dv_360_sink
         * @interface IGoogleDv360SinkWorkerConfiguration
         * @property {google_dv_360_sink.ISinkInput|null} [input] GoogleDv360SinkWorkerConfiguration input
         * @property {string|null} [credentialsDependency] GoogleDv360SinkWorkerConfiguration credentialsDependency
         * @property {string|null} [advertiserId] GoogleDv360SinkWorkerConfiguration advertiserId
         * @property {string|null} [displayName] GoogleDv360SinkWorkerConfiguration displayName
         * @property {string|null} [description] GoogleDv360SinkWorkerConfiguration description
         * @property {string|null} [membershipDurationDays] GoogleDv360SinkWorkerConfiguration membershipDurationDays
         */

        /**
         * Constructs a new GoogleDv360SinkWorkerConfiguration.
         * @memberof google_dv_360_sink
         * @classdesc Represents a GoogleDv360SinkWorkerConfiguration.
         * @implements IGoogleDv360SinkWorkerConfiguration
         * @constructor
         * @param {google_dv_360_sink.IGoogleDv360SinkWorkerConfiguration=} [properties] Properties to set
         */
        function GoogleDv360SinkWorkerConfiguration(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GoogleDv360SinkWorkerConfiguration input.
         * @member {google_dv_360_sink.ISinkInput|null|undefined} input
         * @memberof google_dv_360_sink.GoogleDv360SinkWorkerConfiguration
         * @instance
         */
        GoogleDv360SinkWorkerConfiguration.prototype.input = null;

        /**
         * GoogleDv360SinkWorkerConfiguration credentialsDependency.
         * @member {string} credentialsDependency
         * @memberof google_dv_360_sink.GoogleDv360SinkWorkerConfiguration
         * @instance
         */
        GoogleDv360SinkWorkerConfiguration.prototype.credentialsDependency = "";

        /**
         * GoogleDv360SinkWorkerConfiguration advertiserId.
         * @member {string} advertiserId
         * @memberof google_dv_360_sink.GoogleDv360SinkWorkerConfiguration
         * @instance
         */
        GoogleDv360SinkWorkerConfiguration.prototype.advertiserId = "";

        /**
         * GoogleDv360SinkWorkerConfiguration displayName.
         * @member {string} displayName
         * @memberof google_dv_360_sink.GoogleDv360SinkWorkerConfiguration
         * @instance
         */
        GoogleDv360SinkWorkerConfiguration.prototype.displayName = "";

        /**
         * GoogleDv360SinkWorkerConfiguration description.
         * @member {string} description
         * @memberof google_dv_360_sink.GoogleDv360SinkWorkerConfiguration
         * @instance
         */
        GoogleDv360SinkWorkerConfiguration.prototype.description = "";

        /**
         * GoogleDv360SinkWorkerConfiguration membershipDurationDays.
         * @member {string} membershipDurationDays
         * @memberof google_dv_360_sink.GoogleDv360SinkWorkerConfiguration
         * @instance
         */
        GoogleDv360SinkWorkerConfiguration.prototype.membershipDurationDays = "";

        /**
         * Creates a new GoogleDv360SinkWorkerConfiguration instance using the specified properties.
         * @function create
         * @memberof google_dv_360_sink.GoogleDv360SinkWorkerConfiguration
         * @static
         * @param {google_dv_360_sink.IGoogleDv360SinkWorkerConfiguration=} [properties] Properties to set
         * @returns {google_dv_360_sink.GoogleDv360SinkWorkerConfiguration} GoogleDv360SinkWorkerConfiguration instance
         */
        GoogleDv360SinkWorkerConfiguration.create = function create(properties) {
            return new GoogleDv360SinkWorkerConfiguration(properties);
        };

        /**
         * Encodes the specified GoogleDv360SinkWorkerConfiguration message. Does not implicitly {@link google_dv_360_sink.GoogleDv360SinkWorkerConfiguration.verify|verify} messages.
         * @function encode
         * @memberof google_dv_360_sink.GoogleDv360SinkWorkerConfiguration
         * @static
         * @param {google_dv_360_sink.IGoogleDv360SinkWorkerConfiguration} message GoogleDv360SinkWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GoogleDv360SinkWorkerConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.input != null && Object.hasOwnProperty.call(message, "input"))
                $root.google_dv_360_sink.SinkInput.encode(message.input, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.credentialsDependency != null && Object.hasOwnProperty.call(message, "credentialsDependency"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.credentialsDependency);
            if (message.advertiserId != null && Object.hasOwnProperty.call(message, "advertiserId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.advertiserId);
            if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.displayName);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.description);
            if (message.membershipDurationDays != null && Object.hasOwnProperty.call(message, "membershipDurationDays"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.membershipDurationDays);
            return writer;
        };

        /**
         * Encodes the specified GoogleDv360SinkWorkerConfiguration message, length delimited. Does not implicitly {@link google_dv_360_sink.GoogleDv360SinkWorkerConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof google_dv_360_sink.GoogleDv360SinkWorkerConfiguration
         * @static
         * @param {google_dv_360_sink.IGoogleDv360SinkWorkerConfiguration} message GoogleDv360SinkWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GoogleDv360SinkWorkerConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GoogleDv360SinkWorkerConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof google_dv_360_sink.GoogleDv360SinkWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {google_dv_360_sink.GoogleDv360SinkWorkerConfiguration} GoogleDv360SinkWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GoogleDv360SinkWorkerConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google_dv_360_sink.GoogleDv360SinkWorkerConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.input = $root.google_dv_360_sink.SinkInput.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.credentialsDependency = reader.string();
                        break;
                    }
                case 3: {
                        message.advertiserId = reader.string();
                        break;
                    }
                case 4: {
                        message.displayName = reader.string();
                        break;
                    }
                case 5: {
                        message.description = reader.string();
                        break;
                    }
                case 6: {
                        message.membershipDurationDays = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GoogleDv360SinkWorkerConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof google_dv_360_sink.GoogleDv360SinkWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {google_dv_360_sink.GoogleDv360SinkWorkerConfiguration} GoogleDv360SinkWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GoogleDv360SinkWorkerConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GoogleDv360SinkWorkerConfiguration message.
         * @function verify
         * @memberof google_dv_360_sink.GoogleDv360SinkWorkerConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GoogleDv360SinkWorkerConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.input != null && message.hasOwnProperty("input")) {
                let error = $root.google_dv_360_sink.SinkInput.verify(message.input);
                if (error)
                    return "input." + error;
            }
            if (message.credentialsDependency != null && message.hasOwnProperty("credentialsDependency"))
                if (!$util.isString(message.credentialsDependency))
                    return "credentialsDependency: string expected";
            if (message.advertiserId != null && message.hasOwnProperty("advertiserId"))
                if (!$util.isString(message.advertiserId))
                    return "advertiserId: string expected";
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                if (!$util.isString(message.displayName))
                    return "displayName: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            if (message.membershipDurationDays != null && message.hasOwnProperty("membershipDurationDays"))
                if (!$util.isString(message.membershipDurationDays))
                    return "membershipDurationDays: string expected";
            return null;
        };

        /**
         * Creates a GoogleDv360SinkWorkerConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof google_dv_360_sink.GoogleDv360SinkWorkerConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {google_dv_360_sink.GoogleDv360SinkWorkerConfiguration} GoogleDv360SinkWorkerConfiguration
         */
        GoogleDv360SinkWorkerConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.google_dv_360_sink.GoogleDv360SinkWorkerConfiguration)
                return object;
            let message = new $root.google_dv_360_sink.GoogleDv360SinkWorkerConfiguration();
            if (object.input != null) {
                if (typeof object.input !== "object")
                    throw TypeError(".google_dv_360_sink.GoogleDv360SinkWorkerConfiguration.input: object expected");
                message.input = $root.google_dv_360_sink.SinkInput.fromObject(object.input);
            }
            if (object.credentialsDependency != null)
                message.credentialsDependency = String(object.credentialsDependency);
            if (object.advertiserId != null)
                message.advertiserId = String(object.advertiserId);
            if (object.displayName != null)
                message.displayName = String(object.displayName);
            if (object.description != null)
                message.description = String(object.description);
            if (object.membershipDurationDays != null)
                message.membershipDurationDays = String(object.membershipDurationDays);
            return message;
        };

        /**
         * Creates a plain object from a GoogleDv360SinkWorkerConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof google_dv_360_sink.GoogleDv360SinkWorkerConfiguration
         * @static
         * @param {google_dv_360_sink.GoogleDv360SinkWorkerConfiguration} message GoogleDv360SinkWorkerConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GoogleDv360SinkWorkerConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.input = null;
                object.credentialsDependency = "";
                object.advertiserId = "";
                object.displayName = "";
                object.description = "";
                object.membershipDurationDays = "";
            }
            if (message.input != null && message.hasOwnProperty("input"))
                object.input = $root.google_dv_360_sink.SinkInput.toObject(message.input, options);
            if (message.credentialsDependency != null && message.hasOwnProperty("credentialsDependency"))
                object.credentialsDependency = message.credentialsDependency;
            if (message.advertiserId != null && message.hasOwnProperty("advertiserId"))
                object.advertiserId = message.advertiserId;
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                object.displayName = message.displayName;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.membershipDurationDays != null && message.hasOwnProperty("membershipDurationDays"))
                object.membershipDurationDays = message.membershipDurationDays;
            return object;
        };

        /**
         * Converts this GoogleDv360SinkWorkerConfiguration to JSON.
         * @function toJSON
         * @memberof google_dv_360_sink.GoogleDv360SinkWorkerConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GoogleDv360SinkWorkerConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GoogleDv360SinkWorkerConfiguration
         * @function getTypeUrl
         * @memberof google_dv_360_sink.GoogleDv360SinkWorkerConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GoogleDv360SinkWorkerConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/google_dv_360_sink.GoogleDv360SinkWorkerConfiguration";
        };

        return GoogleDv360SinkWorkerConfiguration;
    })();

    return google_dv_360_sink;
})();

export const meta_sink = $root.meta_sink = (() => {

    /**
     * Namespace meta_sink.
     * @exports meta_sink
     * @namespace
     */
    const meta_sink = {};

    meta_sink.SingleFile = (function() {

        /**
         * Properties of a SingleFile.
         * @memberof meta_sink
         * @interface ISingleFile
         * @property {string|null} [name] SingleFile name
         */

        /**
         * Constructs a new SingleFile.
         * @memberof meta_sink
         * @classdesc Represents a SingleFile.
         * @implements ISingleFile
         * @constructor
         * @param {meta_sink.ISingleFile=} [properties] Properties to set
         */
        function SingleFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SingleFile name.
         * @member {string} name
         * @memberof meta_sink.SingleFile
         * @instance
         */
        SingleFile.prototype.name = "";

        /**
         * Creates a new SingleFile instance using the specified properties.
         * @function create
         * @memberof meta_sink.SingleFile
         * @static
         * @param {meta_sink.ISingleFile=} [properties] Properties to set
         * @returns {meta_sink.SingleFile} SingleFile instance
         */
        SingleFile.create = function create(properties) {
            return new SingleFile(properties);
        };

        /**
         * Encodes the specified SingleFile message. Does not implicitly {@link meta_sink.SingleFile.verify|verify} messages.
         * @function encode
         * @memberof meta_sink.SingleFile
         * @static
         * @param {meta_sink.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified SingleFile message, length delimited. Does not implicitly {@link meta_sink.SingleFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof meta_sink.SingleFile
         * @static
         * @param {meta_sink.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer.
         * @function decode
         * @memberof meta_sink.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {meta_sink.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.meta_sink.SingleFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof meta_sink.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {meta_sink.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SingleFile message.
         * @function verify
         * @memberof meta_sink.SingleFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SingleFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a SingleFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof meta_sink.SingleFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {meta_sink.SingleFile} SingleFile
         */
        SingleFile.fromObject = function fromObject(object) {
            if (object instanceof $root.meta_sink.SingleFile)
                return object;
            let message = new $root.meta_sink.SingleFile();
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a SingleFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof meta_sink.SingleFile
         * @static
         * @param {meta_sink.SingleFile} message SingleFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SingleFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this SingleFile to JSON.
         * @function toJSON
         * @memberof meta_sink.SingleFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SingleFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SingleFile
         * @function getTypeUrl
         * @memberof meta_sink.SingleFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SingleFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meta_sink.SingleFile";
        };

        return SingleFile;
    })();

    meta_sink.RawFile = (function() {

        /**
         * Properties of a RawFile.
         * @memberof meta_sink
         * @interface IRawFile
         */

        /**
         * Constructs a new RawFile.
         * @memberof meta_sink
         * @classdesc Represents a RawFile.
         * @implements IRawFile
         * @constructor
         * @param {meta_sink.IRawFile=} [properties] Properties to set
         */
        function RawFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RawFile instance using the specified properties.
         * @function create
         * @memberof meta_sink.RawFile
         * @static
         * @param {meta_sink.IRawFile=} [properties] Properties to set
         * @returns {meta_sink.RawFile} RawFile instance
         */
        RawFile.create = function create(properties) {
            return new RawFile(properties);
        };

        /**
         * Encodes the specified RawFile message. Does not implicitly {@link meta_sink.RawFile.verify|verify} messages.
         * @function encode
         * @memberof meta_sink.RawFile
         * @static
         * @param {meta_sink.IRawFile} message RawFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RawFile message, length delimited. Does not implicitly {@link meta_sink.RawFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof meta_sink.RawFile
         * @static
         * @param {meta_sink.IRawFile} message RawFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RawFile message from the specified reader or buffer.
         * @function decode
         * @memberof meta_sink.RawFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {meta_sink.RawFile} RawFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.meta_sink.RawFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RawFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof meta_sink.RawFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {meta_sink.RawFile} RawFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RawFile message.
         * @function verify
         * @memberof meta_sink.RawFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RawFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RawFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof meta_sink.RawFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {meta_sink.RawFile} RawFile
         */
        RawFile.fromObject = function fromObject(object) {
            if (object instanceof $root.meta_sink.RawFile)
                return object;
            return new $root.meta_sink.RawFile();
        };

        /**
         * Creates a plain object from a RawFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof meta_sink.RawFile
         * @static
         * @param {meta_sink.RawFile} message RawFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RawFile.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RawFile to JSON.
         * @function toJSON
         * @memberof meta_sink.RawFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RawFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RawFile
         * @function getTypeUrl
         * @memberof meta_sink.RawFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RawFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meta_sink.RawFile";
        };

        return RawFile;
    })();

    meta_sink.ZipFile = (function() {

        /**
         * Properties of a ZipFile.
         * @memberof meta_sink
         * @interface IZipFile
         * @property {meta_sink.ISingleFile|null} [singleFile] ZipFile singleFile
         */

        /**
         * Constructs a new ZipFile.
         * @memberof meta_sink
         * @classdesc Represents a ZipFile.
         * @implements IZipFile
         * @constructor
         * @param {meta_sink.IZipFile=} [properties] Properties to set
         */
        function ZipFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ZipFile singleFile.
         * @member {meta_sink.ISingleFile|null|undefined} singleFile
         * @memberof meta_sink.ZipFile
         * @instance
         */
        ZipFile.prototype.singleFile = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ZipFile selection.
         * @member {"singleFile"|undefined} selection
         * @memberof meta_sink.ZipFile
         * @instance
         */
        Object.defineProperty(ZipFile.prototype, "selection", {
            get: $util.oneOfGetter($oneOfFields = ["singleFile"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ZipFile instance using the specified properties.
         * @function create
         * @memberof meta_sink.ZipFile
         * @static
         * @param {meta_sink.IZipFile=} [properties] Properties to set
         * @returns {meta_sink.ZipFile} ZipFile instance
         */
        ZipFile.create = function create(properties) {
            return new ZipFile(properties);
        };

        /**
         * Encodes the specified ZipFile message. Does not implicitly {@link meta_sink.ZipFile.verify|verify} messages.
         * @function encode
         * @memberof meta_sink.ZipFile
         * @static
         * @param {meta_sink.IZipFile} message ZipFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.singleFile != null && Object.hasOwnProperty.call(message, "singleFile"))
                $root.meta_sink.SingleFile.encode(message.singleFile, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ZipFile message, length delimited. Does not implicitly {@link meta_sink.ZipFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof meta_sink.ZipFile
         * @static
         * @param {meta_sink.IZipFile} message ZipFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ZipFile message from the specified reader or buffer.
         * @function decode
         * @memberof meta_sink.ZipFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {meta_sink.ZipFile} ZipFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.meta_sink.ZipFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.singleFile = $root.meta_sink.SingleFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ZipFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof meta_sink.ZipFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {meta_sink.ZipFile} ZipFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ZipFile message.
         * @function verify
         * @memberof meta_sink.ZipFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ZipFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.singleFile != null && message.hasOwnProperty("singleFile")) {
                properties.selection = 1;
                {
                    let error = $root.meta_sink.SingleFile.verify(message.singleFile);
                    if (error)
                        return "singleFile." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ZipFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof meta_sink.ZipFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {meta_sink.ZipFile} ZipFile
         */
        ZipFile.fromObject = function fromObject(object) {
            if (object instanceof $root.meta_sink.ZipFile)
                return object;
            let message = new $root.meta_sink.ZipFile();
            if (object.singleFile != null) {
                if (typeof object.singleFile !== "object")
                    throw TypeError(".meta_sink.ZipFile.singleFile: object expected");
                message.singleFile = $root.meta_sink.SingleFile.fromObject(object.singleFile);
            }
            return message;
        };

        /**
         * Creates a plain object from a ZipFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof meta_sink.ZipFile
         * @static
         * @param {meta_sink.ZipFile} message ZipFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ZipFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.singleFile != null && message.hasOwnProperty("singleFile")) {
                object.singleFile = $root.meta_sink.SingleFile.toObject(message.singleFile, options);
                if (options.oneofs)
                    object.selection = "singleFile";
            }
            return object;
        };

        /**
         * Converts this ZipFile to JSON.
         * @function toJSON
         * @memberof meta_sink.ZipFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ZipFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ZipFile
         * @function getTypeUrl
         * @memberof meta_sink.ZipFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ZipFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meta_sink.ZipFile";
        };

        return ZipFile;
    })();

    meta_sink.SinkInput = (function() {

        /**
         * Properties of a SinkInput.
         * @memberof meta_sink
         * @interface ISinkInput
         * @property {string|null} [dependency] SinkInput dependency
         * @property {string|null} [name] SinkInput name
         * @property {meta_sink.IRawFile|null} [raw] SinkInput raw
         * @property {meta_sink.IZipFile|null} [zip] SinkInput zip
         */

        /**
         * Constructs a new SinkInput.
         * @memberof meta_sink
         * @classdesc Represents a SinkInput.
         * @implements ISinkInput
         * @constructor
         * @param {meta_sink.ISinkInput=} [properties] Properties to set
         */
        function SinkInput(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SinkInput dependency.
         * @member {string} dependency
         * @memberof meta_sink.SinkInput
         * @instance
         */
        SinkInput.prototype.dependency = "";

        /**
         * SinkInput name.
         * @member {string} name
         * @memberof meta_sink.SinkInput
         * @instance
         */
        SinkInput.prototype.name = "";

        /**
         * SinkInput raw.
         * @member {meta_sink.IRawFile|null|undefined} raw
         * @memberof meta_sink.SinkInput
         * @instance
         */
        SinkInput.prototype.raw = null;

        /**
         * SinkInput zip.
         * @member {meta_sink.IZipFile|null|undefined} zip
         * @memberof meta_sink.SinkInput
         * @instance
         */
        SinkInput.prototype.zip = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SinkInput file.
         * @member {"raw"|"zip"|undefined} file
         * @memberof meta_sink.SinkInput
         * @instance
         */
        Object.defineProperty(SinkInput.prototype, "file", {
            get: $util.oneOfGetter($oneOfFields = ["raw", "zip"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SinkInput instance using the specified properties.
         * @function create
         * @memberof meta_sink.SinkInput
         * @static
         * @param {meta_sink.ISinkInput=} [properties] Properties to set
         * @returns {meta_sink.SinkInput} SinkInput instance
         */
        SinkInput.create = function create(properties) {
            return new SinkInput(properties);
        };

        /**
         * Encodes the specified SinkInput message. Does not implicitly {@link meta_sink.SinkInput.verify|verify} messages.
         * @function encode
         * @memberof meta_sink.SinkInput
         * @static
         * @param {meta_sink.ISinkInput} message SinkInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SinkInput.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dependency != null && Object.hasOwnProperty.call(message, "dependency"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dependency);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.raw != null && Object.hasOwnProperty.call(message, "raw"))
                $root.meta_sink.RawFile.encode(message.raw, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.zip != null && Object.hasOwnProperty.call(message, "zip"))
                $root.meta_sink.ZipFile.encode(message.zip, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SinkInput message, length delimited. Does not implicitly {@link meta_sink.SinkInput.verify|verify} messages.
         * @function encodeDelimited
         * @memberof meta_sink.SinkInput
         * @static
         * @param {meta_sink.ISinkInput} message SinkInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SinkInput.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SinkInput message from the specified reader or buffer.
         * @function decode
         * @memberof meta_sink.SinkInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {meta_sink.SinkInput} SinkInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SinkInput.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.meta_sink.SinkInput();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dependency = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.raw = $root.meta_sink.RawFile.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.zip = $root.meta_sink.ZipFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SinkInput message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof meta_sink.SinkInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {meta_sink.SinkInput} SinkInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SinkInput.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SinkInput message.
         * @function verify
         * @memberof meta_sink.SinkInput
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SinkInput.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                if (!$util.isString(message.dependency))
                    return "dependency: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.raw != null && message.hasOwnProperty("raw")) {
                properties.file = 1;
                {
                    let error = $root.meta_sink.RawFile.verify(message.raw);
                    if (error)
                        return "raw." + error;
                }
            }
            if (message.zip != null && message.hasOwnProperty("zip")) {
                if (properties.file === 1)
                    return "file: multiple values";
                properties.file = 1;
                {
                    let error = $root.meta_sink.ZipFile.verify(message.zip);
                    if (error)
                        return "zip." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SinkInput message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof meta_sink.SinkInput
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {meta_sink.SinkInput} SinkInput
         */
        SinkInput.fromObject = function fromObject(object) {
            if (object instanceof $root.meta_sink.SinkInput)
                return object;
            let message = new $root.meta_sink.SinkInput();
            if (object.dependency != null)
                message.dependency = String(object.dependency);
            if (object.name != null)
                message.name = String(object.name);
            if (object.raw != null) {
                if (typeof object.raw !== "object")
                    throw TypeError(".meta_sink.SinkInput.raw: object expected");
                message.raw = $root.meta_sink.RawFile.fromObject(object.raw);
            }
            if (object.zip != null) {
                if (typeof object.zip !== "object")
                    throw TypeError(".meta_sink.SinkInput.zip: object expected");
                message.zip = $root.meta_sink.ZipFile.fromObject(object.zip);
            }
            return message;
        };

        /**
         * Creates a plain object from a SinkInput message. Also converts values to other types if specified.
         * @function toObject
         * @memberof meta_sink.SinkInput
         * @static
         * @param {meta_sink.SinkInput} message SinkInput
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SinkInput.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.dependency = "";
                object.name = "";
            }
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                object.dependency = message.dependency;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.raw != null && message.hasOwnProperty("raw")) {
                object.raw = $root.meta_sink.RawFile.toObject(message.raw, options);
                if (options.oneofs)
                    object.file = "raw";
            }
            if (message.zip != null && message.hasOwnProperty("zip")) {
                object.zip = $root.meta_sink.ZipFile.toObject(message.zip, options);
                if (options.oneofs)
                    object.file = "zip";
            }
            return object;
        };

        /**
         * Converts this SinkInput to JSON.
         * @function toJSON
         * @memberof meta_sink.SinkInput
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SinkInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SinkInput
         * @function getTypeUrl
         * @memberof meta_sink.SinkInput
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SinkInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meta_sink.SinkInput";
        };

        return SinkInput;
    })();

    meta_sink.MetaSinkWorkerConfiguration = (function() {

        /**
         * Properties of a MetaSinkWorkerConfiguration.
         * @memberof meta_sink
         * @interface IMetaSinkWorkerConfiguration
         * @property {meta_sink.ISinkInput|null} [input] MetaSinkWorkerConfiguration input
         * @property {string|null} [accessTokenDependency] MetaSinkWorkerConfiguration accessTokenDependency
         * @property {string|null} [adAccountId] MetaSinkWorkerConfiguration adAccountId
         * @property {string|null} [audienceName] MetaSinkWorkerConfiguration audienceName
         * @property {string|null} [apiVersion] MetaSinkWorkerConfiguration apiVersion
         */

        /**
         * Constructs a new MetaSinkWorkerConfiguration.
         * @memberof meta_sink
         * @classdesc Represents a MetaSinkWorkerConfiguration.
         * @implements IMetaSinkWorkerConfiguration
         * @constructor
         * @param {meta_sink.IMetaSinkWorkerConfiguration=} [properties] Properties to set
         */
        function MetaSinkWorkerConfiguration(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MetaSinkWorkerConfiguration input.
         * @member {meta_sink.ISinkInput|null|undefined} input
         * @memberof meta_sink.MetaSinkWorkerConfiguration
         * @instance
         */
        MetaSinkWorkerConfiguration.prototype.input = null;

        /**
         * MetaSinkWorkerConfiguration accessTokenDependency.
         * @member {string} accessTokenDependency
         * @memberof meta_sink.MetaSinkWorkerConfiguration
         * @instance
         */
        MetaSinkWorkerConfiguration.prototype.accessTokenDependency = "";

        /**
         * MetaSinkWorkerConfiguration adAccountId.
         * @member {string} adAccountId
         * @memberof meta_sink.MetaSinkWorkerConfiguration
         * @instance
         */
        MetaSinkWorkerConfiguration.prototype.adAccountId = "";

        /**
         * MetaSinkWorkerConfiguration audienceName.
         * @member {string} audienceName
         * @memberof meta_sink.MetaSinkWorkerConfiguration
         * @instance
         */
        MetaSinkWorkerConfiguration.prototype.audienceName = "";

        /**
         * MetaSinkWorkerConfiguration apiVersion.
         * @member {string|null|undefined} apiVersion
         * @memberof meta_sink.MetaSinkWorkerConfiguration
         * @instance
         */
        MetaSinkWorkerConfiguration.prototype.apiVersion = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MetaSinkWorkerConfiguration _apiVersion.
         * @member {"apiVersion"|undefined} _apiVersion
         * @memberof meta_sink.MetaSinkWorkerConfiguration
         * @instance
         */
        Object.defineProperty(MetaSinkWorkerConfiguration.prototype, "_apiVersion", {
            get: $util.oneOfGetter($oneOfFields = ["apiVersion"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MetaSinkWorkerConfiguration instance using the specified properties.
         * @function create
         * @memberof meta_sink.MetaSinkWorkerConfiguration
         * @static
         * @param {meta_sink.IMetaSinkWorkerConfiguration=} [properties] Properties to set
         * @returns {meta_sink.MetaSinkWorkerConfiguration} MetaSinkWorkerConfiguration instance
         */
        MetaSinkWorkerConfiguration.create = function create(properties) {
            return new MetaSinkWorkerConfiguration(properties);
        };

        /**
         * Encodes the specified MetaSinkWorkerConfiguration message. Does not implicitly {@link meta_sink.MetaSinkWorkerConfiguration.verify|verify} messages.
         * @function encode
         * @memberof meta_sink.MetaSinkWorkerConfiguration
         * @static
         * @param {meta_sink.IMetaSinkWorkerConfiguration} message MetaSinkWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MetaSinkWorkerConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.input != null && Object.hasOwnProperty.call(message, "input"))
                $root.meta_sink.SinkInput.encode(message.input, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.accessTokenDependency != null && Object.hasOwnProperty.call(message, "accessTokenDependency"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.accessTokenDependency);
            if (message.adAccountId != null && Object.hasOwnProperty.call(message, "adAccountId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.adAccountId);
            if (message.audienceName != null && Object.hasOwnProperty.call(message, "audienceName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.audienceName);
            if (message.apiVersion != null && Object.hasOwnProperty.call(message, "apiVersion"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.apiVersion);
            return writer;
        };

        /**
         * Encodes the specified MetaSinkWorkerConfiguration message, length delimited. Does not implicitly {@link meta_sink.MetaSinkWorkerConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof meta_sink.MetaSinkWorkerConfiguration
         * @static
         * @param {meta_sink.IMetaSinkWorkerConfiguration} message MetaSinkWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MetaSinkWorkerConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MetaSinkWorkerConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof meta_sink.MetaSinkWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {meta_sink.MetaSinkWorkerConfiguration} MetaSinkWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MetaSinkWorkerConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.meta_sink.MetaSinkWorkerConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.input = $root.meta_sink.SinkInput.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.accessTokenDependency = reader.string();
                        break;
                    }
                case 3: {
                        message.adAccountId = reader.string();
                        break;
                    }
                case 4: {
                        message.audienceName = reader.string();
                        break;
                    }
                case 5: {
                        message.apiVersion = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MetaSinkWorkerConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof meta_sink.MetaSinkWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {meta_sink.MetaSinkWorkerConfiguration} MetaSinkWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MetaSinkWorkerConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MetaSinkWorkerConfiguration message.
         * @function verify
         * @memberof meta_sink.MetaSinkWorkerConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MetaSinkWorkerConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.input != null && message.hasOwnProperty("input")) {
                let error = $root.meta_sink.SinkInput.verify(message.input);
                if (error)
                    return "input." + error;
            }
            if (message.accessTokenDependency != null && message.hasOwnProperty("accessTokenDependency"))
                if (!$util.isString(message.accessTokenDependency))
                    return "accessTokenDependency: string expected";
            if (message.adAccountId != null && message.hasOwnProperty("adAccountId"))
                if (!$util.isString(message.adAccountId))
                    return "adAccountId: string expected";
            if (message.audienceName != null && message.hasOwnProperty("audienceName"))
                if (!$util.isString(message.audienceName))
                    return "audienceName: string expected";
            if (message.apiVersion != null && message.hasOwnProperty("apiVersion")) {
                properties._apiVersion = 1;
                if (!$util.isString(message.apiVersion))
                    return "apiVersion: string expected";
            }
            return null;
        };

        /**
         * Creates a MetaSinkWorkerConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof meta_sink.MetaSinkWorkerConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {meta_sink.MetaSinkWorkerConfiguration} MetaSinkWorkerConfiguration
         */
        MetaSinkWorkerConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.meta_sink.MetaSinkWorkerConfiguration)
                return object;
            let message = new $root.meta_sink.MetaSinkWorkerConfiguration();
            if (object.input != null) {
                if (typeof object.input !== "object")
                    throw TypeError(".meta_sink.MetaSinkWorkerConfiguration.input: object expected");
                message.input = $root.meta_sink.SinkInput.fromObject(object.input);
            }
            if (object.accessTokenDependency != null)
                message.accessTokenDependency = String(object.accessTokenDependency);
            if (object.adAccountId != null)
                message.adAccountId = String(object.adAccountId);
            if (object.audienceName != null)
                message.audienceName = String(object.audienceName);
            if (object.apiVersion != null)
                message.apiVersion = String(object.apiVersion);
            return message;
        };

        /**
         * Creates a plain object from a MetaSinkWorkerConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof meta_sink.MetaSinkWorkerConfiguration
         * @static
         * @param {meta_sink.MetaSinkWorkerConfiguration} message MetaSinkWorkerConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MetaSinkWorkerConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.input = null;
                object.accessTokenDependency = "";
                object.adAccountId = "";
                object.audienceName = "";
            }
            if (message.input != null && message.hasOwnProperty("input"))
                object.input = $root.meta_sink.SinkInput.toObject(message.input, options);
            if (message.accessTokenDependency != null && message.hasOwnProperty("accessTokenDependency"))
                object.accessTokenDependency = message.accessTokenDependency;
            if (message.adAccountId != null && message.hasOwnProperty("adAccountId"))
                object.adAccountId = message.adAccountId;
            if (message.audienceName != null && message.hasOwnProperty("audienceName"))
                object.audienceName = message.audienceName;
            if (message.apiVersion != null && message.hasOwnProperty("apiVersion")) {
                object.apiVersion = message.apiVersion;
                if (options.oneofs)
                    object._apiVersion = "apiVersion";
            }
            return object;
        };

        /**
         * Converts this MetaSinkWorkerConfiguration to JSON.
         * @function toJSON
         * @memberof meta_sink.MetaSinkWorkerConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MetaSinkWorkerConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MetaSinkWorkerConfiguration
         * @function getTypeUrl
         * @memberof meta_sink.MetaSinkWorkerConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MetaSinkWorkerConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meta_sink.MetaSinkWorkerConfiguration";
        };

        return MetaSinkWorkerConfiguration;
    })();

    return meta_sink;
})();

export const metering = $root.metering = (() => {

    /**
     * Namespace metering.
     * @exports metering
     * @namespace
     */
    const metering = {};

    metering.EnclaveInfo = (function() {

        /**
         * Properties of an EnclaveInfo.
         * @memberof metering
         * @interface IEnclaveInfo
         * @property {string|null} [attestationSpecHashHex] EnclaveInfo attestationSpecHashHex
         * @property {string|null} [taskQueueName] EnclaveInfo taskQueueName
         */

        /**
         * Constructs a new EnclaveInfo.
         * @memberof metering
         * @classdesc Represents an EnclaveInfo.
         * @implements IEnclaveInfo
         * @constructor
         * @param {metering.IEnclaveInfo=} [properties] Properties to set
         */
        function EnclaveInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EnclaveInfo attestationSpecHashHex.
         * @member {string} attestationSpecHashHex
         * @memberof metering.EnclaveInfo
         * @instance
         */
        EnclaveInfo.prototype.attestationSpecHashHex = "";

        /**
         * EnclaveInfo taskQueueName.
         * @member {string|null|undefined} taskQueueName
         * @memberof metering.EnclaveInfo
         * @instance
         */
        EnclaveInfo.prototype.taskQueueName = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * EnclaveInfo _taskQueueName.
         * @member {"taskQueueName"|undefined} _taskQueueName
         * @memberof metering.EnclaveInfo
         * @instance
         */
        Object.defineProperty(EnclaveInfo.prototype, "_taskQueueName", {
            get: $util.oneOfGetter($oneOfFields = ["taskQueueName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new EnclaveInfo instance using the specified properties.
         * @function create
         * @memberof metering.EnclaveInfo
         * @static
         * @param {metering.IEnclaveInfo=} [properties] Properties to set
         * @returns {metering.EnclaveInfo} EnclaveInfo instance
         */
        EnclaveInfo.create = function create(properties) {
            return new EnclaveInfo(properties);
        };

        /**
         * Encodes the specified EnclaveInfo message. Does not implicitly {@link metering.EnclaveInfo.verify|verify} messages.
         * @function encode
         * @memberof metering.EnclaveInfo
         * @static
         * @param {metering.IEnclaveInfo} message EnclaveInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnclaveInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.attestationSpecHashHex != null && Object.hasOwnProperty.call(message, "attestationSpecHashHex"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.attestationSpecHashHex);
            if (message.taskQueueName != null && Object.hasOwnProperty.call(message, "taskQueueName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskQueueName);
            return writer;
        };

        /**
         * Encodes the specified EnclaveInfo message, length delimited. Does not implicitly {@link metering.EnclaveInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.EnclaveInfo
         * @static
         * @param {metering.IEnclaveInfo} message EnclaveInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnclaveInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EnclaveInfo message from the specified reader or buffer.
         * @function decode
         * @memberof metering.EnclaveInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.EnclaveInfo} EnclaveInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnclaveInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.EnclaveInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.attestationSpecHashHex = reader.string();
                        break;
                    }
                case 2: {
                        message.taskQueueName = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EnclaveInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.EnclaveInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.EnclaveInfo} EnclaveInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnclaveInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EnclaveInfo message.
         * @function verify
         * @memberof metering.EnclaveInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EnclaveInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.attestationSpecHashHex != null && message.hasOwnProperty("attestationSpecHashHex"))
                if (!$util.isString(message.attestationSpecHashHex))
                    return "attestationSpecHashHex: string expected";
            if (message.taskQueueName != null && message.hasOwnProperty("taskQueueName")) {
                properties._taskQueueName = 1;
                if (!$util.isString(message.taskQueueName))
                    return "taskQueueName: string expected";
            }
            return null;
        };

        /**
         * Creates an EnclaveInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.EnclaveInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.EnclaveInfo} EnclaveInfo
         */
        EnclaveInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.EnclaveInfo)
                return object;
            let message = new $root.metering.EnclaveInfo();
            if (object.attestationSpecHashHex != null)
                message.attestationSpecHashHex = String(object.attestationSpecHashHex);
            if (object.taskQueueName != null)
                message.taskQueueName = String(object.taskQueueName);
            return message;
        };

        /**
         * Creates a plain object from an EnclaveInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.EnclaveInfo
         * @static
         * @param {metering.EnclaveInfo} message EnclaveInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EnclaveInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.attestationSpecHashHex = "";
            if (message.attestationSpecHashHex != null && message.hasOwnProperty("attestationSpecHashHex"))
                object.attestationSpecHashHex = message.attestationSpecHashHex;
            if (message.taskQueueName != null && message.hasOwnProperty("taskQueueName")) {
                object.taskQueueName = message.taskQueueName;
                if (options.oneofs)
                    object._taskQueueName = "taskQueueName";
            }
            return object;
        };

        /**
         * Converts this EnclaveInfo to JSON.
         * @function toJSON
         * @memberof metering.EnclaveInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EnclaveInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EnclaveInfo
         * @function getTypeUrl
         * @memberof metering.EnclaveInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EnclaveInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.EnclaveInfo";
        };

        return EnclaveInfo;
    })();

    metering.MeteringRequest = (function() {

        /**
         * Properties of a MeteringRequest.
         * @memberof metering
         * @interface IMeteringRequest
         * @property {metering.ICreateDcrRequest|null} [createDcr] MeteringRequest createDcr
         * @property {metering.ICreateDcrCommitRequest|null} [createDcrCommit] MeteringRequest createDcrCommit
         * @property {metering.IStopDcrRequest|null} [stopDcr] MeteringRequest stopDcr
         * @property {metering.IPublishDatasetRequest|null} [publishDataset] MeteringRequest publishDataset
         * @property {metering.IUnpublishDatasetRequest|null} [unpublishDataset] MeteringRequest unpublishDataset
         * @property {metering.IWorkerMetadataRequest|null} [workerMetadata] MeteringRequest workerMetadata
         * @property {metering.ISubmitWorkerExecutionTimeRequest|null} [submitWorkerExecutionTime] MeteringRequest submitWorkerExecutionTime
         * @property {metering.IDcrInteractionRequest|null} [dcrInteraction] MeteringRequest dcrInteraction
         * @property {metering.ICreateDatasetRequest|null} [createDataset] MeteringRequest createDataset
         * @property {metering.IGetOrCreateDatasetScopeRequest|null} [getOrCreateDatasetScope] MeteringRequest getOrCreateDatasetScope
         * @property {metering.IMergeDcrCommitRequest|null} [mergeDcrCommit] MeteringRequest mergeDcrCommit
         * @property {metering.IGetDataConnectorCredentialsRequest|null} [getDataConnectorCredentials] MeteringRequest getDataConnectorCredentials
         * @property {integrity_store_api.IIntegrityStoreRequest|null} [integrityStore] MeteringRequest integrityStore
         * @property {metering.IGetAzureCommunicationCredentialsRequest|null} [getAzureCommunicationCredentials] MeteringRequest getAzureCommunicationCredentials
         */

        /**
         * Constructs a new MeteringRequest.
         * @memberof metering
         * @classdesc Represents a MeteringRequest.
         * @implements IMeteringRequest
         * @constructor
         * @param {metering.IMeteringRequest=} [properties] Properties to set
         */
        function MeteringRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringRequest createDcr.
         * @member {metering.ICreateDcrRequest|null|undefined} createDcr
         * @memberof metering.MeteringRequest
         * @instance
         */
        MeteringRequest.prototype.createDcr = null;

        /**
         * MeteringRequest createDcrCommit.
         * @member {metering.ICreateDcrCommitRequest|null|undefined} createDcrCommit
         * @memberof metering.MeteringRequest
         * @instance
         */
        MeteringRequest.prototype.createDcrCommit = null;

        /**
         * MeteringRequest stopDcr.
         * @member {metering.IStopDcrRequest|null|undefined} stopDcr
         * @memberof metering.MeteringRequest
         * @instance
         */
        MeteringRequest.prototype.stopDcr = null;

        /**
         * MeteringRequest publishDataset.
         * @member {metering.IPublishDatasetRequest|null|undefined} publishDataset
         * @memberof metering.MeteringRequest
         * @instance
         */
        MeteringRequest.prototype.publishDataset = null;

        /**
         * MeteringRequest unpublishDataset.
         * @member {metering.IUnpublishDatasetRequest|null|undefined} unpublishDataset
         * @memberof metering.MeteringRequest
         * @instance
         */
        MeteringRequest.prototype.unpublishDataset = null;

        /**
         * MeteringRequest workerMetadata.
         * @member {metering.IWorkerMetadataRequest|null|undefined} workerMetadata
         * @memberof metering.MeteringRequest
         * @instance
         */
        MeteringRequest.prototype.workerMetadata = null;

        /**
         * MeteringRequest submitWorkerExecutionTime.
         * @member {metering.ISubmitWorkerExecutionTimeRequest|null|undefined} submitWorkerExecutionTime
         * @memberof metering.MeteringRequest
         * @instance
         */
        MeteringRequest.prototype.submitWorkerExecutionTime = null;

        /**
         * MeteringRequest dcrInteraction.
         * @member {metering.IDcrInteractionRequest|null|undefined} dcrInteraction
         * @memberof metering.MeteringRequest
         * @instance
         */
        MeteringRequest.prototype.dcrInteraction = null;

        /**
         * MeteringRequest createDataset.
         * @member {metering.ICreateDatasetRequest|null|undefined} createDataset
         * @memberof metering.MeteringRequest
         * @instance
         */
        MeteringRequest.prototype.createDataset = null;

        /**
         * MeteringRequest getOrCreateDatasetScope.
         * @member {metering.IGetOrCreateDatasetScopeRequest|null|undefined} getOrCreateDatasetScope
         * @memberof metering.MeteringRequest
         * @instance
         */
        MeteringRequest.prototype.getOrCreateDatasetScope = null;

        /**
         * MeteringRequest mergeDcrCommit.
         * @member {metering.IMergeDcrCommitRequest|null|undefined} mergeDcrCommit
         * @memberof metering.MeteringRequest
         * @instance
         */
        MeteringRequest.prototype.mergeDcrCommit = null;

        /**
         * MeteringRequest getDataConnectorCredentials.
         * @member {metering.IGetDataConnectorCredentialsRequest|null|undefined} getDataConnectorCredentials
         * @memberof metering.MeteringRequest
         * @instance
         */
        MeteringRequest.prototype.getDataConnectorCredentials = null;

        /**
         * MeteringRequest integrityStore.
         * @member {integrity_store_api.IIntegrityStoreRequest|null|undefined} integrityStore
         * @memberof metering.MeteringRequest
         * @instance
         */
        MeteringRequest.prototype.integrityStore = null;

        /**
         * MeteringRequest getAzureCommunicationCredentials.
         * @member {metering.IGetAzureCommunicationCredentialsRequest|null|undefined} getAzureCommunicationCredentials
         * @memberof metering.MeteringRequest
         * @instance
         */
        MeteringRequest.prototype.getAzureCommunicationCredentials = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MeteringRequest request.
         * @member {"createDcr"|"createDcrCommit"|"stopDcr"|"publishDataset"|"unpublishDataset"|"workerMetadata"|"submitWorkerExecutionTime"|"dcrInteraction"|"createDataset"|"getOrCreateDatasetScope"|"mergeDcrCommit"|"getDataConnectorCredentials"|"integrityStore"|"getAzureCommunicationCredentials"|undefined} request
         * @memberof metering.MeteringRequest
         * @instance
         */
        Object.defineProperty(MeteringRequest.prototype, "request", {
            get: $util.oneOfGetter($oneOfFields = ["createDcr", "createDcrCommit", "stopDcr", "publishDataset", "unpublishDataset", "workerMetadata", "submitWorkerExecutionTime", "dcrInteraction", "createDataset", "getOrCreateDatasetScope", "mergeDcrCommit", "getDataConnectorCredentials", "integrityStore", "getAzureCommunicationCredentials"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MeteringRequest instance using the specified properties.
         * @function create
         * @memberof metering.MeteringRequest
         * @static
         * @param {metering.IMeteringRequest=} [properties] Properties to set
         * @returns {metering.MeteringRequest} MeteringRequest instance
         */
        MeteringRequest.create = function create(properties) {
            return new MeteringRequest(properties);
        };

        /**
         * Encodes the specified MeteringRequest message. Does not implicitly {@link metering.MeteringRequest.verify|verify} messages.
         * @function encode
         * @memberof metering.MeteringRequest
         * @static
         * @param {metering.IMeteringRequest} message MeteringRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeteringRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.createDcr != null && Object.hasOwnProperty.call(message, "createDcr"))
                $root.metering.CreateDcrRequest.encode(message.createDcr, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.createDcrCommit != null && Object.hasOwnProperty.call(message, "createDcrCommit"))
                $root.metering.CreateDcrCommitRequest.encode(message.createDcrCommit, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.stopDcr != null && Object.hasOwnProperty.call(message, "stopDcr"))
                $root.metering.StopDcrRequest.encode(message.stopDcr, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.publishDataset != null && Object.hasOwnProperty.call(message, "publishDataset"))
                $root.metering.PublishDatasetRequest.encode(message.publishDataset, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.unpublishDataset != null && Object.hasOwnProperty.call(message, "unpublishDataset"))
                $root.metering.UnpublishDatasetRequest.encode(message.unpublishDataset, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.workerMetadata != null && Object.hasOwnProperty.call(message, "workerMetadata"))
                $root.metering.WorkerMetadataRequest.encode(message.workerMetadata, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.submitWorkerExecutionTime != null && Object.hasOwnProperty.call(message, "submitWorkerExecutionTime"))
                $root.metering.SubmitWorkerExecutionTimeRequest.encode(message.submitWorkerExecutionTime, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.dcrInteraction != null && Object.hasOwnProperty.call(message, "dcrInteraction"))
                $root.metering.DcrInteractionRequest.encode(message.dcrInteraction, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.createDataset != null && Object.hasOwnProperty.call(message, "createDataset"))
                $root.metering.CreateDatasetRequest.encode(message.createDataset, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.getOrCreateDatasetScope != null && Object.hasOwnProperty.call(message, "getOrCreateDatasetScope"))
                $root.metering.GetOrCreateDatasetScopeRequest.encode(message.getOrCreateDatasetScope, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.mergeDcrCommit != null && Object.hasOwnProperty.call(message, "mergeDcrCommit"))
                $root.metering.MergeDcrCommitRequest.encode(message.mergeDcrCommit, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.getDataConnectorCredentials != null && Object.hasOwnProperty.call(message, "getDataConnectorCredentials"))
                $root.metering.GetDataConnectorCredentialsRequest.encode(message.getDataConnectorCredentials, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.integrityStore != null && Object.hasOwnProperty.call(message, "integrityStore"))
                $root.integrity_store_api.IntegrityStoreRequest.encode(message.integrityStore, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.getAzureCommunicationCredentials != null && Object.hasOwnProperty.call(message, "getAzureCommunicationCredentials"))
                $root.metering.GetAzureCommunicationCredentialsRequest.encode(message.getAzureCommunicationCredentials, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MeteringRequest message, length delimited. Does not implicitly {@link metering.MeteringRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.MeteringRequest
         * @static
         * @param {metering.IMeteringRequest} message MeteringRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeteringRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MeteringRequest message from the specified reader or buffer.
         * @function decode
         * @memberof metering.MeteringRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.MeteringRequest} MeteringRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeteringRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.MeteringRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.createDcr = $root.metering.CreateDcrRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.createDcrCommit = $root.metering.CreateDcrCommitRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.stopDcr = $root.metering.StopDcrRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.publishDataset = $root.metering.PublishDatasetRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.unpublishDataset = $root.metering.UnpublishDatasetRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.workerMetadata = $root.metering.WorkerMetadataRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.submitWorkerExecutionTime = $root.metering.SubmitWorkerExecutionTimeRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.dcrInteraction = $root.metering.DcrInteractionRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.createDataset = $root.metering.CreateDatasetRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.getOrCreateDatasetScope = $root.metering.GetOrCreateDatasetScopeRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.mergeDcrCommit = $root.metering.MergeDcrCommitRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.getDataConnectorCredentials = $root.metering.GetDataConnectorCredentialsRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.integrityStore = $root.integrity_store_api.IntegrityStoreRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 14: {
                        message.getAzureCommunicationCredentials = $root.metering.GetAzureCommunicationCredentialsRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MeteringRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.MeteringRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.MeteringRequest} MeteringRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeteringRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MeteringRequest message.
         * @function verify
         * @memberof metering.MeteringRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MeteringRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.createDcr != null && message.hasOwnProperty("createDcr")) {
                properties.request = 1;
                {
                    let error = $root.metering.CreateDcrRequest.verify(message.createDcr);
                    if (error)
                        return "createDcr." + error;
                }
            }
            if (message.createDcrCommit != null && message.hasOwnProperty("createDcrCommit")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.metering.CreateDcrCommitRequest.verify(message.createDcrCommit);
                    if (error)
                        return "createDcrCommit." + error;
                }
            }
            if (message.stopDcr != null && message.hasOwnProperty("stopDcr")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.metering.StopDcrRequest.verify(message.stopDcr);
                    if (error)
                        return "stopDcr." + error;
                }
            }
            if (message.publishDataset != null && message.hasOwnProperty("publishDataset")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.metering.PublishDatasetRequest.verify(message.publishDataset);
                    if (error)
                        return "publishDataset." + error;
                }
            }
            if (message.unpublishDataset != null && message.hasOwnProperty("unpublishDataset")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.metering.UnpublishDatasetRequest.verify(message.unpublishDataset);
                    if (error)
                        return "unpublishDataset." + error;
                }
            }
            if (message.workerMetadata != null && message.hasOwnProperty("workerMetadata")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.metering.WorkerMetadataRequest.verify(message.workerMetadata);
                    if (error)
                        return "workerMetadata." + error;
                }
            }
            if (message.submitWorkerExecutionTime != null && message.hasOwnProperty("submitWorkerExecutionTime")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.metering.SubmitWorkerExecutionTimeRequest.verify(message.submitWorkerExecutionTime);
                    if (error)
                        return "submitWorkerExecutionTime." + error;
                }
            }
            if (message.dcrInteraction != null && message.hasOwnProperty("dcrInteraction")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.metering.DcrInteractionRequest.verify(message.dcrInteraction);
                    if (error)
                        return "dcrInteraction." + error;
                }
            }
            if (message.createDataset != null && message.hasOwnProperty("createDataset")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.metering.CreateDatasetRequest.verify(message.createDataset);
                    if (error)
                        return "createDataset." + error;
                }
            }
            if (message.getOrCreateDatasetScope != null && message.hasOwnProperty("getOrCreateDatasetScope")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.metering.GetOrCreateDatasetScopeRequest.verify(message.getOrCreateDatasetScope);
                    if (error)
                        return "getOrCreateDatasetScope." + error;
                }
            }
            if (message.mergeDcrCommit != null && message.hasOwnProperty("mergeDcrCommit")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.metering.MergeDcrCommitRequest.verify(message.mergeDcrCommit);
                    if (error)
                        return "mergeDcrCommit." + error;
                }
            }
            if (message.getDataConnectorCredentials != null && message.hasOwnProperty("getDataConnectorCredentials")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.metering.GetDataConnectorCredentialsRequest.verify(message.getDataConnectorCredentials);
                    if (error)
                        return "getDataConnectorCredentials." + error;
                }
            }
            if (message.integrityStore != null && message.hasOwnProperty("integrityStore")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.integrity_store_api.IntegrityStoreRequest.verify(message.integrityStore);
                    if (error)
                        return "integrityStore." + error;
                }
            }
            if (message.getAzureCommunicationCredentials != null && message.hasOwnProperty("getAzureCommunicationCredentials")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.metering.GetAzureCommunicationCredentialsRequest.verify(message.getAzureCommunicationCredentials);
                    if (error)
                        return "getAzureCommunicationCredentials." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MeteringRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.MeteringRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.MeteringRequest} MeteringRequest
         */
        MeteringRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.MeteringRequest)
                return object;
            let message = new $root.metering.MeteringRequest();
            if (object.createDcr != null) {
                if (typeof object.createDcr !== "object")
                    throw TypeError(".metering.MeteringRequest.createDcr: object expected");
                message.createDcr = $root.metering.CreateDcrRequest.fromObject(object.createDcr);
            }
            if (object.createDcrCommit != null) {
                if (typeof object.createDcrCommit !== "object")
                    throw TypeError(".metering.MeteringRequest.createDcrCommit: object expected");
                message.createDcrCommit = $root.metering.CreateDcrCommitRequest.fromObject(object.createDcrCommit);
            }
            if (object.stopDcr != null) {
                if (typeof object.stopDcr !== "object")
                    throw TypeError(".metering.MeteringRequest.stopDcr: object expected");
                message.stopDcr = $root.metering.StopDcrRequest.fromObject(object.stopDcr);
            }
            if (object.publishDataset != null) {
                if (typeof object.publishDataset !== "object")
                    throw TypeError(".metering.MeteringRequest.publishDataset: object expected");
                message.publishDataset = $root.metering.PublishDatasetRequest.fromObject(object.publishDataset);
            }
            if (object.unpublishDataset != null) {
                if (typeof object.unpublishDataset !== "object")
                    throw TypeError(".metering.MeteringRequest.unpublishDataset: object expected");
                message.unpublishDataset = $root.metering.UnpublishDatasetRequest.fromObject(object.unpublishDataset);
            }
            if (object.workerMetadata != null) {
                if (typeof object.workerMetadata !== "object")
                    throw TypeError(".metering.MeteringRequest.workerMetadata: object expected");
                message.workerMetadata = $root.metering.WorkerMetadataRequest.fromObject(object.workerMetadata);
            }
            if (object.submitWorkerExecutionTime != null) {
                if (typeof object.submitWorkerExecutionTime !== "object")
                    throw TypeError(".metering.MeteringRequest.submitWorkerExecutionTime: object expected");
                message.submitWorkerExecutionTime = $root.metering.SubmitWorkerExecutionTimeRequest.fromObject(object.submitWorkerExecutionTime);
            }
            if (object.dcrInteraction != null) {
                if (typeof object.dcrInteraction !== "object")
                    throw TypeError(".metering.MeteringRequest.dcrInteraction: object expected");
                message.dcrInteraction = $root.metering.DcrInteractionRequest.fromObject(object.dcrInteraction);
            }
            if (object.createDataset != null) {
                if (typeof object.createDataset !== "object")
                    throw TypeError(".metering.MeteringRequest.createDataset: object expected");
                message.createDataset = $root.metering.CreateDatasetRequest.fromObject(object.createDataset);
            }
            if (object.getOrCreateDatasetScope != null) {
                if (typeof object.getOrCreateDatasetScope !== "object")
                    throw TypeError(".metering.MeteringRequest.getOrCreateDatasetScope: object expected");
                message.getOrCreateDatasetScope = $root.metering.GetOrCreateDatasetScopeRequest.fromObject(object.getOrCreateDatasetScope);
            }
            if (object.mergeDcrCommit != null) {
                if (typeof object.mergeDcrCommit !== "object")
                    throw TypeError(".metering.MeteringRequest.mergeDcrCommit: object expected");
                message.mergeDcrCommit = $root.metering.MergeDcrCommitRequest.fromObject(object.mergeDcrCommit);
            }
            if (object.getDataConnectorCredentials != null) {
                if (typeof object.getDataConnectorCredentials !== "object")
                    throw TypeError(".metering.MeteringRequest.getDataConnectorCredentials: object expected");
                message.getDataConnectorCredentials = $root.metering.GetDataConnectorCredentialsRequest.fromObject(object.getDataConnectorCredentials);
            }
            if (object.integrityStore != null) {
                if (typeof object.integrityStore !== "object")
                    throw TypeError(".metering.MeteringRequest.integrityStore: object expected");
                message.integrityStore = $root.integrity_store_api.IntegrityStoreRequest.fromObject(object.integrityStore);
            }
            if (object.getAzureCommunicationCredentials != null) {
                if (typeof object.getAzureCommunicationCredentials !== "object")
                    throw TypeError(".metering.MeteringRequest.getAzureCommunicationCredentials: object expected");
                message.getAzureCommunicationCredentials = $root.metering.GetAzureCommunicationCredentialsRequest.fromObject(object.getAzureCommunicationCredentials);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeteringRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.MeteringRequest
         * @static
         * @param {metering.MeteringRequest} message MeteringRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeteringRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.createDcr != null && message.hasOwnProperty("createDcr")) {
                object.createDcr = $root.metering.CreateDcrRequest.toObject(message.createDcr, options);
                if (options.oneofs)
                    object.request = "createDcr";
            }
            if (message.createDcrCommit != null && message.hasOwnProperty("createDcrCommit")) {
                object.createDcrCommit = $root.metering.CreateDcrCommitRequest.toObject(message.createDcrCommit, options);
                if (options.oneofs)
                    object.request = "createDcrCommit";
            }
            if (message.stopDcr != null && message.hasOwnProperty("stopDcr")) {
                object.stopDcr = $root.metering.StopDcrRequest.toObject(message.stopDcr, options);
                if (options.oneofs)
                    object.request = "stopDcr";
            }
            if (message.publishDataset != null && message.hasOwnProperty("publishDataset")) {
                object.publishDataset = $root.metering.PublishDatasetRequest.toObject(message.publishDataset, options);
                if (options.oneofs)
                    object.request = "publishDataset";
            }
            if (message.unpublishDataset != null && message.hasOwnProperty("unpublishDataset")) {
                object.unpublishDataset = $root.metering.UnpublishDatasetRequest.toObject(message.unpublishDataset, options);
                if (options.oneofs)
                    object.request = "unpublishDataset";
            }
            if (message.workerMetadata != null && message.hasOwnProperty("workerMetadata")) {
                object.workerMetadata = $root.metering.WorkerMetadataRequest.toObject(message.workerMetadata, options);
                if (options.oneofs)
                    object.request = "workerMetadata";
            }
            if (message.submitWorkerExecutionTime != null && message.hasOwnProperty("submitWorkerExecutionTime")) {
                object.submitWorkerExecutionTime = $root.metering.SubmitWorkerExecutionTimeRequest.toObject(message.submitWorkerExecutionTime, options);
                if (options.oneofs)
                    object.request = "submitWorkerExecutionTime";
            }
            if (message.dcrInteraction != null && message.hasOwnProperty("dcrInteraction")) {
                object.dcrInteraction = $root.metering.DcrInteractionRequest.toObject(message.dcrInteraction, options);
                if (options.oneofs)
                    object.request = "dcrInteraction";
            }
            if (message.createDataset != null && message.hasOwnProperty("createDataset")) {
                object.createDataset = $root.metering.CreateDatasetRequest.toObject(message.createDataset, options);
                if (options.oneofs)
                    object.request = "createDataset";
            }
            if (message.getOrCreateDatasetScope != null && message.hasOwnProperty("getOrCreateDatasetScope")) {
                object.getOrCreateDatasetScope = $root.metering.GetOrCreateDatasetScopeRequest.toObject(message.getOrCreateDatasetScope, options);
                if (options.oneofs)
                    object.request = "getOrCreateDatasetScope";
            }
            if (message.mergeDcrCommit != null && message.hasOwnProperty("mergeDcrCommit")) {
                object.mergeDcrCommit = $root.metering.MergeDcrCommitRequest.toObject(message.mergeDcrCommit, options);
                if (options.oneofs)
                    object.request = "mergeDcrCommit";
            }
            if (message.getDataConnectorCredentials != null && message.hasOwnProperty("getDataConnectorCredentials")) {
                object.getDataConnectorCredentials = $root.metering.GetDataConnectorCredentialsRequest.toObject(message.getDataConnectorCredentials, options);
                if (options.oneofs)
                    object.request = "getDataConnectorCredentials";
            }
            if (message.integrityStore != null && message.hasOwnProperty("integrityStore")) {
                object.integrityStore = $root.integrity_store_api.IntegrityStoreRequest.toObject(message.integrityStore, options);
                if (options.oneofs)
                    object.request = "integrityStore";
            }
            if (message.getAzureCommunicationCredentials != null && message.hasOwnProperty("getAzureCommunicationCredentials")) {
                object.getAzureCommunicationCredentials = $root.metering.GetAzureCommunicationCredentialsRequest.toObject(message.getAzureCommunicationCredentials, options);
                if (options.oneofs)
                    object.request = "getAzureCommunicationCredentials";
            }
            return object;
        };

        /**
         * Converts this MeteringRequest to JSON.
         * @function toJSON
         * @memberof metering.MeteringRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeteringRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeteringRequest
         * @function getTypeUrl
         * @memberof metering.MeteringRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.MeteringRequest";
        };

        return MeteringRequest;
    })();

    metering.MeteringSuccessResponse = (function() {

        /**
         * Properties of a MeteringSuccessResponse.
         * @memberof metering
         * @interface IMeteringSuccessResponse
         * @property {metering.ICreateDcrResponse|null} [createDcr] MeteringSuccessResponse createDcr
         * @property {metering.ICreateDcrCommitResponse|null} [createDcrCommit] MeteringSuccessResponse createDcrCommit
         * @property {metering.IStopDcrResponse|null} [stopDcr] MeteringSuccessResponse stopDcr
         * @property {metering.IPublishDatasetResponse|null} [publishDataset] MeteringSuccessResponse publishDataset
         * @property {metering.IUnpublishDatasetResponse|null} [unpublishDataset] MeteringSuccessResponse unpublishDataset
         * @property {metering.IWorkerMetadataResponse|null} [workerMetadata] MeteringSuccessResponse workerMetadata
         * @property {metering.ISubmitWorkerExecutionTimeResponse|null} [submitWorkerExecutionTime] MeteringSuccessResponse submitWorkerExecutionTime
         * @property {metering.IDcrInteractionResponse|null} [dcrInteraction] MeteringSuccessResponse dcrInteraction
         * @property {metering.ICreateDatasetResponse|null} [createDataset] MeteringSuccessResponse createDataset
         * @property {metering.IGetOrCreateDatasetScopeResponse|null} [getOrCreateDatasetScope] MeteringSuccessResponse getOrCreateDatasetScope
         * @property {metering.IMergeDcrCommitResponse|null} [mergeDcrCommit] MeteringSuccessResponse mergeDcrCommit
         * @property {metering.IGetDataConnectorCredentialsResponse|null} [dataConnectorCredentials] MeteringSuccessResponse dataConnectorCredentials
         * @property {integrity_store_api.IIntegrityStoreResponse|null} [integrityStore] MeteringSuccessResponse integrityStore
         * @property {metering.IGetAzureCommunicationCredentialsResponse|null} [getAzureCommunicationCredentials] MeteringSuccessResponse getAzureCommunicationCredentials
         */

        /**
         * Constructs a new MeteringSuccessResponse.
         * @memberof metering
         * @classdesc Represents a MeteringSuccessResponse.
         * @implements IMeteringSuccessResponse
         * @constructor
         * @param {metering.IMeteringSuccessResponse=} [properties] Properties to set
         */
        function MeteringSuccessResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringSuccessResponse createDcr.
         * @member {metering.ICreateDcrResponse|null|undefined} createDcr
         * @memberof metering.MeteringSuccessResponse
         * @instance
         */
        MeteringSuccessResponse.prototype.createDcr = null;

        /**
         * MeteringSuccessResponse createDcrCommit.
         * @member {metering.ICreateDcrCommitResponse|null|undefined} createDcrCommit
         * @memberof metering.MeteringSuccessResponse
         * @instance
         */
        MeteringSuccessResponse.prototype.createDcrCommit = null;

        /**
         * MeteringSuccessResponse stopDcr.
         * @member {metering.IStopDcrResponse|null|undefined} stopDcr
         * @memberof metering.MeteringSuccessResponse
         * @instance
         */
        MeteringSuccessResponse.prototype.stopDcr = null;

        /**
         * MeteringSuccessResponse publishDataset.
         * @member {metering.IPublishDatasetResponse|null|undefined} publishDataset
         * @memberof metering.MeteringSuccessResponse
         * @instance
         */
        MeteringSuccessResponse.prototype.publishDataset = null;

        /**
         * MeteringSuccessResponse unpublishDataset.
         * @member {metering.IUnpublishDatasetResponse|null|undefined} unpublishDataset
         * @memberof metering.MeteringSuccessResponse
         * @instance
         */
        MeteringSuccessResponse.prototype.unpublishDataset = null;

        /**
         * MeteringSuccessResponse workerMetadata.
         * @member {metering.IWorkerMetadataResponse|null|undefined} workerMetadata
         * @memberof metering.MeteringSuccessResponse
         * @instance
         */
        MeteringSuccessResponse.prototype.workerMetadata = null;

        /**
         * MeteringSuccessResponse submitWorkerExecutionTime.
         * @member {metering.ISubmitWorkerExecutionTimeResponse|null|undefined} submitWorkerExecutionTime
         * @memberof metering.MeteringSuccessResponse
         * @instance
         */
        MeteringSuccessResponse.prototype.submitWorkerExecutionTime = null;

        /**
         * MeteringSuccessResponse dcrInteraction.
         * @member {metering.IDcrInteractionResponse|null|undefined} dcrInteraction
         * @memberof metering.MeteringSuccessResponse
         * @instance
         */
        MeteringSuccessResponse.prototype.dcrInteraction = null;

        /**
         * MeteringSuccessResponse createDataset.
         * @member {metering.ICreateDatasetResponse|null|undefined} createDataset
         * @memberof metering.MeteringSuccessResponse
         * @instance
         */
        MeteringSuccessResponse.prototype.createDataset = null;

        /**
         * MeteringSuccessResponse getOrCreateDatasetScope.
         * @member {metering.IGetOrCreateDatasetScopeResponse|null|undefined} getOrCreateDatasetScope
         * @memberof metering.MeteringSuccessResponse
         * @instance
         */
        MeteringSuccessResponse.prototype.getOrCreateDatasetScope = null;

        /**
         * MeteringSuccessResponse mergeDcrCommit.
         * @member {metering.IMergeDcrCommitResponse|null|undefined} mergeDcrCommit
         * @memberof metering.MeteringSuccessResponse
         * @instance
         */
        MeteringSuccessResponse.prototype.mergeDcrCommit = null;

        /**
         * MeteringSuccessResponse dataConnectorCredentials.
         * @member {metering.IGetDataConnectorCredentialsResponse|null|undefined} dataConnectorCredentials
         * @memberof metering.MeteringSuccessResponse
         * @instance
         */
        MeteringSuccessResponse.prototype.dataConnectorCredentials = null;

        /**
         * MeteringSuccessResponse integrityStore.
         * @member {integrity_store_api.IIntegrityStoreResponse|null|undefined} integrityStore
         * @memberof metering.MeteringSuccessResponse
         * @instance
         */
        MeteringSuccessResponse.prototype.integrityStore = null;

        /**
         * MeteringSuccessResponse getAzureCommunicationCredentials.
         * @member {metering.IGetAzureCommunicationCredentialsResponse|null|undefined} getAzureCommunicationCredentials
         * @memberof metering.MeteringSuccessResponse
         * @instance
         */
        MeteringSuccessResponse.prototype.getAzureCommunicationCredentials = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MeteringSuccessResponse response.
         * @member {"createDcr"|"createDcrCommit"|"stopDcr"|"publishDataset"|"unpublishDataset"|"workerMetadata"|"submitWorkerExecutionTime"|"dcrInteraction"|"createDataset"|"getOrCreateDatasetScope"|"mergeDcrCommit"|"dataConnectorCredentials"|"integrityStore"|"getAzureCommunicationCredentials"|undefined} response
         * @memberof metering.MeteringSuccessResponse
         * @instance
         */
        Object.defineProperty(MeteringSuccessResponse.prototype, "response", {
            get: $util.oneOfGetter($oneOfFields = ["createDcr", "createDcrCommit", "stopDcr", "publishDataset", "unpublishDataset", "workerMetadata", "submitWorkerExecutionTime", "dcrInteraction", "createDataset", "getOrCreateDatasetScope", "mergeDcrCommit", "dataConnectorCredentials", "integrityStore", "getAzureCommunicationCredentials"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MeteringSuccessResponse instance using the specified properties.
         * @function create
         * @memberof metering.MeteringSuccessResponse
         * @static
         * @param {metering.IMeteringSuccessResponse=} [properties] Properties to set
         * @returns {metering.MeteringSuccessResponse} MeteringSuccessResponse instance
         */
        MeteringSuccessResponse.create = function create(properties) {
            return new MeteringSuccessResponse(properties);
        };

        /**
         * Encodes the specified MeteringSuccessResponse message. Does not implicitly {@link metering.MeteringSuccessResponse.verify|verify} messages.
         * @function encode
         * @memberof metering.MeteringSuccessResponse
         * @static
         * @param {metering.IMeteringSuccessResponse} message MeteringSuccessResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeteringSuccessResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.createDcr != null && Object.hasOwnProperty.call(message, "createDcr"))
                $root.metering.CreateDcrResponse.encode(message.createDcr, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.createDcrCommit != null && Object.hasOwnProperty.call(message, "createDcrCommit"))
                $root.metering.CreateDcrCommitResponse.encode(message.createDcrCommit, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.stopDcr != null && Object.hasOwnProperty.call(message, "stopDcr"))
                $root.metering.StopDcrResponse.encode(message.stopDcr, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.publishDataset != null && Object.hasOwnProperty.call(message, "publishDataset"))
                $root.metering.PublishDatasetResponse.encode(message.publishDataset, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.unpublishDataset != null && Object.hasOwnProperty.call(message, "unpublishDataset"))
                $root.metering.UnpublishDatasetResponse.encode(message.unpublishDataset, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.workerMetadata != null && Object.hasOwnProperty.call(message, "workerMetadata"))
                $root.metering.WorkerMetadataResponse.encode(message.workerMetadata, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.submitWorkerExecutionTime != null && Object.hasOwnProperty.call(message, "submitWorkerExecutionTime"))
                $root.metering.SubmitWorkerExecutionTimeResponse.encode(message.submitWorkerExecutionTime, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.dcrInteraction != null && Object.hasOwnProperty.call(message, "dcrInteraction"))
                $root.metering.DcrInteractionResponse.encode(message.dcrInteraction, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.createDataset != null && Object.hasOwnProperty.call(message, "createDataset"))
                $root.metering.CreateDatasetResponse.encode(message.createDataset, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.getOrCreateDatasetScope != null && Object.hasOwnProperty.call(message, "getOrCreateDatasetScope"))
                $root.metering.GetOrCreateDatasetScopeResponse.encode(message.getOrCreateDatasetScope, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.mergeDcrCommit != null && Object.hasOwnProperty.call(message, "mergeDcrCommit"))
                $root.metering.MergeDcrCommitResponse.encode(message.mergeDcrCommit, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.dataConnectorCredentials != null && Object.hasOwnProperty.call(message, "dataConnectorCredentials"))
                $root.metering.GetDataConnectorCredentialsResponse.encode(message.dataConnectorCredentials, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.integrityStore != null && Object.hasOwnProperty.call(message, "integrityStore"))
                $root.integrity_store_api.IntegrityStoreResponse.encode(message.integrityStore, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.getAzureCommunicationCredentials != null && Object.hasOwnProperty.call(message, "getAzureCommunicationCredentials"))
                $root.metering.GetAzureCommunicationCredentialsResponse.encode(message.getAzureCommunicationCredentials, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MeteringSuccessResponse message, length delimited. Does not implicitly {@link metering.MeteringSuccessResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.MeteringSuccessResponse
         * @static
         * @param {metering.IMeteringSuccessResponse} message MeteringSuccessResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeteringSuccessResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MeteringSuccessResponse message from the specified reader or buffer.
         * @function decode
         * @memberof metering.MeteringSuccessResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.MeteringSuccessResponse} MeteringSuccessResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeteringSuccessResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.MeteringSuccessResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.createDcr = $root.metering.CreateDcrResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.createDcrCommit = $root.metering.CreateDcrCommitResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.stopDcr = $root.metering.StopDcrResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.publishDataset = $root.metering.PublishDatasetResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.unpublishDataset = $root.metering.UnpublishDatasetResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.workerMetadata = $root.metering.WorkerMetadataResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.submitWorkerExecutionTime = $root.metering.SubmitWorkerExecutionTimeResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.dcrInteraction = $root.metering.DcrInteractionResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.createDataset = $root.metering.CreateDatasetResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.getOrCreateDatasetScope = $root.metering.GetOrCreateDatasetScopeResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.mergeDcrCommit = $root.metering.MergeDcrCommitResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.dataConnectorCredentials = $root.metering.GetDataConnectorCredentialsResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.integrityStore = $root.integrity_store_api.IntegrityStoreResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 14: {
                        message.getAzureCommunicationCredentials = $root.metering.GetAzureCommunicationCredentialsResponse.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MeteringSuccessResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.MeteringSuccessResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.MeteringSuccessResponse} MeteringSuccessResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeteringSuccessResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MeteringSuccessResponse message.
         * @function verify
         * @memberof metering.MeteringSuccessResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MeteringSuccessResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.createDcr != null && message.hasOwnProperty("createDcr")) {
                properties.response = 1;
                {
                    let error = $root.metering.CreateDcrResponse.verify(message.createDcr);
                    if (error)
                        return "createDcr." + error;
                }
            }
            if (message.createDcrCommit != null && message.hasOwnProperty("createDcrCommit")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.metering.CreateDcrCommitResponse.verify(message.createDcrCommit);
                    if (error)
                        return "createDcrCommit." + error;
                }
            }
            if (message.stopDcr != null && message.hasOwnProperty("stopDcr")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.metering.StopDcrResponse.verify(message.stopDcr);
                    if (error)
                        return "stopDcr." + error;
                }
            }
            if (message.publishDataset != null && message.hasOwnProperty("publishDataset")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.metering.PublishDatasetResponse.verify(message.publishDataset);
                    if (error)
                        return "publishDataset." + error;
                }
            }
            if (message.unpublishDataset != null && message.hasOwnProperty("unpublishDataset")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.metering.UnpublishDatasetResponse.verify(message.unpublishDataset);
                    if (error)
                        return "unpublishDataset." + error;
                }
            }
            if (message.workerMetadata != null && message.hasOwnProperty("workerMetadata")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.metering.WorkerMetadataResponse.verify(message.workerMetadata);
                    if (error)
                        return "workerMetadata." + error;
                }
            }
            if (message.submitWorkerExecutionTime != null && message.hasOwnProperty("submitWorkerExecutionTime")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.metering.SubmitWorkerExecutionTimeResponse.verify(message.submitWorkerExecutionTime);
                    if (error)
                        return "submitWorkerExecutionTime." + error;
                }
            }
            if (message.dcrInteraction != null && message.hasOwnProperty("dcrInteraction")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.metering.DcrInteractionResponse.verify(message.dcrInteraction);
                    if (error)
                        return "dcrInteraction." + error;
                }
            }
            if (message.createDataset != null && message.hasOwnProperty("createDataset")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.metering.CreateDatasetResponse.verify(message.createDataset);
                    if (error)
                        return "createDataset." + error;
                }
            }
            if (message.getOrCreateDatasetScope != null && message.hasOwnProperty("getOrCreateDatasetScope")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.metering.GetOrCreateDatasetScopeResponse.verify(message.getOrCreateDatasetScope);
                    if (error)
                        return "getOrCreateDatasetScope." + error;
                }
            }
            if (message.mergeDcrCommit != null && message.hasOwnProperty("mergeDcrCommit")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.metering.MergeDcrCommitResponse.verify(message.mergeDcrCommit);
                    if (error)
                        return "mergeDcrCommit." + error;
                }
            }
            if (message.dataConnectorCredentials != null && message.hasOwnProperty("dataConnectorCredentials")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.metering.GetDataConnectorCredentialsResponse.verify(message.dataConnectorCredentials);
                    if (error)
                        return "dataConnectorCredentials." + error;
                }
            }
            if (message.integrityStore != null && message.hasOwnProperty("integrityStore")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.integrity_store_api.IntegrityStoreResponse.verify(message.integrityStore);
                    if (error)
                        return "integrityStore." + error;
                }
            }
            if (message.getAzureCommunicationCredentials != null && message.hasOwnProperty("getAzureCommunicationCredentials")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.metering.GetAzureCommunicationCredentialsResponse.verify(message.getAzureCommunicationCredentials);
                    if (error)
                        return "getAzureCommunicationCredentials." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MeteringSuccessResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.MeteringSuccessResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.MeteringSuccessResponse} MeteringSuccessResponse
         */
        MeteringSuccessResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.MeteringSuccessResponse)
                return object;
            let message = new $root.metering.MeteringSuccessResponse();
            if (object.createDcr != null) {
                if (typeof object.createDcr !== "object")
                    throw TypeError(".metering.MeteringSuccessResponse.createDcr: object expected");
                message.createDcr = $root.metering.CreateDcrResponse.fromObject(object.createDcr);
            }
            if (object.createDcrCommit != null) {
                if (typeof object.createDcrCommit !== "object")
                    throw TypeError(".metering.MeteringSuccessResponse.createDcrCommit: object expected");
                message.createDcrCommit = $root.metering.CreateDcrCommitResponse.fromObject(object.createDcrCommit);
            }
            if (object.stopDcr != null) {
                if (typeof object.stopDcr !== "object")
                    throw TypeError(".metering.MeteringSuccessResponse.stopDcr: object expected");
                message.stopDcr = $root.metering.StopDcrResponse.fromObject(object.stopDcr);
            }
            if (object.publishDataset != null) {
                if (typeof object.publishDataset !== "object")
                    throw TypeError(".metering.MeteringSuccessResponse.publishDataset: object expected");
                message.publishDataset = $root.metering.PublishDatasetResponse.fromObject(object.publishDataset);
            }
            if (object.unpublishDataset != null) {
                if (typeof object.unpublishDataset !== "object")
                    throw TypeError(".metering.MeteringSuccessResponse.unpublishDataset: object expected");
                message.unpublishDataset = $root.metering.UnpublishDatasetResponse.fromObject(object.unpublishDataset);
            }
            if (object.workerMetadata != null) {
                if (typeof object.workerMetadata !== "object")
                    throw TypeError(".metering.MeteringSuccessResponse.workerMetadata: object expected");
                message.workerMetadata = $root.metering.WorkerMetadataResponse.fromObject(object.workerMetadata);
            }
            if (object.submitWorkerExecutionTime != null) {
                if (typeof object.submitWorkerExecutionTime !== "object")
                    throw TypeError(".metering.MeteringSuccessResponse.submitWorkerExecutionTime: object expected");
                message.submitWorkerExecutionTime = $root.metering.SubmitWorkerExecutionTimeResponse.fromObject(object.submitWorkerExecutionTime);
            }
            if (object.dcrInteraction != null) {
                if (typeof object.dcrInteraction !== "object")
                    throw TypeError(".metering.MeteringSuccessResponse.dcrInteraction: object expected");
                message.dcrInteraction = $root.metering.DcrInteractionResponse.fromObject(object.dcrInteraction);
            }
            if (object.createDataset != null) {
                if (typeof object.createDataset !== "object")
                    throw TypeError(".metering.MeteringSuccessResponse.createDataset: object expected");
                message.createDataset = $root.metering.CreateDatasetResponse.fromObject(object.createDataset);
            }
            if (object.getOrCreateDatasetScope != null) {
                if (typeof object.getOrCreateDatasetScope !== "object")
                    throw TypeError(".metering.MeteringSuccessResponse.getOrCreateDatasetScope: object expected");
                message.getOrCreateDatasetScope = $root.metering.GetOrCreateDatasetScopeResponse.fromObject(object.getOrCreateDatasetScope);
            }
            if (object.mergeDcrCommit != null) {
                if (typeof object.mergeDcrCommit !== "object")
                    throw TypeError(".metering.MeteringSuccessResponse.mergeDcrCommit: object expected");
                message.mergeDcrCommit = $root.metering.MergeDcrCommitResponse.fromObject(object.mergeDcrCommit);
            }
            if (object.dataConnectorCredentials != null) {
                if (typeof object.dataConnectorCredentials !== "object")
                    throw TypeError(".metering.MeteringSuccessResponse.dataConnectorCredentials: object expected");
                message.dataConnectorCredentials = $root.metering.GetDataConnectorCredentialsResponse.fromObject(object.dataConnectorCredentials);
            }
            if (object.integrityStore != null) {
                if (typeof object.integrityStore !== "object")
                    throw TypeError(".metering.MeteringSuccessResponse.integrityStore: object expected");
                message.integrityStore = $root.integrity_store_api.IntegrityStoreResponse.fromObject(object.integrityStore);
            }
            if (object.getAzureCommunicationCredentials != null) {
                if (typeof object.getAzureCommunicationCredentials !== "object")
                    throw TypeError(".metering.MeteringSuccessResponse.getAzureCommunicationCredentials: object expected");
                message.getAzureCommunicationCredentials = $root.metering.GetAzureCommunicationCredentialsResponse.fromObject(object.getAzureCommunicationCredentials);
            }
            return message;
        };

        /**
         * Creates a plain object from a MeteringSuccessResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.MeteringSuccessResponse
         * @static
         * @param {metering.MeteringSuccessResponse} message MeteringSuccessResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeteringSuccessResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.createDcr != null && message.hasOwnProperty("createDcr")) {
                object.createDcr = $root.metering.CreateDcrResponse.toObject(message.createDcr, options);
                if (options.oneofs)
                    object.response = "createDcr";
            }
            if (message.createDcrCommit != null && message.hasOwnProperty("createDcrCommit")) {
                object.createDcrCommit = $root.metering.CreateDcrCommitResponse.toObject(message.createDcrCommit, options);
                if (options.oneofs)
                    object.response = "createDcrCommit";
            }
            if (message.stopDcr != null && message.hasOwnProperty("stopDcr")) {
                object.stopDcr = $root.metering.StopDcrResponse.toObject(message.stopDcr, options);
                if (options.oneofs)
                    object.response = "stopDcr";
            }
            if (message.publishDataset != null && message.hasOwnProperty("publishDataset")) {
                object.publishDataset = $root.metering.PublishDatasetResponse.toObject(message.publishDataset, options);
                if (options.oneofs)
                    object.response = "publishDataset";
            }
            if (message.unpublishDataset != null && message.hasOwnProperty("unpublishDataset")) {
                object.unpublishDataset = $root.metering.UnpublishDatasetResponse.toObject(message.unpublishDataset, options);
                if (options.oneofs)
                    object.response = "unpublishDataset";
            }
            if (message.workerMetadata != null && message.hasOwnProperty("workerMetadata")) {
                object.workerMetadata = $root.metering.WorkerMetadataResponse.toObject(message.workerMetadata, options);
                if (options.oneofs)
                    object.response = "workerMetadata";
            }
            if (message.submitWorkerExecutionTime != null && message.hasOwnProperty("submitWorkerExecutionTime")) {
                object.submitWorkerExecutionTime = $root.metering.SubmitWorkerExecutionTimeResponse.toObject(message.submitWorkerExecutionTime, options);
                if (options.oneofs)
                    object.response = "submitWorkerExecutionTime";
            }
            if (message.dcrInteraction != null && message.hasOwnProperty("dcrInteraction")) {
                object.dcrInteraction = $root.metering.DcrInteractionResponse.toObject(message.dcrInteraction, options);
                if (options.oneofs)
                    object.response = "dcrInteraction";
            }
            if (message.createDataset != null && message.hasOwnProperty("createDataset")) {
                object.createDataset = $root.metering.CreateDatasetResponse.toObject(message.createDataset, options);
                if (options.oneofs)
                    object.response = "createDataset";
            }
            if (message.getOrCreateDatasetScope != null && message.hasOwnProperty("getOrCreateDatasetScope")) {
                object.getOrCreateDatasetScope = $root.metering.GetOrCreateDatasetScopeResponse.toObject(message.getOrCreateDatasetScope, options);
                if (options.oneofs)
                    object.response = "getOrCreateDatasetScope";
            }
            if (message.mergeDcrCommit != null && message.hasOwnProperty("mergeDcrCommit")) {
                object.mergeDcrCommit = $root.metering.MergeDcrCommitResponse.toObject(message.mergeDcrCommit, options);
                if (options.oneofs)
                    object.response = "mergeDcrCommit";
            }
            if (message.dataConnectorCredentials != null && message.hasOwnProperty("dataConnectorCredentials")) {
                object.dataConnectorCredentials = $root.metering.GetDataConnectorCredentialsResponse.toObject(message.dataConnectorCredentials, options);
                if (options.oneofs)
                    object.response = "dataConnectorCredentials";
            }
            if (message.integrityStore != null && message.hasOwnProperty("integrityStore")) {
                object.integrityStore = $root.integrity_store_api.IntegrityStoreResponse.toObject(message.integrityStore, options);
                if (options.oneofs)
                    object.response = "integrityStore";
            }
            if (message.getAzureCommunicationCredentials != null && message.hasOwnProperty("getAzureCommunicationCredentials")) {
                object.getAzureCommunicationCredentials = $root.metering.GetAzureCommunicationCredentialsResponse.toObject(message.getAzureCommunicationCredentials, options);
                if (options.oneofs)
                    object.response = "getAzureCommunicationCredentials";
            }
            return object;
        };

        /**
         * Converts this MeteringSuccessResponse to JSON.
         * @function toJSON
         * @memberof metering.MeteringSuccessResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeteringSuccessResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeteringSuccessResponse
         * @function getTypeUrl
         * @memberof metering.MeteringSuccessResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringSuccessResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.MeteringSuccessResponse";
        };

        return MeteringSuccessResponse;
    })();

    metering.MeteringResponse = (function() {

        /**
         * Properties of a MeteringResponse.
         * @memberof metering
         * @interface IMeteringResponse
         * @property {metering.IMeteringSuccessResponse|null} [success] MeteringResponse success
         * @property {string|null} [failure] MeteringResponse failure
         */

        /**
         * Constructs a new MeteringResponse.
         * @memberof metering
         * @classdesc Represents a MeteringResponse.
         * @implements IMeteringResponse
         * @constructor
         * @param {metering.IMeteringResponse=} [properties] Properties to set
         */
        function MeteringResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringResponse success.
         * @member {metering.IMeteringSuccessResponse|null|undefined} success
         * @memberof metering.MeteringResponse
         * @instance
         */
        MeteringResponse.prototype.success = null;

        /**
         * MeteringResponse failure.
         * @member {string|null|undefined} failure
         * @memberof metering.MeteringResponse
         * @instance
         */
        MeteringResponse.prototype.failure = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MeteringResponse response.
         * @member {"success"|"failure"|undefined} response
         * @memberof metering.MeteringResponse
         * @instance
         */
        Object.defineProperty(MeteringResponse.prototype, "response", {
            get: $util.oneOfGetter($oneOfFields = ["success", "failure"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MeteringResponse instance using the specified properties.
         * @function create
         * @memberof metering.MeteringResponse
         * @static
         * @param {metering.IMeteringResponse=} [properties] Properties to set
         * @returns {metering.MeteringResponse} MeteringResponse instance
         */
        MeteringResponse.create = function create(properties) {
            return new MeteringResponse(properties);
        };

        /**
         * Encodes the specified MeteringResponse message. Does not implicitly {@link metering.MeteringResponse.verify|verify} messages.
         * @function encode
         * @memberof metering.MeteringResponse
         * @static
         * @param {metering.IMeteringResponse} message MeteringResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeteringResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                $root.metering.MeteringSuccessResponse.encode(message.success, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.failure != null && Object.hasOwnProperty.call(message, "failure"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.failure);
            return writer;
        };

        /**
         * Encodes the specified MeteringResponse message, length delimited. Does not implicitly {@link metering.MeteringResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.MeteringResponse
         * @static
         * @param {metering.IMeteringResponse} message MeteringResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeteringResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MeteringResponse message from the specified reader or buffer.
         * @function decode
         * @memberof metering.MeteringResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.MeteringResponse} MeteringResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeteringResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.MeteringResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.success = $root.metering.MeteringSuccessResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.failure = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MeteringResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.MeteringResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.MeteringResponse} MeteringResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeteringResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MeteringResponse message.
         * @function verify
         * @memberof metering.MeteringResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MeteringResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.success != null && message.hasOwnProperty("success")) {
                properties.response = 1;
                {
                    let error = $root.metering.MeteringSuccessResponse.verify(message.success);
                    if (error)
                        return "success." + error;
                }
            }
            if (message.failure != null && message.hasOwnProperty("failure")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                if (!$util.isString(message.failure))
                    return "failure: string expected";
            }
            return null;
        };

        /**
         * Creates a MeteringResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.MeteringResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.MeteringResponse} MeteringResponse
         */
        MeteringResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.MeteringResponse)
                return object;
            let message = new $root.metering.MeteringResponse();
            if (object.success != null) {
                if (typeof object.success !== "object")
                    throw TypeError(".metering.MeteringResponse.success: object expected");
                message.success = $root.metering.MeteringSuccessResponse.fromObject(object.success);
            }
            if (object.failure != null)
                message.failure = String(object.failure);
            return message;
        };

        /**
         * Creates a plain object from a MeteringResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.MeteringResponse
         * @static
         * @param {metering.MeteringResponse} message MeteringResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeteringResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.success != null && message.hasOwnProperty("success")) {
                object.success = $root.metering.MeteringSuccessResponse.toObject(message.success, options);
                if (options.oneofs)
                    object.response = "success";
            }
            if (message.failure != null && message.hasOwnProperty("failure")) {
                object.failure = message.failure;
                if (options.oneofs)
                    object.response = "failure";
            }
            return object;
        };

        /**
         * Converts this MeteringResponse to JSON.
         * @function toJSON
         * @memberof metering.MeteringResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeteringResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MeteringResponse
         * @function getTypeUrl
         * @memberof metering.MeteringResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.MeteringResponse";
        };

        return MeteringResponse;
    })();

    /**
     * CreateDcrPurpose enum.
     * @name metering.CreateDcrPurpose
     * @enum {number}
     * @property {number} STANDARD=0 STANDARD value
     * @property {number} VALIDATION=1 VALIDATION value
     * @property {number} DATA_IMPORT=2 DATA_IMPORT value
     * @property {number} DATA_EXPORT=3 DATA_EXPORT value
     * @property {number} DATA_LAB=4 DATA_LAB value
     */
    metering.CreateDcrPurpose = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "STANDARD"] = 0;
        values[valuesById[1] = "VALIDATION"] = 1;
        values[valuesById[2] = "DATA_IMPORT"] = 2;
        values[valuesById[3] = "DATA_EXPORT"] = 3;
        values[valuesById[4] = "DATA_LAB"] = 4;
        return values;
    })();

    /**
     * CreateDcrKind enum.
     * @name metering.CreateDcrKind
     * @enum {number}
     * @property {number} EXPERT=0 EXPERT value
     * @property {number} DATASCIENCE=1 DATASCIENCE value
     * @property {number} MEDIA=2 MEDIA value
     * @property {number} LOOKALIKE_MEDIA=3 LOOKALIKE_MEDIA value
     * @property {number} MEDIA_INSIGHTS=4 MEDIA_INSIGHTS value
     * @property {number} AB_MEDIA=5 AB_MEDIA value
     */
    metering.CreateDcrKind = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "EXPERT"] = 0;
        values[valuesById[1] = "DATASCIENCE"] = 1;
        values[valuesById[2] = "MEDIA"] = 2;
        values[valuesById[3] = "LOOKALIKE_MEDIA"] = 3;
        values[valuesById[4] = "MEDIA_INSIGHTS"] = 4;
        values[valuesById[5] = "AB_MEDIA"] = 5;
        return values;
    })();

    metering.DcrMetadata = (function() {

        /**
         * Properties of a DcrMetadata.
         * @memberof metering
         * @interface IDcrMetadata
         * @property {metering.CreateDcrPurpose|null} [purpose] Why this data room is being created (allows for purpose-based filtering of data rooms)
         * @property {boolean|null} [showOrganizationLogo] Whether to show the organization logo
         * @property {boolean|null} [requirePassword] Whether the DCR requires a password
         * @property {metering.CreateDcrKind|null} [kind] The dcr type
         */

        /**
         * Constructs a new DcrMetadata.
         * @memberof metering
         * @classdesc Represents a DcrMetadata.
         * @implements IDcrMetadata
         * @constructor
         * @param {metering.IDcrMetadata=} [properties] Properties to set
         */
        function DcrMetadata(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Why this data room is being created (allows for purpose-based filtering of data rooms)
         * @member {metering.CreateDcrPurpose} purpose
         * @memberof metering.DcrMetadata
         * @instance
         */
        DcrMetadata.prototype.purpose = 0;

        /**
         * Whether to show the organization logo
         * @member {boolean} showOrganizationLogo
         * @memberof metering.DcrMetadata
         * @instance
         */
        DcrMetadata.prototype.showOrganizationLogo = false;

        /**
         * Whether the DCR requires a password
         * @member {boolean} requirePassword
         * @memberof metering.DcrMetadata
         * @instance
         */
        DcrMetadata.prototype.requirePassword = false;

        /**
         * The dcr type
         * @member {metering.CreateDcrKind} kind
         * @memberof metering.DcrMetadata
         * @instance
         */
        DcrMetadata.prototype.kind = 0;

        /**
         * Creates a new DcrMetadata instance using the specified properties.
         * @function create
         * @memberof metering.DcrMetadata
         * @static
         * @param {metering.IDcrMetadata=} [properties] Properties to set
         * @returns {metering.DcrMetadata} DcrMetadata instance
         */
        DcrMetadata.create = function create(properties) {
            return new DcrMetadata(properties);
        };

        /**
         * Encodes the specified DcrMetadata message. Does not implicitly {@link metering.DcrMetadata.verify|verify} messages.
         * @function encode
         * @memberof metering.DcrMetadata
         * @static
         * @param {metering.IDcrMetadata} message DcrMetadata message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrMetadata.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.purpose != null && Object.hasOwnProperty.call(message, "purpose"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.purpose);
            if (message.showOrganizationLogo != null && Object.hasOwnProperty.call(message, "showOrganizationLogo"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.showOrganizationLogo);
            if (message.requirePassword != null && Object.hasOwnProperty.call(message, "requirePassword"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.requirePassword);
            if (message.kind != null && Object.hasOwnProperty.call(message, "kind"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.kind);
            return writer;
        };

        /**
         * Encodes the specified DcrMetadata message, length delimited. Does not implicitly {@link metering.DcrMetadata.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.DcrMetadata
         * @static
         * @param {metering.IDcrMetadata} message DcrMetadata message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrMetadata.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DcrMetadata message from the specified reader or buffer.
         * @function decode
         * @memberof metering.DcrMetadata
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.DcrMetadata} DcrMetadata
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrMetadata.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.DcrMetadata();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.purpose = reader.int32();
                        break;
                    }
                case 2: {
                        message.showOrganizationLogo = reader.bool();
                        break;
                    }
                case 3: {
                        message.requirePassword = reader.bool();
                        break;
                    }
                case 4: {
                        message.kind = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DcrMetadata message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.DcrMetadata
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.DcrMetadata} DcrMetadata
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrMetadata.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DcrMetadata message.
         * @function verify
         * @memberof metering.DcrMetadata
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DcrMetadata.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.purpose != null && message.hasOwnProperty("purpose"))
                switch (message.purpose) {
                default:
                    return "purpose: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.showOrganizationLogo != null && message.hasOwnProperty("showOrganizationLogo"))
                if (typeof message.showOrganizationLogo !== "boolean")
                    return "showOrganizationLogo: boolean expected";
            if (message.requirePassword != null && message.hasOwnProperty("requirePassword"))
                if (typeof message.requirePassword !== "boolean")
                    return "requirePassword: boolean expected";
            if (message.kind != null && message.hasOwnProperty("kind"))
                switch (message.kind) {
                default:
                    return "kind: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            return null;
        };

        /**
         * Creates a DcrMetadata message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.DcrMetadata
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.DcrMetadata} DcrMetadata
         */
        DcrMetadata.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.DcrMetadata)
                return object;
            let message = new $root.metering.DcrMetadata();
            switch (object.purpose) {
            default:
                if (typeof object.purpose === "number") {
                    message.purpose = object.purpose;
                    break;
                }
                break;
            case "STANDARD":
            case 0:
                message.purpose = 0;
                break;
            case "VALIDATION":
            case 1:
                message.purpose = 1;
                break;
            case "DATA_IMPORT":
            case 2:
                message.purpose = 2;
                break;
            case "DATA_EXPORT":
            case 3:
                message.purpose = 3;
                break;
            case "DATA_LAB":
            case 4:
                message.purpose = 4;
                break;
            }
            if (object.showOrganizationLogo != null)
                message.showOrganizationLogo = Boolean(object.showOrganizationLogo);
            if (object.requirePassword != null)
                message.requirePassword = Boolean(object.requirePassword);
            switch (object.kind) {
            default:
                if (typeof object.kind === "number") {
                    message.kind = object.kind;
                    break;
                }
                break;
            case "EXPERT":
            case 0:
                message.kind = 0;
                break;
            case "DATASCIENCE":
            case 1:
                message.kind = 1;
                break;
            case "MEDIA":
            case 2:
                message.kind = 2;
                break;
            case "LOOKALIKE_MEDIA":
            case 3:
                message.kind = 3;
                break;
            case "MEDIA_INSIGHTS":
            case 4:
                message.kind = 4;
                break;
            case "AB_MEDIA":
            case 5:
                message.kind = 5;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a DcrMetadata message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.DcrMetadata
         * @static
         * @param {metering.DcrMetadata} message DcrMetadata
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DcrMetadata.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.purpose = options.enums === String ? "STANDARD" : 0;
                object.showOrganizationLogo = false;
                object.requirePassword = false;
                object.kind = options.enums === String ? "EXPERT" : 0;
            }
            if (message.purpose != null && message.hasOwnProperty("purpose"))
                object.purpose = options.enums === String ? $root.metering.CreateDcrPurpose[message.purpose] === undefined ? message.purpose : $root.metering.CreateDcrPurpose[message.purpose] : message.purpose;
            if (message.showOrganizationLogo != null && message.hasOwnProperty("showOrganizationLogo"))
                object.showOrganizationLogo = message.showOrganizationLogo;
            if (message.requirePassword != null && message.hasOwnProperty("requirePassword"))
                object.requirePassword = message.requirePassword;
            if (message.kind != null && message.hasOwnProperty("kind"))
                object.kind = options.enums === String ? $root.metering.CreateDcrKind[message.kind] === undefined ? message.kind : $root.metering.CreateDcrKind[message.kind] : message.kind;
            return object;
        };

        /**
         * Converts this DcrMetadata to JSON.
         * @function toJSON
         * @memberof metering.DcrMetadata
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DcrMetadata.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DcrMetadata
         * @function getTypeUrl
         * @memberof metering.DcrMetadata
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DcrMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.DcrMetadata";
        };

        return DcrMetadata;
    })();

    metering.CreateDcrRequest = (function() {

        /**
         * Properties of a CreateDcrRequest.
         * @memberof metering
         * @interface ICreateDcrRequest
         * @property {string|null} [idHex] The DCR hash to be used for the published data room
         * @property {string|null} [name] CreateDcrRequest name
         * @property {string|null} [driverAttestationHash] The driver attestation hash
         * @property {Array.<string>|null} [participantEmails] CreateDcrRequest participantEmails
         * @property {Uint8Array|null} [metadata] Optional metadata that will be persisted to the database
         * @property {Array.<metering.IEnclaveInfo>|null} [enclaveInfo] Information about the enclaves used in this DCR
         */

        /**
         * Constructs a new CreateDcrRequest.
         * @memberof metering
         * @classdesc Represents a CreateDcrRequest.
         * @implements ICreateDcrRequest
         * @constructor
         * @param {metering.ICreateDcrRequest=} [properties] Properties to set
         */
        function CreateDcrRequest(properties) {
            this.participantEmails = [];
            this.enclaveInfo = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * The DCR hash to be used for the published data room
         * @member {string} idHex
         * @memberof metering.CreateDcrRequest
         * @instance
         */
        CreateDcrRequest.prototype.idHex = "";

        /**
         * CreateDcrRequest name.
         * @member {string} name
         * @memberof metering.CreateDcrRequest
         * @instance
         */
        CreateDcrRequest.prototype.name = "";

        /**
         * The driver attestation hash
         * @member {string} driverAttestationHash
         * @memberof metering.CreateDcrRequest
         * @instance
         */
        CreateDcrRequest.prototype.driverAttestationHash = "";

        /**
         * CreateDcrRequest participantEmails.
         * @member {Array.<string>} participantEmails
         * @memberof metering.CreateDcrRequest
         * @instance
         */
        CreateDcrRequest.prototype.participantEmails = $util.emptyArray;

        /**
         * Optional metadata that will be persisted to the database
         * @member {Uint8Array|null|undefined} metadata
         * @memberof metering.CreateDcrRequest
         * @instance
         */
        CreateDcrRequest.prototype.metadata = null;

        /**
         * Information about the enclaves used in this DCR
         * @member {Array.<metering.IEnclaveInfo>} enclaveInfo
         * @memberof metering.CreateDcrRequest
         * @instance
         */
        CreateDcrRequest.prototype.enclaveInfo = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * CreateDcrRequest _metadata.
         * @member {"metadata"|undefined} _metadata
         * @memberof metering.CreateDcrRequest
         * @instance
         */
        Object.defineProperty(CreateDcrRequest.prototype, "_metadata", {
            get: $util.oneOfGetter($oneOfFields = ["metadata"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CreateDcrRequest instance using the specified properties.
         * @function create
         * @memberof metering.CreateDcrRequest
         * @static
         * @param {metering.ICreateDcrRequest=} [properties] Properties to set
         * @returns {metering.CreateDcrRequest} CreateDcrRequest instance
         */
        CreateDcrRequest.create = function create(properties) {
            return new CreateDcrRequest(properties);
        };

        /**
         * Encodes the specified CreateDcrRequest message. Does not implicitly {@link metering.CreateDcrRequest.verify|verify} messages.
         * @function encode
         * @memberof metering.CreateDcrRequest
         * @static
         * @param {metering.ICreateDcrRequest} message CreateDcrRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateDcrRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.idHex != null && Object.hasOwnProperty.call(message, "idHex"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.idHex);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.driverAttestationHash != null && Object.hasOwnProperty.call(message, "driverAttestationHash"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.driverAttestationHash);
            if (message.participantEmails != null && message.participantEmails.length)
                for (let i = 0; i < message.participantEmails.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.participantEmails[i]);
            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.metadata);
            if (message.enclaveInfo != null && message.enclaveInfo.length)
                for (let i = 0; i < message.enclaveInfo.length; ++i)
                    $root.metering.EnclaveInfo.encode(message.enclaveInfo[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateDcrRequest message, length delimited. Does not implicitly {@link metering.CreateDcrRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.CreateDcrRequest
         * @static
         * @param {metering.ICreateDcrRequest} message CreateDcrRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateDcrRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateDcrRequest message from the specified reader or buffer.
         * @function decode
         * @memberof metering.CreateDcrRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.CreateDcrRequest} CreateDcrRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateDcrRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.CreateDcrRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.idHex = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.driverAttestationHash = reader.string();
                        break;
                    }
                case 4: {
                        if (!(message.participantEmails && message.participantEmails.length))
                            message.participantEmails = [];
                        message.participantEmails.push(reader.string());
                        break;
                    }
                case 5: {
                        message.metadata = reader.bytes();
                        break;
                    }
                case 6: {
                        if (!(message.enclaveInfo && message.enclaveInfo.length))
                            message.enclaveInfo = [];
                        message.enclaveInfo.push($root.metering.EnclaveInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateDcrRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.CreateDcrRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.CreateDcrRequest} CreateDcrRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateDcrRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateDcrRequest message.
         * @function verify
         * @memberof metering.CreateDcrRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateDcrRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.idHex != null && message.hasOwnProperty("idHex"))
                if (!$util.isString(message.idHex))
                    return "idHex: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.driverAttestationHash != null && message.hasOwnProperty("driverAttestationHash"))
                if (!$util.isString(message.driverAttestationHash))
                    return "driverAttestationHash: string expected";
            if (message.participantEmails != null && message.hasOwnProperty("participantEmails")) {
                if (!Array.isArray(message.participantEmails))
                    return "participantEmails: array expected";
                for (let i = 0; i < message.participantEmails.length; ++i)
                    if (!$util.isString(message.participantEmails[i]))
                        return "participantEmails: string[] expected";
            }
            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                properties._metadata = 1;
                if (!(message.metadata && typeof message.metadata.length === "number" || $util.isString(message.metadata)))
                    return "metadata: buffer expected";
            }
            if (message.enclaveInfo != null && message.hasOwnProperty("enclaveInfo")) {
                if (!Array.isArray(message.enclaveInfo))
                    return "enclaveInfo: array expected";
                for (let i = 0; i < message.enclaveInfo.length; ++i) {
                    let error = $root.metering.EnclaveInfo.verify(message.enclaveInfo[i]);
                    if (error)
                        return "enclaveInfo." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CreateDcrRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.CreateDcrRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.CreateDcrRequest} CreateDcrRequest
         */
        CreateDcrRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.CreateDcrRequest)
                return object;
            let message = new $root.metering.CreateDcrRequest();
            if (object.idHex != null)
                message.idHex = String(object.idHex);
            if (object.name != null)
                message.name = String(object.name);
            if (object.driverAttestationHash != null)
                message.driverAttestationHash = String(object.driverAttestationHash);
            if (object.participantEmails) {
                if (!Array.isArray(object.participantEmails))
                    throw TypeError(".metering.CreateDcrRequest.participantEmails: array expected");
                message.participantEmails = [];
                for (let i = 0; i < object.participantEmails.length; ++i)
                    message.participantEmails[i] = String(object.participantEmails[i]);
            }
            if (object.metadata != null)
                if (typeof object.metadata === "string")
                    $util.base64.decode(object.metadata, message.metadata = $util.newBuffer($util.base64.length(object.metadata)), 0);
                else if (object.metadata.length >= 0)
                    message.metadata = object.metadata;
            if (object.enclaveInfo) {
                if (!Array.isArray(object.enclaveInfo))
                    throw TypeError(".metering.CreateDcrRequest.enclaveInfo: array expected");
                message.enclaveInfo = [];
                for (let i = 0; i < object.enclaveInfo.length; ++i) {
                    if (typeof object.enclaveInfo[i] !== "object")
                        throw TypeError(".metering.CreateDcrRequest.enclaveInfo: object expected");
                    message.enclaveInfo[i] = $root.metering.EnclaveInfo.fromObject(object.enclaveInfo[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateDcrRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.CreateDcrRequest
         * @static
         * @param {metering.CreateDcrRequest} message CreateDcrRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateDcrRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.participantEmails = [];
                object.enclaveInfo = [];
            }
            if (options.defaults) {
                object.idHex = "";
                object.name = "";
                object.driverAttestationHash = "";
            }
            if (message.idHex != null && message.hasOwnProperty("idHex"))
                object.idHex = message.idHex;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.driverAttestationHash != null && message.hasOwnProperty("driverAttestationHash"))
                object.driverAttestationHash = message.driverAttestationHash;
            if (message.participantEmails && message.participantEmails.length) {
                object.participantEmails = [];
                for (let j = 0; j < message.participantEmails.length; ++j)
                    object.participantEmails[j] = message.participantEmails[j];
            }
            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                object.metadata = options.bytes === String ? $util.base64.encode(message.metadata, 0, message.metadata.length) : options.bytes === Array ? Array.prototype.slice.call(message.metadata) : message.metadata;
                if (options.oneofs)
                    object._metadata = "metadata";
            }
            if (message.enclaveInfo && message.enclaveInfo.length) {
                object.enclaveInfo = [];
                for (let j = 0; j < message.enclaveInfo.length; ++j)
                    object.enclaveInfo[j] = $root.metering.EnclaveInfo.toObject(message.enclaveInfo[j], options);
            }
            return object;
        };

        /**
         * Converts this CreateDcrRequest to JSON.
         * @function toJSON
         * @memberof metering.CreateDcrRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateDcrRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateDcrRequest
         * @function getTypeUrl
         * @memberof metering.CreateDcrRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateDcrRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.CreateDcrRequest";
        };

        return CreateDcrRequest;
    })();

    metering.ExecuteComputationNodeInteraction = (function() {

        /**
         * Properties of an ExecuteComputationNodeInteraction.
         * @memberof metering
         * @interface IExecuteComputationNodeInteraction
         * @property {string|null} [nodeId] ExecuteComputationNodeInteraction nodeId
         * @property {data_room.IRateLimitingConfig|null} [rateLimiting] ExecuteComputationNodeInteraction rateLimiting
         */

        /**
         * Constructs a new ExecuteComputationNodeInteraction.
         * @memberof metering
         * @classdesc Represents an ExecuteComputationNodeInteraction.
         * @implements IExecuteComputationNodeInteraction
         * @constructor
         * @param {metering.IExecuteComputationNodeInteraction=} [properties] Properties to set
         */
        function ExecuteComputationNodeInteraction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExecuteComputationNodeInteraction nodeId.
         * @member {string} nodeId
         * @memberof metering.ExecuteComputationNodeInteraction
         * @instance
         */
        ExecuteComputationNodeInteraction.prototype.nodeId = "";

        /**
         * ExecuteComputationNodeInteraction rateLimiting.
         * @member {data_room.IRateLimitingConfig|null|undefined} rateLimiting
         * @memberof metering.ExecuteComputationNodeInteraction
         * @instance
         */
        ExecuteComputationNodeInteraction.prototype.rateLimiting = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ExecuteComputationNodeInteraction _rateLimiting.
         * @member {"rateLimiting"|undefined} _rateLimiting
         * @memberof metering.ExecuteComputationNodeInteraction
         * @instance
         */
        Object.defineProperty(ExecuteComputationNodeInteraction.prototype, "_rateLimiting", {
            get: $util.oneOfGetter($oneOfFields = ["rateLimiting"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ExecuteComputationNodeInteraction instance using the specified properties.
         * @function create
         * @memberof metering.ExecuteComputationNodeInteraction
         * @static
         * @param {metering.IExecuteComputationNodeInteraction=} [properties] Properties to set
         * @returns {metering.ExecuteComputationNodeInteraction} ExecuteComputationNodeInteraction instance
         */
        ExecuteComputationNodeInteraction.create = function create(properties) {
            return new ExecuteComputationNodeInteraction(properties);
        };

        /**
         * Encodes the specified ExecuteComputationNodeInteraction message. Does not implicitly {@link metering.ExecuteComputationNodeInteraction.verify|verify} messages.
         * @function encode
         * @memberof metering.ExecuteComputationNodeInteraction
         * @static
         * @param {metering.IExecuteComputationNodeInteraction} message ExecuteComputationNodeInteraction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecuteComputationNodeInteraction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nodeId != null && Object.hasOwnProperty.call(message, "nodeId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.nodeId);
            if (message.rateLimiting != null && Object.hasOwnProperty.call(message, "rateLimiting"))
                $root.data_room.RateLimitingConfig.encode(message.rateLimiting, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ExecuteComputationNodeInteraction message, length delimited. Does not implicitly {@link metering.ExecuteComputationNodeInteraction.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.ExecuteComputationNodeInteraction
         * @static
         * @param {metering.IExecuteComputationNodeInteraction} message ExecuteComputationNodeInteraction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecuteComputationNodeInteraction.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExecuteComputationNodeInteraction message from the specified reader or buffer.
         * @function decode
         * @memberof metering.ExecuteComputationNodeInteraction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.ExecuteComputationNodeInteraction} ExecuteComputationNodeInteraction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecuteComputationNodeInteraction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.ExecuteComputationNodeInteraction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.nodeId = reader.string();
                        break;
                    }
                case 2: {
                        message.rateLimiting = $root.data_room.RateLimitingConfig.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExecuteComputationNodeInteraction message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.ExecuteComputationNodeInteraction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.ExecuteComputationNodeInteraction} ExecuteComputationNodeInteraction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecuteComputationNodeInteraction.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExecuteComputationNodeInteraction message.
         * @function verify
         * @memberof metering.ExecuteComputationNodeInteraction
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExecuteComputationNodeInteraction.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.nodeId != null && message.hasOwnProperty("nodeId"))
                if (!$util.isString(message.nodeId))
                    return "nodeId: string expected";
            if (message.rateLimiting != null && message.hasOwnProperty("rateLimiting")) {
                properties._rateLimiting = 1;
                {
                    let error = $root.data_room.RateLimitingConfig.verify(message.rateLimiting);
                    if (error)
                        return "rateLimiting." + error;
                }
            }
            return null;
        };

        /**
         * Creates an ExecuteComputationNodeInteraction message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.ExecuteComputationNodeInteraction
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.ExecuteComputationNodeInteraction} ExecuteComputationNodeInteraction
         */
        ExecuteComputationNodeInteraction.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.ExecuteComputationNodeInteraction)
                return object;
            let message = new $root.metering.ExecuteComputationNodeInteraction();
            if (object.nodeId != null)
                message.nodeId = String(object.nodeId);
            if (object.rateLimiting != null) {
                if (typeof object.rateLimiting !== "object")
                    throw TypeError(".metering.ExecuteComputationNodeInteraction.rateLimiting: object expected");
                message.rateLimiting = $root.data_room.RateLimitingConfig.fromObject(object.rateLimiting);
            }
            return message;
        };

        /**
         * Creates a plain object from an ExecuteComputationNodeInteraction message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.ExecuteComputationNodeInteraction
         * @static
         * @param {metering.ExecuteComputationNodeInteraction} message ExecuteComputationNodeInteraction
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExecuteComputationNodeInteraction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.nodeId = "";
            if (message.nodeId != null && message.hasOwnProperty("nodeId"))
                object.nodeId = message.nodeId;
            if (message.rateLimiting != null && message.hasOwnProperty("rateLimiting")) {
                object.rateLimiting = $root.data_room.RateLimitingConfig.toObject(message.rateLimiting, options);
                if (options.oneofs)
                    object._rateLimiting = "rateLimiting";
            }
            return object;
        };

        /**
         * Converts this ExecuteComputationNodeInteraction to JSON.
         * @function toJSON
         * @memberof metering.ExecuteComputationNodeInteraction
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExecuteComputationNodeInteraction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ExecuteComputationNodeInteraction
         * @function getTypeUrl
         * @memberof metering.ExecuteComputationNodeInteraction
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExecuteComputationNodeInteraction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.ExecuteComputationNodeInteraction";
        };

        return ExecuteComputationNodeInteraction;
    })();

    metering.ExecuteComputationInteraction = (function() {

        /**
         * Properties of an ExecuteComputationInteraction.
         * @memberof metering
         * @interface IExecuteComputationInteraction
         * @property {Array.<metering.IExecuteComputationNodeInteraction>|null} [nodes] ExecuteComputationInteraction nodes
         */

        /**
         * Constructs a new ExecuteComputationInteraction.
         * @memberof metering
         * @classdesc Represents an ExecuteComputationInteraction.
         * @implements IExecuteComputationInteraction
         * @constructor
         * @param {metering.IExecuteComputationInteraction=} [properties] Properties to set
         */
        function ExecuteComputationInteraction(properties) {
            this.nodes = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExecuteComputationInteraction nodes.
         * @member {Array.<metering.IExecuteComputationNodeInteraction>} nodes
         * @memberof metering.ExecuteComputationInteraction
         * @instance
         */
        ExecuteComputationInteraction.prototype.nodes = $util.emptyArray;

        /**
         * Creates a new ExecuteComputationInteraction instance using the specified properties.
         * @function create
         * @memberof metering.ExecuteComputationInteraction
         * @static
         * @param {metering.IExecuteComputationInteraction=} [properties] Properties to set
         * @returns {metering.ExecuteComputationInteraction} ExecuteComputationInteraction instance
         */
        ExecuteComputationInteraction.create = function create(properties) {
            return new ExecuteComputationInteraction(properties);
        };

        /**
         * Encodes the specified ExecuteComputationInteraction message. Does not implicitly {@link metering.ExecuteComputationInteraction.verify|verify} messages.
         * @function encode
         * @memberof metering.ExecuteComputationInteraction
         * @static
         * @param {metering.IExecuteComputationInteraction} message ExecuteComputationInteraction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecuteComputationInteraction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nodes != null && message.nodes.length)
                for (let i = 0; i < message.nodes.length; ++i)
                    $root.metering.ExecuteComputationNodeInteraction.encode(message.nodes[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ExecuteComputationInteraction message, length delimited. Does not implicitly {@link metering.ExecuteComputationInteraction.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.ExecuteComputationInteraction
         * @static
         * @param {metering.IExecuteComputationInteraction} message ExecuteComputationInteraction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecuteComputationInteraction.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExecuteComputationInteraction message from the specified reader or buffer.
         * @function decode
         * @memberof metering.ExecuteComputationInteraction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.ExecuteComputationInteraction} ExecuteComputationInteraction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecuteComputationInteraction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.ExecuteComputationInteraction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.nodes && message.nodes.length))
                            message.nodes = [];
                        message.nodes.push($root.metering.ExecuteComputationNodeInteraction.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExecuteComputationInteraction message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.ExecuteComputationInteraction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.ExecuteComputationInteraction} ExecuteComputationInteraction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecuteComputationInteraction.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExecuteComputationInteraction message.
         * @function verify
         * @memberof metering.ExecuteComputationInteraction
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExecuteComputationInteraction.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nodes != null && message.hasOwnProperty("nodes")) {
                if (!Array.isArray(message.nodes))
                    return "nodes: array expected";
                for (let i = 0; i < message.nodes.length; ++i) {
                    let error = $root.metering.ExecuteComputationNodeInteraction.verify(message.nodes[i]);
                    if (error)
                        return "nodes." + error;
                }
            }
            return null;
        };

        /**
         * Creates an ExecuteComputationInteraction message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.ExecuteComputationInteraction
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.ExecuteComputationInteraction} ExecuteComputationInteraction
         */
        ExecuteComputationInteraction.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.ExecuteComputationInteraction)
                return object;
            let message = new $root.metering.ExecuteComputationInteraction();
            if (object.nodes) {
                if (!Array.isArray(object.nodes))
                    throw TypeError(".metering.ExecuteComputationInteraction.nodes: array expected");
                message.nodes = [];
                for (let i = 0; i < object.nodes.length; ++i) {
                    if (typeof object.nodes[i] !== "object")
                        throw TypeError(".metering.ExecuteComputationInteraction.nodes: object expected");
                    message.nodes[i] = $root.metering.ExecuteComputationNodeInteraction.fromObject(object.nodes[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an ExecuteComputationInteraction message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.ExecuteComputationInteraction
         * @static
         * @param {metering.ExecuteComputationInteraction} message ExecuteComputationInteraction
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExecuteComputationInteraction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.nodes = [];
            if (message.nodes && message.nodes.length) {
                object.nodes = [];
                for (let j = 0; j < message.nodes.length; ++j)
                    object.nodes[j] = $root.metering.ExecuteComputationNodeInteraction.toObject(message.nodes[j], options);
            }
            return object;
        };

        /**
         * Converts this ExecuteComputationInteraction to JSON.
         * @function toJSON
         * @memberof metering.ExecuteComputationInteraction
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExecuteComputationInteraction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ExecuteComputationInteraction
         * @function getTypeUrl
         * @memberof metering.ExecuteComputationInteraction
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExecuteComputationInteraction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.ExecuteComputationInteraction";
        };

        return ExecuteComputationInteraction;
    })();

    metering.PublishDatasetInteraction = (function() {

        /**
         * Properties of a PublishDatasetInteraction.
         * @memberof metering
         * @interface IPublishDatasetInteraction
         * @property {string|null} [nodeId] PublishDatasetInteraction nodeId
         * @property {data_room.IRateLimitingConfig|null} [rateLimiting] PublishDatasetInteraction rateLimiting
         */

        /**
         * Constructs a new PublishDatasetInteraction.
         * @memberof metering
         * @classdesc Represents a PublishDatasetInteraction.
         * @implements IPublishDatasetInteraction
         * @constructor
         * @param {metering.IPublishDatasetInteraction=} [properties] Properties to set
         */
        function PublishDatasetInteraction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PublishDatasetInteraction nodeId.
         * @member {string} nodeId
         * @memberof metering.PublishDatasetInteraction
         * @instance
         */
        PublishDatasetInteraction.prototype.nodeId = "";

        /**
         * PublishDatasetInteraction rateLimiting.
         * @member {data_room.IRateLimitingConfig|null|undefined} rateLimiting
         * @memberof metering.PublishDatasetInteraction
         * @instance
         */
        PublishDatasetInteraction.prototype.rateLimiting = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * PublishDatasetInteraction _rateLimiting.
         * @member {"rateLimiting"|undefined} _rateLimiting
         * @memberof metering.PublishDatasetInteraction
         * @instance
         */
        Object.defineProperty(PublishDatasetInteraction.prototype, "_rateLimiting", {
            get: $util.oneOfGetter($oneOfFields = ["rateLimiting"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new PublishDatasetInteraction instance using the specified properties.
         * @function create
         * @memberof metering.PublishDatasetInteraction
         * @static
         * @param {metering.IPublishDatasetInteraction=} [properties] Properties to set
         * @returns {metering.PublishDatasetInteraction} PublishDatasetInteraction instance
         */
        PublishDatasetInteraction.create = function create(properties) {
            return new PublishDatasetInteraction(properties);
        };

        /**
         * Encodes the specified PublishDatasetInteraction message. Does not implicitly {@link metering.PublishDatasetInteraction.verify|verify} messages.
         * @function encode
         * @memberof metering.PublishDatasetInteraction
         * @static
         * @param {metering.IPublishDatasetInteraction} message PublishDatasetInteraction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublishDatasetInteraction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nodeId != null && Object.hasOwnProperty.call(message, "nodeId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.nodeId);
            if (message.rateLimiting != null && Object.hasOwnProperty.call(message, "rateLimiting"))
                $root.data_room.RateLimitingConfig.encode(message.rateLimiting, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PublishDatasetInteraction message, length delimited. Does not implicitly {@link metering.PublishDatasetInteraction.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.PublishDatasetInteraction
         * @static
         * @param {metering.IPublishDatasetInteraction} message PublishDatasetInteraction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublishDatasetInteraction.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PublishDatasetInteraction message from the specified reader or buffer.
         * @function decode
         * @memberof metering.PublishDatasetInteraction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.PublishDatasetInteraction} PublishDatasetInteraction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublishDatasetInteraction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.PublishDatasetInteraction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.nodeId = reader.string();
                        break;
                    }
                case 2: {
                        message.rateLimiting = $root.data_room.RateLimitingConfig.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PublishDatasetInteraction message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.PublishDatasetInteraction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.PublishDatasetInteraction} PublishDatasetInteraction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublishDatasetInteraction.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PublishDatasetInteraction message.
         * @function verify
         * @memberof metering.PublishDatasetInteraction
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PublishDatasetInteraction.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.nodeId != null && message.hasOwnProperty("nodeId"))
                if (!$util.isString(message.nodeId))
                    return "nodeId: string expected";
            if (message.rateLimiting != null && message.hasOwnProperty("rateLimiting")) {
                properties._rateLimiting = 1;
                {
                    let error = $root.data_room.RateLimitingConfig.verify(message.rateLimiting);
                    if (error)
                        return "rateLimiting." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PublishDatasetInteraction message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.PublishDatasetInteraction
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.PublishDatasetInteraction} PublishDatasetInteraction
         */
        PublishDatasetInteraction.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.PublishDatasetInteraction)
                return object;
            let message = new $root.metering.PublishDatasetInteraction();
            if (object.nodeId != null)
                message.nodeId = String(object.nodeId);
            if (object.rateLimiting != null) {
                if (typeof object.rateLimiting !== "object")
                    throw TypeError(".metering.PublishDatasetInteraction.rateLimiting: object expected");
                message.rateLimiting = $root.data_room.RateLimitingConfig.fromObject(object.rateLimiting);
            }
            return message;
        };

        /**
         * Creates a plain object from a PublishDatasetInteraction message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.PublishDatasetInteraction
         * @static
         * @param {metering.PublishDatasetInteraction} message PublishDatasetInteraction
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PublishDatasetInteraction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.nodeId = "";
            if (message.nodeId != null && message.hasOwnProperty("nodeId"))
                object.nodeId = message.nodeId;
            if (message.rateLimiting != null && message.hasOwnProperty("rateLimiting")) {
                object.rateLimiting = $root.data_room.RateLimitingConfig.toObject(message.rateLimiting, options);
                if (options.oneofs)
                    object._rateLimiting = "rateLimiting";
            }
            return object;
        };

        /**
         * Converts this PublishDatasetInteraction to JSON.
         * @function toJSON
         * @memberof metering.PublishDatasetInteraction
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PublishDatasetInteraction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PublishDatasetInteraction
         * @function getTypeUrl
         * @memberof metering.PublishDatasetInteraction
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PublishDatasetInteraction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.PublishDatasetInteraction";
        };

        return PublishDatasetInteraction;
    })();

    metering.DcrInteractionKind = (function() {

        /**
         * Properties of a DcrInteractionKind.
         * @memberof metering
         * @interface IDcrInteractionKind
         * @property {metering.IExecuteComputationInteraction|null} [executeComputation] DcrInteractionKind executeComputation
         * @property {metering.IPublishDatasetInteraction|null} [publishDataset] DcrInteractionKind publishDataset
         */

        /**
         * Constructs a new DcrInteractionKind.
         * @memberof metering
         * @classdesc Represents a DcrInteractionKind.
         * @implements IDcrInteractionKind
         * @constructor
         * @param {metering.IDcrInteractionKind=} [properties] Properties to set
         */
        function DcrInteractionKind(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DcrInteractionKind executeComputation.
         * @member {metering.IExecuteComputationInteraction|null|undefined} executeComputation
         * @memberof metering.DcrInteractionKind
         * @instance
         */
        DcrInteractionKind.prototype.executeComputation = null;

        /**
         * DcrInteractionKind publishDataset.
         * @member {metering.IPublishDatasetInteraction|null|undefined} publishDataset
         * @memberof metering.DcrInteractionKind
         * @instance
         */
        DcrInteractionKind.prototype.publishDataset = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * DcrInteractionKind kind.
         * @member {"executeComputation"|"publishDataset"|undefined} kind
         * @memberof metering.DcrInteractionKind
         * @instance
         */
        Object.defineProperty(DcrInteractionKind.prototype, "kind", {
            get: $util.oneOfGetter($oneOfFields = ["executeComputation", "publishDataset"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new DcrInteractionKind instance using the specified properties.
         * @function create
         * @memberof metering.DcrInteractionKind
         * @static
         * @param {metering.IDcrInteractionKind=} [properties] Properties to set
         * @returns {metering.DcrInteractionKind} DcrInteractionKind instance
         */
        DcrInteractionKind.create = function create(properties) {
            return new DcrInteractionKind(properties);
        };

        /**
         * Encodes the specified DcrInteractionKind message. Does not implicitly {@link metering.DcrInteractionKind.verify|verify} messages.
         * @function encode
         * @memberof metering.DcrInteractionKind
         * @static
         * @param {metering.IDcrInteractionKind} message DcrInteractionKind message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrInteractionKind.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.executeComputation != null && Object.hasOwnProperty.call(message, "executeComputation"))
                $root.metering.ExecuteComputationInteraction.encode(message.executeComputation, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.publishDataset != null && Object.hasOwnProperty.call(message, "publishDataset"))
                $root.metering.PublishDatasetInteraction.encode(message.publishDataset, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DcrInteractionKind message, length delimited. Does not implicitly {@link metering.DcrInteractionKind.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.DcrInteractionKind
         * @static
         * @param {metering.IDcrInteractionKind} message DcrInteractionKind message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrInteractionKind.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DcrInteractionKind message from the specified reader or buffer.
         * @function decode
         * @memberof metering.DcrInteractionKind
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.DcrInteractionKind} DcrInteractionKind
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrInteractionKind.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.DcrInteractionKind();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.executeComputation = $root.metering.ExecuteComputationInteraction.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.publishDataset = $root.metering.PublishDatasetInteraction.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DcrInteractionKind message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.DcrInteractionKind
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.DcrInteractionKind} DcrInteractionKind
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrInteractionKind.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DcrInteractionKind message.
         * @function verify
         * @memberof metering.DcrInteractionKind
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DcrInteractionKind.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.executeComputation != null && message.hasOwnProperty("executeComputation")) {
                properties.kind = 1;
                {
                    let error = $root.metering.ExecuteComputationInteraction.verify(message.executeComputation);
                    if (error)
                        return "executeComputation." + error;
                }
            }
            if (message.publishDataset != null && message.hasOwnProperty("publishDataset")) {
                if (properties.kind === 1)
                    return "kind: multiple values";
                properties.kind = 1;
                {
                    let error = $root.metering.PublishDatasetInteraction.verify(message.publishDataset);
                    if (error)
                        return "publishDataset." + error;
                }
            }
            return null;
        };

        /**
         * Creates a DcrInteractionKind message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.DcrInteractionKind
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.DcrInteractionKind} DcrInteractionKind
         */
        DcrInteractionKind.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.DcrInteractionKind)
                return object;
            let message = new $root.metering.DcrInteractionKind();
            if (object.executeComputation != null) {
                if (typeof object.executeComputation !== "object")
                    throw TypeError(".metering.DcrInteractionKind.executeComputation: object expected");
                message.executeComputation = $root.metering.ExecuteComputationInteraction.fromObject(object.executeComputation);
            }
            if (object.publishDataset != null) {
                if (typeof object.publishDataset !== "object")
                    throw TypeError(".metering.DcrInteractionKind.publishDataset: object expected");
                message.publishDataset = $root.metering.PublishDatasetInteraction.fromObject(object.publishDataset);
            }
            return message;
        };

        /**
         * Creates a plain object from a DcrInteractionKind message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.DcrInteractionKind
         * @static
         * @param {metering.DcrInteractionKind} message DcrInteractionKind
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DcrInteractionKind.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.executeComputation != null && message.hasOwnProperty("executeComputation")) {
                object.executeComputation = $root.metering.ExecuteComputationInteraction.toObject(message.executeComputation, options);
                if (options.oneofs)
                    object.kind = "executeComputation";
            }
            if (message.publishDataset != null && message.hasOwnProperty("publishDataset")) {
                object.publishDataset = $root.metering.PublishDatasetInteraction.toObject(message.publishDataset, options);
                if (options.oneofs)
                    object.kind = "publishDataset";
            }
            return object;
        };

        /**
         * Converts this DcrInteractionKind to JSON.
         * @function toJSON
         * @memberof metering.DcrInteractionKind
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DcrInteractionKind.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DcrInteractionKind
         * @function getTypeUrl
         * @memberof metering.DcrInteractionKind
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DcrInteractionKind.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.DcrInteractionKind";
        };

        return DcrInteractionKind;
    })();

    metering.DcrInteractionRequest = (function() {

        /**
         * Properties of a DcrInteractionRequest.
         * @memberof metering
         * @interface IDcrInteractionRequest
         * @property {string|null} [dataRoomHash] The hex-encoded hash of the data room
         * @property {string|null} [driverAttestationHash] The driver attestation hash
         * @property {string|null} [scopeId] DcrInteractionRequest scopeId
         * @property {metering.IDcrInteractionKind|null} [interaction] DcrInteractionRequest interaction
         */

        /**
         * Constructs a new DcrInteractionRequest.
         * @memberof metering
         * @classdesc Represents a DcrInteractionRequest.
         * @implements IDcrInteractionRequest
         * @constructor
         * @param {metering.IDcrInteractionRequest=} [properties] Properties to set
         */
        function DcrInteractionRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * The hex-encoded hash of the data room
         * @member {string} dataRoomHash
         * @memberof metering.DcrInteractionRequest
         * @instance
         */
        DcrInteractionRequest.prototype.dataRoomHash = "";

        /**
         * The driver attestation hash
         * @member {string} driverAttestationHash
         * @memberof metering.DcrInteractionRequest
         * @instance
         */
        DcrInteractionRequest.prototype.driverAttestationHash = "";

        /**
         * DcrInteractionRequest scopeId.
         * @member {string} scopeId
         * @memberof metering.DcrInteractionRequest
         * @instance
         */
        DcrInteractionRequest.prototype.scopeId = "";

        /**
         * DcrInteractionRequest interaction.
         * @member {metering.IDcrInteractionKind|null|undefined} interaction
         * @memberof metering.DcrInteractionRequest
         * @instance
         */
        DcrInteractionRequest.prototype.interaction = null;

        /**
         * Creates a new DcrInteractionRequest instance using the specified properties.
         * @function create
         * @memberof metering.DcrInteractionRequest
         * @static
         * @param {metering.IDcrInteractionRequest=} [properties] Properties to set
         * @returns {metering.DcrInteractionRequest} DcrInteractionRequest instance
         */
        DcrInteractionRequest.create = function create(properties) {
            return new DcrInteractionRequest(properties);
        };

        /**
         * Encodes the specified DcrInteractionRequest message. Does not implicitly {@link metering.DcrInteractionRequest.verify|verify} messages.
         * @function encode
         * @memberof metering.DcrInteractionRequest
         * @static
         * @param {metering.IDcrInteractionRequest} message DcrInteractionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrInteractionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dataRoomHash != null && Object.hasOwnProperty.call(message, "dataRoomHash"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dataRoomHash);
            if (message.driverAttestationHash != null && Object.hasOwnProperty.call(message, "driverAttestationHash"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.driverAttestationHash);
            if (message.scopeId != null && Object.hasOwnProperty.call(message, "scopeId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.scopeId);
            if (message.interaction != null && Object.hasOwnProperty.call(message, "interaction"))
                $root.metering.DcrInteractionKind.encode(message.interaction, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DcrInteractionRequest message, length delimited. Does not implicitly {@link metering.DcrInteractionRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.DcrInteractionRequest
         * @static
         * @param {metering.IDcrInteractionRequest} message DcrInteractionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrInteractionRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DcrInteractionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof metering.DcrInteractionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.DcrInteractionRequest} DcrInteractionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrInteractionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.DcrInteractionRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dataRoomHash = reader.string();
                        break;
                    }
                case 2: {
                        message.driverAttestationHash = reader.string();
                        break;
                    }
                case 3: {
                        message.scopeId = reader.string();
                        break;
                    }
                case 4: {
                        message.interaction = $root.metering.DcrInteractionKind.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DcrInteractionRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.DcrInteractionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.DcrInteractionRequest} DcrInteractionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrInteractionRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DcrInteractionRequest message.
         * @function verify
         * @memberof metering.DcrInteractionRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DcrInteractionRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dataRoomHash != null && message.hasOwnProperty("dataRoomHash"))
                if (!$util.isString(message.dataRoomHash))
                    return "dataRoomHash: string expected";
            if (message.driverAttestationHash != null && message.hasOwnProperty("driverAttestationHash"))
                if (!$util.isString(message.driverAttestationHash))
                    return "driverAttestationHash: string expected";
            if (message.scopeId != null && message.hasOwnProperty("scopeId"))
                if (!$util.isString(message.scopeId))
                    return "scopeId: string expected";
            if (message.interaction != null && message.hasOwnProperty("interaction")) {
                let error = $root.metering.DcrInteractionKind.verify(message.interaction);
                if (error)
                    return "interaction." + error;
            }
            return null;
        };

        /**
         * Creates a DcrInteractionRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.DcrInteractionRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.DcrInteractionRequest} DcrInteractionRequest
         */
        DcrInteractionRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.DcrInteractionRequest)
                return object;
            let message = new $root.metering.DcrInteractionRequest();
            if (object.dataRoomHash != null)
                message.dataRoomHash = String(object.dataRoomHash);
            if (object.driverAttestationHash != null)
                message.driverAttestationHash = String(object.driverAttestationHash);
            if (object.scopeId != null)
                message.scopeId = String(object.scopeId);
            if (object.interaction != null) {
                if (typeof object.interaction !== "object")
                    throw TypeError(".metering.DcrInteractionRequest.interaction: object expected");
                message.interaction = $root.metering.DcrInteractionKind.fromObject(object.interaction);
            }
            return message;
        };

        /**
         * Creates a plain object from a DcrInteractionRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.DcrInteractionRequest
         * @static
         * @param {metering.DcrInteractionRequest} message DcrInteractionRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DcrInteractionRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.dataRoomHash = "";
                object.driverAttestationHash = "";
                object.scopeId = "";
                object.interaction = null;
            }
            if (message.dataRoomHash != null && message.hasOwnProperty("dataRoomHash"))
                object.dataRoomHash = message.dataRoomHash;
            if (message.driverAttestationHash != null && message.hasOwnProperty("driverAttestationHash"))
                object.driverAttestationHash = message.driverAttestationHash;
            if (message.scopeId != null && message.hasOwnProperty("scopeId"))
                object.scopeId = message.scopeId;
            if (message.interaction != null && message.hasOwnProperty("interaction"))
                object.interaction = $root.metering.DcrInteractionKind.toObject(message.interaction, options);
            return object;
        };

        /**
         * Converts this DcrInteractionRequest to JSON.
         * @function toJSON
         * @memberof metering.DcrInteractionRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DcrInteractionRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DcrInteractionRequest
         * @function getTypeUrl
         * @memberof metering.DcrInteractionRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DcrInteractionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.DcrInteractionRequest";
        };

        return DcrInteractionRequest;
    })();

    metering.PublishDatasetRequest = (function() {

        /**
         * Properties of a PublishDatasetRequest.
         * @memberof metering
         * @interface IPublishDatasetRequest
         * @property {string|null} [computeNodeId] The UUID of the compute node to which this dataset is being published
         * @property {string|null} [manifestHash] The hex-encoded manifest hash of the dataset
         * @property {string|null} [dataRoomHash] The hex-encoded hash of the data room
         * @property {string|null} [driverAttestationHash] The driver attestation hash
         */

        /**
         * Constructs a new PublishDatasetRequest.
         * @memberof metering
         * @classdesc Represents a PublishDatasetRequest.
         * @implements IPublishDatasetRequest
         * @constructor
         * @param {metering.IPublishDatasetRequest=} [properties] Properties to set
         */
        function PublishDatasetRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * The UUID of the compute node to which this dataset is being published
         * @member {string} computeNodeId
         * @memberof metering.PublishDatasetRequest
         * @instance
         */
        PublishDatasetRequest.prototype.computeNodeId = "";

        /**
         * The hex-encoded manifest hash of the dataset
         * @member {string} manifestHash
         * @memberof metering.PublishDatasetRequest
         * @instance
         */
        PublishDatasetRequest.prototype.manifestHash = "";

        /**
         * The hex-encoded hash of the data room
         * @member {string} dataRoomHash
         * @memberof metering.PublishDatasetRequest
         * @instance
         */
        PublishDatasetRequest.prototype.dataRoomHash = "";

        /**
         * The driver attestation hash
         * @member {string} driverAttestationHash
         * @memberof metering.PublishDatasetRequest
         * @instance
         */
        PublishDatasetRequest.prototype.driverAttestationHash = "";

        /**
         * Creates a new PublishDatasetRequest instance using the specified properties.
         * @function create
         * @memberof metering.PublishDatasetRequest
         * @static
         * @param {metering.IPublishDatasetRequest=} [properties] Properties to set
         * @returns {metering.PublishDatasetRequest} PublishDatasetRequest instance
         */
        PublishDatasetRequest.create = function create(properties) {
            return new PublishDatasetRequest(properties);
        };

        /**
         * Encodes the specified PublishDatasetRequest message. Does not implicitly {@link metering.PublishDatasetRequest.verify|verify} messages.
         * @function encode
         * @memberof metering.PublishDatasetRequest
         * @static
         * @param {metering.IPublishDatasetRequest} message PublishDatasetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublishDatasetRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.computeNodeId != null && Object.hasOwnProperty.call(message, "computeNodeId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.computeNodeId);
            if (message.manifestHash != null && Object.hasOwnProperty.call(message, "manifestHash"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.manifestHash);
            if (message.dataRoomHash != null && Object.hasOwnProperty.call(message, "dataRoomHash"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.dataRoomHash);
            if (message.driverAttestationHash != null && Object.hasOwnProperty.call(message, "driverAttestationHash"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.driverAttestationHash);
            return writer;
        };

        /**
         * Encodes the specified PublishDatasetRequest message, length delimited. Does not implicitly {@link metering.PublishDatasetRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.PublishDatasetRequest
         * @static
         * @param {metering.IPublishDatasetRequest} message PublishDatasetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublishDatasetRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PublishDatasetRequest message from the specified reader or buffer.
         * @function decode
         * @memberof metering.PublishDatasetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.PublishDatasetRequest} PublishDatasetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublishDatasetRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.PublishDatasetRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.computeNodeId = reader.string();
                        break;
                    }
                case 2: {
                        message.manifestHash = reader.string();
                        break;
                    }
                case 3: {
                        message.dataRoomHash = reader.string();
                        break;
                    }
                case 4: {
                        message.driverAttestationHash = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PublishDatasetRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.PublishDatasetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.PublishDatasetRequest} PublishDatasetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublishDatasetRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PublishDatasetRequest message.
         * @function verify
         * @memberof metering.PublishDatasetRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PublishDatasetRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.computeNodeId != null && message.hasOwnProperty("computeNodeId"))
                if (!$util.isString(message.computeNodeId))
                    return "computeNodeId: string expected";
            if (message.manifestHash != null && message.hasOwnProperty("manifestHash"))
                if (!$util.isString(message.manifestHash))
                    return "manifestHash: string expected";
            if (message.dataRoomHash != null && message.hasOwnProperty("dataRoomHash"))
                if (!$util.isString(message.dataRoomHash))
                    return "dataRoomHash: string expected";
            if (message.driverAttestationHash != null && message.hasOwnProperty("driverAttestationHash"))
                if (!$util.isString(message.driverAttestationHash))
                    return "driverAttestationHash: string expected";
            return null;
        };

        /**
         * Creates a PublishDatasetRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.PublishDatasetRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.PublishDatasetRequest} PublishDatasetRequest
         */
        PublishDatasetRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.PublishDatasetRequest)
                return object;
            let message = new $root.metering.PublishDatasetRequest();
            if (object.computeNodeId != null)
                message.computeNodeId = String(object.computeNodeId);
            if (object.manifestHash != null)
                message.manifestHash = String(object.manifestHash);
            if (object.dataRoomHash != null)
                message.dataRoomHash = String(object.dataRoomHash);
            if (object.driverAttestationHash != null)
                message.driverAttestationHash = String(object.driverAttestationHash);
            return message;
        };

        /**
         * Creates a plain object from a PublishDatasetRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.PublishDatasetRequest
         * @static
         * @param {metering.PublishDatasetRequest} message PublishDatasetRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PublishDatasetRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.computeNodeId = "";
                object.manifestHash = "";
                object.dataRoomHash = "";
                object.driverAttestationHash = "";
            }
            if (message.computeNodeId != null && message.hasOwnProperty("computeNodeId"))
                object.computeNodeId = message.computeNodeId;
            if (message.manifestHash != null && message.hasOwnProperty("manifestHash"))
                object.manifestHash = message.manifestHash;
            if (message.dataRoomHash != null && message.hasOwnProperty("dataRoomHash"))
                object.dataRoomHash = message.dataRoomHash;
            if (message.driverAttestationHash != null && message.hasOwnProperty("driverAttestationHash"))
                object.driverAttestationHash = message.driverAttestationHash;
            return object;
        };

        /**
         * Converts this PublishDatasetRequest to JSON.
         * @function toJSON
         * @memberof metering.PublishDatasetRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PublishDatasetRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PublishDatasetRequest
         * @function getTypeUrl
         * @memberof metering.PublishDatasetRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PublishDatasetRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.PublishDatasetRequest";
        };

        return PublishDatasetRequest;
    })();

    metering.CreateDatasetRequest = (function() {

        /**
         * Properties of a CreateDatasetRequest.
         * @memberof metering
         * @interface ICreateDatasetRequest
         * @property {string|null} [manifestHash] The hex-encoded manifest hash of the dataset
         * @property {string|null} [manifest] The hex-encoded manifest of the dataset
         * @property {string|null} [scopeId] The id scope of the scope to which this dataset should be linked
         * @property {string|null} [name] A human-readable name of the dataset that helps to identify it
         * @property {string|null} [description] A human-readable description of the dataset that helps to identify it
         * @property {number|Long|null} [sizeBytes] The size of this dataset in bytes
         * @property {string|null} [statistics] Statistics associated with this dataset as a serialized JSON object
         * @property {string|null} [datasetImportId] An id identifying the dataset import as part of which this dataset was created
         */

        /**
         * Constructs a new CreateDatasetRequest.
         * @memberof metering
         * @classdesc Represents a CreateDatasetRequest.
         * @implements ICreateDatasetRequest
         * @constructor
         * @param {metering.ICreateDatasetRequest=} [properties] Properties to set
         */
        function CreateDatasetRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * The hex-encoded manifest hash of the dataset
         * @member {string} manifestHash
         * @memberof metering.CreateDatasetRequest
         * @instance
         */
        CreateDatasetRequest.prototype.manifestHash = "";

        /**
         * The hex-encoded manifest of the dataset
         * @member {string|null|undefined} manifest
         * @memberof metering.CreateDatasetRequest
         * @instance
         */
        CreateDatasetRequest.prototype.manifest = null;

        /**
         * The id scope of the scope to which this dataset should be linked
         * @member {string} scopeId
         * @memberof metering.CreateDatasetRequest
         * @instance
         */
        CreateDatasetRequest.prototype.scopeId = "";

        /**
         * A human-readable name of the dataset that helps to identify it
         * @member {string} name
         * @memberof metering.CreateDatasetRequest
         * @instance
         */
        CreateDatasetRequest.prototype.name = "";

        /**
         * A human-readable description of the dataset that helps to identify it
         * @member {string|null|undefined} description
         * @memberof metering.CreateDatasetRequest
         * @instance
         */
        CreateDatasetRequest.prototype.description = null;

        /**
         * The size of this dataset in bytes
         * @member {number|Long|null|undefined} sizeBytes
         * @memberof metering.CreateDatasetRequest
         * @instance
         */
        CreateDatasetRequest.prototype.sizeBytes = null;

        /**
         * Statistics associated with this dataset as a serialized JSON object
         * @member {string|null|undefined} statistics
         * @memberof metering.CreateDatasetRequest
         * @instance
         */
        CreateDatasetRequest.prototype.statistics = null;

        /**
         * An id identifying the dataset import as part of which this dataset was created
         * @member {string|null|undefined} datasetImportId
         * @memberof metering.CreateDatasetRequest
         * @instance
         */
        CreateDatasetRequest.prototype.datasetImportId = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * CreateDatasetRequest _manifest.
         * @member {"manifest"|undefined} _manifest
         * @memberof metering.CreateDatasetRequest
         * @instance
         */
        Object.defineProperty(CreateDatasetRequest.prototype, "_manifest", {
            get: $util.oneOfGetter($oneOfFields = ["manifest"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreateDatasetRequest _description.
         * @member {"description"|undefined} _description
         * @memberof metering.CreateDatasetRequest
         * @instance
         */
        Object.defineProperty(CreateDatasetRequest.prototype, "_description", {
            get: $util.oneOfGetter($oneOfFields = ["description"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreateDatasetRequest _sizeBytes.
         * @member {"sizeBytes"|undefined} _sizeBytes
         * @memberof metering.CreateDatasetRequest
         * @instance
         */
        Object.defineProperty(CreateDatasetRequest.prototype, "_sizeBytes", {
            get: $util.oneOfGetter($oneOfFields = ["sizeBytes"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreateDatasetRequest _statistics.
         * @member {"statistics"|undefined} _statistics
         * @memberof metering.CreateDatasetRequest
         * @instance
         */
        Object.defineProperty(CreateDatasetRequest.prototype, "_statistics", {
            get: $util.oneOfGetter($oneOfFields = ["statistics"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreateDatasetRequest _datasetImportId.
         * @member {"datasetImportId"|undefined} _datasetImportId
         * @memberof metering.CreateDatasetRequest
         * @instance
         */
        Object.defineProperty(CreateDatasetRequest.prototype, "_datasetImportId", {
            get: $util.oneOfGetter($oneOfFields = ["datasetImportId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CreateDatasetRequest instance using the specified properties.
         * @function create
         * @memberof metering.CreateDatasetRequest
         * @static
         * @param {metering.ICreateDatasetRequest=} [properties] Properties to set
         * @returns {metering.CreateDatasetRequest} CreateDatasetRequest instance
         */
        CreateDatasetRequest.create = function create(properties) {
            return new CreateDatasetRequest(properties);
        };

        /**
         * Encodes the specified CreateDatasetRequest message. Does not implicitly {@link metering.CreateDatasetRequest.verify|verify} messages.
         * @function encode
         * @memberof metering.CreateDatasetRequest
         * @static
         * @param {metering.ICreateDatasetRequest} message CreateDatasetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateDatasetRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.manifestHash != null && Object.hasOwnProperty.call(message, "manifestHash"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.manifestHash);
            if (message.manifest != null && Object.hasOwnProperty.call(message, "manifest"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.manifest);
            if (message.scopeId != null && Object.hasOwnProperty.call(message, "scopeId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.scopeId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.description);
            if (message.sizeBytes != null && Object.hasOwnProperty.call(message, "sizeBytes"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.sizeBytes);
            if (message.statistics != null && Object.hasOwnProperty.call(message, "statistics"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.statistics);
            if (message.datasetImportId != null && Object.hasOwnProperty.call(message, "datasetImportId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.datasetImportId);
            return writer;
        };

        /**
         * Encodes the specified CreateDatasetRequest message, length delimited. Does not implicitly {@link metering.CreateDatasetRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.CreateDatasetRequest
         * @static
         * @param {metering.ICreateDatasetRequest} message CreateDatasetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateDatasetRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateDatasetRequest message from the specified reader or buffer.
         * @function decode
         * @memberof metering.CreateDatasetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.CreateDatasetRequest} CreateDatasetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateDatasetRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.CreateDatasetRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.manifestHash = reader.string();
                        break;
                    }
                case 2: {
                        message.manifest = reader.string();
                        break;
                    }
                case 3: {
                        message.scopeId = reader.string();
                        break;
                    }
                case 4: {
                        message.name = reader.string();
                        break;
                    }
                case 5: {
                        message.description = reader.string();
                        break;
                    }
                case 6: {
                        message.sizeBytes = reader.uint64();
                        break;
                    }
                case 7: {
                        message.statistics = reader.string();
                        break;
                    }
                case 8: {
                        message.datasetImportId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateDatasetRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.CreateDatasetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.CreateDatasetRequest} CreateDatasetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateDatasetRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateDatasetRequest message.
         * @function verify
         * @memberof metering.CreateDatasetRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateDatasetRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.manifestHash != null && message.hasOwnProperty("manifestHash"))
                if (!$util.isString(message.manifestHash))
                    return "manifestHash: string expected";
            if (message.manifest != null && message.hasOwnProperty("manifest")) {
                properties._manifest = 1;
                if (!$util.isString(message.manifest))
                    return "manifest: string expected";
            }
            if (message.scopeId != null && message.hasOwnProperty("scopeId"))
                if (!$util.isString(message.scopeId))
                    return "scopeId: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.description != null && message.hasOwnProperty("description")) {
                properties._description = 1;
                if (!$util.isString(message.description))
                    return "description: string expected";
            }
            if (message.sizeBytes != null && message.hasOwnProperty("sizeBytes")) {
                properties._sizeBytes = 1;
                if (!$util.isInteger(message.sizeBytes) && !(message.sizeBytes && $util.isInteger(message.sizeBytes.low) && $util.isInteger(message.sizeBytes.high)))
                    return "sizeBytes: integer|Long expected";
            }
            if (message.statistics != null && message.hasOwnProperty("statistics")) {
                properties._statistics = 1;
                if (!$util.isString(message.statistics))
                    return "statistics: string expected";
            }
            if (message.datasetImportId != null && message.hasOwnProperty("datasetImportId")) {
                properties._datasetImportId = 1;
                if (!$util.isString(message.datasetImportId))
                    return "datasetImportId: string expected";
            }
            return null;
        };

        /**
         * Creates a CreateDatasetRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.CreateDatasetRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.CreateDatasetRequest} CreateDatasetRequest
         */
        CreateDatasetRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.CreateDatasetRequest)
                return object;
            let message = new $root.metering.CreateDatasetRequest();
            if (object.manifestHash != null)
                message.manifestHash = String(object.manifestHash);
            if (object.manifest != null)
                message.manifest = String(object.manifest);
            if (object.scopeId != null)
                message.scopeId = String(object.scopeId);
            if (object.name != null)
                message.name = String(object.name);
            if (object.description != null)
                message.description = String(object.description);
            if (object.sizeBytes != null)
                if ($util.Long)
                    (message.sizeBytes = $util.Long.fromValue(object.sizeBytes)).unsigned = true;
                else if (typeof object.sizeBytes === "string")
                    message.sizeBytes = parseInt(object.sizeBytes, 10);
                else if (typeof object.sizeBytes === "number")
                    message.sizeBytes = object.sizeBytes;
                else if (typeof object.sizeBytes === "object")
                    message.sizeBytes = new $util.LongBits(object.sizeBytes.low >>> 0, object.sizeBytes.high >>> 0).toNumber(true);
            if (object.statistics != null)
                message.statistics = String(object.statistics);
            if (object.datasetImportId != null)
                message.datasetImportId = String(object.datasetImportId);
            return message;
        };

        /**
         * Creates a plain object from a CreateDatasetRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.CreateDatasetRequest
         * @static
         * @param {metering.CreateDatasetRequest} message CreateDatasetRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateDatasetRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.manifestHash = "";
                object.scopeId = "";
                object.name = "";
            }
            if (message.manifestHash != null && message.hasOwnProperty("manifestHash"))
                object.manifestHash = message.manifestHash;
            if (message.manifest != null && message.hasOwnProperty("manifest")) {
                object.manifest = message.manifest;
                if (options.oneofs)
                    object._manifest = "manifest";
            }
            if (message.scopeId != null && message.hasOwnProperty("scopeId"))
                object.scopeId = message.scopeId;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.description != null && message.hasOwnProperty("description")) {
                object.description = message.description;
                if (options.oneofs)
                    object._description = "description";
            }
            if (message.sizeBytes != null && message.hasOwnProperty("sizeBytes")) {
                if (typeof message.sizeBytes === "number")
                    object.sizeBytes = options.longs === String ? String(message.sizeBytes) : message.sizeBytes;
                else
                    object.sizeBytes = options.longs === String ? $util.Long.prototype.toString.call(message.sizeBytes) : options.longs === Number ? new $util.LongBits(message.sizeBytes.low >>> 0, message.sizeBytes.high >>> 0).toNumber(true) : message.sizeBytes;
                if (options.oneofs)
                    object._sizeBytes = "sizeBytes";
            }
            if (message.statistics != null && message.hasOwnProperty("statistics")) {
                object.statistics = message.statistics;
                if (options.oneofs)
                    object._statistics = "statistics";
            }
            if (message.datasetImportId != null && message.hasOwnProperty("datasetImportId")) {
                object.datasetImportId = message.datasetImportId;
                if (options.oneofs)
                    object._datasetImportId = "datasetImportId";
            }
            return object;
        };

        /**
         * Converts this CreateDatasetRequest to JSON.
         * @function toJSON
         * @memberof metering.CreateDatasetRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateDatasetRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateDatasetRequest
         * @function getTypeUrl
         * @memberof metering.CreateDatasetRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateDatasetRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.CreateDatasetRequest";
        };

        return CreateDatasetRequest;
    })();

    metering.GetOrCreateDatasetScopeRequest = (function() {

        /**
         * Properties of a GetOrCreateDatasetScopeRequest.
         * @memberof metering
         * @interface IGetOrCreateDatasetScopeRequest
         * @property {string|null} [manifestHash] GetOrCreateDatasetScopeRequest manifestHash
         */

        /**
         * Constructs a new GetOrCreateDatasetScopeRequest.
         * @memberof metering
         * @classdesc Represents a GetOrCreateDatasetScopeRequest.
         * @implements IGetOrCreateDatasetScopeRequest
         * @constructor
         * @param {metering.IGetOrCreateDatasetScopeRequest=} [properties] Properties to set
         */
        function GetOrCreateDatasetScopeRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetOrCreateDatasetScopeRequest manifestHash.
         * @member {string|null|undefined} manifestHash
         * @memberof metering.GetOrCreateDatasetScopeRequest
         * @instance
         */
        GetOrCreateDatasetScopeRequest.prototype.manifestHash = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * GetOrCreateDatasetScopeRequest _manifestHash.
         * @member {"manifestHash"|undefined} _manifestHash
         * @memberof metering.GetOrCreateDatasetScopeRequest
         * @instance
         */
        Object.defineProperty(GetOrCreateDatasetScopeRequest.prototype, "_manifestHash", {
            get: $util.oneOfGetter($oneOfFields = ["manifestHash"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetOrCreateDatasetScopeRequest instance using the specified properties.
         * @function create
         * @memberof metering.GetOrCreateDatasetScopeRequest
         * @static
         * @param {metering.IGetOrCreateDatasetScopeRequest=} [properties] Properties to set
         * @returns {metering.GetOrCreateDatasetScopeRequest} GetOrCreateDatasetScopeRequest instance
         */
        GetOrCreateDatasetScopeRequest.create = function create(properties) {
            return new GetOrCreateDatasetScopeRequest(properties);
        };

        /**
         * Encodes the specified GetOrCreateDatasetScopeRequest message. Does not implicitly {@link metering.GetOrCreateDatasetScopeRequest.verify|verify} messages.
         * @function encode
         * @memberof metering.GetOrCreateDatasetScopeRequest
         * @static
         * @param {metering.IGetOrCreateDatasetScopeRequest} message GetOrCreateDatasetScopeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetOrCreateDatasetScopeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.manifestHash != null && Object.hasOwnProperty.call(message, "manifestHash"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.manifestHash);
            return writer;
        };

        /**
         * Encodes the specified GetOrCreateDatasetScopeRequest message, length delimited. Does not implicitly {@link metering.GetOrCreateDatasetScopeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.GetOrCreateDatasetScopeRequest
         * @static
         * @param {metering.IGetOrCreateDatasetScopeRequest} message GetOrCreateDatasetScopeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetOrCreateDatasetScopeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetOrCreateDatasetScopeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof metering.GetOrCreateDatasetScopeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.GetOrCreateDatasetScopeRequest} GetOrCreateDatasetScopeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetOrCreateDatasetScopeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.GetOrCreateDatasetScopeRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.manifestHash = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetOrCreateDatasetScopeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.GetOrCreateDatasetScopeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.GetOrCreateDatasetScopeRequest} GetOrCreateDatasetScopeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetOrCreateDatasetScopeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetOrCreateDatasetScopeRequest message.
         * @function verify
         * @memberof metering.GetOrCreateDatasetScopeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetOrCreateDatasetScopeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.manifestHash != null && message.hasOwnProperty("manifestHash")) {
                properties._manifestHash = 1;
                if (!$util.isString(message.manifestHash))
                    return "manifestHash: string expected";
            }
            return null;
        };

        /**
         * Creates a GetOrCreateDatasetScopeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.GetOrCreateDatasetScopeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.GetOrCreateDatasetScopeRequest} GetOrCreateDatasetScopeRequest
         */
        GetOrCreateDatasetScopeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.GetOrCreateDatasetScopeRequest)
                return object;
            let message = new $root.metering.GetOrCreateDatasetScopeRequest();
            if (object.manifestHash != null)
                message.manifestHash = String(object.manifestHash);
            return message;
        };

        /**
         * Creates a plain object from a GetOrCreateDatasetScopeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.GetOrCreateDatasetScopeRequest
         * @static
         * @param {metering.GetOrCreateDatasetScopeRequest} message GetOrCreateDatasetScopeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetOrCreateDatasetScopeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.manifestHash != null && message.hasOwnProperty("manifestHash")) {
                object.manifestHash = message.manifestHash;
                if (options.oneofs)
                    object._manifestHash = "manifestHash";
            }
            return object;
        };

        /**
         * Converts this GetOrCreateDatasetScopeRequest to JSON.
         * @function toJSON
         * @memberof metering.GetOrCreateDatasetScopeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetOrCreateDatasetScopeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetOrCreateDatasetScopeRequest
         * @function getTypeUrl
         * @memberof metering.GetOrCreateDatasetScopeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetOrCreateDatasetScopeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.GetOrCreateDatasetScopeRequest";
        };

        return GetOrCreateDatasetScopeRequest;
    })();

    metering.UnpublishDatasetRequest = (function() {

        /**
         * Properties of an UnpublishDatasetRequest.
         * @memberof metering
         * @interface IUnpublishDatasetRequest
         * @property {string|null} [computeNodeId] The UUID of the compute node to which this dataset is being published
         * @property {string|null} [dataRoomHash] The hex-encoded hash of the data room
         * @property {string|null} [driverAttestationHash] The driver attestation hash
         */

        /**
         * Constructs a new UnpublishDatasetRequest.
         * @memberof metering
         * @classdesc Represents an UnpublishDatasetRequest.
         * @implements IUnpublishDatasetRequest
         * @constructor
         * @param {metering.IUnpublishDatasetRequest=} [properties] Properties to set
         */
        function UnpublishDatasetRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * The UUID of the compute node to which this dataset is being published
         * @member {string} computeNodeId
         * @memberof metering.UnpublishDatasetRequest
         * @instance
         */
        UnpublishDatasetRequest.prototype.computeNodeId = "";

        /**
         * The hex-encoded hash of the data room
         * @member {string} dataRoomHash
         * @memberof metering.UnpublishDatasetRequest
         * @instance
         */
        UnpublishDatasetRequest.prototype.dataRoomHash = "";

        /**
         * The driver attestation hash
         * @member {string} driverAttestationHash
         * @memberof metering.UnpublishDatasetRequest
         * @instance
         */
        UnpublishDatasetRequest.prototype.driverAttestationHash = "";

        /**
         * Creates a new UnpublishDatasetRequest instance using the specified properties.
         * @function create
         * @memberof metering.UnpublishDatasetRequest
         * @static
         * @param {metering.IUnpublishDatasetRequest=} [properties] Properties to set
         * @returns {metering.UnpublishDatasetRequest} UnpublishDatasetRequest instance
         */
        UnpublishDatasetRequest.create = function create(properties) {
            return new UnpublishDatasetRequest(properties);
        };

        /**
         * Encodes the specified UnpublishDatasetRequest message. Does not implicitly {@link metering.UnpublishDatasetRequest.verify|verify} messages.
         * @function encode
         * @memberof metering.UnpublishDatasetRequest
         * @static
         * @param {metering.IUnpublishDatasetRequest} message UnpublishDatasetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnpublishDatasetRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.computeNodeId != null && Object.hasOwnProperty.call(message, "computeNodeId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.computeNodeId);
            if (message.dataRoomHash != null && Object.hasOwnProperty.call(message, "dataRoomHash"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.dataRoomHash);
            if (message.driverAttestationHash != null && Object.hasOwnProperty.call(message, "driverAttestationHash"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.driverAttestationHash);
            return writer;
        };

        /**
         * Encodes the specified UnpublishDatasetRequest message, length delimited. Does not implicitly {@link metering.UnpublishDatasetRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.UnpublishDatasetRequest
         * @static
         * @param {metering.IUnpublishDatasetRequest} message UnpublishDatasetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnpublishDatasetRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UnpublishDatasetRequest message from the specified reader or buffer.
         * @function decode
         * @memberof metering.UnpublishDatasetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.UnpublishDatasetRequest} UnpublishDatasetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnpublishDatasetRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.UnpublishDatasetRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.computeNodeId = reader.string();
                        break;
                    }
                case 2: {
                        message.dataRoomHash = reader.string();
                        break;
                    }
                case 3: {
                        message.driverAttestationHash = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UnpublishDatasetRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.UnpublishDatasetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.UnpublishDatasetRequest} UnpublishDatasetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnpublishDatasetRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UnpublishDatasetRequest message.
         * @function verify
         * @memberof metering.UnpublishDatasetRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UnpublishDatasetRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.computeNodeId != null && message.hasOwnProperty("computeNodeId"))
                if (!$util.isString(message.computeNodeId))
                    return "computeNodeId: string expected";
            if (message.dataRoomHash != null && message.hasOwnProperty("dataRoomHash"))
                if (!$util.isString(message.dataRoomHash))
                    return "dataRoomHash: string expected";
            if (message.driverAttestationHash != null && message.hasOwnProperty("driverAttestationHash"))
                if (!$util.isString(message.driverAttestationHash))
                    return "driverAttestationHash: string expected";
            return null;
        };

        /**
         * Creates an UnpublishDatasetRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.UnpublishDatasetRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.UnpublishDatasetRequest} UnpublishDatasetRequest
         */
        UnpublishDatasetRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.UnpublishDatasetRequest)
                return object;
            let message = new $root.metering.UnpublishDatasetRequest();
            if (object.computeNodeId != null)
                message.computeNodeId = String(object.computeNodeId);
            if (object.dataRoomHash != null)
                message.dataRoomHash = String(object.dataRoomHash);
            if (object.driverAttestationHash != null)
                message.driverAttestationHash = String(object.driverAttestationHash);
            return message;
        };

        /**
         * Creates a plain object from an UnpublishDatasetRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.UnpublishDatasetRequest
         * @static
         * @param {metering.UnpublishDatasetRequest} message UnpublishDatasetRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UnpublishDatasetRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.computeNodeId = "";
                object.dataRoomHash = "";
                object.driverAttestationHash = "";
            }
            if (message.computeNodeId != null && message.hasOwnProperty("computeNodeId"))
                object.computeNodeId = message.computeNodeId;
            if (message.dataRoomHash != null && message.hasOwnProperty("dataRoomHash"))
                object.dataRoomHash = message.dataRoomHash;
            if (message.driverAttestationHash != null && message.hasOwnProperty("driverAttestationHash"))
                object.driverAttestationHash = message.driverAttestationHash;
            return object;
        };

        /**
         * Converts this UnpublishDatasetRequest to JSON.
         * @function toJSON
         * @memberof metering.UnpublishDatasetRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UnpublishDatasetRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UnpublishDatasetRequest
         * @function getTypeUrl
         * @memberof metering.UnpublishDatasetRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UnpublishDatasetRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.UnpublishDatasetRequest";
        };

        return UnpublishDatasetRequest;
    })();

    metering.StopDcrRequest = (function() {

        /**
         * Properties of a StopDcrRequest.
         * @memberof metering
         * @interface IStopDcrRequest
         * @property {string|null} [dataRoomHash] The hex-encoded data room hash
         * @property {string|null} [driverAttestationHash] The driver attestation hash
         */

        /**
         * Constructs a new StopDcrRequest.
         * @memberof metering
         * @classdesc Represents a StopDcrRequest.
         * @implements IStopDcrRequest
         * @constructor
         * @param {metering.IStopDcrRequest=} [properties] Properties to set
         */
        function StopDcrRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * The hex-encoded data room hash
         * @member {string} dataRoomHash
         * @memberof metering.StopDcrRequest
         * @instance
         */
        StopDcrRequest.prototype.dataRoomHash = "";

        /**
         * The driver attestation hash
         * @member {string} driverAttestationHash
         * @memberof metering.StopDcrRequest
         * @instance
         */
        StopDcrRequest.prototype.driverAttestationHash = "";

        /**
         * Creates a new StopDcrRequest instance using the specified properties.
         * @function create
         * @memberof metering.StopDcrRequest
         * @static
         * @param {metering.IStopDcrRequest=} [properties] Properties to set
         * @returns {metering.StopDcrRequest} StopDcrRequest instance
         */
        StopDcrRequest.create = function create(properties) {
            return new StopDcrRequest(properties);
        };

        /**
         * Encodes the specified StopDcrRequest message. Does not implicitly {@link metering.StopDcrRequest.verify|verify} messages.
         * @function encode
         * @memberof metering.StopDcrRequest
         * @static
         * @param {metering.IStopDcrRequest} message StopDcrRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StopDcrRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dataRoomHash != null && Object.hasOwnProperty.call(message, "dataRoomHash"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dataRoomHash);
            if (message.driverAttestationHash != null && Object.hasOwnProperty.call(message, "driverAttestationHash"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.driverAttestationHash);
            return writer;
        };

        /**
         * Encodes the specified StopDcrRequest message, length delimited. Does not implicitly {@link metering.StopDcrRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.StopDcrRequest
         * @static
         * @param {metering.IStopDcrRequest} message StopDcrRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StopDcrRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StopDcrRequest message from the specified reader or buffer.
         * @function decode
         * @memberof metering.StopDcrRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.StopDcrRequest} StopDcrRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StopDcrRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.StopDcrRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dataRoomHash = reader.string();
                        break;
                    }
                case 2: {
                        message.driverAttestationHash = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StopDcrRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.StopDcrRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.StopDcrRequest} StopDcrRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StopDcrRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StopDcrRequest message.
         * @function verify
         * @memberof metering.StopDcrRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StopDcrRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dataRoomHash != null && message.hasOwnProperty("dataRoomHash"))
                if (!$util.isString(message.dataRoomHash))
                    return "dataRoomHash: string expected";
            if (message.driverAttestationHash != null && message.hasOwnProperty("driverAttestationHash"))
                if (!$util.isString(message.driverAttestationHash))
                    return "driverAttestationHash: string expected";
            return null;
        };

        /**
         * Creates a StopDcrRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.StopDcrRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.StopDcrRequest} StopDcrRequest
         */
        StopDcrRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.StopDcrRequest)
                return object;
            let message = new $root.metering.StopDcrRequest();
            if (object.dataRoomHash != null)
                message.dataRoomHash = String(object.dataRoomHash);
            if (object.driverAttestationHash != null)
                message.driverAttestationHash = String(object.driverAttestationHash);
            return message;
        };

        /**
         * Creates a plain object from a StopDcrRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.StopDcrRequest
         * @static
         * @param {metering.StopDcrRequest} message StopDcrRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StopDcrRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.dataRoomHash = "";
                object.driverAttestationHash = "";
            }
            if (message.dataRoomHash != null && message.hasOwnProperty("dataRoomHash"))
                object.dataRoomHash = message.dataRoomHash;
            if (message.driverAttestationHash != null && message.hasOwnProperty("driverAttestationHash"))
                object.driverAttestationHash = message.driverAttestationHash;
            return object;
        };

        /**
         * Converts this StopDcrRequest to JSON.
         * @function toJSON
         * @memberof metering.StopDcrRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StopDcrRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StopDcrRequest
         * @function getTypeUrl
         * @memberof metering.StopDcrRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StopDcrRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.StopDcrRequest";
        };

        return StopDcrRequest;
    })();

    metering.CreateDcrCommitRequest = (function() {

        /**
         * Properties of a CreateDcrCommitRequest.
         * @memberof metering
         * @interface ICreateDcrCommitRequest
         * @property {string|null} [id] The commit id
         * @property {string|null} [dcrIdHex] The DCR hash the commit refers to
         * @property {string|null} [driverAttestationHash] The driver attestation hash
         * @property {Array.<metering.IEnclaveInfo>|null} [enclaveInfo] Information about the enclaves used in this commit
         */

        /**
         * Constructs a new CreateDcrCommitRequest.
         * @memberof metering
         * @classdesc Represents a CreateDcrCommitRequest.
         * @implements ICreateDcrCommitRequest
         * @constructor
         * @param {metering.ICreateDcrCommitRequest=} [properties] Properties to set
         */
        function CreateDcrCommitRequest(properties) {
            this.enclaveInfo = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * The commit id
         * @member {string} id
         * @memberof metering.CreateDcrCommitRequest
         * @instance
         */
        CreateDcrCommitRequest.prototype.id = "";

        /**
         * The DCR hash the commit refers to
         * @member {string} dcrIdHex
         * @memberof metering.CreateDcrCommitRequest
         * @instance
         */
        CreateDcrCommitRequest.prototype.dcrIdHex = "";

        /**
         * The driver attestation hash
         * @member {string} driverAttestationHash
         * @memberof metering.CreateDcrCommitRequest
         * @instance
         */
        CreateDcrCommitRequest.prototype.driverAttestationHash = "";

        /**
         * Information about the enclaves used in this commit
         * @member {Array.<metering.IEnclaveInfo>} enclaveInfo
         * @memberof metering.CreateDcrCommitRequest
         * @instance
         */
        CreateDcrCommitRequest.prototype.enclaveInfo = $util.emptyArray;

        /**
         * Creates a new CreateDcrCommitRequest instance using the specified properties.
         * @function create
         * @memberof metering.CreateDcrCommitRequest
         * @static
         * @param {metering.ICreateDcrCommitRequest=} [properties] Properties to set
         * @returns {metering.CreateDcrCommitRequest} CreateDcrCommitRequest instance
         */
        CreateDcrCommitRequest.create = function create(properties) {
            return new CreateDcrCommitRequest(properties);
        };

        /**
         * Encodes the specified CreateDcrCommitRequest message. Does not implicitly {@link metering.CreateDcrCommitRequest.verify|verify} messages.
         * @function encode
         * @memberof metering.CreateDcrCommitRequest
         * @static
         * @param {metering.ICreateDcrCommitRequest} message CreateDcrCommitRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateDcrCommitRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.dcrIdHex != null && Object.hasOwnProperty.call(message, "dcrIdHex"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.dcrIdHex);
            if (message.driverAttestationHash != null && Object.hasOwnProperty.call(message, "driverAttestationHash"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.driverAttestationHash);
            if (message.enclaveInfo != null && message.enclaveInfo.length)
                for (let i = 0; i < message.enclaveInfo.length; ++i)
                    $root.metering.EnclaveInfo.encode(message.enclaveInfo[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateDcrCommitRequest message, length delimited. Does not implicitly {@link metering.CreateDcrCommitRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.CreateDcrCommitRequest
         * @static
         * @param {metering.ICreateDcrCommitRequest} message CreateDcrCommitRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateDcrCommitRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateDcrCommitRequest message from the specified reader or buffer.
         * @function decode
         * @memberof metering.CreateDcrCommitRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.CreateDcrCommitRequest} CreateDcrCommitRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateDcrCommitRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.CreateDcrCommitRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.dcrIdHex = reader.string();
                        break;
                    }
                case 3: {
                        message.driverAttestationHash = reader.string();
                        break;
                    }
                case 6: {
                        if (!(message.enclaveInfo && message.enclaveInfo.length))
                            message.enclaveInfo = [];
                        message.enclaveInfo.push($root.metering.EnclaveInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateDcrCommitRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.CreateDcrCommitRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.CreateDcrCommitRequest} CreateDcrCommitRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateDcrCommitRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateDcrCommitRequest message.
         * @function verify
         * @memberof metering.CreateDcrCommitRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateDcrCommitRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.dcrIdHex != null && message.hasOwnProperty("dcrIdHex"))
                if (!$util.isString(message.dcrIdHex))
                    return "dcrIdHex: string expected";
            if (message.driverAttestationHash != null && message.hasOwnProperty("driverAttestationHash"))
                if (!$util.isString(message.driverAttestationHash))
                    return "driverAttestationHash: string expected";
            if (message.enclaveInfo != null && message.hasOwnProperty("enclaveInfo")) {
                if (!Array.isArray(message.enclaveInfo))
                    return "enclaveInfo: array expected";
                for (let i = 0; i < message.enclaveInfo.length; ++i) {
                    let error = $root.metering.EnclaveInfo.verify(message.enclaveInfo[i]);
                    if (error)
                        return "enclaveInfo." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CreateDcrCommitRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.CreateDcrCommitRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.CreateDcrCommitRequest} CreateDcrCommitRequest
         */
        CreateDcrCommitRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.CreateDcrCommitRequest)
                return object;
            let message = new $root.metering.CreateDcrCommitRequest();
            if (object.id != null)
                message.id = String(object.id);
            if (object.dcrIdHex != null)
                message.dcrIdHex = String(object.dcrIdHex);
            if (object.driverAttestationHash != null)
                message.driverAttestationHash = String(object.driverAttestationHash);
            if (object.enclaveInfo) {
                if (!Array.isArray(object.enclaveInfo))
                    throw TypeError(".metering.CreateDcrCommitRequest.enclaveInfo: array expected");
                message.enclaveInfo = [];
                for (let i = 0; i < object.enclaveInfo.length; ++i) {
                    if (typeof object.enclaveInfo[i] !== "object")
                        throw TypeError(".metering.CreateDcrCommitRequest.enclaveInfo: object expected");
                    message.enclaveInfo[i] = $root.metering.EnclaveInfo.fromObject(object.enclaveInfo[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateDcrCommitRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.CreateDcrCommitRequest
         * @static
         * @param {metering.CreateDcrCommitRequest} message CreateDcrCommitRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateDcrCommitRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.enclaveInfo = [];
            if (options.defaults) {
                object.id = "";
                object.dcrIdHex = "";
                object.driverAttestationHash = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.dcrIdHex != null && message.hasOwnProperty("dcrIdHex"))
                object.dcrIdHex = message.dcrIdHex;
            if (message.driverAttestationHash != null && message.hasOwnProperty("driverAttestationHash"))
                object.driverAttestationHash = message.driverAttestationHash;
            if (message.enclaveInfo && message.enclaveInfo.length) {
                object.enclaveInfo = [];
                for (let j = 0; j < message.enclaveInfo.length; ++j)
                    object.enclaveInfo[j] = $root.metering.EnclaveInfo.toObject(message.enclaveInfo[j], options);
            }
            return object;
        };

        /**
         * Converts this CreateDcrCommitRequest to JSON.
         * @function toJSON
         * @memberof metering.CreateDcrCommitRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateDcrCommitRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateDcrCommitRequest
         * @function getTypeUrl
         * @memberof metering.CreateDcrCommitRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateDcrCommitRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.CreateDcrCommitRequest";
        };

        return CreateDcrCommitRequest;
    })();

    metering.MergeDcrCommitRequest = (function() {

        /**
         * Properties of a MergeDcrCommitRequest.
         * @memberof metering
         * @interface IMergeDcrCommitRequest
         * @property {string|null} [id] The commit id
         * @property {string|null} [dcrIdHex] The DCR hash the commit refers to
         * @property {string|null} [driverAttestationHash] The driver attestation hash
         * @property {Array.<metering.IEnclaveInfo>|null} [enclaveInfo] Information about the enclaves that were added to the DCR
         */

        /**
         * Constructs a new MergeDcrCommitRequest.
         * @memberof metering
         * @classdesc Represents a MergeDcrCommitRequest.
         * @implements IMergeDcrCommitRequest
         * @constructor
         * @param {metering.IMergeDcrCommitRequest=} [properties] Properties to set
         */
        function MergeDcrCommitRequest(properties) {
            this.enclaveInfo = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * The commit id
         * @member {string} id
         * @memberof metering.MergeDcrCommitRequest
         * @instance
         */
        MergeDcrCommitRequest.prototype.id = "";

        /**
         * The DCR hash the commit refers to
         * @member {string} dcrIdHex
         * @memberof metering.MergeDcrCommitRequest
         * @instance
         */
        MergeDcrCommitRequest.prototype.dcrIdHex = "";

        /**
         * The driver attestation hash
         * @member {string} driverAttestationHash
         * @memberof metering.MergeDcrCommitRequest
         * @instance
         */
        MergeDcrCommitRequest.prototype.driverAttestationHash = "";

        /**
         * Information about the enclaves that were added to the DCR
         * @member {Array.<metering.IEnclaveInfo>} enclaveInfo
         * @memberof metering.MergeDcrCommitRequest
         * @instance
         */
        MergeDcrCommitRequest.prototype.enclaveInfo = $util.emptyArray;

        /**
         * Creates a new MergeDcrCommitRequest instance using the specified properties.
         * @function create
         * @memberof metering.MergeDcrCommitRequest
         * @static
         * @param {metering.IMergeDcrCommitRequest=} [properties] Properties to set
         * @returns {metering.MergeDcrCommitRequest} MergeDcrCommitRequest instance
         */
        MergeDcrCommitRequest.create = function create(properties) {
            return new MergeDcrCommitRequest(properties);
        };

        /**
         * Encodes the specified MergeDcrCommitRequest message. Does not implicitly {@link metering.MergeDcrCommitRequest.verify|verify} messages.
         * @function encode
         * @memberof metering.MergeDcrCommitRequest
         * @static
         * @param {metering.IMergeDcrCommitRequest} message MergeDcrCommitRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MergeDcrCommitRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.dcrIdHex != null && Object.hasOwnProperty.call(message, "dcrIdHex"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.dcrIdHex);
            if (message.driverAttestationHash != null && Object.hasOwnProperty.call(message, "driverAttestationHash"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.driverAttestationHash);
            if (message.enclaveInfo != null && message.enclaveInfo.length)
                for (let i = 0; i < message.enclaveInfo.length; ++i)
                    $root.metering.EnclaveInfo.encode(message.enclaveInfo[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MergeDcrCommitRequest message, length delimited. Does not implicitly {@link metering.MergeDcrCommitRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.MergeDcrCommitRequest
         * @static
         * @param {metering.IMergeDcrCommitRequest} message MergeDcrCommitRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MergeDcrCommitRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MergeDcrCommitRequest message from the specified reader or buffer.
         * @function decode
         * @memberof metering.MergeDcrCommitRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.MergeDcrCommitRequest} MergeDcrCommitRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MergeDcrCommitRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.MergeDcrCommitRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.dcrIdHex = reader.string();
                        break;
                    }
                case 3: {
                        message.driverAttestationHash = reader.string();
                        break;
                    }
                case 6: {
                        if (!(message.enclaveInfo && message.enclaveInfo.length))
                            message.enclaveInfo = [];
                        message.enclaveInfo.push($root.metering.EnclaveInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MergeDcrCommitRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.MergeDcrCommitRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.MergeDcrCommitRequest} MergeDcrCommitRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MergeDcrCommitRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MergeDcrCommitRequest message.
         * @function verify
         * @memberof metering.MergeDcrCommitRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MergeDcrCommitRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.dcrIdHex != null && message.hasOwnProperty("dcrIdHex"))
                if (!$util.isString(message.dcrIdHex))
                    return "dcrIdHex: string expected";
            if (message.driverAttestationHash != null && message.hasOwnProperty("driverAttestationHash"))
                if (!$util.isString(message.driverAttestationHash))
                    return "driverAttestationHash: string expected";
            if (message.enclaveInfo != null && message.hasOwnProperty("enclaveInfo")) {
                if (!Array.isArray(message.enclaveInfo))
                    return "enclaveInfo: array expected";
                for (let i = 0; i < message.enclaveInfo.length; ++i) {
                    let error = $root.metering.EnclaveInfo.verify(message.enclaveInfo[i]);
                    if (error)
                        return "enclaveInfo." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MergeDcrCommitRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.MergeDcrCommitRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.MergeDcrCommitRequest} MergeDcrCommitRequest
         */
        MergeDcrCommitRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.MergeDcrCommitRequest)
                return object;
            let message = new $root.metering.MergeDcrCommitRequest();
            if (object.id != null)
                message.id = String(object.id);
            if (object.dcrIdHex != null)
                message.dcrIdHex = String(object.dcrIdHex);
            if (object.driverAttestationHash != null)
                message.driverAttestationHash = String(object.driverAttestationHash);
            if (object.enclaveInfo) {
                if (!Array.isArray(object.enclaveInfo))
                    throw TypeError(".metering.MergeDcrCommitRequest.enclaveInfo: array expected");
                message.enclaveInfo = [];
                for (let i = 0; i < object.enclaveInfo.length; ++i) {
                    if (typeof object.enclaveInfo[i] !== "object")
                        throw TypeError(".metering.MergeDcrCommitRequest.enclaveInfo: object expected");
                    message.enclaveInfo[i] = $root.metering.EnclaveInfo.fromObject(object.enclaveInfo[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a MergeDcrCommitRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.MergeDcrCommitRequest
         * @static
         * @param {metering.MergeDcrCommitRequest} message MergeDcrCommitRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MergeDcrCommitRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.enclaveInfo = [];
            if (options.defaults) {
                object.id = "";
                object.dcrIdHex = "";
                object.driverAttestationHash = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.dcrIdHex != null && message.hasOwnProperty("dcrIdHex"))
                object.dcrIdHex = message.dcrIdHex;
            if (message.driverAttestationHash != null && message.hasOwnProperty("driverAttestationHash"))
                object.driverAttestationHash = message.driverAttestationHash;
            if (message.enclaveInfo && message.enclaveInfo.length) {
                object.enclaveInfo = [];
                for (let j = 0; j < message.enclaveInfo.length; ++j)
                    object.enclaveInfo[j] = $root.metering.EnclaveInfo.toObject(message.enclaveInfo[j], options);
            }
            return object;
        };

        /**
         * Converts this MergeDcrCommitRequest to JSON.
         * @function toJSON
         * @memberof metering.MergeDcrCommitRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MergeDcrCommitRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MergeDcrCommitRequest
         * @function getTypeUrl
         * @memberof metering.MergeDcrCommitRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MergeDcrCommitRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.MergeDcrCommitRequest";
        };

        return MergeDcrCommitRequest;
    })();

    metering.CreateDcrResponse = (function() {

        /**
         * Properties of a CreateDcrResponse.
         * @memberof metering
         * @interface ICreateDcrResponse
         */

        /**
         * Constructs a new CreateDcrResponse.
         * @memberof metering
         * @classdesc Represents a CreateDcrResponse.
         * @implements ICreateDcrResponse
         * @constructor
         * @param {metering.ICreateDcrResponse=} [properties] Properties to set
         */
        function CreateDcrResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new CreateDcrResponse instance using the specified properties.
         * @function create
         * @memberof metering.CreateDcrResponse
         * @static
         * @param {metering.ICreateDcrResponse=} [properties] Properties to set
         * @returns {metering.CreateDcrResponse} CreateDcrResponse instance
         */
        CreateDcrResponse.create = function create(properties) {
            return new CreateDcrResponse(properties);
        };

        /**
         * Encodes the specified CreateDcrResponse message. Does not implicitly {@link metering.CreateDcrResponse.verify|verify} messages.
         * @function encode
         * @memberof metering.CreateDcrResponse
         * @static
         * @param {metering.ICreateDcrResponse} message CreateDcrResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateDcrResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified CreateDcrResponse message, length delimited. Does not implicitly {@link metering.CreateDcrResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.CreateDcrResponse
         * @static
         * @param {metering.ICreateDcrResponse} message CreateDcrResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateDcrResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateDcrResponse message from the specified reader or buffer.
         * @function decode
         * @memberof metering.CreateDcrResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.CreateDcrResponse} CreateDcrResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateDcrResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.CreateDcrResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateDcrResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.CreateDcrResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.CreateDcrResponse} CreateDcrResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateDcrResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateDcrResponse message.
         * @function verify
         * @memberof metering.CreateDcrResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateDcrResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a CreateDcrResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.CreateDcrResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.CreateDcrResponse} CreateDcrResponse
         */
        CreateDcrResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.CreateDcrResponse)
                return object;
            return new $root.metering.CreateDcrResponse();
        };

        /**
         * Creates a plain object from a CreateDcrResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.CreateDcrResponse
         * @static
         * @param {metering.CreateDcrResponse} message CreateDcrResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateDcrResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this CreateDcrResponse to JSON.
         * @function toJSON
         * @memberof metering.CreateDcrResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateDcrResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateDcrResponse
         * @function getTypeUrl
         * @memberof metering.CreateDcrResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateDcrResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.CreateDcrResponse";
        };

        return CreateDcrResponse;
    })();

    metering.GetOrCreateDatasetScopeResponse = (function() {

        /**
         * Properties of a GetOrCreateDatasetScopeResponse.
         * @memberof metering
         * @interface IGetOrCreateDatasetScopeResponse
         * @property {string|null} [id] GetOrCreateDatasetScopeResponse id
         */

        /**
         * Constructs a new GetOrCreateDatasetScopeResponse.
         * @memberof metering
         * @classdesc Represents a GetOrCreateDatasetScopeResponse.
         * @implements IGetOrCreateDatasetScopeResponse
         * @constructor
         * @param {metering.IGetOrCreateDatasetScopeResponse=} [properties] Properties to set
         */
        function GetOrCreateDatasetScopeResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetOrCreateDatasetScopeResponse id.
         * @member {string} id
         * @memberof metering.GetOrCreateDatasetScopeResponse
         * @instance
         */
        GetOrCreateDatasetScopeResponse.prototype.id = "";

        /**
         * Creates a new GetOrCreateDatasetScopeResponse instance using the specified properties.
         * @function create
         * @memberof metering.GetOrCreateDatasetScopeResponse
         * @static
         * @param {metering.IGetOrCreateDatasetScopeResponse=} [properties] Properties to set
         * @returns {metering.GetOrCreateDatasetScopeResponse} GetOrCreateDatasetScopeResponse instance
         */
        GetOrCreateDatasetScopeResponse.create = function create(properties) {
            return new GetOrCreateDatasetScopeResponse(properties);
        };

        /**
         * Encodes the specified GetOrCreateDatasetScopeResponse message. Does not implicitly {@link metering.GetOrCreateDatasetScopeResponse.verify|verify} messages.
         * @function encode
         * @memberof metering.GetOrCreateDatasetScopeResponse
         * @static
         * @param {metering.IGetOrCreateDatasetScopeResponse} message GetOrCreateDatasetScopeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetOrCreateDatasetScopeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified GetOrCreateDatasetScopeResponse message, length delimited. Does not implicitly {@link metering.GetOrCreateDatasetScopeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.GetOrCreateDatasetScopeResponse
         * @static
         * @param {metering.IGetOrCreateDatasetScopeResponse} message GetOrCreateDatasetScopeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetOrCreateDatasetScopeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetOrCreateDatasetScopeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof metering.GetOrCreateDatasetScopeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.GetOrCreateDatasetScopeResponse} GetOrCreateDatasetScopeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetOrCreateDatasetScopeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.GetOrCreateDatasetScopeResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetOrCreateDatasetScopeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.GetOrCreateDatasetScopeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.GetOrCreateDatasetScopeResponse} GetOrCreateDatasetScopeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetOrCreateDatasetScopeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetOrCreateDatasetScopeResponse message.
         * @function verify
         * @memberof metering.GetOrCreateDatasetScopeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetOrCreateDatasetScopeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates a GetOrCreateDatasetScopeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.GetOrCreateDatasetScopeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.GetOrCreateDatasetScopeResponse} GetOrCreateDatasetScopeResponse
         */
        GetOrCreateDatasetScopeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.GetOrCreateDatasetScopeResponse)
                return object;
            let message = new $root.metering.GetOrCreateDatasetScopeResponse();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a GetOrCreateDatasetScopeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.GetOrCreateDatasetScopeResponse
         * @static
         * @param {metering.GetOrCreateDatasetScopeResponse} message GetOrCreateDatasetScopeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetOrCreateDatasetScopeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this GetOrCreateDatasetScopeResponse to JSON.
         * @function toJSON
         * @memberof metering.GetOrCreateDatasetScopeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetOrCreateDatasetScopeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetOrCreateDatasetScopeResponse
         * @function getTypeUrl
         * @memberof metering.GetOrCreateDatasetScopeResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetOrCreateDatasetScopeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.GetOrCreateDatasetScopeResponse";
        };

        return GetOrCreateDatasetScopeResponse;
    })();

    metering.DcrInteractionResponse = (function() {

        /**
         * Properties of a DcrInteractionResponse.
         * @memberof metering
         * @interface IDcrInteractionResponse
         */

        /**
         * Constructs a new DcrInteractionResponse.
         * @memberof metering
         * @classdesc Represents a DcrInteractionResponse.
         * @implements IDcrInteractionResponse
         * @constructor
         * @param {metering.IDcrInteractionResponse=} [properties] Properties to set
         */
        function DcrInteractionResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DcrInteractionResponse instance using the specified properties.
         * @function create
         * @memberof metering.DcrInteractionResponse
         * @static
         * @param {metering.IDcrInteractionResponse=} [properties] Properties to set
         * @returns {metering.DcrInteractionResponse} DcrInteractionResponse instance
         */
        DcrInteractionResponse.create = function create(properties) {
            return new DcrInteractionResponse(properties);
        };

        /**
         * Encodes the specified DcrInteractionResponse message. Does not implicitly {@link metering.DcrInteractionResponse.verify|verify} messages.
         * @function encode
         * @memberof metering.DcrInteractionResponse
         * @static
         * @param {metering.IDcrInteractionResponse} message DcrInteractionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrInteractionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DcrInteractionResponse message, length delimited. Does not implicitly {@link metering.DcrInteractionResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.DcrInteractionResponse
         * @static
         * @param {metering.IDcrInteractionResponse} message DcrInteractionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DcrInteractionResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DcrInteractionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof metering.DcrInteractionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.DcrInteractionResponse} DcrInteractionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrInteractionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.DcrInteractionResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DcrInteractionResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.DcrInteractionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.DcrInteractionResponse} DcrInteractionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DcrInteractionResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DcrInteractionResponse message.
         * @function verify
         * @memberof metering.DcrInteractionResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DcrInteractionResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DcrInteractionResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.DcrInteractionResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.DcrInteractionResponse} DcrInteractionResponse
         */
        DcrInteractionResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.DcrInteractionResponse)
                return object;
            return new $root.metering.DcrInteractionResponse();
        };

        /**
         * Creates a plain object from a DcrInteractionResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.DcrInteractionResponse
         * @static
         * @param {metering.DcrInteractionResponse} message DcrInteractionResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DcrInteractionResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DcrInteractionResponse to JSON.
         * @function toJSON
         * @memberof metering.DcrInteractionResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DcrInteractionResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DcrInteractionResponse
         * @function getTypeUrl
         * @memberof metering.DcrInteractionResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DcrInteractionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.DcrInteractionResponse";
        };

        return DcrInteractionResponse;
    })();

    metering.CreateDcrCommitResponse = (function() {

        /**
         * Properties of a CreateDcrCommitResponse.
         * @memberof metering
         * @interface ICreateDcrCommitResponse
         */

        /**
         * Constructs a new CreateDcrCommitResponse.
         * @memberof metering
         * @classdesc Represents a CreateDcrCommitResponse.
         * @implements ICreateDcrCommitResponse
         * @constructor
         * @param {metering.ICreateDcrCommitResponse=} [properties] Properties to set
         */
        function CreateDcrCommitResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new CreateDcrCommitResponse instance using the specified properties.
         * @function create
         * @memberof metering.CreateDcrCommitResponse
         * @static
         * @param {metering.ICreateDcrCommitResponse=} [properties] Properties to set
         * @returns {metering.CreateDcrCommitResponse} CreateDcrCommitResponse instance
         */
        CreateDcrCommitResponse.create = function create(properties) {
            return new CreateDcrCommitResponse(properties);
        };

        /**
         * Encodes the specified CreateDcrCommitResponse message. Does not implicitly {@link metering.CreateDcrCommitResponse.verify|verify} messages.
         * @function encode
         * @memberof metering.CreateDcrCommitResponse
         * @static
         * @param {metering.ICreateDcrCommitResponse} message CreateDcrCommitResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateDcrCommitResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified CreateDcrCommitResponse message, length delimited. Does not implicitly {@link metering.CreateDcrCommitResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.CreateDcrCommitResponse
         * @static
         * @param {metering.ICreateDcrCommitResponse} message CreateDcrCommitResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateDcrCommitResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateDcrCommitResponse message from the specified reader or buffer.
         * @function decode
         * @memberof metering.CreateDcrCommitResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.CreateDcrCommitResponse} CreateDcrCommitResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateDcrCommitResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.CreateDcrCommitResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateDcrCommitResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.CreateDcrCommitResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.CreateDcrCommitResponse} CreateDcrCommitResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateDcrCommitResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateDcrCommitResponse message.
         * @function verify
         * @memberof metering.CreateDcrCommitResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateDcrCommitResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a CreateDcrCommitResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.CreateDcrCommitResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.CreateDcrCommitResponse} CreateDcrCommitResponse
         */
        CreateDcrCommitResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.CreateDcrCommitResponse)
                return object;
            return new $root.metering.CreateDcrCommitResponse();
        };

        /**
         * Creates a plain object from a CreateDcrCommitResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.CreateDcrCommitResponse
         * @static
         * @param {metering.CreateDcrCommitResponse} message CreateDcrCommitResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateDcrCommitResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this CreateDcrCommitResponse to JSON.
         * @function toJSON
         * @memberof metering.CreateDcrCommitResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateDcrCommitResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateDcrCommitResponse
         * @function getTypeUrl
         * @memberof metering.CreateDcrCommitResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateDcrCommitResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.CreateDcrCommitResponse";
        };

        return CreateDcrCommitResponse;
    })();

    metering.MergeDcrCommitResponse = (function() {

        /**
         * Properties of a MergeDcrCommitResponse.
         * @memberof metering
         * @interface IMergeDcrCommitResponse
         */

        /**
         * Constructs a new MergeDcrCommitResponse.
         * @memberof metering
         * @classdesc Represents a MergeDcrCommitResponse.
         * @implements IMergeDcrCommitResponse
         * @constructor
         * @param {metering.IMergeDcrCommitResponse=} [properties] Properties to set
         */
        function MergeDcrCommitResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new MergeDcrCommitResponse instance using the specified properties.
         * @function create
         * @memberof metering.MergeDcrCommitResponse
         * @static
         * @param {metering.IMergeDcrCommitResponse=} [properties] Properties to set
         * @returns {metering.MergeDcrCommitResponse} MergeDcrCommitResponse instance
         */
        MergeDcrCommitResponse.create = function create(properties) {
            return new MergeDcrCommitResponse(properties);
        };

        /**
         * Encodes the specified MergeDcrCommitResponse message. Does not implicitly {@link metering.MergeDcrCommitResponse.verify|verify} messages.
         * @function encode
         * @memberof metering.MergeDcrCommitResponse
         * @static
         * @param {metering.IMergeDcrCommitResponse} message MergeDcrCommitResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MergeDcrCommitResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified MergeDcrCommitResponse message, length delimited. Does not implicitly {@link metering.MergeDcrCommitResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.MergeDcrCommitResponse
         * @static
         * @param {metering.IMergeDcrCommitResponse} message MergeDcrCommitResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MergeDcrCommitResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MergeDcrCommitResponse message from the specified reader or buffer.
         * @function decode
         * @memberof metering.MergeDcrCommitResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.MergeDcrCommitResponse} MergeDcrCommitResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MergeDcrCommitResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.MergeDcrCommitResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MergeDcrCommitResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.MergeDcrCommitResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.MergeDcrCommitResponse} MergeDcrCommitResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MergeDcrCommitResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MergeDcrCommitResponse message.
         * @function verify
         * @memberof metering.MergeDcrCommitResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MergeDcrCommitResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a MergeDcrCommitResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.MergeDcrCommitResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.MergeDcrCommitResponse} MergeDcrCommitResponse
         */
        MergeDcrCommitResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.MergeDcrCommitResponse)
                return object;
            return new $root.metering.MergeDcrCommitResponse();
        };

        /**
         * Creates a plain object from a MergeDcrCommitResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.MergeDcrCommitResponse
         * @static
         * @param {metering.MergeDcrCommitResponse} message MergeDcrCommitResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MergeDcrCommitResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this MergeDcrCommitResponse to JSON.
         * @function toJSON
         * @memberof metering.MergeDcrCommitResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MergeDcrCommitResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MergeDcrCommitResponse
         * @function getTypeUrl
         * @memberof metering.MergeDcrCommitResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MergeDcrCommitResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.MergeDcrCommitResponse";
        };

        return MergeDcrCommitResponse;
    })();

    metering.StopDcrResponse = (function() {

        /**
         * Properties of a StopDcrResponse.
         * @memberof metering
         * @interface IStopDcrResponse
         */

        /**
         * Constructs a new StopDcrResponse.
         * @memberof metering
         * @classdesc Represents a StopDcrResponse.
         * @implements IStopDcrResponse
         * @constructor
         * @param {metering.IStopDcrResponse=} [properties] Properties to set
         */
        function StopDcrResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new StopDcrResponse instance using the specified properties.
         * @function create
         * @memberof metering.StopDcrResponse
         * @static
         * @param {metering.IStopDcrResponse=} [properties] Properties to set
         * @returns {metering.StopDcrResponse} StopDcrResponse instance
         */
        StopDcrResponse.create = function create(properties) {
            return new StopDcrResponse(properties);
        };

        /**
         * Encodes the specified StopDcrResponse message. Does not implicitly {@link metering.StopDcrResponse.verify|verify} messages.
         * @function encode
         * @memberof metering.StopDcrResponse
         * @static
         * @param {metering.IStopDcrResponse} message StopDcrResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StopDcrResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified StopDcrResponse message, length delimited. Does not implicitly {@link metering.StopDcrResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.StopDcrResponse
         * @static
         * @param {metering.IStopDcrResponse} message StopDcrResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StopDcrResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StopDcrResponse message from the specified reader or buffer.
         * @function decode
         * @memberof metering.StopDcrResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.StopDcrResponse} StopDcrResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StopDcrResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.StopDcrResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StopDcrResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.StopDcrResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.StopDcrResponse} StopDcrResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StopDcrResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StopDcrResponse message.
         * @function verify
         * @memberof metering.StopDcrResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StopDcrResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a StopDcrResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.StopDcrResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.StopDcrResponse} StopDcrResponse
         */
        StopDcrResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.StopDcrResponse)
                return object;
            return new $root.metering.StopDcrResponse();
        };

        /**
         * Creates a plain object from a StopDcrResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.StopDcrResponse
         * @static
         * @param {metering.StopDcrResponse} message StopDcrResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StopDcrResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this StopDcrResponse to JSON.
         * @function toJSON
         * @memberof metering.StopDcrResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StopDcrResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StopDcrResponse
         * @function getTypeUrl
         * @memberof metering.StopDcrResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StopDcrResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.StopDcrResponse";
        };

        return StopDcrResponse;
    })();

    metering.PublishDatasetResponse = (function() {

        /**
         * Properties of a PublishDatasetResponse.
         * @memberof metering
         * @interface IPublishDatasetResponse
         */

        /**
         * Constructs a new PublishDatasetResponse.
         * @memberof metering
         * @classdesc Represents a PublishDatasetResponse.
         * @implements IPublishDatasetResponse
         * @constructor
         * @param {metering.IPublishDatasetResponse=} [properties] Properties to set
         */
        function PublishDatasetResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new PublishDatasetResponse instance using the specified properties.
         * @function create
         * @memberof metering.PublishDatasetResponse
         * @static
         * @param {metering.IPublishDatasetResponse=} [properties] Properties to set
         * @returns {metering.PublishDatasetResponse} PublishDatasetResponse instance
         */
        PublishDatasetResponse.create = function create(properties) {
            return new PublishDatasetResponse(properties);
        };

        /**
         * Encodes the specified PublishDatasetResponse message. Does not implicitly {@link metering.PublishDatasetResponse.verify|verify} messages.
         * @function encode
         * @memberof metering.PublishDatasetResponse
         * @static
         * @param {metering.IPublishDatasetResponse} message PublishDatasetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublishDatasetResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified PublishDatasetResponse message, length delimited. Does not implicitly {@link metering.PublishDatasetResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.PublishDatasetResponse
         * @static
         * @param {metering.IPublishDatasetResponse} message PublishDatasetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublishDatasetResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PublishDatasetResponse message from the specified reader or buffer.
         * @function decode
         * @memberof metering.PublishDatasetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.PublishDatasetResponse} PublishDatasetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublishDatasetResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.PublishDatasetResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PublishDatasetResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.PublishDatasetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.PublishDatasetResponse} PublishDatasetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublishDatasetResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PublishDatasetResponse message.
         * @function verify
         * @memberof metering.PublishDatasetResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PublishDatasetResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a PublishDatasetResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.PublishDatasetResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.PublishDatasetResponse} PublishDatasetResponse
         */
        PublishDatasetResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.PublishDatasetResponse)
                return object;
            return new $root.metering.PublishDatasetResponse();
        };

        /**
         * Creates a plain object from a PublishDatasetResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.PublishDatasetResponse
         * @static
         * @param {metering.PublishDatasetResponse} message PublishDatasetResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PublishDatasetResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this PublishDatasetResponse to JSON.
         * @function toJSON
         * @memberof metering.PublishDatasetResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PublishDatasetResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PublishDatasetResponse
         * @function getTypeUrl
         * @memberof metering.PublishDatasetResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PublishDatasetResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.PublishDatasetResponse";
        };

        return PublishDatasetResponse;
    })();

    metering.CreateDatasetResponse = (function() {

        /**
         * Properties of a CreateDatasetResponse.
         * @memberof metering
         * @interface ICreateDatasetResponse
         * @property {string|null} [id] CreateDatasetResponse id
         */

        /**
         * Constructs a new CreateDatasetResponse.
         * @memberof metering
         * @classdesc Represents a CreateDatasetResponse.
         * @implements ICreateDatasetResponse
         * @constructor
         * @param {metering.ICreateDatasetResponse=} [properties] Properties to set
         */
        function CreateDatasetResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateDatasetResponse id.
         * @member {string} id
         * @memberof metering.CreateDatasetResponse
         * @instance
         */
        CreateDatasetResponse.prototype.id = "";

        /**
         * Creates a new CreateDatasetResponse instance using the specified properties.
         * @function create
         * @memberof metering.CreateDatasetResponse
         * @static
         * @param {metering.ICreateDatasetResponse=} [properties] Properties to set
         * @returns {metering.CreateDatasetResponse} CreateDatasetResponse instance
         */
        CreateDatasetResponse.create = function create(properties) {
            return new CreateDatasetResponse(properties);
        };

        /**
         * Encodes the specified CreateDatasetResponse message. Does not implicitly {@link metering.CreateDatasetResponse.verify|verify} messages.
         * @function encode
         * @memberof metering.CreateDatasetResponse
         * @static
         * @param {metering.ICreateDatasetResponse} message CreateDatasetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateDatasetResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified CreateDatasetResponse message, length delimited. Does not implicitly {@link metering.CreateDatasetResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.CreateDatasetResponse
         * @static
         * @param {metering.ICreateDatasetResponse} message CreateDatasetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateDatasetResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateDatasetResponse message from the specified reader or buffer.
         * @function decode
         * @memberof metering.CreateDatasetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.CreateDatasetResponse} CreateDatasetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateDatasetResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.CreateDatasetResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateDatasetResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.CreateDatasetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.CreateDatasetResponse} CreateDatasetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateDatasetResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateDatasetResponse message.
         * @function verify
         * @memberof metering.CreateDatasetResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateDatasetResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates a CreateDatasetResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.CreateDatasetResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.CreateDatasetResponse} CreateDatasetResponse
         */
        CreateDatasetResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.CreateDatasetResponse)
                return object;
            let message = new $root.metering.CreateDatasetResponse();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a CreateDatasetResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.CreateDatasetResponse
         * @static
         * @param {metering.CreateDatasetResponse} message CreateDatasetResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateDatasetResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this CreateDatasetResponse to JSON.
         * @function toJSON
         * @memberof metering.CreateDatasetResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateDatasetResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateDatasetResponse
         * @function getTypeUrl
         * @memberof metering.CreateDatasetResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateDatasetResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.CreateDatasetResponse";
        };

        return CreateDatasetResponse;
    })();

    metering.UnpublishDatasetResponse = (function() {

        /**
         * Properties of an UnpublishDatasetResponse.
         * @memberof metering
         * @interface IUnpublishDatasetResponse
         */

        /**
         * Constructs a new UnpublishDatasetResponse.
         * @memberof metering
         * @classdesc Represents an UnpublishDatasetResponse.
         * @implements IUnpublishDatasetResponse
         * @constructor
         * @param {metering.IUnpublishDatasetResponse=} [properties] Properties to set
         */
        function UnpublishDatasetResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UnpublishDatasetResponse instance using the specified properties.
         * @function create
         * @memberof metering.UnpublishDatasetResponse
         * @static
         * @param {metering.IUnpublishDatasetResponse=} [properties] Properties to set
         * @returns {metering.UnpublishDatasetResponse} UnpublishDatasetResponse instance
         */
        UnpublishDatasetResponse.create = function create(properties) {
            return new UnpublishDatasetResponse(properties);
        };

        /**
         * Encodes the specified UnpublishDatasetResponse message. Does not implicitly {@link metering.UnpublishDatasetResponse.verify|verify} messages.
         * @function encode
         * @memberof metering.UnpublishDatasetResponse
         * @static
         * @param {metering.IUnpublishDatasetResponse} message UnpublishDatasetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnpublishDatasetResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UnpublishDatasetResponse message, length delimited. Does not implicitly {@link metering.UnpublishDatasetResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.UnpublishDatasetResponse
         * @static
         * @param {metering.IUnpublishDatasetResponse} message UnpublishDatasetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnpublishDatasetResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UnpublishDatasetResponse message from the specified reader or buffer.
         * @function decode
         * @memberof metering.UnpublishDatasetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.UnpublishDatasetResponse} UnpublishDatasetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnpublishDatasetResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.UnpublishDatasetResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UnpublishDatasetResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.UnpublishDatasetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.UnpublishDatasetResponse} UnpublishDatasetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnpublishDatasetResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UnpublishDatasetResponse message.
         * @function verify
         * @memberof metering.UnpublishDatasetResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UnpublishDatasetResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UnpublishDatasetResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.UnpublishDatasetResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.UnpublishDatasetResponse} UnpublishDatasetResponse
         */
        UnpublishDatasetResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.UnpublishDatasetResponse)
                return object;
            return new $root.metering.UnpublishDatasetResponse();
        };

        /**
         * Creates a plain object from an UnpublishDatasetResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.UnpublishDatasetResponse
         * @static
         * @param {metering.UnpublishDatasetResponse} message UnpublishDatasetResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UnpublishDatasetResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UnpublishDatasetResponse to JSON.
         * @function toJSON
         * @memberof metering.UnpublishDatasetResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UnpublishDatasetResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UnpublishDatasetResponse
         * @function getTypeUrl
         * @memberof metering.UnpublishDatasetResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UnpublishDatasetResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.UnpublishDatasetResponse";
        };

        return UnpublishDatasetResponse;
    })();

    metering.WorkerMetadataRequest = (function() {

        /**
         * Properties of a WorkerMetadataRequest.
         * @memberof metering
         * @interface IWorkerMetadataRequest
         * @property {attestation.IAttestationSpecification|null} [attestationSpec] Attestation spec of the worker for which to perform the metadata lookup
         * @property {string|null} [scopeId] Id of the scope in the context of which a computation is performed
         */

        /**
         * Constructs a new WorkerMetadataRequest.
         * @memberof metering
         * @classdesc Represents a WorkerMetadataRequest.
         * @implements IWorkerMetadataRequest
         * @constructor
         * @param {metering.IWorkerMetadataRequest=} [properties] Properties to set
         */
        function WorkerMetadataRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Attestation spec of the worker for which to perform the metadata lookup
         * @member {attestation.IAttestationSpecification|null|undefined} attestationSpec
         * @memberof metering.WorkerMetadataRequest
         * @instance
         */
        WorkerMetadataRequest.prototype.attestationSpec = null;

        /**
         * Id of the scope in the context of which a computation is performed
         * @member {string} scopeId
         * @memberof metering.WorkerMetadataRequest
         * @instance
         */
        WorkerMetadataRequest.prototype.scopeId = "";

        /**
         * Creates a new WorkerMetadataRequest instance using the specified properties.
         * @function create
         * @memberof metering.WorkerMetadataRequest
         * @static
         * @param {metering.IWorkerMetadataRequest=} [properties] Properties to set
         * @returns {metering.WorkerMetadataRequest} WorkerMetadataRequest instance
         */
        WorkerMetadataRequest.create = function create(properties) {
            return new WorkerMetadataRequest(properties);
        };

        /**
         * Encodes the specified WorkerMetadataRequest message. Does not implicitly {@link metering.WorkerMetadataRequest.verify|verify} messages.
         * @function encode
         * @memberof metering.WorkerMetadataRequest
         * @static
         * @param {metering.IWorkerMetadataRequest} message WorkerMetadataRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkerMetadataRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.attestationSpec != null && Object.hasOwnProperty.call(message, "attestationSpec"))
                $root.attestation.AttestationSpecification.encode(message.attestationSpec, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.scopeId != null && Object.hasOwnProperty.call(message, "scopeId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.scopeId);
            return writer;
        };

        /**
         * Encodes the specified WorkerMetadataRequest message, length delimited. Does not implicitly {@link metering.WorkerMetadataRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.WorkerMetadataRequest
         * @static
         * @param {metering.IWorkerMetadataRequest} message WorkerMetadataRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkerMetadataRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkerMetadataRequest message from the specified reader or buffer.
         * @function decode
         * @memberof metering.WorkerMetadataRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.WorkerMetadataRequest} WorkerMetadataRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkerMetadataRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.WorkerMetadataRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.attestationSpec = $root.attestation.AttestationSpecification.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.scopeId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkerMetadataRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.WorkerMetadataRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.WorkerMetadataRequest} WorkerMetadataRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkerMetadataRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkerMetadataRequest message.
         * @function verify
         * @memberof metering.WorkerMetadataRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkerMetadataRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.attestationSpec != null && message.hasOwnProperty("attestationSpec")) {
                let error = $root.attestation.AttestationSpecification.verify(message.attestationSpec);
                if (error)
                    return "attestationSpec." + error;
            }
            if (message.scopeId != null && message.hasOwnProperty("scopeId"))
                if (!$util.isString(message.scopeId))
                    return "scopeId: string expected";
            return null;
        };

        /**
         * Creates a WorkerMetadataRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.WorkerMetadataRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.WorkerMetadataRequest} WorkerMetadataRequest
         */
        WorkerMetadataRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.WorkerMetadataRequest)
                return object;
            let message = new $root.metering.WorkerMetadataRequest();
            if (object.attestationSpec != null) {
                if (typeof object.attestationSpec !== "object")
                    throw TypeError(".metering.WorkerMetadataRequest.attestationSpec: object expected");
                message.attestationSpec = $root.attestation.AttestationSpecification.fromObject(object.attestationSpec);
            }
            if (object.scopeId != null)
                message.scopeId = String(object.scopeId);
            return message;
        };

        /**
         * Creates a plain object from a WorkerMetadataRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.WorkerMetadataRequest
         * @static
         * @param {metering.WorkerMetadataRequest} message WorkerMetadataRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkerMetadataRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.attestationSpec = null;
                object.scopeId = "";
            }
            if (message.attestationSpec != null && message.hasOwnProperty("attestationSpec"))
                object.attestationSpec = $root.attestation.AttestationSpecification.toObject(message.attestationSpec, options);
            if (message.scopeId != null && message.hasOwnProperty("scopeId"))
                object.scopeId = message.scopeId;
            return object;
        };

        /**
         * Converts this WorkerMetadataRequest to JSON.
         * @function toJSON
         * @memberof metering.WorkerMetadataRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkerMetadataRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkerMetadataRequest
         * @function getTypeUrl
         * @memberof metering.WorkerMetadataRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkerMetadataRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.WorkerMetadataRequest";
        };

        return WorkerMetadataRequest;
    })();

    metering.WorkerMetadataResponse = (function() {

        /**
         * Properties of a WorkerMetadataResponse.
         * @memberof metering
         * @interface IWorkerMetadataResponse
         * @property {number|Long|null} [maxExecutionSeconds] WorkerMetadataResponse maxExecutionSeconds
         */

        /**
         * Constructs a new WorkerMetadataResponse.
         * @memberof metering
         * @classdesc Represents a WorkerMetadataResponse.
         * @implements IWorkerMetadataResponse
         * @constructor
         * @param {metering.IWorkerMetadataResponse=} [properties] Properties to set
         */
        function WorkerMetadataResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkerMetadataResponse maxExecutionSeconds.
         * @member {number|Long} maxExecutionSeconds
         * @memberof metering.WorkerMetadataResponse
         * @instance
         */
        WorkerMetadataResponse.prototype.maxExecutionSeconds = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new WorkerMetadataResponse instance using the specified properties.
         * @function create
         * @memberof metering.WorkerMetadataResponse
         * @static
         * @param {metering.IWorkerMetadataResponse=} [properties] Properties to set
         * @returns {metering.WorkerMetadataResponse} WorkerMetadataResponse instance
         */
        WorkerMetadataResponse.create = function create(properties) {
            return new WorkerMetadataResponse(properties);
        };

        /**
         * Encodes the specified WorkerMetadataResponse message. Does not implicitly {@link metering.WorkerMetadataResponse.verify|verify} messages.
         * @function encode
         * @memberof metering.WorkerMetadataResponse
         * @static
         * @param {metering.IWorkerMetadataResponse} message WorkerMetadataResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkerMetadataResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.maxExecutionSeconds != null && Object.hasOwnProperty.call(message, "maxExecutionSeconds"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.maxExecutionSeconds);
            return writer;
        };

        /**
         * Encodes the specified WorkerMetadataResponse message, length delimited. Does not implicitly {@link metering.WorkerMetadataResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.WorkerMetadataResponse
         * @static
         * @param {metering.IWorkerMetadataResponse} message WorkerMetadataResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkerMetadataResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkerMetadataResponse message from the specified reader or buffer.
         * @function decode
         * @memberof metering.WorkerMetadataResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.WorkerMetadataResponse} WorkerMetadataResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkerMetadataResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.WorkerMetadataResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.maxExecutionSeconds = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkerMetadataResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.WorkerMetadataResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.WorkerMetadataResponse} WorkerMetadataResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkerMetadataResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkerMetadataResponse message.
         * @function verify
         * @memberof metering.WorkerMetadataResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkerMetadataResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.maxExecutionSeconds != null && message.hasOwnProperty("maxExecutionSeconds"))
                if (!$util.isInteger(message.maxExecutionSeconds) && !(message.maxExecutionSeconds && $util.isInteger(message.maxExecutionSeconds.low) && $util.isInteger(message.maxExecutionSeconds.high)))
                    return "maxExecutionSeconds: integer|Long expected";
            return null;
        };

        /**
         * Creates a WorkerMetadataResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.WorkerMetadataResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.WorkerMetadataResponse} WorkerMetadataResponse
         */
        WorkerMetadataResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.WorkerMetadataResponse)
                return object;
            let message = new $root.metering.WorkerMetadataResponse();
            if (object.maxExecutionSeconds != null)
                if ($util.Long)
                    (message.maxExecutionSeconds = $util.Long.fromValue(object.maxExecutionSeconds)).unsigned = true;
                else if (typeof object.maxExecutionSeconds === "string")
                    message.maxExecutionSeconds = parseInt(object.maxExecutionSeconds, 10);
                else if (typeof object.maxExecutionSeconds === "number")
                    message.maxExecutionSeconds = object.maxExecutionSeconds;
                else if (typeof object.maxExecutionSeconds === "object")
                    message.maxExecutionSeconds = new $util.LongBits(object.maxExecutionSeconds.low >>> 0, object.maxExecutionSeconds.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a WorkerMetadataResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.WorkerMetadataResponse
         * @static
         * @param {metering.WorkerMetadataResponse} message WorkerMetadataResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkerMetadataResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.maxExecutionSeconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.maxExecutionSeconds = options.longs === String ? "0" : 0;
            if (message.maxExecutionSeconds != null && message.hasOwnProperty("maxExecutionSeconds"))
                if (typeof message.maxExecutionSeconds === "number")
                    object.maxExecutionSeconds = options.longs === String ? String(message.maxExecutionSeconds) : message.maxExecutionSeconds;
                else
                    object.maxExecutionSeconds = options.longs === String ? $util.Long.prototype.toString.call(message.maxExecutionSeconds) : options.longs === Number ? new $util.LongBits(message.maxExecutionSeconds.low >>> 0, message.maxExecutionSeconds.high >>> 0).toNumber(true) : message.maxExecutionSeconds;
            return object;
        };

        /**
         * Converts this WorkerMetadataResponse to JSON.
         * @function toJSON
         * @memberof metering.WorkerMetadataResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkerMetadataResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkerMetadataResponse
         * @function getTypeUrl
         * @memberof metering.WorkerMetadataResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkerMetadataResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.WorkerMetadataResponse";
        };

        return WorkerMetadataResponse;
    })();

    metering.SubmitWorkerExecutionTimeRequest = (function() {

        /**
         * Properties of a SubmitWorkerExecutionTimeRequest.
         * @memberof metering
         * @interface ISubmitWorkerExecutionTimeRequest
         * @property {number|null} [executionTimeSeconds] SubmitWorkerExecutionTimeRequest executionTimeSeconds
         * @property {attestation.IAttestationSpecification|null} [attestationSpec] SubmitWorkerExecutionTimeRequest attestationSpec
         * @property {string|null} [scopeId] Id of the scope in the context of which a computation is performed
         */

        /**
         * Constructs a new SubmitWorkerExecutionTimeRequest.
         * @memberof metering
         * @classdesc Represents a SubmitWorkerExecutionTimeRequest.
         * @implements ISubmitWorkerExecutionTimeRequest
         * @constructor
         * @param {metering.ISubmitWorkerExecutionTimeRequest=} [properties] Properties to set
         */
        function SubmitWorkerExecutionTimeRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SubmitWorkerExecutionTimeRequest executionTimeSeconds.
         * @member {number} executionTimeSeconds
         * @memberof metering.SubmitWorkerExecutionTimeRequest
         * @instance
         */
        SubmitWorkerExecutionTimeRequest.prototype.executionTimeSeconds = 0;

        /**
         * SubmitWorkerExecutionTimeRequest attestationSpec.
         * @member {attestation.IAttestationSpecification|null|undefined} attestationSpec
         * @memberof metering.SubmitWorkerExecutionTimeRequest
         * @instance
         */
        SubmitWorkerExecutionTimeRequest.prototype.attestationSpec = null;

        /**
         * Id of the scope in the context of which a computation is performed
         * @member {string} scopeId
         * @memberof metering.SubmitWorkerExecutionTimeRequest
         * @instance
         */
        SubmitWorkerExecutionTimeRequest.prototype.scopeId = "";

        /**
         * Creates a new SubmitWorkerExecutionTimeRequest instance using the specified properties.
         * @function create
         * @memberof metering.SubmitWorkerExecutionTimeRequest
         * @static
         * @param {metering.ISubmitWorkerExecutionTimeRequest=} [properties] Properties to set
         * @returns {metering.SubmitWorkerExecutionTimeRequest} SubmitWorkerExecutionTimeRequest instance
         */
        SubmitWorkerExecutionTimeRequest.create = function create(properties) {
            return new SubmitWorkerExecutionTimeRequest(properties);
        };

        /**
         * Encodes the specified SubmitWorkerExecutionTimeRequest message. Does not implicitly {@link metering.SubmitWorkerExecutionTimeRequest.verify|verify} messages.
         * @function encode
         * @memberof metering.SubmitWorkerExecutionTimeRequest
         * @static
         * @param {metering.ISubmitWorkerExecutionTimeRequest} message SubmitWorkerExecutionTimeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubmitWorkerExecutionTimeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.executionTimeSeconds != null && Object.hasOwnProperty.call(message, "executionTimeSeconds"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.executionTimeSeconds);
            if (message.attestationSpec != null && Object.hasOwnProperty.call(message, "attestationSpec"))
                $root.attestation.AttestationSpecification.encode(message.attestationSpec, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.scopeId != null && Object.hasOwnProperty.call(message, "scopeId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.scopeId);
            return writer;
        };

        /**
         * Encodes the specified SubmitWorkerExecutionTimeRequest message, length delimited. Does not implicitly {@link metering.SubmitWorkerExecutionTimeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.SubmitWorkerExecutionTimeRequest
         * @static
         * @param {metering.ISubmitWorkerExecutionTimeRequest} message SubmitWorkerExecutionTimeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubmitWorkerExecutionTimeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SubmitWorkerExecutionTimeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof metering.SubmitWorkerExecutionTimeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.SubmitWorkerExecutionTimeRequest} SubmitWorkerExecutionTimeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubmitWorkerExecutionTimeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.SubmitWorkerExecutionTimeRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.executionTimeSeconds = reader.uint32();
                        break;
                    }
                case 2: {
                        message.attestationSpec = $root.attestation.AttestationSpecification.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.scopeId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SubmitWorkerExecutionTimeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.SubmitWorkerExecutionTimeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.SubmitWorkerExecutionTimeRequest} SubmitWorkerExecutionTimeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubmitWorkerExecutionTimeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SubmitWorkerExecutionTimeRequest message.
         * @function verify
         * @memberof metering.SubmitWorkerExecutionTimeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SubmitWorkerExecutionTimeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.executionTimeSeconds != null && message.hasOwnProperty("executionTimeSeconds"))
                if (!$util.isInteger(message.executionTimeSeconds))
                    return "executionTimeSeconds: integer expected";
            if (message.attestationSpec != null && message.hasOwnProperty("attestationSpec")) {
                let error = $root.attestation.AttestationSpecification.verify(message.attestationSpec);
                if (error)
                    return "attestationSpec." + error;
            }
            if (message.scopeId != null && message.hasOwnProperty("scopeId"))
                if (!$util.isString(message.scopeId))
                    return "scopeId: string expected";
            return null;
        };

        /**
         * Creates a SubmitWorkerExecutionTimeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.SubmitWorkerExecutionTimeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.SubmitWorkerExecutionTimeRequest} SubmitWorkerExecutionTimeRequest
         */
        SubmitWorkerExecutionTimeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.SubmitWorkerExecutionTimeRequest)
                return object;
            let message = new $root.metering.SubmitWorkerExecutionTimeRequest();
            if (object.executionTimeSeconds != null)
                message.executionTimeSeconds = object.executionTimeSeconds >>> 0;
            if (object.attestationSpec != null) {
                if (typeof object.attestationSpec !== "object")
                    throw TypeError(".metering.SubmitWorkerExecutionTimeRequest.attestationSpec: object expected");
                message.attestationSpec = $root.attestation.AttestationSpecification.fromObject(object.attestationSpec);
            }
            if (object.scopeId != null)
                message.scopeId = String(object.scopeId);
            return message;
        };

        /**
         * Creates a plain object from a SubmitWorkerExecutionTimeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.SubmitWorkerExecutionTimeRequest
         * @static
         * @param {metering.SubmitWorkerExecutionTimeRequest} message SubmitWorkerExecutionTimeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SubmitWorkerExecutionTimeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.executionTimeSeconds = 0;
                object.attestationSpec = null;
                object.scopeId = "";
            }
            if (message.executionTimeSeconds != null && message.hasOwnProperty("executionTimeSeconds"))
                object.executionTimeSeconds = message.executionTimeSeconds;
            if (message.attestationSpec != null && message.hasOwnProperty("attestationSpec"))
                object.attestationSpec = $root.attestation.AttestationSpecification.toObject(message.attestationSpec, options);
            if (message.scopeId != null && message.hasOwnProperty("scopeId"))
                object.scopeId = message.scopeId;
            return object;
        };

        /**
         * Converts this SubmitWorkerExecutionTimeRequest to JSON.
         * @function toJSON
         * @memberof metering.SubmitWorkerExecutionTimeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SubmitWorkerExecutionTimeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SubmitWorkerExecutionTimeRequest
         * @function getTypeUrl
         * @memberof metering.SubmitWorkerExecutionTimeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SubmitWorkerExecutionTimeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.SubmitWorkerExecutionTimeRequest";
        };

        return SubmitWorkerExecutionTimeRequest;
    })();

    metering.SubmitWorkerExecutionTimeResponse = (function() {

        /**
         * Properties of a SubmitWorkerExecutionTimeResponse.
         * @memberof metering
         * @interface ISubmitWorkerExecutionTimeResponse
         */

        /**
         * Constructs a new SubmitWorkerExecutionTimeResponse.
         * @memberof metering
         * @classdesc Represents a SubmitWorkerExecutionTimeResponse.
         * @implements ISubmitWorkerExecutionTimeResponse
         * @constructor
         * @param {metering.ISubmitWorkerExecutionTimeResponse=} [properties] Properties to set
         */
        function SubmitWorkerExecutionTimeResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SubmitWorkerExecutionTimeResponse instance using the specified properties.
         * @function create
         * @memberof metering.SubmitWorkerExecutionTimeResponse
         * @static
         * @param {metering.ISubmitWorkerExecutionTimeResponse=} [properties] Properties to set
         * @returns {metering.SubmitWorkerExecutionTimeResponse} SubmitWorkerExecutionTimeResponse instance
         */
        SubmitWorkerExecutionTimeResponse.create = function create(properties) {
            return new SubmitWorkerExecutionTimeResponse(properties);
        };

        /**
         * Encodes the specified SubmitWorkerExecutionTimeResponse message. Does not implicitly {@link metering.SubmitWorkerExecutionTimeResponse.verify|verify} messages.
         * @function encode
         * @memberof metering.SubmitWorkerExecutionTimeResponse
         * @static
         * @param {metering.ISubmitWorkerExecutionTimeResponse} message SubmitWorkerExecutionTimeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubmitWorkerExecutionTimeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SubmitWorkerExecutionTimeResponse message, length delimited. Does not implicitly {@link metering.SubmitWorkerExecutionTimeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.SubmitWorkerExecutionTimeResponse
         * @static
         * @param {metering.ISubmitWorkerExecutionTimeResponse} message SubmitWorkerExecutionTimeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubmitWorkerExecutionTimeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SubmitWorkerExecutionTimeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof metering.SubmitWorkerExecutionTimeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.SubmitWorkerExecutionTimeResponse} SubmitWorkerExecutionTimeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubmitWorkerExecutionTimeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.SubmitWorkerExecutionTimeResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SubmitWorkerExecutionTimeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.SubmitWorkerExecutionTimeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.SubmitWorkerExecutionTimeResponse} SubmitWorkerExecutionTimeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubmitWorkerExecutionTimeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SubmitWorkerExecutionTimeResponse message.
         * @function verify
         * @memberof metering.SubmitWorkerExecutionTimeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SubmitWorkerExecutionTimeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SubmitWorkerExecutionTimeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.SubmitWorkerExecutionTimeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.SubmitWorkerExecutionTimeResponse} SubmitWorkerExecutionTimeResponse
         */
        SubmitWorkerExecutionTimeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.SubmitWorkerExecutionTimeResponse)
                return object;
            return new $root.metering.SubmitWorkerExecutionTimeResponse();
        };

        /**
         * Creates a plain object from a SubmitWorkerExecutionTimeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.SubmitWorkerExecutionTimeResponse
         * @static
         * @param {metering.SubmitWorkerExecutionTimeResponse} message SubmitWorkerExecutionTimeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SubmitWorkerExecutionTimeResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SubmitWorkerExecutionTimeResponse to JSON.
         * @function toJSON
         * @memberof metering.SubmitWorkerExecutionTimeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SubmitWorkerExecutionTimeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SubmitWorkerExecutionTimeResponse
         * @function getTypeUrl
         * @memberof metering.SubmitWorkerExecutionTimeResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SubmitWorkerExecutionTimeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.SubmitWorkerExecutionTimeResponse";
        };

        return SubmitWorkerExecutionTimeResponse;
    })();

    metering.SplickyCredentials = (function() {

        /**
         * Properties of a SplickyCredentials.
         * @memberof metering
         * @interface ISplickyCredentials
         */

        /**
         * Constructs a new SplickyCredentials.
         * @memberof metering
         * @classdesc Represents a SplickyCredentials.
         * @implements ISplickyCredentials
         * @constructor
         * @param {metering.ISplickyCredentials=} [properties] Properties to set
         */
        function SplickyCredentials(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SplickyCredentials instance using the specified properties.
         * @function create
         * @memberof metering.SplickyCredentials
         * @static
         * @param {metering.ISplickyCredentials=} [properties] Properties to set
         * @returns {metering.SplickyCredentials} SplickyCredentials instance
         */
        SplickyCredentials.create = function create(properties) {
            return new SplickyCredentials(properties);
        };

        /**
         * Encodes the specified SplickyCredentials message. Does not implicitly {@link metering.SplickyCredentials.verify|verify} messages.
         * @function encode
         * @memberof metering.SplickyCredentials
         * @static
         * @param {metering.ISplickyCredentials} message SplickyCredentials message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SplickyCredentials.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SplickyCredentials message, length delimited. Does not implicitly {@link metering.SplickyCredentials.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.SplickyCredentials
         * @static
         * @param {metering.ISplickyCredentials} message SplickyCredentials message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SplickyCredentials.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SplickyCredentials message from the specified reader or buffer.
         * @function decode
         * @memberof metering.SplickyCredentials
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.SplickyCredentials} SplickyCredentials
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SplickyCredentials.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.SplickyCredentials();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SplickyCredentials message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.SplickyCredentials
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.SplickyCredentials} SplickyCredentials
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SplickyCredentials.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SplickyCredentials message.
         * @function verify
         * @memberof metering.SplickyCredentials
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SplickyCredentials.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SplickyCredentials message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.SplickyCredentials
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.SplickyCredentials} SplickyCredentials
         */
        SplickyCredentials.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.SplickyCredentials)
                return object;
            return new $root.metering.SplickyCredentials();
        };

        /**
         * Creates a plain object from a SplickyCredentials message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.SplickyCredentials
         * @static
         * @param {metering.SplickyCredentials} message SplickyCredentials
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SplickyCredentials.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SplickyCredentials to JSON.
         * @function toJSON
         * @memberof metering.SplickyCredentials
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SplickyCredentials.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SplickyCredentials
         * @function getTypeUrl
         * @memberof metering.SplickyCredentials
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SplickyCredentials.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.SplickyCredentials";
        };

        return SplickyCredentials;
    })();

    metering.MicrosoftDspMember = (function() {

        /**
         * Properties of a MicrosoftDspMember.
         * @memberof metering
         * @interface IMicrosoftDspMember
         * @property {string|null} [member] MicrosoftDspMember member
         */

        /**
         * Constructs a new MicrosoftDspMember.
         * @memberof metering
         * @classdesc Represents a MicrosoftDspMember.
         * @implements IMicrosoftDspMember
         * @constructor
         * @param {metering.IMicrosoftDspMember=} [properties] Properties to set
         */
        function MicrosoftDspMember(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MicrosoftDspMember member.
         * @member {string} member
         * @memberof metering.MicrosoftDspMember
         * @instance
         */
        MicrosoftDspMember.prototype.member = "";

        /**
         * Creates a new MicrosoftDspMember instance using the specified properties.
         * @function create
         * @memberof metering.MicrosoftDspMember
         * @static
         * @param {metering.IMicrosoftDspMember=} [properties] Properties to set
         * @returns {metering.MicrosoftDspMember} MicrosoftDspMember instance
         */
        MicrosoftDspMember.create = function create(properties) {
            return new MicrosoftDspMember(properties);
        };

        /**
         * Encodes the specified MicrosoftDspMember message. Does not implicitly {@link metering.MicrosoftDspMember.verify|verify} messages.
         * @function encode
         * @memberof metering.MicrosoftDspMember
         * @static
         * @param {metering.IMicrosoftDspMember} message MicrosoftDspMember message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MicrosoftDspMember.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.member != null && Object.hasOwnProperty.call(message, "member"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.member);
            return writer;
        };

        /**
         * Encodes the specified MicrosoftDspMember message, length delimited. Does not implicitly {@link metering.MicrosoftDspMember.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.MicrosoftDspMember
         * @static
         * @param {metering.IMicrosoftDspMember} message MicrosoftDspMember message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MicrosoftDspMember.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MicrosoftDspMember message from the specified reader or buffer.
         * @function decode
         * @memberof metering.MicrosoftDspMember
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.MicrosoftDspMember} MicrosoftDspMember
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MicrosoftDspMember.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.MicrosoftDspMember();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.member = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MicrosoftDspMember message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.MicrosoftDspMember
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.MicrosoftDspMember} MicrosoftDspMember
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MicrosoftDspMember.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MicrosoftDspMember message.
         * @function verify
         * @memberof metering.MicrosoftDspMember
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MicrosoftDspMember.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.member != null && message.hasOwnProperty("member"))
                if (!$util.isString(message.member))
                    return "member: string expected";
            return null;
        };

        /**
         * Creates a MicrosoftDspMember message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.MicrosoftDspMember
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.MicrosoftDspMember} MicrosoftDspMember
         */
        MicrosoftDspMember.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.MicrosoftDspMember)
                return object;
            let message = new $root.metering.MicrosoftDspMember();
            if (object.member != null)
                message.member = String(object.member);
            return message;
        };

        /**
         * Creates a plain object from a MicrosoftDspMember message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.MicrosoftDspMember
         * @static
         * @param {metering.MicrosoftDspMember} message MicrosoftDspMember
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MicrosoftDspMember.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.member = "";
            if (message.member != null && message.hasOwnProperty("member"))
                object.member = message.member;
            return object;
        };

        /**
         * Converts this MicrosoftDspMember to JSON.
         * @function toJSON
         * @memberof metering.MicrosoftDspMember
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MicrosoftDspMember.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MicrosoftDspMember
         * @function getTypeUrl
         * @memberof metering.MicrosoftDspMember
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MicrosoftDspMember.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.MicrosoftDspMember";
        };

        return MicrosoftDspMember;
    })();

    metering.CredentialsKind = (function() {

        /**
         * Properties of a CredentialsKind.
         * @memberof metering
         * @interface ICredentialsKind
         * @property {metering.ISplickyCredentials|null} [splicky] CredentialsKind splicky
         * @property {metering.IMicrosoftDspMember|null} [microsoftDspMember] CredentialsKind microsoftDspMember
         */

        /**
         * Constructs a new CredentialsKind.
         * @memberof metering
         * @classdesc Represents a CredentialsKind.
         * @implements ICredentialsKind
         * @constructor
         * @param {metering.ICredentialsKind=} [properties] Properties to set
         */
        function CredentialsKind(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CredentialsKind splicky.
         * @member {metering.ISplickyCredentials|null|undefined} splicky
         * @memberof metering.CredentialsKind
         * @instance
         */
        CredentialsKind.prototype.splicky = null;

        /**
         * CredentialsKind microsoftDspMember.
         * @member {metering.IMicrosoftDspMember|null|undefined} microsoftDspMember
         * @memberof metering.CredentialsKind
         * @instance
         */
        CredentialsKind.prototype.microsoftDspMember = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * CredentialsKind credentials.
         * @member {"splicky"|"microsoftDspMember"|undefined} credentials
         * @memberof metering.CredentialsKind
         * @instance
         */
        Object.defineProperty(CredentialsKind.prototype, "credentials", {
            get: $util.oneOfGetter($oneOfFields = ["splicky", "microsoftDspMember"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CredentialsKind instance using the specified properties.
         * @function create
         * @memberof metering.CredentialsKind
         * @static
         * @param {metering.ICredentialsKind=} [properties] Properties to set
         * @returns {metering.CredentialsKind} CredentialsKind instance
         */
        CredentialsKind.create = function create(properties) {
            return new CredentialsKind(properties);
        };

        /**
         * Encodes the specified CredentialsKind message. Does not implicitly {@link metering.CredentialsKind.verify|verify} messages.
         * @function encode
         * @memberof metering.CredentialsKind
         * @static
         * @param {metering.ICredentialsKind} message CredentialsKind message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CredentialsKind.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.splicky != null && Object.hasOwnProperty.call(message, "splicky"))
                $root.metering.SplickyCredentials.encode(message.splicky, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.microsoftDspMember != null && Object.hasOwnProperty.call(message, "microsoftDspMember"))
                $root.metering.MicrosoftDspMember.encode(message.microsoftDspMember, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CredentialsKind message, length delimited. Does not implicitly {@link metering.CredentialsKind.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.CredentialsKind
         * @static
         * @param {metering.ICredentialsKind} message CredentialsKind message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CredentialsKind.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CredentialsKind message from the specified reader or buffer.
         * @function decode
         * @memberof metering.CredentialsKind
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.CredentialsKind} CredentialsKind
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CredentialsKind.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.CredentialsKind();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.splicky = $root.metering.SplickyCredentials.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.microsoftDspMember = $root.metering.MicrosoftDspMember.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CredentialsKind message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.CredentialsKind
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.CredentialsKind} CredentialsKind
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CredentialsKind.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CredentialsKind message.
         * @function verify
         * @memberof metering.CredentialsKind
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CredentialsKind.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.splicky != null && message.hasOwnProperty("splicky")) {
                properties.credentials = 1;
                {
                    let error = $root.metering.SplickyCredentials.verify(message.splicky);
                    if (error)
                        return "splicky." + error;
                }
            }
            if (message.microsoftDspMember != null && message.hasOwnProperty("microsoftDspMember")) {
                if (properties.credentials === 1)
                    return "credentials: multiple values";
                properties.credentials = 1;
                {
                    let error = $root.metering.MicrosoftDspMember.verify(message.microsoftDspMember);
                    if (error)
                        return "microsoftDspMember." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CredentialsKind message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.CredentialsKind
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.CredentialsKind} CredentialsKind
         */
        CredentialsKind.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.CredentialsKind)
                return object;
            let message = new $root.metering.CredentialsKind();
            if (object.splicky != null) {
                if (typeof object.splicky !== "object")
                    throw TypeError(".metering.CredentialsKind.splicky: object expected");
                message.splicky = $root.metering.SplickyCredentials.fromObject(object.splicky);
            }
            if (object.microsoftDspMember != null) {
                if (typeof object.microsoftDspMember !== "object")
                    throw TypeError(".metering.CredentialsKind.microsoftDspMember: object expected");
                message.microsoftDspMember = $root.metering.MicrosoftDspMember.fromObject(object.microsoftDspMember);
            }
            return message;
        };

        /**
         * Creates a plain object from a CredentialsKind message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.CredentialsKind
         * @static
         * @param {metering.CredentialsKind} message CredentialsKind
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CredentialsKind.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.splicky != null && message.hasOwnProperty("splicky")) {
                object.splicky = $root.metering.SplickyCredentials.toObject(message.splicky, options);
                if (options.oneofs)
                    object.credentials = "splicky";
            }
            if (message.microsoftDspMember != null && message.hasOwnProperty("microsoftDspMember")) {
                object.microsoftDspMember = $root.metering.MicrosoftDspMember.toObject(message.microsoftDspMember, options);
                if (options.oneofs)
                    object.credentials = "microsoftDspMember";
            }
            return object;
        };

        /**
         * Converts this CredentialsKind to JSON.
         * @function toJSON
         * @memberof metering.CredentialsKind
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CredentialsKind.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CredentialsKind
         * @function getTypeUrl
         * @memberof metering.CredentialsKind
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CredentialsKind.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.CredentialsKind";
        };

        return CredentialsKind;
    })();

    metering.GetDataConnectorCredentialsRequest = (function() {

        /**
         * Properties of a GetDataConnectorCredentialsRequest.
         * @memberof metering
         * @interface IGetDataConnectorCredentialsRequest
         * @property {metering.ICredentialsKind|null} [kind] GetDataConnectorCredentialsRequest kind
         */

        /**
         * Constructs a new GetDataConnectorCredentialsRequest.
         * @memberof metering
         * @classdesc Represents a GetDataConnectorCredentialsRequest.
         * @implements IGetDataConnectorCredentialsRequest
         * @constructor
         * @param {metering.IGetDataConnectorCredentialsRequest=} [properties] Properties to set
         */
        function GetDataConnectorCredentialsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetDataConnectorCredentialsRequest kind.
         * @member {metering.ICredentialsKind|null|undefined} kind
         * @memberof metering.GetDataConnectorCredentialsRequest
         * @instance
         */
        GetDataConnectorCredentialsRequest.prototype.kind = null;

        /**
         * Creates a new GetDataConnectorCredentialsRequest instance using the specified properties.
         * @function create
         * @memberof metering.GetDataConnectorCredentialsRequest
         * @static
         * @param {metering.IGetDataConnectorCredentialsRequest=} [properties] Properties to set
         * @returns {metering.GetDataConnectorCredentialsRequest} GetDataConnectorCredentialsRequest instance
         */
        GetDataConnectorCredentialsRequest.create = function create(properties) {
            return new GetDataConnectorCredentialsRequest(properties);
        };

        /**
         * Encodes the specified GetDataConnectorCredentialsRequest message. Does not implicitly {@link metering.GetDataConnectorCredentialsRequest.verify|verify} messages.
         * @function encode
         * @memberof metering.GetDataConnectorCredentialsRequest
         * @static
         * @param {metering.IGetDataConnectorCredentialsRequest} message GetDataConnectorCredentialsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetDataConnectorCredentialsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.kind != null && Object.hasOwnProperty.call(message, "kind"))
                $root.metering.CredentialsKind.encode(message.kind, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetDataConnectorCredentialsRequest message, length delimited. Does not implicitly {@link metering.GetDataConnectorCredentialsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.GetDataConnectorCredentialsRequest
         * @static
         * @param {metering.IGetDataConnectorCredentialsRequest} message GetDataConnectorCredentialsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetDataConnectorCredentialsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetDataConnectorCredentialsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof metering.GetDataConnectorCredentialsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.GetDataConnectorCredentialsRequest} GetDataConnectorCredentialsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetDataConnectorCredentialsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.GetDataConnectorCredentialsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.kind = $root.metering.CredentialsKind.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetDataConnectorCredentialsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.GetDataConnectorCredentialsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.GetDataConnectorCredentialsRequest} GetDataConnectorCredentialsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetDataConnectorCredentialsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetDataConnectorCredentialsRequest message.
         * @function verify
         * @memberof metering.GetDataConnectorCredentialsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetDataConnectorCredentialsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.kind != null && message.hasOwnProperty("kind")) {
                let error = $root.metering.CredentialsKind.verify(message.kind);
                if (error)
                    return "kind." + error;
            }
            return null;
        };

        /**
         * Creates a GetDataConnectorCredentialsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.GetDataConnectorCredentialsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.GetDataConnectorCredentialsRequest} GetDataConnectorCredentialsRequest
         */
        GetDataConnectorCredentialsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.GetDataConnectorCredentialsRequest)
                return object;
            let message = new $root.metering.GetDataConnectorCredentialsRequest();
            if (object.kind != null) {
                if (typeof object.kind !== "object")
                    throw TypeError(".metering.GetDataConnectorCredentialsRequest.kind: object expected");
                message.kind = $root.metering.CredentialsKind.fromObject(object.kind);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetDataConnectorCredentialsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.GetDataConnectorCredentialsRequest
         * @static
         * @param {metering.GetDataConnectorCredentialsRequest} message GetDataConnectorCredentialsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetDataConnectorCredentialsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.kind = null;
            if (message.kind != null && message.hasOwnProperty("kind"))
                object.kind = $root.metering.CredentialsKind.toObject(message.kind, options);
            return object;
        };

        /**
         * Converts this GetDataConnectorCredentialsRequest to JSON.
         * @function toJSON
         * @memberof metering.GetDataConnectorCredentialsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetDataConnectorCredentialsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetDataConnectorCredentialsRequest
         * @function getTypeUrl
         * @memberof metering.GetDataConnectorCredentialsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetDataConnectorCredentialsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.GetDataConnectorCredentialsRequest";
        };

        return GetDataConnectorCredentialsRequest;
    })();

    metering.GetDataConnectorCredentialsResponse = (function() {

        /**
         * Properties of a GetDataConnectorCredentialsResponse.
         * @memberof metering
         * @interface IGetDataConnectorCredentialsResponse
         * @property {string|null} [credentials] Credentials as a serialised JSON object.
         */

        /**
         * Constructs a new GetDataConnectorCredentialsResponse.
         * @memberof metering
         * @classdesc Represents a GetDataConnectorCredentialsResponse.
         * @implements IGetDataConnectorCredentialsResponse
         * @constructor
         * @param {metering.IGetDataConnectorCredentialsResponse=} [properties] Properties to set
         */
        function GetDataConnectorCredentialsResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Credentials as a serialised JSON object.
         * @member {string} credentials
         * @memberof metering.GetDataConnectorCredentialsResponse
         * @instance
         */
        GetDataConnectorCredentialsResponse.prototype.credentials = "";

        /**
         * Creates a new GetDataConnectorCredentialsResponse instance using the specified properties.
         * @function create
         * @memberof metering.GetDataConnectorCredentialsResponse
         * @static
         * @param {metering.IGetDataConnectorCredentialsResponse=} [properties] Properties to set
         * @returns {metering.GetDataConnectorCredentialsResponse} GetDataConnectorCredentialsResponse instance
         */
        GetDataConnectorCredentialsResponse.create = function create(properties) {
            return new GetDataConnectorCredentialsResponse(properties);
        };

        /**
         * Encodes the specified GetDataConnectorCredentialsResponse message. Does not implicitly {@link metering.GetDataConnectorCredentialsResponse.verify|verify} messages.
         * @function encode
         * @memberof metering.GetDataConnectorCredentialsResponse
         * @static
         * @param {metering.IGetDataConnectorCredentialsResponse} message GetDataConnectorCredentialsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetDataConnectorCredentialsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.credentials != null && Object.hasOwnProperty.call(message, "credentials"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.credentials);
            return writer;
        };

        /**
         * Encodes the specified GetDataConnectorCredentialsResponse message, length delimited. Does not implicitly {@link metering.GetDataConnectorCredentialsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.GetDataConnectorCredentialsResponse
         * @static
         * @param {metering.IGetDataConnectorCredentialsResponse} message GetDataConnectorCredentialsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetDataConnectorCredentialsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetDataConnectorCredentialsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof metering.GetDataConnectorCredentialsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.GetDataConnectorCredentialsResponse} GetDataConnectorCredentialsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetDataConnectorCredentialsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.GetDataConnectorCredentialsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.credentials = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetDataConnectorCredentialsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.GetDataConnectorCredentialsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.GetDataConnectorCredentialsResponse} GetDataConnectorCredentialsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetDataConnectorCredentialsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetDataConnectorCredentialsResponse message.
         * @function verify
         * @memberof metering.GetDataConnectorCredentialsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetDataConnectorCredentialsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.credentials != null && message.hasOwnProperty("credentials"))
                if (!$util.isString(message.credentials))
                    return "credentials: string expected";
            return null;
        };

        /**
         * Creates a GetDataConnectorCredentialsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.GetDataConnectorCredentialsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.GetDataConnectorCredentialsResponse} GetDataConnectorCredentialsResponse
         */
        GetDataConnectorCredentialsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.GetDataConnectorCredentialsResponse)
                return object;
            let message = new $root.metering.GetDataConnectorCredentialsResponse();
            if (object.credentials != null)
                message.credentials = String(object.credentials);
            return message;
        };

        /**
         * Creates a plain object from a GetDataConnectorCredentialsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.GetDataConnectorCredentialsResponse
         * @static
         * @param {metering.GetDataConnectorCredentialsResponse} message GetDataConnectorCredentialsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetDataConnectorCredentialsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.credentials = "";
            if (message.credentials != null && message.hasOwnProperty("credentials"))
                object.credentials = message.credentials;
            return object;
        };

        /**
         * Converts this GetDataConnectorCredentialsResponse to JSON.
         * @function toJSON
         * @memberof metering.GetDataConnectorCredentialsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetDataConnectorCredentialsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetDataConnectorCredentialsResponse
         * @function getTypeUrl
         * @memberof metering.GetDataConnectorCredentialsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetDataConnectorCredentialsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.GetDataConnectorCredentialsResponse";
        };

        return GetDataConnectorCredentialsResponse;
    })();

    metering.GetAzureCommunicationCredentialsRequest = (function() {

        /**
         * Properties of a GetAzureCommunicationCredentialsRequest.
         * @memberof metering
         * @interface IGetAzureCommunicationCredentialsRequest
         */

        /**
         * Constructs a new GetAzureCommunicationCredentialsRequest.
         * @memberof metering
         * @classdesc Represents a GetAzureCommunicationCredentialsRequest.
         * @implements IGetAzureCommunicationCredentialsRequest
         * @constructor
         * @param {metering.IGetAzureCommunicationCredentialsRequest=} [properties] Properties to set
         */
        function GetAzureCommunicationCredentialsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetAzureCommunicationCredentialsRequest instance using the specified properties.
         * @function create
         * @memberof metering.GetAzureCommunicationCredentialsRequest
         * @static
         * @param {metering.IGetAzureCommunicationCredentialsRequest=} [properties] Properties to set
         * @returns {metering.GetAzureCommunicationCredentialsRequest} GetAzureCommunicationCredentialsRequest instance
         */
        GetAzureCommunicationCredentialsRequest.create = function create(properties) {
            return new GetAzureCommunicationCredentialsRequest(properties);
        };

        /**
         * Encodes the specified GetAzureCommunicationCredentialsRequest message. Does not implicitly {@link metering.GetAzureCommunicationCredentialsRequest.verify|verify} messages.
         * @function encode
         * @memberof metering.GetAzureCommunicationCredentialsRequest
         * @static
         * @param {metering.IGetAzureCommunicationCredentialsRequest} message GetAzureCommunicationCredentialsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAzureCommunicationCredentialsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetAzureCommunicationCredentialsRequest message, length delimited. Does not implicitly {@link metering.GetAzureCommunicationCredentialsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.GetAzureCommunicationCredentialsRequest
         * @static
         * @param {metering.IGetAzureCommunicationCredentialsRequest} message GetAzureCommunicationCredentialsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAzureCommunicationCredentialsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetAzureCommunicationCredentialsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof metering.GetAzureCommunicationCredentialsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.GetAzureCommunicationCredentialsRequest} GetAzureCommunicationCredentialsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAzureCommunicationCredentialsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.GetAzureCommunicationCredentialsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetAzureCommunicationCredentialsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.GetAzureCommunicationCredentialsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.GetAzureCommunicationCredentialsRequest} GetAzureCommunicationCredentialsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAzureCommunicationCredentialsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetAzureCommunicationCredentialsRequest message.
         * @function verify
         * @memberof metering.GetAzureCommunicationCredentialsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetAzureCommunicationCredentialsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetAzureCommunicationCredentialsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.GetAzureCommunicationCredentialsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.GetAzureCommunicationCredentialsRequest} GetAzureCommunicationCredentialsRequest
         */
        GetAzureCommunicationCredentialsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.GetAzureCommunicationCredentialsRequest)
                return object;
            return new $root.metering.GetAzureCommunicationCredentialsRequest();
        };

        /**
         * Creates a plain object from a GetAzureCommunicationCredentialsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.GetAzureCommunicationCredentialsRequest
         * @static
         * @param {metering.GetAzureCommunicationCredentialsRequest} message GetAzureCommunicationCredentialsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetAzureCommunicationCredentialsRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetAzureCommunicationCredentialsRequest to JSON.
         * @function toJSON
         * @memberof metering.GetAzureCommunicationCredentialsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetAzureCommunicationCredentialsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetAzureCommunicationCredentialsRequest
         * @function getTypeUrl
         * @memberof metering.GetAzureCommunicationCredentialsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetAzureCommunicationCredentialsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.GetAzureCommunicationCredentialsRequest";
        };

        return GetAzureCommunicationCredentialsRequest;
    })();

    metering.GetAzureCommunicationCredentialsResponse = (function() {

        /**
         * Properties of a GetAzureCommunicationCredentialsResponse.
         * @memberof metering
         * @interface IGetAzureCommunicationCredentialsResponse
         * @property {string|null} [endpoint] GetAzureCommunicationCredentialsResponse endpoint
         * @property {string|null} [accessKey] GetAzureCommunicationCredentialsResponse accessKey
         * @property {string|null} [senderEmail] GetAzureCommunicationCredentialsResponse senderEmail
         */

        /**
         * Constructs a new GetAzureCommunicationCredentialsResponse.
         * @memberof metering
         * @classdesc Represents a GetAzureCommunicationCredentialsResponse.
         * @implements IGetAzureCommunicationCredentialsResponse
         * @constructor
         * @param {metering.IGetAzureCommunicationCredentialsResponse=} [properties] Properties to set
         */
        function GetAzureCommunicationCredentialsResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAzureCommunicationCredentialsResponse endpoint.
         * @member {string} endpoint
         * @memberof metering.GetAzureCommunicationCredentialsResponse
         * @instance
         */
        GetAzureCommunicationCredentialsResponse.prototype.endpoint = "";

        /**
         * GetAzureCommunicationCredentialsResponse accessKey.
         * @member {string} accessKey
         * @memberof metering.GetAzureCommunicationCredentialsResponse
         * @instance
         */
        GetAzureCommunicationCredentialsResponse.prototype.accessKey = "";

        /**
         * GetAzureCommunicationCredentialsResponse senderEmail.
         * @member {string} senderEmail
         * @memberof metering.GetAzureCommunicationCredentialsResponse
         * @instance
         */
        GetAzureCommunicationCredentialsResponse.prototype.senderEmail = "";

        /**
         * Creates a new GetAzureCommunicationCredentialsResponse instance using the specified properties.
         * @function create
         * @memberof metering.GetAzureCommunicationCredentialsResponse
         * @static
         * @param {metering.IGetAzureCommunicationCredentialsResponse=} [properties] Properties to set
         * @returns {metering.GetAzureCommunicationCredentialsResponse} GetAzureCommunicationCredentialsResponse instance
         */
        GetAzureCommunicationCredentialsResponse.create = function create(properties) {
            return new GetAzureCommunicationCredentialsResponse(properties);
        };

        /**
         * Encodes the specified GetAzureCommunicationCredentialsResponse message. Does not implicitly {@link metering.GetAzureCommunicationCredentialsResponse.verify|verify} messages.
         * @function encode
         * @memberof metering.GetAzureCommunicationCredentialsResponse
         * @static
         * @param {metering.IGetAzureCommunicationCredentialsResponse} message GetAzureCommunicationCredentialsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAzureCommunicationCredentialsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.endpoint != null && Object.hasOwnProperty.call(message, "endpoint"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.endpoint);
            if (message.accessKey != null && Object.hasOwnProperty.call(message, "accessKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.accessKey);
            if (message.senderEmail != null && Object.hasOwnProperty.call(message, "senderEmail"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.senderEmail);
            return writer;
        };

        /**
         * Encodes the specified GetAzureCommunicationCredentialsResponse message, length delimited. Does not implicitly {@link metering.GetAzureCommunicationCredentialsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof metering.GetAzureCommunicationCredentialsResponse
         * @static
         * @param {metering.IGetAzureCommunicationCredentialsResponse} message GetAzureCommunicationCredentialsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAzureCommunicationCredentialsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetAzureCommunicationCredentialsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof metering.GetAzureCommunicationCredentialsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {metering.GetAzureCommunicationCredentialsResponse} GetAzureCommunicationCredentialsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAzureCommunicationCredentialsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.metering.GetAzureCommunicationCredentialsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.endpoint = reader.string();
                        break;
                    }
                case 2: {
                        message.accessKey = reader.string();
                        break;
                    }
                case 3: {
                        message.senderEmail = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetAzureCommunicationCredentialsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof metering.GetAzureCommunicationCredentialsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {metering.GetAzureCommunicationCredentialsResponse} GetAzureCommunicationCredentialsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAzureCommunicationCredentialsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetAzureCommunicationCredentialsResponse message.
         * @function verify
         * @memberof metering.GetAzureCommunicationCredentialsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetAzureCommunicationCredentialsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.endpoint != null && message.hasOwnProperty("endpoint"))
                if (!$util.isString(message.endpoint))
                    return "endpoint: string expected";
            if (message.accessKey != null && message.hasOwnProperty("accessKey"))
                if (!$util.isString(message.accessKey))
                    return "accessKey: string expected";
            if (message.senderEmail != null && message.hasOwnProperty("senderEmail"))
                if (!$util.isString(message.senderEmail))
                    return "senderEmail: string expected";
            return null;
        };

        /**
         * Creates a GetAzureCommunicationCredentialsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof metering.GetAzureCommunicationCredentialsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {metering.GetAzureCommunicationCredentialsResponse} GetAzureCommunicationCredentialsResponse
         */
        GetAzureCommunicationCredentialsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.metering.GetAzureCommunicationCredentialsResponse)
                return object;
            let message = new $root.metering.GetAzureCommunicationCredentialsResponse();
            if (object.endpoint != null)
                message.endpoint = String(object.endpoint);
            if (object.accessKey != null)
                message.accessKey = String(object.accessKey);
            if (object.senderEmail != null)
                message.senderEmail = String(object.senderEmail);
            return message;
        };

        /**
         * Creates a plain object from a GetAzureCommunicationCredentialsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof metering.GetAzureCommunicationCredentialsResponse
         * @static
         * @param {metering.GetAzureCommunicationCredentialsResponse} message GetAzureCommunicationCredentialsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetAzureCommunicationCredentialsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.endpoint = "";
                object.accessKey = "";
                object.senderEmail = "";
            }
            if (message.endpoint != null && message.hasOwnProperty("endpoint"))
                object.endpoint = message.endpoint;
            if (message.accessKey != null && message.hasOwnProperty("accessKey"))
                object.accessKey = message.accessKey;
            if (message.senderEmail != null && message.hasOwnProperty("senderEmail"))
                object.senderEmail = message.senderEmail;
            return object;
        };

        /**
         * Converts this GetAzureCommunicationCredentialsResponse to JSON.
         * @function toJSON
         * @memberof metering.GetAzureCommunicationCredentialsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetAzureCommunicationCredentialsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetAzureCommunicationCredentialsResponse
         * @function getTypeUrl
         * @memberof metering.GetAzureCommunicationCredentialsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetAzureCommunicationCredentialsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/metering.GetAzureCommunicationCredentialsResponse";
        };

        return GetAzureCommunicationCredentialsResponse;
    })();

    return metering;
})();

export const integrity_store_api = $root.integrity_store_api = (() => {

    /**
     * Namespace integrity_store_api.
     * @exports integrity_store_api
     * @namespace
     */
    const integrity_store_api = {};

    integrity_store_api.IntegrityStoreRequest = (function() {

        /**
         * Properties of an IntegrityStoreRequest.
         * @memberof integrity_store_api
         * @interface IIntegrityStoreRequest
         * @property {integrity_store_api.ICompareAndSwapRequest|null} [cas] IntegrityStoreRequest cas
         */

        /**
         * Constructs a new IntegrityStoreRequest.
         * @memberof integrity_store_api
         * @classdesc Represents an IntegrityStoreRequest.
         * @implements IIntegrityStoreRequest
         * @constructor
         * @param {integrity_store_api.IIntegrityStoreRequest=} [properties] Properties to set
         */
        function IntegrityStoreRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IntegrityStoreRequest cas.
         * @member {integrity_store_api.ICompareAndSwapRequest|null|undefined} cas
         * @memberof integrity_store_api.IntegrityStoreRequest
         * @instance
         */
        IntegrityStoreRequest.prototype.cas = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * IntegrityStoreRequest request.
         * @member {"cas"|undefined} request
         * @memberof integrity_store_api.IntegrityStoreRequest
         * @instance
         */
        Object.defineProperty(IntegrityStoreRequest.prototype, "request", {
            get: $util.oneOfGetter($oneOfFields = ["cas"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new IntegrityStoreRequest instance using the specified properties.
         * @function create
         * @memberof integrity_store_api.IntegrityStoreRequest
         * @static
         * @param {integrity_store_api.IIntegrityStoreRequest=} [properties] Properties to set
         * @returns {integrity_store_api.IntegrityStoreRequest} IntegrityStoreRequest instance
         */
        IntegrityStoreRequest.create = function create(properties) {
            return new IntegrityStoreRequest(properties);
        };

        /**
         * Encodes the specified IntegrityStoreRequest message. Does not implicitly {@link integrity_store_api.IntegrityStoreRequest.verify|verify} messages.
         * @function encode
         * @memberof integrity_store_api.IntegrityStoreRequest
         * @static
         * @param {integrity_store_api.IIntegrityStoreRequest} message IntegrityStoreRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IntegrityStoreRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cas != null && Object.hasOwnProperty.call(message, "cas"))
                $root.integrity_store_api.CompareAndSwapRequest.encode(message.cas, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified IntegrityStoreRequest message, length delimited. Does not implicitly {@link integrity_store_api.IntegrityStoreRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof integrity_store_api.IntegrityStoreRequest
         * @static
         * @param {integrity_store_api.IIntegrityStoreRequest} message IntegrityStoreRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IntegrityStoreRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an IntegrityStoreRequest message from the specified reader or buffer.
         * @function decode
         * @memberof integrity_store_api.IntegrityStoreRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {integrity_store_api.IntegrityStoreRequest} IntegrityStoreRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IntegrityStoreRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.integrity_store_api.IntegrityStoreRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.cas = $root.integrity_store_api.CompareAndSwapRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an IntegrityStoreRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof integrity_store_api.IntegrityStoreRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {integrity_store_api.IntegrityStoreRequest} IntegrityStoreRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IntegrityStoreRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an IntegrityStoreRequest message.
         * @function verify
         * @memberof integrity_store_api.IntegrityStoreRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        IntegrityStoreRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.cas != null && message.hasOwnProperty("cas")) {
                properties.request = 1;
                {
                    let error = $root.integrity_store_api.CompareAndSwapRequest.verify(message.cas);
                    if (error)
                        return "cas." + error;
                }
            }
            return null;
        };

        /**
         * Creates an IntegrityStoreRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof integrity_store_api.IntegrityStoreRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {integrity_store_api.IntegrityStoreRequest} IntegrityStoreRequest
         */
        IntegrityStoreRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.integrity_store_api.IntegrityStoreRequest)
                return object;
            let message = new $root.integrity_store_api.IntegrityStoreRequest();
            if (object.cas != null) {
                if (typeof object.cas !== "object")
                    throw TypeError(".integrity_store_api.IntegrityStoreRequest.cas: object expected");
                message.cas = $root.integrity_store_api.CompareAndSwapRequest.fromObject(object.cas);
            }
            return message;
        };

        /**
         * Creates a plain object from an IntegrityStoreRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof integrity_store_api.IntegrityStoreRequest
         * @static
         * @param {integrity_store_api.IntegrityStoreRequest} message IntegrityStoreRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IntegrityStoreRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.cas != null && message.hasOwnProperty("cas")) {
                object.cas = $root.integrity_store_api.CompareAndSwapRequest.toObject(message.cas, options);
                if (options.oneofs)
                    object.request = "cas";
            }
            return object;
        };

        /**
         * Converts this IntegrityStoreRequest to JSON.
         * @function toJSON
         * @memberof integrity_store_api.IntegrityStoreRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IntegrityStoreRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for IntegrityStoreRequest
         * @function getTypeUrl
         * @memberof integrity_store_api.IntegrityStoreRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        IntegrityStoreRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/integrity_store_api.IntegrityStoreRequest";
        };

        return IntegrityStoreRequest;
    })();

    integrity_store_api.IntegrityStoreResponse = (function() {

        /**
         * Properties of an IntegrityStoreResponse.
         * @memberof integrity_store_api
         * @interface IIntegrityStoreResponse
         * @property {integrity_store_api.ICompareAndSwapResponse|null} [cas] IntegrityStoreResponse cas
         */

        /**
         * Constructs a new IntegrityStoreResponse.
         * @memberof integrity_store_api
         * @classdesc Represents an IntegrityStoreResponse.
         * @implements IIntegrityStoreResponse
         * @constructor
         * @param {integrity_store_api.IIntegrityStoreResponse=} [properties] Properties to set
         */
        function IntegrityStoreResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IntegrityStoreResponse cas.
         * @member {integrity_store_api.ICompareAndSwapResponse|null|undefined} cas
         * @memberof integrity_store_api.IntegrityStoreResponse
         * @instance
         */
        IntegrityStoreResponse.prototype.cas = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * IntegrityStoreResponse response.
         * @member {"cas"|undefined} response
         * @memberof integrity_store_api.IntegrityStoreResponse
         * @instance
         */
        Object.defineProperty(IntegrityStoreResponse.prototype, "response", {
            get: $util.oneOfGetter($oneOfFields = ["cas"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new IntegrityStoreResponse instance using the specified properties.
         * @function create
         * @memberof integrity_store_api.IntegrityStoreResponse
         * @static
         * @param {integrity_store_api.IIntegrityStoreResponse=} [properties] Properties to set
         * @returns {integrity_store_api.IntegrityStoreResponse} IntegrityStoreResponse instance
         */
        IntegrityStoreResponse.create = function create(properties) {
            return new IntegrityStoreResponse(properties);
        };

        /**
         * Encodes the specified IntegrityStoreResponse message. Does not implicitly {@link integrity_store_api.IntegrityStoreResponse.verify|verify} messages.
         * @function encode
         * @memberof integrity_store_api.IntegrityStoreResponse
         * @static
         * @param {integrity_store_api.IIntegrityStoreResponse} message IntegrityStoreResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IntegrityStoreResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cas != null && Object.hasOwnProperty.call(message, "cas"))
                $root.integrity_store_api.CompareAndSwapResponse.encode(message.cas, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified IntegrityStoreResponse message, length delimited. Does not implicitly {@link integrity_store_api.IntegrityStoreResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof integrity_store_api.IntegrityStoreResponse
         * @static
         * @param {integrity_store_api.IIntegrityStoreResponse} message IntegrityStoreResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IntegrityStoreResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an IntegrityStoreResponse message from the specified reader or buffer.
         * @function decode
         * @memberof integrity_store_api.IntegrityStoreResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {integrity_store_api.IntegrityStoreResponse} IntegrityStoreResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IntegrityStoreResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.integrity_store_api.IntegrityStoreResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.cas = $root.integrity_store_api.CompareAndSwapResponse.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an IntegrityStoreResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof integrity_store_api.IntegrityStoreResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {integrity_store_api.IntegrityStoreResponse} IntegrityStoreResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IntegrityStoreResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an IntegrityStoreResponse message.
         * @function verify
         * @memberof integrity_store_api.IntegrityStoreResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        IntegrityStoreResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.cas != null && message.hasOwnProperty("cas")) {
                properties.response = 1;
                {
                    let error = $root.integrity_store_api.CompareAndSwapResponse.verify(message.cas);
                    if (error)
                        return "cas." + error;
                }
            }
            return null;
        };

        /**
         * Creates an IntegrityStoreResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof integrity_store_api.IntegrityStoreResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {integrity_store_api.IntegrityStoreResponse} IntegrityStoreResponse
         */
        IntegrityStoreResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.integrity_store_api.IntegrityStoreResponse)
                return object;
            let message = new $root.integrity_store_api.IntegrityStoreResponse();
            if (object.cas != null) {
                if (typeof object.cas !== "object")
                    throw TypeError(".integrity_store_api.IntegrityStoreResponse.cas: object expected");
                message.cas = $root.integrity_store_api.CompareAndSwapResponse.fromObject(object.cas);
            }
            return message;
        };

        /**
         * Creates a plain object from an IntegrityStoreResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof integrity_store_api.IntegrityStoreResponse
         * @static
         * @param {integrity_store_api.IntegrityStoreResponse} message IntegrityStoreResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IntegrityStoreResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.cas != null && message.hasOwnProperty("cas")) {
                object.cas = $root.integrity_store_api.CompareAndSwapResponse.toObject(message.cas, options);
                if (options.oneofs)
                    object.response = "cas";
            }
            return object;
        };

        /**
         * Converts this IntegrityStoreResponse to JSON.
         * @function toJSON
         * @memberof integrity_store_api.IntegrityStoreResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IntegrityStoreResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for IntegrityStoreResponse
         * @function getTypeUrl
         * @memberof integrity_store_api.IntegrityStoreResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        IntegrityStoreResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/integrity_store_api.IntegrityStoreResponse";
        };

        return IntegrityStoreResponse;
    })();

    integrity_store_api.CompareAndSwapRequest = (function() {

        /**
         * Properties of a CompareAndSwapRequest.
         * @memberof integrity_store_api
         * @interface ICompareAndSwapRequest
         * @property {string|null} [id] CompareAndSwapRequest id
         * @property {string|null} [contentType] CompareAndSwapRequest contentType
         * @property {number|Long|null} [expectedCasIndex] CompareAndSwapRequest expectedCasIndex
         * @property {integrity_store_api.IIntegrityStoreValue|null} [newValue] CompareAndSwapRequest newValue
         */

        /**
         * Constructs a new CompareAndSwapRequest.
         * @memberof integrity_store_api
         * @classdesc Represents a CompareAndSwapRequest.
         * @implements ICompareAndSwapRequest
         * @constructor
         * @param {integrity_store_api.ICompareAndSwapRequest=} [properties] Properties to set
         */
        function CompareAndSwapRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CompareAndSwapRequest id.
         * @member {string} id
         * @memberof integrity_store_api.CompareAndSwapRequest
         * @instance
         */
        CompareAndSwapRequest.prototype.id = "";

        /**
         * CompareAndSwapRequest contentType.
         * @member {string} contentType
         * @memberof integrity_store_api.CompareAndSwapRequest
         * @instance
         */
        CompareAndSwapRequest.prototype.contentType = "";

        /**
         * CompareAndSwapRequest expectedCasIndex.
         * @member {number|Long|null|undefined} expectedCasIndex
         * @memberof integrity_store_api.CompareAndSwapRequest
         * @instance
         */
        CompareAndSwapRequest.prototype.expectedCasIndex = null;

        /**
         * CompareAndSwapRequest newValue.
         * @member {integrity_store_api.IIntegrityStoreValue|null|undefined} newValue
         * @memberof integrity_store_api.CompareAndSwapRequest
         * @instance
         */
        CompareAndSwapRequest.prototype.newValue = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * CompareAndSwapRequest _expectedCasIndex.
         * @member {"expectedCasIndex"|undefined} _expectedCasIndex
         * @memberof integrity_store_api.CompareAndSwapRequest
         * @instance
         */
        Object.defineProperty(CompareAndSwapRequest.prototype, "_expectedCasIndex", {
            get: $util.oneOfGetter($oneOfFields = ["expectedCasIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CompareAndSwapRequest _newValue.
         * @member {"newValue"|undefined} _newValue
         * @memberof integrity_store_api.CompareAndSwapRequest
         * @instance
         */
        Object.defineProperty(CompareAndSwapRequest.prototype, "_newValue", {
            get: $util.oneOfGetter($oneOfFields = ["newValue"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CompareAndSwapRequest instance using the specified properties.
         * @function create
         * @memberof integrity_store_api.CompareAndSwapRequest
         * @static
         * @param {integrity_store_api.ICompareAndSwapRequest=} [properties] Properties to set
         * @returns {integrity_store_api.CompareAndSwapRequest} CompareAndSwapRequest instance
         */
        CompareAndSwapRequest.create = function create(properties) {
            return new CompareAndSwapRequest(properties);
        };

        /**
         * Encodes the specified CompareAndSwapRequest message. Does not implicitly {@link integrity_store_api.CompareAndSwapRequest.verify|verify} messages.
         * @function encode
         * @memberof integrity_store_api.CompareAndSwapRequest
         * @static
         * @param {integrity_store_api.ICompareAndSwapRequest} message CompareAndSwapRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompareAndSwapRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.contentType != null && Object.hasOwnProperty.call(message, "contentType"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.contentType);
            if (message.expectedCasIndex != null && Object.hasOwnProperty.call(message, "expectedCasIndex"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.expectedCasIndex);
            if (message.newValue != null && Object.hasOwnProperty.call(message, "newValue"))
                $root.integrity_store_api.IntegrityStoreValue.encode(message.newValue, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CompareAndSwapRequest message, length delimited. Does not implicitly {@link integrity_store_api.CompareAndSwapRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof integrity_store_api.CompareAndSwapRequest
         * @static
         * @param {integrity_store_api.ICompareAndSwapRequest} message CompareAndSwapRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompareAndSwapRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CompareAndSwapRequest message from the specified reader or buffer.
         * @function decode
         * @memberof integrity_store_api.CompareAndSwapRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {integrity_store_api.CompareAndSwapRequest} CompareAndSwapRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompareAndSwapRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.integrity_store_api.CompareAndSwapRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.contentType = reader.string();
                        break;
                    }
                case 3: {
                        message.expectedCasIndex = reader.uint64();
                        break;
                    }
                case 4: {
                        message.newValue = $root.integrity_store_api.IntegrityStoreValue.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CompareAndSwapRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof integrity_store_api.CompareAndSwapRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {integrity_store_api.CompareAndSwapRequest} CompareAndSwapRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompareAndSwapRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CompareAndSwapRequest message.
         * @function verify
         * @memberof integrity_store_api.CompareAndSwapRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CompareAndSwapRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.contentType != null && message.hasOwnProperty("contentType"))
                if (!$util.isString(message.contentType))
                    return "contentType: string expected";
            if (message.expectedCasIndex != null && message.hasOwnProperty("expectedCasIndex")) {
                properties._expectedCasIndex = 1;
                if (!$util.isInteger(message.expectedCasIndex) && !(message.expectedCasIndex && $util.isInteger(message.expectedCasIndex.low) && $util.isInteger(message.expectedCasIndex.high)))
                    return "expectedCasIndex: integer|Long expected";
            }
            if (message.newValue != null && message.hasOwnProperty("newValue")) {
                properties._newValue = 1;
                {
                    let error = $root.integrity_store_api.IntegrityStoreValue.verify(message.newValue);
                    if (error)
                        return "newValue." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CompareAndSwapRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof integrity_store_api.CompareAndSwapRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {integrity_store_api.CompareAndSwapRequest} CompareAndSwapRequest
         */
        CompareAndSwapRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.integrity_store_api.CompareAndSwapRequest)
                return object;
            let message = new $root.integrity_store_api.CompareAndSwapRequest();
            if (object.id != null)
                message.id = String(object.id);
            if (object.contentType != null)
                message.contentType = String(object.contentType);
            if (object.expectedCasIndex != null)
                if ($util.Long)
                    (message.expectedCasIndex = $util.Long.fromValue(object.expectedCasIndex)).unsigned = true;
                else if (typeof object.expectedCasIndex === "string")
                    message.expectedCasIndex = parseInt(object.expectedCasIndex, 10);
                else if (typeof object.expectedCasIndex === "number")
                    message.expectedCasIndex = object.expectedCasIndex;
                else if (typeof object.expectedCasIndex === "object")
                    message.expectedCasIndex = new $util.LongBits(object.expectedCasIndex.low >>> 0, object.expectedCasIndex.high >>> 0).toNumber(true);
            if (object.newValue != null) {
                if (typeof object.newValue !== "object")
                    throw TypeError(".integrity_store_api.CompareAndSwapRequest.newValue: object expected");
                message.newValue = $root.integrity_store_api.IntegrityStoreValue.fromObject(object.newValue);
            }
            return message;
        };

        /**
         * Creates a plain object from a CompareAndSwapRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof integrity_store_api.CompareAndSwapRequest
         * @static
         * @param {integrity_store_api.CompareAndSwapRequest} message CompareAndSwapRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompareAndSwapRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.contentType = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.contentType != null && message.hasOwnProperty("contentType"))
                object.contentType = message.contentType;
            if (message.expectedCasIndex != null && message.hasOwnProperty("expectedCasIndex")) {
                if (typeof message.expectedCasIndex === "number")
                    object.expectedCasIndex = options.longs === String ? String(message.expectedCasIndex) : message.expectedCasIndex;
                else
                    object.expectedCasIndex = options.longs === String ? $util.Long.prototype.toString.call(message.expectedCasIndex) : options.longs === Number ? new $util.LongBits(message.expectedCasIndex.low >>> 0, message.expectedCasIndex.high >>> 0).toNumber(true) : message.expectedCasIndex;
                if (options.oneofs)
                    object._expectedCasIndex = "expectedCasIndex";
            }
            if (message.newValue != null && message.hasOwnProperty("newValue")) {
                object.newValue = $root.integrity_store_api.IntegrityStoreValue.toObject(message.newValue, options);
                if (options.oneofs)
                    object._newValue = "newValue";
            }
            return object;
        };

        /**
         * Converts this CompareAndSwapRequest to JSON.
         * @function toJSON
         * @memberof integrity_store_api.CompareAndSwapRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompareAndSwapRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CompareAndSwapRequest
         * @function getTypeUrl
         * @memberof integrity_store_api.CompareAndSwapRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CompareAndSwapRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/integrity_store_api.CompareAndSwapRequest";
        };

        return CompareAndSwapRequest;
    })();

    integrity_store_api.CompareAndSwapResponse = (function() {

        /**
         * Properties of a CompareAndSwapResponse.
         * @memberof integrity_store_api
         * @interface ICompareAndSwapResponse
         * @property {boolean|null} [swapped] CompareAndSwapResponse swapped
         * @property {number|Long|null} [previousCasIndex] CompareAndSwapResponse previousCasIndex
         * @property {integrity_store_api.IIntegrityStoreValue|null} [previousValue] CompareAndSwapResponse previousValue
         */

        /**
         * Constructs a new CompareAndSwapResponse.
         * @memberof integrity_store_api
         * @classdesc Represents a CompareAndSwapResponse.
         * @implements ICompareAndSwapResponse
         * @constructor
         * @param {integrity_store_api.ICompareAndSwapResponse=} [properties] Properties to set
         */
        function CompareAndSwapResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CompareAndSwapResponse swapped.
         * @member {boolean} swapped
         * @memberof integrity_store_api.CompareAndSwapResponse
         * @instance
         */
        CompareAndSwapResponse.prototype.swapped = false;

        /**
         * CompareAndSwapResponse previousCasIndex.
         * @member {number|Long|null|undefined} previousCasIndex
         * @memberof integrity_store_api.CompareAndSwapResponse
         * @instance
         */
        CompareAndSwapResponse.prototype.previousCasIndex = null;

        /**
         * CompareAndSwapResponse previousValue.
         * @member {integrity_store_api.IIntegrityStoreValue|null|undefined} previousValue
         * @memberof integrity_store_api.CompareAndSwapResponse
         * @instance
         */
        CompareAndSwapResponse.prototype.previousValue = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * CompareAndSwapResponse _previousCasIndex.
         * @member {"previousCasIndex"|undefined} _previousCasIndex
         * @memberof integrity_store_api.CompareAndSwapResponse
         * @instance
         */
        Object.defineProperty(CompareAndSwapResponse.prototype, "_previousCasIndex", {
            get: $util.oneOfGetter($oneOfFields = ["previousCasIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CompareAndSwapResponse _previousValue.
         * @member {"previousValue"|undefined} _previousValue
         * @memberof integrity_store_api.CompareAndSwapResponse
         * @instance
         */
        Object.defineProperty(CompareAndSwapResponse.prototype, "_previousValue", {
            get: $util.oneOfGetter($oneOfFields = ["previousValue"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CompareAndSwapResponse instance using the specified properties.
         * @function create
         * @memberof integrity_store_api.CompareAndSwapResponse
         * @static
         * @param {integrity_store_api.ICompareAndSwapResponse=} [properties] Properties to set
         * @returns {integrity_store_api.CompareAndSwapResponse} CompareAndSwapResponse instance
         */
        CompareAndSwapResponse.create = function create(properties) {
            return new CompareAndSwapResponse(properties);
        };

        /**
         * Encodes the specified CompareAndSwapResponse message. Does not implicitly {@link integrity_store_api.CompareAndSwapResponse.verify|verify} messages.
         * @function encode
         * @memberof integrity_store_api.CompareAndSwapResponse
         * @static
         * @param {integrity_store_api.ICompareAndSwapResponse} message CompareAndSwapResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompareAndSwapResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.swapped != null && Object.hasOwnProperty.call(message, "swapped"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.swapped);
            if (message.previousCasIndex != null && Object.hasOwnProperty.call(message, "previousCasIndex"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.previousCasIndex);
            if (message.previousValue != null && Object.hasOwnProperty.call(message, "previousValue"))
                $root.integrity_store_api.IntegrityStoreValue.encode(message.previousValue, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CompareAndSwapResponse message, length delimited. Does not implicitly {@link integrity_store_api.CompareAndSwapResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof integrity_store_api.CompareAndSwapResponse
         * @static
         * @param {integrity_store_api.ICompareAndSwapResponse} message CompareAndSwapResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompareAndSwapResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CompareAndSwapResponse message from the specified reader or buffer.
         * @function decode
         * @memberof integrity_store_api.CompareAndSwapResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {integrity_store_api.CompareAndSwapResponse} CompareAndSwapResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompareAndSwapResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.integrity_store_api.CompareAndSwapResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.swapped = reader.bool();
                        break;
                    }
                case 2: {
                        message.previousCasIndex = reader.uint64();
                        break;
                    }
                case 3: {
                        message.previousValue = $root.integrity_store_api.IntegrityStoreValue.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CompareAndSwapResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof integrity_store_api.CompareAndSwapResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {integrity_store_api.CompareAndSwapResponse} CompareAndSwapResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompareAndSwapResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CompareAndSwapResponse message.
         * @function verify
         * @memberof integrity_store_api.CompareAndSwapResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CompareAndSwapResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.swapped != null && message.hasOwnProperty("swapped"))
                if (typeof message.swapped !== "boolean")
                    return "swapped: boolean expected";
            if (message.previousCasIndex != null && message.hasOwnProperty("previousCasIndex")) {
                properties._previousCasIndex = 1;
                if (!$util.isInteger(message.previousCasIndex) && !(message.previousCasIndex && $util.isInteger(message.previousCasIndex.low) && $util.isInteger(message.previousCasIndex.high)))
                    return "previousCasIndex: integer|Long expected";
            }
            if (message.previousValue != null && message.hasOwnProperty("previousValue")) {
                properties._previousValue = 1;
                {
                    let error = $root.integrity_store_api.IntegrityStoreValue.verify(message.previousValue);
                    if (error)
                        return "previousValue." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CompareAndSwapResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof integrity_store_api.CompareAndSwapResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {integrity_store_api.CompareAndSwapResponse} CompareAndSwapResponse
         */
        CompareAndSwapResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.integrity_store_api.CompareAndSwapResponse)
                return object;
            let message = new $root.integrity_store_api.CompareAndSwapResponse();
            if (object.swapped != null)
                message.swapped = Boolean(object.swapped);
            if (object.previousCasIndex != null)
                if ($util.Long)
                    (message.previousCasIndex = $util.Long.fromValue(object.previousCasIndex)).unsigned = true;
                else if (typeof object.previousCasIndex === "string")
                    message.previousCasIndex = parseInt(object.previousCasIndex, 10);
                else if (typeof object.previousCasIndex === "number")
                    message.previousCasIndex = object.previousCasIndex;
                else if (typeof object.previousCasIndex === "object")
                    message.previousCasIndex = new $util.LongBits(object.previousCasIndex.low >>> 0, object.previousCasIndex.high >>> 0).toNumber(true);
            if (object.previousValue != null) {
                if (typeof object.previousValue !== "object")
                    throw TypeError(".integrity_store_api.CompareAndSwapResponse.previousValue: object expected");
                message.previousValue = $root.integrity_store_api.IntegrityStoreValue.fromObject(object.previousValue);
            }
            return message;
        };

        /**
         * Creates a plain object from a CompareAndSwapResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof integrity_store_api.CompareAndSwapResponse
         * @static
         * @param {integrity_store_api.CompareAndSwapResponse} message CompareAndSwapResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompareAndSwapResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.swapped = false;
            if (message.swapped != null && message.hasOwnProperty("swapped"))
                object.swapped = message.swapped;
            if (message.previousCasIndex != null && message.hasOwnProperty("previousCasIndex")) {
                if (typeof message.previousCasIndex === "number")
                    object.previousCasIndex = options.longs === String ? String(message.previousCasIndex) : message.previousCasIndex;
                else
                    object.previousCasIndex = options.longs === String ? $util.Long.prototype.toString.call(message.previousCasIndex) : options.longs === Number ? new $util.LongBits(message.previousCasIndex.low >>> 0, message.previousCasIndex.high >>> 0).toNumber(true) : message.previousCasIndex;
                if (options.oneofs)
                    object._previousCasIndex = "previousCasIndex";
            }
            if (message.previousValue != null && message.hasOwnProperty("previousValue")) {
                object.previousValue = $root.integrity_store_api.IntegrityStoreValue.toObject(message.previousValue, options);
                if (options.oneofs)
                    object._previousValue = "previousValue";
            }
            return object;
        };

        /**
         * Converts this CompareAndSwapResponse to JSON.
         * @function toJSON
         * @memberof integrity_store_api.CompareAndSwapResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompareAndSwapResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CompareAndSwapResponse
         * @function getTypeUrl
         * @memberof integrity_store_api.CompareAndSwapResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CompareAndSwapResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/integrity_store_api.CompareAndSwapResponse";
        };

        return CompareAndSwapResponse;
    })();

    integrity_store_api.IntegrityStoreValue = (function() {

        /**
         * Properties of an IntegrityStoreValue.
         * @memberof integrity_store_api
         * @interface IIntegrityStoreValue
         * @property {number|null} [version] (e.g. what algorithm was used when creating the signature).
         * @property {string|null} [plaintextContent] Generic stringified payload
         * @property {Uint8Array|null} [plaintextSignature] Signature over (content, contentType, version, id)
         * @property {Uint8Array|null} [encryptedContent] Encrypted payload
         */

        /**
         * Constructs a new IntegrityStoreValue.
         * @memberof integrity_store_api
         * @classdesc Represents an IntegrityStoreValue.
         * @implements IIntegrityStoreValue
         * @constructor
         * @param {integrity_store_api.IIntegrityStoreValue=} [properties] Properties to set
         */
        function IntegrityStoreValue(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * (e.g. what algorithm was used when creating the signature).
         * @member {number} version
         * @memberof integrity_store_api.IntegrityStoreValue
         * @instance
         */
        IntegrityStoreValue.prototype.version = 0;

        /**
         * Generic stringified payload
         * @member {string|null|undefined} plaintextContent
         * @memberof integrity_store_api.IntegrityStoreValue
         * @instance
         */
        IntegrityStoreValue.prototype.plaintextContent = null;

        /**
         * Signature over (content, contentType, version, id)
         * @member {Uint8Array|null|undefined} plaintextSignature
         * @memberof integrity_store_api.IntegrityStoreValue
         * @instance
         */
        IntegrityStoreValue.prototype.plaintextSignature = null;

        /**
         * Encrypted payload
         * @member {Uint8Array|null|undefined} encryptedContent
         * @memberof integrity_store_api.IntegrityStoreValue
         * @instance
         */
        IntegrityStoreValue.prototype.encryptedContent = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * IntegrityStoreValue _plaintextContent.
         * @member {"plaintextContent"|undefined} _plaintextContent
         * @memberof integrity_store_api.IntegrityStoreValue
         * @instance
         */
        Object.defineProperty(IntegrityStoreValue.prototype, "_plaintextContent", {
            get: $util.oneOfGetter($oneOfFields = ["plaintextContent"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * IntegrityStoreValue _plaintextSignature.
         * @member {"plaintextSignature"|undefined} _plaintextSignature
         * @memberof integrity_store_api.IntegrityStoreValue
         * @instance
         */
        Object.defineProperty(IntegrityStoreValue.prototype, "_plaintextSignature", {
            get: $util.oneOfGetter($oneOfFields = ["plaintextSignature"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * IntegrityStoreValue _encryptedContent.
         * @member {"encryptedContent"|undefined} _encryptedContent
         * @memberof integrity_store_api.IntegrityStoreValue
         * @instance
         */
        Object.defineProperty(IntegrityStoreValue.prototype, "_encryptedContent", {
            get: $util.oneOfGetter($oneOfFields = ["encryptedContent"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new IntegrityStoreValue instance using the specified properties.
         * @function create
         * @memberof integrity_store_api.IntegrityStoreValue
         * @static
         * @param {integrity_store_api.IIntegrityStoreValue=} [properties] Properties to set
         * @returns {integrity_store_api.IntegrityStoreValue} IntegrityStoreValue instance
         */
        IntegrityStoreValue.create = function create(properties) {
            return new IntegrityStoreValue(properties);
        };

        /**
         * Encodes the specified IntegrityStoreValue message. Does not implicitly {@link integrity_store_api.IntegrityStoreValue.verify|verify} messages.
         * @function encode
         * @memberof integrity_store_api.IntegrityStoreValue
         * @static
         * @param {integrity_store_api.IIntegrityStoreValue} message IntegrityStoreValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IntegrityStoreValue.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.version);
            if (message.plaintextContent != null && Object.hasOwnProperty.call(message, "plaintextContent"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.plaintextContent);
            if (message.plaintextSignature != null && Object.hasOwnProperty.call(message, "plaintextSignature"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.plaintextSignature);
            if (message.encryptedContent != null && Object.hasOwnProperty.call(message, "encryptedContent"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.encryptedContent);
            return writer;
        };

        /**
         * Encodes the specified IntegrityStoreValue message, length delimited. Does not implicitly {@link integrity_store_api.IntegrityStoreValue.verify|verify} messages.
         * @function encodeDelimited
         * @memberof integrity_store_api.IntegrityStoreValue
         * @static
         * @param {integrity_store_api.IIntegrityStoreValue} message IntegrityStoreValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IntegrityStoreValue.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an IntegrityStoreValue message from the specified reader or buffer.
         * @function decode
         * @memberof integrity_store_api.IntegrityStoreValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {integrity_store_api.IntegrityStoreValue} IntegrityStoreValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IntegrityStoreValue.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.integrity_store_api.IntegrityStoreValue();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.version = reader.uint32();
                        break;
                    }
                case 2: {
                        message.plaintextContent = reader.string();
                        break;
                    }
                case 3: {
                        message.plaintextSignature = reader.bytes();
                        break;
                    }
                case 4: {
                        message.encryptedContent = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an IntegrityStoreValue message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof integrity_store_api.IntegrityStoreValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {integrity_store_api.IntegrityStoreValue} IntegrityStoreValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IntegrityStoreValue.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an IntegrityStoreValue message.
         * @function verify
         * @memberof integrity_store_api.IntegrityStoreValue
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        IntegrityStoreValue.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isInteger(message.version))
                    return "version: integer expected";
            if (message.plaintextContent != null && message.hasOwnProperty("plaintextContent")) {
                properties._plaintextContent = 1;
                if (!$util.isString(message.plaintextContent))
                    return "plaintextContent: string expected";
            }
            if (message.plaintextSignature != null && message.hasOwnProperty("plaintextSignature")) {
                properties._plaintextSignature = 1;
                if (!(message.plaintextSignature && typeof message.plaintextSignature.length === "number" || $util.isString(message.plaintextSignature)))
                    return "plaintextSignature: buffer expected";
            }
            if (message.encryptedContent != null && message.hasOwnProperty("encryptedContent")) {
                properties._encryptedContent = 1;
                if (!(message.encryptedContent && typeof message.encryptedContent.length === "number" || $util.isString(message.encryptedContent)))
                    return "encryptedContent: buffer expected";
            }
            return null;
        };

        /**
         * Creates an IntegrityStoreValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof integrity_store_api.IntegrityStoreValue
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {integrity_store_api.IntegrityStoreValue} IntegrityStoreValue
         */
        IntegrityStoreValue.fromObject = function fromObject(object) {
            if (object instanceof $root.integrity_store_api.IntegrityStoreValue)
                return object;
            let message = new $root.integrity_store_api.IntegrityStoreValue();
            if (object.version != null)
                message.version = object.version >>> 0;
            if (object.plaintextContent != null)
                message.plaintextContent = String(object.plaintextContent);
            if (object.plaintextSignature != null)
                if (typeof object.plaintextSignature === "string")
                    $util.base64.decode(object.plaintextSignature, message.plaintextSignature = $util.newBuffer($util.base64.length(object.plaintextSignature)), 0);
                else if (object.plaintextSignature.length >= 0)
                    message.plaintextSignature = object.plaintextSignature;
            if (object.encryptedContent != null)
                if (typeof object.encryptedContent === "string")
                    $util.base64.decode(object.encryptedContent, message.encryptedContent = $util.newBuffer($util.base64.length(object.encryptedContent)), 0);
                else if (object.encryptedContent.length >= 0)
                    message.encryptedContent = object.encryptedContent;
            return message;
        };

        /**
         * Creates a plain object from an IntegrityStoreValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof integrity_store_api.IntegrityStoreValue
         * @static
         * @param {integrity_store_api.IntegrityStoreValue} message IntegrityStoreValue
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IntegrityStoreValue.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.version = 0;
            if (message.version != null && message.hasOwnProperty("version"))
                object.version = message.version;
            if (message.plaintextContent != null && message.hasOwnProperty("plaintextContent")) {
                object.plaintextContent = message.plaintextContent;
                if (options.oneofs)
                    object._plaintextContent = "plaintextContent";
            }
            if (message.plaintextSignature != null && message.hasOwnProperty("plaintextSignature")) {
                object.plaintextSignature = options.bytes === String ? $util.base64.encode(message.plaintextSignature, 0, message.plaintextSignature.length) : options.bytes === Array ? Array.prototype.slice.call(message.plaintextSignature) : message.plaintextSignature;
                if (options.oneofs)
                    object._plaintextSignature = "plaintextSignature";
            }
            if (message.encryptedContent != null && message.hasOwnProperty("encryptedContent")) {
                object.encryptedContent = options.bytes === String ? $util.base64.encode(message.encryptedContent, 0, message.encryptedContent.length) : options.bytes === Array ? Array.prototype.slice.call(message.encryptedContent) : message.encryptedContent;
                if (options.oneofs)
                    object._encryptedContent = "encryptedContent";
            }
            return object;
        };

        /**
         * Converts this IntegrityStoreValue to JSON.
         * @function toJSON
         * @memberof integrity_store_api.IntegrityStoreValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IntegrityStoreValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for IntegrityStoreValue
         * @function getTypeUrl
         * @memberof integrity_store_api.IntegrityStoreValue
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        IntegrityStoreValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/integrity_store_api.IntegrityStoreValue";
        };

        return IntegrityStoreValue;
    })();

    return integrity_store_api;
})();

export const permutive = $root.permutive = (() => {

    /**
     * Namespace permutive.
     * @exports permutive
     * @namespace
     */
    const permutive = {};

    permutive.ImportRole = (function() {

        /**
         * Properties of an ImportRole.
         * @memberof permutive
         * @interface IImportRole
         */

        /**
         * Constructs a new ImportRole.
         * @memberof permutive
         * @classdesc Represents an ImportRole.
         * @implements IImportRole
         * @constructor
         * @param {permutive.IImportRole=} [properties] Properties to set
         */
        function ImportRole(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ImportRole instance using the specified properties.
         * @function create
         * @memberof permutive.ImportRole
         * @static
         * @param {permutive.IImportRole=} [properties] Properties to set
         * @returns {permutive.ImportRole} ImportRole instance
         */
        ImportRole.create = function create(properties) {
            return new ImportRole(properties);
        };

        /**
         * Encodes the specified ImportRole message. Does not implicitly {@link permutive.ImportRole.verify|verify} messages.
         * @function encode
         * @memberof permutive.ImportRole
         * @static
         * @param {permutive.IImportRole} message ImportRole message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImportRole.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ImportRole message, length delimited. Does not implicitly {@link permutive.ImportRole.verify|verify} messages.
         * @function encodeDelimited
         * @memberof permutive.ImportRole
         * @static
         * @param {permutive.IImportRole} message ImportRole message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImportRole.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ImportRole message from the specified reader or buffer.
         * @function decode
         * @memberof permutive.ImportRole
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {permutive.ImportRole} ImportRole
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImportRole.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.permutive.ImportRole();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ImportRole message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof permutive.ImportRole
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {permutive.ImportRole} ImportRole
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImportRole.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ImportRole message.
         * @function verify
         * @memberof permutive.ImportRole
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ImportRole.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an ImportRole message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof permutive.ImportRole
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {permutive.ImportRole} ImportRole
         */
        ImportRole.fromObject = function fromObject(object) {
            if (object instanceof $root.permutive.ImportRole)
                return object;
            return new $root.permutive.ImportRole();
        };

        /**
         * Creates a plain object from an ImportRole message. Also converts values to other types if specified.
         * @function toObject
         * @memberof permutive.ImportRole
         * @static
         * @param {permutive.ImportRole} message ImportRole
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImportRole.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ImportRole to JSON.
         * @function toJSON
         * @memberof permutive.ImportRole
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImportRole.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ImportRole
         * @function getTypeUrl
         * @memberof permutive.ImportRole
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ImportRole.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/permutive.ImportRole";
        };

        return ImportRole;
    })();

    permutive.ExportRole = (function() {

        /**
         * Properties of an ExportRole.
         * @memberof permutive
         * @interface IExportRole
         * @property {permutive.ISinkInput|null} [input] ExportRole input
         */

        /**
         * Constructs a new ExportRole.
         * @memberof permutive
         * @classdesc Represents an ExportRole.
         * @implements IExportRole
         * @constructor
         * @param {permutive.IExportRole=} [properties] Properties to set
         */
        function ExportRole(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExportRole input.
         * @member {permutive.ISinkInput|null|undefined} input
         * @memberof permutive.ExportRole
         * @instance
         */
        ExportRole.prototype.input = null;

        /**
         * Creates a new ExportRole instance using the specified properties.
         * @function create
         * @memberof permutive.ExportRole
         * @static
         * @param {permutive.IExportRole=} [properties] Properties to set
         * @returns {permutive.ExportRole} ExportRole instance
         */
        ExportRole.create = function create(properties) {
            return new ExportRole(properties);
        };

        /**
         * Encodes the specified ExportRole message. Does not implicitly {@link permutive.ExportRole.verify|verify} messages.
         * @function encode
         * @memberof permutive.ExportRole
         * @static
         * @param {permutive.IExportRole} message ExportRole message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExportRole.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.input != null && Object.hasOwnProperty.call(message, "input"))
                $root.permutive.SinkInput.encode(message.input, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ExportRole message, length delimited. Does not implicitly {@link permutive.ExportRole.verify|verify} messages.
         * @function encodeDelimited
         * @memberof permutive.ExportRole
         * @static
         * @param {permutive.IExportRole} message ExportRole message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExportRole.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExportRole message from the specified reader or buffer.
         * @function decode
         * @memberof permutive.ExportRole
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {permutive.ExportRole} ExportRole
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExportRole.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.permutive.ExportRole();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.input = $root.permutive.SinkInput.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExportRole message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof permutive.ExportRole
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {permutive.ExportRole} ExportRole
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExportRole.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExportRole message.
         * @function verify
         * @memberof permutive.ExportRole
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExportRole.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.input != null && message.hasOwnProperty("input")) {
                let error = $root.permutive.SinkInput.verify(message.input);
                if (error)
                    return "input." + error;
            }
            return null;
        };

        /**
         * Creates an ExportRole message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof permutive.ExportRole
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {permutive.ExportRole} ExportRole
         */
        ExportRole.fromObject = function fromObject(object) {
            if (object instanceof $root.permutive.ExportRole)
                return object;
            let message = new $root.permutive.ExportRole();
            if (object.input != null) {
                if (typeof object.input !== "object")
                    throw TypeError(".permutive.ExportRole.input: object expected");
                message.input = $root.permutive.SinkInput.fromObject(object.input);
            }
            return message;
        };

        /**
         * Creates a plain object from an ExportRole message. Also converts values to other types if specified.
         * @function toObject
         * @memberof permutive.ExportRole
         * @static
         * @param {permutive.ExportRole} message ExportRole
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExportRole.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.input = null;
            if (message.input != null && message.hasOwnProperty("input"))
                object.input = $root.permutive.SinkInput.toObject(message.input, options);
            return object;
        };

        /**
         * Converts this ExportRole to JSON.
         * @function toJSON
         * @memberof permutive.ExportRole
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExportRole.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ExportRole
         * @function getTypeUrl
         * @memberof permutive.ExportRole
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExportRole.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/permutive.ExportRole";
        };

        return ExportRole;
    })();

    permutive.SingleFile = (function() {

        /**
         * Properties of a SingleFile.
         * @memberof permutive
         * @interface ISingleFile
         * @property {string|null} [name] SingleFile name
         */

        /**
         * Constructs a new SingleFile.
         * @memberof permutive
         * @classdesc Represents a SingleFile.
         * @implements ISingleFile
         * @constructor
         * @param {permutive.ISingleFile=} [properties] Properties to set
         */
        function SingleFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SingleFile name.
         * @member {string} name
         * @memberof permutive.SingleFile
         * @instance
         */
        SingleFile.prototype.name = "";

        /**
         * Creates a new SingleFile instance using the specified properties.
         * @function create
         * @memberof permutive.SingleFile
         * @static
         * @param {permutive.ISingleFile=} [properties] Properties to set
         * @returns {permutive.SingleFile} SingleFile instance
         */
        SingleFile.create = function create(properties) {
            return new SingleFile(properties);
        };

        /**
         * Encodes the specified SingleFile message. Does not implicitly {@link permutive.SingleFile.verify|verify} messages.
         * @function encode
         * @memberof permutive.SingleFile
         * @static
         * @param {permutive.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified SingleFile message, length delimited. Does not implicitly {@link permutive.SingleFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof permutive.SingleFile
         * @static
         * @param {permutive.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer.
         * @function decode
         * @memberof permutive.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {permutive.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.permutive.SingleFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof permutive.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {permutive.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SingleFile message.
         * @function verify
         * @memberof permutive.SingleFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SingleFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a SingleFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof permutive.SingleFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {permutive.SingleFile} SingleFile
         */
        SingleFile.fromObject = function fromObject(object) {
            if (object instanceof $root.permutive.SingleFile)
                return object;
            let message = new $root.permutive.SingleFile();
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a SingleFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof permutive.SingleFile
         * @static
         * @param {permutive.SingleFile} message SingleFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SingleFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this SingleFile to JSON.
         * @function toJSON
         * @memberof permutive.SingleFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SingleFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SingleFile
         * @function getTypeUrl
         * @memberof permutive.SingleFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SingleFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/permutive.SingleFile";
        };

        return SingleFile;
    })();

    permutive.RawFile = (function() {

        /**
         * Properties of a RawFile.
         * @memberof permutive
         * @interface IRawFile
         */

        /**
         * Constructs a new RawFile.
         * @memberof permutive
         * @classdesc Represents a RawFile.
         * @implements IRawFile
         * @constructor
         * @param {permutive.IRawFile=} [properties] Properties to set
         */
        function RawFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RawFile instance using the specified properties.
         * @function create
         * @memberof permutive.RawFile
         * @static
         * @param {permutive.IRawFile=} [properties] Properties to set
         * @returns {permutive.RawFile} RawFile instance
         */
        RawFile.create = function create(properties) {
            return new RawFile(properties);
        };

        /**
         * Encodes the specified RawFile message. Does not implicitly {@link permutive.RawFile.verify|verify} messages.
         * @function encode
         * @memberof permutive.RawFile
         * @static
         * @param {permutive.IRawFile} message RawFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RawFile message, length delimited. Does not implicitly {@link permutive.RawFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof permutive.RawFile
         * @static
         * @param {permutive.IRawFile} message RawFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RawFile message from the specified reader or buffer.
         * @function decode
         * @memberof permutive.RawFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {permutive.RawFile} RawFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.permutive.RawFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RawFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof permutive.RawFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {permutive.RawFile} RawFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RawFile message.
         * @function verify
         * @memberof permutive.RawFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RawFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RawFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof permutive.RawFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {permutive.RawFile} RawFile
         */
        RawFile.fromObject = function fromObject(object) {
            if (object instanceof $root.permutive.RawFile)
                return object;
            return new $root.permutive.RawFile();
        };

        /**
         * Creates a plain object from a RawFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof permutive.RawFile
         * @static
         * @param {permutive.RawFile} message RawFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RawFile.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RawFile to JSON.
         * @function toJSON
         * @memberof permutive.RawFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RawFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RawFile
         * @function getTypeUrl
         * @memberof permutive.RawFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RawFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/permutive.RawFile";
        };

        return RawFile;
    })();

    permutive.ZipFile = (function() {

        /**
         * Properties of a ZipFile.
         * @memberof permutive
         * @interface IZipFile
         * @property {permutive.ISingleFile|null} [singleFile] ZipFile singleFile
         */

        /**
         * Constructs a new ZipFile.
         * @memberof permutive
         * @classdesc Represents a ZipFile.
         * @implements IZipFile
         * @constructor
         * @param {permutive.IZipFile=} [properties] Properties to set
         */
        function ZipFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ZipFile singleFile.
         * @member {permutive.ISingleFile|null|undefined} singleFile
         * @memberof permutive.ZipFile
         * @instance
         */
        ZipFile.prototype.singleFile = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ZipFile selection.
         * @member {"singleFile"|undefined} selection
         * @memberof permutive.ZipFile
         * @instance
         */
        Object.defineProperty(ZipFile.prototype, "selection", {
            get: $util.oneOfGetter($oneOfFields = ["singleFile"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ZipFile instance using the specified properties.
         * @function create
         * @memberof permutive.ZipFile
         * @static
         * @param {permutive.IZipFile=} [properties] Properties to set
         * @returns {permutive.ZipFile} ZipFile instance
         */
        ZipFile.create = function create(properties) {
            return new ZipFile(properties);
        };

        /**
         * Encodes the specified ZipFile message. Does not implicitly {@link permutive.ZipFile.verify|verify} messages.
         * @function encode
         * @memberof permutive.ZipFile
         * @static
         * @param {permutive.IZipFile} message ZipFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.singleFile != null && Object.hasOwnProperty.call(message, "singleFile"))
                $root.permutive.SingleFile.encode(message.singleFile, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ZipFile message, length delimited. Does not implicitly {@link permutive.ZipFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof permutive.ZipFile
         * @static
         * @param {permutive.IZipFile} message ZipFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ZipFile message from the specified reader or buffer.
         * @function decode
         * @memberof permutive.ZipFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {permutive.ZipFile} ZipFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.permutive.ZipFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.singleFile = $root.permutive.SingleFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ZipFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof permutive.ZipFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {permutive.ZipFile} ZipFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ZipFile message.
         * @function verify
         * @memberof permutive.ZipFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ZipFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.singleFile != null && message.hasOwnProperty("singleFile")) {
                properties.selection = 1;
                {
                    let error = $root.permutive.SingleFile.verify(message.singleFile);
                    if (error)
                        return "singleFile." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ZipFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof permutive.ZipFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {permutive.ZipFile} ZipFile
         */
        ZipFile.fromObject = function fromObject(object) {
            if (object instanceof $root.permutive.ZipFile)
                return object;
            let message = new $root.permutive.ZipFile();
            if (object.singleFile != null) {
                if (typeof object.singleFile !== "object")
                    throw TypeError(".permutive.ZipFile.singleFile: object expected");
                message.singleFile = $root.permutive.SingleFile.fromObject(object.singleFile);
            }
            return message;
        };

        /**
         * Creates a plain object from a ZipFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof permutive.ZipFile
         * @static
         * @param {permutive.ZipFile} message ZipFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ZipFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.singleFile != null && message.hasOwnProperty("singleFile")) {
                object.singleFile = $root.permutive.SingleFile.toObject(message.singleFile, options);
                if (options.oneofs)
                    object.selection = "singleFile";
            }
            return object;
        };

        /**
         * Converts this ZipFile to JSON.
         * @function toJSON
         * @memberof permutive.ZipFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ZipFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ZipFile
         * @function getTypeUrl
         * @memberof permutive.ZipFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ZipFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/permutive.ZipFile";
        };

        return ZipFile;
    })();

    permutive.SinkInput = (function() {

        /**
         * Properties of a SinkInput.
         * @memberof permutive
         * @interface ISinkInput
         * @property {string|null} [dependency] SinkInput dependency
         * @property {string|null} [name] SinkInput name
         * @property {permutive.IRawFile|null} [raw] SinkInput raw
         * @property {permutive.IZipFile|null} [zip] SinkInput zip
         */

        /**
         * Constructs a new SinkInput.
         * @memberof permutive
         * @classdesc Represents a SinkInput.
         * @implements ISinkInput
         * @constructor
         * @param {permutive.ISinkInput=} [properties] Properties to set
         */
        function SinkInput(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SinkInput dependency.
         * @member {string} dependency
         * @memberof permutive.SinkInput
         * @instance
         */
        SinkInput.prototype.dependency = "";

        /**
         * SinkInput name.
         * @member {string} name
         * @memberof permutive.SinkInput
         * @instance
         */
        SinkInput.prototype.name = "";

        /**
         * SinkInput raw.
         * @member {permutive.IRawFile|null|undefined} raw
         * @memberof permutive.SinkInput
         * @instance
         */
        SinkInput.prototype.raw = null;

        /**
         * SinkInput zip.
         * @member {permutive.IZipFile|null|undefined} zip
         * @memberof permutive.SinkInput
         * @instance
         */
        SinkInput.prototype.zip = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SinkInput file.
         * @member {"raw"|"zip"|undefined} file
         * @memberof permutive.SinkInput
         * @instance
         */
        Object.defineProperty(SinkInput.prototype, "file", {
            get: $util.oneOfGetter($oneOfFields = ["raw", "zip"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SinkInput instance using the specified properties.
         * @function create
         * @memberof permutive.SinkInput
         * @static
         * @param {permutive.ISinkInput=} [properties] Properties to set
         * @returns {permutive.SinkInput} SinkInput instance
         */
        SinkInput.create = function create(properties) {
            return new SinkInput(properties);
        };

        /**
         * Encodes the specified SinkInput message. Does not implicitly {@link permutive.SinkInput.verify|verify} messages.
         * @function encode
         * @memberof permutive.SinkInput
         * @static
         * @param {permutive.ISinkInput} message SinkInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SinkInput.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dependency != null && Object.hasOwnProperty.call(message, "dependency"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dependency);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.raw != null && Object.hasOwnProperty.call(message, "raw"))
                $root.permutive.RawFile.encode(message.raw, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.zip != null && Object.hasOwnProperty.call(message, "zip"))
                $root.permutive.ZipFile.encode(message.zip, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SinkInput message, length delimited. Does not implicitly {@link permutive.SinkInput.verify|verify} messages.
         * @function encodeDelimited
         * @memberof permutive.SinkInput
         * @static
         * @param {permutive.ISinkInput} message SinkInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SinkInput.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SinkInput message from the specified reader or buffer.
         * @function decode
         * @memberof permutive.SinkInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {permutive.SinkInput} SinkInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SinkInput.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.permutive.SinkInput();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dependency = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.raw = $root.permutive.RawFile.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.zip = $root.permutive.ZipFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SinkInput message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof permutive.SinkInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {permutive.SinkInput} SinkInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SinkInput.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SinkInput message.
         * @function verify
         * @memberof permutive.SinkInput
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SinkInput.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                if (!$util.isString(message.dependency))
                    return "dependency: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.raw != null && message.hasOwnProperty("raw")) {
                properties.file = 1;
                {
                    let error = $root.permutive.RawFile.verify(message.raw);
                    if (error)
                        return "raw." + error;
                }
            }
            if (message.zip != null && message.hasOwnProperty("zip")) {
                if (properties.file === 1)
                    return "file: multiple values";
                properties.file = 1;
                {
                    let error = $root.permutive.ZipFile.verify(message.zip);
                    if (error)
                        return "zip." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SinkInput message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof permutive.SinkInput
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {permutive.SinkInput} SinkInput
         */
        SinkInput.fromObject = function fromObject(object) {
            if (object instanceof $root.permutive.SinkInput)
                return object;
            let message = new $root.permutive.SinkInput();
            if (object.dependency != null)
                message.dependency = String(object.dependency);
            if (object.name != null)
                message.name = String(object.name);
            if (object.raw != null) {
                if (typeof object.raw !== "object")
                    throw TypeError(".permutive.SinkInput.raw: object expected");
                message.raw = $root.permutive.RawFile.fromObject(object.raw);
            }
            if (object.zip != null) {
                if (typeof object.zip !== "object")
                    throw TypeError(".permutive.SinkInput.zip: object expected");
                message.zip = $root.permutive.ZipFile.fromObject(object.zip);
            }
            return message;
        };

        /**
         * Creates a plain object from a SinkInput message. Also converts values to other types if specified.
         * @function toObject
         * @memberof permutive.SinkInput
         * @static
         * @param {permutive.SinkInput} message SinkInput
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SinkInput.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.dependency = "";
                object.name = "";
            }
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                object.dependency = message.dependency;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.raw != null && message.hasOwnProperty("raw")) {
                object.raw = $root.permutive.RawFile.toObject(message.raw, options);
                if (options.oneofs)
                    object.file = "raw";
            }
            if (message.zip != null && message.hasOwnProperty("zip")) {
                object.zip = $root.permutive.ZipFile.toObject(message.zip, options);
                if (options.oneofs)
                    object.file = "zip";
            }
            return object;
        };

        /**
         * Converts this SinkInput to JSON.
         * @function toJSON
         * @memberof permutive.SinkInput
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SinkInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SinkInput
         * @function getTypeUrl
         * @memberof permutive.SinkInput
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SinkInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/permutive.SinkInput";
        };

        return SinkInput;
    })();

    permutive.PermutiveWorkerConfiguration = (function() {

        /**
         * Properties of a PermutiveWorkerConfiguration.
         * @memberof permutive
         * @interface IPermutiveWorkerConfiguration
         * @property {string|null} [credentialsDependency] PermutiveWorkerConfiguration credentialsDependency
         * @property {permutive.IImportRole|null} [importRole] PermutiveWorkerConfiguration importRole
         * @property {permutive.IExportRole|null} [exportRole] PermutiveWorkerConfiguration exportRole
         * @property {string|null} [importId] PermutiveWorkerConfiguration importId
         * @property {string|null} [segmentName] PermutiveWorkerConfiguration segmentName
         * @property {string|null} [segmentCode] PermutiveWorkerConfiguration segmentCode
         * @property {boolean|null} [inputHasHeaders] PermutiveWorkerConfiguration inputHasHeaders
         */

        /**
         * Constructs a new PermutiveWorkerConfiguration.
         * @memberof permutive
         * @classdesc Represents a PermutiveWorkerConfiguration.
         * @implements IPermutiveWorkerConfiguration
         * @constructor
         * @param {permutive.IPermutiveWorkerConfiguration=} [properties] Properties to set
         */
        function PermutiveWorkerConfiguration(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PermutiveWorkerConfiguration credentialsDependency.
         * @member {string} credentialsDependency
         * @memberof permutive.PermutiveWorkerConfiguration
         * @instance
         */
        PermutiveWorkerConfiguration.prototype.credentialsDependency = "";

        /**
         * PermutiveWorkerConfiguration importRole.
         * @member {permutive.IImportRole|null|undefined} importRole
         * @memberof permutive.PermutiveWorkerConfiguration
         * @instance
         */
        PermutiveWorkerConfiguration.prototype.importRole = null;

        /**
         * PermutiveWorkerConfiguration exportRole.
         * @member {permutive.IExportRole|null|undefined} exportRole
         * @memberof permutive.PermutiveWorkerConfiguration
         * @instance
         */
        PermutiveWorkerConfiguration.prototype.exportRole = null;

        /**
         * PermutiveWorkerConfiguration importId.
         * @member {string} importId
         * @memberof permutive.PermutiveWorkerConfiguration
         * @instance
         */
        PermutiveWorkerConfiguration.prototype.importId = "";

        /**
         * PermutiveWorkerConfiguration segmentName.
         * @member {string} segmentName
         * @memberof permutive.PermutiveWorkerConfiguration
         * @instance
         */
        PermutiveWorkerConfiguration.prototype.segmentName = "";

        /**
         * PermutiveWorkerConfiguration segmentCode.
         * @member {string} segmentCode
         * @memberof permutive.PermutiveWorkerConfiguration
         * @instance
         */
        PermutiveWorkerConfiguration.prototype.segmentCode = "";

        /**
         * PermutiveWorkerConfiguration inputHasHeaders.
         * @member {boolean} inputHasHeaders
         * @memberof permutive.PermutiveWorkerConfiguration
         * @instance
         */
        PermutiveWorkerConfiguration.prototype.inputHasHeaders = false;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * PermutiveWorkerConfiguration role.
         * @member {"importRole"|"exportRole"|undefined} role
         * @memberof permutive.PermutiveWorkerConfiguration
         * @instance
         */
        Object.defineProperty(PermutiveWorkerConfiguration.prototype, "role", {
            get: $util.oneOfGetter($oneOfFields = ["importRole", "exportRole"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new PermutiveWorkerConfiguration instance using the specified properties.
         * @function create
         * @memberof permutive.PermutiveWorkerConfiguration
         * @static
         * @param {permutive.IPermutiveWorkerConfiguration=} [properties] Properties to set
         * @returns {permutive.PermutiveWorkerConfiguration} PermutiveWorkerConfiguration instance
         */
        PermutiveWorkerConfiguration.create = function create(properties) {
            return new PermutiveWorkerConfiguration(properties);
        };

        /**
         * Encodes the specified PermutiveWorkerConfiguration message. Does not implicitly {@link permutive.PermutiveWorkerConfiguration.verify|verify} messages.
         * @function encode
         * @memberof permutive.PermutiveWorkerConfiguration
         * @static
         * @param {permutive.IPermutiveWorkerConfiguration} message PermutiveWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PermutiveWorkerConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.credentialsDependency != null && Object.hasOwnProperty.call(message, "credentialsDependency"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.credentialsDependency);
            if (message.importRole != null && Object.hasOwnProperty.call(message, "importRole"))
                $root.permutive.ImportRole.encode(message.importRole, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.exportRole != null && Object.hasOwnProperty.call(message, "exportRole"))
                $root.permutive.ExportRole.encode(message.exportRole, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.importId != null && Object.hasOwnProperty.call(message, "importId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.importId);
            if (message.segmentName != null && Object.hasOwnProperty.call(message, "segmentName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.segmentName);
            if (message.segmentCode != null && Object.hasOwnProperty.call(message, "segmentCode"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.segmentCode);
            if (message.inputHasHeaders != null && Object.hasOwnProperty.call(message, "inputHasHeaders"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.inputHasHeaders);
            return writer;
        };

        /**
         * Encodes the specified PermutiveWorkerConfiguration message, length delimited. Does not implicitly {@link permutive.PermutiveWorkerConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof permutive.PermutiveWorkerConfiguration
         * @static
         * @param {permutive.IPermutiveWorkerConfiguration} message PermutiveWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PermutiveWorkerConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PermutiveWorkerConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof permutive.PermutiveWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {permutive.PermutiveWorkerConfiguration} PermutiveWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PermutiveWorkerConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.permutive.PermutiveWorkerConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.credentialsDependency = reader.string();
                        break;
                    }
                case 2: {
                        message.importRole = $root.permutive.ImportRole.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.exportRole = $root.permutive.ExportRole.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.importId = reader.string();
                        break;
                    }
                case 5: {
                        message.segmentName = reader.string();
                        break;
                    }
                case 6: {
                        message.segmentCode = reader.string();
                        break;
                    }
                case 7: {
                        message.inputHasHeaders = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PermutiveWorkerConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof permutive.PermutiveWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {permutive.PermutiveWorkerConfiguration} PermutiveWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PermutiveWorkerConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PermutiveWorkerConfiguration message.
         * @function verify
         * @memberof permutive.PermutiveWorkerConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PermutiveWorkerConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.credentialsDependency != null && message.hasOwnProperty("credentialsDependency"))
                if (!$util.isString(message.credentialsDependency))
                    return "credentialsDependency: string expected";
            if (message.importRole != null && message.hasOwnProperty("importRole")) {
                properties.role = 1;
                {
                    let error = $root.permutive.ImportRole.verify(message.importRole);
                    if (error)
                        return "importRole." + error;
                }
            }
            if (message.exportRole != null && message.hasOwnProperty("exportRole")) {
                if (properties.role === 1)
                    return "role: multiple values";
                properties.role = 1;
                {
                    let error = $root.permutive.ExportRole.verify(message.exportRole);
                    if (error)
                        return "exportRole." + error;
                }
            }
            if (message.importId != null && message.hasOwnProperty("importId"))
                if (!$util.isString(message.importId))
                    return "importId: string expected";
            if (message.segmentName != null && message.hasOwnProperty("segmentName"))
                if (!$util.isString(message.segmentName))
                    return "segmentName: string expected";
            if (message.segmentCode != null && message.hasOwnProperty("segmentCode"))
                if (!$util.isString(message.segmentCode))
                    return "segmentCode: string expected";
            if (message.inputHasHeaders != null && message.hasOwnProperty("inputHasHeaders"))
                if (typeof message.inputHasHeaders !== "boolean")
                    return "inputHasHeaders: boolean expected";
            return null;
        };

        /**
         * Creates a PermutiveWorkerConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof permutive.PermutiveWorkerConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {permutive.PermutiveWorkerConfiguration} PermutiveWorkerConfiguration
         */
        PermutiveWorkerConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.permutive.PermutiveWorkerConfiguration)
                return object;
            let message = new $root.permutive.PermutiveWorkerConfiguration();
            if (object.credentialsDependency != null)
                message.credentialsDependency = String(object.credentialsDependency);
            if (object.importRole != null) {
                if (typeof object.importRole !== "object")
                    throw TypeError(".permutive.PermutiveWorkerConfiguration.importRole: object expected");
                message.importRole = $root.permutive.ImportRole.fromObject(object.importRole);
            }
            if (object.exportRole != null) {
                if (typeof object.exportRole !== "object")
                    throw TypeError(".permutive.PermutiveWorkerConfiguration.exportRole: object expected");
                message.exportRole = $root.permutive.ExportRole.fromObject(object.exportRole);
            }
            if (object.importId != null)
                message.importId = String(object.importId);
            if (object.segmentName != null)
                message.segmentName = String(object.segmentName);
            if (object.segmentCode != null)
                message.segmentCode = String(object.segmentCode);
            if (object.inputHasHeaders != null)
                message.inputHasHeaders = Boolean(object.inputHasHeaders);
            return message;
        };

        /**
         * Creates a plain object from a PermutiveWorkerConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof permutive.PermutiveWorkerConfiguration
         * @static
         * @param {permutive.PermutiveWorkerConfiguration} message PermutiveWorkerConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PermutiveWorkerConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.credentialsDependency = "";
                object.importId = "";
                object.segmentName = "";
                object.segmentCode = "";
                object.inputHasHeaders = false;
            }
            if (message.credentialsDependency != null && message.hasOwnProperty("credentialsDependency"))
                object.credentialsDependency = message.credentialsDependency;
            if (message.importRole != null && message.hasOwnProperty("importRole")) {
                object.importRole = $root.permutive.ImportRole.toObject(message.importRole, options);
                if (options.oneofs)
                    object.role = "importRole";
            }
            if (message.exportRole != null && message.hasOwnProperty("exportRole")) {
                object.exportRole = $root.permutive.ExportRole.toObject(message.exportRole, options);
                if (options.oneofs)
                    object.role = "exportRole";
            }
            if (message.importId != null && message.hasOwnProperty("importId"))
                object.importId = message.importId;
            if (message.segmentName != null && message.hasOwnProperty("segmentName"))
                object.segmentName = message.segmentName;
            if (message.segmentCode != null && message.hasOwnProperty("segmentCode"))
                object.segmentCode = message.segmentCode;
            if (message.inputHasHeaders != null && message.hasOwnProperty("inputHasHeaders"))
                object.inputHasHeaders = message.inputHasHeaders;
            return object;
        };

        /**
         * Converts this PermutiveWorkerConfiguration to JSON.
         * @function toJSON
         * @memberof permutive.PermutiveWorkerConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PermutiveWorkerConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PermutiveWorkerConfiguration
         * @function getTypeUrl
         * @memberof permutive.PermutiveWorkerConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PermutiveWorkerConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/permutive.PermutiveWorkerConfiguration";
        };

        return PermutiveWorkerConfiguration;
    })();

    return permutive;
})();

export const salesforce = $root.salesforce = (() => {

    /**
     * Namespace salesforce.
     * @exports salesforce
     * @namespace
     */
    const salesforce = {};

    salesforce.Core = (function() {

        /**
         * Properties of a Core.
         * @memberof salesforce
         * @interface ICore
         */

        /**
         * Constructs a new Core.
         * @memberof salesforce
         * @classdesc Represents a Core.
         * @implements ICore
         * @constructor
         * @param {salesforce.ICore=} [properties] Properties to set
         */
        function Core(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new Core instance using the specified properties.
         * @function create
         * @memberof salesforce.Core
         * @static
         * @param {salesforce.ICore=} [properties] Properties to set
         * @returns {salesforce.Core} Core instance
         */
        Core.create = function create(properties) {
            return new Core(properties);
        };

        /**
         * Encodes the specified Core message. Does not implicitly {@link salesforce.Core.verify|verify} messages.
         * @function encode
         * @memberof salesforce.Core
         * @static
         * @param {salesforce.ICore} message Core message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Core.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified Core message, length delimited. Does not implicitly {@link salesforce.Core.verify|verify} messages.
         * @function encodeDelimited
         * @memberof salesforce.Core
         * @static
         * @param {salesforce.ICore} message Core message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Core.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Core message from the specified reader or buffer.
         * @function decode
         * @memberof salesforce.Core
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {salesforce.Core} Core
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Core.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.salesforce.Core();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Core message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof salesforce.Core
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {salesforce.Core} Core
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Core.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Core message.
         * @function verify
         * @memberof salesforce.Core
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Core.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a Core message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof salesforce.Core
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {salesforce.Core} Core
         */
        Core.fromObject = function fromObject(object) {
            if (object instanceof $root.salesforce.Core)
                return object;
            return new $root.salesforce.Core();
        };

        /**
         * Creates a plain object from a Core message. Also converts values to other types if specified.
         * @function toObject
         * @memberof salesforce.Core
         * @static
         * @param {salesforce.Core} message Core
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Core.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this Core to JSON.
         * @function toJSON
         * @memberof salesforce.Core
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Core.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Core
         * @function getTypeUrl
         * @memberof salesforce.Core
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Core.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/salesforce.Core";
        };

        return Core;
    })();

    salesforce.MarketingCloud = (function() {

        /**
         * Properties of a MarketingCloud.
         * @memberof salesforce
         * @interface IMarketingCloud
         */

        /**
         * Constructs a new MarketingCloud.
         * @memberof salesforce
         * @classdesc Represents a MarketingCloud.
         * @implements IMarketingCloud
         * @constructor
         * @param {salesforce.IMarketingCloud=} [properties] Properties to set
         */
        function MarketingCloud(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new MarketingCloud instance using the specified properties.
         * @function create
         * @memberof salesforce.MarketingCloud
         * @static
         * @param {salesforce.IMarketingCloud=} [properties] Properties to set
         * @returns {salesforce.MarketingCloud} MarketingCloud instance
         */
        MarketingCloud.create = function create(properties) {
            return new MarketingCloud(properties);
        };

        /**
         * Encodes the specified MarketingCloud message. Does not implicitly {@link salesforce.MarketingCloud.verify|verify} messages.
         * @function encode
         * @memberof salesforce.MarketingCloud
         * @static
         * @param {salesforce.IMarketingCloud} message MarketingCloud message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MarketingCloud.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified MarketingCloud message, length delimited. Does not implicitly {@link salesforce.MarketingCloud.verify|verify} messages.
         * @function encodeDelimited
         * @memberof salesforce.MarketingCloud
         * @static
         * @param {salesforce.IMarketingCloud} message MarketingCloud message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MarketingCloud.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MarketingCloud message from the specified reader or buffer.
         * @function decode
         * @memberof salesforce.MarketingCloud
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {salesforce.MarketingCloud} MarketingCloud
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MarketingCloud.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.salesforce.MarketingCloud();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MarketingCloud message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof salesforce.MarketingCloud
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {salesforce.MarketingCloud} MarketingCloud
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MarketingCloud.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MarketingCloud message.
         * @function verify
         * @memberof salesforce.MarketingCloud
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MarketingCloud.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a MarketingCloud message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof salesforce.MarketingCloud
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {salesforce.MarketingCloud} MarketingCloud
         */
        MarketingCloud.fromObject = function fromObject(object) {
            if (object instanceof $root.salesforce.MarketingCloud)
                return object;
            return new $root.salesforce.MarketingCloud();
        };

        /**
         * Creates a plain object from a MarketingCloud message. Also converts values to other types if specified.
         * @function toObject
         * @memberof salesforce.MarketingCloud
         * @static
         * @param {salesforce.MarketingCloud} message MarketingCloud
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MarketingCloud.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this MarketingCloud to JSON.
         * @function toJSON
         * @memberof salesforce.MarketingCloud
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MarketingCloud.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MarketingCloud
         * @function getTypeUrl
         * @memberof salesforce.MarketingCloud
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MarketingCloud.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/salesforce.MarketingCloud";
        };

        return MarketingCloud;
    })();

    salesforce.ImportRole = (function() {

        /**
         * Properties of an ImportRole.
         * @memberof salesforce
         * @interface IImportRole
         * @property {salesforce.ICore|null} [core] ImportRole core
         * @property {salesforce.IMarketingCloud|null} [marketingCloud] ImportRole marketingCloud
         */

        /**
         * Constructs a new ImportRole.
         * @memberof salesforce
         * @classdesc Represents an ImportRole.
         * @implements IImportRole
         * @constructor
         * @param {salesforce.IImportRole=} [properties] Properties to set
         */
        function ImportRole(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImportRole core.
         * @member {salesforce.ICore|null|undefined} core
         * @memberof salesforce.ImportRole
         * @instance
         */
        ImportRole.prototype.core = null;

        /**
         * ImportRole marketingCloud.
         * @member {salesforce.IMarketingCloud|null|undefined} marketingCloud
         * @memberof salesforce.ImportRole
         * @instance
         */
        ImportRole.prototype.marketingCloud = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ImportRole productType.
         * @member {"core"|"marketingCloud"|undefined} productType
         * @memberof salesforce.ImportRole
         * @instance
         */
        Object.defineProperty(ImportRole.prototype, "productType", {
            get: $util.oneOfGetter($oneOfFields = ["core", "marketingCloud"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ImportRole instance using the specified properties.
         * @function create
         * @memberof salesforce.ImportRole
         * @static
         * @param {salesforce.IImportRole=} [properties] Properties to set
         * @returns {salesforce.ImportRole} ImportRole instance
         */
        ImportRole.create = function create(properties) {
            return new ImportRole(properties);
        };

        /**
         * Encodes the specified ImportRole message. Does not implicitly {@link salesforce.ImportRole.verify|verify} messages.
         * @function encode
         * @memberof salesforce.ImportRole
         * @static
         * @param {salesforce.IImportRole} message ImportRole message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImportRole.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.core != null && Object.hasOwnProperty.call(message, "core"))
                $root.salesforce.Core.encode(message.core, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.marketingCloud != null && Object.hasOwnProperty.call(message, "marketingCloud"))
                $root.salesforce.MarketingCloud.encode(message.marketingCloud, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ImportRole message, length delimited. Does not implicitly {@link salesforce.ImportRole.verify|verify} messages.
         * @function encodeDelimited
         * @memberof salesforce.ImportRole
         * @static
         * @param {salesforce.IImportRole} message ImportRole message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImportRole.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ImportRole message from the specified reader or buffer.
         * @function decode
         * @memberof salesforce.ImportRole
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {salesforce.ImportRole} ImportRole
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImportRole.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.salesforce.ImportRole();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.core = $root.salesforce.Core.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.marketingCloud = $root.salesforce.MarketingCloud.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ImportRole message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof salesforce.ImportRole
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {salesforce.ImportRole} ImportRole
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImportRole.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ImportRole message.
         * @function verify
         * @memberof salesforce.ImportRole
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ImportRole.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.core != null && message.hasOwnProperty("core")) {
                properties.productType = 1;
                {
                    let error = $root.salesforce.Core.verify(message.core);
                    if (error)
                        return "core." + error;
                }
            }
            if (message.marketingCloud != null && message.hasOwnProperty("marketingCloud")) {
                if (properties.productType === 1)
                    return "productType: multiple values";
                properties.productType = 1;
                {
                    let error = $root.salesforce.MarketingCloud.verify(message.marketingCloud);
                    if (error)
                        return "marketingCloud." + error;
                }
            }
            return null;
        };

        /**
         * Creates an ImportRole message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof salesforce.ImportRole
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {salesforce.ImportRole} ImportRole
         */
        ImportRole.fromObject = function fromObject(object) {
            if (object instanceof $root.salesforce.ImportRole)
                return object;
            let message = new $root.salesforce.ImportRole();
            if (object.core != null) {
                if (typeof object.core !== "object")
                    throw TypeError(".salesforce.ImportRole.core: object expected");
                message.core = $root.salesforce.Core.fromObject(object.core);
            }
            if (object.marketingCloud != null) {
                if (typeof object.marketingCloud !== "object")
                    throw TypeError(".salesforce.ImportRole.marketingCloud: object expected");
                message.marketingCloud = $root.salesforce.MarketingCloud.fromObject(object.marketingCloud);
            }
            return message;
        };

        /**
         * Creates a plain object from an ImportRole message. Also converts values to other types if specified.
         * @function toObject
         * @memberof salesforce.ImportRole
         * @static
         * @param {salesforce.ImportRole} message ImportRole
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImportRole.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.core != null && message.hasOwnProperty("core")) {
                object.core = $root.salesforce.Core.toObject(message.core, options);
                if (options.oneofs)
                    object.productType = "core";
            }
            if (message.marketingCloud != null && message.hasOwnProperty("marketingCloud")) {
                object.marketingCloud = $root.salesforce.MarketingCloud.toObject(message.marketingCloud, options);
                if (options.oneofs)
                    object.productType = "marketingCloud";
            }
            return object;
        };

        /**
         * Converts this ImportRole to JSON.
         * @function toJSON
         * @memberof salesforce.ImportRole
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImportRole.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ImportRole
         * @function getTypeUrl
         * @memberof salesforce.ImportRole
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ImportRole.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/salesforce.ImportRole";
        };

        return ImportRole;
    })();

    salesforce.ExportRole = (function() {

        /**
         * Properties of an ExportRole.
         * @memberof salesforce
         * @interface IExportRole
         * @property {salesforce.ISinkInput|null} [input] ExportRole input
         */

        /**
         * Constructs a new ExportRole.
         * @memberof salesforce
         * @classdesc Represents an ExportRole.
         * @implements IExportRole
         * @constructor
         * @param {salesforce.IExportRole=} [properties] Properties to set
         */
        function ExportRole(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExportRole input.
         * @member {salesforce.ISinkInput|null|undefined} input
         * @memberof salesforce.ExportRole
         * @instance
         */
        ExportRole.prototype.input = null;

        /**
         * Creates a new ExportRole instance using the specified properties.
         * @function create
         * @memberof salesforce.ExportRole
         * @static
         * @param {salesforce.IExportRole=} [properties] Properties to set
         * @returns {salesforce.ExportRole} ExportRole instance
         */
        ExportRole.create = function create(properties) {
            return new ExportRole(properties);
        };

        /**
         * Encodes the specified ExportRole message. Does not implicitly {@link salesforce.ExportRole.verify|verify} messages.
         * @function encode
         * @memberof salesforce.ExportRole
         * @static
         * @param {salesforce.IExportRole} message ExportRole message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExportRole.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.input != null && Object.hasOwnProperty.call(message, "input"))
                $root.salesforce.SinkInput.encode(message.input, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ExportRole message, length delimited. Does not implicitly {@link salesforce.ExportRole.verify|verify} messages.
         * @function encodeDelimited
         * @memberof salesforce.ExportRole
         * @static
         * @param {salesforce.IExportRole} message ExportRole message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExportRole.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExportRole message from the specified reader or buffer.
         * @function decode
         * @memberof salesforce.ExportRole
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {salesforce.ExportRole} ExportRole
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExportRole.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.salesforce.ExportRole();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.input = $root.salesforce.SinkInput.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExportRole message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof salesforce.ExportRole
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {salesforce.ExportRole} ExportRole
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExportRole.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExportRole message.
         * @function verify
         * @memberof salesforce.ExportRole
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExportRole.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.input != null && message.hasOwnProperty("input")) {
                let error = $root.salesforce.SinkInput.verify(message.input);
                if (error)
                    return "input." + error;
            }
            return null;
        };

        /**
         * Creates an ExportRole message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof salesforce.ExportRole
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {salesforce.ExportRole} ExportRole
         */
        ExportRole.fromObject = function fromObject(object) {
            if (object instanceof $root.salesforce.ExportRole)
                return object;
            let message = new $root.salesforce.ExportRole();
            if (object.input != null) {
                if (typeof object.input !== "object")
                    throw TypeError(".salesforce.ExportRole.input: object expected");
                message.input = $root.salesforce.SinkInput.fromObject(object.input);
            }
            return message;
        };

        /**
         * Creates a plain object from an ExportRole message. Also converts values to other types if specified.
         * @function toObject
         * @memberof salesforce.ExportRole
         * @static
         * @param {salesforce.ExportRole} message ExportRole
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExportRole.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.input = null;
            if (message.input != null && message.hasOwnProperty("input"))
                object.input = $root.salesforce.SinkInput.toObject(message.input, options);
            return object;
        };

        /**
         * Converts this ExportRole to JSON.
         * @function toJSON
         * @memberof salesforce.ExportRole
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExportRole.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ExportRole
         * @function getTypeUrl
         * @memberof salesforce.ExportRole
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExportRole.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/salesforce.ExportRole";
        };

        return ExportRole;
    })();

    salesforce.SingleFile = (function() {

        /**
         * Properties of a SingleFile.
         * @memberof salesforce
         * @interface ISingleFile
         * @property {string|null} [name] SingleFile name
         */

        /**
         * Constructs a new SingleFile.
         * @memberof salesforce
         * @classdesc Represents a SingleFile.
         * @implements ISingleFile
         * @constructor
         * @param {salesforce.ISingleFile=} [properties] Properties to set
         */
        function SingleFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SingleFile name.
         * @member {string} name
         * @memberof salesforce.SingleFile
         * @instance
         */
        SingleFile.prototype.name = "";

        /**
         * Creates a new SingleFile instance using the specified properties.
         * @function create
         * @memberof salesforce.SingleFile
         * @static
         * @param {salesforce.ISingleFile=} [properties] Properties to set
         * @returns {salesforce.SingleFile} SingleFile instance
         */
        SingleFile.create = function create(properties) {
            return new SingleFile(properties);
        };

        /**
         * Encodes the specified SingleFile message. Does not implicitly {@link salesforce.SingleFile.verify|verify} messages.
         * @function encode
         * @memberof salesforce.SingleFile
         * @static
         * @param {salesforce.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified SingleFile message, length delimited. Does not implicitly {@link salesforce.SingleFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof salesforce.SingleFile
         * @static
         * @param {salesforce.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer.
         * @function decode
         * @memberof salesforce.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {salesforce.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.salesforce.SingleFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof salesforce.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {salesforce.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SingleFile message.
         * @function verify
         * @memberof salesforce.SingleFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SingleFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a SingleFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof salesforce.SingleFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {salesforce.SingleFile} SingleFile
         */
        SingleFile.fromObject = function fromObject(object) {
            if (object instanceof $root.salesforce.SingleFile)
                return object;
            let message = new $root.salesforce.SingleFile();
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a SingleFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof salesforce.SingleFile
         * @static
         * @param {salesforce.SingleFile} message SingleFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SingleFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this SingleFile to JSON.
         * @function toJSON
         * @memberof salesforce.SingleFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SingleFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SingleFile
         * @function getTypeUrl
         * @memberof salesforce.SingleFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SingleFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/salesforce.SingleFile";
        };

        return SingleFile;
    })();

    salesforce.RawFile = (function() {

        /**
         * Properties of a RawFile.
         * @memberof salesforce
         * @interface IRawFile
         */

        /**
         * Constructs a new RawFile.
         * @memberof salesforce
         * @classdesc Represents a RawFile.
         * @implements IRawFile
         * @constructor
         * @param {salesforce.IRawFile=} [properties] Properties to set
         */
        function RawFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RawFile instance using the specified properties.
         * @function create
         * @memberof salesforce.RawFile
         * @static
         * @param {salesforce.IRawFile=} [properties] Properties to set
         * @returns {salesforce.RawFile} RawFile instance
         */
        RawFile.create = function create(properties) {
            return new RawFile(properties);
        };

        /**
         * Encodes the specified RawFile message. Does not implicitly {@link salesforce.RawFile.verify|verify} messages.
         * @function encode
         * @memberof salesforce.RawFile
         * @static
         * @param {salesforce.IRawFile} message RawFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RawFile message, length delimited. Does not implicitly {@link salesforce.RawFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof salesforce.RawFile
         * @static
         * @param {salesforce.IRawFile} message RawFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RawFile message from the specified reader or buffer.
         * @function decode
         * @memberof salesforce.RawFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {salesforce.RawFile} RawFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.salesforce.RawFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RawFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof salesforce.RawFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {salesforce.RawFile} RawFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RawFile message.
         * @function verify
         * @memberof salesforce.RawFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RawFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RawFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof salesforce.RawFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {salesforce.RawFile} RawFile
         */
        RawFile.fromObject = function fromObject(object) {
            if (object instanceof $root.salesforce.RawFile)
                return object;
            return new $root.salesforce.RawFile();
        };

        /**
         * Creates a plain object from a RawFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof salesforce.RawFile
         * @static
         * @param {salesforce.RawFile} message RawFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RawFile.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RawFile to JSON.
         * @function toJSON
         * @memberof salesforce.RawFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RawFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RawFile
         * @function getTypeUrl
         * @memberof salesforce.RawFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RawFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/salesforce.RawFile";
        };

        return RawFile;
    })();

    salesforce.ZipFile = (function() {

        /**
         * Properties of a ZipFile.
         * @memberof salesforce
         * @interface IZipFile
         * @property {salesforce.ISingleFile|null} [singleFile] ZipFile singleFile
         */

        /**
         * Constructs a new ZipFile.
         * @memberof salesforce
         * @classdesc Represents a ZipFile.
         * @implements IZipFile
         * @constructor
         * @param {salesforce.IZipFile=} [properties] Properties to set
         */
        function ZipFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ZipFile singleFile.
         * @member {salesforce.ISingleFile|null|undefined} singleFile
         * @memberof salesforce.ZipFile
         * @instance
         */
        ZipFile.prototype.singleFile = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ZipFile selection.
         * @member {"singleFile"|undefined} selection
         * @memberof salesforce.ZipFile
         * @instance
         */
        Object.defineProperty(ZipFile.prototype, "selection", {
            get: $util.oneOfGetter($oneOfFields = ["singleFile"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ZipFile instance using the specified properties.
         * @function create
         * @memberof salesforce.ZipFile
         * @static
         * @param {salesforce.IZipFile=} [properties] Properties to set
         * @returns {salesforce.ZipFile} ZipFile instance
         */
        ZipFile.create = function create(properties) {
            return new ZipFile(properties);
        };

        /**
         * Encodes the specified ZipFile message. Does not implicitly {@link salesforce.ZipFile.verify|verify} messages.
         * @function encode
         * @memberof salesforce.ZipFile
         * @static
         * @param {salesforce.IZipFile} message ZipFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.singleFile != null && Object.hasOwnProperty.call(message, "singleFile"))
                $root.salesforce.SingleFile.encode(message.singleFile, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ZipFile message, length delimited. Does not implicitly {@link salesforce.ZipFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof salesforce.ZipFile
         * @static
         * @param {salesforce.IZipFile} message ZipFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ZipFile message from the specified reader or buffer.
         * @function decode
         * @memberof salesforce.ZipFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {salesforce.ZipFile} ZipFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.salesforce.ZipFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.singleFile = $root.salesforce.SingleFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ZipFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof salesforce.ZipFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {salesforce.ZipFile} ZipFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ZipFile message.
         * @function verify
         * @memberof salesforce.ZipFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ZipFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.singleFile != null && message.hasOwnProperty("singleFile")) {
                properties.selection = 1;
                {
                    let error = $root.salesforce.SingleFile.verify(message.singleFile);
                    if (error)
                        return "singleFile." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ZipFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof salesforce.ZipFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {salesforce.ZipFile} ZipFile
         */
        ZipFile.fromObject = function fromObject(object) {
            if (object instanceof $root.salesforce.ZipFile)
                return object;
            let message = new $root.salesforce.ZipFile();
            if (object.singleFile != null) {
                if (typeof object.singleFile !== "object")
                    throw TypeError(".salesforce.ZipFile.singleFile: object expected");
                message.singleFile = $root.salesforce.SingleFile.fromObject(object.singleFile);
            }
            return message;
        };

        /**
         * Creates a plain object from a ZipFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof salesforce.ZipFile
         * @static
         * @param {salesforce.ZipFile} message ZipFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ZipFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.singleFile != null && message.hasOwnProperty("singleFile")) {
                object.singleFile = $root.salesforce.SingleFile.toObject(message.singleFile, options);
                if (options.oneofs)
                    object.selection = "singleFile";
            }
            return object;
        };

        /**
         * Converts this ZipFile to JSON.
         * @function toJSON
         * @memberof salesforce.ZipFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ZipFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ZipFile
         * @function getTypeUrl
         * @memberof salesforce.ZipFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ZipFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/salesforce.ZipFile";
        };

        return ZipFile;
    })();

    salesforce.SinkInput = (function() {

        /**
         * Properties of a SinkInput.
         * @memberof salesforce
         * @interface ISinkInput
         * @property {string|null} [dependency] SinkInput dependency
         * @property {string|null} [name] SinkInput name
         * @property {salesforce.IRawFile|null} [raw] SinkInput raw
         * @property {salesforce.IZipFile|null} [zip] SinkInput zip
         */

        /**
         * Constructs a new SinkInput.
         * @memberof salesforce
         * @classdesc Represents a SinkInput.
         * @implements ISinkInput
         * @constructor
         * @param {salesforce.ISinkInput=} [properties] Properties to set
         */
        function SinkInput(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SinkInput dependency.
         * @member {string} dependency
         * @memberof salesforce.SinkInput
         * @instance
         */
        SinkInput.prototype.dependency = "";

        /**
         * SinkInput name.
         * @member {string} name
         * @memberof salesforce.SinkInput
         * @instance
         */
        SinkInput.prototype.name = "";

        /**
         * SinkInput raw.
         * @member {salesforce.IRawFile|null|undefined} raw
         * @memberof salesforce.SinkInput
         * @instance
         */
        SinkInput.prototype.raw = null;

        /**
         * SinkInput zip.
         * @member {salesforce.IZipFile|null|undefined} zip
         * @memberof salesforce.SinkInput
         * @instance
         */
        SinkInput.prototype.zip = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SinkInput file.
         * @member {"raw"|"zip"|undefined} file
         * @memberof salesforce.SinkInput
         * @instance
         */
        Object.defineProperty(SinkInput.prototype, "file", {
            get: $util.oneOfGetter($oneOfFields = ["raw", "zip"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SinkInput instance using the specified properties.
         * @function create
         * @memberof salesforce.SinkInput
         * @static
         * @param {salesforce.ISinkInput=} [properties] Properties to set
         * @returns {salesforce.SinkInput} SinkInput instance
         */
        SinkInput.create = function create(properties) {
            return new SinkInput(properties);
        };

        /**
         * Encodes the specified SinkInput message. Does not implicitly {@link salesforce.SinkInput.verify|verify} messages.
         * @function encode
         * @memberof salesforce.SinkInput
         * @static
         * @param {salesforce.ISinkInput} message SinkInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SinkInput.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dependency != null && Object.hasOwnProperty.call(message, "dependency"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dependency);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.raw != null && Object.hasOwnProperty.call(message, "raw"))
                $root.salesforce.RawFile.encode(message.raw, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.zip != null && Object.hasOwnProperty.call(message, "zip"))
                $root.salesforce.ZipFile.encode(message.zip, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SinkInput message, length delimited. Does not implicitly {@link salesforce.SinkInput.verify|verify} messages.
         * @function encodeDelimited
         * @memberof salesforce.SinkInput
         * @static
         * @param {salesforce.ISinkInput} message SinkInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SinkInput.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SinkInput message from the specified reader or buffer.
         * @function decode
         * @memberof salesforce.SinkInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {salesforce.SinkInput} SinkInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SinkInput.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.salesforce.SinkInput();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dependency = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.raw = $root.salesforce.RawFile.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.zip = $root.salesforce.ZipFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SinkInput message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof salesforce.SinkInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {salesforce.SinkInput} SinkInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SinkInput.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SinkInput message.
         * @function verify
         * @memberof salesforce.SinkInput
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SinkInput.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                if (!$util.isString(message.dependency))
                    return "dependency: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.raw != null && message.hasOwnProperty("raw")) {
                properties.file = 1;
                {
                    let error = $root.salesforce.RawFile.verify(message.raw);
                    if (error)
                        return "raw." + error;
                }
            }
            if (message.zip != null && message.hasOwnProperty("zip")) {
                if (properties.file === 1)
                    return "file: multiple values";
                properties.file = 1;
                {
                    let error = $root.salesforce.ZipFile.verify(message.zip);
                    if (error)
                        return "zip." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SinkInput message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof salesforce.SinkInput
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {salesforce.SinkInput} SinkInput
         */
        SinkInput.fromObject = function fromObject(object) {
            if (object instanceof $root.salesforce.SinkInput)
                return object;
            let message = new $root.salesforce.SinkInput();
            if (object.dependency != null)
                message.dependency = String(object.dependency);
            if (object.name != null)
                message.name = String(object.name);
            if (object.raw != null) {
                if (typeof object.raw !== "object")
                    throw TypeError(".salesforce.SinkInput.raw: object expected");
                message.raw = $root.salesforce.RawFile.fromObject(object.raw);
            }
            if (object.zip != null) {
                if (typeof object.zip !== "object")
                    throw TypeError(".salesforce.SinkInput.zip: object expected");
                message.zip = $root.salesforce.ZipFile.fromObject(object.zip);
            }
            return message;
        };

        /**
         * Creates a plain object from a SinkInput message. Also converts values to other types if specified.
         * @function toObject
         * @memberof salesforce.SinkInput
         * @static
         * @param {salesforce.SinkInput} message SinkInput
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SinkInput.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.dependency = "";
                object.name = "";
            }
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                object.dependency = message.dependency;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.raw != null && message.hasOwnProperty("raw")) {
                object.raw = $root.salesforce.RawFile.toObject(message.raw, options);
                if (options.oneofs)
                    object.file = "raw";
            }
            if (message.zip != null && message.hasOwnProperty("zip")) {
                object.zip = $root.salesforce.ZipFile.toObject(message.zip, options);
                if (options.oneofs)
                    object.file = "zip";
            }
            return object;
        };

        /**
         * Converts this SinkInput to JSON.
         * @function toJSON
         * @memberof salesforce.SinkInput
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SinkInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SinkInput
         * @function getTypeUrl
         * @memberof salesforce.SinkInput
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SinkInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/salesforce.SinkInput";
        };

        return SinkInput;
    })();

    salesforce.SalesforceWorkerConfiguration = (function() {

        /**
         * Properties of a SalesforceWorkerConfiguration.
         * @memberof salesforce
         * @interface ISalesforceWorkerConfiguration
         * @property {string|null} [credentialsDependency] SalesforceWorkerConfiguration credentialsDependency
         * @property {salesforce.IImportRole|null} [importRole] SalesforceWorkerConfiguration importRole
         * @property {salesforce.IExportRole|null} [exportRole] SalesforceWorkerConfiguration exportRole
         * @property {string|null} [domainUrl] SalesforceWorkerConfiguration domainUrl
         * @property {string|null} [apiName] SalesforceWorkerConfiguration apiName
         */

        /**
         * Constructs a new SalesforceWorkerConfiguration.
         * @memberof salesforce
         * @classdesc Represents a SalesforceWorkerConfiguration.
         * @implements ISalesforceWorkerConfiguration
         * @constructor
         * @param {salesforce.ISalesforceWorkerConfiguration=} [properties] Properties to set
         */
        function SalesforceWorkerConfiguration(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SalesforceWorkerConfiguration credentialsDependency.
         * @member {string} credentialsDependency
         * @memberof salesforce.SalesforceWorkerConfiguration
         * @instance
         */
        SalesforceWorkerConfiguration.prototype.credentialsDependency = "";

        /**
         * SalesforceWorkerConfiguration importRole.
         * @member {salesforce.IImportRole|null|undefined} importRole
         * @memberof salesforce.SalesforceWorkerConfiguration
         * @instance
         */
        SalesforceWorkerConfiguration.prototype.importRole = null;

        /**
         * SalesforceWorkerConfiguration exportRole.
         * @member {salesforce.IExportRole|null|undefined} exportRole
         * @memberof salesforce.SalesforceWorkerConfiguration
         * @instance
         */
        SalesforceWorkerConfiguration.prototype.exportRole = null;

        /**
         * SalesforceWorkerConfiguration domainUrl.
         * @member {string} domainUrl
         * @memberof salesforce.SalesforceWorkerConfiguration
         * @instance
         */
        SalesforceWorkerConfiguration.prototype.domainUrl = "";

        /**
         * SalesforceWorkerConfiguration apiName.
         * @member {string} apiName
         * @memberof salesforce.SalesforceWorkerConfiguration
         * @instance
         */
        SalesforceWorkerConfiguration.prototype.apiName = "";

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SalesforceWorkerConfiguration role.
         * @member {"importRole"|"exportRole"|undefined} role
         * @memberof salesforce.SalesforceWorkerConfiguration
         * @instance
         */
        Object.defineProperty(SalesforceWorkerConfiguration.prototype, "role", {
            get: $util.oneOfGetter($oneOfFields = ["importRole", "exportRole"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SalesforceWorkerConfiguration instance using the specified properties.
         * @function create
         * @memberof salesforce.SalesforceWorkerConfiguration
         * @static
         * @param {salesforce.ISalesforceWorkerConfiguration=} [properties] Properties to set
         * @returns {salesforce.SalesforceWorkerConfiguration} SalesforceWorkerConfiguration instance
         */
        SalesforceWorkerConfiguration.create = function create(properties) {
            return new SalesforceWorkerConfiguration(properties);
        };

        /**
         * Encodes the specified SalesforceWorkerConfiguration message. Does not implicitly {@link salesforce.SalesforceWorkerConfiguration.verify|verify} messages.
         * @function encode
         * @memberof salesforce.SalesforceWorkerConfiguration
         * @static
         * @param {salesforce.ISalesforceWorkerConfiguration} message SalesforceWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SalesforceWorkerConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.credentialsDependency != null && Object.hasOwnProperty.call(message, "credentialsDependency"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.credentialsDependency);
            if (message.importRole != null && Object.hasOwnProperty.call(message, "importRole"))
                $root.salesforce.ImportRole.encode(message.importRole, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.exportRole != null && Object.hasOwnProperty.call(message, "exportRole"))
                $root.salesforce.ExportRole.encode(message.exportRole, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.domainUrl != null && Object.hasOwnProperty.call(message, "domainUrl"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.domainUrl);
            if (message.apiName != null && Object.hasOwnProperty.call(message, "apiName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.apiName);
            return writer;
        };

        /**
         * Encodes the specified SalesforceWorkerConfiguration message, length delimited. Does not implicitly {@link salesforce.SalesforceWorkerConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof salesforce.SalesforceWorkerConfiguration
         * @static
         * @param {salesforce.ISalesforceWorkerConfiguration} message SalesforceWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SalesforceWorkerConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SalesforceWorkerConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof salesforce.SalesforceWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {salesforce.SalesforceWorkerConfiguration} SalesforceWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SalesforceWorkerConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.salesforce.SalesforceWorkerConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.credentialsDependency = reader.string();
                        break;
                    }
                case 2: {
                        message.importRole = $root.salesforce.ImportRole.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.exportRole = $root.salesforce.ExportRole.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.domainUrl = reader.string();
                        break;
                    }
                case 5: {
                        message.apiName = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SalesforceWorkerConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof salesforce.SalesforceWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {salesforce.SalesforceWorkerConfiguration} SalesforceWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SalesforceWorkerConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SalesforceWorkerConfiguration message.
         * @function verify
         * @memberof salesforce.SalesforceWorkerConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SalesforceWorkerConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.credentialsDependency != null && message.hasOwnProperty("credentialsDependency"))
                if (!$util.isString(message.credentialsDependency))
                    return "credentialsDependency: string expected";
            if (message.importRole != null && message.hasOwnProperty("importRole")) {
                properties.role = 1;
                {
                    let error = $root.salesforce.ImportRole.verify(message.importRole);
                    if (error)
                        return "importRole." + error;
                }
            }
            if (message.exportRole != null && message.hasOwnProperty("exportRole")) {
                if (properties.role === 1)
                    return "role: multiple values";
                properties.role = 1;
                {
                    let error = $root.salesforce.ExportRole.verify(message.exportRole);
                    if (error)
                        return "exportRole." + error;
                }
            }
            if (message.domainUrl != null && message.hasOwnProperty("domainUrl"))
                if (!$util.isString(message.domainUrl))
                    return "domainUrl: string expected";
            if (message.apiName != null && message.hasOwnProperty("apiName"))
                if (!$util.isString(message.apiName))
                    return "apiName: string expected";
            return null;
        };

        /**
         * Creates a SalesforceWorkerConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof salesforce.SalesforceWorkerConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {salesforce.SalesforceWorkerConfiguration} SalesforceWorkerConfiguration
         */
        SalesforceWorkerConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.salesforce.SalesforceWorkerConfiguration)
                return object;
            let message = new $root.salesforce.SalesforceWorkerConfiguration();
            if (object.credentialsDependency != null)
                message.credentialsDependency = String(object.credentialsDependency);
            if (object.importRole != null) {
                if (typeof object.importRole !== "object")
                    throw TypeError(".salesforce.SalesforceWorkerConfiguration.importRole: object expected");
                message.importRole = $root.salesforce.ImportRole.fromObject(object.importRole);
            }
            if (object.exportRole != null) {
                if (typeof object.exportRole !== "object")
                    throw TypeError(".salesforce.SalesforceWorkerConfiguration.exportRole: object expected");
                message.exportRole = $root.salesforce.ExportRole.fromObject(object.exportRole);
            }
            if (object.domainUrl != null)
                message.domainUrl = String(object.domainUrl);
            if (object.apiName != null)
                message.apiName = String(object.apiName);
            return message;
        };

        /**
         * Creates a plain object from a SalesforceWorkerConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof salesforce.SalesforceWorkerConfiguration
         * @static
         * @param {salesforce.SalesforceWorkerConfiguration} message SalesforceWorkerConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SalesforceWorkerConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.credentialsDependency = "";
                object.domainUrl = "";
                object.apiName = "";
            }
            if (message.credentialsDependency != null && message.hasOwnProperty("credentialsDependency"))
                object.credentialsDependency = message.credentialsDependency;
            if (message.importRole != null && message.hasOwnProperty("importRole")) {
                object.importRole = $root.salesforce.ImportRole.toObject(message.importRole, options);
                if (options.oneofs)
                    object.role = "importRole";
            }
            if (message.exportRole != null && message.hasOwnProperty("exportRole")) {
                object.exportRole = $root.salesforce.ExportRole.toObject(message.exportRole, options);
                if (options.oneofs)
                    object.role = "exportRole";
            }
            if (message.domainUrl != null && message.hasOwnProperty("domainUrl"))
                object.domainUrl = message.domainUrl;
            if (message.apiName != null && message.hasOwnProperty("apiName"))
                object.apiName = message.apiName;
            return object;
        };

        /**
         * Converts this SalesforceWorkerConfiguration to JSON.
         * @function toJSON
         * @memberof salesforce.SalesforceWorkerConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SalesforceWorkerConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SalesforceWorkerConfiguration
         * @function getTypeUrl
         * @memberof salesforce.SalesforceWorkerConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SalesforceWorkerConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/salesforce.SalesforceWorkerConfiguration";
        };

        return SalesforceWorkerConfiguration;
    })();

    return salesforce;
})();

export const synth_data = $root.synth_data = (() => {

    /**
     * Namespace synth_data.
     * @exports synth_data
     * @namespace
     */
    const synth_data = {};

    synth_data.SyntheticDataConf = (function() {

        /**
         * Properties of a SyntheticDataConf.
         * @memberof synth_data
         * @interface ISyntheticDataConf
         * @property {Array.<synth_data.IColumn>|null} [columns] SyntheticDataConf columns
         * @property {boolean|null} [outputOriginalDataStats] SyntheticDataConf outputOriginalDataStats
         * @property {number|null} [epsilon] SyntheticDataConf epsilon
         */

        /**
         * Constructs a new SyntheticDataConf.
         * @memberof synth_data
         * @classdesc Represents a SyntheticDataConf.
         * @implements ISyntheticDataConf
         * @constructor
         * @param {synth_data.ISyntheticDataConf=} [properties] Properties to set
         */
        function SyntheticDataConf(properties) {
            this.columns = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SyntheticDataConf columns.
         * @member {Array.<synth_data.IColumn>} columns
         * @memberof synth_data.SyntheticDataConf
         * @instance
         */
        SyntheticDataConf.prototype.columns = $util.emptyArray;

        /**
         * SyntheticDataConf outputOriginalDataStats.
         * @member {boolean} outputOriginalDataStats
         * @memberof synth_data.SyntheticDataConf
         * @instance
         */
        SyntheticDataConf.prototype.outputOriginalDataStats = false;

        /**
         * SyntheticDataConf epsilon.
         * @member {number} epsilon
         * @memberof synth_data.SyntheticDataConf
         * @instance
         */
        SyntheticDataConf.prototype.epsilon = 0;

        /**
         * Creates a new SyntheticDataConf instance using the specified properties.
         * @function create
         * @memberof synth_data.SyntheticDataConf
         * @static
         * @param {synth_data.ISyntheticDataConf=} [properties] Properties to set
         * @returns {synth_data.SyntheticDataConf} SyntheticDataConf instance
         */
        SyntheticDataConf.create = function create(properties) {
            return new SyntheticDataConf(properties);
        };

        /**
         * Encodes the specified SyntheticDataConf message. Does not implicitly {@link synth_data.SyntheticDataConf.verify|verify} messages.
         * @function encode
         * @memberof synth_data.SyntheticDataConf
         * @static
         * @param {synth_data.ISyntheticDataConf} message SyntheticDataConf message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyntheticDataConf.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.columns != null && message.columns.length)
                for (let i = 0; i < message.columns.length; ++i)
                    $root.synth_data.Column.encode(message.columns[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.outputOriginalDataStats != null && Object.hasOwnProperty.call(message, "outputOriginalDataStats"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.outputOriginalDataStats);
            if (message.epsilon != null && Object.hasOwnProperty.call(message, "epsilon"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.epsilon);
            return writer;
        };

        /**
         * Encodes the specified SyntheticDataConf message, length delimited. Does not implicitly {@link synth_data.SyntheticDataConf.verify|verify} messages.
         * @function encodeDelimited
         * @memberof synth_data.SyntheticDataConf
         * @static
         * @param {synth_data.ISyntheticDataConf} message SyntheticDataConf message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyntheticDataConf.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SyntheticDataConf message from the specified reader or buffer.
         * @function decode
         * @memberof synth_data.SyntheticDataConf
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {synth_data.SyntheticDataConf} SyntheticDataConf
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyntheticDataConf.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.synth_data.SyntheticDataConf();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.columns && message.columns.length))
                            message.columns = [];
                        message.columns.push($root.synth_data.Column.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.outputOriginalDataStats = reader.bool();
                        break;
                    }
                case 3: {
                        message.epsilon = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SyntheticDataConf message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof synth_data.SyntheticDataConf
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {synth_data.SyntheticDataConf} SyntheticDataConf
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyntheticDataConf.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SyntheticDataConf message.
         * @function verify
         * @memberof synth_data.SyntheticDataConf
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SyntheticDataConf.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.columns != null && message.hasOwnProperty("columns")) {
                if (!Array.isArray(message.columns))
                    return "columns: array expected";
                for (let i = 0; i < message.columns.length; ++i) {
                    let error = $root.synth_data.Column.verify(message.columns[i]);
                    if (error)
                        return "columns." + error;
                }
            }
            if (message.outputOriginalDataStats != null && message.hasOwnProperty("outputOriginalDataStats"))
                if (typeof message.outputOriginalDataStats !== "boolean")
                    return "outputOriginalDataStats: boolean expected";
            if (message.epsilon != null && message.hasOwnProperty("epsilon"))
                if (typeof message.epsilon !== "number")
                    return "epsilon: number expected";
            return null;
        };

        /**
         * Creates a SyntheticDataConf message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof synth_data.SyntheticDataConf
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {synth_data.SyntheticDataConf} SyntheticDataConf
         */
        SyntheticDataConf.fromObject = function fromObject(object) {
            if (object instanceof $root.synth_data.SyntheticDataConf)
                return object;
            let message = new $root.synth_data.SyntheticDataConf();
            if (object.columns) {
                if (!Array.isArray(object.columns))
                    throw TypeError(".synth_data.SyntheticDataConf.columns: array expected");
                message.columns = [];
                for (let i = 0; i < object.columns.length; ++i) {
                    if (typeof object.columns[i] !== "object")
                        throw TypeError(".synth_data.SyntheticDataConf.columns: object expected");
                    message.columns[i] = $root.synth_data.Column.fromObject(object.columns[i]);
                }
            }
            if (object.outputOriginalDataStats != null)
                message.outputOriginalDataStats = Boolean(object.outputOriginalDataStats);
            if (object.epsilon != null)
                message.epsilon = Number(object.epsilon);
            return message;
        };

        /**
         * Creates a plain object from a SyntheticDataConf message. Also converts values to other types if specified.
         * @function toObject
         * @memberof synth_data.SyntheticDataConf
         * @static
         * @param {synth_data.SyntheticDataConf} message SyntheticDataConf
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SyntheticDataConf.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.columns = [];
            if (options.defaults) {
                object.outputOriginalDataStats = false;
                object.epsilon = 0;
            }
            if (message.columns && message.columns.length) {
                object.columns = [];
                for (let j = 0; j < message.columns.length; ++j)
                    object.columns[j] = $root.synth_data.Column.toObject(message.columns[j], options);
            }
            if (message.outputOriginalDataStats != null && message.hasOwnProperty("outputOriginalDataStats"))
                object.outputOriginalDataStats = message.outputOriginalDataStats;
            if (message.epsilon != null && message.hasOwnProperty("epsilon"))
                object.epsilon = options.json && !isFinite(message.epsilon) ? String(message.epsilon) : message.epsilon;
            return object;
        };

        /**
         * Converts this SyntheticDataConf to JSON.
         * @function toJSON
         * @memberof synth_data.SyntheticDataConf
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SyntheticDataConf.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SyntheticDataConf
         * @function getTypeUrl
         * @memberof synth_data.SyntheticDataConf
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SyntheticDataConf.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/synth_data.SyntheticDataConf";
        };

        return SyntheticDataConf;
    })();

    synth_data.Mask = (function() {

        /**
         * Properties of a Mask.
         * @memberof synth_data
         * @interface IMask
         * @property {synth_data.Mask.MaskFormat|null} [format] Mask format
         */

        /**
         * Constructs a new Mask.
         * @memberof synth_data
         * @classdesc Represents a Mask.
         * @implements IMask
         * @constructor
         * @param {synth_data.IMask=} [properties] Properties to set
         */
        function Mask(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Mask format.
         * @member {synth_data.Mask.MaskFormat} format
         * @memberof synth_data.Mask
         * @instance
         */
        Mask.prototype.format = 0;

        /**
         * Creates a new Mask instance using the specified properties.
         * @function create
         * @memberof synth_data.Mask
         * @static
         * @param {synth_data.IMask=} [properties] Properties to set
         * @returns {synth_data.Mask} Mask instance
         */
        Mask.create = function create(properties) {
            return new Mask(properties);
        };

        /**
         * Encodes the specified Mask message. Does not implicitly {@link synth_data.Mask.verify|verify} messages.
         * @function encode
         * @memberof synth_data.Mask
         * @static
         * @param {synth_data.IMask} message Mask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Mask.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.format != null && Object.hasOwnProperty.call(message, "format"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.format);
            return writer;
        };

        /**
         * Encodes the specified Mask message, length delimited. Does not implicitly {@link synth_data.Mask.verify|verify} messages.
         * @function encodeDelimited
         * @memberof synth_data.Mask
         * @static
         * @param {synth_data.IMask} message Mask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Mask.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Mask message from the specified reader or buffer.
         * @function decode
         * @memberof synth_data.Mask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {synth_data.Mask} Mask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Mask.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.synth_data.Mask();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.format = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Mask message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof synth_data.Mask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {synth_data.Mask} Mask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Mask.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Mask message.
         * @function verify
         * @memberof synth_data.Mask
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Mask.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.format != null && message.hasOwnProperty("format"))
                switch (message.format) {
                default:
                    return "format: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                    break;
                }
            return null;
        };

        /**
         * Creates a Mask message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof synth_data.Mask
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {synth_data.Mask} Mask
         */
        Mask.fromObject = function fromObject(object) {
            if (object instanceof $root.synth_data.Mask)
                return object;
            let message = new $root.synth_data.Mask();
            switch (object.format) {
            default:
                if (typeof object.format === "number") {
                    message.format = object.format;
                    break;
                }
                break;
            case "GENERIC_STRING":
            case 0:
                message.format = 0;
                break;
            case "GENERIC_NUMBER":
            case 1:
                message.format = 1;
                break;
            case "NAME":
            case 2:
                message.format = 2;
                break;
            case "ADDRESS":
            case 3:
                message.format = 3;
                break;
            case "POSTCODE":
            case 4:
                message.format = 4;
                break;
            case "PHONE_NUMBER":
            case 5:
                message.format = 5;
                break;
            case "SOCIAL_SECURITY_NUMBER":
            case 6:
                message.format = 6;
                break;
            case "EMAIL":
            case 7:
                message.format = 7;
                break;
            case "DATE":
            case 8:
                message.format = 8;
                break;
            case "TIMESTAMP":
            case 9:
                message.format = 9;
                break;
            case "IBAN":
            case 10:
                message.format = 10;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a Mask message. Also converts values to other types if specified.
         * @function toObject
         * @memberof synth_data.Mask
         * @static
         * @param {synth_data.Mask} message Mask
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Mask.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.format = options.enums === String ? "GENERIC_STRING" : 0;
            if (message.format != null && message.hasOwnProperty("format"))
                object.format = options.enums === String ? $root.synth_data.Mask.MaskFormat[message.format] === undefined ? message.format : $root.synth_data.Mask.MaskFormat[message.format] : message.format;
            return object;
        };

        /**
         * Converts this Mask to JSON.
         * @function toJSON
         * @memberof synth_data.Mask
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Mask.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Mask
         * @function getTypeUrl
         * @memberof synth_data.Mask
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Mask.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/synth_data.Mask";
        };

        /**
         * MaskFormat enum.
         * @name synth_data.Mask.MaskFormat
         * @enum {number}
         * @property {number} GENERIC_STRING=0 GENERIC_STRING value
         * @property {number} GENERIC_NUMBER=1 GENERIC_NUMBER value
         * @property {number} NAME=2 NAME value
         * @property {number} ADDRESS=3 ADDRESS value
         * @property {number} POSTCODE=4 POSTCODE value
         * @property {number} PHONE_NUMBER=5 PHONE_NUMBER value
         * @property {number} SOCIAL_SECURITY_NUMBER=6 SOCIAL_SECURITY_NUMBER value
         * @property {number} EMAIL=7 EMAIL value
         * @property {number} DATE=8 DATE value
         * @property {number} TIMESTAMP=9 TIMESTAMP value
         * @property {number} IBAN=10 IBAN value
         */
        Mask.MaskFormat = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "GENERIC_STRING"] = 0;
            values[valuesById[1] = "GENERIC_NUMBER"] = 1;
            values[valuesById[2] = "NAME"] = 2;
            values[valuesById[3] = "ADDRESS"] = 3;
            values[valuesById[4] = "POSTCODE"] = 4;
            values[valuesById[5] = "PHONE_NUMBER"] = 5;
            values[valuesById[6] = "SOCIAL_SECURITY_NUMBER"] = 6;
            values[valuesById[7] = "EMAIL"] = 7;
            values[valuesById[8] = "DATE"] = 8;
            values[valuesById[9] = "TIMESTAMP"] = 9;
            values[valuesById[10] = "IBAN"] = 10;
            return values;
        })();

        return Mask;
    })();

    synth_data.Column = (function() {

        /**
         * Properties of a Column.
         * @memberof synth_data
         * @interface IColumn
         * @property {number|null} [index] Column index
         * @property {compute_sql.IColumnType|null} [type] Column type
         * @property {synth_data.IMask|null} [mask] Column mask
         */

        /**
         * Constructs a new Column.
         * @memberof synth_data
         * @classdesc Represents a Column.
         * @implements IColumn
         * @constructor
         * @param {synth_data.IColumn=} [properties] Properties to set
         */
        function Column(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Column index.
         * @member {number} index
         * @memberof synth_data.Column
         * @instance
         */
        Column.prototype.index = 0;

        /**
         * Column type.
         * @member {compute_sql.IColumnType|null|undefined} type
         * @memberof synth_data.Column
         * @instance
         */
        Column.prototype.type = null;

        /**
         * Column mask.
         * @member {synth_data.IMask|null|undefined} mask
         * @memberof synth_data.Column
         * @instance
         */
        Column.prototype.mask = null;

        /**
         * Creates a new Column instance using the specified properties.
         * @function create
         * @memberof synth_data.Column
         * @static
         * @param {synth_data.IColumn=} [properties] Properties to set
         * @returns {synth_data.Column} Column instance
         */
        Column.create = function create(properties) {
            return new Column(properties);
        };

        /**
         * Encodes the specified Column message. Does not implicitly {@link synth_data.Column.verify|verify} messages.
         * @function encode
         * @memberof synth_data.Column
         * @static
         * @param {synth_data.IColumn} message Column message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Column.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.index);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                $root.compute_sql.ColumnType.encode(message.type, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.mask != null && Object.hasOwnProperty.call(message, "mask"))
                $root.synth_data.Mask.encode(message.mask, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Column message, length delimited. Does not implicitly {@link synth_data.Column.verify|verify} messages.
         * @function encodeDelimited
         * @memberof synth_data.Column
         * @static
         * @param {synth_data.IColumn} message Column message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Column.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Column message from the specified reader or buffer.
         * @function decode
         * @memberof synth_data.Column
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {synth_data.Column} Column
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Column.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.synth_data.Column();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.index = reader.int32();
                        break;
                    }
                case 2: {
                        message.type = $root.compute_sql.ColumnType.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.mask = $root.synth_data.Mask.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Column message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof synth_data.Column
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {synth_data.Column} Column
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Column.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Column message.
         * @function verify
         * @memberof synth_data.Column
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Column.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.index != null && message.hasOwnProperty("index"))
                if (!$util.isInteger(message.index))
                    return "index: integer expected";
            if (message.type != null && message.hasOwnProperty("type")) {
                let error = $root.compute_sql.ColumnType.verify(message.type);
                if (error)
                    return "type." + error;
            }
            if (message.mask != null && message.hasOwnProperty("mask")) {
                let error = $root.synth_data.Mask.verify(message.mask);
                if (error)
                    return "mask." + error;
            }
            return null;
        };

        /**
         * Creates a Column message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof synth_data.Column
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {synth_data.Column} Column
         */
        Column.fromObject = function fromObject(object) {
            if (object instanceof $root.synth_data.Column)
                return object;
            let message = new $root.synth_data.Column();
            if (object.index != null)
                message.index = object.index | 0;
            if (object.type != null) {
                if (typeof object.type !== "object")
                    throw TypeError(".synth_data.Column.type: object expected");
                message.type = $root.compute_sql.ColumnType.fromObject(object.type);
            }
            if (object.mask != null) {
                if (typeof object.mask !== "object")
                    throw TypeError(".synth_data.Column.mask: object expected");
                message.mask = $root.synth_data.Mask.fromObject(object.mask);
            }
            return message;
        };

        /**
         * Creates a plain object from a Column message. Also converts values to other types if specified.
         * @function toObject
         * @memberof synth_data.Column
         * @static
         * @param {synth_data.Column} message Column
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Column.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.index = 0;
                object.type = null;
                object.mask = null;
            }
            if (message.index != null && message.hasOwnProperty("index"))
                object.index = message.index;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = $root.compute_sql.ColumnType.toObject(message.type, options);
            if (message.mask != null && message.hasOwnProperty("mask"))
                object.mask = $root.synth_data.Mask.toObject(message.mask, options);
            return object;
        };

        /**
         * Converts this Column to JSON.
         * @function toJSON
         * @memberof synth_data.Column
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Column.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Column
         * @function getTypeUrl
         * @memberof synth_data.Column
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Column.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/synth_data.Column";
        };

        return Column;
    })();

    return synth_data;
})();

export const adform_dsp = $root.adform_dsp = (() => {

    /**
     * Namespace adform_dsp.
     * @exports adform_dsp
     * @namespace
     */
    const adform_dsp = {};

    adform_dsp.SingleFile = (function() {

        /**
         * Properties of a SingleFile.
         * @memberof adform_dsp
         * @interface ISingleFile
         * @property {string|null} [name] SingleFile name
         */

        /**
         * Constructs a new SingleFile.
         * @memberof adform_dsp
         * @classdesc Represents a SingleFile.
         * @implements ISingleFile
         * @constructor
         * @param {adform_dsp.ISingleFile=} [properties] Properties to set
         */
        function SingleFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SingleFile name.
         * @member {string} name
         * @memberof adform_dsp.SingleFile
         * @instance
         */
        SingleFile.prototype.name = "";

        /**
         * Creates a new SingleFile instance using the specified properties.
         * @function create
         * @memberof adform_dsp.SingleFile
         * @static
         * @param {adform_dsp.ISingleFile=} [properties] Properties to set
         * @returns {adform_dsp.SingleFile} SingleFile instance
         */
        SingleFile.create = function create(properties) {
            return new SingleFile(properties);
        };

        /**
         * Encodes the specified SingleFile message. Does not implicitly {@link adform_dsp.SingleFile.verify|verify} messages.
         * @function encode
         * @memberof adform_dsp.SingleFile
         * @static
         * @param {adform_dsp.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified SingleFile message, length delimited. Does not implicitly {@link adform_dsp.SingleFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof adform_dsp.SingleFile
         * @static
         * @param {adform_dsp.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer.
         * @function decode
         * @memberof adform_dsp.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {adform_dsp.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.adform_dsp.SingleFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof adform_dsp.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {adform_dsp.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SingleFile message.
         * @function verify
         * @memberof adform_dsp.SingleFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SingleFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a SingleFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof adform_dsp.SingleFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {adform_dsp.SingleFile} SingleFile
         */
        SingleFile.fromObject = function fromObject(object) {
            if (object instanceof $root.adform_dsp.SingleFile)
                return object;
            let message = new $root.adform_dsp.SingleFile();
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a SingleFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof adform_dsp.SingleFile
         * @static
         * @param {adform_dsp.SingleFile} message SingleFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SingleFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this SingleFile to JSON.
         * @function toJSON
         * @memberof adform_dsp.SingleFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SingleFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SingleFile
         * @function getTypeUrl
         * @memberof adform_dsp.SingleFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SingleFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/adform_dsp.SingleFile";
        };

        return SingleFile;
    })();

    adform_dsp.RawFile = (function() {

        /**
         * Properties of a RawFile.
         * @memberof adform_dsp
         * @interface IRawFile
         */

        /**
         * Constructs a new RawFile.
         * @memberof adform_dsp
         * @classdesc Represents a RawFile.
         * @implements IRawFile
         * @constructor
         * @param {adform_dsp.IRawFile=} [properties] Properties to set
         */
        function RawFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RawFile instance using the specified properties.
         * @function create
         * @memberof adform_dsp.RawFile
         * @static
         * @param {adform_dsp.IRawFile=} [properties] Properties to set
         * @returns {adform_dsp.RawFile} RawFile instance
         */
        RawFile.create = function create(properties) {
            return new RawFile(properties);
        };

        /**
         * Encodes the specified RawFile message. Does not implicitly {@link adform_dsp.RawFile.verify|verify} messages.
         * @function encode
         * @memberof adform_dsp.RawFile
         * @static
         * @param {adform_dsp.IRawFile} message RawFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RawFile message, length delimited. Does not implicitly {@link adform_dsp.RawFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof adform_dsp.RawFile
         * @static
         * @param {adform_dsp.IRawFile} message RawFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RawFile message from the specified reader or buffer.
         * @function decode
         * @memberof adform_dsp.RawFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {adform_dsp.RawFile} RawFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.adform_dsp.RawFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RawFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof adform_dsp.RawFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {adform_dsp.RawFile} RawFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RawFile message.
         * @function verify
         * @memberof adform_dsp.RawFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RawFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RawFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof adform_dsp.RawFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {adform_dsp.RawFile} RawFile
         */
        RawFile.fromObject = function fromObject(object) {
            if (object instanceof $root.adform_dsp.RawFile)
                return object;
            return new $root.adform_dsp.RawFile();
        };

        /**
         * Creates a plain object from a RawFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof adform_dsp.RawFile
         * @static
         * @param {adform_dsp.RawFile} message RawFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RawFile.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RawFile to JSON.
         * @function toJSON
         * @memberof adform_dsp.RawFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RawFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RawFile
         * @function getTypeUrl
         * @memberof adform_dsp.RawFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RawFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/adform_dsp.RawFile";
        };

        return RawFile;
    })();

    adform_dsp.ZipFile = (function() {

        /**
         * Properties of a ZipFile.
         * @memberof adform_dsp
         * @interface IZipFile
         * @property {adform_dsp.ISingleFile|null} [singleFile] ZipFile singleFile
         */

        /**
         * Constructs a new ZipFile.
         * @memberof adform_dsp
         * @classdesc Represents a ZipFile.
         * @implements IZipFile
         * @constructor
         * @param {adform_dsp.IZipFile=} [properties] Properties to set
         */
        function ZipFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ZipFile singleFile.
         * @member {adform_dsp.ISingleFile|null|undefined} singleFile
         * @memberof adform_dsp.ZipFile
         * @instance
         */
        ZipFile.prototype.singleFile = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ZipFile selection.
         * @member {"singleFile"|undefined} selection
         * @memberof adform_dsp.ZipFile
         * @instance
         */
        Object.defineProperty(ZipFile.prototype, "selection", {
            get: $util.oneOfGetter($oneOfFields = ["singleFile"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ZipFile instance using the specified properties.
         * @function create
         * @memberof adform_dsp.ZipFile
         * @static
         * @param {adform_dsp.IZipFile=} [properties] Properties to set
         * @returns {adform_dsp.ZipFile} ZipFile instance
         */
        ZipFile.create = function create(properties) {
            return new ZipFile(properties);
        };

        /**
         * Encodes the specified ZipFile message. Does not implicitly {@link adform_dsp.ZipFile.verify|verify} messages.
         * @function encode
         * @memberof adform_dsp.ZipFile
         * @static
         * @param {adform_dsp.IZipFile} message ZipFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.singleFile != null && Object.hasOwnProperty.call(message, "singleFile"))
                $root.adform_dsp.SingleFile.encode(message.singleFile, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ZipFile message, length delimited. Does not implicitly {@link adform_dsp.ZipFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof adform_dsp.ZipFile
         * @static
         * @param {adform_dsp.IZipFile} message ZipFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ZipFile message from the specified reader or buffer.
         * @function decode
         * @memberof adform_dsp.ZipFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {adform_dsp.ZipFile} ZipFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.adform_dsp.ZipFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.singleFile = $root.adform_dsp.SingleFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ZipFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof adform_dsp.ZipFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {adform_dsp.ZipFile} ZipFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ZipFile message.
         * @function verify
         * @memberof adform_dsp.ZipFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ZipFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.singleFile != null && message.hasOwnProperty("singleFile")) {
                properties.selection = 1;
                {
                    let error = $root.adform_dsp.SingleFile.verify(message.singleFile);
                    if (error)
                        return "singleFile." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ZipFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof adform_dsp.ZipFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {adform_dsp.ZipFile} ZipFile
         */
        ZipFile.fromObject = function fromObject(object) {
            if (object instanceof $root.adform_dsp.ZipFile)
                return object;
            let message = new $root.adform_dsp.ZipFile();
            if (object.singleFile != null) {
                if (typeof object.singleFile !== "object")
                    throw TypeError(".adform_dsp.ZipFile.singleFile: object expected");
                message.singleFile = $root.adform_dsp.SingleFile.fromObject(object.singleFile);
            }
            return message;
        };

        /**
         * Creates a plain object from a ZipFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof adform_dsp.ZipFile
         * @static
         * @param {adform_dsp.ZipFile} message ZipFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ZipFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.singleFile != null && message.hasOwnProperty("singleFile")) {
                object.singleFile = $root.adform_dsp.SingleFile.toObject(message.singleFile, options);
                if (options.oneofs)
                    object.selection = "singleFile";
            }
            return object;
        };

        /**
         * Converts this ZipFile to JSON.
         * @function toJSON
         * @memberof adform_dsp.ZipFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ZipFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ZipFile
         * @function getTypeUrl
         * @memberof adform_dsp.ZipFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ZipFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/adform_dsp.ZipFile";
        };

        return ZipFile;
    })();

    adform_dsp.SinkInput = (function() {

        /**
         * Properties of a SinkInput.
         * @memberof adform_dsp
         * @interface ISinkInput
         * @property {string|null} [dependency] SinkInput dependency
         * @property {string|null} [name] SinkInput name
         * @property {adform_dsp.IRawFile|null} [raw] SinkInput raw
         * @property {adform_dsp.IZipFile|null} [zip] SinkInput zip
         */

        /**
         * Constructs a new SinkInput.
         * @memberof adform_dsp
         * @classdesc Represents a SinkInput.
         * @implements ISinkInput
         * @constructor
         * @param {adform_dsp.ISinkInput=} [properties] Properties to set
         */
        function SinkInput(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SinkInput dependency.
         * @member {string} dependency
         * @memberof adform_dsp.SinkInput
         * @instance
         */
        SinkInput.prototype.dependency = "";

        /**
         * SinkInput name.
         * @member {string} name
         * @memberof adform_dsp.SinkInput
         * @instance
         */
        SinkInput.prototype.name = "";

        /**
         * SinkInput raw.
         * @member {adform_dsp.IRawFile|null|undefined} raw
         * @memberof adform_dsp.SinkInput
         * @instance
         */
        SinkInput.prototype.raw = null;

        /**
         * SinkInput zip.
         * @member {adform_dsp.IZipFile|null|undefined} zip
         * @memberof adform_dsp.SinkInput
         * @instance
         */
        SinkInput.prototype.zip = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SinkInput file.
         * @member {"raw"|"zip"|undefined} file
         * @memberof adform_dsp.SinkInput
         * @instance
         */
        Object.defineProperty(SinkInput.prototype, "file", {
            get: $util.oneOfGetter($oneOfFields = ["raw", "zip"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SinkInput instance using the specified properties.
         * @function create
         * @memberof adform_dsp.SinkInput
         * @static
         * @param {adform_dsp.ISinkInput=} [properties] Properties to set
         * @returns {adform_dsp.SinkInput} SinkInput instance
         */
        SinkInput.create = function create(properties) {
            return new SinkInput(properties);
        };

        /**
         * Encodes the specified SinkInput message. Does not implicitly {@link adform_dsp.SinkInput.verify|verify} messages.
         * @function encode
         * @memberof adform_dsp.SinkInput
         * @static
         * @param {adform_dsp.ISinkInput} message SinkInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SinkInput.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dependency != null && Object.hasOwnProperty.call(message, "dependency"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dependency);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.raw != null && Object.hasOwnProperty.call(message, "raw"))
                $root.adform_dsp.RawFile.encode(message.raw, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.zip != null && Object.hasOwnProperty.call(message, "zip"))
                $root.adform_dsp.ZipFile.encode(message.zip, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SinkInput message, length delimited. Does not implicitly {@link adform_dsp.SinkInput.verify|verify} messages.
         * @function encodeDelimited
         * @memberof adform_dsp.SinkInput
         * @static
         * @param {adform_dsp.ISinkInput} message SinkInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SinkInput.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SinkInput message from the specified reader or buffer.
         * @function decode
         * @memberof adform_dsp.SinkInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {adform_dsp.SinkInput} SinkInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SinkInput.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.adform_dsp.SinkInput();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dependency = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.raw = $root.adform_dsp.RawFile.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.zip = $root.adform_dsp.ZipFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SinkInput message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof adform_dsp.SinkInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {adform_dsp.SinkInput} SinkInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SinkInput.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SinkInput message.
         * @function verify
         * @memberof adform_dsp.SinkInput
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SinkInput.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                if (!$util.isString(message.dependency))
                    return "dependency: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.raw != null && message.hasOwnProperty("raw")) {
                properties.file = 1;
                {
                    let error = $root.adform_dsp.RawFile.verify(message.raw);
                    if (error)
                        return "raw." + error;
                }
            }
            if (message.zip != null && message.hasOwnProperty("zip")) {
                if (properties.file === 1)
                    return "file: multiple values";
                properties.file = 1;
                {
                    let error = $root.adform_dsp.ZipFile.verify(message.zip);
                    if (error)
                        return "zip." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SinkInput message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof adform_dsp.SinkInput
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {adform_dsp.SinkInput} SinkInput
         */
        SinkInput.fromObject = function fromObject(object) {
            if (object instanceof $root.adform_dsp.SinkInput)
                return object;
            let message = new $root.adform_dsp.SinkInput();
            if (object.dependency != null)
                message.dependency = String(object.dependency);
            if (object.name != null)
                message.name = String(object.name);
            if (object.raw != null) {
                if (typeof object.raw !== "object")
                    throw TypeError(".adform_dsp.SinkInput.raw: object expected");
                message.raw = $root.adform_dsp.RawFile.fromObject(object.raw);
            }
            if (object.zip != null) {
                if (typeof object.zip !== "object")
                    throw TypeError(".adform_dsp.SinkInput.zip: object expected");
                message.zip = $root.adform_dsp.ZipFile.fromObject(object.zip);
            }
            return message;
        };

        /**
         * Creates a plain object from a SinkInput message. Also converts values to other types if specified.
         * @function toObject
         * @memberof adform_dsp.SinkInput
         * @static
         * @param {adform_dsp.SinkInput} message SinkInput
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SinkInput.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.dependency = "";
                object.name = "";
            }
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                object.dependency = message.dependency;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.raw != null && message.hasOwnProperty("raw")) {
                object.raw = $root.adform_dsp.RawFile.toObject(message.raw, options);
                if (options.oneofs)
                    object.file = "raw";
            }
            if (message.zip != null && message.hasOwnProperty("zip")) {
                object.zip = $root.adform_dsp.ZipFile.toObject(message.zip, options);
                if (options.oneofs)
                    object.file = "zip";
            }
            return object;
        };

        /**
         * Converts this SinkInput to JSON.
         * @function toJSON
         * @memberof adform_dsp.SinkInput
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SinkInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SinkInput
         * @function getTypeUrl
         * @memberof adform_dsp.SinkInput
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SinkInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/adform_dsp.SinkInput";
        };

        return SinkInput;
    })();

    adform_dsp.AdformDspWorkerConfiguration = (function() {

        /**
         * Properties of an AdformDspWorkerConfiguration.
         * @memberof adform_dsp
         * @interface IAdformDspWorkerConfiguration
         * @property {adform_dsp.ISinkInput|null} [input] AdformDspWorkerConfiguration input
         * @property {Array.<string>|null} [segmentOwners] AdformDspWorkerConfiguration segmentOwners
         */

        /**
         * Constructs a new AdformDspWorkerConfiguration.
         * @memberof adform_dsp
         * @classdesc Represents an AdformDspWorkerConfiguration.
         * @implements IAdformDspWorkerConfiguration
         * @constructor
         * @param {adform_dsp.IAdformDspWorkerConfiguration=} [properties] Properties to set
         */
        function AdformDspWorkerConfiguration(properties) {
            this.segmentOwners = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdformDspWorkerConfiguration input.
         * @member {adform_dsp.ISinkInput|null|undefined} input
         * @memberof adform_dsp.AdformDspWorkerConfiguration
         * @instance
         */
        AdformDspWorkerConfiguration.prototype.input = null;

        /**
         * AdformDspWorkerConfiguration segmentOwners.
         * @member {Array.<string>} segmentOwners
         * @memberof adform_dsp.AdformDspWorkerConfiguration
         * @instance
         */
        AdformDspWorkerConfiguration.prototype.segmentOwners = $util.emptyArray;

        /**
         * Creates a new AdformDspWorkerConfiguration instance using the specified properties.
         * @function create
         * @memberof adform_dsp.AdformDspWorkerConfiguration
         * @static
         * @param {adform_dsp.IAdformDspWorkerConfiguration=} [properties] Properties to set
         * @returns {adform_dsp.AdformDspWorkerConfiguration} AdformDspWorkerConfiguration instance
         */
        AdformDspWorkerConfiguration.create = function create(properties) {
            return new AdformDspWorkerConfiguration(properties);
        };

        /**
         * Encodes the specified AdformDspWorkerConfiguration message. Does not implicitly {@link adform_dsp.AdformDspWorkerConfiguration.verify|verify} messages.
         * @function encode
         * @memberof adform_dsp.AdformDspWorkerConfiguration
         * @static
         * @param {adform_dsp.IAdformDspWorkerConfiguration} message AdformDspWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdformDspWorkerConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.input != null && Object.hasOwnProperty.call(message, "input"))
                $root.adform_dsp.SinkInput.encode(message.input, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.segmentOwners != null && message.segmentOwners.length)
                for (let i = 0; i < message.segmentOwners.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.segmentOwners[i]);
            return writer;
        };

        /**
         * Encodes the specified AdformDspWorkerConfiguration message, length delimited. Does not implicitly {@link adform_dsp.AdformDspWorkerConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof adform_dsp.AdformDspWorkerConfiguration
         * @static
         * @param {adform_dsp.IAdformDspWorkerConfiguration} message AdformDspWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdformDspWorkerConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdformDspWorkerConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof adform_dsp.AdformDspWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {adform_dsp.AdformDspWorkerConfiguration} AdformDspWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdformDspWorkerConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.adform_dsp.AdformDspWorkerConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.input = $root.adform_dsp.SinkInput.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        if (!(message.segmentOwners && message.segmentOwners.length))
                            message.segmentOwners = [];
                        message.segmentOwners.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdformDspWorkerConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof adform_dsp.AdformDspWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {adform_dsp.AdformDspWorkerConfiguration} AdformDspWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdformDspWorkerConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdformDspWorkerConfiguration message.
         * @function verify
         * @memberof adform_dsp.AdformDspWorkerConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdformDspWorkerConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.input != null && message.hasOwnProperty("input")) {
                let error = $root.adform_dsp.SinkInput.verify(message.input);
                if (error)
                    return "input." + error;
            }
            if (message.segmentOwners != null && message.hasOwnProperty("segmentOwners")) {
                if (!Array.isArray(message.segmentOwners))
                    return "segmentOwners: array expected";
                for (let i = 0; i < message.segmentOwners.length; ++i)
                    if (!$util.isString(message.segmentOwners[i]))
                        return "segmentOwners: string[] expected";
            }
            return null;
        };

        /**
         * Creates an AdformDspWorkerConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof adform_dsp.AdformDspWorkerConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {adform_dsp.AdformDspWorkerConfiguration} AdformDspWorkerConfiguration
         */
        AdformDspWorkerConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.adform_dsp.AdformDspWorkerConfiguration)
                return object;
            let message = new $root.adform_dsp.AdformDspWorkerConfiguration();
            if (object.input != null) {
                if (typeof object.input !== "object")
                    throw TypeError(".adform_dsp.AdformDspWorkerConfiguration.input: object expected");
                message.input = $root.adform_dsp.SinkInput.fromObject(object.input);
            }
            if (object.segmentOwners) {
                if (!Array.isArray(object.segmentOwners))
                    throw TypeError(".adform_dsp.AdformDspWorkerConfiguration.segmentOwners: array expected");
                message.segmentOwners = [];
                for (let i = 0; i < object.segmentOwners.length; ++i)
                    message.segmentOwners[i] = String(object.segmentOwners[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from an AdformDspWorkerConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof adform_dsp.AdformDspWorkerConfiguration
         * @static
         * @param {adform_dsp.AdformDspWorkerConfiguration} message AdformDspWorkerConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdformDspWorkerConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.segmentOwners = [];
            if (options.defaults)
                object.input = null;
            if (message.input != null && message.hasOwnProperty("input"))
                object.input = $root.adform_dsp.SinkInput.toObject(message.input, options);
            if (message.segmentOwners && message.segmentOwners.length) {
                object.segmentOwners = [];
                for (let j = 0; j < message.segmentOwners.length; ++j)
                    object.segmentOwners[j] = message.segmentOwners[j];
            }
            return object;
        };

        /**
         * Converts this AdformDspWorkerConfiguration to JSON.
         * @function toJSON
         * @memberof adform_dsp.AdformDspWorkerConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdformDspWorkerConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdformDspWorkerConfiguration
         * @function getTypeUrl
         * @memberof adform_dsp.AdformDspWorkerConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdformDspWorkerConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/adform_dsp.AdformDspWorkerConfiguration";
        };

        return AdformDspWorkerConfiguration;
    })();

    return adform_dsp;
})();

export const microsoft_dsp = $root.microsoft_dsp = (() => {

    /**
     * Namespace microsoft_dsp.
     * @exports microsoft_dsp
     * @namespace
     */
    const microsoft_dsp = {};

    microsoft_dsp.SingleFile = (function() {

        /**
         * Properties of a SingleFile.
         * @memberof microsoft_dsp
         * @interface ISingleFile
         * @property {string|null} [name] SingleFile name
         */

        /**
         * Constructs a new SingleFile.
         * @memberof microsoft_dsp
         * @classdesc Represents a SingleFile.
         * @implements ISingleFile
         * @constructor
         * @param {microsoft_dsp.ISingleFile=} [properties] Properties to set
         */
        function SingleFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SingleFile name.
         * @member {string} name
         * @memberof microsoft_dsp.SingleFile
         * @instance
         */
        SingleFile.prototype.name = "";

        /**
         * Creates a new SingleFile instance using the specified properties.
         * @function create
         * @memberof microsoft_dsp.SingleFile
         * @static
         * @param {microsoft_dsp.ISingleFile=} [properties] Properties to set
         * @returns {microsoft_dsp.SingleFile} SingleFile instance
         */
        SingleFile.create = function create(properties) {
            return new SingleFile(properties);
        };

        /**
         * Encodes the specified SingleFile message. Does not implicitly {@link microsoft_dsp.SingleFile.verify|verify} messages.
         * @function encode
         * @memberof microsoft_dsp.SingleFile
         * @static
         * @param {microsoft_dsp.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified SingleFile message, length delimited. Does not implicitly {@link microsoft_dsp.SingleFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof microsoft_dsp.SingleFile
         * @static
         * @param {microsoft_dsp.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer.
         * @function decode
         * @memberof microsoft_dsp.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {microsoft_dsp.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.microsoft_dsp.SingleFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof microsoft_dsp.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {microsoft_dsp.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SingleFile message.
         * @function verify
         * @memberof microsoft_dsp.SingleFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SingleFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a SingleFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof microsoft_dsp.SingleFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {microsoft_dsp.SingleFile} SingleFile
         */
        SingleFile.fromObject = function fromObject(object) {
            if (object instanceof $root.microsoft_dsp.SingleFile)
                return object;
            let message = new $root.microsoft_dsp.SingleFile();
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a SingleFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof microsoft_dsp.SingleFile
         * @static
         * @param {microsoft_dsp.SingleFile} message SingleFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SingleFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this SingleFile to JSON.
         * @function toJSON
         * @memberof microsoft_dsp.SingleFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SingleFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SingleFile
         * @function getTypeUrl
         * @memberof microsoft_dsp.SingleFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SingleFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/microsoft_dsp.SingleFile";
        };

        return SingleFile;
    })();

    microsoft_dsp.RawFile = (function() {

        /**
         * Properties of a RawFile.
         * @memberof microsoft_dsp
         * @interface IRawFile
         */

        /**
         * Constructs a new RawFile.
         * @memberof microsoft_dsp
         * @classdesc Represents a RawFile.
         * @implements IRawFile
         * @constructor
         * @param {microsoft_dsp.IRawFile=} [properties] Properties to set
         */
        function RawFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RawFile instance using the specified properties.
         * @function create
         * @memberof microsoft_dsp.RawFile
         * @static
         * @param {microsoft_dsp.IRawFile=} [properties] Properties to set
         * @returns {microsoft_dsp.RawFile} RawFile instance
         */
        RawFile.create = function create(properties) {
            return new RawFile(properties);
        };

        /**
         * Encodes the specified RawFile message. Does not implicitly {@link microsoft_dsp.RawFile.verify|verify} messages.
         * @function encode
         * @memberof microsoft_dsp.RawFile
         * @static
         * @param {microsoft_dsp.IRawFile} message RawFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RawFile message, length delimited. Does not implicitly {@link microsoft_dsp.RawFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof microsoft_dsp.RawFile
         * @static
         * @param {microsoft_dsp.IRawFile} message RawFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RawFile message from the specified reader or buffer.
         * @function decode
         * @memberof microsoft_dsp.RawFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {microsoft_dsp.RawFile} RawFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.microsoft_dsp.RawFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RawFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof microsoft_dsp.RawFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {microsoft_dsp.RawFile} RawFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RawFile message.
         * @function verify
         * @memberof microsoft_dsp.RawFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RawFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RawFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof microsoft_dsp.RawFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {microsoft_dsp.RawFile} RawFile
         */
        RawFile.fromObject = function fromObject(object) {
            if (object instanceof $root.microsoft_dsp.RawFile)
                return object;
            return new $root.microsoft_dsp.RawFile();
        };

        /**
         * Creates a plain object from a RawFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof microsoft_dsp.RawFile
         * @static
         * @param {microsoft_dsp.RawFile} message RawFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RawFile.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RawFile to JSON.
         * @function toJSON
         * @memberof microsoft_dsp.RawFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RawFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RawFile
         * @function getTypeUrl
         * @memberof microsoft_dsp.RawFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RawFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/microsoft_dsp.RawFile";
        };

        return RawFile;
    })();

    microsoft_dsp.ZipFile = (function() {

        /**
         * Properties of a ZipFile.
         * @memberof microsoft_dsp
         * @interface IZipFile
         * @property {microsoft_dsp.ISingleFile|null} [singleFile] ZipFile singleFile
         */

        /**
         * Constructs a new ZipFile.
         * @memberof microsoft_dsp
         * @classdesc Represents a ZipFile.
         * @implements IZipFile
         * @constructor
         * @param {microsoft_dsp.IZipFile=} [properties] Properties to set
         */
        function ZipFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ZipFile singleFile.
         * @member {microsoft_dsp.ISingleFile|null|undefined} singleFile
         * @memberof microsoft_dsp.ZipFile
         * @instance
         */
        ZipFile.prototype.singleFile = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ZipFile selection.
         * @member {"singleFile"|undefined} selection
         * @memberof microsoft_dsp.ZipFile
         * @instance
         */
        Object.defineProperty(ZipFile.prototype, "selection", {
            get: $util.oneOfGetter($oneOfFields = ["singleFile"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ZipFile instance using the specified properties.
         * @function create
         * @memberof microsoft_dsp.ZipFile
         * @static
         * @param {microsoft_dsp.IZipFile=} [properties] Properties to set
         * @returns {microsoft_dsp.ZipFile} ZipFile instance
         */
        ZipFile.create = function create(properties) {
            return new ZipFile(properties);
        };

        /**
         * Encodes the specified ZipFile message. Does not implicitly {@link microsoft_dsp.ZipFile.verify|verify} messages.
         * @function encode
         * @memberof microsoft_dsp.ZipFile
         * @static
         * @param {microsoft_dsp.IZipFile} message ZipFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.singleFile != null && Object.hasOwnProperty.call(message, "singleFile"))
                $root.microsoft_dsp.SingleFile.encode(message.singleFile, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ZipFile message, length delimited. Does not implicitly {@link microsoft_dsp.ZipFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof microsoft_dsp.ZipFile
         * @static
         * @param {microsoft_dsp.IZipFile} message ZipFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ZipFile message from the specified reader or buffer.
         * @function decode
         * @memberof microsoft_dsp.ZipFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {microsoft_dsp.ZipFile} ZipFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.microsoft_dsp.ZipFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.singleFile = $root.microsoft_dsp.SingleFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ZipFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof microsoft_dsp.ZipFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {microsoft_dsp.ZipFile} ZipFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ZipFile message.
         * @function verify
         * @memberof microsoft_dsp.ZipFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ZipFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.singleFile != null && message.hasOwnProperty("singleFile")) {
                properties.selection = 1;
                {
                    let error = $root.microsoft_dsp.SingleFile.verify(message.singleFile);
                    if (error)
                        return "singleFile." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ZipFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof microsoft_dsp.ZipFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {microsoft_dsp.ZipFile} ZipFile
         */
        ZipFile.fromObject = function fromObject(object) {
            if (object instanceof $root.microsoft_dsp.ZipFile)
                return object;
            let message = new $root.microsoft_dsp.ZipFile();
            if (object.singleFile != null) {
                if (typeof object.singleFile !== "object")
                    throw TypeError(".microsoft_dsp.ZipFile.singleFile: object expected");
                message.singleFile = $root.microsoft_dsp.SingleFile.fromObject(object.singleFile);
            }
            return message;
        };

        /**
         * Creates a plain object from a ZipFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof microsoft_dsp.ZipFile
         * @static
         * @param {microsoft_dsp.ZipFile} message ZipFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ZipFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.singleFile != null && message.hasOwnProperty("singleFile")) {
                object.singleFile = $root.microsoft_dsp.SingleFile.toObject(message.singleFile, options);
                if (options.oneofs)
                    object.selection = "singleFile";
            }
            return object;
        };

        /**
         * Converts this ZipFile to JSON.
         * @function toJSON
         * @memberof microsoft_dsp.ZipFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ZipFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ZipFile
         * @function getTypeUrl
         * @memberof microsoft_dsp.ZipFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ZipFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/microsoft_dsp.ZipFile";
        };

        return ZipFile;
    })();

    microsoft_dsp.SinkInput = (function() {

        /**
         * Properties of a SinkInput.
         * @memberof microsoft_dsp
         * @interface ISinkInput
         * @property {string|null} [dependency] SinkInput dependency
         * @property {string|null} [name] SinkInput name
         * @property {microsoft_dsp.IRawFile|null} [raw] SinkInput raw
         * @property {microsoft_dsp.IZipFile|null} [zip] SinkInput zip
         */

        /**
         * Constructs a new SinkInput.
         * @memberof microsoft_dsp
         * @classdesc Represents a SinkInput.
         * @implements ISinkInput
         * @constructor
         * @param {microsoft_dsp.ISinkInput=} [properties] Properties to set
         */
        function SinkInput(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SinkInput dependency.
         * @member {string} dependency
         * @memberof microsoft_dsp.SinkInput
         * @instance
         */
        SinkInput.prototype.dependency = "";

        /**
         * SinkInput name.
         * @member {string} name
         * @memberof microsoft_dsp.SinkInput
         * @instance
         */
        SinkInput.prototype.name = "";

        /**
         * SinkInput raw.
         * @member {microsoft_dsp.IRawFile|null|undefined} raw
         * @memberof microsoft_dsp.SinkInput
         * @instance
         */
        SinkInput.prototype.raw = null;

        /**
         * SinkInput zip.
         * @member {microsoft_dsp.IZipFile|null|undefined} zip
         * @memberof microsoft_dsp.SinkInput
         * @instance
         */
        SinkInput.prototype.zip = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SinkInput file.
         * @member {"raw"|"zip"|undefined} file
         * @memberof microsoft_dsp.SinkInput
         * @instance
         */
        Object.defineProperty(SinkInput.prototype, "file", {
            get: $util.oneOfGetter($oneOfFields = ["raw", "zip"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SinkInput instance using the specified properties.
         * @function create
         * @memberof microsoft_dsp.SinkInput
         * @static
         * @param {microsoft_dsp.ISinkInput=} [properties] Properties to set
         * @returns {microsoft_dsp.SinkInput} SinkInput instance
         */
        SinkInput.create = function create(properties) {
            return new SinkInput(properties);
        };

        /**
         * Encodes the specified SinkInput message. Does not implicitly {@link microsoft_dsp.SinkInput.verify|verify} messages.
         * @function encode
         * @memberof microsoft_dsp.SinkInput
         * @static
         * @param {microsoft_dsp.ISinkInput} message SinkInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SinkInput.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dependency != null && Object.hasOwnProperty.call(message, "dependency"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dependency);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.raw != null && Object.hasOwnProperty.call(message, "raw"))
                $root.microsoft_dsp.RawFile.encode(message.raw, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.zip != null && Object.hasOwnProperty.call(message, "zip"))
                $root.microsoft_dsp.ZipFile.encode(message.zip, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SinkInput message, length delimited. Does not implicitly {@link microsoft_dsp.SinkInput.verify|verify} messages.
         * @function encodeDelimited
         * @memberof microsoft_dsp.SinkInput
         * @static
         * @param {microsoft_dsp.ISinkInput} message SinkInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SinkInput.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SinkInput message from the specified reader or buffer.
         * @function decode
         * @memberof microsoft_dsp.SinkInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {microsoft_dsp.SinkInput} SinkInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SinkInput.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.microsoft_dsp.SinkInput();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dependency = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.raw = $root.microsoft_dsp.RawFile.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.zip = $root.microsoft_dsp.ZipFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SinkInput message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof microsoft_dsp.SinkInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {microsoft_dsp.SinkInput} SinkInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SinkInput.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SinkInput message.
         * @function verify
         * @memberof microsoft_dsp.SinkInput
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SinkInput.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                if (!$util.isString(message.dependency))
                    return "dependency: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.raw != null && message.hasOwnProperty("raw")) {
                properties.file = 1;
                {
                    let error = $root.microsoft_dsp.RawFile.verify(message.raw);
                    if (error)
                        return "raw." + error;
                }
            }
            if (message.zip != null && message.hasOwnProperty("zip")) {
                if (properties.file === 1)
                    return "file: multiple values";
                properties.file = 1;
                {
                    let error = $root.microsoft_dsp.ZipFile.verify(message.zip);
                    if (error)
                        return "zip." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SinkInput message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof microsoft_dsp.SinkInput
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {microsoft_dsp.SinkInput} SinkInput
         */
        SinkInput.fromObject = function fromObject(object) {
            if (object instanceof $root.microsoft_dsp.SinkInput)
                return object;
            let message = new $root.microsoft_dsp.SinkInput();
            if (object.dependency != null)
                message.dependency = String(object.dependency);
            if (object.name != null)
                message.name = String(object.name);
            if (object.raw != null) {
                if (typeof object.raw !== "object")
                    throw TypeError(".microsoft_dsp.SinkInput.raw: object expected");
                message.raw = $root.microsoft_dsp.RawFile.fromObject(object.raw);
            }
            if (object.zip != null) {
                if (typeof object.zip !== "object")
                    throw TypeError(".microsoft_dsp.SinkInput.zip: object expected");
                message.zip = $root.microsoft_dsp.ZipFile.fromObject(object.zip);
            }
            return message;
        };

        /**
         * Creates a plain object from a SinkInput message. Also converts values to other types if specified.
         * @function toObject
         * @memberof microsoft_dsp.SinkInput
         * @static
         * @param {microsoft_dsp.SinkInput} message SinkInput
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SinkInput.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.dependency = "";
                object.name = "";
            }
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                object.dependency = message.dependency;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.raw != null && message.hasOwnProperty("raw")) {
                object.raw = $root.microsoft_dsp.RawFile.toObject(message.raw, options);
                if (options.oneofs)
                    object.file = "raw";
            }
            if (message.zip != null && message.hasOwnProperty("zip")) {
                object.zip = $root.microsoft_dsp.ZipFile.toObject(message.zip, options);
                if (options.oneofs)
                    object.file = "zip";
            }
            return object;
        };

        /**
         * Converts this SinkInput to JSON.
         * @function toJSON
         * @memberof microsoft_dsp.SinkInput
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SinkInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SinkInput
         * @function getTypeUrl
         * @memberof microsoft_dsp.SinkInput
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SinkInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/microsoft_dsp.SinkInput";
        };

        return SinkInput;
    })();

    microsoft_dsp.MemberInfo = (function() {

        /**
         * Properties of a MemberInfo.
         * @memberof microsoft_dsp
         * @interface IMemberInfo
         * @property {number|null} [id] MemberInfo id
         * @property {string|null} [name] MemberInfo name
         */

        /**
         * Constructs a new MemberInfo.
         * @memberof microsoft_dsp
         * @classdesc Represents a MemberInfo.
         * @implements IMemberInfo
         * @constructor
         * @param {microsoft_dsp.IMemberInfo=} [properties] Properties to set
         */
        function MemberInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MemberInfo id.
         * @member {number} id
         * @memberof microsoft_dsp.MemberInfo
         * @instance
         */
        MemberInfo.prototype.id = 0;

        /**
         * MemberInfo name.
         * @member {string} name
         * @memberof microsoft_dsp.MemberInfo
         * @instance
         */
        MemberInfo.prototype.name = "";

        /**
         * Creates a new MemberInfo instance using the specified properties.
         * @function create
         * @memberof microsoft_dsp.MemberInfo
         * @static
         * @param {microsoft_dsp.IMemberInfo=} [properties] Properties to set
         * @returns {microsoft_dsp.MemberInfo} MemberInfo instance
         */
        MemberInfo.create = function create(properties) {
            return new MemberInfo(properties);
        };

        /**
         * Encodes the specified MemberInfo message. Does not implicitly {@link microsoft_dsp.MemberInfo.verify|verify} messages.
         * @function encode
         * @memberof microsoft_dsp.MemberInfo
         * @static
         * @param {microsoft_dsp.IMemberInfo} message MemberInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified MemberInfo message, length delimited. Does not implicitly {@link microsoft_dsp.MemberInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof microsoft_dsp.MemberInfo
         * @static
         * @param {microsoft_dsp.IMemberInfo} message MemberInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MemberInfo message from the specified reader or buffer.
         * @function decode
         * @memberof microsoft_dsp.MemberInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {microsoft_dsp.MemberInfo} MemberInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.microsoft_dsp.MemberInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MemberInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof microsoft_dsp.MemberInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {microsoft_dsp.MemberInfo} MemberInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MemberInfo message.
         * @function verify
         * @memberof microsoft_dsp.MemberInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MemberInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a MemberInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof microsoft_dsp.MemberInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {microsoft_dsp.MemberInfo} MemberInfo
         */
        MemberInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.microsoft_dsp.MemberInfo)
                return object;
            let message = new $root.microsoft_dsp.MemberInfo();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a MemberInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof microsoft_dsp.MemberInfo
         * @static
         * @param {microsoft_dsp.MemberInfo} message MemberInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemberInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = 0;
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this MemberInfo to JSON.
         * @function toJSON
         * @memberof microsoft_dsp.MemberInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemberInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MemberInfo
         * @function getTypeUrl
         * @memberof microsoft_dsp.MemberInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MemberInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/microsoft_dsp.MemberInfo";
        };

        return MemberInfo;
    })();

    microsoft_dsp.SegmentInfo = (function() {

        /**
         * Properties of a SegmentInfo.
         * @memberof microsoft_dsp
         * @interface ISegmentInfo
         * @property {string|null} [shortName] SegmentInfo shortName
         * @property {string|null} [code] SegmentInfo code
         */

        /**
         * Constructs a new SegmentInfo.
         * @memberof microsoft_dsp
         * @classdesc Represents a SegmentInfo.
         * @implements ISegmentInfo
         * @constructor
         * @param {microsoft_dsp.ISegmentInfo=} [properties] Properties to set
         */
        function SegmentInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SegmentInfo shortName.
         * @member {string} shortName
         * @memberof microsoft_dsp.SegmentInfo
         * @instance
         */
        SegmentInfo.prototype.shortName = "";

        /**
         * SegmentInfo code.
         * @member {string} code
         * @memberof microsoft_dsp.SegmentInfo
         * @instance
         */
        SegmentInfo.prototype.code = "";

        /**
         * Creates a new SegmentInfo instance using the specified properties.
         * @function create
         * @memberof microsoft_dsp.SegmentInfo
         * @static
         * @param {microsoft_dsp.ISegmentInfo=} [properties] Properties to set
         * @returns {microsoft_dsp.SegmentInfo} SegmentInfo instance
         */
        SegmentInfo.create = function create(properties) {
            return new SegmentInfo(properties);
        };

        /**
         * Encodes the specified SegmentInfo message. Does not implicitly {@link microsoft_dsp.SegmentInfo.verify|verify} messages.
         * @function encode
         * @memberof microsoft_dsp.SegmentInfo
         * @static
         * @param {microsoft_dsp.ISegmentInfo} message SegmentInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SegmentInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.shortName != null && Object.hasOwnProperty.call(message, "shortName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.shortName);
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
            return writer;
        };

        /**
         * Encodes the specified SegmentInfo message, length delimited. Does not implicitly {@link microsoft_dsp.SegmentInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof microsoft_dsp.SegmentInfo
         * @static
         * @param {microsoft_dsp.ISegmentInfo} message SegmentInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SegmentInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SegmentInfo message from the specified reader or buffer.
         * @function decode
         * @memberof microsoft_dsp.SegmentInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {microsoft_dsp.SegmentInfo} SegmentInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SegmentInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.microsoft_dsp.SegmentInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.shortName = reader.string();
                        break;
                    }
                case 2: {
                        message.code = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SegmentInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof microsoft_dsp.SegmentInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {microsoft_dsp.SegmentInfo} SegmentInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SegmentInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SegmentInfo message.
         * @function verify
         * @memberof microsoft_dsp.SegmentInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SegmentInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.shortName != null && message.hasOwnProperty("shortName"))
                if (!$util.isString(message.shortName))
                    return "shortName: string expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            return null;
        };

        /**
         * Creates a SegmentInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof microsoft_dsp.SegmentInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {microsoft_dsp.SegmentInfo} SegmentInfo
         */
        SegmentInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.microsoft_dsp.SegmentInfo)
                return object;
            let message = new $root.microsoft_dsp.SegmentInfo();
            if (object.shortName != null)
                message.shortName = String(object.shortName);
            if (object.code != null)
                message.code = String(object.code);
            return message;
        };

        /**
         * Creates a plain object from a SegmentInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof microsoft_dsp.SegmentInfo
         * @static
         * @param {microsoft_dsp.SegmentInfo} message SegmentInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SegmentInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.shortName = "";
                object.code = "";
            }
            if (message.shortName != null && message.hasOwnProperty("shortName"))
                object.shortName = message.shortName;
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            return object;
        };

        /**
         * Converts this SegmentInfo to JSON.
         * @function toJSON
         * @memberof microsoft_dsp.SegmentInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SegmentInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SegmentInfo
         * @function getTypeUrl
         * @memberof microsoft_dsp.SegmentInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SegmentInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/microsoft_dsp.SegmentInfo";
        };

        return SegmentInfo;
    })();

    microsoft_dsp.MicrosoftDspWorkerConfiguration = (function() {

        /**
         * Properties of a MicrosoftDspWorkerConfiguration.
         * @memberof microsoft_dsp
         * @interface IMicrosoftDspWorkerConfiguration
         * @property {microsoft_dsp.ISinkInput|null} [input] MicrosoftDspWorkerConfiguration input
         * @property {microsoft_dsp.IMemberInfo|null} [memberInfo] MicrosoftDspWorkerConfiguration memberInfo
         * @property {microsoft_dsp.ISegmentInfo|null} [segmentInfo] MicrosoftDspWorkerConfiguration segmentInfo
         */

        /**
         * Constructs a new MicrosoftDspWorkerConfiguration.
         * @memberof microsoft_dsp
         * @classdesc Represents a MicrosoftDspWorkerConfiguration.
         * @implements IMicrosoftDspWorkerConfiguration
         * @constructor
         * @param {microsoft_dsp.IMicrosoftDspWorkerConfiguration=} [properties] Properties to set
         */
        function MicrosoftDspWorkerConfiguration(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MicrosoftDspWorkerConfiguration input.
         * @member {microsoft_dsp.ISinkInput|null|undefined} input
         * @memberof microsoft_dsp.MicrosoftDspWorkerConfiguration
         * @instance
         */
        MicrosoftDspWorkerConfiguration.prototype.input = null;

        /**
         * MicrosoftDspWorkerConfiguration memberInfo.
         * @member {microsoft_dsp.IMemberInfo|null|undefined} memberInfo
         * @memberof microsoft_dsp.MicrosoftDspWorkerConfiguration
         * @instance
         */
        MicrosoftDspWorkerConfiguration.prototype.memberInfo = null;

        /**
         * MicrosoftDspWorkerConfiguration segmentInfo.
         * @member {microsoft_dsp.ISegmentInfo|null|undefined} segmentInfo
         * @memberof microsoft_dsp.MicrosoftDspWorkerConfiguration
         * @instance
         */
        MicrosoftDspWorkerConfiguration.prototype.segmentInfo = null;

        /**
         * Creates a new MicrosoftDspWorkerConfiguration instance using the specified properties.
         * @function create
         * @memberof microsoft_dsp.MicrosoftDspWorkerConfiguration
         * @static
         * @param {microsoft_dsp.IMicrosoftDspWorkerConfiguration=} [properties] Properties to set
         * @returns {microsoft_dsp.MicrosoftDspWorkerConfiguration} MicrosoftDspWorkerConfiguration instance
         */
        MicrosoftDspWorkerConfiguration.create = function create(properties) {
            return new MicrosoftDspWorkerConfiguration(properties);
        };

        /**
         * Encodes the specified MicrosoftDspWorkerConfiguration message. Does not implicitly {@link microsoft_dsp.MicrosoftDspWorkerConfiguration.verify|verify} messages.
         * @function encode
         * @memberof microsoft_dsp.MicrosoftDspWorkerConfiguration
         * @static
         * @param {microsoft_dsp.IMicrosoftDspWorkerConfiguration} message MicrosoftDspWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MicrosoftDspWorkerConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.input != null && Object.hasOwnProperty.call(message, "input"))
                $root.microsoft_dsp.SinkInput.encode(message.input, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.memberInfo != null && Object.hasOwnProperty.call(message, "memberInfo"))
                $root.microsoft_dsp.MemberInfo.encode(message.memberInfo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.segmentInfo != null && Object.hasOwnProperty.call(message, "segmentInfo"))
                $root.microsoft_dsp.SegmentInfo.encode(message.segmentInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MicrosoftDspWorkerConfiguration message, length delimited. Does not implicitly {@link microsoft_dsp.MicrosoftDspWorkerConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof microsoft_dsp.MicrosoftDspWorkerConfiguration
         * @static
         * @param {microsoft_dsp.IMicrosoftDspWorkerConfiguration} message MicrosoftDspWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MicrosoftDspWorkerConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MicrosoftDspWorkerConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof microsoft_dsp.MicrosoftDspWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {microsoft_dsp.MicrosoftDspWorkerConfiguration} MicrosoftDspWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MicrosoftDspWorkerConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.microsoft_dsp.MicrosoftDspWorkerConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.input = $root.microsoft_dsp.SinkInput.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.memberInfo = $root.microsoft_dsp.MemberInfo.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.segmentInfo = $root.microsoft_dsp.SegmentInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MicrosoftDspWorkerConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof microsoft_dsp.MicrosoftDspWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {microsoft_dsp.MicrosoftDspWorkerConfiguration} MicrosoftDspWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MicrosoftDspWorkerConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MicrosoftDspWorkerConfiguration message.
         * @function verify
         * @memberof microsoft_dsp.MicrosoftDspWorkerConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MicrosoftDspWorkerConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.input != null && message.hasOwnProperty("input")) {
                let error = $root.microsoft_dsp.SinkInput.verify(message.input);
                if (error)
                    return "input." + error;
            }
            if (message.memberInfo != null && message.hasOwnProperty("memberInfo")) {
                let error = $root.microsoft_dsp.MemberInfo.verify(message.memberInfo);
                if (error)
                    return "memberInfo." + error;
            }
            if (message.segmentInfo != null && message.hasOwnProperty("segmentInfo")) {
                let error = $root.microsoft_dsp.SegmentInfo.verify(message.segmentInfo);
                if (error)
                    return "segmentInfo." + error;
            }
            return null;
        };

        /**
         * Creates a MicrosoftDspWorkerConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof microsoft_dsp.MicrosoftDspWorkerConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {microsoft_dsp.MicrosoftDspWorkerConfiguration} MicrosoftDspWorkerConfiguration
         */
        MicrosoftDspWorkerConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.microsoft_dsp.MicrosoftDspWorkerConfiguration)
                return object;
            let message = new $root.microsoft_dsp.MicrosoftDspWorkerConfiguration();
            if (object.input != null) {
                if (typeof object.input !== "object")
                    throw TypeError(".microsoft_dsp.MicrosoftDspWorkerConfiguration.input: object expected");
                message.input = $root.microsoft_dsp.SinkInput.fromObject(object.input);
            }
            if (object.memberInfo != null) {
                if (typeof object.memberInfo !== "object")
                    throw TypeError(".microsoft_dsp.MicrosoftDspWorkerConfiguration.memberInfo: object expected");
                message.memberInfo = $root.microsoft_dsp.MemberInfo.fromObject(object.memberInfo);
            }
            if (object.segmentInfo != null) {
                if (typeof object.segmentInfo !== "object")
                    throw TypeError(".microsoft_dsp.MicrosoftDspWorkerConfiguration.segmentInfo: object expected");
                message.segmentInfo = $root.microsoft_dsp.SegmentInfo.fromObject(object.segmentInfo);
            }
            return message;
        };

        /**
         * Creates a plain object from a MicrosoftDspWorkerConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof microsoft_dsp.MicrosoftDspWorkerConfiguration
         * @static
         * @param {microsoft_dsp.MicrosoftDspWorkerConfiguration} message MicrosoftDspWorkerConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MicrosoftDspWorkerConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.input = null;
                object.memberInfo = null;
                object.segmentInfo = null;
            }
            if (message.input != null && message.hasOwnProperty("input"))
                object.input = $root.microsoft_dsp.SinkInput.toObject(message.input, options);
            if (message.memberInfo != null && message.hasOwnProperty("memberInfo"))
                object.memberInfo = $root.microsoft_dsp.MemberInfo.toObject(message.memberInfo, options);
            if (message.segmentInfo != null && message.hasOwnProperty("segmentInfo"))
                object.segmentInfo = $root.microsoft_dsp.SegmentInfo.toObject(message.segmentInfo, options);
            return object;
        };

        /**
         * Converts this MicrosoftDspWorkerConfiguration to JSON.
         * @function toJSON
         * @memberof microsoft_dsp.MicrosoftDspWorkerConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MicrosoftDspWorkerConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MicrosoftDspWorkerConfiguration
         * @function getTypeUrl
         * @memberof microsoft_dsp.MicrosoftDspWorkerConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MicrosoftDspWorkerConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/microsoft_dsp.MicrosoftDspWorkerConfiguration";
        };

        return MicrosoftDspWorkerConfiguration;
    })();

    return microsoft_dsp;
})();

export const amazon_amc = $root.amazon_amc = (() => {

    /**
     * Namespace amazon_amc.
     * @exports amazon_amc
     * @namespace
     */
    const amazon_amc = {};

    amazon_amc.SingleFile = (function() {

        /**
         * Properties of a SingleFile.
         * @memberof amazon_amc
         * @interface ISingleFile
         * @property {string|null} [name] SingleFile name
         */

        /**
         * Constructs a new SingleFile.
         * @memberof amazon_amc
         * @classdesc Represents a SingleFile.
         * @implements ISingleFile
         * @constructor
         * @param {amazon_amc.ISingleFile=} [properties] Properties to set
         */
        function SingleFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SingleFile name.
         * @member {string} name
         * @memberof amazon_amc.SingleFile
         * @instance
         */
        SingleFile.prototype.name = "";

        /**
         * Creates a new SingleFile instance using the specified properties.
         * @function create
         * @memberof amazon_amc.SingleFile
         * @static
         * @param {amazon_amc.ISingleFile=} [properties] Properties to set
         * @returns {amazon_amc.SingleFile} SingleFile instance
         */
        SingleFile.create = function create(properties) {
            return new SingleFile(properties);
        };

        /**
         * Encodes the specified SingleFile message. Does not implicitly {@link amazon_amc.SingleFile.verify|verify} messages.
         * @function encode
         * @memberof amazon_amc.SingleFile
         * @static
         * @param {amazon_amc.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified SingleFile message, length delimited. Does not implicitly {@link amazon_amc.SingleFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof amazon_amc.SingleFile
         * @static
         * @param {amazon_amc.ISingleFile} message SingleFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer.
         * @function decode
         * @memberof amazon_amc.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {amazon_amc.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.amazon_amc.SingleFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SingleFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof amazon_amc.SingleFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {amazon_amc.SingleFile} SingleFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SingleFile message.
         * @function verify
         * @memberof amazon_amc.SingleFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SingleFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a SingleFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof amazon_amc.SingleFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {amazon_amc.SingleFile} SingleFile
         */
        SingleFile.fromObject = function fromObject(object) {
            if (object instanceof $root.amazon_amc.SingleFile)
                return object;
            let message = new $root.amazon_amc.SingleFile();
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a SingleFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof amazon_amc.SingleFile
         * @static
         * @param {amazon_amc.SingleFile} message SingleFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SingleFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this SingleFile to JSON.
         * @function toJSON
         * @memberof amazon_amc.SingleFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SingleFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SingleFile
         * @function getTypeUrl
         * @memberof amazon_amc.SingleFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SingleFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/amazon_amc.SingleFile";
        };

        return SingleFile;
    })();

    amazon_amc.RawFile = (function() {

        /**
         * Properties of a RawFile.
         * @memberof amazon_amc
         * @interface IRawFile
         */

        /**
         * Constructs a new RawFile.
         * @memberof amazon_amc
         * @classdesc Represents a RawFile.
         * @implements IRawFile
         * @constructor
         * @param {amazon_amc.IRawFile=} [properties] Properties to set
         */
        function RawFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RawFile instance using the specified properties.
         * @function create
         * @memberof amazon_amc.RawFile
         * @static
         * @param {amazon_amc.IRawFile=} [properties] Properties to set
         * @returns {amazon_amc.RawFile} RawFile instance
         */
        RawFile.create = function create(properties) {
            return new RawFile(properties);
        };

        /**
         * Encodes the specified RawFile message. Does not implicitly {@link amazon_amc.RawFile.verify|verify} messages.
         * @function encode
         * @memberof amazon_amc.RawFile
         * @static
         * @param {amazon_amc.IRawFile} message RawFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RawFile message, length delimited. Does not implicitly {@link amazon_amc.RawFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof amazon_amc.RawFile
         * @static
         * @param {amazon_amc.IRawFile} message RawFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RawFile message from the specified reader or buffer.
         * @function decode
         * @memberof amazon_amc.RawFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {amazon_amc.RawFile} RawFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.amazon_amc.RawFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RawFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof amazon_amc.RawFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {amazon_amc.RawFile} RawFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RawFile message.
         * @function verify
         * @memberof amazon_amc.RawFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RawFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RawFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof amazon_amc.RawFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {amazon_amc.RawFile} RawFile
         */
        RawFile.fromObject = function fromObject(object) {
            if (object instanceof $root.amazon_amc.RawFile)
                return object;
            return new $root.amazon_amc.RawFile();
        };

        /**
         * Creates a plain object from a RawFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof amazon_amc.RawFile
         * @static
         * @param {amazon_amc.RawFile} message RawFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RawFile.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RawFile to JSON.
         * @function toJSON
         * @memberof amazon_amc.RawFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RawFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RawFile
         * @function getTypeUrl
         * @memberof amazon_amc.RawFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RawFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/amazon_amc.RawFile";
        };

        return RawFile;
    })();

    amazon_amc.ZipFile = (function() {

        /**
         * Properties of a ZipFile.
         * @memberof amazon_amc
         * @interface IZipFile
         * @property {amazon_amc.ISingleFile|null} [singleFile] ZipFile singleFile
         */

        /**
         * Constructs a new ZipFile.
         * @memberof amazon_amc
         * @classdesc Represents a ZipFile.
         * @implements IZipFile
         * @constructor
         * @param {amazon_amc.IZipFile=} [properties] Properties to set
         */
        function ZipFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ZipFile singleFile.
         * @member {amazon_amc.ISingleFile|null|undefined} singleFile
         * @memberof amazon_amc.ZipFile
         * @instance
         */
        ZipFile.prototype.singleFile = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ZipFile selection.
         * @member {"singleFile"|undefined} selection
         * @memberof amazon_amc.ZipFile
         * @instance
         */
        Object.defineProperty(ZipFile.prototype, "selection", {
            get: $util.oneOfGetter($oneOfFields = ["singleFile"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ZipFile instance using the specified properties.
         * @function create
         * @memberof amazon_amc.ZipFile
         * @static
         * @param {amazon_amc.IZipFile=} [properties] Properties to set
         * @returns {amazon_amc.ZipFile} ZipFile instance
         */
        ZipFile.create = function create(properties) {
            return new ZipFile(properties);
        };

        /**
         * Encodes the specified ZipFile message. Does not implicitly {@link amazon_amc.ZipFile.verify|verify} messages.
         * @function encode
         * @memberof amazon_amc.ZipFile
         * @static
         * @param {amazon_amc.IZipFile} message ZipFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.singleFile != null && Object.hasOwnProperty.call(message, "singleFile"))
                $root.amazon_amc.SingleFile.encode(message.singleFile, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ZipFile message, length delimited. Does not implicitly {@link amazon_amc.ZipFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof amazon_amc.ZipFile
         * @static
         * @param {amazon_amc.IZipFile} message ZipFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZipFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ZipFile message from the specified reader or buffer.
         * @function decode
         * @memberof amazon_amc.ZipFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {amazon_amc.ZipFile} ZipFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.amazon_amc.ZipFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.singleFile = $root.amazon_amc.SingleFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ZipFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof amazon_amc.ZipFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {amazon_amc.ZipFile} ZipFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZipFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ZipFile message.
         * @function verify
         * @memberof amazon_amc.ZipFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ZipFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.singleFile != null && message.hasOwnProperty("singleFile")) {
                properties.selection = 1;
                {
                    let error = $root.amazon_amc.SingleFile.verify(message.singleFile);
                    if (error)
                        return "singleFile." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ZipFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof amazon_amc.ZipFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {amazon_amc.ZipFile} ZipFile
         */
        ZipFile.fromObject = function fromObject(object) {
            if (object instanceof $root.amazon_amc.ZipFile)
                return object;
            let message = new $root.amazon_amc.ZipFile();
            if (object.singleFile != null) {
                if (typeof object.singleFile !== "object")
                    throw TypeError(".amazon_amc.ZipFile.singleFile: object expected");
                message.singleFile = $root.amazon_amc.SingleFile.fromObject(object.singleFile);
            }
            return message;
        };

        /**
         * Creates a plain object from a ZipFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof amazon_amc.ZipFile
         * @static
         * @param {amazon_amc.ZipFile} message ZipFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ZipFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.singleFile != null && message.hasOwnProperty("singleFile")) {
                object.singleFile = $root.amazon_amc.SingleFile.toObject(message.singleFile, options);
                if (options.oneofs)
                    object.selection = "singleFile";
            }
            return object;
        };

        /**
         * Converts this ZipFile to JSON.
         * @function toJSON
         * @memberof amazon_amc.ZipFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ZipFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ZipFile
         * @function getTypeUrl
         * @memberof amazon_amc.ZipFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ZipFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/amazon_amc.ZipFile";
        };

        return ZipFile;
    })();

    amazon_amc.DataInput = (function() {

        /**
         * Properties of a DataInput.
         * @memberof amazon_amc
         * @interface IDataInput
         * @property {string|null} [dependency] DataInput dependency
         * @property {string|null} [name] DataInput name
         * @property {amazon_amc.IRawFile|null} [raw] DataInput raw
         * @property {amazon_amc.IZipFile|null} [zip] DataInput zip
         */

        /**
         * Constructs a new DataInput.
         * @memberof amazon_amc
         * @classdesc Represents a DataInput.
         * @implements IDataInput
         * @constructor
         * @param {amazon_amc.IDataInput=} [properties] Properties to set
         */
        function DataInput(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DataInput dependency.
         * @member {string} dependency
         * @memberof amazon_amc.DataInput
         * @instance
         */
        DataInput.prototype.dependency = "";

        /**
         * DataInput name.
         * @member {string} name
         * @memberof amazon_amc.DataInput
         * @instance
         */
        DataInput.prototype.name = "";

        /**
         * DataInput raw.
         * @member {amazon_amc.IRawFile|null|undefined} raw
         * @memberof amazon_amc.DataInput
         * @instance
         */
        DataInput.prototype.raw = null;

        /**
         * DataInput zip.
         * @member {amazon_amc.IZipFile|null|undefined} zip
         * @memberof amazon_amc.DataInput
         * @instance
         */
        DataInput.prototype.zip = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * DataInput file.
         * @member {"raw"|"zip"|undefined} file
         * @memberof amazon_amc.DataInput
         * @instance
         */
        Object.defineProperty(DataInput.prototype, "file", {
            get: $util.oneOfGetter($oneOfFields = ["raw", "zip"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new DataInput instance using the specified properties.
         * @function create
         * @memberof amazon_amc.DataInput
         * @static
         * @param {amazon_amc.IDataInput=} [properties] Properties to set
         * @returns {amazon_amc.DataInput} DataInput instance
         */
        DataInput.create = function create(properties) {
            return new DataInput(properties);
        };

        /**
         * Encodes the specified DataInput message. Does not implicitly {@link amazon_amc.DataInput.verify|verify} messages.
         * @function encode
         * @memberof amazon_amc.DataInput
         * @static
         * @param {amazon_amc.IDataInput} message DataInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataInput.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dependency != null && Object.hasOwnProperty.call(message, "dependency"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dependency);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.raw != null && Object.hasOwnProperty.call(message, "raw"))
                $root.amazon_amc.RawFile.encode(message.raw, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.zip != null && Object.hasOwnProperty.call(message, "zip"))
                $root.amazon_amc.ZipFile.encode(message.zip, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DataInput message, length delimited. Does not implicitly {@link amazon_amc.DataInput.verify|verify} messages.
         * @function encodeDelimited
         * @memberof amazon_amc.DataInput
         * @static
         * @param {amazon_amc.IDataInput} message DataInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataInput.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DataInput message from the specified reader or buffer.
         * @function decode
         * @memberof amazon_amc.DataInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {amazon_amc.DataInput} DataInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataInput.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.amazon_amc.DataInput();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dependency = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.raw = $root.amazon_amc.RawFile.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.zip = $root.amazon_amc.ZipFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DataInput message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof amazon_amc.DataInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {amazon_amc.DataInput} DataInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataInput.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DataInput message.
         * @function verify
         * @memberof amazon_amc.DataInput
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DataInput.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                if (!$util.isString(message.dependency))
                    return "dependency: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.raw != null && message.hasOwnProperty("raw")) {
                properties.file = 1;
                {
                    let error = $root.amazon_amc.RawFile.verify(message.raw);
                    if (error)
                        return "raw." + error;
                }
            }
            if (message.zip != null && message.hasOwnProperty("zip")) {
                if (properties.file === 1)
                    return "file: multiple values";
                properties.file = 1;
                {
                    let error = $root.amazon_amc.ZipFile.verify(message.zip);
                    if (error)
                        return "zip." + error;
                }
            }
            return null;
        };

        /**
         * Creates a DataInput message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof amazon_amc.DataInput
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {amazon_amc.DataInput} DataInput
         */
        DataInput.fromObject = function fromObject(object) {
            if (object instanceof $root.amazon_amc.DataInput)
                return object;
            let message = new $root.amazon_amc.DataInput();
            if (object.dependency != null)
                message.dependency = String(object.dependency);
            if (object.name != null)
                message.name = String(object.name);
            if (object.raw != null) {
                if (typeof object.raw !== "object")
                    throw TypeError(".amazon_amc.DataInput.raw: object expected");
                message.raw = $root.amazon_amc.RawFile.fromObject(object.raw);
            }
            if (object.zip != null) {
                if (typeof object.zip !== "object")
                    throw TypeError(".amazon_amc.DataInput.zip: object expected");
                message.zip = $root.amazon_amc.ZipFile.fromObject(object.zip);
            }
            return message;
        };

        /**
         * Creates a plain object from a DataInput message. Also converts values to other types if specified.
         * @function toObject
         * @memberof amazon_amc.DataInput
         * @static
         * @param {amazon_amc.DataInput} message DataInput
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DataInput.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.dependency = "";
                object.name = "";
            }
            if (message.dependency != null && message.hasOwnProperty("dependency"))
                object.dependency = message.dependency;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.raw != null && message.hasOwnProperty("raw")) {
                object.raw = $root.amazon_amc.RawFile.toObject(message.raw, options);
                if (options.oneofs)
                    object.file = "raw";
            }
            if (message.zip != null && message.hasOwnProperty("zip")) {
                object.zip = $root.amazon_amc.ZipFile.toObject(message.zip, options);
                if (options.oneofs)
                    object.file = "zip";
            }
            return object;
        };

        /**
         * Converts this DataInput to JSON.
         * @function toJSON
         * @memberof amazon_amc.DataInput
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DataInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DataInput
         * @function getTypeUrl
         * @memberof amazon_amc.DataInput
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DataInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/amazon_amc.DataInput";
        };

        return DataInput;
    })();

    /**
     * IdFormat enum.
     * @name amazon_amc.IdFormat
     * @enum {number}
     * @property {number} UNHASHED_EMAIL=0 UNHASHED_EMAIL value
     * @property {number} HASHED_EMAIL_SHA256=1 HASHED_EMAIL_SHA256 value
     * @property {number} PHONE_NR=2 PHONE_NR value
     * @property {number} HASHED_PHONE_NR_SHA256=3 HASHED_PHONE_NR_SHA256 value
     */
    amazon_amc.IdFormat = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNHASHED_EMAIL"] = 0;
        values[valuesById[1] = "HASHED_EMAIL_SHA256"] = 1;
        values[valuesById[2] = "PHONE_NR"] = 2;
        values[valuesById[3] = "HASHED_PHONE_NR_SHA256"] = 3;
        return values;
    })();

    /**
     * Country enum.
     * @name amazon_amc.Country
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} UNITED_ARAB_EMIRATES=1 UNITED_ARAB_EMIRATES value
     * @property {number} AUSTRALIA=2 AUSTRALIA value
     * @property {number} BELGIUM=3 BELGIUM value
     * @property {number} BRAZIL=4 BRAZIL value
     * @property {number} CANADA=5 CANADA value
     * @property {number} GERMANY=6 GERMANY value
     * @property {number} EGYPT=7 EGYPT value
     * @property {number} SPAIN=8 SPAIN value
     * @property {number} FRANCE=9 FRANCE value
     * @property {number} UNITED_KINGDOM=10 UNITED_KINGDOM value
     * @property {number} INDIA=11 INDIA value
     * @property {number} ITALY=12 ITALY value
     * @property {number} JAPAN=13 JAPAN value
     * @property {number} MEXICO=14 MEXICO value
     * @property {number} NETHERLANDS=15 NETHERLANDS value
     * @property {number} POLAND=16 POLAND value
     * @property {number} SAUDI_ARABIA=17 SAUDI_ARABIA value
     * @property {number} SWEDEN=18 SWEDEN value
     * @property {number} SINGAPORE=19 SINGAPORE value
     * @property {number} TURKEY=20 TURKEY value
     * @property {number} UNITED_STATES_OF_AMERICA=21 UNITED_STATES_OF_AMERICA value
     */
    amazon_amc.Country = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "UNITED_ARAB_EMIRATES"] = 1;
        values[valuesById[2] = "AUSTRALIA"] = 2;
        values[valuesById[3] = "BELGIUM"] = 3;
        values[valuesById[4] = "BRAZIL"] = 4;
        values[valuesById[5] = "CANADA"] = 5;
        values[valuesById[6] = "GERMANY"] = 6;
        values[valuesById[7] = "EGYPT"] = 7;
        values[valuesById[8] = "SPAIN"] = 8;
        values[valuesById[9] = "FRANCE"] = 9;
        values[valuesById[10] = "UNITED_KINGDOM"] = 10;
        values[valuesById[11] = "INDIA"] = 11;
        values[valuesById[12] = "ITALY"] = 12;
        values[valuesById[13] = "JAPAN"] = 13;
        values[valuesById[14] = "MEXICO"] = 14;
        values[valuesById[15] = "NETHERLANDS"] = 15;
        values[valuesById[16] = "POLAND"] = 16;
        values[valuesById[17] = "SAUDI_ARABIA"] = 17;
        values[valuesById[18] = "SWEDEN"] = 18;
        values[valuesById[19] = "SINGAPORE"] = 19;
        values[valuesById[20] = "TURKEY"] = 20;
        values[valuesById[21] = "UNITED_STATES_OF_AMERICA"] = 21;
        return values;
    })();

    amazon_amc.UnHashedEmail = (function() {

        /**
         * Properties of an UnHashedEmail.
         * @memberof amazon_amc
         * @interface IUnHashedEmail
         */

        /**
         * Constructs a new UnHashedEmail.
         * @memberof amazon_amc
         * @classdesc Represents an UnHashedEmail.
         * @implements IUnHashedEmail
         * @constructor
         * @param {amazon_amc.IUnHashedEmail=} [properties] Properties to set
         */
        function UnHashedEmail(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UnHashedEmail instance using the specified properties.
         * @function create
         * @memberof amazon_amc.UnHashedEmail
         * @static
         * @param {amazon_amc.IUnHashedEmail=} [properties] Properties to set
         * @returns {amazon_amc.UnHashedEmail} UnHashedEmail instance
         */
        UnHashedEmail.create = function create(properties) {
            return new UnHashedEmail(properties);
        };

        /**
         * Encodes the specified UnHashedEmail message. Does not implicitly {@link amazon_amc.UnHashedEmail.verify|verify} messages.
         * @function encode
         * @memberof amazon_amc.UnHashedEmail
         * @static
         * @param {amazon_amc.IUnHashedEmail} message UnHashedEmail message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnHashedEmail.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UnHashedEmail message, length delimited. Does not implicitly {@link amazon_amc.UnHashedEmail.verify|verify} messages.
         * @function encodeDelimited
         * @memberof amazon_amc.UnHashedEmail
         * @static
         * @param {amazon_amc.IUnHashedEmail} message UnHashedEmail message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnHashedEmail.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UnHashedEmail message from the specified reader or buffer.
         * @function decode
         * @memberof amazon_amc.UnHashedEmail
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {amazon_amc.UnHashedEmail} UnHashedEmail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnHashedEmail.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.amazon_amc.UnHashedEmail();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UnHashedEmail message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof amazon_amc.UnHashedEmail
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {amazon_amc.UnHashedEmail} UnHashedEmail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnHashedEmail.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UnHashedEmail message.
         * @function verify
         * @memberof amazon_amc.UnHashedEmail
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UnHashedEmail.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UnHashedEmail message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof amazon_amc.UnHashedEmail
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {amazon_amc.UnHashedEmail} UnHashedEmail
         */
        UnHashedEmail.fromObject = function fromObject(object) {
            if (object instanceof $root.amazon_amc.UnHashedEmail)
                return object;
            return new $root.amazon_amc.UnHashedEmail();
        };

        /**
         * Creates a plain object from an UnHashedEmail message. Also converts values to other types if specified.
         * @function toObject
         * @memberof amazon_amc.UnHashedEmail
         * @static
         * @param {amazon_amc.UnHashedEmail} message UnHashedEmail
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UnHashedEmail.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UnHashedEmail to JSON.
         * @function toJSON
         * @memberof amazon_amc.UnHashedEmail
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UnHashedEmail.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UnHashedEmail
         * @function getTypeUrl
         * @memberof amazon_amc.UnHashedEmail
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UnHashedEmail.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/amazon_amc.UnHashedEmail";
        };

        return UnHashedEmail;
    })();

    amazon_amc.HashedEmailSha256 = (function() {

        /**
         * Properties of a HashedEmailSha256.
         * @memberof amazon_amc
         * @interface IHashedEmailSha256
         */

        /**
         * Constructs a new HashedEmailSha256.
         * @memberof amazon_amc
         * @classdesc Represents a HashedEmailSha256.
         * @implements IHashedEmailSha256
         * @constructor
         * @param {amazon_amc.IHashedEmailSha256=} [properties] Properties to set
         */
        function HashedEmailSha256(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new HashedEmailSha256 instance using the specified properties.
         * @function create
         * @memberof amazon_amc.HashedEmailSha256
         * @static
         * @param {amazon_amc.IHashedEmailSha256=} [properties] Properties to set
         * @returns {amazon_amc.HashedEmailSha256} HashedEmailSha256 instance
         */
        HashedEmailSha256.create = function create(properties) {
            return new HashedEmailSha256(properties);
        };

        /**
         * Encodes the specified HashedEmailSha256 message. Does not implicitly {@link amazon_amc.HashedEmailSha256.verify|verify} messages.
         * @function encode
         * @memberof amazon_amc.HashedEmailSha256
         * @static
         * @param {amazon_amc.IHashedEmailSha256} message HashedEmailSha256 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HashedEmailSha256.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified HashedEmailSha256 message, length delimited. Does not implicitly {@link amazon_amc.HashedEmailSha256.verify|verify} messages.
         * @function encodeDelimited
         * @memberof amazon_amc.HashedEmailSha256
         * @static
         * @param {amazon_amc.IHashedEmailSha256} message HashedEmailSha256 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HashedEmailSha256.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HashedEmailSha256 message from the specified reader or buffer.
         * @function decode
         * @memberof amazon_amc.HashedEmailSha256
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {amazon_amc.HashedEmailSha256} HashedEmailSha256
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HashedEmailSha256.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.amazon_amc.HashedEmailSha256();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HashedEmailSha256 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof amazon_amc.HashedEmailSha256
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {amazon_amc.HashedEmailSha256} HashedEmailSha256
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HashedEmailSha256.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HashedEmailSha256 message.
         * @function verify
         * @memberof amazon_amc.HashedEmailSha256
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HashedEmailSha256.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a HashedEmailSha256 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof amazon_amc.HashedEmailSha256
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {amazon_amc.HashedEmailSha256} HashedEmailSha256
         */
        HashedEmailSha256.fromObject = function fromObject(object) {
            if (object instanceof $root.amazon_amc.HashedEmailSha256)
                return object;
            return new $root.amazon_amc.HashedEmailSha256();
        };

        /**
         * Creates a plain object from a HashedEmailSha256 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof amazon_amc.HashedEmailSha256
         * @static
         * @param {amazon_amc.HashedEmailSha256} message HashedEmailSha256
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HashedEmailSha256.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this HashedEmailSha256 to JSON.
         * @function toJSON
         * @memberof amazon_amc.HashedEmailSha256
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HashedEmailSha256.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for HashedEmailSha256
         * @function getTypeUrl
         * @memberof amazon_amc.HashedEmailSha256
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        HashedEmailSha256.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/amazon_amc.HashedEmailSha256";
        };

        return HashedEmailSha256;
    })();

    amazon_amc.TargetResource = (function() {

        /**
         * Properties of a TargetResource.
         * @memberof amazon_amc
         * @interface ITargetResource
         * @property {string|null} [connectionId] TargetResource connectionId
         * @property {string|null} [advertiserId] TargetResource advertiserId
         */

        /**
         * Constructs a new TargetResource.
         * @memberof amazon_amc
         * @classdesc Represents a TargetResource.
         * @implements ITargetResource
         * @constructor
         * @param {amazon_amc.ITargetResource=} [properties] Properties to set
         */
        function TargetResource(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TargetResource connectionId.
         * @member {string|null|undefined} connectionId
         * @memberof amazon_amc.TargetResource
         * @instance
         */
        TargetResource.prototype.connectionId = null;

        /**
         * TargetResource advertiserId.
         * @member {string|null|undefined} advertiserId
         * @memberof amazon_amc.TargetResource
         * @instance
         */
        TargetResource.prototype.advertiserId = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * TargetResource target.
         * @member {"connectionId"|"advertiserId"|undefined} target
         * @memberof amazon_amc.TargetResource
         * @instance
         */
        Object.defineProperty(TargetResource.prototype, "target", {
            get: $util.oneOfGetter($oneOfFields = ["connectionId", "advertiserId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new TargetResource instance using the specified properties.
         * @function create
         * @memberof amazon_amc.TargetResource
         * @static
         * @param {amazon_amc.ITargetResource=} [properties] Properties to set
         * @returns {amazon_amc.TargetResource} TargetResource instance
         */
        TargetResource.create = function create(properties) {
            return new TargetResource(properties);
        };

        /**
         * Encodes the specified TargetResource message. Does not implicitly {@link amazon_amc.TargetResource.verify|verify} messages.
         * @function encode
         * @memberof amazon_amc.TargetResource
         * @static
         * @param {amazon_amc.ITargetResource} message TargetResource message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TargetResource.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.connectionId != null && Object.hasOwnProperty.call(message, "connectionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.connectionId);
            if (message.advertiserId != null && Object.hasOwnProperty.call(message, "advertiserId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.advertiserId);
            return writer;
        };

        /**
         * Encodes the specified TargetResource message, length delimited. Does not implicitly {@link amazon_amc.TargetResource.verify|verify} messages.
         * @function encodeDelimited
         * @memberof amazon_amc.TargetResource
         * @static
         * @param {amazon_amc.ITargetResource} message TargetResource message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TargetResource.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TargetResource message from the specified reader or buffer.
         * @function decode
         * @memberof amazon_amc.TargetResource
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {amazon_amc.TargetResource} TargetResource
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TargetResource.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.amazon_amc.TargetResource();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.connectionId = reader.string();
                        break;
                    }
                case 2: {
                        message.advertiserId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TargetResource message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof amazon_amc.TargetResource
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {amazon_amc.TargetResource} TargetResource
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TargetResource.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TargetResource message.
         * @function verify
         * @memberof amazon_amc.TargetResource
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TargetResource.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.connectionId != null && message.hasOwnProperty("connectionId")) {
                properties.target = 1;
                if (!$util.isString(message.connectionId))
                    return "connectionId: string expected";
            }
            if (message.advertiserId != null && message.hasOwnProperty("advertiserId")) {
                if (properties.target === 1)
                    return "target: multiple values";
                properties.target = 1;
                if (!$util.isString(message.advertiserId))
                    return "advertiserId: string expected";
            }
            return null;
        };

        /**
         * Creates a TargetResource message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof amazon_amc.TargetResource
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {amazon_amc.TargetResource} TargetResource
         */
        TargetResource.fromObject = function fromObject(object) {
            if (object instanceof $root.amazon_amc.TargetResource)
                return object;
            let message = new $root.amazon_amc.TargetResource();
            if (object.connectionId != null)
                message.connectionId = String(object.connectionId);
            if (object.advertiserId != null)
                message.advertiserId = String(object.advertiserId);
            return message;
        };

        /**
         * Creates a plain object from a TargetResource message. Also converts values to other types if specified.
         * @function toObject
         * @memberof amazon_amc.TargetResource
         * @static
         * @param {amazon_amc.TargetResource} message TargetResource
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TargetResource.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.connectionId != null && message.hasOwnProperty("connectionId")) {
                object.connectionId = message.connectionId;
                if (options.oneofs)
                    object.target = "connectionId";
            }
            if (message.advertiserId != null && message.hasOwnProperty("advertiserId")) {
                object.advertiserId = message.advertiserId;
                if (options.oneofs)
                    object.target = "advertiserId";
            }
            return object;
        };

        /**
         * Converts this TargetResource to JSON.
         * @function toJSON
         * @memberof amazon_amc.TargetResource
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TargetResource.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TargetResource
         * @function getTypeUrl
         * @memberof amazon_amc.TargetResource
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TargetResource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/amazon_amc.TargetResource";
        };

        return TargetResource;
    })();

    amazon_amc.AmazonAmcWorkerConfiguration = (function() {

        /**
         * Properties of an AmazonAmcWorkerConfiguration.
         * @memberof amazon_amc
         * @interface IAmazonAmcWorkerConfiguration
         * @property {amazon_amc.IDataInput|null} [input] AmazonAmcWorkerConfiguration input
         * @property {string|null} [credentialsDependency] AmazonAmcWorkerConfiguration credentialsDependency
         * @property {amazon_amc.ITargetResource|null} [targetResource] AmazonAmcWorkerConfiguration targetResource
         * @property {string|null} [audienceId] AmazonAmcWorkerConfiguration audienceId
         * @property {string|null} [audienceName] AmazonAmcWorkerConfiguration audienceName
         * @property {string|null} [audienceDescription] AmazonAmcWorkerConfiguration audienceDescription
         * @property {amazon_amc.Country|null} [country] AmazonAmcWorkerConfiguration country
         * @property {amazon_amc.IdFormat|null} [idFormat] AmazonAmcWorkerConfiguration idFormat
         */

        /**
         * Constructs a new AmazonAmcWorkerConfiguration.
         * @memberof amazon_amc
         * @classdesc Represents an AmazonAmcWorkerConfiguration.
         * @implements IAmazonAmcWorkerConfiguration
         * @constructor
         * @param {amazon_amc.IAmazonAmcWorkerConfiguration=} [properties] Properties to set
         */
        function AmazonAmcWorkerConfiguration(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AmazonAmcWorkerConfiguration input.
         * @member {amazon_amc.IDataInput|null|undefined} input
         * @memberof amazon_amc.AmazonAmcWorkerConfiguration
         * @instance
         */
        AmazonAmcWorkerConfiguration.prototype.input = null;

        /**
         * AmazonAmcWorkerConfiguration credentialsDependency.
         * @member {string} credentialsDependency
         * @memberof amazon_amc.AmazonAmcWorkerConfiguration
         * @instance
         */
        AmazonAmcWorkerConfiguration.prototype.credentialsDependency = "";

        /**
         * AmazonAmcWorkerConfiguration targetResource.
         * @member {amazon_amc.ITargetResource|null|undefined} targetResource
         * @memberof amazon_amc.AmazonAmcWorkerConfiguration
         * @instance
         */
        AmazonAmcWorkerConfiguration.prototype.targetResource = null;

        /**
         * AmazonAmcWorkerConfiguration audienceId.
         * @member {string} audienceId
         * @memberof amazon_amc.AmazonAmcWorkerConfiguration
         * @instance
         */
        AmazonAmcWorkerConfiguration.prototype.audienceId = "";

        /**
         * AmazonAmcWorkerConfiguration audienceName.
         * @member {string} audienceName
         * @memberof amazon_amc.AmazonAmcWorkerConfiguration
         * @instance
         */
        AmazonAmcWorkerConfiguration.prototype.audienceName = "";

        /**
         * AmazonAmcWorkerConfiguration audienceDescription.
         * @member {string|null|undefined} audienceDescription
         * @memberof amazon_amc.AmazonAmcWorkerConfiguration
         * @instance
         */
        AmazonAmcWorkerConfiguration.prototype.audienceDescription = null;

        /**
         * AmazonAmcWorkerConfiguration country.
         * @member {amazon_amc.Country} country
         * @memberof amazon_amc.AmazonAmcWorkerConfiguration
         * @instance
         */
        AmazonAmcWorkerConfiguration.prototype.country = 0;

        /**
         * AmazonAmcWorkerConfiguration idFormat.
         * @member {amazon_amc.IdFormat} idFormat
         * @memberof amazon_amc.AmazonAmcWorkerConfiguration
         * @instance
         */
        AmazonAmcWorkerConfiguration.prototype.idFormat = 0;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * AmazonAmcWorkerConfiguration _audienceDescription.
         * @member {"audienceDescription"|undefined} _audienceDescription
         * @memberof amazon_amc.AmazonAmcWorkerConfiguration
         * @instance
         */
        Object.defineProperty(AmazonAmcWorkerConfiguration.prototype, "_audienceDescription", {
            get: $util.oneOfGetter($oneOfFields = ["audienceDescription"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new AmazonAmcWorkerConfiguration instance using the specified properties.
         * @function create
         * @memberof amazon_amc.AmazonAmcWorkerConfiguration
         * @static
         * @param {amazon_amc.IAmazonAmcWorkerConfiguration=} [properties] Properties to set
         * @returns {amazon_amc.AmazonAmcWorkerConfiguration} AmazonAmcWorkerConfiguration instance
         */
        AmazonAmcWorkerConfiguration.create = function create(properties) {
            return new AmazonAmcWorkerConfiguration(properties);
        };

        /**
         * Encodes the specified AmazonAmcWorkerConfiguration message. Does not implicitly {@link amazon_amc.AmazonAmcWorkerConfiguration.verify|verify} messages.
         * @function encode
         * @memberof amazon_amc.AmazonAmcWorkerConfiguration
         * @static
         * @param {amazon_amc.IAmazonAmcWorkerConfiguration} message AmazonAmcWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AmazonAmcWorkerConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.input != null && Object.hasOwnProperty.call(message, "input"))
                $root.amazon_amc.DataInput.encode(message.input, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.credentialsDependency != null && Object.hasOwnProperty.call(message, "credentialsDependency"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.credentialsDependency);
            if (message.targetResource != null && Object.hasOwnProperty.call(message, "targetResource"))
                $root.amazon_amc.TargetResource.encode(message.targetResource, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.audienceId != null && Object.hasOwnProperty.call(message, "audienceId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.audienceId);
            if (message.audienceName != null && Object.hasOwnProperty.call(message, "audienceName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.audienceName);
            if (message.audienceDescription != null && Object.hasOwnProperty.call(message, "audienceDescription"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.audienceDescription);
            if (message.country != null && Object.hasOwnProperty.call(message, "country"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.country);
            if (message.idFormat != null && Object.hasOwnProperty.call(message, "idFormat"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.idFormat);
            return writer;
        };

        /**
         * Encodes the specified AmazonAmcWorkerConfiguration message, length delimited. Does not implicitly {@link amazon_amc.AmazonAmcWorkerConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof amazon_amc.AmazonAmcWorkerConfiguration
         * @static
         * @param {amazon_amc.IAmazonAmcWorkerConfiguration} message AmazonAmcWorkerConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AmazonAmcWorkerConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AmazonAmcWorkerConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof amazon_amc.AmazonAmcWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {amazon_amc.AmazonAmcWorkerConfiguration} AmazonAmcWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AmazonAmcWorkerConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.amazon_amc.AmazonAmcWorkerConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.input = $root.amazon_amc.DataInput.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.credentialsDependency = reader.string();
                        break;
                    }
                case 3: {
                        message.targetResource = $root.amazon_amc.TargetResource.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.audienceId = reader.string();
                        break;
                    }
                case 5: {
                        message.audienceName = reader.string();
                        break;
                    }
                case 6: {
                        message.audienceDescription = reader.string();
                        break;
                    }
                case 7: {
                        message.country = reader.int32();
                        break;
                    }
                case 8: {
                        message.idFormat = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AmazonAmcWorkerConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof amazon_amc.AmazonAmcWorkerConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {amazon_amc.AmazonAmcWorkerConfiguration} AmazonAmcWorkerConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AmazonAmcWorkerConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AmazonAmcWorkerConfiguration message.
         * @function verify
         * @memberof amazon_amc.AmazonAmcWorkerConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AmazonAmcWorkerConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.input != null && message.hasOwnProperty("input")) {
                let error = $root.amazon_amc.DataInput.verify(message.input);
                if (error)
                    return "input." + error;
            }
            if (message.credentialsDependency != null && message.hasOwnProperty("credentialsDependency"))
                if (!$util.isString(message.credentialsDependency))
                    return "credentialsDependency: string expected";
            if (message.targetResource != null && message.hasOwnProperty("targetResource")) {
                let error = $root.amazon_amc.TargetResource.verify(message.targetResource);
                if (error)
                    return "targetResource." + error;
            }
            if (message.audienceId != null && message.hasOwnProperty("audienceId"))
                if (!$util.isString(message.audienceId))
                    return "audienceId: string expected";
            if (message.audienceName != null && message.hasOwnProperty("audienceName"))
                if (!$util.isString(message.audienceName))
                    return "audienceName: string expected";
            if (message.audienceDescription != null && message.hasOwnProperty("audienceDescription")) {
                properties._audienceDescription = 1;
                if (!$util.isString(message.audienceDescription))
                    return "audienceDescription: string expected";
            }
            if (message.country != null && message.hasOwnProperty("country"))
                switch (message.country) {
                default:
                    return "country: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                case 19:
                case 20:
                case 21:
                    break;
                }
            if (message.idFormat != null && message.hasOwnProperty("idFormat"))
                switch (message.idFormat) {
                default:
                    return "idFormat: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        /**
         * Creates an AmazonAmcWorkerConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof amazon_amc.AmazonAmcWorkerConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {amazon_amc.AmazonAmcWorkerConfiguration} AmazonAmcWorkerConfiguration
         */
        AmazonAmcWorkerConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.amazon_amc.AmazonAmcWorkerConfiguration)
                return object;
            let message = new $root.amazon_amc.AmazonAmcWorkerConfiguration();
            if (object.input != null) {
                if (typeof object.input !== "object")
                    throw TypeError(".amazon_amc.AmazonAmcWorkerConfiguration.input: object expected");
                message.input = $root.amazon_amc.DataInput.fromObject(object.input);
            }
            if (object.credentialsDependency != null)
                message.credentialsDependency = String(object.credentialsDependency);
            if (object.targetResource != null) {
                if (typeof object.targetResource !== "object")
                    throw TypeError(".amazon_amc.AmazonAmcWorkerConfiguration.targetResource: object expected");
                message.targetResource = $root.amazon_amc.TargetResource.fromObject(object.targetResource);
            }
            if (object.audienceId != null)
                message.audienceId = String(object.audienceId);
            if (object.audienceName != null)
                message.audienceName = String(object.audienceName);
            if (object.audienceDescription != null)
                message.audienceDescription = String(object.audienceDescription);
            switch (object.country) {
            default:
                if (typeof object.country === "number") {
                    message.country = object.country;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                message.country = 0;
                break;
            case "UNITED_ARAB_EMIRATES":
            case 1:
                message.country = 1;
                break;
            case "AUSTRALIA":
            case 2:
                message.country = 2;
                break;
            case "BELGIUM":
            case 3:
                message.country = 3;
                break;
            case "BRAZIL":
            case 4:
                message.country = 4;
                break;
            case "CANADA":
            case 5:
                message.country = 5;
                break;
            case "GERMANY":
            case 6:
                message.country = 6;
                break;
            case "EGYPT":
            case 7:
                message.country = 7;
                break;
            case "SPAIN":
            case 8:
                message.country = 8;
                break;
            case "FRANCE":
            case 9:
                message.country = 9;
                break;
            case "UNITED_KINGDOM":
            case 10:
                message.country = 10;
                break;
            case "INDIA":
            case 11:
                message.country = 11;
                break;
            case "ITALY":
            case 12:
                message.country = 12;
                break;
            case "JAPAN":
            case 13:
                message.country = 13;
                break;
            case "MEXICO":
            case 14:
                message.country = 14;
                break;
            case "NETHERLANDS":
            case 15:
                message.country = 15;
                break;
            case "POLAND":
            case 16:
                message.country = 16;
                break;
            case "SAUDI_ARABIA":
            case 17:
                message.country = 17;
                break;
            case "SWEDEN":
            case 18:
                message.country = 18;
                break;
            case "SINGAPORE":
            case 19:
                message.country = 19;
                break;
            case "TURKEY":
            case 20:
                message.country = 20;
                break;
            case "UNITED_STATES_OF_AMERICA":
            case 21:
                message.country = 21;
                break;
            }
            switch (object.idFormat) {
            default:
                if (typeof object.idFormat === "number") {
                    message.idFormat = object.idFormat;
                    break;
                }
                break;
            case "UNHASHED_EMAIL":
            case 0:
                message.idFormat = 0;
                break;
            case "HASHED_EMAIL_SHA256":
            case 1:
                message.idFormat = 1;
                break;
            case "PHONE_NR":
            case 2:
                message.idFormat = 2;
                break;
            case "HASHED_PHONE_NR_SHA256":
            case 3:
                message.idFormat = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an AmazonAmcWorkerConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof amazon_amc.AmazonAmcWorkerConfiguration
         * @static
         * @param {amazon_amc.AmazonAmcWorkerConfiguration} message AmazonAmcWorkerConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AmazonAmcWorkerConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.input = null;
                object.credentialsDependency = "";
                object.targetResource = null;
                object.audienceId = "";
                object.audienceName = "";
                object.country = options.enums === String ? "UNKNOWN" : 0;
                object.idFormat = options.enums === String ? "UNHASHED_EMAIL" : 0;
            }
            if (message.input != null && message.hasOwnProperty("input"))
                object.input = $root.amazon_amc.DataInput.toObject(message.input, options);
            if (message.credentialsDependency != null && message.hasOwnProperty("credentialsDependency"))
                object.credentialsDependency = message.credentialsDependency;
            if (message.targetResource != null && message.hasOwnProperty("targetResource"))
                object.targetResource = $root.amazon_amc.TargetResource.toObject(message.targetResource, options);
            if (message.audienceId != null && message.hasOwnProperty("audienceId"))
                object.audienceId = message.audienceId;
            if (message.audienceName != null && message.hasOwnProperty("audienceName"))
                object.audienceName = message.audienceName;
            if (message.audienceDescription != null && message.hasOwnProperty("audienceDescription")) {
                object.audienceDescription = message.audienceDescription;
                if (options.oneofs)
                    object._audienceDescription = "audienceDescription";
            }
            if (message.country != null && message.hasOwnProperty("country"))
                object.country = options.enums === String ? $root.amazon_amc.Country[message.country] === undefined ? message.country : $root.amazon_amc.Country[message.country] : message.country;
            if (message.idFormat != null && message.hasOwnProperty("idFormat"))
                object.idFormat = options.enums === String ? $root.amazon_amc.IdFormat[message.idFormat] === undefined ? message.idFormat : $root.amazon_amc.IdFormat[message.idFormat] : message.idFormat;
            return object;
        };

        /**
         * Converts this AmazonAmcWorkerConfiguration to JSON.
         * @function toJSON
         * @memberof amazon_amc.AmazonAmcWorkerConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AmazonAmcWorkerConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AmazonAmcWorkerConfiguration
         * @function getTypeUrl
         * @memberof amazon_amc.AmazonAmcWorkerConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AmazonAmcWorkerConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/amazon_amc.AmazonAmcWorkerConfiguration";
        };

        return AmazonAmcWorkerConfiguration;
    })();

    return amazon_amc;
})();

export { $root as default };
