import { memo, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { type DatasetExportDerivedState } from "./models";
import { decodeDatasetExportDerivedState } from "./utils";

// This component is needed to redirect the user to the correct export route for a dataset export that includes the dataset manifest hash.
// It's needed to workaround the limitation of the Amazon AMC which doesn't allow to redirect to a route with a dynamic dataset manifest hash in the URL.
const DatasetExportRouteRedirect = memo(() => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const exportState = useMemo<DatasetExportDerivedState | null>(() => {
    const searchParams = new URLSearchParams(search);
    const stateParam = searchParams.get("state");
    return stateParam ? decodeDatasetExportDerivedState(stateParam) : null;
  }, [search]);
  useEffect(() => {
    if (!exportState?.manifestHash) {
      navigate("/datasets/datasets");
      return;
    }
    navigate({
      pathname: `/datasets/datasets/${exportState.manifestHash}/export`,
      search,
    });
  }, [exportState?.manifestHash, search, navigate]);
  return null;
});

DatasetExportRouteRedirect.displayName = "DatasetExportRouteRedirect";

export default DatasetExportRouteRedirect;
