import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { memo, useCallback, useState } from "react";
import { logError } from "utils";

interface AmazonAmcConsentDialogProps {
  open: boolean;
  agreementContent: string;
  onCancel: () => void;
  onSubmit: () => Promise<void>;
}

const AmazonAmcConsentDialog = memo<AmazonAmcConsentDialogProps>(
  ({ open, onCancel, onSubmit, agreementContent }) => {
    const [loading, setLoading] = useState(false);
    const handleSubmit = useCallback(async () => {
      try {
        setLoading(true);
        await onSubmit();
      } catch (error) {
        logError(error);
      } finally {
        setLoading(false);
      }
    }, [setLoading, onSubmit]);
    return (
      <Modal onClose={!loading ? onCancel : undefined} open={open}>
        <ModalDialog>
          <DialogTitle>
            <Typography level="h4" textAlign="center">
              Amazon AMC Terms & Conditions
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography level="body-sm">
              <div dangerouslySetInnerHTML={{ __html: agreementContent }}></div>
            </Typography>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button disabled={loading} onClick={onCancel}>
              Cancel
            </Button>
            <Button
              loading={loading}
              loadingPosition="start"
              onClick={void handleSubmit}
              startDecorator={<FontAwesomeIcon icon={faCheck} />}
              variant="solid"
            >
              Accept
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    );
  }
);
AmazonAmcConsentDialog.displayName = "AmazonAmcConsentDialog";

export default AmazonAmcConsentDialog;
