import {
  DqDatasetExportFormProvider,
  type DqDatasetExportFormProviderProps,
  type DqDatasetExportFormProviderSchemaType,
  DqDatasetSyncDrawer,
} from "@decentriq/components";
import { type DataTargetType } from "@decentriq/graphql/dist/types";
import { memo, useEffect, useState } from "react";
import { Wizard } from "react-use-wizard";
import CreateExportStep from "../CreateExportStep/CreateExportStep";
import SelectSyncStep from "../SelectSyncStep/SelectSyncStep";
import ShowStatusStep from "../ShowStatusStep/ShowStatusStep";

export interface DatasetExportDialogProps {
  manifestHash: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (values: DqDatasetExportFormProviderSchemaType) => Promise<void>;
  canChangeSyncTypeTo?: (type: DataTargetType | null) => boolean;
  defaultSyncType?: DataTargetType;
  defaultValues?: DqDatasetExportFormProviderProps["defaultValues"];
}

const DatasetExportDialog = memo<DatasetExportDialogProps>(
  ({
    open,
    onClose,
    manifestHash,
    onSubmit,
    canChangeSyncTypeTo,
    defaultSyncType,
    defaultValues,
  }) => {
    const [syncType, setSyncType] = useState<DataTargetType | null>(
      defaultSyncType ?? null
    );
    useEffect(() => {
      if (!open) {
        setSyncType(defaultSyncType ?? null);
      }
    }, [open, defaultSyncType]);
    const hasSelectionStep = !defaultSyncType;
    return (
      <DqDatasetExportFormProvider
        datasetManifestHash={manifestHash}
        defaultValues={defaultValues}
        syncType={syncType ?? undefined}
      >
        <DqDatasetSyncDrawer onClose={onClose} open={open}>
          <Wizard key={open.toString()}>
            {hasSelectionStep && (
              <SelectSyncStep
                canChangeTo={canChangeSyncTypeTo}
                defaultSyncType={defaultSyncType}
                onClose={onClose}
                onSelect={[setSyncType]}
              />
            )}
            <CreateExportStep
              hasPreviousStep={hasSelectionStep}
              onClose={onClose}
              onSubmit={onSubmit}
              syncType={syncType!}
            />
            <ShowStatusStep onClose={onClose} syncType={syncType!} />
          </Wizard>
        </DqDatasetSyncDrawer>
      </DqDatasetExportFormProvider>
    );
  }
);
DatasetExportDialog.displayName = "DatasetExportDialog";

export default DatasetExportDialog;
