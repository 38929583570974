import { useBoolean } from "ahooks";
import { Fragment, memo } from "react";
import DatasetExportDialog from "./components/DatasetExportDialog/DatasetExportDialog";
import {
  DatasetExportProvider,
  useDatasetExport,
} from "./contexts/DatasetExportContext/DatasetExportContext";

interface DatasetExportProps {
  manifestHash: string;
  renderTrigger: (open: () => void) => React.ReactNode;
}

const DatasetExport = memo<DatasetExportProps>(
  ({ manifestHash, renderTrigger }) => {
    // TODO: Ideally this component should just provide callback with navigation to export route to the renderTrigger.
    // Currently there is an issue with drawer animation when opening it
    const [isDialogOpen, { setTrue: openDialog, setFalse: closeDialog }] =
      useBoolean(false);
    const { handleSubmit, handleCanChangeSyncTypeTo } = useDatasetExport();
    return (
      <Fragment>
        {renderTrigger(openDialog)}
        <DatasetExportDialog
          canChangeSyncTypeTo={handleCanChangeSyncTypeTo}
          manifestHash={manifestHash}
          onClose={closeDialog}
          onSubmit={handleSubmit}
          open={isDialogOpen}
        />
      </Fragment>
    );
  }
);

const DatasetExportWithProvider = memo<DatasetExportProps>((props) => (
  <DatasetExportProvider manifestHash={props.manifestHash}>
    <DatasetExport {...props} />
  </DatasetExportProvider>
));

DatasetExportWithProvider.displayName = "DatasetExport";

export default DatasetExportWithProvider;
