import { useDatasetByHashQuery } from "@decentriq/graphql/dist/hooks";
import { DataTargetType } from "@decentriq/graphql/dist/types";
import { memo, useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useConfiguration } from "contexts";
import {
  CommonSnackbarOrigin,
  mapErrorToGeneralSnackbar,
  useAmazonAuth,
  useGeneralSnackbar,
} from "hooks";
import AmazonAmcConsentDialog from "./components/AmazonAmcConsentDialog/AmazonAmcConsentDialog";
import DatasetExportDialog, {
  type DatasetExportDialogProps,
} from "./components/DatasetExportDialog/DatasetExportDialog";
import {
  DatasetExportConsumer,
  DatasetExportProvider,
} from "./contexts/DatasetExportContext/DatasetExportContext";
import { type DatasetExportDerivedState } from "./models";
import { decodeDatasetExportDerivedState } from "./utils";

const DatasetExportRoute = memo(() => {
  const { amazonAmcClientId } = useConfiguration();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.DATASET_PORTAL,
  });
  const { manifestHash } = useParams();
  const { data, loading } = useDatasetByHashQuery({
    skip: !manifestHash,
    variables: { manifestHash: manifestHash! },
  });
  const { search } = useLocation();
  const [exportState, amazonAuthCode] = useMemo<
    [DatasetExportDerivedState | null, string | null]
  >(() => {
    const searchParams = new URLSearchParams(search);
    const stateParam = searchParams.get("state");
    const amazonAuthCode = searchParams.get("code") ?? null;
    return [
      stateParam ? decodeDatasetExportDerivedState(stateParam) : null,
      amazonAuthCode,
    ];
  }, [search]);
  const {
    data: amazonAmcAuthData,
    loading: amazonAmcAuthLoading,
    acceptConsent,
  } = useAmazonAuth({
    authCode: amazonAuthCode,
    onError: useCallback(
      (error: Error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "Unable to authenticate with Amazon AMC."
          )
        );
        navigate(`/datasets/datasets`);
      },
      [navigate, enqueueSnackbar]
    ),
    skip: exportState?.syncType !== DataTargetType.AmazonAmc,
  });
  const defaultValues = useMemo<DatasetExportDialogProps["defaultValues"]>(
    () => ({
      amazonAmc:
        exportState?.syncType === DataTargetType.AmazonAmc &&
        amazonAmcAuthData?.accessToken &&
        amazonAmcAuthData?.refreshToken
          ? {
              accessToken: amazonAmcAuthData.accessToken,
              clientId: amazonAmcClientId,
              refreshToken: amazonAmcAuthData.refreshToken,
            }
          : {},
    }),
    [
      amazonAmcAuthData?.accessToken,
      amazonAmcAuthData?.refreshToken,
      exportState?.syncType,
      amazonAmcClientId,
    ]
  );
  if (loading || !data || !manifestHash || amazonAmcAuthLoading) {
    return null;
  }
  if (amazonAmcAuthData?.hasAccepted === false) {
    return (
      <AmazonAmcConsentDialog
        agreementContent={amazonAmcAuthData.agreementContent ?? ""}
        onCancel={() => {
          navigate(`/datasets/datasets`);
        }}
        onSubmit={acceptConsent}
        open={true}
      />
    );
  }
  return (
    <DatasetExportProvider manifestHash={manifestHash}>
      <DatasetExportConsumer>
        {({ handleCanChangeSyncTypeTo, handleSubmit }) => (
          <DatasetExportDialog
            canChangeSyncTypeTo={handleCanChangeSyncTypeTo}
            defaultSyncType={exportState?.syncType}
            defaultValues={defaultValues}
            manifestHash={manifestHash}
            onClose={() => {
              navigate(`/datasets/datasets`);
            }}
            onSubmit={handleSubmit}
            open={true}
          />
        )}
      </DatasetExportConsumer>
    </DatasetExportProvider>
  );
});
DatasetExportRoute.displayName = "DatasetExportRoute";

export default DatasetExportRoute;
