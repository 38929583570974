import { useAuth0 } from "@auth0/auth0-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { AmazonClient } from "services";
import { useConfiguration } from "contexts";
import { getEffectiveErrorMessage } from "utils";

interface AmazonAuthHookPayload {
  authCode: string | null;
  skip?: boolean;
  onError?: (error: Error) => void;
}

interface AmazonAuthData {
  accessToken: string;
  refreshToken: string;
  agreementContent: string | null;
  agreementToken: string | null;
  hasAccepted: boolean;
}

interface AmazonAuthHookResult {
  data?: AmazonAuthData | null;
  loading: boolean;
  error?: Error | null;
  acceptConsent: () => Promise<void>;
}

const useAmazonAuth = ({
  skip,
  onError,
  authCode,
}: AmazonAuthHookPayload): AmazonAuthHookResult => {
  const queryClient = useQueryClient();
  const { amazonAmcClientId } = useConfiguration();
  const { user } = useAuth0();
  const currentUserEmail = user?.email ?? "";
  const amazonClient = useMemo(
    () => new AmazonClient(amazonAmcClientId),
    [amazonAmcClientId]
  );
  const queryKey = useMemo(
    () => ["amazonAuth", currentUserEmail, authCode],
    [currentUserEmail, authCode]
  );
  const { data, isLoading, error } = useQuery({
    enabled: !skip,
    queryFn: async () => {
      try {
        if (!authCode) {
          throw new Error("Auth code is missing");
        }
        const { accessToken, refreshToken } = await amazonClient.verifyAuthCode(
          {
            code: authCode,
            redirectUrl: amazonClient.redirectUrl,
          }
        );
        const { agreementContent, agreementToken, hasAccepted } =
          await amazonClient.getConsent();
        return {
          accessToken,
          agreementContent,
          agreementToken,
          hasAccepted,
          refreshToken,
        };
      } catch (error) {
        onError?.(
          error instanceof Error
            ? error
            : new Error(getEffectiveErrorMessage(error))
        );
        throw error;
      }
    },
    queryKey,
  });
  const acceptConsent = useCallback(async () => {
    if (!data?.agreementToken) {
      throw new Error("Agreement token is missing");
    }
    await amazonClient.acceptConsent({
      agreementToken: data?.agreementToken,
    });
    queryClient.setQueryData<AmazonAuthData>(queryKey, (prevData) => ({
      ...prevData!,
      hasAccepted: true,
    }));
  }, [amazonClient, data?.agreementToken, queryKey, queryClient]);
  return {
    acceptConsent,
    data,
    error,
    loading: isLoading,
  };
};

export default useAmazonAuth;
