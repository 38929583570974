import {
  type DqDatasetExportFormProviderSchemaType,
  DqDatasetSyncActions,
  DqDatasetSyncForm,
  DqDatasetSyncFormControls,
  type DqDatasetSyncFormProps,
  DqDatasetSyncTitle,
  SyncDirectionType,
} from "@decentriq/components";
import { type DataTargetType } from "@decentriq/graphql/dist/types";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ModalDialog,
} from "@mui/joy";
import { memo } from "react";
import { useWizard } from "react-use-wizard";

const FORM_ID = "export-dataset-form";

interface CreateExportStepProps {
  onClose?: () => void;
  onSubmit: DqDatasetSyncFormProps<DqDatasetExportFormProviderSchemaType>["onSubmit"];
  syncType: DataTargetType;
  hasPreviousStep?: boolean;
}

const CreateExportStep: React.FC<CreateExportStepProps> = memo(
  ({ syncType, onSubmit, hasPreviousStep, onClose }) => {
    const { previousStep, nextStep } = useWizard();
    return (
      <DqDatasetSyncForm<DqDatasetExportFormProviderSchemaType>
        id={FORM_ID}
        onSubmit={(formValues, event) =>
          onSubmit(formValues, event).then(() => nextStep())
        }
      >
        <ModalDialog>
          <DialogTitle>
            <DqDatasetSyncTitle
              syncDirection={SyncDirectionType.EXPORT}
              syncType={syncType}
            />
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DqDatasetSyncFormControls
              syncDirection={SyncDirectionType.EXPORT}
              syncType={syncType}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <DqDatasetSyncActions
              form={FORM_ID}
              resetButtonProps={{
                children: hasPreviousStep ? "Back" : "Close",
                onClick: hasPreviousStep ? previousStep : onClose,
              }}
              submitButtonProps={{ children: "Export" }}
            />
          </DialogActions>
        </ModalDialog>
      </DqDatasetSyncForm>
    );
  }
);
CreateExportStep.displayName = "CreateExportStep";

export default CreateExportStep;
