import { type DataTargetType } from "@decentriq/graphql/dist/types";
import { logError } from "utils";
import {
  type DatasetExportDerivedState,
  DatasetExportDerivedStateSchema,
} from "./models";

export const decodeDatasetExportDerivedState = (hash: string) => {
  try {
    const derivedState = JSON.parse(atob(hash)) as DatasetExportDerivedState;
    const isValid =
      DatasetExportDerivedStateSchema.safeParse(derivedState).success;
    if (!isValid) {
      return null;
    }
    return derivedState;
  } catch (error) {
    logError(error);
    return null;
  }
};

export const encodeDatasetExportDerivedState = (
  manifestHash: string,
  syncType: DataTargetType,
  userId: string
) =>
  btoa(
    JSON.stringify({
      manifestHash,
      syncType,
      userId,
    })
  );
