import { logInfo } from "utils";
import {
  type AmazonAcceptConsentRequestDto,
  type AmazonAuthorizeRequestDto,
  type AmazonGetConsentResponseDto,
  type AmazonVerifyAuthCodeRequestDto,
  type AmazonVerifyAuthCodeResponseDto,
  type IAmazonClient,
} from "./models";

export class AmazonClient implements IAmazonClient {
  static BASE_ACCOUNT_API_URL = "https://eu.account.amazon.com";
  static BASE_API_URL = "https://api.amazon.co.uk";
  static BASE_ADVERTISING_API_URL = "https://advertising-api.amazon.com";
  static SCOPE = "advertising::audiences";
  static CLIENT_SECRET =
    "amzn1.oa2-cs.v1.c0e9e3666fdd3e84c9cbc53ca024bb203d97843c06e71d83787c3fb8c6e94a12";

  private _clientId: string;
  private _authTokens: { accessToken: string; refreshToken: string } | null =
    null;

  constructor(clientId: string) {
    this._clientId = clientId;
  }

  get clientId(): string {
    return this._clientId;
  }

  get redirectUrl(): string {
    return `${window.location.origin}/datasets/datasets/export`;
  }

  authorize({ redirectUrl }: AmazonAuthorizeRequestDto): void {
    window.location.href = `${AmazonClient.BASE_ACCOUNT_API_URL}/ap/oa?client_id=${this._clientId}&scope=${AmazonClient.SCOPE}&response_type=code&redirect_uri=${redirectUrl}`;
  }

  async verifyAuthCode({
    code,
    redirectUrl,
  }: AmazonVerifyAuthCodeRequestDto): Promise<AmazonVerifyAuthCodeResponseDto> {
    try {
      const params = new URLSearchParams({
        client_id: this._clientId,
        client_secret: AmazonClient.CLIENT_SECRET,
        code,
        grant_type: "authorization_code",
        redirect_uri: redirectUrl,
      });
      const response = await fetch(
        `${AmazonClient.BASE_API_URL}/auth/o2/token`,
        {
          body: params.toString(),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          method: "POST",
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = (await response.json()) as {
        access_token: string;
        refresh_token: string;
      };
      this._authTokens = {
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
      };
      return this._authTokens;
    } catch (error) {
      logInfo(error);
      throw error;
    }
  }

  async getConsent(): Promise<AmazonGetConsentResponseDto> {
    try {
      if (!this._authTokens) {
        throw new Error("No auth tokens available. Please authorize first.");
      }
      const response = await fetch(
        `${AmazonClient.BASE_ADVERTISING_API_URL}/amc/audiences/connections/terms`,
        {
          headers: {
            "amazon-advertising-api-clientid": this._clientId,
            authorization: `Bearer ${this._authTokens.accessToken}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return (await response.json()) as AmazonGetConsentResponseDto;
    } catch (error) {
      logInfo(error);
      throw error;
    }
  }

  async acceptConsent({
    agreementToken,
  }: AmazonAcceptConsentRequestDto): Promise<void> {
    try {
      if (!this._authTokens) {
        throw new Error("No auth tokens available. Please authorize first.");
      }
      const response = await fetch(
        `${AmazonClient.BASE_ADVERTISING_API_URL}/amc/audiences/connections/terms`,
        {
          body: JSON.stringify({
            agreementToken,
            hasAccepted: true,
          }),
          headers: {
            "Content-Type": "application/json",
            "amazon-advertising-api-clientid": this._clientId,
            authorization: `Bearer ${this._authTokens.accessToken}`,
          },
          method: "PATCH",
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      logInfo(error);
      throw error;
    }
  }
}
